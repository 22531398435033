import React from 'react';
import {
  Collapse as MUICollapse,
  CollapseProps as CollapsePropsType,
} from '@mui/material';

export type CollapseProps = CollapsePropsType;

export const Collapse = (props: CollapseProps) => {
  return <MUICollapse {...props} />;
};

export default Collapse;
