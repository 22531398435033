import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { ATTRIBUTE_GROUP_CREATE } from '@atom/graph/schema';
import { Icon, IconButton, Tooltip } from '@atom/mui';
import { AttributeGroupCreateInput, SchemaTree } from '@atom/types/schema';

import AddItemModal from '../AddItemModal';

import './subItemDetail.css';

interface Props {
  setCreatedAttributeGroupId: Dispatch<SetStateAction<string>>;
}

const DetailHeader = ({ setCreatedAttributeGroupId }: Props) => {
  const container = useRef<HTMLDivElement>();

  const { schemaTree, refetchSchemaTree, selectedSubItem } = useContext(
    SchemaDetailContext,
  );

  const [overflowing, setOverflowing] = useState<boolean>(false);
  const [openAttributeGroupCreate, setOpenAttributeGroupCreate] = useState<
    boolean
  >(false);

  const [createAttributeGroup] = useMutation<
    { attributeGroupCreate: SchemaTree },
    { input: AttributeGroupCreateInput }
  >(ATTRIBUTE_GROUP_CREATE);

  useEffect(() => {
    const { offsetWidth, scrollWidth } = container.current;
    setOverflowing(offsetWidth < scrollWidth);
  }, [selectedSubItem.name]);

  const handleAttributeGroupCreate = async (name: string) => {
    const { data } = await createAttributeGroup({
      variables: {
        input: {
          schemaId: selectedSubItem.id,
          name,
        },
      },
    });

    await refetchSchemaTree();

    const newAttributeGroupId = R.last(
      data?.attributeGroupCreate?.attributeGroups,
    )?.id;

    setCreatedAttributeGroupId(newAttributeGroupId);
    setOpenAttributeGroupCreate(false);
  };

  const showAddButton = !schemaTree?.isPublished;

  return (
    <>
      <div styleName="detail-header-container">
        <div styleName="detail-header-text" ref={container}>
          {!overflowing ? (
            selectedSubItem.name
          ) : (
            <Tooltip title={selectedSubItem.name} placement="bottom-start">
              <p styleName="detail-header-text">{selectedSubItem.name}</p>
            </Tooltip>
          )}
        </div>
        {showAddButton && (
          <IconButton
            onClick={() => setOpenAttributeGroupCreate(true)}
            size="small"
            tooltip="Add Group"
          >
            <Icon>control_point_duplicate</Icon>
          </IconButton>
        )}
      </div>
      <AddItemModal
        open={openAttributeGroupCreate}
        closeModal={() => setOpenAttributeGroupCreate(false)}
        type="Group"
        handleSave={handleAttributeGroupCreate}
      />
    </>
  );
};

export default DetailHeader;
