import * as React from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';

import './mapDrawerContent.css';

function setButtonStyleActive(activeView, value) {
  return activeView === value ? ' active' : '';
}

interface Props {
  value: string;
  label: string;
  icon: any;
  activeView: string;
  setActiveView: (view: string) => void;
}

const NavigationIcon = ({
  value,
  label,
  icon,
  activeView,
  setActiveView,
}: Props) => {
  const iconActiveStyle = setButtonStyleActive(activeView, value);
  const activeClassName = `icon-container${iconActiveStyle}`;

  return (
    <div onClick={() => setActiveView(value)} styleName={activeClassName}>
      <IconButton
        iconStyle={{
          color: activeView === value ? colors.brand.blue : colors.neutral.gray,
        }}
      >
        <Icon>{icon}</Icon>
      </IconButton>
      <div styleName="label">{label}</div>
    </div>
  );
};

export default NavigationIcon;
