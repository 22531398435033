import React, { useContext, useEffect } from 'react';

import WorkOrderStatusPill from '@atom/components/common/workOrderDetail/workOrderStatusPill/WorkOrderStatusPill';
import { Menu } from '@atom/mui';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { hasAssignees } from '@atom/utilities/bulkWorkOrderUtilities';
import {
  ASSIGNED_STATUS_ID,
  CLOSED_STATUS_ID,
  COMPLETED_STATUS_ID,
  getWorkOrderStatusColor,
  IN_REVIEW_STATUS_ID,
  REQUESTED_STATUS_ID,
  workOrderStatusTypes,
} from '@atom/utilities/workOrderStatusUtilities';

import BulkContext from '../../../BulkContext';
import { useWorkOrderValidation } from '../../../hooks/bulkWorkOrderHook';

const { MenuItem } = Menu;

const styles = {
  statusIndicator: {
    height: '1rem',
    width: '1rem',
    marginRight: '1rem',
    borderRadius: '50%',
  },
};

const WorkStatus = () => {
  const { workOrder, workTemplateType, setWorkOrder } = useContext(BulkContext);
  const { validateGeneralTab } = useWorkOrderValidation();

  useEffect(() => {
    // Set the default status id if it's not set:
    if (!workOrder.statusId) {
      setWorkOrder({
        ...workOrder,
        statusId: hasAssignees(workTemplateType?.tasks)
          ? ASSIGNED_STATUS_ID
          : REQUESTED_STATUS_ID,
      });
    }
  }, [workOrder.statusId]);

  // Remove the "In Review" status for bulk work order create,
  // also the backend won't set completed and closed (date and user) fields
  // when the status is "In Review".
  const dropdownStatusTypes = workOrderStatusTypes.filter(
    statusType => statusType.value !== IN_REVIEW_STATUS_ID,
  );

  const isWorkStatusDisabled = (
    currentStatus: string,
    status: string,
  ): boolean => {
    if (!hasRolePermissions(ROLE_SETS.MANAGER) && status === CLOSED_STATUS_ID) {
      return true;
    }

    return (
      currentStatus !== COMPLETED_STATUS_ID &&
      currentStatus !== CLOSED_STATUS_ID &&
      currentStatus !== IN_REVIEW_STATUS_ID &&
      status === IN_REVIEW_STATUS_ID
    );
  };

  const updateStatus = statusId => {
    const newWorkOrder = {
      ...workOrder,
      statusId,
    };

    setWorkOrder(newWorkOrder);

    // Clear the general tab errors if the completed/closed fields
    // are not displayed due to the new status id.
    validateGeneralTab(newWorkOrder);
  };

  return (
    <Menu
      icon={
        <WorkOrderStatusPill
          statusId={workOrder.statusId}
          className="bulk-modal"
        />
      }
      noIconButton
    >
      {dropdownStatusTypes.map(status => {
        const background = getWorkOrderStatusColor(status.value);
        const isDisabled = isWorkStatusDisabled(
          workOrder.statusId,
          status.value,
        );

        const onClick = isDisabled
          ? () => {}
          : () => updateStatus(status.value);

        return (
          <MenuItem
            key={status.value}
            onClick={onClick}
            disabled={isDisabled}
            selected={workOrder.statusId === status.value}
          >
            <div
              style={{
                ...styles.statusIndicator,
                background,
                opacity: isDisabled ? '50%' : '100%',
              }}
            />
            {status.label}
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default WorkStatus;
