import * as React from 'react';
import { EventType } from 'client/types/event';

import TextField from '@atom/components/common/TextField';
import TextLimit from '@atom/components/common/TextLimit';
import {
  MAX_CHAR_DESCRIPTION,
  MAX_CHAR_SHORT_INPUT,
  REVEAL_LONG,
} from '@atom/selectors/formSelectors';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';

import '../formBuilder.css';

const styles = {
  fontSize: fonts.md,
  color: colors.neutral.dark,
};

interface Props {
  title: string;
  description: string;
  onChange: (event: EventType) => void;
}

const BasicInput = ({ title, description, onChange }: Props) => {
  return (
    <div styleName="multi-input-container">
      <div styleName="double-input">
        <TextField
          value={title}
          onChange={onChange}
          name="title"
          style={styles}
          fullWidth
          floatingLabelText="Title"
        />
        <div styleName="text-limit-container">
          <TextLimit
            limit={MAX_CHAR_SHORT_INPUT}
            reveal={REVEAL_LONG}
            text={title}
          />
        </div>
      </div>
      <div styleName="double-input">
        <TextField
          value={description}
          onChange={onChange}
          name="description"
          style={styles}
          fullWidth
          floatingLabelText="Description"
        />
        <div styleName="text-limit-container">
          <TextLimit
            limit={MAX_CHAR_DESCRIPTION}
            reveal={REVEAL_LONG}
            text={description}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicInput;
