import React, { useContext } from 'react';
import * as R from 'ramda';

import WorkOrdersContext from '@atom/components/workOrders/WorkOrdersContext';
import { INITIAL_INPUT } from '@atom/hooks/useWorkOrdersFilters';
import { Button } from '@atom/mui';

import WorkFiltersControls from './WorkFiltersControls';

import './workOrdersFilters.css';

const WorkOrdersFiltersHeader = () => {
  const {
    input,
    refetch,
    setInput,
    workOrdersInputCart,
    filtersDisabled,
    loadingFilters,
  } = useContext(WorkOrdersContext);

  const applyFilters = () => {
    const newFilter = { ...workOrdersInputCart, page: 1 };
    const rerunFilter = R.equals(newFilter, input);

    if (rerunFilter) {
      refetch();
    } else {
      setInput(newFilter);
    }
  };

  const resetFilters = () => {
    setInput({
      ...INITIAL_INPUT,
      ...R.pathOr({}, ['params'], input.defaultFilter),
      defaultFilter: input.defaultFilter,
      dateFilterPresetsCart: {
        taskDatePresets: input.defaultFilter.taskDatePresets,
        workDatePresets: input.defaultFilter.workDatePresets,
      },
    });
  };

  return (
    <div styleName="filters-header">
      <WorkFiltersControls />
      <div styleName="filters-buttons-container">
        <Button
          onClick={resetFilters}
          disabled={filtersDisabled || loadingFilters}
        >
          Reset
        </Button>
        <Button
          onClick={applyFilters}
          color="primary"
          disabled={filtersDisabled || loadingFilters}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default WorkOrdersFiltersHeader;
