import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import {
  useGoogleMapsStateUpdater,
  useGoogleMapsStateValue,
  withGoogleMapsState,
} from '@atom/components/common/map/hooks/googleMapsStateHook';
import Map from '@atom/components/common/map/Map';
import MapMarkers from '@atom/components/common/map/MapMarkers';
import MapPinMarker from '@atom/components/common/map/markers/MapPinMarker';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Progress } from '@atom/mui';
import { getLoggedInUserMapLocationSelector } from '@atom/selectors/mapSelectors';
import {
  MapEditMetadata,
  MapEditMetadataType,
  MapParams,
} from '@atom/types/map';
import { getLatLng } from '@atom/utilities/locationUtilities';
import {
  getGeoJSONFromGoogleMapShape,
  getGeoJsonFromMapBounds,
  getMapCenterAndZoomParams,
  getMapEditMetadata,
  trimPointLatLng,
  urlValueToLatLng,
} from '@atom/utilities/mapUtilities';

import CreateAssetModalContext from '../CreateAssetModalContext';

const INITIAL_ZOOM = 15;
const DECIMAL_ACCURACY = 6;

interface Props {
  onLoad?: any;
  map: google.maps.Map;
}

const LocationTabMap = ({ onLoad, map }: Props) => {
  const {
    location,
    setLocation,
    schema,
    setLocationFromSearch,
    locationFromSearch,
  } = useContext(CreateAssetModalContext);

  const currentUserLocation = useSelector(getLoggedInUserMapLocationSelector);

  const dispatch = useGoogleMapsStateUpdater();
  const { isLoaded, shape } = useGoogleMapsStateValue();
  const preferences = usePreferences();

  const [coordinates, setCoordinates] = useState<string>(null);
  const [mapParams, setMapParams] = useState<MapParams>({
    ids: [],
    hoverId: null,
    isActive: false,
    center: null,
    zoom: INITIAL_ZOOM,
    searchPoint: null,
    searchTerm: null,
  });

  useEffect(() => {
    const newLocation = getGeoJSONFromGoogleMapShape(shape);
    const validCoordinates = !R.all(coord => coord === 0)(
      newLocation?.coordinates,
    );

    if (newLocation && validCoordinates) {
      setLocation(trimPointLatLng(newLocation, DECIMAL_ACCURACY));
      setLocationFromSearch(false);
    }
  }, [shape]);

  useEffect(() => {
    dispatch({
      type: 'SET',
      payload: {
        key: 'grab',
        data: false,
      },
    });
  }, []);

  const updateMapParams = (params: MapParams) => {
    setMapParams({ ...mapParams, ...params });
  };

  useEffect(() => {
    if (locationFromSearch) {
      const newCenter = `${getLatLng(location?.coordinates)?.lat},${
        getLatLng(location?.coordinates)?.lng
      }`;
      updateMapParams({ center: newCenter, zoom: 15 });
    }
  }, [locationFromSearch, location]);

  const onIdle = useCallback(() => {
    try {
      const params = getMapCenterAndZoomParams(
        map,
        mapParams,
        preferences,
        currentUserLocation,
      );

      if (!R.equals(mapParams, params) || !coordinates) {
        setMapParams(params);
        R.pipe(
          getGeoJsonFromMapBounds,
          R.prop('coordinates'),
          JSON.stringify,
          setCoordinates,
        )(map);
      }
    } catch {
      setMapParams(null);
    }
  }, [map, setMapParams, mapParams, coordinates, preferences]);

  const mapData = {
    assets: [
      {
        id: 'create',
        location,
        markerId: schema.markerId,
      },
    ],
  };

  const mapEditMetadata: MapEditMetadata[] = useMemo(
    () => [
      getMapEditMetadata(
        {
          id: 'create',
          location,
          markerId: schema.markerId,
        },
        true,
        MapEditMetadataType.ASSET,
      ),
    ],
    [location],
  );

  return !isLoaded ? (
    <Progress style={{ height: '100%' }} />
  ) : (
    <Map
      onIdle={onIdle}
      onLoad={onLoad}
      // @ts-ignore
      center={mapParams.center && urlValueToLatLng(mapParams.center)}
      zoom={mapParams.zoom}
      mapParams={mapParams}
      updateMapParams={updateMapParams}
      mapStyleControl
      layerControls
      mapEditMetadata={mapEditMetadata}
      alwaysEditMode
      locationFromSearch={locationFromSearch}
    >
      <MapMarkers
        // @ts-ignore
        onClick={() => {}}
        autoActiveId="create"
        mapParams={mapParams}
        data={JSON.stringify(mapData)}
      />
      {mapParams.searchPoint && <MapPinMarker center={mapParams.searchPoint} />}
    </Map>
  );
};

export default withGoogleMapsState<Props>(LocationTabMap);
