import React, { useContext, useMemo } from 'react';
import * as R from 'ramda';

import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import ExpendituresChartContext from './ExpendituresChartContext';
import {
  BarIcon,
  getMonthOnly,
  GroupKeys,
  LineIcon,
  RechartPayload,
} from './expendituresChartUtils';

const styles = {
  tooltipContainer: {
    border: `2px solid ${colors.brand.blue}`,
    borderRadius: '0.25rem',
    background: colors.neutral.white,
    padding: '1rem',
  },
  tooltipTitle: {
    fontWeight: 500,
    fontSize: fonts.lg,
  },
  tooltipSubtitle: {
    fontWeight: 500,
    margin: '0.5rem 0',
    fontSize: fonts.md,
  },
  tooltipItem: { display: 'flex', alignItems: 'center', gap: '0.25rem' },
};

interface Props {
  active?: boolean;
  payload?: RechartPayload[];
  label?: string;
}

const ExpendituresChartTooltip = ({ active, payload, label = '' }: Props) => {
  const {
    hiddenGroup,
    focusDataKey,
    actualFields,
    remainingFields,
  } = useContext(ExpendituresChartContext);

  const tooltipHeading = useMemo(() => {
    return payload?.length > 2 ? getMonthOnly(label) : label;
  }, [label]);

  if (!active || isNilOrEmpty(payload)) {
    return null;
  }

  const fieldsMap = R.groupBy(R.prop('dataKey'), payload);
  const getField = (field, val) => R.pathOr('', [field, 0, val], fieldsMap);

  const hideRemainingHeader =
    hiddenGroup === GroupKeys.REMAINING ||
    (!isNilOrEmpty(focusDataKey) &&
      remainingFields.every(field => field !== focusDataKey));

  const hideActualHeader =
    hiddenGroup === GroupKeys.ACTUAL ||
    (!isNilOrEmpty(focusDataKey) &&
      actualFields.every(field => field !== focusDataKey));

  return (
    <div style={styles.tooltipContainer}>
      <div style={styles.tooltipTitle}>{tooltipHeading}</div>
      {!hideRemainingHeader && (
        <div style={styles.tooltipSubtitle}>{GroupKeys.REMAINING}</div>
      )}
      {hiddenGroup !== GroupKeys.REMAINING &&
        remainingFields
          .filter(fieldKey =>
            isNilOrEmpty(focusDataKey) ? true : fieldKey === focusDataKey,
          )
          .map(fieldName => (
            <div key={fieldName} style={styles.tooltipItem}>
              {LineIcon(getField(fieldName, 'stroke'))}
              {fieldName.split(GroupKeys.REMAINING)[0]}:
              <span>{numberToLocaleString(getField(fieldName, 'value'))}</span>
            </div>
          ))}
      {!hideActualHeader && (
        <div style={styles.tooltipSubtitle}>{GroupKeys.ACTUAL}</div>
      )}
      {hiddenGroup !== GroupKeys.ACTUAL &&
        actualFields
          .filter(fieldKey =>
            isNilOrEmpty(focusDataKey) ? true : fieldKey === focusDataKey,
          )
          .map(fieldName => (
            <div key={fieldName} style={styles.tooltipItem}>
              {BarIcon(getField(fieldName, 'fill'))}
              {fieldName.split(GroupKeys.ACTUAL)[0]}:
              <span>{numberToLocaleString(getField(fieldName, 'value'))}</span>
            </div>
          ))}
    </div>
  );
};

export default ExpendituresChartTooltip;
