import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { getYear } from 'date-fns';
import * as R from 'ramda';

import { GET_PAY_PERIODS } from '@atom/graph/payPeriod';
import { TASK_USERS_STATUS_UPDATE } from '@atom/graph/task';
import {
  GET_TIME_ENTRIES_STATUS,
  TIME_ENTRY_CREATE,
  TIME_ENTRY_UPDATE,
} from '@atom/graph/timeEntry';
import { usePreferences } from '@atom/hooks/usePreferences';
import {
  DatePicker,
  Icon,
  IconButton,
  Progress,
  Select,
  TextField,
} from '@atom/mui';
import { canUserCheckInToTask } from '@atom/selectors/taskSelectors';
import { userBudgetsSelector } from '@atom/selectors/userSelectors';
import { PayPeriods, PayPeriodsInput } from '@atom/types/payPeriod';
import { TimeEntryType } from '@atom/types/preferences';
import {
  Task,
  TaskUsersStatusUpdateInput,
  TaskUserStatus,
} from '@atom/types/task';
import {
  TimeEntriesConnectionInput,
  TimeEntriesStatusConnection,
  TimeEntryCreateInput,
  TimeEntryStatus,
  TimeEntryUpdate,
  WorkOrderTimeEntry,
} from '@atom/types/timeEntry';
import { UserDetail } from '@atom/types/user';
import { WorkOrderDetailType } from '@atom/types/work';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import {
  convertDateToMillisGMTMidday,
  convertHoursToMillis,
  convertMillisToExactHours,
  getCurrentPayPeriod,
  roundTimeEntryDuration,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './userTimeEntryModal.css';

const { MenuItem } = Select;

const styles = {
  progress: {
    marginLeft: '12px',
  },
  typeOption: {
    textTransform: 'capitalize',
  },
};

interface Props {
  timeEntry?: WorkOrderTimeEntry;
  user: UserDetail;
  createTimeEntry?: boolean;
  setEditTimeEntryId?: (id: string) => void;
  setCreateTimeEntry?: (createTimeEntry: boolean) => void;
  refetchTimeEntries: () => void;
  workOrderDetail: WorkOrderDetailType;
  task: Task;
}

const UserTimeEntryRow = ({
  timeEntry,
  user,
  createTimeEntry,
  setEditTimeEntryId = () => {},
  setCreateTimeEntry = () => {},
  refetchTimeEntries,
  workOrderDetail,
  task,
}: Props) => {
  const preferences = usePreferences();

  const [date, setDate] = useState<Date>(
    timeEntry ? new Date(timeEntry.date) : new Date(),
  );
  const [duration, setDuration] = useState<number>(
    timeEntry ? convertMillisToExactHours(timeEntry.duration) : 0,
  );
  const [selectedUserGroupId, setSelectedUserGroupId] = useState<string>(() => {
    if (timeEntry?.userGroupId) {
      return timeEntry?.userGroupId;
    }

    return R.pathOr([], ['userGroups'], user).length === 1
      ? user.userGroups[0].id
      : '';
  });
  const [selectedBudgetId, setSelectedBudgetId] = useState<string>(
    timeEntry ? timeEntry?.budget?.id : '',
  );
  const [selectedType, setSelectedType] = useState<TimeEntryType>(
    timeEntry ? timeEntry?.type : null,
  );
  const [payPeriods, setPayPeriods] = useState<PayPeriods>();

  const [
    getTimeEntriesStatus,
    { loading: timeEntriesStatusLoading, data: timeEntriesStatusData },
  ] = useLazyQuery<
    { timeEntries: TimeEntriesStatusConnection },
    { input: TimeEntriesConnectionInput }
  >(GET_TIME_ENTRIES_STATUS, {
    fetchPolicy: 'network-only',
  });

  const [getPayPeriods, { loading: payPeriodsLoading }] = useLazyQuery<
    { payPeriods: PayPeriods },
    { input: PayPeriodsInput }
  >(GET_PAY_PERIODS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setPayPeriods(data?.payPeriods);
    },
  });

  const [timeEntryCreate, { loading: createLoading }] = useMutation<
    { timeEntryCreate: WorkOrderTimeEntry },
    { input: TimeEntryCreateInput }
  >(TIME_ENTRY_CREATE, {
    onCompleted: () => {
      refetchTimeEntries();
      setEditTimeEntryId(null);
      setCreateTimeEntry(false);
    },
  });

  const [timeEntryUpdate, { loading: editLoading }] = useMutation<
    { timeEntryUpdate: WorkOrderTimeEntry },
    { input: TimeEntryUpdate }
  >(TIME_ENTRY_UPDATE, {
    onCompleted: () => {
      refetchTimeEntries();
      setEditTimeEntryId(null);
      setCreateTimeEntry(false);
    },
  });

  const [updateUsersTaskStatus] = useMutation<
    { taskUsersStatusUpdate: boolean },
    { input: TaskUsersStatusUpdateInput }
  >(TASK_USERS_STATUS_UPDATE);

  const currentPayPeriod = useMemo(() => {
    return getCurrentPayPeriod(payPeriods, date);
  }, [date, payPeriods]);

  useEffect(() => {
    if (!isNilOrEmpty(date) && preferences?.timeTracking?.timesheet) {
      getPayPeriods({
        variables: {
          input: {
            year: getYear(date),
            currentDate: convertDateToMillisGMTMidday(new Date()),
          },
        },
      });
    }
  }, [date, preferences]);

  useEffect(() => {
    if (
      !isNilOrEmpty(user) &&
      !isNilOrEmpty(selectedUserGroupId) &&
      !isNilOrEmpty(currentPayPeriod)
    ) {
      getTimeEntriesStatus({
        variables: {
          input: {
            userId: user.id,
            userGroupId: selectedUserGroupId,
            dateStart: currentPayPeriod.startDate,
            dateEnd: currentPayPeriod.endDate,
          },
        },
      });
    }
  }, [selectedUserGroupId, date]);

  useEffect(() => {
    if (isNilOrEmpty(date)) {
      const initialUserGroup =
        R.pathOr([], ['userGroups'], user).length === 1
          ? user.userGroups[0].id
          : '';

      setSelectedUserGroupId(initialUserGroup);
    }
  }, [date]);

  const budgets = userBudgetsSelector(user);
  const userGroups = user?.userGroups;

  const handleDuration = (newDuration: number) => {
    if (newDuration >= 0) {
      setDuration(newDuration);
    }
  };

  const onDurationBlur = () => {
    const roundedValue = roundTimeEntryDuration(duration);

    setDuration(roundedValue);
  };

  const handleTimeEntryUpdate = async () => {
    await timeEntryUpdate({
      variables: {
        input: {
          id: timeEntry.id,
          date: convertDateToMillisGMTMidday(date),
          duration: convertHoursToMillis(duration),
          ...(preferences?.timeTracking?.timesheet && {
            userGroupId: selectedUserGroupId,
          }),
          ...(!preferences?.timeTracking?.computedBudgets && {
            budgetId: selectedBudgetId,
          }),
          ...(!isNilOrEmpty(preferences?.timeTracking?.typeEnumeration) && {
            type: selectedType,
          }),
        },
      },
    });
  };

  const handleTimeEntryCreate = async () => {
    await timeEntryCreate({
      variables: {
        input: {
          userId: user.id,
          workOrderId: workOrderDetail.id,
          taskId: task.id,
          date: convertDateToMillisGMTMidday(date),
          duration: convertHoursToMillis(duration),
          ...(preferences?.timeTracking?.timesheet && {
            userGroupId: selectedUserGroupId,
          }),
          ...(!preferences?.timeTracking?.computedBudgets &&
            !R.isEmpty(selectedBudgetId) && {
              budgetId: selectedBudgetId,
            }),
          ...(!isNilOrEmpty(preferences?.timeTracking?.typeEnumeration) && {
            type: selectedType,
          }),
        },
      },
    });

    if (canUserCheckInToTask(task, user.id)) {
      await updateUsersTaskStatus({
        variables: {
          input: {
            workOrderId: workOrderDetail.id,
            taskId: task.id,
            users: [
              {
                userId: user.id,
                status: TaskUserStatus.IN_PROGRESS,
              },
            ],
          },
        },
      });
    }
  };

  const handleCancel = () => {
    setEditTimeEntryId(null);
    setCreateTimeEntry(false);
  };

  const handleSave = async () => {
    if (createTimeEntry) {
      await handleTimeEntryCreate();
    } else {
      await handleTimeEntryUpdate();
    }
  };

  const budgetOptions = useMemo(() => {
    const options = budgets.filter(
      budget =>
        !budget.restored ||
        (budget.restored &&
          budget.reopenedWorkOrderIds.includes(workOrderDetail.id)),
    );

    return [{ id: '', name: '', rate: 0 }, ...options];
  }, [budgets, workOrderDetail]);
  const typeOptions = preferences?.timeTracking?.typeEnumeration;
  const hasTypeOptions = !isNilOrEmpty(typeOptions);

  const validationLoading = payPeriodsLoading || timeEntriesStatusLoading;
  const userGroupIsApproved =
    timeEntriesStatusData?.timeEntries?.status === TimeEntryStatus.approved;

  const isSaveDisabled =
    isNilOrEmpty(date) ||
    isNilOrEmpty(duration) ||
    validationLoading ||
    createLoading ||
    editLoading ||
    userGroupIsApproved ||
    (preferences?.timeTracking?.timesheet &&
      isNilOrEmpty(selectedUserGroupId)) ||
    (hasTypeOptions && isNilOrEmpty(selectedType));

  const hoursLabel = duration === 1 ? 'Hour' : 'Hours';

  return (
    <div styleName="time-entry-row">
      <div styleName="time-entry-data-section">
        <div styleName="input-container">
          <DatePicker
            label="Start Date"
            value={date}
            onChange={setDate}
            format="MM/dd/yyyy"
            data-cy="timeEntryDateInput"
          />
        </div>
        {preferences?.timeTracking?.timesheet && (
          <div styleName="input-container">
            <Select
              fullWidth
              label="Group"
              value={selectedUserGroupId || ''}
              onChange={event => setSelectedUserGroupId(event.target.value)}
              disabled={isNilOrEmpty(date)}
              error={userGroupIsApproved}
              helperText={
                userGroupIsApproved &&
                'Cannot add time to an approved timesheet.'
              }
              data-cy="timeEntryGroupInput"
            >
              {userGroups.map(userGroup => {
                const primaryText = userGroup.groupPath.join(' / ');

                return (
                  <MenuItem key={userGroup.id} value={userGroup.id}>
                    {primaryText}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        )}
        <div styleName="input-container work-time">
          <TextField
            type="number"
            value={duration}
            label="Work Time"
            onChange={event => handleDuration(event.target.value)}
            onBlur={onDurationBlur}
            data-cy="timeEntryTimeInput"
          />
          <div styleName="hours-suffix">{hoursLabel}</div>
        </div>
        {preferences?.timeTracking?.computedBudgets ? (
          hasTypeOptions && (
            <div styleName="input-container">
              <Select
                fullWidth
                label="Wage Type"
                value={selectedType || ''}
                onChange={event => setSelectedType(event.target.value)}
                data-cy="timeEntryWageInput"
              >
                {typeOptions.map(type => {
                  const primaryText = type.name;

                  return (
                    <MenuItem
                      key={type.value}
                      value={type.value}
                      style={styles.typeOption}
                    >
                      {primaryText}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          )
        ) : (
          <div styleName="input-container">
            <Select
              fullWidth
              label="Budget"
              value={selectedBudgetId || ''}
              onChange={event => setSelectedBudgetId(event.target.value)}
              data-cy="timeEntryBudgetInput"
            >
              {budgetOptions.map(option => {
                const primaryText = option.id ? (
                  <>
                    <span>
                      {`${option.name}: ${numberToLocaleString(
                        option.rate,
                      )}/Hours `}
                    </span>
                    {option.restored && (
                      <span styleName="snapshot-suffix">(snapshot)</span>
                    )}
                  </>
                ) : (
                  'No Budget'
                );

                return (
                  <MenuItem key={option.id} value={option.id}>
                    {primaryText}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        )}
      </div>
      <div styleName="time-entry-button-section">
        <IconButton tooltip="Cancel" onClick={handleCancel}>
          <Icon>close</Icon>
        </IconButton>
        {validationLoading ? (
          <Progress size={20} style={styles.progress} />
        ) : (
          <IconButton
            tooltip="Save"
            onClick={handleSave}
            disabled={isSaveDisabled}
          >
            <Icon>done</Icon>
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default UserTimeEntryRow;
