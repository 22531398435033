import * as React from 'react';

import SectionHeading from '@atom/components/common/map/mapDrawerContent/SectionHeading';
import { UserDetail } from '@atom/types/user';

import UserRow from './UserRow';

import './workSummaryTray.css';

const getTeamSectionHeading = length => {
  if (!length) {
    return '0 team members';
  }

  return length === 1 ? '1 team member' : `${length} team members`;
};

interface Props {
  users: UserDetail[];
}

const WorkSummaryTeam = ({ users }: Props) => {
  const sectionHeading = getTeamSectionHeading(users.length);

  return (
    <div styleName="user-container">
      <SectionHeading title={sectionHeading} />
      <div styleName="user-detail-section">
        {users.map(user => (
          <UserRow key={user.id} user={user} />
        ))}
      </div>
    </div>
  );
};

export default WorkSummaryTeam;
