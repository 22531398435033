import React, { useEffect, useState } from 'react';
import { match as Match } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import naturalSort from 'natural-sort';
import * as R from 'ramda';

import { GET_WORK_ORDER } from '@atom/graph/work';
import { Progress } from '@atom/mui';
import { MediaItem } from '@atom/types/media';
import { ReportTypes } from '@atom/types/preferences';
import { WorkOrderAssetTreeType, WorkOrderDetailType } from '@atom/types/work';
import api from '@atom/utilities/api';
import {
  MEDIA_ENDPOINT,
  WORK_ORDERS_ENDPOINT,
} from '@atom/utilities/endpoints';
import {
  getHydratedUserDisplayName,
  rollUpHydratedTaskUserGroups,
  rollUpHydratedTaskUsers,
} from '@atom/utilities/userUtilities';
import {
  getAllWorkAssetTreesByIds,
  getWorkAssetIdsWithoutWorkAncestors,
  getWorkAssetTreeFullPathName,
} from '@atom/utilities/workOrderAssetTreeUtilities';

import AttributesTextbox from '../AttributesTextbox';
import MainPhotoTile from '../MainPhotoTile';

import AssessmentTable from './AssessmentTable';
import AssetAssessment from './AssetAssessment';
import CustomTextbox from './CustomTextbox';
import ReportTask from './ReportTask';

import '../workOrderDetailPdfReport.css';

export interface Overview {
  region: string;
  railroad: string;
  subdivision: string;
  assetName: string;
  latitude: string;
  longitude: string;
}

export interface Inspection {
  workName: string;
  dueDate: string;
  completedDate: string;
  completedBy: string;
  closedDate: string;
  closedBy: string;
  leadInspector: string;
  otherPersonnel: string;
}

interface Props {
  match: Match<{ workOrderId: string; reportType: string }>;
}

const GwrrWorkOrderDetailPdfReport = ({ match }: Props) => {
  const [workOrder, setWorkOrder] = useState<WorkOrderDetailType>(
    {} as WorkOrderDetailType,
  );
  const [overview, setOverview] = useState<Overview>({
    region: '',
    railroad: '',
    subdivision: '',
    assetName: '',
    latitude: '',
    longitude: '',
  });
  const [inspection, setInspection] = useState<Inspection>({
    workName: '',
    dueDate: '',
    completedDate: '',
    completedBy: '',
    closedDate: '',
    closedBy: '',
    leadInspector: '',
    otherPersonnel: '',
  });
  const [inventoryAssetId, setInventoryAssetId] = useState<string>('');
  const [tree, setTree] = useState<WorkOrderAssetTreeType>(
    {} as WorkOrderAssetTreeType,
  );
  const [photo, setPhoto] = useState<MediaItem>({} as MediaItem);
  const [mainPhotoLoading, setMainPhotoLoading] = useState<boolean>(true);
  const [otherPhotosLoading, setOtherPhotosLoading] = useState<{
    [id: string]: boolean;
  }>({
    attributeGroupTable: true,
    attributeGroupAssessment: true,
    assessmentTable: true,
  });

  const onCompleted = data => {
    const {
      name,
      inventoryAssetName,
      location,
      assets,
      dueDate,
      closedByUser,
      closedDate,
      completedByUser,
      completionDate,
      leadAssigneeUser,
      tasks,
    } = data.workOrder;

    setWorkOrder(data.workOrder);
    setInventoryAssetId(data.workOrder.inventoryAssetId);

    const mainAssetCategoryPath =
      assets[Object.keys(assets)[0]]?.categoryPath || [];

    const updatedOverview = {
      assetName: inventoryAssetName,
      longitude: location?.coordinates[0],
      latitude: location?.coordinates[1],
      region: mainAssetCategoryPath[3]?.name,
      division: mainAssetCategoryPath[4]?.name,
      railroad: mainAssetCategoryPath[5]?.name,
      subdivision: mainAssetCategoryPath[6]?.name,
    };

    setOverview(updatedOverview);

    const hydratedUsers = rollUpHydratedTaskUsers(tasks);
    const hydratedUserGroups = rollUpHydratedTaskUserGroups(tasks);
    const hydratedUserList = [...hydratedUserGroups, ...hydratedUsers];
    const displayList = hydratedUserList.map(
      list => list.name || getHydratedUserDisplayName(list),
    );

    const completedBy = getHydratedUserDisplayName(completedByUser);
    const closedBy = getHydratedUserDisplayName(closedByUser);
    const leadInspector = getHydratedUserDisplayName(leadAssigneeUser);

    const updatedInspection = {
      workName: name,
      dueDate,
      completedDate: completionDate,
      completedBy,
      closedDate,
      closedBy,
      leadInspector,
      otherPersonnel: displayList.join(', '),
    };
    setInspection(updatedInspection);
  };

  const { loading } = useQuery(GET_WORK_ORDER, {
    variables: {
      id: match.params.workOrderId,
    },
    onCompleted,
  });

  useEffect(() => {
    if (inventoryAssetId) {
      const getWorkOrderAssetTree = async () => {
        const endpoint = `${WORK_ORDERS_ENDPOINT}/${match.params.workOrderId}/assets/${inventoryAssetId}/tree`;
        const { data } = await api.get(endpoint);
        setTree(data);
      };
      getWorkOrderAssetTree();
    }
  }, [inventoryAssetId]);

  useEffect(() => {
    if (workOrder.mainPhotoId) {
      const getPhoto = async () => {
        const endpoint = `${MEDIA_ENDPOINT}/${workOrder.mainPhotoId}`;
        const response = await api.get(endpoint);
        setPhoto(response.data);
      };
      getPhoto();
    } else {
      setMainPhotoLoading(false);
    }
  }, [workOrder.mainPhotoId]);

  const getWorkOrderAssetTreesPerTask = (assetIds: string[]) => {
    const filteredAssetsPerTask = Object.keys(workOrder.assets)
      .filter(key => assetIds.includes(key))
      .reduce((obj, key) => {
        obj[key] = workOrder.assets[key];
        return obj;
      }, {});

    return getAllWorkAssetTreesByIds(
      tree,
      getWorkAssetIdsWithoutWorkAncestors(filteredAssetsPerTask),
    );
  };

  const getFullPathName = (id: string) => {
    const name = getWorkAssetTreeFullPathName(tree, id).split(' ');
    const fullPathName = `${name[0].toUpperCase()} ${name
      .slice(1, name.length)
      .join(' ')}`;

    return <div styleName="task-group-header">{fullPathName}</div>;
  };

  const getAssessmentReportElements = workAssetTree => {
    const elements = [];
    workAssetTree.elementGroups.forEach(group =>
      elements.push(...group.elements),
    );

    const sortedElements = elements.sort((first, second) => {
      return naturalSort({ direction: 'asc' })(first.name, second.name);
    });

    return sortedElements;
  };

  const customV1PhotosLoaded =
    !mainPhotoLoading && !otherPhotosLoading?.assessmentTable;

  const customV3PhotosLoaded =
    !mainPhotoLoading &&
    !otherPhotosLoading?.attributeGroupTable &&
    !otherPhotosLoading?.attributeGroupAssessment;

  // Used to block the PDF service from printing until all necessary photos are loaded
  const photosLoaded =
    match.params.reportType === ReportTypes.CUSTOM_V1
      ? customV1PhotosLoaded
      : customV3PhotosLoaded;

  return (
    <div styleName="background">
      {loading || R.isEmpty(workOrder) || R.isEmpty(tree) ? (
        <Progress />
      ) : (
        <div styleName="main">
          <div styleName="details">
            <div styleName="col-1">
              <CustomTextbox
                header="Overview"
                data={overview}
                labelWidth={{ minWidth: '5rem' }}
              />
              <AttributesTextbox
                header="Details"
                workOrderAssetTree={tree}
                labelWidth={{ minWidth: '7.8rem' }}
              />
              <AttributesTextbox
                header="Inventory Information"
                workOrderAssetTree={tree}
                labelWidth={{ minWidth: '7.8rem' }}
              />
            </div>

            <div styleName="col-2">
              {workOrder.mainPhotoId ? (
                <MainPhotoTile
                  image={photo}
                  imageWidth="29rem"
                  imageHeight="20rem"
                  loading={setMainPhotoLoading}
                />
              ) : (
                <div styleName="empty-photo-section gwrr" />
              )}
              <AttributesTextbox
                header="Location"
                workOrderAssetTree={tree}
                labelWidth={{ minWidth: '5.625rem' }}
              />
              <CustomTextbox
                header="Inspection"
                data={inspection}
                labelWidth={{ minWidth: '5.625rem' }}
              />
            </div>
          </div>
          <div style={{ pageBreakAfter: 'always' }} />

          {/* ASSESSMENT REPORT */}
          {match.params.reportType === ReportTypes.CUSTOM_V1 && (
            <>
              <div styleName="group-container">
                <AttributesTextbox
                  header="Summary"
                  workOrderAssetTree={tree}
                  labelWidth={{ minWidth: '5.625rem' }}
                />
                <AssetAssessment
                  workOrderAsset={tree}
                  workOrderId={workOrder.id}
                  setOtherPhotosLoading={setOtherPhotosLoading}
                />
              </div>
              <div styleName="group-container">
                {getAssessmentReportElements(tree).map(element => (
                  <div style={{ padding: '0.8rem 0' }}>
                    <AssessmentTable
                      key={element.id}
                      element={element}
                      workOrderId={workOrder.id}
                      setOtherPhotosLoading={setOtherPhotosLoading}
                    />
                  </div>
                ))}
              </div>
            </>
          )}

          {/* DETAILED REPORT */}
          {match.params.reportType === ReportTypes.CUSTOM_V3 && (
            <div>
              <div styleName="group-container">
                {workOrder.tasks.map((task, taskIndex) => {
                  const workOrderAssetTreesPerTask = getWorkOrderAssetTreesPerTask(
                    task.assetIds,
                  );

                  const taskTitle = `Task ${
                    R.length(workOrder?.tasks) - taskIndex
                  }: ${task.name}`;

                  return (
                    <ReportTask
                      workOrderAssetTreesPerTask={workOrderAssetTreesPerTask}
                      taskIndex={taskIndex}
                      taskTitle={taskTitle}
                      task={task}
                      workOrder={workOrder}
                      setOtherPhotosLoading={setOtherPhotosLoading}
                      getFullPathName={getFullPathName}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {photosLoaded && <div id="loaded" />}
        </div>
      )}
    </div>
  );
};

export default GwrrWorkOrderDetailPdfReport;
