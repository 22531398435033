import * as React from 'react';
import * as R from 'ramda';

import { Button, Collapse, Icon, IconButton, List, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import fontStyles from '@atom/styles/fonts';
import { EventType } from '@atom/types/event';
import iconUtilities from '@atom/utilities/iconUtilities';

const { ListItemButton, ListItemText } = List;

const styles = {
  rootListItemStyle: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: fontStyles.md,
    fontSize: fontStyles.md,
    paddingTop: '0.50rem',
    paddingRight: '1.0rem',
    paddingBottom: '0.50rem',
    paddingLeft: '4.25rem',
  },
  listItemStyle: {
    lineHeight: fontStyles.md,
    fontSize: fontStyles.md,
    paddingTop: '0.50rem',
    paddingRight: '1.0rem',
    paddingBottom: '0.50rem',
    paddingLeft: '4.25rem',
    display: 'flex',
    alignItems: 'center',
    height: '1rem',
  },
  nestedListStyle: {
    padding: '0px',
    display: 'inline-block',
    minWidth: '100%',
  },
  folderNameContainer: {
    padding: '0',
  },
  assetLabel: {
    padding: '0',
    margin: '0',
    marginBottom: '2px',
  },
  assetType: {
    padding: '0',
    margin: '0',
    marginTop: '2px',
    color: colors.neutral.gray,
    fontSize: fontStyles.sm,
  },
  selectedLeafNode: {
    boxSizing: 'border-box',
    backgroundColor: colors.utility.highlight,
    borderLeft: `3px solid ${colors.brand.blue}`,
    height: '2.5rem',
    paddingTop: '0.25rem',
    paddingRight: '0.5rem',
    lineHeight: 0,
  },
  folderNode: {
    boxSizing: 'border-box',
    borderLeft: '3px solid transparent',
    height: '2.5rem',
    paddingTop: '0.25rem',
    paddingRight: '0.5rem',
    lineHeight: 0,
  },
  categoryFolderEditContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  button: {
    marginLeft: '0.5rem',
  },
  rowContentContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  iconStyles: {
    padding: '0.25rem',
  },
};

const initialState = {
  selectedParentCategoryId: '',
  selectedEditParentCategoryId: '',
  folderName: '',
  childPath: [],
  hoveredListItem: null,
};

interface Props {
  inventoryFilterCategoryTree: any[];
  onToggle: (child: any, childPath: any[], selectedSchemaId?: string) => void;
  createCategory: (
    selectedEditParentCategoryId: string,
    folderName: string,
    childPath: any[],
  ) => void;
  renameCategory: (
    selectedEditParentCategoryId: string,
    folderName: string,
    childPath: any[],
  ) => void;
  selectedCategoryId?: string;
  enabledSchemaId?: string;
  disabledCategoryIds?: string[];
}

interface State {
  selectedParentCategoryId: string;
  selectedEditParentCategoryId: string;
  folderName: string;
  childPath: any[];
  hoveredListItem: any;
}

class CategorySelectionList extends React.Component<Props, State> {
  state = initialState;

  getListItemRootStyle = (
    listItemId: string,
    selected: string,
    hasCategories: boolean,
  ) => {
    if (selected === listItemId && !hasCategories) {
      return styles.selectedLeafNode;
    }
    return styles.folderNode;
  };

  handleCancel = () => {
    this.setState({ ...initialState });
  };

  onChange = (event: EventType) => {
    const { name, value } = event.target;

    // @ts-ignore
    this.setState({ [name]: value });
  };

  buildEditField = (onSave: () => void) => {
    const { folderName } = this.state;
    return (
      <div style={styles.categoryFolderEditContainer}>
        <TextField
          value={folderName}
          onChange={this.onChange}
          name="folderName"
          variant="standard"
        />
        <Button onClick={this.handleCancel} style={styles.button}>
          CANCEL
        </Button>
        <Button onClick={onSave} style={styles.button} color="primary">
          SAVE
        </Button>
      </div>
    );
  };

  buildEditableFolder = () => {
    const editField = this.buildEditField(this.saveCategoryCreate);
    return {
      name: editField,
      id: null,
      hasCategories: false,
    };
  };

  createNewCategory = (
    child: any,
    childPath: any[],
    selectedSchemaId?: string,
  ) => {
    const { onToggle } = this.props;
    this.setState({
      selectedParentCategoryId: child.id,
      childPath,
    });
    onToggle(child, childPath, selectedSchemaId);
  };

  editCategory = (child: any, childPath: any[]) => {
    this.setState({
      selectedEditParentCategoryId: child.id,
      folderName: child.name,
      childPath,
    });
  };

  saveCategoryRename = () => {
    const { selectedEditParentCategoryId, folderName, childPath } = this.state;
    const { renameCategory } = this.props;
    renameCategory(selectedEditParentCategoryId, folderName, childPath);
    this.setState({ ...initialState });
  };

  saveCategoryCreate = () => {
    const { selectedParentCategoryId, folderName, childPath } = this.state;
    const { createCategory } = this.props;
    createCategory(selectedParentCategoryId, folderName, childPath);
    this.setState({ ...initialState });
  };

  handleHover = (id: any) => {
    this.setState({ hoveredListItem: id });
  };

  addEditableFolderToChildren = (children: any[]) => {
    const { selectedParentCategoryId } = this.state;
    const editableFolder = this.buildEditableFolder();

    return children.map((child: any): any => {
      return child.id === selectedParentCategoryId
        ? {
            ...child,
            hasCategories: true,
            expanded: true,
            subCategories: [
              editableFolder,
              ...R.pathOr([], ['subCategories'], child),
            ],
          }
        : child;
    });
  };

  renderSideNode(
    children: any,
    onToggle: (child: any, childPath: any[], selectedSchemaId: string) => void,
    categoryPath: any[],
    selected: string,
    level: number = 0,
  ) {
    if (!children) {
      return [];
    }

    const { enabledSchemaId, disabledCategoryIds = [] } = this.props;
    const { hoveredListItem, selectedEditParentCategoryId } = this.state;
    const hydratedChildren = this.addEditableFolderToChildren(children);

    return [...hydratedChildren].map((child, pathIndex) => {
      const {
        name,
        id,
        hasCategories,
        subCategories,
        expanded,
        schemaId,
      } = child;

      const colorId = R.pathOr('DEFAULT', ['colorId'], child);
      const assetType = R.pathOr('', ['assetType'], child);
      const selectedSchemaId = R.pathOr(null, ['schemaId'], child);
      const childPath = [...categoryPath, 'subCategories', pathIndex];
      const rootStyles = this.getListItemRootStyle(id, selected, hasCategories);

      const disabled =
        disabledCategoryIds.includes(id) ||
        (!R.isNil(enabledSchemaId) &&
          !hasCategories &&
          schemaId !== enabledSchemaId);

      const iconStyle = {
        opacity: disabled ? '50%' : '100%',
      };

      const leftIcon = !hasCategories
        ? iconUtilities.assetFolderIcon(colorId, {
            ...iconStyle,
            marginLeft: '24px',
            marginRight: '10px',
            bottom: '0px',
          })
        : expanded
        ? iconUtilities.leftFolderIcon('down-arrow', iconStyle)
        : iconUtilities.leftFolderIcon('right-arrow', iconStyle);

      const rightIconButtonStyles = {
        display: hoveredListItem === id ? 'inline-flex' : 'none',
      };

      const rightIconButtons = (
        <div style={rightIconButtonStyles}>
          {R.isNil(selectedSchemaId) && (
            <IconButton
              style={styles.iconStyles}
              onClick={() =>
                this.createNewCategory(child, childPath, selectedSchemaId)
              }
            >
              <Icon color={colors.neutral.gray}>create_new_folder</Icon>
            </IconButton>
          )}
          <IconButton
            style={styles.iconStyles}
            onClick={() => this.editCategory(child, childPath)}
          >
            <Icon color={colors.neutral.gray}>edit</Icon>
          </IconButton>
        </div>
      );

      const isEdit = selectedEditParentCategoryId === child.id;

      const assetName = isEdit
        ? this.buildEditField(this.saveCategoryRename)
        : name;

      const assetTypeText =
        !R.isNil(selectedSchemaId) && !isEdit ? (
          <div style={styles.folderNameContainer}>
            <p style={styles.assetLabel}>{name}</p>
            <p style={styles.assetType}>{assetType}</p>
          </div>
        ) : (
          <div>{assetName}</div>
        );

      const rowContent = (
        <div
          style={{
            ...styles.rowContentContainer,
            ...(disabled && {
              color: colors.neutral.gray,
            }),
          }}
        >
          {assetTypeText}
          {rightIconButtons}
        </div>
      );

      return (
        <>
          <ListItemButton
            disableGutters
            key={id}
            id={id}
            style={{ ...rootStyles, paddingLeft: `${level * 1}rem` }}
            onMouseEnter={() => this.handleHover(id)}
            onClick={() =>
              !isEdit && !disabled
                ? onToggle(child, childPath, selectedSchemaId)
                : {}
            }
          >
            {leftIcon}
            <ListItemText
              primaryTextStyle={{ fontWeight: 0 }}
              primary={rowContent}
            />
          </ListItemButton>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List disablePadding>
              {!R.isEmpty(subCategories)
                ? this.renderSideNode(
                    subCategories,
                    onToggle,
                    childPath,
                    selected,
                    level + 1,
                  )
                : []}
            </List>
          </Collapse>
        </>
      );
    });
  }

  render() {
    const {
      inventoryFilterCategoryTree,
      selectedCategoryId,
      onToggle,
    } = this.props;
    const { selectedParentCategoryId, hoveredListItem } = this.state;

    return (
      <List>
        {inventoryFilterCategoryTree.map((category, categoryIndex) => {
          const editableFolder = this.buildEditableFolder();

          const hydratedCategory =
            category.id === selectedParentCategoryId
              ? {
                  ...category,
                  hasCategories: true,
                  expanded: true,
                  subCategories: [
                    editableFolder,
                    ...R.pathOr([], ['subCategories'], category),
                  ],
                }
              : category;

          const {
            subCategories,
            expanded,
            id,
            name,
            hasCategories,
          } = hydratedCategory;

          const icon = expanded
            ? iconUtilities.leftFolderIcon('down-arrow')
            : iconUtilities.leftFolderIcon('right-arrow');
          const rootStyles = this.getListItemRootStyle(
            id,
            selectedCategoryId,
            hasCategories,
          );
          const categoryPath = [categoryIndex];

          const rightIconButtonStyles =
            hoveredListItem === id
              ? {
                  display: 'inline-flex',
                }
              : {
                  display: 'none',
                };

          const rightIconButton = (
            <IconButton
              style={rightIconButtonStyles}
              onClick={() => this.createNewCategory(category, categoryPath)}
            >
              <Icon color={colors.neutral.gray}>create_new_folder</Icon>
            </IconButton>
          );

          return (
            <React.Fragment>
              <ListItemButton
                key={id}
                id={id}
                style={rootStyles}
                onMouseEnter={() => this.handleHover(id)}
                onClick={() => onToggle(category, categoryPath)}
                disableGutters
              >
                {icon}
                <ListItemText
                  primaryTextStyle={{ fontWeight: 0 }}
                  primary={name}
                />
                {rightIconButton}
              </ListItemButton>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <List disablePadding>
                  {this.renderSideNode(
                    subCategories,
                    onToggle,
                    categoryPath,
                    selectedCategoryId,
                    1,
                  )}
                </List>
              </Collapse>
            </React.Fragment>
          );
        })}
      </List>
    );
  }
}

export default CategorySelectionList;
