import { all, fork } from 'redux-saga/effects';

import applicationSearch from './applicationSearchSagas';
import auth from './authSagas';
import file from './fileSagas';
import formInstance from './formInstanceSagas';
import formTemplate from './formTemplateSagas';
import inventoryAssets from './inventoryAssetSagas';
import inventoryCategories from './inventoryCategoriesSagas';
import inventoryExport from './inventoryExportSagas';
import inventorySchema from './inventorySchemaSagas';
import media from './mediaSagas';
import poolAttributes from './poolAttributeSagas';
import tasks from './taskSagas';
import tenant from './tenantSagas';
import users from './userSagas';
import workOrderAsset from './workOrderAssetSagas';
import workOrder from './workOrderSagas';
import workTemplate from './workTemplateSagas';

const sagas = [
  ...auth,
  ...tenant,
  ...applicationSearch,
  ...inventorySchema,
  ...poolAttributes,
  ...inventoryCategories,
  ...inventoryAssets,
  ...file,
  ...media,
  ...workOrder,
  ...workOrderAsset,
  ...tasks,
  ...formInstance,
  ...formTemplate,
  ...users,
  ...workTemplate,
  ...inventoryExport,
];

export default function* root() {
  yield all(sagas.map(saga => fork(saga)));
}
