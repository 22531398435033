import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import { requestSchemaTree } from '@atom/actions/inventorySchemaActions';
import { Modal, Select } from '@atom/mui';
import {
  formTemplateContainsAnyModule,
  formTemplateContainsModule,
} from '@atom/selectors/formModuleSelectors';
import {
  getSelectedSchemaIds,
  updateFormAsset,
} from '@atom/selectors/formSelectors';
import { AssetSchemaType, FormFieldType } from '@atom/types/form';
import {
  InventorySchemaItem,
  InventorySchemaTreeType,
} from '@atom/types/inventory';
import schemaUtilities from '@atom/utilities/schemaUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import FormBuilderContext from './FormBuilderContext';
import LinkInventoryTree from './LinkInventoryTree';

import './formBuilder.css';

const { MenuItem } = Select;

const styles = {
  menu: {
    maxHeight: '30rem',
  },
};

const initialFormAsset = {
  type: 'asset',
  assetSchemas: [],
};

export interface Props {
  open: boolean;
  onClose: () => void;
  inventorySchemaTree: InventorySchemaTreeType;
  loading: boolean;
  onToggle: (data: any[], boolean: boolean) => void;
  addAssetField: (assetField: FormFieldType, schemaId: string) => void;
}

const LinkInventoryModal = ({
  open,
  onClose,
  inventorySchemaTree,
  loading,
  onToggle,
  addAssetField,
}: Props) => {
  const dispatch = useDispatch();
  const { formTemplate, selectedSchema, schemas = [] } = useContext(
    FormBuilderContext,
  );

  const [schemaId, setSchemaId] = useState<string>(selectedSchema);
  const [formAsset, setFormAsset] = useState<FormFieldType>(initialFormAsset);

  useEffect(() => {
    setSchemaId(selectedSchema);
  }, [selectedSchema]);

  useEffect(() => {
    if (
      open &&
      schemaId &&
      (R.isEmpty(inventorySchemaTree) || inventorySchemaTree.id !== schemaId)
    ) {
      dispatch(requestSchemaTree({ id: schemaId }));
    }
  }, [open, schemaId]);

  const handleSchemaChange = (id: string) => {
    setSchemaId(id);
    setFormAsset(initialFormAsset);
    dispatch(requestSchemaTree({ id }));
  };

  const closeModal = () => {
    onClose();
    setSchemaId(selectedSchema);
    setFormAsset(initialFormAsset);
  };

  const toggleCheckbox = (assetSchema: AssetSchemaType) => {
    const updatedFormAsset = updateFormAsset(formAsset, assetSchema);
    setFormAsset(updatedFormAsset);
  };

  const confirm = () => {
    addAssetField(formAsset, schemaId);
    closeModal();
  };

  const selectedSchemaIds = getSelectedSchemaIds(formTemplate);

  const rootSchema = schemas.find(
    (schema: InventorySchemaItem): boolean => schema.id === schemaId,
  );

  const formHasLinkedAsset = formTemplateContainsModule('asset', formTemplate);
  const formHasAnyModule = formTemplateContainsAnyModule(formTemplate);

  const isSchemaSelectDisabled = formHasAnyModule || formHasLinkedAsset;
  const isSaveDisabled = isNilOrEmpty(formAsset.assetSchemas);

  return (
    <Modal
      title="Link Inventory"
      onConfirm={confirm}
      onCancel={closeModal}
      confirmButtonText="Save"
      disabled={isSaveDisabled}
      width="lg"
      open={open}
    >
      <div styleName="modal-content-container">
        <Select
          fullWidth
          label="Inventory Type"
          value={schemaId}
          onChange={event => handleSchemaChange(event.target.value)}
          disabled={isSchemaSelectDisabled}
          MenuProps={{ style: styles.menu }}
        >
          {schemas.map(schema => {
            return (
              <MenuItem key={schema.id} value={schema.id}>
                <div styleName="schema-option">
                  <img
                    src={schemaUtilities.getSchemaIconFromMarkerId(
                      schema.markerId,
                    )}
                  />
                  <span>{schema.name}</span>
                </div>
              </MenuItem>
            );
          })}
        </Select>
        <div styleName="schema-sub-text">
          Selecting an inventory type will show its element types.
        </div>
        {!R.isNil(rootSchema) && (
          <LinkInventoryTree
            selectedSchemaIds={selectedSchemaIds}
            loading={loading}
            inventorySchemaTree={inventorySchemaTree}
            // @ts-ignore
            rootSchema={rootSchema}
            onToggle={onToggle}
            toggleCheckbox={toggleCheckbox}
            formAsset={formAsset}
          />
        )}
      </div>
    </Modal>
  );
};

export default LinkInventoryModal;
