import React, { useState } from 'react';
import * as R from 'ramda';

import UserGroupIcon from '@atom/components/common/UserGroupIcon';
import { Checkbox, Collapse, Icon, List } from '@atom/mui';
import { UserGroupTreeType } from '@atom/types/userGroups';
import { toggleFromSet } from '@atom/utilities/setUtilities';

const { ListItemButton, ListItemText } = List;

interface Props {
  userGroupTree: UserGroupTreeType;
  selected: Set<string>;
  disabled: Set<string>;
  onToggle: (id: string) => void;
}

const getListItemStyle = (hasChildren: boolean, level: number) =>
  hasChildren
    ? { paddingLeft: `${level}rem`, paddingRight: '1rem' }
    : { paddingLeft: `${level + 2}rem`, paddingRight: '1rem' };

const styles = {
  toggle: {
    cursor: 'pointer',
    marginRight: '0.5rem',
  },
};

const UserGroupTree = ({
  userGroupTree,
  selected,
  disabled,
  onToggle,
}: Props) => {
  const [open, setOpen] = useState<Set<string>>(new Set(['root']));

  const renderList = (userGroups: UserGroupTreeType[], level: number) => {
    return userGroups.map(userGroup => {
      const hasChildren = !!userGroup.userGroups?.length;

      return (
        <React.Fragment key={userGroup.id}>
          <ListItemButton
            disableGutters
            style={getListItemStyle(hasChildren, level)}
            onClick={() => setOpen(toggleFromSet(open, userGroup.id))}
          >
            {hasChildren && (
              <Icon style={styles.toggle}>
                {open.has(userGroup.id) ? 'arrow_drop_down' : 'arrow_right'}
              </Icon>
            )}
            <Checkbox
              checked={selected.has(userGroup.id)}
              onClick={event => {
                event.stopPropagation();
                onToggle(userGroup.id);
              }}
              disabled={disabled.has(userGroup.id)}
            />
            <div style={{ marginRight: '1rem' }}>
              <UserGroupIcon colorId={userGroup.colorId} />
            </div>
            <ListItemText primary={userGroup.name} />
          </ListItemButton>
          {hasChildren && (
            <Collapse in={open.has(userGroup.id)} timeout="auto" unmountOnExit>
              <>{renderList(userGroup.userGroups, R.inc(level))}</>
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };

  return <>{renderList([userGroupTree], 0)}</>;
};

export default UserGroupTree;
