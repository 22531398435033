import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
import InheritanceLockIcon from '@atom/components/workTemplate/InheritanceLockIcon';
import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_TASK_MATERIAL_DELETE } from '@atom/graph/workTemplate';
import { Icon, IconButton, Snackbar, Tooltip } from '@atom/mui';
import { TaskMaterialItem } from '@atom/types/task';
import {
  InheritedComponentType,
  WorkOrderTemplateMaterialDeleteInput,
} from '@atom/types/workTemplate';
import schemaUtilities from '@atom/utilities/schemaUtilities';
import { isComponentInherited } from '@atom/utilities/workTemplateUtilities';

import './materialSection.css';

interface Props {
  material: TaskMaterialItem;
}

const MaterialTile = ({ material }: Props) => {
  const { workTemplate, task, refetch } = useContext(WorkTemplateContext);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [removeMaterial] = useMutation<
    void,
    { input: WorkOrderTemplateMaterialDeleteInput }
  >(WORK_TEMPLATE_TASK_MATERIAL_DELETE, {
    onCompleted: res => refetch(),
  });

  const iconStyle = {
    color: schemaUtilities.getSchemaColorFromColorId(material.colorId),
  };

  const isDisabled = workTemplate?.published;

  const handleRemoveMaterial = async () => {
    setDeleteModalOpen(false);

    Snackbar.info({
      message: 'Deleting...',
    });

    await removeMaterial({
      variables: {
        input: {
          workTemplateId: workTemplate.id,
          taskId: task.id,
          assetId: material.assetId,
        },
      },
    });

    Snackbar.info({
      message: `Removed Material.`,
    });

    refetch();
  };

  const isItemInherited = isComponentInherited(
    InheritedComponentType.TASK_MATERIAL,
    task?.inheritedComponents || [],
    material?.assetId,
  );

  const actionButton = isItemInherited ? (
    <div styleName="lock-container">
      <InheritanceLockIcon
        tooltip="Inherited Equipment and Material cannot be removed or edited."
        placement="bottom-start"
      />
    </div>
  ) : isDisabled ? (
    <div />
  ) : (
    <IconButton
      onClick={() => setDeleteModalOpen(true)}
      tooltip="Remove"
      edge="end"
    >
      <Icon>close</Icon>
    </IconButton>
  );

  return (
    <>
      <div styleName="tile-container">
        <div styleName="tile-content-container">
          <div styleName="tile-icon-container">
            <Icon style={iconStyle}>category</Icon>
          </div>
          <div styleName="tile-content">
            <Tooltip title={material.name}>
              <div styleName="tile-title">{material.name}</div>
            </Tooltip>
          </div>
        </div>
        {actionButton}
      </div>
      <DeleteModal
        open={deleteModalOpen}
        onConfirm={handleRemoveMaterial}
        onCancel={() => setDeleteModalOpen(false)}
        title="Remove Equipment or Material From Task"
        content="Are you sure you want to remove this item from the task?"
        cancelText="Cancel"
        confirmText="Remove"
      />
    </>
  );
};

export default MaterialTile;
