import * as React from 'react';

import { Button, Icon } from '@atom/mui';
import colors from '@atom/styles/colors';

const styles = {
  root: {
    marginTop: '1rem',
    width: '2rem',
    minWidth: '2rem',
    color: colors.neutral.gray,
  },
  input: {
    display: 'none',
  },
  assetUploadButton: {
    width: '2rem',
    minWidth: '2rem',
    color: colors.neutral.gray,
  },
  fieldUploadButton: {
    marginTop: '1rem',
    width: '2rem',
    minWidth: '2rem',
    color: colors.neutral.gray,
  },
};

interface Props {
  onChange: (event: Object) => void;
  isUploadDisabled: boolean;
  asset?: boolean;
}

const FileUploadButton = ({ onChange, asset, isUploadDisabled }: Props) => {
  const rootStyles = asset
    ? styles.assetUploadButton
    : styles.fieldUploadButton;

  return (
    <Button component="label" style={rootStyles} disabled={isUploadDisabled}>
      <Icon>file_upload</Icon>
      <input
        type="file"
        multiple
        style={styles.input}
        onChange={onChange}
        disabled={isUploadDisabled}
      />
    </Button>
  );
};

export default FileUploadButton;
