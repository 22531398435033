import { call, debounce, fork, put } from 'redux-saga/effects';

import actionTypes from '@atom/actions/actionTypes';
import * as applicationSearchActions from '@atom/actions/applicationSearchActions';
import accountUtilities from '@atom/utilities/accountUtilities';
import api from '@atom/utilities/api';
import { SEARCH_ENDPOINT } from '@atom/utilities/endpoints';

export function* getSearchResults(action) {
  const {
    query,
    top,
    includeAssets,
    includeWorkOrders,
    includeUsers,
  } = action.data;
  const params = {
    query,
    top,
    ...(includeAssets ? { includeAssets } : {}),
    ...(includeWorkOrders ? { includeWorkOrders } : {}),
    ...(includeUsers ? { includeUsers } : {}),
  };

  try {
    const { data } = yield call(api.get, SEARCH_ENDPOINT, params);

    yield put(applicationSearchActions.getApplicationSearchSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      applicationSearchActions.getApplicationSearchFailure,
    );
  }
}

/* WATCHERS */

export function* retrieveSearchResults() {
  yield debounce(500, actionTypes.REQUEST_APPLICATION_SEARCH, getSearchResults);
}

export default [retrieveSearchResults];
