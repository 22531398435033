import * as React from 'react';

import { MapWorkOrder } from '@atom/types/map';

import NoItemsPane from './NoItemsPane';
import WorkOrdersListItem from './WorkOrdersListItem';

import './drawerContent.css';

interface Props {
  workOrders: MapWorkOrder[];
  navigateToWorkOrder: (id: string) => void;
}

const WorkOrderList = ({ workOrders, navigateToWorkOrder }: Props) => {
  const totalVisible = workOrders.length;

  return (
    <div styleName="sidepane-list-container">
      <div styleName="list-header">{`${totalVisible} work`}</div>
      {!totalVisible ? (
        <NoItemsPane
          icon="work_off"
          text={[
            'There is no work in this map area.',
            'Please zoom out or move around on map to find work.',
          ]}
        />
      ) : (
        <div styleName="list-item-container">
          {workOrders.map(workOrder => (
            <WorkOrdersListItem
              key={workOrder.id}
              workOrder={workOrder}
              navigateToWorkOrder={navigateToWorkOrder}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default WorkOrderList;
