import React, { useContext, useMemo } from 'react';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import { Icon, IconButton } from '@atom/mui';

import AnalyticsContext from './AnalyticsContext';

import './analyticsDashboard.css';

const DEBOUNCE_MS = 500;
const MIN_QUERY_LENGTH = 2;

const AnalyticsSearch = () => {
  const { searching, setSearching, setActiveFolderId, setQuery } = useContext(
    AnalyticsContext,
  );

  const handleSearchClick = () => {
    setActiveFolderId('analytics');
    setSearching(true);
  };

  const handleSetQuery = (newQuery: string) => {
    if (newQuery.length >= MIN_QUERY_LENGTH || R.isEmpty(newQuery)) {
      setQuery(newQuery);
    }
  };

  const debouncedSetQuery = useMemo(
    () => debounce(event => handleSetQuery(event.target.value), DEBOUNCE_MS),
    [],
  );

  const getContent = searching ? (
    <div styleName="search-container">
      <div styleName="search-icon">
        <Icon>search</Icon>
      </div>
      <input
        autoFocus
        placeholder="Search in Analytics"
        onChange={debouncedSetQuery}
        data-cy="analyticsSearchQueryInput"
      />
      <IconButton
        size="small"
        onClick={() => setSearching(false)}
        data-cy="analyticsSearchClose"
      >
        <Icon>close</Icon>
      </IconButton>
    </div>
  ) : (
    <IconButton
      size="small"
      onClick={handleSearchClick}
      data-cy="analyticsSearchIcon"
    >
      <Icon>search</Icon>
    </IconButton>
  );

  return <>{getContent}</>;
};

export default AnalyticsSearch;
