import api from '@atom/utilities/api';
import { FORM_INSTANCES_ENDPOINT } from '@atom/utilities/endpoints';

import actionTypes from './actionTypes';

export const createFormInstanceSuccess = data => ({
  type: actionTypes.CREATE_FORM_INSTANCE_SUCCESS,
  data,
});

export const createFormInstanceFailure = () => ({
  type: actionTypes.CREATE_FORM_INSTANCE_FAILURE,
});

export function requestCreateFormInstance(body) {
  return async function (dispatch) {
    try {
      const baseEndpoint = FORM_INSTANCES_ENDPOINT;
      const { data } = await api.post(baseEndpoint, body);
      dispatch(createFormInstanceSuccess(data));
      return data;
    } catch (error) {
      dispatch(createFormInstanceFailure());
      throw error;
    }
  };
}

export const retrieveFormInstance = data => ({
  type: actionTypes.REQUEST_FORM_INSTANCE,
  data,
});

export const getFormInstanceSuccess = data => ({
  type: actionTypes.GET_FORM_INSTANCE_SUCCESS,
  data,
});

export const getFormInstanceFailure = () => ({
  type: actionTypes.GET_FORM_INSTANCE_FAILURE,
});

export const requestUpdateFormInstanceField = data => ({
  type: actionTypes.REQUEST_UPDATE_FORM_INSTANCE_FIELD,
  data,
});

export const updateFormInstanceFieldSuccess = (data?: object) => ({
  type: actionTypes.UPDATE_FORM_INSTANCE_FIELD_SUCCESS,
  data,
});

export const updateFormInstanceFieldFailure = () => ({
  type: actionTypes.UPDATE_FORM_INSTANCE_FIELD_FAILURE,
});

export const requestProgressiveUpdateFormInstanceField = data => ({
  type: actionTypes.REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_FIELD,
  data,
});

export const requestUpdateFormInstanceAttribute = data => ({
  type: actionTypes.REQUEST_UPDATE_FORM_INSTANCE_ATTRIBUTE,
  data,
});

export const updateFormInstanceAttributeSuccess = (data?: object) => ({
  type: actionTypes.UPDATE_FORM_INSTANCE_ATTRIBUTE_SUCCESS,
  data,
});

export const updateFormInstanceAttributeFailure = () => ({
  type: actionTypes.UPDATE_FORM_INSTANCE_ATTRIBUTE_FAILURE,
});

export const requestProgressiveUpdateFormInstanceAttribute = data => ({
  type: actionTypes.REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_ATTRIBUTE,
  data,
});

export const requestUpdateFormInstanceFhwa = data => ({
  type: actionTypes.REQUEST_UPDATE_FORM_INSTANCE_FHWA,
  data,
});

export const updateFormInstanceFhwaSuccess = (data?: object) => ({
  type: actionTypes.UPDATE_FORM_INSTANCE_FHWA_SUCCESS,
  data,
});

export const updateFormInstanceFhwaFailure = () => ({
  type: actionTypes.UPDATE_FORM_INSTANCE_FHWA_FAILURE,
});

export const requestProgressiveUpdateFormInstanceFhwa = data => ({
  type: actionTypes.REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_FHWA,
  data,
});

export const requestAddFormInstanceMedia = data => ({
  type: actionTypes.REQUEST_ADD_FORM_INSTANCE_MEDIA,
  data,
});

export const addFormInstanceMediaSuccess = data => ({
  type: actionTypes.ADD_FORM_INSTANCE_MEDIA_SUCCESS,
  data,
});

export const addFormInstanceMediaFailure = () => ({
  type: actionTypes.ADD_FORM_INSTANCE_MEDIA_FAILURE,
});

export const requestDeleteFormInstanceMedia = data => ({
  type: actionTypes.REQUEST_DELETE_FORM_INSTANCE_MEDIA,
  data,
});

export const deleteFormInstanceMediaSuccess = data => ({
  type: actionTypes.DELETE_FORM_INSTANCE_MEDIA_SUCCESS,
  data,
});

export const deleteFormInstanceMediaFailure = () => ({
  type: actionTypes.DELETE_FORM_INSTANCE_MEDIA_FAILURE,
});

export const requestClearFormInstance = () => ({
  type: actionTypes.REQUEST_CLEAR_FORM_INSTANCE,
});

export const clearFormInstanceSuccess = () => ({
  type: actionTypes.CLEAR_FORM_INSTANCE_SUCCESS,
});

export const requestProgressiveUpdateFormInstanceCi = data => ({
  type: actionTypes.REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_CI,
  data,
});

export const updateFormInstanceCiSuccess = (data?: object) => ({
  type: actionTypes.UPDATE_FORM_INSTANCE_CI_SUCCESS,
  data,
});

export const updateFormInstanceCiFailure = () => ({
  type: actionTypes.UPDATE_FORM_INSTANCE_CI_FAILURE,
});

export const requestProgressiveUpdateFormInstancePci = data => ({
  type: actionTypes.REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_PCI,
  data,
});

export const updateFormInstancePciSuccess = (data?: object) => ({
  type: actionTypes.UPDATE_FORM_INSTANCE_PCI_SUCCESS,
  data,
});

export const updateFormInstancePciFailure = () => ({
  type: actionTypes.UPDATE_FORM_INSTANCE_PCI_FAILURE,
});
