import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import * as inventoryExportActionCreators from '@atom/actions/inventoryExportActions';
import DeleteModal from '@atom/components/common/DeleteModal';
// @ts-ignore
import singleExportIcon from '@atom/components/common/svgIcons/singleExportIcon.svg';
import { Icon, ListTable, Menu, Progress } from '@atom/mui';
import layout from '@atom/styles/layout';
import { BasicInventoryExportTemplateType } from '@atom/types/inventoryExport';
import history from '@atom/utilities/history';
import { setDisplayDate } from '@atom/utilities/timeUtilities';

// @ts-ignore
import leftPaneStyles from '../../styles/left-pane.css';
// @ts-ignore
import tableStyles from '../../styles/table.css';

const { TableHead, TableRow, TableCell, TableBody } = ListTable;

const { MenuItem } = Menu;

/* Note that this is to satisfy the webpack build step which would
   automatically de-reference/scrub the import of styles as they arent
   used anywhere in the code.  Passing them as magic strings to styleName
   is irrelevant

   For additional reading:
   https://github.com/gajus/babel-plugin-react-css-modules/issues/130
*/

// @ts-ignore
let styleReference = tableStyles;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
styleReference = leftPaneStyles;

const styles = {
  progress: {
    position: 'fixed',
    top: 0,
    right: 0,
    width: `calc(100vw - ${layout.sidePaneWidth})`,
    height: '100%',
  },
  name: {
    cursor: 'pointer',
    fontWeight: 500,
  },
};

interface Props {
  exportTemplates: BasicInventoryExportTemplateType[];
  loading: boolean;
}

const InventoryExportList = ({ exportTemplates, loading }: Props) => {
  const dispatch = useDispatch();
  const [exportToDelete, setExportToDelete] = useState<
    BasicInventoryExportTemplateType
  >(null);

  const navigateToTemplate = (exportTemplateId: string) => {
    history.push(`/inventory/export/${exportTemplateId}`);
  };

  const deleteExportTemplate = (exportTemplateId: string) => {
    dispatch(
      inventoryExportActionCreators.requestExportTemplateDelete({
        exportTemplateId,
      }),
    );
    setExportToDelete(null);
  };

  return (
    <>
      {loading ? (
        <Progress style={styles.progress} />
      ) : (
        <>
          <ListTable offsetTop="150px">
            <TableHead>
              <TableRow header>
                <TableCell variant="head" />
                <TableCell variant="head">Name</TableCell>
                <TableCell variant="head">Status</TableCell>
                <TableCell variant="head">Created By</TableCell>
                <TableCell variant="head">Created On</TableCell>
                <TableCell variant="head" />
              </TableRow>
            </TableHead>
            <TableBody>
              {exportTemplates.map(row => {
                const status = row.isPublished
                  ? { icon: 'lock', text: 'Published' }
                  : { icon: 'edit', text: 'Draft' };

                const firstName = R.pathOr('', ['firstName'], row.createdBy);
                const lastName = R.pathOr('', ['lastName'], row.createdBy);
                return (
                  <TableRow key={row.id}>
                    <TableCell>
                      <img src={singleExportIcon} />
                    </TableCell>
                    <TableCell>
                      <div
                        style={styles.name}
                        onClick={(): void => navigateToTemplate(row.id)}
                      >
                        {row.name}
                      </div>
                    </TableCell>
                    <TableCell>
                      <Icon>{status.icon}</Icon>
                      <div style={{ paddingLeft: '0.5rem' }}>{status.text}</div>
                    </TableCell>
                    <TableCell>{`${firstName} ${lastName}`}</TableCell>
                    <TableCell>{setDisplayDate(row.createdDate)}</TableCell>
                    <TableCell width="60px">
                      <Menu>
                        <MenuItem
                          startAdornment={<Icon>delete</Icon>}
                          onClick={() => setExportToDelete(row)}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </ListTable>
          <DeleteModal
            title="Delete Export Template?"
            content="Are you sure you want to delete this export template?"
            open={!!exportToDelete}
            onCancel={() => setExportToDelete(null)}
            onConfirm={() => deleteExportTemplate(exportToDelete.id)}
          />
        </>
      )}
    </>
  );
};

export default InventoryExportList;
