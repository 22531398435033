import React, { useState } from 'react';

import { Checkbox, Icon, IconButton } from '@atom/mui';
import { ProjectionOption, ProjectionType } from '@atom/types/export';

import WorkExportFieldRow from './WorkExportFieldRow';

import './workExport.css';

const ProjectionTypeIconMap: { [key in ProjectionType]: string } = {
  WORK: 'work',
  TASK: 'playlist_add_check_icon',
  FORM: 'library_books',
};

interface Props {
  projection: ProjectionOption;
  selectedProjections: {
    [id: string]: Set<string>;
  };
  filterCounter: number;
  fieldLength: number;
  fields: string[];
  customFields: string[];
  handleToggle: (id: string, field: string) => void;
  handleSectionToggle: (id: string, fieldLength: number) => void;
}

const styles = {
  icon: {
    padding: 0,
  },
  typeIcon: {
    marginLeft: '0.3rem',
  },
  label: {
    fontWeight: 500,
    marginTop: '2px',
    paddingBottom: '0.5rem',
    paddingLeft: '0.5rem',
  },
};

const WorkExportProjections = ({
  projection,
  selectedProjections,
  handleToggle,
  handleSectionToggle,
  filterCounter,
  fieldLength,
  fields,
  customFields,
}: Props) => {
  const [openSection, setOpenSection] = useState<boolean>(false);
  return (
    <>
      <div>
        <div styleName="field-row">
          <IconButton
            style={styles.icon}
            size="small"
            onClick={() => setOpenSection(!openSection)}
          >
            <Icon
              children={
                openSection ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
              }
            />
          </IconButton>
          <Checkbox
            style={styles.icon}
            checked={
              fieldLength + filterCounter ===
              selectedProjections[projection.id]?.size
            }
            onClick={() =>
              handleSectionToggle(projection.id, fieldLength + filterCounter)
            }
          />
          <Icon style={styles.typeIcon}>
            {ProjectionTypeIconMap[projection.type]}
          </Icon>
          <span style={styles.label}>{projection.name}</span>
        </div>

        {openSection && (
          <div style={{ paddingLeft: '3.3125rem' }}>
            {fields.map((field, index) => (
              <WorkExportFieldRow
                key={index}
                field={field}
                projectionId={projection.id}
                selectedProjections={selectedProjections}
                handleToggle={handleToggle}
                isCustomField={customFields.includes(field)}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default WorkExportProjections;
