import React, { useContext, useState } from 'react';

import WorkOrderAssetViewContext from '@atom/components/common/workOrderDetail/workOrderAssetView/WorkOrderAssetViewContext';
import { Icon, IconButton, Modal } from '@atom/mui';
import api from '@atom/utilities/api';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { getWorkOrderAssetsEndpoint } from '@atom/utilities/endpoints';

import './workOrderAssetView.css';

const EDITABLE_ERROR_CODE = 422;

const WorkOrderAssetHeader = () => {
  const {
    setActiveElementId,
    workOrderDetail,
    workOrderAsset,
    setActiveWorkOrderAsset,
    refetchWorkOrderAssetTreeLoading,
    refetchWorkOrderAssetTree,
    previewView,
    hasUnsavedChanges,
  } = useContext(WorkOrderAssetViewContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [openApproveModal, setOpenApproveModal] = useState<boolean>(false);

  const hasPendingChanges =
    hasRolePermissions(ROLE_SETS.MANAGER) && !!workOrderAsset.changeType;

  const handleApprove = async () => {
    setLoading(true);

    try {
      await api.post(
        `${getWorkOrderAssetsEndpoint(workOrderDetail.id)}/${
          workOrderAsset.id
        }/approve`,
        {
          includeChangesBefore: workOrderAsset.includeChangesBefore,
          approveChangesRecursively: true,
        },
      );

      setActiveElementId(workOrderAsset.id);
      refetchWorkOrderAssetTree();
    } catch (error) {
      if (error?.response?.status === EDITABLE_ERROR_CODE) {
        setOpenErrorModal(true);
      }
    } finally {
      setLoading(false);
      setOpenApproveModal(false);
    }
  };

  return (
    <>
      <div styleName="header-container">
        <div styleName="back-button-container">
          <IconButton onClick={() => setActiveWorkOrderAsset('')}>
            <Icon>arrow_back</Icon>
          </IconButton>
          <div>Back to Tasks</div>
        </div>
        {!previewView && (
          <div styleName="changes-container">
            {refetchWorkOrderAssetTreeLoading || loading ? (
              'Saving...'
            ) : hasUnsavedChanges ? (
              <span style={{ color: '#ff8f00' }}>Unsaved Changes.</span>
            ) : (
              'All changes saved.'
            )}
            {hasPendingChanges && (
              <IconButton
                tooltip="Approve All Changes"
                onClick={() => setOpenApproveModal(true)}
              >
                <Icon>done_all</Icon>
              </IconButton>
            )}
          </div>
        )}
      </div>
      <Modal
        open={openApproveModal}
        onCancel={() => setOpenApproveModal(false)}
        confirmButtonText="Approve All"
        onConfirm={handleApprove}
        title={`Approve All Changes for ${workOrderAsset.name}`}
      >
        <span>
          Are you sure you want to approve all changes to
          <strong> {workOrderAsset.name} </strong>in all tasks?
        </span>
      </Modal>
      <Modal
        open={openErrorModal}
        confirmButtonText="OK"
        onConfirm={() => setOpenErrorModal(false)}
        title="Cannot Approve All Changes"
      >
        Some pending changes cannot be approved because the attribute is not
        editable. Please set the attribute to editable in the asset schema to
        approve or reject changes.
      </Modal>
    </>
  );
};

export default WorkOrderAssetHeader;
