import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function applicationSearchReducer(
  state = initialState.applicationSearch,
  action,
) {
  const { data } = action;
  switch (action.type) {
    case actionTypes.CLEAR_APPLICATION_SEARCH: {
      return {
        ...initialState.applicationSearch,
      };
    }
    case actionTypes.REQUEST_APPLICATION_SEARCH: {
      return {
        ...state,
        query: data.query,
        assets: [],
        workOrders: [],
        users: [],
      };
    }
    case actionTypes.GET_APPLICATION_SEARCH_SUCCESS: {
      return {
        ...state,
        assets: data.assets,
        workOrders: data.workOrders,
        users: data.users,
      };
    }
    default: {
      return state;
    }
  }
}
