import { gql } from '@apollo/client';

import { BASE_POLICY } from './policy';

export const BASE_ROLE = gql`
  fragment BaseRole on Role {
    id
    name
    description
    isDefault
    createdBy {
      id
      firstName
      lastName
      email
    }
    createdDate
    updatedBy {
      id
      firstName
      lastName
      email
    }
    updatedDate
  }
`;

export const BASE_ASSIGNED_ROLE = gql`
  fragment BaseAssignedRole on AssignedRole {
    id
    name
    description
    isDefault
    inheritedFrom {
      id
      name
    }
    assignedBy {
      id
      firstName
      lastName
      email
    }
    assignedDate
  }
`;

export const GET_ROLE = gql`
  query getRole(
    $id: ID!
    $assigneesInput: RoleAssigneesConnectionInput!
    $policiesInput: PoliciesConnectionInput!
  ) {
    role(id: $id) {
      ...BaseRole
      policies(input: $policiesInput) {
        totalCount
        policies {
          ...BasePolicy
        }
      }
      assignees(input: $assigneesInput) {
        totalCount
        totalUsersCount
        totalUserGroupsCount
        assignees {
          id
          type
          name
          colorId
          photoUrl
          roles {
            id
          }
          assignedDate
          assignedBy {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
  ${BASE_ROLE}
  ${BASE_POLICY}
`;

export const GET_ROLES = gql`
  query getRoles($input: RolesConnectionInput!) {
    roles(input: $input) {
      totalCount
      roles {
        ...BaseRole
      }
    }
  }
  ${BASE_ROLE}
`;

export const CREATE_ROLE = gql`
  mutation roleCreate($input: RoleCreateInput!) {
    roleCreate(input: $input) {
      ...BaseRole
    }
  }
  ${BASE_ROLE}
`;

export const DUPLICATE_ROLE = gql`
  mutation roleDuplicate($input: RoleDuplicateInput!) {
    roleDuplicate(input: $input) {
      ...BaseRole
    }
  }
  ${BASE_ROLE}
`;

export const UPDATE_ROLE = gql`
  mutation roleUpdate($input: RoleUpdateInput!) {
    roleUpdate(input: $input) {
      ...BaseRole
    }
  }
  ${BASE_ROLE}
`;

export const DELETE_ROLE = gql`
  mutation roleDelete($id: ID!) {
    roleDelete(id: $id)
  }
`;

export const ADD_ROLE_ASSIGNEES = gql`
  mutation roleAssigneesAdd(
    $input: RoleAssigneesAddInput!
    $assigneesInput: RoleAssigneesConnectionInput!
  ) {
    roleAssigneesAdd(input: $input) {
      ...BaseRole
      assignees(input: $assigneesInput) {
        totalCount
        totalUsersCount
        totalUserGroupsCount
        assignees {
          id
          type
          name
          colorId
          photoUrl
          roles {
            id
          }
          assignedDate
          assignedBy {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
  ${BASE_ROLE}
`;
