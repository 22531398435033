import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { Icon, IconButton, ListTable } from '@atom/components/common/mui';
import UserGroupIcon from '@atom/components/common/UserGroupIcon';
import UserThumbnail from '@atom/components/common/UserThumbnail';
import InheritanceLockIcon from '@atom/components/workTemplate/InheritanceLockIcon';
import WorkTemplateContext, {
  WorkTemplateActionTypes,
} from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_UPDATE } from '@atom/graph/workTemplate';
import { Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  InheritedComponentType,
  WorkOrderTemplateUpdateInput,
  WorkTemplate,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import {
  rollUpHydratedTaskUserGroups,
  rollUpHydratedTaskUsers,
} from '@atom/utilities/userUtilities';
import {
  isComponentInherited,
  rollUpTaskInheritedComponent,
  WorkTypeVariant,
} from '@atom/utilities/workTemplateUtilities';

import './workTemplateTeam.css';

const { TableHead, TableRow, TableCell, TableBody } = ListTable;

const styles = {
  row: {
    backgroundColor: colors.neutral.white,
    height: '3rem',
  },
};

const WorkTemplateTeam = () => {
  const { workTemplate, workTypeVariant, dispatch, loading } = useContext(
    WorkTemplateContext,
  );

  const [updateWorkTemplate] = useMutation<
    { workOrderTemplateUpdate: WorkTemplate },
    { input: WorkOrderTemplateUpdateInput }
  >(WORK_TEMPLATE_UPDATE);

  const updateLeadAssignee = async (leadAssigneeId: string) => {
    const updatedLeadId =
      leadAssigneeId === workTemplate?.leadAssigneeId ? '' : leadAssigneeId;

    await updateWorkTemplate({
      variables: {
        input: {
          workOrderTemplateId: workTemplate.id,
          leadAssigneeId: updatedLeadId,
        },
      },
    });

    dispatch({
      type: WorkTemplateActionTypes.UPDATE_WORK_TEMPLATE_PROPERTY,
      data: {
        property: 'leadAssigneeId',
        value: updatedLeadId,
      },
    });
  };

  const hydratedUsers = rollUpHydratedTaskUsers(workTemplate.tasks);
  const hydratedUserGroups = rollUpHydratedTaskUserGroups(workTemplate.tasks);

  const displayList = [...hydratedUserGroups, ...hydratedUsers];

  const totalMembers = displayList.reduce((acc, item) => {
    return item.totalQuantity ? acc + item.totalQuantity : acc + 1;
  }, 0);

  const isDisabled =
    workTemplate?.published || doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  return (
    <div styleName="team-container">
      <div styleName="center-pane">
        {loading ? (
          <Progress />
        ) : (
          <>
            <div styleName="team-total">{`${totalMembers} team members`}</div>
            <ListTable offsetTop="260px">
              <TableHead>
                <TableRow header>
                  <TableCell variant="head" padding="checkbox" />
                  <TableCell variant="head">Label</TableCell>
                  <TableCell variant="head">Task</TableCell>
                  <TableCell variant="head" padding="checkbox" />
                </TableRow>
              </TableHead>
              <TableBody>
                {displayList.map(item => {
                  const isActive = workTemplate.leadAssigneeId === item.id;
                  const isUserGroup = item.__typename === 'TaskUserGroup';

                  const showActionIcon =
                    workTypeVariant === WorkTypeVariant.CHILD ||
                    (workTypeVariant === WorkTypeVariant.REGULAR &&
                      !isUserGroup);

                  const isUserInherited = isComponentInherited(
                    InheritedComponentType.TASK_ASSIGNEE,
                    rollUpTaskInheritedComponent(
                      workTemplate,
                      InheritedComponentType.TASK_ASSIGNEE,
                    ),
                    item?.id,
                  );

                  const isUserGroupInherited = isComponentInherited(
                    InheritedComponentType.TASK_USER_GROUP,
                    rollUpTaskInheritedComponent(
                      workTemplate,
                      InheritedComponentType.TASK_USER_GROUP,
                    ),
                    item?.userGroupId,
                  );

                  const tooltipText = isUserGroup
                    ? 'Inherited Group cannot be removed or edited.'
                    : 'Inherited Team member cannot be removed or edited.';

                  const showLock = isUserGroup
                    ? isUserGroupInherited
                    : isUserInherited;

                  const actionIcon = showLock ? (
                    <div style={{ marginLeft: '10px' }}>
                      <InheritanceLockIcon
                        tooltip={tooltipText}
                        placement="bottom-start"
                      />
                    </div>
                  ) : (
                    <IconButton
                      onClick={() => updateLeadAssignee(item.id)}
                      size="small"
                      disabled={isDisabled}
                    >
                      <Icon
                        color={
                          isActive ? colors.brand.blue : colors.neutral.gray
                        }
                      >
                        {isActive ? 'star' : 'star_border'}
                      </Icon>
                    </IconButton>
                  );

                  const name = isUserGroup
                    ? item.name
                    : `${item.firstName} ${item.lastName}`;
                  const subtext = isUserGroup
                    ? `${item.totalQuantity} User(s)`
                    : item.email;

                  return (
                    <TableRow key={item.id} style={styles.row}>
                      <TableCell padding="checkbox">
                        {isUserGroup ? (
                          <div>
                            <UserGroupIcon colorId={item.colorId} />
                          </div>
                        ) : (
                          <UserThumbnail
                            image={item.photoUrl}
                            alt={`${item.firstName} ${item.lastName}`}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <div styleName="label-column">
                          <div styleName="name">{name}</div>
                          <div styleName="subtext">{subtext}</div>
                        </div>
                      </TableCell>
                      <TableCell>{item.taskName.join(', ')}</TableCell>
                      <TableCell>
                        {showActionIcon && (
                          <div styleName="team-lead-column">
                            {isActive ? (
                              <div>Team Lead</div>
                            ) : (
                              <div styleName="lead-filler" />
                            )}
                            {actionIcon}
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </ListTable>
          </>
        )}
      </div>
    </div>
  );
};

export default WorkTemplateTeam;
