import { DragDrop } from 'react-beautiful-dnd';
import * as R from 'ramda';

import { DataType } from '@atom/types/dataType';
import {
  SchemaTree,
  SchemaTreeAttribute,
  SchemaTreeAttributeGroup,
  SelectedAttributeRoute,
} from '@atom/types/schema';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import { PendingCreations } from '../../SchemaDetailContext';

export const INITIAL_TEMP_ATTRIBUTE = {
  isTempAttribute: true,
  dataType: DataType.SHORT_TEXT,
  defaultValue: null,
  isVisibleAsSubtext: false,
  isFilterable: false,
  isEditable: true,
  isRequired: false,
};

export const getAttributeGroupsPayload = attributeGroups => {
  return attributeGroups.map(attributeGroup => ({
    ...attributeGroup,
    attributes: attributeGroup.attributes.map(attribute => attribute.id),
  }));
};

export const reorderAttributeGroups = (
  schema: Partial<SchemaTree>,
  result: DragDrop,
) => {
  const sourceIndex = result.source.index;
  const destinationIndex = result.destination.index;

  const updatedAttributeGroups = R.move(
    sourceIndex,
    destinationIndex,
    schema.attributeGroups,
  );

  return {
    updatedAttributeGroups,
    payload: {
      schemaId: schema.id,
      attributeGroups: getAttributeGroupsPayload(updatedAttributeGroups),
    },
  };
};

export const reorderAttributes = (
  schema: Partial<SchemaTree>,
  result: DragDrop,
) => {
  const attributeId = result.draggableId;
  const sourceGroupId = result.source.droppableId;
  const sourceIndex = result.source.index;
  const destinationGroupId = result.destination.droppableId;
  const destinationIndex = result.destination.index;

  const originalAttributeGroup = schema.attributeGroups.find(
    group => group.id === sourceGroupId,
  );

  // Handles moving attributes within same attributeGroup
  if (sourceGroupId === destinationGroupId) {
    const updatedAttributeGroups = schema.attributeGroups.map(group => {
      return group.id === sourceGroupId
        ? {
            ...group,
            attributes: R.move(
              sourceIndex,
              destinationIndex,
              originalAttributeGroup.attributes,
            ),
          }
        : group;
    });

    return {
      updatedAttributeGroups,
      payload: {
        schemaId: schema.id,
        attributeGroups: getAttributeGroupsPayload(updatedAttributeGroups),
      },
    };
  }

  // Handles moving attributes across attributeGroups
  const newAttributeGroup = schema.attributeGroups.find(
    group => group.id === destinationGroupId,
  );

  const selectedAttribute = originalAttributeGroup.attributes.find(
    attribute => attribute.id === attributeId,
  );

  const updatedAttributeGroups = schema.attributeGroups.map(group => {
    switch (group.id) {
      // Remove attribute from original attributeGroup
      case sourceGroupId:
        return {
          ...group,
          attributes: R.remove(
            sourceIndex,
            1,
            originalAttributeGroup.attributes,
          ),
        };
      // Add attribute to destination attributeGroup at specified location
      case destinationGroupId:
        return {
          ...group,
          attributes: R.insert(
            destinationIndex,
            selectedAttribute,
            newAttributeGroup.attributes,
          ),
        };
      default:
        return group;
    }
  });

  return {
    updatedAttributeGroups,
    payload: {
      schemaId: schema.id,
      attributeGroups: getAttributeGroupsPayload(updatedAttributeGroups),
    },
  };
};

export const addPendingCreation = (
  schemaId: string,
  pendingCreations: PendingCreations,
  newAttribute: SchemaTreeAttribute,
): PendingCreations => {
  const attributeGroupId = newAttribute.attributeGroupId;

  const hasSchemaEntry = R.has(schemaId)(pendingCreations);
  const hasAttributeGroupEntry = hasSchemaEntry
    ? R.has(attributeGroupId)(pendingCreations[schemaId])
    : false;

  const updatedPendingCreations = {
    ...pendingCreations,
    [schemaId]: {
      ...(hasSchemaEntry ? pendingCreations[schemaId] : {}),
      [attributeGroupId]: {
        ...(hasAttributeGroupEntry
          ? pendingCreations[schemaId][attributeGroupId]
          : {}),
        [newAttribute.id]: newAttribute,
      },
    },
  };

  return updatedPendingCreations;
};

export const updatePendingCreation = (
  schemaId: string,
  selectedAttributeRoute: SelectedAttributeRoute,
  pendingCreations: PendingCreations,
  payload: Partial<SchemaTreeAttribute>,
): PendingCreations => {
  const attributeGroupId = selectedAttributeRoute.attributeGroupId;
  const attributeId = selectedAttributeRoute.attributeId;

  const path = [schemaId, attributeGroupId, attributeId];
  return R.over(
    R.lensPath(path),
    prev => ({ ...prev, ...payload }),
    pendingCreations,
  );
};

// Cleans update elements and attributeGroups that have no pending creations
export const cleanCreations = (
  pendingCreations: PendingCreations,
): PendingCreations => {
  return R.keys(pendingCreations).reduce((acc, elementKey) => {
    const cleanedGroups = R.keys(pendingCreations[elementKey]).reduce(
      (innerAcc, groupKey) => {
        const currentGroup = pendingCreations[elementKey][groupKey];
        return isNilOrEmpty(currentGroup)
          ? innerAcc
          : { ...innerAcc, [groupKey]: currentGroup };
      },
      {},
    );

    return isNilOrEmpty(cleanedGroups)
      ? acc
      : { ...acc, [elementKey]: cleanedGroups };
  }, {});
};

export const removePendingCreation = (
  schemaId: string,
  attributeGroupId: string,
  attributeId: string,
  pendingCreations: PendingCreations,
): PendingCreations => {
  const path = [schemaId, attributeGroupId];
  const rawCreations = R.over(
    R.lensPath(path),
    prev => R.omit([attributeId], prev),
    pendingCreations,
  );

  return cleanCreations(rawCreations);
};

export const mergePendingCreations = (
  schemaId: string,
  attributeGroup: SchemaTreeAttributeGroup,
  pendingCreations: PendingCreations,
): SchemaTreeAttributeGroup => {
  const hasSchemaEntry = R.has(schemaId)(pendingCreations);
  const hasAttributeGroupEntry = hasSchemaEntry
    ? R.has(attributeGroup.id)(pendingCreations[schemaId])
    : false;

  const newAttributes =
    hasSchemaEntry && hasAttributeGroupEntry
      ? R.values(pendingCreations[schemaId][attributeGroup.id])
      : [];

  return {
    ...attributeGroup,
    attributes: [...attributeGroup.attributes, ...newAttributes],
  };
};
