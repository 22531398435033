import * as R from 'ramda';
import { createSelector } from 'reselect';

export const inventoryFilterCategoryTreeSelector = R.pathOr(
  [],
  ['inventoryFilterCategoryTree'],
);

export const inventoryCategoryTreeSelector = R.pathOr({}, [
  'inventory',
  'categories',
  'inventoryCategoryTree',
]);

export const inventorySchemasSelector = R.pathOr([], ['inventorySchemas']);

export const getRootSchema = (inventorySchemas, schemaId) =>
  inventorySchemas.find(schema => schema.id === schemaId);

export const getColorAndAssetType = (inventorySchemas, schemaId) => {
  const rootSchema = getRootSchema(inventorySchemas, schemaId);
  if (!R.isNil(rootSchema)) {
    return {
      colorId: R.pathOr('DEFAULT', ['colorId'], rootSchema),
      assetType: R.pathOr('', ['assetType'], rootSchema),
    };
  }
  return {
    colorId: 'DEFAULT',
    assetType: '',
  };
};

export const hydrateSubCategories = (categories, inventorySchemas) => {
  return categories.map(node => {
    if (!R.isNil(node.subCategories) && !R.isNil(node.schemaId)) {
      return {
        ...node,
        ...getColorAndAssetType(inventorySchemas, node.schemaId),
        subCategories: hydrateSubCategories(
          node.subCategories,
          inventorySchemas,
        ),
      };
    }
    if (R.isNil(node.subCategories) && !R.isNil(node.schemaId)) {
      return {
        ...node,
        ...getColorAndAssetType(inventorySchemas, node.schemaId),
      };
    }
    if (!R.isNil(node.subCategories)) {
      return {
        ...node,
        subCategories: hydrateSubCategories(
          node.subCategories,
          inventorySchemas,
        ),
      };
    }
    return node;
  });
};

export const hydrateChildren = (children, inventorySchemas) => {
  return R.keys(children).reduce((acc, childId) => {
    const child = children[childId];
    if (!child.hasChildren && !R.isNil(child.schemaId)) {
      return {
        ...acc,
        [childId]: {
          ...child,
          ...getColorAndAssetType(inventorySchemas, child.schemaId),
        },
      };
    }
    if (child.hasChildren) {
      return {
        ...acc,
        [childId]: {
          ...child,
          children: hydrateChildren(child.children, inventorySchemas),
        },
      };
    }
    return { ...acc, [childId]: { ...child } };
  }, {});
};

export const getHydratedInventoryCategoryTreeNode = createSelector(
  state => inventoryCategoryTreeSelector(state),
  state => inventorySchemasSelector(state),
  (inventoryCategoryTree, inventorySchemas) => {
    // @ts-ignore
    if (inventoryCategoryTree.hasChildren) {
      return {
        ...inventoryCategoryTree,
        children: hydrateChildren(
          // @ts-ignore
          inventoryCategoryTree.children,
          inventorySchemas,
        ),
      };
    }
    return inventoryCategoryTree;
  },
);

export const getHydratedInventoryFilterCategoryTreeNode = createSelector(
  state => inventoryFilterCategoryTreeSelector(state),
  state => inventorySchemasSelector(state),
  (inventoryFilterCategoryTree, inventorySchemas) => {
    return inventoryFilterCategoryTree.map(node => {
      if (!R.isNil(node.subCategories)) {
        return {
          ...node,
          subCategories: hydrateSubCategories(
            node.subCategories,
            inventorySchemas,
          ),
        };
      }
      return node;
    });
  },
);

export const filteredCategoriesTextSelector = createSelector(
  // @ts-ignore
  selectedItems => selectedItems.size,
  size => {
    if (!size) {
      return 'Filter by all folders';
    }

    const folderText = size > 1 ? 'folders' : 'folder';
    return `Filtered by ${size} ${folderText}`;
  },
);
