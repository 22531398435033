import { HydratedUser } from 'client/types/work';
import * as R from 'ramda';

import { RecentlyUpdatedItem } from '@atom/types/dashboard';
import { Task } from '@atom/types/task';
import { UserCollectionItem, UserDetail, UserProfile } from '@atom/types/user';
import { TimeEntryApprover } from '@atom/types/userGroups';
import { WorkTemplateTaskItem } from '@atom/types/workTemplate';

import textDisplayUtilities from './textDisplayUtilities';

export const sortByFullName = (
  current: UserCollectionItem,
  next: UserCollectionItem,
): number =>
  `${current.firstName} ${current.lastName}`.localeCompare(
    `${next.firstName} ${next.lastName}`,
  );

export const sortByName = (current: any, next: any): number =>
  current.name.localeCompare(next.name);

export const rollUpTaskUsers = (tasks: Task[]): UserDetail[] => {
  const users = tasks.reduce((acc, task) => {
    return [
      ...acc,
      ...task.users.map(user => ({ ...user, taskName: task.name })),
    ];
  }, []);

  // @ts-ignore
  return R.pipe(
    // @ts-ignore
    R.uniqWith(R.eqProps('id')),
    // @ts-ignore
    R.sort(sortByFullName),
    // @ts-ignore
  )(users);
};

export const rollUpHydratedTaskUsers = (
  tasks: Task[] | WorkTemplateTaskItem[],
): any[] => {
  // @ts-ignore
  const users = tasks.reduce((acc, task) => {
    return [
      ...acc,
      ...task.users.map(user => ({ ...user, taskName: [task.name] })),
    ];
  }, []);

  const usersMap = users.reduce((acc, user) => {
    const taskName = [
      ...(acc[user.id] ? acc[user.id].taskName : []),
      ...user.taskName,
    ];

    return {
      ...acc,
      [user.id]: {
        ...user,
        taskName,
      },
    };
  }, {});

  return R.sort(sortByFullName)(R.keys(usersMap).map(key => usersMap[key]));
};

export const rollUpHydratedTaskUserGroups = (
  tasks: Task[] | WorkTemplateTaskItem[],
): any[] => {
  // @ts-ignore
  const userGroups = tasks.reduce((acc, task) => {
    return [
      ...acc,
      ...task.userGroups.map(userGroup => ({
        ...userGroup,
        taskName: [task.name],
      })),
    ];
  }, []);

  const userGroupsMap = userGroups.reduce((acc, userGroup) => {
    const totalQuantity = acc[userGroup.userGroupId]
      ? acc[userGroup.userGroupId].totalQuantity + userGroup.quantity
      : userGroup.quantity;

    const taskName = [
      ...(acc[userGroup.userGroupId]
        ? acc[userGroup.userGroupId].taskName
        : []),
      ...userGroup.taskName,
    ];

    return {
      ...acc,
      [userGroup.userGroupId]: {
        ...userGroup,
        totalQuantity,
        taskName,
      },
    };
  }, {});

  return R.sort(sortByName)(
    R.keys(userGroupsMap).map(key => userGroupsMap[key]),
  );
};

export const getHydratedUserDisplayName = (user: HydratedUser) => {
  if (!user) {
    return '-';
  }

  return user?.employeeId
    ? `${user?.firstName} ${user?.lastName} (${user?.employeeId})`
    : `${user?.firstName} ${user?.lastName}`;
};

export const getUserRole = (role: string): string => {
  if (role === 'Admin') {
    return 'Administrator';
  }

  if (role === 'OrgAdmin') {
    return 'Organization Administrator';
  }

  if (role === 'Manager') {
    return 'Manager';
  }

  if (role === 'Inspector') {
    return 'Field Inspector';
  }

  return 'Read Only User';
};

export const getUserFullName = (
  user:
    | UserCollectionItem
    | UserProfile
    | TimeEntryApprover
    | RecentlyUpdatedItem,
): string => {
  if (R.isNil(user) || (R.isNil(user.firstName) && R.isNil(user.lastName))) {
    return '';
  }

  if (R.isNil(user.firstName) || R.isEmpty(user.firstName)) {
    return textDisplayUtilities.capitalize(user.lastName);
  }

  if (R.isNil(user.lastName) || R.isEmpty(user.lastName)) {
    return textDisplayUtilities.capitalize(user.firstName);
  }

  return `${textDisplayUtilities.capitalize(
    user.firstName,
  )} ${textDisplayUtilities.capitalize(user.lastName)}`;
};

const userUtilities = {
  getUserRole,
  getUserFullName,
  rollUpTaskUsers,
  sortByFullName,
};

export default userUtilities;
