import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';

import {
  CREATE_DASHBOARD_CARD,
  DASHBOARD_CARDS_LAYOUT_UPDATE,
  DELETE_DASHBOARD_CARD,
  GET_DASHBOARD_CARDS,
  GET_DASHBOARD_CARDS_LAYOUT,
  UPDATE_DASHBOARD_CARD,
} from '@atom/graph/dashboard';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { Progress, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  ColumnsData,
  DashboardCard,
  DashboardCardContentState,
  DashboardCardCreateInput,
  DashboardCardDeleteInput,
  DashboardCardsConnection,
  DashboardCardsConnectionInput,
  DashboardCardSize,
  DashboardCardsLayoutConnection,
  DashboardCardsLayoutUpdateInput,
  DashboardCardType,
  DashboardCardUpdateInput,
} from '@atom/types/dashboard';
import { updateAtId } from '@atom/utilities/stateUtilities';

import CustomizeDashboardModal from './CustomizeDashboardModal/CustomizeDashboardModal';
import DashboardContext from './DashboardContext';
import DashboardEmptyState from './DashboardEmptyState';
import DashboardGrid from './DashboardGrid';
import { CARD_CONFIG } from './dashboardSettings';
import DashboardTitleBar from './DashboardTitleBar';
import {
  getColumn,
  getColumnIndex,
  getColumnsData,
  initCards,
} from './dashboardUtilities';

import './dashboard.css';

const DashboardPortal = () => {
  const { userId, firstName } = useUserProfile();
  const [allCards, setAllCards] = useState<DashboardCard[]>([]);
  const [cardsRaw, setCardsRaw] = useState<DashboardCard[]>([]);
  const [loadingInitData, setLoadingInitData] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [showCustomize, setShowCustomize] = useState<boolean>(false);
  const [layoutId, setLayoutId] = useState<string>();

  const [getDashboard] = useLazyQuery<{
    data: DashboardCardsConnection;
    input: DashboardCardsConnectionInput;
  }>(GET_DASHBOARD_CARDS, {
    variables: {
      input: {
        userId,
      },
    },
    onCompleted: data => {
      const cards = R.pathOr([], ['dashboardCards'], data);
      setCardsRaw(cards);
      if (R.isEmpty(cards)) {
        setLoadingInitData(false);
      }
    },
    fetchPolicy: 'network-only',
  });

  const [getDashboardLayout] = useLazyQuery<{
    data: DashboardCardsLayoutConnection;
    input: DashboardCardsConnectionInput;
  }>(GET_DASHBOARD_CARDS_LAYOUT, {
    variables: {
      input: { userId },
    },
    onCompleted: data => {
      const columns = R.pathOr([], ['dashboardCardsLayout', 'columns'], data);
      setLayoutId(R.pathOr(null, ['dashboardCardsLayout', 'id'], data));
      setAllCards(initCards(cardsRaw, columns));
      setLoadingInitData(false);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => getDashboard(), []);

  useEffect(() => {
    if (!R.isEmpty(cardsRaw)) {
      getDashboardLayout();
    }
  }, [cardsRaw]);

  const [callUpdateLayout, { loading: updatingLayout }] = useMutation<{
    input: DashboardCardsLayoutUpdateInput;
  }>(DASHBOARD_CARDS_LAYOUT_UPDATE);

  const [createDashboardCard, { loading: creatingCard }] = useMutation<{
    input: DashboardCardCreateInput;
  }>(CREATE_DASHBOARD_CARD);

  const [deleteDashboardCard, { loading: deletingCard }] = useMutation<{
    input: DashboardCardDeleteInput;
  }>(DELETE_DASHBOARD_CARD);

  const [updateDashboardCard, { loading: updatingCard }] = useMutation<{
    input: DashboardCardUpdateInput;
  }>(UPDATE_DASHBOARD_CARD);

  const updateLayout = (columns: ColumnsData): Promise<any> =>
    callUpdateLayout({
      variables: { input: { id: layoutId, userId, columns } },
    });

  const updateCardState = (
    card: DashboardCard,
    state: DashboardCardContentState,
  ) => {
    const cardUpdatedIdx = allCards.indexOf(card);
    setAllCards(prevAllCards =>
      R.update(cardUpdatedIdx, { ...card, contentState: state }, prevAllCards),
    );
  };

  const updateAllCardsState = (state: DashboardCardContentState) => {
    // Good example of when prevState is needed in useState hook
    //  - setting contentState by mapping over allCards
    //    carries over stale values and causes LOADING to persist on cards
    setAllCards(prevAllCards =>
      prevAllCards.map(card => ({
        ...card,
        contentState: state,
      })),
    );
  };

  const enabledCards: DashboardCard[] = allCards.filter(
    card => card.order !== -1,
  );

  const lgColumn: DashboardCard[] = useMemo(
    () => getColumn(DashboardCardSize.LG, enabledCards),
    [enabledCards],
  );
  const smColumn: DashboardCard[] = useMemo(
    () => getColumn(DashboardCardSize.SM, enabledCards),
    [enabledCards],
  );
  const someCardIsLoading: boolean = useMemo(
    () => enabledCards.some(card => card.contentState === 'LOADING'),
    [enabledCards],
  );

  const emptyBoard = R.isEmpty(enabledCards);

  const displayError = () => {
    setLoading(false);
    Snackbar.error({
      message:
        'Something went wrong. Please try again or contact an administrator.',
    });
  };

  const handleAddEmbedCard = async (card: DashboardCard) => {
    setLoading(true);
    const targetColumnIdx = getColumnIndex(card.size);
    const columns: ColumnsData = getColumnsData(lgColumn, smColumn);
    const order = columns[targetColumnIdx].length;
    const newCardParams = {
      ...card,
      userId,
      type: DashboardCardType.EMBED,
    };

    let newCard: DashboardCard;
    try {
      const newCardData = await createDashboardCard({
        variables: { input: { ...newCardParams } },
      });
      newCard = R.path(['data', 'dashboardCardCreate'], newCardData);
      columns[targetColumnIdx] = [...columns[targetColumnIdx], newCard.id];
      await updateLayout(columns);
      setShowCustomize(false);
      setAllCards(prevAllCards => [
        ...prevAllCards,
        {
          ...newCard,
          order,
          contentState: DashboardCardContentState.LOADING,
          ...CARD_CONFIG[DashboardCardType.EMBED],
        },
      ]);
      setLoading(false);
    } catch (error) {
      displayError();
    }
  };

  const handleDeleteEmbedCard = async (card: DashboardCard) => {
    setLoading(true);
    const targetColumnIdx = getColumnIndex(card.size);
    const columns: ColumnsData = getColumnsData(lgColumn, smColumn);

    try {
      await deleteDashboardCard({
        variables: { input: { userId, id: card.id } },
      });
      columns[targetColumnIdx] = R.without([card.id], columns[targetColumnIdx]);
      await updateLayout(columns);
      setAllCards(prevAllCards => R.without([card], prevAllCards));
      setLoading(false);
    } catch (error) {
      displayError();
    }
  };

  const handleEditEmbedCard = async (newCardFields: DashboardCard) => {
    setLoading(true);
    const cardId = newCardFields.id;
    const cardToUpdate: DashboardCard = allCards.find(
      card => card.id === cardId,
    );
    const columnSwap: boolean = cardToUpdate.size !== newCardFields.size;
    const columns: ColumnsData = getColumnsData(lgColumn, smColumn);
    const nameChangeOnly: boolean =
      cardToUpdate.size === newCardFields.size &&
      cardToUpdate.embedLink === newCardFields.embedLink &&
      cardToUpdate.title !== newCardFields.title;
    let newOrder: number = cardToUpdate.order;

    try {
      await updateDashboardCard({
        variables: {
          input: {
            userId,
            id: cardId,
            title: newCardFields.title,
            size: newCardFields.size,
            embedLink: newCardFields.embedLink,
          },
        },
      });

      if (columnSwap) {
        const targetColumnIdx = getColumnIndex(newCardFields.size);
        const sourceColumnIdx = getColumnIndex(cardToUpdate.size);

        columns[targetColumnIdx] = [...columns[targetColumnIdx], cardId];
        columns[sourceColumnIdx] = R.without(
          [cardId],
          columns[targetColumnIdx],
        );
        newOrder = columns[targetColumnIdx].length;
        await updateLayout(columns);
      }

      const updatedCards: DashboardCard[] = updateAtId(
        cardId,
        R.evolve({
          title: () => newCardFields.title,
          embedLink: () => newCardFields.embedLink,
          size: () => newCardFields.size,
          contentState: () =>
            nameChangeOnly
              ? DashboardCardContentState.READY
              : DashboardCardContentState.LOADING,
          order: () => newOrder,
        }),
      )(allCards) as DashboardCard[];
      setAllCards(updatedCards);
      setLoading(false);
    } catch (error) {
      displayError();
    }
  };

  const loadingEmbedCard =
    creatingCard || deletingCard || updatingCard || loading;

  const contextValue = {
    emptyBoard,
    allCards,
    setAllCards,
    updateCardState,
    updateAllCardsState,
    setShowCustomize,
    lgColumn,
    smColumn,
    updateLayout,
    updatingLayout,
    someCardIsLoading,
    handleAddEmbedCard,
    handleDeleteEmbedCard,
    handleEditEmbedCard,
    loadingEmbedCard,
  };

  const dashboardBody = loadingInitData ? (
    <Progress style={{ height: '80vh' }} />
  ) : emptyBoard ? (
    <DashboardEmptyState />
  ) : (
    <DashboardGrid />
  );

  return (
    <DashboardContext.Provider value={contextValue}>
      {loadingEmbedCard && (
        <Progress
          style={{
            height: '100vh',
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            opacity: 0.5,
            background: colors.neutral.ash,
            zIndex: 1000,
          }}
        />
      )}
      <div styleName="portal-container">
        <DashboardTitleBar />
        <div styleName="dashboard-hello">Hello, {firstName}</div>
        {dashboardBody}
      </div>
      <CustomizeDashboardModal
        open={showCustomize}
        onClose={() => setShowCustomize(false)}
      />
    </DashboardContext.Provider>
  );
};

export default DashboardPortal;
