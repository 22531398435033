import React from 'react';

import { DatePicker } from '@atom/mui';

import './workTemplateTaskDetail.css';

const TaskStartDate = () => {
  return (
    <div styleName="time-row-item input">
      <div styleName="label">Start Date</div>
      <div styleName="time-picker-container">
        <DatePicker
          value={null}
          onChange={() => {}}
          format="MM/dd/yyyy"
          disabled
        />
      </div>
    </div>
  );
};

export default TaskStartDate;
