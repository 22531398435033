import React from 'react';

import { MediaItem } from '@atom/types/media';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';

import './workOrderDetailPdfReport.css';

interface Props {
  image: MediaItem;
  imageWidth: string;
  imageHeight: string;
  loading: (loading: boolean) => void;
}

const MainPhotoTile = ({ image, imageWidth, imageHeight, loading }: Props) => {
  return (
    <div
      style={{
        width: imageWidth,
        height: imageHeight,
        backgroundImage: `url('${FILES_ENDPOINT}/${image.fileId}')`,
      }}
      styleName="main-photo-container"
      onLoad={() => loading(false)}
    />
  );
};

export default MainPhotoTile;
