import React from 'react';

import { CsvErrorItem } from '@atom/types/error';

import './inventoryUpload.css';

interface Props {
  error: CsvErrorItem;
}

const lowercaseFirstLetter = (str: string) =>
  str.charAt(0).toLowerCase() + str.slice(1);

const InventoryUploadError = ({ error }: Props) => {
  return (
    <div styleName="error-message">
      <div styleName="error-bullet" />
      {error.row > 0
        ? `Row ${error.row} Column "${error.column}" ${lowercaseFirstLetter(
            error.message,
          )}`
        : error.message}
    </div>
  );
};

export default InventoryUploadError;
