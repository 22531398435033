import React, { useContext } from 'react';
import * as R from 'ramda';

import TeamContext, {
  Section,
  View,
} from '@atom/components/teamPortal/TeamContext';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Icon, IconButton } from '@atom/mui';
import { getColorFromColorId } from '@atom/utilities/colorUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import UserGroupTree from './userGroupTree/UserGroupTree';

import './teamSidebar.css';

const TeamSidebar = () => {
  const preferences = usePreferences();
  const {
    activeView,
    setActiveView,
    activeTimeSheetGroup,
    selectedTimeSheetUser,
    setActiveTimeSheetGroup,
    setSelectedTimeSheetUser,
    timeEntryApproverUserGroups,
    userLoading,
    currentUserHasUserGroups,
    expanded,
    toggleExpanded,
  } = useContext(TeamContext);

  const getStyleName = (view: View) => {
    if (view === View.TIME_SHEET && !R.isNil(selectedTimeSheetUser)) {
      return 'sidebar-row';
    }

    return view === activeView ? 'sidebar-row selected' : 'sidebar-row';
  };

  const handleGroupClick = (userGroup: any) => {
    setActiveView(View.TIME_SHEET_LIST);
    setActiveTimeSheetGroup(userGroup);
  };

  const handleMyTimeSheetClick = async () => {
    await setSelectedTimeSheetUser(null);
    await setActiveTimeSheetGroup(null);
    setActiveView(View.TIME_SHEET);
  };

  const peopleExpandedIcon = expanded.has(Section.PEOPLE)
    ? 'expand_less'
    : 'expand_more';

  const timeExpandedIcon = expanded.has(Section.TIME_SHEET)
    ? 'expand_less'
    : 'expand_more';

  const showPeopleManagement = expanded.has(Section.PEOPLE);
  const showTeamTimeSheet =
    !isNilOrEmpty(timeEntryApproverUserGroups) &&
    !userLoading &&
    expanded.has(Section.TIME_SHEET);
  const showMyTimeSheet =
    currentUserHasUserGroups && expanded.has(Section.TIME_SHEET);
  const showTimeSheetSection =
    preferences?.timeTracking?.timesheet &&
    ((!isNilOrEmpty(timeEntryApproverUserGroups) && !userLoading) ||
      currentUserHasUserGroups);

  return (
    <div styleName="sidebar-container">
      <UserGroupTree />
      <div styleName="sidebar-section">
        <div styleName="section-header">
          <div styleName="section-title">People Management</div>
          <IconButton onClick={() => toggleExpanded(Section.PEOPLE)}>
            <Icon>{peopleExpandedIcon}</Icon>
          </IconButton>
        </div>
        {showPeopleManagement && (
          <>
            <div
              styleName={getStyleName(View.ACTIVE)}
              onClick={() => setActiveView(View.ACTIVE)}
            >
              <Icon>contacts</Icon>
              <div styleName="row-title" data-cy="activeUsers">
                Active Users
              </div>
            </div>
            <div
              styleName={getStyleName(View.PENDING)}
              onClick={() => setActiveView(View.PENDING)}
            >
              <Icon>person_add</Icon>
              <div styleName="row-title" data-cy="pendingRequests">
                Pending Requests
              </div>
            </div>
            <div
              styleName={getStyleName(View.DEACTIVATED)}
              onClick={() => setActiveView(View.DEACTIVATED)}
            >
              <Icon>not_interested</Icon>
              <div styleName="row-title" data-cy="deactivatedUsers">
                Deactivated Users
              </div>
            </div>
            <div
              styleName={getStyleName(View.ROLES)}
              onClick={() => setActiveView(View.ROLES)}
            >
              <Icon>account_circle</Icon>
              <div styleName="row-title" data-cy="roles">
                Roles
              </div>
            </div>
          </>
        )}
      </div>
      {showTimeSheetSection && (
        <div styleName="sidebar-section">
          <div styleName="section-header">
            <div styleName="section-title">Time Management</div>
            <IconButton
              onClick={() => toggleExpanded(Section.TIME_SHEET)}
              data-cy="timeManagementSectionExpandCollapseButton"
            >
              <Icon>{timeExpandedIcon}</Icon>
            </IconButton>
          </div>
          {showMyTimeSheet && (
            <div
              styleName={getStyleName(View.TIME_SHEET)}
              onClick={handleMyTimeSheetClick}
            >
              <Icon>schedule</Icon>
              <div styleName="row-title" data-cy="myTimesheet">
                My Timesheet
              </div>
            </div>
          )}
          {showTeamTimeSheet && (
            <>
              <div styleName="sidebar-row">
                <Icon>group</Icon>
                <div styleName="row-title" data-cy="teamTimesheet">
                  Team Timesheet
                </div>
              </div>
              {timeEntryApproverUserGroups.map(userGroup => {
                const color = getColorFromColorId(userGroup.colorId);
                const styleName =
                  (activeView === View.TIME_SHEET_LIST ||
                    activeView === View.TIME_SHEET) &&
                  activeTimeSheetGroup?.id === userGroup.id
                    ? 'sidebar-row-time-group selected sub'
                    : 'sidebar-row-time-group sub';

                return (
                  <div
                    key={userGroup.id}
                    styleName={styleName}
                    onClick={() => handleGroupClick(userGroup)}
                    data-cy={userGroup.id}
                  >
                    <Icon color={color}>lens</Icon>
                    <div styleName="row-title">
                      {userGroup.groupPath.join(' / ')}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TeamSidebar;
