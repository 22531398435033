import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import { defaultClusterDensitySelector } from '@atom/selectors/mapSelectors';
import { ClusterDensity } from '@atom/types/preferences';
import workOrderStatusUtilities from '@atom/utilities/workOrderStatusUtilities';

import baseReducer from './reducer';

const STORAGE_KEY = 'layers';

const persistState = R.pipe(
  R.evolve({
    assetSchemas: Array.from,
    workStatusIds: Array.from,
    workTemplateIds: Array.from,
    workTemplateFolderIds: Array.from,
    kmlLayerIds: Array.from,
  }),
  JSON.stringify,
  value => localStorage.setItem(STORAGE_KEY, value),
);

const getInitialState = state => {
  const existing = localStorage.getItem(STORAGE_KEY);
  if (existing) {
    return R.evolve({
      assetSchemas: value => new Set(value),
      workStatusIds: value => new Set(value),
      kmlLayerIds: value => new Set(value),
      workTemplateIds: value => new Set(value),
      workTemplateFolderIds: value => new Set(value),
    })({ ...state, ...JSON.parse(existing) });
  }
  return state;
};

window.addEventListener('onbeforeunload', () => {
  localStorage.removeItem(STORAGE_KEY);
});

export interface Layers {
  assets: boolean;
  users: boolean;
  work: boolean;
  kmlLayers: boolean;
  assetSchemas: Set<string>;
  workStatusIds: Set<string>;
  kmlLayerIds: Set<string>;
  clusterDensity: string;
  workTemplateIds?: Set<string>;
  workTemplateFolderIds?: Set<string>;
}

export const INITIAL_LAYERS: Layers = {
  assets: true,
  users: true,
  work: true,
  assetSchemas: new Set([]),
  workStatusIds: new Set([
    workOrderStatusUtilities.REQUESTED_STATUS_ID,
    workOrderStatusUtilities.UNASSIGNED_STATUS_ID,
    workOrderStatusUtilities.ASSIGNED_STATUS_ID,
    workOrderStatusUtilities.IN_PROGRESS_STATUS_ID,
    workOrderStatusUtilities.COMPLETED_STATUS_ID,
    workOrderStatusUtilities.IN_REVIEW_STATUS_ID,
  ]),
  workTemplateIds: new Set([]),
  workTemplateFolderIds: new Set([]),
  kmlLayers: true,
  kmlLayerIds: new Set([]),
  clusterDensity: ClusterDensity.default,
};

const reducer = baseReducer(INITIAL_LAYERS);

const ValueContext = createContext(INITIAL_LAYERS);
const UpdaterContext = createContext(reducer);

const ValueProvider = ValueContext.Provider;
const UpdaterProvider = UpdaterContext.Provider;

export const useLayersValue = () => useContext<Layers>(ValueContext);
export const useLayersUpdater = () => useContext(UpdaterContext);

export const withLayers = WrappedComponent => {
  const Layers = props => {
    const clusterDensity = useSelector(defaultClusterDensitySelector);

    // @ts-ignore
    const [layers, setLayers] = useReducer(reducer, {
      ...getInitialState(INITIAL_LAYERS),
      clusterDensity,
    });

    useEffect(() => {
      persistState(layers);
    }, [layers]);

    return (
      <ValueProvider value={layers}>
        <UpdaterProvider value={setLayers}>
          <WrappedComponent {...props} />
        </UpdaterProvider>
      </ValueProvider>
    );
  };

  Layers.getInitialProps = ctx => {
    if (WrappedComponent.getInitialProps) {
      WrappedComponent.getInitialProps(ctx);
    }
  };

  return Layers;
};
