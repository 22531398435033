import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import LoadMoreButton from '@atom/components/common/loadMoreButton/LoadMoreButton';
import UserGroupIcon from '@atom/components/common/UserGroupIcon';
import UserThumbnail from '@atom/components/common/UserThumbnail';
import client from '@atom/graph/client';
import { ADD_ROLE_ASSIGNEES } from '@atom/graph/role';
import { GET_USERS } from '@atom/graph/user';
import { GET_USER_GROUP_TREE, GET_USER_GROUPS } from '@atom/graph/userGroup';
import { Checkbox, Icon, List, Modal, Progress, TextField } from '@atom/mui';
import {
  RoleAssigneesAddInput,
  RoleAssigneesConnectionInput,
} from '@atom/types/role';
import {
  UserDetail,
  UsersConnection,
  UsersConnectionInput,
} from '@atom/types/user';
import {
  UserGroup,
  UserGroupsConnection,
  UserGroupsConnectionInput,
  UserGroupTreeType,
} from '@atom/types/userGroups';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { combineSets, toggleFromSet } from '@atom/utilities/setUtilities';
import { getUserFullName } from '@atom/utilities/userUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import RoleContext from '../RoleContext';

import UserGroupTree from './UserGroupTree';

import './roleAssignees.css';

const { ListItem, ListItemText } = List;

interface Props {
  open: boolean;
  onClose: () => void;
}

const styles = {
  modal: {
    height: '50vh',
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
  },
  progress: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  close: {
    cursor: 'pointer',
  },
  avatar: {
    marginRight: '1rem',
  },
  button: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '0.5rem',
  },
};

const SEARCH_THRESHOLD = 3;
const DEBOUNCE_TIME = 500;

const AddAssigneesModal = ({ open, onClose }: Props) => {
  const { role, assigneesInput } = useContext(RoleContext);

  const [search, setSearch] = useState<string>('');

  const [userGroupTree, setUserGroupTree] = useState<UserGroupTreeType>(null);
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
  const [loadingUserGroups, setLoadingUserGroups] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);
  const [users, setUsers] = useState<UserDetail[]>([]);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [totalUsers, setTotalUsers] = useState<number>(0);

  const [selectedUserGroups, setSelectedUserGroups] = useState<Set<string>>(
    new Set(),
  );
  const [selectedUsers, setSelectedUsers] = useState<Set<string>>(new Set());

  const resetState = () => {
    setSearch('');
    setUserGroupTree(null);
    setUserGroups([]);
    setLoadingUserGroups(false);
    setPage(1);
    setUsers([]);
    setLoadingUsers(false);
    setTotalUsers(0);
    setSelectedUserGroups(new Set());
    setSelectedUsers(new Set());
  };

  const [addAssignees, { loading: loadingAddAssignees }] = useMutation<
    {},
    {
      input: RoleAssigneesAddInput;
      assigneesInput: RoleAssigneesConnectionInput;
    }
  >(ADD_ROLE_ASSIGNEES);

  const [getUserGroupTree, { loading: loadingUserGroupTree }] = useLazyQuery<{
    userGroupsTree: UserGroupTreeType;
  }>(GET_USER_GROUP_TREE, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: res => setUserGroupTree(res.userGroupsTree),
  });

  const getUsers = useCallback(async (input: UsersConnectionInput) => {
    setLoadingUsers(true);

    const { data } = await client.query<
      { users: UsersConnection },
      { input: UsersConnectionInput }
    >({
      query: GET_USERS,
      fetchPolicy: 'network-only',
      variables: { input },
    });

    setUsers(current => [...current, ...data.users.users]);
    setTotalUsers(data.users.totalCount);
    setLoadingUsers(false);
  }, []);

  const getMoreUsers = () => {
    getUsers({
      page,
      limit: 25,
      showAdmin: hasRolePermissions(ROLE_SETS.ADMIN),
    });

    setPage(R.inc);
  };

  const getUserGroups = useCallback(
    async (input: UserGroupsConnectionInput) => {
      setLoadingUserGroups(true);

      const { data } = await client.query<
        { userGroups: UserGroupsConnection },
        { input: UserGroupsConnectionInput }
      >({
        query: GET_USER_GROUPS,
        fetchPolicy: 'network-only',
        variables: { input },
      });

      setUserGroups(data.userGroups.userGroups);
      setLoadingUserGroups(false);
    },
    [],
  );

  const getInitialUsersAndGroups = async () => {
    await Promise.all([getUserGroupTree(), getMoreUsers()]);
  };

  useEffect(() => {
    if (open) {
      getInitialUsersAndGroups();
    }
  }, [open]);

  const debouncedSearch = useCallback(
    debounce((name: string) => {
      getUsers({ name, showAdmin: hasRolePermissions(ROLE_SETS.ADMIN) });
      getUserGroups({ name });
    }, DEBOUNCE_TIME),
    [],
  );

  const assignedUserGroups = useMemo(() => {
    return new Set(
      role.assignees.assignees
        .filter(assignee => assignee.type === 'USER_GROUP')
        .map(userGroup => userGroup.id),
    );
  }, [role.assignees]);

  const assignedUsers = useMemo(() => {
    return new Set(
      role.assignees.assignees
        .filter(assignee => assignee.type === 'USER')
        .map(user => user.id),
    );
  }, [role.assignees]);

  const handleSearch = (value: string) => {
    setSearch(value);
    setUsers([]);
    setUserGroups([]);
    setPage(1);
    setTotalUsers(0);

    if (!value) {
      debouncedSearch.cancel();
      getInitialUsersAndGroups();
    } else if (value.length >= SEARCH_THRESHOLD) {
      debouncedSearch(value);
    } else {
      debouncedSearch.cancel();
    }
  };

  const handleSubmit = async () => {
    await addAssignees({
      variables: {
        assigneesInput,
        // @ts-ignore
        input: R.omit<RoleAssigneesAddInput>(isNilOrEmpty, {
          id: role.id,
          userIds: [...selectedUsers],
          userGroupIds: [...selectedUserGroups],
        }),
      },
    });

    onClose();
  };

  const isSearch = !!search.length;
  const haveUsers = !!users.length;
  const haveGroups = !!userGroups.length;
  const loading = isSearch
    ? loadingUsers || loadingUserGroups
    : (loadingUsers && !users.length) || loadingUserGroupTree;

  return (
    <Modal
      title="Assign To"
      open={open}
      onCancel={onClose}
      onConfirm={handleSubmit}
      contentStyle={styles.modal}
      loading={loadingAddAssignees}
      disabled={!selectedUsers.size && !selectedUserGroups.size}
      onExited={resetState}
    >
      <TextField
        name="search"
        value={search}
        onChange={event => handleSearch(event.target.value)}
        placeholder="Search by user's name or group name"
        InputProps={{
          startAdornment: <Icon>search</Icon>,
          endAdornment: search && (
            <Icon style={styles.close} onClick={() => handleSearch('')}>
              close
            </Icon>
          ),
        }}
      />
      {loading ? (
        <div style={styles.progress}>
          <Progress />
        </div>
      ) : (
        <div>
          {!isSearch && (
            <UserGroupTree
              userGroupTree={userGroupTree}
              selected={combineSets(selectedUserGroups, assignedUserGroups)}
              onToggle={id =>
                setSelectedUserGroups(toggleFromSet(selectedUserGroups, id))
              }
              disabled={assignedUserGroups}
            />
          )}
          {isSearch && haveUsers && <div styleName="grouping-label">Users</div>}
          {users.map(user => (
            <ListItem key={user.id} disableGutters>
              <Checkbox
                checked={combineSets(selectedUsers, assignedUsers).has(user.id)}
                disabled={assignedUsers.has(user.id)}
                onChange={() =>
                  setSelectedUsers(toggleFromSet(selectedUsers, user.id))
                }
              />
              <div style={styles.avatar}>
                <UserThumbnail image={user.photoUrl} />
              </div>
              <ListItemText
                primary={getUserFullName(user)}
                secondary={user.email}
              />
            </ListItem>
          ))}
          {isSearch && haveGroups && (
            <>
              <div styleName="grouping-label">Groups</div>
              {userGroups.map(userGroup => (
                <ListItem key={userGroup.id} disableGutters>
                  <Checkbox
                    checked={combineSets(
                      selectedUserGroups,
                      assignedUserGroups,
                    ).has(userGroup.id)}
                    disabled={assignedUserGroups.has(userGroup.id)}
                    onChange={() =>
                      setSelectedUsers(
                        toggleFromSet(selectedUserGroups, userGroup.id),
                      )
                    }
                  />
                  <div style={styles.avatar}>
                    <UserGroupIcon colorId={userGroup.colorId} />
                  </div>
                  <ListItemText primary={userGroup.name} />
                </ListItem>
              ))}
            </>
          )}
          {users.length < totalUsers && (
            <LoadMoreButton
              style={styles.button}
              loading={loadingUsers}
              onClick={getMoreUsers}
              error={false}
            />
          )}
        </div>
      )}
    </Modal>
  );
};

export default AddAssigneesModal;
