import React, { useState } from 'react';

// @ts-ignore
import icon from '@atom/images/location-link.svg';
import { IconButton, List, Popover } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { LocationLinkOption } from '@atom/types/map';

import './locationLinkControls.css';

const { ListItemButton } = List;

export interface Props {
  locationLinkOptions: LocationLinkOption[];
  disabled?: boolean;
}

const styles = {
  listItem: {
    fontSize: fonts.md,
    color: colors.neutral.dark,
    fontWeight: 500,
  },
};

const LocationLinkControls = ({
  locationLinkOptions = [],
  disabled,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = useState<boolean>(false);

  const handleToggle = event => {
    if (!disabled) {
      setOpen(!open);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClosePopover = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleClick = ({ onClick }: LocationLinkOption) => {
    onClick();
    handleClosePopover();
  };

  return (
    <div styleName={`container ${disabled ? 'disabled' : ''}`}>
      <IconButton
        disabled={disabled}
        tooltip="Location Sync"
        onClick={handleToggle}
      >
        <img styleName="icon" src={icon} />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onClose={handleClosePopover}
      >
        <List style={styles.listItem}>
          {locationLinkOptions.map(option => {
            return (
              <ListItemButton
                key={option.text}
                onClick={() => handleClick(option)}
              >
                {option.text}
              </ListItemButton>
            );
          })}
        </List>
      </Popover>
    </div>
  );
};

export default LocationLinkControls;
