import * as React from 'react';

import UserThumbnail from '@atom/components/common/UserThumbnail';
import { UserDetail } from '@atom/types/user';
import userUtilities from '@atom/utilities/userUtilities';

import './workSummaryTray.css';

interface Props {
  user: UserDetail;
}

const UserRow = ({ user }: Props) => {
  return (
    <div styleName="user-row-container">
      <div styleName="user-thumbnail-container">
        <UserThumbnail
          image={user.photoUrl}
          alt={`${user.firstName} ${user.lastName}`}
        />
      </div>
      <div styleName="user-detail-content">
        <div styleName="user-name-container">
          {userUtilities.getUserFullName(user)}
        </div>
        <div styleName="user-email-container">{user.email}</div>
      </div>
    </div>
  );
};

export default UserRow;
