import React from 'react';
import { Link } from 'react-router-dom';

import { Icon, IconButton } from '@atom/mui';
import { AssetDetailView } from '@atom/types/inventory';
import { TaskMaterialItem } from '@atom/types/task';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';

import './materialModal.css';

export interface Props {
  material: TaskMaterialItem;
  totalRemaining: number;
}

const MaterialModalTitle = ({ material, totalRemaining }: Props) => {
  const convertNumberToLocaleString = (input: string | number): string => {
    return Number(input).toLocaleString('en-US');
  };

  return (
    <div styleName="material-header">
      <div>
        <div styleName="material-title">{material?.name}</div>
        <div styleName="material-subtext">
          <span>{numberToLocaleString(material?.rate)}</span>
          <span>/</span>
          <span styleName="material-unit">{material?.unit}</span>
          {material.isStockBased && (
            <>
              <span> - </span>
              <span>{`${convertNumberToLocaleString(totalRemaining)} `}</span>
              <span styleName="material-unit">{`${material?.unit} `}</span>
              <span>Remaining</span>
            </>
          )}
        </div>
      </div>
      <Link
        to={`/inventory/${material?.assetId}?activeView=${AssetDetailView.COST}`}
      >
        <IconButton>
          <Icon>launch</Icon>
        </IconButton>
      </Link>
    </div>
  );
};

export default MaterialModalTitle;
