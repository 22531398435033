import React from 'react';

import { TextField } from '@atom/mui';

import './locationTab.css';

const styles = {
  input: {
    padding: 0,
  },
};

interface Props {
  coordinatesInput: string;
  handleCoordinatesChange: (coordinates: string) => void;
}

const CoordinatesInput = ({
  coordinatesInput,
  handleCoordinatesChange,
}: Props) => {
  return (
    <TextField
      fullWidth
      placeholder="Enter coordinates"
      value={coordinatesInput}
      onChange={event => handleCoordinatesChange(event.target.value)}
      style={styles.input}
      disableUnderline
    />
  );
};

export default CoordinatesInput;
