import React from 'react';
import * as R from 'ramda';

import { Task } from '@atom/types/task';

import CustomFieldRow from './CustomFieldRow';
import { getMappedCustomFields } from './reportUtilities';

import './workOrderReport.css';

interface Props {
  task: Task;
  isCompletedOrAbove: boolean;
}

const CustomFieldsSection = ({ task, isCompletedOrAbove }: Props) => {
  const mappedCustomFields = getMappedCustomFields(task, isCompletedOrAbove);
  const customFieldRows = R.splitEvery(3, mappedCustomFields);

  return (
    <div styleName="custom-field-section">
      {customFieldRows.map((fields, index) => (
        <CustomFieldRow key={index} fields={fields} />
      ))}
    </div>
  );
};

export default CustomFieldsSection;
