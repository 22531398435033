import React from 'react';
import {
  Tooltip as MUITooltip,
  TooltipProps as MUITooltipProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import colors from '@atom/styles/colors';

type CustomProps = {
  /**
   * lightTooltip will change basic styles for tooltip to
   * be a white background card
   */
  lightTooltip?: boolean;
};

export type Props = CustomProps & MUITooltipProps;

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: colors.neutral.white,
    color: colors.neutral.black,
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
    fontSize: 11,
    fontWeight: 'normal',
  },
});

export const Tooltip = React.forwardRef((props: Props, ref: React.Ref<any>) => {
  const classes = useStyles();
  const { children, lightTooltip, ...data } = props;

  return (
    <MUITooltip
      {...data}
      classes={{
        ...(lightTooltip && { tooltip: classes.tooltip }),
        ...props?.classes,
      }}
      ref={ref}
    >
      {children}
    </MUITooltip>
  );
});
