import React from 'react';

import { Icon } from '@atom/mui';

import './field.css';

export interface Props {
  icon?: string;
  iconColor?: string;
  label?: string;
  input: React.ReactNode;
}

const Field = ({ icon, iconColor, label, input }: Props) => (
  <div styleName="field">
    <div styleName="field-icon">
      {icon && <Icon color={iconColor}>{icon}</Icon>}
    </div>
    <div styleName="field-content">
      {label && <div styleName="field-title">{label}</div>}
      <div styleName="field-component">{input}</div>
    </div>
  </div>
);

export default Field;
