import { createContext } from 'react';

import { PoliciesConnectionInput } from '@atom/types/policy';
import { RoleAssigneesConnectionInput, RoleDetail } from '@atom/types/role';

export enum View {
  POLICIES,
  ASSIGNEES,
}

export interface Context {
  view: View;
  setView: (view: View) => void;
  role: RoleDetail;
  loading: boolean;
  refetch: () => Promise<any>;
  assigneesInput: RoleAssigneesConnectionInput;
  setAssigneesInput: (input: RoleAssigneesConnectionInput) => void;
  policiesInput: PoliciesConnectionInput;
  setPoliciesInput: (input: PoliciesConnectionInput) => void;
}

const RoleContext = createContext<Context>({
  view: View.ASSIGNEES,
  setView: () => {},
  role: null,
  loading: false,
  refetch: () => Promise.resolve(),
  assigneesInput: {},
  setAssigneesInput: () => {},
  policiesInput: {},
  setPoliciesInput: () => {},
});

export default RoleContext;
