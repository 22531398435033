import { gql } from '@apollo/client';

export const BASE_FAVORITE_LIST_FRAGMENT = gql`
  fragment BaseFavoritesList on FavoritesList {
    id
    name
    subjectType
    favorites {
      id
      subjectId
      subjectType
      subjectName
      listIds
      data {
        colorId
        assetType
        categories {
          id
          name
        }
        photoUrl
        email
        firstName
        lastName
        markerId
        schemaId
        isMaterial
        inactive
      }
      createdDate
      createdBy {
        firstName
        lastName
      }
    }
    createdDate
    createdBy {
      firstName
      lastName
    }
  }
`;

export const GET_FAVORITES_LIST_TREE = gql`
  query favoritesListTree($input: FavoritesListsTreeConnectionInput!) {
    favoritesListTree(input: $input) {
      totalCount
      totalUniqueFavorites
      favoritesLists {
        ...BaseFavoritesList
      }
    }
  }
  ${BASE_FAVORITE_LIST_FRAGMENT}
`;

export const CREATE_FAVORITES_LIST = gql`
  mutation favoritesListCreate($input: FavoritesListCreateInput!) {
    favoritesListCreate(input: $input) {
      ...BaseFavoritesList
    }
  }
  ${BASE_FAVORITE_LIST_FRAGMENT}
`;

export const DELETE_FAVORITES_LIST = gql`
  mutation favoritesListDelete($input: FavoritesListDeleteInput!) {
    favoritesListDelete(input: $input)
  }
`;

export const UPDATE_FAVORITES_LIST = gql`
  mutation favoritesListUpdate($input: FavoritesListUpdateInput!) {
    favoritesListUpdate(input: $input) {
      ...BaseFavoritesList
    }
  }
  ${BASE_FAVORITE_LIST_FRAGMENT}
`;
