import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { BooleanParam, useQueryParams } from 'use-query-params';

import { retrieveInventorySchemas } from '@atom/actions/inventorySchemaActions';
import {
  useGoogleMapsStateValue,
  withGoogleMapsState,
} from '@atom/components/common/map/hooks/googleMapsStateHook';
import api from '@atom/utilities/api';
import { USERS_ENDPOINT } from '@atom/utilities/endpoints';
import {
  getCurrentUserLocation,
  getGeoJSONLocation,
} from '@atom/utilities/mapUtilities';

import { withKmlLayers } from './hooks/kmlHook';
import { withLayers } from './hooks/layersHook';
import { withListView } from './hooks/listViewHook';
import MapContainer from './MapContainer';
import MapDrawerContent from './MapDrawerContent';
import MapPortalSideNavigation from './MapPortalSideNavigation';

import './mapPortal.css';

const MapPortal = () => {
  const dispatch = useDispatch();

  const { isLoaded } = useGoogleMapsStateValue();

  const userId = useSelector(R.pathOr('', ['userProfile', 'userId']));

  useEffect(() => {
    const updateUserLocation = async () => {
      const location = await getCurrentUserLocation();

      if (location && userId) {
        api.patch(`${USERS_ENDPOINT}/${userId}`, {
          location: getGeoJSONLocation(location),
        });
      }
    };

    updateUserLocation();
  }, [userId]);

  useEffect(() => {
    const setSchemas = async () => {
      await dispatch(retrieveInventorySchemas({ rootSchemas: true }));
    };
    setSchemas();
  }, []);

  const [query] = useQueryParams({
    collapsed: BooleanParam,
  });

  return (
    <div styleName="container">
      <div styleName="title">Map</div>
      <div styleName="dashboard-container">
        <div styleName="side-nav-container">
          <MapPortalSideNavigation />
        </div>
        <MapDrawerContent />
        {isLoaded && (
          <div styleName={`map-container ${query.collapsed ? '' : 'expanded'}`}>
            <MapContainer />
          </div>
        )}
      </div>
    </div>
  );
};

export default R.compose(
  withGoogleMapsState,
  withLayers,
  withListView,
  withKmlLayers,
)(MapPortal);
