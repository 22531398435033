import * as R from 'ramda';

import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function mediaFolderTreeReducer(
  state = initialState.mediaFolderTree,
  action,
) {
  const { data, type } = action;
  switch (type) {
    case actionTypes.GET_MEDIA_FOLDER_TREE_SUCCESS: {
      return {
        ...data,
        expanded: true,
      };
    }
    case actionTypes.UPDATE_MEDIA_FOLDER_TREE_SUCCESS: {
      const { folderPath, expanded } = data;
      const lensPath = R.lensPath(folderPath);

      return R.over(
        lensPath,
        folder => ({
          ...folder,
          expanded,
        }),
        state,
      );
    }
    case actionTypes.CREATE_MEDIA_FOLDER_SUCCESS: {
      const { folderData, folderPath } = data;
      const lensPath = R.lensPath(folderPath);

      return R.over(
        lensPath,
        folder => ({
          ...folder,
          children: [
            {
              id: folderData.id,
              name: folderData.name,
              ancestors: folderData.ancestors,
            },
            ...R.pathOr([], ['children'], folder),
          ],
        }),
        state,
      );
    }
    case actionTypes.UPDATE_MEDIA_FOLDER_SUCCESS: {
      const { folderData, folderPath } = data;
      const lensPath = R.lensPath(folderPath);

      return R.over(
        lensPath,
        folder => ({
          ...folder,
          name: folderData.name,
        }),
        state,
      );
    }
    case actionTypes.DELETE_MEDIA_FOLDER_SUCCESS: {
      const { folderId, folderPath } = data;
      const path = R.dropLast(1, folderPath);
      // @ts-ignore
      const lensPath = R.lensPath(path);

      return R.over(
        lensPath,
        children => children.filter(folder => folder.id !== folderId),
        state,
      );
    }
    default: {
      return state;
    }
  }
}
