import React from 'react';

import DescriptionSection from './DescriptionSection';
import DetailsSection from './DetailsSection';

import './userGroupInfo.css';

const UserGroupInfo = () => {
  return (
    <>
      <DescriptionSection />
      <DetailsSection />
    </>
  );
};

export default UserGroupInfo;
