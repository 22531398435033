import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';

import UserGroupIcon from '@atom/components/common/UserGroupIcon';
import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { GET_USER_GROUP } from '@atom/graph/userGroup';
import {
  WORK_TEMPLATE_TASK_USER_GROUP_DELETE,
  WORK_TEMPLATE_TASK_USER_GROUP_UPDATE,
} from '@atom/graph/workTemplate';
import { Button, Icon, IconButton, Modal, Select, TextField } from '@atom/mui';
import { UserGroup } from '@atom/types/userGroups';
import {
  InheritedComponentType,
  WorkOrderTemplateUserGroupDeleteInput,
  WorkOrderTemplateUserGroupUpdateInput,
  WorkTemplateTaskItem,
  WorkTemplateTaskUserGroup,
} from '@atom/types/workTemplate';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import {
  convertHoursToMillis,
  convertMillisToExactHours,
} from '@atom/utilities/timeUtilities';
import { isComponentInherited } from '@atom/utilities/workTemplateUtilities';

import './teamSection.css';

const MenuItem = Select.MenuItem;

const styles = {
  textFieldStyle: {
    width: '10rem',
    marginLeft: '1rem',
  },
  selectStyle: {
    width: '75%',
    marginLeft: '2rem',
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
  taskUserGroup: WorkTemplateTaskUserGroup;
}

const EditTaskUserGroupModal = ({ open, onClose, taskUserGroup }: Props) => {
  const { workTemplate, task, refetch } = useContext(WorkTemplateContext);
  const [hours, setHours] = useState<number>(() =>
    convertMillisToExactHours(taskUserGroup.workTime),
  );
  const [quantity, setQuantity] = useState<number>(taskUserGroup.quantity || 0);
  const [selectedBudgetId, setSelectedBudgetId] = useState<string>(
    taskUserGroup?.budget?.budgetId || '',
  );

  const [getUserGroup, { data, error, loading }] = useLazyQuery<{
    userGroup: UserGroup;
  }>(GET_USER_GROUP, {
    fetchPolicy: 'network-only',
  });

  const [taskUserGroupUpdate] = useMutation<
    { workOrderTemplateUserGroupUpdate: WorkTemplateTaskItem },
    { input: WorkOrderTemplateUserGroupUpdateInput }
  >(WORK_TEMPLATE_TASK_USER_GROUP_UPDATE, {
    onCompleted: () => {
      refetch();
    },
  });

  const [taskUserGroupRemove] = useMutation<
    { workOrderTemplateUserGroupDelete: boolean },
    { input: WorkOrderTemplateUserGroupDeleteInput }
  >(WORK_TEMPLATE_TASK_USER_GROUP_DELETE, {
    onCompleted: () => {
      refetch();
    },
  });

  useEffect(() => {
    if (open) {
      getUserGroup({
        variables: {
          id: taskUserGroup.userGroupId,
        },
      });
    }
  }, [open, getUserGroup]);

  useEffect(() => {
    setHours(convertMillisToExactHours(taskUserGroup?.workTime));
    setQuantity(taskUserGroup?.quantity || 0);
    setSelectedBudgetId(taskUserGroup?.budget?.budgetId || '');
  }, [open]);

  const confirm = async () => {
    await taskUserGroupUpdate({
      variables: {
        input: {
          taskId: task.id,
          workTemplateId: workTemplate.id,
          userGroupId: taskUserGroup.userGroupId,
          quantity,
          workTime: convertHoursToMillis(hours),
          budgetId: selectedBudgetId,
        },
      },
    });

    refetch();
    onClose();
  };

  const removeUserGroup = async () => {
    await taskUserGroupRemove({
      variables: {
        input: {
          taskId: task.id,
          workTemplateId: workTemplate.id,
          userGroupId: taskUserGroup.userGroupId,
        },
      },
    });

    refetch();
    onClose();
  };

  const userGroup = data?.userGroup;
  const isLoading = loading || error || R.isNil(userGroup);
  const fullGroupPath = [
    'Directory',
    ...R.pathOr([], ['groupPath'], userGroup),
  ];

  const isItemInherited = isComponentInherited(
    InheritedComponentType.TASK_USER_GROUP,
    task?.inheritedComponents || [],
    taskUserGroup?.userGroupId,
  );

  const modalTitle = (
    <div styleName="header-container">
      <div styleName="header-left">
        {isLoading ? (
          <div />
        ) : (
          <>
            <div>
              <UserGroupIcon colorId={userGroup.colorId} />
            </div>
            <div styleName="title-container">
              <div styleName="header-title">{userGroup.name}</div>
              <div styleName="header-path">{fullGroupPath.join(' / ')}</div>
            </div>
          </>
        )}
      </div>
      <Link to={`/team/group/${taskUserGroup.userGroupId}`}>
        <IconButton>
          <Icon>launch</Icon>
        </IconButton>
      </Link>
    </div>
  );

  const handleQuantity = event => {
    if (event.target.value >= 0) {
      setQuantity(event.target.value);
    }
  };

  const handleHours = event => {
    if (event.target.value >= 0) {
      setHours(event.target.value);
    }
  };

  const budgetOptions = useMemo(() => {
    return [
      { id: '', name: '', rate: 0 },
      ...R.pathOr([], ['budgets'], userGroup),
    ];
  }, [userGroup]);

  const actionDisabled = !!loading || isItemInherited;
  const inputDisabled = isItemInherited;

  const renderFooter = (
    <div styleName="modal-footer">
      <div>
        <Button onClick={removeUserGroup} disabled={actionDisabled}>
          Remove From Task
        </Button>
      </div>
      <div>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={confirm}
          disabled={actionDisabled}
        >
          Save
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      title={modalTitle}
      open={open}
      footer={renderFooter}
      loading={loading}
    >
      <>
        <div styleName="modal-row">
          <Icon>group</Icon>
          <TextField
            type="number"
            value={quantity}
            style={styles.textFieldStyle}
            label="Number of People"
            onChange={event => handleQuantity(event)}
            disabled={inputDisabled}
          />
        </div>
        <div styleName="modal-row">
          <Icon>schedule</Icon>
          <TextField
            type="number"
            value={hours}
            style={styles.textFieldStyle}
            label="Work Time per Person"
            onChange={event => handleHours(event)}
            disabled={inputDisabled}
          />
          <div styleName="work-time-label">Hour(s)</div>
        </div>
        <div styleName="modal-row">
          <Icon>monetization_on</Icon>
          <Select
            value={selectedBudgetId}
            onChange={event => setSelectedBudgetId(event.target.value)}
            label="Budget"
            style={styles.selectStyle}
            disabled={inputDisabled}
          >
            {budgetOptions.map(option => {
              const budgetData = R.pathOr([], ['budgets'], userGroup).find(
                item => item.id === option.id,
              );

              const primaryText = option.id ? (
                <>
                  <span>
                    {`${budgetData.name}: ${numberToLocaleString(
                      budgetData.rate,
                    )}/Hours `}
                  </span>
                </>
              ) : (
                'No Budget'
              );

              return (
                <MenuItem key={option.id} value={option.id}>
                  {primaryText}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </>
    </Modal>
  );
};

export default EditTaskUserGroupModal;
