import React from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup as MUIRadioGroup,
  RadioGroupProps as MUIRadioGroupProps,
} from '@mui/material';

import RadioButton from './RadioButton';

type CustomProps = {
  label?: string;
};

export type RadioGroupProps = CustomProps & MUIRadioGroupProps;

export const RadioGroup = (props: RadioGroupProps) => {
  const { label = '', ...data } = props;

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <MUIRadioGroup {...data} />
    </FormControl>
  );
};

RadioGroup.RadioButton = RadioButton;

export default RadioGroup;
