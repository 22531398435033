import actionTypes from './actionTypes';

export const uploadFilesSuccess = data => ({
  type: actionTypes.UPLOAD_FILES_SUCCESS,
  data,
});

export const uploadFilesFailure = () => ({
  type: actionTypes.UPLOAD_FILES_FAILURE,
});

export const requestUploadFiles = data => ({
  type: actionTypes.REQUEST_UPLOAD_FILES,
  data,
});

export const uploadAssetsCsvSuccess = () => ({
  type: actionTypes.UPLOAD_ASSETS_CSV_SUCCESS,
});

export const uploadAssetsCsvFailure = () => ({
  type: actionTypes.UPLOAD_ASSETS_CSV_FAILURE,
});

export const requestUploadAssetsCsv = data => ({
  type: actionTypes.REQUEST_UPLOAD_ASSETS_CSV,
  data,
});

export const downloadFilesSuccess = (data?: object) => ({
  type: actionTypes.DOWNLOAD_FILES_SUCCESS,
  data,
});

export const downloadFilesFailure = () => ({
  type: actionTypes.DOWNLOAD_FILES_FAILURE,
});

export const requestDownloadFiles = data => ({
  type: actionTypes.REQUEST_DOWNLOAD_FILES,
  data,
});

export const requestValidateAssetsCsv = data => ({
  type: actionTypes.REQUEST_VALIDATE_ASSETS_CSV,
  data,
});

export const validateAssetsCsvSuccess = () => ({
  type: actionTypes.VALIDATE_ASSETS_CSV_SUCCESS,
});

export const validateAssetsCsvFailure = data => ({
  type: actionTypes.VALIDATE_ASSETS_CSV_FAILURE,
  data,
});
