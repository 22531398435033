import { WorkTypeVariant } from '@atom/utilities/workTemplateUtilities';

export enum ModalVariant {
  DELETE_CONFIRM = 'DELETE_CONFIRM',
  DELETE_ERROR = 'DELETE_ERROR',
}

export const regularModalValues = {
  [ModalVariant.DELETE_CONFIRM]: {
    title: 'Delete Work Template',
    content: 'Are you sure you want to delete this work template?',
    button: 'DELETE',
  },
  [ModalVariant.DELETE_ERROR]: {
    title: 'Cannot Delete Work Template',
    content:
      'The work template cannot be deleted because it was used to create work.',
    button: 'OK',
  },
};

export const parentModalValues = {
  [ModalVariant.DELETE_CONFIRM]: {
    title: 'Delete Parent Work Template',
    content: 'Are you sure you want to delete this work template?',
    button: 'DELETE',
  },
  [ModalVariant.DELETE_ERROR]: {
    title: 'Cannot Delete Parent Work Template',
    content:
      'This parent work template cannot be deleted because it contains child work templates.',
    button: 'OK',
  },
};

export const childModalValues = {
  [ModalVariant.DELETE_CONFIRM]: {
    title: 'Delete Child Work Template',
    content: 'Are you sure you want to delete this work template?',
    button: 'DELETE',
  },
  [ModalVariant.DELETE_ERROR]: {
    title: 'Cannot Delete Child Work Template',
    content:
      'This child work template cannot be deleted because it was used to create work.',
    button: 'OK',
  },
};

export const modalVariants = {
  [WorkTypeVariant.REGULAR]: regularModalValues,
  [WorkTypeVariant.PARENT]: parentModalValues,
  [WorkTypeVariant.CHILD]: childModalValues,
};

export const getModalValue = (
  workTypeVariant: WorkTypeVariant,
  modalVariant: ModalVariant,
): any => {
  if (!workTypeVariant || !workTypeVariant) {
    return {};
  }

  return modalVariants[workTypeVariant][modalVariant];
};
