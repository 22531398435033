import FormData from 'form-data';
import * as R from 'ramda';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import actionTypes from '@atom/actions/actionTypes';
import {
  downloadFilesFailure,
  downloadFilesSuccess,
  uploadAssetsCsvFailure,
  uploadAssetsCsvSuccess,
  uploadFilesFailure,
  uploadFilesSuccess,
  validateAssetsCsvFailure,
  validateAssetsCsvSuccess,
} from '@atom/actions/fileActions';
import { retrieveInventorySchemas } from '@atom/actions/inventorySchemaActions';
import { requestMediaTotals, retrieveMedia } from '@atom/actions/mediaActions';
import textConstants from '@atom/constants/textConstants';
import { Snackbar } from '@atom/mui';
import accountUtilities from '@atom/utilities/accountUtilities';
import api from '@atom/utilities/api';
import {
  FILES_ARCHIVE_ENDPOINT,
  FILES_CSV_UPLOAD_ENDPOINT,
  FILES_ENDPOINT,
} from '@atom/utilities/endpoints';

import {
  addFormInstanceMedia,
  updateFormInstanceCi,
} from './formInstanceSagas';

function setUploadSuccessMessage(fileData) {
  const errorFiles = fileData.filter(item => item.type === 'error');
  if (errorFiles.length > 0) {
    const errorFileNames = errorFiles.map(file => file.name).join(', ');
    return `Some files failed to be uploaded: ${errorFileNames}`;
  }
  return 'Upload success';
}

export function* uploadFiles(action) {
  const formData = new FormData();
  const {
    files,
    widths,
    subjectId,
    subjectType,
    parentSubjectId,
    folderId,
    queryParams,
  } = action.data;
  // iterate across keys to create arrays in formData
  const fileKeys = Object.keys(files);

  if (fileKeys.length > 5) {
    yield fork(accountUtilities.apiErrorHandler, '', uploadFilesFailure);
    Snackbar.error({
      message:
        'The maximum number of files that can be uploaded in a single request is [5]',
    });

    return;
  }

  fileKeys.forEach(key => {
    formData.append('files', files.item(key));
  });
  widths.forEach(width => {
    formData.append('widths', width);
  });
  formData.append('subjectId', subjectId);
  formData.append('subjectType', subjectType);
  if (parentSubjectId) {
    formData.append('parentSubjectId', parentSubjectId);
  }
  if (folderId && folderId !== 'root') {
    formData.append('folderId', folderId);
  }

  try {
    Snackbar.info({ message: `Uploading ${fileKeys.length} items...` });

    const { data } = yield call(api.post, FILES_ENDPOINT, formData);

    yield put(uploadFilesSuccess(data));

    yield put(retrieveMedia(queryParams));

    if (subjectType === 'inventoryAsset') {
      const params = {
        parentSubjectIds: subjectId,
        subjectTypes: subjectType,
      };

      yield put(requestMediaTotals(params));
    }

    if (
      subjectType === 'formInstanceField' ||
      subjectType === 'formInstanceAsset'
    ) {
      const media = data.map(mediaItem => mediaItem.id);

      // CI module inspection media upload
      if (action.data.inspectionUpdate) {
        const {
          pageId,
          fieldId,
          name,
          mediaIds,
        } = action.data.inspectionUpdate;

        const formInstanceAction = {
          data: {
            formInstanceId: parentSubjectId,
            pageId,
            fieldId,
            name,
            mediaIds: [...mediaIds, ...media],
          },
        };

        yield call(updateFormInstanceCi, formInstanceAction);
      } else {
        // form instance generic or FHWA media upload
        const formInstanceAction = {
          data: {
            subjectId,
            formInstanceId: parentSubjectId,
            media,
          },
        };

        yield call(addFormInstanceMedia, formInstanceAction);
      }
    }

    Snackbar.info({
      message: setUploadSuccessMessage(data),
    });
  } catch (error) {
    yield fork(accountUtilities.apiErrorHandler, error, uploadFilesFailure);

    const response = R.pathOr('', ['response', 'data'], error);
    const message =
      response === textConstants.MULTER_FILE_LIMIT_ERROR
        ? 'The file you are trying to upload exceeds the 300MB maximum limit.'
        : 'An unknown error occurred. Please try again.';

    Snackbar.error({
      message,
    });
  }
}

export function* uploadAssetsCsv(action) {
  const formData = new FormData();
  const {
    files,
    categoryId,
    validateOnly,
    schemaId,
    schemaName,
    colorId,
    markerId,
  } = action.data;
  const file = files.item(0);

  try {
    formData.append('file', file);

    const params = {
      categoryId,
      validateOnly,
      schemaId,
      schemaName,
      colorId,
      markerId,
    };

    if (!validateOnly) {
      Snackbar.info({ message: `Uploading ${file.name}...` });
    }

    yield call(api.post, FILES_CSV_UPLOAD_ENDPOINT, formData, params);

    if (!validateOnly) {
      yield put(uploadAssetsCsvSuccess());

      yield put(retrieveInventorySchemas({ rootSchemas: true }));

      Snackbar.info({
        message: `Uploaded ${file.name}`,
      });
    } else {
      yield put(validateAssetsCsvSuccess());
    }
  } catch (error) {
    if (!validateOnly) {
      yield fork(
        accountUtilities.apiErrorHandler,
        error,
        uploadAssetsCsvFailure,
      );

      Snackbar.error({
        message: `Failed to upload ${file.name}`,
      });
    } else {
      const errors = R.pathOr(
        [
          {
            message:
              'Something went wrong internally.  Please validate that you do not have any special characters in your file and try again.',
          },
        ],
        ['response', 'data', 'errors'],
        error,
      );
      yield put(validateAssetsCsvFailure(errors));
    }
  }
}

export function* downloadFiles(action) {
  try {
    Snackbar.info({ message: 'Preparing files for download' });

    const { data } = yield call(api.post, FILES_ARCHIVE_ENDPOINT, action.data);

    yield put(downloadFilesSuccess());
    window.location.assign(`${FILES_ENDPOINT}/${data}`);
  } catch (error) {
    yield fork(accountUtilities.apiErrorHandler, error, downloadFilesFailure);
    Snackbar.error({
      message: error.message,
    });
  }
}

/* WATCHERS */
export function* requestUploadFiles() {
  yield takeEvery(actionTypes.REQUEST_UPLOAD_FILES, uploadFiles);
}

export function* requestUploadAssetsCsv() {
  yield takeEvery(actionTypes.REQUEST_UPLOAD_ASSETS_CSV, uploadAssetsCsv);
}

export function* requestValidateAssetsCsv() {
  yield takeEvery(actionTypes.REQUEST_VALIDATE_ASSETS_CSV, uploadAssetsCsv);
}

export function* requestDownloadFiles() {
  yield takeEvery(actionTypes.REQUEST_DOWNLOAD_FILES, downloadFiles);
}

export default [
  requestUploadFiles,
  requestUploadAssetsCsv,
  requestDownloadFiles,
  requestValidateAssetsCsv,
];
