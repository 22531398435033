import React, { useContext } from 'react';

import { Select } from '@atom/mui';
import { POLICY_RESOURCE_OPTIONS } from '@atom/utilities/policyUtilities';

import PolicyModalContext from './PolicyModalContext';

const { MenuItem } = Select;

const PolicyGrantTypeSelection = () => {
  const { state, updateState } = useContext(PolicyModalContext);
  const { resource, grantType, loadingExistingPolicies } = state;

  const option = POLICY_RESOURCE_OPTIONS[resource];

  return (
    <Select
      label="Sub Source"
      value={grantType || ''}
      onChange={event =>
        updateState({ grantType: event.target.value, grants: [] })
      }
      loading={loadingExistingPolicies}
      displayEmpty
    >
      {option.grantTypes.map(type => (
        <MenuItem value={type} key={type}>
          {POLICY_RESOURCE_OPTIONS[type]?.label || ''}
        </MenuItem>
      ))}
    </Select>
  );
};

export default PolicyGrantTypeSelection;
