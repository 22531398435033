export const ASSETS_LIMIT = 5;
export const FAVORITES_LIMIT = 250;
export const FAVORITES_LIST_LIMIT = 50;

export enum FavoriteSubjectType {
  USER = 'USER',
  MATERIAL = 'MATERIAL',
}

export interface CategoriesType {
  id?: string;
  name?: string;
}

export interface FavoriteData {
  colorId?: number;
  assetType?: string;
  categories?: CategoriesType;
  photoUrl?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  markerId?: number;
  schemaId?: string;
  isMaterial?: boolean;
  inactive?: boolean;
}

export interface FavoriteDetail extends Partial<FavoriteData> {
  id: string;
  subjectType: string;
  subjectId: string;
  subjectName: string;
  listIds?: string[];
  data: FavoriteData;
  createdDate?: number;
  createdBy?: string;
  favoriteId?: string;
  name?: string;
}

export interface FavoritesList {
  id: string;
  name: string;
  subjectType: FavoriteSubjectType;
  favorites: FavoriteDetail[];
  createdDate: number;
  createdBy: string;
}

export interface FavoritesConnection {
  totalCount: number;
  favorites: FavoriteDetail[];
}

export interface FavoritesListTreeConnection {
  totalCount: number;
  totalUniqueFavorites: number;
  favoritesLists: FavoritesList[];
}

export interface FavoritesListsTreeConnectionInput {
  userId: string;
  sortBy?: string;
  isAscending?: boolean;
  subjectType?: string;
}

export interface FavoritesConnectionInput {
  userId: string;
  sortBy?: string;
  isAscending?: boolean;
  subjectType?: string;
}

export interface FavoriteCreateInput {
  userId: string;
  subjectId: string;
  subjectType: string;
  subjectName: string;
  listIds?: string[];
}

export interface FavoriteDeleteInput {
  userId: string;
  id: string;
}

export interface FavoriteUpdateInput {
  userId: string;
  id: string;
  listIds: string[];
}

export interface FavoritesListCreateInput {
  userId: string;
  subjectType: string;
  name: string;
  subjectId?: string;
  subjectName?: string;
}

export interface FavoritesListDeleteInput {
  userId: string;
  id: string;
}

export interface FavoritesListUpdateInput {
  userId: string;
  id: string;
  name: string;
}

export const flattenFavoriteData = favorite => ({
  id: favorite.subjectId,
  name: favorite.subjectName,
  assetType: favorite.data.assetType,
  categories: favorite.data.categories,
  colorId: favorite.data.colorId,
  favoriteId: favorite.id,
  attributes: {}, // needed to flag it's not a folder.
  markerId: favorite.data.markerId,
  schemaId: favorite.data.schemaId,
  isMaterial: favorite.data.isMaterial,
  inactive: favorite.data.inactive,
  listIds: favorite.listIds || [],
  firstName: favorite.data.firstName,
  lastName: favorite.data.lastName,
  photoUrl: favorite.data.photoUrl,
  email: favorite.data.email,
});
