import * as R from 'ramda';

import { InventoryFullCategoryTreeType } from '@atom/types/inventory';
import { ExportTemplateDetailType } from '@atom/types/inventoryExport';

export const getSelectedSchemaIds = (
  categoryTree: InventoryFullCategoryTreeType,
  exportTemplateDetail: ExportTemplateDetailType,
): string[] => {
  return !R.isNil(categoryTree.children)
    ? categoryTree.children.reduce(
        (
          selectedSchemaIds: string[],
          category: InventoryFullCategoryTreeType,
        ): string[] => {
          const categoryIds = new Set(exportTemplateDetail.categoryIds);
          const schemaId = categoryIds.has(category.id)
            ? category.schemaId
            : null;

          return !R.isNil(schemaId)
            ? [...selectedSchemaIds, schemaId]
            : [
                ...selectedSchemaIds,
                ...getSelectedSchemaIds(category, exportTemplateDetail),
              ];
        },
        [],
      )
    : [];
};

export const getSelectedSchemaId = (
  categoryTree: InventoryFullCategoryTreeType,
  exportTemplateDetail: ExportTemplateDetailType,
): string => {
  const selectedSchemaIds = getSelectedSchemaIds(
    categoryTree,
    exportTemplateDetail,
  );
  return selectedSchemaIds.length > 0 ? selectedSchemaIds[0] : '';
};

export const getSubItemCheckedValue = (
  schemaId: string,
  exportTemplateDetail: ExportTemplateDetailType,
): boolean => {
  const selectedSubItemIds = new Set(...[R.keys(exportTemplateDetail.schemas)]);
  return selectedSubItemIds.has(schemaId);
};

export const getAttributeCheckedValue = (
  attribute: any,
  exportTemplateDetail: ExportTemplateDetailType,
  schemaId: string,
): boolean => {
  const selectedAttributes = new Set(
    R.pathOr([], ['schemas', schemaId, 'attributeIds'], exportTemplateDetail),
  );
  return selectedAttributes.has(attribute.id);
};

export const getAttributeFilters = (
  attribute: any,
  exportTemplateDetail: ExportTemplateDetailType,
  schemaId: string,
): string[] => {
  return R.pathOr(
    [],
    ['schemas', schemaId, 'filters', attribute.id],
    exportTemplateDetail,
  );
};

export const getAttributeCount = (
  exportTemplateDetail: ExportTemplateDetailType,
  schemaId: string,
): any => {
  return R.pathOr(
    [],
    ['schemas', schemaId, 'attributeIds'],
    exportTemplateDetail,
  ).length;
};
