import { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import { ANALYTICS_FOLDER_SEARCH } from '@atom/graph/analytics';
import {
  AnalyticsFolderSearch,
  AnalyticsFolderSearchConnection,
} from '@atom/types/analytics';

import client from '../graph/client';

export interface AnalyticsFolderSearchOptions {
  // search query
  query: string;
  // number of milliseconds to debounce search at
  // defaults to 500
  debounceTime?: number;
  // character min before querying the API for results
  characterMin?: number;
  // results limit
  limit?: number;
}

export type Data = [
  AnalyticsFolderSearchConnection,
  {
    fetchMore: () => void;
    hasMore: boolean;
    loadingSearch: boolean;
    loadingPagination: boolean;
    error: boolean;
  },
];

type DebouncedSearch = (
  query: string,
  folders: AnalyticsFolderSearch[],
  limit?: number,
  page?: number,
) => void;

export const useAnalyticsFolderSearch = ({
  query,
  limit,
  characterMin = 1,
  debounceTime = 500,
}: AnalyticsFolderSearchOptions): Data => {
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [loadingPagination, setLoadingPagination] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [connection, setConnection] = useState<AnalyticsFolderSearchConnection>(
    {
      totalCount: 0,
      analyticsFolders: [],
    },
  );

  const nextPage = useMemo(() => {
    if (!limit || !connection.totalCount) {
      return 1;
    }

    return Math.floor(connection.analyticsFolders.length / limit) + 1;
  }, [connection, limit]);

  const searchAnalyticsFolders = async (
    search: string,
    folders: AnalyticsFolderSearch[],
    // eslint-disable-next-line @typescript-eslint/no-shadow
    limit?: number,
    page?: number,
  ) => {
    setError(false);

    try {
      const { data } = await client.query<{
        analyticsFolderSearch: AnalyticsFolderSearchConnection;
      }>({
        query: ANALYTICS_FOLDER_SEARCH,
        variables: {
          input: {
            query: search,
            ...R.reject(R.isNil, {
              limit,
              page,
            }),
          },
        },
      });

      setConnection({
        totalCount: data.analyticsFolderSearch.totalCount,
        analyticsFolders: [
          ...folders,
          ...data.analyticsFolderSearch.analyticsFolders,
        ],
      });
    } catch (err) {
      setError(true);
    }

    setLoadingSearch(false);
    setLoadingPagination(false);
  };

  const searchDebounced = useCallback<DebouncedSearch>(
    debounce(searchAnalyticsFolders, debounceTime),
    [],
  );

  useEffect(() => {
    if (query.length >= characterMin) {
      setLoadingSearch(true);
      searchDebounced(query, [], limit);
    } else {
      setConnection({ totalCount: 0, analyticsFolders: [] });
    }
  }, [query]);

  const hasMore = connection.analyticsFolders.length < connection.totalCount;

  const fetchMore = () => {
    if (hasMore) {
      setLoadingPagination(true);
      searchAnalyticsFolders(
        query,
        connection.analyticsFolders,
        limit,
        nextPage,
      );
    }
  };

  return [
    connection,
    {
      fetchMore,
      hasMore,
      loadingSearch,
      loadingPagination,
      error,
    },
  ];
};
