import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';

import { Checkbox, TextField } from '@atom/mui';
import { WorkOrderAssetTreeElement } from '@atom/types/work';
import iconUtilities from '@atom/utilities/iconUtilities';

import './addElementModal.css';

const useStyles = makeStyles({
  input: {
    textAlign: 'center',
  },
});

const PADDING_OFFSET = 2;

interface Props {
  element: WorkOrderAssetTreeElement;
  indentation: number;
  toggleElement: (id: string) => void;
  updateQuantity: (id: string, quantity: number) => void;
}

const AddElementRow = ({
  element,
  indentation,
  toggleElement,
  updateQuantity,
}: Props) => {
  const classes = useStyles();
  const [checked, setChecked] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(0);

  const toggleCheck = () => {
    if (checked) {
      setQuantity(0);
    } else {
      setQuantity(1);
    }

    setChecked(!checked);
    toggleElement(element.id);
  };

  const onChange = event => {
    setQuantity(Number(event.target.value));
    updateQuantity(element.id, Number(event.target.value));
  };

  const elementIcon = iconUtilities.getDataManagementElementIcon(
    element.markerId,
    checked,
  );

  const containerStyle = {
    paddingLeft: `calc(1rem + ${PADDING_OFFSET * indentation}rem)`,
  };

  return (
    <>
      <div styleName="element-row" style={containerStyle}>
        <div styleName="row-name-container">
          <Checkbox checked={checked} onChange={toggleCheck} />
          {elementIcon}
          <div styleName="row-name">{element.name}</div>
        </div>
        <div styleName="textfield-container">
          <TextField
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            type="number"
            value={quantity}
            name="Quantity"
            onChange={onChange}
            disabled={!checked}
          />
        </div>
      </div>
    </>
  );
};

export default AddElementRow;
