import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import WorkOrderContext, {
  WorkOrderActionTypes,
} from '@atom/components/workOrderDetail/WorkOrderContext';
import { TASK_UPDATE } from '@atom/graph/task';
import { DatePicker } from '@atom/mui';
import { Task, TaskUpdateInput } from '@atom/types/task';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { convertDateToMillisGMTMidday } from '@atom/utilities/timeUtilities';

import './taskDetailView.css';

const DEBOUNCE_TIME = 1000;

const TaskDueDate = () => {
  const { workOrderDetail, task, dispatch } = useContext(WorkOrderContext);
  const [dueDate, setDueDate] = useState<Date>(
    task.dueDate ? new Date(task.dueDate) : null,
  );

  const [taskUpdate] = useMutation<
    { taskUpdate: Task },
    { input: TaskUpdateInput }
  >(TASK_UPDATE);

  useEffect(() => {
    setDueDate(task.dueDate ? new Date(task.dueDate) : null);
  }, [task.dueDate]);

  const setTask = (updatedTask: Task) => {
    dispatch({
      type: WorkOrderActionTypes.SET_TASK,
      data: {
        ...updatedTask,
      },
    });
  };

  const handleSubmit = async (date: Date) => {
    if (R.isEmpty(date) || R.isNil(date)) {
      return;
    }

    const res = await taskUpdate({
      variables: {
        input: {
          id: task.id,
          workOrderId: workOrderDetail.id,
          dueDate: convertDateToMillisGMTMidday(date),
        },
      },
    });

    setTask(res?.data?.taskUpdate);
  };

  const debouncedHandleSubmit = useCallback(
    debounce(handleSubmit, DEBOUNCE_TIME),
    [task.id, workOrderDetail.id],
  );

  const onChange = (date?: Date) => {
    setDueDate(date);

    if (!date) {
      return;
    } else if (!Number.isNaN(date.valueOf())) {
      debouncedHandleSubmit(date);
    }
  };

  const isDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  return (
    <div styleName="time-row-item input">
      <div styleName="label">Due Date</div>
      <div styleName="time-picker-container" id="dueDate">
        <DatePicker
          value={dueDate}
          onChange={onChange}
          format="MM/dd/yyyy"
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default TaskDueDate;
