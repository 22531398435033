import React from 'react';
import { List as MUIList, ListProps as ListPropsType } from '@mui/material';

import ListItem from './ListItem';
import ListItemButton from './ListItemButton';
import ListItemText from './ListItemText';

export type ListProps = ListPropsType;

export const List = (props: ListProps) => {
  return <MUIList {...props} />;
};

List.ListItem = ListItem;
List.ListItemButton = ListItemButton;
List.ListItemText = ListItemText;

export default List;
