import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import { useGoogleMapsStateValue } from '@atom/components/common/map/hooks/googleMapsStateHook';
import Map from '@atom/components/common/map/Map';
import MapMarkers from '@atom/components/common/map/MapMarkers';
import { usePreferences } from '@atom/hooks/usePreferences';
import { getLoggedInUserMapLocationSelector } from '@atom/selectors/mapSelectors';
import {
  getMapShapeBoundingBox,
  shapeIsPolyline,
} from '@atom/utilities/mapShapeUtilities';
import {
  buildMapDataRequest,
  getGeoJsonFromMapBounds,
  getGrabShape,
  getMapCenterAndZoomParams,
  urlValueToLatLng,
} from '@atom/utilities/mapUtilities';

import Kml from '../common/map/kml/Kml';
import MapPinMarker from '../common/map/markers/MapPinMarker';

import { useLayersValue } from './hooks/layersHook';
import { useListView } from './hooks/listViewHook';
import { useMapData } from './hooks/mapDataHook';
import MapStats from './mapStats/MapStats';

const MapContainer = () => {
  const [map, setMap] = useState<google.maps.Map>(null);
  const [coordinates, setCoordinates] = useState(null);
  const { grab } = useGoogleMapsStateValue();

  const preferences = usePreferences();
  const currentUserLocation = useSelector(getLoggedInUserMapLocationSelector);

  const [queryParams, setQueryParams] = useQueryParams({
    center: StringParam,
    zoom: NumberParam,
    searchPoint: StringParam,
  });

  const [, setViewParams] = useQueryParams({
    id: StringParam,
    view: StringParam,
    hover: StringParam,
  });

  const [, setListView] = useListView();

  const layers = useLayersValue();
  const { shape } = useGoogleMapsStateValue();

  const useShapeCoordinates = shape && !shapeIsPolyline(shape);

  const onLoad = useCallback((gmap: google.maps.Map) => {
    setMap(gmap);
  }, []);

  const onIdle = useCallback(() => {
    try {
      const params = getMapCenterAndZoomParams(
        map,
        queryParams,
        preferences,
        currentUserLocation,
      );

      if (!R.equals(queryParams, params) || !coordinates) {
        setQueryParams(params);
        R.pipe(
          getGeoJsonFromMapBounds,
          R.prop('coordinates'),
          JSON.stringify,
          setCoordinates,
        )(map);
      }
    } catch {
      setQueryParams(null);
    }
  }, [map, setQueryParams, queryParams, coordinates, preferences]);

  const url = buildMapDataRequest(layers, {
    coordinates: useShapeCoordinates
      ? JSON.stringify(getMapShapeBoundingBox(shape))
      : coordinates,
    zoom: queryParams.zoom,
  });

  const [mapData, loading] = useMapData(url);

  const grabInRadius = async event => {
    const id = getGrabShape(map, mapData)(event);
    if (grab) {
      setViewParams({ id, view: 'list', hover: null });
    }
  };

  useEffect(() => {
    setListView(mapData);
  }, [mapData]);

  return (
    <Map
      onIdle={onIdle}
      onLoad={onLoad}
      center={queryParams.center && urlValueToLatLng(queryParams.center)}
      zoom={queryParams.zoom}
      loading={loading}
      mapStyleControl
      mapSearchBox
      layerControls
      drawingControls
      onClick={grabInRadius}
    >
      {/* @ts-ignore */}
      <MapMarkers data={JSON.stringify(mapData)} />
      {queryParams.searchPoint && (
        <MapPinMarker center={queryParams.searchPoint} />
      )}
      <MapStats data={mapData} loading={loading} />
      {layers.kmlLayers && !!layers.kmlLayerIds.size && <Kml map={map} />}
    </Map>
  );
};

export default React.memo(MapContainer);
