import React from 'react';

import { FavoriteDetail, FavoritesList } from '@atom/types/favorite';
import { UserDetail } from '@atom/types/user';
import { UserGroup, UserGroupTreeType } from '@atom/types/userGroups';

export enum View {
  USERS,
  GROUPS,
  SEARCH,
  FAVORITES,
}

export interface SelectedUserGroup extends UserGroupTreeType {
  quantity: number;
}

export const baseUserGroup = {
  id: 'root',
  name: 'directory',
  colorId: 0,
  userGroups: [],
};

interface Context {
  userGroupTree: UserGroupTreeType;
  activeView: View;
  setActiveView: (view: View) => void;
  expanded: Set<string>;
  toggleExpanded: (id: string) => void;
  selectedUsers: UserDetail[];
  selectedUserGroups: SelectedUserGroup[];
  addUserToCart: (user: any) => void;
  removeUserFromCart: (userId: string) => void;
  addUserGroupToCart: (userGroup: UserGroupTreeType) => void;
  removeUserGroupFromCart: (userGroupId: string) => void;
  updateUserGroupQuantity: (userGroupId: string, quantity: number) => void;
  userGroupsOnTask: string[];
  usersOnTask: string[];
  users: UserDetail[];
  setPage: Function;
  total: number;
  favoritesLoading: boolean;
  usersLoading: boolean;
  page: number;
  favoritesError: any;
  usersError: any;
  ref: any;
  addLoading: boolean;
  userGroups: UserGroup[];
  userGroupsLoading: boolean;
  favoriteUsers: FavoriteDetail[];
  searchUsers: UserDetail[];
  createFavorite: (user: UserDetail) => void;
  updateFavorite: (favorite: FavoriteDetail) => any;
  favoritesEnabled: boolean;
  favoritesLists: FavoritesList[];
  uniqueFavoritesCount: number;
  handleAddList: (listName: string) => void;
  setSelectedUsers: (users: UserDetail[]) => void;
  handleGetFavorites: () => void;
}

export const initialState: Context = {
  userGroupTree: baseUserGroup,
  activeView: View.USERS,
  setActiveView: () => {},
  expanded: new Set(['root']),
  toggleExpanded: () => {},
  selectedUsers: [],
  selectedUserGroups: [],
  addUserToCart: () => {},
  removeUserFromCart: () => {},
  addUserGroupToCart: () => {},
  removeUserGroupFromCart: () => {},
  updateUserGroupQuantity: () => {},
  userGroupsOnTask: [],
  usersOnTask: [],
  users: [],
  setPage: () => {},
  total: 0,
  favoritesLoading: false,
  usersLoading: false,
  page: 1,
  favoritesError: null,
  usersError: null,
  ref: null,
  addLoading: false,
  userGroups: [],
  userGroupsLoading: false,
  favoriteUsers: [],
  searchUsers: [],
  createFavorite: () => {},
  updateFavorite: () => {},
  favoritesEnabled: false,
  favoritesLists: [],
  uniqueFavoritesCount: 0,
  handleAddList: () => {},
  setSelectedUsers: () => {},
  handleGetFavorites: () => {},
};

const AddTeamModalContext = React.createContext<Context>(initialState);

export default AddTeamModalContext;
