// Used to configure the default colors and styling of Material UI
import { createTheme, Theme } from '@mui/material';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import spacing from 'material-ui/styles/spacing';

import colors from '@atom/styles/colors';

export const muiTheme = getMuiTheme({
  spacing,
  fontFamily: 'Roboto, sans-serif',
  datePicker: {
    selectColor: colors.brand.blue,
    headerColor: colors.brand.blue,
  },
  timePicker: {
    headerColor: colors.brand.blue,
  },
  snackbar: {
    actionColor: colors.brand.blue,
  },
  palette: {
    primary1Color: colors.brand.blue,
    primary2Color: colors.brand.turquoise,
    primary3Color: colors.neutral.silver,
    accent1Color: colors.brand.violet,
    accent2Color: colors.neutral.fog,
    accent3Color: colors.neutral.gray,
    textColor: colors.neutral.dark,
    alternateTextColor: colors.neutral.white,
    canvasColor: colors.neutral.white,
    borderColor: colors.neutral.silver,
    disabledColor: colors.neutral.gray,
    pickerHeaderColor: colors.brand.turquoise,
    clockCircleColor: colors.neutral.typhoon,
    shadowColor: colors.neutral.black,
  },
});

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

export const newMuiTheme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  palette: {
    primary: {
      main: colors.brand.blue,
    },
    text: {
      primary: colors.neutral.dark,
    },
  },
});
