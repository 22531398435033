import { ReportTypes } from '@atom/types/preferences';

export interface ReportOption {
  label: string;
  value: string;
  type: string;
  displayEmptyAttributes: boolean;
}

export const REPORT_OPTIONS = {
  [ReportTypes.CUSTOM_V1]: {
    label: 'Assessment Report',
    value: ReportTypes.CUSTOM_V1,
    type: 'custom_v1',
    displayEmptyAttributes: false,
  },
  [ReportTypes.CUSTOM_V2]: {
    label: 'Assessment Report',
    value: ReportTypes.CUSTOM_V2,
    type: 'custom_v2',
    displayEmptyAttributes: true,
  },
  [ReportTypes.CUSTOM_V3]: {
    label: 'Detailed Report',
    value: ReportTypes.CUSTOM_V3,
    type: 'custom_v3',
    displayEmptyAttributes: true,
  },
  [ReportTypes.CUSTOM_WORK_REPORT]: {
    label: '',
    value: ReportTypes.CUSTOM_WORK_REPORT,
    type: '',
    displayEmptyAttributes: false,
  },

  [ReportTypes.ALDOT_REPORT]: {
    label: '',
    value: ReportTypes.ALDOT_REPORT,
    type: '',
    displayEmptyAttributes: false,
  },
  [ReportTypes.SCDOT_REPORT]: {
    label: '',
    value: ReportTypes.SCDOT_REPORT,
    type: '',
    displayEmptyAttributes: false,
  },
  [ReportTypes.BASIC_FULL]: {
    label: 'Full report',
    value: ReportTypes.BASIC_FULL,
    type: 'basic',
    displayEmptyAttributes: true,
  },
  [ReportTypes.BASIC_FULL_V2]: {
    label: 'Full report',
    value: ReportTypes.BASIC_FULL_V2,
    type: 'basic',
    displayEmptyAttributes: true,
  },
  [ReportTypes.BASIC_PARTIAL]: {
    label: 'Only fields with values',
    value: ReportTypes.BASIC_PARTIAL,
    type: 'basic',
    displayEmptyAttributes: false,
  },
};
