import React from 'react';

import CustomFieldCell from './CustomFieldCell';
import { MappedField } from './reportUtilities';

import './workOrderReport.css';

interface Props {
  fields: MappedField[];
}

const CustomFieldRow = ({ fields }: Props) => {
  return (
    <div styleName="custom-field-row">
      {fields.map(field => (
        <CustomFieldCell key={field?.id} field={field} />
      ))}
    </div>
  );
};

export default CustomFieldRow;
