import React, { useContext } from 'react';
import * as R from 'ramda';

import { DataType } from '@atom/types/dataType';
import { Task } from '@atom/types/task';
import {
  InvalidTaskFields,
  TaskRequiredValidation,
  WorkOrderDetailType,
} from '@atom/types/work';
import { removeFromSet } from '@atom/utilities/setUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import {
  getFieldPathById,
  isFieldInvalid,
  validateTaskRequiredFields,
} from '@atom/utilities/workOrderFieldUtilities';
import {
  CLOSED_STATUS_ID,
  isWorkOrderCompletedOrAbove,
} from '@atom/utilities/workOrderStatusUtilities';

import BulkContext, { buildTabs, Tab } from '../BulkContext';

// Validate the fields of the 'general' tab.
const validateGeneralRequiredFields = (
  workOrderDetail: WorkOrderDetailType,
): TaskRequiredValidation => {
  const fields = [];

  // Only validate these fields when they are visible:
  if (isWorkOrderCompletedOrAbove(workOrderDetail.statusId)) {
    fields.push('completionDate');
    fields.push('completedBy');
  }

  if (workOrderDetail.statusId === CLOSED_STATUS_ID) {
    fields.push('closedDate');
    fields.push('closedBy');
  }

  const invalidFields = fields.reduce((acc, field) => {
    return isNilOrEmpty(workOrderDetail[field]) ? [...acc, field] : acc;
  }, []);

  return {
    isValid: invalidFields.length === 0,
    invalidTaskFields: {
      general: new Set(invalidFields),
    },
  };
};

export const validateAll = (
  workOrderDetail: WorkOrderDetailType,
  invalidTabs: Set<string>,
  invalidFields: InvalidTaskFields,
  setInvalidTabs: React.Dispatch<React.SetStateAction<Set<string>>>,
  setInvalidFields: React.Dispatch<React.SetStateAction<InvalidTaskFields>>,
  setTab: React.Dispatch<React.SetStateAction<Tab>>,
) => {
  const tabs = buildTabs(workOrderDetail.tasks);
  let isAllValid = true;
  // Create a local working copy of invalidTabs and invalidFields,
  // since if we call set() on each loop iteration, the value
  // won't be actually set on the state.
  let localInvalidTabs = Array.from(invalidTabs);
  let localInvalidFields = { ...invalidFields };

  // Validate all tabs:
  tabs.forEach(tab => {
    const validation =
      tab.id === 'general'
        ? validateGeneralRequiredFields(workOrderDetail)
        : validateTaskRequiredFields(workOrderDetail, tab.id);

    if (validation.isValid) {
      localInvalidTabs = [...localInvalidTabs].filter(
        invalidTab => invalidTab !== tab.id,
      );
      localInvalidFields = { ...R.omit([tab.id], localInvalidFields) };
    } else {
      localInvalidTabs = [...localInvalidTabs, tab.id];
      localInvalidFields = {
        ...localInvalidFields,
        ...validation.invalidTaskFields,
      };

      // Switch to the step with errors (only the first time):
      if (isAllValid) {
        setTab(tab);
        isAllValid = false;
      }
    }
  });

  // And the end, set the invalid tabs and fields values:
  setInvalidTabs(new Set([...localInvalidTabs]));
  setInvalidFields({ ...localInvalidFields });

  if (isAllValid) {
    return Promise.resolve();
  }

  return Promise.reject();
};

const setInvalid = (
  tab: string,
  field: string,
  isValid: boolean,
  invalidTabs: Set<string>,
  invalidFields: InvalidTaskFields,
  setInvalidTabs: React.Dispatch<React.SetStateAction<Set<string>>>,
  setInvalidFields: React.Dispatch<React.SetStateAction<InvalidTaskFields>>,
) => {
  if (isValid) {
    const updatedFieldIds = removeFromSet(
      invalidFields[tab] || new Set([]),
      field,
    );

    const updatedInvalidFields =
      updatedFieldIds.size === 0
        ? R.omit([tab], invalidFields)
        : {
            ...invalidFields,
            [tab]: updatedFieldIds,
          };

    setInvalidFields(updatedInvalidFields);

    if (updatedFieldIds.size === 0) {
      // Remove tab from invalidTabs if there are no errors left in this tab.
      setInvalidTabs(
        new Set(
          Array.from(invalidTabs).filter(invalidTab => invalidTab !== tab),
        ),
      );
    }
  } else {
    setInvalidFields({
      ...invalidFields,
      [tab]: new Set([...(invalidFields[tab] || []), field]),
    });
    setInvalidTabs(new Set([...invalidTabs, tab]));
  }
};

const isGeneralFieldValid = (
  field: string,
  workOrderDetail: WorkOrderDetailType,
) => !isNilOrEmpty(workOrderDetail[field]);

const isTaskFieldValid = (fieldId: string, task: Task) => {
  // Get path to field:
  const nestedFieldPath = getFieldPathById(fieldId, task.fields);

  // Find field (it could be nested).
  const field = R.view(R.lensPath(nestedFieldPath), task);

  // Find the root field (the one that contains the nested one, if nested).
  const rootField = R.view(R.lensPath(R.slice(0, 2, nestedFieldPath)), task);

  // @ts-ignore
  const isEnumSingle = field.dataType === DataType.ENUM_SINGLE;

  // Get the field's data for validation.
  const fieldData = isEnumSingle ? rootField : field;

  // @ts-ignore
  const currentFieldId = isEnumSingle ? rootField.id : fieldId;

  return {
    fieldId: currentFieldId,
    // @ts-ignore
    isValid: !isFieldInvalid(fieldData),
  };
};

export const useWorkOrderValidation = () => {
  const {
    invalidTabs,
    invalidFields,
    setInvalidTabs,
    setInvalidFields,
  } = useContext(BulkContext);

  // These methods run validation on a specific single field, if the validation
  // succeeds, any previous validation error is cleared.
  //
  // If it fails, we don't set the error, we only do full from validation
  // when we click on CREATE (i.e. calling validateAll()).
  return {
    // Validate a single field from the 'general' step.
    validateField: (workOrderDetail, field) => {
      const isValid = isGeneralFieldValid(field, workOrderDetail);
      if (isValid) {
        setInvalid(
          'general',
          field,
          isValid,
          invalidTabs,
          invalidFields,
          setInvalidTabs,
          setInvalidFields,
        );
      }
    },
    validateGeneralTab: workOrderDetail => {
      // If the status is not 'Completed', 'In Review' or 'Closed', then the
      // completed/closed fields are not enabled, thus there are no required
      // fields on the general tab and the form is valid.
      if (!isWorkOrderCompletedOrAbove(workOrderDetail.statusId)) {
        // Clear the general tab errors:
        setInvalidFields({ ...R.omit(['general'], invalidFields) });
        setInvalidTabs(
          new Set(
            Array.from(invalidTabs).filter(
              invalidTab => invalidTab !== 'general',
            ),
          ),
        );
      }
    },
    // Validate a single field from any task step.
    validateTaskField: (id, task) => {
      const { fieldId, isValid } = isTaskFieldValid(id, task);
      if (isValid) {
        setInvalid(
          task.id,
          fieldId,
          isValid,
          invalidTabs,
          invalidFields,
          setInvalidTabs,
          setInvalidFields,
        );
      }
    },
  };
};
