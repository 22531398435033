import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import TaskCreateControls from '@atom/components/common/workOrderDetail/taskCreate/TaskCreateControls';
import WorkOrderContext from '@atom/components/workOrderDetail/WorkOrderContext';
import { TASK_CREATE } from '@atom/graph/task';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Progress } from '@atom/mui';
import { Task, TaskCreateInput } from '@atom/types/task';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import TaskListItem from './TaskListItem';

import './workOrderTasks.css';

const styles = {
  progress: {
    height: '100%',
  },
};

const TaskList = () => {
  const preferences = usePreferences();
  const disableTaskCreation = R.pathOr(
    false,
    ['workOrders', 'disableTaskCreation'],
    preferences,
  );

  const { workOrderDetail, loading, refetch } = useContext(WorkOrderContext);

  const [taskCreate, { loading: loadingCreate }] = useMutation<
    { taskCreate: Task },
    { input: TaskCreateInput }
  >(TASK_CREATE);

  const handleCreate = async (
    property: keyof TaskCreateInput,
    value: string,
  ) => {
    await taskCreate({
      variables: {
        input: {
          workOrderId: workOrderDetail.id,
          [property]: value,
        },
      },
    });

    refetch();
  };

  const taskAmount = R.length(workOrderDetail.tasks);

  const canCreateTask =
    hasRolePermissions(ROLE_SETS.INSPECTOR) && !disableTaskCreation;

  const isCreateDisabled = loading || workOrderDetail.isClosed;

  return (
    <>
      <div styleName="task-list-header">
        <div>{`${taskAmount} tasks`}</div>
        {canCreateTask && (
          <TaskCreateControls
            loading={loading || loadingCreate}
            workOrderId={workOrderDetail?.id}
            handleCreate={handleCreate}
            isCreateDisabled={isCreateDisabled}
            data-cy="addTaskDetail"
          />
        )}
      </div>
      <div styleName="task-list-container">
        {loading && (
          <div styleName="task-list-loading-container">
            <Progress size={30} thickness={2} style={styles.progress} />
          </div>
        )}
        <div styleName={loading ? 'task-list-item-loading-mask' : ''}>
          {workOrderDetail.tasks.map(task => {
            return <TaskListItem key={task.id} task={task} />;
          })}
        </div>
      </div>
    </>
  );
};

export default TaskList;
