import React from 'react';

import { Icon, IconButton, List, Popper } from '@atom/mui';
import colors from '@atom/styles/colors';
import layout from '@atom/styles/layout';
import { MapEditMetadata, MapShapeType } from '@atom/types/map';
import { getGoogleShapeCenter } from '@atom/utilities/mapUtilities';

import './mapSearchBox.css';

const { ListItemButton } = List;

const styles = {
  popper: {
    width: '23rem',
    backgroundColor: colors.neutral.white,
    boxShadow: layout.muiDropdownBoxShadow,
  },
};

interface Props {
  mapEditMetadata: MapEditMetadata[];
  anchor: any;
  open: boolean;
  onCenter?: ({ lat, lng }, zoom: number) => void;
  onChange: (drawingMode: MapShapeType) => void;
  setEditItem?: (editItem: MapEditMetadata) => void;
  locationEditZoom?: number;
}

const DEFAULT_ZOOM = 14;

const LocationEditControls = ({
  anchor,
  mapEditMetadata,
  open,
  onCenter,
  onChange,
  setEditItem,
  locationEditZoom = DEFAULT_ZOOM,
}: Props) => {
  const handleClick = center => {
    onCenter(center, locationEditZoom);
  };

  const handleEditClick = (
    locationType: MapShapeType,
    dataItem: MapEditMetadata,
  ) => {
    onChange(locationType);
    setEditItem(dataItem);
  };

  return (
    <Popper
      open={open}
      anchorEl={anchor.current}
      style={styles.popper}
      disablePortal
    >
      <List>
        {mapEditMetadata.map(dataItem => {
          const centerLocation = getGoogleShapeCenter(dataItem.location);
          const displayCoordinates = `${centerLocation.lat
            .toString()
            .slice(0, 10)}, ${centerLocation.lng.toString().slice(0, 10)}`;

          const canEdit = dataItem?.canEdit;

          const locationType =
            dataItem?.location?.type === 'Point'
              ? MapShapeType.MARKER
              : MapShapeType.POLYLINE;

          return (
            <ListItemButton
              key={dataItem.id}
              onClick={() => handleClick(centerLocation)}
            >
              <div styleName="dropdown-icon-container">{dataItem?.icon}</div>
              <div styleName="dropdown-item-primary-text">
                <div styleName="dropdown-item-title">{dataItem?.name}</div>
                {dataItem?.address && (
                  <div styleName="dropdown-item-address">
                    {dataItem?.address}
                  </div>
                )}
                {dataItem.location && (
                  <div styleName="dropdown-item-coordinates">
                    {displayCoordinates}
                  </div>
                )}
              </div>
              {canEdit && (
                <IconButton
                  onClick={() => handleEditClick(locationType, dataItem)}
                  disabled={!canEdit}
                >
                  <Icon>edit</Icon>
                </IconButton>
              )}
            </ListItemButton>
          );
        })}
      </List>
    </Popper>
  );
};

export default LocationEditControls;
