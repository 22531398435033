import * as React from 'react';

import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';

import CreateTemplateModal from './CreateTemplateModal';

import './inventoryExport.css';

interface Props {
  navigateBack: () => void;
  loading: boolean;
}

const InventoryExportPortalHeader = ({ navigateBack, loading }: Props) => {
  return (
    <div styleName="navigation-bar-container">
      <div styleName="back-navigation-container" onClick={navigateBack}>
        <div styleName="back-button-icon">
          <Icon color={colors.neutral.white}>arrow_back</Icon>
        </div>
        <div styleName="back-button-text">Exports</div>
      </div>
      <CreateTemplateModal loading={loading} />
    </div>
  );
};

export default InventoryExportPortalHeader;
