import React, { useContext, useEffect, useRef, useState } from 'react';
import * as R from 'ramda';

import { Icon, IconButton, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventoryCategoryTree as InventoryCategoryTreeType } from '@atom/types/inventory';
import schemaUtilities from '@atom/utilities/schemaUtilities';

import InventoryPortalContext from '../InventoryPortalContext';

import InventoryCategoryTreeOptions from './InventoryCategoryTreeOptions';

import './inventoryCategoryTree.css';

const PADDING_OFFSET = 0.75;

const styles = {
  blankStyle: {
    boxSizing: 'border-box',
    padding: '24px',
  },
};

export interface Props {
  category: InventoryCategoryTreeType;
  indentation: number;
  path: any[];
}

const InventoryCategoryTreeRow = ({ category, indentation, path }: Props) => {
  const {
    open,
    toggleOpen,
    setActiveCategory,
    activeCategory,
    resetSelectedAssets,
  } = useContext(InventoryPortalContext);
  const [overflowing, setOverflowing] = useState<boolean>(false);
  const container = useRef<HTMLDivElement>();

  useEffect(() => {
    const { offsetWidth, scrollWidth } = container.current;
    setOverflowing(offsetWidth < scrollWidth);
  }, [category.name]);

  const expanded = open.has(category.id);

  const onClick = () => {
    setActiveCategory(category, path);
    resetSelectedAssets();
  };

  const onToggle = (event: any, id: string) => {
    event.stopPropagation();
    toggleOpen(id);
  };

  const arrowDirection = expanded ? 'arrow_drop_down' : 'arrow_right';

  const leftIcon = (
    <>
      {!R.isEmpty(category.categories) ? (
        <IconButton onClick={event => onToggle(event, category.id)}>
          <Icon color={colors.neutral.dim}>{arrowDirection}</Icon>
        </IconButton>
      ) : (
        <div style={styles.blankStyle} />
      )}
      <div styleName="folder-icon-container">
        <Icon
          color={
            category.schemaId
              ? schemaUtilities.getSchemaColorFromColorId(category.colorId)
              : colors.neutral.gray
          }
        >
          folder
        </Icon>
        {category.hasAssetChanges && <div styleName="folder-changes-icon" />}
      </div>
    </>
  );

  const rowStyle =
    category.id === activeCategory.id ? 'folder-row selected' : 'folder-row';

  const containerStyle = {
    paddingLeft: `calc(1rem + ${PADDING_OFFSET * indentation}rem)`,
  };

  const overflowStyle = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: `calc(12rem - (1rem + ${PADDING_OFFSET * indentation}rem)`,
  };

  return (
    <>
      <div styleName={rowStyle} style={containerStyle} onClick={onClick}>
        <div ref={container} styleName="folder-name-container">
          {leftIcon}
          <div styleName="row-name-container">
            <div styleName="asset-name">
              {!overflowing ? (
                category.name
              ) : (
                <Tooltip
                  lightTooltip
                  title={category.name}
                  placement="right-start"
                >
                  <p style={overflowStyle} styleName="asset-name">
                    {category.name}
                  </p>
                </Tooltip>
              )}
            </div>
            <div styleName="asset-type">{category.assetType}</div>
          </div>
        </div>
        {/* onClick handler prevents modal clicks from propagating up */}
        <div onClick={event => event.stopPropagation()}>
          <InventoryCategoryTreeOptions category={category} path={path} />
        </div>
      </div>
      {expanded ? (
        category.categories.map((child: any, index: number) => {
          return (
            <InventoryCategoryTreeRow
              key={child.id}
              category={child}
              indentation={indentation + 1}
              path={[...path, 'categories', index]}
            />
          );
        })
      ) : (
        <div />
      )}
    </>
  );
};

export default InventoryCategoryTreeRow;
