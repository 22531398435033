import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function breadcrumbsReducer(
  state = initialState.breadcrumbs,
  action,
) {
  const { data, type } = action;
  switch (type) {
    case actionTypes.UPDATE_TASK_BREADCRUMBS: {
      return {
        ...state,
        task: data,
      };
    }
    case actionTypes.UPDATE_INVENTORY_BREADCRUMBS: {
      return {
        ...state,
        inventory: data,
      };
    }
    default: {
      return state;
    }
  }
}
