import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
import UserContext from '@atom/components/userDetail/UserContext';
import { DELETE_USER_CERTIFICATION } from '@atom/graph/user';
import { Progress } from '@atom/mui';
import { UserCertificationDeleteInput, UserDetail } from '@atom/types/user';

import './certificationsTab.css';

interface Props {
  open: boolean;
  onClose: () => void;
  certificationId: string;
}

const DeleteCertModal = ({ open, onClose, certificationId }: Props) => {
  const { user } = useContext(UserContext);

  const [deleteCert, { loading }] = useMutation<
    { userCertificationDelete: UserDetail },
    { input: UserCertificationDeleteInput }
  >(DELETE_USER_CERTIFICATION);

  const confirm = () => {
    deleteCert({
      variables: {
        input: {
          id: certificationId,
          userId: user.id,
        },
      },
    });

    onClose();
  };

  return (
    <DeleteModal
      title="Delete Certification?"
      onConfirm={confirm}
      onCancel={onClose}
      open={open}
      content={
        loading ? (
          <Progress />
        ) : (
          <div styleName="modal-container">
            Are you sure you want to delete this Certification?
          </div>
        )
      }
    />
  );
};

export default DeleteCertModal;
