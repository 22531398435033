import React, { useState } from 'react';
import * as R from 'ramda';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { UserBudgetItem } from '@atom/types/user';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';

import AddEditBudgetModal from './AddEditBudgetModal';
import DeleteBudgetModal from './DeleteBudgetModal';

import './budgetsTab.css';

export interface Props {
  budget: UserBudgetItem;
}

const UserBudgetTile = ({ budget }: Props) => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  return (
    <>
      <div styleName="budget-tile">
        <div styleName="budget-title-container">
          <Icon color={colors.brand.blue}>monetization_on</Icon>
          <div>
            <div styleName="title">{`${budget.name}: ${numberToLocaleString(
              budget.rate,
            )}/Hour`}</div>
            {!R.isNil(budget.groupPath) && !R.isEmpty(budget.groupPath) && (
              <div styleName="subtext-container">
                <Icon color={colors.neutral.gray} style={{ fontSize: '16px' }}>
                  group
                </Icon>
                <div styleName="subtext">{budget.groupPath.join(' / ')}</div>
              </div>
            )}
          </div>
        </div>
        {budget.isEditable && (
          <div>
            {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
              <IconButton onClick={() => setOpenEdit(true)}>
                <Icon color={colors.neutral.gray}>edit</Icon>
              </IconButton>
            )}
            {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
              <IconButton onClick={() => setOpenDelete(true)}>
                <Icon color={colors.neutral.gray}>delete</Icon>
              </IconButton>
            )}
          </div>
        )}
      </div>
      <AddEditBudgetModal
        isEdit
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        budget={budget}
      />
      <DeleteBudgetModal
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        budgetId={budget.id}
      />
    </>
  );
};

export default UserBudgetTile;
