import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function formInstanceReducer(
  state = initialState.exportTemplateDetail,
  action,
) {
  const { data, type } = action;
  switch (type) {
    case actionTypes.CREATE_EXPORT_TEMPLATE_SUCCESS:
    case actionTypes.GET_EXPORT_TEMPLATE_DETAIL_SUCCESS:
    case actionTypes.UPDATE_EXPORT_TEMPLATE_SUCCESS:
    case actionTypes.ADD_EXPORT_TEMPLATE_SCHEMA_SUCCESS:
    case actionTypes.DELETE_EXPORT_TEMPLATE_SCHEMA_SUCCESS:
    case actionTypes.UPDATE_EXPORT_TEMPLATE_SCHEMA_SUCCESS: {
      return data;
    }
    case actionTypes.DELETE_EXPORT_TEMPLATE_SUCCESS: {
      return initialState.exportTemplateDetail;
    }
    default: {
      return state;
    }
  }
}
