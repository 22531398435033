import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
import TeamErrorModal from '@atom/components/common/teamErrorModal/TeamErrorModal';
import UserGroupContext from '@atom/components/userGroupDetail/UserGroupContext';
import { DELETE_USER_GROUP_BUDGET } from '@atom/graph/userGroup';
import { UserGroup, UserGroupBudgetDeleteInput } from '@atom/types/userGroups';

import './userGroupBudgets.css';

interface Props {
  open: boolean;
  onClose: () => void;
  budgetId: string;
}

const DeleteBudgetModal = ({ open, onClose, budgetId }: Props) => {
  const { userGroup } = useContext(UserGroupContext);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);

  const [deleteBudget, { loading, error }] = useMutation<
    { userGroupBudgetDelete: UserGroup },
    { input: UserGroupBudgetDeleteInput }
  >(DELETE_USER_GROUP_BUDGET);

  const confirm = () => {
    deleteBudget({
      variables: {
        input: {
          id: budgetId,
          userGroupId: userGroup.id,
        },
      },
    });

    onClose();
  };

  useEffect(() => {
    if (error) {
      // @ts-ignore
      const statusCode = error.networkError?.statusCode;

      if (statusCode === 422) {
        setErrorOpen(true);
      }
    }
  }, [error]);

  return (
    <>
      <DeleteModal
        title="Delete Budget?"
        onConfirm={confirm}
        onCancel={onClose}
        content="Are you sure you want to remove this budget?"
        loading={loading}
        open={open}
      />
      <TeamErrorModal
        open={errorOpen}
        onClose={() => setErrorOpen(false)}
        type="budget"
      />
    </>
  );
};

export default DeleteBudgetModal;
