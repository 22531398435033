import * as React from 'react';

import PriorityControl from '@atom/components/common/PriorityControl';
import WorkOrderStatusPill from '@atom/components/common/workOrderDetail/workOrderStatusPill/WorkOrderStatusPill';
import { MapWorkOrder } from '@atom/types/map';
import timeUtilities from '@atom/utilities/timeUtilities';

import './drawerContent.css';

interface Props {
  workOrder: MapWorkOrder;
  navigateToWorkOrder: (id: string) => void;
}

const WorkOrderListItem = ({ workOrder, navigateToWorkOrder }: Props) => {
  const {
    name,
    statusId,
    priorityId,
    dueDate,
    assigneeCount,
    taskCount,
    id,
  } = workOrder;

  return (
    <div styleName="list-item" onClick={(): void => navigateToWorkOrder(id)}>
      <div styleName="name">{name}</div>
      <div styleName="priority-status-container">
        <div styleName="priority-status-icon">
          <PriorityControl disabled priorityId={priorityId} />
        </div>
        <WorkOrderStatusPill
          statusId={statusId}
          className="dashboard-map-list"
        />
      </div>
      <div styleName="work-row-details-container">
        <div styleName="work-detail-content">
          Due Date: {timeUtilities.setDisplayDate(dueDate)}
        </div>
        <div styleName="work-detail-content">Team: {assigneeCount}</div>
        <div styleName="work-detail-content">Tasks: {taskCount}</div>
      </div>
    </div>
  );
};

export default WorkOrderListItem;
