import React, { useMemo, useState } from 'react';
import * as R from 'ramda';

import { useWorkValidations } from '@atom/hooks/useWorkValidations';
import { EventType } from '@atom/types/event';
import { FormAssetErrors } from '@atom/types/form';
import { AttributesType } from '@atom/types/inventory';
import {
  isNilOrEmpty,
  isNumericZero,
} from '@atom/utilities/validationUtilities';

import BooleanAttribute from './attributeInputs/BooleanAttribute';
import DateAttribute from './attributeInputs/DateAttribute';
import EnumMultipleAttribute from './attributeInputs/EnumMultipleAttribute';
import EnumSingleAttribute from './attributeInputs/EnumSingleAttribute';
import NumericAttribute from './attributeInputs/NumericAttribute';
import TextAttribute from './attributeInputs/TextAttribute';

import '../formInstance.css';

interface Props {
  attribute: AttributesType;
  isEditable: boolean;
  assetId: string;
  fieldId: string;
  attributeGroupName: string;
  progressiveUpdateFormInstanceAttribute: (
    fieldId: string,
    assetId: string,
    attributeGroupName: string,
    attributeId: string,
    body: Object,
  ) => void;
  savingFormInstance: boolean;
  assetErrors?: FormAssetErrors;
}

const InstanceAttributeField = ({
  attribute,
  isEditable,
  assetId,
  fieldId,
  attributeGroupName,
  progressiveUpdateFormInstanceAttribute,
  savingFormInstance,
  assetErrors,
}: Props) => {
  const { resolveInvalidFormAssetsError } = useWorkValidations();

  const [value, setValue] = useState<any>(
    isNumericZero(attribute.dataType, attribute.value) || attribute.value
      ? attribute.value
      : '',
  );

  const progressiveSaveAttribute = (newValue: any) => {
    const body = {
      value: newValue,
    };

    if (!isNilOrEmpty(newValue)) {
      resolveInvalidFormAssetsError(
        assetErrors.taskId,
        assetErrors.formId,
        assetErrors.pageNumber,
        fieldId,
        attribute.id,
      );
    }

    progressiveUpdateFormInstanceAttribute(
      fieldId,
      assetId,
      attributeGroupName,
      attribute.id,
      body,
    );
  };

  const onChange = (event: EventType) => {
    const dataType = event.target.name;
    let newValue = event.target.value;

    if (dataType === 'number' || dataType === 'currency') {
      newValue = Number(event.target.value);
    }

    progressiveSaveAttribute(newValue);
    setValue(newValue);
  };

  const onBooleanChange = (event: any) => {
    const newValue = event.target.value === 'true';

    progressiveSaveAttribute(newValue);
    setValue(newValue);
  };

  const onEnumChange = (event: any) => {
    const newValue = event.target.value;
    progressiveSaveAttribute(newValue);
    setValue(newValue);
  };

  const onDateChange = (dateMillis?: number) => {
    const newValue = R.isNil(dateMillis) ? '' : dateMillis;
    progressiveSaveAttribute(newValue);
    setValue(newValue);
  };

  const hasAssetAttributeError = useMemo(() => {
    return !!R.find(
      item =>
        item.fieldId === fieldId &&
        item.page === assetErrors.pageNumber &&
        item.attributeId === attribute.id,
      assetErrors.errors,
    );
  }, [attribute, fieldId, assetErrors]);

  const renderAttributeInput = () => {
    const isEditableWhileSaving = isEditable && !savingFormInstance;
    const props = {
      value,
      type: attribute.dataType,
      error: hasAssetAttributeError,
    };

    switch (attribute.dataType) {
      case 'longtext':
      case 'shorttext': {
        return (
          <TextAttribute
            {...props}
            onChange={onChange}
            isEditable={isEditable && attribute.isEditable}
          />
        );
      }
      case 'currency':
      case 'number': {
        return (
          <NumericAttribute
            {...props}
            onChange={onChange}
            unit={attribute.unit}
            isEditable={isEditable && attribute.isEditable}
          />
        );
      }
      case 'boolean': {
        return (
          <BooleanAttribute
            {...props}
            onChange={onBooleanChange}
            isEditable={isEditableWhileSaving && attribute.isEditable}
          />
        );
      }
      case 'date': {
        return (
          <DateAttribute
            {...props}
            onChange={onDateChange}
            isEditable={isEditableWhileSaving && attribute.isEditable}
          />
        );
      }
      case 'enumsingle': {
        return (
          <EnumSingleAttribute
            {...props}
            onChange={onEnumChange}
            options={attribute.enumeration}
            isEditable={isEditableWhileSaving && attribute.isEditable}
          />
        );
      }
      case 'enummultiple': {
        return (
          <EnumMultipleAttribute
            {...props}
            onChange={onEnumChange}
            options={attribute.enumeration}
            isEditable={isEditableWhileSaving && attribute.isEditable}
          />
        );
      }
      default: {
        return <div />;
      }
    }
  };

  const attributeInputs = renderAttributeInput();

  const attributeName = attribute.isRequired
    ? `* ${attribute.name}`
    : attribute.name;
  const labelStyle = hasAssetAttributeError
    ? 'attribute-name error'
    : 'attribute-name';

  return (
    <div styleName="attribute-field-block">
      <div styleName={labelStyle}>{attributeName}</div>
      {attributeInputs}
    </div>
  );
};

export default InstanceAttributeField;
