import React from 'react';

import { Checkbox } from '@atom/mui';

import './workExport.css';

interface Props {
  field: string;
  handleToggle: (id: string, field: string) => void;
  projectionId: string;
  selectedProjections: {
    [id: string]: Set<string>;
  };
  isCustomField: boolean;
}

const styles = {
  checkbox: {
    padding: 0,
  },
  label: {
    marginTop: '2px',
    paddingBottom: '0.5rem',
    paddingLeft: '0.5rem',
  },
};

const WorkExportFieldRow = ({
  field,
  handleToggle,
  projectionId,
  selectedProjections,
  isCustomField,
}: Props) => {
  const toggleCheck = () => {
    handleToggle(projectionId, field);
  };

  return (
    <div styleName="field-row">
      <Checkbox
        checked={selectedProjections[projectionId]?.has(field) || false}
        onClick={toggleCheck}
        style={styles.checkbox}
      />
      <div style={styles.label}>{isCustomField ? `${field} *` : field} </div>
    </div>
  );
};

export default WorkExportFieldRow;
