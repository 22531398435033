import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import LoadMoreButton from '@atom/components/common/loadMoreButton/LoadMoreButton';
import client from '@atom/graph/client';
import { GET_ROLES } from '@atom/graph/role';
import { Checkbox, Icon, Modal, Progress, TextField } from '@atom/mui';
import { Role, RolesConnection, RolesConnectionInput } from '@atom/types/role';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import './assignedRoles.css';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (roleIds: string[]) => void;
  loading: boolean;
  existingRoles: Set<string>;
}

const styles = {
  modal: {
    height: '20rem',
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
  },
  progress: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  close: {
    cursor: 'pointer',
  },
};

const LIMIT = 15;
const DEBOUNCE_TIME = 500;

const AssignRolesModal = ({
  open,
  onClose,
  onConfirm,
  existingRoles,
  loading: loadingAssign,
}: Props) => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>(null);
  const [page, setPage] = useState<number>(1);
  const [selected, setSelected] = useState<Set<string>>(existingRoles);
  const [name, setName] = useState<string>('');

  useEffect(() => {
    setSelected(existingRoles);
  }, [existingRoles]);

  const resetState = () => {
    setRoles([]);
    setTotalCount(0);
    setLoading(false);
    setError(null);
    setPage(1);
    setSelected(existingRoles);
    setName('');
  };

  const getRoles = useCallback(async (input: RolesConnectionInput) => {
    setLoading(true);

    const { data } = await client.query<
      { roles: RolesConnection },
      { input: RolesConnectionInput }
    >({
      query: GET_ROLES,
      variables: { input },
      fetchPolicy: 'network-only',
    });

    setRoles(current => [...current, ...data.roles.roles]);
    setTotalCount(data.roles.totalCount);
    setLoading(false);
  }, []);

  const getMoreRoles = async () => {
    try {
      await getRoles({
        page,
        sortBy: 'name',
        sortDirection: 'asc',
        limit: LIMIT,
        isDefault: false,
        ...(name.length && {
          name,
        }),
      });

      setError(null);
      setPage(R.inc);
    } catch (err) {
      setError(err);
    }
  };

  const debouncedSearch = useCallback(
    debounce((search: string) => {
      getRoles({
        name: search,
        page: 1,
        sortBy: 'name',
        sortDirection: 'asc',
        limit: LIMIT,
        isDefault: false,
      });
    }, DEBOUNCE_TIME),
    [],
  );

  const handleSearch = (value: string) => {
    setName(value);
    setRoles([]);
    setTotalCount(0);

    if (!value) {
      debouncedSearch.cancel();
      getRoles({ limit: LIMIT, page: 1, isDefault: false });
    } else {
      debouncedSearch(value);
    }

    setPage(2);
  };

  useEffect(() => {
    if (open) {
      getMoreRoles();
    }
  }, [open]);

  const hasMore = roles.length < totalCount;

  return (
    <Modal
      title="Assign Roles"
      open={open}
      onCancel={onClose}
      onConfirm={() => onConfirm([...selected])}
      confirmButtonText="Assign"
      contentStyle={styles.modal}
      onExited={resetState}
      loading={loadingAssign}
      disabled={[...selected].every(id => existingRoles.has(id))}
    >
      <TextField
        value={name}
        onChange={event => handleSearch(event.target.value)}
        placeholder="Search by role name"
        InputProps={{
          startAdornment: <Icon>search</Icon>,
          endAdornment: name && (
            <Icon style={styles.close} onClick={() => handleSearch('')}>
              close
            </Icon>
          ),
        }}
      />
      {loading && !roles.length ? (
        <div style={styles.progress}>
          <Progress />
        </div>
      ) : (
        <>
          {roles.map(role => (
            <div key={role.id} style={{ marginTop: '1rem' }}>
              <Checkbox
                checked={selected.has(role.id)}
                disabled={existingRoles.has(role.id) || role.isDefault}
                onChange={() => setSelected(toggleFromSet(selected, role.id))}
                label={
                  <div>
                    <div styleName="role-name">{role.name}</div>
                    <div styleName="role-description">{role.description}</div>
                  </div>
                }
              />
            </div>
          ))}
          {hasMore && (
            <LoadMoreButton
              loading={loading}
              error={!!error}
              onClick={getMoreRoles}
              style={{
                width: '100%',
                marginTop: '1rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            />
          )}
        </>
      )}
    </Modal>
  );
};

export default AssignRolesModal;
