import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { StringParam, useQueryParams } from 'use-query-params';

import AssetSummaryInfo from '@atom/components/common/map/mapDrawerContent/AssetSummaryInfo';
import AssetSummaryWork from '@atom/components/common/map/mapDrawerContent/AssetSummaryWork';
import NavigationButton from '@atom/components/common/map/mapDrawerContent/NavigationButton';
import NavigationControls from '@atom/components/common/map/mapDrawerContent/NavigationControls';
import { useWorkOrdersData } from '@atom/components/mapPortal/hooks/workOrdersDataHook';
import { GET_ASSET } from '@atom/graph/asset';
import { Icon, IconButton, Progress } from '@atom/mui';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';
import { getStaticImage } from '@atom/utilities/mapUtilities';

import './inventoryMap.css';

export interface Props {
  onClick: (id: string) => void;
}

const views = [
  {
    value: 'info',
    icon: 'info',
  },
  {
    value: 'work',
    icon: 'work',
  },
];

const styles = {
  iconContainer: {
    width: 24,
    height: 24,
    padding: 0,
  },
  progress: {
    height: '100%',
  },
};

const InventoryMapDetail = ({ onClick }: Props) => {
  const [queryParams, setQueryParams] = useQueryParams({ id: StringParam });
  const [view, setView] = useState<string>('info');
  const [workOrders, woLoading] = useWorkOrdersData({
    assetId: queryParams.id,
    statusIds: '1,2,3,4,5',
    sortBy: 'status',
  });

  const { loading, data, refetch } = useQuery(GET_ASSET, {
    variables: { id: queryParams.id },
    fetchPolicy: 'network-only',
  });

  const getSummaryImage = () => {
    return !data?.asset?.mainPhotoFileId
      ? getStaticImage(data?.asset?.location)
      : `${FILES_ENDPOINT}/${data?.asset?.mainPhotoFileId}_340`;
  };

  const getTrayContentBody = () => {
    switch (view) {
      case 'info': {
        return <AssetSummaryInfo asset={data?.asset} refetch={refetch} />;
      }
      case 'work': {
        return <AssetSummaryWork work={workOrders} asset={data?.asset} />;
      }
      default: {
        return <AssetSummaryInfo asset={data?.asset} refetch={refetch} />;
      }
    }
  };

  const body = getTrayContentBody();

  const setActiveView = activeView => {
    setView(activeView);
  };

  return (
    <div styleName="container">
      {loading || woLoading ? (
        <Progress style={styles.progress} />
      ) : (
        <div styleName="tray-container">
          <div styleName="header-container">
            <div styleName="header-title-container">
              <div styleName="header-title">{data?.asset.name}</div>
              <div styleName="exit-container">
                <IconButton
                  style={styles.iconContainer}
                  onClick={() => setQueryParams({ id: '' })}
                >
                  <Icon>close</Icon>
                </IconButton>
              </div>
            </div>
          </div>
          <div styleName="tray-content-container">
            <div
              styleName="summary-image-container"
              style={{
                backgroundImage: `url('${getSummaryImage()}')`,
              }}
            />
            <NavigationControls
              views={views}
              activeView={view}
              setActiveView={setActiveView}
            />
            <div>{body}</div>
          </div>
          <NavigationButton
            label="View Full Detail"
            onClick={() => onClick(queryParams.id)}
          />
        </div>
      )}
    </div>
  );
};

export default InventoryMapDetail;
