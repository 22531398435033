import React, { useEffect, useState } from 'react';
import * as R from 'ramda';

import { Icon, Progress } from '@atom/mui';
import { pciFormModule } from '@atom/selectors/formModuleSelectors';
import colors from '@atom/styles/colors';
import {
  FormInstanceFieldItem,
  PciModuleDataUpdate,
  PciModuleInspectionOptions,
  PciType,
} from '@atom/types/form';
import { InventoryAssetDetailType } from '@atom/types/inventory';
import { MediaItem } from '@atom/types/media';
import { SchemaPavementConditionInspection } from '@atom/types/schema';
import api from '@atom/utilities/api';
import { INVENTORY_SCHEMAS_ENDPOINT } from '@atom/utilities/endpoints';

import AcInspection from './AcInspection';
import NbiInspection from './NbiInspection';
import PciFieldDetails from './PciFieldDetails';

import './pci.css';

interface Props {
  formInstanceId: string;
  fieldId: string;
  field: FormInstanceFieldItem;
  pageName: string;
  inventoryAssetId: string;
  inventoryAsset: InventoryAssetDetailType;
  media: MediaItem[];
  onChange: (update: PciModuleDataUpdate) => void;
  removeFile: (mediaId: string, subjectId: string) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  preview: boolean;
  isEditable: boolean;
}

const getInspection = (pciType: PciType, props) => {
  switch (pciType) {
    case PciType.AC:
    case PciType.AC_OVER_PCC: {
      return <AcInspection {...props} />;
    }
    case PciType.PCC_NBI: {
      return <NbiInspection {...props} />;
    }
    default:
      return <div />;
  }
};

const EMPTY_OPTIONS: PciModuleInspectionOptions = {
  distress: [],
  severity: [],
};

const PciField = ({
  formInstanceId,
  fieldId,
  field,
  pageName,
  inventoryAssetId,
  inventoryAsset,
  media,
  preview,
  onChange,
  renameMedia,
  removeFile,
  isEditable,
}: Props) => {
  const { pci } = field;
  const [loadingSchemas, setLoadingSchemas] = useState<boolean>(preview);
  const [pciType, setPciType] = useState<PciType>(preview ? null : pci?.type);
  const [options, setOptions] = useState<PciModuleInspectionOptions>(
    preview ? EMPTY_OPTIONS : pci?.options,
  );
  const [schemas, setSchemas] = useState<SchemaPavementConditionInspection[]>(
    [],
  );

  useEffect(() => {
    const getPciTemplates = async () => {
      setLoadingSchemas(true);

      const { data } = await api.get(`${INVENTORY_SCHEMAS_ENDPOINT}/pci`);

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const options = R.pathOr({}, [0, 'options'], data);

      if (!R.isEmpty(options)) {
        const type: PciType = R.path([0], R.keys(options));
        // @ts-ignore
        setPciType(type);
        setOptions(options[type]);
      }

      setSchemas(data);
      setLoadingSchemas(false);
    };

    if (preview) {
      getPciTemplates();
    }
  }, []);

  if (loadingSchemas) {
    return (
      <div styleName="spinner-container">
        <Progress />
      </div>
    );
  }

  return (
    <>
      <div styleName="container">
        <div styleName="header">
          <div styleName="title-container">
            <Icon color={colors.brand.blue}>{pciFormModule.icon}</Icon>
            <div styleName="title">Pavement Condition Inspection (PCI)</div>
            <div>{pageName}</div>
          </div>
        </div>
        <PciFieldDetails
          fieldId={fieldId}
          field={field}
          inventoryAsset={inventoryAsset}
          preview={preview}
          schemas={schemas}
          pciType={pciType}
          onPciTypeChange={(updatedType, updatedOptions) => {
            setPciType(updatedType);
            setOptions(updatedOptions);
          }}
          onChange={onChange}
          isEditable={isEditable}
        />
        {getInspection(pciType, {
          formInstanceId,
          fieldId,
          field,
          inventoryAssetId,
          inventoryAsset,
          preview,
          media,
          onChange,
          options,
          renameMedia,
          removeFile,
          isEditable,
        })}
      </div>
    </>
  );
};

export default PciField;
