import * as R from 'ramda';

export const copySet = <T>(set: Set<T>): Set<T> => {
  return new Set<T>([...set]);
};

export const removeFromSet = <T>(set: Set<T>, target: T): Set<T> => {
  const copy = copySet(set);
  copy.delete(target);
  return copy;
};

export const addToSet = <T>(set: Set<T>, target: T): Set<T> => {
  const copy = copySet(set);
  copy.add(target);
  return copy;
};

export const toggleFromSet = <T>(set: Set<T>, target: T): Set<T> => {
  if (set.has(target)) {
    return removeFromSet(set, target);
  }

  return addToSet(set, target);
};

export const combineSets = <T>(setOne: Set<T>, setTwo: Set<T>): Set<T> =>
  new Set([...setOne, ...setTwo]);

export const diffSets = <T>(setOne: Set<T>, setTwo: Set<T>): Set<T> =>
  new Set(R.difference([...setOne], [...setTwo]));

export const equalSets = (set1, set2) => {
  if (set1.size !== set2.size) {
    return false;
  }
  for (const item of set1) {
    if (!set2.has(item)) {
      return false;
    }
  }
  return true;
};
