import React from 'react';

type Props = {
  isActive: Boolean;
  count: Number;
};

const WorkOrderGroupMarker = ({ isActive, count }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="50"
      height="39"
    >
      <defs>
        <linearGradient id="a" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopOpacity="0.209" />
          <stop offset="100%" />
        </linearGradient>
        <filter
          id="b"
          width="208.3%"
          height="424.8%"
          x="-54.1%"
          y="-162.4%"
          filterUnits="objectBoundingBox"
        >
          <feGaussianBlur in="SourceGraphic" stdDeviation="2.165" />
        </filter>
        <path
          id="d"
          d="M27 22l-5 6-5-6H2a2 2 0 01-2-2V2a2 2 0 012-2h40a2 2 0 012 2v18a2 2 0 01-2 2H27z"
        />
        <filter
          id="c"
          width="122.7%"
          height="135.7%"
          x="-11.4%"
          y="-21.4%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="-1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="1.5"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(3 4)">
        <ellipse
          cx="22"
          cy="28"
          fill="url(#a)"
          filter="url(#b)"
          opacity="0.498"
          rx="6"
          ry="2"
        />
        <use fill="#000" filter="url(#c)" xlinkHref="#d" />
        <use fill={isActive ? '#0283EA' : '#FFF'} xlinkHref="#d" />
        <path
          fill={isActive ? '#FFF' : '#0283EA'}
          d="M35.7 6.526c.721 0 1.3.562 1.3 1.263v6.948c0 .7-.578 1.263-1.3 1.263H25.3c-.722 0-1.3-.562-1.3-1.263l.006-6.948c0-.7.572-1.263 1.294-1.263h2.6V5.263c0-.7.579-1.263 1.3-1.263h2.6c.722 0 1.3.562 1.3 1.263v1.263h2.6zM32 6V5h-3v1h3zm5.859 3.553c0-.306.255-.553.57-.553.316 0 .571.247.571.553v6.052C39 16.928 37.893 18 36.527 18h-9.956a.562.562 0 01-.571-.553c0-.305.255-.552.57-.552h9.957c.736 0 1.332-.578 1.332-1.29V9.553z"
        />
        <text
          x={12}
          y={12}
          textAnchor="middle"
          alignmentBaseline="middle"
          color="#9209ae"
          fontSize="14px"
          fontFamily="Roboto, Arial, sans-serif"
          fontWeight="500"
          fill={isActive ? '#FFF' : '#0283EA'}
        >
          {count}
        </text>
      </g>
    </svg>
  );
};

export default WorkOrderGroupMarker;
