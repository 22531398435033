import React, { useState } from 'react';
import { match as Match } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_USER_GROUP } from '@atom/graph/userGroup';
import { Progress } from '@atom/mui';

import UserGroupHeader from './header/UserGroupHeader';
import UserGroupBudgets from './userGroupBudgets/UserGroupBudgets';
import UserGroupCerts from './userGroupCertifications/UserGroupCerts';
import UserGroupInfo from './userGroupInfo/UserGroupInfo';
import UserGroupRoles from './userGroupRoles/UserGroupRoles';
import UserGroupContext, { View } from './UserGroupContext';

import './userGroupDetail.css';

interface Props {
  match: Match<{ id: string }>;
}

const UserGroupDetail = ({ match }: Props) => {
  const [activeView, setActiveView] = useState<View>(View.INFO);

  const { loading, data, error, refetch } = useQuery(GET_USER_GROUP, {
    variables: {
      id: match.params.id,
    },
    fetchPolicy: 'network-only',
  });

  if (error || !data) {
    return <Progress style={{ height: '100%' }} />;
  }

  const getActiveView = () => {
    const views = {
      [View.INFO]: <UserGroupInfo />,
      [View.BUDGETS]: <UserGroupBudgets />,
      [View.CERTIFICATIONS]: <UserGroupCerts />,
      [View.ROLES]: <UserGroupRoles />,
    };

    return views[activeView];
  };

  const contextValue = {
    activeView,
    setActiveView,
    userGroup: data.userGroup,
    loading,
    refetch,
  };

  return (
    <UserGroupContext.Provider value={contextValue}>
      <div styleName="team-detail-container">
        <UserGroupHeader />
        <div styleName="content-container">
          <div styleName="center-pane-container">
            <div styleName="center-pane">{getActiveView()}</div>
          </div>
        </div>
      </div>
    </UserGroupContext.Provider>
  );
};

export default UserGroupDetail;
