import * as React from 'react';

import {
  ApplicationSearchInventoryState,
  ApplicationSearchUsersState,
  ApplicationSearchWorkOrdersState,
} from '@atom/types/store';

import InventorySearchResultsRows from './InventorySearchResultsRows';
import TeamSearchResultsRows from './TeamSearchResultsRows';
import WorkSearchResultsRows from './WorkSearchResultsRows';

import './searchPortal.css';

interface Props {
  query: string;
  users: ApplicationSearchUsersState[];
  workOrders: ApplicationSearchWorkOrdersState[];
  assets: ApplicationSearchInventoryState[];
}

const AllSearchResultsRows = ({ users, workOrders, assets, query }: Props) => {
  return (
    <React.Fragment>
      <InventorySearchResultsRows assets={assets} query={query} />
      <WorkSearchResultsRows workOrders={workOrders} query={query} />
      <TeamSearchResultsRows users={users} query={query} />
    </React.Fragment>
  );
};

export default AllSearchResultsRows;
