import * as React from 'react';

import './login.css';

const AuthLogo = ({ style }: any) => {
  return (
    <div styleName="logo-container">
      <img
        style={style}
        styleName="logo-image"
        src={require('../../images/login_logo.svg')}
        alt={'Atom logo'}
      />
    </div>
  );
};

export default AuthLogo;
