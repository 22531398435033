import React, { useEffect, useMemo, useRef } from 'react';
import { KmlLayer } from '@react-google-maps/api';
import * as R from 'ramda';

import { useKmlLayers } from '@atom/components/mapPortal/hooks/kmlHook';
import { useLayersValue } from '@atom/components/mapPortal/hooks/layersHook';
import { KmlLayer as KmlLayerType } from '@atom/types/map';

import KmlGeoXml3Layer from './KmlGeoXml3Layer';

interface Props {
  map: google.maps.Map;
}

const MemoizedKmlLayer = React.memo(
  KmlLayer,
  // memoize to only re-render on URL changes
  // polling is setup to modify the URL at the given polling interval
  // which will cause a re-render and a refetch
  (prevProps, nextProps) => prevProps.url === nextProps.url,
);

const updateLayerForPolling = (layerId: string) => (
  layers: KmlLayerType[],
): KmlLayerType[] => {
  return layers.map(layer =>
    layer.id === layerId
      ? { ...layer, url: `${layer.url}?rev=${Date.now()}` }
      : layer,
  );
};

const Kml = ({ map }: Props) => {
  // eslint-disable-next-line no-undef
  const pollingIntervalsRef = useRef<{ [layerId: string]: NodeJS.Timeout }>({});

  const { layers: allLayers, setLayers } = useKmlLayers();
  const {
    kmlLayerIds: selectedLayerIds,
    kmlLayers: kmlLayersEnabled,
  } = useLayersValue();

  const selectedLayers = useMemo(() => {
    return kmlLayersEnabled
      ? allLayers.filter(layer => selectedLayerIds.has(layer.id))
      : [];
  }, [allLayers, selectedLayerIds, kmlLayersEnabled]);

  useEffect(() => {
    const layerIdsWithPollingIntervals = new Set(
      selectedLayers
        .filter(layer => !!layer.pollingInterval)
        .map(layer => layer.id),
    );

    // get polling intervals to add
    const newPollingIntervals = selectedLayers.reduce((acc, layer) => {
      // polling is not needed or is already setup
      if (
        !layerIdsWithPollingIntervals.has(layer.id) ||
        pollingIntervalsRef.current[layer.id]
      ) {
        return acc;
      }

      const intervalId = setInterval(() => {
        setLayers(updateLayerForPolling(layer.id));
      }, layer.pollingInterval);

      return { ...acc, [layer.id]: intervalId };
    }, {});

    // get polling intervals to remove
    // this likely means layer was deselected from filters
    const pollingIntervalsToRemove = Object.entries(
      pollingIntervalsRef.current,
    ).reduce((acc, [layerId, intervalId]) => {
      if (!layerIdsWithPollingIntervals.has(layerId)) {
        clearInterval(intervalId);
        return [...acc, layerId];
      }

      return acc;
    }, []);

    // update the ref
    pollingIntervalsRef.current = R.omit(pollingIntervalsToRemove, {
      ...pollingIntervalsRef.current,
      ...newPollingIntervals,
    });
  }, [selectedLayers]);

  useEffect(() => {
    // clear all intervals on unmount
    return () => {
      // NOTE ref value should be referenced as pollingIntervalsRef.current
      // Destructuring or assigning to a new variable can cause side effects
      Object.values(pollingIntervalsRef.current).forEach(intervalId => {
        clearInterval(intervalId);
      });
    };
  }, []);

  return !map || !selectedLayers.length ? null : (
    <>
      {selectedLayers.map(layer =>
        layer.parser === 'GEOXML3' ? (
          <KmlGeoXml3Layer key={layer.id} map={map} layer={layer} />
        ) : (
          <MemoizedKmlLayer
            key={layer.url}
            url={layer.url}
            onLoad={() => {}}
            onUnmount={() => {}}
            options={{
              preserveViewport: true,
              suppressInfoWindows: layer.suppressInfoWindows,
            }}
          />
        ),
      )}
    </>
  );
};

export default Kml;
