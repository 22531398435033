import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';

import { requestDownloadFiles } from '@atom/actions/fileActions';
import PaginationControl from '@atom/components/common/PaginationControl';
import CarouselWrapper from '@atom/components/common/photoCarousel/CarouselWrapper';
import WorkOrderContext, {
  WorkOrderActionTypes,
} from '@atom/components/workOrderDetail/WorkOrderContext';
import textConstants from '@atom/constants/textConstants';
import {
  GET_MEDIA,
  MEDIA_BULK_DELETE,
  MEDIA_DELETE,
  MEDIA_UPDATE,
} from '@atom/graph/media';
import { WORK_ORDER_UPDATE } from '@atom/graph/work';
import { useFileUpload } from '@atom/hooks/useFileUpload';
import { useMediaSearch } from '@atom/hooks/useMediaSearch';
import { Snackbar } from '@atom/mui';
import {
  MediaBulkDeleteInput,
  MediaItem,
  MediaType,
  MediaUpdateInput,
  SubjectType,
} from '@atom/types/media';
import {
  WorkOrderDetailType,
  WorkOrderUpdate,
  WorkOrderUpdateInput,
} from '@atom/types/work';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import PhotosHeader from './PhotosHeader';
import PhotoTile from './PhotoTile';

import './workOrderPhotos.css';

const INITIAL_LIMIT = 25;
const CHARACTER_MIN = 3;

const WorkOrderPhotos = () => {
  const dispatch = useDispatch();
  const { workOrderDetail, dispatch: contextDispatch } = useContext(
    WorkOrderContext,
  );

  const [media, setMedia] = useState<MediaItem[]>([]);
  const [mediaTotal, setMediaTotal] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(INITIAL_LIMIT);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set([]));

  const [uploadFiles] = useFileUpload();

  const onCompleted = data => {
    setMedia(data?.media?.media);
    setMediaTotal(data?.media?.totalCount);
  };

  const { refetch, loading: loadingMedia } = useQuery(GET_MEDIA, {
    variables: {
      input: {
        parentSubjectIds: [
          workOrderDetail.id,
          ...R.keys(workOrderDetail.formInstances),
        ],
        type: MediaType.IMAGE,
        page,
        limit,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted,
  });

  const [mediaSearch, { loadingSearch }] = useMediaSearch({
    query: searchValue,
    parentSubjectIds: [
      workOrderDetail.id,
      ...R.keys(workOrderDetail.formInstances),
    ],
    type: MediaType.IMAGE,
    limit,
    characterMin: CHARACTER_MIN,
  });

  useEffect(() => {
    if (searchValue.length >= CHARACTER_MIN) {
      setMedia(mediaSearch.media);
      setMediaTotal(mediaSearch.totalCount);
    }
  }, [mediaSearch.media, mediaSearch.totalCount, searchValue]);

  const [updateWorkDetail] = useMutation<
    { workOrderUpdate: WorkOrderUpdate },
    { workOrder: WorkOrderUpdateInput }
  >(WORK_ORDER_UPDATE);

  const [mediaUpdate] = useMutation<
    { mediaUpdate: MediaItem },
    { input: MediaUpdateInput }
  >(MEDIA_UPDATE);

  const [mediaDelete, { loading: loadingDelete }] = useMutation<
    { mediaDelete: boolean },
    { id: string }
  >(MEDIA_DELETE);

  const [mediaBulkDelete] = useMutation<
    { mediaBulkDelete: boolean },
    { input: MediaBulkDeleteInput }
  >(MEDIA_BULK_DELETE);

  const updateWorkOrderProperty = (
    property: keyof WorkOrderDetailType,
    value: any,
  ) => {
    contextDispatch({
      type: WorkOrderActionTypes.UPDATE_WORK_ORDER_PROPERTY,
      data: {
        property,
        value,
      },
    });
  };

  const resetToFirstPage = () => {
    if (page === 1) {
      refetch({
        input: {
          parentSubjectIds: [
            workOrderDetail.id,
            ...R.keys(workOrderDetail.formInstances),
          ],
          type: MediaType.IMAGE,
          page: 1,
          limit,
        },
      });
    } else {
      setPage(1);
    }
  };

  const handleUpload = async event => {
    const { files } = event.target;

    if (!files.length) {
      return;
    }

    await uploadFiles({
      files,
      subjectId: workOrderDetail.id,
      subjectType: SubjectType.WORK_ORDER,
      parentSubjectId: workOrderDetail.id,
    });

    refetch();
  };

  const deleteMedia = async (id: string) => {
    try {
      Snackbar.info({
        message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
      });

      await mediaDelete({
        variables: {
          id,
        },
      });

      Snackbar.info({
        message: 'Successfully deleted file',
      });

      setSelectedItems(new Set([]));
      resetToFirstPage();
    } catch (err) {
      Snackbar.error({
        message: 'Failed to delete file',
      });
    }
  };

  const renameMedia = async (id: string, subjectId: string, name: string) => {
    const res = await mediaUpdate({
      variables: {
        input: {
          id,
          name,
        },
      },
    });

    const updatedMedia = media.map((mediaItem: MediaItem) => {
      return mediaItem.id === id ? res?.data?.mediaUpdate : mediaItem;
    });

    setMedia(updatedMedia);
  };

  const incrementPage = (increment: number) => {
    setPage(page + increment);
    setSelectedItems(new Set([]));
  };

  const updateLimit = (newLimit: string) => {
    setLimit(Number(newLimit));
    setSelectedItems(new Set([]));
    resetToFirstPage();
  };

  const onChange = (value: string) => {
    setSearchValue(value);
  };

  const clearSearch = async () => {
    setSearchValue('');
    setSelectedItems(new Set([]));
    resetToFirstPage();
  };

  const toggleCheckbox = (id: string) => {
    setSelectedItems(toggleFromSet(selectedItems, id));
  };

  const downloadMultipleFiles = () => {
    const selectedMedia = media.filter(medium =>
      [...selectedItems].includes(medium.id),
    );

    dispatch(requestDownloadFiles(selectedMedia));
  };

  const deleteMultipleFiles = async () => {
    await mediaBulkDelete({
      variables: {
        input: {
          ids: [...selectedItems],
        },
      },
    });

    setSelectedItems(new Set([]));
    resetToFirstPage();
  };

  const selectAll = () => {
    const mediaIds = media.map(medium => medium.id);
    setSelectedItems(new Set(mediaIds));
  };

  const setAsPrimaryMedia = async mediaId => {
    const res = await updateWorkDetail({
      variables: {
        workOrder: {
          id: workOrderDetail.id,
          mainPhotoId: workOrderDetail.mainPhotoId === mediaId ? '' : mediaId,
        },
      },
    });

    updateWorkOrderProperty(
      'mainPhotoId',
      res?.data?.workOrderUpdate.mainPhotoId,
    );
  };

  const isEditDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  return (
    <div styleName="body-container">
      <div styleName="center-pane">
        <PhotosHeader
          media={media}
          selectLimit={R.length(media)}
          searchValue={searchValue}
          clearSearch={clearSearch}
          onChange={event => onChange(event.target.value)}
          uploadFiles={handleUpload}
          downloadMultipleFiles={downloadMultipleFiles}
          deleteMultipleFiles={deleteMultipleFiles}
          selectAll={selectAll}
          deselectAll={() => setSelectedItems(new Set([]))}
          selectedItems={[...selectedItems]}
          loading={loadingSearch || loadingMedia}
        />
        <div styleName="photo-block">
          <div styleName="photo-tiles-container">
            {media.map((medium: MediaItem) => {
              const isChecked = selectedItems.has(medium.id);

              return (
                <CarouselWrapper
                  key={medium.id}
                  media={media}
                  renameMedia={renameMedia}
                  selectedMediaId={medium.id}
                  removeFile={deleteMedia}
                  subjectId={medium.subjectId}
                  toggleCheckbox={toggleCheckbox}
                  isChecked={isChecked}
                  setAsPrimaryMedia={setAsPrimaryMedia}
                  primaryMediaId={workOrderDetail.mainPhotoId}
                  canMarkAsCoverPhoto={!isEditDisabled}
                  canUpdateMedia={!isEditDisabled}
                  canDeleteMedia={!isEditDisabled}
                  refetchMedia={refetch}
                >
                  <PhotoTile
                    media={medium}
                    renameMedia={renameMedia}
                    deleteMedia={deleteMedia}
                    loadingDelete={loadingDelete}
                    isEditDisabled={isEditDisabled}
                    mainMediaId={workOrderDetail.mainPhotoId}
                    setAsPrimaryMedia={setAsPrimaryMedia}
                  />
                </CarouselWrapper>
              );
            })}
          </div>
        </div>
        {!!media.length && (
          <div styleName="pagination-container">
            <PaginationControl
              incrementPage={incrementPage}
              updateLimit={updateLimit}
              page={page}
              limit={limit}
              total={mediaTotal}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkOrderPhotos;
