import React from 'react';
import {
  TableFooter as MUITableFooter,
  TableFooterProps as MUITableFooterProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import layout from '@atom/styles/layout';

type TableFooterProps = {
  /**
   * width of the pagination component.
   * since it is absolutely positioned percentage widths reflect percentage of the entire viewport.
   * defaults to calc(100vw - layout.sidePaneWidth)
   */
  width?: string | number;
} & MUITableFooterProps;

const useStyles = makeStyles({
  root: ({
    width = `calc(100vw - ${layout.sidePaneWidth})`,
  }: TableFooterProps) => ({
    width,
    position: 'absolute',
    bottom: 0,
    background: 'white',
    display: 'flex',
    justifyContent: 'flex-end',
    overflow: 'hidden',
  }),
});

const TableFooter = (props: TableFooterProps) => {
  const classes = useStyles(props);

  return (
    <MUITableFooter
      classes={{ root: classes.root, ...props.classes }}
      {...props}
    />
  );
};

export default TableFooter;
