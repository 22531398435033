import * as React from 'react';
import * as R from 'ramda';

import { Button, Icon, IconButton, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import { EventType } from '@atom/types/event';
import { MediaItem } from '@atom/types/media';

import FhwaMediaTile from './FhwaMediaTile';

import '../formInstance.css';

const styles = {
  icon: {
    padding: '0px',
    height: '24px',
    width: '24px',
  },
};

interface PassedProps {
  isUploadDisabled: boolean;
  isEditable: boolean;
  media: MediaItem[];
  assetId: string;
  assetFileUpload: (event: EventType) => void;
  removeFile: (mediaId: string, subjectId: string) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
}

type Props = PassedProps;

interface State {
  open: boolean;
}

class FhwaMediaModal extends React.Component<Props, State> {
  state = {
    open: false,
  };

  closeModal = () => {
    this.setState({ open: false });
  };

  openModal = () => {
    this.setState({ open: true });
  };

  render() {
    const { open } = this.state;
    const {
      assetId,
      assetFileUpload,
      media,
      removeFile,
      renameMedia,
      isEditable,
      isUploadDisabled,
    } = this.props;

    const mediaCount = R.length(media);
    const photosIconColor = !R.isEmpty(media)
      ? colors.brand.blue
      : colors.neutral.silver;

    const footerButtons = (
      <>
        {isEditable && !isUploadDisabled && (
          <Button component="label" color="primary">
            Upload Photo
            <input
              type="file"
              hidden
              multiple
              // @ts-ignore
              onChange={assetFileUpload}
            />
          </Button>
        )}

        <Button onClick={this.closeModal} variant="contained" color="primary">
          Done
        </Button>
      </>
    );

    return (
      <React.Fragment>
        <IconButton style={styles.icon} onClick={this.openModal}>
          <Icon color={photosIconColor}>photo</Icon>
        </IconButton>
        <div styleName="column-suffix-text">{mediaCount}</div>
        <Modal
          title="Photos"
          open={open}
          footer={footerButtons}
          onCancel={this.closeModal}
        >
          <div styleName="fhwa-photo-modal-content">
            {media.map((medium: MediaItem) => (
              <FhwaMediaTile
                key={medium.id}
                media={medium}
                assetId={assetId}
                removeFile={removeFile}
                renameMedia={renameMedia}
              />
            ))}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default FhwaMediaModal;
