import React, { useState } from 'react';
import * as R from 'ramda';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { AnalyticsFolderTree as FolderTree } from '@atom/types/analytics';

import '../analyticsFolderTree.css';

const PADDING_OFFSET = 2;

const styles = {
  iconStyle: {
    fontSize: '24px',
    margin: 0,
  },
  blankStyle: {
    boxSizing: 'border-box',
    padding: '24px',
  },
  selected: {
    backgroundColor: colors.neutral.ash,
    borderLeft: `2px solid ${colors.brand.blue}`,
    marginLeft: '-2px',
  },
  hovering: {
    backgroundColor: colors.neutral.fog,
  },
  selectable: {
    cursor: 'pointer',
  },
  disabled: {
    color: colors.neutral.gray,
  },
};

export interface Props {
  folder: FolderTree;
  indentation: number;
  setFolder: (folder: FolderTree) => void;
  open: Set<string>;
  toggleOpen: (id: string) => void;
  selectedFolder: FolderTree;
  activeFolderId: string;
}

const SelectFolderRow = ({
  folder,
  indentation,
  setFolder,
  open,
  toggleOpen,
  selectedFolder,
  activeFolderId,
}: Props) => {
  const expanded = open.has(folder.id);
  const [hovering, setHovering] = useState(false);
  const arrowDirection = expanded ? 'arrow_drop_down' : 'arrow_right';
  const leftIcon = (
    <>
      {!R.isEmpty(folder.children) ? (
        <IconButton
          onClick={() => toggleOpen(folder.id)}
          style={styles.iconStyle}
          data-cy="openAnalyticsFolder"
        >
          <Icon color={colors.neutral.dim}>{arrowDirection}</Icon>
        </IconButton>
      ) : (
        <div style={styles.blankStyle} />
      )}
      <Icon style={styles.iconStyle} color={colors.neutral.gray}>
        folder
      </Icon>
    </>
  );

  const canSelect = folder.id !== 'analytics' && R.isEmpty(folder.children);
  const selected = canSelect && selectedFolder === folder ? 'selected' : '';

  const containerStyle = {
    paddingLeft: `calc(1rem + ${PADDING_OFFSET * indentation}rem)`,
    ...(!canSelect ? styles.disabled : {}),
    ...(canSelect ? styles.selectable : {}),
    ...(hovering && canSelect ? styles.hovering : {}),
    ...(selected ? styles.selected : {}),
  };

  const onClick = () => canSelect && setFolder(folder);

  const rowName = `${folder.name}${
    activeFolderId === folder.id ? ' (current folder)' : ''
  }`;

  return (
    <>
      <div
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        styleName="folder-row"
        style={containerStyle}
      >
        <div
          onClick={onClick}
          styleName="folder-name-container"
          data-cy="selectAnalyticsFolder"
        >
          {leftIcon}
          <div styleName="row-name-container">
            <div styleName="row-name">{rowName}</div>
          </div>
        </div>
      </div>
      {expanded ? (
        folder.children.map((child: FolderTree) => {
          return (
            <SelectFolderRow
              key={child.id}
              folder={child}
              indentation={indentation + 1}
              open={open}
              setFolder={setFolder}
              toggleOpen={toggleOpen}
              selectedFolder={selectedFolder}
              activeFolderId={activeFolderId}
            />
          );
        })
      ) : (
        <div />
      )}
    </>
  );
};

export default SelectFolderRow;
