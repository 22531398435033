import React, { useContext } from 'react';

import WorkTemplateCreationModalContext, {
  ModalStep,
} from '@atom/components/common/workTemplateCreationModal/WorkTemplateCreationModalContext';
import { Stepper } from '@atom/mui';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';

import DetailsView from './DetailsView';
import InheritanceView from './InheritanceView';
import TemplateTypeView from './TemplateTypeView';
import {
  MODAL_STEPS,
  MODAL_STEPS_ARRAY,
  MODAL_STEPS_ARRAY_NO_INHERITANCE,
} from './workTemplateOptions';

import './workTemplateCreationModal.css';

const { StepLabel, Step } = Stepper;

const MainCreationModalView = () => {
  const { activeStep } = useContext(WorkTemplateCreationModalContext);

  const getContent = () => {
    const content = {
      [ModalStep.DETAILS]: <DetailsView />,
      [ModalStep.TEMPLATE_TYPE]: <TemplateTypeView />,
      [ModalStep.INHERITANCE]: <InheritanceView />,
    };

    return content[activeStep];
  };

  const STEPS_ARRAY = isCurrentClient([Client.ALDOT])
    ? MODAL_STEPS_ARRAY_NO_INHERITANCE
    : MODAL_STEPS_ARRAY;

  return (
    <div styleName="modal-content">
      <div styleName="stepper-container">
        <Stepper
          activeStep={MODAL_STEPS[activeStep]?.index}
          orientation="vertical"
        >
          {STEPS_ARRAY.map(step => (
            <Step key={step.title}>
              <StepLabel>{step.title}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <div styleName="content-container">{getContent()}</div>
    </div>
  );
};

export default MainCreationModalView;
