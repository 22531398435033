import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import WorkSummaryTray from '@atom/components/mapPortal/workSummary/WorkSummaryTray';

import { useDetailsData } from '../hooks/detailsDataHook';

const WorkSummaryWrapper = () => {
  const [params, setParams] = useQueryParams({
    view: StringParam,
    id: StringParam,
  });

  const [data, loading] = useDetailsData(params.view, params.id);

  const onCancel = () => {
    setParams({
      view: 'list',
      id: '',
    });
  };

  return <WorkSummaryTray data={data} loading={loading} onCancel={onCancel} />;
};

export default WorkSummaryWrapper;
