import { gql } from '@apollo/client';

export const BASE_TASK_LOCATION = gql`
  fragment BaseTaskLocation on TaskLocation {
    id
    workOrderId
    taskId
    name
    geometry
    data {
      title
      dataType
      enumeration
      value
    }
    createdDate
    createdBy
    updatedDate
    updatedBy
  }
`;

export const GET_TASK_LOCATION = gql`
  query taskLocation($id: ID!) {
    taskLocation(id: $id) {
      ...BaseTaskLocation
    }
  }
  ${BASE_TASK_LOCATION}
`;

export const GET_TASK_LOCATIONS = gql`
  query taskLocations($input: TaskLocationsConnectionInput!) {
    taskLocations(input: $input) {
      totalCount
      taskLocations {
        ...BaseTaskLocation
      }
    }
  }
  ${BASE_TASK_LOCATION}
`;

export const TASK_LOCATION_CREATE = gql`
  mutation taskLocationCreate($input: TaskLocationCreateInput!) {
    taskLocationCreate(input: $input) {
      ...BaseTaskLocation
    }
  }
  ${BASE_TASK_LOCATION}
`;

export const TASK_LOCATION_DUPLICATE = gql`
  mutation taskLocationDuplicate($id: ID!) {
    taskLocationDuplicate(id: $id) {
      ...BaseTaskLocation
    }
  }
  ${BASE_TASK_LOCATION}
`;

export const TASK_LOCATION_UPDATE = gql`
  mutation taskLocationUpdate($input: TaskLocationUpdateInput!) {
    taskLocationUpdate(input: $input) {
      ...BaseTaskLocation
    }
  }
  ${BASE_TASK_LOCATION}
`;

export const TASK_LOCATION_DELETE = gql`
  mutation taskLocationDelete($id: ID!) {
    taskLocationDelete(id: $id)
  }
`;

export const TASK_LOCATION_DATA_AUTOCOMPLETE = gql`
  mutation taskLocationDataAutocomplete($input: TaskLocationSimpleInput!) {
    taskLocationDataAutocomplete(input: $input) {
      components {
        title
        dataType
        enumeration
        value
      }
      minMilePost
      maxMilePost
    }
  }
`;

export const TASK_LOCATION_FIND = gql`
  mutation taskLocationFind($input: TaskLocationSimpleInput!) {
    taskLocationFind(input: $input) {
      name
      geometry
    }
  }
`;
