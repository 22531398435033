import React, { useState } from 'react';

import DeleteModal from '@atom/components/common/DeleteModal';
// @ts-ignore
import taskTypeBlueIcon from '@atom/components/common/svgIcons/taskTypeBlue.svg';
// @ts-ignore
import taskTypeBlueGreyIcon from '@atom/components/common/svgIcons/taskTypeBlueGrey.svg';
import { Icon, IconButton } from '@atom/mui';
import { TaskTemplate } from '@atom/types/taskTemplate';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import './taskCreate.css';

interface Props {
  taskTemplate: TaskTemplate;
  selectedTaskTemplate: TaskTemplate;
  setSelectedTaskTemplate: (selectedTaskTemplate: TaskTemplate) => void;
  handleDelete: (id: string) => void;
  disableDelete?: boolean;
}

const TaskTemplateRow = ({
  taskTemplate,
  selectedTaskTemplate,
  setSelectedTaskTemplate,
  handleDelete,
  disableDelete,
}: Props) => {
  const [hover, setHover] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);

  const onDelete = () => {
    handleDelete(taskTemplate?.id);
  };

  const icon = taskTemplate?.applicable
    ? taskTypeBlueIcon
    : taskTypeBlueGreyIcon;

  const subtextStyles = `template-subtext ${
    taskTemplate?.applicable ? '' : 'disabled'
  }`;

  const selected = selectedTaskTemplate?.id === taskTemplate?.id;

  const toggleTaskTemplate = () => {
    if (!taskTemplate?.applicable) {
      return null;
    }

    if (selected) {
      return setSelectedTaskTemplate(null);
    }

    return setSelectedTaskTemplate(taskTemplate);
  };

  const titleStyles = () => {
    if (!taskTemplate?.applicable) {
      return 'template-title disabled';
    }

    if (selected) {
      return 'template-title selected';
    }

    return 'template-title';
  };

  const rowStyles = selected ? 'template-row-selected' : 'template-row';

  const showDelete =
    !disableDelete &&
    hover === taskTemplate?.id &&
    hasRolePermissions(ROLE_SETS.ORG_ADMIN);

  const getTaskTemplateSubtext = () => {
    if (taskTemplate?.rootSchemaName) {
      return taskTemplate.rootSchemaName;
    }

    if (taskTemplate?.multiAssetsEnabled) {
      return taskTemplate?.allowedMultiAssetSchemas
        .map(schema => schema?.name)
        .join(', ');
    }

    return '';
  };

  return (
    <>
      <div
        styleName={rowStyles}
        onMouseEnter={() => setHover(taskTemplate?.id)}
        onMouseLeave={() => setHover(null)}
        onClick={toggleTaskTemplate}
      >
        <div styleName="template-label-container">
          <img src={icon} />
          <div>
            <div styleName={titleStyles()}>{taskTemplate.name}</div>
            <div styleName={subtextStyles}>{getTaskTemplateSubtext()}</div>
          </div>
        </div>
        {showDelete ? (
          <IconButton onClick={() => setOpen(true)}>
            <Icon>delete</Icon>
          </IconButton>
        ) : (
          <div styleName="button-spacer" />
        )}
      </div>
      <DeleteModal
        title="Delete Task Template"
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={onDelete}
        confirmText="Delete"
        content="Are you sure you want to delete this task template?"
      />
    </>
  );
};

export default TaskTemplateRow;
