import React, { useContext, useMemo } from 'react';
import * as R from 'ramda';

import LocationsAndAssetsContext from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsContext';
import TaskItemTile from '@atom/components/common/workOrderDetail/taskItemTile/TaskItemTile';
import { useSchemas } from '@atom/hooks/useSchemas';
import { Button, Modal } from '@atom/mui';
import { getSchemaIconFromMarkerId } from '@atom/utilities/schemaUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './assetSection.css';

const styles = {
  icon: {
    height: '28px',
    width: '28px',
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
}

const AssetSettingsModal = ({ open, onClose }: Props) => {
  const { schemas = [] } = useSchemas();
  const { task } = useContext(LocationsAndAssetsContext);

  const allowedMultiAssetSchemaIds = R.pathOr(
    [],
    ['allowedMultiAssetSchemaIds'],
    task,
  );

  const requiredMultiAssetSchemaIds = R.pathOr(
    [],
    ['requiredMultiAssetSchemaIds'],
    task,
  );

  const allowedSchemas = useMemo(() => {
    return allowedMultiAssetSchemaIds.map(schemaId => {
      return R.find(R.propEq('id', schemaId))(schemas);
    });
  }, [allowedMultiAssetSchemaIds, schemas]);

  const requiredSchemas = useMemo(() => {
    return requiredMultiAssetSchemaIds.map(schemaId => {
      return R.find(R.propEq('id', schemaId))(schemas);
    });
  }, [requiredMultiAssetSchemaIds, schemas]);

  const requiredText = () => {
    switch (true) {
      case task?.requireAtLeastOneMultiAsset: {
        return (
          <span>
            <strong>At least 1 asset</strong> is required for the task to be
            completed or the work to be completed or closed.
          </span>
        );
      }
      case isNilOrEmpty(task?.requiredMultiAssetSchemaIds): {
        return (
          <span>
            <strong>No assets</strong> are required for the task to be completed
            or the work to be completed or closed.
          </span>
        );
      }
      default:
        return (
          <span>
            <strong>
              At least 1 asset from each of the following asset type(s)
            </strong>{' '}
            is required for the task to be completed or the work to be completed
            or closed.
          </span>
        );
    }
  };

  const allowedCount = allowedMultiAssetSchemaIds.length;

  const footer = (
    <div styleName="footer">
      <Button color="primary" variant="contained" onClick={onClose}>
        Ok
      </Button>
    </div>
  );

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title="Asset Settings"
      footer={footer}
    >
      <>
        <div>
          <div>
            <strong>{`${allowedCount} asset type(s) are `}</strong>
            <span>allowed for the task.</span>
          </div>
          <div styleName="schema-list">
            {allowedSchemas.map(schema => (
              <TaskItemTile
                key={`allowed-${schema?.id}`}
                content={R.pathOr('', ['name'], schema)}
                icon={
                  <img
                    style={styles.icon}
                    src={getSchemaIconFromMarkerId(schema?.markerId)}
                  />
                }
              />
            ))}
          </div>
        </div>
        <div>
          <div>{requiredText()}</div>
          <div styleName="schema-list">
            {requiredSchemas.map(schema => (
              <TaskItemTile
                key={`required-${schema?.id}`}
                content={R.pathOr('', ['name'], schema)}
                icon={
                  <img
                    style={styles.icon}
                    src={getSchemaIconFromMarkerId(schema?.markerId)}
                  />
                }
              />
            ))}
          </div>
        </div>
      </>
    </Modal>
  );
};

export default AssetSettingsModal;
