import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function usersTotalReducer(
  state = initialState.usersTotal,
  action,
) {
  switch (action.type) {
    case actionTypes.USERS_TOTAL_GET_SUCCESS: {
      return action.usersTotal ? action.usersTotal : 0;
    }
    default: {
      return state;
    }
  }
}
