import * as React from 'react';

import '../../styles/left-pane.css';

interface Props {
  onToggle: () => void;
  toggled: boolean;
}

const TopLevelAssetFilterToggle = ({ toggled, onToggle }: Props) => {
  return (
    <div styleName="asset-filter-toggle-container">
      <div
        styleName={`asset-filter-box all-inventory ${
          toggled ? 'selected' : ''
        }`}
        onClick={onToggle}
      >
        Inventory Info
      </div>
      <div
        styleName={`asset-filter-box ${toggled ? '' : 'selected'}`}
        onClick={onToggle}
      >
        Inventory + Sub Items
      </div>
    </div>
  );
};

export default TopLevelAssetFilterToggle;
