import actionTypes from './actionTypes';

export const retrieveInventorySchemas = data => ({
  type: actionTypes.REQUEST_INVENTORY_SCHEMAS,
  data,
});

export const getInventorySchemasSuccess = data => ({
  type: actionTypes.GET_INVENTORY_SCHEMAS_SUCCESS,
  data,
});

export const getInventorySchemasFailure = () => ({
  type: actionTypes.GET_INVENTORY_SCHEMAS_FAILURE,
});

export const retrieveInventorySchema = data => ({
  type: actionTypes.REQUEST_INVENTORY_SCHEMA,
  data,
});

export const getInventorySchemaSuccess = data => ({
  type: actionTypes.GET_INVENTORY_SCHEMA_SUCCESS,
  data,
});

export const getInventorySchemaFailure = () => ({
  type: actionTypes.GET_INVENTORY_SCHEMA_FAILURE,
});

export const retrieveInventorySchemaByAssetId = data => ({
  type: actionTypes.REQUEST_INVENTORY_SCHEMA_BY_ASSET_ID,
  data,
});

export const requestInventorySchemaClear = () => ({
  type: actionTypes.REQUEST_INVENTORY_SCHEMA_CLEAR,
});

export const clearInventorySchemaSuccess = () => ({
  type: actionTypes.CLEAR_INVENTORY_SCHEMA_SUCCESS,
});

export const retrieveInventorySchemaTreeNode = data => ({
  type: actionTypes.REQUEST_INVENTORY_SCHEMA_TREE_NODE,
  data,
});

export const getInventorySchemaTreeNodeSuccess = data => ({
  type: actionTypes.GET_INVENTORY_SCHEMA_TREE_NODE_SUCCESS,
  data,
});

export const getInventorySchemaTreeNodeFailure = () => ({
  type: actionTypes.GET_INVENTORY_SCHEMA_TREE_NODE_FAILURE,
});

export const requestSchemaTree = data => ({
  type: actionTypes.REQUEST_SCHEMA_TREE,
  data,
});

export const getSchemaTreeSuccess = data => ({
  type: actionTypes.GET_SCHEMA_TREE_SUCCESS,
  data,
});

export const getSchemaTreeFailure = () => ({
  type: actionTypes.GET_SCHEMA_TREE_FAILURE,
});

export const retrieveSchemaDetail = data => ({
  type: actionTypes.REQUEST_SCHEMA_DETAIL,
  data,
});

export const getSchemaDetailSuccess = data => ({
  type: actionTypes.GET_SCHEMA_DETAIL_SUCCESS,
  data,
});

export const getSchemaDetailFailure = () => ({
  type: actionTypes.GET_SCHEMA_DETAIL_FAILURE,
});

export const requestSchemaAttributeUpdate = data => ({
  type: actionTypes.REQUEST_SCHEMA_ATTRIBUTE_UPDATE,
  data,
});

export const schemaAttributeUpdateSuccess = data => ({
  type: actionTypes.SCHEMA_ATTRIBUTE_UPDATE_SUCCESS,
  data,
});

export const schemaAttributeUpdateFailure = () => ({
  type: actionTypes.SCHEMA_ATTRIBUTE_UPDATE_FAILURE,
});

export const requestSchemaTreeUpdate = data => ({
  type: actionTypes.REQUEST_SCHEMA_TREE_UPDATE,
  data,
});

export const updateSchemaTreeSuccess = data => ({
  type: actionTypes.UPDATE_SCHEMA_TREE_SUCCESS,
  data,
});

export const updateSchemaTreeFailure = () => ({
  type: actionTypes.UPDATE_SCHEMA_TREE_FAILURE,
});

export const requestSchemasByIds = data => ({
  type: actionTypes.REQUEST_SCHEMA_BY_IDS,
  data,
});

export const getSchemasByIdsSuccess = data => ({
  type: actionTypes.GET_SCHEMA_BY_IDS_SUCCESS,
  data,
});

export const getSchemasByIdsFailure = () => ({
  type: actionTypes.GET_SCHEMA_BY_IDS_FAILURE,
});

export const requestCreateSchema = data => ({
  type: actionTypes.REQUEST_CREATE_SCHEMA,
  data,
});

export const createSchemaSuccess = data => ({
  type: actionTypes.CREATE_SCHEMA_SUCCESS,
  data,
});

export const createSchemaFailure = () => ({
  type: actionTypes.CREATE_SCHEMA_FAILURE,
});

export const requestDeleteSchema = data => ({
  type: actionTypes.REQUEST_DELETE_SCHEMA,
  data,
});

export const deleteSchemaSuccess = data => ({
  type: actionTypes.DELETE_SCHEMA_SUCCESS,
  data,
});

export const deleteSchemaFailure = () => ({
  type: actionTypes.DELETE_SCHEMA_FAILURE,
});

export const requestUpdateSchema = data => ({
  type: actionTypes.REQUEST_UPDATE_SCHEMA,
  data,
});

export const updateSchemaSuccess = data => ({
  type: actionTypes.UPDATE_SCHEMA_SUCCESS,
  data,
});

export const updateSchemaFailure = () => ({
  type: actionTypes.UPDATE_SCHEMA_FAILURE,
});

export const requestUpdateGroup = data => ({
  type: actionTypes.REQUEST_UPDATE_GROUP,
  data,
});

export const updateGroupSuccess = data => ({
  type: actionTypes.UPDATE_GROUP_SUCCESS,
  data,
});

export const updateGroupFailure = () => ({
  type: actionTypes.UPDATE_GROUP_FAILURE,
});

export const requestDeleteGroup = data => ({
  type: actionTypes.REQUEST_DELETE_GROUP,
  data,
});

export const deleteGroupSuccess = data => ({
  type: actionTypes.DELETE_GROUP_SUCCESS,
  data,
});

export const deleteGroupFailure = () => ({
  type: actionTypes.DELETE_GROUP_FAILURE,
});

export const requestCreateAttributeGroup = data => ({
  type: actionTypes.REQUEST_CREATE_ATTRIBUTE_GROUP,
  data,
});

export const createAttributeGroupSuccess = data => ({
  type: actionTypes.CREATE_ATTRIBUTE_GROUP_SUCCESS,
  data,
});

export const createAttributeGroupFailure = () => ({
  type: actionTypes.CREATE_ATTRIBUTE_GROUP_FAILURE,
});

export const requestUpdateAttributeGroup = data => ({
  type: actionTypes.REQUEST_UPDATE_ATTRIBUTE_GROUP,
  data,
});

export const updateAttributeGroupSuccess = data => ({
  type: actionTypes.UPDATE_ATTRIBUTE_GROUP_SUCCESS,
  data,
});

export const updateAttributeGroupFailure = () => ({
  type: actionTypes.UPDATE_ATTRIBUTE_GROUP_FAILURE,
});

export const requestDeleteAttributeGroup = data => ({
  type: actionTypes.REQUEST_DELETE_ATTRIBUTE_GROUP,
  data,
});

export const deleteAttributeGroupSuccess = data => ({
  type: actionTypes.DELETE_ATTRIBUTE_GROUP_SUCCESS,
  data,
});

export const deleteAttributeGroupFailure = () => ({
  type: actionTypes.DELETE_ATTRIBUTE_GROUP_FAILURE,
});

export const requestCreateAttribute = data => ({
  type: actionTypes.REQUEST_CREATE_ATTRIBUTE,
  data,
});

export const createAttributeSuccess = data => ({
  type: actionTypes.CREATE_ATTRIBUTE_SUCCESS,
  data,
});

export const createAttributeFailure = () => ({
  type: actionTypes.CREATE_ATTRIBUTE_FAILURE,
});

export const requestUpdateAttribute = data => ({
  type: actionTypes.REQUEST_UPDATE_ATTRIBUTE,
  data,
});

export const updateAttributeSuccess = data => ({
  type: actionTypes.UPDATE_ATTRIBUTE_SUCCESS,
  data,
});

export const updateAttributeFailure = () => ({
  type: actionTypes.UPDATE_ATTRIBUTE_FAILURE,
});

export const requestDeleteAttribute = data => ({
  type: actionTypes.REQUEST_DELETE_ATTRIBUTE,
  data,
});

export const deleteAttributeSuccess = data => ({
  type: actionTypes.DELETE_ATTRIBUTE_SUCCESS,
  data,
});

export const deleteAttributeFailure = () => ({
  type: actionTypes.DELETE_ATTRIBUTE_FAILURE,
});
