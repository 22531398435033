import React, { useState } from 'react';
import { match as Match } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_ROLE } from '@atom/graph/role';
import { Progress } from '@atom/mui';
import { PoliciesConnectionInput } from '@atom/types/policy';
import {
  RoleAssigneesConnectionInput,
  RoleDetail as RoleDetailType,
} from '@atom/types/role';

import RoleHeader from './header/RoleHeader';
import RoleAssignees from './roleAssignees/RoleAssignees';
import RolePolicies from './rolePolicies/RolePolicies';
import RoleContext from './RoleContext';

import './roleDetail.css';

enum View {
  POLICIES,
  ASSIGNEES,
}

interface Props {
  match: Match<{ id: string }>;
}
const RoleDetail = ({ match }: Props) => {
  const [view, setView] = useState<View>(View.POLICIES);

  const [assigneesInput, setAssigneesInput] = useState<
    RoleAssigneesConnectionInput
  >({
    page: 1,
    limit: 15,
    sortBy: 'name',
    sortDirection: 'asc',
  });

  const [policiesInput, setPoliciesInput] = useState<PoliciesConnectionInput>({
    page: 1,
    limit: 15,
    sortBy: 'createdDate',
    sortDirection: 'asc',
  });

  const { data, error, loading, refetch } = useQuery<
    { role: RoleDetailType },
    {
      id: string;
      assigneesInput: RoleAssigneesConnectionInput;
      policiesInput: PoliciesConnectionInput;
    }
  >(GET_ROLE, {
    variables: {
      id: match.params.id,
      assigneesInput,
      policiesInput,
    },
    fetchPolicy: 'network-only',
  });

  if (error || !data) {
    return (
      <div styleName="spinner-container">
        <Progress />
      </div>
    );
  }

  const getActiveView = () => {
    const views = {
      [View.POLICIES]: <RolePolicies />,
      [View.ASSIGNEES]: <RoleAssignees />,
    };

    return views[view];
  };

  const { role } = data;

  return (
    <RoleContext.Provider
      value={{
        role,
        refetch,
        loading,
        assigneesInput,
        setAssigneesInput,
        policiesInput,
        setPoliciesInput,
        view,
        setView,
      }}
    >
      <div styleName="container">
        <RoleHeader />
        <div styleName="content-container">
          <div styleName="center-pane-container">
            <div styleName="center-pane">{getActiveView()}</div>
          </div>
        </div>
      </div>
    </RoleContext.Provider>
  );
};

export default RoleDetail;
