import React from 'react';

import colors from '@atom/styles/colors';
import { FormFieldType } from '@atom/types/form';
import { isValidUrl } from '@atom/utilities/validationUtilities';

import '../formInstance.css';

interface Props {
  field: FormFieldType;
}

const styles = {
  iframeContainer: {
    position: 'relative',
    paddingBottom: '56.25%',
    paddingTop: '2.5rem',
    height: 0,
    marginBottom: '3rem',
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: `1px solid ${colors.neutral.ash}`,
  },
};

const IFrameField = ({ field }: Props) => {
  const { title, url, width } = field;

  return (
    <>
      <div styleName="iframe-title">{title}</div>
      <div styleName="iframe-preview">
        <div style={{ width: `${width || 100}%` }}>
          <div style={styles.iframeContainer}>
            {isValidUrl(url) ? (
              <iframe style={styles.iframe} src={url} frameBorder={0} />
            ) : (
              <div
                style={{ ...styles.iframe, background: colors.neutral.ash }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default IFrameField;
