import React from 'react';
import { Marker } from '@react-google-maps/api';
import * as R from 'ramda';

const Z_INDEX = 498;

const getPositionFromString = (stringPosition: string) => {
  if (stringPosition) {
    const data = stringPosition.split(',');
    return {
      lat: Number(data[0]),
      lng: Number(data[1]),
    };
  }

  return {};
};

type Props = {
  center: string;
};

const MapPinMarker = (props: Props) => {
  const position = getPositionFromString(props.center);

  // @ts-ignore
  return <Marker position={position} zIndex={Z_INDEX} />;
};

export default React.memo(MapPinMarker, R.eqProps('center'));
