import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import { GET_BUDGET_UNIT_TREE, SEARCH_BUDGET_UNITS } from '@atom/graph/budget';
import { Icon, Popover, Progress, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  BudgetUnitSearch,
  BudgetUnitSearchConnection,
  BudgetUnitSearchInput,
  BudgetUnitTree,
} from '@atom/types/budget';

import BudgetDetailContext from './BudgetDetailContext';
import BudgetDetailUnitSearchResults from './BudgetDetailUnitSearchResults';
import BudgetDetailUnitTree from './BudgetDetailUnitTree';
import { DEBOUNCE_TIME, POPOVER_HEIGHT } from './budgetDetailUtils';

import './budgetDetail.css';

const SEARCH_RESULTS_LIMIT = 25;
const MIN_SEARCH_CHARS = 2;

const styles = {
  popover: {
    height: POPOVER_HEIGHT,
    width: '23rem',
  },
  searchInput: {
    width: '20rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    margin: '0.5rem 0.5rem 0 0.5rem',
    borderBottom: `1px solid ${colors.neutral.gray}`,
    color: colors.neutral.gray,
  },
  searchInputClear: {
    cursor: 'pointer',
  },
  progress: { marginLeft: '0.5rem' },
};

const BudgetDetailUnitTreeNav = () => {
  const anchor = useRef();
  const {
    budget,
    getParentUnit,
    parentBudgetUnit,
    categoryIds,
    budgetItemTemplateNames,
  } = useContext(BudgetDetailContext);

  const [collapsed, setCollapsed] = useState<Set<string>>(new Set([]));
  const [open, setOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<BudgetUnitSearch[]>(null);
  const [searchPage, setSearchPage] = useState<number>(1);
  const [searchTotal, setSearchTotal] = useState<number>(0);

  useEffect(() => {
    if (open) {
      setQuery('');
    }
  }, [open]);

  const [
    searchUnits,
    { data: searchData, loading: loadingSearch },
  ] = useLazyQuery<
    { budgetUnitSearch: BudgetUnitSearchConnection },
    { input: BudgetUnitSearchInput }
  >(SEARCH_BUDGET_UNITS, { fetchPolicy: 'network-only' });

  useEffect(() => {
    const nextResults = R.pathOr(
      [],
      ['budgetUnitSearch', 'budgetUnits'],
      searchData,
    );
    const newTotal = searchData?.budgetUnitSearch?.totalCount || 0;
    const newResults =
      searchPage === 1
        ? nextResults
        : [...(searchResults || []), ...nextResults];
    setSearchResults(newResults);
    setSearchTotal(newTotal);
  }, [searchData]);

  const handleNavigateToUnit = (unitId: string) => {
    if (unitId !== parentBudgetUnit.id) {
      getParentUnit({
        variables: {
          input: {
            budgetId: budget?.id,
            budgetUnitId: unitId,
            categoryIds,
            budgetItemTemplateNames,
          },
        },
      });
      setOpen(false);
    }
  };

  const searchUnitsDebounced = useCallback(
    debounce((queryString: string) => {
      setSearchPage(1);
      searchUnits({
        variables: {
          input: {
            budgetId: budget.id,
            query: queryString,
            page: searchPage,
            limit: SEARCH_RESULTS_LIMIT,
          },
        },
      });
    }, DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    if (query.length > 1) {
      searchUnitsDebounced(query);
    } else {
      setSearchResults(null);
    }
  }, [query]);

  const { data: treeData, loading: loadingTree } = useQuery<
    { budgetUnitTree: BudgetUnitTree },
    { id: string }
  >(GET_BUDGET_UNIT_TREE, {
    variables: { id: budget?.id },
  });
  const tree: BudgetUnitTree = useMemo(
    () => R.pathOr(null, ['budgetUnitTree'], treeData),
    [treeData],
  );

  const handleSearchPageScroll = (nextPage: number) => {
    setSearchPage(nextPage);
    searchUnits({
      variables: {
        input: {
          budgetId: budget.id,
          query,
          page: nextPage,
          limit: SEARCH_RESULTS_LIMIT,
        },
      },
    });
  };

  return R.isNil(tree) || loadingTree ? (
    <Progress style={styles.progress} size={10} />
  ) : (
    <div ref={anchor}>
      <Icon styleName="clickable" onClick={() => setOpen(prev => !prev)}>
        arrow_drop_down
      </Icon>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        style={styles.popover}
        anchorEl={anchor.current}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <div style={styles.searchInput}>
          <Icon>search</Icon>
          <TextField
            placeholder="Search"
            value={query}
            onChange={event => setQuery(event.target.value)}
            disableUnderline
          />
          {query.length > 0 && (
            <Icon style={styles.searchInputClear} onClick={() => setQuery('')}>
              close
            </Icon>
          )}
        </div>
        {R.isNil(searchResults) || query.length < MIN_SEARCH_CHARS ? (
          <BudgetDetailUnitTree
            tree={tree}
            handleNavigateToUnit={handleNavigateToUnit}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
        ) : (
          <BudgetDetailUnitSearchResults
            loadingSearch={loadingSearch}
            searchResults={searchResults}
            handleNavigateToUnit={handleNavigateToUnit}
            handlePageScroll={handleSearchPageScroll}
            page={searchPage}
            total={searchTotal}
          />
        )}
      </Popover>
    </div>
  );
};

export default BudgetDetailUnitTreeNav;
