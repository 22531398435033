import { gql } from '@apollo/client';

export const BASE_FIELD_FILTER = gql`
  fragment BaseFieldFilter on FieldFilter {
    id
    valueStart
    valueEnd
    value
    values
  }
`;

export const BASE_WORK_FILTER = gql`
  fragment BaseWorkFilter on WorkFilter {
    id
    name
    isDefault
    workDatePresets {
      dueDatePreset
      startDatePreset
      completionDatePreset
      createdDatePreset
    }
    taskDatePresets {
      dueDatePreset
      startDatePreset
      completionDatePreset
    }
    params {
      sortBy
      page
      limit
      dueDateStart
      dueDateEnd
      withoutDueDate
      completionDateStart
      completionDateEnd
      categoryIds
      assetId
      assetSchemaId
      statusIds
      priorityIds
      workTemplateIds
      userIds
      groupIds
      completedByIds
      hasForms
      hasWorkTemplate
      name
      createdDateStart
      createdDateEnd
      createdByIds
      startDateStart
      startDateEnd
      durationStart
      durationEnd
      workTemplateFolderIds
      fields {
        ...BaseFieldFilter
      }
      tasks {
        dueDateStart
        dueDateEnd
        withoutDueDate
        startDateStart
        startDateEnd
        completionDateStart
        completionDateEnd
        durationStart
        durationEnd
        commonFields {
          ...BaseFieldFilter
        }
        each {
          id
          fields {
            ...BaseFieldFilter
          }
        }
      }
    }
  }
  ${BASE_FIELD_FILTER}
`;

export const GET_WORK_FILTER = gql`
  query workFilter($id: ID!) {
    workFilter(id: $id) {
      ...BaseWorkFilter
    }
  }
  ${BASE_WORK_FILTER}
`;

export const GET_WORK_FILTERS = gql`
  query workFilters($input: WorkFiltersConnectionInput!) {
    workFilters(input: $input) {
      totalCount
      workFilters {
        ...BaseWorkFilter
      }
    }
  }
  ${BASE_WORK_FILTER}
`;

export const WORK_FILTER_CREATE = gql`
  mutation workFilterCreate($input: WorkFilterCreateInput!) {
    workFilterCreate(input: $input) {
      ...BaseWorkFilter
    }
  }
  ${BASE_WORK_FILTER}
`;

export const WORK_FILTER_UPDATE = gql`
  mutation workFilterUpdate($input: WorkFilterUpdateInput!) {
    workFilterUpdate(input: $input) {
      ...BaseWorkFilter
    }
  }
  ${BASE_WORK_FILTER}
`;

export const WORK_FILTER_DELETE = gql`
  mutation workFilterDelete($id: ID!) {
    workFilterDelete(id: $id)
  }
`;

export const GET_CUSTOM_FIELD_FILTERS = gql`
  query customFieldFilters($input: CustomFieldFiltersConnectionInput!) {
    customFieldFilters(input: $input) {
      totalCount
      customFieldFilters {
        id
        dataType
        title
        enumeration
        units
      }
    }
  }
`;
