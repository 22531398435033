import React, { useState } from 'react';
import {
  DatePicker as KeyboardDatePicker,
  DatePickerProps as KeyboardDatePickerProps,
} from '@mui/lab';
import { makeStyles } from '@mui/styles';

import { TextField, TextFieldProps as TextFieldPropsType } from '@atom/mui';
import colors from '@atom/styles/colors';

type CustomProps = {
  /**
   * style to be applied to the input container
   */
  style?: React.CSSProperties;
  /**
   * if input should be full width of container
   * defaults to true
   */
  fullWidth?: boolean;
  /**
   * Fix label to "floating" position (focused)
   * defaults to true
   */
  floatingLabelFixed?: boolean;
  /**
   * Optional placeholder label
   */
  placeholder?: string;
  /**
   * Denotes that the field is required
   */
  required?: boolean;
  /**
   * date format (defaults to "MM/dd/yyyy")
   */
  format?: string;
  /**
   * Optional props to pass to the internal TextField component
   */
  TextFieldProps?: TextFieldPropsType;
  /**
   * Provide an error message when error passed down to TextField.
   */
  errorHelperText?: string;
  /**
   * Styles for the TextField label on the default state.
   */
  labelStyle?: React.CSSProperties;
  /**
   * Styles for the TextField label on the focused state.
   */
  focusedStyle?: React.CSSProperties;
  /**
   * method for onBlur of the inner textfield
   */
  onBlur?: (event) => void;
};

export type DatePickerProps = CustomProps &
  Omit<KeyboardDatePickerProps, 'renderInput'>;

const styles = {
  label: {
    position: 'unset',
    color: colors.neutral.gray,
  },
  focused: {
    color: `${colors.neutral.gray} !important`,
  },
};

const useStyles = makeStyles(theme => ({
  inputLabel: (props: any) => props.labelStyle || styles.label,
  inputLabelFocused: (props: any) => props.focusedStyle || styles.focused,
  inputLabelError: {
    color: `${theme.palette.error.main} !important`,
  },
  inputIcon: {
    '& button': {
      color: colors.neutral.gray,
    },
  },
  inputIconHidden: {
    '& button': {
      display: 'none',
    },
  },
}));

const defaultStyle: React.CSSProperties = {
  width: '100%',
};

export const DatePicker = (props: DatePickerProps) => {
  const classes = useStyles(props);

  const [hovered, setHovered] = useState<boolean>(false);

  const {
    style,
    placeholder,
    required,
    TextFieldProps,
    fullWidth = true,
    onBlur = () => {},
    floatingLabelFixed = true,
    format = 'MM/dd/yyyy',
    errorHelperText,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    labelStyle: _,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    focusedStyle: _2,
    ...data
  } = props;

  return (
    <div style={{ ...defaultStyle, ...style }}>
      <KeyboardDatePicker
        {...data}
        fullWidth={fullWidth}
        label={props.label}
        inputFormat={format}
        renderInput={textFieldProps => (
          <TextField
            {...textFieldProps}
            {...TextFieldProps}
            floatingLabelFixed={floatingLabelFixed}
            placeholder={placeholder}
            required={required}
            onBlur={onBlur}
            error={textFieldProps?.error || TextFieldProps?.error}
            helperText={
              (textFieldProps?.error || TextFieldProps?.error) &&
              errorHelperText
            }
            InputLabelProps={{
              ...textFieldProps?.InputLabelProps,
              ...TextFieldProps?.InputLabelProps,
              classes: {
                root: classes.inputLabel,
                focused: classes.inputLabelFocused,
                error: classes.inputLabelError,
                ...textFieldProps?.InputLabelProps?.classes,
                ...TextFieldProps?.InputLabelProps?.classes,
              },
            }}
            InputProps={{
              ...textFieldProps?.InputProps,
              ...TextFieldProps?.InputProps,
              onMouseEnter: () => setHovered(true),
              onMouseLeave: () => setHovered(false),
            }}
          />
        )}
        InputAdornmentProps={{
          ...props.InputAdornmentProps,
          classes: {
            root:
              hovered && !props.disabled
                ? classes.inputIcon
                : classes.inputIconHidden,
            ...props.InputAdornmentProps?.classes,
          },
        }}
      />
    </div>
  );
};

export default DatePicker;
