import * as R from 'ramda';
export const hostLocation = window.location.host;
const protocol = window.location.protocol;
export const baseEndpoint = `${protocol}//${hostLocation}`;
export const domain = R.pipe(
  R.split('.'),
  R.drop(1),
  R.prepend(`${protocol}//www`),
  R.join('.'),
)(hostLocation);

export const subdomain = window.location.host.split('.')[2]
  ? window.location.host.split('.')[0]
  : false;

export const redirectDomain = `${protocol}//www.${hostLocation}`;

export const CURRENT_SUBDOMAIN = window.location.hostname.split('.')[0];
export const CALLBACK_ENDPOINT = `${domain}/api/v1/auth/callback`;
export const TOKEN_EXCHANGE_ENDPOINT = `${baseEndpoint}/api/v1/auth/token`;
export const VERIFICATION_EMAIL_ENDPOINT = `${baseEndpoint}/api/v1/auth/email-verification`;
export const PASSWORD_RESET_ENDPOINT = `${baseEndpoint}/api/v1/auth/password-reset-email`;
export const TENANT_ENDPOINT = `${baseEndpoint}/api/v1/tenants`;
export const WORK_ORDERS_ENDPOINT = `${baseEndpoint}/api/v1/workOrders`;
export const BULK_WORK_ORDERS_ENDPOINT = `${baseEndpoint}/api/v1/bulk/workOrders`;
export const BULK_WORK_ORDERS_FROM_FILTER_ENDPOINT = `${baseEndpoint}/api/v1/bulk-filter/workOrders`;
export const MAP_ENDPOINT = `${baseEndpoint}/api/v1/map`;
export const SEARCH_ENDPOINT = `${baseEndpoint}/api/v1/search`;
export const REGISTRATION_ENDPOINT = `${baseEndpoint}/api/v1/registration`;
export const USER_PROFILE_ENDPOINT = `${baseEndpoint}/api/v1/profile`;
export const USERS_ENDPOINT = `${baseEndpoint}/api/v1/users`;
export const FORM_TEMPLATES_ENDPOINT = `${baseEndpoint}/api/v1/formTemplates`;
export const FORM_INSTANCES_ENDPOINT = `${baseEndpoint}/api/v1/formInstances`;
export const BULK_EDIT_ASSETS_TEMPLATE_ENDPOINT = `${baseEndpoint}/api/v1/inventory/bulkEdit/assets/template`;
export const CATEGORIES_ENDPOINT = `${baseEndpoint}/api/v1/inventory/categories`;
export const INVENTORY_BULK_ASSETS_ENDPOINT = `${baseEndpoint}/api/v1/inventory/bulk/assets`;
export const INVENTORY_ASSETS_ENDPOINT = `${baseEndpoint}/api/v1/inventory/assets`;
export const INVENTORY_ASSETS_FILTER_ENDPOINT = `${baseEndpoint}/api/v1/inventory/assets-filter`;
export const INVENTORY_MUTCD_ENDPOINT = `${baseEndpoint}/api/v1/inventory/mutcd`;
export const INVENTORY_SCHEMAS_ENDPOINT = `${baseEndpoint}/api/v1/schemas`;
export const INVENTORY_REPORT_ENDPOINT = `${baseEndpoint}/api/v1/inventory/report`;
export const INVENTORY_EXPORT_ENDPOINT = `${baseEndpoint}/api/v1/inventory/assets/exportTemplates`;
export const POOL_ATTRIBUTES_ENDPOINT = `${baseEndpoint}/api/v1/poolAttributes`;
export const FILES_ENDPOINT = `${baseEndpoint}/api/v1/files`;
export const FILES_ARCHIVE_ENDPOINT = `${baseEndpoint}/api/v1/files-archive`;
export const FILES_CSV_UPLOAD_ENDPOINT = `${baseEndpoint}/api/v1/files-csv-upload`;
export const FILES_INVENTORY_CSV_DOWNLOAD_ENDPOINT = `${baseEndpoint}/api/v1/files-csv-download/inventory`;
export const FILES_WORK_CSV_DOWNLOAD_ENDPOINT = `${baseEndpoint}/api/v1/files-csv-download/work`;
export const FILES_USERS_CSV_DOWNLOAD_ENDPOINT = `${baseEndpoint}/api/v1/files-csv-download/users`;
export const MEDIA_ENDPOINT = `${baseEndpoint}/api/v1/media`;
export const HISTORY_ENDPOINT = `${baseEndpoint}/api/v1/history`;
export const WORK_TEMPLATES_ENDPOINT = `${baseEndpoint}/api/v1/workTemplates`;
export const TASK_TEMPLATES_ENDPOINT = `${baseEndpoint}/api/v1/taskTemplates`;
export const WORK_TEMPLATES_FOLDER_TREE_ENDPOINT = `${baseEndpoint}/api/v1/workTemplateFolders/tree`;
export const WORK_TEMPLATES_FOLDER_ENDPOINT = `${baseEndpoint}/api/v1/workTemplateFolders`;
export const BASE_API_ENDPOINT = `${baseEndpoint}/api/v1`;
export const GRAPHQL_ENDPOINT = `${baseEndpoint}/graphql`;

export const getWorkOrderTasksEndpoint = workOrderId =>
  `${WORK_ORDERS_ENDPOINT}/${workOrderId}/tasks`;

export const getWorkOrderAssetsEndpoint = workOrderId =>
  `${WORK_ORDERS_ENDPOINT}/${workOrderId}/assets`;

export const getWorkOrderAssetElementsEndpoint = (
  workOrderId,
  workOrderAssetId,
) =>
  `${WORK_ORDERS_ENDPOINT}/${workOrderId}/assets/${workOrderAssetId}/elements`;

export const getFormInstanceMediaEndpoint = (formInstanceId, subjectId) =>
  `${FORM_INSTANCES_ENDPOINT}/${formInstanceId}/subject/${subjectId}/media`;

export const getFormInstanceFhwaEndpoint = (formInstanceId, pageId, fieldId) =>
  `${FORM_INSTANCES_ENDPOINT}/${formInstanceId}/page/${pageId}/fhwa/${fieldId}/asset`;

export const getFormInstanceCiEndpoint = (
  formInstanceId: string,
  pageId: string,
  fieldId: string,
) =>
  `${FORM_INSTANCES_ENDPOINT}/${formInstanceId}/page/${pageId}/ci/${fieldId}`;

export const getFormInstancePciEndpoint = (
  formInstanceId: string,
  pageId: string,
  fieldId: string,
) =>
  `${FORM_INSTANCES_ENDPOINT}/${formInstanceId}/page/${pageId}/pci/${fieldId}`;

export const getFormInstanceAttributeEndpoint = (
  formInstanceId,
  pageId,
  fieldId,
  assetId,
) =>
  `${FORM_INSTANCES_ENDPOINT}/${formInstanceId}/page/${pageId}/field/${fieldId}/asset/${assetId}/attribute`;

export const getFormInstanceFieldEndpoint = (formInstanceId, pageId) =>
  `${FORM_INSTANCES_ENDPOINT}/${formInstanceId}/page/${pageId}/field`;

export const getSchemaGroupsEndpoint = schemaId =>
  `${INVENTORY_SCHEMAS_ENDPOINT}/${schemaId}/groups`;

export const getSchemaAttributeGroupsEndpoint = schemaId =>
  `${INVENTORY_SCHEMAS_ENDPOINT}/${schemaId}/attributeGroups`;

export const getSchemaAttributesEndpoint = (schemaId, attributeGroupId) =>
  `${INVENTORY_SCHEMAS_ENDPOINT}/${schemaId}/attributeGroups/${attributeGroupId}/attributes`;

export const getTaskAssigneePostEndpoint = (workId, taskId) =>
  `${baseEndpoint}/api/v2/workOrders/${workId}/tasks/${taskId}/assignees`;
