import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import UserGroupIcon from '@atom/components/common/UserGroupIcon';
import InheritanceLockIcon from '@atom/components/workTemplate/InheritanceLockIcon';
import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_TASK_USER_GROUP_DELETE } from '@atom/graph/workTemplate';
import { Icon, IconButton } from '@atom/mui';
import {
  InheritedComponentType,
  WorkOrderTemplateUserGroupDeleteInput,
  WorkTemplateTaskUserGroup,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import {
  convertMillisToExactHours,
  setWorkTimeDisplay,
} from '@atom/utilities/timeUtilities';
import { isComponentInherited } from '@atom/utilities/workTemplateUtilities';

import EditTaskUserGroupModal from './EditTaskUserGroupModal';

import './teamSection.css';

interface Props {
  userGroup: WorkTemplateTaskUserGroup;
}

const TaskUserGroupTile = ({ userGroup }: Props) => {
  const { workTemplate, task, refetch } = useContext(WorkTemplateContext);

  const [open, setOpen] = useState<boolean>(false);
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);

  const [taskUserGroupRemove] = useMutation<
    { workOrderTemplateUserGroupDelete: boolean },
    { input: WorkOrderTemplateUserGroupDeleteInput }
  >(WORK_TEMPLATE_TASK_USER_GROUP_DELETE, {
    onCompleted: () => {
      refetch();
    },
  });

  const removeUserGroup = async () => {
    await taskUserGroupRemove({
      variables: {
        input: {
          taskId: task.id,
          userGroupId: userGroup.userGroupId,
          workTemplateId: workTemplate.id,
        },
      },
    });

    setRemoveModalOpen(false);
    refetch();
  };

  const usersLabel = userGroup.quantity === 1 ? 'User' : 'Users';

  const getTotalCost = () => {
    const workTime = convertMillisToExactHours(
      R.pathOr(0, ['workTime'], userGroup),
    );
    const quantity = R.pathOr(0, ['quantity'], userGroup);
    const rate = R.pathOr(0, ['budget', 'rate'], userGroup);

    return workTime * quantity * rate;
  };

  const totalCost = getTotalCost();

  const isDisabled =
    workTemplate?.published || doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  const isItemInherited = isComponentInherited(
    InheritedComponentType.TASK_USER_GROUP,
    task?.inheritedComponents || [],
    userGroup?.userGroupId,
  );

  const actionButton = isItemInherited ? (
    <div styleName="lock-container">
      <InheritanceLockIcon
        tooltip="Inherited Group cannot be removed or edited."
        placement="bottom-start"
      />
    </div>
  ) : isDisabled ? (
    <div />
  ) : (
    <IconButton
      onClick={() => setRemoveModalOpen(true)}
      tooltip="Remove"
      edge="end"
      disabled={isDisabled}
    >
      <Icon>close</Icon>
    </IconButton>
  );

  return (
    <>
      <div styleName="user-container group">
        <div
          styleName="user-content-container"
          onClick={() => setOpen(!isDisabled)}
        >
          <UserGroupIcon colorId={userGroup.colorId} />
          <div styleName="user-details">
            <div styleName="name">{userGroup.name}</div>
            <div styleName="email">
              <div styleName="email">
                <span>{`${userGroup.quantity} ${usersLabel}`}</span>
                <span styleName="spacer">&#183;</span>
                <span>{setWorkTimeDisplay(userGroup.workTime)}</span>
                <span styleName="spacer">&#183;</span>
                <span>{numberToLocaleString(totalCost)}</span>
              </div>
            </div>
          </div>
        </div>
        {actionButton}
      </div>
      <EditTaskUserGroupModal
        open={open}
        onClose={() => setOpen(false)}
        taskUserGroup={userGroup}
      />
      <DeleteModal
        open={removeModalOpen}
        onCancel={() => setRemoveModalOpen(false)}
        onConfirm={removeUserGroup}
        title="Remove User Group From Task"
        confirmText="Remove"
        content="Are you sure you want to remove this user group from the task?"
      />
    </>
  );
};

export default TaskUserGroupTile;
