/* eslint-disable */
import * as React from 'react';

export const PrivacyPolicy = (
  <div>
    <p>Effective Date:&nbsp;January, 2018&nbsp;</p>
    <p>
      <strong>WELCOME TO&nbsp;</strong>
      <strong>ATOM</strong>
      <strong>&rsquo;S&nbsp;</strong>
      <strong>PRIVACY POLICY</strong>&nbsp;
    </p>
    <p>
      This Privacy Policy&nbsp;describes&nbsp;the privacy and data security
      principles followed by SADA&nbsp;Systems, Inc. and Collins Engineers,
      Inc.&nbsp;(together referred to in this Privacy Policy
      as&nbsp;&ldquo;Owner&rdquo;,&nbsp;&ldquo;we&rdquo; and
      &ldquo;our&rdquo;)&nbsp;with regard to the personal information of
      individuals, customers and licensees&nbsp;who visit&nbsp;and use&nbsp;our
      online, web-based platform and mobile applications known
      as&nbsp;Atom&nbsp;App&nbsp;(the&nbsp;&ldquo;Services&rdquo;).&nbsp;&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      This&nbsp;Privacy Policy will help you understand how we collect and use
      the personal information you share with us. By using or accessing
      the&nbsp;Atom website and&nbsp;applications, you are accepting the
      practices described in this&nbsp;Privacy Policy.&nbsp;In this privacy
      policy, we sometimes refer to &ldquo;You&rdquo;. &ldquo;You&rdquo; may be
      a visitor to&nbsp;the&nbsp;Atom&nbsp;website, a&nbsp;customer
      and&nbsp;user of one or more of our Services, or an&nbsp;employee&nbsp;of
      a customer.&nbsp;&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      This policy does not apply to third-party websites, products, or services
      even if they link to our Services, and You should consider the privacy
      practices of those third&nbsp;parties carefully.&nbsp;By using our website
      or Services, you authorize the collection, use, storage, sharing, and
      disclose of your personal information as described in this Privacy
      Statement and any applicable updates.&nbsp;
    </p>
    <p>
      To further clarify,&nbsp;by accessing Atom&nbsp;website and using&nbsp;the
      Atom&nbsp;applications, You acknowledge and agree that certain providers
      of third-party products may also have access to&nbsp;the data You share or
      we access. One such third-party provider is Google, Inc., and You allow
      Google, Inc. to use such data as described in Google,&nbsp;Inc.&rsquo;s
      privacy policy at&nbsp;
      <a
        href="https://www.google.com/intl/ALL/policies/privacy/index.html"
        target="_blank"
      >
        https://www.google.com/intl/ALL/policies/privacy/index.html
      </a>
      .&nbsp;
    </p>
    <p>
      This&nbsp;Atom&nbsp;Privacy Policy applies to the collection, hosting,
      storage, security, processing, transfer,&nbsp;and&nbsp;use of,&nbsp;as
      well as the&nbsp;access to,&nbsp;personal information, customer data, and
      services data concerning&nbsp;You, and&nbsp;applies only to use of
      the&nbsp;Services&nbsp;and not to any other services or products provided
      by&nbsp;SADA, Collins,&nbsp;or&nbsp;any&nbsp;third parties.&nbsp;&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>How We Collect and Use Information</strong>&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      We collect and use Your personal information to provide
      our&nbsp;Services&nbsp;to You and to operate and improve the Atom
      website&nbsp;and services. We will only use your personal information in
      ways&nbsp;outlined in this&nbsp;Privacy Policy.&nbsp;Depending on how You
      use the Atom&nbsp;website, applications, and Services, we may collect the
      following types of information and use it in this manner:&nbsp;
    </p>
    <p>
      <strong>Accessing our website</strong> &ndash; Like most websites,
      our&nbsp;Atom website&nbsp;automatically collects and uses the data
      contained in log files. Information in log files may include&nbsp;Your
      computer&rsquo;s IP (internet protocol) address, your ISP (internet
      service provider), the web browser&nbsp;You used to visit our site, the
      time&nbsp;You visited our site, which web pages&nbsp;You visited, and
      other anonymous site usage data. Every computer with internet capability
      is given a number that serves as that computer&rsquo;s IP address. When
      You request a page from&nbsp;Atom&rsquo;s website, our web servers
      automatically recognize Your IP address. The domain name and IP
      address&nbsp;reveal nothing personal about You other&nbsp;than the IP
      address from which You have accessed our site. We&nbsp;may&nbsp;use this
      information to examine traffic in aggregate, investigate misuse of
      SADA&rsquo;s websites, detect fraud, or cooperate with law
      enforcement.&nbsp;
    </p>
    <p>
      <strong>Information Y</strong>
      <strong>ou provide</strong> &ndash; We offer access to some information
      and services without a charge, but to access the information we ask that
      You complete a registration form. In this instance, we may ask You to
      provide limited personal information (such as your name and email
      address).&nbsp;Furthermore,&nbsp;employees of our customers&nbsp;may
      provide information through Atom&rsquo;s website and applications in order
      to use the Services. &nbsp;Such information will include first and last
      name, work email address (which may include an image of the
      employee&nbsp;profile from Google Accounts if&nbsp;the
      employee&nbsp;signs-in using his or her&nbsp;Google-based email for work),
      and phone number.<em>&nbsp;</em>In the future, the Atom website
      and&nbsp;related&nbsp;applications&nbsp;may collect an
      employee&rsquo;s&nbsp;geolocation&nbsp;using such
      employee&rsquo;s&nbsp;mobile device in order to provide
      traffic&nbsp;conditions&nbsp;and&nbsp;routes, weather alerts,
      disaster alerts, and on-site updates when actual work is being
      conducted. &nbsp;Our geolocation will allow the user to opt-in and opt-out
      of any alerts or other notifications by modifying applicable
      settings.&nbsp;
    </p>
    <p>
      <strong>How we a</strong>
      <strong>
        nd others may communicate with You using the information Y
      </strong>
      <strong>ou provide</strong> &ndash; We communicate through email,&nbsp;and
      notices posted&nbsp;on&nbsp;the Atom&nbsp;website. Examples of these email
      notices include: (1) welcome&nbsp;and engagement messages &ndash; when You
      register&nbsp;for our Services, we may send you a series of emails to
      inform&nbsp;You about how to best use our Services, as well as to inform
      you of new features; (2) service messages &ndash; these will cover service
      availability, security, and other issues about the functioning of Atom;
      and (3) promotional email messages, which may contain promotional
      information directly about us or about our business
      partners.&nbsp;Finally, we may provide Your contact information to our
      partners for marketing purposes;&nbsp;please see below if You would like
      to opt-out of such communications.&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      You may change your email and contact preferences, or any other personal
      information, at any time by notifying&nbsp;us&nbsp;as explained in more
      detail at the end of this policy under the&nbsp;&ldquo;Your Privacy
      Rights&rdquo; section of this Policy. If we use Your information in a
      manner different than the purpose for which it was collected, we will ask
      for&nbsp;Your consent before such other use.&nbsp;&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Push Notifications</strong>&nbsp;-&nbsp;We may send You push
      notifications from time-to-time in order to update You about any updates,
      events or promotions that we may be running. If you do not wish to receive
      these types of communications, you may turn them off&nbsp;at the device
      level. To ensure You receive proper notifications, we will need to collect
      certain information about Your device such as operating system and user
      identification information.&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      <strong>Cookies</strong> &ndash; When&nbsp;You
      visit&nbsp;Atom&rsquo;s&nbsp;website&nbsp;or it related
      applications&nbsp;we may send one or more &ldquo;cookies&rdquo; to your
      computer&nbsp;or mobile device.&nbsp;Third-party websites You may access
      through our sites may also send cookies to&nbsp;Your computer. A cookie is
      a small piece of data that is sent to&nbsp;Your browser from a web server
      and stored on&nbsp;Your computer&rsquo;s hard drive. We use cookies to
      improve the quality of our services, for instance, storing&nbsp;Your user
      preferences or log-in information, as well as for marketing purposes. You
      can choose whether to accept cookies by changing your browser settings.
      You can reset your browser to refuse all cookies, or allow&nbsp;Your
      browser to show you when a cookie is being sent. If you choose not to
      accept cookies,&nbsp;Your&nbsp;experience at our site and other websites
      may be diminished and some features may not work as intended. Our systems
      do not recognize browser &ldquo;Do&nbsp;Not Track&rdquo; signals. We
      provide You with tools to opt out of marketing from us or certain
      transfers of&nbsp;Your information. You can learn about this in the
      &ldquo;Your Privacy Rights&rdquo; section of this Privacy Policy.&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Links</strong> &ndash; We may present links in a format that
      enables us to keep track of whether these links have been followed. We use
      this information to determine which sites are being accessed and how
      often, and to improve our services.&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Other Sites</strong> &ndash; This&nbsp;Privacy Policy applies to
      the Atom website, applications,&nbsp;and&nbsp;Services only. Although we
      may provide links to internet sites or resources administered by third
      parties, we do not exercise control over third party sites that include
      other products or services. These other sites may place their own cookies
      or other files on&nbsp;Your computer, collect data or solicit personal
      information from&nbsp;You. We cannot protect the privacy of the
      information&nbsp;You share with any such third party and we suggest
      that&nbsp;You review their applicable privacy policies.&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Other Requested Information</strong>&nbsp;
    </p>
    <p>
      We may request&nbsp;Your name, e-mail address or mailing address for the
      purposes of conducting a survey or providing additional services (for
      example, subscriptions to e-mail newsletters, promotional announcements,
      or information about seminars). When making such a request, we will
      indicate the purpose of the inquiry. If we use this information in a
      manner different than the purpose for which it was collected, we will ask
      for&nbsp;Your consent before such other use.&nbsp;
    </p>
    <p>
      <strong>Potential Disclosure of Your Information</strong>&nbsp;
    </p>
    <p>
      We will disclose personal information and an IP address when we
      have&nbsp;Your consent or have the good-faith belief that such action is
      reasonably necessary to:&nbsp;
    </p>
    <ol>
      <li>
        satisfy any applicable law, regulation, legal process or enforceable
        governmental request;&nbsp;
      </li>
      <li>
        protect and defend the rights of&nbsp;our&nbsp;websites and related
        properties, or visitors to&nbsp;our&nbsp;websites and related
        properties;&nbsp;
      </li>
      <li>
        enforce applicable terms of our Terms of Use, including investigation of
        potential violations thereof;&nbsp;
      </li>
      <li>
        identify persons who may be committing a fraud or otherwise violating
        the law; or&nbsp;
      </li>
      <li>
        cooperate with investigations of purported unlawful activities.&nbsp;
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>Security of Your Information</strong>&nbsp;
    </p>
    <p>
      We take appropriate security measures to protect against unauthorized
      access to or unauthorized alteration, disclosure, or destruction of
      data.&nbsp;Other than as set forth in the last sentence in this paragraph,
      we restrict access to personal information to our employees, contractors,
      agents and authorized third parties who need to know the information in
      order to&nbsp;operate, develop or improve the Services.  These individuals
      are bound by confidentiality obligations. As noted above in the section
      entitled <strong>&ldquo;How we a</strong>
      <strong>
        nd others may communicate with You using the information Y
      </strong>
      <strong>ou provide&rdquo;</strong>, we may provide Your contact
      information to our partners for marketing purposes.&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      <strong>Data Integrity</strong>&nbsp;
    </p>
    <p>
      We process personal information only for the purposes for which it is
      collected and in accordance with this Privacy Policy or any applicable
      service-specific privacy notice. We review our data collection, storage
      and processing practices to ensure that we only collect, store and process
      the personal information needed to provide or&nbsp;improve the Services or
      as otherwise permitted under this&nbsp;Privacy Policy.&nbsp;
    </p>
    <p>
      <strong>Customer Data</strong>
      <strong>&nbsp;and Services Data</strong>&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Customer Data</strong> is information that we may collect from
      Your use of the&nbsp;Atom website and related applications, and Your
      interactions with us off-line. We deal&nbsp;with customer
      data&nbsp;according to the terms of this&nbsp;Privacy Policy.&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Services Data</strong> is data that resides
      on&nbsp;Google&nbsp;Cloud Platform, our customers&rsquo; or third-party
      systems to which&nbsp;we are&nbsp;provided access to perform&nbsp;the
      Services (including&nbsp;development, Q&amp;A, UAT and
      production&nbsp;environments that may be accessed to perform consulting
      and support services).&nbsp;We&nbsp;treat services data in accordance with
      the terms of this&nbsp;Privacy Policy.&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      Below are the conditions under which SADA may access, collect and/or use
      services data:&nbsp;
    </p>
    <p>
      <strong>To Provide Services &ndash;</strong> Services data may be accessed
      and used to fulfill the requirements specified in&nbsp;a&nbsp;Services
      contract or purchase order for support, consulting, or other
      services.&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>To Maintain and Upgrade a System &ndash; </strong>Technical staff
      may require periodic access to services data to monitor system
      performance, test systems and develop and implement upgrades to systems,
      if applicable.&nbsp;&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>To Address Performance and Fix Issues &ndash;</strong>On
      occasion,&nbsp;we&nbsp;may have need to deploy new versions, patches,
      updates, and other fixes to our programs (or third party programs we
      support) and services. In accordance with the terms
      of&nbsp;Your&nbsp;subscription&nbsp;order and/or with notice to&nbsp;You,
      we may access and/or use a copy of&nbsp;Your&nbsp;development, Q&amp;A,
      UAT and production&nbsp;environment, including services data, to test such
      new versions, patches, updates and fixes and validate that they work
      in&nbsp;Your environment(s).&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Other Uses &ndash; </strong>We&nbsp;may transfer and access
      services data globally as required for the purposes specified above.
      If&nbsp;we&nbsp;hire subcontractors to assist in providing services, their
      access to services data will be consistent with the terms of
      this&nbsp;Privacy Policy, except where the confidentiality provisions
      within&nbsp;a specific&nbsp;service contract define different
      requirements.&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      We&nbsp;do not use services data except as stated above or in Your
      order.&nbsp;We&nbsp;may process services data, but do not
      control&nbsp;Your information collection or use practices for services
      data. If&nbsp;You provide any services data to&nbsp;us,&nbsp;You&nbsp;are
      responsible for providing any notices and/or obtaining any consents
      necessary for&nbsp;us&nbsp;to access and use services data as specified in
      this policy and&nbsp;Your order.&nbsp;
    </p>
    <p>
      <strong>Your Privacy Rights: Opting Out&nbsp;</strong>
      <strong>Of</strong>
      <strong>
        &nbsp;Marketing And Transfers; Accessing, Updating And Deleting
        Information
      </strong>
      &nbsp;
    </p>
    <p>
      We want to communicate&nbsp;with You only if you want to hear from us. If
      you wish to opt out of receiving our marketing efforts or our sharing
      information with third parties for their marketing
      efforts,&nbsp;You&nbsp;may do so, at any time by clicking
      &ldquo;unsubscribe&rdquo; in any email you receive from us and following
      the instructions on the screen or by responding to an email and including
      &ldquo;Remove&rdquo; in the subject line. Please be aware that You cannot
      opt out of receiving our service messages.&nbsp;
    </p>
    <p>
      You may also opt out of receiving our marketing efforts or our sharing
      information with third parties by contacting us at:&nbsp;
      <a href="mailto:info@sadasystems.com">support@atomapp.com&nbsp;</a> or
      mail your questions to:&nbsp;
    </p>
    <p>
      <strong>SADA Systems, Inc.</strong>&nbsp;
      <br />
      <strong>c/o Atom App</strong>&nbsp;
      <br />
      <strong>5250 Lankershim</strong>
      <strong>&nbsp;Blvd., Suite 620</strong>&nbsp;
      <br />
      <strong>North Hollywood, CA  91601</strong>&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      Please note that, from time to time, personal information collected from
      You may be processed and stored by us or our partners in jurisdictions in
      which disclosure may be compelled by governments, law enforcement agencies
      and regulatory bodies of those jurisdictions as required or permitted by
      the laws and courts of those jurisdictions. Depending on the laws of the
      applicable jurisdiction, we may not be allowed to provide notice to You of
      such required disclosure.&nbsp;We may also share Your personal information
      and other information with entities controlling, controlled by, or under
      common control with us. If some or all of our business assets are sold
      or&nbsp;transferred, we would transfer Your information to the buyer or
      transferee and for due diligence purposes to its professional advisors
      under an obligation of confidentiality.&nbsp;
    </p>
    <p>
      <strong>Changes to this Policy</strong>&nbsp;
    </p>
    <p>
      Our Privacy Policy may change from time to time. If we do make changes, we
      will post any policy changes on this page so that You are always aware of
      what information we collect, how we use it, and under what circumstances
      we disclose it. Each version of this Privacy Policy will be identified at
      the top of the page by its effective date. Please send any questions about
      our Privacy Policy to the contact email and address above.&nbsp;
    </p>
    <p>&nbsp;</p>
  </div>
);
