import * as React from 'react';

import CarouselWrapper from '@atom/components/common/photoCarousel/CarouselWrapper';
import { Checkbox, Select } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { EventType } from '@atom/types/event';
import { FormFieldType } from '@atom/types/form';
import { MediaItem } from '@atom/types/media';

import FileUploadButton from '../FileUploadButton';
import FileUploadTile from '../FileUploadTile';

import '../formInstance.css';

const { MenuItem } = Select;

const styles = {
  underlineStyle: {
    '&:before': {
      borderBottom: `1px solid ${colors.neutral.silver}`,
    },
    '&:after': {
      borderBottom: `1px solid ${colors.neutral.silver}`,
    },
  },
  floatingLabelStyle: {
    fontSize: fonts.md,
    color: colors.neutral.dark,
  },
  textFieldStyle: {
    fontSize: fonts.md,
  },
  selectFieldStyle: {
    input: {
      fontSize: fonts.md,
      color: colors.neutral.black,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    selected: {
      fontSize: fonts.md,
      color: colors.brand.blue,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    iconStyle: {
      fill: colors.neutral.gray,
    },
  },
};

interface PassedProps {
  field: FormFieldType;
  media: MediaItem[];
  fieldId: string;
  onChange: (event: any) => void;
  removeFile: (mediaId: string, subjectId: string) => void;
  fieldFileUpload: (event: EventType) => void;
  value: any;
  isEditable: boolean;
  isUploadDisabled: boolean;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
}

type Props = PassedProps;

const EnumMultipleField = ({
  field,
  fieldId,
  onChange,
  value,
  removeFile,
  isEditable,
  media,
  fieldFileUpload,
  isUploadDisabled,
  renameMedia,
}: Props) => {
  const fieldValue = value !== '' ? value : [];
  const required = field.required ? 'required' : '';
  const medium = media.filter((med: MediaItem) => med.fieldId === fieldId);

  return (
    <div styleName="field-block">
      <div styleName={`short-textfield-container ${required}`}>
        <Select
          fullWidth
          multiple
          label={field.title}
          style={styles.textFieldStyle}
          onChange={onChange}
          value={fieldValue}
          disabled={!isEditable}
          renderValue={(selected: string[]) =>
            field.enum
              .filter(item => selected.indexOf(item) !== -1)
              .map(item => item)
              .join(', ')
          }
        >
          {field.enum.map((option: string, index: number) => {
            return (
              <MenuItem key={index} value={option}>
                <Checkbox
                  checked={fieldValue && fieldValue.indexOf(option) > -1}
                />
                {option}
              </MenuItem>
            );
          })}
        </Select>
        {field.fileUpload && (
          <FileUploadButton
            onChange={fieldFileUpload}
            isUploadDisabled={!isEditable || isUploadDisabled}
          />
        )}
      </div>
      <div styleName="field-description">{field.description}</div>
      <div styleName="file-tile-container">
        {medium.map((mediaItem: MediaItem) => (
          <CarouselWrapper
            media={media}
            selectedMediaId={mediaItem.id}
            removeFile={removeFile}
            subjectId={fieldId}
            renameMedia={renameMedia}
            canUpdateMedia={isEditable}
            canDeleteMedia={isEditable}
          >
            <FileUploadTile key={mediaItem.id} media={mediaItem} />
          </CarouselWrapper>
        ))}
      </div>
    </div>
  );
};

export default EnumMultipleField;
