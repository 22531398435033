import React, { useEffect, useState } from 'react';
import * as R from 'ramda';

import { gwrrSchema } from '@atom/constants/customReportSchemaConstants';
import { MediaItem } from '@atom/types/media';
import api from '@atom/utilities/api';
import { MEDIA_ENDPOINT } from '@atom/utilities/endpoints';

import ChildAssessmentTable from './ChildAssessmentTable';
import ElementAssessmentRow from './ElementAssessmentRow';
import PhotoTile from './PhotoTile';

import '../workOrderDetailPdfReport.css';

interface Props {
  element?: any;
  workOrderId?: string;
  setOtherPhotosLoading?: (currentLoading: { [id: string]: boolean }) => void;
}

const AssessmentTable = ({
  element,
  setOtherPhotosLoading,
  workOrderId,
}: Props) => {
  const [photos, setPhotos] = useState<MediaItem[]>([]);

  useEffect(() => {
    const getMedia = async () => {
      const subElementIds = R.pathOr([], ['elements'], element).map(
        item => item.id,
      );

      const data = {
        subjectTypes: 'workOrderAsset',
        parentSubjectId: workOrderId,
        subjectIds: [element.id, ...subElementIds].join(','),
      };

      const res = await api.get(MEDIA_ENDPOINT, data);

      setPhotos(res.data);
      // @ts-ignore
      setOtherPhotosLoading(currentLoading => ({
        ...currentLoading,
        assessmentTable: false,
      }));
    };

    getMedia();
  }, [element]);

  return (
    <>
      <div styleName="table-wrapper">
        <table>
          <thead>
            <tr styleName="table-header">
              <th styleName="table-header-cell">Structure</th>
              <th styleName="table-header-cell">Condition Rating</th>
              <th styleName="table-header-cell comment">Comment</th>
              <th styleName="table-header-cell">Repair Recommendation</th>
              <th styleName="table-header-cell">Priority</th>
              <th styleName="table-header-cell">Assessment Flag</th>
            </tr>
          </thead>
          <tbody>
            <ElementAssessmentRow
              key={element.id}
              element={element}
              schemaIds={R.values(
                R.omit(
                  ['boredAtId', 'shellId', 'voidId'],
                  gwrrSchema.poolAttributes,
                ),
              )}
              isAssessmentReport
            />
          </tbody>
        </table>
        {element?.elements && (
          <ChildAssessmentTable
            key={`${element.id} elements`}
            elements={element.elements}
          />
        )}
      </div>
      <div>
        {!!photos.length && (
          <PhotoTile
            photos={photos}
            title={element.name}
            elements={element.elements}
            id={element.id}
            setOtherPhotosLoading={setOtherPhotosLoading}
          />
        )}
      </div>
    </>
  );
};

export default AssessmentTable;
