import React from 'react';

import { Draggable, Droppable } from '@atom/components/common/dragAndDrop';
import { DashboardCard } from '@atom/types/dashboard';

import DashboardCardContainer from './DashboardCardContainer';

import './dashboard.css';

interface Props {
  cards: DashboardCard[];
  size: string;
}

const DashboardColumn = ({ cards, size }: Props) => {
  return (
    <div styleName={`col col-${size}`}>
      <Droppable droppableId={`col-${size}`}>
        {cards.map((card, idx) => (
          <Draggable
            key={card.id}
            draggableId={card.id}
            index={idx}
            isDragStyleDisabled
          >
            <DashboardCardContainer card={card} />
          </Draggable>
        ))}
      </Droppable>
    </div>
  );
};

export default DashboardColumn;
