import React, { useContext } from 'react';

import AddTeamModalContext from '@atom/components/common/workOrderDetail/addTeamModal/AddTeamModalContext';
import { Icon } from '@atom/mui';

import GroupsTreeRow from './GroupsTreeRow';

import './groupsTab.css';

const GroupsTab = () => {
  const { userGroupTree, expanded } = useContext(AddTeamModalContext);

  const isExpanded = expanded.has(userGroupTree.id);
  const arrowDirection = isExpanded ? 'arrow_drop_down' : 'arrow_right';

  return (
    <>
      <div styleName="row">
        <div styleName="row-content">
          <div styleName="left-content">
            <Icon>{arrowDirection}</Icon>
            <div styleName="name-container">
              <div styleName="title">{userGroupTree.name}</div>
            </div>
          </div>
        </div>
      </div>
      {isExpanded &&
        userGroupTree.userGroups.map(group => (
          <GroupsTreeRow key={group.id} userGroup={group} indentation={0} />
        ))}
    </>
  );
};

export default GroupsTab;
