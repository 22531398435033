import React, { useEffect } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import { MapAsset, MapUser, MapWorkOrder } from '@atom/types/map';

import AssetListItem from '../drawerContent/AssetsListItem_v2';
import UserListItem from '../drawerContent/UserListItem_v2';
import WorkOrderListItem from '../drawerContent/WorkOrdersListItem_v2';

interface Props {
  param: string;
  row: MapAsset | MapUser | MapWorkOrder;
}

const componentMap = {
  asset: AssetListItem,
  user: UserListItem,
  workOrder: WorkOrderListItem,
};

const ListItemWrapper = ({ param, row }: Props) => {
  const Component = componentMap[param];

  const ref = React.useRef();
  const [query, setQueryParams] = useQueryParams({
    id: StringParam,
    hover: StringParam,
    view: StringParam,
  });

  const itemHover = hover => setQueryParams({ hover });
  const itemClick = (id, view) => setQueryParams({ id, view });

  const hoverParams = (id, view) => ({
    onMouseEnter: () => {
      itemHover(id);
    },
    onMouseLeave: () => {
      itemHover(null);
    },
    onClick: () => {
      itemClick(id, view);
    },
  });

  React.useEffect(() => {
    if (query.hover === row.id) {
      if (ref && ref.current) {
        // @ts-ignore
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      itemHover(null);
    };
  }, []);

  if (!Component) {
    return null;
  }

  const props = { [param]: row };

  return (
    <div {...hoverParams(row.id, param)} ref={ref}>
      <Component {...props} />
    </div>
  );
};

export default ListItemWrapper;
