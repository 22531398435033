import React from 'react';
import * as R from 'ramda';

import { UserCollectionItem } from '@atom/types/user';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

const capitalize = (value: string) => {
  if (isNilOrEmpty(value)) {
    return value;
  }

  return R.replace(/^./, R.toUpper)(value);
};

const latLong = (value: number): string => {
  if (!value || isNaN(value)) {
    return '';
  }
  return value.toFixed(6);
};

const highlightedText = (query: string, text: string) => {
  if (query === text) {
    return <span style={{ fontWeight: 500 }}>{query}</span>;
  }

  if (!text) {
    return <span />;
  }

  const index = text.toLowerCase().indexOf(query.toLowerCase());

  if (index >= 0) {
    return (
      <span>
        <span>{text.substring(0, index)}</span>
        <span style={{ fontWeight: 500 }}>
          {text.substring(index, index + query.length)}
        </span>
        <span>{text.substring(index + query.length)}</span>
      </span>
    );
  }

  return <span>{text}</span>;
};

const displayName = (user: UserCollectionItem) => {
  if (R.isEmpty(user) || R.isNil(user)) {
    return '';
  }

  return `${user.firstName} ${user.lastName}`;
};

/**
 * @param {string} [text] The text that the client would like to truncate
 * @param {number|undefined} [maxLength] The maximum length that they would like the text to be truncated to.  Note the default of 60 characters which DOES include the ... suffix.
 *
 * For example if the text was "Bridge 30310 - Annual Inspection 2020"
 * and the maxLength was set to 5, the resulting string would be "Br..."
 *
 * Note that the minimum value for maxLength is 5
 *
 *  @returns {string} Resulting string
 */

const truncate = (text: string, maxLength: number | undefined = 60) => {
  if (!text || maxLength <= 4 || !R.is(String)(text)) {
    return '';
  }

  if (text?.length < maxLength) {
    return text;
  }

  return `${text.slice(0, maxLength - 3)}...`;
};

const multilineParagraph = (text: string) => {
  if (!text) {
    return '';
  }

  const splitText = text
    .replace(/↵/gi, `|br|`)
    .replace(/\n/gi, '|br|')
    .split('|');
  return splitText.map(textItem => (textItem === 'br' ? <br /> : textItem));
};

const toOwnership = (str: string) => {
  return str.toLowerCase().charAt(str.length - 1) === 's'
    ? `${str}'`
    : `${str}'s`;
};

const textDisplayUtilities = {
  capitalize,
  latLong,
  highlightedText,
  displayName,
  truncate,
  multilineParagraph,
  toOwnership,
};

export default textDisplayUtilities;
