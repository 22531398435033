import React, { useContext, useState } from 'react';
import * as R from 'ramda';

import UserGroupContext from '@atom/components/userGroupDetail/UserGroupContext';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { UserGroupBudget } from '@atom/types/userGroups';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import AddEditBudgetModal from './AddEditBudgetModal';
import UserGroupBudgetTile from './UserGroupBudgetTile';

import './userGroupBudgets.css';

const getBudgetHeaderLabel = (budgets: UserGroupBudget[]): string => {
  if (!budgets || !R.length(budgets)) {
    return 'No budget configured';
  }

  if (R.length(budgets) === 1) {
    return '1 budget';
  }

  return `${R.length(budgets)} budgets`;
};

const UserGroupBudgets = () => {
  const { userGroup } = useContext(UserGroupContext);

  const [open, setOpen] = useState<boolean>(false);

  const budgetHeaderLabel = getBudgetHeaderLabel(userGroup.budgets);

  return (
    <>
      <div styleName="header-container">
        <div>{budgetHeaderLabel}</div>
        {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
          <IconButton onClick={() => setOpen(true)}>
            <Icon color={colors.neutral.gray}>add</Icon>
          </IconButton>
        )}
      </div>
      <div styleName="budget-list-container">
        {userGroup.budgets.map((budget: UserGroupBudget) => (
          <UserGroupBudgetTile key={budget.id} budget={budget} />
        ))}
      </div>
      <AddEditBudgetModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default UserGroupBudgets;
