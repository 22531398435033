import React from 'react';

import { Checkbox } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventorySchemaItem } from '@atom/types/inventory';
import { getSchemaIconFromSchemaOrAsset } from '@atom/utilities/schemaUtilities';

import './multiSchemaSection.css';

interface Props {
  schema: InventorySchemaItem;
  onClick: (schemaId: string) => void;
  checked: boolean;
  disabled?: boolean;
}

const SchemaRow = ({ schema, checked, onClick, disabled }: Props) => {
  const icon = getSchemaIconFromSchemaOrAsset(schema);

  const handleClick = disabled ? () => {} : () => onClick(schema?.id);
  const titleStyle = {
    color: disabled ? colors.neutral.gray : colors.neutral.dark,
  };
  const iconStyle = {
    opacity: disabled ? '0.5' : '1',
  };

  return (
    <div styleName="schema-row" onClick={handleClick}>
      <Checkbox
        checked={checked}
        onClick={handleClick}
        edge="start"
        disabled={disabled}
      />
      <div style={iconStyle}>{icon}</div>
      <div style={titleStyle} styleName="schema-title">
        {schema?.name}
      </div>
    </div>
  );
};

export default SchemaRow;
