import React from 'react';

import { WorkOrderAssetTreeType } from '@atom/types/work';
import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';

import '../workOrderDetailPdfReport.css';

const OVERVIEW_MAP: { [key in string]: string } = {
  inspectionDate: 'Inspection Date',
  leadInspector: 'Lead Inspector',
  additionalInspectors: 'Additional Inspector(s)',
  otherInspectors: 'Other Inspectors',
  previousInspection: 'Previous Inspection',
  previousInspectionDate: 'Previous Inspection Date',
  overallCondition: 'Overall Condition',
  recommendedInspectionFrequency: 'Recommended Inspection Frequency',
};

const GENERAL_DATA_MAP: { [key in string]: string } = {
  crs: 'CRS #',
  district: 'District',
  county: 'County',
  routeNumber: 'Route Number',
  sideOfRoad: 'Side of Road',
  directionOfTravel: 'Direction of Travel',
  supportStyle: 'Support Style',
  medianWallPlate: 'Median Wall Plate',
  spliceFlangeType: 'Splice Flange Type',
  spanLength: 'Span Length',
  structureHeightRightCenter: 'Structure Height - Right / Center',
  structureHeightLeft: 'Structure Height - Left',
  protectedByGuardRail: 'Protected by Guardrail',
  attachmentsToStructure: 'Attachments to Structure',
  attachments: 'Attachments',
  attachmentNotes: 'Attachment Notes',
  trafficControlMethodUsed: 'Traffic Control Method Used',
};

interface Props {
  header: string;
  workOrderAsset: WorkOrderAssetTreeType;
  columnCount?: number;
  height?: string;
}

const getLabels = header => {
  switch (header) {
    case 'Overall Structure Assessment':
      return OVERVIEW_MAP;
    case 'General Data':
      return GENERAL_DATA_MAP;
    default:
      return '';
  }
};

const CustomAttributesTextbox = ({
  header,
  workOrderAsset,
  columnCount,
  height,
}: Props) => {
  const renderInspectionData = () => {
    const { attributeGroups, attributes } = workOrderAsset;
    const group = attributeGroups.filter(
      attributeGroup => attributeGroup.name === 'General Data',
    );
    const attributeGroupIds = group[0]?.attributes ? group[0]?.attributes : [];

    const labels = getLabels(header);
    return Object.keys(labels).map(key => {
      const label = labels[key];

      return attributeGroupIds.map(id => {
        if (attributes[id].name === label) {
          return (
            <div styleName="row">
              <div style={{ width: '15rem' }}>{label}:</div>
              <div styleName="value">
                {attributeDisplayUtility.display(
                  attributes[id].value,
                  attributes[id].dataType,
                  attributes[id].unit,
                  !!attributes[id].changeId,
                  attributes[id].newValue,
                )}
              </div>
            </div>
          );
        }
        return <></>;
      });
    });
  };

  const renderRatings = () => {
    const { attributeGroups, attributes } = workOrderAsset;
    const group = attributeGroups.filter(
      attributeGroup => attributeGroup.name === 'Ratings',
    );
    const attributeGroupIds = group[0]?.attributes ? group[0]?.attributes : [];

    const labels = getLabels(header);
    return Object.keys(labels).map(key => {
      const label = labels[key];

      return attributeGroupIds.map(id => {
        if (attributes[id].name === label) {
          return (
            <div styleName="row">
              <div style={{ width: '15rem' }}>{label}:</div>
              <div styleName="value">
                {attributeDisplayUtility.display(
                  attributes[id].value,
                  attributes[id].dataType,
                  attributes[id].unit,
                  !!attributes[id].changeId,
                  attributes[id].newValue,
                )}
              </div>
            </div>
          );
        }
        return <></>;
      });
    });
  };

  const renderInspectionComments = () => {
    const { attributeGroups, attributes } = workOrderAsset;
    const group = attributeGroups.filter(
      attributeGroup => attributeGroup.name === 'General Data',
    );
    const attributeGroupIds = group[0]?.attributes ? group[0]?.attributes : [];
    return attributeGroupIds.map(
      id =>
        attributes[id].name === 'Inspection Comments' &&
        attributeDisplayUtility.display(
          attributes[id].value,
          attributes[id].dataType,
          attributes[id].unit,
          !!attributes[id].changeId,
          attributes[id].newValue,
        ),
    );
  };

  return (
    <>
      <div styleName="header-title" style={{ margin: 0 }}>
        {header}
      </div>
      <div styleName="content" style={{ columnCount, height }}>
        <div>{renderInspectionData()}</div>
        {header === 'Overall Structure Assessment' && (
          <div>{renderRatings()}</div>
        )}
      </div>
      {header === 'General Data' && (
        <div styleName="content row">
          <div style={{ width: '11rem' }}>Inspection Comments:</div>
          <div styleName="value">{renderInspectionComments()}</div>
        </div>
      )}
    </>
  );
};

export default CustomAttributesTextbox;
