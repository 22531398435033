import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as R from 'ramda';

import WorkTemplateTree from '@atom/components/common/workTemplateTree/WorkTemplateTree';
import WorkOrdersContext, {
  WorkOrdersInputActionTypes,
} from '@atom/components/workOrders/WorkOrdersContext';
import { GET_WORK_ORDER_TEMPLATES } from '@atom/graph/workTemplate';
import { Button, Checkbox, List, Modal, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { PolicyAction } from '@atom/types/policy';
import {
  WorkOrderTemplatesConnectionInput,
  WorkTemplatesConnection,
} from '@atom/types/workTemplate';

import './workOrdersFilters.css';

const { ListItem, ListItemText } = List;

interface WorkTemplate {
  id: string;
  name: string;
}

const styles = {
  modal: {
    height: '50vh',
    padding: '2rem',
  },
  label: {
    fontSize: fonts.sm,
    color: colors.neutral.dim,
    marginBottom: '0.25rem',
  },
  value: {
    fontSize: fonts.md,
    color: colors.neutral.dark,
    marginBottom: '0.5rem',
  },
  button: {
    minWidth: 0,
    padding: 0,
  },
  checkbox: {
    padding: 0,
    marginRight: '1rem',
  },
};

// use strict boolean equality check as value may be null
const isNoWorkTemplatesFilter = (hasWorkTemplate: boolean) =>
  R.equals(hasWorkTemplate, false);

// use strict boolean equality check as value may be null
const isAllWorkTemplatesFilter = (hasWorkTemplate: boolean) =>
  R.equals(hasWorkTemplate, true);

const WorkTemplateFilter = () => {
  const { workOrdersInputCart, dispatch, filtersDisabled } = useContext(
    WorkOrdersContext,
  );

  const [selected, setSelected] = useState<WorkTemplate[]>([]);
  const [applied, setApplied] = useState<WorkTemplate[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [hasWorkTemplate, setHasWorkTemplate] = useState<boolean>(
    workOrdersInputCart.hasWorkTemplate,
  );

  const [getWorkTemplates, { loading }] = useLazyQuery<
    { workOrderTemplates: WorkTemplatesConnection },
    { input: WorkOrderTemplatesConnectionInput }
  >(GET_WORK_ORDER_TEMPLATES, {
    onCompleted: res => {
      const workTemplates = R.pathOr(
        [],
        ['workOrderTemplates', 'workOrderTemplates'],
        res,
      );

      setSelected(workTemplates);
      setApplied(workTemplates);
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (R.length(workOrdersInputCart.workTemplateIds) > 0) {
      getWorkTemplates({
        variables: {
          input: {
            ids: workOrdersInputCart.workTemplateIds,
            action: PolicyAction.READ,
          },
        },
      });
    }
  }, [workOrdersInputCart.workTemplateIds]);

  useEffect(() => {
    const workTemplates = selected.filter(({ id }) =>
      workOrdersInputCart?.workTemplateIds?.includes(id),
    );

    setSelected(workTemplates);
    setApplied(workTemplates);
  }, [workOrdersInputCart.workTemplateIds]);

  useEffect(() => {
    setHasWorkTemplate(workOrdersInputCart.hasWorkTemplate);
  }, [workOrdersInputCart.hasWorkTemplate]);

  const handleToggle = (template?: WorkTemplate) => {
    const isSelected = selected.some(({ id }) => template.id === id);

    setSelected(
      isSelected
        ? selected.filter(({ id }) => id !== template.id)
        : [...selected, template],
    );
  };

  const handleSave = () => {
    const workTemplateIds = selected.map(({ id }) => id);

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_INPUT_PROPERTY,
      data: {
        property: 'workTemplateIds',
        value: workTemplateIds,
      },
    });

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_INPUT_PROPERTY,
      data: {
        property: 'hasWorkTemplate',
        value: hasWorkTemplate,
      },
    });

    setApplied(selected);
    setOpen(false);
  };

  const handleReset = () => {
    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_INPUT_PROPERTY,
      data: {
        property: 'workTemplateIds',
        value: [],
      },
    });

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_INPUT_PROPERTY,
      data: {
        property: 'hasWorkTemplate',
        value: null,
      },
    });
  };

  const handleClear = () => {
    setSelected([]);
    setHasWorkTemplate(null);
  };

  const handleCancel = () => {
    setSelected(applied);
    setHasWorkTemplate(workOrdersInputCart.hasWorkTemplate);
    setOpen(false);
  };

  const handleNoWorkTemplatesToggle = () => {
    setHasWorkTemplate(isNoWorkTemplatesFilter(hasWorkTemplate) ? null : false);
    setSelected([]);
  };

  const handleAllWorkTemplatesToggle = () => {
    setHasWorkTemplate(isAllWorkTemplatesFilter(hasWorkTemplate) ? null : true);
    setSelected([]);
  };

  const selectedIds = useMemo(() => {
    return new Set(selected.map(({ id }) => id));
  }, [selected]);

  return (
    <>
      <div style={styles.label}>Work Template</div>
      <div style={styles.value}>
        {loading && (
          <Progress size={20} style={{ justifyContent: 'flex-start' }} />
        )}
        {isNoWorkTemplatesFilter(workOrdersInputCart.hasWorkTemplate) &&
          'Without Work Template'}
        {isAllWorkTemplatesFilter(workOrdersInputCart.hasWorkTemplate) &&
          'All Work Templates'}
        {applied
          .filter(({ id }) =>
            workOrdersInputCart?.workTemplateIds?.includes(id),
          )
          .map(({ name }) => name)
          .join(', ')}
      </div>
      {(R.length(workOrdersInputCart.workTemplateIds) > 0 ||
        !R.isNil(workOrdersInputCart.hasWorkTemplate)) && (
        <Button
          style={{ ...styles.button, marginRight: '1rem' }}
          size="small"
          disabled={filtersDisabled || loading}
          onClick={handleReset}
          data-cy="workFilterTemplateClear"
        >
          clear
        </Button>
      )}
      <Button
        style={styles.button}
        size="small"
        onClick={() => setOpen(true)}
        disabled={filtersDisabled || loading}
        data-cy="workFilterTemplateSelect"
      >
        select
      </Button>
      <Modal
        title="Select Work Template"
        open={open}
        onCancel={handleCancel}
        onConfirm={handleSave}
        onClear={handleClear}
        contentStyle={styles.modal}
        confirmButtonText="Ok"
      >
        <WorkTemplateTree
          includeSearch
          getTemplateDisabled={() => !R.isNil(hasWorkTemplate)}
          getTemplateSelected={template =>
            isAllWorkTemplatesFilter(hasWorkTemplate) ||
            selectedIds.has(template.id)
          }
          onTemplateToggle={template => handleToggle(template)}
          additionalContentTop={
            <>
              <ListItem disableGutters>
                <Checkbox
                  style={styles.checkbox}
                  checked={isNoWorkTemplatesFilter(hasWorkTemplate)}
                  onChange={handleNoWorkTemplatesToggle}
                />
                <ListItemText>Without Work Template</ListItemText>
              </ListItem>
              <ListItem disableGutters>
                <Checkbox
                  style={styles.checkbox}
                  checked={isAllWorkTemplatesFilter(hasWorkTemplate)}
                  onChange={handleAllWorkTemplatesToggle}
                />
                <ListItemText>All Work Templates</ListItemText>
              </ListItem>
            </>
          }
        />
      </Modal>
    </>
  );
};

export default WorkTemplateFilter;
