import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
import WorkTemplateContext, {
  WorkTemplateActionTypes,
} from '@atom/components/workTemplate/WorkTemplateContext';
import {
  GET_WORK_ORDER_TEMPLATES,
  WORK_TEMPLATE_TASK_DELETE,
  WORK_TEMPLATE_TASK_DUPLICATE,
} from '@atom/graph/workTemplate';
import { Icon, IconButton, Snackbar, Tooltip } from '@atom/mui';
import { PolicyAction } from '@atom/types/policy';
import {
  WorkOrderTemplatesConnectionInput,
  WorkOrderTemplateTaskDeleteInput,
  WorkOrderTemplateTaskDuplicateInput,
  WorkTemplatesConnection,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { WorkTypeVariant } from '@atom/utilities/workTemplateUtilities';

import './taskHeader.css';

const HTTP_RESOURCE_CONFLICT = 409;

const WorkTemplateTaskControls = () => {
  const { workTemplate, workTypeVariant, task, refetch, dispatch } = useContext(
    WorkTemplateContext,
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [workTemplateTaskDelete] = useMutation<
    {},
    { input: WorkOrderTemplateTaskDeleteInput }
  >(WORK_TEMPLATE_TASK_DELETE);

  const [workTemplateTaskDuplicate] = useMutation<
    {},
    { input: WorkOrderTemplateTaskDuplicateInput }
  >(WORK_TEMPLATE_TASK_DUPLICATE);

  const [
    getChildWorkTypes,
    { data: workOrderTemplatesData, loading: workOrderTemplatesLoading },
  ] = useLazyQuery<
    { workOrderTemplates: WorkTemplatesConnection },
    { input: WorkOrderTemplatesConnectionInput }
  >(GET_WORK_ORDER_TEMPLATES, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getChildWorkTypes({
      variables: {
        input: {
          page: 1,
          limit: 1,
          action: PolicyAction.READ,
          parentId: workTemplate?.id,
        },
      },
    });
  }, [workTemplate?.id]);

  const duplicateErrorText =
    workTypeVariant === WorkTypeVariant.CHILD
      ? 'You cannot duplicate tasks on a child work template after it has been created.'
      : 'You cannot duplicate tasks on the parent work template when any child work template exists.';

  const deleteErrorText =
    workTypeVariant === WorkTypeVariant.CHILD
      ? 'You cannot delete tasks from a child work template after it has been created.'
      : 'You cannot delete this task from the parent work template when any child work template exists.';

  const handleDuplicate = async () => {
    Snackbar.info({ message: `Duplicating ${task.name}...` });

    try {
      await workTemplateTaskDuplicate({
        variables: {
          input: {
            workTemplateId: workTemplate.id,
            taskId: task.id,
          },
        },
      });

      await refetch();

      Snackbar.info({ message: `Duplicated ${task.name}.` });
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_RESOURCE_CONFLICT) {
        Snackbar.error({ message: duplicateErrorText });
      } else {
        Snackbar.error({ message: 'An unknown error occurred' });
      }
    }
  };

  const handleDelete = async () => {
    try {
      await workTemplateTaskDelete({
        variables: {
          input: {
            workTemplateId: workTemplate.id,
            taskId: task.id,
          },
        },
      });

      Snackbar.info({
        message: 'Task deleted',
      });

      dispatch({
        type: WorkTemplateActionTypes.DELETE_TASK,
        data: { taskId: task.id },
      });

      setDeleteModalOpen(false);
      refetch();
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_RESOURCE_CONFLICT) {
        Snackbar.error({ message: deleteErrorText });
      } else {
        Snackbar.error({ message: 'An unknown error occurred' });
      }
    }
  };

  const childWorkTypeCount =
    workOrderTemplatesData?.workOrderTemplates?.totalCount;

  const isDisabled =
    workTemplate?.published ||
    childWorkTypeCount > 0 ||
    workTypeVariant === WorkTypeVariant.CHILD ||
    workOrderTemplatesLoading ||
    doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  const showTooltip =
    childWorkTypeCount > 0 || workTypeVariant === WorkTypeVariant.CHILD;

  const getButton = (text: string, button: React.ReactNode) => {
    return showTooltip ? (
      <Tooltip title={text}>
        <span>{button}</span>
      </Tooltip>
    ) : (
      button
    );
  };

  const duplicateButton = getButton(
    duplicateErrorText,
    <IconButton
      tooltip="Duplicate Task"
      disabled={isDisabled}
      onClick={handleDuplicate}
    >
      <Icon>content_copy</Icon>
    </IconButton>,
  );

  const deleteButton = getButton(
    deleteErrorText,
    <IconButton
      tooltip="Delete Task"
      disabled={isDisabled}
      onClick={() => setDeleteModalOpen(true)}
    >
      <Icon>delete</Icon>
    </IconButton>,
  );

  return (
    <>
      {duplicateButton}
      {deleteButton}
      <DeleteModal
        title="Delete task?"
        open={deleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={() => handleDelete()}
        confirmText="Delete"
        content="This will delete the task details, team members, asset association, and forms data."
      />
    </>
  );
};

export default WorkTemplateTaskControls;
