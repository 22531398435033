import React, { useContext } from 'react';

import TaskContext from '@atom/components/workOrderPreview/taskView/TaskContext';
import { setDurationDisplay } from '@atom/utilities/timeUtilities';

import './taskView.css';

const TaskDuration = () => {
  const { task } = useContext(TaskContext);

  return (
    <div styleName="time-row-item">
      <div styleName="label-container">
        <div styleName="label">Duration</div>
      </div>
      <div styleName="date-display">{setDurationDisplay(task.duration)}</div>
    </div>
  );
};

export default TaskDuration;
