export interface ReportOption {
  label: string;
  value: string;
  subheading?: string;
}

export const NOTIFICATION_OPTIONS = {
  WORK_STATUS_CHANGE: {
    label: 'Any work status changes.',
    value: 'WorkOrderStatusChange',
  },
  ASSIGNED_TASK: {
    label: 'Someone assigns me a new task.',
    value: 'WorkOrderTaskAssignedEmail',
  },
};
