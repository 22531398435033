import React, { useState } from 'react';
import { match as Match } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import { GET_USER } from '@atom/graph/user';
import { Progress } from '@atom/mui';

import BudgetsTab from './budgetsTab/BudgetsTab';
import CertificationsTab from './certificationsTab/CertificationsTab';
import UserHeader from './header/UserHeader';
import InfoTab from './infoTab/InfoTab';
import PermissionsTab from './permissionsTab/PermissionsTab';
import RolesTab from './rolesTab/RolesTab';
import SettingsTab from './settingsTab/SettingsTab';
import WorkTab from './workTab/WorkTab';
import UserContext, { View } from './UserContext';

import './userDetail.css';

export interface Props {
  match: Match<{ id: string }>;
}

const UserDetail = ({ match }: Props) => {
  const [activeView, setActiveView] = useState<View>(View.INFO);

  const { loading, data, error, refetch } = useQuery(GET_USER, {
    variables: {
      id: match.params.id,
    },
    fetchPolicy: 'network-only',
  });

  if (error || R.isNil(data)) {
    return <Progress style={{ height: '100%' }} />;
  }

  const getActiveView = () => {
    const views = {
      [View.INFO]: <InfoTab />,
      [View.BUDGETS]: <BudgetsTab />,
      [View.PERMISSIONS]: <PermissionsTab />,
      [View.CERTIFICATIONS]: <CertificationsTab />,
      [View.WORK]: <WorkTab />,
      [View.SETTINGS]: <SettingsTab />,
      [View.ROLES]: <RolesTab />,
    };

    return views[activeView];
  };

  const contextValue = {
    activeView,
    setActiveView,
    user: data ? data.user : {},
    loading,
    refetch,
  };

  return (
    <UserContext.Provider value={contextValue}>
      <div styleName="user-detail-container">
        <UserHeader />
        <div styleName="content-container">
          {activeView === View.INFO || activeView === View.WORK ? (
            <div styleName="full-pane-container">{getActiveView()}</div>
          ) : (
            <div styleName="center-pane-container">
              <div styleName="center-pane" style={{ overflow: 'auto' }}>
                {getActiveView()}
              </div>
            </div>
          )}
        </div>
      </div>
    </UserContext.Provider>
  );
};

export default UserDetail;
