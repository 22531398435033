import React, { useEffect, useMemo, useState } from 'react';
import {
  Snackbar as MUISnackbar,
  SnackbarCloseReason,
  SnackbarContent,
} from '@mui/material';
import * as R from 'ramda';

import { Button, Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';

export type NotificationType = 'info' | 'warning' | 'error';

export type Notification = {
  /**
   * Type or "level" of notification
   */
  type?: NotificationType;
  /**
   * Message to show to the user
   */
  message: string;
  /**
   * Optional duration in milliseconds
   * to auto-close the snackbar at.
   * Defaults to 10000 (10 seconds)
   */
  autoHideDuration?: number;
  /**
   * Text to render in the "action" button
   * defaults to "dismiss"
   */
  action?: string;
  /**
   * Optional callback to be called when
   * action button is clicked
   */
  onActionClick?: () => void;
  /**
   * Optional callback to be called when
   * the snackbar is dismissed via
   * "click away", or dismiss action click
   */
  onDismiss?: () => void;
  /**
   * Denotes that the user clicking outside the notification
   * should not dismiss the notification.
   * User must explicitly click the dismiss action or X button.
   */
  disableClickAwayDismiss?: boolean;
};

type Subscriber = (notifications: Notification[]) => void;

const EMPTY_NOTIFICATION: Notification = {
  type: 'info',
  message: '',
};

const styles = {
  icon: {
    marginRight: '1rem',
    color: colors.neutral.white,
  },
  button: {
    color: colors.neutral.white,
    minWidth: 0,
  },
  info: {
    backgroundColor: colors.neutral.dark,
  },
  error: {
    backgroundColor: colors.utility.error,
  },
};

const icons: { [key in NotificationType]: React.ReactNode } = {
  info: null,
  warning: <Icon style={styles.icon}>warning</Icon>,
  error: <Icon style={styles.icon}>error</Icon>,
};

export class SnackbarWrapper {
  private notifications: Notification[] = [];

  private subscribers: Subscriber[] = [];

  private subscribe = (subscriber: Subscriber) => {
    this.subscribers = [...this.subscribers, subscriber];
  };

  private notify = () => {
    this.subscribers.forEach(subscriber => subscriber(this.notifications));
  };

  private getNotificationHandler = (type: NotificationType) => (
    notification: R.Omit<Notification, 'type'>,
  ) => {
    this.notifications = [...this.notifications, { ...notification, type }];
    this.notify();
  };

  private handleExit = () => {
    this.notifications = R.remove(0, 1, this.notifications);
    this.notify();
  };

  info = this.getNotificationHandler('info');
  warning = this.getNotificationHandler('warning');
  error = this.getNotificationHandler('error');

  dismiss = () => {
    this.notifications = [];
    this.notify();
  };

  Provider = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<Notification[]>([]);

    useEffect(() => {
      this.subscribe(setNotifications);

      return () => {
        this.subscribers = [];
      };
    }, []);

    useEffect(() => {
      // keeps peeling notifications off the front of the stack until 1 remains
      // this ensures component is properly opened and closed instead of the content being instantly replaced
      // setting open to false causes MUI Snackbar to call onExited which in turn removes the first notification
      // from the stack causing this effect to be run again, looping until 1 remains
      if (notifications.length >= 2) {
        setOpen(false);
      } else if (notifications.length === 1) {
        setOpen(true);
      }
    }, [notifications]);

    const notification = useMemo(() => {
      return R.pathOr<Notification>(EMPTY_NOTIFICATION, [0], notifications);
    }, [notifications]);

    const {
      type,
      message = '',
      autoHideDuration = 10000,
      action,
      disableClickAwayDismiss,
      onActionClick = () => {},
      onDismiss = () => {},
    } = notification;

    const handleClose = (event: any, reason: SnackbarCloseReason) => {
      if (!disableClickAwayDismiss || reason !== 'clickaway') {
        setOpen(false);
        onDismiss();
      }
    };

    const handleDismiss = () => {
      setOpen(false);
      onDismiss();
    };

    const handleActionClick = () => {
      setOpen(false);
      onActionClick();
    };

    return (
      <MUISnackbar
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        TransitionProps={{
          onExited: this.handleExit,
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <SnackbarContent
          message={
            <>
              {icons[type]}
              {message}
            </>
          }
          action={
            <>
              <Button
                onClick={handleActionClick}
                size="small"
                style={styles.button}
              >
                {action || 'Dismiss'}
              </Button>
              {action && (
                <IconButton size="small" onClick={handleDismiss}>
                  <Icon color={colors.neutral.white}>close</Icon>
                </IconButton>
              )}
            </>
          }
          style={type === 'error' ? styles.error : styles.info}
        />
      </MUISnackbar>
    );
  };
}

export const Snackbar = new SnackbarWrapper();
