import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { SCHEMA_UPDATE } from '@atom/graph/schema';
import {
  SchemaBudget,
  SchemaTree,
  SchemaUpdateInput,
} from '@atom/types/schema';

import CostSection from './CostSection';
import ReadingsSection from './ReadingsSection';
import StockSection from './StockSection';

import './costDetail.css';

const CostDetail = () => {
  const { schemaTree, refetchSchemaTree } = useContext(SchemaDetailContext);

  const [budget, setBudget] = useState<SchemaBudget>(schemaTree?.budget);

  useEffect(() => {
    setBudget(schemaTree?.budget);
  }, [schemaTree?.budget]);

  const [updateSchema, { loading: loadingUpdate }] = useMutation<
    { schemaUpdate: SchemaTree },
    { input: SchemaUpdateInput }
  >(SCHEMA_UPDATE);

  const updateBudget = async (updatedBudget: SchemaBudget) => {
    await updateSchema({
      variables: {
        input: {
          schemaId: schemaTree?.id,
          budget: updatedBudget,
        },
      },
    });

    refetchSchemaTree();
  };

  return (
    <div styleName="center-pane-container">
      <div styleName="center-pane">
        <CostSection
          loading={loadingUpdate}
          budget={budget}
          setBudget={setBudget}
          updateBudget={updateBudget}
        />
        <StockSection
          budget={budget}
          setBudget={setBudget}
          updateBudget={updateBudget}
        />
        <ReadingsSection
          budget={budget}
          setBudget={setBudget}
          updateBudget={updateBudget}
        />
      </div>
    </div>
  );
};

export default CostDetail;
