import React, { useContext, useEffect, useRef, useState } from 'react';
import * as R from 'ramda';

import WorkTemplatesContext from '@atom/components/workTemplates/WorkTemplatesContext';
import { Icon, IconButton, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import { WorkTemplateFolderTree } from '@atom/types/workTemplate';

import FolderOptions from './FolderOptions';
import WorkTemplatesFolderRow from './WorkTemplatesFolderRow';

import './workTemplatesFolderTree.css';

const styles = {
  indentation: {
    paddingLeft: '2rem',
  },
  iconStyle: {
    fontSize: '24px',
    margin: 0,
  },
  overflowStyle: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '12rem',
  },
};

export interface Props {
  workTemplateFolderTree: WorkTemplateFolderTree;
}

const WorkTemplatesFolderTree = ({ workTemplateFolderTree }: Props) => {
  const {
    open,
    toggleOpen,
    setActiveFolderId,
    activeFolderId,
    setIsActiveFolderLeafNode,
  } = useContext(WorkTemplatesContext);
  const expanded = open.has(workTemplateFolderTree.id);
  const [overflowing, setOverflowing] = useState<boolean>(false);
  const container = useRef<HTMLDivElement>();

  useEffect(() => {
    const { offsetWidth, scrollWidth } = container.current;
    setOverflowing(offsetWidth < scrollWidth);
  }, [workTemplateFolderTree.name]);

  useEffect(() => {
    if (activeFolderId === workTemplateFolderTree.id) {
      setIsActiveFolderLeafNode(R.isEmpty(workTemplateFolderTree.children));
    }
  }, [
    setIsActiveFolderLeafNode,
    workTemplateFolderTree.children,
    activeFolderId,
    workTemplateFolderTree.id,
  ]);

  const arrowDirection = expanded ? 'arrow_drop_down' : 'arrow_right';
  const rowStyle =
    workTemplateFolderTree.id === activeFolderId
      ? 'folder-row selected'
      : 'folder-row';

  const renderExpandedArrow = !R.isEmpty(workTemplateFolderTree.children);

  const onClick = () => {
    setActiveFolderId(workTemplateFolderTree.id);
    setIsActiveFolderLeafNode(R.isEmpty(workTemplateFolderTree.children));
  };

  return (
    <>
      <div styleName={rowStyle} onClick={onClick}>
        <div
          ref={container}
          styleName="folder-name-container"
          style={renderExpandedArrow ? {} : styles.indentation}
        >
          {renderExpandedArrow && (
            <IconButton
              onClick={() => toggleOpen(workTemplateFolderTree.id)}
              style={styles.iconStyle}
            >
              <Icon color={colors.neutral.dim}>{arrowDirection}</Icon>
            </IconButton>
          )}
          <Icon style={styles.iconStyle} color={colors.neutral.gray}>
            folder
          </Icon>
          <div styleName="row-name-container">
            <div>
              {!overflowing ? (
                workTemplateFolderTree.name
              ) : (
                <Tooltip
                  lightTooltip
                  title={workTemplateFolderTree.name}
                  placement="right-start"
                >
                  <p style={styles.overflowStyle}>
                    {workTemplateFolderTree.name}
                  </p>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <FolderOptions folder={workTemplateFolderTree} isRootFolder />
      </div>
      {expanded ? (
        <>
          {workTemplateFolderTree.children.map(
            (child: WorkTemplateFolderTree) => (
              <WorkTemplatesFolderRow
                key={child.id}
                folder={child}
                indentation={0}
              />
            ),
          )}
        </>
      ) : (
        <div />
      )}
    </>
  );
};

export default WorkTemplatesFolderTree;
