import React from 'react';

import { ciFormModule } from '@atom/selectors/formModuleSelectors';
import {
  CiModuleInspectionUpdate,
  FormInstanceFieldItem,
} from '@atom/types/form';
import { MediaItem } from '@atom/types/media';

import CiFieldRow from './CiFieldRow';
import CiScore from './CiScore';

import './ci.css';

interface Props {
  field: FormInstanceFieldItem;
  fieldId: string;
  assetId: string;
  media: MediaItem[];
  preview: boolean;
  onChange: (update: CiModuleInspectionUpdate) => void;
  uploadFiles: (
    files?: any[],
    subjectId?: string,
    subjectType?: string,
    inspectionUpdate?: CiModuleInspectionUpdate,
  ) => void;
  removeFile: (mediaId: string, subjectId: string) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  isEditable: boolean;
}

const CiField = ({
  field,
  fieldId,
  assetId,
  preview,
  media,
  onChange,
  uploadFiles,
  renameMedia,
  isEditable,
}: Props) => {
  return (
    <div>
      <div styleName="container">
        <div styleName="header">
          <div styleName="title-container">
            {ciFormModule.icon}
            <div styleName="title">
              {`${ciFormModule.title}: ${field.name}`}
            </div>
          </div>
          <CiScore />
        </div>
      </div>
      <div styleName="table-header">
        <div styleName="large-column">inspection item</div>
        <div styleName="description-column">description</div>
        <div styleName="normal-column rating">rating description</div>
        <div styleName="small-column points">ci points</div>
        <div styleName="small-column">photos</div>
        <div styleName="small-column">notes</div>
      </div>
      <div styleName="table-rows-container">
        {field.inspections.map((inspection, index) => {
          const inspectionMedia = inspection.mediaIds
            ? media.filter(({ id }) => inspection.mediaIds.includes(id))
            : [];

          return (
            <CiFieldRow
              fieldId={fieldId}
              assetId={assetId}
              inspection={inspection}
              inspectionIndex={index}
              media={inspectionMedia}
              onChange={onChange}
              preview={preview}
              key={index}
              uploadFiles={uploadFiles}
              renameMedia={renameMedia}
              isEditable={isEditable}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CiField;
