import * as React from 'react';

import { Progress } from '@atom/mui';

import './searchPortal.css';

interface Props {
  loading: boolean;
  total: number;
  data: any;
}

const SearchPortalResultsContainer = ({ loading, total, data }: Props) => {
  const content = loading ? (
    <div styleName="spinner-container">
      <Progress />
    </div>
  ) : (
    data
  );

  const results =
    isNaN(total) || loading ? 'Searching...' : `${total} search results`;

  return (
    <div styleName="search-portal-results-container">
      <div styleName="search-portal-results">
        <div styleName="search-portal-results-heading">{results}</div>
        {content}
      </div>
    </div>
  );
};

export default SearchPortalResultsContainer;
