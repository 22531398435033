import React, { useContext } from 'react';

import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';

import BulkContext, { Tab } from '../BulkContext';

import './step.css';

export interface Props {
  currentTab: Tab;
  tab: Tab;
}

const Step = ({ currentTab, tab }: Props) => {
  const { invalidTabs, setTab } = useContext(BulkContext);
  const isInvalid = invalidTabs.has(tab.id);
  const isActive = currentTab.value === tab.value;
  const iconColor = isActive ? colors.utility.activeBlue : colors.neutral.gray;

  const onStepClick = () => setTab(tab);

  return (
    <div
      styleName={`step ${isActive ? 'step-active' : ''}`}
      onClick={onStepClick}
    >
      {isInvalid ? (
        <Icon color={colors.brand.red}>error</Icon>
      ) : (
        <Icon color={iconColor}>{tab.icon}</Icon>
      )}
      <div styleName="step-title-container">
        <div
          styleName={`step-title ${isActive ? 'active' : ''} ${
            isInvalid ? 'invalid' : ''
          }`}
        >
          {tab.label}
        </div>
        {tab.subText && (
          <div
            styleName={`step-subtext ${isActive ? 'active' : ''} ${
              isInvalid ? 'invalid' : ''
            }`}
          >
            {tab.subText}
          </div>
        )}
      </div>
    </div>
  );
};

export default Step;
