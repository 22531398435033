/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import * as R from 'ramda';

import InventoryAssetTree from '@atom/components/common/inventoryAssetTree/InventoryAssetTree';
import { Button, Icon, IconButton, Modal, Snackbar } from '@atom/mui';
import { CsvResponse } from '@atom/types/csv';
import { CsvErrorItem, CsvErrorLevel } from '@atom/types/error';
import { InventoryCategoryTree } from '@atom/types/inventory';
import api from '@atom/utilities/api';
import { INVENTORY_BULK_ASSETS_ENDPOINT } from '@atom/utilities/endpoints';

import InventoryUploadMain from './InventoryUploadMain';

import './inventoryUpload.css';

const styles = {
  back: {
    marginRight: '1rem',
  },
};

export enum InventoryUploadView {
  MAIN,
  INVENTORY_FOLDER,
  PENDING_CHANGES,
  PROCESSING,
}

export enum InventoryUploadType {
  CREATE = 'Create Inventory',
  UPDATE = 'Update Inventory',
}

export interface InventoryUploadState {
  open: boolean;
  type: InventoryUploadType;
  view: InventoryUploadView;
  file: File | null;
  category: InventoryCategoryTree | null;
  schemaId: string;
  errors: CsvErrorItem[];
  validating: boolean;
  processing: boolean;
}

type Subscriber = (open: boolean) => void;

const PENDING_CHANGES = 'pending changes';

const INITIAL_STATE: InventoryUploadState = {
  open: false,
  type: InventoryUploadType.CREATE,
  view: InventoryUploadView.MAIN,
  file: null,
  category: null,
  schemaId: '',
  errors: [],
  validating: false,
  processing: false,
};

const getErrorMessage = (
  err: AxiosError<CsvResponse>,
  type: InventoryUploadType,
) => {
  if (!err?.response?.data) {
    return 'Upload Failed.';
  }

  const { processedRows, failedRows, totalRows } = err.response.data;
  const isCreate = type === InventoryUploadType.CREATE;

  return R.isNil(processedRows) || R.isNil(failedRows) || R.isNil(totalRows)
    ? 'Upload Failed.'
    : `Successfully ${
        isCreate ? 'created' : 'updated'
      } row 1 to ${processedRows}. Failed ${
        isCreate ? 'creating' : 'updating'
      } row ${totalRows - failedRows + 1} to ${totalRows}.`;
};

class UploadInventoryCSVModalWrapper {
  private subscriber: Subscriber;

  open = () => this.subscriber(true);

  close = () => this.subscriber(false);

  Provider = () => {
    const [state, setState] = useState<InventoryUploadState>(INITIAL_STATE);

    const {
      open,
      type,
      view,
      file,
      category,
      schemaId,
      errors,
      validating,
      processing,
    } = state;

    // sets state by merging current state with the given partial
    const mergeState = useCallback(
      (updates: Partial<InventoryUploadState>) => {
        setState(current => ({ ...current, ...updates }));
      },
      [setState],
    );

    const isCreate = type === InventoryUploadType.CREATE;

    useEffect(() => {
      this.subscriber = (open: boolean) => {
        mergeState({ open });
      };

      return () => {
        this.subscriber = null;
      };
    }, []);

    const handleExited = () => {
      // if no upload currently being processed, reset state on modal exit
      if (!processing) {
        setState({ ...INITIAL_STATE });
      } else {
        mergeState({
          view: InventoryUploadView.PROCESSING,
        });
      }
    };

    const handleCancel = () => {
      if (view === InventoryUploadView.PENDING_CHANGES) {
        mergeState({ view: InventoryUploadView.MAIN });
      } else {
        mergeState({ open: false });
      }
    };

    const handleCategoryChange = (tree: InventoryCategoryTree) => {
      // only categories with a schema or with no child nodes may be selected
      if (tree.schemaId || !tree.categories.length) {
        setState(current => ({
          ...current,
          view: InventoryUploadView.MAIN,
          category: tree,
          schemaId: tree.schemaId || '',
          errors: [],
        }));
      }
    };

    // handles error response of CSV bulk create/update with `validateOnly: true`
    const handleValidationError = (err: AxiosError<CsvResponse>) => {
      const message =
        'Something went wrong. You may have reached a limit with the number of rows and columns in your file. Reducing them may resolve the issue.';

      const errs = R.pathOr<CsvErrorItem[]>(
        [
          {
            message,
          },
        ],
        ['response', 'data', 'errors'],
        err,
      );

      mergeState({ validating: false, errors: errs });
    };

    // handles success (2XX range) response of calling CSV bulk create/update with `validateOnly: true`
    // note that INFO and WARNING level errors may still be present
    const handleValidationResponse = async (response: CsvResponse) => {
      const hasPendingChanges =
        response.errors?.length &&
        response.errors?.every(
          err =>
            err.level === CsvErrorLevel.WARNING &&
            err.message.includes(PENDING_CHANGES),
        );

      // if pending changes, prompt user to confirm before actual application
      if (hasPendingChanges) {
        mergeState({ view: InventoryUploadView.PENDING_CHANGES });
      } else {
        // else submit for application (no validation)
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await handleSubmit(false);
      }

      mergeState({ validating: false });
    };

    // handles errors response of CSV bulk create/update with `validateOnly: false`
    // i.e. errors response from the attempted application of changes
    const handleApplicationError = (err: AxiosError<CsvResponse>) => {
      const message = getErrorMessage(err, type);

      mergeState({ open: false });

      Snackbar.error({
        message,
        action: 'Try Again',
        autoHideDuration: 10000,
        onActionClick: () => {
          if (isCreate) {
            setState({ ...INITIAL_STATE, open: true });
          } else {
            mergeState({
              open: true,
              processing: false,
              view: InventoryUploadView.MAIN,
            });
          }
        },
        onDismiss: () => {
          // reset all state properties except open
          // this is in case user clicks icon to open modal
          // which additionally "dismisses" the snackbar calling this callback
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { open: _, ...data } = INITIAL_STATE;
          mergeState(data);
        },
      });
    };

    // handles success (2XX range) response of calling CSV bulk create/update with `validateOnly: false`
    // i.e. changes are actually applied and not just validated
    const handleApplicationResponse = (response: CsvResponse) => {
      if (open) {
        mergeState({ open: false, processing: false });
      } else {
        setState({ ...INITIAL_STATE });
      }

      const message = isCreate
        ? 'Successfully created inventory items.'
        : `Successfully updated ${response.processedRows} inventory items.`;

      Snackbar.info({ message });
    };

    // handles network request for bulk update, both validation and application
    const handleSubmit = async (validateOnly: boolean) => {
      if (validateOnly) {
        mergeState({ validating: true });
      } else {
        mergeState({ open: false, processing: true });

        Snackbar.info({
          message: 'Upload in progress...',
          disableClickAwayDismiss: true,
          autoHideDuration: null,
        });
      }

      const formData = new FormData();
      formData.append('file', file);

      try {
        const verb = isCreate ? 'post' : 'patch';

        // @ts-ignore
        const { data } = await api[verb]<CsvResponse>(
          INVENTORY_BULK_ASSETS_ENDPOINT,
          formData,
          {
            schemaId,
            validateOnly,
            ...(category && {
              categoryId: category.id,
            }),
          },
        );

        // TODO: make sure all these handlers set validating/processing states as well as open
        if (validateOnly) {
          await handleValidationResponse(data);
        } else {
          handleApplicationResponse(data);
        }
      } catch (error) {
        if (validateOnly) {
          handleValidationError(error);
        } else {
          handleApplicationError(error);
        }
      }
    };

    const handleValidation = () => {
      // if PENDING_CHANGES validation has already occurred
      const validateOnly = view !== InventoryUploadView.PENDING_CHANGES;
      handleSubmit(validateOnly);
    };

    const isDisabled = R.isNil(file) || (isCreate && !category) || !schemaId;

    // map of modal titles per view
    const titles = useMemo<{ [key in InventoryUploadView]: React.ReactNode }>(
      () => ({
        [InventoryUploadView.MAIN]: 'Upload CSV',
        [InventoryUploadView.PENDING_CHANGES]: 'Upload CSV',
        [InventoryUploadView.PROCESSING]: 'Upload in Progress...',
        [InventoryUploadView.INVENTORY_FOLDER]: (
          <>
            <IconButton
              style={styles.back}
              onClick={() => mergeState({ view: InventoryUploadView.MAIN })}
            >
              <Icon>arrow_back</Icon>
            </IconButton>
            Choose Folder
          </>
        ),
      }),
      [],
    );

    // map of primary content per view
    const content: { [key in InventoryUploadView]: React.ReactNode } = {
      [InventoryUploadView.MAIN]: (
        <InventoryUploadMain
          type={type}
          onTypeChange={type =>
            setState({ ...INITIAL_STATE, type, open: true })
          }
          file={file}
          onFileChange={file => mergeState({ file, errors: [] })}
          schemaId={schemaId}
          onSchemaChange={schemaId => mergeState({ schemaId, errors: [] })}
          category={category}
          onCategoryChange={category =>
            mergeState({
              category,
              schemaId: category?.schemaId || '',
              errors: [],
            })
          }
          onViewChange={view => mergeState({ view })}
          errors={errors}
          validating={validating}
        />
      ),
      [InventoryUploadView.PENDING_CHANGES]:
        'File contains attributes with pending changes that will be overwritten. Are you sure?',
      [InventoryUploadView.PROCESSING]:
        "This may take a few minutes. Please don't press the Back button or exit your browser while in progress. We will let you know when it's complete.",
      [InventoryUploadView.INVENTORY_FOLDER]: (
        <>
          <InventoryAssetTree
            onCategoryClick={handleCategoryChange}
            schemaId={schemaId}
            search="categories"
            includeAssets={false}
          />
        </>
      ),
    };

    return (
      <Modal
        open={open}
        title={titles[view]}
        onCancel={handleCancel}
        confirmButtonText="UPLOAD"
        onConfirm={handleValidation}
        disabled={isDisabled}
        loading={validating}
        onExited={handleExited}
        contentStyle={
          view === InventoryUploadView.INVENTORY_FOLDER
            ? { height: '50vh', padding: '2rem' }
            : {}
        }
        footer={
          view === InventoryUploadView.PROCESSING && (
            <Button onClick={() => mergeState({ open: false })}>OK</Button>
          )
        }
      >
        {content[view]}
      </Modal>
    );
  };
}

export default new UploadInventoryCSVModalWrapper();
