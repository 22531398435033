import React, { useContext } from 'react';

import WorkOrderDetailMap from '@atom/components/common/workOrderDetail/workOrderDetailMap/WorkOrderDetailMap';
import WorkOrderFields from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderFields';
import WorkOrderContext, {
  WorkOrderActionTypes,
} from '@atom/components/workOrderDetail/WorkOrderContext';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import DetailPane from './DetailPane';
import SummaryPane from './SummaryPane';

import './workOrderInfo.css';

const WorkOrderInfo = () => {
  const { workOrderDetail, refetch, loading, dispatch } = useContext(
    WorkOrderContext,
  );

  const showFields =
    hasRolePermissions(ROLE_SETS.INSPECTOR) || !workOrderDetail.workTemplateId;

  return (
    <div styleName="info-container">
      {workOrderDetail.isLocationBased ? (
        <>
          <div styleName="pane left">
            <SummaryPane />
            {showFields && (
              <WorkOrderFields
                workOrderDetail={workOrderDetail}
                dispatch={dispatch}
                WorkOrderActionTypes={WorkOrderActionTypes}
              />
            )}
            <DetailPane />
          </div>
          <div styleName="pane">
            <WorkOrderDetailMap
              workOrderDetail={workOrderDetail}
              refetch={refetch}
              loading={loading}
            />
          </div>
        </>
      ) : (
        <div styleName="no-location-pane">
          <SummaryPane />
          {showFields && (
            <WorkOrderFields
              workOrderDetail={workOrderDetail}
              dispatch={dispatch}
              WorkOrderActionTypes={WorkOrderActionTypes}
            />
          )}
          <DetailPane />
        </div>
      )}
    </div>
  );
};

export default WorkOrderInfo;
