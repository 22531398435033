import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DragDrop } from 'react-beautiful-dnd';
import { useMutation } from '@apollo/client';
import debounce from 'lodash.debounce';

import {
  DragDropContext,
  Draggable,
  Droppable,
} from '@atom/components/common/dragAndDrop';
import SchemaDetailContext, {
  DragDropType,
} from '@atom/components/schemaDetail/SchemaDetailContext';
import { ATTRIBUTE_UPDATE } from '@atom/graph/schema';
import { Button, Icon, IconButton, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { DataType } from '@atom/types/dataType';
import {
  AttributeUpdateInput,
  SchemaTree,
  SchemaTreeAttribute,
} from '@atom/types/schema';

import { updatePendingCreation } from '../subItemDetail/subItemDetailUtilities';

import {
  addEnumOption,
  mergePendingChanges,
  removeEnumOption,
  reorderEnumOptions,
  updateEnumOption,
  updatePendingUpdates,
} from './attributeDetailUtilities';

import './attributeDetail.css';

const styles = {
  dragIcon: {
    color: colors.neutral.silver,
    paddingRight: '0.5rem',
  },
};

const DEBOUNCE_TIME = 500;

const EnumBuilder = () => {
  const {
    schemaTree,
    selectedAttribute,
    setSelectedAttribute,
    selectedSubItem,
    selectedAttributeRoute,
    refetchSchemaTree,
    setPendingUpdates,
    pendingUpdates,
    setPendingCreations,
  } = useContext(SchemaDetailContext);

  // If pending changes exist, they will be merged with the
  // selected attribute data to display
  const mergedAttribute = mergePendingChanges(
    selectedSubItem.id,
    selectedAttribute,
    pendingUpdates,
  );

  const [enumeration, setEnumeration] = useState<string[]>(
    mergedAttribute.enumeration,
  );

  useEffect(() => {
    if (mergedAttribute.enumeration.length !== enumeration.length) {
      setEnumeration(mergedAttribute.enumeration);
    }
  }, [mergedAttribute.enumeration]);

  useEffect(() => {
    setEnumeration(mergedAttribute.enumeration);
  }, [mergedAttribute.id]);

  const isEnumSingle = mergedAttribute.dataType === DataType.ENUM_SINGLE;

  const [updateAttribute] = useMutation<
    { attributeUpdate: SchemaTree },
    { input: AttributeUpdateInput }
  >(ATTRIBUTE_UPDATE);

  // If attribute is a temp attribute, values must be updated in pendingCreations state
  const handlePendingCreationUpdate = (value: string[]) => {
    const updatedAttribute = { ...selectedAttribute, enumeration: value };

    setPendingCreations(prev =>
      updatePendingCreation(
        selectedSubItem.id,
        selectedAttributeRoute,
        prev,
        updatedAttribute,
      ),
    );

    setSelectedAttribute(updatedAttribute);
  };

  // If schema is published, changes must be saved to the pending updates / creations carts
  const handlePendingAttributeUpdate = (
    property: keyof SchemaTreeAttribute,
    value: any,
  ) => {
    setPendingUpdates(prev =>
      updatePendingUpdates(
        selectedSubItem.id,
        prev,
        mergedAttribute,
        property,
        value,
      ),
    );
  };

  const handleAttributeUpdate = async (value: string[]) => {
    setSelectedAttribute(prev => ({ ...prev, enumeration: value }));

    await updateAttribute({
      variables: {
        input: {
          schemaId: selectedSubItem.id,
          attributeGroupId: selectedAttributeRoute.attributeGroupId,
          attributeId: mergedAttribute.id,
          enumeration: value,
        },
      },
    });

    refetchSchemaTree();
  };

  const onDragEnd = async (result: DragDrop) => {
    if (!result.destination) {
      return;
    }

    const newEnumeration = reorderEnumOptions(enumeration, result);

    setEnumeration(newEnumeration);
    await handleAttributeUpdate(newEnumeration);
  };

  const updateAttributeDebounced = useCallback(
    debounce((newEnumeration: string[]) => {
      handleAttributeUpdate(newEnumeration);
    }, DEBOUNCE_TIME),
    [mergedAttribute],
  );

  const onEnumChange = event => {
    const newEnumeration = updateEnumOption(
      enumeration,
      Number(event.target.id),
      event.target.value,
    );

    setEnumeration(newEnumeration);

    if (selectedAttribute.isTempAttribute) {
      handlePendingCreationUpdate(newEnumeration);
      return;
    }

    if (schemaTree.isPublished) {
      handlePendingAttributeUpdate('enumeration', newEnumeration);
      return;
    }

    if (!event.target.value) {
      updateAttributeDebounced.cancel();
    } else {
      updateAttributeDebounced(newEnumeration);
    }
  };

  const onEnumRemove = async (index: number) => {
    const newEnumeration = removeEnumOption(enumeration, index);

    if (selectedAttribute.isTempAttribute) {
      handlePendingCreationUpdate(newEnumeration);
    } else if (schemaTree.isPublished) {
      handlePendingAttributeUpdate('enumeration', newEnumeration);
    } else {
      setEnumeration(newEnumeration);
      await handleAttributeUpdate(newEnumeration);
    }
  };

  const onEnumAdd = async () => {
    const newEnumeration = addEnumOption(enumeration, isEnumSingle);

    if (selectedAttribute.isTempAttribute) {
      handlePendingCreationUpdate(newEnumeration);
    } else if (schemaTree.isPublished) {
      handlePendingAttributeUpdate('enumeration', newEnumeration);
    } else {
      setEnumeration(newEnumeration);
      await handleAttributeUpdate(newEnumeration);
    }
  };

  const isDragDisabled =
    schemaTree.isPublished && !selectedAttribute.isTempAttribute;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="enumBuilder"
        type={DragDropType.ENUM_OPTION}
        isDropDisabled={isDragDisabled}
      >
        {enumeration.map((option: string, index: number) => {
          // For enumsingle, there is an uneditable empty option in 0 index
          if (isEnumSingle && index === 0) {
            return <div />;
          }

          // On published schema, if the current index is less than the published enum length,
          // the option cannot be edited. Newly added options can be edited
          const isDisabled =
            schemaTree.isPublished &&
            index < selectedAttribute.enumeration.length &&
            !selectedAttribute.isTempAttribute;

          const displayNumber = isEnumSingle ? index : index + 1;

          return (
            <Draggable
              key={index}
              draggableId={index.toString()}
              index={index}
              type={DragDropType.ENUM_OPTION}
              isDragDisabled={isDragDisabled}
            >
              <div styleName="options-input-container">
                <Icon style={styles.dragIcon}>drag_indicator_icon</Icon>
                <div styleName="options-number">{`Option ${displayNumber}:`}</div>
                <TextField
                  key={index}
                  value={option}
                  onChange={onEnumChange}
                  id={index.toString()}
                  disabled={isDisabled}
                />
                <IconButton
                  onClick={() => onEnumRemove(index)}
                  size="small"
                  disabled={isDisabled}
                >
                  <Icon>close</Icon>
                </IconButton>
              </div>
            </Draggable>
          );
        })}
        <div styleName="options-input-container">
          <Button
            color="primary"
            onClick={onEnumAdd}
            startIcon={<Icon color={colors.brand.blue}>add</Icon>}
          >
            add option
          </Button>
        </div>
      </Droppable>
    </DragDropContext>
  );
};

export default EnumBuilder;
