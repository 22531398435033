import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  AssetDetailView,
  InventoryAssetDetailType,
} from '@atom/types/inventory';
import history from '@atom/utilities/history';

import './header.css';

interface Props {
  asset: InventoryAssetDetailType;
}

const styles = {
  disabled: {
    opacity: '40%',
  },
};

const AssetDetailPagination = ({ asset: { cursor } }: Props) => {
  const [query] = useQueryParams({
    activeView: StringParam,
  });

  const handleChange = (id: string) => {
    const activeView = query?.activeView || AssetDetailView.ASSET_INFO;
    history.push(`/inventory/${id}?activeView=${activeView}`);
  };

  const isDisabled = cursor.total === 1;

  return (
    <>
      <div styleName="pagination-container">
        {cursor.index + 1} of {cursor.total}
        <IconButton
          disabled={isDisabled}
          onClick={() => handleChange(cursor.previous)}
        >
          <Icon
            style={isDisabled ? styles.disabled : {}}
            color={colors.neutral.white}
          >
            keyboard_arrow_left
          </Icon>
        </IconButton>
        <IconButton
          disabled={isDisabled}
          onClick={() => handleChange(cursor.next)}
        >
          <Icon
            style={isDisabled ? styles.disabled : {}}
            color={colors.neutral.white}
          >
            keyboard_arrow_right
          </Icon>
        </IconButton>
      </div>
      <div styleName="pagination-divider" />
    </>
  );
};

export default AssetDetailPagination;
