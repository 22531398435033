import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function (state = initialState.inventorySchemasByIds, action) {
  switch (action.type) {
    case actionTypes.GET_SCHEMA_BY_IDS_SUCCESS: {
      const { schemas, fieldId } = action.data;
      return {
        ...state,
        [fieldId]: schemas,
      };
    }
    default: {
      return state;
    }
  }
}
