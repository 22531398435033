import React, { useContext, useEffect, useState } from 'react';
import * as R from 'ramda';

import AddSummaryModal from '@atom/components/common/workOrderDetail/workOrderSummary/AddSummaryModal';
import WorkOrderPreviewContext, {
  ExpandedInfo,
} from '@atom/components/workOrderPreview/WorkOrderPreviewContext';
import { Icon, IconButton } from '@atom/mui';
import { Summary } from '@atom/types/work';
import api from '@atom/utilities/api';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { WORK_ORDERS_ENDPOINT } from '@atom/utilities/endpoints';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import SummaryRow from './SummaryRow';

import './infoView.css';

const SummarySection = () => {
  const {
    expandedInfo,
    setExpandedInfo,
    workOrderDetail,
    refetch,
  } = useContext(WorkOrderPreviewContext);

  const [open, setOpen] = useState<boolean>(false);
  const [summary, setSummary] = useState<Summary[]>(
    workOrderDetail.summary || [],
  );

  useEffect(() => {
    setSummary(workOrderDetail.summary);
  }, [workOrderDetail.summary]);

  const updateSummary = async (updatedSummary: Summary[]) => {
    setSummary(updatedSummary);

    const endpoint = `${WORK_ORDERS_ENDPOINT}/${workOrderDetail.id}`;

    await api.patch(endpoint, { summary: updatedSummary });
    refetch();
  };

  const addSummary = (newSummary: Summary) => {
    updateSummary([...summary, newSummary]);
  };

  const toggleSection = () => {
    setExpandedInfo(toggleFromSet(expandedInfo, ExpandedInfo.SUMMARY));
  };

  const icon = expandedInfo.has(ExpandedInfo.SUMMARY)
    ? 'expand_less'
    : 'expand_more';

  const canAddSummary = hasRolePermissions(ROLE_SETS.INSPECTOR);

  return (
    <>
      <div styleName="section-header">
        <div styleName="section-title">Summary</div>
        <div>
          {canAddSummary && (
            <IconButton onClick={() => setOpen(true)}>
              <Icon>add</Icon>
            </IconButton>
          )}
          <IconButton onClick={toggleSection}>
            <Icon>{icon}</Icon>
          </IconButton>
        </div>
      </div>
      {expandedInfo.has(ExpandedInfo.SUMMARY) && (
        <div styleName="section-content">
          {R.isEmpty(summary) ? (
            <div styleName="no-summary">There is no summary.</div>
          ) : (
            summary.map((summaryItem: Summary, index: number) => (
              <SummaryRow
                key={`${summaryItem.title}-${index}`}
                summary={summaryItem}
                summaries={summary}
                index={index}
                updateSummary={updateSummary}
              />
            ))
          )}
        </div>
      )}
      <AddSummaryModal
        open={open}
        onClose={() => setOpen(false)}
        addSummary={addSummary}
      />
    </>
  );
};

export default SummarySection;
