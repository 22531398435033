import { call, fork, put, takeEvery } from 'redux-saga/effects';

import actionTypes from '@atom/actions/actionTypes';
import * as organizationActions from '@atom/actions/organizationActions';
import { Snackbar } from '@atom/mui';
import { apiErrorHandler, getToken } from '@atom/utilities/accountUtilities';
import api from '@atom/utilities/api';
import { TENANT_ENDPOINT } from '@atom/utilities/endpoints';

export function* createTenant(action) {
  try {
    const payload = {
      ...action.data,
    };

    yield call(api.post, TENANT_ENDPOINT, payload);
    yield put(organizationActions.createTenantSuccess());

    const origin = `${window.location.origin}`;
    const token = getToken();
    const redirectTo = `${origin}/callback/${token}?tenant=${action.data.name}`;

    window.location.href = redirectTo;
  } catch (error) {
    Snackbar.error({
      message: `This tenant already exists or you've created a tenant already`,
    });
    yield fork(apiErrorHandler, error, organizationActions.createTenantFailure);
  }
}

/* WATCHERS */

export function* requestTenantCreation() {
  yield takeEvery(actionTypes.REQUEST_TENANT_CREATION, createTenant);
}

export default [requestTenantCreation];
