import React, { useState } from 'react';
import * as R from 'ramda';

// @ts-ignore
import taskTypeIcon from '@atom/components/common/svgIcons/taskType.svg';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Icon, IconButton, Menu, Progress } from '@atom/mui';
import { TaskCreateInput } from '@atom/types/task';

import TaskCreateModal from './TaskCreateModal';
import TaskCreateTemplateModal from './TaskCreateTemplateModal';

import './taskCreate.css';

const { MenuItem } = Menu;

interface Props {
  workOrderId: string;
  loading: boolean;
  handleCreate: (property: keyof TaskCreateInput, value: string) => void;
  isCreateDisabled: boolean;
}

const TaskCreateControls = ({
  workOrderId,
  loading,
  handleCreate,
  isCreateDisabled,
}: Props) => {
  const preferences = usePreferences();
  const requireTaskType = R.pathOr(
    false,
    ['workOrders', 'requireTaskType'],
    preferences,
  );

  const [taskModalOpen, setTaskModalOpen] = useState<boolean>(false);
  const [taskTemplateModalOpen, setTaskTemplateModalOpen] = useState<boolean>(
    false,
  );

  const handleTaskClick = () => {
    setTaskModalOpen(true);
  };

  const handleTaskTemplateClick = () => {
    setTaskTemplateModalOpen(true);
  };

  return (
    <div styleName="create-controls-container" data-cy="addTaskButtonContainer">
      {loading && <Progress size={25} thickness={2} />}
      {requireTaskType ? (
        <IconButton
          onClick={handleTaskTemplateClick}
          disabled={isCreateDisabled}
        >
          <Icon>add</Icon>
        </IconButton>
      ) : (
        <Menu disabled={isCreateDisabled} icon={<Icon>add</Icon>}>
          <div styleName="menu-header">create task</div>
          <MenuItem
            startAdornment={
              <Icon>
                <img src={taskTypeIcon} />
              </Icon>
            }
            onClick={handleTaskTemplateClick}
            data-cy="addTaskCreateNewTask"
          >
            Choose from Templates
          </MenuItem>
          <MenuItem
            startAdornment={<Icon>add</Icon>}
            onClick={handleTaskClick}
            data-cy="addTaskChooseTaskTemplate"
          >
            New Task
          </MenuItem>
        </Menu>
      )}
      <TaskCreateModal
        open={taskModalOpen}
        onClose={() => setTaskModalOpen(false)}
        handleCreate={handleCreate}
      />
      <TaskCreateTemplateModal
        workOrderId={workOrderId}
        open={taskTemplateModalOpen}
        onClose={() => setTaskTemplateModalOpen(false)}
        handleConfirm={handleCreate}
      />
    </div>
  );
};

export default TaskCreateControls;
