import * as React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';

import * as inventoryCategoryActionCreators from '@atom/actions/inventoryCategoryActions';
import * as inventoryExportActionCreators from '@atom/actions/inventoryExportActions';
import * as inventorySchemaActionCreators from '@atom/actions/inventorySchemaActions';
import { Progress } from '@atom/mui';
import {
  InventoryCategoryActions,
  InventoryExportActions,
  InventorySchemaActions,
} from '@atom/types/actions';
import {
  InventoryFullCategoryTreeType,
  InventorySchemaDetail,
  InventorySchemaItem,
  InventorySchemaTreeType,
} from '@atom/types/inventory';
import { ExportTemplateDetailType } from '@atom/types/inventoryExport';
import { ReduxStore } from '@atom/types/store';
import history from '@atom/utilities/history';

import AttributePane from './AttributePane';
import CategoryPane from './CategoryPane';
import InventoryExportDetailHeader from './InventoryExportDetailHeader';
import SchemaPane from './SchemaPane';

import './inventoryExportDetail.css';

const styles = {
  progress: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    top: '60px',
    left: '0',
    width: '100%',
    height: '100%',
  },
};
interface PassedProps {
  match: any;
}

interface ReduxStateProps {
  loadingExport: boolean;
  loadingSchema: boolean;
  loadingFullCategoryTree: boolean;
  exportTemplateDetail: ExportTemplateDetailType;
  inventoryFullCategoryTree: InventoryFullCategoryTreeType;
  inventorySchemas: InventorySchemaItem[];
  inventorySchemaTree: InventorySchemaTreeType;
  inventorySchemaDetail: InventorySchemaDetail;
}

interface ReduxDispatchProps {
  inventoryExportActions: InventoryExportActions;
  inventoryCategoryActions: InventoryCategoryActions;
  inventorySchemaActions: InventorySchemaActions;
}

type Props = ReduxStateProps & ReduxDispatchProps & PassedProps;

interface State {
  selectedSchemaId: string;
  selectedSubItemId: string;
}

class InventoryExportDetail extends React.Component<Props, State> {
  state = {
    selectedSchemaId: '',
    selectedSubItemId: '',
  };

  componentDidMount() {
    const {
      inventoryExportActions,
      inventoryCategoryActions,
      match,
    } = this.props;

    inventoryExportActions.requestExportTemplateDetail({
      exportTemplateId: match.params.id,
    });

    inventoryCategoryActions.requestFullCategoryTree();

    const { inventorySchemaActions, inventorySchemas } = this.props;

    if (R.isNil(inventorySchemas) || R.isEmpty(inventorySchemas)) {
      inventorySchemaActions.retrieveInventorySchemas({
        rootSchemas: true,
      });
    }
  }

  navigateBack = (): void => {
    return history.push('/inventory/export');
  };

  updateSelectedSchema = (schemaId: string) => {
    const { inventorySchemaActions } = this.props;
    const { selectedSchemaId } = this.state;

    this.setState({
      selectedSchemaId: schemaId,
      selectedSubItemId: '',
    });

    if (
      !R.isEmpty(schemaId) &&
      !R.isNil(schemaId) &&
      schemaId !== selectedSchemaId
    ) {
      inventorySchemaActions.requestSchemaTree({ id: schemaId });
    }
  };

  updateSelectedSubItem = (subItemId: string) => {
    const { inventorySchemaActions } = this.props;
    const { selectedSubItemId } = this.state;
    this.setState({ selectedSubItemId: subItemId });

    if (subItemId !== selectedSubItemId) {
      inventorySchemaActions.retrieveSchemaDetail({ id: subItemId });
    }
  };

  render() {
    const { selectedSchemaId, selectedSubItemId } = this.state;
    const {
      exportTemplateDetail,
      inventoryFullCategoryTree,
      loadingExport,
      loadingSchema,
      loadingFullCategoryTree,
      inventorySchemas,
      inventorySchemaTree,
      inventorySchemaDetail,
    } = this.props;

    const loading = loadingExport || loadingSchema || loadingFullCategoryTree;

    return (
      <div styleName="portal-wrapper">
        <InventoryExportDetailHeader
          exportTemplateDetail={exportTemplateDetail}
          navigateBack={this.navigateBack}
        />
        {loading ? (
          <Progress style={styles.progress} />
        ) : (
          <div styleName="content-container">
            <CategoryPane
              categoryTree={inventoryFullCategoryTree}
              rootSchemas={inventorySchemas}
              exportTemplateDetail={exportTemplateDetail}
              updateSelectedSchema={this.updateSelectedSchema}
            />
            {!R.isEmpty(selectedSchemaId) &&
            !R.isNil(selectedSchemaId) &&
            selectedSchemaId === inventorySchemaTree.id ? (
              <SchemaPane
                inventorySchemaTree={inventorySchemaTree}
                exportTemplateDetail={exportTemplateDetail}
                selectedSubItemId={selectedSubItemId}
                updateSelectedSubItem={this.updateSelectedSubItem}
              />
            ) : (
              <div styleName="section-pane center" />
            )}
            {!R.isEmpty(selectedSubItemId) &&
            selectedSubItemId === inventorySchemaDetail.id ? (
              <AttributePane
                schema={inventorySchemaDetail}
                exportTemplateDetail={exportTemplateDetail}
              />
            ) : (
              <div styleName="section-pane right" />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  loadingExport: state.loading.loadingExportTemplateDetail,
  loadingSchema: state.loading.loadingInventorySchemas,
  loadingFullCategoryTree: state.loading.loadingFullCategoryTree,
  exportTemplateDetail: state.exportTemplateDetail,
  inventoryFullCategoryTree: state.inventoryFullCategoryTree,
  inventorySchemas: state.inventorySchemas,
  inventorySchemaDetail: state.inventorySchemaDetail,
  inventorySchemaTree: state.inventorySchemaTree,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  inventoryExportActions: bindActionCreators(
    inventoryExportActionCreators,
    dispatch,
  ),
  inventoryCategoryActions: bindActionCreators(
    inventoryCategoryActionCreators,
    dispatch,
  ),
  inventorySchemaActions: bindActionCreators(
    inventorySchemaActionCreators,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InventoryExportDetail);
