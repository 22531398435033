import * as React from 'react';
import { useSelector } from 'react-redux';

import { Icon, IconButton } from '@atom/mui';
import { knowledgeCenterLinkSelector } from '@atom/selectors/preferencesSelectors';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import CreateControls from './CreateControls';
import Portal from './Portal';
import Profile from './Profile';

import './navigation.css';

interface Props {
  pathname: string;
}

const NavControls = ({ pathname }: Props) => {
  const link = useSelector(knowledgeCenterLinkSelector);

  return (
    <div styleName="container">
      <div styleName="additional-buttons-wrapper">
        <div styleName="additional-buttons-container">
          <a target="_blank" href={link}>
            <IconButton size="small" tooltip="Knowledge Center">
              <Icon>help_outline</Icon>
            </IconButton>
          </a>
        </div>
      </div>
      {hasRolePermissions(ROLE_SETS.INSPECTOR) && <CreateControls />}
      <Portal pathname={pathname} />
      <Profile />
    </div>
  );
};

export default NavControls;
