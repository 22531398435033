import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';

import TextOverflowTooltip from '@atom/components/common/tooltip/TextOverflowTooltip';
import {
  getModalValue,
  ModalVariant,
} from '@atom/components/workTemplate/workTemplateHeader/WorkTemplateDeleteModalValues';
import WorkTemplatesContext from '@atom/components/workTemplates/WorkTemplatesContext';
import {
  GET_WORK_ORDER_TEMPLATES,
  WORK_TEMPLATE_DELETE,
} from '@atom/graph/workTemplate';
import {
  Button,
  Icon,
  ListTable,
  Menu,
  Modal,
  Progress,
  Snackbar,
} from '@atom/mui';
import colors from '@atom/styles/colors';
import { PolicyAction } from '@atom/types/policy';
import { WorkOrderType } from '@atom/types/work';
import {
  WorkOrderTemplatesConnectionInput,
  WorkTemplateItem,
  WorkTemplatesConnection,
} from '@atom/types/workTemplate';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import {
  getWorkTypeVariant,
  WorkTypeVariant,
} from '@atom/utilities/workTemplateUtilities';
import { getWorkTypeIconSvg } from '@atom/utilities/workTypeIconUtilities';

const {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} = ListTable;

const { MenuItem } = Menu;

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 25;

const HTTP_STATUS_CONFLICT = 409;

const styles = {
  progress: {
    height: '100%',
  },
  statusCell: {
    display: 'flex',
    alignItems: 'center',
  },
  statusName: {
    paddingLeft: '5px',
  },
  delete: {
    background: colors.brand.red,
    color: colors.neutral.white,
  },
};

const WorkTemplatesList = () => {
  const { activeFolderId } = useContext(WorkTemplatesContext);

  const [page, setPage] = useState<number>(INITIAL_PAGE);
  const [limit, setLimit] = useState<number>(INITIAL_LIMIT);
  const [deleteModalVariant, setDeleteModalVariant] = useState<ModalVariant>();
  const [workTemplateToDelete, setWorkTemplateToDelete] = useState<
    WorkTemplateItem
  >();

  const {
    data: workTemplatesData,
    loading: workTemplatesLoading,
    refetch,
  } = useQuery<
    { workOrderTemplates: WorkTemplatesConnection },
    { input: WorkOrderTemplatesConnectionInput }
  >(GET_WORK_ORDER_TEMPLATES, {
    variables: {
      input: {
        page,
        limit,
        action: PolicyAction.READ,
        folderId: activeFolderId,
        includeParents: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const [workTemplateDelete] = useMutation(WORK_TEMPLATE_DELETE);

  const workTemplates =
    workTemplatesData?.workOrderTemplates?.workOrderTemplates;
  const totalCount = workTemplatesData?.workOrderTemplates?.totalCount || 0;

  useEffect(() => {
    setPage(INITIAL_PAGE);
    setLimit(INITIAL_LIMIT);
  }, [activeFolderId]);

  const workTypeVariant = getWorkTypeVariant(
    workTemplateToDelete?.isParent,
    workTemplateToDelete?.parentId,
  );

  const getSnackbarText = (variant: WorkTypeVariant) => {
    const texts = {
      [WorkTypeVariant.REGULAR]: 'Work template deleted',
      [WorkTypeVariant.PARENT]: 'Parent work template deleted',
      [WorkTypeVariant.CHILD]: 'Child work template deleted',
    };

    return texts[variant];
  };

  const deleteWorkTemplate = async () => {
    try {
      await workTemplateDelete({
        variables: {
          id: workTemplateToDelete.id,
        },
      });

      setPage(1);
      setDeleteModalVariant(null);
      setWorkTemplateToDelete(null);

      Snackbar.info({
        message: getSnackbarText(workTypeVariant),
      });

      refetch({
        input: {
          page: 1,
          limit,
          action: PolicyAction.READ,
          folderId: activeFolderId,
          includeParents: true,
        },
      });
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_STATUS_CONFLICT) {
        setDeleteModalVariant(ModalVariant.DELETE_ERROR);
      } else {
        Snackbar.error({ message: 'An unknown error occurred' });
      }
    }
  };

  const getStatusCell = (published: boolean) => {
    return (
      <div style={styles.statusCell}>
        <Icon>{published ? 'lock' : 'edit'}</Icon>
        <div style={styles.statusName}>{published ? 'Published' : 'Draft'}</div>
      </div>
    );
  };

  const showDelete = hasRolePermissions(ROLE_SETS.ORG_ADMIN);

  const handleDeleteClick = (workTemplate: WorkTemplateItem) => {
    setDeleteModalVariant(ModalVariant.DELETE_CONFIRM);
    setWorkTemplateToDelete(workTemplate);
  };

  const deleteModalValue = getModalValue(workTypeVariant, deleteModalVariant);

  const getFooter = () => {
    return deleteModalVariant === ModalVariant.DELETE_ERROR ? (
      <Button
        color="primary"
        variant="contained"
        onClick={() => setDeleteModalVariant(null)}
      >
        {deleteModalValue?.button}
      </Button>
    ) : (
      <div>
        <Button
          onClick={() => setDeleteModalVariant(null)}
          style={{ marginRight: '0.5rem' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={deleteWorkTemplate}
          style={styles.delete}
        >
          {deleteModalValue?.button}
        </Button>
      </div>
    );
  };

  return (
    <>
      {workTemplatesLoading ? (
        <Progress style={styles.progress} />
      ) : (
        <>
          <ListTable offsetTop="160px">
            <TableHead>
              <TableRow header>
                <TableCell variant="head" />
                <TableCell variant="head">Name</TableCell>
                <TableCell variant="head">Status</TableCell>
                <TableCell variant="head">Asset Type</TableCell>
                <TableCell variant="head">Created By</TableCell>
                <TableCell variant="head">Created On</TableCell>
                <TableCell variant="head" />
              </TableRow>
            </TableHead>
            <TableBody>
              {workTemplates.map(workTemplate => {
                const assetType =
                  R.pathOr(WorkOrderType.DEFAULT, ['type'], workTemplate) ===
                  WorkOrderType.DEFAULT
                    ? workTemplate.schemaName || 'All'
                    : 'N/A';

                return (
                  <TableRow key={workTemplate.id}>
                    <TableCell width="2rem" align="right">
                      <Icon>
                        <img
                          src={getWorkTypeIconSvg(
                            workTemplate?.isParent,
                            workTemplate?.parentId,
                          )}
                        />
                      </Icon>
                    </TableCell>
                    <TableCell>
                      <Link to={`/work-templates/${workTemplate.id}`}>
                        <TextOverflowTooltip
                          text={workTemplate.name}
                          width="22.5rem"
                          bold
                          lightTooltip
                        />
                      </Link>
                    </TableCell>
                    <TableCell>
                      {getStatusCell(workTemplate.published)}
                    </TableCell>
                    <TableCell>{assetType}</TableCell>
                    <TableCell>
                      {workTemplate.createdBy.firstName}{' '}
                      {workTemplate.createdBy.lastName}
                    </TableCell>
                    <TableCell>
                      {setDisplayDate(workTemplate.createdDate)}
                    </TableCell>
                    <TableCell width="3rem">
                      {showDelete && (
                        <Menu>
                          <MenuItem
                            startAdornment={<Icon>delete</Icon>}
                            onClick={() => handleDeleteClick(workTemplate)}
                            disabled={!showDelete}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  count={totalCount}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={value => setPage(value)}
                  onRowsPerPageChange={event => {
                    setPage(1);
                    setLimit(+event.target.value);
                  }}
                />
              </TableRow>
            </TableFooter>
          </ListTable>
          <Modal
            title={deleteModalValue?.title}
            open={!isNilOrEmpty(deleteModalVariant)}
            onCancel={() => setDeleteModalVariant(null)}
            footer={getFooter()}
          >
            {deleteModalValue?.content}
          </Modal>
        </>
      )}
    </>
  );
};

export default WorkTemplatesList;
