import React from 'react';

import { Checkbox, Select } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { AttributesType } from '@atom/types/inventory';

import './inventoryExportDetail.css';

const { MenuItem } = Select;

const styles = {
  underlineStyle: {
    borderColor: colors.neutral.silver,
  },
  floatingLabelStyle: {
    fontSize: fonts.md,
    color: colors.neutral.dark,
  },
  textFieldStyle: {
    fontSize: fonts.md,
  },
  selectFieldStyle: {
    input: {
      fontSize: fonts.md,
      color: colors.neutral.black,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    selected: {
      fontSize: fonts.md,
      color: colors.brand.blue,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    iconStyle: {
      fill: colors.neutral.gray,
    },
  },
};

interface Props {
  attribute: AttributesType;
  filters: string[];
  onAttributeToggle: (id: string) => void;
  onAttributeFiltersChange: (id: string, filters: string[]) => void;
  isChecked: boolean;
  isDisabled: boolean;
}

const AttributeRow = ({
  attribute,
  filters,
  onAttributeToggle,
  onAttributeFiltersChange,
  isChecked,
  isDisabled,
}: Props) => {
  const handleChange = (event: any) => {
    const { value } = event.target;
    onAttributeFiltersChange(attribute.id, value);
  };

  const attributeContent =
    attribute.dataType === 'enumsingle' ||
    attribute.dataType === 'enummultiple' ? (
      <Select
        fullWidth
        multiple
        label={attribute.name}
        style={styles.textFieldStyle}
        onChange={handleChange}
        value={filters}
        disabled={!isChecked}
        renderValue={(selected: string[]) =>
          attribute.enumeration
            .filter(item => selected.indexOf(item) !== -1)
            .join(', ')
        }
      >
        {attribute.enumeration.map((option: string, index: number) => {
          return (
            <MenuItem key={index} value={option}>
              <Checkbox checked={filters && filters.indexOf(option) > -1} />
              {option}
            </MenuItem>
          );
        })}
      </Select>
    ) : (
      <div>{attribute.name}</div>
    );

  return (
    <div styleName="attribute-row">
      <Checkbox
        checked={isChecked}
        onChange={() => onAttributeToggle(attribute.id)}
        disabled={isDisabled}
      />
      {attributeContent}
    </div>
  );
};

export default AttributeRow;
