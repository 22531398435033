import React, { useContext, useState } from 'react';
import * as R from 'ramda';

import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { Icon, IconButton } from '@atom/mui';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';

import AddSchemaModal from './addSchemaModal/AddSchemaModal';
import SchemaTile from './SchemaTile';

import './schemaSection.css';

const SchemaSection = () => {
  const { workTemplate, task } = useContext(WorkTemplateContext);

  const [expanded, setExpanded] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  const isSectionEmpty = R.isEmpty(task.schemas);
  const totalCount = R.length(task.schemas) || 0;
  const icon = expanded ? 'expand_less' : 'expand_more';
  const isDisabled =
    workTemplate?.published || doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  return (
    <>
      <div styleName="section-container">
        <Icon>category</Icon>
        <div styleName="section-title">Asset Type</div>
        <div styleName="total-count">{totalCount}</div>
        <div styleName="buttons-container">
          {expanded && (
            <IconButton
              onClick={() => setOpen(true)}
              edge="end"
              tooltip="Add Asset"
              disabled={isDisabled}
            >
              <Icon>add</Icon>
            </IconButton>
          )}
          <IconButton onClick={() => setExpanded(!expanded)} edge="end">
            <Icon>{icon}</Icon>
          </IconButton>
        </div>
      </div>
      {expanded && (
        <div styleName="tile-list">
          {isSectionEmpty ? (
            <div styleName="empty-section-text">Add Asset Type</div>
          ) : (
            <>
              {task.schemas.map(schema => (
                <SchemaTile key={schema.id} schema={schema} />
              ))}
            </>
          )}
        </div>
      )}
      <AddSchemaModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default SchemaSection;
