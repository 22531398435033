import React, { useContext, useState } from 'react';

import AttributeGroupHeader from '@atom/components/common/attributeDetail/AttributeGroupHeader';
import AttributesList from '@atom/components/common/attributeDetail/AttributesList';
import EditAttributeList from '@atom/components/common/attributeDetail/EditAttributeList';
import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { SchemaTree } from '@atom/types/schema';

import { hydrateAttributesWithPendingChanges } from '../schemaPreviewUtilities';

import './previewSubItems.css';

export const mapAttributeValue = (value: any, dataType: string): any => {
  switch (dataType) {
    case 'currency':
    case 'number': {
      return Number(value);
    }
    case 'date': {
      return value.valueOf();
    }
    case 'boolean': {
      return value === 'true';
    }
    default:
      return value;
  }
};

interface Props {
  schema: Partial<SchemaTree>;
}

const PreviewAttributeDetail = ({ schema }: Props) => {
  const { pendingUpdates, pendingCreations } = useContext(SchemaDetailContext);

  const [attributeGroups, setAttributeGroups] = useState<any>(
    schema?.attributeGroups || [],
  );

  const updateAttributeGroupCollapsedState = (
    attributeGroupName: string,
  ): any => {
    const updatedAttributes = attributeGroups.map(
      (attributeGroup: any): any => {
        if (attributeGroup.name !== attributeGroupName) {
          return attributeGroup;
        }

        return {
          ...attributeGroup,
          collapsed: !attributeGroup.collapsed,
        };
      },
    );

    setAttributeGroups(updatedAttributes);
  };

  const updateAttributeGroupEditState = (
    attributeGroupName: string,
    isEdit: boolean,
  ): any => {
    const updatedAttributes = attributeGroups.map(
      (attributeGroup: any): any => {
        if (attributeGroup.name !== attributeGroupName) {
          return attributeGroup;
        }

        return {
          ...attributeGroup,
          isEdit,
        };
      },
    );

    setAttributeGroups(updatedAttributes);
  };

  const onCollapse = (attributeGroupName: string) => {
    updateAttributeGroupCollapsedState(attributeGroupName);
  };

  const onEditClick = (attributeGroupName: string) => {
    updateAttributeGroupEditState(attributeGroupName, true);
  };

  const onCancel = (attributeGroupName: string) => {
    updateAttributeGroupEditState(attributeGroupName, false);
  };

  return (
    <>
      {schema?.attributeGroups?.map((attributeGroup: any, key: any) => {
        const collapsed = attributeGroups[key]
          ? attributeGroups[key].collapsed
          : false;

        const isEdit = attributeGroups[key]
          ? attributeGroups[key].isEdit
          : false;

        const hydratedAttributes = hydrateAttributesWithPendingChanges(
          schema.id,
          attributeGroup.id,
          attributeGroup.attributes,
          pendingUpdates,
          pendingCreations,
        );

        return (
          <div key={key}>
            <AttributeGroupHeader
              name={attributeGroup.name}
              onClick={onEditClick}
              onCollapse={onCollapse}
              collapsed={collapsed}
              isEdit={isEdit}
              onCancel={onCancel}
              onSave={onCancel}
              loading={false}
              canUpdate
              typePreview
            />
            {isEdit ? (
              <div styleName="list-container">
                <EditAttributeList
                  attributes={hydratedAttributes}
                  schemaPreview
                />
              </div>
            ) : collapsed ? (
              <div />
            ) : (
              <div styleName="list-container">
                <AttributesList attributes={hydratedAttributes} schemaPreview />
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default PreviewAttributeDetail;
