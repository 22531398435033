import React, { useEffect, useState } from 'react';

import { Modal, TextField } from '@atom/mui';

interface Props {
  name: string;
  open: boolean;
  loading: boolean;
  onCancel: () => void;
  renameInventory: (name: string) => void;
}

const RenameInventoryModal = ({
  name,
  open,
  loading,
  onCancel,
  renameInventory,
}: Props) => {
  const [assetName, setAssetName] = useState<string>(name || '');

  useEffect(() => {
    setAssetName(name);
  }, [name]);

  const onSave = () => {
    renameInventory(assetName);
    onCancel();
  };

  const handleCancel = () => {
    setAssetName(name || '');
    onCancel();
  };

  return (
    <Modal
      title="Rename Inventory"
      open={open}
      loading={loading}
      onCancel={handleCancel}
      onConfirm={onSave}
      confirmButtonText="Save"
    >
      <TextField
        label="Name"
        value={assetName}
        onChange={event => setAssetName(event.target.value)}
      />
    </Modal>
  );
};

export default RenameInventoryModal;
