import React from 'react';

import { Icon } from '@atom/mui';

import './analyticsListEmpty.css';

interface Props {
  message?: string;
  icon?: string;
}

const AnalyticsListEmpty = ({
  message = 'This folder is empty',
  icon = 'folder',
}: Props) => (
  <div styleName="empty-list-container">
    <Icon style={{ fontSize: '3.5rem' }} styleName="empty-list-icon">
      {icon}
    </Icon>
    <span styleName="empty-list-text">{message}</span>
  </div>
);

export default AnalyticsListEmpty;
