import React, { useContext } from 'react';

import WorkOrdersContext, {
  WorkOrdersInputActionTypes,
} from '@atom/components/workOrders/WorkOrdersContext';
import { Icon, Switch } from '@atom/mui';

import './workOrdersFilters.css';

const styles = {
  icon: {
    marginRight: '1rem',
  },
};

const WorkOrdersFiltersFormSection = () => {
  const { workOrdersInputCart, filtersDisabled, dispatch } = useContext(
    WorkOrdersContext,
  );

  const handleChange = (event: any) => {
    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_INPUT_PROPERTY,
      data: {
        property: 'hasForms',
        value: !workOrdersInputCart?.hasForms,
      },
    });
  };

  return (
    <div styleName="filters-section">
      <div styleName="filters-section-header toggle">
        <div>
          <Icon style={styles.icon}>library_books</Icon>
          Forms
        </div>
        <Switch
          style={{ marginRight: 0 }}
          checked={workOrdersInputCart?.hasForms}
          disabled={filtersDisabled}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default WorkOrdersFiltersFormSection;
