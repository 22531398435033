import React from 'react';

import { Button, Checkbox, Select } from '@atom/mui';
import colors from '@atom/styles/colors';
import { workOrderStatusTypes } from '@atom/utilities/workOrderStatusUtilities';

import '../../../styles/left-pane.css';

const { MenuItem } = Select;

const filterStyle = {
  underlineStyle: {
    borderColor: colors.neutral.silver,
  },
  floatingLabelStyle: {
    color: colors.neutral.gray,
  },
  selectFieldStyle: {
    input: {
      color: colors.neutral.black,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    selected: {
      color: colors.brand.blue,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    iconStyle: {
      fill: colors.neutral.dim,
    },
  },
  applyButtonStyle: {
    marginTop: '0.5rem',
  },
  resetButtonStyle: {
    marginTop: '0.5rem',
    marginLeft: '2rem',
  },
};

interface Filters {
  statusIds: string[];
}

interface Props {
  filters: Filters;
  setFilters: (filters: Filters) => void;
  getWorkOrders: (filters: Filters) => void;
}

const WorkFilters = ({ filters, setFilters, getWorkOrders }: Props) => {
  const reset = () => {
    setFilters({ statusIds: [] });
    getWorkOrders({ statusIds: [] });
  };

  return (
    <>
      <div styleName="filters-header">
        <div styleName="filters-header-label">Filter</div>
        <Button style={filterStyle.resetButtonStyle} onClick={reset}>
          Reset
        </Button>
        <Button
          color="primary"
          style={filterStyle.applyButtonStyle}
          onClick={() => getWorkOrders(filters)}
        >
          Apply
        </Button>
      </div>
      <div styleName="select-filter-wrapper">
        <Select
          multiple
          fullWidth
          label="Work Status"
          onChange={(event: any) => {
            const { value } = event.target;
            setFilters({ statusIds: value });
          }}
          renderValue={(selected: string[]) =>
            workOrderStatusTypes
              .filter(item => selected.indexOf(item.value) !== -1)
              .map(item => item.label)
              .join(', ')
          }
          value={filters.statusIds}
        >
          {workOrderStatusTypes.map(status => {
            return (
              <MenuItem key={status.value} value={status.value}>
                <Checkbox checked={filters.statusIds.includes(status.value)} />
                {status.label}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    </>
  );
};

export default WorkFilters;
