import React, { useEffect, useMemo } from 'react';
import { match as Match } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';

import { GET_MATERIAL_ENTRIES } from '@atom/graph/materialEntry';
import { GET_TASK_LOCATIONS } from '@atom/graph/taskLocation';
import { GET_TIME_ENTRIES } from '@atom/graph/timeEntry';
import { GET_WORK_ORDER } from '@atom/graph/work';
import {
  MaterialEntriesConnection,
  MaterialEntriesConnectionInput,
} from '@atom/types/materialEntry';
import {
  TaskLocationsConnection,
  TaskLocationsConnectionInput,
} from '@atom/types/taskLocation';
import {
  TimeEntriesConnectionInput,
  WorkOrderTimeEntriesConnection,
} from '@atom/types/timeEntry';
import { WorkOrderDetailType, WorkOrderType } from '@atom/types/work';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import { isWorkOrderCompletedOrAbove } from '@atom/utilities/workOrderStatusUtilities';

import {
  EMPTY_TASK,
  getLocationsDictionary,
  getReportAssetsDictionary,
  getReportMaterialEntriesDictionary,
  getReportUsersDictionary,
} from './reportUtilities';
import TaskDetailReport from './TaskDetailReport';

import './workOrderReport.css';

interface Props {
  match: Match<{ workOrderId: string }>;
}

const WorkOrderPdfReport = ({ match }: Props) => {
  const {
    data: workOrderData,
    loading: workOrderLoading,
    error: workOrderError,
  } = useQuery<{ workOrder: WorkOrderDetailType }, { id: string }>(
    GET_WORK_ORDER,
    {
      variables: {
        id: match.params.workOrderId,
      },
      fetchPolicy: 'network-only',
    },
  );

  const {
    data: timeEntriesData,
    loading: timeEntriesLoading,
    error: timeEntriesError,
  } = useQuery<
    { timeEntries: WorkOrderTimeEntriesConnection },
    { input: TimeEntriesConnectionInput }
  >(GET_TIME_ENTRIES, {
    variables: {
      input: {
        workOrderId: match.params.workOrderId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: materialEntriesData,
    loading: materialEntriesLoading,
    error: materialEntriesError,
  } = useQuery<
    { materialEntries: MaterialEntriesConnection },
    { input: MaterialEntriesConnectionInput }
  >(GET_MATERIAL_ENTRIES, {
    variables: {
      input: {
        workOrderId: match.params.workOrderId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const [
    getTaskLocations,
    { data: locationsData, loading: locationsLoading },
  ] = useLazyQuery<
    { taskLocations: TaskLocationsConnection },
    { input: TaskLocationsConnectionInput }
  >(GET_TASK_LOCATIONS, {
    fetchPolicy: 'no-cache',
  });

  const workOrder = workOrderData?.workOrder;
  const hasNoTasks = isNilOrEmpty(workOrderData?.workOrder?.tasks);
  const timeEntries = timeEntriesData?.timeEntries?.timeEntries;
  const materialEntries = materialEntriesData?.materialEntries?.materialEntries;
  const isCompletedOrAbove = isWorkOrderCompletedOrAbove(workOrder?.statusId);

  useEffect(() => {
    if (workOrder?.type === WorkOrderType.TASK_ASSETS_AND_LOCATIONS) {
      const ids = workOrder?.tasks?.reduce((acc, task) => {
        return [...acc, ...task?.locations.map(location => location.id)];
      }, []);

      getTaskLocations({ variables: { input: { ids } } });
    }
  }, [workOrder]);

  const locations = locationsData?.taskLocations?.taskLocations;

  const reportLocationsDictionary = useMemo(() => {
    return getLocationsDictionary(locations, workOrder);
  }, [locations, workOrder]);

  const reportUsersDictionary = useMemo(() => {
    return getReportUsersDictionary(timeEntries, workOrder);
  }, [timeEntries, workOrder]);

  const reportMaterialEntriesDictionary = useMemo(() => {
    return getReportMaterialEntriesDictionary(materialEntries, workOrder);
  }, [materialEntries, workOrder]);

  const reportAssetsDictionary = useMemo(() => {
    return getReportAssetsDictionary(workOrder);
  }, [workOrder]);

  const showLoaded =
    !workOrderLoading &&
    !workOrderError &&
    !timeEntriesLoading &&
    !timeEntriesError &&
    !materialEntriesLoading &&
    !materialEntriesError &&
    !locationsLoading;

  return (
    <div styleName="container">
      {hasNoTasks ? (
        <TaskDetailReport
          key={EMPTY_TASK.id}
          // @ts-ignore
          task={EMPTY_TASK}
          workType={workOrder?.type}
          isCompletedOrAbove={false}
          reportUsers={[]}
          reportMaterials={{ startEndMaterials: [], usageMaterials: [] }}
          reportLocations={[]}
        />
      ) : (
        workOrder?.tasks?.map(task => (
          <TaskDetailReport
            key={task?.id}
            task={task}
            workType={workOrder?.type}
            isCompletedOrAbove={isCompletedOrAbove}
            reportUsers={reportUsersDictionary[task?.id]}
            reportMaterials={reportMaterialEntriesDictionary[task?.id]}
            reportAssets={reportAssetsDictionary[task?.id]}
            reportLocations={reportLocationsDictionary[task?.id]}
          />
        ))
      )}
      {showLoaded && <div id="loaded" />}
    </div>
  );
};

export default WorkOrderPdfReport;
