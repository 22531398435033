import React from 'react';
import * as R from 'ramda';
import uuid from 'uuid/v4';

import { Button, Icon, IconButton, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { SubField } from '@atom/types/work';
import {
  initialNestedField,
  mapDropdownField,
  removeSubFieldByMatchValue,
} from '@atom/utilities/workOrderFieldUtilities';

import './customFieldEnumEdit.css';

const MAX_NESTING = 11;
const MIN_ENUMERATION = 2;

const styles = {
  button: {
    padding: '0',
    marginLeft: '5px',
  },
  addOptionButton: {
    marginTop: '0.5rem',
  },
  deleteButton: {
    padding: 0,
    marginRight: '1.75rem',
  },
};

interface ValueDictionary {
  [index: string]: string;
}

interface Props {
  subField: SubField;
  updateSubField: (subField: SubField) => void;
  valueDictionary: ValueDictionary;
  setValueDictionary: (valueDictionary: ValueDictionary) => void;
  removeSubField: any;
  setActiveKey: (activeKey: string) => void;
  nestedDepth: number;
}

const CustomFieldEnumNestedEdit = ({
  subField,
  updateSubField,
  valueDictionary,
  setValueDictionary,
  removeSubField,
  setActiveKey,
  nestedDepth,
}: Props) => {
  const { enumeration, defaultValue, subFields, title } = subField?.nestedField;

  const toggleDefaultValue = (newDefaultValue: string) => {
    const updatedSubField = {
      ...subField,
      nestedField: {
        ...subField.nestedField,
        defaultValue: defaultValue !== newDefaultValue ? newDefaultValue : null,
      },
    };

    updateSubField(updatedSubField);
  };

  const addOption = () => {
    const id = uuid();

    const updatedSubField = {
      ...subField,
      nestedField: {
        ...subField.nestedField,
        enumeration: [...enumeration, id],
      },
    };

    updateSubField(updatedSubField);
    setValueDictionary({
      ...valueDictionary,
      [id]: '',
    });
  };

  const updateOption = (event, key: string) => {
    setValueDictionary({
      ...valueDictionary,
      [key]: event.target.value,
    });
  };

  const removeOption = (key: string) => {
    const updatedSubField = {
      ...subField,
      nestedField: {
        ...subField.nestedField,
        enumeration: R.remove(enumeration.indexOf(key), 1, enumeration),
        defaultValue: defaultValue === key ? null : key,
        subFields: removeSubFieldByMatchValue(subFields, key),
      },
    };

    updateSubField(updatedSubField);
    setValueDictionary(R.omit([key], valueDictionary));
    setActiveKey(null);
  };

  const addSubField = (key: string) => {
    const mappedField = mapDropdownField(initialNestedField);
    setValueDictionary({ ...valueDictionary, ...mappedField.valueDictionary });

    const updatedSubField = {
      ...subField,
      nestedField: {
        ...subField.nestedField,
        subFields: [
          ...subField.nestedField.subFields,
          {
            matchValue: key,
            nestedField: R.omit(['valueDictionary'], mappedField),
          },
        ],
      },
    };

    updateSubField(updatedSubField);
    setActiveKey(key);
  };

  const updateTitle = event => {
    updateSubField({
      ...subField,
      nestedField: {
        ...subField.nestedField,
        title: event.target.value,
      },
    });
  };

  return (
    <>
      <div styleName="option-input-container">
        <TextField
          id="title"
          label="Title"
          value={title}
          onChange={event => updateTitle(event)}
          required
        />
        <IconButton
          style={styles.deleteButton}
          onClick={(): void => removeSubField(subField.matchValue)}
        >
          <Icon>delete</Icon>
        </IconButton>
      </div>
      {enumeration.map((key: string, index: number) => {
        const isDefaultValue = key === defaultValue;
        const nextSubField = R.find(R.propEq('matchValue', key))(subFields);

        const defaultValueIcon = isDefaultValue
          ? 'check_circle'
          : 'check_circle_outline';

        const defaultValueIconColor = isDefaultValue
          ? colors.neutral.gray
          : colors.neutral.ash;

        const defaultValueTooltip = isDefaultValue ? 'Deselect' : 'Preselect';

        return (
          <div key={key}>
            <div styleName="option-input-container">
              <div styleName="option-number">{`${index + 1}.`}</div>
              <TextField
                id={key}
                value={valueDictionary[key]}
                key={key}
                onChange={event => updateOption(event, key)}
                style={{ width: '100%' }}
              />
              <IconButton
                style={styles.button}
                onClick={() => toggleDefaultValue(key)}
                tooltip={defaultValueTooltip}
              >
                <Icon color={defaultValueIconColor}>{defaultValueIcon}</Icon>
              </IconButton>
              <IconButton
                style={styles.button}
                onClick={(): void => removeOption(key)}
                disabled={enumeration.length <= MIN_ENUMERATION}
              >
                <Icon>close</Icon>
              </IconButton>
              {!nextSubField && nestedDepth < MAX_NESTING && (
                <IconButton
                  style={styles.button}
                  onClick={(): void => addSubField(key)}
                  tooltip="Link Question"
                >
                  <Icon>link</Icon>
                </IconButton>
              )}
              {nextSubField && nestedDepth < MAX_NESTING && (
                <IconButton
                  style={styles.button}
                  onClick={() => setActiveKey(key)}
                >
                  <Icon>arrow_forward</Icon>
                </IconButton>
              )}
            </div>
          </div>
        );
      })}
      <div>
        <Button
          color="primary"
          startIcon={<Icon color={colors.brand.blue}>add</Icon>}
          onClick={addOption}
          style={styles.addOptionButton}
        >
          Add Option
        </Button>
      </div>
    </>
  );
};

export default CustomFieldEnumNestedEdit;
