import * as React from 'react';

import { FormFieldType } from '@atom/types/form';

import BasicInput from './BasicInput';
import IFrameInput from './IFrameInput';
import MultipleOptionInput from './MultipleOptionInput';
import NumericInput from './NumericInput';
import TitleAndDescriptionInput from './TitleAndDescriptionInput';

import '../formBuilder.css';

type Props = {
  field: FormFieldType;
  text: string;
  title: string;
  description: string;
  unit: string;
  enumValue: string[];
  url?: string;
  width?: number;
  onChange: (event: Object) => void;
  updateOption: (event: Object) => void;
  removeOption: (index: number) => void;
  addOption: () => void;
};

const FieldInputs = ({
  field,
  text,
  title,
  description,
  unit,
  enumValue,
  url,
  width,
  onChange,
  updateOption,
  removeOption,
  addOption,
}: Props) => {
  switch (field.type) {
    case 'enumsingle':
    case 'enummultiple':
    case 'radio': {
      return (
        <MultipleOptionInput
          title={title}
          description={description}
          enumValue={enumValue}
          onChange={onChange}
          updateOption={updateOption}
          addOption={addOption}
          removeOption={removeOption}
        />
      );
    }
    case 'shorttext':
    case 'longtext':
    case 'date':
    case 'currency': {
      return (
        <BasicInput
          title={title}
          description={description}
          onChange={onChange}
        />
      );
    }
    case 'number': {
      return (
        <NumericInput
          unit={unit}
          title={title}
          description={description}
          onChange={onChange}
        />
      );
    }
    case 'iframe': {
      return (
        <IFrameInput
          title={title}
          url={url}
          width={width}
          onChange={onChange}
        />
      );
    }
    default: {
      return (
        <TitleAndDescriptionInput
          text={text}
          onChange={onChange}
          field={field}
        />
      );
    }
  }
};

export default FieldInputs;
