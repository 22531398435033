import { useEffect, useState } from 'react';
import axios from 'axios';

import api from '@atom/utilities/api';
import { WORK_ORDERS_ENDPOINT } from '@atom/utilities/endpoints';

export const useWorkOrdersData = ({
  userIds = null,
  assetId = '',
  statusIds = '1,2,3,4',
  sortBy = 'dueDate',
}): [any, boolean] => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const source = axios.CancelToken.source();

    const getMapData = async () => {
      try {
        setLoading(true);
        const { data: response } = await api.get(
          WORK_ORDERS_ENDPOINT,
          {
            statusIds,
            sortBy,
            page: '1',
            limit: '250',
            isAscending: 'true',
            ...(userIds ? { userIds } : {}),
            ...(assetId && { assetId }),
          },
          source.token,
        );
        setData(response);
        setLoading(false);
      } catch (error) {
        if (!axios.isCancel(error)) {
          throw error;
        }
      }
    };

    if ((userIds || assetId) && !assetId.includes(',')) {
      getMapData();
    }

    return () => {
      source.cancel();
    };
  }, [userIds, assetId]);

  return [data, loading];
};
