import React from 'react';

import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';

import './scdotPdfReport.css';

interface Props {
  row: any[];
}

const DoubleRow = ({ row }: Props) => {
  const left = row[0];
  const right = row[1];

  return (
    <tr>
      {left && (
        <>
          <td>{`${left?.name}:`}</td>
          <td styleName="left-value">
            {left?.value
              ? attributeDisplayUtility.display(
                  left?.value,
                  left?.dataType,
                  left?.unit,
                  !!left?.changeId,
                  left?.newValue,
                )
              : '-'}
          </td>
        </>
      )}
      {right && (
        <>
          <td>{`${right?.name}:`}</td>
          <td>
            {right?.value
              ? attributeDisplayUtility.display(
                  right?.value,
                  right?.dataType,
                  right?.unit,
                  !!right?.changeId,
                  right?.newValue,
                )
              : '-'}
          </td>
        </>
      )}
    </tr>
  );
};

export default DoubleRow;
