import React, { useContext } from 'react';

import WorkOrderContext from '@atom/components/workOrderDetail/WorkOrderContext';
import { setDurationDisplay } from '@atom/utilities/timeUtilities';

import './taskDetailView.css';

const TaskDuration = () => {
  const { task } = useContext(WorkOrderContext);

  return (
    <div styleName="time-row-item duration">
      <div styleName="label-container">
        <div styleName="label">Duration</div>
      </div>
      <div styleName="date-display">{setDurationDisplay(task.duration)}</div>
    </div>
  );
};

export default TaskDuration;
