import * as React from 'react';

import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';

import './drawerContent.css';

const styles = {
  icon: {
    fontSize: '5.5rem',
    width: '5.5rem',
    height: '5.5rem',
    marginTop: '3.5rem',
    marginBottom: '2.25rem',
  },
};

interface Props {
  icon: string;
  text: string[];
}

const NoItemsPane = ({ icon, text }: Props) => {
  return (
    <div styleName="no-items-container">
      <Icon style={styles.icon} color={colors.neutral.silver}>
        {icon}
      </Icon>
      {text.map((row, index) => (
        <p styleName="no-items-text" key={index}>
          {row}
        </p>
      ))}
    </div>
  );
};

export default NoItemsPane;
