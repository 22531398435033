import React from 'react';
import * as R from 'ramda';

import { useWorkValidations } from '@atom/hooks/useWorkValidations';
import { Icon, List } from '@atom/mui';
import { pageContainsModule } from '@atom/selectors/formModuleSelectors';
import colors from '@atom/styles/colors';
import fontStyles from '@atom/styles/fonts';
import { FormModuleKeys } from '@atom/types/form';
import { getTaskIdByFormInstanceId } from '@atom/utilities/workValidationUtilities';

import './formInstance.css';

const { ListItemButton } = List;

const styles = {
  listStyle: {
    padding: '0',
  },
  rootListItemStyle: {
    lineHeight: fontStyles.md,
    fontSize: fontStyles.md,
    paddingTop: '0.50rem',
    paddingRight: '1.0rem',
    paddingBottom: '0.50rem',
    paddingLeft: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    height: '1rem',
  },
  listItemStyles: {
    boxSizing: 'border-box',
    borderLeft: '3px solid transparent',
    height: '2.5rem',
  },
  selectedListItemStyles: {
    boxSizing: 'border-box',
    backgroundColor: colors.utility.highlight,
    borderLeft: `3px solid ${colors.brand.blue}`,
    height: '2.5rem',
  },
};

interface PassedProps {
  form: any;
  preview: boolean;
  updateSelectedPage: (index: number) => void;
  selectedPage: number;
}

type Props = PassedProps;

const FormInstancePages = ({
  form,
  preview,
  updateSelectedPage,
  selectedPage,
}: Props) => {
  const pages = R.pathOr([], ['pages'], form);

  const { workValidations } = useWorkValidations();
  const errorTaskId = getTaskIdByFormInstanceId(form.id, workValidations);

  const getAssetAttributeError = (pageNumber: number) => {
    const formErrors = R.pathOr(
      [],
      ['taskValidations', errorTaskId, 'invalidForms', form.id],
      workValidations,
    );

    const pagesWithErrors = formErrors.reduce((acc, formError) => {
      return acc.add(formError.page);
    }, new Set([]));

    return pagesWithErrors.has(pageNumber);
  };

  const pciIndex = pages.findIndex(page =>
    pageContainsModule(FormModuleKeys.PCI, page),
  );

  return (
    <div styleName="form-pages-container">
      <List style={styles.listStyle}>
        {pages.reduce((acc, page: any, index: number) => {
          const rootStyles =
            selectedPage === index
              ? styles.selectedListItemStyles
              : styles.listItemStyles;

          const isPciPage =
            pageContainsModule(FormModuleKeys.PCI, page) ||
            pageContainsModule(FormModuleKeys.PCI_SUMMARY, page);
          // in preview mode only the first PCI page will be rendered
          // as a preview placeholder
          const hasPciPagePlaceholder =
            preview && isPciPage && index > pciIndex;

          const hasAssetAttributeError = getAssetAttributeError(index);
          const nameStyle = hasAssetAttributeError
            ? 'page-name error'
            : 'page-name';

          const listContent = (
            <div styleName="page-name-container">
              <div styleName={nameStyle}>{page.name}</div>
              {hasAssetAttributeError && (
                <Icon color={colors.brand.red}>error_outline</Icon>
              )}
            </div>
          );

          const Page = (
            <ListItemButton
              key={page.id}
              id={String(index)}
              onClick={(): void => updateSelectedPage(index)}
              style={{
                ...rootStyles,
                ...(isPciPage && {
                  paddingLeft: '1rem',
                }),
              }}
              innerDivStyle={styles.rootListItemStyle}
            >
              {listContent}
            </ListItemButton>
          );

          if (index === pciIndex) {
            const PageGroup = (
              <ListItemButton
                key={preview ? page.id : FormModuleKeys.PCI}
                id={preview ? String(index) : FormModuleKeys.PCI}
                onClick={(): void => updateSelectedPage(index)}
                style={preview ? rootStyles : { fontSize: fontStyles.md }}
                innerDivStyle={preview ? styles.rootListItemStyle : {}}
                disabled={!preview}
              >
                <div styleName="page-name-container">
                  Pavement Condition Inspection
                </div>
              </ListItemButton>
            );

            return preview ? [...acc, PageGroup] : [...acc, PageGroup, Page];
          }

          return hasPciPagePlaceholder ? acc : [...acc, Page];
        }, [])}
      </List>
    </div>
  );
};

export default FormInstancePages;
