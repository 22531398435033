import React, { useContext, useMemo, useState } from 'react';
import * as R from 'ramda';

// @ts-ignore
import dataExportOutlinedIcon from '@atom/components/common/svgIcons/dataExportOutlined.svg';
import { Icon, Menu } from '@atom/mui';
import colors from '@atom/styles/colors';
import { FILES_WORK_CSV_DOWNLOAD_ENDPOINT } from '@atom/utilities/endpoints';
import { buildEndpointWithParamsFromData } from '@atom/utilities/requestUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import ExportWorkModal from '../workExports/ExportWorkModal';

import WorkOrdersContext from './WorkOrdersContext';

const { MenuItem } = Menu;

const styles = {
  downloadIcon: {
    marginTop: 2,
  },
  icon: {
    marginRight: '1rem',
  },
};

const DOWNLOAD_THRESHOLD = 50000;

const WorkOrdersExport = () => {
  const [open, setOpen] = useState<boolean>(false);

  const { totalCount, input } = useContext(WorkOrdersContext);

  const getDownloadEndpoint = (): string => {
    if (totalCount > DOWNLOAD_THRESHOLD) {
      return '';
    }

    const {
      priorityIds,
      statusIds,
      categoryIds,
      workTemplateIds,
      userIds,
      groupIds,
      completedByIds,
    } = input;

    const data = R.reject(isNilOrEmpty, {
      ...input,
      priorityIds: priorityIds?.join(','),
      statusIds: statusIds?.join(','),
      categoryIds: categoryIds?.join(','),
      workTemplateIds: workTemplateIds?.join(','),
      userIds: userIds?.join(','),
      groupIds: groupIds?.join(','),
      completedByIds: completedByIds?.join(','),
    });

    return buildEndpointWithParamsFromData(
      FILES_WORK_CSV_DOWNLOAD_ENDPOINT,
      data,
    );
  };

  const href = useMemo(() => {
    return getDownloadEndpoint();
  }, [input, totalCount]);

  return (
    <>
      <Menu
        icon="file_download"
        IconButtonProps={{ tooltip: 'Export Work' }}
        IconProps={{ color: colors.neutral.white, style: styles.downloadIcon }}
      >
        <MenuItem disabled={!href} onClick={() => window.open(href)}>
          <Icon style={styles.icon}>insert_drive_file</Icon>
          Download CSV
        </MenuItem>
        <MenuItem
          // disallow customized export when filtered by more than one work templates
          disabled={
            input?.workTemplateIds?.length > 1 ||
            R.equals(input.hasWorkTemplate, true)
          }
          onClick={() => setOpen(true)}
        >
          <Icon color={colors.neutral.gray} style={styles.icon}>
            <img src={dataExportOutlinedIcon} />
          </Icon>
          Export With Custom Columns
        </MenuItem>
      </Menu>
      <ExportWorkModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default WorkOrdersExport;
