import React, { useContext } from 'react';

import { useAnalyticsFolderSearch } from '@atom/hooks/useAnalyticsFolderSearch';
import { Checkbox, Icon, List, Progress } from '@atom/mui';
import { AnalyticsFolderSearch } from '@atom/types/analytics';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import AnalyticsFolderSelectionContext from './AnalyticsFolderSelectionContext';
import { MIN_SEARCH_CHARS, styles } from './analyticsFolderSelectUtils';

const { ListItemButton, ListItemText } = List;

const AnalyticsFolderSearchResults = () => {
  const { selectedFolderIds, query, handleChange, allFolders } = useContext(
    AnalyticsFolderSelectionContext,
  );

  const [{ analyticsFolders }, { loadingSearch }] = useAnalyticsFolderSearch({
    query,
    characterMin: MIN_SEARCH_CHARS,
  });

  const ancestorIsSelected = ancestors =>
    ancestors.some(ancestor => selectedFolderIds.has(ancestor.id));

  const handleItemClick = (searchFolder: AnalyticsFolderSearch) => {
    const treeFolder = allFolders.find(folder => folder.id === searchFolder.id);
    if (isNilOrEmpty(treeFolder)) {
      return;
    }
    handleChange(treeFolder);
  };

  const getListItem = folder => {
    const ancestorSelected = ancestorIsSelected(folder.ancestors);
    return (
      <ListItemButton
        key={folder.id}
        onClick={() => !ancestorSelected && handleItemClick(folder)}
      >
        <Checkbox
          checked={selectedFolderIds.has(folder.id) || ancestorSelected}
          disabled={ancestorSelected}
        />
        <Icon style={styles.folderIcon}>folder</Icon>
        <ListItemText
          primary={folder.name}
          secondary={folder.ancestors.map(({ name }) => name).join(' / ')}
        />
      </ListItemButton>
    );
  };

  return loadingSearch ? (
    <Progress style={styles.progress} />
  ) : (
    <List>{analyticsFolders.map(getListItem)}</List>
  );
};

export default AnalyticsFolderSearchResults;
