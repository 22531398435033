import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import AddTeamModal from '@atom/components/common/workOrderDetail/addTeamModal/AddTeamModal';
import WorkOrderContext from '@atom/components/workOrderDetail/WorkOrderContext';
import { TASK_USER_ADD, TASK_USER_GROUP_ADD } from '@atom/graph/task';
import { Icon, IconButton } from '@atom/mui';
import { sortTaskUsersByName } from '@atom/selectors/taskSelectors';
import {
  Task,
  TaskUserGroupInputItem,
  TaskUserGroupsAddInput,
  TaskUsersAddInput,
} from '@atom/types/task';
import { UserDetail } from '@atom/types/user';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';

import TaskUserGroupTile from './TaskUserGroupTile';
import TaskUserTile from './TaskUserTile';

import './teamSection.css';

const TeamSection = () => {
  const { workOrderDetail, task, refetch } = useContext(WorkOrderContext);

  const [expanded, setExpanded] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  const [taskUserAdd, { loading: addUserLoading }] = useMutation<
    { taskUserAdd: Task },
    { input: TaskUsersAddInput }
  >(TASK_USER_ADD, {
    onCompleted: () => {
      refetch();
    },
  });

  const [taskUserGroupAdd, { loading: addUserGroupLoading }] = useMutation<
    { taskUserGroupAdd: Task },
    { input: TaskUserGroupsAddInput }
  >(TASK_USER_GROUP_ADD, {
    onCompleted: () => {
      refetch();
    },
  });

  const handleAddUsers = async (users: UserDetail[]) => {
    await taskUserAdd({
      variables: {
        input: {
          id: task.id,
          workOrderId: workOrderDetail.id,
          users: users.map((user: UserDetail) => ({
            userId: user.id,
          })),
        },
      },
    });
  };

  const handleAddUserGroups = async (userGroups: TaskUserGroupInputItem[]) => {
    await taskUserGroupAdd({
      variables: {
        input: {
          id: task.id,
          workOrderId: workOrderDetail.id,
          userGroups,
        },
      },
    });
  };

  const isUserGroupAndUserEmpty =
    R.isEmpty(task.userGroups) && R.isEmpty(task.users);

  const totalCount = R.length(task.userGroups) + R.length(task.users);
  const icon = expanded ? 'expand_less' : 'expand_more';

  const sortedUsers = sortTaskUsersByName(task.users);
  const isEditDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  const userGroupsOnTask = task.userGroups.map(group => group.userGroupId);
  const usersOnTask = task.users.map(user => user.id);

  const loading = addUserLoading || addUserGroupLoading;

  return (
    <>
      <div styleName="team-header-container">
        <div styleName="team-left">
          <div styleName="section-title">Teams</div>
          <div styleName="total-count">{totalCount}</div>
        </div>
        <div styleName="team-right">
          {expanded && (
            <IconButton
              onClick={() => setOpen(true)}
              edge="end"
              tooltip="Add Team"
              disabled={isEditDisabled}
            >
              <Icon>add</Icon>
            </IconButton>
          )}
          <IconButton onClick={() => setExpanded(!expanded)} edge="end">
            <Icon>{icon}</Icon>
          </IconButton>
        </div>
      </div>
      {expanded && (
        <div>
          {isUserGroupAndUserEmpty ? (
            <div styleName="empty-section-text">Add Team Members</div>
          ) : (
            <>
              {task.userGroups.map((userGroup, index) => (
                <TaskUserGroupTile key={index} userGroup={userGroup} />
              ))}
              {sortedUsers.map((user, index) => (
                <TaskUserTile key={index} user={user} />
              ))}
            </>
          )}
        </div>
      )}
      <AddTeamModal
        open={open}
        userGroupsOnTask={userGroupsOnTask}
        usersOnTask={usersOnTask}
        onClose={() => setOpen(false)}
        handleAddUsers={handleAddUsers}
        handleAddUserGroups={handleAddUserGroups}
        loading={loading}
        favoritesEnabled
      />
    </>
  );
};

export default TeamSection;
