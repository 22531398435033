import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '@atom/components/common/navigation/Logo';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Button, Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import { getPortals } from '@atom/utilities/portalUtilities';

import './errorPages.css';

const styles = {
  icon: {
    fontSize: '75px',
  },
  button: {
    marginTop: '2rem',
  },
};

interface Props {
  // This is a prop provided by the ErrorBoundary package
  resetErrorBoundary?: () => void;
}

const UnknownError = ({ resetErrorBoundary = () => {} }: Props) => {
  const preferences = usePreferences();
  const landingPortal = getPortals(preferences)[preferences.landingPortal];

  return (
    <div styleName="page">
      <div styleName="header" onClick={resetErrorBoundary}>
        <Logo pathname={location.pathname} />
      </div>
      <div styleName="content-container">
        <div styleName="content">
          <Icon style={styles.icon} color={colors.neutral.silver}>
            error
          </Icon>
          <div styleName="title">Unknown Error</div>
          <div styleName="subtext">You've encountered an issue.</div>
          <div styleName="subtext">
            Please go to the homepage or contact support.
          </div>
          <Link to={landingPortal.urlPath} style={styles.button}>
            <Button
              variant="contained"
              color="primary"
              onClick={resetErrorBoundary}
            >
              Go To Homepage
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UnknownError;
