import React from 'react';

import { WorkOrderField } from '@atom/types/work';

import './workOrderFields.css';

interface Props {
  field: WorkOrderField;
}

const WorkOrderSummary = ({ field }: Props) => {
  return (
    <div styleName="field-summary">
      <div styleName="field-summary-title">{field.title}</div>
      <div styleName="field-summary-value">{field.value}</div>
    </div>
  );
};

export default WorkOrderSummary;
