/* eslint-disable */
import initialState from './initialState';
import actionTypes from '@atom/actions/actionTypes';

export default function csvErrorsReducer(
  state = initialState.csvErrors,
  action,
) {
  switch (action.type) {
    case actionTypes.VALIDATE_ASSETS_CSV_FAILURE: {
      return action.data;
    }
    case actionTypes.VALIDATE_ASSETS_CSV_SUCCESS:
    default: {
      return initialState.csvErrors;
    }
  }
}
