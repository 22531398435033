import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_TASK_UPDATE } from '@atom/graph/workTemplate';
import { Checkbox, Icon, IconButton, Modal, Switch } from '@atom/mui';
import {
  WorkOrderTemplateTaskUpdateInput,
  WorkTemplateTaskItem,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { WorkTypeVariant } from '@atom/utilities/workTemplateUtilities';

import './locationSection.css';

const styles = {
  switchStyles: {
    marginRight: '0',
    marginLeft: '5px',
  },
};

const LocationSection = () => {
  const { workTemplate, task, refetch, workTypeVariant } = useContext(
    WorkTemplateContext,
  );

  const [expanded, setExpanded] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  const [taskTemplateUpdate] = useMutation<
    { workOrderTemplateTaskUpdate: WorkTemplateTaskItem },
    { input: WorkOrderTemplateTaskUpdateInput }
  >(WORK_TEMPLATE_TASK_UPDATE);

  const toggleRequireSetting = async (requireAtLeastOneLocation: boolean) => {
    await taskTemplateUpdate({
      variables: {
        input: {
          workTemplateId: workTemplate.id,
          taskId: task.id,
          requireAtLeastOneLocation,
        },
      },
    });

    refetch();
  };

  const updateEnabledOption = async (locationsEnabled: boolean) => {
    await taskTemplateUpdate({
      variables: {
        input: {
          workTemplateId: workTemplate.id,
          taskId: task.id,
          locationsEnabled,
          requireAtLeastOneLocation: false,
        },
      },
    });

    setOpen(false);
    refetch();
  };

  const toggleEnabledSetting = (newLocationsEnabled: boolean) => {
    if (newLocationsEnabled) {
      updateEnabledOption(newLocationsEnabled);
    } else {
      setOpen(true);
    }
  };

  const icon = expanded ? 'expand_less' : 'expand_more';
  const isDisabled =
    workTemplate?.published ||
    doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN) ||
    workTypeVariant === WorkTypeVariant.CHILD;

  return (
    <>
      <div styleName="section-container">
        <Icon>place</Icon>
        <div styleName="section-title">Locations</div>
        <div styleName="buttons-container">
          <Switch
            style={styles.switchStyles}
            checked={task?.locationsEnabled}
            onChange={() => toggleEnabledSetting(!task?.locationsEnabled)}
            disabled={isDisabled}
          />
          <IconButton onClick={() => setExpanded(!expanded)} edge="end">
            <Icon>{icon}</Icon>
          </IconButton>
        </div>
      </div>
      {expanded && (
        <div styleName="tile-list">
          {task?.locationsEnabled ? (
            <div styleName="location-require">
              <Checkbox
                edge="start"
                checked={task?.requireAtLeastOneLocation}
                onChange={() =>
                  toggleRequireSetting(!task?.requireAtLeastOneLocation)
                }
                disabled={isDisabled}
              />
              <div>
                At least 1 location is required for the the task to be completed
                or the work to be completed or closed.
              </div>
            </div>
          ) : (
            <div styleName="empty-section-text">
              This section will not show in the task view.
            </div>
          )}
        </div>
      )}
      <Modal
        open={open}
        cancelButtonText="Cancel"
        confirmButtonText="Disable"
        onCancel={() => setOpen(false)}
        onConfirm={() => updateEnabledOption(!task?.locationsEnabled)}
        title="Disable Section?"
      >
        Disabling this section will hide it in the task view. Any changes made
        to the section settings will be lost. Are you sure?
      </Modal>
    </>
  );
};

export default LocationSection;
