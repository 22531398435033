import React from 'react';
import * as R from 'ramda';

import { Draggable, Droppable } from '@atom/components/common/dragAndDrop';
import FontIconButton from '@atom/components/common/FontIconButton';
// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import TextField from '@atom/components/common/TextField';
import { Icon, Menu, Modal } from '@atom/mui';
import { pageContainsModule } from '@atom/selectors/formModuleSelectors';
import colors from '@atom/styles/colors';
import fontStyles from '@atom/styles/fonts';
import { EventType } from '@atom/types/event';
import { FormFieldPageType, FormModuleKeys } from '@atom/types/form';

import './formBuilder.css';

const { MenuItem } = Menu;

const styles = {
  textFieldStyles: {
    fontSize: fontStyles.md,
  },
};

interface PassedProps {
  formTemplate: Object;
  toggleDrawer: () => void;
  updateSelectedPage: (index: number) => void;
  updatePageInEditMode: (index: any) => void;
  addPage: () => void;
  deletePage: (index: number) => boolean;
  duplicatePage: (index: number) => void;
  updatePageName: (name: string) => void;
  selectedPage: number;
  pageInEditMode: any;
  isDrawerOpen: boolean;
  pageName: string;
}

type Props = PassedProps;

const DROPPABLE_ID = 'pages';

class FormPages extends React.Component<Props> {
  state: {
    deleteModalOpenId: string | null;
  } = { deleteModalOpenId: null };

  toggleDeleteModal = (pageIdToOpen: string | null) => {
    this.setState({ deleteModalOpenId: pageIdToOpen });
  };

  onChange = (event: EventType) => {
    const { updatePageName } = this.props;
    updatePageName(event.target.value);
  };

  handleSubmit = (event: EventType) => {
    const { updatePageInEditMode, pageName, updatePageName } = this.props;

    if (event.key === 'Enter') {
      if (R.isEmpty(pageName)) {
        updatePageName('Untitled');
      }
      updatePageInEditMode(null);
    }
  };

  onBlur = () => {
    const { updatePageInEditMode, pageName, updatePageName } = this.props;

    if (R.isEmpty(pageName)) {
      updatePageName('Untitled');
    }

    updatePageInEditMode(null);
  };

  isStandaloneModulePage = (page: FormFieldPageType): boolean => {
    const { order, fields } = page;

    if (R.isEmpty(order) || R.isEmpty(fields)) {
      return false;
    }

    // @ts-ignore
    return Object.values(FormModuleKeys).includes(fields[order[0]].type);
  };

  buildPages = (pages: FormFieldPageType[]): any[] => {
    return pages.map((page: FormFieldPageType, index: number) => {
      const {
        selectedPage,
        updateSelectedPage,
        deletePage,
        duplicatePage,
        updatePageInEditMode,
        pageInEditMode,
        pageName,
      } = this.props;

      const styleName =
        selectedPage === index ? 'page-list-item selected' : 'page-list-item';

      const isStandaloneModulePage = this.isStandaloneModulePage(page);
      const isPciPage = pageContainsModule(FormModuleKeys.PCI, page);

      const isDeleteModalOpen = this.state.deleteModalOpenId === page.id;

      const onConfirmDeleteModal = () => {
        deletePage(index);
        this.toggleDeleteModal(null);
      };

      const menuOptions = (
        <>
          <Menu>
            {!isPciPage && (
              <MenuItem
                startAdornment={
                  <Icon>
                    <img src={renameIcon} />
                  </Icon>
                }
                onClick={() => updatePageInEditMode(index)}
              >
                Rename
              </MenuItem>
            )}
            {!isStandaloneModulePage && (
              <MenuItem
                startAdornment={<Icon>content_copy</Icon>}
                onClick={() => duplicatePage(index)}
              >
                Duplicate
              </MenuItem>
            )}
            <MenuItem
              startAdornment={<Icon>delete</Icon>}
              onClick={() => this.toggleDeleteModal(page.id)}
            >
              Delete
            </MenuItem>
          </Menu>
          <Modal
            open={isDeleteModalOpen}
            title={`Delete ${page.name}`}
            onCancel={() => this.toggleDeleteModal(null)}
            confirmButtonText="Delete"
            ConfirmButtonProps={{
              style: { background: colors.brand.red },
            }}
            onConfirm={onConfirmDeleteModal}
          >
            Are you sure you want to delete this page? All content will be
            deleted.
          </Modal>
        </>
      );

      return pageInEditMode !== index ? (
        <Draggable key={page.id} draggableId={page.id} index={index}>
          <div
            styleName={styleName}
            onClick={(): void => updateSelectedPage(index)}
          >
            <div styleName="page-item-left-container">
              <Icon color={colors.neutral.ash}>drag_indicator</Icon>
              <div styleName="page-name-container">{page.name}</div>
            </div>
            {menuOptions}
          </div>
        </Draggable>
      ) : (
        <div styleName={styleName} key={page.id}>
          <div styleName="page-item-left-container">
            <Icon color={colors.neutral.ash}>drag_indicator</Icon>
            <TextField
              onBlur={this.onBlur}
              value={pageName}
              styleName="page-item-left-container"
              onChange={this.onChange}
              key={index}
              onKeyDown={this.handleSubmit}
              name="pageName"
              style={styles.textFieldStyles}
              fullWidth
              autoFocus
            />
          </div>
          {menuOptions}
        </div>
      );
    });
  };

  render() {
    const { formTemplate, isDrawerOpen, toggleDrawer, addPage } = this.props;
    const pages = R.pathOr([], ['pages'], formTemplate);
    const formPages = this.buildPages(pages);

    const containerStyle = {
      ...(!isDrawerOpen ? { display: 'none' } : {}),
    };

    return (
      <div styleName="form-pages-container" style={containerStyle}>
        <div styleName="form-pages-navigation">
          <div styleName="add-tab-container">
            <FontIconButton
              onClick={addPage}
              color={colors.neutral.gray}
              icon="add"
            />
            <div styleName="add-tab-text">add tab</div>
          </div>
          <FontIconButton
            onClick={toggleDrawer}
            color={colors.neutral.gray}
            icon="keyboard_arrow_left"
          />
        </div>
        <Droppable droppableId={DROPPABLE_ID}>{formPages}</Droppable>
      </div>
    );
  }
}

export default FormPages;
