export const FHWA_PREVIEW_MOCK = {
  type: 'fhwa',
  order: [
    'e88091d2-oa5o-11e7-857c-63617aaae3fg',
    'e88091d2-oa5o-11e7-857c-63617aaae3ll',
  ],
  elements: {
    'e88091d2-oa5o-11e7-857c-63617aaae3fg': {
      name: '205 - Columns, Reinforced',
      attributes: {
        totalQuantity: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fa',
          name: 'total quantity',
          dataType: 'number',
        },
        remarks: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fb',
          name: 'Remarks',
          dataType: 'string',
        },
        environment: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fmc',
          name: 'environment',
          dataType: 'enumsingle',
          enumeration: ['1 - Benign', '2 - Low'],
          value: '1 - Benign',
        },
        cs1: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fd',
          name: 'CS1 (SF)',
          dataType: 'number',
        },
        cs1Percent: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fe',
          name: 'CS1 (%)',
          dataType: 'number',
        },
        cs2: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3ff',
          name: 'CS2 (SF)',
          dataType: 'number',
        },
        cs2Percent: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fl',
          name: 'CS2 (%)',
          dataType: 'number',
        },
        cs3: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fz',
          name: 'CS3 (SF)',
          dataType: 'number',
        },
        cs3Percent: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fy',
          name: 'CS3 (%)',
          dataType: 'number',
        },
        cs4: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fq',
          name: 'CS4 (SF)',
          dataType: 'number',
        },
        cs4Percent: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fo',
          name: 'CS4 (%)',
          dataType: 'number',
        },
      },
    },
    'e88091d2-oa5o-11e7-857c-63617aaae3ll': {
      name: '215 - Abutment, Reinforced Concrete',
      attributes: {
        totalQuantity: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fa',
          name: 'total quantity',
          dataType: 'number',
        },
        remarks: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fb',
          name: 'Remarks',
          dataType: 'string',
        },
        environment: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fmc',
          name: 'environment',
          dataType: 'enumsingle',
          enumeration: ['1 - Benign', '2 - Low'],
          value: '2 - Low',
        },
        cs1: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fd',
          name: 'CS1 (SF)',
          dataType: 'number',
        },
        cs1Percent: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fe',
          name: 'CS1 (%)',
          dataType: 'number',
        },
        cs2: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3ff',
          name: 'CS2 (SF)',
          dataType: 'number',
        },
        cs2Percent: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fl',
          name: 'CS2 (%)',
          dataType: 'number',
        },
        cs3: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fz',
          name: 'CS3 (SF)',
          dataType: 'number',
        },
        cs3Percent: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fy',
          name: 'CS3 (%)',
          dataType: 'number',
        },
        cs4: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fq',
          name: 'CS4 (SF)',
          dataType: 'number',
        },
        cs4Percent: {
          id: 'e88091d2-oa5o-11e7-857c-63617aaae3fo',
          name: 'CS4 (%)',
          dataType: 'number',
        },
      },
    },
  },
};
