import React, { Dispatch, SetStateAction } from 'react';

import { UserGroup } from '@atom/types/userGroups';

export enum View {
  INFO,
  BUDGETS,
  CERTIFICATIONS,
  ROLES,
}

interface Context {
  userGroup: UserGroup;
  activeView: View;
  setActiveView: Dispatch<SetStateAction<View>>;
  loading: boolean;
  refetch: () => Promise<any>;
}

export const initialState: Context = {
  activeView: View.INFO,
  setActiveView: () => {},
  // @ts-ignore
  userGroup: {},
  loading: false,
  refetch: () => Promise.resolve({}),
};

const UserGroupContext = React.createContext<Context>(initialState);

export default UserGroupContext;
