import React, { useEffect, useState } from 'react';
import { match as Match } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_MEDIA } from '@atom/graph/media';
import { GET_WORK_ORDER } from '@atom/graph/work';
import {
  MediaConnection,
  MediaConnectionInput,
  MediaType,
} from '@atom/types/media';
import { WorkOrderAssetTreeType, WorkOrderDetailType } from '@atom/types/work';
import api from '@atom/utilities/api';
import { WORK_ORDERS_ENDPOINT } from '@atom/utilities/endpoints';

import CoverSection from './CoverSection';
import DoubleSection from './DoubleSection';
import PhotoSection from './PhotoSection';
import {
  buildColumns,
  getAttributesByGroupName,
  getSectionsBySchemaName,
  ScdotGroupNameEnum,
} from './scdotReportUtilities';
import SingleSection from './SingleSection';

import './scdotPdfReport.css';

interface Props {
  match: Match<{ workOrderId: string }>;
}

const ScdotWorkOrderPdfReport = ({ match }: Props) => {
  const [treeLoading, setTreeLoading] = useState<boolean>(true);
  const [tree, setTree] = useState<WorkOrderAssetTreeType>(
    {} as WorkOrderAssetTreeType,
  );

  const {
    data: workOrderData,
    loading: workOrderLoading,
    error: workOrderError,
  } = useQuery<{ workOrder: WorkOrderDetailType }, { id: string }>(
    GET_WORK_ORDER,
    {
      variables: {
        id: match.params.workOrderId,
      },
      fetchPolicy: 'network-only',
    },
  );

  const {
    data: workMedia,
    loading: loadingWorkMedia,
    error: errorWorkMedia,
  } = useQuery<{ media: MediaConnection }, { input: MediaConnectionInput }>(
    GET_MEDIA,
    {
      variables: {
        input: {
          parentSubjectIds: [match.params.workOrderId],
          type: MediaType.IMAGE,
          page: 1,
          limit: 250,
        },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  );

  const workOrder = workOrderData?.workOrder;
  const task = workOrderData?.workOrder?.tasks[0];
  const media = workMedia?.media?.media;

  useEffect(() => {
    if (workOrder?.inventoryAssetId) {
      const getWorkOrderAssetTree = async () => {
        setTreeLoading(true);
        const endpoint = `${WORK_ORDERS_ENDPOINT}/${match.params.workOrderId}/assets/${workOrder?.inventoryAssetId}/tree`;
        const { data } = await api.get(endpoint);
        setTree(data);
        setTreeLoading(false);
      };

      getWorkOrderAssetTree();
    }
  }, [workOrder?.inventoryAssetId]);

  const sectionNames = getSectionsBySchemaName(tree?.assetType);

  // Maps attributes to the given styled section
  const getSection = (sectionName: ScdotGroupNameEnum) => {
    const attributes = getAttributesByGroupName(tree, sectionName);
    const columns = buildColumns(attributes);

    switch (sectionName) {
      case ScdotGroupNameEnum.LOCATION: {
        // Manually add the location of the asset to the location section
        const mappedRows = [
          [
            {
              name: 'Latitude',
              dataType: 'shorttext',
              // @ts-ignore
              value: workOrder?.inventoryAssetLocation?.coordinates[1],
            },
            {
              name: 'Longitude',
              dataType: 'shorttext',
              // @ts-ignore
              value: workOrder?.inventoryAssetLocation?.coordinates[0],
            },
          ],
          ...columns,
        ];
        return (
          <DoubleSection
            key={ScdotGroupNameEnum.LOCATION}
            title={ScdotGroupNameEnum.LOCATION}
            rows={mappedRows}
          />
        );
      }
      case ScdotGroupNameEnum.STRUCTURE: {
        return (
          <DoubleSection
            key={ScdotGroupNameEnum.STRUCTURE}
            title={ScdotGroupNameEnum.STRUCTURE}
            rows={columns}
          />
        );
      }
      case ScdotGroupNameEnum.INSPECTION: {
        return (
          <SingleSection
            key={ScdotGroupNameEnum.INSPECTION}
            title={ScdotGroupNameEnum.INSPECTION}
            rows={attributes}
          />
        );
      }
      case ScdotGroupNameEnum.MAINTENANCE: {
        return (
          <DoubleSection
            key={ScdotGroupNameEnum.MAINTENANCE}
            title={ScdotGroupNameEnum.MAINTENANCE}
            rows={columns}
          />
        );
      }
      default:
        return <div />;
    }
  };

  const showLoaded =
    !workOrderLoading &&
    !workOrderError &&
    !loadingWorkMedia &&
    !errorWorkMedia &&
    !treeLoading;

  return (
    <div styleName="container">
      {showLoaded && (
        <>
          <CoverSection workOrder={workOrder} task={task} media={media} />
          {sectionNames.map(sectionName => getSection(sectionName))}
          <PhotoSection media={media} mainPhotoId={workOrder?.mainPhotoId} />
          <div id="loaded" />
        </>
      )}
    </div>
  );
};

export default ScdotWorkOrderPdfReport;
