import React, { useContext } from 'react';

import SchemaDetailContext, {
  PreviewTab,
} from '@atom/components/schemaDetail/SchemaDetailContext';
import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';

import './schemaDetailHeader.css';

export interface Props {
  view: {
    value: PreviewTab;
    label: string;
    icon: string;
  };
}

const HeaderNavigationButton = ({ view }: Props) => {
  const { previewTab, setPreviewTab } = useContext(SchemaDetailContext);

  const { value, label, icon } = view;

  const styleName =
    previewTab === value ? 'icon-container active' : 'icon-container';

  return (
    <div onClick={() => setPreviewTab(value)} styleName={styleName}>
      <div styleName="icon">
        <Icon color={colors.neutral.white} children={icon} />
      </div>
      <div styleName="button-label">{label}</div>
    </div>
  );
};

export default HeaderNavigationButton;
