import * as R from 'ramda';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import actionTypes from '@atom/actions/actionTypes';
import * as inventoryExportActions from '@atom/actions/inventoryExportActions';
import textConstants from '@atom/constants/textConstants';
import { Snackbar } from '@atom/mui';
import accountUtilities from '@atom/utilities/accountUtilities';
import api from '@atom/utilities/api';
import { INVENTORY_EXPORT_ENDPOINT } from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';

export function* getExportTemplates(action) {
  try {
    const { isPublished } = action.data;
    const endpoint = INVENTORY_EXPORT_ENDPOINT;

    const params = {
      ...(isPublished ? { isPublished } : {}),
    };

    const { data } = yield call(api.get, endpoint, params);

    yield put(inventoryExportActions.getExportTemplatesSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryExportActions.getExportTemplatesFailure,
    );
  }
}

export function* getExportTemplateDetail(action) {
  try {
    const { exportTemplateId } = action.data;
    const endpoint = `${INVENTORY_EXPORT_ENDPOINT}/${exportTemplateId}`;

    const { data } = yield call(api.get, endpoint);

    yield put(inventoryExportActions.getExportTemplateDetailSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryExportActions.getExportTemplateDetailFailure,
    );
  }
}

export function* createExportTemplate(action) {
  try {
    const { name } = action.data;
    const endpoint = INVENTORY_EXPORT_ENDPOINT;

    const params = { name };

    const { data } = yield call(api.post, endpoint, params);

    yield put(inventoryExportActions.createExportTemplateSuccess(data));

    yield call(history.push, `/inventory/export/${data.id}`);
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryExportActions.createExportTemplateFailure,
    );
  }
}

export function* updateExportTemplate(action) {
  try {
    const {
      exportTemplateId,
      name,
      isPublished,
      categoryIds,
      rootSchemaId,
    } = action.data;
    const endpoint = `${INVENTORY_EXPORT_ENDPOINT}/${exportTemplateId}`;

    const body = {
      ...(name ? { name } : {}),
      ...(categoryIds ? { categoryIds } : {}),
      ...(rootSchemaId ? { rootSchemaId } : {}),
      ...(!R.isNil(isPublished) ? { isPublished } : {}),
    };

    const { data } = yield call(api.patch, endpoint, body);

    yield put(inventoryExportActions.updateExportTemplateSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryExportActions.updateExportTemplateFailure,
    );
  }
}

export function* deleteExportTemplate(action) {
  try {
    const { exportTemplateId } = action.data;
    const endpoint = `${INVENTORY_EXPORT_ENDPOINT}/${exportTemplateId}`;

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
    });

    const { data } = yield call(api.delete, endpoint);

    yield put(inventoryExportActions.deleteExportTemplateSuccess(data));

    yield call(history.push, '/inventory/export');

    Snackbar.info({
      message: 'Export Template Deleted',
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryExportActions.deleteExportTemplateFailure,
    );
  }
}

export function* addExportTemplateSchema(action) {
  try {
    const { exportTemplateId, schemaId } = action.data;
    const endpoint = `${INVENTORY_EXPORT_ENDPOINT}/${exportTemplateId}/schemas`;

    const params = { schemaId };

    const { data } = yield call(api.post, endpoint, params);

    yield put(inventoryExportActions.addExportTemplateSchemaSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryExportActions.addExportTemplateSchemaFailure,
    );
  }
}

export function* deleteExportTemplateSchema(action) {
  try {
    const { exportTemplateId, schemaId } = action.data;
    const endpoint = `${INVENTORY_EXPORT_ENDPOINT}/${exportTemplateId}/schemas/${schemaId}`;

    const { data } = yield call(api.delete, endpoint);

    yield put(inventoryExportActions.deleteExportTemplateSchemaSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryExportActions.deleteExportTemplateSchemaFailure,
    );
  }
}

export function* updateExportTemplateSchema(action) {
  try {
    const { exportTemplateId, schemaId, attributeIds, filters } = action.data;
    const endpoint = `${INVENTORY_EXPORT_ENDPOINT}/${exportTemplateId}/schemas/${schemaId}`;

    const body = {
      ...(attributeIds ? { attributeIds } : {}),
      ...(filters ? { filters } : {}),
    };

    Snackbar.info({
      message: 'Saving',
    });

    const { data } = yield call(api.patch, endpoint, body);

    yield put(inventoryExportActions.updateExportTemplateSchemaSuccess(data));

    Snackbar.info({
      message: 'Saved',
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryExportActions.updateExportTemplateSchemaFailure,
    );
  }
}

/* WATCHERS */
export function* requestExportTemplates() {
  yield takeEvery(actionTypes.REQUEST_EXPORT_TEMPLATES, getExportTemplates);
}

export function* requestExportTemplateDetail() {
  yield takeEvery(
    actionTypes.REQUEST_EXPORT_TEMPLATE_DETAIL,
    getExportTemplateDetail,
  );
}

export function* requestExportTemplateCreate() {
  yield takeEvery(
    actionTypes.REQUEST_EXPORT_TEMPLATE_CREATE,
    createExportTemplate,
  );
}

export function* requestExportTemplateUpdate() {
  yield takeEvery(
    actionTypes.REQUEST_EXPORT_TEMPLATE_UPDATE,
    updateExportTemplate,
  );
}

export function* requestExportTemplateDelete() {
  yield takeEvery(
    actionTypes.REQUEST_EXPORT_TEMPLATE_DELETE,
    deleteExportTemplate,
  );
}

export function* requestAddExportTemplateSchema() {
  yield takeEvery(
    actionTypes.REQUEST_ADD_EXPORT_TEMPLATE_SCHEMA,
    addExportTemplateSchema,
  );
}

export function* requestDeleteExportTemplateSchema() {
  yield takeEvery(
    actionTypes.REQUEST_DELETE_EXPORT_TEMPLATE_SCHEMA,
    deleteExportTemplateSchema,
  );
}

export function* requestUpdateExportTemplateSchema() {
  yield takeEvery(
    actionTypes.REQUEST_UPDATE_EXPORT_TEMPLATE_SCHEMA,
    updateExportTemplateSchema,
  );
}

export default [
  requestExportTemplates,
  requestExportTemplateDetail,
  requestExportTemplateCreate,
  requestExportTemplateDelete,
  requestExportTemplateUpdate,
  requestAddExportTemplateSchema,
  requestDeleteExportTemplateSchema,
  requestUpdateExportTemplateSchema,
];
