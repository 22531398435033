import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as actionCreators from '@atom/actions/formTemplateActions';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { FormTemplateActions } from '@atom/types/actions';
import history from '@atom/utilities/history';

import '../../styles/detail-header.css';

interface ReduxDispatchProps {
  formTemplateActions: FormTemplateActions;
}

interface PassedProps {
  form: any;
  isPublished: boolean;
  instanceCount: number;
  preview: boolean;
  taskId?: string;
  savingFormInstance: boolean;
}

type Props = PassedProps & ReduxDispatchProps;

class FormInstanceHeader extends React.Component<Props> {
  navigateBack = () => {
    history.goBackWithState('/form-repository');
  };

  navigateToFormBuilder = () => {
    const { id } = this.props.form;
    history.goBackWithState(`/form-builder/${id}`);
  };

  navigateToFormRepository = () => {
    const { formTemplateActions } = this.props;

    formTemplateActions.requestClearFormTemplate();
    history.push('/form-repository');
  };

  getRightButtons = (savingText: string) => {
    const { preview, isPublished, instanceCount } = this.props;

    if (isPublished) {
      return (
        <div styleName="form-builder-header">
          <div styleName="in-use-text">{`In Use: ${instanceCount}`}</div>
          <div styleName="published-block">
            <Icon color={colors.neutral.white}>lock</Icon>
            <div styleName="published-text">published</div>
          </div>
        </div>
      );
    }

    if (preview && !isPublished) {
      return (
        <div styleName="form-builder-header">
          <div
            styleName="back-to-edit-button"
            onClick={(): void => this.navigateToFormBuilder()}
          >
            back to edit
          </div>
        </div>
      );
    }

    return <div styleName="saving-text">{savingText}</div>;
  };

  render() {
    const { form, preview, savingFormInstance, isPublished } = this.props;

    const savingText = savingFormInstance ? 'Saving ...' : 'All changes saved.';

    const rightButtons = this.getRightButtons(savingText);

    const hasLeftBackButton = !preview || isPublished;

    const leftButtons = hasLeftBackButton ? (
      <IconButton onClick={this.navigateBack} tooltip="Go back">
        <Icon color={colors.neutral.white}>arrow_back</Icon>
      </IconButton>
    ) : (
      <div />
    );

    return (
      <div styleName="preview-header-container">
        <div styleName="preview-name-container">
          {leftButtons}
          <Icon color={colors.neutral.white}>library_books</Icon>
          <div styleName="preview-name-text">{form.name}</div>
        </div>
        <div styleName="action-icon-container">{rightButtons}</div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  formTemplateActions: bindActionCreators(actionCreators, dispatch),
});

export default connect(null, mapDispatchToProps)(FormInstanceHeader);
