import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { Icon, IconButton, ListTable } from '@atom/components/common/mui';
import UserGroupIcon from '@atom/components/common/UserGroupIcon';
import UserThumbnail from '@atom/components/common/UserThumbnail';
import { WORK_ORDER_UPDATE } from '@atom/graph/work';
import { Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import { WorkOrderUpdate, WorkOrderUpdateInput } from '@atom/types/work';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import {
  rollUpHydratedTaskUserGroups,
  rollUpHydratedTaskUsers,
} from '@atom/utilities/userUtilities';

import WorkOrderContext from '../WorkOrderContext';

import './workOrderTeam.css';

const { TableHead, TableRow, TableCell, TableBody } = ListTable;

const styles = {
  row: {
    backgroundColor: colors.neutral.white,
    height: '3rem',
  },
};

const WorkOrderTeam = () => {
  const { workOrderDetail, refetch, loading } = useContext(WorkOrderContext);

  const [updateAssignee] = useMutation<
    { workOrderUpdate: WorkOrderUpdate },
    { workOrder: WorkOrderUpdateInput }
  >(WORK_ORDER_UPDATE, {
    onCompleted: () => {
      refetch();
    },
  });

  const hydratedUsers = rollUpHydratedTaskUsers(workOrderDetail.tasks);
  const hydratedUserGroups = rollUpHydratedTaskUserGroups(
    workOrderDetail.tasks,
  );

  const displayList = [...hydratedUserGroups, ...hydratedUsers];

  const totalMembers = displayList.reduce((acc, item) => {
    return item.totalQuantity ? acc + item.totalQuantity : acc + 1;
  }, 0);

  const updateTeamLead = (leadAssigneeId: string) => {
    const updatedLeadId =
      leadAssigneeId === workOrderDetail?.leadAssigneeId ? '' : leadAssigneeId;

    updateAssignee({
      variables: {
        workOrder: {
          id: workOrderDetail.id,
          leadAssigneeId: updatedLeadId,
        },
      },
    });
  };

  return (
    <div styleName="team-container">
      <div styleName="center-pane">
        {loading ? (
          <Progress />
        ) : (
          <>
            <div styleName="team-total">{`${totalMembers} team members`}</div>
            <ListTable offsetTop="260px">
              <TableHead>
                <TableRow header>
                  <TableCell variant="head" padding="checkbox" />
                  <TableCell variant="head">Label</TableCell>
                  <TableCell variant="head">Task</TableCell>
                  <TableCell variant="head" padding="checkbox" />
                </TableRow>
              </TableHead>
              <TableBody>
                {displayList.map(item => {
                  const isActive = workOrderDetail.leadAssigneeId === item.id;
                  const isUserGroup = item.__typename === 'TaskUserGroup';

                  const onTeamLeadClick = hasRolePermissions(
                    ROLE_SETS.INSPECTOR,
                  )
                    ? () => updateTeamLead(item.id)
                    : () => {};

                  const name = isUserGroup
                    ? item.name
                    : `${item.firstName} ${item.lastName}`;
                  const subtext = isUserGroup
                    ? `${item.totalQuantity} User(s)`
                    : item.email;

                  return (
                    <TableRow key={item.id} style={styles.row}>
                      <TableCell padding="checkbox">
                        {isUserGroup ? (
                          <div>
                            <UserGroupIcon colorId={item.colorId} />
                          </div>
                        ) : (
                          <UserThumbnail
                            image={item.photoUrl}
                            alt={`${item.firstName} ${item.lastName}`}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <div styleName="label-column">
                          <div styleName="name">{name}</div>
                          <div styleName="subtext">{subtext}</div>
                        </div>
                      </TableCell>
                      <TableCell>{item.taskName.join(', ')}</TableCell>
                      <TableCell>
                        <div styleName="team-lead-column">
                          {isActive ? (
                            <div>Team Lead</div>
                          ) : (
                            <div styleName="lead-filler" />
                          )}
                          {!isUserGroup && (
                            <IconButton onClick={onTeamLeadClick} size="small">
                              <Icon
                                color={
                                  isActive
                                    ? colors.brand.blue
                                    : colors.neutral.gray
                                }
                              >
                                {isActive ? 'star' : 'star_border'}
                              </Icon>
                            </IconButton>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </ListTable>
          </>
        )}
      </div>
    </div>
  );
};

export default WorkOrderTeam;
