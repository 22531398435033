import React, { useContext } from 'react';
import { Collapse } from '@mui/material';

import CreateAssetModalContext from '../CreateAssetModalContext';

import AttributeGroupRow from './AttributeGroupRow';
import AttributeRow from './AttributeRow';

import './attributesTab.css';

const AttributesTab = () => {
  const { schema, collapsedGroups } = useContext(CreateAssetModalContext);

  return (
    <div styleName="attributes-tab-container">
      {schema?.attributeGroups?.map(group => (
        <div key={group.id}>
          <AttributeGroupRow key={group.id} attributeGroup={group} />
          <Collapse
            in={!collapsedGroups.has(group.id)}
            timeout="auto"
            unmountOnExit
          >
            <div styleName="attribute-list">
              {group.attributes.map(attributeId => {
                const attribute = {
                  id: attributeId,
                  ...schema.attributes[attributeId],
                };

                return <AttributeRow key={attributeId} attribute={attribute} />;
              })}
            </div>
          </Collapse>
        </div>
      ))}
    </div>
  );
};

export default AttributesTab;
