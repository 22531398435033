import { gql } from '@apollo/client';

export const BASE_ANALYTICS_CARD = gql`
  fragment BaseAnalyticsCard on AnalyticsCard {
    id
    name
    embedLinks
    createdDate
    createdBy {
      id
      firstName
      lastName
      photoUrl
    }
  }
`;

export const GET_ANALYTICS_CARD = gql`
  query analyticsCard($id: ID!) {
    analyticsCard(id: $id) {
      ...BaseAnalyticsCard
    }
  }
  ${BASE_ANALYTICS_CARD}
`;

export const GET_ANALYTICS = gql`
  query analytics($input: AnalyticsConnectionInput) {
    analytics(input: $input) {
      totalCount
      analytics {
        ...BaseAnalyticsCard
      }
    }
  }
  ${BASE_ANALYTICS_CARD}
`;

export const GET_ANALYTICS_FOLDER_TREE = gql`
  query analyticsFolderTree {
    analyticsFolderTree {
      id
      name
      folderPath
      children
      hasCards
      cardsCount
    }
  }
`;

export const CREATE_ANALYTICS_FOLDER = gql`
  mutation analyticsFolderCreate($input: AnalyticsFolderCreateInput!) {
    analyticsFolderCreate(input: $input) {
      id
      name
      parentFolderId
      ancestors
      hasCards
    }
  }
`;

export const UPDATE_ANALYTICS_FOLDER = gql`
  mutation analyticsFolderUpdate($input: AnalyticsFolderUpdateInput!) {
    analyticsFolderUpdate(input: $input) {
      id
      name
      parentFolderId
      ancestors
      hasCards
    }
  }
`;

export const DELETE_ANALYTICS_FOLDER = gql`
  mutation analyticsFolderDelete($id: ID!) {
    analyticsFolderDelete(id: $id)
  }
`;

export const GET_ANALYTICS_FOLDERS = gql`
  query analyticsFolders($input: AnalyticsFoldersConnectionInput!) {
    analyticsFolders(input: $input) {
      totalCount
      analyticsFolders {
        id
        name
        parentFolderId
        ancestors
        hasCards
        createdBy {
          firstName
          lastName
        }
        createdDate
      }
    }
  }
`;

export const CREATE_ANALYTICS_CARD = gql`
  mutation analyticsCardCreate($input: AnalyticsCardCreateInput!) {
    analyticsCardCreate(input: $input) {
      id
      name
      folderPath
      embedLinks
    }
  }
`;

export const UPDATE_ANALYTICS_CARD = gql`
  mutation analyticsCardUpdate($input: AnalyticsCardUpdateInput!) {
    analyticsCardUpdate(input: $input) {
      id
      name
      folderPath
      embedLinks
    }
  }
`;

export const DELETE_ANALYTICS_CARD = gql`
  mutation analyticsCardDelete($id: ID!) {
    analyticsCardDelete(id: $id)
  }
`;

export const SEARCH_ANALYTICS = gql`
  query analyticsSearch($input: AnalyticsSearchInput!) {
    analyticsSearch(input: $input) {
      totalCount
      analytics {
        id
        name
        embedLinks
        createdBy {
          id
          firstName
          lastName
        }
        createdDate
      }
    }
  }
`;

export const ANALYTICS_FOLDER_SEARCH = gql`
  query analyticsFolderSearch($input: AnalyticsFolderSearchInput!) {
    analyticsFolderSearch(input: $input) {
      totalCount
      analyticsFolders {
        id
        name
        ancestors {
          id
          name
        }
      }
    }
  }
`;

export const MOVE_ANALYTICS_CARDS = gql`
  mutation moveAnalyticsToNewFolder($input: MoveAnalyticsCardInput!) {
    moveAnalyticsToNewFolder(input: $input)
  }
`;
