import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { SelectChangeEvent } from '@mui/material';
import * as R from 'ramda';

import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { Progress, RadioGroup, Select, TextField } from '@atom/mui';
import { budgetUnitsSelector } from '@atom/selectors/preferencesSelectors';
import { SchemaBudget, SchemaBudgetType } from '@atom/types/schema';

import './costDetail.css';

const { MenuItem } = Select;
const { RadioButton } = RadioGroup;

const styles = {
  unitOption: {
    textTransform: 'uppercase',
  },
  inputStyles: {
    width: '200px',
  },
};

interface Props {
  loading: boolean;
  budget: SchemaBudget;
  setBudget: (budget: SchemaBudget) => void;
  updateBudget: (budget: SchemaBudget) => void;
}

const CostSection = ({ loading, budget, setBudget, updateBudget }: Props) => {
  const { schemaTree } = useContext(SchemaDetailContext);
  const budgetUnits = useSelector(budgetUnitsSelector);

  const { rate, type, unit } = budget;

  const updateRate = event => {
    const newTimeRate = event.target.value
      ? Math.abs(Number(event.target.value))
      : 0;

    const updatedBudget = {
      ...budget,
      rate: newTimeRate,
    };

    setBudget(updatedBudget);

    if (!R.isNil(newTimeRate)) {
      updateBudget(updatedBudget);
    }
  };

  const updateUnit = (event: SelectChangeEvent) => {
    const updatedBudget = {
      ...budget,
      unit: event.target.value,
    };

    setBudget(updatedBudget);
    updateBudget(updatedBudget);
  };

  const updateBudgetType = event => {
    const updatedBudget = {
      ...budget,
      rate: 0,
      unit: 'sq ft',
      type: event.target.value,
    };

    setBudget(updatedBudget);
    updateBudget(updatedBudget);
  };

  const isDisabled = schemaTree?.isPublished;

  return (
    <>
      <div styleName="cost-title">
        <div>Cost Details</div>
        {loading && <Progress size={25} thickness={2} />}
      </div>
      <div styleName="cost-container">
        <RadioGroup value={type} onChange={updateBudgetType}>
          <RadioButton
            value={SchemaBudgetType.VARIABLE}
            label="Variable"
            disabled={isDisabled}
          />
          <div styleName="option-subtext">
            Set the cost and unit at the individual inventory item level.
          </div>
          <RadioButton
            value={SchemaBudgetType.VARIABLE_COST}
            label="Variable Cost"
            disabled={isDisabled}
          />
          <div styleName="option-subtext">
            Set a fixed unit for all inventory of this type.
          </div>
          {type === SchemaBudgetType.VARIABLE_COST && (
            <div styleName="cost-input-container">
              <Select
                label="Unit"
                fullWidth
                InputProps={{
                  style: styles.unitOption,
                }}
                style={styles.inputStyles}
                onChange={updateUnit}
                value={unit}
                disabled={isDisabled}
              >
                {budgetUnits.map(option => {
                  return (
                    <MenuItem
                      key={option}
                      value={option || null}
                      style={styles.unitOption}
                    >
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          )}
          <RadioButton
            value={SchemaBudgetType.FIXED}
            label="Fixed"
            disabled={isDisabled}
          />
          <div styleName="option-subtext">
            Set a fixed cost and unit for all inventory of this type.
          </div>
          {type === SchemaBudgetType.FIXED && (
            <div styleName="cost-input-container">
              <TextField
                label="Cost"
                value={rate}
                type="number"
                style={styles.inputStyles}
                onChange={updateRate}
                InputProps={{
                  startAdornment: '$',
                }}
                disabled={isDisabled}
              />
              <div styleName="separator">per</div>
              <Select
                label="Unit"
                fullWidth
                InputProps={{
                  style: styles.unitOption,
                }}
                style={styles.inputStyles}
                onChange={updateUnit}
                value={unit}
                disabled={isDisabled}
              >
                {budgetUnits.map(option => {
                  return (
                    <MenuItem
                      key={option}
                      value={option || null}
                      style={styles.unitOption}
                    >
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          )}
        </RadioGroup>
      </div>
    </>
  );
};

export default CostSection;
