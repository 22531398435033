import * as React from 'react';
import * as R from 'ramda';

import DetailRow from '@atom/components/common/map/mapDrawerContent/DetailRow';
import { UserDetail } from '@atom/types/user';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';
import { maskUntilPos } from '@atom/utilities/stringUtilities';

import './userSummaryTray.css';

interface Props {
  user: UserDetail;
}

const UserSummaryInfo = ({ user }: Props) => {
  const isAldot = isCurrentClient([Client.ALDOT]);
  const displayId =
    isAldot && user.employeeId
      ? maskUntilPos(user.employeeId)
      : user.employeeId;
  return (
    <div styleName="info-container">
      <div styleName="info-detail-section">
        <DetailRow label="Employee ID" value={displayId} />
        <DetailRow label="Base Location" value={user.baseLocation} />
        <DetailRow label="Department" value={user.department} />
        <DetailRow label="Job Title" value={user.title} />
        <DetailRow
          label="Hourly Rate"
          value={R.isNil(user.rate) ? '' : numberToLocaleString(user.rate)}
        />
      </div>
    </div>
  );
};

export default UserSummaryInfo;
