import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import { GET_BUDGET_OPTIONS, SEARCH_BUDGET_OPTIONS } from '@atom/graph/budget';
import { ComboSelect, Snackbar } from '@atom/mui';
import {
  BasicBudget,
  BudgetOptionsConnection,
  BudgetsConnectionInput,
  BudgetSearchConnection,
  BudgetSearchInput,
} from '@atom/types/budget';
import { objectIdsMatch } from '@atom/utilities/objectCompareUtilities';

import BudgetDetailContext from '../BudgetDetailContext';
import { DEBOUNCE_TIME, getDateRange } from '../budgetDetailUtils';

import '../budgetDetail.css';

const LIMIT = 25;
const MIN_SEARCH_CHARS = 2;
const MAX_SELECTIONS = 5;

const BudgetDetailComparisonFilter = () => {
  const {
    comparisonBudgets,
    setComparisonBudgets,
    setBudgetSummaries,
    budget,
  } = useContext(BudgetDetailContext);

  const [comparisonBudgetsCart, setComparisonBudgetsCart] = useState<
    BasicBudget[]
  >(comparisonBudgets);
  const [comparisonBudgetsSnapshot, setComparisonBudgetsSnapshot] = useState<
    BasicBudget[]
  >(comparisonBudgets);

  const [query, setQuery] = useState<string>('');
  const [searchOptions, setSearchOptions] = useState<BasicBudget[]>([]);
  const [searchPage, setSearchPage] = useState<number>(1);
  const [searchTotal, setSearchTotal] = useState<number>(0);

  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [open, setOpen] = useState<boolean>();
  const [options, setOptions] = useState<BasicBudget[]>([]);

  const searching = useMemo(() => query.length >= MIN_SEARCH_CHARS, [query]);

  const [fetchSearchOptions, { loading: loadingSearchOptions }] = useLazyQuery<
    { budgetSearch: BudgetSearchConnection },
    { input: BudgetSearchInput }
  >(SEARCH_BUDGET_OPTIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const nextOptions: BasicBudget[] = R.pathOr(
        [],
        ['budgetSearch', 'budgets'],
        data,
      );
      const newTotal: number = R.pathOr(
        0,
        ['budgetSearch', 'totalCount'],
        data,
      );
      setSearchOptions([...searchOptions, ...nextOptions]);
      setSearchTotal(newTotal);
    },
    onError: () => {
      Snackbar.error({ message: 'Failed to search comparison options.' });
    },
  });

  const [fetchBudgetOptions, { loading: loadingOptions }] = useLazyQuery<
    { budgets: BudgetOptionsConnection },
    { input: BudgetsConnectionInput }
  >(GET_BUDGET_OPTIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const nextOptions: BasicBudget[] = R.pathOr(
        [],
        ['budgetOptions', 'budgets'],
        data,
      );
      const newTotal: number = R.pathOr(
        0,
        ['budgetOptions', 'totalCount'],
        data,
      );
      setOptions([...options, ...nextOptions]);
      setTotal(newTotal);
    },
  });

  const handleSelectOption = (option: BasicBudget) => {
    const selectedIds = comparisonBudgetsCart.map(comp => comp.id);
    const newSelectedOptions = selectedIds.includes(option.id)
      ? comparisonBudgetsCart.filter(comp => comp.id !== option.id)
      : [...comparisonBudgetsCart, option];
    setComparisonBudgetsCart(newSelectedOptions);
  };

  const handleClearSelections = () => setComparisonBudgets([]);

  const fetchSearchOptionsDebounced = useCallback(
    debounce((value: string) => {
      fetchSearchOptions({
        variables: {
          input: {
            budgetTemplateId: budget.templateId,
            query: value,
            limit: LIMIT,
            page: searchPage,
          },
        },
      });
    }, DEBOUNCE_TIME),
    [],
  );

  const handleQueryChange = (value: string = '') => {
    setQuery(value);
    setSearchPage(1);
    setSearchOptions([]);
    if (!value) {
      fetchSearchOptionsDebounced.cancel();
    } else if (value.length >= MIN_SEARCH_CHARS) {
      fetchSearchOptionsDebounced(value);
    } else {
      fetchSearchOptionsDebounced.cancel();
    }
  };

  const handleFetchOptions = () => {
    fetchBudgetOptions({
      variables: {
        input: {
          templateId: budget?.templateId,
          limit: LIMIT,
        },
      },
    });
  };

  const handlePageScroll = (nextPage: number) => {
    if (searching) {
      setSearchPage(nextPage);
      fetchSearchOptions({
        variables: {
          input: {
            budgetTemplateId: budget.templateId,
            query,
            limit: LIMIT,
            page: nextPage,
          },
        },
      });
    } else {
      setPage(nextPage);
      fetchBudgetOptions({
        variables: {
          input: {
            templateId: budget.templateId,
            limit: LIMIT,
            page: nextPage,
          },
        },
      });
    }
  };

  const removeSelf = (option: BasicBudget): boolean => option.id !== budget.id;
  const filteredOptions = useMemo(() => options.filter(removeSelf), [options]);
  const filteredSearchOptions = useMemo(
    () => searchOptions.filter(removeSelf),
    [searchOptions],
  );

  useEffect(() => {
    // Cache initial selected item snapshot when user opens menu
    if (open === true) {
      setComparisonBudgetsSnapshot(comparisonBudgetsCart);
    }
    if (
      open === false &&
      !objectIdsMatch(comparisonBudgetsSnapshot, comparisonBudgetsCart)
    ) {
      setComparisonBudgets(comparisonBudgetsCart);
      setBudgetSummaries([]);
    }
  }, [open]);

  return (
    <ComboSelect
      open={open}
      setOpen={setOpen}
      itemName="Budgets"
      loading={loadingOptions || loadingSearchOptions}
      options={searching ? filteredSearchOptions : filteredOptions}
      total={searching ? searchTotal : total}
      page={searching ? searchPage : page}
      handlePageScroll={handlePageScroll}
      fetchOptions={handleFetchOptions}
      heading={budget?.templateName}
      getOptionSubheading={getDateRange}
      query={query}
      onQueryChange={handleQueryChange}
      selectedOptions={comparisonBudgetsCart}
      clearSelections={() => handleClearSelections()}
      onSelectOption={handleSelectOption}
      dataCyLabel="BudgetComparisonFilter"
      maxSelections={MAX_SELECTIONS}
    />
  );
};
// TODO: raise state - https://atomai.atlassian.net/browse/AM-15622
export default memo(BudgetDetailComparisonFilter);
