import React, { useEffect, useState } from 'react';
import * as R from 'ramda';

import WorkTemplatesContext from '@atom/components/workTemplates/WorkTemplatesContext';
import { Progress } from '@atom/mui';
import { PolicyAction } from '@atom/types/policy';
import { WorkTemplateFolderTree } from '@atom/types/workTemplate';
import api from '@atom/utilities/api';
import { WORK_TEMPLATES_FOLDER_TREE_ENDPOINT } from '@atom/utilities/endpoints';

import WorkTemplatesFolderTree from './workTemplatesFolderTree/WorkTemplatesFolderTree';
import WorkTemplatesFolderList from './workTemplatesList/WorkTemplatesFolderList';
import WorkTemplatesList from './workTemplatesList/WorkTemplatesList';
import WorkTemplatesHeader from './WorkTemplatesHeader';

import './workTemplates.css';

const styles = {
  progress: {
    width: '100%',
  },
};

const WorkTemplates = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [activeFolderId, setActiveFolderId] = useState<string>('workTypes');
  const [open, setOpen] = useState<Set<string>>(new Set(['workTypes']));
  const [workTemplateFolderTree, setWorkTemplateFolderTree] = useState<
    WorkTemplateFolderTree
    // @ts-ignore
  >({});
  const [isActiveFolderLeafNode, setIsActiveFolderLeafNode] = useState<boolean>(
    false,
  );

  const refetchWorkTemplateFolderTree = async () => {
    const { data } = await api.get(WORK_TEMPLATES_FOLDER_TREE_ENDPOINT, {
      action: PolicyAction.READ,
      countParentWorkTemplates: true,
    });
    return setWorkTemplateFolderTree(data);
  };

  useEffect(() => {
    const getWorkTemplateFolderTree = async () => {
      setLoading(true);

      const { data } = await api.get(WORK_TEMPLATES_FOLDER_TREE_ENDPOINT, {
        action: PolicyAction.READ,
        countParentWorkTemplates: true,
      });

      setWorkTemplateFolderTree(data);
      setIsActiveFolderLeafNode(R.isEmpty(data.children));
      setLoading(false);
    };

    getWorkTemplateFolderTree();
  }, []);

  const toggleOpen = (id: string) => {
    if (open.has(id)) {
      open.delete(id);
      setOpen(new Set([...open]));
    } else {
      setOpen(new Set([...open.add(id)]));
    }
  };

  return (
    <WorkTemplatesContext.Provider
      value={{
        activeFolderId,
        setActiveFolderId,
        open,
        toggleOpen,
        refetchWorkTemplateFolderTree,
        isActiveFolderLeafNode,
        setIsActiveFolderLeafNode,
      }}
    >
      <WorkTemplatesHeader />
      <div styleName="portal-container">
        {loading ? (
          <Progress style={styles.progress} />
        ) : (
          <>
            <div styleName="pane left">
              <WorkTemplatesFolderTree
                workTemplateFolderTree={workTemplateFolderTree}
              />
            </div>
            <div styleName="pane right">
              {isActiveFolderLeafNode ? (
                <WorkTemplatesList />
              ) : (
                <WorkTemplatesFolderList
                  workTemplateFolderTree={workTemplateFolderTree}
                />
              )}
            </div>
          </>
        )}
      </div>
    </WorkTemplatesContext.Provider>
  );
};

export default WorkTemplates;
