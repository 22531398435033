import * as R from 'ramda';
import { createSelector } from 'reselect';
import uuid from 'uuid/v4';

import {
  formTemplateContainsModule,
  getFormModuleByType,
  getFormModuleSchema,
  pageContainsModule,
} from '@atom/selectors/formModuleSelectors';
import {
  AssetSchemaType,
  AttributeGroupItem,
  FormFieldPageType,
  FormFieldType,
  FormModuleKeys,
  FormTemplateInfo,
  FormTemplateType,
} from '@atom/types/form';
import { AttributesType, InventorySchemaItem } from '@atom/types/inventory';

const initialFormTemplate = {
  id: '',
  name: '',
  createdDate: 0,
  createdBy: '',
  pages: [
    {
      id: '',
      name: 'Page 1',
      fields: {},
      order: [],
    },
  ],
};

export const formTemplateDetail = R.pathOr({}, ['formTemplateDetail']);

export const formTemplateSelector = createSelector(
  (state: any): any => formTemplateDetail(state),
  (formTemplate: Object): any => {
    if (R.isNil(formTemplate) || R.isEmpty(formTemplate)) {
      return initialFormTemplate;
    }

    return formTemplate;
  },
);

export const MAX_OPTIONS = 150;
export const MAX_CHAR_SHORT_INPUT = 250;
export const MAX_CHAR_MEDIUM_INPUT = 2500;
export const MAX_CHAR_LONG_INPUT = 5000;
export const MAX_CHAR_DESCRIPTION = 1000;
export const MAX_CHAR_UNIT = 100;
export const MAX_CHAR_NUMBER = 24;
export const MAX_CHAR_CURRENCY = 14;
export const REVEAL_LONG = 50;
export const REVEAL_SHORT = 5;

export const getTypeIconData = (
  type: string,
): { icon: string; text: string } => {
  const iconData = {
    title: {
      icon: 'title',
      text: 'title',
    },
    description: {
      icon: 'subject',
      text: 'description',
    },
    shorttext: {
      icon: 'short_text',
      text: 'short text field',
    },
    longtext: {
      icon: 'format_align_left',
      text: 'long text field',
    },
    number: {
      icon: 'looks_one',
      text: 'numeric',
    },
    enumsingle: {
      icon: 'arrow_drop_down_circle',
      text: 'single select dropdown',
    },
    enummultiple: {
      icon: 'done_all',
      text: 'multi select dropdown',
    },
    radio: {
      icon: 'radio_button_checked',
      text: 'multiple choices',
    },
    date: {
      icon: 'today',
      text: 'date picker field',
    },
    currency: {
      icon: 'attach_money',
      text: 'currency field (USD)',
    },
    asset: {
      icon: 'category',
      text: 'Inventory',
    },
    iframe: {
      icon: 'code',
      text: 'web content',
    },
  };
  return iconData[type];
};

export const getFieldSchema = (type: string): FormFieldType => {
  const fields = {
    title: {
      type: 'title',
      text: '',
    },
    description: {
      type: 'description',
      text: '',
    },
    shorttext: {
      type: 'shorttext',
      title: '',
      description: '',
      required: false,
      fileUpload: false,
    },
    longtext: {
      type: 'longtext',
      title: '',
      description: '',
      required: false,
      fileUpload: false,
    },
    number: {
      type: 'number',
      title: '',
      description: '',
      unit: '',
      required: false,
      fileUpload: false,
    },
    enumsingle: {
      type: 'enumsingle',
      title: '',
      description: '',
      enum: ['Option 1', 'Option 2'],
      required: false,
      fileUpload: false,
    },
    enummultiple: {
      type: 'enummultiple',
      title: '',
      description: '',
      enum: ['Option 1', 'Option 2'],
      required: false,
      fileUpload: false,
    },
    radio: {
      type: 'radio',
      title: '',
      description: '',
      enum: ['Choice 1', 'Choice 2'],
      required: false,
      fileUpload: false,
    },
    date: {
      type: 'date',
      title: '',
      description: '',
      required: false,
      fileUpload: false,
    },
    currency: {
      type: 'currency',
      title: '',
      description: '',
      required: false,
      fileUpload: false,
    },
    iframe: {
      type: 'iframe',
      title: '',
      url: '',
      width: 33,
    },
  };

  return fields[type];
};

export const addFormTemplateField = (
  type: string,
  selectedPage: number,
  formTemplate: FormTemplateType,
): FormTemplateType => {
  const newField = getFieldSchema(type);
  const id = uuid();

  return {
    ...formTemplate,
    pages: [
      ...formTemplate.pages.map(
        (page: FormFieldPageType, index: number): FormFieldPageType =>
          index === selectedPage
            ? {
                ...page,
                order: [...page.order, id],
                fields: { ...page.fields, [id]: newField },
              }
            : page,
      ),
    ],
  };
};

export const deleteFormTemplateField = (
  id: string,
  selectedPage: number,
  formTemplate: FormTemplateType,
): FormTemplateType => {
  return {
    ...formTemplate,
    pages: [
      ...formTemplate.pages.map(
        (page: FormFieldPageType, index: number): FormFieldPageType =>
          index === selectedPage
            ? {
                ...page,
                order: R.filter(
                  (item: string): boolean => item !== id,
                  page.order,
                ),

                fields: { ...R.omit([id], page.fields) },
              }
            : page,
      ),
    ],
  };
};

export const duplicateFormTemplateField = (
  id: string,
  selectedPage: number,
  formTemplate: FormTemplateType,
): FormTemplateType => {
  const field = formTemplate.pages[selectedPage].fields[id];
  const order = formTemplate.pages[selectedPage].order;
  const afterFieldIndex = order.indexOf(id) + 1;
  const newId = uuid();
  return {
    ...formTemplate,
    pages: [
      ...formTemplate.pages.map(
        (page: FormFieldPageType, index: number): FormFieldPageType =>
          index === selectedPage
            ? {
                ...page,
                order: R.insert(afterFieldIndex, newId, page.order),
                fields: { ...page.fields, [newId]: field },
              }
            : page,
      ),
    ],
  };
};

export const addFormTemplatePage = (
  formTemplate: FormTemplateType,
): FormTemplateType => {
  const pageNumber = R.length(formTemplate.pages) + 1;
  const newPage = {
    id: uuid(),
    name: `Page ${pageNumber}`,
    order: [],
    fields: {},
  };
  return {
    ...formTemplate,
    pages: [...formTemplate.pages, newPage],
  };
};

export const deleteFormTemplatePage = (
  index: number,
  formTemplate: FormTemplateType,
): FormTemplateType => {
  return {
    ...formTemplate,
    pages: R.remove(index, 1, formTemplate.pages),
  };
};

export const addNewFieldIds = (page: FormFieldPageType): Object => {
  return page.order.reduce(
    (acc: Object, fieldId: string): Object => {
      const newId = uuid();
      return {
        // @ts-ignore
        order: [...acc.order, newId],
        fields: {
          // @ts-ignore
          ...acc.fields,
          [newId]: page.fields[fieldId],
        },
      };
    },
    {
      order: [],
      fields: {},
    },
  );
};

export const duplicateFormTemplatePage = (
  index: number,
  formTemplate: FormTemplateType,
): FormTemplateType => {
  const originalPage = formTemplate.pages[index];
  const newPageName = `Copy of ${originalPage.name}`;
  const updatedFieldsAndOrder = addNewFieldIds(originalPage);

  const newPage = {
    id: uuid(),
    name: newPageName,
    ...updatedFieldsAndOrder,
  };

  const afterPageIndex = index + 1;
  return {
    ...formTemplate,
    // @ts-ignore
    pages: R.insert(afterPageIndex, newPage, formTemplate.pages),
  };
};

export const updateFormTemplatePageName = (
  name: string,
  pageInEditMode: any,
  formTemplate: FormTemplateType,
): FormTemplateType => {
  return {
    ...formTemplate,
    pages: formTemplate.pages.map(
      (page: FormFieldPageType, index: number): FormFieldPageType =>
        index === pageInEditMode ? { ...page, name } : page,
    ),
  };
};

export const addFieldOption = (enumValue: string[]): string[] => {
  const optionName = `Option ${enumValue.length + 1}`;
  return [...enumValue, optionName];
};

export const removeFieldOption = (
  index: number,
  enumValue: string[],
): string[] => {
  return R.remove(index, 1, enumValue);
};

export const updateFieldOption = (
  optionIndex: string,
  value: string,
  enumValue: string[],
): string[] => {
  return enumValue.map((option: string, index: number): string => {
    return Number(optionIndex) === index ? value : option;
  });
};

export const updateFormTemplateFields = (
  id: string,
  selectedPage: number,
  data: any,
  formTemplate: FormTemplateType,
): FormTemplateType => {
  const updatedField = {
    ...(data.required !== undefined ? { required: data.required } : {}),
    ...(data.text ? { text: data.text } : {}),
    ...(data.title ? { title: data.title } : {}),
    ...(data.description ? { description: data.description } : {}),
    ...(data.unit ? { unit: data.unit } : {}),
    ...(data.enumValue ? { enum: data.enumValue } : {}),
    ...(data.value ? { value: data.value } : {}),
    ...(data.fileUpload ? { fileUpload: data.fileUpload } : {}),
    ...(!R.isNil(data.url) && { url: data.url }),
    ...(data.width && { width: data.width }),
  };

  return {
    ...formTemplate,
    pages: [
      ...formTemplate.pages.map(
        (page: FormFieldPageType, index: number): FormFieldPageType =>
          index === selectedPage
            ? {
                ...page,
                fields: {
                  ...page.fields,
                  [id]: { ...page.fields[id], ...updatedField },
                },
              }
            : page,
      ),
    ],
  };
};

export const reorderArray = (
  data: any,
  toIndex: number,
  fromIndex: number,
  order: any[],
): any[] => {
  const removeIndexOrder = R.remove(fromIndex, 1, order);
  return R.insert(toIndex, data, removeIndexOrder);
};

export const updateFormTemplateFieldOrder = (
  id: string,
  toIndex: number,
  fromIndex: number,
  selectedPage: number,
  formTemplate: FormTemplateType,
): FormTemplateType => {
  return {
    ...formTemplate,
    pages: [
      ...formTemplate.pages.map(
        (page: FormFieldPageType, index: number): FormFieldPageType =>
          index === selectedPage
            ? {
                ...page,
                order: reorderArray(id, toIndex, fromIndex, page.order),
              }
            : page,
      ),
    ],
  };
};

export const updateFormTemplatePageOrder = (
  toIndex: number,
  fromIndex: number,
  formTemplate: FormTemplateType,
): FormTemplateType => {
  const selectedPage = formTemplate.pages[fromIndex];
  return {
    ...formTemplate,
    pages: reorderArray(selectedPage, toIndex, fromIndex, formTemplate.pages),
  };
};

export const getBuilderInputValidation = (
  type: string,
  subType: string,
  value: string | string[] | number,
): boolean => {
  switch (type) {
    case 'title': {
      return value.toString().length <= MAX_CHAR_SHORT_INPUT;
    }
    case 'description': {
      return value.toString().length <= MAX_CHAR_MEDIUM_INPUT;
    }
    case 'number': {
      if (subType === 'title') {
        return value.toString().length <= MAX_CHAR_SHORT_INPUT;
      } else if (subType === 'unit') {
        return value.toString().length <= MAX_CHAR_UNIT;
      }
      return value.toString().length <= MAX_CHAR_DESCRIPTION;
    }
    case 'enumsingle':
    case 'enummultiple':
    case 'radio':
    case 'shorttext':
    case 'longtext':
    case 'date':
    case 'currency':
    case 'iframe': {
      return subType === 'title'
        ? value.toString().length <= MAX_CHAR_SHORT_INPUT
        : value.toString().length <= MAX_CHAR_DESCRIPTION;
    }
    default: {
      return true;
    }
  }
};

export const getInstanceInputValidation = (
  type: string,
  value: string | number,
): boolean => {
  switch (type) {
    case 'number': {
      return value.toString().length <= MAX_CHAR_NUMBER;
    }
    case 'shorttext': {
      return value.toString().length <= MAX_CHAR_SHORT_INPUT;
    }
    case 'longtext': {
      return value.toString().length <= MAX_CHAR_LONG_INPUT;
    }
    case 'currency': {
      return value.toString().length <= MAX_CHAR_CURRENCY;
    }
    default: {
      return true;
    }
  }
};

export const addFormAsset = (
  formAsset: FormFieldType,
  assetSchema: AssetSchemaType,
): FormFieldType => {
  return {
    ...formAsset,
    assetSchemas: [...formAsset.assetSchemas, assetSchema],
  };
};

export const removeFormAsset = (
  formAsset: FormFieldType,
  assetSchema: AssetSchemaType,
): FormFieldType => {
  return {
    ...formAsset,
    assetSchemas: formAsset.assetSchemas.filter(
      (schema: AssetSchemaType): boolean => schema.id !== assetSchema.id,
    ),
  };
};

export const updateFormAsset = (
  formAsset: FormFieldType,
  assetSchema: AssetSchemaType,
): FormFieldType => {
  const matchedSchema = formAsset.assetSchemas.filter(
    (schema: AssetSchemaType): boolean => schema.id === assetSchema.id,
  );
  const assetSchemaExists = !R.isEmpty(matchedSchema);

  return assetSchemaExists
    ? removeFormAsset(formAsset, assetSchema)
    : addFormAsset(formAsset, assetSchema);
};

export const isSchemaChecked = (
  id: string,
  formAsset: FormFieldType,
): boolean => {
  const matchedSchema = formAsset.assetSchemas.filter(
    (schema: AssetSchemaType): boolean => schema.id === id,
  );
  return !R.isEmpty(matchedSchema);
};

export const isSchemaPreviouslySelected = (
  id: string,
  selectedSchemaIds: string[],
): boolean => {
  const matchedSchema = selectedSchemaIds.filter(
    (selectedId: string): boolean => selectedId === id,
  );
  return !R.isEmpty(matchedSchema);
};

export const addFormTemplateAssetField = (
  assetField: FormFieldType,
  selectedPage: number,
  formTemplate: FormTemplateType,
  schemaId: string,
): FormTemplateType => {
  const id = uuid();

  return {
    ...formTemplate,
    schemaId,
    pages: [
      ...formTemplate.pages.map(
        (page: FormFieldPageType, index: number): FormFieldPageType =>
          index === selectedPage
            ? {
                ...page,
                order: [...page.order, id],
                fields: { ...page.fields, [id]: assetField },
              }
            : page,
      ),
    ],
  };
};

export const addFormTemplateAttributeGroup = (
  assetSchemas: AssetSchemaType[],
  schemaId: string,
  attributeGroup: AttributeGroupItem,
): AssetSchemaType[] => {
  return assetSchemas.map(
    (schema: AssetSchemaType): AssetSchemaType =>
      schema.id === schemaId
        ? {
            ...schema,
            attributeGroups: [
              ...(!R.isNil(schema.attributeGroups)
                ? schema.attributeGroups
                : []),
              attributeGroup,
            ],
          }
        : schema,
  );
};

export const removeFormTemplateAttributeGroup = (
  assetSchemas: AssetSchemaType[],
  schemaId: string,
  attributeGroup: AttributeGroupItem,
): AssetSchemaType[] => {
  return assetSchemas.map(
    (schema: AssetSchemaType): AssetSchemaType =>
      schema.id === schemaId
        ? {
            ...schema,
            attributeGroups: schema.attributeGroups.filter(
              (group: AttributeGroupItem): boolean =>
                group.name !== attributeGroup.name,
            ),
          }
        : schema,
  );
};

export const updateFormTemplateAttributeGroup = (
  fieldId: string,
  schemaId: string,
  addGroup: boolean,
  attributeGroup: AttributeGroupItem,
  selectedPage: number,
  formTemplate: FormTemplateType,
): FormTemplateType => {
  return {
    ...formTemplate,
    pages: [
      ...formTemplate.pages.map(
        (page: FormFieldPageType, index: number): FormFieldPageType =>
          index === selectedPage
            ? {
                ...page,
                fields: {
                  ...page.fields,
                  [fieldId]: {
                    ...page.fields[fieldId],
                    assetSchemas: addGroup
                      ? addFormTemplateAttributeGroup(
                          page.fields[fieldId].assetSchemas,
                          schemaId,
                          attributeGroup,
                        )
                      : removeFormTemplateAttributeGroup(
                          page.fields[fieldId].assetSchemas,
                          schemaId,
                          attributeGroup,
                        ),
                  },
                },
              }
            : page,
      ),
    ],
  };
};

export const addFormTemplateAttribute = (
  assetSchemas: AssetSchemaType[],
  schemaId: string,
  attributeGroupName: string,
  newAttribute: AttributesType,
): AssetSchemaType[] => {
  return assetSchemas.map(
    (schema: AssetSchemaType): AssetSchemaType =>
      schema.id === schemaId
        ? {
            ...schema,
            attributeGroups: schema.attributeGroups.map(
              (group: AttributeGroupItem): AttributeGroupItem =>
                group.name === attributeGroupName
                  ? {
                      ...group,
                      attributes:
                        group.attributes.filter(
                          (attribute: Object): boolean =>
                            // @ts-ignore
                            attribute.id === newAttribute.id,
                        ).length === 0
                          ? [...group.attributes, newAttribute]
                          : group.attributes,
                    }
                  : group,
            ),
          }
        : schema,
  );
};

export const removeFormTemplateAttribute = (
  assetSchemas: AssetSchemaType[],
  schemaId: string,
  attributeGroupName: string,
  newAttribute: AttributesType,
): AssetSchemaType[] => {
  return assetSchemas.map(
    (schema: AssetSchemaType): AssetSchemaType =>
      schema.id === schemaId
        ? {
            ...schema,
            attributeGroups: schema.attributeGroups.map(
              (group: AttributeGroupItem): AttributeGroupItem =>
                group.name === attributeGroupName
                  ? {
                      ...group,
                      attributes: group.attributes.filter(
                        (attribute: AttributesType): boolean =>
                          attribute.id !== newAttribute.id,
                      ),
                    }
                  : group,
            ),
          }
        : schema,
  );
};

export const updateFormTemplateAttribute = (
  fieldId: string,
  schemaId: string,
  addAttribute: boolean,
  attributeGroupName: string,
  attribute: AttributesType,
  selectedPage: number,
  formTemplate: FormTemplateType,
): FormTemplateType => {
  return {
    ...formTemplate,
    pages: [
      ...formTemplate.pages.map(
        (page: FormFieldPageType, index: number): FormFieldPageType =>
          index === selectedPage
            ? {
                ...page,
                fields: {
                  ...page.fields,
                  [fieldId]: {
                    ...page.fields[fieldId],
                    assetSchemas: addAttribute
                      ? addFormTemplateAttribute(
                          page.fields[fieldId].assetSchemas,
                          schemaId,
                          attributeGroupName,
                          attribute,
                        )
                      : removeFormTemplateAttribute(
                          page.fields[fieldId].assetSchemas,
                          schemaId,
                          attributeGroupName,
                          attribute,
                        ),
                  },
                },
              }
            : page,
      ),
    ],
  };
};

export const addAllAttributes = (
  assetSchemas: AssetSchemaType[],
  schemaId: string,
  attributeGroupName: string,
  newAttributes: AttributesType[],
): AssetSchemaType[] => {
  return assetSchemas.map(
    (schema: AssetSchemaType): AssetSchemaType =>
      schema.id === schemaId
        ? {
            ...schema,
            attributeGroups: schema.attributeGroups.map(
              (group: AttributeGroupItem): AttributeGroupItem =>
                group.name === attributeGroupName
                  ? {
                      ...group,
                      attributes: newAttributes,
                    }
                  : group,
            ),
          }
        : schema,
  );
};

export const addAllFormTemplateAttributes = (
  fieldId: string,
  schemaId: string,
  attributeGroupName: string,
  attributes: AttributesType[],
  selectedPage: number,
  formTemplate: FormTemplateType,
): FormTemplateType => {
  return {
    ...formTemplate,
    pages: [
      ...formTemplate.pages.map(
        (page: FormFieldPageType, index: number): FormFieldPageType =>
          index === selectedPage
            ? {
                ...page,
                fields: {
                  ...page.fields,
                  [fieldId]: {
                    ...page.fields[fieldId],
                    assetSchemas: addAllAttributes(
                      page.fields[fieldId].assetSchemas,
                      schemaId,
                      attributeGroupName,
                      attributes,
                    ),
                  },
                },
              }
            : page,
      ),
    ],
  };
};

export const removeFormTemplateAssetSchema = (
  fieldId: string,
  schemaId: string,
  selectedPage: number,
  formTemplate: FormTemplateType,
): FormTemplateType => {
  return {
    ...formTemplate,
    pages: [
      ...formTemplate.pages.map(
        (page: FormFieldPageType, index: number): FormFieldPageType =>
          index === selectedPage
            ? {
                ...page,
                fields: {
                  ...page.fields,
                  [fieldId]: {
                    ...page.fields[fieldId],
                    assetSchemas: page.fields[fieldId].assetSchemas.filter(
                      (schema: AssetSchemaType): boolean =>
                        schema.id !== schemaId,
                    ),
                  },
                },
              }
            : page,
      ),
    ],
  };
};

export const buildAssetFieldName = (name: string, ancestors: any[]): string => {
  if (R.isNil(ancestors) || R.isEmpty(ancestors)) {
    return name;
  }

  const ancestorNames = ancestors.map(
    // @ts-ignore
    (ancestor: Object): string => ancestor.name,
  );

  const assetName = [...ancestorNames, name];
  return assetName.join(' / ');
};

export const collectSchemaIds = (allAssetFields: FormFieldType[]): string[] => {
  return allAssetFields.reduce(
    (acc: string[], field: FormFieldType): string[] => [
      ...acc,
      ...field.assetSchemas.map(
        (assetSchema: AssetSchemaType): string => assetSchema.id,
      ),
    ],
    [],
  );
};

export const getSelectedSchemaIds = (
  formTemplate: FormTemplateType,
): string[] => {
  if (R.isEmpty(formTemplate.pages)) {
    return [];
  }

  const allAssetFields = formTemplate.pages.reduce(
    (acc: FormFieldType[], page: FormFieldPageType): FormFieldType[] => [
      ...acc,
      ...page.order.reduce(
        (accumulator: FormFieldType[], id: string): FormFieldType[] =>
          page.fields[id].type === 'asset'
            ? [...accumulator, page.fields[id]]
            : accumulator,
        [],
      ),
    ],
    [],
  );

  return collectSchemaIds(allAssetFields);
};

export const addFormTemplateModule = (
  type: string,
  selectedPage: number,
  formTemplate: FormTemplateType,
  conditionInspectionId?: string,
  schemaId?: string,
): FormTemplateType => {
  // @ts-ignore
  const formModule = getFormModuleByType(type);
  const formModuleSchema = getFormModuleSchema(type);
  const afterPageIndex = selectedPage + 1;
  const newFieldId = uuid();

  const newPage = {
    id: uuid(),
    name: formModule.pageName,
    order: [newFieldId],
    fields: {
      [newFieldId]: {
        ...formModuleSchema,
        ...(conditionInspectionId && { conditionInspectionId }),
      },
    },
  };

  return {
    ...formTemplate,
    ...(schemaId && { schemaId }),
    // @ts-ignore
    pages: R.insert(afterPageIndex, newPage, formTemplate.pages),
  };
};

export const updateCiFormModule = (
  selectedPage: number,
  fieldId: string,
  conditionInspectionId: string,
  formTemplate: FormTemplateType,
): FormTemplateType => {
  return {
    ...formTemplate,
    pages: [
      ...formTemplate.pages.map(
        (page: FormFieldPageType, index: number): FormFieldPageType =>
          index === selectedPage
            ? {
                ...page,
                fields: {
                  ...page.fields,
                  [fieldId]: {
                    type: page.fields[fieldId].type,
                    conditionInspectionId,
                  },
                },
              }
            : page,
      ),
    ],
  };
};

export const publishFormTemplate = (
  formTemplate: FormTemplateType,
): FormTemplateType => {
  if (!formTemplateContainsModule(FormModuleKeys.PCI, formTemplate)) {
    return { ...formTemplate, isPublished: true };
  }

  // when PCI form is published replace single PCI page placeholder with all
  // pages required for PCI
  const pciPages = ['Inspection 1', 'Inspection 2'].map(name => {
    const newFieldId = uuid();

    return {
      id: uuid(),
      name,
      order: [newFieldId],
      fields: {
        [newFieldId]: getFormModuleSchema(FormModuleKeys.PCI),
      },
    };
  });

  const pciSummaryFieldId = uuid();
  const pciSummaryPage = {
    id: uuid(),
    name: 'Summary',
    order: [pciSummaryFieldId],
    fields: {
      [pciSummaryFieldId]: getFormModuleSchema(FormModuleKeys.PCI_SUMMARY),
    },
  };

  const pages = formTemplate.pages.reduce((acc, page) => {
    return pageContainsModule(FormModuleKeys.PCI, page)
      ? [...acc, ...pciPages, pciSummaryPage]
      : [...acc, page];
  }, []);

  return { ...formTemplate, pages, isPublished: true };
};

export const getAvailableForms = (
  formTemplates: FormTemplateInfo[],
  schemas: InventorySchemaItem[],
  inventorySchemaId?: string,
): Set<string> => {
  const noSchemaForms = formTemplates
    .filter(formTemplate => {
      return R.isEmpty(formTemplate.schemaId) || R.isNil(formTemplate.schemaId);
    })
    .filter(formTemplate => {
      const hasCiModule = formTemplate.modules.includes(FormModuleKeys.CI);
      const hasPciModule = formTemplate.modules.includes(FormModuleKeys.PCI);

      if (hasCiModule) {
        return schemas.find(({ id }) => id === inventorySchemaId)?.ciEnabled;
      }

      if (hasPciModule) {
        return schemas.find(({ id }) => id === inventorySchemaId)?.pciEnabled;
      }

      return true;
    })
    .map(({ id }) => id);

  if (R.isNil(inventorySchemaId) || R.isEmpty(inventorySchemaId)) {
    return new Set(noSchemaForms);
  }

  const filteredForms = formTemplates
    .filter(({ schemaId }) => schemaId === inventorySchemaId)
    .map(({ id }) => id);

  return new Set([...noSchemaForms, ...filteredForms]);
};
