import * as React from 'react';
import * as R from 'ramda';

import { Icon, List } from '@atom/mui';
import colors from '@atom/styles/colors';
import fontStyles from '@atom/styles/fonts';

import './folders.css';

const { ListItemButton, ListItemText } = List;

const styles = {
  listStyle: {
    padding: '0',
  },
  rootListItemStyle: {
    lineHeight: fontStyles.xl,
    fontSize: fontStyles.md,
    paddingRight: '1.25rem',
    paddingLeft: '1.25rem',
    height: '3.125rem',
  },
  selectedNode: {
    boxSizing: 'border-box',
    backgroundColor: colors.utility.highlight,
    borderLeft: `3px solid ${colors.brand.blue}`,
    height: '3.125rem',
  },
  listNode: {
    boxSizing: 'border-box',
    borderLeft: '3px solid transparent',
    height: '3.125rem',
  },
};

interface Props {
  selectedType: string;
  mediaTotals: any;
  selectMediaType: (mediaType: any) => void;
}

class MediaTotalList extends React.Component<Props> {
  getListItemRootStyle = (listItemId, selected) => {
    return selected === listItemId ? styles.selectedNode : styles.listNode;
  };

  getMediaTypeIcon = mediaType => {
    if (mediaType === 'image') {
      return 'insert_photo';
    } else if (mediaType === 'video') {
      return 'movie';
    }
    return 'insert_drive_file';
  };

  render() {
    const { mediaTotals, selectedType, selectMediaType } = this.props;
    const mediaTotalsList = R.keys(mediaTotals).map(mediaType => {
      const { name, count } = mediaTotals[mediaType];
      const rootStyles = this.getListItemRootStyle(mediaType, selectedType);
      const iconName = this.getMediaTypeIcon(mediaType);

      const icon = (
        <div styleName="media-totals-icon">
          <Icon color={colors.neutral.gray}>{iconName}</Icon>
        </div>
      );

      const content = (
        <div styleName="media-totals-text-container">
          <div styleName="media-totals-type">{name}</div>
          <div styleName="media-totals-count">{count}</div>
        </div>
      );

      return (
        <ListItemButton
          key={mediaType.toString()}
          style={rootStyles}
          onClick={() => selectMediaType(mediaType)}
        >
          {icon}
          <ListItemText
            primaryTextStyle={{ fontWeight: 0 }}
            primary={content}
          />
        </ListItemButton>
      );
    });

    return <List disablePadding>{mediaTotalsList}</List>;
  }
}

export default MediaTotalList;
