import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';

import {
  clearInventoryFilterCategoryTreeNode,
  requestCategoryTreeUpdate,
  requestCreateCategory,
  retrieveInventoryFilterCategoryTreeNode,
} from '@atom/actions/inventoryCategoryActions';
import CategorySelectionList from '@atom/components/inventory/CategorySelectionList';
import { Modal, Progress, Snackbar } from '@atom/mui';
import { getHydratedInventoryFilterCategoryTreeNode } from '@atom/selectors/categorySelectors';
import { InventoryAssetDetailType } from '@atom/types/inventory';
import api from '@atom/utilities/api';
import { INVENTORY_ASSETS_ENDPOINT } from '@atom/utilities/endpoints';

export interface Props {
  id: string;
  open?: boolean;
  closeModal?: () => any;
  onComplete?: () => void;
}

const styles = {
  modal: {
    height: '50vh',
  },
};

const MoveInventoryModal = ({ id, open, closeModal, onComplete }: Props) => {
  const dispatch = useDispatch();

  const [selectedCategoryId, setSelectedCategoryId] = useState<string>('');
  const [selectedCategoryName, setSelectedCategoryName] = useState<string>('');
  // @ts-ignore
  const [asset, setAsset] = useState<InventoryAssetDetailType>({});
  const [loadingAsset, setLoadingAsset] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const categoryTree = useSelector(getHydratedInventoryFilterCategoryTreeNode);
  const { schemaId, categoryId } = asset;

  const getInventoryAsset = async () => {
    setLoadingAsset(true);
    const { data } = await api.get(`${INVENTORY_ASSETS_ENDPOINT}/${id}`);
    setAsset(data);
    setLoadingAsset(false);
  };

  const createCategory = (
    parentCategoryId: string,
    name: string,
    categoryPath: any[],
  ) => {
    const data = {
      parentCategoryId,
      name,
      categoryPath,
      schemaId,
    };

    dispatch(requestCreateCategory(data));
  };

  const renameCategory = (
    parentCategoryId: string,
    name: string,
    categoryPath: any[],
  ) => {
    const data = {
      categoryId: parentCategoryId,
      name,
      path: categoryPath,
    };

    dispatch(requestCategoryTreeUpdate(data));
  };

  const handleToggle = (category, categoryPath) => {
    setSelectedCategoryId(!category.hasCategories ? category.id : '');
    setSelectedCategoryName(!category.hasCategories ? category.name : '');

    if (!R.isNil(category.id)) {
      const data = {
        id: category.id,
        categoryPath,
        expanded: !category.expanded,
      };

      dispatch(retrieveInventoryFilterCategoryTreeNode(data));
    }
  };

  const handleMove = async () => {
    const { name } = asset;

    setLoading(true);

    const data = {
      destCategoryId: selectedCategoryId,
    };

    await api.post(`${INVENTORY_ASSETS_ENDPOINT}/${id}/move`, data);

    setLoading(false);

    Snackbar.info({
      message: `Moved ${name} to ${selectedCategoryName}`,
    });

    if (closeModal) {
      closeModal();
    }

    if (onComplete) {
      onComplete();
    }
  };

  useEffect(() => {
    if (!open && schemaId) {
      setSelectedCategoryId('');
      setSelectedCategoryName('');
      dispatch(clearInventoryFilterCategoryTreeNode());
    }
  }, [open, schemaId, dispatch]);

  useEffect(() => {
    if (open && !schemaId) {
      getInventoryAsset();
    }
  }, [open, schemaId]);

  return (
    <Modal
      open={open}
      onConfirm={handleMove}
      confirmButtonText="Move"
      onCancel={closeModal}
      title="Move to Folder"
    >
      <div style={styles.modal}>
        {loadingAsset || loading ? (
          <Progress style={{ height: '100%' }} />
        ) : (
          <CategorySelectionList
            inventoryFilterCategoryTree={categoryTree}
            onToggle={handleToggle}
            createCategory={createCategory}
            renameCategory={renameCategory}
            selectedCategoryId={selectedCategoryId}
            enabledSchemaId={schemaId}
            disabledCategoryIds={[categoryId]}
          />
        )}
      </div>
    </Modal>
  );
};

export default MoveInventoryModal;
