import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function workTemplatesReducer(
  state = initialState.workTemplates,
  action,
) {
  switch (action.type) {
    case actionTypes.GET_WORK_TEMPLATES_SUCCESS: {
      return action.data ? [...action.data] : [];
    }
    case actionTypes.DELETE_WORK_TEMPLATE_SUCCESS: {
      return state.filter(workTemplate => workTemplate.id !== action.data.id);
    }
    default: {
      return state;
    }
  }
}
