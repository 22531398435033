import React, { useContext } from 'react';

import { TextField } from '@atom/mui';

import CreateAssetModalContext from './CreateAssetModalContext';

import './createAssetModal.css';

const styles = {
  input: {
    marginTop: '1rem',
  },
};

const NameTab = () => {
  const { name, setName } = useContext(CreateAssetModalContext);

  return (
    <div styleName="tab-container">
      <div styleName="tab-title">Name</div>
      <TextField
        style={styles.input}
        placeholder="Enter Inventory Name"
        value={name}
        onChange={event => setName(event?.target?.value)}
        variant="standard"
        data-cy="globalCreateAssetModalNameInput"
      />
    </div>
  );
};

export default NameTab;
