import * as React from 'react';

import './mapDrawerContent.css';

interface Props {
  title: string;
}

const SectionHeading = ({ title }: Props) => {
  return (
    <div styleName="section-heading-container">
      <div>{title}</div>
    </div>
  );
};

export default SectionHeading;
