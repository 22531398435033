import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function userDetailReducer(
  state = initialState.userDetail,
  action,
) {
  switch (action.type) {
    case actionTypes.USER_GET_SUCCESS:
    case actionTypes.UPDATE_USER_SUCCESS: {
      return {
        ...action.data,
      };
    }
    default: {
      return state;
    }
  }
}
