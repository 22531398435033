/* eslint-disable */
import { combineReducers } from 'redux';

import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';
import applicationSearch from './applicationSearchReducer';
import users from './usersReducer';
import organizations from './organizationsReducer';
import userDetail from './userDetailReducer';
import usersTotal from './usersTotalReducer';
import userProfile from './userProfileReducer';
import preferences from './preferencesReducer';
import menuState from './menuStateReducer';
import formInstance from './formInstanceReducer';
import formTemplates from './formTemplatesReducer';
import formTemplatesTotal from './formTemplatesTotalReducer';
import formTemplateDetail from './formTemplateDetailReducer';
import inventorySchema from './inventorySchemaReducer';
import inventorySchemaDetail from './inventorySchemaDetailReducer';
import inventorySchemasByIds from './inventorySchemasByIdsReducer';
import inventorySchemas from './inventorySchemasReducer';
import inventorySchemaTree from './inventorySchemaTreeReducer';
import exportTemplates from './inventoryExportTemplatesReducer';
import exportTemplateDetail from './inventoryExportTemplateDetailReducer';
import poolAttributes from './poolAttributesReducer';
import inventory from './inventoryReducer';
import inventoryTotal from './inventoryTotalReducer';
import inventoryAssetDetail from './inventoryAssetDetailReducer';
import inventoryFilterCategoryTree from './inventoryFilterCategoryTreeReducer';
import inventoryFullCategoryTree from './inventoryFullCategoryTreeReducer';
import workOrders from './workOrdersReducer';
import workOrdersTotal from './workOrdersTotalReducer';
import workOrderDetail from './workOrderReducer';
import workOrderAssetDetail from './workOrderAssetDetailReducer';
import loading from './loadingReducer';
import auth from './authReducer';
import media from './mediaReducer';
import mediaFolders from './mediaFoldersReducer';
import mediaFolderTree from './mediaFolderTreeReducer';
import mediaTotals from './mediaTotalsReducer';
import mediaTotal from './mediaTotalReducer';
import inventorySearch from './inventorySearchReducer';
import breadcrumbs from './breadcrumbsReducer';
import csvErrors from './csvErrorsReducer';
import workTemplates from './workTemplatesReducer';
import workTemplatesTotal from './workTemplatesTotalReducer';
import inventoryAssetTree from './inventoryAssetTreeReducer';
import workOrdersInput from './workOrdersInputReducer';
import workValidations from './workValidationsReducer';

const applicationReducer = combineReducers({
  applicationSearch,
  userDetail,
  users,
  organizations,
  usersTotal,
  inventorySchema,
  inventorySchemaDetail,
  inventorySchemas,
  inventorySchemasByIds,
  inventorySchemaTree,
  poolAttributes,
  inventory,
  inventoryTotal,
  inventoryAssetDetail,
  inventoryFilterCategoryTree,
  userProfile,
  preferences,
  menuState,
  formInstance,
  formTemplates,
  formTemplatesTotal,
  formTemplateDetail,
  workOrders,
  workOrdersTotal,
  workOrderDetail,
  workOrderAssetDetail,
  loading,
  auth,
  media,
  mediaFolders,
  mediaTotals,
  mediaFolderTree,
  mediaTotal,
  inventorySearch,
  breadcrumbs,
  csvErrors,
  workTemplates,
  workTemplatesTotal,
  exportTemplates,
  exportTemplateDetail,
  inventoryFullCategoryTree,
  inventoryAssetTree,
  workOrdersInput,
  workValidations,
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.LOGOUT_SUCCESS) {
    state = initialState;
  }

  return applicationReducer(state, action);
};

export default rootReducer;
