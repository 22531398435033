import React, { useMemo } from 'react';

import { useSchemas } from '@atom/hooks/useSchemas';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import { ReportMaterial } from './reportUtilities';

import './workOrderReport.css';

interface Props {
  reportMaterial: ReportMaterial;
  isCompletedOrAbove: boolean;
}

const ReportStartEndRow = ({ reportMaterial, isCompletedOrAbove }: Props) => {
  const { schemas = [] } = useSchemas();

  const schema = useMemo(() => {
    return schemas.find(({ id }) => id === reportMaterial.schemaId);
  }, [schemas, reportMaterial.schemaId]);

  const entries = isNilOrEmpty(reportMaterial?.entries)
    ? [
        {
          id: `empty${reportMaterial?.assetId}`,
          startReading: 0,
          endReading: 0,
          amount: 0,
        },
      ]
    : reportMaterial?.entries;

  const name = reportMaterial?.unit
    ? `${reportMaterial?.name} (${reportMaterial?.unit.toUpperCase()})`
    : reportMaterial?.name;

  return (
    <div styleName="start-end-row">
      <div styleName="start-end-type">{schema?.name || ''}</div>
      <div styleName="start-end-name">{name}</div>
      <div styleName="start-end-sub-container">
        {entries.map(entry => (
          <div key={entry?.id} styleName="start-end-row-sub">
            <div styleName="start-end-value">{entry?.startReading || ''}</div>
            <div styleName="start-end-value">{entry?.endReading || ''}</div>
            <div styleName="start-end-value used">{entry?.amount || ''}</div>
            {isCompletedOrAbove && (
              <div styleName="start-end-value">
                {reportMaterial?.rate && entry?.amount
                  ? numberToLocaleString(reportMaterial?.rate * entry?.amount)
                  : ''}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportStartEndRow;
