import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import TaskItemTile from '@atom/components/common/workOrderDetail/taskItemTile/TaskItemTile';
import InheritanceLockIcon from '@atom/components/workTemplate/InheritanceLockIcon';
import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_TASK_UPDATE } from '@atom/graph/workTemplate';
import { Icon, IconButton, Snackbar } from '@atom/mui';
import {
  InheritedComponentType,
  WorkOrderTemplateTaskUpdateInput,
  WorkTemplateTaskCategory,
  WorkTemplateTaskItem,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { isComponentInherited } from '@atom/utilities/workTemplateUtilities';

import './materialSection.css';

interface Props {
  category: WorkTemplateTaskCategory;
  onClick: () => void;
}

const CategoryTile = ({ category, onClick }: Props) => {
  const { id, name } = category;
  const { workTemplate, task, refetch } = useContext(WorkTemplateContext);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [updateTask, { loading }] = useMutation<
    { workOrderTemplateTaskUpdate: WorkTemplateTaskItem },
    { input: WorkOrderTemplateTaskUpdateInput }
  >(WORK_TEMPLATE_TASK_UPDATE, { onCompleted: res => refetch() });

  const handleDeleteConfirm = async () => {
    const categories = task.categories.filter(
      ({ id: categoryId }) => categoryId !== id,
    );

    const categoryIds = categories.map(({ id: categoryId }) => categoryId);
    const inheritedCategoryIds =
      R.find(
        R.propEq('type', InheritedComponentType.TASK_CATEGORY),
        task?.inheritedComponents || [],
      )?.ids || [];

    const filteredCategoryIds = R.filter(
      itemId => !R.includes(itemId, inheritedCategoryIds),
      categoryIds,
    );

    setDeleteModalOpen(false);

    Snackbar.info({
      message: 'Deleting...',
    });

    await updateTask({
      variables: {
        input: {
          taskId: task.id,
          workTemplateId: workTemplate.id,
          categoryIds: filteredCategoryIds,
        },
      },
    });

    refetch();

    Snackbar.info({
      message: `Removed Folder.`,
    });
  };

  const handleDeleteClick = event => {
    event.stopPropagation();
    setDeleteModalOpen(true);
  };

  const isDisabled =
    workTemplate?.published || doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);
  const isItemInherited = isComponentInherited(
    InheritedComponentType.TASK_CATEGORY,
    task?.inheritedComponents || [],
    id,
  );

  const icon = <Icon>create_new_folder</Icon>;
  const actionButton = isItemInherited ? (
    <div styleName="category-lock-container">
      <InheritanceLockIcon
        tooltip="Inherited Equipment and Material folder cannot be removed or edited."
        placement="bottom-start"
      />
    </div>
  ) : isDisabled ? (
    <div />
  ) : (
    <IconButton onClick={handleDeleteClick} size="small" tooltip="Remove">
      <Icon>close</Icon>
    </IconButton>
  );

  return (
    <>
      <TaskItemTile
        content={name}
        tooltipContent={name}
        onTileClick={onClick}
        icon={icon}
        actionButton={actionButton}
        maxWidth="21.25rem"
      />
      <DeleteModal
        open={deleteModalOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setDeleteModalOpen(false)}
        loading={loading}
        title="Remove Folder"
        content="Are you sure you want to remove this folder?"
        confirmText="Remove"
      />
    </>
  );
};

export default CategoryTile;
