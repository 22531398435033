import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function workOrdersTotalReducer(
  state = initialState.workOrdersTotal,
  action,
) {
  switch (action.type) {
    case actionTypes.GET_WORK_TOTAL_SUCCESS: {
      return action.workOrdersTotal ? action.workOrdersTotal : 0;
    }
    default: {
      return state;
    }
  }
}
