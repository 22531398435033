import React, { useEffect, useState } from 'react';

import { useUserProfile } from '@atom/hooks/useUserProfile';
import { Modal, Snackbar, TextField } from '@atom/mui';
import api from '@atom/utilities/api';
import { WORK_ORDERS_ENDPOINT } from '@atom/utilities/endpoints';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

interface Props {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  workOrderId: string;
  taskId: string;
}

const styles = {
  modal: {
    padding: '1.5rem',
  },
};

const HelpModal = ({
  open,
  onClose,
  loading,
  setLoading,
  workOrderId,
  taskId,
}: Props) => {
  const userProfile = useUserProfile();

  const [reason, setReason] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setReason('');
    setError('');
  }, [open]);

  useEffect(() => {
    if (isNilOrEmpty(reason)) {
      setError('You must provide a message');
    } else {
      setError('');
    }
  }, [reason]);

  const handleSubmit = async () => {
    try {
      setLoading(true);

      await api.post(
        `${WORK_ORDERS_ENDPOINT}/${workOrderId}/tasks/${taskId}/help`,
        {
          message: reason,
          userId: userProfile.userId,
        },
      );

      setLoading(false);

      Snackbar.info({ message: 'Request for help sent' });

      onClose();
    } catch (err) {
      Snackbar.error({ message: 'Failed to request help' });
    }
  };

  return (
    <Modal
      open={open}
      title="Ask For Help"
      onCancel={onClose}
      onConfirm={handleSubmit}
      confirmButtonText="Send"
      onExited={onClose}
      loading={loading}
      contentStyle={styles.modal}
      disabled={isNilOrEmpty(reason)}
    >
      <TextField
        multiline
        label="Message"
        name="reason"
        value={reason}
        error={!isNilOrEmpty(error)}
        helperText={error}
        onChange={event => {
          setReason(event.target.value);
        }}
      />
    </Modal>
  );
};

export default HelpModal;
