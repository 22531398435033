import React, { useContext, useMemo } from 'react';

import { useSchemas } from '@atom/hooks/useSchemas';
import { Select } from '@atom/mui';
import { InventorySchemaItem } from '@atom/types/inventory';
import { POLICY_GRANT_IDS_ALL } from '@atom/types/policy';

import PolicyModalContext from './PolicyModalContext';

const { MenuItem } = Select;

type SchemaMap = { [id: string]: InventorySchemaItem };

const PolicySchemaSelection = () => {
  const { state, updateState } = useContext(PolicyModalContext);
  const { grants, loadingExistingPolicies } = state;

  const { schemas } = useSchemas();

  const schemasMap = useMemo<SchemaMap>(() => {
    return schemas.reduce(
      (acc, schema): SchemaMap => ({
        ...acc,
        [schema.id]: schema,
      }),
      {},
    );
  }, [schemas]);

  const handleChange = event => {
    const values: string[] = event.target.value;
    const isAllSelected = grants.some(({ id }) => id === POLICY_GRANT_IDS_ALL);

    if (isAllSelected) {
      return updateState({
        grants: values
          .filter(val => val !== POLICY_GRANT_IDS_ALL)
          .map(id => ({
            id,
            name: schemasMap[id]?.name || '',
          })),
      });
    }

    return updateState({
      grants: values.includes(POLICY_GRANT_IDS_ALL)
        ? [{ id: POLICY_GRANT_IDS_ALL, name: 'All' }]
        : values.map(id => ({
            id,
            name: schemasMap[id]?.name || '',
          })),
    });
  };

  return (
    <Select
      multiple
      loading={loadingExistingPolicies}
      label="Inventory Type(s)"
      value={grants.map(({ id }) => id) || []}
      onChange={handleChange}
    >
      <MenuItem value="ALL">All</MenuItem>
      {schemas.map(schema => (
        <MenuItem key={schema.id} value={schema.id}>
          {schema.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default PolicySchemaSelection;
