import * as R from 'ramda';

import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function loadingReducer(state = initialState.loading, action) {
  switch (action.type) {
    case actionTypes.REQUEST_WORK_ORDERS: {
      return { ...state, loadingWorkOrders: true };
    }
    case actionTypes.GET_WORK_ORDERS_SUCCESS: {
      return { ...state, loadingWorkOrders: false };
    }
    case actionTypes.LOADING_USER: {
      return { ...state, retrievedUser: false };
    }
    case actionTypes.REQUEST_USER: {
      return { ...state, loadingUserDetail: true };
    }
    case actionTypes.USER_GET_SUCCESS: {
      return { ...state, loadingUserDetail: false };
    }
    case actionTypes.REQUEST_USER_UPDATE: {
      return { ...state, loadingUserUpdate: true };
    }
    case actionTypes.UPDATE_USER_SUCCESS:
    case actionTypes.UPDATE_USER_FAILURE: {
      return { ...state, loadingUserUpdate: false };
    }
    case actionTypes.REQUEST_USERS: {
      return { ...state, loadingUsers: true };
    }
    case actionTypes.USERS_GET_SUCCESS:
    case actionTypes.USERS_GET_FAILURE: {
      return { ...state, loadingUsers: false };
    }
    case actionTypes.REQUEST_INVENTORY_CATEGORIES:
    case actionTypes.REQUEST_INVENTORY_ASSETS:
    case actionTypes.REQUEST_INVENTORY_ASSETS_FILTER: {
      return { ...state, loadingInventory: true };
    }
    case actionTypes.GET_INVENTORY_CATEGORIES_SUCCESS:
    case actionTypes.GET_INVENTORY_ASSETS_SUCCESS:
    case actionTypes.GET_INVENTORY_ASSETS_FILTER_SUCCESS: {
      return { ...state, loadingInventory: false };
    }
    case actionTypes.REQUEST_INVENTORY_ASSET_DETAIL: {
      return { ...state, loadingInventoryAssetDetail: true };
    }
    case actionTypes.GET_INVENTORY_ASSET_DETAIL_SUCCESS: {
      return { ...state, loadingInventoryAssetDetail: false };
    }
    case actionTypes.REQUEST_INVENTORY_ASSET_DETAIL_UPDATE: {
      const loadingInventoryAssetAttributes = [
        ...state.loadingInventoryAssetAttributes,
        action.data.attributeGroupName,
      ];
      return { ...state, loadingInventoryAssetAttributes };
    }
    case actionTypes.GET_INVENTORY_ASSET_DETAIL_UPDATE_SUCCESS: {
      const loadingInventoryAssetAttributes = R.without(
        [action.data.attributeGroupName],
        state.loadingInventoryAssetAttributes,
      );
      return { ...state, loadingInventoryAssetAttributes };
    }
    case actionTypes.GET_INVENTORY_ASSET_DETAIL_UPDATE_FAILURE: {
      return { ...state, loadingInventoryAssetAttributes: [] };
    }
    case actionTypes.LOGGED_IN_USER_GET_FAILURE:
    case actionTypes.LOGGED_IN_USER_GET_SUCCESS:
    case actionTypes.LOGGED_IN_USER_FORBIDDEN: {
      return { ...state, retrievedUser: true, loadingUserLogin: false };
    }
    case actionTypes.REQUEST_INVENTORY_ELEMENT_DETAIL: {
      return { ...state, loadingInventoryElementDetail: true };
    }
    case actionTypes.GET_INVENTORY_ELEMENT_DETAIL_SUCCESS: {
      return { ...state, loadingInventoryElementDetail: false };
    }
    case actionTypes.REQUEST_INVENTORY_ELEMENT_DETAIL_UPDATE: {
      const loadingInventoryElementAttributes = [
        ...state.loadingInventoryElementAttributes,
        action.data.attributeGroupName,
      ];
      return { ...state, loadingInventoryElementAttributes };
    }
    case actionTypes.GET_INVENTORY_ELEMENT_DETAIL_UPDATE_SUCCESS: {
      const loadingInventoryElementAttributes = R.without(
        [action.data.attributeGroupName],
        state.loadingInventoryElementAttributes,
      );
      return { ...state, loadingInventoryElementAttributes };
    }
    case actionTypes.GET_INVENTORY_ELEMENT_DETAIL_UPDATE_FAILURE: {
      return { ...state, loadingInventoryElementAttributes: [] };
    }
    case actionTypes.REQUEST_INVENTORY_ASSET_TREE_NODE:
    case actionTypes.REQUEST_INVENTORY_ELEMENT_TREE_NODE: {
      return { ...state, loadingInventoryAssetTreeNode: true };
    }
    case actionTypes.GET_INVENTORY_ASSET_TREE_NODE_SUCCESS:
    case actionTypes.GET_INVENTORY_ELEMENT_TREE_NODE_SUCCESS: {
      return { ...state, loadingInventoryAssetTreeNode: false };
    }
    case actionTypes.REQUEST_INVENTORY_SCHEMA:
    case actionTypes.REQUEST_INVENTORY_SCHEMA_BY_ASSET_ID: {
      return { ...state, loadingInventorySchema: true };
    }
    case actionTypes.GET_INVENTORY_SCHEMA_SUCCESS:
    case actionTypes.GET_INVENTORY_SCHEMA_FAILURE: {
      return { ...state, loadingInventorySchema: false };
    }
    case actionTypes.REQUEST_SCHEMA_DETAIL: {
      return { ...state, loadingSchemaDetail: true };
    }
    case actionTypes.GET_SCHEMA_DETAIL_SUCCESS:
    case actionTypes.GET_SCHEMA_DETAIL_FAILURE: {
      return { ...state, loadingSchemaDetail: false };
    }
    case actionTypes.REQUEST_INVENTORY_SCHEMAS: {
      return { ...state, loadingInventorySchemas: true };
    }
    case actionTypes.GET_INVENTORY_SCHEMAS_SUCCESS: {
      return { ...state, loadingInventorySchemas: false };
    }
    case actionTypes.REQUEST_INVENTORY_SCHEMA_TREE_NODE: {
      return { ...state, loadingSchemaTreeNode: true };
    }
    case actionTypes.GET_INVENTORY_SCHEMA_TREE_NODE_SUCCESS: {
      return { ...state, loadingSchemaTreeNode: false };
    }
    case actionTypes.REQUEST_SCHEMA_TREE: {
      return { ...state, loadingInventorySchemaTree: true };
    }
    case actionTypes.GET_SCHEMA_TREE_SUCCESS: {
      return { ...state, loadingInventorySchemaTree: false };
    }
    case actionTypes.REQUEST_INVENTORY_FILTER_CATEGORY_TREE_NODE: {
      return { ...state, loadingInventoryFilterCategoryTreeNode: true };
    }
    case actionTypes.GET_INVENTORY_FILTER_CATEGORY_TREE_NODE_SUCCESS: {
      return { ...state, loadingInventoryFilterCategoryTreeNode: false };
    }
    case actionTypes.REQUEST_WORK_ORDER_DETAIL:
    case actionTypes.REQUEST_PATCH_WORK_ORDER: {
      return { ...state, loadingWorkOrderDetail: true };
    }
    case actionTypes.GET_WORK_ORDER_DETAIL_SUCCESS:
    case actionTypes.PATCH_WORK_ORDER_SUCCESS:
    case actionTypes.CLEAR_WORK_ORDER_LOADING_STATE: {
      return { ...state, loadingWorkOrderDetail: false };
    }
    case actionTypes.REQUEST_WORK_ORDER_ASSET: {
      return {
        ...state,
        loadingWorkOrderAssetDetail: true,
        loadingWorkOrderAssetElements: true,
      };
    }
    case actionTypes.REQUEST_PATCH_WORK_ORDER_ASSET: {
      const loadingWorkOrderAssetAttributes = [
        ...state.loadingWorkOrderAssetAttributes,
        action.data.attributeGroupName,
      ];
      return { ...state, loadingWorkOrderAssetAttributes };
    }
    case actionTypes.PATCH_WORK_ORDER_ASSET_SUCCESS: {
      const loadingWorkOrderAssetAttributes = R.without(
        [action.data.attributeGroupName],
        state.loadingWorkOrderAssetAttributes,
      );
      return { ...state, loadingWorkOrderAssetAttributes };
    }
    case actionTypes.PATCH_WORK_ORDER_ASSET_FAILURE: {
      return { ...state, loadingWorkOrderAssetAttributes: [] };
    }
    case actionTypes.GET_WORK_ORDER_ASSET_SUCCESS: {
      return {
        ...state,
        loadingWorkOrderAssetDetail: false,
        loadingWorkOrderAssetElements: true,
      };
    }
    case actionTypes.REQUEST_WORK_ORDER_ASSET_ELEMENTS: {
      return { ...state, loadingWorkOrderAssetElements: true };
    }
    case actionTypes.GET_WORK_ORDER_ASSET_ELEMENTS_SUCCESS: {
      return { ...state, loadingWorkOrderAssetElements: false };
    }
    case actionTypes.REQUEST_ROOT_ASSET_BATCH_CREATE:
    case actionTypes.REQUEST_ROOT_ASSET_BATCH_CREATE_FHWA:
    case actionTypes.REQUEST_ELEMENT_BATCH_CREATE: {
      return { ...state, loadingElementBatchCreation: true };
    }
    case actionTypes.CREATE_ROOT_ASSET_BATCH_SUCCESS:
    case actionTypes.CREATE_ROOT_ASSET_BATCH_FAILURE:
    case actionTypes.CREATE_ELEMENT_BATCH_SUCCESS:
    case actionTypes.CREATE_ELEMENT_BATCH_FAILURE:
    case actionTypes.CREATE_ROOT_ASSET_BATCH_FHWA_SUCCESS:
    case actionTypes.CREATE_ROOT_ASSET_BATCH_FHWA_FAILURE: {
      return { ...state, loadingElementBatchCreation: false };
    }
    case actionTypes.REQUEST_FHWA_ELEMENT_DELETE: {
      return { ...state, loadingElementDelete: true };
    }
    case actionTypes.DELETE_FHWA_ELEMENT_SUCCESS:
    case actionTypes.DELETE_FHWA_ELEMENT_FAILURE: {
      return { ...state, loadingElementDelete: false };
    }
    case actionTypes.REQUEST_CREATE_WORK_ORDER: {
      return { ...state, loadingCreateWorkOrder: true };
    }
    case actionTypes.CREATE_WORK_ORDER_SUCCESS:
    case actionTypes.CREATE_WORK_ORDER_FAILURE: {
      return { ...state, loadingCreateWorkOrder: false };
    }
    case actionTypes.REQUEST_CREATE_ASSET: {
      return { ...state, loadingCreateAsset: true };
    }
    case actionTypes.CREATE_ASSET_SUCCESS: {
      return { ...state, loadingCreateAsset: false };
    }
    case actionTypes.REQUEST_MEDIA: {
      return { ...state, loadingMedia: true };
    }
    case actionTypes.GET_MEDIA_SUCCESS:
    case actionTypes.GET_MEDIA_FAILURE: {
      return { ...state, loadingMedia: false };
    }
    case actionTypes.REQUEST_MEDIA_FOLDERS: {
      return { ...state, loadingMediaFolders: true };
    }
    case actionTypes.GET_MEDIA_FOLDERS_SUCCESS:
    case actionTypes.GET_MEDIA_FOLDERS_FAILURE: {
      return { ...state, loadingMediaFolders: false };
    }
    case actionTypes.REQUEST_CREATE_FORM_TEMPLATE:
    case actionTypes.REQUEST_DUPLICATE_FORM_TEMPLATE: {
      return { ...state, loadingFormTemplateCreation: true };
    }
    case actionTypes.CREATE_FORM_TEMPLATE_SUCCESS:
    case actionTypes.CREATE_FORM_TEMPLATE_FAILURE:
    case actionTypes.DUPLICATE_FORM_TEMPLATE_SUCCESS:
    case actionTypes.DUPLICATE_FORM_TEMPLATE_FAILURE: {
      return { ...state, loadingFormTemplateCreation: false };
    }
    case actionTypes.REQUEST_UPDATE_FORM_TEMPLATE: {
      return { ...state, loadingFormTemplateUpdate: true };
    }
    case actionTypes.UPDATE_FORM_TEMPLATE_SUCCESS:
    case actionTypes.UPDATE_FORM_TEMPLATE_FAILURE: {
      return { ...state, loadingFormTemplateUpdate: false };
    }
    case actionTypes.REQUEST_FORM_INSTANCE: {
      return { ...state, loadingFormInstance: true };
    }
    case actionTypes.GET_FORM_INSTANCE_SUCCESS: {
      return { ...state, loadingFormInstance: false };
    }
    case actionTypes.REQUEST_VALIDATE_ASSETS_CSV: {
      return { ...state, loadingCsvUploadValidation: true };
    }
    case actionTypes.VALIDATE_ASSETS_CSV_SUCCESS:
    case actionTypes.VALIDATE_ASSETS_CSV_FAILURE: {
      return { ...state, loadingCsvUploadValidation: false };
    }
    case actionTypes.REQUEST_FORM_TEMPLATE: {
      return { ...state, loadingFormTemplate: true };
    }
    case actionTypes.FORM_TEMPLATE_GET_SUCCESS:
    case actionTypes.FORM_TEMPLATE_GET_FAILURE: {
      return { ...state, loadingFormTemplate: false };
    }
    case actionTypes.REQUEST_FORM_TEMPLATES: {
      return { ...state, loadingFormTemplates: true };
    }
    case actionTypes.GET_FORM_TEMPLATES_SUCCESS:
    case actionTypes.GET_FORM_TEMPLATES_FAILURE: {
      return { ...state, loadingFormTemplates: false };
    }
    case actionTypes.REQUEST_SCHEMA_BY_IDS: {
      return { ...state, loadingInventorySchemasByIds: true };
    }
    case actionTypes.GET_SCHEMA_BY_IDS_SUCCESS:
    case actionTypes.GET_SCHEMA_BY_IDS_FAILURE: {
      return { ...state, loadingInventorySchemasByIds: false };
    }
    case actionTypes.REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_FIELD:
    case actionTypes.REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_ATTRIBUTE:
    case actionTypes.REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_CI:
    case actionTypes.REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_PCI: {
      return { ...state, savingFormInstance: true };
    }
    case actionTypes.UPDATE_FORM_INSTANCE_FIELD_SUCCESS:
    case actionTypes.UPDATE_FORM_INSTANCE_FIELD_FAILURE:
    case actionTypes.UPDATE_FORM_INSTANCE_ATTRIBUTE_SUCCESS:
    case actionTypes.UPDATE_FORM_INSTANCE_ATTRIBUTE_FAILURE:
    case actionTypes.UPDATE_FORM_INSTANCE_FHWA_SUCCESS:
    case actionTypes.UPDATE_FORM_INSTANCE_FHWA_FAILURE:
    case actionTypes.UPDATE_FORM_INSTANCE_CI_SUCCESS:
    case actionTypes.UPDATE_FORM_INSTANCE_CI_FAILURE:
    case actionTypes.UPDATE_FORM_INSTANCE_PCI_SUCCESS:
    case actionTypes.UPDATE_FORM_INSTANCE_PCI_FAILURE: {
      return { ...state, savingFormInstance: false };
    }
    case actionTypes.REQUEST_APPLICATION_SEARCH: {
      return { ...state, loadingApplicationSearch: true };
    }
    case actionTypes.GET_APPLICATION_SEARCH_SUCCESS:
    case actionTypes.GET_APPLICATION_SEARCH_FAILURE: {
      return { ...state, loadingApplicationSearch: false };
    }
    case actionTypes.REQUEST_USER_REGISTER: {
      return { ...state, loadingUserRegistration: true };
    }
    case actionTypes.USER_REGISTER_SUCCESS:
    case actionTypes.USER_REGISTER_FAILURE: {
      return { ...state, loadingUserRegistration: false };
    }
    case actionTypes.REQUEST_USER_AUTH_TOKEN_EXCHANGE: {
      return { ...state, loadingUserLogin: true };
    }
    case actionTypes.USER_TOKEN_PERSIST_FAILURE: {
      return { ...state, loadingUserLogin: false };
    }
    case actionTypes.REQUEST_USER_ORGANIZATIONS: {
      return { ...state, loadingOrganizations: true };
    }
    case actionTypes.USER_ORGANIZATIONS_GET_SUCCESS:
    case actionTypes.USER_ORGANIZATIONS_GET_FAILURE: {
      return { ...state, loadingOrganizations: false };
    }
    case actionTypes.REQUEST_TENANT_CREATION: {
      return { ...state, loadingTenantCreation: true };
    }
    case actionTypes.CREATE_TENANT_SUCCESS:
    case actionTypes.CREATE_TENANT_FAILURE: {
      return { ...state, loadingTenantCreation: false };
    }
    case actionTypes.REQUEST_UPDATE_SCHEMA:
    case actionTypes.REQUEST_SCHEMA_ATTRIBUTE_UPDATE:
    case actionTypes.REQUEST_CREATE_SCHEMA:
    case actionTypes.REQUEST_DELETE_SCHEMA:
    case actionTypes.REQUEST_UPDATE_GROUP:
    case actionTypes.REQUEST_DELETE_GROUP:
    case actionTypes.REQUEST_CREATE_ATTRIBUTE_GROUP:
    case actionTypes.REQUEST_UPDATE_ATTRIBUTE_GROUP:
    case actionTypes.REQUEST_DELETE_ATTRIBUTE_GROUP:
    case actionTypes.REQUEST_CREATE_ATTRIBUTE:
    case actionTypes.REQUEST_UPDATE_ATTRIBUTE:
    case actionTypes.REQUEST_DELETE_ATTRIBUTE: {
      return { ...state, loadingSchema: true };
    }
    case actionTypes.UPDATE_SCHEMA_SUCCESS:
    case actionTypes.UPDATE_SCHEMA_FAILURE:
    case actionTypes.SCHEMA_ATTRIBUTE_UPDATE_SUCCESS:
    case actionTypes.SCHEMA_ATTRIBUTE_UPDATE_FAILURE:
    case actionTypes.CREATE_SCHEMA_SUCCESS:
    case actionTypes.CREATE_SCHEMA_FAILURE:
    case actionTypes.DELETE_SCHEMA_SUCCESS:
    case actionTypes.DELETE_SCHEMA_FAILURE:
    case actionTypes.UPDATE_GROUP_SUCCESS:
    case actionTypes.UPDATE_GROUP_FAILURE:
    case actionTypes.DELETE_GROUP_SUCCESS:
    case actionTypes.DELETE_GROUP_FAILURE:
    case actionTypes.CREATE_ATTRIBUTE_GROUP_SUCCESS:
    case actionTypes.CREATE_ATTRIBUTE_GROUP_FAILURE:
    case actionTypes.UPDATE_ATTRIBUTE_GROUP_SUCCESS:
    case actionTypes.UPDATE_ATTRIBUTE_GROUP_FAILURE:
    case actionTypes.DELETE_ATTRIBUTE_GROUP_SUCCESS:
    case actionTypes.DELETE_ATTRIBUTE_GROUP_FAILURE:
    case actionTypes.CREATE_ATTRIBUTE_SUCCESS:
    case actionTypes.CREATE_ATTRIBUTE_FAILURE:
    case actionTypes.UPDATE_ATTRIBUTE_SUCCESS:
    case actionTypes.UPDATE_ATTRIBUTE_FAILURE:
    case actionTypes.DELETE_ATTRIBUTE_SUCCESS:
    case actionTypes.DELETE_ATTRIBUTE_FAILURE: {
      return { ...state, loadingSchema: false };
    }
    case actionTypes.REQUEST_HISTORY: {
      return { ...state, loadingHistory: true };
    }
    case actionTypes.GET_HISTORY_SUCCESS:
    case actionTypes.GET_HISTORY_FAILURE: {
      return { ...state, loadingHistory: false };
    }
    case actionTypes.REQUEST_WORK_TEMPLATES:
    case actionTypes.REQUEST_WORK_TEMPLATE_DELETION: {
      return { ...state, loadingWorkTemplates: true };
    }
    case actionTypes.GET_WORK_TEMPLATES_SUCCESS:
    case actionTypes.GET_WORK_TEMPLATES_FAILURE:
    case actionTypes.DELETE_WORK_TEMPLATE_SUCCESS:
    case actionTypes.DELETE_WORK_TEMPLATE_FAILURE: {
      return { ...state, loadingWorkTemplates: false };
    }
    case actionTypes.REQUEST_PATCH_MEDIA: {
      return { ...state, loadingMediaRename: true };
    }
    case actionTypes.PATCH_MEDIA_SUCCESS:
    case actionTypes.PATCH_MEDIA_FAILURE: {
      return { ...state, loadingMediaRename: false };
    }
    case actionTypes.REQUEST_EXPORT_TEMPLATES: {
      return { ...state, loadingExportTemplates: true };
    }
    case actionTypes.GET_EXPORT_TEMPLATES_SUCCESS:
    case actionTypes.GET_EXPORT_TEMPLATES_FAILURE: {
      return { ...state, loadingExportTemplates: false };
    }
    case actionTypes.REQUEST_EXPORT_TEMPLATE_CREATE: {
      return { ...state, loadingExportTemplateCreation: true };
    }
    case actionTypes.CREATE_EXPORT_TEMPLATE_SUCCESS:
    case actionTypes.CREATE_EXPORT_TEMPLATE_FAILURE: {
      return { ...state, loadingExportTemplateCreation: false };
    }
    case actionTypes.REQUEST_EXPORT_TEMPLATE_DETAIL: {
      return { ...state, loadingExportTemplateDetail: true };
    }
    case actionTypes.GET_EXPORT_TEMPLATE_DETAIL_SUCCESS:
    case actionTypes.GET_EXPORT_TEMPLATE_DETAIL_FAILURE: {
      return { ...state, loadingExportTemplateDetail: false };
    }
    case actionTypes.REQUEST_FULL_CATEGORY_TREE: {
      return { ...state, loadingFullCategoryTree: true };
    }
    case actionTypes.GET_FULL_CATEGORY_TREE_SUCCESS:
    case actionTypes.GET_FULL_CATEGORY_TREE_FAILURE: {
      return { ...state, loadingFullCategoryTree: false };
    }
    case actionTypes.REQUEST_ASSET_PENDING_APPROVAL: {
      return { ...state, loadingApproveAll: true };
    }
    case actionTypes.ASSET_PENDING_APPROVAL_SUCCESS:
    case actionTypes.ASSET_PENDING_APPROVAL_FAILURE: {
      return { ...state, loadingApproveAll: false };
    }
    case actionTypes.REQUEST_INVENTORY_ASSET_TREE: {
      return { ...state, loadingInventoryAssetTree: true };
    }
    case actionTypes.GET_INVENTORY_ASSET_TREE_SUCCESS:
    case actionTypes.GET_INVENTORY_ASSET_TREE_FAILURE: {
      return { ...state, loadingInventoryAssetTree: false };
    }
    default: {
      return state;
    }
  }
}
