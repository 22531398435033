import React, { useMemo, useState } from 'react';
import * as R from 'ramda';

import { Modal, TextField } from '@atom/mui';

export interface Props {
  open: boolean;
  onClose: () => void;
  handleAddList: (listName: string) => void;
  favoritesListNames: string[];
}

const CHAR_LIMIT = 40;

const NewFavoritesListModal = ({
  open,
  onClose,
  handleAddList,
  favoritesListNames,
}: Props) => {
  const [listName, setListName] = useState('');
  const handleSubmit = (): void => {
    handleAddList(listName);
    setListName('');
  };

  const isNameInUse = useMemo(() => {
    return favoritesListNames.some(
      name => name.toLowerCase() === listName.toLowerCase(),
    )
      ? 'This list name is already in use.'
      : '';
  }, [favoritesListNames, listName]);

  const hasError = !R.isEmpty(isNameInUse) || listName.length > CHAR_LIMIT;
  const helperText = isNameInUse
    ? isNameInUse
    : `${listName?.length}/${CHAR_LIMIT}`;

  const isDisabled = hasError || R.isEmpty(listName);

  return (
    <div onClick={event => event.stopPropagation()}>
      <Modal
        title="New Favorite List"
        open={open}
        onCancel={onClose}
        onConfirm={handleSubmit}
        onExited={onClose}
        data-cy="newFavoriteListModal"
        width="sx"
        disabled={isDisabled}
      >
        <TextField
          id="favorites_list_name_input"
          label="Name"
          value={listName}
          onChange={event => setListName(event.target.value)}
          helperText={helperText}
          error={hasError}
          FormHelperTextProps={{
            style: { textAlign: R.isEmpty(isNameInUse) ? 'right' : 'left' },
          }}
          required
        />
      </Modal>
    </div>
  );
};

export default NewFavoritesListModal;
