import React from 'react';

import { WorkOrderAssetTreeType } from '@atom/types/work';
import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';

import '../workOrderDetailPdfReport.css';

enum RatingLabels {
  RATING_NUMBER = 'Rating Number',
  REPAIR_PRIORITY = 'Repair Priority',
  REPAIR_RECOMMENDATIONS = 'Repair Recommendations',
}

interface Props {
  workOrderAsset: WorkOrderAssetTreeType;
}

const RecommendationTextbox = ({ workOrderAsset }: Props) => {
  const renderRatingDetails = label => {
    const ratingsElementGroups = workOrderAsset.elementGroups.filter(
      elementGroup => elementGroup.name === 'Ratings',
    );
    const elements = ratingsElementGroups[0]?.elements;

    return elements?.map(element =>
      element.attributeGroups.map(attributeGroup =>
        attributeGroup.attributes.map(
          key =>
            element.attributes[key].name === label && (
              <div>
                {attributeDisplayUtility.display(
                  element.attributes[key].value,
                  element.attributes[key].dataType,
                  element.attributes[key].unit,
                  !!element.attributes[key].changeId,
                  element.attributes[key].newValue,
                )}
              </div>
            ),
        ),
      ),
    );
  };

  return (
    <>
      <div styleName="header-title" style={{ margin: 0 }}>
        Recommendation
      </div>
      <div styleName="content row">
        <div style={{ width: '15rem' }}>
          <div styleName="table-header">{RatingLabels.RATING_NUMBER}</div>
          {renderRatingDetails(RatingLabels.RATING_NUMBER)}
        </div>
        <div style={{ width: '15rem' }}>
          <div styleName="table-header">{RatingLabels.REPAIR_PRIORITY}</div>
          {renderRatingDetails(RatingLabels.REPAIR_PRIORITY)}
        </div>
        <div style={{ width: '18.75rem' }}>
          <div styleName="table-header">
            {RatingLabels.REPAIR_RECOMMENDATIONS}
          </div>
          {renderRatingDetails(RatingLabels.REPAIR_RECOMMENDATIONS)}
        </div>
      </div>
    </>
  );
};

export default RecommendationTextbox;
