import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
import UserThumbnail from '@atom/components/common/UserThumbnail';
import InheritanceLockIcon from '@atom/components/workTemplate/InheritanceLockIcon';
import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_TASK_ASSIGNEE_DELETE } from '@atom/graph/workTemplate';
import { Icon, IconButton } from '@atom/mui';
import { TaskUser } from '@atom/types/task';
import {
  InheritedComponentType,
  WorkOrderTemplateAssigneeDeleteInput,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { isComponentInherited } from '@atom/utilities/workTemplateUtilities';

import './teamSection.css';

interface Props {
  user: TaskUser;
}

const TaskUserTile = ({ user }: Props) => {
  const { workTemplate, task, refetch } = useContext(WorkTemplateContext);

  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);

  const [taskUserRemove] = useMutation<
    { workOrderTemplateAssigneeDelete: boolean },
    { input: WorkOrderTemplateAssigneeDeleteInput }
  >(WORK_TEMPLATE_TASK_ASSIGNEE_DELETE, {
    onCompleted: () => {
      refetch();
    },
  });

  const removeUser = async () => {
    await taskUserRemove({
      variables: {
        input: {
          taskId: task.id,
          workTemplateId: workTemplate.id,
          assigneeId: user.id,
        },
      },
    });

    setRemoveModalOpen(false);
    refetch();
  };

  const userName = `${user.firstName} ${user.lastName}`;

  const isDisabled =
    workTemplate?.published || doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  const isItemInherited = isComponentInherited(
    InheritedComponentType.TASK_ASSIGNEE,
    task?.inheritedComponents || [],
    user?.id,
  );

  const actionButton = isItemInherited ? (
    <div styleName="lock-container">
      <InheritanceLockIcon
        tooltip="Inherited Team member cannot be removed or edited."
        placement="bottom-start"
      />
    </div>
  ) : isDisabled ? (
    <div />
  ) : (
    <IconButton
      onClick={() => setRemoveModalOpen(true)}
      tooltip="Remove"
      edge="end"
      disabled={isDisabled}
    >
      <Icon>close</Icon>
    </IconButton>
  );

  return (
    <>
      <div styleName="user-container">
        <div styleName="user-content-container">
          <UserThumbnail image={user.photoUrl} alt={userName} />
          <div styleName="user-details">
            <div styleName="name">{userName}</div>
          </div>
        </div>
        {actionButton}
      </div>
      <DeleteModal
        open={removeModalOpen}
        onCancel={() => setRemoveModalOpen(false)}
        onConfirm={removeUser}
        title="Remove User From Task"
        confirmText="Remove"
        content="Are you sure you want to remove this user from the task?"
      />
    </>
  );
};

export default TaskUserTile;
