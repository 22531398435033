import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { SortDirection } from '@mui/material';

import { Progress } from '@atom/components/common/mui';
import { GET_ANALYTICS } from '@atom/graph/analytics';
import {
  AnalyticsConnection,
  AnalyticsConnectionInput,
  AnalyticsSortByOptions,
} from '@atom/types/analytics';

import AnalyticsContext from './AnalyticsContext';
import AnalyticsListEmpty from './AnalyticsListEmpty';
import AnalyticsTable from './AnalyticsTable';

import './analyticsDashboard.css';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 25;

const AnalyticsList = () => {
  const { activeFolderId, triggerListRefetch } = useContext(AnalyticsContext);

  const [page, setPage] = useState<number>(INITIAL_PAGE);
  const [limit, setLimit] = useState<number>(INITIAL_LIMIT);
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [sortBy, setSortBy] = useState<AnalyticsSortByOptions>('name');

  const { data, loading, refetch } = useQuery<
    { analytics: AnalyticsConnection },
    { input: AnalyticsConnectionInput }
  >(GET_ANALYTICS, {
    variables: {
      input: {
        page,
        limit,
        sortBy,
        isAscending: sortDirection === 'asc',
        parentFolderId: activeFolderId,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    refetch();
  }, [triggerListRefetch]);

  const getSortDirection = (field: string) => {
    return field === sortBy ? sortDirection : 'desc';
  };

  const handleSortDirectionChange = (field: AnalyticsSortByOptions) => (
    newSortDirection: SortDirection,
  ) => {
    setSortBy(field);
    setSortDirection(newSortDirection);
  };

  const analyticsCards = data?.analytics?.analytics || [];
  const totalCount = data?.analytics?.totalCount || 0;

  return loading ? (
    <div styleName="loading-container">
      <Progress />
    </div>
  ) : totalCount === 0 ? (
    <AnalyticsListEmpty />
  ) : (
    <AnalyticsTable
      getSortDirection={getSortDirection}
      handleSortDirectionChange={handleSortDirectionChange}
      analyticsCards={analyticsCards}
      refetch={refetch}
      totalCount={totalCount}
      limit={limit}
      setLimit={setLimit}
      page={page}
      setPage={setPage}
    />
  );
};

export default AnalyticsList;
