import React, { useMemo } from 'react';
import * as R from 'ramda';

// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import { usePreferences } from '@atom/hooks/usePreferences';
import { useSchemas } from '@atom/hooks/useSchemas';
import { DatePicker, Icon, List, Switch, TextField } from '@atom/mui';
import { InventoryAssetDetailType } from '@atom/types/inventory';
import { WorkOrderType } from '@atom/types/work';
import {
  SearchWorkTemplate,
  WorkTemplateListItem,
} from '@atom/types/workTemplate';
import { getWorkTypeIconSvg } from '@atom/utilities/workTypeIconUtilities';

import InventoryAssetTile from '../inventoryAssetTree/InventoryAssetTile';

import './createWorkModals.css';

const { ListItem, ListItemText } = List;

interface Props {
  name: string;
  onNameChange: (name: string) => void;
  dueDate?: Date;
  onDueDateChange: (date: Date) => void;
  asset: InventoryAssetDetailType;
  onAssetChange: (asset: InventoryAssetDetailType) => void;
  onAssetViewClick: () => void;
  isLocationBased: boolean;
  onLocationBasedChange: (value: boolean) => void;
  workTemplate?: WorkTemplateListItem | SearchWorkTemplate;
  disableAssetChange?: boolean;
  requireAsset?: boolean;
}

const styles = {
  icon: {
    marginRight: '2rem',
  },
};

const CreateSingleWork = ({
  name,
  onNameChange,
  dueDate,
  onDueDateChange,
  asset,
  onAssetChange,
  onAssetViewClick,
  isLocationBased,
  onLocationBasedChange,
  workTemplate,
  disableAssetChange,
  requireAsset,
}: Props) => {
  const { schemas = [] } = useSchemas();
  const preferences = usePreferences();
  const isWorkNamingDisabled = preferences.workOrders?.workNamingDisabled;

  const workTemplateSchema = useMemo(() => {
    return schemas.find(({ id }) => id === workTemplate?.schemaId);
  }, [workTemplate]);

  const handleDueDateChange = (date?: Date) => {
    if (!date) {
      onDueDateChange(null);
    } else {
      onDueDateChange(date);
    }
  };

  const workTemplateType = R.pathOr(
    WorkOrderType.DEFAULT,
    ['type'],
    workTemplate,
  );
  const showAssetAndLocation = workTemplateType === WorkOrderType.DEFAULT;
  const schemaSubtext =
    workTemplateType === WorkOrderType.DEFAULT
      ? workTemplateSchema?.name || 'All Asset Types'
      : '';

  return (
    <>
      {workTemplate && (
        <div styleName="modal-row work-template">
          <Icon style={styles.icon}>
            <img
              src={getWorkTypeIconSvg(
                workTemplate?.isParent,
                workTemplate?.parentId,
              )}
            />
          </Icon>
          <ListItem disableGutters style={{ margin: 0, padding: 0 }}>
            <ListItemText
              style={{ margin: 0 }}
              primary={workTemplate.name}
              secondary={schemaSubtext}
            />
          </ListItem>
        </div>
      )}
      <div styleName="modal-row name">
        <Icon style={styles.icon}>
          <img src={renameIcon} />
        </Icon>
        <TextField
          required
          id="workOrderName"
          label="Work Name"
          value={name}
          onChange={event => onNameChange(event.target.value)}
          disabled={!!isWorkNamingDisabled}
        />
      </div>
      <div styleName="modal-row due-date">
        <Icon style={styles.icon}>event</Icon>
        <DatePicker
          label="Due Date"
          value={dueDate}
          onChange={handleDueDateChange}
        />
      </div>
      {showAssetAndLocation && (
        <>
          <div styleName="modal-row">
            <Icon style={styles.icon}>category</Icon>
            <InventoryAssetTile
              asset={asset}
              onClick={onAssetViewClick}
              onRemove={!disableAssetChange ? () => onAssetChange(null) : null}
              requireAsset={requireAsset}
            />
          </div>
          <div style={{ marginTop: '0.5rem' }}>
            <Switch
              checked={isLocationBased}
              onChange={() => onLocationBasedChange(!isLocationBased)}
              name="isLocationBased"
              label="Location"
              disabled={!!asset}
            />
          </div>
        </>
      )}
    </>
  );
};

export default CreateSingleWork;
