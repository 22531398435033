import React from 'react';
import { Chip as MUIChip, ChipProps as MUIChipProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

export type ChipProps = MUIChipProps;

const useStyles = makeStyles({
  label: ({ avatar }: ChipProps) => ({
    paddingLeft: !!avatar ? 0 : '0.5rem',
  }),
});

export const Chip = (props: ChipProps) => {
  const classes = useStyles(props);

  return (
    <MUIChip classes={{ label: classes.label, ...props.classes }} {...props} />
  );
};
