import React from 'react';
import { makeStyles } from '@mui/styles';

import { Icon, TextField } from '@atom/mui';
import fonts from '@atom/styles/fonts';
import { KeyPressEventType } from '@atom/types/event';

import './searchBar.css';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: fonts.md,
    height: '38px',
  },
}));

const styles = {
  close: {
    cursor: 'pointer',
  },
  textField: {
    boxSizing: 'border-box',
    padding: '0 0.625rem',
  },
  icon: {
    marginRight: '0.625rem',
  },
};

interface Props {
  query: string;
  onChange: (query: string) => void;
  onKeyPress: (event: KeyPressEventType) => void;
}

const SearchInputControl = ({ query, onChange, onKeyPress }: Props) => {
  const classes = useStyles();

  return (
    <TextField
      value={query}
      onChange={event => onChange(event.target.value)}
      placeholder="Search"
      onKeyDown={onKeyPress}
      autoFocus
      style={styles.textField}
      disableUnderline
      InputProps={{
        classes: { root: classes.root },
        startAdornment: <Icon style={styles.icon}>search</Icon>,
        endAdornment: query && (
          <Icon style={styles.close} onClick={() => onChange('')}>
            close
          </Icon>
        ),
      }}
    />
  );
};

export default SearchInputControl;
