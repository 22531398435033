import React from 'react';
import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as R from 'ramda';

import colors from '@atom/styles/colors';

// box shadow used for Button in MUI 0.4
const MUI_0_BUTTON_BOX_SHADOW =
  'rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px';

type CustomProps = {
  style?: any;
  component?: string;
};

export type ButtonProps = CustomProps & MUIButtonProps;

const useStyles = makeStyles(theme => ({
  root: ({ style, component }: ButtonProps) => ({
    borderRadius: '2px',
    minWidth: '88px',
    padding: '6px 8px',
    whiteSpace: 'nowrap',
    component,
    ...style,
  }),
  containedPrimary: {
    boxShadow: MUI_0_BUTTON_BOX_SHADOW,
    '&:hover': {
      boxShadow: MUI_0_BUTTON_BOX_SHADOW,
    },
    color: colors.neutral.white,
    backgroundColor: colors.brand.blue,
  },
  textInherit: {
    color: colors.neutral.black,
  },
  textPrimary: {
    color: colors.brand.blue,
  },
  outlinedInherit: {
    border: `1px solid ${colors.neutral.silver}`,
  },
}));

export const Button = ({ color = 'inherit', ...props }: ButtonProps) => {
  const classes = useStyles(props);

  return (
    <MUIButton
      {...R.omit(['textColor'], props)}
      classes={{
        root: classes.root,
        containedPrimary: classes.containedPrimary,
        textInherit: classes.textInherit,
        textPrimary: classes.textPrimary,
        outlinedInherit: classes.outlinedInherit,
        ...props.classes,
      }}
      color={color}
    />
  );
};

export default Button;
