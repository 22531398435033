import React, { useContext, useMemo } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import * as R from 'ramda';

import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { WorkOrder } from '@atom/types/work';
import history from '@atom/utilities/history';
import { getWorkOrderStatusCalendarColor } from '@atom/utilities/workOrderStatusUtilities';

import WorkOrdersCalendarEvent from './WorkOrdersCalendarEvent';
import WorkOrdersCalendarToolbar from './WorkOrdersCalendarToolbar';
import WorkOrdersContext from './WorkOrdersContext';

import './workOrders.css';

const styles = {
  calendar: {
    height: 'calc(100vh - 110px)',
  },
  event: {
    color: colors.neutral.dim,
    fontSize: fonts.sm,
    borderRadius: '2px',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
  },
};

const WorkCalendar = () => {
  const { workOrders, loading, input, setInput } = useContext(
    WorkOrdersContext,
  );

  const workOrdersDictionary = useMemo(() => {
    return workOrders.reduce(
      (acc: { [id: string]: WorkOrder }, workOrder) => ({
        ...acc,
        [workOrder.id]: workOrder,
      }),
      {},
    );
  }, [workOrders]);

  const localizer = useMemo(() => momentLocalizer(moment), []);

  const events = R.keys(workOrdersDictionary).map((workOrderId: string) => ({
    start: moment(workOrdersDictionary[workOrderId].dueDate).toDate(),
    end: moment(workOrdersDictionary[workOrderId].dueDate).toDate(),
    allDay: true,
    // Due to library constraints with passing props per event,
    // title is equal to the workOrder's id.
    title: workOrdersDictionary[workOrderId].id,
  }));

  const onSelectEvent = event => {
    history.push(`/workOrders/${event.title}`);
  };

  const getEventStyles = event => {
    const workOrder = workOrdersDictionary[event.title];
    const backgroundColor = getWorkOrderStatusCalendarColor(workOrder.statusId);

    return {
      style: {
        ...styles.event,
        backgroundColor,
      },
    };
  };

  return (
    <div styleName="calendar-container">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={onSelectEvent}
        style={styles.calendar}
        defaultDate={new Date(input.calendarViewDate)}
        defaultView={input.calendarView}
        onNavigate={calendarViewDate =>
          setInput({ ...input, calendarViewDate: calendarViewDate.valueOf() })
        }
        onView={calendarView => setInput({ ...input, calendarView })}
        components={{
          toolbar: props => (
            <WorkOrdersCalendarToolbar {...props} loading={loading} />
          ),
          event: props => (
            <WorkOrdersCalendarEvent
              {...props}
              workOrdersDictionary={workOrdersDictionary}
            />
          ),
        }}
        eventPropGetter={getEventStyles}
      />
    </div>
  );
};

export default WorkCalendar;
