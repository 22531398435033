import React, { useMemo, useState } from 'react';

import { Icon, IconButton } from '@atom/mui';
import { ElementGroupsTreeItem } from '@atom/types/inventory';
import { WorkOrderAssetTreeElement } from '@atom/types/work';
import { assetTreeHasDownstreamChanges } from '@atom/utilities/assetUtilities';

import ElementRow from './ElementRow';

import './workOrderAssetTree.css';

const PADDING_OFFSET = 2;

interface Props {
  elementGroup: ElementGroupsTreeItem;
  indentation: number;
}

const ElementGroupRow = ({ elementGroup, indentation }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const arrowDirection = expanded ? 'arrow_drop_down' : 'arrow_right';

  const style = {
    paddingLeft: `calc(1rem + ${PADDING_OFFSET * indentation}rem)`,
  };

  const hasChanges = useMemo(() => {
    return assetTreeHasDownstreamChanges(elementGroup);
  }, [elementGroup]);

  return (
    <>
      <div
        styleName="group-row"
        style={style}
        onClick={() => setExpanded(!expanded)}
      >
        <IconButton onClick={() => setExpanded(!expanded)}>
          <Icon>{arrowDirection}</Icon>
        </IconButton>
        <div styleName="row-name group">
          {elementGroup.name}
          {hasChanges && <div styleName="change-indicator" />}
        </div>
      </div>
      {expanded ? (
        elementGroup.elements.map((elem: WorkOrderAssetTreeElement) => {
          return (
            <ElementRow
              key={elem.id}
              element={elem}
              indentation={indentation + 1}
            />
          );
        })
      ) : (
        <div />
      )}
    </>
  );
};

export default ElementGroupRow;
