import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as R from 'ramda';

import WorkTemplateTree from '@atom/components/common/workTemplateTree/WorkTemplateTree';
import WorkOrdersContext, {
  WorkOrdersInputActionTypes,
} from '@atom/components/workOrders/WorkOrdersContext';
import { GET_WORK_ORDER_TEMPLATE_FOLDERS } from '@atom/graph/workTemplate';
import { Button, Modal, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import {
  WorkOrderTemplateFolder,
  WorkOrderTemplateFoldersConnection,
  WorkOrderTemplateFoldersConnectionInput,
} from '@atom/types/workTemplate';
import { flattenTreeChildren } from '@atom/utilities/treeUtilities';

import './workOrdersFilters.css';

const styles = {
  modal: {
    height: '50vh',
    padding: '2rem',
  },
  label: {
    fontSize: fonts.sm,
    color: colors.neutral.dim,
    marginBottom: '0.25rem',
  },
  value: {
    fontSize: fonts.md,
    color: colors.neutral.dark,
    marginBottom: '0.5rem',
  },
  button: {
    minWidth: 0,
    padding: 0,
  },
  checkbox: {
    padding: 0,
    marginRight: '1rem',
  },
};

const WorkTemplateFoldersFilter = () => {
  const { workOrdersInputCart, dispatch, filtersDisabled } = useContext(
    WorkOrdersContext,
  );

  const [selected, setSelected] = useState<WorkOrderTemplateFolder[]>([]);
  const [applied, setApplied] = useState<WorkOrderTemplateFolder[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const [getWorkTemplateFolders, { loading }] = useLazyQuery<
    { workOrderTemplateFolders: WorkOrderTemplateFoldersConnection },
    { input: WorkOrderTemplateFoldersConnectionInput }
  >(GET_WORK_ORDER_TEMPLATE_FOLDERS, {
    onCompleted: res => {
      const workTemplateFolders = R.pathOr(
        [],
        ['workOrderTemplateFolders', 'workOrderTemplateFolders'],
        res,
      );

      setSelected(workTemplateFolders);
      setApplied(workTemplateFolders);
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (R.length(workOrdersInputCart.workTemplateFolderIds) > 0) {
      getWorkTemplateFolders({
        variables: {
          input: {
            ids: workOrdersInputCart.workTemplateFolderIds,
          },
        },
      });
    }
  }, [workOrdersInputCart.workTemplateFolderIds]);

  useEffect(() => {
    const workTemplateFolders = selected.filter(({ id }) =>
      workOrdersInputCart?.workTemplateIds?.includes(id),
    );

    setSelected(workTemplateFolders);
    setApplied(workTemplateFolders);
  }, [workOrdersInputCart.workTemplateFolderIds]);

  const handleToggle = (folder?: WorkOrderTemplateFolder) => {
    const isSelected = selected.some(({ id }) => folder.id === id);

    setSelected(
      isSelected
        ? selected.filter(({ id }) => id !== folder.id)
        : [...selected, folder],
    );
  };

  const getTopLevelFolders = (
    folders: WorkOrderTemplateFolder[],
  ): WorkOrderTemplateFolder[] => {
    const subfolders = folders.reduce((prev, folder) => {
      return R.uniq([...prev, ...flattenTreeChildren(folder)]);
    }, []);

    return R.without(
      subfolders.map(({ id }) => id),
      folders.map(({ id }) => id),
    );
  };

  const handleSave = () => {
    const rootSelected = selected.some(folder => folder.id === 'workTypes');
    const workTemplateFolderIds = rootSelected
      ? ['workTypes']
      : getTopLevelFolders(selected);

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_INPUT_PROPERTY,
      data: {
        property: 'workTemplateFolderIds',
        value: workTemplateFolderIds,
      },
    });

    setOpen(false);
  };

  const handleReset = () => {
    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_INPUT_PROPERTY,
      data: {
        property: 'workTemplateFolderIds',
        value: [],
      },
    });
  };

  const handleClear = () => {
    setSelected([]);
  };

  const handleCancel = () => {
    setSelected(applied);
    setOpen(false);
  };

  const selectedIds = useMemo(() => {
    return new Set(selected.map(({ id }) => id));
  }, [selected]);

  return (
    <>
      <div style={styles.label}>Work Template Folder</div>
      <div style={styles.value}>
        {loading && (
          <Progress size={20} style={{ justifyContent: 'flex-start' }} />
        )}
        {applied
          .filter(({ id }) =>
            workOrdersInputCart?.workTemplateFolderIds?.includes(id),
          )
          .map(({ name }) => name)
          .join(', ')}
      </div>
      {R.length(workOrdersInputCart.workTemplateFolderIds) > 0 && (
        <Button
          style={{ ...styles.button, marginRight: '1rem' }}
          size="small"
          disabled={filtersDisabled || loading}
          onClick={handleReset}
          data-cy="workFilterFolderClear"
        >
          clear
        </Button>
      )}
      <Button
        style={styles.button}
        size="small"
        onClick={() => setOpen(true)}
        disabled={filtersDisabled || loading}
        data-cy="workFilterFolderSelect"
      >
        select
      </Button>
      <Modal
        title="Select Work Template Folder"
        open={open}
        onCancel={handleCancel}
        onConfirm={handleSave}
        onClear={handleClear}
        contentStyle={styles.modal}
        confirmButtonText="Ok"
      >
        <WorkTemplateTree
          onFolderToggle={template => handleToggle(template)}
          getFolderSelected={folder => selectedIds.has(folder.id)}
          autoSelectChildFolders
          hideTemplates
          includeSearch={false}
        />
      </Modal>
    </>
  );
};

export default WorkTemplateFoldersFilter;
