import React, { useState } from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  AttributesType,
  DetailAttributeGroupsItem,
} from '@atom/types/inventory';

import AttributeRow from './AttributeRow';

import './inventoryExportDetail.css';

interface Props {
  attributeGroup: DetailAttributeGroupsItem;
  attributeFilters: { [key: string]: string[] };
  selectedAttributes: Set<string>;
  onAttributeToggle: (id: string) => void;
  onAttributeFiltersChange: (id: string, filters: string[]) => void;
  isDisabled: boolean;
}

const AttributeGroupRow = ({
  attributeGroup,
  attributeFilters,
  selectedAttributes,
  onAttributeToggle,
  onAttributeFiltersChange,
  isDisabled,
}: Props) => {
  const [open, toggleOpen] = useState(true);

  const icon = open ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
  const openStyle = open ? 'attribute-section open' : 'attribute-section';

  return (
    <>
      <div styleName="attribute-group-header">
        <div>{attributeGroup.name}</div>
        <IconButton onClick={() => toggleOpen(!open)}>
          <Icon color={colors.neutral.gray}>{icon}</Icon>
        </IconButton>
      </div>
      <div styleName={openStyle}>
        {attributeGroup.attributes.map((attribute: AttributesType) => (
          <AttributeRow
            key={attribute.id}
            attribute={attribute}
            filters={attributeFilters[attribute.id] || []}
            onAttributeToggle={onAttributeToggle}
            onAttributeFiltersChange={onAttributeFiltersChange}
            isChecked={selectedAttributes.has(attribute.id)}
            isDisabled={isDisabled}
          />
        ))}
      </div>
    </>
  );
};

export default AttributeGroupRow;
