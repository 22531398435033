import { InventoryCategoryTree } from '@atom/types/inventory';

type CategoryPathAcc = {
  categories: InventoryCategoryTree[];
  names: string[];
};

// returns the category path as names when given
// category path of ids
export const getCategoryPathNames = (
  tree: InventoryCategoryTree,
  categoryPath: string[],
): string[] => {
  if (!tree || !categoryPath || !categoryPath.length) {
    return [];
  }

  const { names } = categoryPath.reduce(
    (acc: CategoryPathAcc, id) => {
      const match = acc.categories.find(category => category.id === id);

      return match
        ? {
            categories: match.categories,
            names: [...acc.names, match.name],
          }
        : acc;
    },
    { categories: [tree], names: [] },
  );

  return names;
};

// recursively searches the category tree for category with the given id
export const getCategoryById = (
  id: string,
  trees: InventoryCategoryTree[],
): InventoryCategoryTree => {
  if (!id || !trees || !trees.length) {
    return null;
  }

  for (let index = 0; index < trees.length; index++) {
    const tree = trees[index];

    if (tree.id === id) {
      return tree;
    }

    const category = getCategoryById(id, tree?.categories || []);

    if (category) {
      return category;
    }
  }

  return null;
};

export const getCategoryDescendantSchemaIds = (
  trees: InventoryCategoryTree[],
): string[] => {
  if (!trees || !trees.length) {
    return [];
  }

  return trees.reduce((acc, tree) => {
    return [
      ...acc,
      ...(tree.schemaId ? [tree.schemaId] : []),
      ...getCategoryDescendantSchemaIds(tree.categories || []),
    ];
  }, []);
};

// recursively compiles all categories matching the given ids
export const getCategoriesByIds = (
  ids: string[],
  trees: InventoryCategoryTree[],
): InventoryCategoryTree[] => {
  if (!ids || !ids.length || !trees || !trees.length) {
    return [];
  }

  return trees.reduce((acc, tree) => {
    return [
      ...acc,
      ...(ids.includes(tree.id) ? [tree] : []),
      ...getCategoriesByIds(ids, tree.categories),
    ];
  }, []);
};

// getAllDescendantCategoryIds returns all descendant category ids for the given
// category tree node
export const getAllDescendantCategoryIds = (tree: InventoryCategoryTree) =>
  !tree || !tree.categories
    ? []
    : tree.categories.reduce(
        (acc: string[], category): string[] => [
          ...acc,
          category.id,
          ...getAllDescendantCategoryIds(category),
        ],
        [],
      );
