import { PolicyAction, PolicyGrant } from '@atom/types/policy';
import { TimeEntryType } from '@atom/types/preferences';

export interface TimeEntryBudget {
  id: string;
  name: string;
  rate: number;
  restored: boolean;
  reopenedWorkOrderIds: string[];
}

export enum TimeEntryStatus {
  draft = 'draft',
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
}

export interface TimeEntryUserGroup {
  id: string;
  name: string;
  groupPath: string[];
}

export interface TimeEntryTask {
  id: string;
  name: string;
  isCompleted?: boolean;
}

export interface TimeEntryComputedBudget {
  id: string;
  name: string;
  rate: number;
  duration: number;
  restored: boolean;
  reopenedWorkOrderIds: string[];
}

export interface TimeEntryWorkOrder {
  id: string;
  name: string;
  priorityId: number;
  statusId: string;
  inventoryAssetId?: string;
  inventoryAssetName?: string;
  workTemplateId?: string;
  reopened: boolean;
  workTemplate?: {
    name: string;
  };
  dueDate?: number;
  completionDate?: number;
  completedBy?: {
    firstName: string;
    lastName: string;
  };
  createdBy?: {
    id: string;
    firstName: string;
    lastName: string;
  };
  tasks: TimeEntryTask[];
  workTemplateFolderActions?: PolicyAction[];
  workTemplateFolderGrants?: PolicyGrant[];
}

export interface TimeEntryDetail {
  id: string;
  status: TimeEntryStatus;
  userId: string;
  user: {
    firstName: string;
    lastName: string;
  };
  type?: TimeEntryType;
  userGroupId?: string;
  userGroup?: TimeEntryUserGroup;
  workOrderId: string;
  workOrder: TimeEntryWorkOrder;
  taskId: string;
  task: TimeEntryTask;
  duration: number;
  date: number;
  budget: TimeEntryBudget;
  computedBudgets: TimeEntryComputedBudget[];
  createdBy: {
    firstName: string;
    lastName: string;
  };
  updatedBy: {
    firstName: string;
    lastName: string;
  };
  createdDate: number;
  updatedDate: number;
}

export interface TimeEntrySummaryDetail {
  title: string;
  duration: number;
}

export interface DateDictionary {
  [key: number]: {
    totalDuration: number;
    computedBudgets: TimeEntryComputedBudget[];
  };
}

export interface CondensedTimeEntryDetail
  extends Omit<
    TimeEntryDetail,
    | 'date'
    | 'duration'
    | 'createdBy'
    | 'createdDate'
    | 'updatedBy'
    | 'updatedDate'
  > {
  dateDictionary: DateDictionary;
}

export type WorkOrderTimeEntry = Omit<TimeEntryDetail, 'workOrder' | 'task'>;

export interface TimeEntriesConnectionInput {
  userId?: string;
  workOrderId?: string;
  taskId?: string;
  userGroupId?: string;
  dateStart?: number;
  dateEnd?: number;
  page?: number;
  limit?: number;
  statuses?: TimeEntryStatus[];
}

export interface TimeEntriesDailyTotal {
  duration: number;
  date: number;
}

export interface TimeEntriesConnection {
  totalCount: number;
  status: TimeEntryStatus;
  totalDuration: number;
  totalWorkTypes: number;
  totalWorkOrders: number;
  totalOpenWorkOrders: number;
  dailyTotals: TimeEntriesDailyTotal[];
  timeEntries: TimeEntryDetail[];
  summary?: TimeEntrySummaryDetail[];
}

export interface TimeEntriesTotalDurationInput {
  dateStart: number;
  dateEnd: number;
}

export interface TimeEntriesStatusConnection {
  totalCount: number;
  status: TimeEntryStatus;
}

export interface WorkOrderTimeEntriesConnection {
  totalCount: number;
  timeEntries: WorkOrderTimeEntry[];
}

export interface TimeEntryCreateInput {
  userId: string;
  workOrderId: string;
  taskId: string;
  userGroupId?: string;
  budgetId?: string;
  date?: number;
  duration?: number;
  type?: TimeEntryType;
}

export interface TimeEntryUpdate {
  id: string;
  status?: TimeEntryStatus;
  userGroupId?: string;
  budgetId?: string;
  date?: number;
  duration?: number;
  type?: TimeEntryType;
}

export interface TimeEntriesSubmitByUserInput {
  dateStart: number;
  dateEnd: number;
}

export interface TimeEntriesUpdateByApproverInput {
  status: TimeEntryStatus;
  dateStart: number;
  dateEnd: number;
  userGroupId: string;
  userIds?: string[];
}
