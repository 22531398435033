import React, { useRef, useState } from 'react';

import { Icon, IconButton, Popover } from '@atom/mui';
import colors from '@atom/styles/colors';
import { UserGroupTreeType } from '@atom/types/userGroups';

import AddUsersToGroupModal from './AddUsersToGroupModal';
import CreateEditUserGroupModal from './CreateEditUserGroupModal';

import './teamControls.css';

const styles = {
  icon: {
    marginRight: '5px',
  },
};

interface Props {
  userGroup: UserGroupTreeType;
}

const TeamCreateControls = ({ userGroup }: Props) => {
  const anchor = useRef();

  const [open, setOpen] = useState<boolean>(false);
  const [userGroupOpen, setUserGroupOpen] = useState<boolean>(false);
  const [userOpen, setUserOpen] = useState<boolean>(false);

  const handleUserGroupClick = () => {
    setOpen(false);
    setUserGroupOpen(true);
  };

  const handleUserClick = () => {
    setOpen(false);
    setUserOpen(true);
  };

  const isRoot = userGroup.id === 'root';

  return (
    <>
      <div ref={anchor}>
        <IconButton onClick={() => setOpen(true)}>
          <Icon color={colors.neutral.gray}>add</Icon>
        </IconButton>
      </div>
      <Popover
        open={open}
        anchorEl={anchor.current}
        onClose={() => setOpen(false)}
      >
        <div styleName="menu-row" onClick={handleUserGroupClick}>
          <Icon color={colors.neutral.gray} style={styles.icon}>
            group_add
          </Icon>
          <div>Create Group</div>
        </div>
        {!isRoot && (
          <div styleName="menu-row" onClick={handleUserClick}>
            <Icon color={colors.neutral.gray} style={styles.icon}>
              person_add
            </Icon>
            <div>Add Users To Group</div>
          </div>
        )}
      </Popover>
      <CreateEditUserGroupModal
        open={userGroupOpen}
        onClose={() => setUserGroupOpen(false)}
        groupId={userGroup.id}
      />
      <AddUsersToGroupModal
        open={userOpen}
        onClose={() => setUserOpen(false)}
        userGroup={userGroup}
      />
    </>
  );
};

export default TeamCreateControls;
