import React, { useEffect, useState } from 'react';

import { Select } from '@atom/mui';
import { TaskField } from '@atom/types/task';

const { MenuItem } = Select;

interface Props {
  field: TaskField;
  onChange: (fieldId: string, value: any) => void;
  isDisabled?: boolean;
  previewView?: boolean;
  error?: boolean;
  helperText?: string;
  onClick?: () => void;
  taskId?: string;
}

const TaskEnumMultipleField = ({
  field,
  onChange,
  isDisabled,
  previewView,
  error,
  helperText,
  onClick,
}: Props) => {
  const [value, setValue] = useState<string[]>([]);

  const handleChange = (event: any) => {
    setValue(event.target.value);
    onChange(field.id, event.target.value);
  };

  useEffect(() => {
    setValue(field?.value || []);
  }, [field?.value]);

  const styles = {
    select: {
      flex: previewView ? 1 : 0.66,
    },
  };

  const title = field.required ? `* ${field.title}` : field.title;

  return (
    <>
      <Select
        multiple
        id={field.id}
        style={styles.select}
        value={value}
        label={title}
        onChange={handleChange}
        disabled={isDisabled}
        error={error}
        helperText={helperText}
        onClick={onClick}
      >
        {field.enumeration.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default TaskEnumMultipleField;
