import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';

import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { ATTRIBUTE_UPDATE } from '@atom/graph/schema';
import { Checkbox } from '@atom/mui';
import {
  AttributeUpdateInput,
  SchemaTree,
  SchemaTreeAttribute,
} from '@atom/types/schema';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import { updatePendingCreation } from '../subItemDetail/subItemDetailUtilities';

import {
  FILTERABLE_DATA_TYPES,
  mergePendingChanges,
  updatePendingUpdates,
} from './attributeDetailUtilities';

import './attributeDetail.css';

const ControlsSection = () => {
  const {
    schemaTree,
    selectedAttribute,
    setSelectedAttribute,
    selectedAttributeRoute,
    selectedSubItem,
    refetchSchemaTree,
    pendingUpdates,
    setPendingUpdates,
    setPendingCreations,
    selectedSubItemPath,
  } = useContext(SchemaDetailContext);

  // If pending changes exist, they will be merged with the
  // selected attribute data to display
  const mergedAttribute = mergePendingChanges(
    selectedSubItem.id,
    selectedAttribute,
    pendingUpdates,
  );

  const [updateAttribute] = useMutation<
    { attributeUpdate: SchemaTree },
    { input: AttributeUpdateInput }
  >(ATTRIBUTE_UPDATE);

  const handleAttributeUpdate = async (payload: object) => {
    await updateAttribute({
      variables: {
        input: {
          schemaId: selectedSubItem.id,
          attributeGroupId: selectedAttributeRoute.attributeGroupId,
          attributeId: mergedAttribute.id,
          ...payload,
        },
      },
    });

    refetchSchemaTree();
  };

  // If attribute is a temp attribute, values must be updated in pendingCreations state
  const handlePendingCreationUpdate = (
    payload: Partial<SchemaTreeAttribute>,
  ) => {
    const updatedAttribute = { ...selectedAttribute, ...payload };

    setPendingCreations(prev =>
      updatePendingCreation(
        selectedSubItem.id,
        selectedAttributeRoute,
        prev,
        updatedAttribute,
      ),
    );

    setSelectedAttribute(updatedAttribute);
  };

  // If schema is published, changes must be saved to the pending updates cart
  const handlePendingAttributeUpdate = (
    property: keyof SchemaTreeAttribute,
    value: any,
  ) => {
    setPendingUpdates(prev =>
      updatePendingUpdates(
        selectedSubItem.id,
        prev,
        mergedAttribute,
        property,
        value,
      ),
    );
  };

  const toggleFilterable = async () => {
    const isFilterable = !mergedAttribute.isFilterable;

    if (selectedAttribute.isTempAttribute) {
      handlePendingCreationUpdate({ isFilterable });
    } else if (schemaTree.isPublished) {
      handlePendingAttributeUpdate('isFilterable', isFilterable);
      return;
    } else {
      setSelectedAttribute(prev => ({ ...prev, isFilterable }));
      await handleAttributeUpdate({ isFilterable });
    }
  };

  const toggleVisibleAsSubtext = async () => {
    const isVisibleAsSubtext = !mergedAttribute.isVisibleAsSubtext;

    if (selectedAttribute.isTempAttribute) {
      handlePendingCreationUpdate({ isVisibleAsSubtext });
    } else if (schemaTree.isPublished) {
      handlePendingAttributeUpdate('isVisibleAsSubtext', isVisibleAsSubtext);
      return;
    } else {
      setSelectedAttribute(prev => ({ ...prev, isVisibleAsSubtext }));
      await handleAttributeUpdate({ isVisibleAsSubtext });
    }
  };

  // isVisibleAsSubtext can only be set on attributes on sub items
  const showVisibleAsSubtext = !isNilOrEmpty(selectedSubItemPath);
  const showFilterable = FILTERABLE_DATA_TYPES.has(mergedAttribute.dataType);
  const hideFullSection = !showVisibleAsSubtext && !showFilterable;

  return hideFullSection ? (
    <div />
  ) : (
    <div styleName="attribute-section-controls">
      <div styleName="section-header">Controls</div>
      {showFilterable && (
        <Checkbox
          checked={mergedAttribute.isFilterable}
          onChange={toggleFilterable}
          label="Filterable"
        />
      )}
      {showVisibleAsSubtext && (
        <Checkbox
          checked={mergedAttribute.isVisibleAsSubtext}
          onChange={toggleVisibleAsSubtext}
          label={
            <div>
              <div>Display as subtext</div>
              <div styleName="label-subtext">
                Selected subtext display items cannot exceed 15 characters per
                item.
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default ControlsSection;
