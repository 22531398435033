import * as R from 'ramda';
import { call, fork, put, takeEvery, throttle } from 'redux-saga/effects';
import uuid from 'uuid/v4';

import actionTypes from '@atom/actions/actionTypes';
import {
  clearFormTemplateSuccess,
  createFormTemplateFailure,
  createFormTemplateSuccess,
  deleteFormTemplateFailure,
  deleteFormTemplateSuccess,
  duplicateFormTemplateFailure,
  duplicateFormTemplateSuccess,
  getFormTemplateFailure,
  getFormTemplatesFailure,
  getFormTemplatesSuccess,
  getFormTemplatesTotalSuccess,
  getFormTemplateSuccess,
  renameFormTemplateFailure,
  renameFormTemplateSuccess,
  updateFormTemplateFailure,
  updateFormTemplateSuccess,
} from '@atom/actions/formTemplateActions';
import { Snackbar } from '@atom/mui';
import accountUtilities from '@atom/utilities/accountUtilities';
import api from '@atom/utilities/api';
import { FORM_TEMPLATES_ENDPOINT } from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';
import { TOTAL_COUNT_HEADER } from '@atom/utilities/requestUtilities';

export function* createFormTemplate(action: any): any {
  try {
    const body = {
      ...action.data,
      pages: [
        {
          id: uuid(),
          name: 'Page 1',
          fields: {},
          order: [],
        },
      ],
    };

    const { data } = yield call(api.post, FORM_TEMPLATES_ENDPOINT, body);
    // @ts-ignore
    yield call(history.push, `/form-builder/${data.id}`);

    Snackbar.info({
      message: `Form Created: ${data.name}`,
    });
    yield put(createFormTemplateSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      createFormTemplateFailure,
    );
  }
}

export function* duplicateFormTemplate(action: any): any {
  try {
    const getEndpoint = `${FORM_TEMPLATES_ENDPOINT}/${action.data}`;
    const { data: originalFormTemplate } = yield call(api.get, getEndpoint);

    const body = {
      name: `Copy of ${originalFormTemplate.name}`,
      pages: originalFormTemplate.pages,
      ...(originalFormTemplate.schemaId
        ? {
            schemaId: originalFormTemplate.schemaId,
          }
        : {}),
    };

    const { data: newFormTemplate } = yield call(
      api.post,
      FORM_TEMPLATES_ENDPOINT,
      body,
    );

    // @ts-ignore
    yield call(history.push, `/form-builder/${newFormTemplate.id}`);

    Snackbar.info({
      message: `Form Created: ${newFormTemplate.name}`,
    });

    yield put(duplicateFormTemplateSuccess(newFormTemplate));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      duplicateFormTemplateFailure,
    );
  }
}

export function* getFormTemplate(action: any): any {
  try {
    const endpoint = `${FORM_TEMPLATES_ENDPOINT}/${action.data}`;
    const { data } = yield call(api.get, endpoint);
    yield put(getFormTemplateSuccess(data));
  } catch (error) {
    yield fork(accountUtilities.apiErrorHandler, error, getFormTemplateFailure);
  }
}

export function* getFormTemplates(action: any): any {
  try {
    const { page, limit, isPublished } = action.data;
    const params = {
      ...(page ? { page } : {}),
      ...(limit ? { limit } : {}),
      ...(!R.isNil(isPublished) ? { isPublished } : {}),
    };

    const { data, headers } = yield call(
      api.get,
      FORM_TEMPLATES_ENDPOINT,
      params,
    );
    yield put(getFormTemplatesSuccess(data));
    yield put(
      getFormTemplatesTotalSuccess(Number(headers[TOTAL_COUNT_HEADER])),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      getFormTemplatesFailure,
    );
  }
}

export function* renameFormTemplate(action: any): any {
  try {
    const { id, name } = action.data;
    const endpoint = `${FORM_TEMPLATES_ENDPOINT}/${id}`;

    const body = { name };
    const { data } = yield call(api.patch, endpoint, body);
    yield put(renameFormTemplateSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      renameFormTemplateFailure,
    );
  }
}

export function* updateFormTemplate(action: any): any {
  try {
    const endpoint = `${FORM_TEMPLATES_ENDPOINT}/${action.data.id}`;
    const { data } = yield call(api.put, endpoint, action.data);
    yield put(updateFormTemplateSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      updateFormTemplateFailure,
    );
  }
}

export function* deleteFormTemplate(action: any): any {
  try {
    const endpoint = `${FORM_TEMPLATES_ENDPOINT}/${action.data}`;
    yield call(api.delete, endpoint);
    yield put(deleteFormTemplateSuccess(action.data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      deleteFormTemplateFailure,
    );
  }
}

export function* clearFormTemplate(): any {
  yield put(clearFormTemplateSuccess());
}

/* WATCHERS */
export function* requestCreateFormTemplate(): any {
  yield takeEvery(actionTypes.REQUEST_CREATE_FORM_TEMPLATE, createFormTemplate);
}

export function* requestDuplicateFormTemplate(): any {
  yield takeEvery(
    actionTypes.REQUEST_DUPLICATE_FORM_TEMPLATE,
    duplicateFormTemplate,
  );
}

export function* requestRenameFormTemplate(): any {
  yield takeEvery(actionTypes.REQUEST_RENAME_FORM_TEMPLATE, renameFormTemplate);
}

export function* requestFormTemplate(): any {
  yield takeEvery(actionTypes.REQUEST_FORM_TEMPLATE, getFormTemplate);
}

export function* requestClearFormTemplate(): any {
  yield takeEvery(actionTypes.REQUEST_CLEAR_FORM_TEMPLATE, clearFormTemplate);
}

export function* requestFormTemplates(): any {
  yield takeEvery(actionTypes.REQUEST_FORM_TEMPLATES, getFormTemplates);
}

export function* requestFormTemplateUpdate(): any {
  yield takeEvery(actionTypes.REQUEST_UPDATE_FORM_TEMPLATE, updateFormTemplate);
}

export function* requestFormTemplateDelete(): any {
  yield takeEvery(actionTypes.REQUEST_FORM_TEMPLATE_DELETE, deleteFormTemplate);
}

export function* requestFormTemplateTextUpdate(): any {
  yield throttle(
    500,
    actionTypes.REQUEST_UPDATE_FORM_TEMPLATE_TEXT,
    updateFormTemplate,
  );
}

export default [
  requestCreateFormTemplate,
  requestDuplicateFormTemplate,
  requestFormTemplate,
  requestFormTemplates,
  requestFormTemplateUpdate,
  requestFormTemplateTextUpdate,
  requestClearFormTemplate,
  requestRenameFormTemplate,
  requestFormTemplateDelete,
];
