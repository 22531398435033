import React from 'react';
import {
  LinearProgress as MUILinearProgress,
  LinearProgressProps as MUILinearProgressProps,
} from '@mui/material';

import colors from '@atom/styles/colors';

type CustomProps = {
  style?: React.CSSProperties;
  progressColor?: string;
};

export type LinearProgressProps = CustomProps & MUILinearProgressProps;

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
};

export const LinearProgress = (props: LinearProgressProps) => {
  const { style = {}, progressColor = colors.brand.blue, ...data } = props;

  return (
    <div style={{ ...styles.container, ...style }}>
      <MUILinearProgress style={{ color: progressColor }} {...data} />
    </div>
  );
};

export default LinearProgress;
