import React, { useState } from 'react';
import * as R from 'ramda';

import TaskEnumMultipleField from '@atom/components/common/workOrderDetail/taskFields/TaskEnumMultipleField';
import TaskEnumSingleField from '@atom/components/common/workOrderDetail/taskFields/TaskEnumSingleField';
import WorkOrdersFiltersDatePicker from '@atom/components/workOrders/workOrdersFilters/WorkOrdersFiltersDatePicker';
import WorkOrdersFiltersNumericRange from '@atom/components/workOrders/workOrdersFilters/WorkOrdersFiltersNumericRange';
import { Icon, IconButton } from '@atom/mui';
import { DataType } from '@atom/types/dataType';
import { TaskField } from '@atom/types/task';
import { FieldFilter } from '@atom/types/work';
import { WorkTemplate } from '@atom/types/workTemplate';
import {
  convertDateToLocalDayEnd,
  convertDateToLocalDayStart,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import { isCascadingField } from '@atom/utilities/workOrderFieldUtilities';

import { TasksAdditionalFieldFilters } from '../AssetWorkOrdersFilterPanel';

import './../assetWorkOrdersFilterPanel.css';

interface Props {
  areFiltersDisabled: boolean;
  workOrderTemplate?: WorkTemplate;
  taskFieldFilters: TasksAdditionalFieldFilters;
  updateTaskFieldFilters: (
    fieldFiltersUpdate: TasksAdditionalFieldFilters,
  ) => void;
}

const WorkTemplateTaskFieldsFilter = ({
  areFiltersDisabled,
  workOrderTemplate,
  taskFieldFilters,
  updateTaskFieldFilters,
}: Props) => {
  const [collapsed, setCollapsed] = useState<{
    [key: string]: boolean;
  }>({});

  const onCollapse = (name: string) => {
    setCollapsed({ ...collapsed, [name]: !collapsed[name] });
  };

  const updateFilterState = (
    taskId?: string,
    fieldId?: string,
    fieldProperty?: keyof FieldFilter,
    value?: number | string | string[],
  ) => {
    const updatedFieldFilters = {
      ...taskFieldFilters,
      [taskId]: {
        ...taskFieldFilters[taskId],
        [fieldId]: {
          ...taskFieldFilters[taskId]?.[fieldId],
          id: fieldId,
          [fieldProperty]: value,
        },
      },
    };

    updateTaskFieldFilters(updatedFieldFilters);
  };

  const getField = (taskId?: string, fieldId?: string): FieldFilter | null => {
    const foundField: FieldFilter | null = R.pathOr(
      null,
      [taskId, fieldId],
      taskFieldFilters,
    );

    return foundField;
  };

  const getDateValue = (
    taskId?: string,
    fieldId?: string,
    fieldProperty?: keyof FieldFilter,
  ) => {
    const foundField = getField(taskId, fieldId);

    if (foundField && !isNilOrEmpty(foundField[fieldProperty])) {
      return new Date(Number(foundField[fieldProperty]));
    }

    return null;
  };

  const handleDateChange = (
    taskId?: string,
    fieldId?: string,
    fieldProperty?: keyof FieldFilter,
  ) => (_, dateValue: Date) => {
    const dateMillis = dateValue
      ? fieldProperty === 'valueStart'
        ? convertDateToLocalDayStart(dateValue)
        : convertDateToLocalDayEnd(dateValue)
      : null;

    updateFilterState(taskId, fieldId, fieldProperty, dateMillis);
  };

  const handleDateTimeChange = (
    taskId?: string,
    fieldId?: string,
    fieldProperty?: keyof FieldFilter,
  ) => (_, dateValue: Date) => {
    const dateMillis = dateValue ? dateValue.getTime() : null;

    updateFilterState(taskId, fieldId, fieldProperty, dateMillis);
  };

  const handleSelectChange = (
    taskId?: string,
    fieldId?: string,
    fieldProperty?: keyof FieldFilter,
  ) => (_, value: any) => {
    const sanitizedValue = R.isEmpty(value) ? null : value;
    updateFilterState(taskId, fieldId, fieldProperty, sanitizedValue);
  };

  const handleNumericFieldChange = (
    taskId?: string,
    fieldId?: string,
    fieldProperty?: keyof FieldFilter,
  ) => (_, value: any) => {
    const numValue = value === '' ? null : Number(value);

    updateFilterState(taskId, fieldId, fieldProperty, numValue);
  };

  const getContent = (taskId: string, field: TaskField) => {
    if (isCascadingField(field)) {
      return <div />;
    }

    const components = {
      [DataType.DATE]: (
        <WorkOrdersFiltersDatePicker
          field={field}
          startDateValue={getDateValue(taskId, field.id, 'valueStart')}
          endDateValue={getDateValue(taskId, field.id, 'valueEnd')}
          onStartDateChange={handleDateChange(taskId, field.id, 'valueStart')}
          onEndDateChange={handleDateChange(taskId, field.id, 'valueEnd')}
          isEditable={!areFiltersDisabled}
        />
      ),
      [DataType.DATE_TIME]: (
        <WorkOrdersFiltersDatePicker
          field={field}
          startDateValue={getDateValue(taskId, field.id, 'valueStart')}
          endDateValue={getDateValue(taskId, field.id, 'valueEnd')}
          onStartDateChange={handleDateTimeChange(
            taskId,
            field.id,
            'valueStart',
          )}
          onEndDateChange={handleDateTimeChange(taskId, field.id, 'valueEnd')}
          isEditable={!areFiltersDisabled}
        />
      ),
      [DataType.ENUM_SINGLE]: (
        <TaskEnumSingleField
          key={field.id}
          field={{ ...field, value: getField(taskId, field.id)?.value }}
          onChange={handleSelectChange(taskId, field.id, 'value')}
          isDisabled={areFiltersDisabled}
        />
      ),
      [DataType.ENUM_MULTIPLE]: (
        <TaskEnumMultipleField
          key={field.id}
          field={{ ...field, value: getField(taskId, field.id)?.values }}
          onChange={handleSelectChange(taskId, field.id, 'values')}
          isDisabled={areFiltersDisabled}
        />
      ),
      [DataType.NUMBER]: (
        <WorkOrdersFiltersNumericRange
          field={field}
          startValue={getField(taskId, field.id)?.valueStart}
          endValue={getField(taskId, field.id)?.valueEnd}
          onStartChange={handleNumericFieldChange(
            taskId,
            field.id,
            'valueStart',
          )}
          onEndChange={handleNumericFieldChange(taskId, field.id, 'valueEnd')}
          isEditable={areFiltersDisabled}
        />
      ),
    };

    return components[field.dataType];
  };

  return (
    <>
      {workOrderTemplate?.tasks?.map(task =>
        !isNilOrEmpty(task?.fields) ? (
          <div key={task.id}>
            <div styleName="header filter-section-header filter-container">
              <div>{task.name}</div>
              <IconButton onClick={() => onCollapse(task.id)} edge="end">
                <Icon>
                  {collapsed[task.id]
                    ? 'keyboard_arrow_down'
                    : 'keyboard_arrow_up'}
                </Icon>
              </IconButton>
            </div>
            {!collapsed[task.id] &&
              task.fields?.map(field => (
                <div styleName="filter-container" key={field.id}>
                  {getContent(task.id, field)}
                </div>
              ))}
          </div>
        ) : (
          <div />
        ),
      )}
    </>
  );
};

export default WorkTemplateTaskFieldsFilter;
