import React, { useMemo } from 'react';

import { Avatar, Icon, Menu, Tooltip } from '@atom/mui';
import { getTaskUserStatusValues } from '@atom/selectors/taskSelectors';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { TaskUser } from '@atom/types/task';
import { UserDetail } from '@atom/types/user';
import { WorkOrderUserGroup } from '@atom/types/work';
import { getColorFromColorId } from '@atom/utilities/colorUtilities';
import { getUserFullName, sortByFullName } from '@atom/utilities/userUtilities';

import './workUsers.css';

const { MenuItem } = Menu;

const styles = {
  condensed: {
    background: colors.neutral.white,
    color: colors.neutral.gray,
    fontSize: fonts.sm,
    border: `0.5px solid ${colors.neutral.silver}`,
  },
  statusIcon: {
    position: 'absolute',
    top: '24px',
    left: '21px',
    fontSize: '10px',
    padding: '0',
  },
};

interface Props {
  users?: UserDetail[];
  userGroups?: WorkOrderUserGroup[];
  avatarSize?: number;
  showTaskUserStatus?: boolean;
}

const isUser = (user: UserDetail | WorkOrderUserGroup): user is UserDetail => {
  return user.hasOwnProperty('firstName');
};

const getAvatarStyle = (size: number) => ({
  height: size,
  width: size,
  marginRight: '5px',
});

const getUserAvatar = (
  user: UserDetail | TaskUser,
  size: number,
  showTaskUserStatus: boolean,
  tooltip: boolean,
): React.ReactNode => (
  <div styleName="thumbnail-container">
    <Tooltip title={tooltip ? getUserFullName(user) : ''} placement="bottom">
      <span>
        <Avatar
          style={getAvatarStyle(size)}
          src={user.photoUrl}
          alt={user.firstName}
        />
        {showTaskUserStatus && (
          <>
            <div styleName="status-background" />
            <Icon
              style={styles.statusIcon}
              color={getTaskUserStatusValues(user.status).color}
            >
              lens
            </Icon>
          </>
        )}
      </span>
    </Tooltip>
  </div>
);

const getUserGroupAvatar = (
  group: WorkOrderUserGroup,
  size: number,
  tooltip = true,
): React.ReactNode => (
  <Tooltip title={tooltip ? group.name : ''} placement="bottom">
    <span>
      <Avatar
        style={{
          ...getAvatarStyle(size),
          background: getColorFromColorId(group.colorId || 0),
        }}
        alt={group.name}
      >
        <Icon style={{ fontSize: 20 }} color={colors.neutral.white}>
          people
        </Icon>
      </Avatar>
    </span>
  </Tooltip>
);

const WorkUsers = ({
  users = [],
  userGroups = [],
  avatarSize = 26,
  showTaskUserStatus = false,
}: Props) => {
  // remove dupes
  const usersMap = [...users].reduce(
    (acc: { [id: string]: UserDetail }, user) => ({ ...acc, [user.id]: user }),
    {},
  );

  const sortedUsers = useMemo(() => {
    return Object.values(usersMap).sort(sortByFullName);
  }, [users]);

  const sortedUserGroups = useMemo(() => {
    // remove dupes
    const userGroupMap = [...userGroups].reduce(
      (acc: { [id: string]: WorkOrderUserGroup }, userGroup) => {
        return { ...acc, [userGroup.id]: userGroup };
      },
      {},
    );

    // eslint-disable-next-line id-length
    return Object.values(userGroupMap).sort((a, b) =>
      a.name.localeCompare(b.name),
    );
  }, [userGroups]);

  const usersAndGroups = [...sortedUserGroups, ...sortedUsers];
  const initial = usersAndGroups.slice(0, 2);
  const condensed = usersAndGroups.slice(2);

  return initial.length ? (
    <div styleName="container">
      {initial.map((userOrGroup, index) => {
        return (
          <React.Fragment key={userOrGroup.id + index}>
            {isUser(userOrGroup)
              ? getUserAvatar(userOrGroup, avatarSize, showTaskUserStatus, true)
              : getUserGroupAvatar(userOrGroup, avatarSize)}
          </React.Fragment>
        );
      })}
      {!!condensed.length && (
        <Menu
          noIconButton
          variant="menu"
          icon={
            <Avatar
              style={{ ...getAvatarStyle(avatarSize), ...styles.condensed }}
            >
              {condensed.length}
            </Avatar>
          }
        >
          {condensed.map(userOrGroup => {
            const name = isUser(userOrGroup)
              ? getUserFullName(userOrGroup)
              : userOrGroup.name;

            return (
              <MenuItem
                key={userOrGroup.id}
                startAdornment={
                  isUser(userOrGroup)
                    ? getUserAvatar(
                        userOrGroup,
                        avatarSize,
                        showTaskUserStatus,
                        false,
                      )
                    : getUserGroupAvatar(userOrGroup, avatarSize, false)
                }
              >
                {name}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </div>
  ) : (
    <div>No Assignees</div>
  );
};

export default WorkUsers;
