import React, { useEffect, useState } from 'react';
import * as R from 'ramda';

// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import { Icon, IconButton, Modal, TextField } from '@atom/mui';
import {
  AnalyticsCardCreateInput,
  AnalyticsFolderTree as FolderTree,
} from '@atom/types/analytics';
import { isNilOrEmpty, isValidUrl } from '@atom/utilities/validationUtilities';

import EmbedLinksField from './EmbedLinksField';
import SelectAnalyticsFolderTree from './SelectAnalyticsFolderTree';

import './analyticsCardModal.css';

export interface Props {
  openFolders: boolean;
  onClose: () => void;
  handleAddCard: (data: AnalyticsCardCreateInput) => void;
}

export enum CreateAnalyticsView {
  FORM = 'FORM',
  CHOOSE_FOLDER = 'CHOOSE_FOLDER',
}

export const styles = {
  fieldRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  button: {
    padding: '0',
    marginLeft: '5px',
  },
  addOptionButton: {
    marginTop: '0.5rem',
  },
};

const CreateAnalyticsCardModal = ({
  openFolders,
  onClose,
  handleAddCard,
}: Props) => {
  const [activeView, setActiveView] = useState<CreateAnalyticsView>(
    CreateAnalyticsView.FORM,
  );
  const [name, setName] = useState<string>('');
  const [embedLinks, setEmbedLinks] = useState<string[]>(['']);
  const [folder, setFolder] = useState<FolderTree>();

  useEffect(() => {
    if (openFolders) {
      setActiveView(CreateAnalyticsView.FORM);
      setName('');
      setEmbedLinks(['']);
      setFolder(null);
    }
  }, [openFolders]);

  const handleSubmit = (): void => {
    handleAddCard({ name, embedLinks, folderId: folder.id });
  };

  const disabled =
    R.isEmpty(name) ||
    isNilOrEmpty(folder) ||
    embedLinks.some(url => !isValidUrl(url));

  const title =
    activeView === CreateAnalyticsView.FORM ? (
      'Create Card'
    ) : (
      <div styleName="modal-header">
        <Icon onClick={() => setActiveView(CreateAnalyticsView.FORM)}>
          arrow_back
        </Icon>
        <div>Choose Folder</div>
      </div>
    );

  const handleSelectFolder = (selected: FolderTree) => {
    setFolder(selected);
    setActiveView(CreateAnalyticsView.FORM);
  };

  const getContent = () => {
    const views = {
      [CreateAnalyticsView.CHOOSE_FOLDER]: (
        <SelectAnalyticsFolderTree setFolder={handleSelectFolder} />
      ),
      [CreateAnalyticsView.FORM]: (
        <div styleName="form-fields-container">
          <div style={styles.fieldRow}>
            <div>
              <img src={renameIcon} />
            </div>
            <TextField
              id="card_name_input"
              label="Name"
              value={name}
              onChange={event => setName(event.target.value)}
            />
          </div>
          <EmbedLinksField
            embedLinks={embedLinks}
            setEmbedLinks={setEmbedLinks}
          />
          <div style={styles.fieldRow}>
            <div>
              <Icon>folder</Icon>
            </div>
            <div styleName="tile-container">
              {isNilOrEmpty(folder) ? (
                <div
                  styleName="choose-button"
                  onClick={() =>
                    setActiveView(CreateAnalyticsView.CHOOSE_FOLDER)
                  }
                >
                  Choose Folder *
                </div>
              ) : (
                <>
                  <div styleName="selected-folder">{folder?.name}</div>
                  <IconButton
                    style={styles.button}
                    onClick={() => setFolder(null)}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </>
              )}
            </div>
          </div>
        </div>
      ),
    };

    return views[activeView];
  };

  return (
    <Modal
      title={title}
      open={openFolders}
      onCancel={onClose}
      onConfirm={handleSubmit}
      onExited={onClose}
      data-cy="createAnalyticsCardModal"
      width="sm"
      disabled={disabled}
      disableFooter={activeView === CreateAnalyticsView.CHOOSE_FOLDER}
      confirmButtonText="CREATE"
    >
      {getContent()}
    </Modal>
  );
};

export default CreateAnalyticsCardModal;
