import React from 'react';
import * as R from 'ramda';

// @ts-ignore
import ciFormIcon from '@atom/components/common/svgIcons/ciForm.svg';
// @ts-ignore
import fhwaFormIcon from '@atom/components/common/svgIcons/fhwaForm.svg';
// @ts-ignore
import pciFormIcon from '@atom/components/common/svgIcons/pciForm.svg';
// @ts-ignore
import ciTemplateGraphic from '@atom/images/ci_template_graphic@2x.png';
// @ts-ignore
import fhwaTemplateGraphic from '@atom/images/fhwa_template_graphic@2x.png';
// @ts-ignore
import pciTemplateGraphic from '@atom/images/pci_template_graphic@2x.png';
import colors from '@atom/styles/colors';
import {
  FormFieldPageType,
  FormModuleKeys,
  FormModuleType,
  FormTemplateType,
} from '@atom/types/form';
import { InventorySchemaItem } from '@atom/types/inventory';
import { Preferences } from '@atom/types/preferences';

const formsPreferenceSelector = R.pathOr([], ['formModules']);

export const fhwaFormModule: FormModuleType = {
  icon: <img src={fhwaFormIcon} />,
  color: colors.brand.blue,
  title: 'Bridge Inspection',
  pageName: 'FHWA Inspection',
  description: 'FHWA Bridge inspection questions.',
  getLongDescription: () =>
    'This is a pre-created form page with Federal Highway Administration (FHWA) bridge inspection questions.',
  type: 'fhwa',
  previewImageSrc: fhwaTemplateGraphic,
};

export const ciFormModule: FormModuleType = {
  icon: <img src={ciFormIcon} />,
  color: colors.brand.green,
  title: 'Condition Inspection',
  pageName: 'Condition Inspection',
  description: 'Inventory condition inspection questions.',
  getLongDescription: (schemas?: InventorySchemaItem[]) => {
    const enabled = schemas
      .filter(({ ciEnabled }) => ciEnabled)
      .map(({ name }) => name)
      .sort()
      .join(', ');

    return `This condition inspection template is ready for inspections on the following inventory types: ${enabled}. Questions specific to those inventory types will populate after the form is added to work linked to the inventory item.`;
  },
  type: 'ci',
  previewImageSrc: ciTemplateGraphic,
};

export const pciFormModule: FormModuleType = {
  icon: <img src={pciFormIcon} />,
  color: colors.neutral.dim,
  title: 'Pavement Condition Inspection (PCI)',
  pageName: 'Pavement Condition Inspection',
  description: 'Pavement condition inspection questions.',
  getLongDescription: () => {
    return 'This is a form template to be used for PCI inspections on asphalt concrete (AC) and plain cement concrete (PCC) NBI. The PCI form is displayed when the surface is equal to asphalt concrete (AC) or AC over PCC and the PCC NBI form is displayed when the surface is equal to PCC NBI. In the PCI form, there are two pages for inspectors to fill out and one summary page to display averaged data. For the PCC NBI form, there is only one form.';
  },
  type: 'pci',
  previewImageSrc: pciTemplateGraphic,
};

const formModuleMap: { [key in FormModuleKeys]: FormModuleType } = {
  [FormModuleKeys.FHWA]: fhwaFormModule,
  [FormModuleKeys.CI]: ciFormModule,
  [FormModuleKeys.PCI]: pciFormModule,
  [FormModuleKeys.PCI_SUMMARY]: null,
};

export const getFormModulesList = (
  preferences: Preferences,
): FormModuleType[] => {
  const moduleList = formsPreferenceSelector(preferences);

  return moduleList.reduce((acc, key) => {
    const module = formModuleMap[key.toLowerCase()];
    return module ? [...acc, module] : acc;
  }, []);
};

export const getFormModuleByType = (type: FormModuleKeys): FormModuleType => {
  return formModuleMap[type];
};

export const getFormModuleSchema = (type: string): Object => {
  const moduleSchema = {
    fhwa: {
      type: 'fhwa',
    },
    ci: {
      type: 'ci',
    },
    pci: {
      type: 'pci',
    },
    pciSummary: {
      type: 'pciSummary',
    },
  };

  return moduleSchema[type];
};

export const isCurrentPageStandaloneModule = (
  formTemplate: FormTemplateType,
  selectedPage: number,
): boolean => {
  if (
    R.isNil(formTemplate.pages[selectedPage]) ||
    R.isEmpty(formTemplate.pages[selectedPage].order)
  ) {
    return false;
  }

  const fieldId = formTemplate.pages[selectedPage].order[0];

  return Object.values(FormModuleKeys).includes(
    // @ts-ignore
    formTemplate.pages[selectedPage].fields[fieldId].type,
  );
};

export const getCurrentPageModule = (
  formTemplate: FormTemplateType,
  selectedPage: number,
): FormModuleType | null => {
  if (!isCurrentPageStandaloneModule(formTemplate, selectedPage)) {
    return null;
  }

  if (
    R.isNil(formTemplate.pages[selectedPage]) ||
    R.isEmpty(formTemplate.pages[selectedPage].order)
  ) {
    return null;
  }

  const fieldId = formTemplate.pages[selectedPage].order[0];
  const { type } = formTemplate.pages[selectedPage].fields[fieldId];
  // @ts-ignore
  return getFormModuleByType(type);
};

export const formTemplateContainsModule = (
  type: string,
  { pages }: FormTemplateType,
) => {
  const allFields = pages.reduce(
    (acc, { fields }) => [...acc, ...R.values(fields)],
    [],
  );

  return allFields.some(field => field.type === type);
};

export const formTemplateContainsAnyModule = ({ pages }: FormTemplateType) => {
  const formModulesList = [
    FormModuleKeys.FHWA,
    FormModuleKeys.CI,
    FormModuleKeys.PCI,
    FormModuleKeys.PCI_SUMMARY,
  ];

  const allFields = pages.reduce(
    (acc, { fields }) => [...acc, ...R.values(fields)],
    [],
  );

  return allFields.some(field => formModulesList.includes(field.type));
};

export const pageContainsModule = (
  type: FormModuleKeys,
  page: FormFieldPageType,
) => Object.values(page.fields).some(field => field.type === type);

export const round = (number: number): number => {
  if (number <= 1 && number !== 0) {
    return 1;
  }

  return Math.floor(number);
};

export const equalizeFhwaValues = (attributes: Object): Object => {
  // @ts-ignore
  const { totalQuantity, cs2, cs3, cs4 } = attributes;

  if (totalQuantity === 0) {
    return {
      ...attributes,
      cs1Percent: 0,
      cs2Percent: 0,
      cs3Percent: 0,
      cs4Percent: 0,
    };
  }

  const equalizedCs1 = totalQuantity - (cs2 + cs3 + cs4);

  const newCs1 = equalizedCs1 <= 0 ? 0 : equalizedCs1;

  const newCs2Percent = round((cs2 / totalQuantity) * 100);
  const newCs3Percent = round((cs3 / totalQuantity) * 100);
  const newCs4Percent = round((cs4 / totalQuantity) * 100);
  const equalizedCs1Percent =
    100 - newCs2Percent - newCs3Percent - newCs4Percent;

  const newCs1Percent = equalizedCs1Percent <= 0 ? 0 : equalizedCs1Percent;

  return {
    cs1: round(newCs1),
    cs1Percent: newCs1Percent,
    cs2Percent: newCs2Percent,
    cs3Percent: newCs3Percent,
    cs4Percent: newCs4Percent,
  };
};

export const equalizeFhwaPercentValues = (attributes: Object): Object => {
  // @ts-ignore
  const { totalQuantity, cs2Percent, cs3Percent, cs4Percent } = attributes;

  if (totalQuantity === 0) {
    return {
      ...attributes,
      cs1Percent: 0,
      cs2Percent: 0,
      cs3Percent: 0,
      cs4Percent: 0,
    };
  }

  const equalizedCs1Percent = 100 - (cs2Percent + cs3Percent + cs4Percent);

  const newCs1Percent = equalizedCs1Percent <= 0 ? 0 : equalizedCs1Percent;

  const newCs1 = totalQuantity * (newCs1Percent / 100);
  const newCs2 = totalQuantity * (cs2Percent / 100);
  const newCs3 = totalQuantity * (cs3Percent / 100);
  const newCs4 = totalQuantity * (cs4Percent / 100);

  return {
    cs1: round(newCs1),
    cs2: round(newCs2),
    cs3: round(newCs3),
    cs4: round(newCs4),
    cs1Percent: round(newCs1Percent),
  };
};

export const isFhwaValid = (attributes: Object): boolean => {
  const {
    // @ts-ignore
    totalQuantity,
    // @ts-ignore
    cs2,
    // @ts-ignore
    cs3,
    // @ts-ignore
    cs4,
    // @ts-ignore
    cs2Percent,
    // @ts-ignore
    cs3Percent,
    // @ts-ignore
    cs4Percent,
  } = attributes;

  const isSquareFootValid = totalQuantity - (cs2 + cs3 + cs4) >= 0;

  const isPercentValid = 100 - (cs2Percent + cs3Percent + cs4Percent) >= 0;

  return isSquareFootValid && isPercentValid;
};

export const buildFhwaUpdateBody = (
  values: Object,
  element: Object,
): Object => {
  // @ts-ignore
  const { attributes } = element;

  const updatedAttributes = R.keys(attributes).reduce(
    (acc: Object, attributeName: string): Object => {
      const assetId = attributes[attributeName].id;
      const value = values[attributeName];
      return {
        ...acc,
        [assetId]: value,
      };
    },
    {},
  );

  return { attributes: R.reject(R.isNil, updatedAttributes) };
};
