import * as React from 'react';

import FontIconButton from '@atom/components/common/FontIconButton';
import TextField from '@atom/components/common/TextField';
import { Button, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { EventType } from '@atom/types/event';

import '../formInstance.css';

const styles = {
  icon: {
    padding: '0px',
    height: '24px',
    width: '24px',
  },
  dialog: {
    bodyStyle: {
      margin: 0,
      padding: 0,
    },
    title: {
      fontSize: fonts.xl,
      padding: '1.125em',
      fontWeight: '500',
    },
    actionsContainerStyle: {
      paddingRight: '1rem',
      paddingBottom: '1rem',
    },
  },
  actionButtons: {
    cancel: { marginRight: '1rem' },
  },
  floatingLabelStyle: {
    fontSize: fonts.md,
    color: colors.neutral.gray,
  },
  textFieldStyle: {
    color: colors.neutral.dark,
    fontSize: fonts.md,
  },
};

interface PassedProps {
  remarks: string;
  isEditable: boolean;
  saveRemarks: (value: string) => Promise<void>;
  hasRemarks: boolean;
}

type Props = PassedProps;

interface State {
  open: boolean;
  remarks: string;
}

class FhwaRemarksModal extends React.Component<Props, State> {
  state = {
    remarks: this.props.remarks,
    open: false,
  };

  onChange = (event: EventType) => {
    this.setState({ remarks: event.target.value });
  };

  closeModal = () => {
    const { remarks } = this.props;

    this.setState({ open: false, remarks });
  };

  openModal = () => {
    const { remarks } = this.props;

    this.setState({ open: true, remarks });
  };

  confirm = () => {
    const { saveRemarks } = this.props;
    const { remarks } = this.state;

    saveRemarks(remarks);

    this.closeModal();
  };

  render() {
    const { remarks, open } = this.state;
    const { isEditable, hasRemarks } = this.props;

    const remarksIconColor = hasRemarks
      ? colors.brand.blue
      : colors.neutral.silver;

    const getFooter = () => {
      return (
        <div style={styles.dialog.actionsContainerStyle}>
          <Button style={styles.actionButtons.cancel} onClick={this.closeModal}>
            Cancel
          </Button>
          <Button
            disabled={!isEditable}
            onClick={this.confirm}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </div>
      );
    };

    return (
      <React.Fragment>
        <FontIconButton
          style={styles.icon}
          onClick={this.openModal}
          color={remarksIconColor}
          icon="format_align_left"
        />
        <Modal
          title="Notes"
          footer={getFooter()}
          style={styles.dialog.bodyStyle}
          open={open}
        >
          <div styleName="modal-container">
            <TextField
              fullWidth
              multiLine
              rowsMax={10}
              style={styles.textFieldStyle}
              floatingLabelStyle={styles.floatingLabelStyle}
              value={remarks}
              name="remarks"
              onChange={this.onChange}
              disabled={!isEditable}
            />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
export default FhwaRemarksModal;
