import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Modal, RadioGroup } from '@atom/mui';
import { getCiModuleEmergencySelectedSelector } from '@atom/selectors/formInstanceSelectors';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { CiModuleInspection } from '@atom/types/form';

const { RadioButton } = RadioGroup;

import './ci.css';

const styles = {
  radio: {
    marginBottom: '1rem',
    fontSize: fonts.md,
  },
  subtext: {
    fontSize: fonts.sm,
    color: colors.neutral.gray,
    lineHeight: fonts.sm,
  },
};

interface Props {
  inspection: CiModuleInspection;
  open: boolean;
  onClose: () => void;
  onSubmit: (rating: string) => void;
}

const CiInspectionChoiceModal = ({
  inspection,
  open,
  onClose,
  onSubmit,
}: Props) => {
  const isEmergencySelected = useSelector(getCiModuleEmergencySelectedSelector);

  const [rating, setRating] = useState<string>('');

  useEffect(() => {
    setRating(inspection.rating || '');
  }, [inspection, open]);

  const hasEmergencyOption = !!inspection.choices.find(
    choice => choice.emergency,
  );
  const isDisabled = !hasEmergencyOption && isEmergencySelected;

  return (
    <Modal
      title="Select Condition Description"
      open={open}
      onConfirm={() => onSubmit(rating)}
      onCancel={onClose}
      confirmButtonText="Add"
      width="lg"
      disabled={!rating || isDisabled}
    >
      <div styleName="modal-description">
        Which description is most appropriate for the
        <strong> {inspection.name} </strong> condition?
      </div>
      <RadioGroup
        value={rating}
        onChange={event => setRating(event.target.value)}
      >
        {inspection.choices.map((choice, index) => (
          <RadioButton
            style={styles.radio}
            key={index}
            value={choice.rating}
            disabled={isDisabled}
            label={
              <div>
                <div>{choice.description}</div>
                <div style={styles.subtext}>{choice.rating}</div>
              </div>
            }
          />
        ))}
      </RadioGroup>
    </Modal>
  );
};

export default CiInspectionChoiceModal;
