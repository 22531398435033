export const getWorkAssetTreeById = (rootTree: any, id: string) => {
  if (rootTree.id === id) {
    return rootTree;
  }

  if (rootTree.elements?.length) {
    for (let index = 0; index < rootTree.elements.length; index++) {
      const tree = rootTree.elements[index];

      if (tree.id === id) {
        return tree;
      }

      const matching = getWorkAssetTreeById(tree, id);

      if (matching) {
        return matching;
      }
    }
  }

  if (rootTree.elementGroups?.length) {
    for (let index = 0; index < rootTree.elementGroups.length; index++) {
      const tree = rootTree.elementGroups[index];

      if (tree.id === id) {
        return tree;
      }

      const matching = getWorkAssetTreeById(tree, id);

      if (matching) {
        return matching;
      }
    }
  }

  return null;
};

export const getWorkAssetIdsWithoutWorkAncestors = (assets: {
  [key: string]: any;
}) => {
  const allAssetIds = new Set(Object.keys(assets));

  return Object.entries(assets).reduce((acc, [assetId, asset]) => {
    const isAnyAncestorPresent = asset.ancestors?.some(ancestor =>
      allAssetIds.has(ancestor.id),
    );

    return isAnyAncestorPresent ? acc : [...acc, assetId];
  }, []);
};

export const getAllWorkAssetTreesByIds = (rootTree, ids) => {
  return ids.reduce((acc, id) => {
    const tree = getWorkAssetTreeById(rootTree, id);
    return tree ? [...acc, tree] : acc;
  }, []);
};

export const getWorkAssetTreeFullPathName = (rootTree: any, id: string) => {
  if (rootTree.id === id) {
    return rootTree.name;
  }

  if (rootTree.elementGroups?.length) {
    for (let index = 0; index < rootTree.elementGroups.length; index++) {
      const tree = rootTree.elementGroups[index];
      if (tree.id === id) {
        return tree.name;
      }

      const matching = getWorkAssetTreeFullPathName(tree, id);
      if (matching) {
        return `${tree.name} / ${matching}`;
      }
    }
  }

  if (rootTree.elements?.length) {
    for (let index = 0; index < rootTree.elements.length; index++) {
      const tree = rootTree.elements[index];
      if (tree.id === id) {
        return tree.name;
      }

      const matching = getWorkAssetTreeFullPathName(tree, id);
      if (matching) {
        return `${tree.name} / ${matching}`;
      }
    }
  }

  return null;
};
