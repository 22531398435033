import * as React from 'react';

import SectionHeading from '@atom/components/common/map/mapDrawerContent/SectionHeading';
import { Task } from '@atom/types/task';

import TaskRow from './TaskRow';

import './workSummaryTray.css';

interface Props {
  tasks: Task[];
}

const WorkSummaryTasks = ({ tasks }: Props) => {
  return (
    <div styleName="task-container">
      <SectionHeading title={`${tasks.length} tasks`} />
      <div styleName="task-detail-section">
        {tasks.map(task => (
          <TaskRow key={task.id} task={task} />
        ))}
      </div>
    </div>
  );
};

export default WorkSummaryTasks;
