import { gql } from '@apollo/client';

import { BASE_ASSIGNED_ROLE } from './role';

export const BASE_USER_GROUP_FRAGMENT = gql`
  fragment BaseUserGroup on UserGroup {
    id
    name
    colorId
    hasChildGroups
    budgets {
      id
      externalId
      name
      rate
      restored
      reopenedWorkOrderIds
    }
    certifications {
      id
      name
      description
    }
    timeEntryApprovers {
      id
      firstName
      lastName
      email
      photoUrl
    }
    roles {
      ...BaseAssignedRole
    }
    groupPath
    description
    baseLocation
    organization
    appropriation
    createdBy {
      firstName
      lastName
    }
    updatedBy {
      firstName
      lastName
    }
    createdDate
    updatedDate
  }
  ${BASE_ASSIGNED_ROLE}
`;

export const GET_USER_GROUP_TREE = gql`
  query userGroupsTree {
    userGroupsTree {
      id
      name
      userGroups
    }
  }
`;

export const GET_USER_GROUP = gql`
  query userGroup($id: ID!, $input: UserGroupInput) {
    userGroup(id: $id, input: $input) {
      ...BaseUserGroup
    }
  }
  ${BASE_USER_GROUP_FRAGMENT}
`;

export const GET_USER_GROUPS = gql`
  query userGroups($input: UserGroupsConnectionInput!) {
    userGroups(input: $input) {
      totalCount
      userGroups {
        ...BaseUserGroup
      }
    }
  }
  ${BASE_USER_GROUP_FRAGMENT}
`;

export const GET_USERS_AND_USER_GROUPS = gql`
  query userGroup($id: ID!, $input: UserGroupChildrenConnectionInput!) {
    userGroup(id: $id) {
      id
      name
      children(input: $input) {
        totalCount
        userGroupChildren {
          id
          type
          name
          colorId
          photoUrl
          isPending
          level
          budgets {
            id
            name
            rate
            restored
            reopenedWorkOrderIds
          }
          roles {
            ...BaseAssignedRole
          }
          inheritedRoles {
            ...BaseAssignedRole
          }
          updatedBy {
            firstName
            lastName
          }
          updatedDate
        }
      }
    }
  }
  ${BASE_ASSIGNED_ROLE}
`;

export const CREATE_USER_GROUP = gql`
  mutation userGroupCreate($input: UserGroupCreateInput!) {
    userGroupCreate(input: $input) {
      ...BaseUserGroup
    }
  }
  ${BASE_USER_GROUP_FRAGMENT}
`;

export const UPDATE_USER_GROUP = gql`
  mutation userGroupUpdate($input: UserGroupUpdateInput!) {
    userGroupUpdate(input: $input) {
      ...BaseUserGroup
    }
  }
  ${BASE_USER_GROUP_FRAGMENT}
`;

export const DELETE_USER_GROUP = gql`
  mutation userGroupDelete($id: ID!) {
    userGroupDelete(id: $id)
  }
`;

export const CREATE_USER_GROUP_BUDGET = gql`
  mutation userGroupBudgetCreate($input: UserGroupBudgetCreateInput!) {
    userGroupBudgetCreate(input: $input) {
      ...BaseUserGroup
    }
  }
  ${BASE_USER_GROUP_FRAGMENT}
`;

export const UPDATE_USER_GROUP_BUDGET = gql`
  mutation userGroupBudgetUpdate($input: UserGroupBudgetUpdateInput!) {
    userGroupBudgetUpdate(input: $input) {
      ...BaseUserGroup
    }
  }
  ${BASE_USER_GROUP_FRAGMENT}
`;

export const DELETE_USER_GROUP_BUDGET = gql`
  mutation userGroupBudgetDelete($input: UserGroupBudgetDeleteInput!) {
    userGroupBudgetDelete(input: $input) {
      ...BaseUserGroup
    }
  }
  ${BASE_USER_GROUP_FRAGMENT}
`;

export const CREATE_USER_GROUP_CERTIFICATION = gql`
  mutation userGroupCertificationCreate(
    $input: UserGroupCertificationCreateInput!
  ) {
    userGroupCertificationCreate(input: $input) {
      ...BaseUserGroup
    }
  }
  ${BASE_USER_GROUP_FRAGMENT}
`;

export const UPDATE_USER_GROUP_CERTIFICATION = gql`
  mutation userGroupCertificationUpdate(
    $input: UserGroupCertificationUpdateInput!
  ) {
    userGroupCertificationUpdate(input: $input) {
      ...BaseUserGroup
    }
  }
  ${BASE_USER_GROUP_FRAGMENT}
`;

export const DELETE_USER_GROUP_CERTIFICATION = gql`
  mutation userGroupCertificationDelete(
    $input: UserGroupCertificationDeleteInput!
  ) {
    userGroupCertificationDelete(input: $input) {
      ...BaseUserGroup
    }
  }
  ${BASE_USER_GROUP_FRAGMENT}
`;

export const ADD_USERS_TO_USER_GROUP = gql`
  mutation userGroupUsersAdd($input: UserGroupUsersAddInput!) {
    userGroupUsersAdd(input: $input) {
      id
    }
  }
`;
