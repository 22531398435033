import React from 'react';
import * as R from 'ramda';
import uuid from 'uuid/v4';

import { Button, Icon, IconButton, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { SubField, ValueDictionary } from '@atom/types/work';
import {
  initialNestedField,
  mapDropdownField,
  removeSubFieldByMatchValue,
} from '@atom/utilities/workOrderFieldUtilities';

import './customFieldEnumEdit.css';

const MIN_ENUMERATION = 2;

const styles = {
  button: {
    padding: '0',
    marginLeft: '5px',
  },
  addOptionButton: {
    marginTop: '0.5rem',
  },
};

interface Props {
  valueDictionary: ValueDictionary;
  enumeration: string[];
  subFields: SubField[];
  setEnumeration: (enumeration: string[]) => void;
  setValueDictionary: (valueDictionary: ValueDictionary) => void;
  setSubFields: (subFields: SubField[]) => void;
  setActiveKey: (activeKey: string) => void;
}

const CustomFieldEnumRootEdit = ({
  valueDictionary,
  setValueDictionary,
  enumeration,
  setEnumeration,
  subFields,
  setSubFields,
  setActiveKey,
}: Props) => {
  const addOption = () => {
    const id = uuid();

    setEnumeration([...enumeration, id]);
    setValueDictionary({
      ...valueDictionary,
      [id]: '',
    });
  };

  const updateOption = (event, key: string) => {
    setValueDictionary({
      ...valueDictionary,
      [key]: event.target.value,
    });
  };

  const removeOption = (key: string) => {
    setEnumeration(R.remove(enumeration.indexOf(key), 1, enumeration));

    setValueDictionary(R.omit([key], valueDictionary));

    setSubFields(removeSubFieldByMatchValue(subFields, key));
  };

  const addSubField = (key: string) => {
    const mappedField = mapDropdownField(initialNestedField);
    setValueDictionary({ ...valueDictionary, ...mappedField.valueDictionary });

    const updatedSubFields = [
      ...subFields,
      {
        matchValue: key,
        nestedField: R.omit(['valueDictionary'], mappedField),
      },
    ];

    setActiveKey(key);
    setSubFields(updatedSubFields);
  };

  return (
    <>
      {enumeration.map((key: string, index: number) => {
        const subField = R.find(R.propEq('matchValue', key))(subFields);

        return (
          <div key={key} styleName="option-input-container">
            <div styleName="option-number">{`${index + 1}.`}</div>
            <TextField
              id={key}
              value={valueDictionary[key]}
              key={key}
              onChange={event => updateOption(event, key)}
              style={{ width: '100%' }}
            />
            <IconButton
              style={styles.button}
              onClick={(): void => removeOption(key)}
              disabled={enumeration.length <= MIN_ENUMERATION}
            >
              <Icon>close</Icon>
            </IconButton>
            {!subField && (
              <IconButton
                style={styles.button}
                onClick={(): void => addSubField(key)}
                tooltip="Link Question"
              >
                <Icon>link</Icon>
              </IconButton>
            )}
            {subField && (
              <IconButton
                style={styles.button}
                onClick={() => setActiveKey(key)}
              >
                <Icon>arrow_forward</Icon>
              </IconButton>
            )}
          </div>
        );
      })}
      <div>
        <Button
          color="primary"
          startIcon={<Icon color={colors.brand.blue}>add</Icon>}
          onClick={addOption}
          style={styles.addOptionButton}
        >
          Add Option
        </Button>
      </div>
    </>
  );
};

export default CustomFieldEnumRootEdit;
