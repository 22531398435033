import * as React from 'react';

import { Button, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';

import { PrivacyPolicy } from '../../documents/PrivacyPolicy';
import { TermsOfService } from '../../documents/TermsOfService';

import './login.css';

const termsOfService = {
  title: 'Terms of Service',
  body: TermsOfService,
};

const privacyPolicy = {
  title: 'Privacy Policy',
  body: PrivacyPolicy,
};

interface State {
  open: boolean;
  title: string;
  body: any;
}

class LoginFooter extends React.Component<{}, State> {
  state = {
    open: false,
    title: '',
    body: null,
  };

  closeModal = () => {
    this.setState({ open: false });
  };

  openModal = message => {
    this.setState({ open: true, title: message.title, body: message.body });
  };

  render() {
    const { open, title, body } = this.state;

    const renderFooter = (
      <div>
        <Button onClick={this.closeModal}>Close</Button>
      </div>
    );

    return (
      <div styleName="login-footer">
        <span
          styleName="login-footer-element"
          onClick={() => this.openModal(termsOfService)}
        >
          Terms of Service
        </span>
        <span
          styleName="login-footer-element"
          onClick={() => this.openModal(privacyPolicy)}
        >
          Privacy Policy
        </span>
        <a
          styleName="login-footer-element"
          target="_blank"
          href="http://goatomapp.com/"
        >
          Atom Website
        </a>
        <Modal
          title={title}
          open={open}
          footer={renderFooter}
          width="lg"
          contentStyle={{ color: colors.neutral.gray }}
        >
          <span styleName="login-footer-dialog-content">{body}</span>
        </Modal>
      </div>
    );
  }
}

export default LoginFooter;
