import * as R from 'ramda';

import { SchemaTree, SelectedAttributeRoute } from '@atom/types/schema';

export const getAttributePathFromRoute = (
  subItem: Partial<SchemaTree>,
  route: SelectedAttributeRoute,
): any[] => {
  const groupIndex = R.findIndex(R.propEq('id', route.attributeGroupId))(
    subItem?.attributeGroups || [],
  );

  const group = subItem.attributeGroups[groupIndex];

  const attributeIndex = R.findIndex(R.propEq('id', route.attributeId))(
    group?.attributes || [],
  );

  return ['attributeGroups', groupIndex, 'attributes', attributeIndex];
};
