import React, { useState } from 'react';

import { TextField } from '@atom/mui';
import { WorkOrderField } from '@atom/types/work';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

const styles = {
  unitsStyle: {
    textTransform: 'uppercase',
  },
  textField: {
    flex: 0.66,
  },
};

interface Props {
  field: WorkOrderField;
  onChange: (fieldId: string, value: any) => void;
  onBlur: (fieldId: string, value: any) => void;
  isDisabled?: boolean;
}

const WorkOrderNumericField = ({
  field,
  onChange,
  onBlur,
  isDisabled,
}: Props) => {
  const [value, setValue] = useState<any>(
    !isNilOrEmpty(field?.value) ? field?.value : '',
  );

  const handleChange = event => {
    const number =
      event.target.value === '' ? '' : parseFloat(event.target.value);
    setValue(number);
    onChange(field.id, number);
  };

  const handleOnBlur = event => {
    const number =
      event.target.value === '' ? '' : parseFloat(event.target.value);

    if (field?.value !== number) {
      setValue(number);
      onBlur(field.id, number);
    }
  };

  const title = (
    <span>
      <span>{field.title}</span>
      {field?.units && (
        <span style={styles.unitsStyle}>{` (${field?.units})`}</span>
      )}
    </span>
  );

  return (
    <TextField
      id={field.id}
      style={styles.textField}
      label={title}
      type="number"
      value={value}
      disabled={isDisabled}
      onChange={handleChange}
      onBlur={handleOnBlur}
    />
  );
};

export default WorkOrderNumericField;
