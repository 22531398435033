import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
import TimeEntryStatusIcon from '@atom/components/common/timeEntryStatusIcon/TimeEntryStatusIcon';
import WorkOrderStatusEdit from '@atom/components/common/workOrderDetail/workOrderStatusEdit/WorkOrderStatusEdit';
import TeamContext from '@atom/components/teamPortal/TeamContext';
import { TIME_ENTRY_DELETE } from '@atom/graph/timeEntry';
import { ListTable, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import { TimeEntryDetail, WorkOrderTimeEntry } from '@atom/types/timeEntry';
import { timeSheetTableWidths } from '@atom/utilities/timeSheetUtilities';
import {
  convertMillisToExactHours,
  setDisplayDate,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import TimeEntryOptions from './TimeEntryOptions';

import './timeSheet.css';

const { TableRow, TableCell } = ListTable;

const styles = {
  workInfo: {
    minWidth: timeSheetTableWidths.work,
  },
  smallCell: {
    padding: '8px 4px',
  },
  start: {
    padding: '8px',
  },
};

interface Props {
  timeEntry: TimeEntryDetail;
  refetchTimeSheet: () => void;
  editTimeEntryId?: string;
  setEditTimeEntryId: (id: string) => void;
  selectedPayPeriodWeek: number;
  createTimeEntry: boolean;
}

const TimeSheetTableRow = ({
  timeEntry,
  refetchTimeSheet,
  editTimeEntryId,
  setEditTimeEntryId,
  selectedPayPeriodWeek,
  createTimeEntry,
}: Props) => {
  const { payPeriodWeeks } = useContext(TeamContext);

  const [open, setOpen] = useState<boolean>(false);

  const [timeEntryDelete, { loading: timeEntryDeleteLoading }] = useMutation<
    { timeEntryDelete: WorkOrderTimeEntry },
    { id: string }
  >(TIME_ENTRY_DELETE);

  const handleDelete = async () => {
    await timeEntryDelete({
      variables: {
        id: timeEntry.id,
      },
    });

    refetchTimeSheet();
    setOpen(false);
  };

  const currentWeekDates = payPeriodWeeks[selectedPayPeriodWeek];
  const budgetNames = timeEntry.computedBudgets
    .map(budget => `${budget.name}${budget.restored ? ' (snapshot)' : ''}`)
    .join(', ');

  const showTooltip = timeEntry.computedBudgets.length > 1;
  const isEditMode = !isNilOrEmpty(editTimeEntryId) || createTimeEntry;

  const budgetsTooltipTitle = (
    <div styleName="tooltip-container">
      {timeEntry.computedBudgets.map(budget => (
        <div key={budget.id} styleName="tooltip-row">
          <span>
            {`${budget.name}: `}
            <strong>{`${convertMillisToExactHours(
              budget.duration,
            )} Hour(s)`}</strong>
            {budget.restored && (
              <span styleName="snapshot-suffix">(snapshot)</span>
            )}
          </span>
        </div>
      ))}
    </div>
  );

  const workTimeStyle = showTooltip
    ? { borderBottom: `1px dotted ${colors.neutral.gray}` }
    : {};

  return (
    <>
      <TableRow key={timeEntry.id}>
        <TableCell
          width={timeSheetTableWidths.status}
          style={styles.start}
          center
        >
          <TimeEntryStatusIcon status={timeEntry.status} />
        </TableCell>
        <TableCell width={timeSheetTableWidths.workType}>
          {timeEntry?.workOrder?.workTemplate?.name}
        </TableCell>
        <TableCell style={styles.workInfo}>
          <div>
            <Link to={`/workOrders/${timeEntry?.workOrder?.id}`}>
              {timeEntry?.workOrder?.name}
            </Link>
            <WorkOrderStatusEdit
              workOrder={timeEntry?.workOrder}
              createdById={timeEntry?.workOrder?.createdBy?.id}
              refetch={refetchTimeSheet}
              styleType="table"
            />
            {timeEntry?.workOrder?.inventoryAssetName && (
              <div styleName="subtext">{`Inventory: ${timeEntry?.workOrder?.inventoryAssetName}`}</div>
            )}
            {!isNilOrEmpty(timeEntry?.workOrder?.dueDate) && (
              <div styleName="subtext">{`Due Date: ${setDisplayDate(
                timeEntry?.workOrder?.dueDate,
              )}`}</div>
            )}
          </div>
        </TableCell>
        <TableCell width={timeSheetTableWidths.task}>
          {timeEntry?.task?.name}
        </TableCell>
        <TableCell width={timeSheetTableWidths.userGroup}>
          {timeEntry?.userGroup?.name}
        </TableCell>
        <TableCell width={timeSheetTableWidths.budget}>{budgetNames}</TableCell>
        <TableCell
          width={timeSheetTableWidths.small}
          center
          style={styles.smallCell}
        />
        {currentWeekDates.map(day => {
          const hours =
            day === timeEntry.date
              ? convertMillisToExactHours(timeEntry.duration)
              : '';

          return (
            <TableCell
              width={timeSheetTableWidths.small}
              center
              style={styles.smallCell}
              key={day}
            >
              {showTooltip ? (
                <Tooltip lightTooltip title={budgetsTooltipTitle}>
                  <div style={workTimeStyle}>{hours}</div>
                </Tooltip>
              ) : (
                <div>{hours}</div>
              )}
            </TableCell>
          );
        })}
        <TableCell
          width={timeSheetTableWidths.small}
          center
          style={styles.smallCell}
        />
        {isEditMode && (
          <TableCell
            width={timeSheetTableWidths.action}
            style={styles.smallCell}
            center
          />
        )}
        <TableCell
          width={timeSheetTableWidths.action}
          style={styles.smallCell}
          center
        >
          <TimeEntryOptions
            setEditTimeEntryId={setEditTimeEntryId}
            timeEntry={timeEntry}
            refetchTimeSheet={refetchTimeSheet}
            disabled={isEditMode}
          />
        </TableCell>
      </TableRow>
      <DeleteModal
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={handleDelete}
        title="Delete Time Entry"
        content={<div>Are you sure you want to delete this time entry?</div>}
        loading={timeEntryDeleteLoading}
      />
    </>
  );
};

export default TimeSheetTableRow;
