import React, { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import * as R from 'ramda';

import { useUserProfile } from '@atom/hooks/useUserProfile';
import { Button, Icon, IconButton, Modal, Skeleton, Tooltip } from '@atom/mui';
import { DashboardCard } from '@atom/types/dashboard';
import {
  isNilOrEmpty,
  isValidUrlWithMessage,
} from '@atom/utilities/validationUtilities';

import CustomEmbedFormView from './CustomizeDashboardModal/CustomEmbedFormView';
import DashboardContext from './DashboardContext';

import './dashboardCard.css';

const MAX_CHAR_WIDTH_SM = 30;
const MAX_CHAR_WIDTH_LG = 90;
const TITLE_LOAD_SKELETON_WIDTH = '10rem';

interface Props {
  card: DashboardCard;
}

const blankIcon = (
  <div key="blank-icon" style={{ visibility: 'hidden' }}>
    <IconButton>
      <Icon>blank</Icon>
    </IconButton>
  </div>
);

const DashboardCardTitlebar = ({ card }: Props) => {
  const { userId } = useUserProfile();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [urlErrorMsg, setUrlErrorMsg] = useState('');
  const {
    handleDeleteEmbedCard,
    handleEditEmbedCard,
    loadingEmbedCard,
  } = useContext(DashboardContext);
  const [cardData, setCardData] = useState(card);

  const isLoading = card.contentState === 'LOADING';
  const isEditable = card.type === 'EMBED';
  const charThreshold =
    card.size === 'SM' ? MAX_CHAR_WIDTH_SM : MAX_CHAR_WIDTH_LG;
  const overflowing = card.title.length > charThreshold;
  const hasPortalLink = !isNilOrEmpty(card.portalLink);

  const closeAndEditCard = () => {
    setOpenEditModal(false);
    handleEditEmbedCard(cardData);
  };

  const portalLinkIcon = hasPortalLink ? (
    <div
      key="portal-link-icon"
      styleName="card-action-icon"
      data-cy={`dashboardCardLinkIcon_${card.title}`}
    >
      <Tooltip title="Open in Portal">
        <Link to={card.portalLink(userId)} target="_blank">
          <IconButton>
            <Icon>open_in_new</Icon>
          </IconButton>
        </Link>
      </Tooltip>
    </div>
  ) : null;

  const editIcon = isEditable ? (
    <div
      key="edit-card-icon"
      styleName="card-action-icon"
      onClick={() => setOpenEditModal(true)}
      data-cy={`dashboardCardEditIcon_${card.title}`}
    >
      <Tooltip title="Delete Card">
        <IconButton>
          <Icon>edit</Icon>
        </IconButton>
      </Tooltip>
    </div>
  ) : (
    <div />
  );

  const deleteIcon = isEditable ? (
    <div
      key="delete-card-icon"
      styleName="card-action-icon"
      onClick={() => setOpenDeleteModal(true)}
      data-cy={`dashboardCardDeleteIcon_${card.title}`}
    >
      <Tooltip title="Delete Card">
        <IconButton>
          <Icon>delete</Icon>
        </IconButton>
      </Tooltip>
    </div>
  ) : (
    <div />
  );

  const getDeleteCardFooter = () => (
    <div>
      <Button
        onClick={() => setOpenDeleteModal(false)}
        style={{ marginRight: '8px' }}
      >
        CANCEL
      </Button>
      <Button
        color="error"
        variant="contained"
        onClick={() => handleDeleteEmbedCard(card)}
      >
        DELETE
      </Button>
    </div>
  );

  const editFormIsValid = useMemo(() => {
    const [isURLValid, urlError] = isValidUrlWithMessage(cardData.embedLink);
    setUrlErrorMsg(urlError);
    const isHyperlinkValid = !R.isEmpty(cardData.embedLink) && isURLValid;

    return isHyperlinkValid && !R.isEmpty(cardData.title);
  }, [cardData]);

  const cardActionIcons =
    !isLoading && (isEditable || hasPortalLink)
      ? [
          ...(deleteIcon ? [deleteIcon] : []),
          ...(editIcon ? [editIcon] : []),
          ...(portalLinkIcon ? [portalLinkIcon] : []),
        ]
      : [blankIcon];

  return (
    <>
      <div styleName="card-titlebar">
        <Tooltip title={overflowing ? card.title : ''}>
          <div styleName="card-title">
            {isLoading ? (
              <Skeleton width={TITLE_LOAD_SKELETON_WIDTH} />
            ) : (
              card.title
            )}
          </div>
        </Tooltip>
        <div styleName="card-title-icons">
          {cardActionIcons.map(icon => icon)}
        </div>
      </div>
      <Modal
        title="Edit Card: Custom Embed"
        open={openEditModal}
        onCancel={() => setOpenEditModal(false)}
        onExited={() => setOpenEditModal(false)}
        onConfirm={closeAndEditCard}
        disabled={!editFormIsValid || loadingEmbedCard}
        data-cy={`dashboardCardEditModal_${card.title}`}
        confirmButtonText="SAVE"
      >
        <CustomEmbedFormView
          cardData={cardData}
          setCardData={setCardData}
          urlErrorMsg={urlErrorMsg}
        />
      </Modal>
      <Modal
        title="Delete Card?"
        open={openDeleteModal}
        onCancel={() => setOpenDeleteModal(false)}
        onExited={() => setOpenDeleteModal(false)}
        onConfirm={() => handleDeleteEmbedCard(card)}
        disabled={loadingEmbedCard}
        data-cy={`dashboardCardDeleteModal_${card.title}`}
        width="sm"
        footer={getDeleteCardFooter()}
      >
        Are you sure you want to delete this card?
      </Modal>
    </>
  );
};

export default DashboardCardTitlebar;
