import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
import UserGroupContext from '@atom/components/userGroupDetail/UserGroupContext';
import { DELETE_USER_GROUP_CERTIFICATION } from '@atom/graph/userGroup';
import {
  UserGroup,
  UserGroupCertificationDeleteInput,
} from '@atom/types/userGroups';

import './userGroupCerts.css';

interface Props {
  open: boolean;
  onClose: () => void;
  certificationId: string;
}

const DeleteCertModal = ({ open, onClose, certificationId }: Props) => {
  const { userGroup } = useContext(UserGroupContext);

  const [deleteCert, { loading }] = useMutation<
    { userGroupCertificationDelete: UserGroup },
    { input: UserGroupCertificationDeleteInput }
  >(DELETE_USER_GROUP_CERTIFICATION);

  const confirm = () => {
    deleteCert({
      variables: {
        input: {
          id: certificationId,
          userGroupId: userGroup.id,
        },
      },
    });

    onClose();
  };

  return (
    <DeleteModal
      title="Delete Certification?"
      onConfirm={confirm}
      onCancel={onClose}
      content="Are you sure you want to delete this Certification?"
      loading={loading}
      open={open}
    />
  );
};

export default DeleteCertModal;
