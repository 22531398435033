import { useEffect, useState } from 'react';

import { InventoryAssetDetailType } from '@atom/types/inventory';
import api from '@atom/utilities/api';
import { INVENTORY_ASSETS_ENDPOINT } from '@atom/utilities/endpoints';

export interface Options {
  // lazy denotes the data should not be retrieved immediately
  // and that the consumer will manually invoke refetch when data is needed
  lazy?: boolean;
}

export interface Data {
  loading: boolean;
  refetch: () => Promise<void>;
}

type ReturnTuple = [InventoryAssetDetailType, Data];

export const useInventoryAsset = (
  id: string,
  options?: Options,
): ReturnTuple => {
  const lazy = options?.lazy ?? false;
  const [asset, setAsset] = useState(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [invoked, setInvoked] = useState<boolean>(false);

  const refetch = async () => {
    setInvoked(true);
    setLoading(true);

    const { data } = await api.get(`${INVENTORY_ASSETS_ENDPOINT}/${id}`);

    if (data) {
      setAsset(data);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (lazy && !invoked) {
      setLoading(false);
      return;
    }

    if (id) {
      refetch();
    } else {
      setAsset(null);
    }
  }, [id]);

  return [asset, { loading, refetch }];
};
