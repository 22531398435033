import { DragDrop } from 'react-beautiful-dnd';
import * as R from 'ramda';

import { AttributeDataType, DataType } from '@atom/types/dataType';
import { SchemaTreeAttribute } from '@atom/types/schema';

import { PendingUpdates } from '../../SchemaDetailContext';

export interface DataTypeOption {
  dataType: AttributeDataType;
  title: string;
  icon: string;
}

// dataTypes that require debounced onChange
export const DEBOUNCED_DATA_TYPES = new Set([
  DataType.SHORT_TEXT,
  DataType.LONG_TEXT,
  DataType.NUMBER,
  DataType.CURRENCY,
]);

export const DATA_TYPE_OPTIONS: DataTypeOption[] = [
  {
    dataType: DataType.SHORT_TEXT,
    title: 'Short Text',
    icon: 'short_text',
  },
  {
    dataType: DataType.LONG_TEXT,
    title: 'Long Text',
    icon: 'format_align_left',
  },
  {
    dataType: DataType.ENUM_SINGLE,
    title: 'Single Select',
    icon: 'arrow_drop_down_circle',
  },
  {
    dataType: DataType.ENUM_MULTIPLE,
    title: 'Multi Select',
    icon: 'done_all',
  },
  {
    dataType: DataType.BOOLEAN,
    title: 'Yes / No',
    icon: 'radio_button_checked',
  },
  {
    dataType: DataType.NUMBER,
    title: 'Numeric',
    icon: 'looks_one',
  },
  {
    dataType: DataType.CURRENCY,
    title: 'Currency',
    icon: 'attach_money',
  },
  {
    dataType: DataType.DATE,
    title: 'Date Picker',
    icon: 'today',
  },
];

export const FILTERABLE_DATA_TYPES = new Set([
  DataType.ENUM_SINGLE,
  DataType.ENUM_MULTIPLE,
  DataType.BOOLEAN,
  DataType.NUMBER,
  DataType.CURRENCY,
  DataType.DATE,
]);

export const getDataTypeChangePayload = (dataType: AttributeDataType) => {
  const defaultPayload = {
    dataType,
    unit: null,
    enumeration: null,
    defaultValue: null,
  };

  const payloads = {
    [DataType.NUMBER]: {
      dataType,
      unit: '',
      enumeration: null,
    },
    [DataType.ENUM_SINGLE]: {
      dataType,
      unit: null,
      enumeration: ['', 'Option 1'],
    },
    [DataType.ENUM_MULTIPLE]: {
      dataType,
      unit: null,
      enumeration: ['Option 1', 'Option 2'],
    },
  };

  return payloads[dataType] || defaultPayload;
};

export const addEnumOption = (
  enumeration: string[],
  isEnumSingle: boolean,
): string[] => {
  // Offset due to enum single empty value in index 0
  const optionNumber = isEnumSingle
    ? enumeration.length
    : enumeration.length + 1;

  return [...enumeration, `Option ${optionNumber}`];
};

export const removeEnumOption = (
  enumeration: string[],
  index: number,
): string[] => {
  return R.remove(index, 1, enumeration);
};

export const updateEnumOption = (
  enumeration: string[],
  index: number,
  value: string,
): string[] => {
  return R.update(index, value, enumeration);
};

export const reorderEnumOptions = (
  enumeration: string[],
  result: DragDrop,
): string[] => {
  const sourceIndex = result.source.index;
  const destinationIndex = result.destination.index;

  return R.move(sourceIndex, destinationIndex, enumeration);
};

// Handles updating pendingUpdates state tree
export const updatePendingUpdates = (
  schemaId: string,
  pendingUpdates: PendingUpdates,
  attribute: Partial<SchemaTreeAttribute>,
  property: keyof SchemaTreeAttribute,
  value: any,
): PendingUpdates => {
  const hasSchemaEntry = R.has(schemaId)(pendingUpdates);
  const hasAttributeEntry = hasSchemaEntry
    ? R.has(attribute.id)(pendingUpdates[schemaId])
    : false;

  const updatedPendingUpdates = {
    ...pendingUpdates,
    [schemaId]: {
      ...(hasSchemaEntry ? pendingUpdates[schemaId] : {}),
      [attribute.id]: {
        id: attribute.id,
        ...(hasAttributeEntry ? pendingUpdates[schemaId][attribute.id] : {}),
        [property]: value,
      },
    },
  };

  return updatedPendingUpdates;
};

// Merges pending changes with the selected attribute
// If an attribute property has pending changes, they will be displayed
// instead of the currently published changes.
export const mergePendingChanges = (
  schemaId: string,
  attribute: Partial<SchemaTreeAttribute>,
  pendingUpdates: PendingUpdates,
): Partial<SchemaTreeAttribute> => {
  const hasSchemaEntry = R.has(schemaId)(pendingUpdates);
  const hasAttributeEntry = hasSchemaEntry
    ? R.has(attribute?.id)(pendingUpdates[schemaId])
    : false;

  const pendingUpdateData =
    hasSchemaEntry && hasAttributeEntry
      ? pendingUpdates[schemaId][attribute?.id]
      : {};

  return { ...attribute, ...pendingUpdateData };
};
