import React, { useEffect, useState } from 'react';

import { Modal, TextField } from '@atom/mui';
import { TaskCreateInput } from '@atom/types/task';

export interface Props {
  open: boolean;
  onClose: () => void;
  handleCreate: (property: keyof TaskCreateInput, value: string) => void;
}

const styles = {
  modal: {
    padding: '2rem',
  },
};

const TaskCreateModal = ({ open, onClose, handleCreate }: Props) => {
  const [name, setName] = useState<string>('');

  const resetState = () => {
    setName('');
  };

  useEffect(() => {
    resetState();
  }, [open]);

  const handleSubmit = async () => {
    if (name) {
      handleCreate('name', name);
      onClose();
    }
  };

  return (
    <Modal
      title="Create Task"
      open={open}
      onConfirm={handleSubmit}
      onCancel={onClose}
      onExited={resetState}
      confirmButtonText="Create"
      contentStyle={styles.modal}
      disabled={!name}
    >
      <div>
        <TextField
          label="Task Name *"
          value={name}
          autoFocus
          name="name"
          onChange={event => {
            setName(event.target.value);
          }}
        />
      </div>
    </Modal>
  );
};

export default TaskCreateModal;
