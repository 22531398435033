import React from 'react';

import { Icon } from '@atom/mui';

import './mapDrawerContent.css';

interface Props {
  icon: string;
  main: string;
  sub: string;
}

const InfoRow = ({ icon, main, sub }: Props) => {
  return (
    <div styleName="basic-info-row">
      <Icon children={icon} />
      <div styleName="data-container">
        <div styleName="data-line">{main}</div>
        <div styleName="data-sub-line">{sub}</div>
      </div>
    </div>
  );
};

export default InfoRow;
