import * as React from 'react';

import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';

import './searchPortal.css';

interface Props {
  navigateBack: () => void;
  onFilterClick: (data: 'all' | 'assets' | 'work' | 'team') => void;
  activeView: string;
}

const SearchNavigationBar = ({
  navigateBack,
  onFilterClick,
  activeView,
}: Props) => {
  return (
    <div styleName="search-navigation-bar-container">
      <div styleName="search-back-navigation-container" onClick={navigateBack}>
        <div styleName="back-button-icon">
          <Icon color={colors.neutral.gray}>arrow_back</Icon>
        </div>
        <div styleName="back-button-text">Search Results</div>
      </div>

      <div styleName="search-entity-type-container">
        <div
          styleName="search-entity-view-control"
          onClick={(): void => onFilterClick('all')}
          style={{
            color:
              activeView === 'all' ? colors.brand.blue : colors.neutral.dark,
          }}
        >
          all results
        </div>
        <div
          styleName="search-entity-view-control"
          onClick={(): void => onFilterClick('assets')}
          style={{
            color:
              activeView === 'assets' ? colors.brand.blue : colors.neutral.dark,
          }}
        >
          inventory
        </div>
        <div
          styleName="search-entity-view-control"
          onClick={(): void => onFilterClick('work')}
          style={{
            color:
              activeView === 'work' ? colors.brand.blue : colors.neutral.dark,
          }}
        >
          work
        </div>
        <div
          styleName="search-entity-view-control"
          onClick={(): void => onFilterClick('team')}
          style={{
            color:
              activeView === 'team' ? colors.brand.blue : colors.neutral.dark,
          }}
        >
          team
        </div>
      </div>
    </div>
  );
};

export default SearchNavigationBar;
