import React from 'react';
import * as R from 'ramda';

import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import ReportUserRow from './ReportUserRow';
import { ReportUser } from './reportUtilities';

import './workOrderReport.css';

interface Props {
  users: ReportUser[];
  isCompletedOrAbove: boolean;
}

const ReportUsers = ({ users, isCompletedOrAbove }: Props) => {
  const allEmpty = R.all(R.propEq('name', ''))(users);

  const showUsers = !isNilOrEmpty(users);
  const showPerDiem = !allEmpty;

  return (
    showUsers && (
      <>
        <div styleName="users-table-header">
          <div styleName="users-table-sub-header">
            <div styleName="users-header-cell name">Name</div>
            <div styleName="users-header-cell">Hours</div>
            {showPerDiem && <div styleName="users-header-cell">Per Diem</div>}
          </div>
          {isCompletedOrAbove && (
            <div styleName="users-header-cell cost">Cost</div>
          )}
        </div>
        {users.map(user => (
          <ReportUserRow
            key={user.userId}
            user={user}
            showPerDiem={showPerDiem}
            isCompletedOrAbove={isCompletedOrAbove}
          />
        ))}
      </>
    )
  );
};

export default ReportUsers;
