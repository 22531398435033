import React, { useContext, useState } from 'react';

import { AnalyticsFolderTree as FolderTree } from '@atom/types/analytics';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import AnalyticsContext from '../AnalyticsContext';

import SelectFolderRow from './SelectFolderRow';

export interface Props {
  setFolder: (folder: FolderTree) => void;
}

const SelectAnalyticsFolderTree = ({ setFolder }: Props) => {
  const { treeData, activeFolderId } = useContext(AnalyticsContext);
  const [open, setOpen] = useState<Set<string>>(new Set(['analytics']));
  const [selectedFolder, setSelectedFolder] = useState<FolderTree>();
  const toggleOpen = (id: string) => setOpen(toggleFromSet(open, id));

  const handleSetFolder = (folder: FolderTree) => {
    setSelectedFolder(folder);
    setFolder(folder);
  };

  return (
    <SelectFolderRow
      folder={treeData}
      setFolder={handleSetFolder}
      open={open}
      selectedFolder={selectedFolder}
      toggleOpen={toggleOpen}
      indentation={0}
      activeFolderId={activeFolderId}
    />
  );
};

export default SelectAnalyticsFolderTree;
