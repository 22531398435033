import React from 'react';

import { List } from '@atom/mui';
import { ApplicationSearchInventoryState } from '@atom/types/store';
import history from '@atom/utilities/history';
import schemaUtilities from '@atom/utilities/schemaUtilities';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';

import './searchBar.css';

const { ListItemButton, ListItemText } = List;

const styles = {
  list: {
    padding: 0,
  },
  listItem: {
    paddingLeft: '2rem',
  },
  icon: {
    marginRight: '1rem',
  },
  primaryTextStyle: {
    fontWeight: 'none',
  },
};

interface Props {
  query: string;
  assets: ApplicationSearchInventoryState[];
}

const InventorySearchResultsContainer = ({ query, assets }: Props) => {
  const navigate = (id: string) => {
    history.push(`/inventory/${id}`);
  };

  return (
    <>
      <div styleName="search-entity-results-heading">Inventory</div>
      <List style={styles.list}>
        {assets.map((asset: ApplicationSearchInventoryState) => {
          const icon = schemaUtilities.getSchemaIconFromSchemaOrAsset(asset);

          const assetName = textDisplayUtilities.highlightedText(
            query,
            asset.name,
          );

          return (
            <ListItemButton
              key={asset.id}
              onClick={() => navigate(asset.id)}
              style={styles.listItem}
            >
              <div style={styles.icon}>{icon}</div>
              <ListItemText
                primary={assetName}
                primaryTextStyle={styles.primaryTextStyle}
              />
            </ListItemButton>
          );
        })}
      </List>
    </>
  );
};

export default InventorySearchResultsContainer;
