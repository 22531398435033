import React from 'react';
import * as R from 'ramda';

import { DataType } from '@atom/types/dataType';
import { TaskField } from '@atom/types/task';
import { WorkOrder } from '@atom/types/work';
import { WorkOrderColumn } from '@atom/types/workColumns';
import {
  getDisplayDateTime,
  setDisplayDate,
} from '@atom/utilities/timeUtilities';

import './workOrders.css';

interface Props {
  workOrder: WorkOrder;
  column: WorkOrderColumn;
}

const BLANK_FIELD = {
  id: '',
  title: '',
  enumeration: [],
  dataType: DataType.SHORT_TEXT,
  value: '-',
};

const getFieldValue = (
  workOrder: WorkOrder,
  column: WorkOrderColumn,
): TaskField => {
  const task = R.find(R.propEq('id', column.taskId))(workOrder.tasks);

  return task
    ? R.find(R.propEq('id', column.fieldId))(task.fields) || BLANK_FIELD
    : BLANK_FIELD;
};

const CustomTaskFieldCell = ({ workOrder, column }: Props) => {
  const field = getFieldValue(workOrder, column);

  const getFieldDisplay = () => {
    const content = {
      [DataType.SHORT_TEXT]: field?.value,
      [DataType.LONG_TEXT]: field?.value,
      [DataType.DATE]: setDisplayDate(field?.value),
      [DataType.DATE_TIME]: getDisplayDateTime(field?.value),
      [DataType.ENUM_SINGLE]: field?.value,
      [DataType.ENUM_MULTIPLE]:
        field.dataType === DataType.ENUM_MULTIPLE
          ? R.pathOr([], ['value'], field).join(', ')
          : '',
      [DataType.NUMBER]: field?.value,
    };

    return content[field.dataType] || '-';
  };

  return <div>{getFieldDisplay()}</div>;
};

export default CustomTaskFieldCell;
