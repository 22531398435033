import React, { useMemo, useState } from 'react';

import { useSchemas } from '@atom/hooks/useSchemas';
import { Button, DatePicker, Icon, List, Modal } from '@atom/mui';
import { PolicyAction } from '@atom/types/policy';
import {
  SearchWorkTemplate,
  WorkTemplateListItem,
} from '@atom/types/workTemplate';
import { convertDateToMillisGMTMidday } from '@atom/utilities/timeUtilities';
import { getWorkTypeIconSvg } from '@atom/utilities/workTypeIconUtilities';

import CreateWorkTemplateSelection from './CreateWorkTemplateSelection';

import './createWorkModals.css';

const { ListItem, ListItemText } = List;

enum View {
  WORK_TEMPLATE,
  DUE_DATE,
}

type WorkTemplate = WorkTemplateListItem | SearchWorkTemplate;

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (workTemplateId: string, dueDate: number) => void;
  schemaId: string;
  count: number;
}

const styles = {
  modal: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
  },
  icon: {
    marginRight: '2rem',
  },
};

const CreateWorkForAssetsModal = ({
  open,
  onClose,
  onConfirm,
  schemaId,
  count,
}: Props) => {
  const [view, setView] = useState<View>(View.WORK_TEMPLATE);
  const [dueDate, setDueDate] = useState<Date>(new Date());
  const [workTemplate, setWorkTemplate] = useState<WorkTemplate>(null);

  const { schemas = [] } = useSchemas();

  const workTemplateSchema = useMemo(() => {
    return schemas.find(({ id }) => id === workTemplate?.schemaId);
  }, [workTemplate]);

  const handleDueDateChange = (date?: Date) => {
    if (!date) {
      setDueDate(null);
    } else if (!Number.isNaN(date.valueOf())) {
      setDueDate(date);
    }
  };

  const resetState = () => {
    setView(View.WORK_TEMPLATE);
    setDueDate(new Date());
    setWorkTemplate(null);
  };

  const isValid = () => {
    return workTemplate;
  };

  const handleSubmit = () => {
    onConfirm(workTemplate.id, convertDateToMillisGMTMidday(dueDate));
    onClose();
  };

  const getFooter = () => {
    return view === View.WORK_TEMPLATE ? (
      <Button onClick={onClose}>Cancel</Button>
    ) : (
      <div styleName="footer">
        <div>
          <Button onClick={resetState}>Back</Button>
        </div>
        <div>
          <Button onClick={onClose} style={{ marginRight: '0.5rem' }}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!isValid()}
            onClick={handleSubmit}
          >
            Create
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      title="Create Work for Filter Results"
      open={open}
      footer={getFooter()}
      onExited={resetState}
      contentStyle={
        view === View.WORK_TEMPLATE
          ? { ...styles.modal, height: '50vh', padding: '2rem 3.875rem' }
          : styles.modal
      }
    >
      <div style={{ marginBottom: '1.25rem' }}>
        {`You are creating ${count} work for inventory in the filter results. You can only create work for top-level inventory.`}
      </div>
      {view === View.WORK_TEMPLATE ? (
        <CreateWorkTemplateSelection
          onClick={template => {
            setWorkTemplate(template);
            setView(View.DUE_DATE);
          }}
          label="Start with a work template:"
          emptyContentLabel="There are 0 matching work templates."
          schemaId={schemaId}
          hasSchema={false}
          action={PolicyAction.BULK_CREATE}
        />
      ) : (
        <>
          {workTemplate && (
            <div styleName="modal-row work-template">
              <Icon style={styles.icon}>
                <img
                  src={getWorkTypeIconSvg(
                    workTemplate?.isParent,
                    workTemplate?.parentId,
                  )}
                />
              </Icon>
              <ListItem disableGutters style={{ margin: 0, padding: 0 }}>
                <ListItemText
                  style={{ margin: 0 }}
                  primary={workTemplate.name}
                  secondary={workTemplateSchema?.name}
                />
              </ListItem>
            </div>
          )}
          <div styleName="modal-row due-date">
            <Icon style={styles.icon}>event</Icon>
            <DatePicker
              label="Due Date"
              value={dueDate}
              onChange={handleDueDateChange}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default CreateWorkForAssetsModal;
