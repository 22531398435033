import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { SortDirection } from '@mui/material';
import * as R from 'ramda';

import WorkOrderStatusPill from '@atom/components/common/workOrderDetail/workOrderStatusPill/WorkOrderStatusPill';
import WorkUsers from '@atom/components/common/workUsers/WorkUsers';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { ListTable } from '@atom/mui';
import { getDataAccessByProperty } from '@atom/selectors/workColumnSelectors';
import colors from '@atom/styles/colors';
import { DashboardCard, TableColumn } from '@atom/types/dashboard';
import { UserDetail } from '@atom/types/user';
import { WorkOrdersConnection, WorkOrderUserGroup } from '@atom/types/work';
import { WorkOrderColumnProperty } from '@atom/types/workColumns';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import {
  getUserFullName,
  rollUpTaskUsers,
} from '@atom/utilities/userUtilities';

import CardEmpty from '../commonCardStates/CardEmpty';
import CardLoading from '../commonCardStates/CardLoading';
import { getCardHeight } from '../dashboardUtilities';

import '../dashboardCard.css';

const {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} = ListTable;

interface Props {
  card: DashboardCard;
  cardData: { workOrders: WorkOrdersConnection[] };
  setCardData: (data: any) => {};
}

const getTableCell = (column: TableColumn, workOrder) => {
  const getUserGroups = () => {
    return workOrder.tasks.reduce(
      (acc: WorkOrderUserGroup[], task) => [...acc, ...task.userGroups],
      [],
    );
  };

  const cellContent = {
    [WorkOrderColumnProperty.STATUS]: (
      <WorkOrderStatusPill
        className="table"
        statusId={workOrder?.statusId}
        style={{ margin: 0 }}
      />
    ),
    [WorkOrderColumnProperty.INVENTORY_NAME]: workOrder?.inventoryAssetName,
    [WorkOrderColumnProperty.DUE_DATE]: setDisplayDate(workOrder?.dueDate),
    [WorkOrderColumnProperty.ASSIGNED_TO]: (
      <WorkUsers
        users={rollUpTaskUsers(workOrder?.tasks as any)}
        userGroups={getUserGroups()}
        avatarSize={24}
      />
    ),
    [WorkOrderColumnProperty.CREATED_BY]: workOrder.createdBy
      ? getUserFullName(workOrder?.createdBy as UserDetail)
      : '-',
  };

  return (
    (
      <TableCell key={column.label}>{cellContent[column.property]}</TableCell>
    ) || <TableCell />
  );
};

interface TableInput {
  page: number;
  limit: number;
  sortBy?: string;
}

const initInput = {
  page: 1,
  limit: 10,
};

const styles = {
  tableContainer: {
    borderTop: `1px solid ${colors.neutral.ash}`,
    overflow: 'hidden',
  },
  paginationRow: {
    backgroundColor: colors.neutral.white,
    height: '3rem',
  },
  tableFooter: {
    borderTop: `1px solid ${colors.neutral.ash}`,
  },
};

const CardWorkOrderTable = ({ card, cardData, setCardData }: Props) => {
  const { userId } = useUserProfile();
  const workOrders = useMemo(
    () => R.pathOr([], ['workOrders', 'workOrders'], cardData),
    [cardData],
  );
  const totalCount = useMemo(
    () => R.pathOr(0, ['workOrders', 'totalCount'], cardData),
    [cardData],
  );
  const [input, setInput] = useState<TableInput>(initInput);
  const [loading, setLoading] = useState(false);

  const getSortDirection = useCallback(
    (field: string): SortDirection => {
      if (!input.sortBy?.includes(field)) {
        return false;
      }
      return R.pathOr('asc', [1], input.sortBy.split(','));
    },
    [input.sortBy],
  );

  useEffect(() => {
    const loadCardContent = async () => {
      const data = await card.getCardData({ userId, ...input });
      setCardData(R.pathOr({}, ['data'], data));
      setLoading(false);
    };

    loadCardContent();
    setLoading(true);
  }, [input]);

  const handleSortDirectionChange = (field: string) => (
    sortBy: SortDirection,
  ) => {
    setInput({ ...input, sortBy: `${field},${sortBy}` });
  };

  const getHeaderColumn = (column: TableColumn) => {
    const sortProps = {
      ...(column.property !== 'ASSIGNED_TO' && {
        sortDirection: getSortDirection(
          getDataAccessByProperty(column.property),
        ),
      }),
      ...(column.property !== 'ASSIGNED_TO' && {
        onSortChange: handleSortDirectionChange(
          getDataAccessByProperty(column.property),
        ),
      }),
    };
    return (
      <TableCell key={column.label} {...sortProps} variant="head">
        <div>
          <div>{column.label}</div>
        </div>
      </TableCell>
    );
  };

  if (loading) {
    return <CardLoading card={card} />;
  }

  if (totalCount === 0) {
    return <CardEmpty card={card} />;
  }

  return (
    <div style={{ ...styles.tableContainer, height: getCardHeight(card) }}>
      <ListTable fullHeight={false}>
        <TableHead>
          <TableRow header>
            <TableCell
              variant="head"
              sortDirection={getSortDirection('name')}
              onSortChange={handleSortDirectionChange('name')}
            >
              Work ID
            </TableCell>
            {card.tableColumns.map(column => getHeaderColumn(column))}
          </TableRow>
        </TableHead>
        <TableBody>
          {workOrders.map(workOrder => (
            <TableRow key={workOrder.id}>
              <TableCell style={{ fontWeight: 500 }}>
                <Link target="_blank" to={`/workOrders/${workOrder.id}`}>
                  {workOrder.name}
                </Link>
              </TableCell>
              {card.tableColumns.map(column => getTableCell(column, workOrder))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter width={'100%'} style={styles.tableFooter}>
          <TableRow style={styles.paginationRow}>
            <TablePagination
              rowsPerPageOptions={[10]}
              count={totalCount}
              rowsPerPage={10}
              page={input.page}
              onPageChange={page => setInput({ ...input, page })}
            />
          </TableRow>
        </TableFooter>
      </ListTable>
    </div>
  );
};

export default CardWorkOrderTable;
