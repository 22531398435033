// @ts-ignore
import workTypeIconChildBlue from '@atom/components/common/svgIcons/workTypeIconChildBlue.svg';
// @ts-ignore
import workTypeIconChildSilver from '@atom/components/common/svgIcons/workTypeIconChildSilver.svg';
// @ts-ignore
import workTypeIconChildWhite from '@atom/components/common/svgIcons/workTypeIconChildWhite.svg';
// @ts-ignore
import workTypeIconParentBlue from '@atom/components/common/svgIcons/workTypeIconParentBlue.svg';
// @ts-ignore
import workTypeIconParentSilver from '@atom/components/common/svgIcons/workTypeIconParentSilver.svg';
// @ts-ignore
import workTypeIconParentWhite from '@atom/components/common/svgIcons/workTypeIconParentWhite.svg';
// @ts-ignore
import workTypeIconRegularBlue from '@atom/components/common/svgIcons/workTypeIconRegularBlue.svg';
// @ts-ignore
import workTypeIconRegularSilver from '@atom/components/common/svgIcons/workTypeIconRegularSilver.svg';
// @ts-ignore
import workTypeIconRegularWhite from '@atom/components/common/svgIcons/workTypeIconRegularWhite.svg';
import {
  getWorkTypeVariant,
  WorkTypeVariant,
} from '@atom/utilities/workTemplateUtilities';

export enum WorkTypeIcon {
  REGULAR = 'REGULAR',
  REGULAR_DISABLED = 'REGULAR_DISABLED',
  REGULAR_WHITE = 'REGULAR_WHITE',
  PARENT = 'PARENT',
  PARENT_DISABLED = 'PARENT_DISABLED',
  PARENT_WHITE = 'PARENT_WHITE',
  CHILD = 'CHILD',
  CHILD_DISABLED = 'CHILD_DISABLED',
  CHILD_WHITE = 'CHILD_WHITE',
}

const workTypeIcons = {
  [WorkTypeIcon.REGULAR]: workTypeIconRegularBlue,
  [WorkTypeIcon.REGULAR_DISABLED]: workTypeIconRegularSilver,
  [WorkTypeIcon.REGULAR_WHITE]: workTypeIconRegularWhite,
  [WorkTypeIcon.PARENT]: workTypeIconParentBlue,
  [WorkTypeIcon.PARENT_DISABLED]: workTypeIconParentSilver,
  [WorkTypeIcon.PARENT_WHITE]: workTypeIconParentWhite,
  [WorkTypeIcon.CHILD]: workTypeIconChildBlue,
  [WorkTypeIcon.CHILD_DISABLED]: workTypeIconChildSilver,
  [WorkTypeIcon.CHILD_WHITE]: workTypeIconChildWhite,
};

export const getWorkTypeIconSvg = (
  isParent: boolean,
  parentId: string,
  disabled: boolean = false,
  white: boolean = false,
) => {
  const workTypeVariant = getWorkTypeVariant(isParent, parentId);

  switch (true) {
    case workTypeVariant === WorkTypeVariant.REGULAR && !disabled && white: {
      return workTypeIcons[WorkTypeIcon.REGULAR_WHITE];
    }
    case workTypeVariant === WorkTypeVariant.REGULAR && !disabled: {
      return workTypeIcons[WorkTypeIcon.REGULAR];
    }
    case workTypeVariant === WorkTypeVariant.REGULAR && disabled: {
      return workTypeIcons[WorkTypeIcon.REGULAR_DISABLED];
    }
    case workTypeVariant === WorkTypeVariant.PARENT && !disabled && white: {
      return workTypeIcons[WorkTypeIcon.PARENT_WHITE];
    }
    case workTypeVariant === WorkTypeVariant.PARENT && !disabled: {
      return workTypeIcons[WorkTypeIcon.PARENT];
    }
    case workTypeVariant === WorkTypeVariant.PARENT && disabled: {
      return workTypeIcons[WorkTypeIcon.PARENT_DISABLED];
    }
    case workTypeVariant === WorkTypeVariant.CHILD && !disabled && white: {
      return workTypeIcons[WorkTypeIcon.CHILD_WHITE];
    }
    case workTypeVariant === WorkTypeVariant.CHILD && !disabled: {
      return workTypeIcons[WorkTypeIcon.CHILD];
    }
    case workTypeVariant === WorkTypeVariant.CHILD && disabled: {
      return workTypeIcons[WorkTypeIcon.CHILD_DISABLED];
    }
    default: {
      return workTypeIcons[WorkTypeIcon.REGULAR];
    }
  }
};
