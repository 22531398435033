import api from '@atom/utilities/api';
import { USERS_ENDPOINT } from '@atom/utilities/endpoints';
import { TOTAL_COUNT_HEADER } from '@atom/utilities/requestUtilities';

import actionTypes from './actionTypes';

export const requestUserPasswordReset = data => ({
  type: actionTypes.REQUEST_USER_PASSWORD_RESET,
  data,
});

export const userPasswordResetSuccess = () => ({
  type: actionTypes.USER_PASSWORD_RESET_SUCCESS,
});

export const userPasswordResetFailure = () => ({
  type: actionTypes.USER_PASSWORD_RESET_FAILURE,
});

export const requestEmailVerificationLink = () => ({
  type: actionTypes.REQUEST_EMAIL_VERIFICATION_LINK,
});

export const getEmailVerificationLinkSuccess = () => ({
  type: actionTypes.GET_EMAIL_VERIFICATION_LINK_SUCCESS,
});

export const getEmailVerificationLinkFailure = () => ({
  type: actionTypes.GET_EMAIL_VERIFICATION_LINK_FAILURE,
});

export const requestUserTokenPersist = data => ({
  type: actionTypes.REQUEST_USER_TOKEN_PERSIST,
  data,
});

export const persistUserTokenSuccess = () => ({
  type: actionTypes.USER_TOKEN_PERSIST_SUCCESS,
});

export const persistUserTokenFailure = () => ({
  type: actionTypes.USER_TOKEN_PERSIST_FAILURE,
});

export const requestUserAuthTokenExchange = data => ({
  type: actionTypes.REQUEST_USER_AUTH_TOKEN_EXCHANGE,
  data,
});

export const userAuthTokenExchangeSuccess = () => ({
  type: actionTypes.USER_AUTH_TOKEN_EXCHANGE_SUCCESS,
});

export const userAuthTokenExchangeFailure = () => ({
  type: actionTypes.USER_AUTH_TOKEN_EXCHANGE_FAILURE,
});

export const requestUserRegister = data => ({
  type: actionTypes.REQUEST_USER_REGISTER,
  data,
});

export const userRegisterSuccess = data => ({
  type: actionTypes.USER_REGISTER_SUCCESS,
  data,
});

export const userRegisterFailure = () => ({
  type: actionTypes.USER_REGISTER_FAILURE,
});

export const retrieveLoggedInUser = () => ({
  type: actionTypes.REQUEST_LOGGED_IN_USER,
});

export const loggedInUserSuccess = (data?: object) => ({
  type: actionTypes.LOGGED_IN_USER_GET_SUCCESS,
  data,
});

export const loggedInUserFailure = () => ({
  type: actionTypes.LOGGED_IN_USER_GET_FAILURE,
});

export const forbiddenUser = () => ({
  type: actionTypes.LOGGED_IN_USER_FORBIDDEN,
});

export const triggerLogout = () => ({
  type: actionTypes.TRIGGER_LOGOUT,
});

export const logoutSuccess = () => ({
  type: actionTypes.LOGOUT_SUCCESS,
});

export const retrieveUsers = data => ({
  type: actionTypes.REQUEST_USERS,
  data,
});

export const getUsersSuccess = (data?: object) => ({
  type: actionTypes.USERS_GET_SUCCESS,
  data,
});

export const getUsersFailure = () => ({
  type: actionTypes.USERS_GET_FAILURE,
});

export const retrieveUser = data => ({
  type: actionTypes.REQUEST_USER,
  data,
});

export const getUserSuccess = (data?: object) => ({
  type: actionTypes.USER_GET_SUCCESS,
  data,
});

export const getUserFailure = () => ({
  type: actionTypes.USER_GET_FAILURE,
});

export const getUsersTotalSuccess = (usersTotal: number) => ({
  type: actionTypes.USERS_TOTAL_GET_SUCCESS,
  usersTotal,
});

export const requestUserDeactivation = data => ({
  type: actionTypes.REQUEST_DEACTIVATE_USER,
  data,
});

export const userDeactivationSuccess = (data?: object) => ({
  type: actionTypes.DEACTIVATE_USER_SUCCESS,
  data,
});

export const userDeactivationFailure = () => ({
  type: actionTypes.DEACTIVATE_USER_FAILURE,
});

export const requestUserVerification = data => ({
  type: actionTypes.REQUEST_VERIFY_USER,
  data,
});

export const userVerificationSuccess = (data?: object) => ({
  type: actionTypes.VERIFY_USER_SUCCESS,
  data,
});

export const userVerificationFailure = () => ({
  type: actionTypes.VERIFY_USER_FAILURE,
});

export const requestUserDeletion = data => ({
  type: actionTypes.REQUEST_USER_DELETION,
  data,
});

export const userDeletionSuccess = (data?: object) => ({
  type: actionTypes.DELETE_USER_SUCCESS,
  data,
});

export const userDeletionFailure = () => ({
  type: actionTypes.DELETE_USER_FAILURE,
});

export const requestUserActivation = data => ({
  type: actionTypes.REQUEST_USER_ACTIVATION,
  data,
});

export const userActivationSuccess = (data?: object) => ({
  type: actionTypes.ACTIVATE_USER_SUCCESS,
  data,
});

export const userActivationFailure = () => ({
  type: actionTypes.ACTIVATE_USER_FAILURE,
});

export const requestUserUpdate = (data?: object) => ({
  type: actionTypes.REQUEST_USER_UPDATE,
  data,
});

export const userUpdateSuccess = (data?: object) => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
  data,
});

export const userUpdateFailure = () => ({
  type: actionTypes.UPDATE_USER_FAILURE,
});

export function getUsersSync(page, limit) {
  return async function (dispatch) {
    const endpoint = `${USERS_ENDPOINT}?page=${page}&limit=${limit}`;

    try {
      const response = await api.get(endpoint);
      dispatch(getUsersSuccess(response.data));
      dispatch(
        getUsersTotalSuccess(Number(response.headers[TOTAL_COUNT_HEADER])),
      );
    } catch (error) {
      dispatch(getUsersFailure());
      throw error;
    }
  };
}
