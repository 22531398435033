import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import UserGroupContext from '@atom/components/userGroupDetail/UserGroupContext';
import { UPDATE_USER_GROUP } from '@atom/graph/userGroup';
import { useErrorNotification } from '@atom/hooks/useErrorNotification';
import { Icon, Modal, Progress, TextField } from '@atom/mui';
import { UserGroup, UserGroupUpdateInput } from '@atom/types/userGroups';
import { colorSwatch } from '@atom/utilities/colorUtilities';

import './userGroupHeader.css';

interface Props {
  open: boolean;
  onClose: () => void;
}

const EditUserGroupModal = ({ open, onClose }: Props) => {
  const { userGroup } = useContext(UserGroupContext);

  const [name, setName] = useState<string>(userGroup.name);
  const [colorId, setColorId] = useState<number>(userGroup.colorId);

  useEffect(() => {
    if (open) {
      setName(userGroup.name);
      setColorId(userGroup.colorId);
    }
  }, [open]);

  const [updateUserGroup, { loading, error }] = useMutation<
    { userGroupUpdate: UserGroup },
    { input: UserGroupUpdateInput }
  >(UPDATE_USER_GROUP);

  useErrorNotification(error, {
    409: 'The group name is already in use. Please choose a different name.',
  });

  const closeModal = () => {
    onClose();
    setName('');
    setColorId(0);
  };

  const confirm = () => {
    updateUserGroup({
      variables: {
        input: {
          id: userGroup.id,
          ...(name !== userGroup.name && { name }),
          colorId,
        },
      },
    });

    closeModal();
  };

  const renderColorSwatches = () => {
    return colorSwatch.map((swatch, index) => (
      <div
        key={index}
        styleName={index === colorId ? 'color-swatch selected' : 'color-swatch'}
        onClick={() => setColorId(index)}
      >
        <Icon style={{ color: swatch, fontSize: 28 }}>lens</Icon>
      </div>
    ));
  };

  return (
    <Modal
      title="Edit Group"
      confirmButtonText="Save"
      onConfirm={confirm}
      onCancel={closeModal}
      disabled={R.isEmpty(name)}
      open={open}
    >
      {loading ? (
        <Progress />
      ) : (
        <div styleName="modal-content">
          <TextField
            floatingLabelText="Name"
            value={name}
            name="groupName"
            label="Name"
            onChange={event => setName(event.target.value)}
          />
          <div styleName="color-title">Color:</div>
          <div styleName="color-container">{renderColorSwatches()}</div>
        </div>
      )}
    </Modal>
  );
};

export default EditUserGroupModal;
