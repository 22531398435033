import React, { useState } from 'react';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import { Icon, IconButton, Progress, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { EventType } from '@atom/types/event';
import { MediaItem } from '@atom/types/media';

import './workTemplatePhotos.css';

const styles = {
  close: {
    cursor: 'pointer',
  },
  input: {
    display: 'none',
  },
  progress: {
    marginRight: '0.5rem',
  },
};

interface Props {
  selectLimit: number;
  clearSearch: () => void;
  searchValue: string;
  onChange: (event: EventType) => void;
  uploadFiles: (event: any) => void;
  downloadMultipleFiles: () => void;
  deleteMultipleFiles: () => void;
  selectAll: () => void;
  deselectAll: () => void;
  selectedItems: string[];
  media: MediaItem[];
  loading: boolean;
  isEditDisabled: boolean;
  isInherited?: boolean;
}

const PhotosTabHeader = ({
  clearSearch,
  searchValue,
  onChange,
  uploadFiles,
  selectedItems,
  downloadMultipleFiles,
  deleteMultipleFiles,
  selectAll,
  deselectAll,
  selectLimit,
  media,
  loading,
  isEditDisabled,
  isInherited = false,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleDeleteMultipleFiles = async () => {
    await deleteMultipleFiles();
    setOpen(false);
  };

  const defaultHeaderButtons = (
    <>
      <TextField
        value={searchValue}
        onChange={onChange}
        placeholder="Search"
        variant="standard"
        InputProps={{
          startAdornment: <Icon>search</Icon>,
          endAdornment: (
            <Icon style={styles.close} onClick={clearSearch}>
              close
            </Icon>
          ),
        }}
      />
      {!isEditDisabled && (
        <div>
          <input
            id="icon-button-file"
            style={styles.input}
            multiple
            type="file"
            onChange={uploadFiles}
          />
          <label htmlFor="icon-button-file">
            <IconButton
              component="span"
              tooltip="Upload"
              disabled={isEditDisabled}
            >
              <Icon>publish</Icon>
            </IconButton>
          </label>
        </div>
      )}
    </>
  );

  const bulkHeaderButtons = (
    <>
      <IconButton onClick={downloadMultipleFiles} tooltip="Download">
        <Icon>file_download</Icon>
      </IconButton>
      {!isEditDisabled && !isInherited && (
        <IconButton
          onClick={() => setOpen(true)}
          tooltip="Delete"
          disabled={isEditDisabled}
        >
          <Icon>delete</Icon>
        </IconButton>
      )}
      <DeleteModal
        open={open}
        title="Delete Files"
        content="Are you sure you want to delete these photos?"
        onCancel={() => setOpen(false)}
        onConfirm={handleDeleteMultipleFiles}
      />
    </>
  );

  const getSelectAllButtonInfo = () => {
    const amountSelected = selectedItems.length;
    switch (true) {
      case selectLimit === 0: {
        return {
          icon: 'check_box_outline_blank',
          color: colors.neutral.gray,
          message: 'Select all',
          onClick: selectAll,
        };
      }
      case amountSelected === selectLimit: {
        return {
          icon: 'check_box',
          color: colors.brand.blue,
          message: `Selected ${amountSelected} photos`,
          onClick: deselectAll,
        };
      }
      case amountSelected !== selectLimit && amountSelected > 0: {
        return {
          icon: 'indeterminate_check_box',
          color: colors.brand.blue,
          message: `Selected ${amountSelected} photos`,
          onClick: deselectAll,
        };
      }
      default: {
        return {
          icon: 'check_box_outline_blank',
          color: colors.neutral.gray,
          message: 'Select all',
          onClick: selectAll,
        };
      }
    }
  };

  const selectAllButtonInfo = getSelectAllButtonInfo();

  const getHeaderLabel = mediaLength => {
    const itemsSelected = selectedItems.length > 0;
    const viewType = 'photos';

    return itemsSelected
      ? `${selectedItems.length} of ${mediaLength} ${viewType} selected`
      : `${mediaLength} ${viewType}`;
  };

  const headerLabel = getHeaderLabel(media.length);

  const headerButtons = R.isEmpty(selectedItems)
    ? defaultHeaderButtons
    : bulkHeaderButtons;

  const isDisabled = selectLimit === 0;

  return (
    <div styleName="right-pane-header">
      {media.length ? (
        <div styleName="select-all-buttons">
          <IconButton
            onClick={selectAllButtonInfo.onClick}
            disabled={isDisabled}
          >
            <Icon color={selectAllButtonInfo.color}>
              {selectAllButtonInfo.icon}
            </Icon>
          </IconButton>
          <div style={{ color: selectAllButtonInfo.color }}>
            {selectAllButtonInfo.message}
          </div>
        </div>
      ) : (
        <div styleName="header-label">{headerLabel}</div>
      )}
      <div styleName="button-container">
        {loading && <Progress size={20} style={styles.progress} />}
        {headerButtons}
      </div>
    </div>
  );
};

export default PhotosTabHeader;
