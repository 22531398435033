import { UserGroupTreeType } from 'client/types/userGroups';
import * as R from 'ramda';

export const getUserGroupTableTotalCount = data => {
  if (R.isNil(data)) {
    return 0;
  }

  return R.pathOr(0, ['userGroup', 'children', 'totalCount'], data);
};

export const getUserGroupTableData = data => {
  if (R.isNil(data)) {
    return [];
  }
  return R.pathOr([], ['userGroup', 'children', 'userGroupChildren'], data);
};

const getNestedGroupIds = data => {
  if (R.isEmpty(data.userGroups)) {
    return [];
  }

  return data.userGroups.reduce(
    (acc: string[], userGroup: UserGroupTreeType) => {
      return [...acc, userGroup.id, ...getNestedGroupIds(userGroup)];
    },
    [],
  );
};

export const getAllTreeIds = data => {
  if (R.isEmpty(data.userGroups)) {
    return ['root'];
  }

  return ['root', ...getNestedGroupIds(data)];
};
