// root definition of all possible dataTypes in Atom.
// This is extended upon in schema, task, taskLocation, and work typings
export enum DataType {
  SHORT_TEXT = 'shorttext',
  LONG_TEXT = 'longtext',
  DATE = 'date',
  DATE_TIME = 'datetime',
  ENUM_SINGLE = 'enumsingle',
  ENUM_MULTIPLE = 'enummultiple',
  CURRENCY = 'currency',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  HYPERLINK = 'hyperlink',
  SUMMARY = 'summary',
}

// Used for attributes on schema, asset, work order assets
export type AttributeDataType =
  | Exclude<
      DataType,
      DataType.SUMMARY | DataType.DATE_TIME | DataType.HYPERLINK
    >
  | DataType;

// Used for fields on locations on work orders
export type LocationFieldDataType =
  | Exclude<
      DataType,
      | DataType.SUMMARY
      | DataType.HYPERLINK
      | DataType.BOOLEAN
      | DataType.CURRENCY
    >
  | DataType;

// Used for custom fields on tasks
export type TaskFieldDataType =
  | Exclude<DataType, DataType.SUMMARY | DataType.BOOLEAN | DataType.CURRENCY>
  | DataType;

// Used for custom fields on work orders
export type WorkOrderFieldDataType =
  | Exclude<DataType, DataType.BOOLEAN | DataType.CURRENCY>
  | DataType;
