import React from 'react';

import colors from '@atom/styles/colors';
import { MediaItem } from '@atom/types/media';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';

import '../workOrderDetailPdfReport.css';

const styles = {
  photoDiv: {
    border: `1px solid ${colors.neutral.ash}`,
    display: 'inline-block',
    margin: '0.5rem',
  },
  photoName: {
    padding: '0 0.5rem 0.5rem 0.5rem',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
};

interface Props {
  photos: MediaItem[];
  title?: string;
  elements?: any[];
  id: string;
  setOtherPhotosLoading: (loadingState: any) => void;
}

const PhotoTile = ({
  photos,
  title,
  elements,
  id,
  setOtherPhotosLoading,
}: Props) => {
  const getPhotoName = (name: string) =>
    name.toLowerCase().includes('mobile') ? '' : ` / ${name}`;

  return (
    <div styleName="page-break-avoid">
      {photos.map(photo => {
        const photoStyle = {
          margin: '0.5rem',
          flex: '1 1 auto',
          background: `url('${FILES_ENDPOINT}/${photo.fileId}_360') no-repeat center`,
          backgroundSize: 'contain',
        };

        return (
          id === photo.subjectId && (
            <div style={styles.photoDiv}>
              <div styleName="photo-tile gwrr" key={photo.id}>
                <div style={photoStyle} />
                <div style={styles.photoName}>
                  <div>{`${title}${getPhotoName(photo.name)}`}</div>
                </div>
              </div>
            </div>
          )
        );
      })}

      {!!elements.length &&
        elements.map(element => (
          <PhotoTile
            elements={element.elements}
            title={`${title} / ${element.name}`}
            photos={photos}
            id={element.id}
            setOtherPhotosLoading={setOtherPhotosLoading}
          />
        ))}
    </div>
  );
};

export default PhotoTile;
