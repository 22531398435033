import React from 'react';

import { CalendarView } from '@atom/hooks/useWorkOrdersFilters';
import { Button, Icon, IconButton, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';

import './workOrders.css';

export interface Props {
  onNavigate: (keyword: string) => void;
  onView: (view: string) => void;
  view: CalendarView;
  label: string;
  loading: boolean;
}

const styles = {
  button: {
    border: `1px solid ${colors.neutral.ash}`,
    color: colors.neutral.dim,
  },
  toggle: {
    border: 'none',
  },
  selected: {
    background: colors.opacity.black0_1,
  },
};

const WorkCalendarToolbar = ({
  onNavigate,
  onView,
  view,
  loading,
  label,
}: Props) => {
  const handleChange = (option: CalendarView) => {
    onView(option);
  };

  const getToggleStyles = (calendarView: CalendarView) => ({
    ...styles.button,
    ...styles.toggle,
    ...(view === calendarView ? styles.selected : {}),
  });

  return (
    <div styleName="calendar-toolbar-container">
      <div styleName="calendar-toolbar-left-controls">
        <Button style={styles.button} onClick={() => onNavigate('TODAY')}>
          Today
        </Button>
        <div styleName="date-label">{label}</div>
        <IconButton onClick={() => onNavigate('PREV')}>
          <Icon>keyboard_arrow_left</Icon>
        </IconButton>
        <IconButton onClick={() => onNavigate('NEXT')}>
          <Icon>keyboard_arrow_right</Icon>
        </IconButton>
      </div>
      <div styleName="right-container">
        {loading && (
          <div styleName="loading-container">
            <Progress size={20} thickness={1.5} />
          </div>
        )}
        <div>
          <Button
            style={getToggleStyles(CalendarView.MONTH)}
            onClick={() => handleChange(CalendarView.MONTH)}
          >
            Month
          </Button>
          <Button
            style={getToggleStyles(CalendarView.WEEK)}
            onClick={() => handleChange(CalendarView.WEEK)}
          >
            Week
          </Button>
          <Button
            style={getToggleStyles(CalendarView.DAY)}
            onClick={() => handleChange(CalendarView.DAY)}
          >
            Day
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WorkCalendarToolbar;
