import React from 'react';

import { usePreferences } from '@atom/hooks/usePreferences';
// @ts-ignore
import logo from '@atom/images/logo.svg';
import {
  Environment,
  isCurrentEnvironment,
} from '@atom/utilities/featureToggleUtilities';
import history from '@atom/utilities/history';
import { getPortals } from '@atom/utilities/portalUtilities';

import './navigation.css';

interface Props {
  pathname?: string;
}

const Logo = ({ pathname }: Props) => {
  const preferences = usePreferences();
  const landingPortal = getPortals(preferences)[preferences.landingPortal];
  const DASHBOARD_PATHS = ['/', landingPortal?.urlPath];

  const navigateToDashboard = () => {
    if (!DASHBOARD_PATHS.includes(pathname)) {
      history.push(landingPortal.urlPath);
    }
  };

  return (
    <div styleName="logo-testEnv">
      <img
        onClick={navigateToDashboard}
        src={logo}
        styleName="logo"
        alt="Atom Logo"
      />
      {isCurrentEnvironment([
        Environment.DEV,
        Environment.QA,
        Environment.UAT,
      ]) && (
        <div id="testEnv" styleName="testDiv">
          <span>Test Environment</span>
        </div>
      )}
    </div>
  );
};

export default Logo;
