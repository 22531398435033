import api from '@atom/utilities/api';
import { getWorkOrderAssetsEndpoint } from '@atom/utilities/endpoints';

import actionTypes from './actionTypes';

export const requestCreateWorkOrderAsset = data => ({
  type: actionTypes.REQUEST_CREATE_WORK_ORDER_ASSET,
  data,
});

export const createWorkOrderAssetSuccess = data => ({
  type: actionTypes.CREATE_WORK_ORDER_ASSET_SUCCESS,
  data,
});

export const createWorkOrderAssetFailure = () => ({
  type: actionTypes.CREATE_WORK_ORDER_ASSET_FAILURE,
});

export const retrieveWorkOrderAsset = data => ({
  type: actionTypes.REQUEST_WORK_ORDER_ASSET,
  data,
});

export const getWorkOrderAssetSuccess = data => ({
  type: actionTypes.GET_WORK_ORDER_ASSET_SUCCESS,
  data,
});

export const getWorkOrderAssetFailure = () => ({
  type: actionTypes.GET_WORK_ORDER_ASSET_FAILURE,
});

export const retrieveWorkOrderAssetElements = data => ({
  type: actionTypes.REQUEST_WORK_ORDER_ASSET_ELEMENTS,
  data,
});

export const getWorkOrderAssetElementsSuccess = data => ({
  type: actionTypes.GET_WORK_ORDER_ASSET_ELEMENTS_SUCCESS,
  data,
});

export const getWorkOrderAssetElementsFailure = () => ({
  type: actionTypes.GET_WORK_ORDER_ASSET_ELEMENTS_FAILURE,
});

export const requestPatchWorkOrderAsset = data => ({
  type: actionTypes.REQUEST_PATCH_WORK_ORDER_ASSET,
  data,
});

export const patchWorkOrderAssetSuccess = data => ({
  type: actionTypes.PATCH_WORK_ORDER_ASSET_SUCCESS,
  data,
});

export const patchWorkOrderAssetFailure = () => ({
  type: actionTypes.PATCH_WORK_ORDER_ASSET_FAILURE,
});

export const requestWorkOrderElementTreeNodeUpdate = data => ({
  type: actionTypes.REQUEST_WORK_ORDER_ELEMENT_TREE_NODE_UPDATE,
  data,
});

export const workOrderElementTreeNodeUpdateSuccess = data => ({
  type: actionTypes.GET_WORK_ORDER_ELEMENT_TREE_NODE_UPDATE_SUCCESS,
  data,
});

export const workOrderElementTreeNodeUpdateFailure = () => ({
  type: actionTypes.GET_WORK_ORDER_ELEMENT_TREE_NODE_UPDATE_FAILURE,
});

export const requestDeleteWorkOrderElement = data => ({
  type: actionTypes.REQUEST_DELETE_WORK_ORDER_ASSET,
  data,
});

export const deleteWorkOrderElementSuccess = data => ({
  type: actionTypes.DELETE_WORK_ORDER_ASSET_SUCCESS,
  data,
});

export const deleteWorkOrderElementFailure = () => ({
  type: actionTypes.DELETE_WORK_ORDER_ASSET_FAILURE,
});

export const requestWorkOrderElementBatchCreate = data => ({
  type: actionTypes.REQUEST_WORK_ORDER_ELEMENT_BATCH_CREATE,
  data,
});

export const createWorkOrderElementBatchSuccess = data => ({
  type: actionTypes.CREATE_WORK_ORDER_ELEMENT_BATCH_SUCCESS,
  data,
});

export const createWorkOrderElementBatchFailure = () => ({
  type: actionTypes.CREATE_WORK_ORDER_ELEMENT_BATCH_FAILURE,
});

export function createWorkOrderAsset(workOrderId, body) {
  return async function (dispatch) {
    try {
      const baseEndpoint = getWorkOrderAssetsEndpoint(workOrderId);
      const { data } = await api.post(baseEndpoint, body);
      dispatch(createWorkOrderAssetSuccess(data));
      return data;
    } catch (error) {
      dispatch(createWorkOrderAssetFailure());
      throw error;
    }
  };
}
