import React, { useContext } from 'react';

import LocationsAndAssetsSection from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsSection';
import TaskFields from '@atom/components/common/workOrderDetail/taskFields/TaskFields';
import WorkOrderPreviewContext, {
  WorkOrderActionTypes,
} from '@atom/components/workOrderPreview/WorkOrderPreviewContext';
import { Icon } from '@atom/mui';
import { Task } from '@atom/types/task';
import { WorkOrderType } from '@atom/types/work';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';

import AssetSection from './assetSection/AssetSection';
import FormSection from './formSection/FormSection';
import MaterialSection from './materialSection/MaterialSection';
import TeamSection from './teamSection/TeamSection';
import TaskActualCost from './TaskActualCost';
import TaskCompletedDate from './TaskCompletedDate';
import TaskContext from './TaskContext';
import TaskDescription from './TaskDescription';
import TaskDueDate from './TaskDueDate';
import TaskDuration from './TaskDuration';
import TaskEstimatedCost from './TaskEstimatedCost';
import TaskHeader from './TaskHeader';
import TaskStartDate from './TaskStartDate';
import TaskWorkTime from './TaskWorkTime';

import './taskView.css';

const styles = {
  icon: {
    marginTop: '1rem',
  },
};

interface Props {
  task: Task;
}

const TaskDetail = ({ task }: Props) => {
  const {
    workOrderDetail,
    workValidations,
    setWorkValidations,
    dispatch,
    refetch,
  } = useContext(WorkOrderPreviewContext);

  const showAssetSection =
    workOrderDetail.inventoryAssetId &&
    workOrderDetail?.type === WorkOrderType.DEFAULT;
  const showLocationsAndAssetsSection =
    workOrderDetail?.type === WorkOrderType.TASK_ASSETS_AND_LOCATIONS &&
    (task?.locationsEnabled || task?.multiAssetsEnabled);

  const showDescription = !isCurrentClient([Client.ALDOT]);

  return (
    <TaskContext.Provider value={{ task }}>
      <div styleName="task-container">
        <TaskHeader />
        <div styleName="content-container">
          <div styleName="left-column">
            <div styleName="time-detail-row">
              <Icon style={styles.icon}>today</Icon>
              <div styleName="time-row-container">
                <TaskDueDate />
                <TaskStartDate />
                <TaskCompletedDate />
                <TaskWorkTime />
                <TaskDuration />
              </div>
            </div>
            {showDescription && (
              <div styleName="task-detail-row">
                <TaskDescription />
              </div>
            )}
            <TaskFields
              workOrderDetail={workOrderDetail}
              task={task}
              workValidations={workValidations}
              setWorkValidations={setWorkValidations}
              dispatch={dispatch}
              WorkOrderActionTypes={WorkOrderActionTypes}
              previewView
              refetch={refetch}
            />
            {showAssetSection && <AssetSection />}
            {showLocationsAndAssetsSection && (
              <LocationsAndAssetsSection
                workOrderDetail={workOrderDetail}
                task={task}
                refetch={refetch}
                workValidations={workValidations}
                setWorkValidations={setWorkValidations}
                previewView
              />
            )}
            <FormSection />
          </div>
          <div styleName="right-column">
            <div styleName="cost-container">
              <TaskEstimatedCost />
              <TaskActualCost />
            </div>
            <TeamSection />
            <MaterialSection />
          </div>
        </div>
      </div>
    </TaskContext.Provider>
  );
};

export default TaskDetail;
