import React from 'react';

import { InventoryAssetTreeStateType } from '@atom/types/inventory';
import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';

import '../workOrderDetailPdfReport.css';

enum AttributeGroupNames {
  ELEMENT_DETAILS = 'Element Details',
}

interface Props {
  elements: InventoryAssetTreeStateType[];
  elementName: string;
}

const ElementDetailsTable = ({ elements, elementName }: Props) => {
  const getElementDetailsTable = (assetTypes = [], usedAssetTypes = []) => {
    let attributeMax = 0;
    elements.forEach(element => {
      element.attributeGroups.forEach(attributeGroup => {
        if (attributeGroup.name === AttributeGroupNames.ELEMENT_DETAILS) {
          const attributeLength = attributeGroup.attributes.length;
          if (attributeLength > attributeMax) {
            attributeMax = attributeLength;
          }
          if (!assetTypes.includes(element.assetType)) {
            assetTypes.push(element.assetType);
          }
        }
      });
    });

    const isUsedAssetType = assetType => {
      if (!usedAssetTypes.includes(assetType)) {
        usedAssetTypes.push(assetType);
        return false;
      }
      return true;
    };

    return assetTypes.map((assetType, index) => (
      <div styleName="table-wrapper">
        <table>
          {index === 0 && (
            <thead>
              <tr>
                <th colSpan={attributeMax + 1}>
                  <div styleName="table-header">
                    {elementName} Element Details
                  </div>
                </th>
              </tr>
            </thead>
          )}
          <tbody>
            {elements.map(element => {
              return element.attributeGroups.map(attributeGroup => {
                if (
                  attributeGroup.name === AttributeGroupNames.ELEMENT_DETAILS &&
                  element.assetType === assetType
                ) {
                  return (
                    <>
                      {!isUsedAssetType(assetType) && (
                        <tr styleName="table-subheader">
                          <th>Element</th>
                          {attributeGroup.attributes.map(key => {
                            const style =
                              element.attributes[key].name === 'Comment'
                                ? 'table-header-cell comment'
                                : 'table-header-cell';

                            return (
                              <th styleName={style}>
                                {element.attributes[key].name}
                              </th>
                            );
                          })}
                        </tr>
                      )}
                      <tr styleName="table-value">
                        <td>{element.name}</td>
                        {attributeGroup.attributes.map(key => (
                          <td>
                            {attributeDisplayUtility.display(
                              element.attributes[key].value,
                              element.attributes[key].dataType,
                              element.attributes[key].unit,
                              !!element.attributes[key].changeId,
                              element.attributes[key].newValue,
                            )}
                          </td>
                        ))}
                      </tr>
                    </>
                  );
                }
                return <></>;
              });
            })}
          </tbody>
        </table>
      </div>
    ));
  };

  return <>{getElementDetailsTable()}</>;
};

export default ElementDetailsTable;
