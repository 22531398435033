import * as React from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';

import './header.css';

interface Props {
  title: string;
  navigateBack: () => void;
}

const NavigationTitle = ({ title, navigateBack }: Props) => (
  <div>
    <IconButton tooltip="Back" onClick={navigateBack}>
      <Icon color={colors.neutral.white}>arrow_back</Icon>
    </IconButton>
    <div styleName="title-bar">
      <span>{title}</span>
    </div>
  </div>
);

export default NavigationTitle;
