import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ListTable, Progress } from '@atom/components/common/mui';
import WorkOrderStatusPill from '@atom/components/common/workOrderDetail/workOrderStatusPill/WorkOrderStatusPill';
import colors from '@atom/styles/colors';
import { AssetUsageItem } from '@atom/types/assetUsage';
import { InventoryAssetDetailType } from '@atom/types/inventory';
import api from '@atom/utilities/api';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { INVENTORY_ASSETS_ENDPOINT } from '@atom/utilities/endpoints';
import { TOTAL_COUNT_HEADER } from '@atom/utilities/requestUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';

const styles = {
  link: {
    fontWeight: 500,
  },
  row: {
    backgroundColor: colors.neutral.white,
    height: '3rem',
  },
};

const {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} = ListTable;

interface Props {
  asset: InventoryAssetDetailType;
}

const UsageTable = ({ asset }: Props) => {
  const [usage, setUsage] = useState<AssetUsageItem[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(25);
  const [loadingUsage, setLoadingUsage] = useState<boolean>(true);

  useEffect(() => {
    const getUsage = async () => {
      const { data, headers } = await api.get(
        `${INVENTORY_ASSETS_ENDPOINT}/${asset.id}/usage`,
        {
          page,
          limit,
        },
      );

      setUsage(data);
      setTotalCount(Number(headers[TOTAL_COUNT_HEADER]) || 0);
      setLoadingUsage(false);
    };

    if (asset.isMaterial) {
      getUsage();
    }
  }, [asset.id, page, limit, asset?.budget?.quantityOnHand]);

  const convertNumberToLocaleString = (input: string | number): string => {
    return Number(input).toLocaleString('en-US');
  };

  return loadingUsage ? (
    <Progress />
  ) : (
    <ListTable offsetTop="360px">
      <TableHead>
        <TableRow header>
          <TableCell variant="head">Work ID</TableCell>
          <TableCell variant="head">Status</TableCell>
          <TableCell variant="head">Due Date</TableCell>
          <TableCell variant="head">Completion Date</TableCell>
          <TableCell variant="head">Usage</TableCell>
          <TableCell variant="head">Cost</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {usage.map(usageItem => (
          <TableRow key={usageItem.id} style={styles.row}>
            <TableCell style={styles.link}>
              <Link to={`/workOrders/${usageItem.id}`}>{usageItem.name}</Link>
            </TableCell>
            <TableCell>
              <WorkOrderStatusPill
                className="table"
                statusId={usageItem.statusId}
                style={{ margin: 0 }}
              />
            </TableCell>
            <TableCell>{setDisplayDate(usageItem.dueDate)}</TableCell>
            <TableCell>{setDisplayDate(usageItem.completionDate)}</TableCell>
            <TableCell>
              {convertNumberToLocaleString(usageItem.totalUsage)}
            </TableCell>
            <TableCell>{numberToLocaleString(usageItem.totalCost)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            count={totalCount}
            rowsPerPage={limit}
            page={page}
            onPageChange={data => setPage(data)}
            onRowsPerPageChange={event => {
              setLimit(+event.target.value);
              setPage(1);
            }}
          />
        </TableRow>
      </TableFooter>
    </ListTable>
  );
};

export default UsageTable;
