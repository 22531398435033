import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import * as R from 'ramda';

import {
  useGoogleMapsStateUpdater,
  useGoogleMapsStateValue,
  withGoogleMapsState,
} from '@atom/components/common/map/hooks/googleMapsStateHook';
import Map from '@atom/components/common/map/Map';
import MapMarkers from '@atom/components/common/map/MapMarkers';
import MapPinMarker from '@atom/components/common/map/markers/MapPinMarker';
import LocationsAndAssetsContext from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsContext';
import { useMapData } from '@atom/components/mapPortal/hooks/mapDataHook';
import { GET_TASK_BOUNDING_BOX } from '@atom/graph/map';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Progress } from '@atom/mui';
import {
  defaultClusterDensitySelector,
  getLoggedInUserMapLocationSelector,
} from '@atom/selectors/mapSelectors';
import {
  BoundingBox,
  BoundingBoxSubjectType,
  MapParams,
  TaskBoundingBoxInput,
} from '@atom/types/map';
import {
  buildMapDataRequest,
  getGeoJsonFromMapBounds,
  getMapCenterAndZoomParams,
  urlValueToLatLng,
} from '@atom/utilities/mapUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

const LOCATION_MAP_ZOOM = 14;

interface Props {
  cartAssetIds: string[];
  onAssetClick: (id: string) => void;
  hoverId?: string;
  // ids of assets that have already been added to the task
  taskAssetIds?: string[];
}

const TaskAssetMap = ({
  cartAssetIds,
  taskAssetIds,
  onAssetClick,
  hoverId = '',
}: Props) => {
  const { workOrderDetail, task } = useContext(LocationsAndAssetsContext);

  const currentUserLocation = useSelector(getLoggedInUserMapLocationSelector);
  const clusterDensity = useSelector(defaultClusterDensitySelector);

  const dispatch = useGoogleMapsStateUpdater();
  const { isLoaded } = useGoogleMapsStateValue();
  const preferences = usePreferences();

  const [map, setMap] = useState<google.maps.Map>(null);
  const [coordinates, setCoordinates] = useState<string>(null);
  const [loadedInitialBox, setLoadedInitialBox] = useState<boolean>(false);
  const [mapParams, setMapParams] = useState<MapParams>({
    ids: cartAssetIds,
    disabledIds: taskAssetIds,
    hoverId: null,
    isActive: false,
    center: null,
    zoom: LOCATION_MAP_ZOOM,
    searchPoint: null,
    searchTerm: null,
  });

  const [
    getTaskBoundingBox,
    { data: taskBoundingBoxData, loading: loadingTaskBoundingBox },
  ] = useLazyQuery<
    { taskBoundingBox: BoundingBox },
    { input: TaskBoundingBoxInput }
  >(GET_TASK_BOUNDING_BOX, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const boundingBox = data?.taskBoundingBox;

      if (map) {
        map.fitBounds({
          east: boundingBox?.xMax,
          north: boundingBox?.yMax,
          south: boundingBox?.yMin,
          west: boundingBox?.xMin,
        });
      }
    },
  });

  const boundingBox = taskBoundingBoxData?.taskBoundingBox;

  const retrieveTaskBoundingBox = async () => {
    getTaskBoundingBox({
      variables: {
        input: {
          taskId: task?.id,
          workOrderId: workOrderDetail?.id,
          subjectType: BoundingBoxSubjectType.ASSET,
        },
      },
    });
  };

  useEffect(() => {
    if (!loadedInitialBox && !isNilOrEmpty(taskAssetIds)) {
      retrieveTaskBoundingBox();
    }
  }, [loadedInitialBox]);

  const updateMapParams = (params: MapParams) => {
    setMapParams({
      ...mapParams,
      ...params,
    });
  };

  useEffect(() => {
    if (hoverId) {
      updateMapParams({ ids: [], hoverId });
    } else {
      updateMapParams({ ids: cartAssetIds, hoverId: '' });
    }
  }, [hoverId, cartAssetIds]);

  useEffect(() => {
    dispatch({
      type: 'SET',
      payload: {
        key: 'grab',
        data: false,
      },
    });
  }, []);

  const onLoad = useCallback(setMap, []);

  const onIdle = useCallback(() => {
    try {
      const params = getMapCenterAndZoomParams(
        map,
        mapParams,
        preferences,
        currentUserLocation,
      );

      if (!R.equals(mapParams, params) || !coordinates) {
        updateMapParams(params);
        R.pipe(
          getGeoJsonFromMapBounds,
          R.prop('coordinates'),
          JSON.stringify,
          setCoordinates,
        )(map);

        if (
          !loadedInitialBox &&
          !loadingTaskBoundingBox &&
          boundingBox &&
          !isNilOrEmpty(taskAssetIds)
        ) {
          setLoadedInitialBox(true);

          map.fitBounds({
            east: boundingBox?.xMax,
            north: boundingBox?.yMax,
            south: boundingBox?.yMin,
            west: boundingBox?.xMin,
          });
        }
      }
    } catch {
      setMapParams(null);
    }
  }, [
    map,
    setMapParams,
    mapParams,
    coordinates,
    preferences,
    loadingTaskBoundingBox,
    boundingBox,
    loadedInitialBox,
    setLoadedInitialBox,
  ]);

  const mapLayers = {
    assets: true,
    users: false,
    work: false,
    assetSchemas: new Set(task?.allowedMultiAssetSchemaIds),
    workStatusIds: new Set([]),
    kmlLayers: false,
    kmlLayerIds: new Set([]),
    workTemplateIds: new Set([]),
    workTemplateFolderIds: new Set([]),
    clusterDensity,
  };

  const url = buildMapDataRequest(mapLayers, {
    coordinates,
    zoom: mapParams.zoom,
  });

  const [mapData, loadingMapData] = useMapData(url);

  return !isLoaded ? (
    <Progress
      style={{
        height: '100%',
      }}
    />
  ) : (
    <Map
      onIdle={onIdle}
      onLoad={onLoad}
      // @ts-ignore
      center={mapParams.center && urlValueToLatLng(mapParams.center)}
      zoom={mapParams.zoom}
      mapParams={mapParams}
      updateMapParams={updateMapParams}
      mapStyleControl
      mapSearchBox
      layerControls
      loading={loadingMapData}
    >
      <MapMarkers
        // @ts-ignore
        data={JSON.stringify(mapData)}
        onClick={onAssetClick}
        mapParams={mapParams}
        updateMapParams={updateMapParams}
      />
      {mapParams.searchPoint && <MapPinMarker center={mapParams.searchPoint} />}
    </Map>
  );
};

export default withGoogleMapsState<Props>(TaskAssetMap);
