import React, { useContext } from 'react';
import * as R from 'ramda';

import { Icon } from '@atom/mui';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import ExpendituresChartContext from './ExpendituresChartContext';
import {
  BarIcon,
  GroupKeys,
  LineIcon,
  RechartPayload,
} from './expendituresChartUtils';

const styles = {
  legendContainer: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'center',
  },
  legendItemsColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  legendItemsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
  legendLabel: {
    width: '8rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    cursor: 'pointer',
    fontWeight: 500,
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    cursor: 'pointer',
  },
  legendName: {
    maxWidth: '12rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  refreshIcon: {
    cursor: 'pointer',
  },
};

interface Props {
  payload?: RechartPayload[];
  onClick?: (elemKey: string) => void;
  onMouseLeave?: (elemKey: string) => void;
  onMouseEnter?: (elemKey: string) => void;
}

const ExpendituresChartLegend = ({
  payload,
  onClick,
  onMouseLeave,
  onMouseEnter,
}: Props) => {
  const {
    hiddenGroup,
    focusDataKey,
    actualFields,
    remainingFields,
    handleLabelClick,
    refreshData,
  } = useContext(ExpendituresChartContext);

  const entriesMap = R.groupBy(R.prop('dataKey'), payload);
  const getEntry = dataKey => R.pathOr('', [dataKey, 0], entriesMap);
  const getField = (field, val) => R.pathOr('', [field, 0, val], entriesMap);

  const actualCostValFocused =
    !isNilOrEmpty(focusDataKey) &&
    actualFields.some(field => field === focusDataKey);

  const remainingValFocused =
    !isNilOrEmpty(focusDataKey) &&
    remainingFields.some(field => field === focusDataKey);

  return !payload?.length ? null : (
    <div style={styles.legendContainer}>
      <div style={styles.legendItemsColumn}>
        <div
          style={{
            ...styles.legendItemsContainer,
            opacity:
              hiddenGroup === GroupKeys.REMAINING || actualCostValFocused
                ? 0.5
                : 1,
          }}
        >
          <div
            style={styles.legendLabel}
            onClick={() => handleLabelClick(GroupKeys.ACTUAL)}
          >
            {GroupKeys.REMAINING}
          </div>
          {remainingFields.map(fieldName => (
            <div
              key={fieldName}
              onClick={() => onClick(getEntry(fieldName))}
              onMouseLeave={() =>
                hiddenGroup !== GroupKeys.REMAINING &&
                onMouseLeave(getEntry(fieldName))
              }
              onMouseEnter={() =>
                hiddenGroup !== GroupKeys.REMAINING &&
                onMouseEnter(getEntry(fieldName))
              }
              style={{
                ...styles.legendItem,
                opacity: isNilOrEmpty(focusDataKey)
                  ? 1
                  : focusDataKey === fieldName
                  ? 1
                  : 0.5,
              }}
            >
              {LineIcon(getField(fieldName, 'color'))}
              <div style={styles.legendName}>
                {fieldName.split(GroupKeys.REMAINING)[0]}
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            ...styles.legendItemsContainer,
            opacity:
              hiddenGroup === GroupKeys.ACTUAL || remainingValFocused ? 0.5 : 1,
          }}
        >
          <div
            style={styles.legendLabel}
            onClick={() => handleLabelClick(GroupKeys.REMAINING)}
          >
            {GroupKeys.ACTUAL}
          </div>
          {actualFields.map(fieldName => (
            <div
              key={fieldName}
              onClick={() => onClick(getEntry(fieldName))}
              onMouseLeave={() =>
                hiddenGroup !== GroupKeys.ACTUAL &&
                onMouseLeave(getEntry(fieldName))
              }
              onMouseEnter={() =>
                hiddenGroup !== GroupKeys.ACTUAL &&
                onMouseEnter(getEntry(fieldName))
              }
              style={{
                ...styles.legendItem,
                opacity: isNilOrEmpty(focusDataKey)
                  ? 1
                  : focusDataKey === fieldName
                  ? 1
                  : 0.5,
              }}
            >
              {BarIcon(getField(fieldName, 'color'))}
              {fieldName.split(GroupKeys.ACTUAL)[0]}
            </div>
          ))}
        </div>
      </div>
      {/* @ts-ignore */}
      <Icon
        style={{
          ...styles.refreshIcon,
          visibility: hiddenGroup || focusDataKey ? 'visible' : 'hidden',
        }}
        onClick={refreshData}
        title="Reset Chart Filters"
      >
        refresh
      </Icon>
    </div>
  );
};

export default ExpendituresChartLegend;
