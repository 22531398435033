import React, { useContext } from 'react';

import TaskContext from '@atom/components/workOrderPreview/taskView/TaskContext';
import { Icon, IconButton } from '@atom/mui';
import { setWorkTimeDisplay } from '@atom/utilities/timeUtilities';

import './taskView.css';

const styles = {
  iconButton: {
    width: '1rem',
    height: '1rem',
    padding: '0 0 0.2rem 0',
  },
  icon: {
    fontSize: '1rem',
  },
};

const TaskWorkTime = () => {
  const { task } = useContext(TaskContext);

  const tooltipContent = (
    <div styleName="tooltip-content">
      <div>Total labor time for this task.</div>
    </div>
  );

  return (
    <div styleName="time-row-item">
      <div styleName="label-container">
        <div styleName="label">Work Time</div>
        <IconButton tooltip={tooltipContent} style={styles.iconButton}>
          <Icon style={styles.icon}>help</Icon>
        </IconButton>
      </div>
      <div styleName="date-display">{setWorkTimeDisplay(task.workTime)}</div>
    </div>
  );
};

export default TaskWorkTime;
