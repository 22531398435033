import { InheritedComponentType } from '@atom/types/workTemplate';

export const inheritedTypeTitles = {
  [InheritedComponentType.CUSTOM_FIELD]: 'Custom fields',
  [InheritedComponentType.SUMMARY]: 'Summary',
  [InheritedComponentType.MEDIA]: 'Documents, photos and videos',
  [InheritedComponentType.TASK_CUSTOM_FIELD]: 'Custom fields',
  [InheritedComponentType.TASK_USER_GROUP]: 'Groups',
  [InheritedComponentType.TASK_ASSIGNEE]: 'Team members',
  [InheritedComponentType.TASK_SCHEMA]: 'Assets',
  [InheritedComponentType.TASK_MATERIAL]: 'Equipment and material',
  [InheritedComponentType.TASK_CATEGORY]: 'Equipment and material folders',
  [InheritedComponentType.TASK_FORM_TEMPLATE]: 'Forms',
  [InheritedComponentType.TASK_ESTIMATED_COST]: 'Estimated Cost',
};

export const workInheritedTypes = [
  InheritedComponentType.CUSTOM_FIELD,
  InheritedComponentType.SUMMARY,
  InheritedComponentType.MEDIA,
];

export const taskInheritedTypes = [
  InheritedComponentType.TASK_CUSTOM_FIELD,
  InheritedComponentType.TASK_USER_GROUP,
  InheritedComponentType.TASK_ASSIGNEE,
  InheritedComponentType.TASK_SCHEMA,
  InheritedComponentType.TASK_MATERIAL,
  InheritedComponentType.TASK_CATEGORY,
  InheritedComponentType.TASK_FORM_TEMPLATE,
  InheritedComponentType.TASK_ESTIMATED_COST,
];

export const getInheritedTypeTitle = (type: InheritedComponentType): string => {
  return inheritedTypeTitles[type];
};
