import { WorkOrderColumnProperty } from './workColumns';

export enum DashboardCardType {
  OPEN_WORK = 'OPEN_WORK',
  PENDING_USERS = 'PENDING_USERS',
  ASSET_COUNT = 'ASSET_COUNT',
  PAST_DUE_WORK = 'PAST_DUE_WORK',
  RECENTLY_UPDATED = 'RECENTLY_UPDATED',
  EMBED = 'EMBED',
}

export enum DashboardCardLoadStyle {
  CHART = 'CHART',
  TABLE = 'TABLE',
  LIST = 'LIST',
}

export enum DashboardCardSize {
  SM = 'SM',
  LG = 'LG',
}

export enum DashboardCardContentState {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  EMPTY = 'EMPTY',
  READY = 'READY',
}

export enum ItemType {
  ASSET = 'EMPTY',
  USER = 'USER',
  WORK_ORDER = 'WORK_ORDER',
}

export type ColumnsData = string[][];

export interface DashboardCardsConnectionInput {
  userId: string;
}

export interface DashboardCardCreateInput {
  userId: string;
  type: DashboardCardType;
  title: string;
  size: DashboardCardSize;
  embedLink: string;
}

export interface DashboardCardUpdateInput {
  userId: string;
  id: string;
  title: string;
  size: DashboardCardSize;
  embedLink: string;
}

export interface DashboardCardDeleteInput {
  userId: string;
  id: string;
}

export interface DashboardCardsConnection {
  totalCount: number;
  dashboardCards: DashboardCard[];
}

export interface DashboardLayoutData {
  columns: ColumnsData;
  id: string;
}

export interface DashboardCardsLayoutConnection {
  totalCount: number;
  dashboardCardsLayout: DashboardLayoutData;
}

export interface DashboardCardsLayoutUpdateInput {
  userId: string;
  id: string;
  columns: ColumnsData;
}

export interface RecentlyUpdatedItem {
  workOrderId?: string;
  workOrderName?: string;
  workTemplateName?: string;
  statusId?: string;
  assetId?: string;
  assetName?: string;
  schemaName?: string;
  isMaterial?: Boolean;
  colorId?: number;
  markerId?: number;
  userId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  photoUrl?: string;
  itemType?: ItemType;
  updatedDate?: number;
}

export interface RecentlyUpdatedItemsConnection {
  totalCount: number;
  workOrders: RecentlyUpdatedItem[];
}

interface DashboardCardData {
  id?: string;
  userId?: string;
  type?: DashboardCardType;
  title: string;
  size: DashboardCardSize;
  embedLink?: string;
}

export type TableColumn = {
  property: WorkOrderColumnProperty;
  label: string;
  sortable?: boolean;
};

// DashboardCardUIState contains any state properties used on all
//   cards regardless of type.
// For example, if we add a "maximize"
//   state to zoom a single card to the full view, we would add a
//   "maximized" field here with the id of the maximized card.
interface DashboardCardUIState {
  contentState?: DashboardCardContentState;
  order?: number;
}

interface DashboardCardConfiguration {
  getCardData?: any;
  portalLink?: (userId?: string) => string;
  emptyHeight?: number;
  loadStyle?: DashboardCardLoadStyle;
  tableColumns?: TableColumn[];
}

export type DashboardCard = DashboardCardData &
  DashboardCardConfiguration &
  DashboardCardUIState;

export interface DashboardCardConfigurations {
  [key: string]: DashboardCardConfiguration;
}
