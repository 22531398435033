import * as React from 'react';

import CarouselWrapper from '@atom/components/common/photoCarousel/CarouselWrapper';
import TextField from '@atom/components/common/TextField';
import TextLimit from '@atom/components/common/TextLimit';
import {
  MAX_CHAR_SHORT_INPUT,
  REVEAL_LONG,
} from '@atom/selectors/formSelectors';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { EventType } from '@atom/types/event';
import { FormFieldType } from '@atom/types/form';
import { MediaItem } from '@atom/types/media';

import FileUploadButton from '../FileUploadButton';
import FileUploadTile from '../FileUploadTile';

import '../formInstance.css';

const styles = {
  inputStyles: {
    fontSize: fonts.md,
    color: colors.neutral.dark,
  },
  floatingLabelStyles: {
    fontSize: fonts.md,
    color: colors.neutral.dark,
  },
};

interface PassedProps {
  field: FormFieldType;
  fieldId: string;
  media: MediaItem[];
  fieldFileUpload: (event: EventType) => void;
  onChange: (event: EventType) => void;
  removeFile: (mediaId: string, subjectId: string) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  value: any;
  isEditable: boolean;
  isUploadDisabled: boolean;
}

type Props = PassedProps;

const ShortTextField = ({
  field,
  fieldId,
  onChange,
  value,
  removeFile,
  isEditable,
  media,
  fieldFileUpload,
  isUploadDisabled,
  renameMedia,
}: Props) => {
  const required = field.required ? 'required' : '';
  const medium = media.filter((med: MediaItem) => med.fieldId === fieldId);

  return (
    <div styleName="field-block">
      <div styleName={`short-textfield-container ${required}`}>
        <TextField
          name={field.type}
          floatingLabelText={field.title}
          style={styles.inputStyles}
          floatingLabelStyle={styles.floatingLabelStyles}
          fullWidth
          onChange={onChange}
          value={value}
          disabled={!isEditable}
        />
        {field.fileUpload && (
          <FileUploadButton
            onChange={fieldFileUpload}
            isUploadDisabled={!isEditable || isUploadDisabled}
          />
        )}
      </div>
      <div styleName="short-description-container">
        <div styleName="field-description">{field.description}</div>
        <TextLimit
          limit={MAX_CHAR_SHORT_INPUT}
          reveal={REVEAL_LONG}
          text={value}
        />
      </div>
      <div styleName="file-tile-container">
        {medium.map((mediaItem: MediaItem) => (
          <CarouselWrapper
            media={media}
            selectedMediaId={mediaItem.id}
            removeFile={removeFile}
            subjectId={fieldId}
            renameMedia={renameMedia}
            canUpdateMedia={isEditable}
            canDeleteMedia={isEditable}
          >
            <FileUploadTile key={mediaItem.id} media={mediaItem} />
          </CarouselWrapper>
        ))}
      </div>
    </div>
  );
};

export default ShortTextField;
