import React, { useMemo } from 'react';
import * as R from 'ramda';

import {
  FormInstanceFieldItem,
  PciModuleDistress,
  PciModuleInspectionOption,
} from '@atom/types/form';

import { HAS_NO_DISTRESS_OPTION } from './NbiInspection';

import './pci.css';

interface Props {
  field: FormInstanceFieldItem;
}

const NbiSummary = ({ field }: Props) => {
  const distressSummary = R.pathOr<PciModuleDistress>(
    null,
    ['pci', 'distress', 0],
    field,
  );

  const distressSummaryOption = useMemo<PciModuleInspectionOption>(() => {
    if (field.pci?.hasNoDistress) {
      return HAS_NO_DISTRESS_OPTION;
    }

    if (!distressSummary || !field?.pci?.options) {
      return null;
    }

    return field.pci.options.distress.find(
      option => option.name === distressSummary.distress,
    );
  }, [field]);

  return (
    distressSummaryOption && (
      <div styleName="inspection-container">
        <div styleName="subtitle">Inspection</div>
        <div styleName="label">
          Which description is most appropriate for this item's condition?
        </div>
        <div styleName="distress-container">
          <div styleName="distress-label">{`${distressSummaryOption.points} - ${distressSummaryOption.name}`}</div>
          <div styleName="distress-description">
            {distressSummaryOption.description}
          </div>
          {distressSummaryOption.descriptionSubtexts &&
            distressSummaryOption.descriptionSubtexts.map(subtext => (
              <div key={subtext} styleName="distress-subtext-container">
                <div styleName="distress-bullet" />
                <div styleName="distress-subtext">{subtext}</div>
              </div>
            ))}
        </div>
      </div>
    )
  );
};

export default NbiSummary;
