import React, { useState } from 'react';
import * as R from 'ramda';
import { useQueryParam } from 'use-query-params';

import NewWorkOrderIcon from '@atom/components/common/svgIcons/NewWorkOrderIcon';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';

import ListItemWrapper from '../drawerContentWrappers/ListItemWrapper_v2';
import { useListView } from '../hooks/listViewHook';
import { initialState } from '../hooks/mapDataHook';

import BulkCreateModal from './bulkCreateModal/BulkCreateModal';
import BulkLimitModal from './BulkLimitModal';
import ContentListHeader from './ContentListHeader';
import NoItemsPane from './NoItemsPane_v2';

import './drawerContent.css';

const getArrayDepth = value =>
  Array.isArray(value) ? 1 + Math.max(...value.map(getArrayDepth)) : 0;
const sortAssets = R.sortBy(
  R.pipe(R.path(['location', 'coordinates']), getArrayDepth),
);

const DrawerContentList = () => {
  const [data = initialState] = useListView();
  const [activeId, setActiveId] = useQueryParam('id');
  const [workOpen, setWorkOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [assetOpen, setAssetOpen] = useState(true);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [limitModalOpen, setLimitModalOpen] = useState<boolean>(false);

  const toggleWork = () => setWorkOpen(!workOpen);
  const toggleUser = () => setUserOpen(!userOpen);
  const toggleAsset = () => setAssetOpen(!assetOpen);

  // @ts-ignore
  const ids = activeId && activeId.split(',');
  const { clusters = [], workOrders = [], users = [], assets = [] } = data;
  const length = workOrders.length + users.length + assets.length;
  const sortedAssets = sortAssets(assets);

  const closeCreateModal = () => setCreateModalOpen(false);
  const closeLimitModal = () => setLimitModalOpen(false);

  // Bulk work warning and info message section is toggled via client
  const enableBulkImplementation = isCurrentClient([Client.HENNEPIN]);

  const createWorkInBulk = () => {
    if (assets.length > 5000) {
      setLimitModalOpen(true);
    } else {
      setCreateModalOpen(true);
    }
  };

  return (
    <div styleName="sidepane-list-container">
      {enableBulkImplementation && length === 0 && (
        <div styleName="drawer-content-list">
          <NoItemsPane
            icon="location_off"
            text="No items to show"
            subtext={
              clusters.length > 0
                ? 'Please zoom closer on the map to view items'
                : null
            }
          />
        </div>
      )}
      {!enableBulkImplementation && !ids && (
        <div styleName="list-header">{length} items</div>
      )}
      {ids && (
        <div styleName="list-header">
          Identify results
          <div styleName="close-button">
            <IconButton
              onClick={() => {
                setActiveId('');
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </div>
        </div>
      )}
      <div styleName="drawer-content-list">
        {enableBulkImplementation && (
          <ContentListHeader
            count={workOrders.length}
            label="Work Order"
            open={workOpen}
            toggle={toggleWork}
          />
        )}
        {(workOpen || !enableBulkImplementation) &&
          workOrders
            .filter(({ id }) => !ids || R.includes(id, ids))
            .map(workOrder => (
              <ListItemWrapper
                key={workOrder.id}
                row={workOrder}
                param={'workOrder'}
              />
            ))}
        {enableBulkImplementation && (
          <ContentListHeader
            count={users.length}
            label="Team Member"
            open={userOpen}
            toggle={toggleUser}
          />
        )}
        {(userOpen || !enableBulkImplementation) &&
          users
            .filter(({ id }) => !ids || R.includes(id, ids))
            .map(user => (
              <ListItemWrapper key={user.id} row={user} param={'user'} />
            ))}
        {enableBulkImplementation && (
          <ContentListHeader
            actionIcon={<NewWorkOrderIcon color={colors.neutral.gray} />}
            actionOnClick={createWorkInBulk}
            actionTooltip="Create Work in Bulk"
            count={sortedAssets.length}
            label="Inventory Item"
            open={assetOpen}
            toggle={toggleAsset}
          />
        )}
        {(assetOpen || !enableBulkImplementation) &&
          sortedAssets
            .filter(({ id }) => !ids || R.includes(id, ids))
            .map(asset => (
              // @ts-ignore
              <ListItemWrapper key={asset.id} row={asset} param={'asset'} />
            ))}
      </div>
      <BulkCreateModal
        assets={assets}
        open={createModalOpen}
        onClose={closeCreateModal}
      />
      <BulkLimitModal open={limitModalOpen} onConfirm={closeLimitModal} />
    </div>
  );
};

export default DrawerContentList;
