import React from 'react';
import * as R from 'ramda';

import { TextField } from '@atom/mui';

interface Props {
  id: string;
  value: any;
  onChange: (value: any) => void;
  disabled: boolean;
  error: boolean;
  dataCyPrefix: string;
}

const NumberInput = ({
  id,
  value,
  onChange,
  disabled,
  error,
  dataCyPrefix,
}: Props) => {
  const handleChange = (number: string) => {
    onChange(!R.isNil(number) ? parseFloat(number) : '');
  };

  const data = !R.isNil(value) ? value : null;

  return (
    <TextField
      key={id}
      type="number"
      value={data}
      onChange={event => handleChange(event.target.value)}
      disabled={disabled}
      error={error}
      data-cy={`${dataCyPrefix}CurrencyInput`}
    />
  );
};

export default NumberInput;
