import React, { useState } from 'react';

import { Button, DatePicker, TextField } from '@atom/mui';

import '../../styles/left-pane.css';

interface FilterState {
  createdOnDateStart?: number | object;
  createdOnDateEnd?: number | object;
  createdBy?: string;
  workTemplateIds?: string[];
}

const WorkExportsFilter = () => {
  const [filters, setFilters] = useState<FilterState>({});

  const resetFilters = () => {};

  const applyFilters = () => {};

  const updateCreatedOnDateFilter = () => {
    setFilters({
      ...filters,
    });
  };

  const isFilterDisplayed = false;

  return (
    <div styleName="left-pane">
      {isFilterDisplayed && (
        <>
          <div styleName="filters-header">
            <div styleName="filters-header-label">Filter</div>
            <Button onClick={resetFilters}>Reset</Button>
            <Button disabled={false} onClick={() => applyFilters()}>
              Apply
            </Button>
          </div>
          <div styleName="date-range-title">Created on</div>
          <div styleName="date-range-container">
            <DatePicker
              name="createdOnDateStart"
              value={filters.createdOnDateStart}
              onChange={updateCreatedOnDateFilter}
              format="MM/dd/yyyy"
              disabled={false}
            />
            <span
              style={{ alignSelf: 'center', padding: '0 1.25rem 0 1.25rem' }}
            >
              to
            </span>
            <DatePicker
              name="createdOnDateEnd"
              value={filters.createdOnDateEnd}
              onChange={updateCreatedOnDateFilter}
              format="MM/dd/yyyy"
              disabled={false}
            />
          </div>

          <div styleName="select-filter-wrapper">
            <TextField label="Created by" placeholder="Search by name" />
          </div>
        </>
      )}
    </div>
  );
};

export default WorkExportsFilter;
