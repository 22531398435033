import React, { useEffect, useMemo, useState } from 'react';
import { createFilterOptions, Tooltip } from '@mui/material';
import * as R from 'ramda';

import { SelectAutocomplete } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { TaskField } from '@atom/types/task';

interface Props {
  field: TaskField;
  taskId?: string;
  onChange: (fieldId: string, value: any) => void;
  isDisabled?: boolean;
  fieldsLoading?: Set<string>;
  previewView?: boolean;
  isNested?: boolean;
  error?: boolean;
  helperText?: string;
  onClick?: () => void;
}

const filterOptions = createFilterOptions<string>({
  matchFrom: 'any',
  limit: 250,
});

const styles = {
  overflow: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: fonts.sm,
  },
};

const TaskEnumSingleField = ({
  field,
  onChange,
  isDisabled,
  fieldsLoading = new Set([]),
  previewView,
  isNested,
  error,
  helperText,
  onClick,
}: Props) => {
  const [value, setValue] = useState<string>(field.value);

  const handleChange = (event: any, option: string) => {
    const newValue = R.isNil(option) ? '' : option;

    setValue(newValue);
    onChange(field.id, newValue);
  };

  useEffect(() => {
    setValue(field.value);
  }, [field?.value]);

  const nestedField = useMemo(() => {
    if (!field.subFields) {
      return null;
    }

    const nestedFieldIndex = R.findIndex(R.propEq('matchValue', value))(
      field.subFields,
    );

    return field.subFields[nestedFieldIndex]?.nestedField;
  }, [value, field]);

  const title = field.required ? `* ${field.title}` : field.title;
  const inputError = !nestedField ? error : false;

  const loading = field.subFieldsTruncated && fieldsLoading.has(field.id);
  const hasSubFields = !R.isEmpty(field.subFields);
  const selectStyles =
    hasSubFields || isNested
      ? {
          marginRight: '0.5rem',
          marginBottom: '5px',
          width: previewView ? '8.3rem' : '9rem',
        }
      : { flex: previewView ? 1 : 0.66 };

  const tooltipText = (
    <>
      <strong>{value ? `${title}: ` : title}</strong>
      {value && <span>{value}</span>}
    </>
  );

  const titleStyle = {
    ...styles.overflow,
    color: inputError ? colors.utility.muiError : colors.neutral.gray,
  };

  return (
    <>
      <div style={selectStyles}>
        <Tooltip title={tooltipText} placement="right-start">
          <div style={titleStyle}>
            <div style={titleStyle}>{title}</div>
          </div>
        </Tooltip>
        <SelectAutocomplete
          id={field.id}
          options={field.enumeration}
          value={value}
          onChange={handleChange}
          disabled={isDisabled}
          loading={loading}
          helperText={helperText}
          filterOptions={filterOptions}
          TextFieldProps={{ error: inputError, onClick }}
        />
      </div>
      {nestedField && !loading && (
        <TaskEnumSingleField
          field={{ ...nestedField, required: field.required }}
          onChange={onChange}
          isDisabled={isDisabled}
          fieldsLoading={fieldsLoading}
          previewView={previewView}
          isNested
          error={error}
          helperText={helperText}
          onClick={onClick}
        />
      )}
    </>
  );
};

export default TaskEnumSingleField;
