import React from 'react';
import { MenuItem as MUIMenuItem, MenuItemProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';

type CustomProps = {
  style?: React.CSSProperties;
};

type Props = CustomProps & MenuItemProps<any, any>;

const useStyles = makeStyles({
  root: ({ style }: Props) => ({
    minHeight: '2.25rem',
    fontWeight: 500,
    fontSize: fonts.md,
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: 'auto',
    ...style,
  }),
  selected: {
    color: colors.brand.blue,
  },
});

// forwardRef needed to forward ref passed by Select component
const MenuItem = React.forwardRef((props: Props, ref: React.Ref<any>) => {
  const classes = useStyles(props);

  return (
    <MUIMenuItem
      {...props}
      classes={{
        root: classes.root,
        selected: classes.selected,
        ...props?.classes,
      }}
      ref={ref}
    />
  );
});

export default MenuItem;
