import {
  BasicInventoryExportTemplateType,
  ExportFilters,
  ExportTemplateDetailType,
} from '@atom/types/inventoryExport';

import actionTypes from './actionTypes';

export interface RequestExportTemplatesPayload {
  isPublished?: string;
}

export interface RequestCreateExportTemplatesPayload {
  name: string;
}

export interface RequestDeleteExportTemplatesPayload {
  exportTemplateId: string;
}

export interface RequestExportTemplateDetailPayload {
  exportTemplateId: string;
}

export interface RequestUpdateExportTemplatesPayload {
  exportTemplateId: string;
  name?: string;
  isPublished?: boolean;
  categoryIds?: string[];
  rootSchemaId?: string;
}

export interface RequestAddExportTemplateSchemaPayload {
  exportTemplateId: string;
  schemaId: string;
}

export interface RequestDeleteExportTemplateSchemaPayload {
  exportTemplateId: string;
  schemaId: string;
}

export interface RequestUpdateExportTemplateSchemaPayload {
  exportTemplateId: string;
  schemaId: string;
  attributeIds?: string[];
  filters?: ExportFilters;
}

export const requestExportTemplates = (
  data: RequestExportTemplatesPayload,
) => ({
  type: actionTypes.REQUEST_EXPORT_TEMPLATES,
  data,
});

export const getExportTemplatesSuccess = (
  data: BasicInventoryExportTemplateType[],
) => ({
  type: actionTypes.GET_EXPORT_TEMPLATES_SUCCESS,
  data,
});

export const getExportTemplatesFailure = () => ({
  type: actionTypes.GET_EXPORT_TEMPLATES_FAILURE,
});

export const requestExportTemplateDetail = (
  data: RequestExportTemplateDetailPayload,
) => ({
  type: actionTypes.REQUEST_EXPORT_TEMPLATE_DETAIL,
  data,
});

export const getExportTemplateDetailSuccess = (
  data: ExportTemplateDetailType,
) => ({
  type: actionTypes.GET_EXPORT_TEMPLATE_DETAIL_SUCCESS,
  data,
});

export const getExportTemplateDetailFailure = () => ({
  type: actionTypes.GET_EXPORT_TEMPLATE_DETAIL_FAILURE,
});

export const requestExportTemplateCreate = (
  data: RequestCreateExportTemplatesPayload,
) => ({
  type: actionTypes.REQUEST_EXPORT_TEMPLATE_CREATE,
  data,
});

export const createExportTemplateSuccess = (
  data: ExportTemplateDetailType,
) => ({
  type: actionTypes.CREATE_EXPORT_TEMPLATE_SUCCESS,
  data,
});

export const createExportTemplateFailure = () => ({
  type: actionTypes.CREATE_EXPORT_TEMPLATE_FAILURE,
});

export const requestExportTemplateDelete = (
  data: RequestDeleteExportTemplatesPayload,
) => ({
  type: actionTypes.REQUEST_EXPORT_TEMPLATE_DELETE,
  data,
});

export const deleteExportTemplateSuccess = (
  data: ExportTemplateDetailType,
) => ({
  type: actionTypes.DELETE_EXPORT_TEMPLATE_SUCCESS,
  data,
});

export const deleteExportTemplateFailure = () => ({
  type: actionTypes.DELETE_EXPORT_TEMPLATE_FAILURE,
});

export const requestExportTemplateUpdate = (
  data: RequestUpdateExportTemplatesPayload,
) => ({
  type: actionTypes.REQUEST_EXPORT_TEMPLATE_UPDATE,
  data,
});

export const updateExportTemplateSuccess = (
  data: ExportTemplateDetailType,
) => ({
  type: actionTypes.UPDATE_EXPORT_TEMPLATE_SUCCESS,
  data,
});

export const updateExportTemplateFailure = () => ({
  type: actionTypes.UPDATE_EXPORT_TEMPLATE_FAILURE,
});

export const requestAddExportTemplateSchema = (
  data: RequestAddExportTemplateSchemaPayload,
) => ({
  type: actionTypes.REQUEST_ADD_EXPORT_TEMPLATE_SCHEMA,
  data,
});

export const addExportTemplateSchemaSuccess = (
  data: ExportTemplateDetailType,
) => ({
  type: actionTypes.ADD_EXPORT_TEMPLATE_SCHEMA_SUCCESS,
  data,
});

export const addExportTemplateSchemaFailure = () => ({
  type: actionTypes.ADD_EXPORT_TEMPLATE_SCHEMA_FAILURE,
});

export const requestDeleteExportTemplateSchema = (
  data: RequestDeleteExportTemplateSchemaPayload,
) => ({
  type: actionTypes.REQUEST_DELETE_EXPORT_TEMPLATE_SCHEMA,
  data,
});

export const deleteExportTemplateSchemaSuccess = (
  data: ExportTemplateDetailType,
) => ({
  type: actionTypes.DELETE_EXPORT_TEMPLATE_SCHEMA_SUCCESS,
  data,
});

export const deleteExportTemplateSchemaFailure = () => ({
  type: actionTypes.DELETE_EXPORT_TEMPLATE_SCHEMA_FAILURE,
});

export const requestUpdateExportTemplateSchema = (
  data: RequestUpdateExportTemplateSchemaPayload,
) => ({
  type: actionTypes.REQUEST_UPDATE_EXPORT_TEMPLATE_SCHEMA,
  data,
});

export const updateExportTemplateSchemaSuccess = (
  data: ExportTemplateDetailType,
) => ({
  type: actionTypes.UPDATE_EXPORT_TEMPLATE_SCHEMA_SUCCESS,
  data,
});

export const updateExportTemplateSchemaFailure = () => ({
  type: actionTypes.UPDATE_EXPORT_TEMPLATE_SCHEMA_FAILURE,
});
