import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import * as R from 'ramda';

import TimeEntryStatusIcon from '@atom/components/common/timeEntryStatusIcon/TimeEntryStatusIcon';
import WorkOrderStatusEdit from '@atom/components/common/workOrderDetail/workOrderStatusEdit/WorkOrderStatusEdit';
import TeamContext from '@atom/components/teamPortal/TeamContext';
import { ListTable, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import { CondensedTimeEntryDetail } from '@atom/types/timeEntry';
import {
  convertMillisToExactHours,
  setDisplayDate,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './timeSheet.css';

const { TableRow, TableCell } = ListTable;

const styles = {
  workInfo: {
    minWidth: '160px',
  },
  smallCell: {
    padding: '0.5rem',
  },
};

interface Props {
  timeEntry: CondensedTimeEntryDetail;
  refetchTimeSheet: () => void;
}

const TimeSheetTableCondensedRow = ({ timeEntry, refetchTimeSheet }: Props) => {
  const { payPeriodWeeks } = useContext(TeamContext);

  const fullPayPeriodWeeks = R.flatten(payPeriodWeeks);
  const budgetNames = timeEntry.computedBudgets
    .map(budget => `${budget.name}${budget.restored ? ' (snapshot)' : ''}`)
    .join(', ');

  return (
    <TableRow key={timeEntry.id}>
      <TableCell width="45px" center>
        <TimeEntryStatusIcon status={timeEntry.status} />
      </TableCell>
      <TableCell>{timeEntry?.workOrder?.workTemplate?.name}</TableCell>
      <TableCell style={styles.workInfo}>
        <div>
          <Link to={`/workOrders/${timeEntry?.workOrder?.id}`}>
            {timeEntry?.workOrder?.name}
          </Link>
          <WorkOrderStatusEdit
            workOrder={timeEntry?.workOrder}
            createdById={timeEntry?.workOrder?.createdBy?.id}
            refetch={refetchTimeSheet}
            styleType="table"
          />
          {timeEntry?.workOrder?.inventoryAssetName && (
            <div styleName="subtext">{`Inventory: ${timeEntry?.workOrder?.inventoryAssetName}`}</div>
          )}
          {!isNilOrEmpty(timeEntry?.workOrder?.dueDate) && (
            <div styleName="subtext">{`Due Date: ${setDisplayDate(
              timeEntry?.workOrder?.dueDate,
            )}`}</div>
          )}
        </div>
      </TableCell>
      <TableCell>{timeEntry?.task?.name}</TableCell>
      <TableCell>{timeEntry?.userGroup?.name}</TableCell>
      <TableCell>{budgetNames}</TableCell>
      {fullPayPeriodWeeks.map(day => {
        const dateDictionary = timeEntry.dateDictionary[day];

        const hours = dateDictionary?.totalDuration
          ? convertMillisToExactHours(dateDictionary?.totalDuration)
          : '';

        const showTooltip = dateDictionary?.computedBudgets.length > 1;

        const workTimeStyle = showTooltip
          ? { borderBottom: `1px dotted ${colors.neutral.gray}` }
          : {};

        const budgetsTooltipTitle = (
          <div styleName="tooltip-container">
            {dateDictionary?.computedBudgets.map(budget => (
              <div key={budget.id} styleName="tooltip-row">
                <span>
                  {`${budget.name}: `}
                  <strong>{`${convertMillisToExactHours(
                    budget.duration,
                  )} Hour(s)`}</strong>
                  {budget.restored && (
                    <span styleName="snapshot-suffix">(snapshot)</span>
                  )}
                </span>
              </div>
            ))}
          </div>
        );

        return (
          <TableCell width="30px" center style={styles.smallCell} key={day}>
            {showTooltip ? (
              <Tooltip lightTooltip title={budgetsTooltipTitle}>
                <div style={workTimeStyle}>{hours}</div>
              </Tooltip>
            ) : (
              <div>{hours}</div>
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default TimeSheetTableCondensedRow;
