import React, { useState } from 'react';
import { actions, Control } from 'react-redux-form';
import { InputLabel } from '@mui/material';
import { withStyles } from '@mui/styles';

import { Select } from '@atom/mui';
import colors from '@atom/styles/colors';

import './attributeControls.css';

const { MenuItem } = Select;

const stylesOverride = {
  root: {
    borderBottom: `1px solid ${colors.neutral.silver}`,
  },
  select: {
    color: colors.neutral.gray,
  },
};

const styles = {
  container: {
    marginTop: '1rem',
  },
  inputLabel: {
    fontSize: '13px',
    color: colors.neutral.gray,
  },
  placeholder: {
    color: colors.neutral.black,
  },
  menuItem: {
    color: colors.neutral.black,
    selectedColor: colors.brand.blue,
    fontSize: '15px',
    padding: '60px',
  },
};

interface Props {
  enumeration: any[];
  name: string;
  title: string;
  values: any[];
  classes: any;
}

const EnumMultipleAttribute = ({
  name,
  title,
  enumeration,
  classes,
}: Props) => {
  const [values, setValues] = useState<string[]>([]);

  const onChange = ({ dispatch, model }) => {
    return function update(event, child) {
      const { value } = child.props;

      const updatedValues = values.includes(value)
        ? values.filter(val => val !== value)
        : [...values, value];

      setValues(updatedValues);

      dispatch(actions.change(model, updatedValues));
    };
  };

  const mapProps = {
    multiple: true,
    fullWidth: true,
    disableUnderline: true,
    displayEmpty: true,
    renderValue: () =>
      values.length
        ? () => <span style={styles.placeholder}>{values.join(', ')}</span>
        : () => 'All',
    value: () => values,
    onChange: props => onChange(props),
    MenuProps: { getContentAnchorEl: () => null },
    classes,
  };

  return (
    <div key={name} style={styles.container}>
      <InputLabel style={styles.inputLabel}>{title}</InputLabel>
      <Control.custom
        model={`.${name}`}
        component={Select}
        // @ts-ignore
        mapProps={mapProps}
      >
        {enumeration.map(optionValue => {
          return (
            <MenuItem
              key={optionValue}
              value={optionValue}
              selected={values && values.includes(optionValue)}
              style={{
                color: values.includes(optionValue)
                  ? styles.menuItem.selectedColor
                  : styles.menuItem.color,
                fontSize: styles.menuItem.fontSize,
              }}
            >
              {optionValue}
            </MenuItem>
          );
        })}
      </Control.custom>
    </div>
  );
};

export default withStyles(stylesOverride)(EnumMultipleAttribute);
