import React, { useContext, useEffect } from 'react';

import WorkOrdersContext, {
  WorkOrdersInputActionTypes,
} from '@atom/components/workOrders/WorkOrdersContext';
import { Icon, Progress } from '@atom/mui';

import WorkOrdersFiltersFormSection from './WorkOrdersFiltersFormSection';
import WorkOrdersFiltersHeader from './WorkOrdersFiltersHeader';
import WorkOrdersFiltersInventorySection from './WorkOrdersFiltersInventorySection';
import WorkOrdersFiltersTaskSection from './WorkOrdersFiltersTaskSection';
import WorkOrdersFiltersTeamSection from './WorkOrdersFiltersTeamSection';
import WorkOrdersFiltersWorkSection from './WorkOrdersFiltersWorkSection';

import './workOrdersFilters.css';

const WorkOrdersFilters = () => {
  const { input, setInput, dispatch, loadingFilters } = useContext(
    WorkOrdersContext,
  );

  useEffect(() => {
    dispatch({
      type: WorkOrdersInputActionTypes.SET_WORK_ORDERS_INPUT,
      data: input,
    });
  }, [input]);

  const expandIcon = input?.expanded ? 'arrow_left' : 'arrow_right';
  const expandButtonStyles = input?.expanded
    ? 'expand-button'
    : 'expand-button collapsed';
  const filtersContainerStyles = input?.expanded
    ? 'filters-container'
    : 'filters-container collapsed';

  const toggleExpanded = () => {
    setInput({
      ...input,
      expanded: !input?.expanded,
    });
  };

  return (
    <div styleName={filtersContainerStyles}>
      <div styleName={expandButtonStyles} onClick={toggleExpanded}>
        <Icon>{expandIcon}</Icon>
      </div>
      <WorkOrdersFiltersHeader />
      {loadingFilters ? (
        <div styleName="filter-loading-container">
          <Progress />
        </div>
      ) : (
        <div styleName="filters-content">
          <WorkOrdersFiltersWorkSection />
          <WorkOrdersFiltersTaskSection />
          <WorkOrdersFiltersTeamSection />
          <WorkOrdersFiltersInventorySection />
          <WorkOrdersFiltersFormSection />
        </div>
      )}
    </div>
  );
};

export default WorkOrdersFilters;
