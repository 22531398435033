import React, { useContext, useMemo } from 'react';

import InventoryAssetTree from '@atom/components/common/inventoryAssetTree/InventoryAssetTree';
import { InventoryCategoryTree } from '@atom/types/inventory';
import { POLICY_GRANT_IDS_ALL } from '@atom/types/policy';
import { getAllDescendantCategoryIds } from '@atom/utilities/categoryUtilities';

import PolicyModalContext from './PolicyModalContext';

const ROOT_TREE_ID = 'inventory';

const PolicyCategorySelection = () => {
  const { state, updateState } = useContext(PolicyModalContext);
  const { grants } = state;

  const selectedCategoryIds = useMemo(
    () =>
      grants.some(({ id }) => id === POLICY_GRANT_IDS_ALL)
        ? new Set([ROOT_TREE_ID])
        : new Set(grants.map(({ id }) => id)),
    [grants],
  );

  const handleChange = (tree: InventoryCategoryTree) => {
    if (tree.id === ROOT_TREE_ID) {
      const isAllSelected = grants.some(
        ({ id }) => id === POLICY_GRANT_IDS_ALL,
      );

      return updateState({
        grants: isAllSelected
          ? []
          : [{ id: POLICY_GRANT_IDS_ALL, name: 'All' }],
      });
    }

    const isSelected = selectedCategoryIds.has(tree.id);
    const updated = isSelected
      ? grants.filter(({ id }) => id !== tree.id)
      : [...grants, { id: tree.id, name: tree.name }];

    // remove all categories that are descendants of another selected category
    // descendant categories are auto-selected and don't need to be shown or
    // included in the API request
    const descendantIds = new Set(getAllDescendantCategoryIds(tree));
    const filtered = updated.filter(({ id }) => !descendantIds.has(id));

    return updateState({ grants: filtered });
  };

  return (
    <InventoryAssetTree
      search="categories"
      includeAssets={false}
      selectedCategoryIds={selectedCategoryIds}
      onCategoryToggle={handleChange}
      rootTreeName="All"
      autoSelectChildCategories
      showRootCategoryCheckbox
    />
  );
};

export default PolicyCategorySelection;
