import React, { createContext, useCallback, useState } from 'react';
import { Menu as MUIMenu, MenuProps as MUIMenuProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as R from 'ramda';

import {
  Icon,
  IconButton,
  IconButtonProps as IconButtonPropsType,
  IconProps as IconPropsType,
} from '@atom/mui';

import MenuItem from './MenuItem';

interface Context {
  open: boolean;
  close: (event: React.MouseEvent<any>) => void;
}

export const MenuContext = createContext<Context>({
  open: false,
  close: () => {},
});

type CustomProps = {
  /**
   * optionally control menu open state
   */
  open?: boolean;
  /**
   * disable opening the menu
   */
  disabled?: boolean;
  /**
   * MUI icon to use for icon button
   * defaults to more_vert
   */
  icon?: React.ReactNode;
  /**
   * optional props to be passed to IconButton
   */
  IconButtonProps?: IconButtonPropsType;
  /**
   * optional props to be passed to Icon
   */
  IconProps?: IconPropsType;
  /**
   * denotes the given icon should be used standalone
   * and not wrapped with IconButton and Icon
   */
  noIconButton?: boolean;
  /**
   * turns off the minWidth of the menu popover
   */
  noMinWidth?: boolean;
  dataCyLabel?: string;
};

export type MenuProps = CustomProps & Omit<MUIMenuProps, 'open'>;

const useStyles = makeStyles({
  paper: (props: any) => ({
    minWidth: props.noMinWidth ? '0' : '15rem',
    width: 'auto',
  }),
});

const styles = {
  pointer: {
    cursor: 'pointer',
  },
};

export const Menu = (props: MenuProps) => {
  const classes = useStyles(props);
  const [anchor, setAnchor] = useState<HTMLElement>(null);

  const {
    disabled = false,
    dataCyLabel = '',
    icon = 'more_vert',
    IconButtonProps = {},
    IconProps = {},
    noIconButton = false,
    anchorOrigin = { horizontal: 'left', vertical: 'top' },
    transformOrigin = { horizontal: 'left', vertical: 'top' },
    ...data
  } = props;

  const handleOpen = useCallback(
    (event: React.MouseEvent<any>) => {
      event.stopPropagation();

      if (!disabled) {
        setAnchor(event.currentTarget);
      }
    },
    [disabled],
  );

  const handleClose = useCallback((event: React.MouseEvent<any>) => {
    event.stopPropagation();
    setAnchor(null);
  }, []);

  return (
    <MenuContext.Provider value={{ open: !!anchor, close: handleClose }}>
      {noIconButton ? (
        <span onClick={handleOpen} style={disabled ? {} : styles.pointer}>
          {icon}
        </span>
      ) : (
        <IconButton
          disabled={disabled}
          onClick={handleOpen}
          data-cy={dataCyLabel}
          {...IconButtonProps}
        >
          <Icon {...IconProps}>{icon}</Icon>
        </IconButton>
      )}
      <MUIMenu
        open={props.open ?? !!anchor}
        anchorEl={anchor}
        onClose={handleClose}
        keepMounted
        classes={{ paper: classes.paper, ...props.classes }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        {...R.omit(['noMinWidth'], data)}
      />
    </MenuContext.Provider>
  );
};

Menu.MenuItem = MenuItem;

export default Menu;
