import React, { useContext, useState } from 'react';
import * as R from 'ramda';

import UserContext from '@atom/components/userDetail/UserContext';
import { Icon, IconButton } from '@atom/mui';
import { userCertificationsSelector } from '@atom/selectors/userSelectors';
import colors from '@atom/styles/colors';
import { UserCertificationItem } from '@atom/types/user';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import AddEditCertModal from './AddEditCertModal';
import UserGroupCertTile from './UserCertTile';

import './certificationsTab.css';

const getCertHeaderLabel = (
  certifications: UserCertificationItem[],
): string => {
  if (!certifications || !R.length(certifications)) {
    return 'No certification configured';
  }

  if (R.length(certifications) === 1) {
    return '1 certification';
  }

  return `${R.length(certifications)} certifications`;
};

const CertificationsTab = () => {
  const { user } = useContext(UserContext);

  const [open, setOpen] = useState<boolean>(false);

  const certifications = userCertificationsSelector(user);
  const certHeaderLabel = getCertHeaderLabel(certifications);

  return (
    <>
      <div styleName="header-container">
        <div>{certHeaderLabel}</div>
        {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
          <IconButton onClick={() => setOpen(true)}>
            <Icon color={colors.neutral.gray}>add</Icon>
          </IconButton>
        )}
      </div>
      <div styleName="cert-list-container">
        {certifications.map((certification: UserCertificationItem) => (
          <UserGroupCertTile
            key={certification.id}
            certification={certification}
          />
        ))}
      </div>
      <AddEditCertModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default CertificationsTab;
