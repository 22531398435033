import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import AddFormsModal from '@atom/components/common/workOrderDetail/addFormsModal/AddFormsModal';
import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_TASK_FORM_TEMPLATE_CREATE } from '@atom/graph/workTemplate';
import { Icon, IconButton } from '@atom/mui';
import { WorkOrderTemplateFormCreateInput } from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';

import FormTile from './FormTile';

import './formSection.css';

const FormSection = () => {
  const { workTemplate, refetch, task, loading } = useContext(
    WorkTemplateContext,
  );

  const [expanded, setExpanded] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  const [addForms] = useMutation<
    void,
    { input: WorkOrderTemplateFormCreateInput }
  >(WORK_TEMPLATE_TASK_FORM_TEMPLATE_CREATE, {
    onCompleted: res => refetch(),
  });

  const handleAddForm = async (formTemplateIds: string[]) => {
    await addForms({
      variables: {
        input: {
          workTemplateId: workTemplate.id,
          taskId: task.id,
          formTemplateIds,
        },
      },
    });

    setOpen(false);
  };

  const isSectionEmpty = R.isEmpty(task?.formTemplates);
  const totalCount = R.length(task?.formTemplates) || 0;
  const icon = expanded ? 'expand_less' : 'expand_more';
  const isDisabled =
    workTemplate?.published || doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  return (
    <>
      <div styleName="section-container">
        <Icon>library_books</Icon>
        <div styleName="section-title">Forms</div>
        <div styleName="total-count">{totalCount}</div>
        <div styleName="buttons-container">
          {expanded && (
            <IconButton
              onClick={() => setOpen(true)}
              edge="end"
              tooltip="Add Forms"
              disabled={isDisabled}
            >
              <Icon>add</Icon>
            </IconButton>
          )}
          <IconButton onClick={() => setExpanded(!expanded)} edge="end">
            <Icon>{icon}</Icon>
          </IconButton>
        </div>
      </div>
      {expanded && (
        <div styleName="tile-list">
          {isSectionEmpty ? (
            <div styleName="empty-section-text">Add Forms</div>
          ) : (
            <>
              {task.formTemplates.map(formTemplate => (
                <FormTile key={formTemplate.id} formTemplate={formTemplate} />
              ))}
            </>
          )}
        </div>
      )}
      <AddFormsModal
        schemaId={workTemplate?.schemaId}
        loading={loading}
        open={open}
        onClose={() => setOpen(false)}
        handleAddForm={handleAddForm}
      />
    </>
  );
};

export default FormSection;
