import React from 'react';
import * as R from 'ramda';

import { DataType } from '@atom/types/dataType';
import { WorkOrder } from '@atom/types/work';
import { WorkOrderColumn } from '@atom/types/workColumns';
import {
  getDisplayDateTime,
  setDisplayDate,
} from '@atom/utilities/timeUtilities';

import './workOrders.css';

interface Props {
  workOrder: WorkOrder;
  column: WorkOrderColumn;
}

const CustomWorkFieldCell = ({ workOrder, column }: Props) => {
  const field = workOrder.fields.find(item => item.id === column.fieldId);

  const getFieldDisplay = () => {
    const content = {
      [DataType.SHORT_TEXT]: field?.value,
      [DataType.LONG_TEXT]: field?.value,
      [DataType.DATE]: setDisplayDate(field?.value),
      [DataType.DATE_TIME]: getDisplayDateTime(field?.value),
      [DataType.ENUM_SINGLE]: field?.value,
      [DataType.ENUM_MULTIPLE]:
        field?.dataType === DataType.ENUM_MULTIPLE
          ? R.pathOr([], ['value'], field).join(', ')
          : '',
      [DataType.NUMBER]: field?.value,
    };

    return content[field?.dataType] || '';
  };

  return <div>{getFieldDisplay()}</div>;
};

export default CustomWorkFieldCell;
