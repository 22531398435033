import React from 'react';

import { Icon } from '@atom/mui';
import userSelectors from '@atom/selectors/userSelectors';
import colors from '@atom/styles/colors';

import './permissionsTab.css';

interface Row {
  device: string;
  page: string;
  name: string;
  orgAdmin: string;
  manager: string;
  readOnly: string;
  field: string;
}

const PermissionsTable = () => {
  const getPermissionIcon = (level: string) => {
    switch (level) {
      case 'viewOnly': {
        return <Icon color={colors.neutral.gray}>remove_red_eye</Icon>;
      }
      case 'fullAccess': {
        return <Icon color={colors.brand.green}>check</Icon>;
      }
      default: {
        return <Icon color={colors.brand.red}>close</Icon>;
      }
    }
  };

  return (
    <div styleName="content-container">
      <div styleName="content-header">
        <div />
        <div styleName="legend-container">
          <div styleName="legend-item">
            <p styleName="legend-item-title">Full Functionalities:</p>
            <Icon color={colors.brand.green}>check</Icon>
          </div>
          <div styleName="legend-item">
            <p styleName="legend-item-title">View Only:</p>
            <Icon color={colors.neutral.gray}>remove_red_eye</Icon>
          </div>
          <div styleName="legend-item">
            <p styleName="legend-item-title">No Access:</p>
            <Icon color={colors.brand.red}>close</Icon>
          </div>
        </div>
      </div>
      <div styleName="table-header">
        <div styleName="column type">Device</div>
        <div styleName="column type">Page</div>
        <div styleName="column access">Access</div>
        <div styleName="column large-role">Organization Admin</div>
        <div styleName="column small-role">Manager</div>
        <div styleName="column small-role">Field</div>
        <div styleName="column small-role">Read Only</div>
      </div>
      <div styleName="table-container">
        {userSelectors.permissionsTable.map((row: Row, index: number) => {
          return (
            <div styleName="table-row" key={index}>
              <div styleName="column type bold">{row.device || ''}</div>
              <div styleName="column type bold">{row.page || ''}</div>
              <div styleName="column access">{row.name}</div>
              <div styleName="column large-role">
                {getPermissionIcon(row.orgAdmin)}
              </div>
              <div styleName="column small-role">
                {getPermissionIcon(row.manager)}
              </div>
              <div styleName="column small-role">
                {getPermissionIcon(row.field)}
              </div>
              <div styleName="column small-role">
                {getPermissionIcon(row.readOnly)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PermissionsTable;
