import * as R from 'ramda';
import { createSelector } from 'reselect';

export const inventorySchemas = R.pathOr([], ['inventorySchemas']);
export const inventorySchema = R.pathOr({}, ['inventorySchema']);
export const inventorySchemaDetail = R.pathOr({}, ['inventorySchemaDetail']);

export const DEFECT_MARKER_ID = 100;
export const PROTECTIVE_COATING_MARKER_ID = 200;

export const getFilteredSchemaByMarkerId = (state, ownProps) => {
  const markerId = ownProps.markerId;
  const isFhwaForm = !!ownProps.isFhwaForm;
  const schema = inventorySchema(state);

  if (R.isNil(schema) || R.isEmpty(schema)) {
    return schema;
  }

  if (markerId) {
    return {
      ...schema,
      // @ts-ignore
      elementGroups: schema.elementGroups.reduce((acc, elementGroup) => {
        if (elementGroup.isGroup) {
          return acc;
        }

        const elementMarkerId = elementGroup.elements[0].markerId;

        const newElementGroup = {
          ...elementGroup,
          elements: elementGroup.elements.map(element => ({
            ...element,
            hasChildren: false,
          })),
        };

        return elementMarkerId === markerId ? [...acc, newElementGroup] : acc;
      }, []),
    };
  }

  if (isFhwaForm) {
    return {
      ...schema,
      // @ts-ignore
      elementGroups: schema.elementGroups.map(elementGroup => ({
        ...elementGroup,
        elements: elementGroup.elements.map(element => ({
          ...element,
          hasChildren: false,
        })),
      })),
    };
  }

  return schema;
};

export const getSchemaAttributeFilterGroupsSelector = createSelector(
  // @ts-ignore
  ownProps => ownProps.rootSchema,
  schema => {
    if (R.isNil(schema)) {
      return [];
    }

    return schema.attributeGroups
      .map(group => {
        const { name, attributes } = group;

        const filterableAttributes = attributes
          .map(id => ({
            id,
            ...schema.attributes[id],
          }))
          .filter(attribute => attribute.isFilterable);

        return {
          name,
          attributes: filterableAttributes,
        };
      })
      .filter(group => !R.isEmpty(group.attributes));
  },
);

export const mapElementProperties = element => ({
  id: element.id,
  name: element.name,
  assetType: element.assetType,
  attributeGroups: element.attributeGroups,
});

export const getElements = element => {
  const rootElement = mapElementProperties(element);

  if (R.isEmpty(element.elements)) {
    return [rootElement];
  }

  const elements = element.elements.map(elem => R.flatten(getElements(elem)));

  const groupedElements = element.elementGroups.reduce((acc, group) => {
    const elems = group.elements.map(elem => getElements(elem));
    return [...acc, ...R.flatten(elems)];
  }, []);

  return [rootElement, ...elements, ...groupedElements];
};

export const getInventorySchemasSelector = createSelector(
  inventorySchemas,
  schemas => [
    { id: 'all', name: 'All', attributes: {}, attributeGroups: [] },
    ...schemas,
  ],
);

export const getAttributeIconName = dataType => {
  switch (dataType) {
    case 'date': {
      return 'today';
    }
    case 'enummultiple': {
      return 'done_all';
    }
    case 'boolean': {
      return 'radio_button_checked';
    }
    case 'number': {
      return 'looks_one';
    }
    case 'currency': {
      return 'attach_money';
    }
    case 'shorttext': {
      return 'short_text';
    }
    case 'longtext': {
      return 'format_align_left';
    }
    case 'enumsingle': {
      return 'arrow_drop_down_circle';
    }
    default: {
      return 'title';
    }
  }
};
