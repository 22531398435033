import colors from '@atom/styles/colors';

const textStyle = {
  title: {
    color: colors.neutral.dark,
  },
  input: {
    color: colors.neutral.black,
    whiteSpace: 'normal',
    padding: '0.25em 0',
  },
  selected: {
    color: colors.brand.blue,
    whiteSpace: 'normal',
    padding: '0.25em 0',
  },
  textarea: {
    color: colors.neutral.black,
  },
  inputText: { marginTop: '-0.4rem' },
};

export default textStyle;
