import React, { useEffect, useState } from 'react';
import * as R from 'ramda';

import { Checkbox, Collapse, Icon, List, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventorySchemaDetail } from '@atom/types/inventory';
import api from '@atom/utilities/api';
import { INVENTORY_SCHEMAS_ENDPOINT } from '@atom/utilities/endpoints';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import TreeRowIcon from './TreeRowIcon';

import './addSchemaModal.css';

const { ListItemButton, ListItemText } = List;

interface Props {
  rootSchemaId: string;
  toggleSchema: (schemaId: string) => void;
  selectedSchemaIds: Set<string>;
  lockedSchemaIds: Set<string>;
}

export type Tree = InventorySchemaDetail;

const styles = {
  toggle: {
    cursor: 'pointer',
    marginRight: '0.5rem',
  },
  elementGroup: {
    fontWeight: '400',
    color: colors.neutral.gray,
    textTransform: 'uppercase',
  },
};

const getListItemStyle = (hasChildren: boolean, level: number) =>
  hasChildren
    ? { paddingLeft: `${level}rem`, paddingRight: '1rem' }
    : { paddingLeft: `${level + 2}rem`, paddingRight: '1rem' };

const SchemaTree = ({
  rootSchemaId,
  toggleSchema,
  selectedSchemaIds,
  lockedSchemaIds,
}: Props) => {
  const [open, setOpen] = useState<Set<string>>(new Set([rootSchemaId]));

  const [schemaTree, setSchemaTree] = useState<InventorySchemaDetail>();
  const [schemaTreeLoading, setSchemaTreeLoading] = useState<boolean>(false);

  useEffect(() => {
    const getSchemaTree = async () => {
      setSchemaTreeLoading(true);

      const { data } = await api.get(
        `${INVENTORY_SCHEMAS_ENDPOINT}/${rootSchemaId}/fulltree`,
      );

      setSchemaTree(data);
      setSchemaTreeLoading(false);
    };

    getSchemaTree();
  }, [rootSchemaId]);

  const handleToggle = (id: string) => {
    setOpen(toggleFromSet(open, id));
  };

  const handleSchemaToggle = (
    event: React.MouseEvent<any>,
    schemaId: string,
  ) => {
    event.stopPropagation();

    toggleSchema(schemaId);
  };

  const renderList = (
    trees: Tree[],
    level: number,
    isElementGroup: boolean,
  ) => {
    return (
      <List disablePadding>
        {trees.map(tree => {
          const isRoot = tree.id === rootSchemaId;

          const nameStyles = isElementGroup ? styles.elementGroup : {};
          const hasChildren =
            !isNilOrEmpty(tree.elementGroups) || !isNilOrEmpty(tree.elements);

          const isLocked = lockedSchemaIds.has(tree.id);
          const isChecked =
            lockedSchemaIds.has(tree.id) || selectedSchemaIds.has(tree.id);

          return (
            <React.Fragment key={tree.id}>
              <ListItemButton
                disableGutters
                style={getListItemStyle(hasChildren, level)}
                onClick={() => handleToggle(tree.id)}
              >
                {hasChildren && (
                  <Icon style={styles.toggle}>
                    {open.has(tree.id) ? 'arrow_drop_down' : 'arrow_right'}
                  </Icon>
                )}
                {!isElementGroup && (
                  <Checkbox
                    aria-checked
                    checked={isChecked}
                    onClick={event => handleSchemaToggle(event, tree.id)}
                    style={{ marginRight: '0.5rem' }}
                    color="primary"
                    disabled={isLocked}
                  />
                )}
                <TreeRowIcon
                  isRoot={isRoot}
                  isElementGroup={isElementGroup}
                  markerId={tree?.markerId}
                />
                <ListItemText
                  primaryTextStyle={nameStyles}
                  primary={tree.name}
                />
              </ListItemButton>
              {!isNilOrEmpty(tree.elementGroups) && (
                <Collapse in={open.has(tree.id)} timeout="auto" unmountOnExit>
                  {renderList((tree as any).elementGroups, R.inc(level), true)}
                </Collapse>
              )}
              {!isNilOrEmpty(tree.elements) && (
                <Collapse in={open.has(tree.id)} timeout="auto" unmountOnExit>
                  {renderList((tree as any).elements, R.inc(level), false)}
                </Collapse>
              )}
            </React.Fragment>
          );
        })}
      </List>
    );
  };

  return (
    <div styleName="container">
      {!schemaTree || schemaTreeLoading ? (
        <div styleName="progress-container">
          <Progress style={{ flex: 1 }} />
        </div>
      ) : (
        renderList([schemaTree], 0, false)
      )}
    </div>
  );
};

export default SchemaTree;
