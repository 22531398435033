import React from 'react';
import { Link } from 'react-router-dom';

import UserThumbnail from '@atom/components/common/UserThumbnail';
import { Icon, IconButton } from '@atom/mui';
import { getTaskUserStatusValues } from '@atom/selectors/taskSelectors';
import colors from '@atom/styles/colors';
import { TaskUserStatus } from '@atom/types/task';
import { UserProfile } from '@atom/types/user';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './userStatusControls.css';

interface Props {
  user: UserProfile;
  isLeadAssignee: boolean;
  setIsLeadAssignee: (isLeadAssignee: boolean) => void;
  taskUserStatus: TaskUserStatus;
}

const ModalTitle = ({
  user,
  isLeadAssignee,
  setIsLeadAssignee,
  taskUserStatus,
}: Props) => {
  const userName = `${user?.firstName} ${user?.lastName}`;

  const taskUserStatusValues = getTaskUserStatusValues(taskUserStatus);
  const taskUserStatusColor = { backgroundColor: taskUserStatusValues?.color };

  return (
    <div styleName="header-container">
      <div styleName="header-section">
        <UserThumbnail image={user?.profileImage} alt={userName} />
        <div styleName="title-container">
          <div styleName="header-title">{userName}</div>
          {!isNilOrEmpty(taskUserStatus) && (
            <div styleName="header-status" style={taskUserStatusColor}>
              {taskUserStatusValues.text}
            </div>
          )}
          <div styleName="header-subtext">{user?.email}</div>
        </div>
      </div>
      <div>
        <IconButton onClick={() => setIsLeadAssignee(!isLeadAssignee)}>
          {isLeadAssignee ? (
            <Icon color={colors.brand.blue}>star</Icon>
          ) : (
            <Icon>star_border</Icon>
          )}
        </IconButton>
        <Link to={`/team/${user?.userId}`}>
          <IconButton>
            <Icon>launch</Icon>
          </IconButton>
        </Link>
      </div>
    </div>
  );
};

export default ModalTitle;
