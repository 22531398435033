import React from 'react';

import { WorkOrderType } from '@atom/types/work';
import { WorkTemplateFolderTree } from '@atom/types/workTemplate';

export const EMPTY_SCHEMA = 'empty';

export enum ModalView {
  MAIN = 'MAIN',
  FOLDER_TREE = 'FOLDER_TREE',
}

export enum ModalStep {
  DETAILS = 'DETAILS',
  TEMPLATE_TYPE = 'TEMPLATE_TYPE',
  INHERITANCE = 'INHERITANCE',
}

export interface ModalStepType {
  index: number;
  title: string;
  value: ModalStep;
}

export enum InheritanceType {
  REGULAR = 'REGULAR',
  INHERITANCE = 'INHERITANCE',
}

interface Context {
  activeView: ModalView;
  setActiveView: React.Dispatch<React.SetStateAction<ModalView>>;
  activeStep: ModalStep;
  setActiveStep: React.Dispatch<React.SetStateAction<ModalStep>>;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  workTemplateFolder: WorkTemplateFolderTree;
  setWorkTemplateFolder: React.Dispatch<
    React.SetStateAction<WorkTemplateFolderTree>
  >;
  schemaId: string;
  setSchemaId: React.Dispatch<React.SetStateAction<string>>;
  type: WorkOrderType;
  setType: React.Dispatch<React.SetStateAction<WorkOrderType>>;
  inheritanceType: InheritanceType;
  setInheritanceType: React.Dispatch<React.SetStateAction<InheritanceType>>;
}

export const initialState: Context = {
  activeView: ModalView.MAIN,
  setActiveView: () => {},
  activeStep: ModalStep.DETAILS,
  setActiveStep: () => {},
  error: '',
  setError: () => {},
  name: '',
  setName: () => {},
  workTemplateFolder: null,
  setWorkTemplateFolder: () => {},
  schemaId: EMPTY_SCHEMA,
  setSchemaId: () => {},
  type: null,
  setType: () => {},
  inheritanceType: InheritanceType.REGULAR,
  setInheritanceType: () => {},
};

const WorkTemplateCreationModalContext = React.createContext<Context>(
  initialState,
);

export default WorkTemplateCreationModalContext;
