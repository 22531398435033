import React, { useEffect, useState } from 'react';

import { MediaItem } from '@atom/types/media';
import { WorkOrderAssetTreeType } from '@atom/types/work';
import api from '@atom/utilities/api';
import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';
import { MEDIA_ENDPOINT } from '@atom/utilities/endpoints';

import PhotoTile from './PhotoTile';

import '../workOrderDetailPdfReport.css';

interface Props {
  workOrderAsset: WorkOrderAssetTreeType;
  workOrderId: string;
  setOtherPhotosLoading: (currentLoading: { [id: string]: boolean }) => void;
}

const AssetAssessment = ({
  workOrderAsset,
  workOrderId,
  setOtherPhotosLoading,
}: Props) => {
  const [photos, setPhotos] = useState<MediaItem[]>([]);

  useEffect(() => {
    const getMedia = async () => {
      const data = {
        subjectTypes: 'workOrderAsset',
        parentSubjectId: workOrderId,
        subjectIds: workOrderAsset.id,
      };
      const response = await api.get(MEDIA_ENDPOINT, data);

      setPhotos(response.data);
      // @ts-ignore
      setOtherPhotosLoading(currentLoading => ({
        ...currentLoading,
        attributeGroupAssessment: false,
      }));
    };
    getMedia();
  }, []);

  const getSubheader = () => {
    const attributeGroup = workOrderAsset.attributeGroups.filter(
      group => group.name === 'Assessment',
    );

    const attributeGroupIds = attributeGroup[0]?.attributes
      ? attributeGroup[0]?.attributes
      : [];

    return attributeGroupIds.map(key => {
      const style =
        workOrderAsset.attributes[key].name === 'Comment'
          ? 'table-header-cell comment'
          : 'table-header-cell';

      return (
        workOrderAsset.attributes[key] && (
          <th styleName={style}>{workOrderAsset.attributes[key].name}</th>
        )
      );
    });
  };

  const getValues = () => {
    const attributeGroup = workOrderAsset.attributeGroups.filter(
      group => group.name === 'Assessment',
    );

    const attributeGroupIds = attributeGroup[0]?.attributes
      ? attributeGroup[0]?.attributes
      : [];

    return attributeGroupIds.map(
      key =>
        workOrderAsset.attributes[key] && (
          <td>
            {attributeDisplayUtility.display(
              workOrderAsset.attributes[key].value,
              workOrderAsset.attributes[key].dataType,
              workOrderAsset.attributes[key].unit,
              !!workOrderAsset.attributes[key].changeId,
              workOrderAsset.attributes[key].newValue,
            )}
          </td>
        ),
    );
  };

  return (
    <div styleName="table-wrapper">
      <table>
        <tbody>
          <tr styleName="table-subheader">{getSubheader()}</tr>
          <tr styleName="table-value">{getValues()}</tr>
        </tbody>
      </table>
      <div>
        {!!photos.length && (
          <PhotoTile
            photos={photos}
            title={workOrderAsset.name}
            elements={[]}
            id={workOrderAsset.id}
            setOtherPhotosLoading={setOtherPhotosLoading}
          />
        )}
      </div>
    </div>
  );
};

export default AssetAssessment;
