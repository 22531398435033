import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash.debounce';

import { GET_SCHEMAS } from '@atom/graph/schema';
import { Icon, List, Progress, TextField } from '@atom/mui';
import { Schema, SchemasConnection, SchemasInput } from '@atom/types/schema';
import schemaUtilities from '@atom/utilities/schemaUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import CreateAssetModalContext from './CreateAssetModalContext';

import './createAssetModal.css';

const { ListItemButton, ListItemText } = List;

const styles = {
  close: {
    cursor: 'pointer',
  },
  list: {
    padding: '0',
  },
  listItem: {
    padding: '4px 8px',
  },
  listText: {
    paddingLeft: '0.5rem',
  },
  virtuoso: {
    height: '360px',
  },
};

const MIN_SEARCH_CHARS = 3;
const DEBOUNCE_TIME = 500;

const SchemaSelectionTab = () => {
  const { schema, setSchema } = useContext(CreateAssetModalContext);

  const [schemas, setSchemas] = useState<Schema[]>([]);
  const [query, setQuery] = useState<string>('');

  const [getSchemas, { loading }] = useLazyQuery<
    { schemas: SchemasConnection },
    { input: SchemasInput }
  >(GET_SCHEMAS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setSchemas(data?.schemas?.schemas || []);
    },
  });

  useEffect(() => {
    if (isNilOrEmpty(query)) {
      getSchemas({
        variables: {
          input: {
            rootSchemas: true,
            isPublished: 'true',
            isMaterial: 'true,false',
          },
        },
      });
    }
  }, [query]);

  const fetchSearchOptionsDebounced = useCallback(
    debounce((value: string) => {
      getSchemas({
        variables: {
          input: {
            name: value,
            rootSchemas: true,
            isPublished: 'true',
            isMaterial: 'true,false',
          },
        },
      });
    }, DEBOUNCE_TIME),
    [],
  );

  const handleQueryChange = (value: string = '') => {
    setQuery(value);

    if (!value) {
      fetchSearchOptionsDebounced.cancel();
    } else if (value.length >= MIN_SEARCH_CHARS) {
      fetchSearchOptionsDebounced(value);
    } else {
      fetchSearchOptionsDebounced.cancel();
    }
  };

  return (
    <div styleName="tab-container">
      <div styleName="title-container">
        <div styleName="tab-title">Inventory Type</div>
        {loading && <Progress size={20} thickness={2} />}
      </div>
      <TextField
        value={query}
        onChange={event => handleQueryChange(event.target.value)}
        data-cy="globalCreateAssetModalSchemaInput"
        placeholder="Search"
        InputProps={{
          startAdornment: <Icon>search</Icon>,
          endAdornment: query && (
            <Icon style={styles.close} onClick={() => handleQueryChange('')}>
              close
            </Icon>
          ),
        }}
      />
      <div styleName="list-container">
        <List style={styles.list}>
          <Virtuoso
            style={styles.virtuoso}
            data={schemas}
            itemContent={index => {
              const item = schemas[index];

              return (
                <ListItemButton
                  key={item.id}
                  onClick={() => setSchema(item)}
                  selected={item.id === schema?.id}
                  style={styles.listItem}
                >
                  {schemaUtilities.getSchemaIconFromSchemaOrAsset(item)}
                  <ListItemText
                    primary={item.name}
                    primaryTextStyle={styles.listText}
                  />
                </ListItemButton>
              );
            }}
          />
        </List>
      </div>
    </div>
  );
};

export default SchemaSelectionTab;
