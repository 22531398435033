import * as R from 'ramda';
import uuid from 'uuid/v4';

import { DataType } from '@atom/types/dataType';
import {
  Task,
  TaskDropdownField,
  TaskField,
  TaskUserStatus,
} from '@atom/types/task';
import {
  SubField,
  TaskRequiredValidation,
  ValueDictionary,
  WorkOrderDetailType,
  WorkOrderDropdownField,
  WorkOrderField,
} from '@atom/types/work';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import { isWorkOrderCompletedOrAbove } from './workOrderStatusUtilities';

export const initialNestedField: WorkOrderDropdownField = {
  title: '',
  dataType: DataType.ENUM_SINGLE,
  enumeration: ['', ''],
  defaultValue: null,
  subFields: [],
};

export const getPropertyByValue = (
  match: string,
  valueDictionary: ValueDictionary,
) => {
  const property = R.keys(valueDictionary).find(
    (key: string): boolean => match === valueDictionary[key],
  );

  return property ? property.toString() : null;
};

// Creates a valueDictionary of UUID to enumeration value and replaces all
// enumeration, matchValue, and defaultValue down an infinite tree with the UUID.
export const mapDropdownField = (
  field: WorkOrderDropdownField | TaskDropdownField,
): any => {
  let valueDictionary = {};

  const mappedEnumeration = field.enumeration.map((enumItem: string) => {
    const id = uuid();

    valueDictionary = {
      ...valueDictionary,
      [id]: enumItem,
    };

    return id;
  });

  const mappedDefaultValue = getPropertyByValue(
    field.defaultValue,
    valueDictionary,
  );

  const mappedSubFields = field.subFields
    ? field.subFields.map(
        (subField: SubField): SubField => {
          const mappedMatchValue = getPropertyByValue(
            subField.matchValue,
            valueDictionary,
          );

          const mappedNestedField = mapDropdownField(subField.nestedField);

          valueDictionary = {
            ...valueDictionary,
            ...mappedNestedField.valueDictionary,
          };

          return {
            matchValue: mappedMatchValue,
            nestedField: R.omit(['valueDictionary'], mappedNestedField),
          };
        },
      )
    : [];

  return {
    ...field,
    valueDictionary,
    enumeration: mappedEnumeration,
    defaultValue: mappedDefaultValue,
    subFields: mappedSubFields,
  };
};

// Replaces all enumeration, matchValue, defaultValue with the matching value
// from the passed in valueDictionary in an infinite tree. It also hydrates
// the required value to all tree nodes if that value is passed in
export const hydrateDropdownField = (
  field: any,
  valueDictionary: ValueDictionary,
  required?: boolean,
) => {
  const hydratedEnumeration = field.enumeration.map(
    (key: string): string => valueDictionary[key],
  );

  const hydratedSubFields = field.subFields.map(
    (subField: SubField): SubField => {
      const hydratedNestedField = hydrateDropdownField(
        subField.nestedField,
        valueDictionary,
        required,
      );

      return {
        matchValue: valueDictionary[subField.matchValue],
        nestedField: R.omit(['value'], hydratedNestedField),
      };
    },
  );

  return {
    ...field,
    enumeration: hydratedEnumeration,
    defaultValue: field.defaultValue
      ? valueDictionary[field.defaultValue]
      : null,
    subFields: hydratedSubFields,
    ...(!R.isNil(required) && { required }),
  };
};

export const getNestedPath = (
  matchValue: string,
  subFields: SubField[],
  previousPath: any[],
): any[] => {
  return subFields.reduce((acc: any[], subField: SubField, index: number) => {
    const currentPath = [...previousPath, 'nestedField', 'subFields', index];

    return subField.matchValue === matchValue
      ? [...acc, ...currentPath]
      : [
          ...acc,
          ...getNestedPath(
            matchValue,
            subField.nestedField.subFields || [],
            currentPath,
          ),
        ];
  }, []);
};

export const getPath = (matchValue: string, subFields: SubField[]): any[] => {
  return subFields.reduce((acc: any[], subField: SubField, index: number) => {
    const currentPath = [index];

    return subField.matchValue === matchValue
      ? [...acc, ...currentPath]
      : [
          ...acc,
          ...getNestedPath(
            matchValue,
            subField.nestedField.subFields || [],
            currentPath,
          ),
        ];
  }, []);
};

// Takes a subField object and replaces the matching subField in the
// root subFields array.
export const updateSubFields = (
  subField: SubField,
  subFields: SubField[],
): SubField[] => {
  const path = getPath(subField.matchValue, subFields);
  return R.set(R.lensPath(path), R.omit(['value'], subField), subFields);
};

export const getNestedSubFieldsPath = (
  matchValue: string,
  subFields: SubField[],
  previousPath: any[],
): any[] => {
  return subFields.reduce((acc: any[], subField: SubField, index: number) => {
    const currentPath = [...previousPath, 'nestedField', 'subFields'];

    return subField.matchValue === matchValue
      ? [...acc, ...currentPath]
      : [
          ...acc,
          ...getNestedSubFieldsPath(
            matchValue,
            subField.nestedField.subFields || [],
            [...currentPath, index],
          ),
        ];
  }, []);
};

export const getSubFieldsPath = (
  matchValue: string,
  subFields: SubField[],
): any[] => {
  return subFields.reduce((acc: any[], subField: SubField, index: number) => {
    const currentPath = [];

    return subField.matchValue === matchValue
      ? [...acc, ...currentPath]
      : [
          ...acc,
          ...getNestedSubFieldsPath(
            matchValue,
            subField.nestedField.subFields || [],
            [...currentPath, index],
          ),
        ];
  }, []);
};

export const removeSubFieldByMatchValue = (
  subFields: SubField[],
  key: string,
): SubField[] => {
  const path = getSubFieldsPath(key, subFields);
  return R.over(
    R.lensPath(path),
    nestedSubFields => {
      const matchValueIndex = R.findIndex(R.propEq('matchValue', key))(
        nestedSubFields,
      );

      return matchValueIndex < 0
        ? nestedSubFields
        : R.remove(matchValueIndex, 1, nestedSubFields);
    },
    subFields,
  );
};

// Returns the full breadcrumb path for cascading dropdowns in the
// form of Title: option / Title: option ? etc
export const getActiveSubFieldPathLabel = (
  rootTitle: string,
  activePath: any[],
  subFields: SubField[],
  valueDictionary: ValueDictionary,
): string => {
  if (R.isEmpty(activePath)) {
    return '';
  }

  const selectedRootOption = subFields[R.head(activePath)].matchValue;
  const rootLabel = `${rootTitle}: ${valueDictionary[selectedRootOption]}`;

  const groupedNestedActivePath = R.splitEvery(3, R.drop(1, activePath));

  const nestedLabels = groupedNestedActivePath.map(
    (nestedPath: any[], index: number) => {
      const pathToSubField = [
        activePath[0],
        ...R.flatten(R.slice(0, index + 1, groupedNestedActivePath)),
      ];
      const pathToNestedField = R.dropLast(2, pathToSubField);

      const field: any = R.view(R.lensPath(pathToNestedField), subFields);
      const subField: SubField = R.view(R.lensPath(pathToSubField), subFields);

      return `${field.title}: ${valueDictionary[subField.matchValue]}`;
    },
  );

  return [rootLabel, ...nestedLabels].join(' / ');
};

// Returns the depth of the current nestedField that
// matches the passed in matchValue.
export const getNestedDepth = (matchValue: string, subFields: SubField[]) => {
  const currentPath = getPath(matchValue, subFields);

  // Each grouping of 3 items in the path represents a full cascaded
  // dropdown child, thus splitting by every 3 will produce an
  // array with length equal to nested depth.
  return R.splitEvery(3, currentPath).length;
};

export const getNestedLeafPath = (field: TaskField): any[] => {
  const subFieldIndex = R.findIndex(R.propEq('matchValue', field.value))(
    field.subFields || [],
  );

  if (subFieldIndex < 0) {
    return [];
  }

  return [
    'subFields',
    subFieldIndex,
    'nestedField',
    ...getNestedLeafPath(field.subFields[subFieldIndex].nestedField),
  ];
};

export const getCascadingDropdownLeafNode = (field: TaskField) => {
  const subFieldIndex = R.findIndex(R.propEq('matchValue', field.value))(
    field.subFields || [],
  );

  if (subFieldIndex < 0) {
    return field;
  }

  const path = [
    'subFields',
    subFieldIndex,
    'nestedField',
    ...getNestedLeafPath(field.subFields[subFieldIndex].nestedField),
  ];

  return R.view(R.lensPath(path), field);
};

// Checks if the given field is invalid
// If a field is not required, it is valid
// If a field is required, it's value must pass dataType specific
// tests in order to be valid
export const isFieldInvalid = (field: TaskField): boolean => {
  if (!field?.required) {
    return false;
  }

  if (
    field.dataType === DataType.ENUM_SINGLE &&
    !isNilOrEmpty(field.subFields)
  ) {
    const fieldLeafNode = getCascadingDropdownLeafNode(field);

    // @ts-ignore
    return isNilOrEmpty(fieldLeafNode?.value);
  }

  return isNilOrEmpty(field?.value);
};

// Validate required fields checks if all custom fields on tasks
// are valid according to dataType specific rules in relation to the required boolean.
// If a taskId is passed, validation will only be run on that
// specific task, otherwise it is run on all tasks on the given workOrder.
// * Added 5.2.24 - if status is below completed, skip required field check
export const validateTaskRequiredFields = (
  workOrderDetail: WorkOrderDetailType,
  taskId?: string,
): TaskRequiredValidation => {
  if (
    isNilOrEmpty(workOrderDetail.tasks) ||
    !isWorkOrderCompletedOrAbove(workOrderDetail.statusId)
  ) {
    return {
      isValid: true,
      invalidTaskFields: {},
    };
  }

  const taskList = taskId
    ? workOrderDetail.tasks.filter(task => task.id === taskId)
    : workOrderDetail.tasks;

  const invalidTaskFields = taskList.reduce((acc, task) => {
    if (isNilOrEmpty(task.fields)) {
      return acc;
    }

    const invalidFieldIds = task.fields.reduce((accumulator, field) => {
      return isFieldInvalid(field) ? [...accumulator, field.id] : accumulator;
    }, []);

    return {
      ...acc,
      ...(!isNilOrEmpty(invalidFieldIds) && {
        [task.id]: new Set(invalidFieldIds),
      }),
    };
  }, {});

  return {
    isValid: isNilOrEmpty(R.keys(invalidTaskFields)),
    invalidTaskFields,
  };
};

export const getNestedFieldPathById = (
  id: string,
  subFields: SubField[],
  previousPath: any[],
): any[] => {
  return subFields.reduce((acc: any[], subField: SubField, index: number) => {
    const currentPath = [...previousPath, 'subFields', index, 'nestedField'];

    return subField.nestedField.id === id
      ? [...acc, ...currentPath]
      : [
          ...acc,
          ...getNestedFieldPathById(
            id,
            subField.nestedField.subFields || [],
            currentPath,
          ),
        ];
  }, []);
};

export const getFieldPathById = (id: string, fields: any[]): any[] => {
  return fields.reduce((acc: any[], field: any, index: number) => {
    const currentPath = ['fields', index];

    return field.id === id
      ? [...acc, ...currentPath]
      : [
          ...acc,
          ...getNestedFieldPathById(id, field.subFields || [], currentPath),
        ];
  }, []);
};

export const isLastUserToComplete = (task: Task): boolean => {
  const usersNotComplete = task.users.filter(
    user => user.status !== TaskUserStatus.COMPLETED,
  );

  return usersNotComplete.length === 1;
};

export const isCascadingField = (field: WorkOrderField | TaskField) => {
  return !R.isEmpty(field?.subFields) || field?.subFieldsTruncated;
};
