import React, { useContext } from 'react';

import FavoritesIconButton from '@atom/components/common/favorites/FavoritesIconButton';
import { Checkbox, Icon, List } from '@atom/mui';

import { isAsset } from './InventoryAssetTree';
import TreeContext, { Tree } from './TreeContext';
import TreeRowIcon from './TreeRowIcon';

const { ListItemButton, ListItemText } = List;

export interface Props {
  isAncestorSelected?: boolean;
  hasChildren: boolean;
  level: number;
  tree: Tree;
}

const getListItemStyle = (hasChildren: boolean, level: number) =>
  hasChildren
    ? { paddingLeft: `${level}rem`, paddingRight: '1rem' }
    : { paddingLeft: `${level + 2}rem`, paddingRight: '1rem' };

const styles = {
  toggle: {
    cursor: 'pointer',
    marginRight: '0.5rem',
  },
};

const TreeItem = ({ hasChildren, isAncestorSelected, level, tree }: Props) => {
  const {
    allAssetsSelected,
    autoSelectChildCategories,
    createFavorite,
    updateFavorite,
    disabledAssetIds,
    disabledCategoryIds,
    favoritesEnabled,
    favoriteMaterials,
    handleAssetClick,
    handleAssetToggle,
    handleCategoryToggle,
    handleToggle,
    onSelectAllAssetsToggle,
    open,
    rootTree,
    schemaId,
    selectedAssetIds,
    selectedCategoryId,
    selectedCategoryIds,
    showRootCategoryCheckbox,
    favoritesLists,
    handleAddList,
    canAddFavorite,
    canAddList,
  } = useContext(TreeContext);

  if (!rootTree) {
    return null;
  }

  const isRoot = tree.id === rootTree.id;

  const disabled =
    (!!schemaId && isAsset(tree) && tree.schemaId !== schemaId) ||
    //@ts-ignore
    tree?.inactive;

  const isAutoSelectedDescendant =
    autoSelectChildCategories && rootTree.id !== tree.id && isAncestorSelected;

  const selected =
    selectedAssetIds?.has(tree.id) ||
    selectedCategoryIds?.has(tree.id) ||
    (isAsset(tree) && allAssetsSelected);

  const enabledCheckbox = isAsset(tree)
    ? !!selectedAssetIds
    : !!selectedCategoryIds || (isRoot && !!onSelectAllAssetsToggle);

  const showCheckbox =
    isRoot && !isAsset(tree) ? showRootCategoryCheckbox : enabledCheckbox;

  const checkboxDisabled =
    disabledAssetIds?.has(tree.id) ||
    disabledCategoryIds?.has(tree.id) ||
    isAutoSelectedDescendant ||
    (isAsset(tree) && allAssetsSelected);

  const listItemSelected = selectedCategoryId === tree.id;

  return (
    <ListItemButton
      disableGutters
      style={getListItemStyle(hasChildren, level)}
      selected={listItemSelected}
      disabled={disabled}
      onClick={
        isAsset(tree) ? () => handleAssetClick(tree) : () => handleToggle(tree)
      }
    >
      {hasChildren && (
        <Icon style={styles.toggle}>
          {open.has(tree.id) ? 'arrow_drop_down' : 'arrow_right'}
        </Icon>
      )}
      {showCheckbox && (
        <Checkbox
          checked={selected || isAutoSelectedDescendant}
          onClick={event =>
            isAsset(tree)
              ? handleAssetToggle(event, tree)
              : handleCategoryToggle(event, tree)
          }
          style={{ marginRight: '0.5rem' }}
          color="primary"
          disabled={checkboxDisabled}
        />
      )}
      <TreeRowIcon tree={tree} />
      <ListItemText
        primary={tree.name}
        secondary={isAsset(tree) || (!hasChildren && tree?.assetType)}
      />
      {favoritesEnabled && !hasChildren && showCheckbox && (
        <FavoritesIconButton
          item={tree}
          handleAddList={handleAddList}
          createFavorite={createFavorite}
          updateFavorite={updateFavorite}
          favorites={favoriteMaterials}
          favoritesLists={favoritesLists}
          typeName="items"
          canAddFavorite={canAddFavorite}
          canAddList={canAddList}
        />
      )}
    </ListItemButton>
  );
};

export default TreeItem;
