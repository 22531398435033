import * as R from 'ramda';
import { createSelector } from 'reselect';

export const inventoryCollection = R.pathOr([], ['inventory', 'data']);
export const columnsSelector = R.pathOr([], ['inventory', 'columns']);

export const inventoryAncestorPathString = (ancestors, name) => {
  if (R.isNil(ancestors) || R.isEmpty(ancestors)) {
    return '';
  }

  const pathCollection = [...R.drop(1, ancestors), { name }];

  // @ts-ignore
  return `${pathCollection.map(entity => entity.name).join(' / ')}`;
};

export const rootAssetName = (ancestors, name) => {
  if (R.isNil(ancestors) || R.isEmpty(ancestors)) {
    return name;
  }

  // @ts-ignore
  return R.head(ancestors).name;
};

export const rootAssetId = (ancestors, id) => {
  if (R.isNil(ancestors) || R.isEmpty(ancestors)) {
    return id;
  }

  // @ts-ignore
  return R.head(ancestors).id;
};

export const inventoryCollectionSelector = createSelector(
  inventoryCollection,
  inventory =>
    inventory.map(item => ({
      ...item,
      rootAssetId: rootAssetId(item.ancestors, item.id),
      assetName: rootAssetName(item.ancestors, item.name),
      ancestors: inventoryAncestorPathString(item.ancestors, item.name),
    })),
);

export const getAddressParts = (address: string) => {
  if (!address) {
    return {
      street: '-',
      cityState: '-',
    };
  }

  const parts = address.split(', ');

  // If the address does not split into three distinct sections
  // it is inaccurate to try and split based on design.
  if (parts.length < 3) {
    return {
      street: '-',
      cityState: '-',
    };
  }

  const [street, ...rest] = parts;

  return {
    street,
    cityState: rest.join(', '),
  };
};

export const inventorySortColumnsSelector = createSelector(
  columnsSelector,
  columns => {
    if (R.isNil(columns) || R.isEmpty(columns)) {
      return [];
    }

    const sortableDataTypes = [
      'enumsingle',
      'shorttext',
      'longtext',
      'number',
      'date',
      'currency',
    ];

    const nonSortableColumns = [
      'assetType',
      'updatedDate',
      'activeWorkOrderCount',
      'assetCount',
      'assetUpdatedDate',
      'workOrderCount',
      'assignedWorkOrderCount',
      'estimatedWorkOrdersBudget',
      'pastDueWorkOrderCount',
      'estimatedWorkOrdersCost',
    ];

    return columns
      .filter(column => sortableDataTypes.includes(column.dataType))
      .filter(column => !nonSortableColumns.includes(column.fieldName))
      .map(column => column.fieldName);
  },
);
