import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as R from 'ramda';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import {
  useGoogleMapsStateUpdater,
  useGoogleMapsStateValue,
  withGoogleMapsState,
} from '@atom/components/common/map/hooks/googleMapsStateHook';
import Map from '@atom/components/common/map/Map';
import MapMarkers from '@atom/components/common/map/MapMarkers';
import MapPinMarker from '@atom/components/common/map/markers/MapPinMarker';
import { Progress } from '@atom/mui';
import { InventoryMapFilters } from '@atom/types/map';
import {
  getGeoJsonFromMapBounds,
  urlValueToLatLng,
} from '@atom/utilities/mapUtilities';

import InventoryPortalContext from '../InventoryPortalContext';

import { useInventoryMapData } from './hooks/inventoryMapDataHook';

interface Props {
  filters: InventoryMapFilters;
}

const InventoryMap = ({ filters }: Props) => {
  const { activeCategory } = useContext(InventoryPortalContext);

  const { isLoaded } = useGoogleMapsStateValue();
  const dispatch = useGoogleMapsStateUpdater();

  const [map, setMap] = useState<google.maps.Map>(null);
  const [coordinates, setCoordinates] = useState<string>(null);

  useEffect(() => {
    dispatch({
      type: 'SET',
      payload: {
        key: 'grab',
        data: false,
      },
    });
  }, []);

  const [queryParams, setQueryParams] = useQueryParams({
    id: StringParam,
    center: StringParam,
    zoom: NumberParam,
    searchPoint: StringParam,
  });

  const params = {
    coordinates,
    zoomLevel: queryParams.zoom,
    ...(activeCategory.id !== 'inventory' && {
      categoryIds: activeCategory.id,
    }),
    ...filters,
  };

  const [mapData, loading] = useInventoryMapData(params);
  const onLoad = useCallback(setMap, []);

  const onIdle = useCallback(() => {
    const updatedParams = {
      ...queryParams,
      id: queryParams.id,
      zoom: map.getZoom(),
      center: map.getCenter().toUrlValue(),
    };

    if (!R.equals(queryParams, updatedParams) || !coordinates) {
      setQueryParams(updatedParams);

      R.pipe(
        getGeoJsonFromMapBounds,
        R.prop('coordinates'),
        JSON.stringify,
        setCoordinates,
      )(map);
    }
  }, [map, setQueryParams, queryParams, coordinates]);

  return !isLoaded ? (
    <Progress style={{ height: '100%' }} />
  ) : (
    <Map
      onIdle={onIdle}
      onLoad={onLoad}
      center={queryParams.center && urlValueToLatLng(queryParams.center)}
      zoom={queryParams.zoom}
      loading={loading}
      mapStyleControl
      mapSearchBox
      layerControls
    >
      {/* @ts-ignore */}
      <MapMarkers data={JSON.stringify(mapData)} />
      {queryParams.searchPoint && (
        <MapPinMarker center={queryParams.searchPoint} />
      )}
    </Map>
  );
};

export default withGoogleMapsState<Props>(InventoryMap);
