import React from 'react';

import { InventoryAssetTreeStateType } from '@atom/types/inventory';

import ElementAssessmentTable from './ElementAssessmentTable';
import ElementDetailsTable from './ElementDetailsTable';

import '../workOrderDetailPdfReport.css';

interface Props {
  elements: InventoryAssetTreeStateType[];
  elementName: string;
}

const ElementsSection = ({ elements, elementName }: Props) => {
  return (
    !!elements.length && (
      <>
        <ElementAssessmentTable elements={elements} elementName={elementName} />
        <ElementDetailsTable elements={elements} elementName={elementName} />
        {elements.map(element => (
          <ElementsSection
            elements={element.elements}
            elementName={element.name}
          />
        ))}
      </>
    )
  );
};

export default ElementsSection;
