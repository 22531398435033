import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import UserContext from '@atom/components/userDetail/UserContext';
import { CREATE_USER_BUDGET, UPDATE_USER_BUDGET } from '@atom/graph/user';
import { Modal, Progress, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import layout from '@atom/styles/layout';
import {
  UserBudgetCreateInput,
  UserBudgetItem,
  UserBudgetUpdateInput,
  UserDetail,
} from '@atom/types/user';

import './budgetsTab.css';

const styles = {
  dialog: {
    bodyStyle: {
      margin: 0,
      padding: 0,
    },
    contentStyle: {
      width: layout.mediumModalWidth,
      paddingRight: '2rem',
      paddingBottom: '1rem',
      height: '100vh',
    },
    title: {
      fontSize: fonts.xl,
      padding: '1.125em',
      fontWeight: '500',
    },
    actionsContainerStyle: {
      paddingRight: '1rem',
      paddingBottom: '1rem',
    },
  },
  actionButtons: {
    cancel: { marginRight: '1rem' },
  },
  floatingLabelStyle: {
    color: colors.neutral.dim,
  },
  textFieldStyle: {
    color: colors.neutral.dark,
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
  isEdit?: boolean;
  budget?: UserBudgetItem;
}

const AddEditBudgetModal = ({ open, onClose, isEdit, budget }: Props) => {
  const { user } = useContext(UserContext);

  const [name, setName] = useState<string>(isEdit ? budget.name : '');
  const [rate, setRate] = useState<number>(isEdit ? budget.rate : null);

  useEffect(() => {
    if (open) {
      setName(isEdit ? budget.name : '');
      setRate(isEdit ? budget.rate : null);
    } else {
      setName('');
      setRate(null);
    }
  }, [open]);

  const [createBudget, { loading: createBudgetLoading }] = useMutation<
    { userBudgetCreate: UserDetail },
    { input: UserBudgetCreateInput }
  >(CREATE_USER_BUDGET);

  const [updateBudget, { loading: updateBudgetLoading }] = useMutation<
    { userBudgetUpdate: UserDetail },
    { input: UserBudgetUpdateInput }
  >(UPDATE_USER_BUDGET);

  const confirm = () => {
    if (isEdit) {
      updateBudget({
        variables: {
          input: {
            id: budget.id,
            userId: user.id,
            name,
            rate,
          },
        },
      });
    } else {
      createBudget({
        variables: {
          input: {
            userId: user.id,
            name,
            rate,
          },
        },
      });
    }

    onClose();
  };

  return (
    <Modal
      title={isEdit ? 'Edit Budget' : 'Add Budget'}
      onConfirm={confirm}
      onCancel={onClose}
      confirmButtonText={isEdit ? 'Save' : 'Add'}
      disabled={R.isEmpty(name) || R.isNil(rate) || Number(rate) < 0}
      open={open}
    >
      {createBudgetLoading || updateBudgetLoading ? (
        <Progress />
      ) : (
        <div styleName="modal-container">
          <div styleName="left-textfield">
            <TextField
              label="Name"
              style={styles.textFieldStyle}
              value={name}
              name="name"
              onChange={event => setName(event.target.value)}
            />
          </div>
          <div styleName="right-textfield">
            <span styleName="prefix-icon">$</span>
            <TextField
              label="Hourly Rate"
              style={styles.textFieldStyle}
              type="number"
              value={rate}
              name="rate"
              onChange={event => setRate(event.target.value)}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AddEditBudgetModal;
