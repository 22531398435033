import React from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { FAVORITES_LIST_LIMIT } from '@atom/types/favorite';

import './newFavoritesListButton.css';

export interface Props {
  canAddList: boolean;
  handleClick: (event: any) => void;
}

const NewFavoritesListButton = ({ canAddList, handleClick }: Props) => {
  const buildTooltipTextNewList = () => {
    return canAddList
      ? ''
      : `You can only create ${FAVORITES_LIST_LIMIT} favorite lists.`;
  };

  return (
    <div styleName="new-favorites-list-button">
      <IconButton onClick={handleClick} tooltip={buildTooltipTextNewList()}>
        <Icon styleName="new-favorites-list-icon" color={colors.brand.blue}>
          add
        </Icon>
        NEW LIST
      </IconButton>
    </div>
  );
};

export default NewFavoritesListButton;
