import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';

// @ts-ignore
import folderMove from '@atom/components/common/svgIcons/folderMove.svg';
import {
  CREATE_ANALYTICS_CARD,
  GET_ANALYTICS_FOLDER_TREE,
  MOVE_ANALYTICS_CARDS,
} from '@atom/graph/analytics';
import colors from '@atom/styles/colors';
import layout from '@atom/styles/layout';
import {
  AnalyticsCard,
  AnalyticsCardCreateInput,
  AnalyticsFolderTree as AnalyticsTree,
  AnalyticsModal,
  MoveAnalyticsCardInput,
} from '@atom/types/analytics';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import { flattenTreeChildren } from '@atom/utilities/treeUtilities';

import { Icon, IconButton, Progress, Snackbar } from '../common/mui';

import BulkMoveAnalyticsCardModal from './analyticsModals/BulkMoveAnalyticsCardModal';
import CreateAnalyticsCardModal from './analyticsModals/CreateAnalyticsCardModal';
import AnalyticsBreadcrumbs from './AnalyticsBreadcrumbs';
import AnalyticsContext from './AnalyticsContext';
import AnalyticsFolderList from './AnalyticsFolderList';
import AnalyticsFolderTree from './AnalyticsFolderTree';
import AnalyticsList from './AnalyticsList';
import AnalyticsSearch from './AnalyticsSearch';
import AnalyticsSearchResults from './AnalyticsSearchResults';

import './analyticsDashboard.css';

const style = {
  progress: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const AnalyticsDashboard = () => {
  const [activeFolderId, setActiveFolderId] = useState<string>('analytics');
  const [openFolders, setOpen] = useState<Set<string>>(new Set(['analytics']));
  const [searching, setSearching] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [triggerListRefetch, setTriggerListRefetch] = useState<boolean>();
  const [bulkMoveSelected, setBulkMoveSelected] = useState<Set<string>>(
    new Set([]),
  );

  const [activeModal, setActiveModal] = useState<AnalyticsModal>(null);

  const [isActiveFolderLeafNode, setIsActiveFolderLeafNode] = useState<boolean>(
    false,
  );

  const [getTree, { loading, data, refetch: refetchTree }] = useLazyQuery(
    GET_ANALYTICS_FOLDER_TREE,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => getTree(), []);
  useEffect(() => {
    if (!searching) {
      setQuery('');
    }
  }, [searching]);

  const toggleOpen = (id: string) => setOpen(toggleFromSet(openFolders, id));

  const [createCard] = useMutation<
    { analyticsCardCreate: AnalyticsCard },
    { input: AnalyticsCardCreateInput }
  >(CREATE_ANALYTICS_CARD);

  const handleAddCard = async (input: AnalyticsCardCreateInput) => {
    try {
      await createCard({
        variables: {
          input,
        },
      });
      Snackbar.info({
        message: `Created card "${input.name}".`,
      });
      setTriggerListRefetch(!triggerListRefetch);
    } catch (error) {
      Snackbar.error({
        message: `An error occurred while creating "${input.name}". Please Try Again.`,
      });
    } finally {
      setActiveModal(null);
    }
  };

  const [bulkMove] = useMutation<{
    MoveAnalyticsCardInput: MoveAnalyticsCardInput;
  }>(MOVE_ANALYTICS_CARDS);

  const handleBulkMove = async (folder: AnalyticsTree) => {
    try {
      await bulkMove({
        variables: {
          input: {
            destFolderId: folder.id,
            analyticsIds: Array.from(bulkMoveSelected),
          },
        },
      });
      setBulkMoveSelected(new Set([]));
      Snackbar.info({
        message: `Moved ${bulkMoveSelected.size} Cards to "${folder.name}"`,
        action: 'View',
        onActionClick: () => {
          setActiveFolderId(folder.id);
          setIsActiveFolderLeafNode(R.isEmpty(folder.children));
          setSearching(false);
        },
      });
      setTriggerListRefetch(!triggerListRefetch);
      refetchTree();
    } catch (error) {
      Snackbar.error({
        message: `An error occurred while moving ${bulkMoveSelected.size} cards to "${folder.name}". Please Try Again.`,
      });
    } finally {
      setActiveModal(null);
    }
  };

  const treeData = data?.analyticsFolderTree;
  const treeLoading = loading || R.isNil(treeData);
  const allFolders = useMemo(
    () => (!treeLoading ? flattenTreeChildren(treeData) : []),
    [treeData],
  );

  const getContent = () => {
    switch (true) {
      case searching:
        return <AnalyticsSearchResults />;
      case isActiveFolderLeafNode:
        return <AnalyticsList />;
      default:
        return <AnalyticsFolderList />;
    }
  };

  const BulkMoveHeader = (
    <>
      <span
        style={{ color: colors.brand.blue }}
      >{`${bulkMoveSelected.size} Selected`}</span>
      <IconButton onClick={() => setActiveModal(AnalyticsModal.MOVE)}>
        <img src={folderMove} />
      </IconButton>
    </>
  );

  const showCreateButton = hasRolePermissions(ROLE_SETS.ORG_ADMIN);

  return (
    <AnalyticsContext.Provider
      value={{
        activeFolderId,
        setActiveFolderId,
        openFolders,
        toggleOpen,
        refetchTree,
        treeLoading,
        treeData,
        isActiveFolderLeafNode,
        setIsActiveFolderLeafNode,
        allFolders,
        searching,
        setSearching,
        query,
        setQuery,
        setTriggerListRefetch,
        triggerListRefetch,
        bulkMoveSelected,
        setBulkMoveSelected,
      }}
    >
      <div styleName="header-container">
        <div styleName="header-content">
          <div styleName="header-title">Analytics</div>
          {showCreateButton && (
            <IconButton onClick={() => setActiveModal(AnalyticsModal.CREATE)}>
              <Icon style={{ color: colors.neutral.white }}>add</Icon>
            </IconButton>
          )}
        </div>
      </div>
      <div styleName="analytics-portal-content">
        <div
          styleName="left-block"
          style={{
            width: layout.sidePaneWidth,
            flex: `0 0 ${layout.sidePaneWidth}`,
          }}
        >
          {treeLoading ? (
            <div style={style.progress}>
              <Progress />
            </div>
          ) : (
            <AnalyticsFolderTree />
          )}
        </div>
        <div styleName="right-block">
          <div styleName="analytics-subheader">
            {bulkMoveSelected.size > 0 ? (
              BulkMoveHeader
            ) : (
              <>
                <AnalyticsBreadcrumbs />
                <AnalyticsSearch />
              </>
            )}
          </div>
          {getContent()}
        </div>
      </div>
      <CreateAnalyticsCardModal
        openFolders={activeModal === AnalyticsModal.CREATE}
        onClose={() => setActiveModal(null)}
        handleAddCard={handleAddCard}
      />
      <BulkMoveAnalyticsCardModal
        openFolders={activeModal === AnalyticsModal.MOVE}
        onClose={() => setActiveModal(null)}
        handleBulkMove={handleBulkMove}
        title={`Move ${bulkMoveSelected.size} Cards to Folder`}
      />
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsDashboard;
