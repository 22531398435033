import React from 'react';

import { Switch } from '@atom/mui';

import MapDrawerLayerIcon from './MapDrawerLayerIcon';

import './mapDrawerLayers.css';

interface Props {
  onUpdate: (boolean) => Promise<any>;
  toggled: boolean;
}

const MapDrawerLayersUsersSection = ({ onUpdate, toggled }: Props) => {
  return (
    <div styleName="section-container">
      <div styleName="section-header-container">
        <Switch
          label={
            <div styleName="toggle-label">
              <div styleName="section-header-icon">
                <MapDrawerLayerIcon name="account_circle" />
              </div>
              <div>Team</div>
            </div>
          }
          checked={toggled}
          onChange={() => {
            onUpdate(!toggled);
          }}
        />
      </div>
    </div>
  );
};

export default MapDrawerLayersUsersSection;
