import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import {
  useGoogleMapsStateUpdater,
  useGoogleMapsStateValue,
  withGoogleMapsState,
} from '@atom/components/common/map/hooks/googleMapsStateHook';
import Map from '@atom/components/common/map/Map';
import MapMarkers from '@atom/components/common/map/MapMarkers';
import MapPinMarker from '@atom/components/common/map/markers/MapPinMarker';
import { WORK_ORDER_UPDATE } from '@atom/graph/work';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Progress } from '@atom/mui';
import { getLoggedInUserMapLocationSelector } from '@atom/selectors/mapSelectors';
import { GeoJSON } from '@atom/types/geojson';
import {
  LocationLinkOption,
  LocationUpdatePayload,
  MapEditMetadata,
  MapEditMetadataType,
} from '@atom/types/map';
import { PolicyAction } from '@atom/types/policy';
import {
  WorkOrderDetailType,
  WorkOrderUpdate,
  WorkOrderUpdateInput,
} from '@atom/types/work';
import { hasAccess } from '@atom/utilities/accessUtilities';
import api from '@atom/utilities/api';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { INVENTORY_ASSETS_ENDPOINT } from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';
import { isPoint } from '@atom/utilities/locationUtilities';
import {
  getGeoJsonFromMapBounds,
  getGoogleShapeCenter,
  getMapCenterAndZoomParams,
  getMapEditMetadata,
  urlValueToLatLng,
} from '@atom/utilities/mapUtilities';

const WORK_DETAIL_ZOOM = 17;

interface Props {
  workOrderDetail: WorkOrderDetailType;
  refetch: () => void;
  loading: boolean;
  initialOpenEditList?: boolean;
}

const WorkOrderDetailMap = ({
  workOrderDetail,
  refetch,
  loading,
  initialOpenEditList,
}: Props) => {
  const currentUserLocation = useSelector(getLoggedInUserMapLocationSelector);

  const dispatch = useGoogleMapsStateUpdater();
  const { isLoaded } = useGoogleMapsStateValue();
  const preferences = usePreferences();

  const [map, setMap] = useState<google.maps.Map>(null);
  const [coordinates, setCoordinates] = useState<string>(null);
  const [assetUpdateLoading, setAssetUpdateLoading] = useState<boolean>(false);

  const [updateWorkDetail, { loading: workUpdateLoading }] = useMutation<
    { workOrderUpdate: WorkOrderUpdate },
    { workOrder: WorkOrderUpdateInput }
  >(WORK_ORDER_UPDATE);

  useEffect(() => {
    dispatch({
      type: 'SET',
      payload: {
        key: 'grab',
        data: false,
      },
    });
  }, []);

  const {
    location: workOrderLocation,
    inventoryAssetId,
    inventoryAssetName,
    inventoryAssetLocation,
    inventoryAssetMarkerId,
    inventoryAssetAddress,
    inventoryAssetActions,
    isClosed,
  } = workOrderDetail;

  const hasAsset = !R.isEmpty(inventoryAssetId);

  const [queryParams, setQueryParams] = useQueryParams({
    id: StringParam,
    center: StringParam,
    zoom: NumberParam,
    searchPoint: StringParam,
  });

  useEffect(() => {
    const center = getGoogleShapeCenter(workOrderLocation);
    setQueryParams({
      ...queryParams,
      zoom: WORK_DETAIL_ZOOM,
      center: `${center?.lat},${center?.lng}`,
    });

    history.handleHistoryChange(location, 'POP');
  }, []);

  const onLoad = useCallback(setMap, []);

  const onIdle = useCallback(() => {
    try {
      const params = getMapCenterAndZoomParams(
        map,
        queryParams,
        preferences,
        currentUserLocation,
      );

      if (!R.equals(queryParams, params) || !coordinates) {
        setQueryParams(params);
        R.pipe(
          getGeoJsonFromMapBounds,
          R.prop('coordinates'),
          JSON.stringify,
          setCoordinates,
        )(map);

        history.handleHistoryChange(location, 'POP');
      }
    } catch {
      setQueryParams(null);

      history.handleHistoryChange(location, 'POP');
    }
  }, [map, setQueryParams, queryParams, coordinates, preferences]);

  const asset = {
    id: inventoryAssetId,
    name: inventoryAssetName,
    location: inventoryAssetLocation,
    address: inventoryAssetAddress,
    markerId: inventoryAssetMarkerId,
  };

  const canUpdateAssetLocation = hasAccess(
    PolicyAction.UPDATE_LOCATION,
    inventoryAssetActions,
  );

  const canEditAsset = !isClosed && canUpdateAssetLocation;
  const canEditWorkOrder = !isClosed && hasRolePermissions(ROLE_SETS.INSPECTOR);

  const locationLinkControlsEnabled =
    (canEditAsset || canEditWorkOrder) &&
    hasAsset &&
    isPoint(inventoryAssetLocation);

  const mapEditMetadata: MapEditMetadata[] = useMemo(
    () => [
      getMapEditMetadata(
        workOrderDetail,
        canEditWorkOrder,
        MapEditMetadataType.WORK_ORDER,
      ),
      ...(hasAsset && [
        getMapEditMetadata(asset, canEditAsset, MapEditMetadataType.ASSET),
      ]),
    ],
    [workOrderDetail],
  );

  const mapData = {
    workOrders: [workOrderDetail],
    assets: [
      {
        id: inventoryAssetId,
        location: inventoryAssetLocation,
        markerId: inventoryAssetMarkerId,
      },
    ],
  };

  const updateWorkLocation = async (location: GeoJSON) => {
    await updateWorkDetail({
      variables: {
        workOrder: {
          id: workOrderDetail.id,
          location,
        },
      },
    });

    refetch();
  };

  const updateAssetLocation = async (location: GeoJSON) => {
    setAssetUpdateLoading(true);

    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${inventoryAssetId}`;
    await api.patch(endpoint, { location });

    setAssetUpdateLoading(false);
    refetch();
  };

  const onSave = (payload: LocationUpdatePayload) => {
    if (payload?.type === MapEditMetadataType.WORK_ORDER) {
      updateWorkLocation(payload?.location);
    } else {
      updateAssetLocation(payload?.location);
    }
  };

  const setInventoryAssetLocationFromWorkOrder = async () => {
    await updateAssetLocation(workOrderLocation);
  };

  const setWorkOrderLocationFromInventoryAsset = async () => {
    await updateWorkLocation(inventoryAssetLocation);
  };

  const locationLinkOptions: LocationLinkOption[] = [
    ...(canEditWorkOrder
      ? [
          {
            text: `Sync work to inventory's location`,
            onClick: setWorkOrderLocationFromInventoryAsset,
          },
        ]
      : []),
    ...(canEditAsset
      ? [
          {
            text: `Sync inventory to work's location`,
            onClick: setInventoryAssetLocationFromWorkOrder,
          },
        ]
      : []),
  ];

  const markerLoading = workUpdateLoading || assetUpdateLoading;

  return !isLoaded ? (
    <Progress style={{ height: '100%' }} />
  ) : (
    <Map
      onIdle={onIdle}
      onLoad={onLoad}
      // @ts-ignore
      center={queryParams.center && urlValueToLatLng(queryParams.center)}
      zoom={queryParams.zoom}
      mapStyleControl
      layerControls
      mapSearchBox
      locationLinkControls
      editControls
      mapEditMetadata={mapEditMetadata}
      onSave={onSave}
      locationEditZoom={WORK_DETAIL_ZOOM}
      loading={loading}
      locationLinkOptions={locationLinkOptions}
      locationLinkControlsDisabled={!locationLinkControlsEnabled}
      initialOpenEditList={initialOpenEditList}
    >
      {!markerLoading && (
        // @ts-ignore
        <MapMarkers onClick={() => {}} data={JSON.stringify(mapData)} />
      )}
      {queryParams.searchPoint && (
        <MapPinMarker center={queryParams.searchPoint} />
      )}
    </Map>
  );
};

export default withGoogleMapsState<Props>(WorkOrderDetailMap);
