import React from 'react';
import { useMutation } from '@apollo/client';

import { TASK_UPDATE, TASK_USERS_STATUS_UPDATE } from '@atom/graph/task';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { Modal } from '@atom/mui';
import {
  Task,
  TaskUpdateInput,
  TaskUsersStatusUpdateInput,
  TaskUserStatus,
} from '@atom/types/task';

interface Props {
  open: boolean;
  onCancel: () => void;
  onComplete: () => void;
  task: Task;
  workOrderId: string;
}

const RequiredFieldsConfirmModal = ({
  open,
  onCancel,
  onComplete,
  task,
  workOrderId,
}: Props) => {
  const userProfile = useUserProfile();

  const [taskUpdate] = useMutation<
    { taskUpdate: Task },
    { input: TaskUpdateInput }
  >(TASK_UPDATE);

  const [updateUsersTaskStatus] = useMutation<
    { taskUsersStatusUpdate: boolean },
    { input: TaskUsersStatusUpdateInput }
  >(TASK_USERS_STATUS_UPDATE);

  const handleProceed = async () => {
    const userIsAssignedToTask = task.users
      .map(item => item.id)
      .includes(userProfile.userId);

    if (userIsAssignedToTask) {
      await updateUsersTaskStatus({
        variables: {
          input: {
            workOrderId,
            taskId: task.id,
            users: [
              {
                userId: userProfile.userId,
                status: TaskUserStatus.IN_PROGRESS,
              },
            ],
          },
        },
      });

      onComplete();
    } else {
      await taskUpdate({
        variables: {
          input: {
            id: task.id,
            workOrderId,
            isCompleted: false,
          },
        },
      });

      onComplete();
    }
  };

  return (
    <Modal
      open={open}
      cancelButtonText="Cancel"
      confirmButtonText="Proceed"
      onCancel={onCancel}
      onConfirm={handleProceed}
      title="Make Changes to Required Fields?"
    >
      This task has been marked complete, any changes made to required fields
      will mark the task incomplete. Do you want to proceed?
    </Modal>
  );
};

export default RequiredFieldsConfirmModal;
