import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import thunkMiddleware from 'redux-thunk';

import rootReducer from '../reducers/rootReducer';

export function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [thunkMiddleware, sagaMiddleware];

  const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middlewares)),
  );

  // @ts-ignore
  store.runSaga = sagaMiddleware.run;
  // @ts-ignore
  store.close = () => store.dispatch(END);

  return store;
}

export default configureStore;
