import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import TeamContext from '@atom/components/teamPortal/TeamContext';
import { TIME_ENTRIES_SUBMIT_BY_USER } from '@atom/graph/timeEntry';
import { Button, Menu, Modal, Switch } from '@atom/mui';
import { PayPeriod } from '@atom/types/payPeriod';
import { TimeEntriesSubmitByUserInput } from '@atom/types/timeEntry';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';

import './timeHeaders.css';

const MenuItem = Menu.MenuItem;

const HTTP_STATUS_CONFLICT = 409;

const styles = {
  switch: {
    marginLeft: '1rem',
  },
};

interface Props {
  showSubmitButton?: boolean;
  showCondensedViewButton?: boolean;
  disableSubmitButton?: boolean;
  condensedView?: boolean;
  setCondensedView?: (condensedView: boolean) => void;
  refetchTimeSheet?: () => void;
  loading: boolean;
}

const PayPeriodControls = ({
  showSubmitButton,
  showCondensedViewButton,
  disableSubmitButton,
  condensedView,
  setCondensedView,
  refetchTimeSheet,
  loading,
}: Props) => {
  const {
    payPeriodYear,
    setPayPeriodYear,
    selectedPayPeriod,
    setSelectedPayPeriod,
    payPeriods,
  } = useContext(TeamContext);

  const [submitOpen, setSubmitOpen] = useState<boolean>(false);
  const [invalidOpen, setInvalidOpen] = useState<boolean>(false);

  const [
    timeEntriesSubmitByUser,
    { loading: timeEntriesSubmitByUserLoading },
  ] = useMutation<
    { timeEntriesSubmitByUser: boolean },
    { input: TimeEntriesSubmitByUserInput }
  >(TIME_ENTRIES_SUBMIT_BY_USER);

  const getPayPeriodDisplay = (selectedPeriod: PayPeriod) => {
    return `${selectedPeriod.period} (${setDisplayDate(
      selectedPeriod.startDate,
      'MM/dd',
    )} - ${setDisplayDate(selectedPeriod.endDate, 'MM/dd')})`;
  };

  const yearOptions = R.pathOr([], ['availableYears'], payPeriods);

  const handleSubmit = async () => {
    try {
      await timeEntriesSubmitByUser({
        variables: {
          input: {
            dateStart: selectedPayPeriod.startDate,
            dateEnd: selectedPayPeriod.endDate,
          },
        },
      });

      refetchTimeSheet();
      setSubmitOpen(false);
    } catch (err) {
      setSubmitOpen(false);

      if (err?.networkError?.statusCode === HTTP_STATUS_CONFLICT) {
        setInvalidOpen(true);
      }
    }
  };

  const disableSubmit =
    loading ||
    timeEntriesSubmitByUserLoading ||
    disableSubmitButton ||
    doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  return (
    <>
      <div styleName="pay-periods-header">
        <div styleName="left-container">
          <div>Pay Period:</div>
          <div styleName="menu-container">
            <Menu
              icon={<div styleName="pay-period-select">{payPeriodYear}</div>}
              noIconButton
              noMinWidth
              data-cy="timesheetPayPeriodSelect"
            >
              {yearOptions.map(option => {
                return (
                  <MenuItem
                    key={option}
                    onClick={() => setPayPeriodYear(option)}
                    selected={payPeriodYear === option}
                  >
                    {option}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
          <div styleName="menu-container">
            <Menu
              icon={
                <div styleName="pay-period-select">
                  {getPayPeriodDisplay(selectedPayPeriod)}
                </div>
              }
              noIconButton
              noMinWidth
            >
              {payPeriods.periods.map(periodItem => {
                return (
                  <MenuItem
                    key={periodItem.period}
                    onClick={() => setSelectedPayPeriod(periodItem)}
                    selected={selectedPayPeriod.period === periodItem.period}
                  >
                    {getPayPeriodDisplay(periodItem)}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
          {showCondensedViewButton && (
            <Switch
              checked={condensedView}
              onChange={() => setCondensedView(!condensedView)}
              name="condensedView"
              label="Condensed View"
              style={styles.switch}
              data-cy="timeheetCondensedViewToggle"
            />
          )}
        </div>
        {showSubmitButton && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setSubmitOpen(true)}
            disabled={disableSubmit}
            data-cy="timesheetSubmitButton"
          >
            Submit
          </Button>
        )}
      </div>
      <Modal
        open={submitOpen}
        title="Submit Timesheet"
        loading={loading || timeEntriesSubmitByUserLoading}
        onConfirm={handleSubmit}
        onCancel={() => setSubmitOpen(false)}
        confirmButtonText="submit"
        data-cy="timesheetSubmitModal"
      >
        <div>Are you sure you want to submit this timesheet?</div>
      </Modal>
      <Modal
        open={invalidOpen}
        title="Cannot Submit Timesheet"
        loading={loading}
        footer={
          <Button
            onClick={() => setInvalidOpen(false)}
            data-cy="cannotSubmitTimesheetModalOKButton"
          >
            OK
          </Button>
        }
      >
        <div>
          The timesheet cannot be submitted because you entered more than 24
          hours on one or more of the dates. Please modify your timesheet and
          try again.
        </div>
      </Modal>
    </>
  );
};

export default PayPeriodControls;
