import * as React from 'react';

import CarouselWrapper from '@atom/components/common/photoCarousel/CarouselWrapper';
import { RadioGroup } from '@atom/mui';
import colors from '@atom/styles/colors';
import { EventType } from '@atom/types/event';
import { FormFieldType } from '@atom/types/form';
import { MediaItem } from '@atom/types/media';

import FileUploadButton from '../FileUploadButton';
import FileUploadTile from '../FileUploadTile';

import '../formInstance.css';

const { RadioButton } = RadioGroup;

const styles = {
  radioButtonStyles: {
    color: colors.brand.blue,
    paddingTop: '1rem',
  },
  labelStyles: {
    whiteSpace: 'nowrap',
    paddingLeft: '0.5rem',
  },
};

interface PassedProps {
  field: FormFieldType;
  media: MediaItem[];
  fieldId: string;
  onChange: (value: string) => void;
  removeFile: (mediaId: string, subjectId: string) => void;
  fieldFileUpload: (event: EventType) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  value: any;
  isEditable: boolean;
  isUploadDisabled: boolean;
}

type Props = PassedProps;

class RadioField extends React.Component<Props> {
  onRadioChange = (event: any): void => {
    const { onChange, value } = this.props;
    if (event.target.value === value) {
      return onChange('');
    }
    return onChange(event.target.value);
  };

  render() {
    const {
      field,
      value,
      removeFile,
      fieldId,
      isEditable,
      fieldFileUpload,
      media,
      isUploadDisabled,
      renameMedia,
    } = this.props;
    const required = field.required ? 'required' : '';
    const medium = media.filter((med: MediaItem) => med.fieldId === fieldId);

    return (
      <div styleName="field-block">
        <div styleName={required}>{field.title}</div>
        <div styleName="field-description">{field.description}</div>
        <div styleName="radio-container">
          <RadioGroup
            name="radio"
            defaultValue={value}
            onChange={this.onRadioChange}
          >
            {field.enum.map((option: string, index: number) => {
              return (
                <RadioButton
                  value={option}
                  label={option}
                  key={index}
                  style={styles.labelStyles}
                  disabled={!isEditable}
                />
              );
            })}
          </RadioGroup>
        </div>
        {field.fileUpload && (
          <FileUploadButton
            onChange={fieldFileUpload}
            isUploadDisabled={!isEditable || isUploadDisabled}
          />
        )}
        <div styleName="file-tile-container">
          {medium.map((mediaItem: MediaItem) => (
            <CarouselWrapper
              media={media}
              selectedMediaId={mediaItem.id}
              removeFile={removeFile}
              subjectId={fieldId}
              renameMedia={renameMedia}
              canUpdateMedia={isEditable}
              canDeleteMedia={isEditable}
            >
              <FileUploadTile key={mediaItem.id} media={mediaItem} />
            </CarouselWrapper>
          ))}
        </div>
      </div>
    );
  }
}

export default RadioField;
