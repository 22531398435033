import React, { useEffect, useState } from 'react';
import * as R from 'ramda';

import LoadMoreButton from '@atom/components/common/loadMoreButton/LoadMoreButton';
import { useGroupedPagination } from '@atom/hooks/useGroupedPagination';
import { Checkbox, Collapse, Icon, List, Progress, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { PolicyAction } from '@atom/types/policy';
import { WorkOrderType } from '@atom/types/work';
import {
  SearchWorkTemplate,
  WorkTemplateFolderTree,
  WorkTemplateListItem,
} from '@atom/types/workTemplate';
import api from '@atom/utilities/api';
import {
  WORK_TEMPLATES_ENDPOINT,
  WORK_TEMPLATES_FOLDER_TREE_ENDPOINT,
} from '@atom/utilities/endpoints';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import { getWorkTypeIconSvg } from '@atom/utilities/workTypeIconUtilities';

import WorkTemplateSearch from './WorkTemplateSearch';

const { ListItemButton, ListItemText } = List;

const TEMPLATES_LIMIT = 100;

type WorkTemplate = WorkTemplateListItem | SearchWorkTemplate;

interface Props {
  /**
   * If defined, only work templates applicable to the given schema will be rendered.
   * This includes work templates with the given schema or with no schema.
   */
  schemaId?: string;
  /**
   * If defined, only work templates with or without schema will be returned.
   * If used in conjunction with schemaId the two filters are combined into a logical OR operator.
   * This allows for filters such as schemaId=1 OR schemaId=null
   */
  hasSchema?: boolean;
  /**
   * If defined, only work templates that the user has access with the defined action
   * will be returned in the tree. The default is "READ"
   */
  action?: PolicyAction;
  /**
   * Optional callback to be called on work template click.
   */
  onClick?: (template: WorkTemplate) => void;
  /**
   * Optional callback called for each work template in the tree
   * to determine if it should be marked selected.
   * If provided, work template selection will be enabled via checkboxes.
   */
  getTemplateSelected?: (template: WorkTemplate) => boolean;
  /**
   * Optional callback called for each work template in the tree
   * to determine if it should be disabled. If not provided,
   * all work templates will be enabled by default.
   */
  getTemplateDisabled?: (template: WorkTemplate) => boolean;
  /**
   * Optional callback to call when work template is toggled. Will only be called
   * when multi selection is enabled by providing selectedIds
   */
  onTemplateToggle?: (template: WorkTemplate) => void;
  /**
   * Optional callback called for each work template folder in the tree
   * to determine if it should be marked selected.
   * If provided, work template folder selection will be enabled via checkboxes.
   */
  getFolderSelected?: (folder: WorkTemplateFolderTree) => boolean;
  /**
   * Optional callback called for each work template folder to determine
   * if it should be marked as disabled. Will only be called if folder
   * selection is enabled by defining getFolderSelected.
   */
  getFolderDisabled?: (folder: WorkTemplateFolderTree) => boolean;
  /**
   * Optional callback to called when work template folder is toggled.
   * Will only be called if selection is enabled by defining getFolderSelected.
   */
  onFolderToggle?: (folder: WorkTemplateFolderTree) => void;
  /**
   * Denotes whether search for work templates should be enabled.
   * Defaults to true.
   */
  includeSearch?: boolean;
  /**
   * Optional content to be rendered at the top of the modal content.
   * Useful for additional content such as "select all" or "deselect all" buttons.
   */
  additionalContentTop?: React.ReactNode;
  /**
   * Content to render for "empty state" when there are no work templates after filtering
   */
  emptyContent?: React.ReactNode;
  /**
   * Determines whether a checkbox or checkmark will be used to denote
   * if a template is selected. Currently design has associated multi
   * select functionality to checkbox and single select to checkmark.
   * Defaults to checkbox
   */
  select?: 'checkbox' | 'checkmark';
  /**
   * If true all descendants of selected folder will also be
   * marked as selected and disabled.
   */
  autoSelectChildFolders?: boolean;
  /**
   * Optional prop specifying a different name to use for the root tree.
   * This is useful for instances like "Select All".
   * Defaults to "Work Templates".
   */
  rootTreeName?: string;
  /**
   * Hides templates for selecting only folders
   */
  hideTemplates?: boolean;
  /**
   * type allows the template lists to be filtered by WorkOrderType
   */
  type?: WorkOrderType;
}

type Tree = WorkTemplateFolderTree | WorkTemplateListItem;

const isTreeFolder = (tree: Tree): tree is WorkTemplateFolderTree => {
  return tree.hasOwnProperty('hasTemplates');
};

const treeHasChildren = (tree: Tree, level: number): boolean => {
  return (
    isTreeFolder(tree) &&
    (level === 0 || tree.hasTemplates || !!tree.children.length)
  );
};

const styles = {
  progress: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toggle: {
    cursor: 'pointer',
    marginRight: '0.5rem',
  },
  icon: {
    marginRight: '0.5rem',
  },
  close: {
    cursor: 'pointer',
  },
};

const WorkTemplateTree = ({
  schemaId,
  hasSchema,
  action = PolicyAction.READ,
  onClick,
  onTemplateToggle,
  getTemplateSelected,
  getTemplateDisabled,
  getFolderSelected,
  getFolderDisabled,
  onFolderToggle,
  additionalContentTop,
  includeSearch = true,
  emptyContent,
  autoSelectChildFolders,
  hideTemplates,
  select = 'checkbox',
  rootTreeName = 'Work Templates',
  type,
}: Props) => {
  const [open, setOpen] = useState<Set<string>>(new Set(['workTypes']));
  const [query, setQuery] = useState<string>('');
  const [rootTree, setRootTree] = useState<WorkTemplateFolderTree>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const {
    getData,
    hasMore,
    fetchMore,
    isLoading,
    hasError,
    reset,
  } = useGroupedPagination<WorkTemplateListItem>({
    limit: TEMPLATES_LIMIT,
    fetcher: (folderId: string, page: number, limit: number) => {
      return api.get(WORK_TEMPLATES_ENDPOINT, {
        folderId,
        schemaId,
        hasSchema,
        action,
        page,
        limit,
        published: true,
        type,
      });
    },
  });

  useEffect(() => {
    const getWorkTemplateFolderTree = async () => {
      setLoading(true);

      const { data } = await api.get(WORK_TEMPLATES_FOLDER_TREE_ENDPOINT, {
        schemaId,
        hasSchema,
        action,
      });

      setOpen(
        new Set<string>(['workTypes']),
      );

      setRootTree({ ...data, name: rootTreeName });

      reset();

      setLoading(false);
    };

    getWorkTemplateFolderTree();
  }, [schemaId, hasSchema]);

  const handleToggle = (folder: WorkTemplateFolderTree) => {
    const { id } = folder;

    if (!open.has(id) && folder.hasTemplates && !getData(id).length) {
      fetchMore(id);
    }

    setOpen(toggleFromSet(open, id));
  };

  const handleWorkTemplateToggle = (
    event: React.MouseEvent<any>,
    template: WorkTemplate,
  ) => {
    event?.stopPropagation();

    if (onTemplateToggle) {
      onTemplateToggle(template);
    }
  };

  const handleFolderToggle = (
    event: React.MouseEvent<any>,
    folder: WorkTemplateFolderTree,
  ) => {
    event?.stopPropagation();

    if (onFolderToggle) {
      onFolderToggle(folder);
    }
  };

  const handleClick = (template: WorkTemplate) => {
    if (onClick) {
      onClick(template);
    }
  };

  const renderList = (
    trees: Tree[],
    level: number,
    isAncestorSelected?: boolean,
    parentHasTemplates?: boolean,
    isParentLoading?: boolean,
  ) => {
    if (parentHasTemplates && !trees.length && !isParentLoading) {
      return (
        <ListItemButton
          disableGutters
          style={{ paddingLeft: `${level + 1}rem`, paddingRight: '1rem' }}
          onClick={() => {}}
          disabled
        >
          <Icon color={colors.neutral.dark} style={{ marginRight: '0.5rem' }}>
            info
          </Icon>
          Folder does not have published work templates
        </ListItemButton>
      );
    }

    if (!trees.length) {
      return null;
    }

    // indicates caret icon will be shown (used for padding calculation)
    const levelHasChildren = trees.some(treeHasChildren);

    const isRoot = level === 1;

    return trees.map(tree => {
      const isValid = isTreeFolder(tree)
        ? !getFolderDisabled || !getFolderDisabled(tree)
        : !getTemplateDisabled || !getTemplateDisabled(tree);

      const hasTemplates = isTreeFolder(tree) && tree.hasTemplates;

      const hasChildren =
        isTreeFolder(tree) &&
        (level === 0 || tree.hasTemplates || !!tree.children.length);

      const isSelected = isTreeFolder(tree)
        ? getFolderSelected && getFolderSelected(tree)
        : getTemplateSelected && getTemplateSelected(tree);

      const isSelectable = isTreeFolder(tree)
        ? !!getFolderSelected
        : !!getTemplateSelected;

      const primaryTextStyle = {
        color:
          isSelected && select === 'checkmark'
            ? colors.brand.blue
            : colors.neutral.dark,
      };

      const padding = [
        level,
        // add extra padding to account for caret icons for other items on same level
        levelHasChildren && !hasChildren ? 2 : 0,
      ].reduce((acc, num) => acc + num, 0);

      const nextAssetLevel = isSelectable ? level + 3 : level + 1;

      const invisible = hasTemplates && hideTemplates;

      const dataCy = `workTypeSelect${hasTemplates ? 'Folder' : 'Template'}-${
        tree.name
      }`;

      const templateType = R.pathOr(WorkOrderType.DEFAULT, ['type'], tree);

      return (
        <React.Fragment key={tree.id}>
          <ListItemButton
            disableGutters
            data-cy={dataCy}
            style={{ paddingLeft: `${padding}rem`, paddingRight: '1rem' }}
            onClick={() =>
              isTreeFolder(tree) ? handleToggle(tree) : handleClick(tree)
            }
            disabled={!isTreeFolder(tree) && !isValid}
          >
            {hasChildren && (
              <Icon style={styles.toggle} invisible={invisible}>
                {open.has(tree.id) ? 'arrow_drop_down' : 'arrow_right'}
              </Icon>
            )}
            {isSelectable && select === 'checkbox' && (
              <Checkbox
                checked={
                  isSelected || (autoSelectChildFolders && isAncestorSelected)
                }
                disabled={
                  !isValid ||
                  (autoSelectChildFolders && isAncestorSelected && !isRoot)
                }
                onClick={event =>
                  isTreeFolder(tree)
                    ? handleFolderToggle(event, tree)
                    : handleWorkTemplateToggle(event, tree)
                }
              />
            )}
            {!isTreeFolder(tree) && (
              <Icon style={styles.icon}>
                <img
                  src={getWorkTypeIconSvg(
                    tree?.isParent,
                    tree?.parentId,
                    !isValid,
                  )}
                />
              </Icon>
            )}
            {isTreeFolder(tree) && hideTemplates && (
              <Icon style={styles.icon}>folder</Icon>
            )}
            <ListItemText
              primary={tree.name}
              primaryTextStyle={primaryTextStyle}
              secondary={
                !isTreeFolder(tree) && templateType === WorkOrderType.DEFAULT
                  ? tree.schemaName || 'All Asset Types'
                  : ''
              }
            />
            {isSelected && select === 'checkmark' && (
              <Icon color={colors.brand.blue}>check</Icon>
            )}
          </ListItemButton>
          {hasChildren && (
            <Collapse in={open.has(tree.id)} timeout="auto" unmountOnExit>
              {hasTemplates && !hideTemplates ? (
                <>
                  {renderList(
                    getData(tree.id),
                    nextAssetLevel,
                    isAncestorSelected || isSelected,
                    hasTemplates,
                    isLoading(tree.id),
                  )}
                  {hasMore(tree.id) && (
                    <LoadMoreButton
                      loading={isLoading(tree.id)}
                      error={hasError(tree.id)}
                      onClick={() => fetchMore(tree.id)}
                      style={{
                        paddingLeft: `${nextAssetLevel}rem`,
                        paddingRight: '1rem',
                      }}
                    />
                  )}
                </>
              ) : (
                renderList(
                  (tree as any).children,
                  R.inc(level),
                  isAncestorSelected || isSelected,
                )
              )}
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <>
      {includeSearch && (
        <TextField
          value={query}
          onChange={event => setQuery(event.target.value)}
          placeholder="Search Work Templates"
          InputProps={{
            startAdornment: <Icon>search</Icon>,
            endAdornment: query && (
              <Icon style={styles.close} onClick={() => setQuery('')}>
                close
              </Icon>
            ),
          }}
        />
      )}
      {query.length >= 3 ? (
        <WorkTemplateSearch
          query={query}
          schemaId={schemaId}
          hasSchema={hasSchema}
          onClick={handleClick}
          onToggle={template => handleWorkTemplateToggle(null, template)}
          getTemplateDisabled={getTemplateDisabled}
          getTemplateSelected={getTemplateSelected}
          action={action}
          type={type}
        />
      ) : (
        <>
          {loading ? (
            <div style={styles.progress}>
              <Progress />
            </div>
          ) : R.isEmpty(rootTree?.children) && emptyContent ? (
            emptyContent
          ) : (
            <List>
              {additionalContentTop}
              {renderList(
                [rootTree],
                includeSearch ? 0 : 1,
                getFolderSelected && getFolderSelected(rootTree),
              )}
            </List>
          )}
        </>
      )}
    </>
  );
};

export default WorkTemplateTree;
