import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function formTemplatesReducer(
  state = initialState.formTemplates,
  action,
) {
  switch (action.type) {
    case actionTypes.GET_FORM_TEMPLATES_SUCCESS: {
      return action.data ? [...action.data] : [];
    }
    case actionTypes.RENAME_FORM_TEMPLATE_SUCCESS: {
      return state.map(formTemplate =>
        formTemplate.id === action.data.id ? action.data : formTemplate,
      );
    }
    case actionTypes.DELETE_FORM_TEMPLATE_SUCCESS: {
      return state.filter(formTemplate => formTemplate.id !== action.data);
    }
    default: {
      return state;
    }
  }
}
