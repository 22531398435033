import React from 'react';

import { AnalyticsFolder, AnalyticsFolderTree } from '@atom/types/analytics';

interface Context {
  activeFolderId: string;
  setActiveFolderId: React.Dispatch<React.SetStateAction<string>>;
  openFolders: Set<string>;
  toggleOpen: (id: string) => void;
  refetchTree: () => void;
  treeLoading: boolean;
  treeData: AnalyticsFolderTree;
  isActiveFolderLeafNode: boolean;
  setIsActiveFolderLeafNode: React.Dispatch<React.SetStateAction<boolean>>;
  allFolders: AnalyticsFolder[];
  searching: boolean;
  setSearching: React.Dispatch<React.SetStateAction<boolean>>;
  query: string;
  setQuery: (query: string) => void;
  triggerListRefetch: boolean;
  setTriggerListRefetch: (trigger: boolean) => void;
  bulkMoveSelected: Set<string>;
  setBulkMoveSelected: (folderIds: Set<string>) => void;
}

export const initialState: Context = {
  activeFolderId: 'analytics',
  setActiveFolderId: () => {},
  openFolders: new Set(['analytics']),
  toggleOpen: () => {},
  refetchTree: () => {},
  treeLoading: false,
  treeData: null,
  isActiveFolderLeafNode: false,
  setIsActiveFolderLeafNode: () => {},
  allFolders: [],
  searching: false,
  setSearching: () => {},
  query: '',
  setQuery: () => {},
  triggerListRefetch: false,
  setTriggerListRefetch: () => {},
  bulkMoveSelected: new Set([]),
  setBulkMoveSelected: (folderIds: Set<string>) => {},
};

const AnalyticsContext = React.createContext<Context>(initialState);

export default AnalyticsContext;
