import React, { useContext, useMemo } from 'react';

import { Button, Menu, Progress } from '@atom/mui';
import { BudgetStatus } from '@atom/types/budget';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from './BudgetDetailContext';
import { getStatusLabel } from './budgetDetailUtils';

import './budgetDetail.css';
const { MenuItem } = Menu;

const styles = {
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '1.75rem',
    paddingBottom: '0.75rem',
    gap: '1rem',
    marginRight: '0.5rem',
  },
  progress: { paddingRight: '1rem' },
  submitButton: {
    marginRight: '0.5rem',
  },
};

const BudgetDetailStatus = () => {
  const { parentBudgetUnit } = useContext(BudgetDetailContext);
  const showAdditionalOptions: boolean =
    parentBudgetUnit?.status !== BudgetStatus.DRAFT;

  const StatusLabel = useMemo(() => {
    return getStatusLabel(parentBudgetUnit?.status);
  }, [parentBudgetUnit?.status]);

  return (
    <div style={styles.statusContainer}>
      {isNilOrEmpty(parentBudgetUnit) ? (
        <Progress style={styles.progress} size={20} />
      ) : (
        <>
          {StatusLabel}
          {showAdditionalOptions ? (
            <Menu>
              {/* TODO: Call APIs when ready */}
              <MenuItem>Revert to Draft</MenuItem>
              <MenuItem>Revert to Submitted</MenuItem>
            </Menu>
          ) : (
            <Button
              style={styles.submitButton}
              color="primary"
              variant="contained"
            >
              Submit
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default BudgetDetailStatus;
