import React from 'react';

import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import { ElementsType } from '@atom/types/inventory';
import { ExportTemplateDetailType } from '@atom/types/inventoryExport';

import SchemaTreeRow from './SchemaTreeRow';

import './inventoryExportDetail.css';

const PADDING_OFFSET = 2;

const styles = {
  arrowStyle: {
    fontSize: '24px',
    margin: 0,
  },
};

interface Props {
  element: any;
  indentation: number;
  exportTemplateDetail: ExportTemplateDetailType;
  selectedSubItemId: string;
  updateSelectedSubItem: (subItemId: string) => void;
}

const SchemaTreeGroupRow = ({
  element,
  indentation,
  exportTemplateDetail,
  updateSelectedSubItem,
  selectedSubItemId,
}: Props) => {
  const style = {
    paddingLeft: `calc(1rem + ${PADDING_OFFSET * indentation}rem)`,
  };

  return (
    <>
      <div styleName="row" style={style}>
        <Icon style={styles.arrowStyle} color={colors.neutral.gray}>
          arrow_drop_down
        </Icon>
        <div styleName="schema-row-name group">{element.name}</div>
      </div>
      {element.elements ? (
        element.elements.map((elem: ElementsType) => {
          return (
            <SchemaTreeRow
              key={elem.id}
              element={elem}
              indentation={indentation + 1}
              exportTemplateDetail={exportTemplateDetail}
              updateSelectedSubItem={updateSelectedSubItem}
              selectedSubItemId={selectedSubItemId}
            />
          );
        })
      ) : (
        <div />
      )}
    </>
  );
};

export default SchemaTreeGroupRow;
