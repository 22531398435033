import * as R from 'ramda';

import actionTypes from '@atom/actions/actionTypes';
import treeUtilities from '@atom/utilities/treeUtilities';

import initialState from './initialState';

export const renameBy = (
  obj: object,
  modifier: (val: string) => string,
): object =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({ ...acc, [modifier(key)]: value }),
    {},
  );

export default function inventoryReducer(
  state = initialState.inventory,
  action,
) {
  switch (action.type) {
    case actionTypes.GET_INVENTORY_CATEGORIES_SUCCESS: {
      return {
        ...state,
        data: action.data.data,
        columns: action.data.columns,
      };
    }
    case actionTypes.INVENTORY_PORTAL_PATH_UPDATE_SUCCESS: {
      return {
        ...state,
        categories: {
          ...state.categories,
          inventoryPortalPath: action.data,
        },
      };
    }
    case actionTypes.GET_INVENTORY_ASSETS_SUCCESS: {
      const updatedData = action.data.data.map(item => {
        const dataWithoutAttributes = R.omit(['attributes'], item);
        const updatedAttributes = renameBy(item.attributes, key =>
          R.concat('attributes.', key),
        );

        return {
          ...dataWithoutAttributes,
          ...updatedAttributes,
        };
      });

      return {
        ...state,
        data: updatedData,
        columns: action.data.columns,
        categories: {
          inventoryCategory: action.data.parentCategoryId,
          inventoryCategoryTree: state.categories.inventoryCategoryTree,
          inventoryPortalPath: state.categories.inventoryPortalPath,
        },
      };
    }
    case actionTypes.CREATE_ASSET_SUCCESS: {
      return { ...state, data: [action.data, ...state.data] };
    }
    case actionTypes.ROOT_INVENTORY_ASSET_DELETION_SUCCESS: {
      const updatedData = state.data.filter(asset => asset.id !== action.data);
      return {
        ...state,
        data: updatedData,
      };
    }
    case actionTypes.GET_INVENTORY_ASSETS_FILTER_SUCCESS: {
      const { data, columns } = action.data;
      const updatedData = data.map(item => {
        const dataWithoutAttributes = R.omit(['attributes'], item);
        const updatedAttributes = renameBy(item.attributes, key =>
          R.concat('attributes.', key),
        );

        return {
          ...dataWithoutAttributes,
          ...updatedAttributes,
        };
      });

      return {
        ...state,
        data: updatedData,
        columns,
        categories: state.categories,
      };
    }
    case actionTypes.GET_INVENTORY_CATEGORY_TREE_NODE_SUCCESS: {
      const tree = { ...state.categories.inventoryCategoryTree };
      const { category, children } = action.data;

      const updatedChildren = children.reduce((accumulator, next) => {
        return {
          ...accumulator,
          [next.id]: {
            id: next.id,
            schemaId: next.schemaId,
            name: next.name,
            path: next.path,
            hasChildren: next.hasCategories,
            hasItems: next.hasItems,
            assetType: next.assetType,
            expanded: false,
          },
        };
      }, {});

      const location = R.lensPath(treeUtilities.splitPath(category.path));
      const parentLocation = R.lensPath(
        treeUtilities.splitParentPath(category.path),
      );

      // Update the node location with the children from the XHR call
      // Additionally expand the parent node when navigating from a table
      const updatedTree = R.pipe(
        R.over(location, node => ({
          ...node,
          expanded: !node.expanded,
          children: updatedChildren,
        })),
        R.over(parentLocation, node => ({
          ...node,
          expanded: true,
        })),
      )(tree);

      return {
        ...state,
        categories: {
          inventoryCategory: category.id,
          inventoryCategoryTree: updatedTree,
          inventoryPortalPath: state.categories.inventoryPortalPath,
        },
      };
    }
    case actionTypes.CREATE_INVENTORY_CATEGORY_SUCCESS: {
      const { data, parentPath } = action.data;
      const location = R.lensPath(treeUtilities.splitPath(parentPath));
      const tree = { ...state.categories.inventoryCategoryTree };
      const updatedTree = R.over(
        location,
        node => ({
          ...node,
          expanded: true,
          children: {
            ...node.children,
            [data.id]: {
              ...data,
              expanded: false,
              hasChildren: false,
              hasItems: false,
            },
          },
        }),
        tree,
      );

      return {
        ...state,
        categories: {
          ...state.categories,
          inventoryCategoryTree: updatedTree,
        },
      };
    }
    case actionTypes.UPDATE_INVENTORY_CATEGORY_SUCCESS: {
      const location = R.lensPath(treeUtilities.splitPath(action.data.path));
      const tree = { ...state.categories.inventoryCategoryTree };

      const updatedTree = R.over(
        location,
        node => ({
          ...node,
          ...action.data,
        }),
        tree,
      );

      return {
        ...state,
        categories: {
          ...state.categories,
          inventoryCategoryTree: updatedTree,
        },
      };
    }
    case actionTypes.DELETE_INVENTORY_CATEGORY_SUCCESS: {
      const pathArray = R.dropLast(
        1,
        treeUtilities.splitPath(action.data.path),
      );
      const location = R.lensPath(pathArray);
      const tree = { ...state.categories.inventoryCategoryTree };

      const updatedTree = R.over(
        location,
        node => ({
          ...R.omit([action.data.id], node),
        }),
        tree,
      );

      return {
        ...state,
        categories: {
          ...state.categories,
          inventoryCategoryTree: updatedTree,
        },
      };
    }
    default: {
      return state;
    }
  }
}
