import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as R from 'ramda';

import { GET_BUDGET_OPTIONS } from '@atom/graph/budget';
import { Select } from '@atom/mui';
import {
  BasicBudget,
  BudgetsConnection,
  BudgetsConnectionInput,
} from '@atom/types/budget';
import { POLICY_GRANT_IDS_ALL } from '@atom/types/policy';

import PolicyModalContext from '../PolicyModalContext';

const { MenuItem } = Select;

const BudgetingSelection = () => {
  const [budgets, setBudgets] = useState<BasicBudget[]>([]);

  const [getBudgets, { loading }] = useLazyQuery<
    { budgets: BudgetsConnection },
    { input: BudgetsConnectionInput }
  >(GET_BUDGET_OPTIONS, {
    fetchPolicy: 'network-only',
    onCompleted: res => {
      const options: BasicBudget[] = R.pathOr(
        [],
        ['budgetOptions', 'budgets'],
        res,
      );
      setBudgets(options);
    },
  });

  useEffect(
    () =>
      getBudgets({
        variables: {
          input: {
            sortBy: 'name,asc',
          },
        },
      }),
    [],
  );

  const { state, updateState } = useContext(PolicyModalContext);
  const { grants, grantType, loadingExistingPolicies } = state;

  const handleChange = event => {
    const values: string[] = event.target.value;
    const isAllSelected = grants.some(({ id }) => id === POLICY_GRANT_IDS_ALL);

    if (isAllSelected) {
      return updateState({
        grants: values
          .filter(val => val !== POLICY_GRANT_IDS_ALL)
          .map(id => ({ id, name: grantType })),
      });
    }

    return updateState({
      grants: values.includes(POLICY_GRANT_IDS_ALL)
        ? [{ id: POLICY_GRANT_IDS_ALL, name: grantType }]
        : values.map(id => ({ id, name: grantType })),
    });
  };

  return (
    <Select
      multiple
      loading={loadingExistingPolicies || loading}
      label="Budget(s)"
      value={grants.map(({ id }) => id) || []}
      onChange={handleChange}
    >
      <MenuItem value="ALL">All</MenuItem>
      {budgets.map(budget => (
        <MenuItem key={budget.id} value={budget.id}>
          {budget.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default BudgetingSelection;
