import * as React from 'react';

import TextField from '@atom/components/common/TextField';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { EventType } from '@atom/types/event';

import '../../formInstance.css';

const styles = {
  inputStyles: {
    fontSize: fonts.md,
    color: colors.neutral.dark,
  },
};

interface PassedProps {
  type: string;
  value: any;
  isEditable: boolean;
  onChange: (event: EventType) => void;
}

type Props = PassedProps;

const TextAttribute = ({ type, value, onChange, isEditable }: Props) => {
  return (
    <div styleName="attribute-input">
      <TextField
        name={type}
        style={styles.inputStyles}
        fullWidth
        onChange={onChange}
        value={value}
        disabled={!isEditable}
        multiLine={type === 'longtext'}
      />
    </div>
  );
};

export default TextAttribute;
