import React, { useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import * as R from 'ramda';

import { useOutsideClick } from '@atom/hooks/useOutsideClick';
import { DatePicker, Select } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  dateOptions,
  DateRange,
  DateRangePresetOption,
  getDateRangeLabel,
  getDatesFromSelection,
} from '@atom/utilities/workOrdersDateFilterUtilities';

const { MenuItem } = Select;

const styles = {
  separator: {
    margin: '0 1rem',
    paddingBottom: '0.5rem',
  },
  dateContainer: {
    padding: '1rem',
    background: colors.neutral.white,
    borderRadius: '4px',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.3)',
  },
};

const useClasses = makeStyles({
  label: {
    position: 'unset',
    // !important below overrides mui label styles
    color: `${colors.neutral.dim} !important`,
  },
});

interface Props {
  label?: string;
  disabled?: boolean;
  startDateValue?: Date;
  handleStartChange: (val: Date) => void;
  endDateValue?: Date;
  handleEndChange: (val: Date) => void;
  presetSelected?: string;
  handleNoDueDateSelect?: (selected: boolean) => void;
  handleSelectDatePreset?: (selected: DateRangePresetOption) => void;
}

const InputDateRange = ({
  label = '',
  disabled = false,
  startDateValue,
  endDateValue,
  presetSelected,
  handleStartChange,
  handleEndChange,
  handleNoDueDateSelect,
  handleSelectDatePreset,
}: Props) => {
  const [showDatePickers, setShowDatePickers] = useState(false);

  const options = useMemo(() => {
    const localOptions = R.clone(dateOptions);
    return handleNoDueDateSelect
      ? localOptions
      : localOptions.filter(option => option.value !== DateRange.NO_DATE);
  }, [handleNoDueDateSelect]);

  const [customLabel, setCustomLabel] = useState<string>();

  // Storing date values in refs to be available in handleClickOutside
  // - called through useOutsideClick ref
  const startDateRef = useRef(startDateValue);
  const endDateRef = useRef(endDateValue);
  useEffect(() => {
    startDateRef.current = startDateValue;
  }, [startDateValue]);
  useEffect(() => {
    endDateRef.current = endDateValue;
  }, [endDateValue]);

  const getSelectedValue = (): DateRangePresetOption => {
    if (!R.isNil(presetSelected) && presetSelected !== DateRange.CUSTOM_RANGE) {
      return dateOptions.find(option => option.value === presetSelected);
    }

    if (R.isNil(startDateValue) && R.isNil(endDateValue)) {
      return dateOptions.find(option => option.value === DateRange.ANYTIME);
    }

    const newCustomLabel = getDateRangeLabel(
      startDateRef.current,
      endDateRef.current,
    );
    setCustomLabel(newCustomLabel);
    const customOption = options.find(
      option => option.value === DateRange.CUSTOM_RANGE,
    );
    return customOption;
  };

  const [selected, setSelected] = useState<DateRangePresetOption>(
    getSelectedValue,
  );
  useEffect(() => {
    setCustomLabel(null);
    setSelected(getSelectedValue());
  }, [presetSelected, startDateValue, endDateValue]);

  const handleClickOutside = () => {
    if (startDateRef.current === null && endDateRef.current === null) {
      setSelected(
        dateOptions.find(option => option.value === DateRange.ANYTIME),
      );
    } else {
      const newCustomLabel = getDateRangeLabel(
        startDateRef.current,
        endDateRef.current,
      );
      setCustomLabel(newCustomLabel);
      const customOption = options.find(
        option => option.value === DateRange.CUSTOM_RANGE,
      );
      setSelected(customOption);
    }
    setShowDatePickers(false);
  };

  const detectOutsideRangePickerRef = useOutsideClick(handleClickOutside, true);
  const classes = useClasses();

  const handleSelect = (event: React.ChangeEvent<any>) => {
    const selectedOption = options.find(
      option => option.value === event.target.value,
    );
    setSelected(selectedOption);

    // reset custom range label from custom dates on any selection
    setCustomLabel(null);

    if (handleNoDueDateSelect) {
      handleNoDueDateSelect(event.target.value === DateRange.NO_DATE);
    }

    handleSelectDatePreset(selectedOption);

    const [startDate, endDate] = getDatesFromSelection(event.target.value);
    handleStartChange(startDate);
    handleEndChange(endDate);

    if (selectedOption.value === DateRange.CUSTOM_RANGE) {
      setShowDatePickers(true);
    }
  };

  // handle case where user clicks on customized date label
  const handleSelectedCustomRangeClick = clickEvent => {
    if (
      clickEvent?.target?.selected === true &&
      clickEvent?.target?.dataset?.value === DateRange.CUSTOM_RANGE
    ) {
      setShowDatePickers(true);
    }
  };

  return (
    <>
      {showDatePickers ? (
        <div ref={detectOutsideRangePickerRef} style={styles.dateContainer}>
          <DatePicker
            value={startDateValue}
            onChange={handleStartChange}
            label={label}
            placeholder="mm/dd/yyyy"
            disabled={disabled}
            TextFieldProps={{
              InputLabelProps: {
                classes: { root: classes.label },
              },
            }}
            data-cy={`dateRangePickerStart-${label}`}
          />
          <span style={styles.separator}>to</span>
          <DatePicker
            value={endDateValue}
            onChange={handleEndChange}
            placeholder="mm/dd/yyyy"
            disabled={disabled}
            TextFieldProps={{
              InputLabelProps: { style: { display: 'none' } },
            }}
            data-cy={`dateRangePickerEnd-${label}`}
          />
        </div>
      ) : (
        <Select
          label={label}
          value={selected?.value}
          onChange={handleSelect}
          disabled={disabled}
          InputLabelProps={{
            classes: { root: classes.label },
          }}
          data-cy={`dateRangeSelect-${label}`}
        >
          {options.map((option, idx) => (
            <MenuItem
              onClick={handleSelectedCustomRangeClick}
              Share
              key={idx}
              value={option.value}
            >
              {option.value !== DateRange.CUSTOM_RANGE
                ? option.label
                : customLabel || 'Custom Range'}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};

export default InputDateRange;
