import { createContext } from 'react';

import { FormTemplateType } from '@atom/types/form';
import { InventorySchemaItem } from '@atom/types/inventory';

export interface Context {
  formTemplate: FormTemplateType;
  isDrawerOpen: boolean;
  selectedPage: number;
  pageInEditMode: any;
  selectedSchema?: string;
  pageName: string;
  schemas: InventorySchemaItem[];
}

const initialContext: Context = {
  formTemplate: null,
  isDrawerOpen: true,
  selectedPage: 0,
  pageInEditMode: null,
  pageName: '',
  schemas: [],
};

export default createContext<Context>(initialContext);
