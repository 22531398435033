import React from 'react';

import { Button, Modal } from '@atom/mui';

export interface Props {
  open: boolean;
  onConfirm: () => void;
}

const BulkLimitModal = ({ open, onConfirm }: Props) => {
  const getFooter = () => (
    <Button variant="contained" color="primary" onClick={onConfirm}>
      OK
    </Button>
  );

  return (
    <Modal
      footer={getFooter()}
      title="Create Work in Bulk Limit Reached"
      open={open}
    >
      You may select up to 5,000 inventory items to create work in bulk. Please
      reduce your selection or break it up into smaller batches.
    </Modal>
  );
};

export default BulkLimitModal;
