import { useEffect, useState } from 'react';
import axios from 'axios';

import api from '@atom/utilities/api';
import { BASE_API_ENDPOINT } from '@atom/utilities/endpoints';

export const useDetailsData = (type, id): [object, boolean] => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getMapData = async () => {
      try {
        setLoading(true);
        const { data: response } = await api.get(
          `${BASE_API_ENDPOINT}/${type}s/${id}`,
          null,
          source.token,
        );
        setData(response);
        setLoading(false);
      } catch (error) {
        if (!axios.isCancel(error)) {
          throw error;
        }
      }
    };

    if (type && id) {
      getMapData();
    }

    return () => {
      source.cancel();
    };
  }, [type, id]);

  return [data, loading];
};
