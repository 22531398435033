import * as React from 'react';
import * as R from 'ramda';

import { TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { EventType } from '@atom/types/event';

import '../formInstance.css';

const ERROR_BACKGROUND_COLOR = 'rgba(237,54,32,0.05)';

const styles = {
  underlineStyles: {
    display: 'none',
  },
  textFieldStyles: {
    height: '2.125rem',
    paddingLeft: '5px',
  },
  inputStyles: {
    textAlign: 'right',
    fontSize: fonts.md,
    color: colors.neutral.dark,
  },
};

interface PassedProps {
  name: string;
  value: number;
  onChange?: (event: EventType) => Promise<void>;
  isEditable: boolean;
  isValid: boolean;
  width: string;
}

type Props = PassedProps;

class FhwaInput extends React.Component<Props> {
  onBlur = () => {
    const { name, value, onChange } = this.props;

    const event = {
      target: {
        name,
        value,
      },
    };

    if (R.isNil(value) || R.isEmpty(value)) {
      event.target.value = 0;
    }

    // @ts-ignore
    onChange(event);
  };

  render() {
    const { isEditable, name, value, onChange, isValid, width } = this.props;

    const textFieldStyles = isValid
      ? { ...styles.textFieldStyles, width }
      : {
          ...styles.textFieldStyles,
          borderColor: colors.brand.red,
          backgroundColor: ERROR_BACKGROUND_COLOR,
          width,
        };

    return (
      <TextField
        onBlur={this.onBlur}
        name={name}
        type="number"
        disabled={!isEditable}
        style={textFieldStyles}
        value={value}
        onChange={onChange}
        variant="standard"
      />
    );
  }
}

export default FhwaInput;
