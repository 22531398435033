import React from 'react';
import * as R from 'ramda';

import { gwrrSchema } from '@atom/constants/customReportSchemaConstants';

import ElementAssessmentRow from './ElementAssessmentRow';

import '../workOrderDetailPdfReport.css';

interface Props {
  elements?: any;
}

const ChildAssessmentTable = ({ elements }: Props) => {
  const containsSpecificAttributes = (attributeIds: string[]) => {
    return elements.reduce((acc, element) => {
      const hasAttributes = R.keys(element.attributes).reduce(
        (innerAcc, attributeId) => {
          return attributeIds.includes(String(attributeId)) || innerAcc;
        },
        false,
      );

      return hasAttributes || acc;
    }, false);
  };

  const specificAttributeIds = R.values(
    R.pick(['boredAtId', 'shellId', 'voidId'], gwrrSchema.poolAttributes),
  );

  const showColumns = containsSpecificAttributes(specificAttributeIds);

  const schemaIds = R.values(
    showColumns
      ? gwrrSchema.poolAttributes
      : R.omit(['boredAtId', 'shellId', 'voidId'], gwrrSchema.poolAttributes),
  );

  return (
    <>
      <div>
        <table>
          <thead>
            <tr styleName="table-header">
              <th styleName="table-header-cell">Element</th>
              <th styleName="table-header-cell">Condition Rating</th>
              {showColumns && (
                <>
                  <th styleName="table-header-cell">Bored At</th>
                  <th styleName="table-header-cell">Shell</th>
                  <th styleName="table-header-cell">Void</th>
                </>
              )}
              <th styleName="table-header-cell comment">Comment</th>
              <th styleName="table-header-cell">Repair Recommendation</th>
              <th styleName="table-header-cell">Priority</th>
              <th styleName="table-header-cell">Assessment Flag</th>
            </tr>
          </thead>
          <tbody>
            {elements.map(element => (
              <ElementAssessmentRow
                key={element.id}
                element={element}
                schemaIds={schemaIds}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ChildAssessmentTable;
