/* eslint-disable react/no-did-update-set-state */
import * as React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';

import * as fileActionCreators from '@atom/actions/fileActions';
import * as instanceActionCreators from '@atom/actions/formInstanceActions';
import * as templateActionCreators from '@atom/actions/formTemplateActions';
import * as inventorySchemaActionCreators from '@atom/actions/inventorySchemaActions';
import * as mediaActionCreators from '@atom/actions/mediaActions';
import {
  getOrderedFormInstanceMedia,
  getRootSchema,
  mergeFormStates,
  updateFormInstanceAttribute,
  updateFormInstanceFhwa,
  updateFormInstanceField,
  updateFormInstanceMedia,
} from '@atom/selectors/formInstanceSelectors';
import { inventorySchemasSelector } from '@atom/selectors/inventorySchemasSelectors';
import {
  FileActions,
  FormInstanceActions,
  FormTemplateActions,
  InventorySchemaActions,
  MediaActions,
} from '@atom/types/actions';
import {
  CiModuleInspectionUpdate,
  FormInstanceTemplateType,
  FormTemplateType,
  PciModuleDataUpdate,
} from '@atom/types/form';
import { InventorySchemasState, ReduxStore } from '@atom/types/store';

import FormInstanceCanvas from './FormInstanceCanvas';
import FormInstanceHeader from './FormInstanceHeader';
import FormInstancePages from './FormInstancePages';

import './formInstance.css';

type HistoryType = (data: string, info: Object) => void;

interface ReduxStateProps {
  formTemplate: FormTemplateType;
  formInstance: FormInstanceTemplateType;
  loadingFormInstance: boolean;
  loadingFormTemplate: boolean;
  savingFormInstance: boolean;
  inventorySchemas: InventorySchemasState;
}

interface ReduxDispatchProps {
  formTemplateActions: FormTemplateActions;
  formInstanceActions: FormInstanceActions;
  inventorySchemaActions: InventorySchemaActions;
  fileActions: FileActions;
  mediaActions: MediaActions;
}

interface PassedProps {
  match: any;
  location: any;
  history: HistoryType;
}

type Props = ReduxStateProps & ReduxDispatchProps & PassedProps;

type State = {
  selectedPage: number;
  preview: boolean;
  form: any;
};

const initialForm = {
  id: '',
  name: '',
  createdDate: 0,
  pages: [{ name: '', order: [], fields: {} }],
  media: [],
};

class FormInstance extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedPage: 0,
      preview: props.match.path.includes('preview'),
      form: props.match.path.includes('preview')
        ? props.formTemplate
        : initialForm,
    };
  }

  componentDidMount() {
    const {
      match,
      formInstanceActions,
      inventorySchemas,
      inventorySchemaActions,
      formTemplateActions,
    } = this.props;

    if (match.path.includes('preview')) {
      const id = match.params.id;
      formTemplateActions.getFormTemplate(id);
    }

    if (!match.path.includes('preview')) {
      const id = match.params.id;
      // @ts-ignore
      formInstanceActions.retrieveFormInstance({
        formInstanceId: id,
      });
    }

    if (R.isNil(inventorySchemas) || R.isEmpty(inventorySchemas)) {
      inventorySchemaActions.retrieveInventorySchemas({
        rootSchemas: true,
      });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: ReduxStateProps) {
    if (!this.props.match.path.includes('preview')) {
      const { loadingFormInstance, formInstance } = this.props;

      if (loadingFormInstance && !nextProps.loadingFormInstance) {
        this.setState({ form: nextProps.formInstance });
      }

      const { form } = this.state;

      if (R.isEmpty(form.id)) {
        this.setState({ form: nextProps.formInstance });
      } else if (nextProps.formInstance !== formInstance) {
        this.setState({ form: mergeFormStates(form, nextProps.formInstance) });
      }
    }

    if (this.props.match.path.includes('preview')) {
      this.setState({ form: nextProps.formTemplate });
    }
  }

  componentWillUnmount() {
    const { formInstanceActions } = this.props;
    formInstanceActions.requestClearFormInstance();
  }

  updateSelectedPage = (index: number) => {
    this.setState({ selectedPage: index });
  };

  removeFile = (mediaId: string, subjectId: string) => {
    const { formInstanceActions } = this.props;
    const { form } = this.state;

    const data = {
      mediaId,
      subjectId,
      formInstanceId: form.id,
    };

    formInstanceActions.requestDeleteFormInstanceMedia(data);
  };

  progressiveUpdateFormInstanceField = (fieldId: string, body: Object) => {
    const { preview, form, selectedPage } = this.state;
    const { formInstanceActions } = this.props;

    // @ts-ignore
    if (preview || R.isNil(body.value)) {
      return;
    }

    const pageId = form.pages[selectedPage].id;

    const data = {
      formInstanceId: form.id,
      pageId,
      fieldId,
      body,
    };

    formInstanceActions.requestProgressiveUpdateFormInstanceField(data);

    const updatedForm = updateFormInstanceField(form, pageId, fieldId, body);
    this.setState({ form: updatedForm });
  };

  progressiveUpdateFormInstanceAttribute = (
    fieldId: string,
    assetId: string,
    attributeGroupName: string,
    attributeId: string,
    body: Object,
  ) => {
    const { preview, form, selectedPage } = this.state;
    const { formInstanceActions } = this.props;

    // @ts-ignore
    if (preview || R.isNil(body.value)) {
      return;
    }

    const pageId = form.pages[selectedPage].id;

    const data = {
      formInstanceId: form.id,
      pageId,
      fieldId,
      assetId,
      attributeId,
      body,
    };

    formInstanceActions.requestProgressiveUpdateFormInstanceAttribute(data);

    const updatedForm = updateFormInstanceAttribute(
      form,
      pageId,
      fieldId,
      assetId,
      attributeGroupName,
      attributeId,
      body,
    );
    this.setState({ form: updatedForm });
  };

  progressiveUpdateFormInstanceFhwa = (
    fieldId: string,
    assetId: string,
    body: Object,
    elementPath: any[],
  ) => {
    const { preview, form, selectedPage } = this.state;
    const { formInstanceActions } = this.props;

    // @ts-ignore
    if (preview || R.isNil(body.attributes)) {
      return;
    }

    const pageId = form.pages[selectedPage].id;

    const data = {
      formInstanceId: form.id,
      pageId,
      fieldId,
      assetId,
      body,
    };

    formInstanceActions.requestProgressiveUpdateFormInstanceFhwa(data);

    const updatedForm = updateFormInstanceFhwa(
      form,
      pageId,
      fieldId,
      assetId,
      body,
      elementPath,
    );
    this.setState({ form: updatedForm });
  };

  progressiveUpdateFormInstanceCi = (update: CiModuleInspectionUpdate) => {
    const { fieldId, inspectionIndex, ...updates } = update;
    const { form, selectedPage, preview } = this.state;
    const { formInstanceActions } = this.props;

    const formInstanceId = form.id;
    const pageId = form.pages[selectedPage].id;

    if (!preview) {
      formInstanceActions.requestProgressiveUpdateFormInstanceCi({
        formInstanceId,
        pageId,
        selectedPage,
        fieldId,
        ...updates,
      });
    }

    const lens = R.lensPath([
      'pages',
      selectedPage,
      'fields',
      fieldId,
      'inspections',
      inspectionIndex,
    ]);

    this.setState({
      form: R.over(lens, inspection => ({ ...inspection, ...updates }), form),
    });
  };

  progressiveUpdateFormInstancePci = (update: PciModuleDataUpdate) => {
    const { fieldId, ...updates } = update;

    const { form, selectedPage, preview } = this.state;
    const { formInstanceActions } = this.props;

    const formInstanceId = form.id;
    const pageId = form.pages[selectedPage].id;

    if (!preview) {
      formInstanceActions.requestProgressiveUpdateFormInstancePci({
        formInstanceId,
        pageId,
        fieldId,
        ...updates,
      });
    }

    const lens = R.lensPath(['pages', selectedPage, 'fields', fieldId, 'pci']);
    this.setState({
      form: R.over(lens, pci => ({ ...pci, ...updates }), form),
    });
  };

  uploadFiles = (
    files?: any[],
    subjectId?: string,
    subjectType?: string,
    inspectionUpdate?: CiModuleInspectionUpdate,
  ) => {
    const { fileActions } = this.props;
    const { form, selectedPage } = this.state;
    const widths = [24, 180, 1440];

    const pageId = form.pages[selectedPage].id;

    const data = {
      files,
      widths,
      subjectId,
      subjectType,
      parentSubjectId: form.id,
      queryParams: {
        parentSubjectId: form.id,
      },
      ...(inspectionUpdate && {
        inspectionUpdate: {
          ...inspectionUpdate,
          pageId,
        },
      }),
    };

    fileActions.requestUploadFiles(data);
  };

  renameMedia = (mediaId: string, subjectId: string, name: string) => {
    const { preview, form } = this.state;
    const { mediaActions } = this.props;

    // @ts-ignore
    if (preview) {
      return;
    }

    const data = {
      id: mediaId,
      name,
    };

    mediaActions.requestPatchMedia(data);

    const updatedForm = updateFormInstanceMedia(form, subjectId, mediaId, name);
    this.setState({ form: updatedForm });
  };

  render() {
    const {
      loadingFormTemplate,
      loadingFormInstance,
      savingFormInstance,
      inventorySchemas,
      location,
    } = this.props;
    const { selectedPage, form, preview } = this.state;

    const taskId = R.pathOr('', ['state', 'taskId'], location);
    const instanceCount = R.pathOr(0, ['instanceCount'], form);
    const isPublished = R.pathOr(false, ['isPublished'], form);

    const loading = preview ? loadingFormTemplate : loadingFormInstance;
    const rootSchema = getRootSchema(form.schemaId, inventorySchemas);
    const formMedia = form.media ? getOrderedFormInstanceMedia(form) : [];

    return (
      <React.Fragment>
        <FormInstanceHeader
          form={form}
          isPublished={isPublished}
          instanceCount={instanceCount}
          preview={preview}
          taskId={taskId}
          savingFormInstance={savingFormInstance}
        />
        <div styleName="form-instance-container">
          <FormInstancePages
            form={form}
            preview={preview}
            updateSelectedPage={this.updateSelectedPage}
            selectedPage={selectedPage}
          />
          <FormInstanceCanvas
            preview={preview}
            rootSchema={rootSchema}
            uploadFiles={this.uploadFiles}
            form={form}
            media={formMedia}
            selectedPage={selectedPage}
            loading={loading}
            progressiveUpdateFormInstanceField={
              this.progressiveUpdateFormInstanceField
            }
            progressiveUpdateFormInstanceAttribute={
              this.progressiveUpdateFormInstanceAttribute
            }
            progressiveUpdateFormInstanceFhwa={
              this.progressiveUpdateFormInstanceFhwa
            }
            progressiveUpdateFormInstanceCi={
              this.progressiveUpdateFormInstanceCi
            }
            progressiveUpdateFormInstancePci={
              this.progressiveUpdateFormInstancePci
            }
            removeFile={this.removeFile}
            renameMedia={this.renameMedia}
            savingFormInstance={savingFormInstance}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  formTemplate: state.formTemplateDetail,
  formInstance: state.formInstance,
  loadingFormInstance: state.loading.loadingFormInstance,
  savingFormInstance: state.loading.savingFormInstance,
  loadingFormTemplate: state.loading.loadingFormTemplate,
  inventorySchemas: inventorySchemasSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  formInstanceActions: bindActionCreators(instanceActionCreators, dispatch),
  formTemplateActions: bindActionCreators(templateActionCreators, dispatch),
  fileActions: bindActionCreators(fileActionCreators, dispatch),
  inventorySchemaActions: bindActionCreators(
    inventorySchemaActionCreators,
    dispatch,
  ),
  mediaActions: bindActionCreators(mediaActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormInstance);
