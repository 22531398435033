import * as R from 'ramda';

import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

const mapAttributeGroups = assetDetail => {
  const attributeGroups = assetDetail.attributeGroups;

  return attributeGroups.map(group => {
    return {
      ...group,
      attributes: group.attributes.map(key => {
        return {
          id: key,
          ...assetDetail.attributes[key],
        };
      }),
    };
  });
};

export default function (state = initialState.workOrderAssetDetail, action) {
  const { type, data } = action;
  switch (type) {
    case actionTypes.GET_WORK_ORDER_ASSET_SUCCESS:
    case actionTypes.PATCH_WORK_ORDER_ASSET_SUCCESS: {
      const workOrderAssetDetail = data;

      const hydratedAttributeGroups = mapAttributeGroups(workOrderAssetDetail);

      return {
        ...workOrderAssetDetail,
        attributeGroups: hydratedAttributeGroups,
      };
    }
    case actionTypes.GET_WORK_ORDER_ASSET_ELEMENTS_SUCCESS:
    case actionTypes.CREATE_WORK_ORDER_ELEMENT_BATCH_SUCCESS: {
      return {
        ...state,
        elementGroups: data,
      };
    }
    case actionTypes.GET_WORK_ORDER_ELEMENT_TREE_NODE_UPDATE_SUCCESS: {
      const { data: elementData, elementPath } = data;
      const lensPath = R.lensPath(elementPath);

      return R.over(
        lensPath,
        element => ({
          ...element,
          ...elementData,
          ...(element.elementGroups
            ? {
                elementGroups: element.elementGroups,
              }
            : {}),
        }),
        state,
      );
    }
    case actionTypes.DELETE_WORK_ORDER_ASSET_SUCCESS: {
      const { elementPath } = data;
      const lensPath = R.lensPath(R.init(elementPath));
      const elementIndex = R.last(elementPath);

      // @ts-ignore
      return R.over(lensPath, R.remove(elementIndex, 1), state);
    }
    default: {
      return state;
    }
  }
}
