import React, { useContext, useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';

import { Icon, Modal } from '@atom/mui';
import { DashboardCard, DashboardCardSize } from '@atom/types/dashboard';
import { isValidUrlWithMessage } from '@atom/utilities/validationUtilities';

import DashboardContext from '../DashboardContext';

import CustomEmbedFormView from './CustomEmbedFormView';
import CustomizeDashboardView from './CustomizeDashboardView';

import './customizeDashboardModal.css';

export const enum ModalView {
  ROOT = 'ROOT',
  ADD_CUSTOM = 'ADD_CUSTOM',
}

export interface Props {
  open: boolean;
  onClose: () => void;
}

const INIT_EMBED_FORM_FIELDS: DashboardCard = {
  size: DashboardCardSize.LG,
  title: '',
  embedLink: '',
};

const CustomizeDashboardModal = ({ open, onClose }: Props) => {
  const [view, setView] = useState<ModalView>(ModalView.ROOT);

  const { handleAddEmbedCard, loadingEmbedCard } = useContext(DashboardContext);
  const [urlErrorMsg, setUrlErrorMsg] = useState<string>('');
  const [cardData, setCardData] = useState(INIT_EMBED_FORM_FIELDS);

  useEffect(() => {
    if (open) {
      setView(ModalView.ROOT);
      setCardData(INIT_EMBED_FORM_FIELDS);
    }
  }, [open]);

  const isValid = useMemo(() => {
    const [isURLValid, urlError] = isValidUrlWithMessage(cardData.embedLink);
    setUrlErrorMsg(urlError);
    const isHyperlinkValid = !R.isEmpty(cardData.embedLink) && isURLValid;

    return isHyperlinkValid && !R.isEmpty(cardData.title);
  }, [cardData]);

  const modalTitle =
    view === ModalView.ADD_CUSTOM ? (
      <div styleName="modal-header custom-embed">
        <Icon onClick={() => setView(ModalView.ROOT)}>arrow_back</Icon>
        <div>Add Card: Custom Embed</div>
      </div>
    ) : (
      <div styleName="modal-header">
        <div>Customize Dashboard</div>
        <Icon onClick={onClose}>close</Icon>
      </div>
    );

  return (
    <Modal
      title={modalTitle}
      open={open}
      onCancel={onClose}
      onExited={onClose}
      onConfirm={() => handleAddEmbedCard(cardData)}
      data-cy="customizeDashboardModal"
      width="sm"
      disabled={!isValid || loadingEmbedCard}
      disableFooter={view === ModalView.ROOT}
      confirmButtonText="ADD"
    >
      {view === ModalView.ADD_CUSTOM ? (
        <CustomEmbedFormView
          cardData={cardData}
          setCardData={setCardData}
          urlErrorMsg={urlErrorMsg}
        />
      ) : (
        <CustomizeDashboardView setView={setView} />
      )}
    </Modal>
  );
};

export default CustomizeDashboardModal;
