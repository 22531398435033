import React from 'react';

import { InventoryAssetDetailType } from '@atom/types/inventory';

import CostHeader from './CostHeader';
import UsageTable from './UsageTable';

import './cost.css';

interface Props {
  asset: InventoryAssetDetailType;
}

const CostTab = ({ asset }: Props) => {
  return (
    <div styleName="body-container">
      <div styleName="left-body-pane-fixed" />
      <div styleName="right-body-pane-fill">
        <CostHeader asset={asset} />
        <div styleName="content-container">
          <UsageTable asset={asset} />
        </div>
      </div>
    </div>
  );
};

export default CostTab;
