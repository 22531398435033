import React, { useEffect, useState } from 'react';
import * as R from 'ramda';

import { usePreferences } from '@atom/hooks/usePreferences';
import { AssetUsageSummary } from '@atom/types/assetUsage';
import { InventoryAssetDetailType } from '@atom/types/inventory';
import api from '@atom/utilities/api';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { INVENTORY_ASSETS_ENDPOINT } from '@atom/utilities/endpoints';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './cost.css';

interface Props {
  asset: InventoryAssetDetailType;
}

const CostHeader = ({ asset }: Props) => {
  const preferences = usePreferences();
  const fiscalStartDate = R.pathOr(null, ['fiscalStartDate'], preferences);
  const [usageSummary, setUsageSummary] = useState<AssetUsageSummary>({
    totalUsage: 0,
    totalCost: 0,
    totalCostYear: 0,
    totalCostMonth: 0,
    totalUsageToday: 0,
    totalRemaining: 0,
    quantityOnHand: 0,
  });

  useEffect(() => {
    const getUsageSummary = async () => {
      const { data } = await api.get(
        `${INVENTORY_ASSETS_ENDPOINT}/${asset.id}/usageSummary`,
      );

      setUsageSummary(data);
    };

    if (asset.isMaterial) {
      getUsageSummary();
    }
  }, [asset.id, asset?.budget?.quantityOnHand]);

  const convertNumberToLocaleString = (input: string | number): string => {
    return Number(input).toLocaleString('en-US');
  };

  const showStockBasedSections = asset?.budget?.isStockBased;

  return (
    <div styleName="right-pane-header">
      {showStockBasedSections && (
        <>
          <div styleName="header-info-block">
            <div styleName="header-section-title">
              <span>Total Remaining (</span>
              <span styleName="usage-unit">{asset?.budget?.unit}</span>
              <span>)</span>
            </div>
            <div styleName="header-section-info">
              {convertNumberToLocaleString(usageSummary.totalRemaining)}
            </div>
          </div>
          <div styleName="header-info-block">
            <div styleName="header-section-title">
              <span>Today's Usage (</span>
              <span styleName="usage-unit">{asset?.budget?.unit}</span>
              <span>)</span>
            </div>
            <div styleName="header-section-info">
              {convertNumberToLocaleString(usageSummary.totalUsageToday)}
            </div>
          </div>
        </>
      )}
      <div styleName="header-info-block">
        <div styleName="header-section-title">
          <span>Total Usage (</span>
          <span styleName="usage-unit">{asset?.budget?.unit}</span>
          <span>)</span>
        </div>
        <div styleName="header-section-info">
          {convertNumberToLocaleString(usageSummary.totalUsage)}
        </div>
      </div>
      <div styleName="header-info-block">
        <div styleName="header-section-title">
          <span>Cost per </span>
          <span styleName="usage-unit">{asset?.budget?.unit}</span>
        </div>
        <div styleName="header-section-info">
          {numberToLocaleString(asset?.budget?.rate)}
        </div>
      </div>
      <div styleName="header-info-block">
        <div styleName="header-section-title">
          {!isNilOrEmpty(fiscalStartDate)
            ? 'Total Cost (Fiscal Year)'
            : 'Total Cost (Year)'}
        </div>
        <div styleName="header-section-info">
          {numberToLocaleString(usageSummary.totalCostYear)}
        </div>
      </div>
      <div styleName="header-info-block">
        <div styleName="header-section-title">Total Cost (Month)</div>
        <div styleName="header-section-info">
          {numberToLocaleString(usageSummary.totalCostMonth)}
        </div>
      </div>
      <div styleName="header-info-block">
        <div styleName="header-section-title">Total Cost</div>
        <div styleName="header-section-info">
          {numberToLocaleString(usageSummary.totalCost)}
        </div>
      </div>
    </div>
  );
};

export default CostHeader;
