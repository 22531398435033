import React, { useEffect, useState } from 'react';
import * as R from 'ramda';

import { CiModuleInspection, CiModuleInspectionUpdate } from '@atom/types/form';
import { MediaItem } from '@atom/types/media';
import history from '@atom/utilities/history';

import CiCommentsModal from './CiCommentsModal';
import CiInspectionChoiceModal from './CiInspectionChoiceModal';
import CiMediaModal from './CiMediaModal';

import './ci.css';

interface Props {
  assetId: string;
  fieldId: string;
  inspection: CiModuleInspection;
  inspectionIndex: number;
  media: MediaItem[];
  preview: boolean;
  onChange: (update: CiModuleInspectionUpdate) => void;
  uploadFiles: (
    files?: any[],
    subjectId?: string,
    subjectType?: string,
    inspectionUpdate?: CiModuleInspectionUpdate,
  ) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  isEditable: boolean;
}

const getChoice = ({ choices }: CiModuleInspection, rating: string) => {
  return rating ? choices.find(choice => choice.rating === rating) : null;
};

const CiFieldRow = ({
  assetId,
  fieldId,
  inspection,
  inspectionIndex,
  media,
  preview,
  onChange,
  uploadFiles,
  renameMedia,
  isEditable,
}: Props) => {
  const { name } = inspection;

  const [open, setOpen] = useState<boolean>(false);
  const [rating, setRating] = useState<string>(inspection.rating || '');

  useEffect(() => {
    if (preview) {
      setRating('');
    } else {
      setRating(inspection?.rating);
    }
  }, [inspection, preview]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleChoice = (rating: string) => {
    setRating(rating);
    onChange({ fieldId, inspectionIndex, name, rating });
    setOpen(false);
  };

  const handleComments = (comments: string) => {
    onChange({ fieldId, inspectionIndex, name, comments });
  };

  const handleUploadFiles = (
    files?: any[],
    subjectId?: string,
    subjectType?: string,
  ) => {
    const mediaIds = media.map(({ id }) => id);
    const inspectionUpdate = { fieldId, inspectionIndex, name, mediaIds };
    uploadFiles(files, subjectId, subjectType, inspectionUpdate);
  };

  const handleMediaChange = (mediaIds: string[]) => {
    onChange({ fieldId, inspectionIndex, name, mediaIds });
  };

  const choice = getChoice(inspection, rating);

  const isWorkOrderClosed = history.location.state?.workOrderClosed;

  return (
    <>
      <div styleName="table-row">
        <div styleName="large-column">{inspection.name}</div>
        <div
          styleName="description-column"
          onClick={() => isEditable && !isWorkOrderClosed && setOpen(true)}
        >
          <div styleName="description-row">
            {choice?.description || 'Select'}
          </div>
        </div>
        <div styleName="normal-column rating">{choice?.rating || ''}</div>
        <div styleName="small-column points">
          {R.isNil(choice?.points) ? '' : choice.points}
        </div>
        <div styleName="small-column">
          <CiMediaModal
            media={media}
            onChange={handleMediaChange}
            uploadFiles={handleUploadFiles}
            renameMedia={renameMedia}
            assetId={assetId}
            disabled={preview || isWorkOrderClosed}
            isEditable={isEditable}
          />
        </div>
        <div styleName="small-column">
          <CiCommentsModal
            inspection={inspection}
            onSubmit={handleComments}
            preview={preview}
            isWorkOrderClosed={isWorkOrderClosed}
            isEditable={isEditable}
          />
        </div>
      </div>
      <CiInspectionChoiceModal
        inspection={inspection}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={handleChoice}
      />
    </>
  );
};

export default CiFieldRow;
