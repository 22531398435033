import * as React from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';

import './elements.css';

const setButtonStyleActive = (activeView, value) => {
  return activeView === value ? ' active' : '';
};

const setIconStyle = (activeView, value) => {
  return activeView === value ? colors.brand.blue : colors.neutral.gray;
};

interface Props {
  value: string;
  icon: any;
  activeView: string;
  setActiveView: (string) => any;
}

const ElementNavigationIcon = ({
  value,
  icon,
  activeView,
  setActiveView,
}: Props) => {
  const iconActiveStyle = setButtonStyleActive(activeView, value);
  const iconColor = setIconStyle(activeView, value);
  const activeClassName = `icon-container${iconActiveStyle}`;

  return (
    <div onClick={() => setActiveView(value)} styleName={activeClassName}>
      <IconButton>
        <Icon color={iconColor}>{icon}</Icon>
      </IconButton>
    </div>
  );
};

export default ElementNavigationIcon;
