import React, { useContext } from 'react';

import { Button, Icon, IconButton } from '@atom/mui';
import { POLICY_RESOURCE_OPTIONS } from '@atom/utilities/policyUtilities';

import PolicyModalContext from './PolicyModalContext';

import './policyModals.css';

const PolicyGenericGrantsSelection = () => {
  const { state, updateState } = useContext(PolicyModalContext);

  const { grantType, grants, loadingExistingPolicies } = state;

  return (
    <div styleName="input-container">
      <div styleName="label">{POLICY_RESOURCE_OPTIONS[grantType]?.label}</div>
      {grants.length ? (
        <div styleName="tile">
          {grants.map(({ name }) => name).join(', ')}
          <div styleName="tile">
            <IconButton
              size="small"
              onClick={() => updateState({ view: grantType })}
              disabled={loadingExistingPolicies}
            >
              <Icon>edit</Icon>
            </IconButton>
            <IconButton
              size="small"
              onClick={() => updateState({ grants: [] })}
              disabled={loadingExistingPolicies}
            >
              <Icon>close</Icon>
            </IconButton>
          </div>
        </div>
      ) : (
        <Button
          size="small"
          color="primary"
          onClick={() => updateState({ view: grantType })}
          style={{ minWidth: 0, padding: 0 }}
          disabled={loadingExistingPolicies}
        >
          Select
        </Button>
      )}
    </div>
  );
};

export default PolicyGenericGrantsSelection;
