import {
  call,
  delay,
  fork,
  put,
  takeEvery,
  takeLatest,
  throttle,
} from 'redux-saga/effects';

import actionTypes from '@atom/actions/actionTypes';
import {
  addFormInstanceMediaFailure,
  addFormInstanceMediaSuccess,
  clearFormInstanceSuccess,
  deleteFormInstanceMediaFailure,
  deleteFormInstanceMediaSuccess,
  getFormInstanceFailure,
  getFormInstanceSuccess,
  updateFormInstanceAttributeFailure,
  updateFormInstanceAttributeSuccess,
  updateFormInstanceCiFailure,
  updateFormInstanceCiSuccess,
  updateFormInstanceFhwaFailure,
  updateFormInstanceFhwaSuccess,
  updateFormInstanceFieldFailure,
  updateFormInstanceFieldSuccess,
  updateFormInstancePciFailure,
  updateFormInstancePciSuccess,
} from '@atom/actions/formInstanceActions';
import accountUtilities from '@atom/utilities/accountUtilities';
import api from '@atom/utilities/api';
import {
  FORM_INSTANCES_ENDPOINT,
  getFormInstanceAttributeEndpoint,
  getFormInstanceCiEndpoint,
  getFormInstanceFhwaEndpoint,
  getFormInstanceFieldEndpoint,
  getFormInstanceMediaEndpoint,
  getFormInstancePciEndpoint,
} from '@atom/utilities/endpoints';

export function* getFormInstance(action) {
  try {
    const { formInstanceId } = action.data;
    const endpoint = `${FORM_INSTANCES_ENDPOINT}/${formInstanceId}`;

    const { data } = yield call(api.get, endpoint);

    yield put(getFormInstanceSuccess(data));
  } catch (error) {
    yield fork(accountUtilities.apiErrorHandler, error, getFormInstanceFailure);
  }
}

export function* updateFormInstanceField(action) {
  try {
    const { formInstanceId, pageId, fieldId, body } = action.data;
    const baseEndpoint = getFormInstanceFieldEndpoint(formInstanceId, pageId);
    const endpoint = `${baseEndpoint}/${fieldId}`;

    yield call(api.patch, endpoint, body);

    yield put(updateFormInstanceFieldSuccess());
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      updateFormInstanceFieldFailure,
    );
  }
}

export function* updateFormInstanceAttribute(action) {
  try {
    const {
      formInstanceId,
      pageId,
      fieldId,
      assetId,
      attributeId,
      body,
    } = action.data;

    const baseEndpoint = getFormInstanceAttributeEndpoint(
      formInstanceId,
      pageId,
      fieldId,
      assetId,
    );

    const endpoint = `${baseEndpoint}/${attributeId}`;

    yield call(api.patch, endpoint, body);

    yield put(updateFormInstanceAttributeSuccess());
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      updateFormInstanceAttributeFailure,
    );
  }
}

export function* updateFormInstanceFhwa(action) {
  try {
    const { formInstanceId, pageId, fieldId, assetId, body } = action.data;

    const baseEndpoint = getFormInstanceFhwaEndpoint(
      formInstanceId,
      pageId,
      fieldId,
    );

    const endpoint = `${baseEndpoint}/${assetId}`;

    yield call(api.patch, endpoint, body);

    yield put(updateFormInstanceFhwaSuccess());
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      updateFormInstanceFhwaFailure,
    );
  }
}

export function* updateFormInstanceCi(action) {
  const { formInstanceId, pageId, fieldId, ...body } = action.data;

  try {
    const endpoint = getFormInstanceCiEndpoint(formInstanceId, pageId, fieldId);

    const { data } = yield call(api.patch, endpoint, [body]);

    yield put(updateFormInstanceCiSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      updateFormInstanceCiFailure,
    );
  }
}

export function* updateFormInstancePci(action) {
  const { formInstanceId, pageId, fieldId, ...body } = action.data;

  try {
    // debounces network requests in conjunction with takeLatest
    yield delay(1000);

    const endpoint = getFormInstancePciEndpoint(
      formInstanceId,
      pageId,
      fieldId,
    );

    const { data } = yield call(api.patch, endpoint, body);

    yield put(updateFormInstancePciSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      updateFormInstancePciFailure,
    );
  }
}

export function* addFormInstanceMedia(action) {
  try {
    const { formInstanceId, subjectId, media } = action.data;
    const baseEndpoint = getFormInstanceMediaEndpoint(
      formInstanceId,
      subjectId,
    );

    const body = {
      media,
    };

    const { data } = yield call(api.post, baseEndpoint, body);

    yield put(addFormInstanceMediaSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      addFormInstanceMediaFailure,
    );
  }
}

export function* deleteFormInstanceMedia(action) {
  try {
    const { formInstanceId, subjectId, mediaId } = action.data;
    const baseEndpoint = getFormInstanceMediaEndpoint(
      formInstanceId,
      subjectId,
    );

    const endpoint = `${baseEndpoint}/${mediaId}`;

    yield call(api.delete, endpoint);

    yield put(deleteFormInstanceMediaSuccess({ subjectId, mediaId }));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      deleteFormInstanceMediaFailure,
    );
  }
}

export function* clearFormInstance() {
  yield put(clearFormInstanceSuccess());
}

/* WATCHERS */
export function* retrieveFormInstance() {
  yield takeLatest(actionTypes.REQUEST_FORM_INSTANCE, getFormInstance);
}

export function* requestUpdateFormInstanceField() {
  yield takeEvery(
    actionTypes.REQUEST_UPDATE_FORM_INSTANCE_FIELD,
    updateFormInstanceField,
  );
}

export function* requestProgressiveUpdateFormInstanceField() {
  yield throttle(
    2000,
    actionTypes.REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_FIELD,
    updateFormInstanceField,
  );
}

export function* requestUpdateFormInstanceAttribute() {
  yield takeEvery(
    actionTypes.REQUEST_UPDATE_FORM_INSTANCE_ATTRIBUTE,
    updateFormInstanceAttribute,
  );
}

export function* requestUpdateFormInstanceFhwa() {
  yield takeEvery(
    actionTypes.REQUEST_UPDATE_FORM_INSTANCE_FHWA,
    updateFormInstanceFhwa,
  );
}

export function* requestAddFormInstanceMedia() {
  yield takeEvery(
    actionTypes.REQUEST_ADD_FORM_INSTANCE_MEDIA,
    addFormInstanceMedia,
  );
}

export function* requestDeleteFormInstanceMedia() {
  yield takeEvery(
    actionTypes.REQUEST_DELETE_FORM_INSTANCE_MEDIA,
    deleteFormInstanceMedia,
  );
}

export function* requestClearFormInstance() {
  yield takeEvery(actionTypes.REQUEST_CLEAR_FORM_INSTANCE, clearFormInstance);
}

export function* requestProgressiveUpdateFormInstanceAttribute() {
  yield throttle(
    500,
    actionTypes.REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_ATTRIBUTE,
    updateFormInstanceAttribute,
  );
}

export function* requestProgressiveUpdateFormInstanceFhwa() {
  yield throttle(
    500,
    actionTypes.REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_FHWA,
    updateFormInstanceFhwa,
  );
}

export function* requestProgressiveUpdateFormInstanceCi() {
  yield throttle(
    500,
    actionTypes.REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_CI,
    updateFormInstanceCi,
  );
}

export function* requestProgressiveUpdateFormInstancePci() {
  yield takeLatest(
    actionTypes.REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_PCI,
    updateFormInstancePci,
  );
}

export default [
  retrieveFormInstance,
  requestUpdateFormInstanceField,
  requestProgressiveUpdateFormInstanceField,
  requestUpdateFormInstanceAttribute,
  requestProgressiveUpdateFormInstanceAttribute,
  requestAddFormInstanceMedia,
  requestDeleteFormInstanceMedia,
  requestProgressiveUpdateFormInstanceFhwa,
  requestUpdateFormInstanceFhwa,
  requestClearFormInstance,
  requestProgressiveUpdateFormInstanceCi,
  requestProgressiveUpdateFormInstancePci,
];
