import * as R from 'ramda';

export const capitalize = R.converge(R.concat, [
  R.compose(R.toUpper, R.head),
  R.tail,
]);

export const toTitleCase = R.compose(
  R.join(' '),
  R.map(capitalize),
  R.split(' '),
  R.toLower,
);

export const capitalSnakeCaseToTitleCase = (str: string) => {
  const words = str.split('_');
  return words.map(toTitleCase).join(' ');
};

export const pluralize = (count, label) =>
  `${label}${count > 1 || count === 0 ? 's' : ''}`;

export const maskUntilPos = (str: string, pos: number = 3): string => {
  const keepChars = str.slice(-pos);
  if (str.length <= pos) {
    return keepChars;
  }
  return ['*'.repeat(str.length - pos), keepChars].join('');
};
