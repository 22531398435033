import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function (state = initialState.poolAttributes, action) {
  const { type, data } = action;
  switch (type) {
    case actionTypes.POOL_ATTRIBUTES_GET_SUCCESS: {
      return data;
    }
    default: {
      return state;
    }
  }
}
