import * as React from 'react';

import { Icon, IconButton } from '@atom/mui';

interface PassedProps {
  style?: object;
  tooltip?: string;
  disabled?: boolean;
  onClick?: () => void;
  color: string;
  icon: string;
  tooltipPosition?: string;
}

type Props = PassedProps;

const FontIconButton = ({
  style = {},
  tooltip = '',
  onClick = () => {},
  disabled,
  color,
  icon,
  tooltipPosition = 'bottom-center',
}: Props) => {
  return (
    <IconButton
      style={style}
      tooltip={tooltip}
      onClick={onClick}
      disabled={disabled}
      // @ts-ignore
      tooltipPosition={tooltipPosition}
    >
      <Icon color={color}>{icon}</Icon>
    </IconButton>
  );
};

export default FontIconButton;
