import React, { useContext, useEffect, useState } from 'react';
import { WorkTemplateFolderTree } from 'client/types/workTemplate';
import * as R from 'ramda';

import WorkTemplatesContext from '@atom/components/workTemplates/WorkTemplatesContext';
import FolderOptions from '@atom/components/workTemplates/workTemplatesFolderTree/FolderOptions';
import { Icon, ListTable, Progress } from '@atom/mui';
import api from '@atom/utilities/api';
import { WORK_TEMPLATES_FOLDER_ENDPOINT } from '@atom/utilities/endpoints';
import { setDisplayDate } from '@atom/utilities/timeUtilities';

import '../../../styles/table.css';

const { TableHead, TableRow, TableCell, TableBody } = ListTable;

const styles = {
  progress: {
    height: '100%',
  },
  name: {
    cursor: 'pointer',
    fontWeight: 500,
    overflow: 'visible',
    whiteSpace: 'nowrap',
  },
};

export interface Props {
  workTemplateFolderTree: WorkTemplateFolderTree;
}

const WorkTemplatesFolderList = ({ workTemplateFolderTree }: Props) => {
  const { activeFolderId, setActiveFolderId, open, toggleOpen } = useContext(
    WorkTemplatesContext,
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [workTemplateFolders, setWorkTemplateFolders] = useState<any[]>([]);

  useEffect(() => {
    const getWorkTemplates = async () => {
      setLoading(true);

      const { data } = await api.get(WORK_TEMPLATES_FOLDER_ENDPOINT, {
        parentFolderId: activeFolderId,
      });

      setWorkTemplateFolders(data);

      setLoading(false);
    };

    getWorkTemplates();
  }, [activeFolderId, workTemplateFolderTree]);

  return (
    <>
      {loading ? (
        <Progress style={styles.progress} />
      ) : (
        <ListTable offsetTop="110px">
          <TableHead>
            <TableRow header>
              <TableCell variant="head" />
              <TableCell variant="head">Name</TableCell>
              <TableCell variant="head">Created By</TableCell>
              <TableCell variant="head">Created On</TableCell>
              <TableCell variant="head" />
            </TableRow>
          </TableHead>
          <TableBody>
            {workTemplateFolders.map(folder => {
              const onClick = () => {
                if (!open.has(activeFolderId)) {
                  toggleOpen(activeFolderId);
                }
                setActiveFolderId(folder.id);
              };
              return (
                <TableRow key={folder.id}>
                  <TableCell width="2rem" align="right">
                    <Icon>folder</Icon>
                  </TableCell>
                  <TableCell>
                    <div style={styles.name} onClick={onClick}>
                      {folder.name}
                    </div>
                  </TableCell>
                  <TableCell>
                    {!R.isNil(folder.createdBy)
                      ? `${folder.createdBy.firstName} ${folder.createdBy.lastName}`
                      : '-'}
                  </TableCell>
                  <TableCell>{setDisplayDate(folder.createdDate)}</TableCell>
                  <TableCell width="3rem">
                    <FolderOptions folder={folder} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </ListTable>
      )}
    </>
  );
};

export default WorkTemplatesFolderList;
