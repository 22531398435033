import * as React from 'react';
import { connect } from 'react-redux';
import { AppBar, Box, Toolbar } from '@mui/material';
import { bindActionCreators, Dispatch } from 'redux';

import * as userActionCreators from '@atom/actions/userActions';
import Logo from '@atom/components/common/navigation/Logo';
import TextField from '@atom/components/common/TextField';
import { Icon, IconButton, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import layoutStyles from '@atom/styles/layout';
import { UserActions } from '@atom/types/actions';
import { EventType } from '@atom/types/event';
import { ReduxStore } from '@atom/types/store';
import accountUtilities from '@atom/utilities/accountUtilities';
import history from '@atom/utilities/history';

import GoogleSocialLoginButton from './GoogleSocialLoginButton';
import LoginFooter from './LoginFooter';

import './login.css';

const navBarStyle = {
  height: layoutStyles.topNavHeight,
  backgroundColor: colors.neutral.white,
  boxShadow: '0 2px 4px 1px',
  color: colors.utility.boxShadow,
  position: 'fixed',
  top: 0,
};

const styles = {
  titleStyle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  activeIcon: {
    fontSize: '24px',
    color: colors.neutral.white,
  },
  inactiveIcon: {
    fontSize: '24px',
    color: colors.neutral.gray,
  },
  floatingLabelStyle: {
    color: colors.neutral.dark,
  },
  passwordIcon: {
    cursor: 'pointer',
    color: colors.neutral.silver,
  },
  passwordField: {
    width: '95%',
  },
};

interface ReduxDispatchProps {
  userActions: UserActions;
}

interface ReduxStateProps {
  loading: boolean;
}

type Props = ReduxDispatchProps & ReduxStateProps;

interface ErrorsState {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
}

interface State {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  phone: string;
  passwordVisible: boolean;
  errors: ErrorsState;
}

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  passwordVisible: false,
  phone: '',
  errors: {
    email: '',
    confirmPassword: '',
    password: '',
    firstName: '',
    lastName: '',
  },
};

class Registration extends React.Component<Props, State> {
  state = initialState;

  navigateToLogin = () => {
    history.push('/login');
  };

  onChange = (event: EventType) => {
    const { name, value } = event.target;
    // @ts-ignore
    this.setState({ [name]: value });
  };

  isValidPasswordStrength = (password: string): boolean => {
    return password.length >= 6;
  };

  isValid = (): boolean => {
    const email = !accountUtilities.isValidEmail(this.state.email)
      ? 'Please enter a valid email address.'
      : '';

    const firstName = !this.state.firstName ? 'Please enter a first name.' : '';
    const lastName = !this.state.lastName ? 'Please enter a last name.' : '';
    const password =
      !this.state.password || !this.isValidPasswordStrength(this.state.password)
        ? 'Please enter a password with at least (6) characters.'
        : '';
    const confirmPassword =
      this.state.password !== this.state.confirmPassword
        ? 'Passwords do not match.  Please re-confirm your password.'
        : '';

    this.setState({
      errors: {
        ...this.state.errors,
        email,
        firstName,
        lastName,
        password,
        confirmPassword,
      },
    });

    return !firstName && !lastName && !email && !password && !confirmPassword;
  };

  submit = () => {
    const { userActions } = this.props;
    const { firstName, lastName, email, password, phone } = this.state;
    if (this.isValid()) {
      userActions.requestUserRegister({
        firstName,
        lastName,
        email,
        password,
        phone,
      });
    }
  };

  getErrorText = () => {
    const { errors } = this.state;

    if (errors.firstName) {
      return <div styleName="registration-error-text">{errors.firstName}</div>;
    }

    if (errors.lastName) {
      return <div styleName="registration-error-text">{errors.lastName}</div>;
    }

    if (errors.email) {
      return <div styleName="registration-error-text">{errors.email}</div>;
    }

    if (errors.password) {
      return <div styleName="registration-error-text">{errors.password}</div>;
    }

    if (errors.confirmPassword) {
      return (
        <div styleName="registration-error-text">{errors.confirmPassword}</div>
      );
    }

    return <div />;
  };

  togglePasswordVisibleState = () => {
    this.setState({ passwordVisible: !this.state.passwordVisible });
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      phone,
      passwordVisible,
    } = this.state;
    const { loading } = this.props;

    const errors = this.getErrorText();

    const content = loading ? (
      <div styleName="registration-login-spinner">
        <Progress />
      </div>
    ) : (
      <React.Fragment>
        <div>{errors}</div>
        <div styleName="text-field-container">
          <div styleName="text-field">
            <TextField
              floatingLabelFixed
              floatingLabelStyle={styles.floatingLabelStyle}
              value={firstName}
              name="firstName"
              floatingLabelText="First Name"
              onChange={this.onChange}
              fullWidth
            />
          </div>
          <div styleName="text-field">
            <TextField
              floatingLabelFixed
              floatingLabelStyle={styles.floatingLabelStyle}
              value={lastName}
              name="lastName"
              floatingLabelText="Last Name"
              onChange={this.onChange}
              fullWidth
            />
          </div>
          <div styleName="text-field">
            <TextField
              floatingLabelFixed
              type="email"
              floatingLabelStyle={styles.floatingLabelStyle}
              value={email}
              name="email"
              floatingLabelText="Email"
              onChange={this.onChange}
              fullWidth
            />
          </div>
          <div styleName="text-field">
            <TextField
              floatingLabelFixed
              style={styles.passwordField}
              type={passwordVisible ? 'text' : 'password'}
              floatingLabelStyle={styles.floatingLabelStyle}
              value={password}
              name="password"
              floatingLabelText="Password"
              onChange={this.onChange}
              fullWidth
            />
            <div styleName="password-visible-toggle-container">
              <IconButton
                style={styles.passwordIcon}
                onClick={this.togglePasswordVisibleState}
              >
                <Icon>remove_red_eye</Icon>
              </IconButton>
            </div>
          </div>
          <div styleName="text-field">
            <TextField
              floatingLabelFixed
              style={styles.passwordField}
              type={passwordVisible ? 'text' : 'password'}
              floatingLabelStyle={styles.floatingLabelStyle}
              value={confirmPassword}
              name="confirmPassword"
              floatingLabelText="Confirm Password"
              onChange={this.onChange}
              fullWidth
            />
            <div styleName="password-visible-toggle-container">
              <IconButton
                style={styles.passwordIcon}
                onClick={this.togglePasswordVisibleState}
              >
                <Icon>remove_red_eye</Icon>
              </IconButton>
            </div>
          </div>
          <div styleName="text-field">
            <TextField
              floatingLabelFixed
              floatingLabelStyle={styles.floatingLabelStyle}
              value={phone}
              name="phone"
              floatingLabelText="Phone Number (Optional)"
              onChange={this.onChange}
              fullWidth
            />
          </div>
        </div>
        <div styleName="generic-button-container">
          <div styleName="generic-button" onClick={this.submit}>
            sign up
          </div>
        </div>
        <div styleName="registration-social-login-container">
          <div styleName="login-decision-break">
            <span>or</span>
          </div>
          <div styleName="social-login-container">
            <GoogleSocialLoginButton text="sign up with google" />
          </div>
        </div>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <AppBar style={navBarStyle}>
          <Toolbar>
            <div>
              <Logo />
            </div>
            <Box sx={{ flexGrow: 1 }} />
            <div styleName="nav-right-container">
              <div styleName="nav-right-text">Already have an account?</div>
              <div styleName="nav-right-login" onClick={this.navigateToLogin}>
                Sign In
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <div styleName="container">
          <div styleName="auth-background base" />
          <div styleName="heading-text">Let's get started</div>
          <div styleName="registration-icon-container">
            <div styleName="registration-icon active">
              <Icon className="material-icons" style={styles.activeIcon}>
                person
              </Icon>
            </div>
            <div styleName="registration-icon">
              <Icon className="material-icons" style={styles.inactiveIcon}>
                email
              </Icon>
            </div>
            <div styleName="registration-icon">
              <Icon className="material-icons" style={styles.inactiveIcon}>
                business
              </Icon>
            </div>
          </div>
          <div styleName="icon-horizontal-bar" />
          {content}
          <LoginFooter />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  loading: state.loading.loadingUserRegistration,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  userActions: bindActionCreators(userActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
