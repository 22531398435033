import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';

import { TASK_USER_REMOVE } from '@atom/graph/task';
import { GET_TIME_ENTRIES, TIME_ENTRY_DELETE } from '@atom/graph/timeEntry';
import { GET_USER } from '@atom/graph/user';
import { WORK_ORDER_UPDATE } from '@atom/graph/work';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { Button, Modal, Progress, Switch, Tooltip } from '@atom/mui';
import { Task, TaskUserRemoveInput, TaskUserStatus } from '@atom/types/task';
import {
  TimeEntriesConnectionInput,
  TimeEntryStatus,
  WorkOrderTimeEntriesConnection,
  WorkOrderTimeEntry,
} from '@atom/types/timeEntry';
import { UserDetail } from '@atom/types/user';
import {
  WorkOrderDetailType,
  WorkOrderUpdate,
  WorkOrderUpdateInput,
  WorkValidations,
} from '@atom/types/work';
import {
  doesNotHaveRolePermissions,
  hasRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import {
  getWorkTimeFromTimeEntries,
  setWorkTimeDisplay,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import ModalTitle from './ModalTitle';
import UserTimeEntryDisplayRow from './UserTimeEntryDisplayRow';
import UserTimeEntryEditRow from './UserTimeEntryEditRow';

import './userTimeEntryModal.css';

interface Props {
  open: boolean;
  onClose: () => void;
  userId: string;
  workTime: number;
  taskUserStatus: TaskUserStatus;
  task: Task;
  workOrderDetail: WorkOrderDetailType;
  refetch: () => void;
  WorkOrderActionTypes: any;
  dispatch: any;
  setWorkValidations: (workValidations: WorkValidations) => void;
}

const UserTimeEntryModal = (props: Props) => {
  const userProfile = useUserProfile();

  const [showTimeEntry, setShowTimeEntry] = useState<boolean>(false);
  const [createTimeEntry, setCreateTimeEntry] = useState<boolean>(false);
  const [editTimeEntryId, setEditTimeEntryId] = useState<string>();

  const [getUser, { data, loading }] = useLazyQuery<{
    user: UserDetail;
  }>(GET_USER, {
    fetchPolicy: 'network-only',
  });

  const user = data?.user;

  const [
    getTimeEntries,
    {
      data: timeEntriesData,
      loading: timeEntriesLoading,
      refetch: refetchTimeEntries,
    },
  ] = useLazyQuery<
    { timeEntries: WorkOrderTimeEntriesConnection },
    { input: TimeEntriesConnectionInput }
  >(GET_TIME_ENTRIES, {
    fetchPolicy: 'network-only',
  });

  const [updateAssignee] = useMutation<
    { workOrderUpdate: WorkOrderUpdate },
    { workOrder: WorkOrderUpdateInput }
  >(WORK_ORDER_UPDATE);

  const [taskUserRemove] = useMutation<
    { taskUserRemove: Task },
    { input: TaskUserRemoveInput }
  >(TASK_USER_REMOVE, {
    onCompleted: () => {
      props.refetch();
    },
  });

  const [timeEntryDelete, { loading: timeEntryDeleteLoading }] = useMutation<
    { timeEntryDelete: WorkOrderTimeEntry },
    { id: string }
  >(TIME_ENTRY_DELETE, {
    onCompleted: () => {
      refetchTimeEntries();
    },
  });

  const timeEntries = timeEntriesData?.timeEntries?.timeEntries || [];

  useEffect(() => {
    if (props.open) {
      getUser({
        variables: {
          id: props.userId,
          input: {
            includeRestoredBudgets:
              props.workOrderDetail.reopened && !props.workOrderDetail.isClosed,
          },
        },
      });
      getTimeEntries({
        variables: {
          input: {
            userId: props.userId,
            workOrderId: props.workOrderDetail.id,
            taskId: props.task.id,
          },
        },
      });
    }
  }, [props.open]);

  useEffect(() => {
    if (
      !createTimeEntry &&
      isNilOrEmpty(editTimeEntryId) &&
      isNilOrEmpty(timeEntries)
    ) {
      setShowTimeEntry(false);
    }
  }, [createTimeEntry, editTimeEntryId, timeEntriesData]);

  useEffect(() => {
    if (!isNilOrEmpty(timeEntries)) {
      setShowTimeEntry(true);
    }
  }, [timeEntries]);

  const removeUser = async () => {
    await taskUserRemove({
      variables: {
        input: {
          id: props.task.id,
          userId: props.userId,
          workOrderId: props.workOrderDetail.id,
        },
      },
    });

    props.refetch();
    props.onClose();
  };

  const updateWorkOrderProperty = (
    property: keyof WorkOrderDetailType,
    value: any,
  ) => {
    props.dispatch({
      type: props.WorkOrderActionTypes.UPDATE_WORK_ORDER_PROPERTY,
      data: {
        property,
        value,
      },
    });
  };

  const setLeadAssignee = async (userId: string) => {
    if (!hasRolePermissions(ROLE_SETS.INSPECTOR)) {
      return;
    }
    const res = await updateAssignee({
      variables: {
        workOrder: {
          id: props.workOrderDetail.id,
          leadAssigneeId: userId,
        },
      },
    });

    updateWorkOrderProperty(
      'leadAssigneeId',
      res?.data?.workOrderUpdate.leadAssigneeId,
    );
  };

  const handleTimeEntryDelete = (id: string) => {
    timeEntryDelete({
      variables: {
        id,
      },
    });
  };

  const isLoading =
    loading || timeEntriesLoading || timeEntryDeleteLoading || R.isNil(user);

  const handleDone = () => {
    props.refetch();
    props.onClose();
  };

  const workTime = getWorkTimeFromTimeEntries(timeEntries);

  const toggleLabel = (
    <div styleName="toggle-label">
      {`Work Time${showTimeEntry ? ': ' : ''}`}
      {showTimeEntry && <strong>{setWorkTimeDisplay(workTime)}</strong>}
    </div>
  );

  const toggleShowTimeEntry = () => {
    if (showTimeEntry && !R.isEmpty(timeEntries)) {
      return;
    }

    setCreateTimeEntry(!createTimeEntry);
    setShowTimeEntry(!showTimeEntry);
  };

  const getTimeEntryDisabled = () => {
    const notPermitted =
      props.userId === userProfile.userId
        ? doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR)
        : doesNotHaveRolePermissions(ROLE_SETS.MANAGER);

    return props.workOrderDetail.isClosed || notPermitted;
  };

  const containsApprovedTimeEntry: boolean = R.any(
    R.propEq('status', TimeEntryStatus.approved),
  )(timeEntries);

  const isTimeEntryDisabled = getTimeEntryDisabled();
  const isDoneDisabled = !!editTimeEntryId || createTimeEntry;
  const isAddDisabled =
    isLoading || isTimeEntryDisabled || !!editTimeEntryId || createTimeEntry;
  const isRemoveUserDisabled =
    isLoading || props.workOrderDetail.isClosed || containsApprovedTimeEntry;

  const renderFooter = (
    <div styleName="modal-footer">
      {hasRolePermissions(ROLE_SETS.MANAGER) && (
        <Tooltip
          title={
            containsApprovedTimeEntry
              ? 'Team members with approved work time cannot be removed.'
              : ''
          }
          lightTooltip
        >
          <span>
            <Button onClick={removeUser} disabled={isRemoveUserDisabled}>
              Remove From Task
            </Button>
          </span>
        </Tooltip>
      )}
      <Button onClick={handleDone} disabled={isDoneDisabled}>
        Done
      </Button>
    </div>
  );

  return (
    <Modal
      title={
        <ModalTitle
          leadAssigneeId={props.workOrderDetail.leadAssigneeId}
          setLeadAssignee={setLeadAssignee}
          isClosed={props.workOrderDetail.isClosed}
          user={user}
          loading={loading}
          taskUserStatus={props.taskUserStatus}
          workOrderDetail={props.workOrderDetail}
          task={props.task}
          refetch={props.refetch}
          setWorkValidations={props.setWorkValidations}
        />
      }
      open={props.open}
      footer={renderFooter}
      loading={loading}
      width="lg"
    >
      <div styleName="modal-container">
        {loading || timeEntriesLoading ? (
          <Progress />
        ) : (
          <>
            <Switch
              checked={showTimeEntry}
              onChange={toggleShowTimeEntry}
              name="showTimeEntry"
              color="primary"
              disabled={isTimeEntryDisabled}
              label={toggleLabel}
            />
            {showTimeEntry && (
              <>
                {timeEntries.map(timeEntry => {
                  return timeEntry.id === editTimeEntryId ? (
                    <UserTimeEntryEditRow
                      key={timeEntry.id}
                      timeEntry={timeEntry}
                      user={user}
                      setEditTimeEntryId={setEditTimeEntryId}
                      refetchTimeEntries={refetchTimeEntries}
                      workOrderDetail={props.workOrderDetail}
                      task={props.task}
                    />
                  ) : (
                    <UserTimeEntryDisplayRow
                      key={timeEntry.id}
                      timeEntry={timeEntry}
                      setEditTimeEntryId={setEditTimeEntryId}
                      editTimeEntryId={editTimeEntryId}
                      isTimeEntryDisabled={isTimeEntryDisabled}
                      handleTimeEntryDelete={handleTimeEntryDelete}
                    />
                  );
                })}
                {createTimeEntry && (
                  <UserTimeEntryEditRow
                    key="create"
                    user={user}
                    createTimeEntry={createTimeEntry}
                    setCreateTimeEntry={setCreateTimeEntry}
                    refetchTimeEntries={refetchTimeEntries}
                    workOrderDetail={props.workOrderDetail}
                    task={props.task}
                  />
                )}
                <Button
                  onClick={() => setCreateTimeEntry(true)}
                  disabled={isAddDisabled}
                  color="primary"
                >
                  Add Time
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default UserTimeEntryModal;
