import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import * as R from 'ramda';

import { useSchemas } from '@atom/hooks/useSchemas';
import { Icon, Modal, Progress, TextField } from '@atom/mui';
import { getAvailableForms } from '@atom/selectors/formSelectors';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { FormModuleKeys, FormTemplateInfo } from '@atom/types/form';
import api from '@atom/utilities/api';
import { FORM_TEMPLATES_ENDPOINT } from '@atom/utilities/endpoints';

import FormRow from './FormRow';

import './addFormsModal.css';

const useStyles = makeStyles({
  root: {
    fontSize: fonts.lg,
    height: '3rem',
    paddingLeft: '1rem',
  },
  input: {
    paddingLeft: '1rem',
  },
});

const styles = {
  addIcon: {
    fontSize: '20px',
    '&:hover': {
      color: colors.brand.blue,
    },
  },
  progress: {
    height: '100%',
    alignItems: 'center',
  },
};

const getTemplateSubtext = (
  formTemplate: FormTemplateInfo,
  schemas: any[],
): string => {
  if (formTemplate?.schemaId) {
    return schemas.find(schema => schema.id === formTemplate.schemaId)?.name;
  }

  if (formTemplate.modules.includes(FormModuleKeys.CI)) {
    return 'Condition Inspection Asset Types';
  }

  if (formTemplate.modules.includes(FormModuleKeys.PCI)) {
    return 'Pavement';
  }

  return 'All Asset Types';
};

interface Props {
  schemaId?: string;
  loading: boolean;
  open: boolean;
  onClose: () => void;
  handleAddForm: (formTemplateIds: string[]) => void;
}

const AddFormsModal = ({
  schemaId,
  loading,
  open,
  onClose,
  handleAddForm,
}: Props) => {
  const classes = useStyles();

  const { schemas = [], loading: loadingSchemas } = useSchemas();

  const [selectedForms, setSelectedForms] = useState<Set<string>>(new Set([]));
  const [loadingFormTemplates, setLoadingFormTemplates] = useState<boolean>(
    false,
  );
  const [formTemplates, setFormTemplates] = useState<FormTemplateInfo[]>([]);
  const [filteredFormTemplates, setFilteredFormTemplates] = useState<
    FormTemplateInfo[]
  >([]);
  const [search, setSearch] = useState<string>('');
  const [availableForms, setAvailableForms] = useState<Set<string>>();

  useEffect(() => {
    const getFormTemplates = async () => {
      setLoadingFormTemplates(true);

      const { data } = await api.get(FORM_TEMPLATES_ENDPOINT, {
        page: 1,
        limit: 200,
        isPublished: true,
      });

      setFormTemplates(data);
      setFilteredFormTemplates(data);
      setLoadingFormTemplates(false);
    };

    if (open) {
      getFormTemplates();
    } else {
      onClose();
      setSelectedForms(new Set([]));
      setSearch('');
    }
  }, [open, schemaId]);

  useEffect(() => {
    const availableFormsList = getAvailableForms(
      formTemplates,
      schemas,
      schemaId,
    );

    setAvailableForms(availableFormsList);
  }, [schemas, formTemplates, schemaId]);

  useEffect(() => {
    setFilteredFormTemplates(
      formTemplates.filter(
        formTemplate =>
          !R.isEmpty(R.match(new RegExp(search, 'i'), formTemplate.name)),
      ),
    );
  }, [search, formTemplates]);

  const closeModal = () => {
    onClose();
    setSelectedForms(new Set([]));
    setSearch('');
  };

  const onConfirm = async () => {
    if (!R.isEmpty([...selectedForms])) {
      await handleAddForm([...selectedForms]);
    }
  };

  const toggleFormSelect = (id: string) => {
    return selectedForms.has(id)
      ? setSelectedForms(
          new Set([...selectedForms].filter(item => item !== id)),
        )
      : setSelectedForms(selectedForms.add(id));
  };

  const isLoading = loadingFormTemplates || loadingSchemas || loading;

  return (
    <Modal
      title="Add Forms"
      onCancel={closeModal}
      confirmButtonText="Add"
      width="lg"
      onConfirm={onConfirm}
      open={open}
    >
      <div styleName="modal-content">
        <div styleName="search-box">
          <TextField
            InputProps={{
              startAdornment: <Icon>search</Icon>,
              classes: {
                root: classes.root,
                input: classes.input,
              },
            }}
            value={search}
            name="search"
            onChange={event => setSearch(event.target.value)}
          />
        </div>
        {isLoading ? (
          <Progress style={styles.progress} />
        ) : (
          filteredFormTemplates.map(formTemplate => {
            const isSelected = selectedForms.has(formTemplate.id);

            return (
              <FormRow
                key={formTemplate.id}
                availableForms={availableForms}
                formTemplate={formTemplate}
                subtext={getTemplateSubtext(formTemplate, schemas)}
                isSelected={isSelected}
                toggleFormSelect={toggleFormSelect}
              />
            );
          })
        )}
      </div>
    </Modal>
  );
};

export default AddFormsModal;
