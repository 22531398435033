import React from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { WorkOrderTypeOption } from '@atom/types/work';

import './workTemplateTypeOption.css';

interface Props {
  selected?: boolean;
  option: WorkOrderTypeOption;
  onDropdownClick?: () => void;
  dropdown?: boolean;
}

const WorkTemplateTypeOption = ({
  option,
  dropdown = false,
  selected = false,
  onDropdownClick = () => {},
}: Props) => {
  const titleStyles = {
    color: selected ? colors.brand.blue : colors.neutral.dim,
  };

  return (
    <div styleName="type-option-container">
      <div styleName="type-option-content">
        <img src={option.icon} />
        <div styleName="type-option-text">
          <div style={titleStyles}>{option.title}</div>
          <div styleName="type-option-subtext">{option.subtext}</div>
        </div>
      </div>
      {dropdown && (
        <IconButton onClick={onDropdownClick}>
          <Icon>arrow_drop_down_icon</Icon>
        </IconButton>
      )}
    </div>
  );
};

export default WorkTemplateTypeOption;
