import { gql } from '@apollo/client';

export const BASE_TASK_FIELD = gql`
  fragment BaseTaskField on TaskField {
    id
    dataType
    title
    required
    value
    enumeration
    defaultValue
    subFieldsTruncated
    subFields {
      matchValue
      nestedField
    }
    units
    hyperlink
  }
`;

export const BASE_TASK = gql`
  fragment BaseTask on Task {
    id
    name
    originalName
    duration
    dueDate
    completionDate
    completedBy
    completedByName
    description
    estimatedCost
    isCompleted
    updatedDate
    startTime
    workTime
    actualCost
    isEstimatedCostOverridden
    taskTemplateId
    createdBy

    assetIds
    formInstanceIds

    users {
      id
      firstName
      lastName
      email
      photoUrl
      status
      workTime
      actualCost
      employeeId

      budget {
        budgetId
        name
        rate
      }
    }

    materials {
      name
      assetId
      schemaId
      colorId
      isTimeBased
      isStockBased
      isStartEndReading
      isStartEndCalculated
      rate
      cost
      quantity
      unit
    }

    userGroups {
      userGroupId
      name
      colorId
      quantity
      workTime

      budget {
        budgetId
        name
        rate
        restored
        reopenedWorkOrderIds
      }
    }

    fieldOrder
    fields {
      ...BaseTaskField
    }

    categoryIds
    categories {
      id
      name
      categoryPath
    }

    locations {
      id
      name
    }

    locationsEnabled
    requireAtLeastOneLocation
    multiAssetsEnabled
    allowedMultiAssetSchemaIds
    requireAtLeastOneMultiAsset
    requiredMultiAssetSchemaIds
  }
  ${BASE_TASK_FIELD}
`;

export const BASE_TASK_ITEM = gql`
  fragment BaseTaskItem on TaskConnectionItem {
    id
    name
    formInstanceIds
    locationIds

    dueDate
    completedDate
    startTime
    duration

    users {
      id
      firstName
      lastName
      email
      photoUrl
      status
    }

    userGroups {
      id
      name
      colorId
    }

    fields {
      ...BaseTaskField
    }

    locationsEnabled
    requireAtLeastOneLocation
    multiAssetsEnabled
    allowedMultiAssetSchemaIds
    requireAtLeastOneMultiAsset
    requiredMultiAssetSchemaIds
  }
  ${BASE_TASK_FIELD}
`;

export const TASK_CREATE = gql`
  mutation taskCreate($input: TaskCreateInput!) {
    taskCreate(input: $input) {
      ...BaseTask
    }
  }
  ${BASE_TASK}
`;

export const TASK_UPDATE = gql`
  mutation taskUpdate($input: TaskUpdateInput!) {
    taskUpdate(input: $input) {
      ...BaseTask
    }
  }
  ${BASE_TASK}
`;

export const TASK_DELETE = gql`
  mutation taskDelete($workOrderId: ID!, $taskId: ID!) {
    taskDelete(workOrderId: $workOrderId, taskId: $taskId)
  }
`;

export const TASK_DUPLICATE = gql`
  mutation taskDuplicate($workOrderId: ID!, $taskId: ID!) {
    taskDuplicate(workOrderId: $workOrderId, taskId: $taskId) {
      id
      name
    }
  }
`;

export const TASK_USER_GROUP_ADD = gql`
  mutation taskUserGroupsAdd($input: TaskUserGroupsAddInput!) {
    taskUserGroupsAdd(input: $input) {
      ...BaseTask
    }
  }
  ${BASE_TASK}
`;

export const TASK_USER_GROUP_UPDATE = gql`
  mutation taskUserGroupUpdate($input: TaskUserGroupUpdateInput!) {
    taskUserGroupUpdate(input: $input) {
      ...BaseTask
    }
  }
  ${BASE_TASK}
`;

export const TASK_USER_GROUP_REMOVE = gql`
  mutation taskUserGroupRemove($input: TaskUserGroupRemoveInput!) {
    taskUserGroupRemove(input: $input) {
      ...BaseTask
    }
  }
  ${BASE_TASK}
`;

export const TASK_USER_ADD = gql`
  mutation taskUsersAdd($input: TaskUsersAddInput!) {
    taskUsersAdd(input: $input) {
      ...BaseTask
    }
  }
  ${BASE_TASK}
`;

export const TASK_USER_UPDATE = gql`
  mutation taskUserUpdate($input: TaskUserUpdateInput!) {
    taskUserUpdate(input: $input) {
      ...BaseTask
    }
  }
  ${BASE_TASK}
`;

export const TASK_USER_REMOVE = gql`
  mutation taskUserRemove($input: TaskUserRemoveInput!) {
    taskUserRemove(input: $input) {
      ...BaseTask
    }
  }
  ${BASE_TASK}
`;

export const TASK_FIELD_CREATE = gql`
  mutation taskFieldCreate($input: TaskFieldCreateInput!) {
    taskFieldCreate(input: $input) {
      ...BaseTaskField
    }
  }
  ${BASE_TASK_FIELD}
`;

export const TASK_FIELD_UPDATE = gql`
  mutation taskFieldUpdate($input: TaskFieldUpdateInput!) {
    taskFieldUpdate(input: $input) {
      ...BaseTaskField
    }
  }
  ${BASE_TASK_FIELD}
`;

export const TASK_FIELD_DELETE = gql`
  mutation taskFieldDelete($input: TaskFieldDeleteInput!) {
    taskFieldDelete(input: $input)
  }
`;

export const TASK_MATERIALS_ADD = gql`
  mutation taskMaterialsAdd($input: TaskMaterialsAddInput!) {
    taskMaterialsAdd(input: $input) {
      name
      assetId
      colorId
      isTimeBased
      rate
      quantity
      unit
    }
  }
`;

export const TASK_USERS_STATUS_UPDATE = gql`
  mutation taskUsersStatusUpdate($input: TaskUsersStatusUpdateInput!) {
    taskUsersStatusUpdate(input: $input)
  }
`;

export const TASK_ASSETS_ADD = gql`
  mutation taskAssetsAdd($input: TaskAssetsAddInput!) {
    taskAssetsAdd(input: $input)
  }
`;

export const TASK_ASSET_REMOVE = gql`
  mutation taskAssetRemove($input: TaskAssetRemoveInput!) {
    taskAssetRemove(input: $input)
  }
`;
