import React, { useContext } from 'react';

import TextOverflowTooltip from '@atom/components/common/tooltip/TextOverflowTooltip';
import UserGroupIcon from '@atom/components/common/UserGroupIcon';
import AddTeamModalContext, {
  SelectedUserGroup,
} from '@atom/components/common/workOrderDetail/addTeamModal/AddTeamModalContext';
import { Icon, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';

import './addTeamModal.css';

const styles = {
  textFieldStyle: {
    color: colors.neutral.dark,
  },
};

interface Props {
  userGroup: SelectedUserGroup;
}

const UserGroupCartTile = ({ userGroup }: Props) => {
  const { removeUserGroupFromCart, updateUserGroupQuantity } = useContext(
    AddTeamModalContext,
  );

  return (
    <div styleName="cart-tile-container">
      <div styleName="side-tile-container">
        <UserGroupIcon colorId={userGroup.colorId} />
        <div>
          <div styleName="tile-title">
            <TextOverflowTooltip
              width="25rem"
              text={userGroup.name}
              lightTooltip
            />
          </div>
          <div styleName="tile-subtext path">
            <TextOverflowTooltip
              width="25rem"
              text={userGroup.groupPath.join(' / ')}
              lightTooltip
            />
          </div>
        </div>
      </div>
      <div styleName="side-tile-container">
        <div styleName="quantity-textfield-container">
          <TextField
            style={styles.textFieldStyle}
            type="number"
            value={userGroup.quantity}
            name="rate"
            onChange={event =>
              updateUserGroupQuantity(userGroup.id, event.target.value)
            }
          />
        </div>
        <Icon
          styleName="close-icon"
          onClick={() => removeUserGroupFromCart(userGroup.id)}
        >
          close
        </Icon>
      </div>
    </div>
  );
};

export default UserGroupCartTile;
