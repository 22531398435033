import React from 'react';

interface Context {
  activeFolderId: string;
  setActiveFolderId: React.Dispatch<React.SetStateAction<string>>;
  open: Set<string>;
  toggleOpen: (id: string) => void;
  refetchWorkTemplateFolderTree: () => Promise<void>;
  isActiveFolderLeafNode: boolean;
  setIsActiveFolderLeafNode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const initialState: Context = {
  activeFolderId: 'workTypes',
  setActiveFolderId: () => {},
  open: new Set(['workTypes']),
  toggleOpen: () => {},
  refetchWorkTemplateFolderTree: () => Promise.resolve(),
  isActiveFolderLeafNode: false,
  setIsActiveFolderLeafNode: () => {},
};

const WorkTemplatesContext = React.createContext<Context>(initialState);

export default WorkTemplatesContext;
