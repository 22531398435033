import React, { useContext } from 'react';

import WorkOrderPreviewContext from '@atom/components/workOrderPreview/WorkOrderPreviewContext';

import PreviewHeaderInfo from './PreviewHeaderInfo';
import PreviewHeaderTools from './PreviewHeaderTools';

import './workOrderPreviewHeader.css';

const WorkOrderPreviewHeader = () => {
  const { workOrderDetail } = useContext(WorkOrderPreviewContext);

  return (
    <div styleName="header-container">
      <div styleName="top-header-row">
        <div styleName="work-order-name">{workOrderDetail.name}</div>
        <PreviewHeaderTools />
      </div>
      <PreviewHeaderInfo />
    </div>
  );
};

export default WorkOrderPreviewHeader;
