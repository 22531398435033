// @ts-ignore
import workTypeIconChildBlue from '@atom/components/common/svgIcons/workTypeIconChildBlue.svg';
// @ts-ignore
import workTypeIconParentBlue from '@atom/components/common/svgIcons/workTypeIconParentBlue.svg';
import {
  InheritanceType,
  ModalStep,
} from '@atom/components/common/workTemplateCreationModal/WorkTemplateCreationModalContext';

export const INHERITANCE_OPTIONS = {
  [InheritanceType.REGULAR]: {
    value: InheritanceType.REGULAR,
    title: 'Regular',
    subtext:
      'Create a regular work template that can be used as a template to create work but without ability to have inherited child work templates.',
    icon: workTypeIconChildBlue,
  },
  [InheritanceType.INHERITANCE]: {
    value: InheritanceType.INHERITANCE,
    title: 'With Inheritance',
    subtext:
      'Create a parent work template that cannot be used to create work but can be used to create inherited child work templates.',
    icon: workTypeIconParentBlue,
  },
};

export const MODAL_STEPS = {
  [ModalStep.DETAILS]: {
    index: 0,
    value: ModalStep.DETAILS,
    title: 'Details',
  },
  [ModalStep.TEMPLATE_TYPE]: {
    index: 1,
    value: ModalStep.TEMPLATE_TYPE,
    title: 'Template Type',
  },
  [ModalStep.INHERITANCE]: {
    index: 2,
    value: ModalStep.INHERITANCE,
    title: 'Inheritance',
  },
};

export const MODAL_STEPS_ARRAY = [
  MODAL_STEPS[ModalStep.DETAILS],
  MODAL_STEPS[ModalStep.TEMPLATE_TYPE],
  MODAL_STEPS[ModalStep.INHERITANCE],
];

export const MODAL_STEPS_ARRAY_NO_INHERITANCE = [
  MODAL_STEPS[ModalStep.DETAILS],
  MODAL_STEPS[ModalStep.TEMPLATE_TYPE],
];
