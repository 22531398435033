import React from 'react';

interface Props {
  count: Number;
}

const MapClusterMarker = ({ count = 1 }: Props) => {
  // @ts-ignore
  const gte100 = count >= 100;
  // @ts-ignore
  const gte1000 = count >= 1000;
  const label = gte1000 ? '1000+' : `${count}`;
  const diameter = gte100 ? 90 : 70;
  const center = diameter / 2;
  const innerRadius = center - 15;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={diameter} height={diameter}>
      <g fill="#0283EA" fillRule="evenodd">
        <circle cx={center} cy={center} r={center} fillOpacity="0.2" />
        <circle cx={center} cy={center} r={innerRadius} fillOpacity="0.8" />
      </g>
      <text
        x={center}
        y={center + 2}
        textAnchor="middle"
        alignmentBaseline="middle"
        color="#FFF"
        fontSize="16px"
        fontFamily="Roboto, Arial, sans-serif"
        fontWeight="500"
        fill="#FFF"
      >
        {label}
      </text>
    </svg>
  );
};

export default MapClusterMarker;
