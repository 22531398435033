import React from 'react';
import * as R from 'ramda';
import uuid from 'uuid/v4';

import { RadioGroup } from '@atom/mui';
import {
  FormInstanceFieldItem,
  PciModuleDataUpdate,
  PciModuleDistress,
  PciModuleInspectionOption,
  PciModuleInspectionOptions,
} from '@atom/types/form';
import { MediaItem } from '@atom/types/media';

import './pci.css';

const { RadioButton } = RadioGroup;

interface Props {
  formInstanceId: string;
  fieldId: string;
  field: FormInstanceFieldItem;
  inventoryAssetId: string;
  media: MediaItem[];
  preview: boolean;
  options: PciModuleInspectionOptions;
  onChange: (update: PciModuleDataUpdate) => void;
  removeFile: (mediaId: string, subjectId: string) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  isEditable: boolean;
}

export const HAS_NO_DISTRESS = 'No Distress';
export const HAS_NO_DISTRESS_OPTION: PciModuleInspectionOption = {
  name: HAS_NO_DISTRESS,
  points: 100,
  description: 'Reconstructed during current calendar year.',
  descriptionSubtexts: [],
};

const getDistressLabel = (option: PciModuleInspectionOption) => (
  <div styleName="distress-container">
    <div styleName="distress-label">{`${option.points} - ${option.name}`}</div>
    <div styleName="distress-description">{option.description}</div>
    {option.descriptionSubtexts &&
      option.descriptionSubtexts.map(subtext => (
        <div key={subtext} styleName="distress-subtext-container">
          <div styleName="distress-bullet" />
          <div styleName="distress-subtext">{subtext}</div>
        </div>
      ))}
  </div>
);

// specific to PCI when type is "PCC NBI"
const NbiInspection = ({
  formInstanceId,
  fieldId,
  field,
  inventoryAssetId,
  media,
  preview,
  options,
  onChange,
  removeFile,
  renameMedia,
  isEditable,
}: Props) => {
  const distress = R.pathOr<PciModuleDistress>(
    null,
    ['pci', 'distress', 0],
    field,
  );

  // eslint-disable-next-line consistent-return
  const handleDistressClick = (event: Event) => {
    // @ts-ignore
    const { value } = event.target;

    if (value === HAS_NO_DISTRESS && field?.pci?.hasNoDistress) {
      // user is deselecting "no distress"
      return onChange({
        fieldId,
        hasNoDistress: false,
        distress: [],
      });
    }

    // user is "deselecting" selected distress
    if (value === distress?.distress) {
      return onChange({
        fieldId,
        distress: [{ ...distress, distress: '' }],
      });
    }
  };

  const handleDistressChange = (event: any) => {
    if (event.target.value === HAS_NO_DISTRESS) {
      // user is selecting "has no distress"
      return onChange({
        fieldId,
        hasNoDistress: !field?.pci?.hasNoDistress,
        distress: [],
      });
    }

    // user is selecting distress option
    return onChange({
      fieldId,
      hasNoDistress: false,
      distress: [
        {
          ...distress,
          id: distress?.id || uuid(),
          distress: event.target.value,
          quantity: 1,
        },
      ],
    });
  };

  return (
    <div styleName="inspection-container">
      <div styleName="subtitle">Inspection</div>
      <div styleName="label">
        Which description is most appropriate for this item's condition?
      </div>
      <RadioGroup
        name="distress"
        defaultValue={
          field.pci?.hasNoDistress ? HAS_NO_DISTRESS : distress?.distress
        }
        onChange={handleDistressChange}
      >
        {[HAS_NO_DISTRESS_OPTION, ...options.distress].map(option => (
          <RadioButton
            key={option.name}
            value={option.name}
            label={getDistressLabel(option)}
            onChange={handleDistressClick}
            disabled={!isEditable}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

export default NbiInspection;
