import React, { ReactNode } from 'react';

// @ts-ignore
import budgetDraft from '@atom/components/common/svgIcons/budgetDraft.svg';
// @ts-ignore
import budgetSubmitted from '@atom/components/common/svgIcons/budgetSubmitted.svg';
// @ts-ignore
import compare from '@atom/components/common/svgIcons/compare.svg';
import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  BasicBudget,
  Budget,
  BudgetStatus,
  BudgetSummary,
} from '@atom/types/budget';
import { formatDateMoment } from '@atom/utilities/timeUtilities';

import './budgetDetail.css';

export const COST_TYPE_COL_WIDTH = '7rem';
export const BUDGET_COL_WIDTH = '9rem';
export const COMMENT_COL_WIDTH = '18rem';
export const ICON_COL_WIDTH = '4.25rem';
export const POPOVER_HEIGHT = '25rem';
export const COMPARE_MENU_HEIGHT = '17rem';
export const CATEGORY_EXPAND_LIMIT = 10;
export const DEBOUNCE_TIME = 500;
export const MAX_ITEM_VALUE = {
  ERROR: 'The maximum budget item value is $999,999,999,999.99.',
  VALUE: 999999999999.99,
};
export const BUDGET_OPTIONS_LIMIT = 25;

// Used to manage budget item virtualization
export const ITEM_ROW_HEIGHT = 3.4375; // 55px in rem
export const MAX_ROWS_PER_CATEGORY = 10;

export const FIXED_COST_TOOLTIP_COPY =
  'Remaining accounts for actual cost and future fixed cost not spent yet.';

export enum EditField {
  VALUE = 'VALUE',
  COMMENT = 'COMMENT',
}

export const FIELD_KEYS = {
  VALUE: 'budgetValue',
  COMMENT: 'comment',
};

export const FIELD_NAMES = {
  VALUE: 'budget',
  COMMENT: 'comment',
};

const formatDate = (millis: number) => formatDateMoment(millis, 'MM/DD/YYYY');

export const getDateRange = (
  budget: BasicBudget | BudgetSummary | Budget,
): string =>
  ` (${formatDate(budget?.startDate?.valueOf())} - ${formatDate(
    budget?.endDate?.valueOf(),
  )})`;

export const getComparisonIcon = (hovering: boolean, open: boolean) => (
  <img
    style={{
      padding: '0 1.5rem',
      visibility: hovering || open ? 'visible' : 'hidden',
    }}
    src={compare}
  />
);

export const EmptyComparisonChart = (
  <div styleName="comparison-none-selected">
    No comparison budgets selected. Select budgets from the Compare menu to
    compare this data across budgets.
  </div>
);

const styles = {
  statusLabel: { display: 'flex', gap: '0.5rem', alignItems: 'center' },
};

export const getStatusLabel = (
  status: BudgetStatus,
  iconOnly?: boolean,
): ReactNode => {
  switch (true) {
    case status === BudgetStatus.APPROVED:
      return (
        <div style={styles.statusLabel}>
          <Icon style={{ color: colors.brand.green }}>
            check_circle_outlined
          </Icon>
          {!iconOnly && <span>Approved</span>}
        </div>
      );
    case status === BudgetStatus.SUBMITTED:
      return (
        <div style={styles.statusLabel}>
          <img src={budgetSubmitted} />
          {!iconOnly && <span>Submitted</span>}
        </div>
      );
    default:
      return (
        <div style={styles.statusLabel}>
          <img src={budgetDraft} />
          {!iconOnly && <span>Draft</span>}
        </div>
      );
  }
};
