import React from 'react';

interface Props {
  message: string;
}

const WorkExportNotification = ({ message }: Props) => {
  return (
    <span>
      <span>{message}</span> You'll receive an email with a link to download the
      report when it's ready. This may take several minutes to complete. Please
      allow extra time for larger datasets.
    </span>
  );
};

export default WorkExportNotification;
