import { endOfDay, startOfDay } from 'date-fns';
import * as R from 'ramda';

import {
  DateFilterPresetsCart,
  TaskDatePresets,
  WorkDatePresetKey,
  WorkDatePresets,
} from '@atom/types/workFilters';

import { getDaysAgo, setDisplayDate } from './timeUtilities';

export enum DateRangeTerminator {
  START,
  END,
}

export interface DateRangePresetOption {
  label: string;
  value: string;
}

export enum DateRange {
  ANYTIME = 'ANYTIME',
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  THIS_YEAR = 'THIS_YEAR',
  NO_DATE = 'NO_DATE',
  CUSTOM_RANGE = 'CUSTOM_RANGE',
}

export const dateOptions: DateRangePresetOption[] = [
  { label: 'Anytime', value: DateRange.ANYTIME },
  { label: 'Today', value: DateRange.TODAY },
  { label: 'Yesterday', value: DateRange.YESTERDAY },
  { label: 'Last 7 Days', value: DateRange.LAST_7_DAYS },
  { label: 'Last 30 Days', value: DateRange.LAST_30_DAYS },
  { label: 'This Year', value: DateRange.THIS_YEAR },
  { label: 'No Date', value: DateRange.NO_DATE },
  { label: 'Custom Range', value: DateRange.CUSTOM_RANGE },
];

export const getDateKeyFromPreset = (key: string, str: string): string =>
  R.endsWith('Preset', key) ? R.replace(/Preset$/, str, key) : key;

const getStartDateOfCurrentYear = (): Date => {
  const date = new Date();
  const currentYear = date.getUTCFullYear();
  return new Date(currentYear, 0, 1);
};

const getEndDateOfCurrentYear = (): Date => {
  const date = new Date();
  const currentYear = date.getUTCFullYear();
  return new Date(currentYear, 11, 31);
};

const getYesterday = getDaysAgo;

export const getDatesFromSelection = (value: DateRange): [Date, Date] => {
  let startDate: Date;
  let endDate: Date;
  switch (value) {
    case DateRange.TODAY:
      startDate = startOfDay(new Date());
      endDate = endOfDay(new Date());
      break;
    case DateRange.YESTERDAY:
      startDate = getYesterday();
      endDate = endOfDay(getYesterday());
      break;
    case DateRange.LAST_7_DAYS:
      startDate = getDaysAgo(7);
      endDate = endOfDay(new Date());
      break;
    case DateRange.LAST_30_DAYS:
      startDate = getDaysAgo(30);
      endDate = endOfDay(new Date());
      break;
    case DateRange.THIS_YEAR:
      startDate = getStartDateOfCurrentYear();
      endDate = getEndDateOfCurrentYear();
      break;
    case DateRange.ANYTIME:
    default:
      startDate = null;
      endDate = null;
  }
  return [startDate, endDate];
};

export const getDateRangeLabel = (startDate: Date, endDate: Date): string => {
  const formattedFromString = R.isNil(startDate)
    ? ''
    : `From ${setDisplayDate(startDate?.valueOf())}`;

  const formattedToString = R.isNil(endDate)
    ? ''
    : `to ${setDisplayDate(endDate?.valueOf())}`;

  return [formattedFromString, formattedToString].join(' ');
};

export const getDatePresets = (defaultFilter = {}): DateFilterPresetsCart => ({
  [WorkDatePresetKey.WORK]: {
    dueDatePreset: R.pathOr(
      DateRange.ANYTIME,
      [WorkDatePresetKey.WORK, 'dueDatePreset'],
      defaultFilter,
    ),
    completionDatePreset: R.pathOr(
      DateRange.ANYTIME,
      [WorkDatePresetKey.WORK, 'completionDatePreset'],
      defaultFilter,
    ),
    startDatePreset: R.pathOr(
      DateRange.ANYTIME,
      [WorkDatePresetKey.WORK, 'startDatePreset'],
      defaultFilter,
    ),
    createdDatePreset: R.pathOr(
      DateRange.ANYTIME,
      [WorkDatePresetKey.WORK, 'createdDatePreset'],
      defaultFilter,
    ),
  },
  [WorkDatePresetKey.TASK]: {
    dueDatePreset: R.pathOr(
      DateRange.ANYTIME,
      [WorkDatePresetKey.TASK, 'dueDatePreset'],
      defaultFilter,
    ),
    completionDatePreset: R.pathOr(
      DateRange.ANYTIME,
      [WorkDatePresetKey.TASK, 'completionDatePreset'],
      defaultFilter,
    ),
    startDatePreset: R.pathOr(
      DateRange.ANYTIME,
      [WorkDatePresetKey.TASK, 'startDatePreset'],
      defaultFilter,
    ),
  },
});

export const applyDatePresetParam = (
  defaultFilter,
  params,
  key: string,
  presets: WorkDatePresets | TaskDatePresets,
): void => {
  const selectedRange: DateRange = presets[key];
  const startKey = getDateKeyFromPreset(key, 'Start');
  const endKey = getDateKeyFromPreset(key, 'End');
  if (selectedRange === DateRange.CUSTOM_RANGE) {
    params[startKey] = defaultFilter[startKey];
    params[endKey] = defaultFilter[endKey];
  } else {
    const [startDate, endDate] = getDatesFromSelection(selectedRange);
    params[startKey] = startDate?.valueOf() || null;
    params[endKey] = endDate?.valueOf() || null;
  }
};
