import React from 'react';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
import textConstants from '@atom/constants/textConstants';
import { USER_UPDATE } from '@atom/graph/user';
import { Progress } from '@atom/mui';
import { UserDetail, UserUpdateInput } from '@atom/types/user';
import history from '@atom/utilities/history';

import './userHeader.css';

interface Props {
  open: boolean;
  onClose: () => void;
  userId: string;
}

const DeactivateUserModal = ({ open, onClose, userId }: Props) => {
  const [updateUser, { loading: updateLoading }] = useMutation<
    { userUpdate: UserDetail },
    { input: UserUpdateInput }
  >(USER_UPDATE, {
    onCompleted: () => {
      history.push('/team');
    },
  });

  const confirm = () => {
    updateUser({
      variables: {
        input: {
          id: userId,
          isActive: false,
        },
      },
    });

    onClose();
  };

  return (
    <DeleteModal
      title="Deactivate User?"
      onConfirm={confirm}
      confirmText="Deactivate"
      onCancel={onClose}
      content={
        updateLoading ? (
          <Progress />
        ) : (
          <div styleName="modal-content">
            {textConstants.DEACTIVATE_USER_MODAL_COPY}
          </div>
        )
      }
      open={open}
    />
  );
};

export default DeactivateUserModal;
