import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';

import { updateWorkOrdersInput } from '@atom/actions/workOrdersInputActions';
import WorkOrderPreviewContext from '@atom/components/workOrderPreview/WorkOrderPreviewContext';
import WorkOrdersContext from '@atom/components/workOrders/WorkOrdersContext';
import { GET_TIME_ENTRIES_STATUS } from '@atom/graph/timeEntry';
import { WORK_ORDER_DELETE, WORK_ORDER_DUPLICATE } from '@atom/graph/work';
import { Button, Icon, Menu, Modal, Progress, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  TimeEntriesConnectionInput,
  TimeEntriesStatusConnection,
  TimeEntryStatus,
} from '@atom/types/timeEntry';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import {
  isCurrentTenant,
  Tenant,
} from '@atom/utilities/featureToggleUtilities';
import history from '@atom/utilities/history';

import './workOrderPreviewHeader.css';

const { MenuItem } = Menu;

const styles = {
  icon: {
    marginRight: '0.5rem',
  },
  removeButton: {
    background: colors.brand.red,
    color: colors.neutral.white,
  },
};

const PreviewHeaderOptions = () => {
  const dispatch = useDispatch();

  const { input, refetch: refetchList, setInput } = useContext(
    WorkOrdersContext,
  );
  const { workOrderDetail } = useContext(WorkOrderPreviewContext);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState<boolean>(false);

  const [duplicateWorkOrder, { loading: loadingDuplicate }] = useMutation(
    WORK_ORDER_DUPLICATE,
  );

  const [deleteWorkOrder, { loading: loadingDelete }] = useMutation(
    WORK_ORDER_DELETE,
  );

  const [
    getTimeEntriesStatus,
    { loading: timeEntriesStatusLoading, data: timeEntriesStatusData },
  ] = useLazyQuery<
    { timeEntries: TimeEntriesStatusConnection },
    { input: TimeEntriesConnectionInput }
  >(GET_TIME_ENTRIES_STATUS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (deleteModalOpen || duplicateModalOpen) {
      getTimeEntriesStatus({
        variables: {
          input: {
            workOrderId: workOrderDetail.id,
            statuses: [TimeEntryStatus.approved],
          },
        },
      });
    }
  }, [deleteModalOpen, duplicateModalOpen]);

  const handleDuplicate = async () => {
    const { id, name } = workOrderDetail;

    Snackbar.info({ message: `Duplicating work ${name}...` });

    try {
      const { data } = await duplicateWorkOrder({ variables: { id } });

      Snackbar.info({
        message: `Duplicated work ${name}.`,
        action: 'View',
        onActionClick: () =>
          history.push(`/workOrders/${data.workOrderDuplicate}`),
      });
    } catch (error) {
      Snackbar.error({ message: `Failed to duplicate work ${name}.` });
    }

    setDuplicateModalOpen(false);
  };

  const handleDeleteWorkOrder = async () => {
    const state = history.location.state;

    try {
      await deleteWorkOrder({
        variables: {
          id: workOrderDetail.id,
        },
      });

      history.push('/workOrders', state);

      refetchList();
      setInput({ ...input, workOrderId: undefined });
      dispatch(updateWorkOrdersInput({ ...input, workOrderId: undefined }));

      Snackbar.info({
        message: 'Work deleted',
      });
    } catch (error) {
      const message =
        error?.networkError?.statusCode === 423
          ? 'Cannot delete a work used to create a work template.'
          : 'An error occurred. Please try again.';

      Snackbar.error({ message });

      setDeleteModalOpen(false);
    }
  };

  const hasApprovedTimeEntries =
    timeEntriesStatusData?.timeEntries?.totalCount > 0;

  const getDeleteModalValue = () => {
    return hasApprovedTimeEntries
      ? {
          title: `Cannot Delete Work`,
          content: 'Work with approved work time cannot be deleted.',
        }
      : {
          title: 'Delete Work',
          content: 'Are you sure you want to delete this work?',
        };
  };

  const getDuplicateModalValue = () => {
    return {
      title: `Duplicate Work`,
      content: 'Are you sure you want to duplicate this work?',
    };
  };

  const deleteModalLoading = loadingDelete || timeEntriesStatusLoading;
  const duplicateModalLoading = loadingDuplicate || timeEntriesStatusLoading;

  const getDeleteFooter = () => {
    return hasApprovedTimeEntries ? (
      <Button onClick={() => setDeleteModalOpen(false)}>OK</Button>
    ) : (
      <div>
        <Button
          onClick={() => setDeleteModalOpen(false)}
          style={{ marginRight: '0.5rem' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={deleteModalLoading}
          onClick={handleDeleteWorkOrder}
          style={styles.removeButton}
        >
          Delete
        </Button>
      </div>
    );
  };

  const getDuplicateFooter = () => {
    return (
      <div>
        <Button
          onClick={() => setDuplicateModalOpen(false)}
          style={{ marginRight: '0.5rem' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={duplicateModalLoading}
          onClick={handleDuplicate}
          color="primary"
        >
          Duplicate
        </Button>
      </div>
    );
  };

  const isDeleteEnabled = isCurrentTenant([
    Tenant.DEV_HENNEPIN,
    Tenant.QA_HENNEPIN,
    Tenant.UAT_HENNEPIN,
    Tenant.HENNEPIN,
  ])
    ? hasRolePermissions(ROLE_SETS.MANAGER)
    : hasRolePermissions(ROLE_SETS.ORG_ADMIN);
  const isDuplicateEnabled = hasRolePermissions(ROLE_SETS.INSPECTOR);
  const showActionMenu = isDeleteEnabled || isDuplicateEnabled;

  const deleteModalValue = getDeleteModalValue();
  const duplicateModalValue = getDuplicateModalValue();

  return (
    <>
      {showActionMenu && (
        <Menu IconButtonProps={{ edge: 'end' }}>
          {isDuplicateEnabled && (
            <MenuItem
              startAdornment={<Icon style={styles.icon}>content_copy</Icon>}
              onClick={() => setDuplicateModalOpen(true)}
              disabled={loadingDuplicate}
              data-cy="workDuplicationButton"
            >
              Duplicate
            </MenuItem>
          )}
          {isDeleteEnabled && (
            <MenuItem
              onClick={() => setDeleteModalOpen(true)}
              startAdornment={<Icon style={styles.icon}>delete</Icon>}
              data-cy="workDeleteButton"
            >
              Delete
            </MenuItem>
          )}
        </Menu>
      )}
      <Modal
        open={deleteModalOpen}
        title={deleteModalLoading ? '' : deleteModalValue.title}
        footer={deleteModalLoading ? '' : getDeleteFooter()}
      >
        {deleteModalLoading ? (
          <div styleName="loading-container">
            <Progress />
          </div>
        ) : (
          deleteModalValue.content
        )}
      </Modal>
      <Modal
        open={duplicateModalOpen}
        title={duplicateModalLoading ? '' : duplicateModalValue.title}
        footer={duplicateModalLoading ? '' : getDuplicateFooter()}
      >
        {duplicateModalLoading ? (
          <div styleName="loading-container">
            <Progress />
          </div>
        ) : (
          duplicateModalValue.content
        )}
      </Modal>
    </>
  );
};

export default PreviewHeaderOptions;
