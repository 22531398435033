import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as inventoryExportActions from '@atom/actions/inventoryExportActions';
import DeleteModal from '@atom/components/common/DeleteModal';
import RenameModal from '@atom/components/common/RenameModal';
import { Button, Icon, IconButton, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import { ExportTemplateDetailType } from '@atom/types/inventoryExport';
import { INVENTORY_EXPORT_ENDPOINT } from '@atom/utilities/endpoints';

import './inventoryExportDetail.css';

interface Props {
  navigateBack: () => void;
  exportTemplateDetail: ExportTemplateDetailType;
}

const InventoryExportDetailHeader = ({
  navigateBack,
  exportTemplateDetail,
}: Props) => {
  const dispatch = useDispatch();

  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [exportOpen, setExportOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

  const renameTemplate = (exportTemplateId: string, name: string) => {
    dispatch(
      inventoryExportActions.requestExportTemplateUpdate({
        exportTemplateId,
        name,
      }),
    );
  };

  const deleteTemplate = () => {
    dispatch(
      inventoryExportActions.requestExportTemplateDelete({
        exportTemplateId: exportTemplateDetail.id,
      }),
    );
  };

  const publishTemplate = () => {
    const { id, isPublished } = exportTemplateDetail;

    dispatch(
      inventoryExportActions.requestExportTemplateUpdate({
        exportTemplateId: id,
        isPublished: !isPublished,
      }),
    );
  };

  const exportToCSV = () => {
    const href = `${INVENTORY_EXPORT_ENDPOINT}/${exportTemplateDetail.id}/export`;
    window.location.href = href;

    setExportOpen(false);
  };

  return (
    <>
      <div styleName="navigation-bar-container">
        <div styleName="back-navigation-container" onClick={navigateBack}>
          <div styleName="back-button-icon">
            <Icon color={colors.neutral.white}>arrow_back</Icon>
          </div>
          <div styleName="back-button-text">{exportTemplateDetail.name}</div>
        </div>
        <div styleName="controls-container">
          <IconButton onClick={() => setEditOpen(true)} tooltip="Edit">
            <Icon color={colors.neutral.white}>edit</Icon>
          </IconButton>
          <IconButton tooltip="Export" onClick={() => setExportOpen(true)}>
            <Icon color={colors.neutral.white}>file_download</Icon>
          </IconButton>
          <IconButton tooltip="Delete" onClick={() => setDeleteOpen(true)}>
            <Icon color={colors.neutral.white}>delete</Icon>
          </IconButton>
          <Button
            onClick={publishTemplate}
            style={{
              color: colors.brand.purple,
              backgroundColor: colors.neutral.white,
            }}
          >
            Publish
          </Button>
        </div>
      </div>
      <RenameModal
        id={exportTemplateDetail.id}
        name={exportTemplateDetail.name}
        type="Data Export"
        renameAction={renameTemplate}
        open={editOpen}
        closeModal={() => setEditOpen(false)}
      />
      <Modal
        open={exportOpen}
        onConfirm={exportToCSV}
        confirmButtonText="Export"
        onCancel={() => setExportOpen(false)}
        title="Export to CSV"
      >
        There is a maximum limit of 25,000 inventory items in one export. If
        your export contains more than the maximum limit, you will only export
        25,000 inventory items.
      </Modal>
      <DeleteModal
        title="Delete Data Export Template?"
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={deleteTemplate}
        confirmText="Delete"
        content="Are you sure you want to delete this data export template?"
      />
    </>
  );
};

export default InventoryExportDetailHeader;
