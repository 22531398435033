import React, { createContext } from 'react';
import * as R from 'ramda';

import { WorkOrdersInputState } from '@atom/hooks/useWorkOrdersFilters';
import {
  FieldFilter,
  TaskFieldsFilter,
  TaskFilter,
  WorkOrder,
} from '@atom/types/work';
import { WorkOrderColumn } from '@atom/types/workColumns';
import { CustomFieldFilter, WorkFilter } from '@atom/types/workFilters';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

export enum FilterSection {
  WORK = 'WORK',
  TASK = 'TASK',
  TEAM = 'TEAM',
  INVENTORY = 'INVENTORY',
}

export interface Context {
  workOrders: WorkOrder[];
  customFieldFilters?: CustomFieldFilter[];
  workOrderColumns: WorkOrderColumn[];
  refetchColumns: () => Promise<any>;
  totalCount: number;
  loading: boolean;
  workFilters: WorkFilter[];
  refetchWorkFilters: () => Promise<any>;
  loadingFilters: boolean;
  refetch: () => Promise<any>;
  input: WorkOrdersInputState;
  setInput: (input: WorkOrdersInputState) => void;
  workOrdersInputCart: WorkOrdersInputState;
  dispatch: React.Dispatch<WorkOrdersInputActions>;
  filtersDisabled?: boolean;
  collapsedSections: Set<FilterSection>;
  setCollapsedSections: (collapsedSections: Set<FilterSection>) => void;
  getWorkFilters: (params: any) => void;
}

export const initialState: Context = {
  workOrders: [],
  workOrderColumns: [],
  customFieldFilters: [],
  refetchColumns: () => Promise.resolve(),
  totalCount: 0,
  loading: false,
  workFilters: [],
  refetchWorkFilters: () => Promise.resolve(),
  loadingFilters: false,
  input: {},
  setInput: () => {},
  workOrdersInputCart: {},
  dispatch: () => {},
  refetch: () => Promise.resolve(),
  filtersDisabled: false,
  collapsedSections: new Set([]),
  setCollapsedSections: () => {},
  getWorkFilters: (params: any) => {},
};

export default createContext<Context>(initialState);

export enum WorkOrdersInputActionTypes {
  SET_WORK_ORDERS_INPUT = 'SET_WORK_ORDERS_INPUT',
  UPDATE_WORK_ORDERS_INPUT_PROPERTY = 'UPDATE_WORK_ORDERS_INPUT_PROPERTY',
  UPDATE_TASKS_INPUT_PROPERTY = 'UPDATE_TASKS_INPUT_PROPERTY',
  UPDATE_WORK_ORDERS_FIELD = 'UPDATE_WORK_ORDERS_FIELD',
  UPDATE_TASKS_COMMON_FIELD = 'UPDATE_TASKS_COMMON_FIELD',
  UPDATE_TASKS_FIELD = 'UPDATE_TASKS_FIELD',
}

interface SetWorkOrdersInput {
  type: WorkOrdersInputActionTypes.SET_WORK_ORDERS_INPUT;
  data: WorkOrdersInputState;
}

interface UpdateWorkOrdersInputProperty {
  type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_INPUT_PROPERTY;
  data: {
    property: keyof WorkOrdersInputState;
    value: any;
  };
}

interface UpdateTasksInputProperty {
  type: WorkOrdersInputActionTypes.UPDATE_TASKS_INPUT_PROPERTY;
  data: {
    property: keyof TaskFilter;
    value: any;
  };
}

// value can contain any of the field filter properties
// value, values, or valueStart / valueEnd
interface UpdateWorkOrdersField {
  type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_FIELD;
  data: {
    fieldId: string;
    fieldProperty: string;
    value: any;
  };
}

// value can contain any of the field filter properties
// value, values, or valueStart / valueEnd
interface UpdateTasksCommonField {
  type: WorkOrdersInputActionTypes.UPDATE_TASKS_COMMON_FIELD;
  data: {
    fieldId: string;
    fieldProperty: string;
    value: any;
  };
}

// payload can contain any of the task field filter properties
// value, values, or valueStart / valueEnd
interface UpdateTasksField {
  type: WorkOrdersInputActionTypes.UPDATE_TASKS_FIELD;
  data: {
    taskId: string;
    fieldId: string;
    fieldProperty: string;
    value: any;
  };
}

type WorkOrdersInputActions =
  | SetWorkOrdersInput
  | UpdateWorkOrdersInputProperty
  | UpdateTasksInputProperty
  | UpdateWorkOrdersField
  | UpdateTasksCommonField
  | UpdateTasksField;

// updates a single property on the workOrder input.
const updateWorkOrdersInputProperty = (
  state: WorkOrdersInputState,
  action: UpdateWorkOrdersInputProperty,
) => {
  return {
    ...state,
    [action.data.property]: action.data.value,
  };
};

// updates a single property on the tasks input.
const updateTasksInputProperty = (
  state: WorkOrdersInputState,
  action: UpdateTasksInputProperty,
) => {
  return {
    ...state,
    tasks: {
      ...state.tasks,
      [action.data.property]: action.data.value,
    },
  };
};

// determines if the field should be removed from the filter
// For date range fields, they should only be removed if both valueStart
// and valueEnd are null / empty
const shouldRemoveField = (
  fieldId: string,
  value: any,
  fieldProperty: any,
  fields: FieldFilter[],
): boolean => {
  const selectedField = fields.find(field => field.id === fieldId);

  if (
    selectedField &&
    (fieldProperty === 'valueStart' || fieldProperty === 'valueEnd')
  ) {
    const otherFieldProperty =
      fieldProperty === 'valueStart' ? 'valueEnd' : 'valueStart';

    return (
      isNilOrEmpty(value) && isNilOrEmpty(selectedField[otherFieldProperty])
    );
  }

  return isNilOrEmpty(value);
};

// updates a common field property on the tasks input.
const updateTasksCommonField = (
  state: WorkOrdersInputState,
  action: UpdateTasksCommonField,
) => {
  const { fieldId, fieldProperty, value } = action.data;

  const currentCommonFields = state?.tasks?.commonFields || [];
  const hasField = currentCommonFields.find(field => field.id === fieldId);

  const removeField = shouldRemoveField(
    fieldId,
    value,
    fieldProperty,
    currentCommonFields,
  );

  if (removeField) {
    return {
      ...state,
      tasks: {
        ...state.tasks,
        commonFields: currentCommonFields.filter(field => field.id !== fieldId),
      },
    };
  }

  const commonFields = hasField
    ? currentCommonFields.map(field => {
        return field.id === fieldId
          ? { ...field, ...{ [fieldProperty]: value } }
          : field;
      })
    : [...currentCommonFields, { id: fieldId, ...{ [fieldProperty]: value } }];

  return {
    ...state,
    tasks: {
      ...state.tasks,
      commonFields,
    },
  };
};

// updates a single work field filter
const updateWorkOrdersField = (
  state: WorkOrdersInputState,
  action: UpdateWorkOrdersField,
) => {
  const { fieldId, fieldProperty, value } = action.data;

  const hasField = state.fields.find(field => field.id === fieldId);

  const removeField = shouldRemoveField(
    fieldId,
    value,
    fieldProperty,
    state?.fields || [],
  );

  if (removeField) {
    return {
      ...state,
      fields: state.fields.filter(field => field.id !== fieldId),
    };
  }

  const fields = hasField
    ? state.fields.map(field => {
        return field.id === fieldId
          ? { ...field, ...{ [fieldProperty]: value } }
          : field;
      })
    : [...state.fields, { id: fieldId, ...{ [fieldProperty]: value } }];

  return {
    ...state,
    fields,
  };
};

// updates nested task filter
const updateNestedField = (
  task: TaskFieldsFilter,
  action: UpdateTasksField,
) => {
  const { fieldId, fieldProperty, value } = action.data;

  const hasTaskField = task.fields.find(field => field.id === fieldId);

  const removeField = shouldRemoveField(
    fieldId,
    value,
    fieldProperty,
    task?.fields || [],
  );

  if (removeField) {
    return {
      ...task,
      fields: R.pathOr([], ['fields'], task).filter(
        field => field.id !== fieldId,
      ),
    };
  }

  if (hasTaskField) {
    const updatedFields = task.fields.map(field =>
      field.id === fieldId
        ? { ...field, ...{ [fieldProperty]: value } }
        : field,
    );

    return {
      ...task,
      fields: updatedFields,
    };
  }

  return {
    ...task,
    fields: [
      ...R.pathOr([], ['fields'], task),
      { id: fieldId, ...{ [fieldProperty]: value } },
    ],
  };
};

// Removes tasks that have empty filters
const cleanTasks = (tasks: TaskFieldsFilter[]): TaskFieldsFilter[] => {
  return tasks.filter(task => !isNilOrEmpty(task.fields));
};

// updates a single task field filter
const updateTasksField = (
  state: WorkOrdersInputState,
  action: UpdateTasksField,
) => {
  const { taskId, fieldId, fieldProperty, value } = action.data;

  const hasTask = state.tasks.each?.find(task => task.id === taskId);

  if (hasTask) {
    const updatedTasks = state.tasks.each.map(task =>
      task.id === taskId ? updateNestedField(task, action) : task,
    );

    return {
      ...state,
      tasks: {
        ...state.tasks,
        each: cleanTasks(updatedTasks),
      },
    };
  }

  const newTasks = [
    ...R.pathOr([], ['tasks', 'each'], state),
    {
      id: taskId,
      fields: [
        {
          id: fieldId,
          ...{ [fieldProperty]: value },
        },
      ],
    },
  ];

  return {
    ...state,
    tasks: {
      ...state.tasks,
      each: newTasks,
    },
  };
};

export const reducer = (
  state: WorkOrdersInputState,
  action: WorkOrdersInputActions,
): WorkOrdersInputState => {
  switch (action.type) {
    case WorkOrdersInputActionTypes.SET_WORK_ORDERS_INPUT:
      return action.data;
    case WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_INPUT_PROPERTY:
      return updateWorkOrdersInputProperty(state, action);
    case WorkOrdersInputActionTypes.UPDATE_TASKS_INPUT_PROPERTY:
      return updateTasksInputProperty(state, action);
    case WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_FIELD:
      return updateWorkOrdersField(state, action);
    case WorkOrdersInputActionTypes.UPDATE_TASKS_COMMON_FIELD:
      return updateTasksCommonField(state, action);
    case WorkOrdersInputActionTypes.UPDATE_TASKS_FIELD:
      return updateTasksField(state, action);
    default: {
      return state;
    }
  }
};

export const getTaskCommonField = (
  workOrdersInputCart: WorkOrdersInputState,
  fieldId?: string,
): any => {
  return (
    R.find(R.propEq('id', fieldId))(
      R.pathOr([], ['tasks', 'commonFields'], workOrdersInputCart),
    ) || {}
  );
};

export const getTaskField = (
  workOrdersInputCart: WorkOrdersInputState,
  fieldId?: string,
  taskId?: string,
): any => {
  const taskIndex = R.findIndex(R.propEq('id', taskId))(
    R.pathOr([], ['tasks', 'each'], workOrdersInputCart),
  );

  if (taskIndex < 0) {
    return {};
  }

  const fieldIndex = R.findIndex(R.propEq('id', fieldId))(
    R.view(
      R.lensPath(['tasks', 'each', taskIndex, 'fields']),
      workOrdersInputCart,
    ) || [],
  );

  if (fieldIndex < 0) {
    return {};
  }

  const field = R.view(
    R.lensPath(['tasks', 'each', taskIndex, 'fields', fieldIndex]),
    workOrdersInputCart,
  );

  return field || {};
};
