import React from 'react';

import { WorkOrderAssetTreeType } from '@atom/types/work';
import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';

import '../workOrderDetailPdfReport.css';

interface Props {
  workOrderAsset: WorkOrderAssetTreeType;
  header: string;
}

const AttributesTable = ({ workOrderAsset, header }: Props) => {
  const renderAttributeRow = () => {
    const { attributeGroups, attributes } = workOrderAsset;
    const group = attributeGroups.filter(
      attributeGroup => attributeGroup.name === header,
    );
    const attributeGroupIds = group[0]?.attributes ? group[0]?.attributes : [];
    return attributeGroupIds.map(key => {
      if (attributes[key]) {
        return (
          <tr styleName="textbox-table-row">
            <td styleName="textbox-table-row-title" style={{ width: '16rem' }}>
              {attributes[key].name}:
            </td>
            <td styleName="value">
              {attributeDisplayUtility.display(
                attributes[key].value,
                attributes[key].dataType,
                attributes[key].unit,
                !!attributes[key].changeId,
                attributes[key].newValue,
              )}
            </td>
          </tr>
        );
      }
      return <></>;
    });
  };

  return (
    <table styleName="collapse-border">
      <thead>
        <tr>
          <th styleName="textbox-table-title" colSpan={2}>
            {header}
          </th>
        </tr>
      </thead>
      <tbody styleName="content">{renderAttributeRow()}</tbody>
    </table>
  );
};

export default AttributesTable;
