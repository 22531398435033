import { createContext, Dispatch, SetStateAction } from 'react';

import { Location } from '@atom/hooks/useCurrentLocation';
import {
  AssetCreateAttributesInput,
  InventoryCategoryTree,
} from '@atom/types/inventory';
import { Schema } from '@atom/types/schema';

export enum ModalStep {
  TYPE = 'TYPE',
  FOLDER = 'FOLDER',
  LOCATION = 'LOCATION',
  COST = 'COST',
  ATTRIBUTES = 'ATTRIBUTES',
  NAME = 'NAME',
}

export interface ModalStepType {
  title: string;
  value: ModalStep;
}

interface Context {
  activeStep: ModalStep;
  setActiveStep: Dispatch<SetStateAction<ModalStep>>;
  STEPS_ARRAY: ModalStepType[];
  collapsedGroups: Set<String>;
  setCollapsedGroups: Dispatch<SetStateAction<Set<String>>>;
  schema: Partial<Schema>;
  setSchema: Dispatch<SetStateAction<Schema>>;
  category: Partial<InventoryCategoryTree>;
  setCategory: Dispatch<SetStateAction<InventoryCategoryTree>>;
  location: Partial<Location>;
  setLocation: Dispatch<SetStateAction<Location>>;
  address: string;
  setAddress: Dispatch<SetStateAction<string>>;
  rate: number;
  setRate: Dispatch<SetStateAction<number>>;
  unit: string;
  setUnit: Dispatch<SetStateAction<string>>;
  isStockBased: boolean;
  setIsStockBased: Dispatch<SetStateAction<boolean>>;
  isStartEndReading: boolean;
  setIsStartEndReading: Dispatch<SetStateAction<boolean>>;
  isStartEndCalculated: boolean;
  setIsStartEndCalculated: Dispatch<SetStateAction<boolean>>;
  quantityOnHand: number;
  setQuantityOnHand: Dispatch<SetStateAction<number>>;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  attributes: AssetCreateAttributesInput;
  setAttributes: Dispatch<AssetCreateAttributesInput>;
  errorAttributeIds: Set<string>;
  setErrorAttributeIds: Dispatch<Set<string>>;
  openCategories: Set<string>;
  setOpenCategories: Dispatch<Set<string>>;
  locationFromSearch: boolean;
  setLocationFromSearch: Dispatch<SetStateAction<boolean>>;
}

export const initialState: Context = {
  activeStep: ModalStep.TYPE,
  setActiveStep: () => {},
  STEPS_ARRAY: [],
  collapsedGroups: new Set([]),
  setCollapsedGroups: () => {},
  schema: {},
  setSchema: () => {},
  category: {},
  setCategory: () => {},
  location: {},
  setLocation: () => {},
  address: '',
  setAddress: () => {},
  rate: null,
  setRate: () => {},
  unit: '',
  setUnit: () => {},
  isStockBased: false,
  setIsStockBased: () => {},
  isStartEndReading: false,
  setIsStartEndReading: () => {},
  isStartEndCalculated: false,
  setIsStartEndCalculated: () => {},
  quantityOnHand: 0,
  setQuantityOnHand: () => {},
  name: '',
  setName: () => {},
  attributes: {},
  setAttributes: () => {},
  errorAttributeIds: new Set([]),
  setErrorAttributeIds: () => {},
  openCategories: new Set([]),
  setOpenCategories: () => {},
  locationFromSearch: false,
  setLocationFromSearch: () => {},
};

const CreateAssetModalContext = createContext<Context>(initialState);

export default CreateAssetModalContext;
