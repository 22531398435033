import colors from '@atom/styles/colors';

export const textStyle = {
  title: {
    color: colors.neutral.dark,
  },
  input: {
    color: colors.neutral.black,
    whiteSpace: 'normal',
    padding: '0.25em 0',
  },
  selected: {
    color: colors.brand.blue,
    whiteSpace: 'normal',
    padding: '0.25em 0',
  },
  textarea: {
    color: colors.neutral.black,
  },
  inputText: { marginTop: '-0.4rem' },
};

export const filterStyle = {
  datePicker: {
    marginRight: '1.25rem',
  },
  underlineStyle: {
    borderColor: colors.neutral.silver,
  },
  floatingLabelStyle: {
    color: colors.neutral.gray,
  },
  textFieldStyle: {
    color: colors.neutral.dark,
  },
  selectFieldStyle: {
    input: {
      color: colors.neutral.black,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    selected: {
      color: colors.brand.blue,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    iconStyle: {
      fill: colors.neutral.dim,
    },
  },
  applyButtonStyle: {
    marginTop: '0.5rem',
  },
  resetButtonStyle: {
    marginTop: '0.5rem',
    marginLeft: '2rem',
  },
};

export default {
  filterStyle,
  textStyle,
};
