import React from 'react';
import * as R from 'ramda';
import { StringParam, useQueryParams } from 'use-query-params';

import AssetsList from '@atom/components/mapPortal/drawerContent/AssetsList';

import { useListView } from '../hooks/listViewHook';
import { initialState } from '../hooks/mapDataHook';

const AssetsListWrapper = () => {
  const [listView = initialState] = useListView();
  const [queryParams, setQueryParams] = useQueryParams({
    id: StringParam,
    view: StringParam,
  });

  const assets = R.pipe(
    R.propOr([], 'assets'),
    // @ts-ignore
    R.filter(R.propSatisfies(R.includes(R.__, queryParams.id), 'id')),
    // @ts-ignore
  )(listView);

  const onClick = id => {
    setQueryParams({ id, view: 'asset' });
  };
  // @ts-ignore
  return <AssetsList assets={assets} navigateToAsset={onClick} />;
};

export default AssetsListWrapper;
