import React, { useContext, useState } from 'react';

import LocationsAndAssetsContext, {
  ProceedAction,
} from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsContext';
import RequiredFieldsConfirmModal from '@atom/components/common/workOrderDetail/requiredFieldsConfirmModal/RequiredFieldsConfirmModal';
import { Icon, IconButton, Progress } from '@atom/mui';
import { TaskLocation } from '@atom/types/taskLocation';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { pluralize } from '@atom/utilities/stringUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import TaskAssetRow from '../TaskAssetRow';

import LocationRow from './LocationRow';

import './taskMapModal.css';

interface Props {
  setHoverId: (hoverId: string) => void;
  selectedAssetId: string;
  setSelectedAssetId: (id: string) => void;
  handleAddLocationClickFromMap: () => void;
  handleEditLocationClickFromMap: (location: TaskLocation) => void;
  handleAddAssetClickFromMap: () => void;
}

const TaskMapSidebar = ({
  setHoverId,
  selectedAssetId,
  setSelectedAssetId,
  handleAddLocationClickFromMap,
  handleEditLocationClickFromMap,
  handleAddAssetClickFromMap,
}: Props) => {
  const {
    workOrderDetail,
    task,
    locations,
    assets,
    refetch,
    refetchLoading,
    dataCySuffix,
  } = useContext(LocationsAndAssetsContext);

  const [expandedLocation, setExpandedLocation] = useState<boolean>(true);
  const [expandedAsset, setExpandedAsset] = useState<boolean>(true);
  const [proceedAction, setProceedAction] = useState<ProceedAction>();

  const [openFieldsConfirm, setOpenFieldsConfirm] = useState<boolean>(false);

  const handleLocationAddClick = () => {
    if (task.isCompleted && task?.requireAtLeastOneLocation) {
      setOpenFieldsConfirm(true);
      setProceedAction(ProceedAction.ADD_LOCATION);
    } else {
      handleAddLocationClickFromMap();
    }
  };

  const handleAssetAddClick = () => {
    if (
      task.isCompleted &&
      (task?.requireAtLeastOneMultiAsset ||
        !isNilOrEmpty(task?.requiredMultiAssetSchemaIds))
    ) {
      setOpenFieldsConfirm(true);
      setProceedAction(ProceedAction.ADD_ASSET);
    } else {
      handleAddAssetClickFromMap();
    }
  };

  const onProceedComplete = async () => {
    await refetch();

    setOpenFieldsConfirm(false);

    if (proceedAction === ProceedAction.ADD_LOCATION) {
      handleAddLocationClickFromMap();
    } else {
      handleAddAssetClickFromMap();
    }

    setProceedAction(null);
  };

  const locationIcon = expandedLocation ? 'expand_less' : 'expand_more';
  const assetIcon = expandedAsset ? 'expand_less' : 'expand_more';

  const showLocationSection = task?.locationsEnabled;
  const locationCount = locations?.length || 0;
  const locationHeaderText = `${locationCount} ${pluralize(
    locationCount,
    'Location',
  )}`;
  const isLocationAddDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);
  const showLocationAddButton = !isLocationAddDisabled;

  const showAssetSection = task?.multiAssetsEnabled;
  const assetCount = assets?.length || 0;
  const assetHeaderText = `${assetCount} ${pluralize(assetCount, 'Asset')}`;
  const isAssetAddDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);
  const showAssetAssetButton = !isAssetAddDisabled;

  return (
    <>
      <div styleName="sidebar-container">
        {showLocationSection && (
          <div styleName="sidebar-section">
            <div styleName="section-header">
              <div>{locationHeaderText}</div>
              <div styleName="header-action-buttons">
                {refetchLoading && <Progress size={25} thickness={2} />}
                {showLocationAddButton && (
                  <IconButton
                    edge="end"
                    tooltip="Add Location"
                    onClick={handleLocationAddClick}
                    disabled={isLocationAddDisabled}
                    data-cy={`taskMapLocationAdd_${dataCySuffix}`}
                  >
                    <Icon>add</Icon>
                  </IconButton>
                )}
                <IconButton
                  edge="end"
                  onClick={() => setExpandedLocation(!expandedLocation)}
                >
                  <Icon>{locationIcon}</Icon>
                </IconButton>
              </div>
            </div>
            {expandedLocation && (
              <div>
                {locations.map(location => (
                  <LocationRow
                    key={location.id}
                    location={location}
                    setHoverId={setHoverId}
                    handleEditLocationClickFromMap={
                      handleEditLocationClickFromMap
                    }
                  />
                ))}
              </div>
            )}
          </div>
        )}
        {showAssetSection && (
          <div styleName="sidebar-section">
            <div styleName="section-header">
              <div>{assetHeaderText}</div>
              <div styleName="header-action-buttons">
                {refetchLoading && <Progress size={25} thickness={2} />}
                {showAssetAssetButton && (
                  <IconButton
                    edge="end"
                    tooltip="Add Asset"
                    onClick={handleAssetAddClick}
                    disabled={isAssetAddDisabled}
                    data-cy={`taskMapAssetAdd_${dataCySuffix}`}
                  >
                    <Icon>add</Icon>
                  </IconButton>
                )}
                <IconButton
                  edge="end"
                  onClick={() => setExpandedAsset(!expandedAsset)}
                >
                  <Icon>{assetIcon}</Icon>
                </IconButton>
              </div>
            </div>
            {expandedAsset && (
              <div>
                {assets.map(asset => (
                  <TaskAssetRow
                    key={asset.id}
                    asset={asset}
                    selectedAssetId={selectedAssetId}
                    setSelectedAssetId={setSelectedAssetId}
                    setHoverId={setHoverId}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      <RequiredFieldsConfirmModal
        open={openFieldsConfirm}
        onCancel={() => setOpenFieldsConfirm(false)}
        onComplete={onProceedComplete}
        task={task}
        workOrderId={workOrderDetail?.id}
      />
    </>
  );
};

export default TaskMapSidebar;
