import React, { useContext } from 'react';

import FontIconButton from '@atom/components/common/FontIconButton';
import colors from '@atom/styles/colors';
import { FormFieldType } from '@atom/types/form';
import { InventorySchemaTreeType } from '@atom/types/inventory';

import FormBuilderContext from './FormBuilderContext';
import Toolbar from './Toolbar';

import './formBuilder.css';

const styles = {
  icon: {
    padding: '0px',
    height: '40px',
    width: '40px',
  },
  fillerStyles: {
    position: 'relative',
  },
};

export interface Props {
  inventorySchemaTree: InventorySchemaTreeType;
  loadingInventorySchemaTree: boolean;
  onToggle: (data: any[], boolean: boolean) => void;
  toggleDrawer: () => void;
  addField: (type: string) => void;
  addFormModule: (
    type: string,
    inspectionId?: string,
    schemaId?: string,
  ) => void;
  addAssetField: (assetField: FormFieldType, schemaId: string) => void;
}

const ToolbarBase = ({
  toggleDrawer,
  addField,
  inventorySchemaTree,
  loadingInventorySchemaTree,
  onToggle,
  addAssetField,
  addFormModule,
}: Props) => {
  const { isDrawerOpen } = useContext(FormBuilderContext);

  return (
    <div styleName="toolbar-container">
      {!isDrawerOpen ? (
        <div styleName="toolbar-section">
          <FontIconButton
            style={styles.icon}
            onClick={toggleDrawer}
            tooltip="Tabs"
            color={colors.neutral.gray}
            icon="view_list"
          />
        </div>
      ) : (
        <div style={styles.fillerStyles} />
      )}
      <Toolbar
        addField={addField}
        inventorySchemaTree={inventorySchemaTree}
        loadingInventorySchemaTree={loadingInventorySchemaTree}
        onToggle={onToggle}
        addAssetField={addAssetField}
        addFormModule={addFormModule}
      />
    </div>
  );
};

export default ToolbarBase;
