import React from 'react';

import './schedule.css';

const ScheduleSideBar = () => {
  return (
    <div styleName="pane left">
      <div styleName="sub-nav-header">Schedule</div>
    </div>
  );
};

export default ScheduleSideBar;
