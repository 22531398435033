import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import UserGroupContext from '@atom/components/userGroupDetail/UserGroupContext';
import { UPDATE_USER_GROUP } from '@atom/graph/userGroup';
import { usePreferences } from '@atom/hooks/usePreferences';
import {
  Button,
  Icon,
  IconButton,
  Progress,
  Snackbar,
  TextField,
} from '@atom/mui';
import {
  TimeEntryApprover,
  UserGroup,
  UserGroupUpdateInput,
} from '@atom/types/userGroups';
import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { getUserFullName } from '@atom/utilities/userUtilities';

import TimeApproversModal from './TimeApproversModal';
import TimeEntryApproverEdit from './TimeEntryApproverEdit';

import './userGroupInfo.css';

const getTimeApproversDisplayText = (
  timeEntryApprovers: TimeEntryApprover[],
): string => {
  return timeEntryApprovers
    .map((timeEntryApprover: TimeEntryApprover) =>
      getUserFullName(timeEntryApprover),
    )
    .join(', ');
};

const DetailsSection = () => {
  const preferences = usePreferences();
  const { userGroup } = useContext(UserGroupContext);

  const [open, setOpen] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [baseLocation, setBaseLocation] = useState<string>(
    userGroup.baseLocation,
  );
  const [organization, setOrganization] = useState<string>(
    userGroup.organization,
  );
  const [appropriation, setAppropriation] = useState<string>(
    userGroup.appropriation,
  );
  const [timeEntryApprovers, setTimeEntryApprovers] = useState<
    TimeEntryApprover[]
  >(userGroup.timeEntryApprovers);
  const [removeApproversFromGroup, setRemoveApproversFromGroup] = useState<
    boolean
  >(false);

  const [updateUserGroup, { loading }] = useMutation<
    { userGroupUpdate: UserGroup },
    { input: UserGroupUpdateInput }
  >(UPDATE_USER_GROUP);

  useEffect(() => {
    setTimeEntryApprovers(userGroup.timeEntryApprovers);
  }, [userGroup.timeEntryApprovers]);

  const confirm = async () => {
    try {
      await updateUserGroup({
        variables: {
          input: {
            id: userGroup.id,
            baseLocation,
            organization,
            appropriation,
            timeEntryApproverIds: timeEntryApprovers.map(
              (timeEntryApprover: TimeEntryApprover) => timeEntryApprover.id,
            ),
            removeApproversFromGroup,
          },
        },
      });

      setEditMode(false);
      setOpen(false);
    } catch (error) {
      if (error?.networkError?.statusCode === 422) {
        Snackbar.error({
          message:
            'The user has unapproved time entries for the group you wish to remove',
        });
      }

      setEditMode(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    if (!editMode && !open) {
      setBaseLocation(userGroup.baseLocation);
      setOrganization(userGroup.organization);
      setAppropriation(userGroup.appropriation);
      setTimeEntryApprovers(userGroup.timeEntryApprovers);
      setRemoveApproversFromGroup(false);
    }
  }, [editMode, open]);

  const onCancel = () => {
    setOpen(false);
    setRemoveApproversFromGroup(false);
  };

  const onSaveClick = () => {
    const oldApproverIds = userGroup.timeEntryApprovers
      .map(approver => approver.id)
      .sort();

    const newApproverIds = timeEntryApprovers
      .map(approver => approver.id)
      .sort();

    if (R.equals(oldApproverIds, newApproverIds)) {
      return confirm();
    }

    return setOpen(true);
  };

  const rightButtons = editMode ? (
    <div>
      <Button onClick={onCancel} disabled={loading}>
        Cancel
      </Button>
      <Button color="primary" onClick={onSaveClick} disabled={loading}>
        Save
      </Button>
      {loading && (
        <div styleName="spinner-container">
          <Progress size={25} thickness={2} />
        </div>
      )}
    </div>
  ) : hasRolePermissions(ROLE_SETS.ORG_ADMIN) ? (
    <IconButton onClick={() => setEditMode(true)}>
      <Icon>edit</Icon>
    </IconButton>
  ) : (
    <div />
  );

  return (
    <>
      <div styleName="title-container">
        <div>Details</div>
        {rightButtons}
      </div>
      <div styleName="content-container">
        {preferences?.timeTracking?.timesheet && (
          <div styleName="list-row">
            <div styleName="label edit">Time Approver:</div>
            <div styleName="value">
              {editMode ? (
                <TimeEntryApproverEdit
                  timeEntryApprovers={timeEntryApprovers}
                  setTimeEntryApprovers={setTimeEntryApprovers}
                />
              ) : (
                getTimeApproversDisplayText(timeEntryApprovers)
              )}
            </div>
          </div>
        )}
        <div styleName="list-row">
          <div styleName="label edit">Created Date:</div>
          <div styleName="value">
            {attributeDisplayUtility.display(userGroup.createdDate, 'date')}
          </div>
        </div>
        <div styleName="list-row">
          <div styleName="label edit">Created By:</div>
          <div styleName="value">{`${R.pathOr(
            '',
            ['createdBy', 'firstName'],
            userGroup,
          )} ${R.pathOr('', ['createdBy', 'lastName'], userGroup)}`}</div>
        </div>
        <div styleName="list-row">
          <div styleName="label edit">Base Location:</div>
          <div styleName="value">
            {editMode ? (
              <TextField
                value={baseLocation}
                onChange={event => setBaseLocation(event.target.value)}
              />
            ) : (
              userGroup.baseLocation
            )}
          </div>
        </div>
        <div styleName="list-row">
          <div styleName="label edit">Organization:</div>
          <div styleName="value">
            {editMode ? (
              <TextField
                value={organization}
                onChange={event => setOrganization(event.target.value)}
              />
            ) : (
              userGroup.organization
            )}
          </div>
        </div>
        <div styleName="list-row">
          <div styleName="label edit">Appropriation:</div>
          <div styleName="value">
            {editMode ? (
              <TextField
                value={appropriation}
                onChange={event => setAppropriation(event.target.value)}
              />
            ) : (
              userGroup.appropriation
            )}
          </div>
        </div>
      </div>
      <TimeApproversModal
        open={open}
        loading={loading}
        removeApproversFromGroup={removeApproversFromGroup}
        setRemoveApproversFromGroup={setRemoveApproversFromGroup}
        onClose={onCancel}
        onConfirm={confirm}
      />
    </>
  );
};

export default DetailsSection;
