import React from 'react';

import { Chip, Icon } from '@atom/mui';
import colors from '@atom/styles/colors';

import './tab.css';

export interface Tab {
  icon?: string;
  label: string;
  value: any;
  showCount?: boolean;
}

interface Props {
  activeView: any;
  setActiveView: (view: any) => void;
  count?: number;
  tab: Tab;
}

const styles = {
  chip: {
    marginTop: '5px',
    marginRight: '5px',
    backgroundColor: colors.utility.highlight,
    borderRadius: '20px',
  },
  icon: {
    marginRight: '0.25rem',
  },
};

const ChipTab = ({ activeView, setActiveView, count, tab }: Props) => {
  const isActive = activeView === tab.value;
  const styleName = `tab ${isActive ? 'active' : ''}`;
  const iconColor = isActive ? colors.brand.blue : colors.neutral.gray;
  const label = (
    <div styleName={styleName}>
      {tab.icon && (
        <Icon color={iconColor} style={styles.icon}>
          {tab.icon}
        </Icon>
      )}
      {tab.label}
      {tab.showCount && count > 0 && <div styleName="tab-count">{count}</div>}
    </div>
  );
  const chipStyle = {
    background: isActive
      ? colors.utility.lightBlueHighlight
      : colors.utility.highlight,
    ...styles.chip,
  };

  return (
    <Chip
      color={isActive ? 'primary' : 'default'}
      key={tab.value}
      label={label}
      onClick={() => setActiveView(tab.value)}
      style={chipStyle}
    />
  );
};

export default ChipTab;
