import { useEffect, useState } from 'react';

export const useScrollPos = () => {
  const [scrollPos, setScrollPos] = useState(0);

  const checkScroll = () => {
    setScrollPos(document.body.scrollTop || document.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScroll);
    return () => window.removeEventListener('scroll', checkScroll);
  }, []);

  return scrollPos;
};
