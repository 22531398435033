import React from 'react';
import { Link } from 'react-router-dom';
import * as R from 'ramda';

import { Avatar, Icon, List, Tooltip } from '@atom/mui';
import { DashboardCard, RecentlyUpdatedItem } from '@atom/types/dashboard';
import { UserDetail } from '@atom/types/user';
import { getWorkIconByStatusId } from '@atom/utilities/iconUtilities';
import schemaUtilities from '@atom/utilities/schemaUtilities';
import { getUserFullName } from '@atom/utilities/userUtilities';

import CardEmpty from '../commonCardStates/CardEmpty';
import { getCardHeight } from '../dashboardUtilities';

import '../dashboardCard.css';

const { ListItemText, ListItem } = List;

interface Props {
  card: DashboardCard;
  cardData: { recentlyUpdatedItems: RecentlyUpdatedItem[] };
}

const ICON_SIZE = 24;

const styles = {
  avatar: {
    height: ICON_SIZE,
    width: ICON_SIZE,
  },
  listItem: {
    gap: '1rem',
    paddingTop: '1px',
    paddingBottom: '1px',
  },
  list: {
    paddingTop: '6px',
  },
  textNoWrap: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};

const getUserAvatar = (user: UserDetail): React.ReactNode => (
  <div style={{ position: 'relative' }}>
    <Tooltip title={getUserFullName(user)} placement="bottom">
      <span>
        <Avatar
          style={styles.avatar}
          src={user.photoUrl}
          alt={user.firstName}
        />
      </span>
    </Tooltip>
  </div>
);

const assetIconParams = {
  imgOffset: '-5px',
  iconSize: `${ICON_SIZE}px`,
  marginTop: '0',
};

const getItemValues = (
  item: RecentlyUpdatedItem,
): {
  to: string;
  icon: React.ReactNode;
  listItemText: React.ReactNode;
} => {
  if (!R.isEmpty(item.assetId)) {
    return {
      to: `/inventory/${item.assetId}`,
      icon: schemaUtilities.getSchemaIconFromSchemaOrAsset(
        item,
        assetIconParams,
      ),
      listItemText: (
        <ListItemText
          primary={item.assetName}
          primaryTextStyle={styles.textNoWrap}
          primaryTypographyProps={{ style: { whiteSpace: 'nowrap' } }}
          secondary={item.schemaName}
          secondaryTextStyle={styles.textNoWrap}
        />
      ),
    };
  }
  if (!R.isEmpty(item.userId)) {
    return {
      to: `/team/${item.userId}`,
      icon: getUserAvatar(item as UserDetail),
      listItemText: (
        <ListItemText
          primary={getUserFullName(item)}
          primaryTextStyle={styles.textNoWrap}
          primaryTypographyProps={{ style: { whiteSpace: 'nowrap' } }}
          secondary={item.email}
          secondaryTextStyle={styles.textNoWrap}
        />
      ),
    };
  }
  if (!R.isEmpty(item.workOrderId)) {
    return {
      to: `/workOrders/${item.workOrderId}`,
      icon: getWorkIconByStatusId(item.statusId),
      listItemText: (
        <ListItemText
          primary={item.workOrderName}
          primaryTextStyle={styles.textNoWrap}
          primaryTypographyProps={{ style: { whiteSpace: 'nowrap' } }}
          secondary={item.workTemplateName}
          secondaryTextStyle={styles.textNoWrap}
        />
      ),
    };
  }
  return {
    to: '/dashboard',
    icon: <Icon />,
    listItemText: '',
  };
};

const CardRecentlyUpdated = ({ card, cardData }: Props) => {
  const { recentlyUpdatedItems: items } = cardData;

  return R.isEmpty(items) ? (
    <CardEmpty card={card} />
  ) : (
    <div style={{ height: getCardHeight(card) }} styleName="card-list">
      <List style={styles.list}>
        {items.slice(0, 15).map((item, idx) => {
          const { to, icon, listItemText } = getItemValues(item);
          return (
            <Link to={to} target="_blank">
              <ListItem style={styles.listItem} key={idx}>
                {icon}
                {listItemText}
              </ListItem>
            </Link>
          );
        })}
      </List>
    </div>
  );
};

export default CardRecentlyUpdated;
