import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { TaskFieldUpdatePayload } from 'client/types/task';
import { WorkOrderTemplateTaskFieldUpdatePayload } from 'client/types/workTemplate';
import { debounce } from 'lodash';
import * as R from 'ramda';

import CustomFieldEnumMultipleEdit from '@atom/components/common/customFieldEnumEdit/customFieldEnumMultipleEdit';
import CustomFieldEnumNestedEdit from '@atom/components/common/customFieldEnumEdit/CustomFieldEnumNestedEdit';
import CustomFieldEnumRootEdit from '@atom/components/common/customFieldEnumEdit/CustomFieldEnumRootEdit';
import {
  Checkbox,
  Icon,
  IconButton,
  Modal,
  Select,
  TextField,
} from '@atom/mui';
import { customFieldUnitsSelector } from '@atom/selectors/preferencesSelectors';
import { DataType } from '@atom/types/dataType';
import {
  TASK_FIELD_DATA_TYPE_OPTIONS,
  TaskDropdownField,
  TaskField,
} from '@atom/types/task';
import { SubField, ValueDictionary } from '@atom/types/work';
import {
  isNilOrEmpty,
  isValidUrlWithMessage,
  MAX_HYPERLINK_CHARS,
  VALIDATE_HYPERLINK_DEBOUNCE_MS,
} from '@atom/utilities/validationUtilities';
import {
  getActiveSubFieldPathLabel,
  getNestedDepth,
  getPath,
  hydrateDropdownField,
  mapDropdownField,
  removeSubFieldByMatchValue,
  updateSubFields,
} from '@atom/utilities/workOrderFieldUtilities';

import './taskFields.css';

const { MenuItem } = Select;

const enum ModalView {
  ROOT = 'ROOT',
  NESTED = 'NESTED',
}

const styles = {
  inputContainer: {
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  inputContainerInline: {
    display: 'inline-block',
    width: '48%',
    marginTop: '1rem',
    marginRight: '1rem',
  },
  fieldTypeSelect: {
    width: '65%',
  },
  unitSelect: {
    display: 'inline-block',
    width: '48%',
    marginTop: '1rem',
  },
  contentStyle: {
    padding: '3rem',
    overflow: 'auto',
  },
  upper: {
    textTransform: 'uppercase',
  },
};

const useClasses = makeStyles({
  upper: {
    textTransform: 'uppercase',
  },
});

interface Props {
  field: TaskField | null;
  open: boolean;
  handleUpdate: (
    payload: TaskFieldUpdatePayload | WorkOrderTemplateTaskFieldUpdatePayload,
  ) => void;
  loadingUpdate: boolean;
  onClose: () => void;
  fields: TaskField[];
  isWorkTemplate?: boolean;
  errorText?: string;
}

const TaskFieldEditModal = ({
  field,
  fields,
  handleUpdate,
  loadingUpdate,
  open,
  onClose,
  isWorkTemplate = false,
  errorText = '',
}: Props) => {
  const unitTypes = useSelector(customFieldUnitsSelector);
  const classes = useClasses();

  const [view, setView] = useState<ModalView>(ModalView.ROOT);
  const [title, setTitle] = useState<string>(field?.title || '');
  const [value, setValue] = useState<string>('');
  const [hyperlink, setHyperlink] = useState<string>('');
  const [urlErrorMsg, setUrlErrorMsg] = useState<string>('');
  const [dropdownField, setDropdownField] = useState<TaskDropdownField>(
    field?.enumeration
      ? {
          enumeration: field.enumeration,
          subFields: field.subFields,
        }
      : null,
  );
  const [activeKey, setActiveKey] = useState<string>();
  const [activePath, setActivePath] = useState<any[]>([]);

  const [valueDictionary, setValueDictionary] = useState<ValueDictionary>();
  const [enumeration, setEnumeration] = useState<string[]>(
    field?.enumeration || [],
  );
  const [subFields, setSubFields] = useState<SubField[]>(
    field?.subFields || [],
  );
  const [units, setUnits] = useState<string>(field?.units || '');
  const [required, setRequired] = useState<boolean>(field?.required || false);

  useEffect(() => {
    if (open) {
      setTitle(field?.title || '');
      setValue(field?.value || '');
      setHyperlink(field?.hyperlink || '');
      setView(ModalView.ROOT);
      setUnits(field?.units || '');
      setRequired(field?.required || false);
      setActivePath([]);
      setActiveKey(null);

      const initialDropdownField = field?.enumeration
        ? {
            enumeration: field.enumeration,
            subFields: field.subFields,
          }
        : null;

      // Transforms field tree into mapped field tree with value
      // dictionary for ease of create and edit.
      const mappedField = mapDropdownField(initialDropdownField);

      setValueDictionary(mappedField.valueDictionary);
      setEnumeration(mappedField.enumeration);
      setSubFields(mappedField.subFields);
      setDropdownField(initialDropdownField);
    }
  }, [open]);

  // Applies value dictionary to mapped field tree to produce
  // the properly hydrated field tree.
  useEffect(() => {
    const hydratedField = hydrateDropdownField(
      { enumeration, subFields },
      valueDictionary,
      required,
    );

    setDropdownField(hydratedField);
  }, [enumeration, subFields, valueDictionary, required]);

  useEffect(() => {
    const newActivePath = getPath(activeKey, subFields);
    const newView =
      R.length(newActivePath) === 0 ? ModalView.ROOT : ModalView.NESTED;

    setActivePath(newActivePath);
    setView(newView);
  }, [activeKey]);

  const isNameInUse = useMemo(() => {
    return fields
      .filter(({ id }) => id !== field?.id)
      .some(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        field => field.title.toLowerCase() === title.toLowerCase(),
      )
      ? 'This name is already in use.'
      : '';
  }, [title, field, fields]);

  const isValid = useMemo(() => {
    const validEnums = dropdownField?.enumeration.reduce(
      (acc, option) => (!R.isEmpty(option.trim()) ? acc + 1 : acc),
      0,
    );

    const isNameValid = isWorkTemplate || !isNameInUse;
    const [isURLValid, urlError] = isValidUrlWithMessage(hyperlink);
    setUrlErrorMsg(urlError);
    const isHyperlinkValid =
      field?.dataType === DataType.HYPERLINK
        ? !R.isEmpty(hyperlink) &&
          isURLValid &&
          !R.isEmpty(value) &&
          value?.length <= MAX_HYPERLINK_CHARS
        : true;

    return (
      isNameValid &&
      isHyperlinkValid &&
      !R.isEmpty(title) &&
      (field?.dataType !== DataType.ENUM_SINGLE || validEnums >= 2)
    );
  }, [title, value, hyperlink, isNameInUse, dropdownField, isWorkTemplate]);

  const handleSubmit = () => {
    handleUpdate({
      fieldId: field.id,
      required,
      ...R.reject(isNilOrEmpty, {
        title,
        value,
        hyperlink,
        enumeration: dropdownField?.enumeration,
        subFields: dropdownField?.subFields,
      }),
      ...(field.dataType === DataType.NUMBER && {
        units,
      }),
    });
  };

  const debouncedSetHyperlink = useMemo(
    () =>
      debounce(event => {
        setHyperlink(event.target.value);
      }, VALIDATE_HYPERLINK_DEBOUNCE_MS),
    [],
  );

  const navigateBack = async () => {
    // drops the last 3 entries in the activePath in order
    // to access the parent nested field;
    const parentPath = R.dropLast(3, activePath);
    const parent: SubField = R.view(R.lensPath(parentPath), subFields);
    await setActiveKey(parent.matchValue);
  };

  const updateSubField = (subField: SubField) => {
    setSubFields(updateSubFields(subField, subFields));
  };

  const removeSubField = async (key: string) => {
    await navigateBack();
    setSubFields(removeSubFieldByMatchValue(subFields, key));
  };

  const activeSubField = R.view(R.lensPath(activePath), subFields);
  const nestedDepth = getNestedDepth(activeKey, subFields);
  const pathLabel = getActiveSubFieldPathLabel(
    title,
    activePath,
    subFields,
    valueDictionary,
  );

  const requiredLabel = isNilOrEmpty(subFields)
    ? 'Required'
    : 'Required (All cascading dropdowns)';

  const modalTitle =
    view === ModalView.ROOT ? (
      'Edit Field'
    ) : (
      <div styleName="modal-title">
        <IconButton onClick={navigateBack} size="small">
          <Icon>arrow_back</Icon>
        </IconButton>
        <div styleName="modal-title-path">{pathLabel}</div>
      </div>
    );

  const error = isWorkTemplate ? errorText : isNameInUse;

  const content = {
    [ModalView.ROOT]: (
      <>
        <Select
          value={field?.dataType}
          style={styles.fieldTypeSelect}
          label="Field Type"
          disabled
        >
          {TASK_FIELD_DATA_TYPE_OPTIONS.map(option => (
            <MenuItem key={option.dataType} value={option.dataType}>
              <div styleName="menu-item">
                <Icon style={{ marginRight: '0.5rem' }}>{option.icon}</Icon>
                {option.text}
              </div>
            </MenuItem>
          ))}
        </Select>
        <TextField
          id="title"
          label="Title"
          value={title}
          onChange={event => setTitle(event.target.value)}
          style={
            field?.dataType === DataType.NUMBER
              ? styles.inputContainerInline
              : styles.inputContainer
          }
          // ensure modal is open to prevent "flash" of error during close animation
          error={open && !!error}
          helperText={open ? error : ''}
          required
        />
        {field?.dataType === DataType.NUMBER && (
          <Select
            label="Unit"
            value={units}
            style={styles.unitSelect}
            classes={{ root: classes.upper }}
            onChange={event => setUnits(event.target.value)}
          >
            <MenuItem value="" />
            {unitTypes.map(type => (
              <MenuItem key={type.unit} value={type.unit} style={styles.upper}>
                {type.unit}
              </MenuItem>
            ))}
          </Select>
        )}
        {field?.dataType === DataType.ENUM_SINGLE && (
          <CustomFieldEnumRootEdit
            valueDictionary={valueDictionary}
            setValueDictionary={setValueDictionary}
            enumeration={enumeration}
            setEnumeration={setEnumeration}
            subFields={subFields}
            setSubFields={setSubFields}
            setActiveKey={setActiveKey}
          />
        )}
        {field?.dataType === DataType.ENUM_MULTIPLE && (
          <CustomFieldEnumMultipleEdit
            valueDictionary={valueDictionary}
            setValueDictionary={setValueDictionary}
            enumeration={enumeration}
            setEnumeration={setEnumeration}
          />
        )}
        {field?.dataType === DataType.HYPERLINK && (
          <>
            <TextField
              id="hyperlink"
              label="URL"
              defaultValue={hyperlink}
              onChange={debouncedSetHyperlink}
              style={styles.inputContainer}
              error={urlErrorMsg.length > 0}
              helperText={urlErrorMsg}
              required
            />
            <TextField
              id="text_to_display"
              label="Text to Display"
              value={value}
              onChange={event => setValue(event.target.value)}
              style={styles.inputContainer}
              helperText={`${value?.length}/${MAX_HYPERLINK_CHARS}`}
              error={value?.length > MAX_HYPERLINK_CHARS}
              FormHelperTextProps={{ style: { textAlign: 'right' } }}
              required
            />
          </>
        )}
        {field?.dataType !== DataType.HYPERLINK && (
          <div styleName="required-container">
            <Checkbox
              label={requiredLabel}
              checked={required}
              onClick={() => setRequired(!required)}
            />
          </div>
        )}
      </>
    ),
    [ModalView.NESTED]: (
      <CustomFieldEnumNestedEdit
        // @ts-ignore
        subField={activeSubField}
        updateSubField={updateSubField}
        valueDictionary={valueDictionary}
        setValueDictionary={setValueDictionary}
        removeSubField={removeSubField}
        setActiveKey={setActiveKey}
        nestedDepth={nestedDepth}
      />
    ),
  };

  return (
    <Modal
      title={modalTitle}
      open={open}
      onCancel={onClose}
      onConfirm={handleSubmit}
      confirmButtonText="Save"
      loading={loadingUpdate}
      disabled={!isValid}
      contentStyle={styles.contentStyle}
      disableFooter={view === ModalView.NESTED}
    >
      {content[view]}
    </Modal>
  );
};

export default TaskFieldEditModal;
