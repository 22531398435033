import React, { useState } from 'react';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import RenameModal from '@atom/components/common/RenameModal';
// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import InheritanceLockIcon from '@atom/components/workTemplate/InheritanceLockIcon';
import { Icon, Menu } from '@atom/mui';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';

import './workTemplatePhotos.css';

const MenuItem = Menu.MenuItem;

interface Props {
  media: any;
  renameMedia: (id: string, subjectId: string, name: string) => void;
  deleteMedia: (id: string) => void;
  isEditDisabled: boolean;
  loadingDelete: boolean;
  isItemInherited?: boolean;
}

const PhotoTile = ({
  media,
  renameMedia,
  deleteMedia,
  isEditDisabled,
  loadingDelete,
  isItemInherited = false,
}: Props) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [renameModalOpen, setRenameModalOpen] = useState<boolean>(false);

  const handleRename = (id: string, name: string) => {
    renameMedia(id, '', name);
  };

  const handleDelete = async (id: string) => {
    await deleteMedia(id);
    setDeleteModalOpen(false);
  };

  const imageStyle = {
    backgroundImage: `url('${FILES_ENDPOINT}/${media.fileId}_180')`,
  };

  const nameStyles = isItemInherited ? 'image-name disabled' : 'image-name';

  return (
    <>
      <div styleName="photo-tile">
        <div styleName="tile-image-container" style={imageStyle} />
        <div styleName="image-info-container">
          <div styleName="image-title-container">
            {isItemInherited && (
              <div styleName="lock-icon">
                <InheritanceLockIcon
                  tooltip="Inherited Photo cannot be removed or edited."
                  placement="bottom-start"
                />
              </div>
            )}
            <div
              styleName={nameStyles}
            >{`${media.name}.${media.fileExtension}`}</div>
            <Menu noMinWidth IconButtonProps={{ size: 'small' }}>
              {!isEditDisabled && !isItemInherited && (
                <MenuItem
                  onClick={() => setRenameModalOpen(true)}
                  startAdornment={<img src={renameIcon} />}
                >
                  Rename
                </MenuItem>
              )}
              <a
                href={`${FILES_ENDPOINT}/${media.fileId}`}
                download={`${media.name}.${media.fileExtension}`}
              >
                <MenuItem startAdornment={<Icon>file_download</Icon>}>
                  Download
                </MenuItem>
              </a>
              {!isEditDisabled && !isItemInherited && (
                <MenuItem
                  onClick={() => setDeleteModalOpen(true)}
                  startAdornment={<Icon>delete</Icon>}
                >
                  Delete
                </MenuItem>
              )}
            </Menu>
          </div>
          <div styleName="additional-info-container">
            <div>
              {!R.isEmpty(media.createdBy)
                ? textDisplayUtilities.displayName(media.createdBy)
                : ''}
            </div>
            <div>{`Uploaded on ${setDisplayDate(media.createdDate)}`}</div>
          </div>
        </div>
      </div>
      <RenameModal
        id={media.id}
        name={media.name}
        type={media.type}
        renameAction={handleRename}
        open={renameModalOpen}
        closeModal={() => setRenameModalOpen(false)}
      />
      <DeleteModal
        open={deleteModalOpen}
        onConfirm={() => handleDelete(media.id)}
        onCancel={() => setDeleteModalOpen(false)}
        title={`Delete ${textDisplayUtilities.capitalize(media.type)}`}
        content={`Are you sure you want to delete this ${media.type}?`}
        loading={loadingDelete}
      />
    </>
  );
};

export default PhotoTile;
