import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import { GET_USERS } from '@atom/graph/user';
import { ADD_USERS_TO_USER_GROUP } from '@atom/graph/userGroup';
import { Icon, Modal, Progress, Snackbar, TextField } from '@atom/mui';
import fonts from '@atom/styles/fonts';
import layout from '@atom/styles/layout';
import {
  UserDetail,
  UsersConnection,
  UsersConnectionInput,
} from '@atom/types/user';
import {
  UserGroupTreeType,
  UserGroupUsersAddInput,
} from '@atom/types/userGroups';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import TeamContext from '../TeamContext';

import AddUserRow from './AddUserRow';

import './teamControls.css';

const useStyles = makeStyles({
  root: {
    fontSize: fonts.lg,
    height: '3rem',
    paddingLeft: '1rem',
  },
  input: {
    paddingLeft: '1rem',
  },
});

const styles = {
  dialog: {
    bodyStyle: {
      margin: 0,
      padding: 0,
    },
    contentStyle: {
      width: layout.modalWidth,
      height: '100vh',
    },
    title: {
      fontSize: fonts.xl,
      padding: '1.125em',
      fontWeight: '500',
    },
    actionsContainerStyle: {
      paddingRight: '1rem',
      paddingBottom: '1rem',
    },
  },
  actionButtons: {
    cancel: { marginRight: '1rem' },
  },
  name: {
    textTransform: 'capitalize',
  },
  progress: {
    height: '100%',
  },
};

const DEBOUNCE_TIME = 500;
const LIMIT = 25;

interface Props {
  open: boolean;
  onClose: () => void;
  userGroup: UserGroupTreeType;
}

const AddUsersToGroupModal = ({ open, onClose, userGroup }: Props) => {
  const classes = useStyles();
  const ref = useRef(null);

  const [users, setUsers] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<Set<string>>(new Set([]));
  const [page, setPage] = useState(1);

  const { refetchTable } = useContext(TeamContext);

  const [getUsers, { loading, error }] = useLazyQuery<
    { users: UsersConnection },
    { input: UsersConnectionInput }
  >(GET_USERS, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const nextUsers = R.pathOr([], ['users', 'users'], data);

      const newUsersValue = !R.isEmpty(search)
        ? nextUsers
        : [...users, ...nextUsers];

      setUsers(newUsersValue);
      setTotal(data?.users?.totalCount || 0);
    },
  });

  const [addUsersToUserGroup, { loading: addUsersLoading }] = useMutation<
    { userGroupUsersAdd: { id: string } },
    { input: UserGroupUsersAddInput }
  >(ADD_USERS_TO_USER_GROUP);

  useEffect(() => {
    if (open) {
      getUsers({
        variables: {
          input: {
            showAdmin: hasRolePermissions(ROLE_SETS.ADMIN),
            showPending: true,
            limit: LIMIT,
            page,
            ...(search && { name: search }),
          },
        },
      });
    } else {
      setUsers([]);
      setSearch('');
      setSelectedUsers(new Set([]));
      setPage(1);
    }
  }, [open, page, search]);

  const handleScroll = useCallback(() => {
    const isBottom =
      ref.current.scrollTop + ref.current.clientHeight >=
      ref.current.scrollHeight;

    if (isBottom && users.length !== total && !loading) {
      setPage(currentPage => currentPage + 1);
    }
  }, [ref.current, total, page, users, loading]);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('scroll', handleScroll);
      return () => ref.current?.removeEventListener('scroll', handleScroll);
    }

    return () => {};
  }, [handleScroll]);

  const confirm = async () => {
    await addUsersToUserGroup({
      variables: { input: { id: userGroup.id, userIds: [...selectedUsers] } },
    });

    Snackbar.info({ message: `Added users to ${userGroup.name}` });

    refetchTable();

    onClose();
  };

  const toggleUser = (id: string) => {
    if (selectedUsers.has(id)) {
      selectedUsers.delete(id);
      setSelectedUsers(new Set([...selectedUsers]));
    } else {
      setSelectedUsers(new Set([...selectedUsers.add(id)]));
    }
  };

  const handleSearch = useCallback(
    debounce(value => {
      setSearch(value);
      setPage(1);
    }, DEBOUNCE_TIME),
    [],
  );

  const isAddDisabled = selectedUsers.size === 0;

  return (
    <Modal
      title={<div style={styles.name}>Add Users To {userGroup.name}</div>}
      confirmButtonText="Add"
      width="lg"
      onConfirm={confirm}
      onCancel={onClose}
      disabled={isAddDisabled}
      open={open}
    >
      <div styleName="users-modal-content">
        <div styleName="search-content">
          <div styleName="search-box">
            <TextField
              InputProps={{
                startAdornment: <Icon>search</Icon>,
                classes: {
                  root: classes.root,
                  input: classes.input,
                },
              }}
              placeholder="Search by name"
              name="search"
              onChange={event => handleSearch(event.target.value)}
            />
          </div>
        </div>
        <div styleName="users-list" ref={ref}>
          {addUsersLoading ? (
            <Progress style={styles.progress} />
          ) : (
            <>
              {users.map((user: UserDetail, index: number) => (
                <AddUserRow
                  key={`${user.id}${index}`}
                  user={user}
                  selectedUsers={selectedUsers}
                  toggleUser={toggleUser}
                  groupId={userGroup.id}
                />
              ))}
              {(loading || error) && <Progress />}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddUsersToGroupModal;
