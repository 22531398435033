import React, { useContext, useMemo } from 'react';
import { makeStyles } from '@mui/styles';

import WorkOrdersContext, {
  FilterSection,
  WorkOrdersInputActionTypes,
} from '@atom/components/workOrders/WorkOrdersContext';
import { useSchemas } from '@atom/hooks/useSchemas';
import { Icon, IconButton, Select } from '@atom/mui';
import colors from '@atom/styles/colors';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import WorkOrdersCategoryFilter from './WorkOrdersCategoryFilter';

import './workOrdersFilters.css';

const { MenuItem } = Select;

const styles = {
  icon: {
    marginRight: '1rem',
  },
};

const useClasses = makeStyles({
  label: {
    position: 'unset',
    color: `${colors.neutral.dim} !important`,
  },
});

const WorkOrdersFiltersInventorySection = () => {
  const classes = useClasses();

  const {
    collapsedSections,
    setCollapsedSections,
    workOrdersInputCart,
    filtersDisabled,
    dispatch,
  } = useContext(WorkOrdersContext);

  const { schemas: allSchemas = [], loading: loadingSchemas } = useSchemas();

  const onToggle = (section: FilterSection) => {
    setCollapsedSections(toggleFromSet(collapsedSections, section));
  };

  const handleChange = (event: any) => {
    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_INPUT_PROPERTY,
      data: {
        property: 'assetSchemaId',
        value: event.target.value,
      },
    });
  };

  const schemas = useMemo(() => {
    return allSchemas.filter(schema => !schema.isMaterial);
  }, [allSchemas]);

  return (
    <div styleName="filters-section">
      <div styleName="filters-section-header">
        <div>
          <Icon style={styles.icon}>category</Icon>
          Inventory
        </div>
        <IconButton
          onClick={() => onToggle(FilterSection.INVENTORY)}
          edge="end"
        >
          <Icon>
            {collapsedSections.has(FilterSection.INVENTORY)
              ? 'keyboard_arrow_down'
              : 'keyboard_arrow_up'}
          </Icon>
        </IconButton>
      </div>
      {!collapsedSections.has(FilterSection.INVENTORY) && (
        <div styleName="filters-section-content">
          <div styleName="filter-container">
            <Select
              displayEmpty
              label="Type"
              value={workOrdersInputCart?.assetSchemaId || ''}
              onChange={handleChange}
              renderValue={
                workOrdersInputCart?.assetSchemaId ? null : () => 'All'
              }
              disabled={loadingSchemas || filtersDisabled}
              loading={loadingSchemas}
              InputLabelProps={{ classes: { root: classes.label } }}
            >
              <MenuItem value="" />
              {schemas.map(schema => (
                <MenuItem key={schema.id} value={schema.id}>
                  {schema.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div styleName="filter-container">
            <WorkOrdersCategoryFilter />
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkOrdersFiltersInventorySection;
