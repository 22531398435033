import React, { useContext, useState } from 'react';

import BulkCoverPhotoModal from '@atom/components/common/bulkCoverPhotoModal/BulkCoverPhotoModal';
import { Icon, IconButton } from '@atom/mui';

import InventoryPortalContext from './InventoryPortalContext';

import './inventoryPortal.css';

const BulkSelectHeader = () => {
  const { selectedAssets, resetSelectedAssets } = useContext(
    InventoryPortalContext,
  );

  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    resetSelectedAssets();
  };

  const assetCount = selectedAssets.size;

  return (
    <>
      <div styleName="bulk-header-container">
        <div styleName="bulk-left-container">
          <IconButton onClick={resetSelectedAssets}>
            <Icon>close</Icon>
          </IconButton>
          <div>{`Selected ${assetCount}`}</div>
        </div>
        <IconButton onClick={() => setOpen(true)}>
          <Icon>add_photo_alternate</Icon>
        </IconButton>
      </div>
      <BulkCoverPhotoModal
        open={open}
        onClose={handleClose}
        ids={[...selectedAssets]}
      />
    </>
  );
};

export default BulkSelectHeader;
