import React from 'react';

import { WorkOrderAssetTreeType } from '@atom/types/work';
import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';

import './workOrderDetailPdfReport.css';

interface Props {
  workOrderAssetTree: WorkOrderAssetTreeType;
  header: string;
  labelWidth?: React.CSSProperties;
}

const AttributesTextbox = ({
  workOrderAssetTree,
  header,
  labelWidth,
}: Props) => {
  const renderAttributeRow = () => {
    const { attributeGroups, attributes } = workOrderAssetTree;
    const group = attributeGroups.filter(
      attributeGroup => attributeGroup.name === header,
    );
    const attributeGroupIds = group[0]?.attributes ? group[0]?.attributes : [];
    return attributeGroupIds.map(key => {
      if (attributes[key]) {
        return (
          <div styleName="row">
            <div styleName="value" style={labelWidth}>
              {attributes[key].name}:
            </div>
            <div styleName="value">
              {attributeDisplayUtility.display(
                attributes[key].value,
                attributes[key].dataType,
                attributes[key].unit,
                !!attributes[key].changeId,
                attributes[key].newValue,
              )}
            </div>
          </div>
        );
      }
      return <></>;
    });
  };

  return (
    <>
      <div styleName="header-title">{header}</div>
      <div styleName="content">{renderAttributeRow()}</div>
    </>
  );
};

export default AttributesTextbox;
