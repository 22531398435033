import React, { useContext, useMemo, useState } from 'react';
import * as R from 'ramda';

import LocationsAndAssetsContext from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsContext';
import RequiredFieldsConfirmModal from '@atom/components/common/workOrderDetail/requiredFieldsConfirmModal/RequiredFieldsConfirmModal';
import { TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  ALDOTLocationDataState,
  ALDOTLocationDataTitle,
  ALDOTLocationOptionsState,
} from '@atom/types/taskLocation';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import { isMilepostsValid } from '../taskLocationUtilities';

import TaskLocationSelectInput from './TaskLocationSelectInput';

import './taskLocationModal.css';

const styles = {
  leftInput: {
    marginRight: '0.5rem',
  },
  rightInput: {
    marginLeft: '0.5rem',
  },
};

interface Props {
  values: ALDOTLocationDataState;
  options: ALDOTLocationOptionsState;
  updateValue: (property: ALDOTLocationDataTitle, value: any) => void;
  loading: boolean;
  inputsLocked: boolean;
  rangeGapError: boolean;
  setRangeGapError: (rangeGapError: boolean) => void;
}

const TaskLocationAldotInputs = ({
  values,
  options,
  updateValue,
  loading,
  inputsLocked,
  rangeGapError,
  setRangeGapError,
}: Props) => {
  const { workOrderDetail, task, refetch, dataCySuffix } = useContext(
    LocationsAndAssetsContext,
  );

  const [openFieldsConfirm, setOpenFieldsConfirm] = useState<boolean>(false);

  const showRanges =
    !isNilOrEmpty(options[ALDOTLocationDataTitle.START_MILEPOST]) &&
    !isNilOrEmpty(options[ALDOTLocationDataTitle.END_MILEPOST]);

  const isRangeError = useMemo(() => {
    if (loading) {
      return false;
    }

    return !isMilepostsValid(options, values) || rangeGapError;
  }, [loading, options, values, rangeGapError]);

  const isMilepostDisabled = useMemo(() => {
    const managementUnitValue = values[ALDOTLocationDataTitle.MANAGEMENT_UNIT];
    const countyValue = values[ALDOTLocationDataTitle.COUNTY];
    const roadClassValue = values[ALDOTLocationDataTitle.ROAD_CLASS];
    const routeValue = values[ALDOTLocationDataTitle.ROUTE];
    const directionValue = values[ALDOTLocationDataTitle.DIRECTION];

    return (
      R.any(isNilOrEmpty)([
        managementUnitValue,
        countyValue,
        roadClassValue,
        routeValue,
        directionValue,
      ]) ||
      workOrderDetail?.isClosed ||
      task.isCompleted
    );
  }, [workOrderDetail, task, values, options]);

  const rangeSubtext = isRangeError
    ? `Provide a range from ${
        options[ALDOTLocationDataTitle.START_MILEPOST]
      } to ${
        options[ALDOTLocationDataTitle.END_MILEPOST]
      }. If the value is within the range but there is still an error, please visit the Knowledge Center for a route map.`
    : `Provide a range from ${
        options[ALDOTLocationDataTitle.START_MILEPOST]
      } to ${options[ALDOTLocationDataTitle.END_MILEPOST]}.`;

  const rangeSubtextStyles = {
    color: isRangeError ? colors.brand.red : colors.neutral.dim,
  };

  // onBlur of milepost input, auto fill the other milepost input if it is empty
  const autoFillMilepost = (currentInput: ALDOTLocationDataTitle) => {
    setRangeGapError(false);

    const startValue = values[ALDOTLocationDataTitle.START_MILEPOST];
    const endValue = values[ALDOTLocationDataTitle.END_MILEPOST];

    if (
      !isNilOrEmpty(startValue) &&
      isNilOrEmpty(endValue) &&
      currentInput === ALDOTLocationDataTitle.START_MILEPOST
    ) {
      updateValue(ALDOTLocationDataTitle.END_MILEPOST, startValue);
    } else if (
      !isNilOrEmpty(endValue) &&
      isNilOrEmpty(startValue) &&
      currentInput === ALDOTLocationDataTitle.END_MILEPOST
    ) {
      updateValue(ALDOTLocationDataTitle.START_MILEPOST, endValue);
    } else {
      return;
    }
  };

  const handleMilepostChange = (title: ALDOTLocationDataTitle, value: any) => {
    setRangeGapError(false);

    if (value === '') {
      updateValue(title, '');
      return;
    }

    updateValue(title, Number(value));
  };

  const onProceedComplete = async () => {
    await refetch();

    setOpenFieldsConfirm(false);
  };

  const onClick =
    task?.isCompleted && task?.requireAtLeastOneLocation
      ? () => setOpenFieldsConfirm(true)
      : () => {};

  const disableSharedInput =
    inputsLocked || workOrderDetail?.isClosed || task.isCompleted;
  const disableSelectInput = workOrderDetail?.isClosed || task.isCompleted;

  return (
    <>
      <div styleName="input-section" onClick={onClick}>
        <TaskLocationSelectInput
          property={ALDOTLocationDataTitle.MANAGEMENT_UNIT}
          options={options[ALDOTLocationDataTitle.MANAGEMENT_UNIT]}
          value={values[ALDOTLocationDataTitle.MANAGEMENT_UNIT]}
          updateValue={updateValue}
          loading={loading}
          disabled={disableSharedInput}
          onClick={onClick}
          dataCy={`locationManagementUnit_${dataCySuffix}`}
        />
        <div styleName="input-row">
          <TaskLocationSelectInput
            property={ALDOTLocationDataTitle.COUNTY}
            options={options[ALDOTLocationDataTitle.COUNTY]}
            value={values[ALDOTLocationDataTitle.COUNTY]}
            updateValue={updateValue}
            loading={loading}
            disabled={disableSharedInput}
            position="left"
            onClick={onClick}
            dataCy={`locationCounty_${dataCySuffix}`}
          />
          <TaskLocationSelectInput
            property={ALDOTLocationDataTitle.ROAD_CLASS}
            options={options[ALDOTLocationDataTitle.ROAD_CLASS]}
            value={values[ALDOTLocationDataTitle.ROAD_CLASS]}
            updateValue={updateValue}
            loading={loading}
            disabled={disableSharedInput}
            position="right"
            onClick={onClick}
            dataCy={`locationRoadClass_${dataCySuffix}`}
          />
        </div>
        <div styleName="input-row">
          <TaskLocationSelectInput
            property={ALDOTLocationDataTitle.ROUTE}
            options={options[ALDOTLocationDataTitle.ROUTE]}
            value={values[ALDOTLocationDataTitle.ROUTE]}
            updateValue={updateValue}
            loading={loading}
            disabled={disableSelectInput}
            position="left"
            onClick={onClick}
            dataCy={`locationRoute_${dataCySuffix}`}
          />
          <TaskLocationSelectInput
            property={ALDOTLocationDataTitle.DIRECTION}
            options={options[ALDOTLocationDataTitle.DIRECTION]}
            value={values[ALDOTLocationDataTitle.DIRECTION]}
            updateValue={updateValue}
            loading={loading}
            disabled={disableSelectInput}
            position="right"
            onClick={onClick}
            dataCy={`locationDirection_${dataCySuffix}`}
          />
        </div>
        <div styleName="input-row">
          <TextField
            type="number"
            value={values[ALDOTLocationDataTitle.START_MILEPOST]}
            label={`${ALDOTLocationDataTitle.START_MILEPOST} *`}
            onChange={event =>
              handleMilepostChange(
                ALDOTLocationDataTitle.START_MILEPOST,
                event.target.value,
              )
            }
            onBlur={() =>
              autoFillMilepost(ALDOTLocationDataTitle.START_MILEPOST)
            }
            style={styles.leftInput}
            error={isRangeError}
            disabled={isMilepostDisabled}
            onClick={onClick}
            data-cy={`locationStartMilepost_${dataCySuffix}`}
          />
          <TextField
            type="number"
            value={values[ALDOTLocationDataTitle.END_MILEPOST]}
            label={`${ALDOTLocationDataTitle.END_MILEPOST} *`}
            onChange={event =>
              handleMilepostChange(
                ALDOTLocationDataTitle.END_MILEPOST,
                event.target.value,
              )
            }
            onBlur={() => autoFillMilepost(ALDOTLocationDataTitle.END_MILEPOST)}
            style={styles.rightInput}
            error={isRangeError}
            disabled={isMilepostDisabled}
            onClick={onClick}
            data-cy={`locationEndMilepost_${dataCySuffix}`}
          />
        </div>
        {showRanges && (
          <div style={rangeSubtextStyles} styleName="range-subtext">
            {rangeSubtext}
          </div>
        )}
      </div>
      <RequiredFieldsConfirmModal
        open={openFieldsConfirm}
        onCancel={() => setOpenFieldsConfirm(false)}
        onComplete={onProceedComplete}
        task={task}
        workOrderId={workOrderDetail?.id}
      />
    </>
  );
};

export default TaskLocationAldotInputs;
