import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import * as R from 'ramda';

import {
  useGoogleMapsStateUpdater,
  useGoogleMapsStateValue,
  withGoogleMapsState,
} from '@atom/components/common/map/hooks/googleMapsStateHook';
import Map from '@atom/components/common/map/Map';
import MapMarkers from '@atom/components/common/map/MapMarkers';
import MapPinMarker from '@atom/components/common/map/markers/MapPinMarker';
import LocationsAndAssetsContext from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsContext';
import { GET_TASK_BOUNDING_BOX } from '@atom/graph/map';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Progress } from '@atom/mui';
import { getLoggedInUserMapLocationSelector } from '@atom/selectors/mapSelectors';
import {
  BoundingBox,
  BoundingBoxSubjectType,
  MapParams,
  TaskBoundingBoxInput,
} from '@atom/types/map';
import {
  getGeoJsonFromMapBounds,
  getMapCenterAndZoomParams,
  urlValueToLatLng,
} from '@atom/utilities/mapUtilities';

interface Props {
  hoverId: string;
}

const TaskMap = ({ hoverId = '' }: Props) => {
  const { workOrderDetail, task, assets, locations } = useContext(
    LocationsAndAssetsContext,
  );

  const currentUserLocation = useSelector(getLoggedInUserMapLocationSelector);

  const dispatch = useGoogleMapsStateUpdater();
  const { isLoaded } = useGoogleMapsStateValue();
  const preferences = usePreferences();

  const [map, setMap] = useState<google.maps.Map>(null);
  const [coordinates, setCoordinates] = useState<string>(null);
  const [loadedInitialBox, setLoadedInitialBox] = useState<boolean>(false);
  const [mapParams, setMapParams] = useState<MapParams>({
    ids: [],
    hoverId: null,
    isActive: false,
    center: null,
    zoom: null,
    searchPoint: null,
    searchTerm: null,
  });

  const [
    getTaskBoundingBox,
    { data: taskBoundingBoxData, loading: loadingTaskBoundingBox },
  ] = useLazyQuery<
    { taskBoundingBox: BoundingBox },
    { input: TaskBoundingBoxInput }
  >(GET_TASK_BOUNDING_BOX, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const boundingBox = data?.taskBoundingBox;

      if (map) {
        map.fitBounds({
          east: boundingBox?.xMax,
          north: boundingBox?.yMax,
          south: boundingBox?.yMin,
          west: boundingBox?.xMin,
        });
      }
    },
  });

  const boundingBox = taskBoundingBoxData?.taskBoundingBox;

  const retrieveTaskBoundingBox = async () => {
    getTaskBoundingBox({
      variables: {
        input: {
          taskId: task?.id,
          workOrderId: workOrderDetail?.id,
          subjectType: BoundingBoxSubjectType.ALL,
        },
      },
    });
  };

  useEffect(() => {
    retrieveTaskBoundingBox();
  }, [assets, locations]);

  const updateMapParams = (params: MapParams) => {
    setMapParams({ ...mapParams, ...params });
  };

  useEffect(() => {
    updateMapParams({ hoverId });
  }, [hoverId]);

  useEffect(() => {
    dispatch({
      type: 'SET',
      payload: {
        key: 'grab',
        data: false,
      },
    });
  }, []);

  const onLoad = useCallback(setMap, []);

  const onIdle = useCallback(() => {
    try {
      const params = getMapCenterAndZoomParams(
        map,
        mapParams,
        preferences,
        currentUserLocation,
      );

      if (!R.equals(mapParams, params) || !coordinates) {
        setMapParams(params);
        R.pipe(
          getGeoJsonFromMapBounds,
          R.prop('coordinates'),
          JSON.stringify,
          setCoordinates,
        )(map);

        // Initially set bounding box
        if (!loadedInitialBox && !loadingTaskBoundingBox && boundingBox) {
          setLoadedInitialBox(true);

          map.fitBounds({
            east: boundingBox?.xMax,
            north: boundingBox?.yMax,
            south: boundingBox?.yMin,
            west: boundingBox?.xMin,
          });
        }
      }
    } catch {
      setMapParams(null);
    }
  }, [
    map,
    setMapParams,
    mapParams,
    coordinates,
    preferences,
    loadingTaskBoundingBox,
    boundingBox,
    loadedInitialBox,
    setLoadedInitialBox,
  ]);

  const mapMarkersData = useMemo(() => {
    return {
      assets,
      locations: locations.map(item => ({
        ...item,
        isActive: hoverId === item?.id,
        statusId: workOrderDetail?.statusId,
      })),
    };
  }, [location, locations, workOrderDetail, hoverId, assets]);

  return !isLoaded ? (
    <Progress style={{ height: '100%' }} />
  ) : (
    <Map
      onIdle={onIdle}
      onLoad={onLoad}
      // @ts-ignore
      center={mapParams.center && urlValueToLatLng(mapParams.center)}
      zoom={mapParams.zoom}
      mapParams={mapParams}
      updateMapParams={updateMapParams}
      mapStyleControl
      mapSearchBox
      layerControls
      onCenterMapClick={retrieveTaskBoundingBox}
    >
      <MapMarkers
        // @ts-ignore
        data={JSON.stringify(mapMarkersData)}
        mapParams={mapParams}
        updateMapParams={updateMapParams}
      />
      {mapParams.searchPoint && <MapPinMarker center={mapParams.searchPoint} />}
    </Map>
  );
};

export default withGoogleMapsState<Props>(TaskMap);
