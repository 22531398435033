import React, { useContext } from 'react';

import colors from '@atom/styles/colors';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import {
  CLOSED_STATUS_ID,
  isWorkOrderCompletedOrAbove,
} from '@atom/utilities/workOrderStatusUtilities';

import BulkContext, { Tab } from '../../../BulkContext';
import Field from '../Field';

import DateField from './DateField';
import UserSelectField from './UserSelectField';
import WorkStatus from './WorkStatus';

import '../body.css';

export interface Props {
  tab: Tab;
}

const GeneralBody = ({ tab }: Props) => {
  const { assets, invalidFields, workOrder, workTemplateType } = useContext(
    BulkContext,
  );

  const invalid = invalidFields?.general || new Set();

  const sameAssetType =
    assets.length > 0 &&
    assets.every(asset => asset.assetType === assets[0].assetType);

  const inventoryType = sameAssetType ? assets[0].assetType : 'Multiple';
  const estimatedCost = numberToLocaleString(workTemplateType?.estimatedCost);

  return (
    <div styleName="body">
      <div styleName="body-header">
        <div styleName="body-header-title">{tab.label}</div>
        <div styleName="body-header-meta-container">
          <div styleName="body-header-meta-field">Inventory Name: Multiple</div>
          <div styleName="body-header-meta-field">
            Inventory Type: {inventoryType}
          </div>
          <div styleName="body-header-meta-field">
            Estimated Cost: {estimatedCost}
          </div>
        </div>
      </div>
      <div styleName="body-content">
        <Field
          icon="lock"
          iconColor={colors.utility.activeBlue}
          label="Work Template"
          input={<div>{workTemplateType?.name}</div>}
        />
        <div styleName="body-content-two-column">
          <Field
            icon="event"
            input={<DateField label="Due Date" name="dueDate" />}
          />
        </div>
        <Field
          icon="arrow_drop_down_circle"
          label="Work Status"
          input={<WorkStatus />}
        />
        {isWorkOrderCompletedOrAbove(workOrder.statusId) && (
          <div styleName="body-content-two-column">
            <Field
              input={
                <DateField
                  label="* Completed Date"
                  name="completionDate"
                  error={invalid.has('completionDate')}
                  required
                />
              }
            />
            <Field
              input={
                <UserSelectField
                  label="* Completed By"
                  name="completedBy"
                  error={invalid.has('completedBy')}
                />
              }
            />
          </div>
        )}
        {workOrder.statusId === CLOSED_STATUS_ID && (
          <div styleName="body-content-two-column">
            <Field
              input={
                <DateField
                  label="* Closed Date"
                  name="closedDate"
                  error={invalid.has('closedDate')}
                  required
                />
              }
            />
            <Field
              input={
                <UserSelectField
                  label="* Closed By"
                  name="closedBy"
                  error={invalid.has('closedBy')}
                />
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneralBody;
