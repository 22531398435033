import React from 'react';

import { MediaItem } from '@atom/types/media';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';

import './scdotPdfReport.css';

interface Props {
  medium: MediaItem;
}

const PhotoBlock = ({ medium }: Props) => {
  return (
    <div styleName="photo-block">
      <div styleName="photo-container">
        <div
          style={{
            backgroundImage: `url('${FILES_ENDPOINT}/${medium?.fileId}_1440')`,
          }}
          styleName="photo"
        />
      </div>
      <div>{medium?.name}</div>
    </div>
  );
};

export default PhotoBlock;
