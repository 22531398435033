import { useEffect, useState } from 'react';
import axios from 'axios';

import { MapData } from '@atom/types/map';
import api from '@atom/utilities/api';

export const initialState: MapData = {
  clusters: [],
  assets: [],
  assetsCount: 0,
  workOrders: [],
  workOrdersCount: 0,
  users: [],
  usersCount: 0,
};

export const useMapData = (url: string): [MapData, boolean] => {
  const [mapData, setMapData] = useState<MapData>(initialState);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getMapData = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(url, null, source.token);
        setMapData(data);
        setLoading(false);
      } catch (error) {
        if (!axios.isCancel(error)) {
          throw error;
        }
      }
    };

    if (url) {
      getMapData();
    }

    return () => {
      source.cancel();
    };
  }, [url]);

  return [mapData, loading];
};
