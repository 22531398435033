import React, { useContext, useState } from 'react';

import WorkOrderEnumMultipleField from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderEnumMultipleField';
import WorkOrderEnumSingleField from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderEnumSingleField';
import WorkOrdersContext, {
  getTaskCommonField,
  WorkOrdersInputActionTypes,
} from '@atom/components/workOrders/WorkOrdersContext';
import { Icon, IconButton } from '@atom/mui';
import { DataType } from '@atom/types/dataType';
import { FieldFilter } from '@atom/types/work';
import { CustomFieldFilter } from '@atom/types/workFilters';
import {
  convertDateToLocalDayEnd,
  convertDateToLocalDayStart,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import WorkOrdersFiltersDatePicker from './WorkOrdersFiltersDatePicker';
import WorkOrdersFiltersNumericRange from './WorkOrdersFiltersNumericRange';

import './workOrdersFilters.css';

const WorkOrdersTaskCustomFieldsFilter = () => {
  const { customFieldFilters, workOrdersInputCart, dispatch } = useContext(
    WorkOrdersContext,
  );

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const handleDateStartChange = (
    fieldId?: string,
    fieldProperty?: keyof FieldFilter,
  ) => (_, val: any) => {
    const dateMillis = val ? convertDateToLocalDayStart(val) : null;

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_TASKS_COMMON_FIELD,
      data: {
        fieldId,
        fieldProperty,
        value: dateMillis,
      },
    });
  };

  const handleDateEndChange = (
    fieldId?: string,
    fieldProperty?: keyof FieldFilter,
  ) => (_, val: any) => {
    const dateMillis = val ? convertDateToLocalDayEnd(val) : null;

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_TASKS_COMMON_FIELD,
      data: {
        fieldId,
        fieldProperty,
        value: dateMillis,
      },
    });
  };

  const handleNumericFieldChange = (
    fieldId?: string,
    fieldProperty?: keyof FieldFilter,
  ) => (_, val: any) => {
    const value = val === '' ? null : Number(val);

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_TASKS_COMMON_FIELD,
      data: {
        fieldId,
        fieldProperty,
        value,
      },
    });
  };

  const handleChange = (
    fieldId?: string,
    fieldProperty?: keyof FieldFilter,
  ) => (_, val: any) => {
    const value = val ? val.valueOf() : null;

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_TASKS_COMMON_FIELD,
      data: {
        fieldId,
        fieldProperty,
        value,
      },
    });
  };

  const getDateValue = (
    fieldId?: string,
    fieldProperty?: keyof FieldFilter,
  ) => {
    const field = getTaskCommonField(workOrdersInputCart, fieldId);

    if (field && !isNilOrEmpty(field[fieldProperty])) {
      return new Date(Number(field[fieldProperty]));
    }

    return null;
  };

  const getContent = (field: CustomFieldFilter) => {
    const components = {
      [DataType.DATE]: (
        <div styleName="custom-field-container">
          <WorkOrdersFiltersDatePicker
            key={field.id}
            field={field}
            startDateValue={getDateValue(field.id, 'valueStart')}
            endDateValue={getDateValue(field.id, 'valueEnd')}
            onStartDateChange={handleDateStartChange(field.id, 'valueStart')}
            onEndDateChange={handleDateEndChange(field.id, 'valueEnd')}
          />
        </div>
      ),
      [DataType.DATE_TIME]: (
        <div styleName="custom-field-container">
          <WorkOrdersFiltersDatePicker
            key={field.id}
            field={field}
            startDateValue={getDateValue(field.id, 'valueStart')}
            endDateValue={getDateValue(field.id, 'valueEnd')}
            onStartDateChange={handleChange(field.id, 'valueStart')}
            onEndDateChange={handleChange(field.id, 'valueEnd')}
          />
        </div>
      ),
      [DataType.ENUM_SINGLE]: (
        <div styleName="custom-field-container">
          <WorkOrderEnumSingleField
            key={field.id}
            field={{
              ...field,
              value: getTaskCommonField(workOrdersInputCart, field.id)?.value,
            }}
            onChange={handleChange(field.id, 'value')}
          />
        </div>
      ),
      [DataType.ENUM_MULTIPLE]: (
        <div styleName="custom-field-container">
          <WorkOrderEnumMultipleField
            key={field.id}
            field={{
              ...field,
              value: getTaskCommonField(workOrdersInputCart, field.id)?.values,
            }}
            onChange={handleChange(field.id, 'values')}
          />
        </div>
      ),
      [DataType.NUMBER]: (
        <div styleName="custom-field-container">
          <WorkOrdersFiltersNumericRange
            field={field}
            startValue={
              getTaskCommonField(workOrdersInputCart, field.id)?.valueStart
            }
            endValue={
              getTaskCommonField(workOrdersInputCart, field.id)?.valueEnd
            }
            onStartChange={handleNumericFieldChange(field.id, 'valueStart')}
            onEndChange={handleNumericFieldChange(field.id, 'valueEnd')}
          />
        </div>
      ),
    };

    return components[field.dataType];
  };

  return !isNilOrEmpty(customFieldFilters) ? (
    <div>
      <div styleName="filters-section-header">
        <div>Common Fields</div>
        <IconButton onClick={() => setCollapsed(!collapsed)} edge="end">
          <Icon>{collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</Icon>
        </IconButton>
      </div>
      {!collapsed &&
        customFieldFilters.map(field => (
          <div key={field?.id}>{getContent(field)}</div>
        ))}
    </div>
  ) : (
    <div />
  );
};

export default WorkOrdersTaskCustomFieldsFilter;
