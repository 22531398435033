import { useState } from 'react';
import * as R from 'ramda';

import textConstants from '@atom/constants/textConstants';
import { Snackbar } from '@atom/mui';
import { MediaItem } from '@atom/types/media';
import api from '@atom/utilities/api';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';

const ROOT_FOLDER_ID = 'root';

interface FilesData {
  files: FileList;
  subjectId: string;
  subjectType: string;
  parentSubjectId?: string;
  folderId?: string;
  widths?: number[];
}

type FileUploadFunction = (data: FilesData) => Promise<MediaItem[]>;

interface ResponseData {
  loading: boolean;
  error: Error;
}

const getUploadSuccessMessage = (files: any[]) => {
  const errorFiles = files.filter(item => item.type === 'error');

  if (errorFiles.length > 0) {
    const errorFileNames = errorFiles.map(file => file.name).join(', ');
    return `Some files failed to be uploaded: ${errorFileNames}`;
  }

  return 'Upload success';
};

export const useFileUpload = (): [FileUploadFunction, ResponseData] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>(null);

  const uploader = async ({
    files,
    subjectId,
    subjectType,
    parentSubjectId,
    folderId,
    widths = [],
  }: FilesData) => {
    setError(null);
    setLoading(true);

    const formData = new FormData();
    const keys = Object.keys(files);

    if (keys.length > 5) {
      Snackbar.error({
        message:
          'The maximum number of files that can be uploaded in a single request is 5',
      });
      return [];
    }

    // @ts-ignore
    keys.forEach(key => formData.append('files', files.item(key)));
    widths.forEach(width => formData.append('widths', width.toString()));
    formData.append('subjectId', subjectId);
    formData.append('subjectType', subjectType);

    if (parentSubjectId) {
      formData.append('parentSubjectId', parentSubjectId);
    }

    if (folderId && folderId !== ROOT_FOLDER_ID) {
      formData.append('folderId', folderId);
    }

    Snackbar.info({
      message: `Uploading ${keys.length} items...`,
    });

    try {
      const { data } = await api.post<MediaItem[]>(FILES_ENDPOINT, formData);

      setLoading(false);
      setError(null);

      Snackbar.info({
        message: getUploadSuccessMessage(data),
      });

      return data;
    } catch (err) {
      setLoading(false);
      setError(err);

      const response = R.pathOr('', ['response', 'data'], error);
      const message =
        response === textConstants.MULTER_FILE_LIMIT_ERROR
          ? 'The file you are trying to upload exceeds the 100MB maximum limit.'
          : 'An unknown error occurred. Please try again.';

      Snackbar.error({
        message,
      });

      return [];
    }
  };

  return [uploader, { loading, error }];
};
