import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';

import { triggerLogout } from '@atom/actions/userActions';
import { useUserProfile } from '@atom/hooks/useUserProfile';
// @ts-ignore
import defaultProfileImage from '@atom/images/defaultProfileImage.png';
import { Icon, Popover } from '@atom/mui';
import colors from '@atom/styles/colors';
import history from '@atom/utilities/history';

import './navigation.css';

const styles = {
  iconStyle: {
    color: colors.neutral.gray,
    marginRight: '1rem',
  },
};

const useStyles = makeStyles({
  paper: {
    marginTop: '5px',
    right: 0,
    width: '168px',
    left: 'auto',
  },
});

const Profile = () => {
  const classes = useStyles();
  const userProfile = useUserProfile();
  const dispatch = useDispatch();
  const anchor = useRef();

  const [open, setOpen] = useState<boolean>(false);

  const handleLogout = () => {
    setOpen(false);
    dispatch(triggerLogout());
  };

  const handleProfileClick = () => {
    setOpen(false);
    history.push(`/team/${userProfile.userId}`);
  };

  return (
    <div styleName="profile-wrapper" ref={anchor}>
      <img
        styleName="profile"
        onClick={() => setOpen(true)}
        src={userProfile.profileImage}
        onError={(error: any) => {
          error.target.src = defaultProfileImage;
        }}
      />
      <Popover
        open={open}
        anchorEl={anchor.current}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={() => setOpen(false)}
        classes={classes}
      >
        <div styleName="menu-row" onClick={handleProfileClick}>
          <Icon style={styles.iconStyle} className="material-icons">
            account_circle
          </Icon>
          User Profile
        </div>
        <div styleName="menu-row" onClick={handleLogout}>
          <Icon style={styles.iconStyle} className="material-icons">
            exit_to_app
          </Icon>
          Log Out
        </div>
      </Popover>
    </div>
  );
};

export default Profile;
