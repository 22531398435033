import React, { useEffect, useState } from 'react';
import { InputLabelProps as InputLabelPropsType } from '@mui/material';

import { Select } from '@atom/mui';
import { WorkOrderField } from '@atom/types/work';
const { MenuItem } = Select;

const styles = {
  select: {
    flex: 0.66,
  },
};

interface Props {
  field: WorkOrderField;
  onChange: (fieldId: string, value: any) => void;
  isDisabled?: boolean;
  InputLabelProps?: InputLabelPropsType;
}

const WorkOrderEnumMultipleField = ({
  field,
  onChange,
  isDisabled,
  InputLabelProps,
}: Props) => {
  const [value, setValue] = useState<string[]>([]);

  const handleChange = (event: any) => {
    setValue(event.target.value);

    onChange(field.id, event.target.value);
  };

  useEffect(() => {
    setValue(field?.value || []);
  }, [field?.value]);

  return (
    <>
      <Select
        multiple
        id={field.id}
        style={styles.select}
        value={value}
        label={field.title}
        onChange={handleChange}
        disabled={isDisabled}
        InputLabelProps={InputLabelProps}
      >
        {field.enumeration.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default WorkOrderEnumMultipleField;
