import * as React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';

import * as inventorySchemaActionCreators from '@atom/actions/inventorySchemaActions';
import { Icon, IconButton, Modal, Progress } from '@atom/mui';
import { getFilteredSchemaByMarkerId } from '@atom/selectors/schemaSelectors';
import { InventorySchemaActions } from '@atom/types/actions';
import { SchemaType } from '@atom/types/schema';
import { ReduxStore } from '@atom/types/store';

import NoElementsDialog from './NoElementsDialog';
import SchemaElementGroupSection from './SchemaElementGroupSection';
import SchemaElementListRow from './SchemaElementListRow';

import './elements.css';

const setInitialState = () => ({
  open: false,
  selectedItems: new Set([]),
  schemaQuantities: {},
});

interface PassedProps {
  schema?: object;
  schemaId?: string;
  loading: boolean;
  loadingBatchCreation: boolean;
  title: string;
  assetName: string;
  assetId: string;
  onBatchCreateAction: () => any;
  inventorySchemaActions: object;
  isFhwaForm: boolean;
  existingSchemaIds?: { [schemaId: string]: any };
}

interface ReduxStateProps {
  schema: SchemaType;
  loadingSchemaTreeNode: boolean;
}

interface ReduxDispatchProps {
  inventorySchemaActions: InventorySchemaActions;
}

type Props = ReduxDispatchProps & PassedProps & ReduxStateProps;

class AddElementModal extends React.Component<Props> {
  state = setInitialState();

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.loadingBatchCreation && !nextProps.loadingBatchCreation) {
      this.closeModal();
    }
  }

  closeModal = () => {
    const { inventorySchemaActions, schema } = this.props;

    if (!R.isEmpty(schema)) {
      inventorySchemaActions.requestInventorySchemaClear();
    }

    this.setState(setInitialState);
  };

  openModal = () => {
    const { schemaId, inventorySchemaActions } = this.props;
    inventorySchemaActions.retrieveInventorySchema({ id: schemaId });

    this.setState({
      open: true,
    });
  };

  confirm = () => {
    const { schemaQuantities, selectedItems } = this.state;
    const { schema, assetId, onBatchCreateAction } = this.props;
    const elements = [...selectedItems].reduce((previous, next) => {
      const pathAndId = next.split('.');
      const schemaId = R.last(pathAndId);
      const path = R.dropLast(1, pathAndId);

      const batchItem = {
        schemaId,
        quantity: schemaQuantities[next] || 1,
      };

      if (R.isEmpty(path)) {
        return [...previous, batchItem];
      }

      const indexPath = path.reduce((previousPath, nextPathId) => {
        // @ts-ignore
        const lens = R.lensPath(previousPath);
        const section = R.view(lens, previous);

        // @ts-ignore
        const nextIndex = section.findIndex(
          item => item.schemaId === nextPathId,
        );
        // @ts-ignore
        return [...previousPath, nextIndex, 'elements'];
      }, []);
      // @ts-ignore
      const lens = R.lensPath(indexPath);

      const view = R.view(lens, previous) || [];
      // @ts-ignore
      return R.set(lens, [...view, batchItem], previous);
    }, []);

    const batchSchemaRequestBody = {
      assetId,
      assetSchemaId: schema.id,
      elements,
    };
    // @ts-ignore
    onBatchCreateAction(batchSchemaRequestBody);
    return this.closeModal();
  };

  setSchemaQuantity = input => {
    const parsedValue = Math.floor(Number(input));
    if (parsedValue > 99) {
      return 99;
    }
    if (parsedValue < 1) {
      return 1;
    }

    return parsedValue;
  };

  onChange = (event, name, value) => {
    const { schemaQuantities } = this.state;
    const schemaQuantity = this.setSchemaQuantity(value);

    return this.setState({
      schemaQuantities: { ...schemaQuantities, [name]: schemaQuantity },
    });
  };

  toggleChecked = id => {
    const { selectedItems } = this.state;

    const selectedItemsSet = new Set([...selectedItems]);

    if (selectedItemsSet.has(id)) {
      selectedItemsSet.delete(id);
      const selectedItemsWithRemovals = new Set(
        [...selectedItemsSet].filter(item => !item.startsWith(id)),
      );
      return this.setState({ selectedItems: selectedItemsWithRemovals });
    }
    selectedItemsSet.add(id);
    return this.setState({ selectedItems: selectedItemsSet });
  };

  renderElements = () => {
    const {
      schema,
      inventorySchemaActions,
      loadingSchemaTreeNode,
      existingSchemaIds,
    } = this.props;
    const { selectedItems, schemaQuantities } = this.state;

    if (R.isEmpty(schema) || R.isEmpty(schema.elementGroups)) {
      return <div />;
    }

    const elementGroups = existingSchemaIds
      ? schema.elementGroups.filter(group => {
          return !existingSchemaIds[group.id];
        })
      : schema.elementGroups;

    return elementGroups.map((elementGroup, elementGroupIndex) => {
      // @ts-ignore
      if (elementGroup.isGroup) {
        return (
          <SchemaElementGroupSection
            key={elementGroup.name}
            elementGroupIndex={elementGroupIndex}
            inventorySchemaActions={inventorySchemaActions}
            loadingSchemaTreeNode={loadingSchemaTreeNode}
            onClick={this.toggleChecked}
            onChange={this.onChange}
            // @ts-ignore
            elementGroup={elementGroup}
            // @ts-ignore
            selectedItems={selectedItems}
            schemaQuantities={schemaQuantities}
          />
        );
      }

      // @ts-ignore
      const element = elementGroup.elements[0];

      return (
        <div styleName="element-list-row-container" key={element.id}>
          <SchemaElementListRow
            isChecked={selectedItems.has(element.id)}
            onChange={this.onChange}
            // @ts-ignore
            element={element}
            loading={loadingSchemaTreeNode}
            inventorySchemaActions={inventorySchemaActions}
            onClick={this.toggleChecked}
            value={schemaQuantities[element.id]}
            selectedItems={selectedItems}
            schemaQuantities={schemaQuantities}
            elementPath={['elementGroups', elementGroupIndex, 'elements', 0]}
          />
        </div>
      );
    });
  };

  render() {
    const { open, selectedItems } = this.state;
    const {
      title,
      assetName,
      loading,
      loadingBatchCreation,
      schema,
    } = this.props;
    const addModalDisabledState = !selectedItems.size;

    const dialogContent =
      loading || loadingBatchCreation || R.isEmpty(schema) ? (
        <div styleName="element-modal-spinner-container">
          <Progress />
        </div>
      ) : (
        <div styleName="element-modal-container">
          <div styleName="element-modal-subheading">
            Select elements and quantities from inventory type for
            <span styleName="element-modal-asset-name">{assetName}</span>
          </div>
          <div styleName="element-modal-selection-backdrop">
            {this.renderElements()}
          </div>
        </div>
      );

    const dialog = R.isEmpty(schema.elementGroups) ? (
      <NoElementsDialog
        title="No elements available."
        assetName={assetName}
        closeModal={this.closeModal}
        open={open}
      />
    ) : (
      <Modal
        title={title}
        confirmButtonText="Add"
        onConfirm={this.confirm}
        onCancel={this.closeModal}
        disabled={addModalDisabledState}
        open={open}
        width="lg"
      >
        {dialogContent}
      </Modal>
    );

    return (
      <div>
        <div styleName="header-icon">
          <IconButton
            onClick={this.openModal}
            style={{ height: '24px', padding: 0 }}
          >
            <Icon style={{ padding: 0 }}>add</Icon>
          </IconButton>
        </div>
        {dialog}
      </div>
    );
  }
}

export const mapStateToProps = (
  state: ReduxStore,
  ownProps: Props,
): ReduxStateProps => ({
  loading: state.loading.loadingInventorySchema,
  loadingBatchCreation: state.loading.loadingElementBatchCreation,
  // @ts-ignore
  schema: getFilteredSchemaByMarkerId(state, ownProps),
  loadingSchemaTreeNode: state.loading.loadingSchemaTreeNode,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  inventorySchemaActions: bindActionCreators(
    inventorySchemaActionCreators,
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddElementModal);
