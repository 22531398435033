import React from 'react';

import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import ReportUserRow from './ReportUserRow';
import { ReportUser } from './reportUtilities';

import './workOrderReport.css';

interface Props {
  users: ReportUser[];
  isCompletedOrAbove: boolean;
}

const ReportUsers = ({ users, isCompletedOrAbove }: Props) => {
  const showUsers = !isNilOrEmpty(users);

  return (
    showUsers && (
      <>
        <div styleName="users-table-header">
          <div styleName="users-table-sub-header">
            <div styleName="users-header-cell name">Name</div>
            <div styleName="users-header-cell">Hours</div>
          </div>
          {isCompletedOrAbove && (
            <div styleName="users-header-cell cost">Cost</div>
          )}
        </div>
        {users.map(user => (
          <ReportUserRow
            key={user.userId}
            user={user}
            isCompletedOrAbove={isCompletedOrAbove}
          />
        ))}
      </>
    )
  );
};

export default ReportUsers;
