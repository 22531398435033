import colors from '@atom/styles/colors';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

export const REQUESTED_STATUS_ID = '0';
export const UNASSIGNED_STATUS_ID = '1';
export const ASSIGNED_STATUS_ID = '2';
export const IN_PROGRESS_STATUS_ID = '3';
export const COMPLETED_STATUS_ID = '4';
export const CLOSED_STATUS_ID = '5';
export const IN_REVIEW_STATUS_ID = '6';

export interface WorkOrderStatusType {
  name: string;
  label: string;
  value: string;
}

export const workOrderStatusTypes: WorkOrderStatusType[] = [
  { name: 'statusId', label: 'Requested', value: REQUESTED_STATUS_ID },
  { name: 'statusId', label: 'Unassigned', value: UNASSIGNED_STATUS_ID },
  { name: 'statusId', label: 'Assigned', value: ASSIGNED_STATUS_ID },
  { name: 'statusId', label: 'In Progress', value: IN_PROGRESS_STATUS_ID },
  { name: 'statusId', label: 'Completed', value: COMPLETED_STATUS_ID },
  { name: 'statusId', label: 'In Review', value: IN_REVIEW_STATUS_ID },
  { name: 'statusId', label: 'Closed', value: CLOSED_STATUS_ID },
];

export const isWorkOrderCompletedOrAbove = (statusId: string) => {
  if (isNilOrEmpty(statusId)) {
    return false;
  }

  return Number(statusId) >= 4;
};

export const totalWorkOrderStatusTypes = workOrderStatusTypes.length;

export function getWorkOrderStatusColor(statusId: any): string {
  const statusColor = [
    colors.status.requested,
    colors.status.unassigned,
    colors.status.assigned,
    colors.status.inprogress,
    colors.status.completed,
    colors.status.closed,
    colors.status.inReview,
  ];
  return statusColor[statusId];
}

export function getWorkOrderStatusCalendarColor(statusId: any): string {
  const statusColor = [
    colors.calendarStatus.requested,
    colors.calendarStatus.unassigned,
    colors.calendarStatus.assigned,
    colors.calendarStatus.inprogress,
    colors.calendarStatus.completed,
    colors.calendarStatus.closed,
    colors.calendarStatus.inReview,
  ];
  return statusColor[statusId];
}

export function getWorkOrderStatusType(statusId: any): WorkOrderStatusType {
  return workOrderStatusTypes.find(statusType => statusType.value === statusId);
}

export function getWorkOrderStatusDescription(statusId: any): string {
  // At this time this cannot be refactored to consume workOrderStatusTypes with
  // a .map prototype iteration.  Array.prototype.map does not guarantee insertion
  // order.  Instead, a Map object should be used, regardless if by luck the order
  // returns based on the order of workOrderStatusTypes.
  // TODO: [ATOM-594]
  const statusDescription = [
    'Requested',
    'Unassigned',
    'Assigned',
    'In Progress',
    'Completed',
    'Closed',
    'In Review',
  ];

  return statusDescription[statusId];
}

const workOrderStatusUtilities = {
  REQUESTED_STATUS_ID,
  UNASSIGNED_STATUS_ID,
  ASSIGNED_STATUS_ID,
  IN_PROGRESS_STATUS_ID,
  COMPLETED_STATUS_ID,
  IN_REVIEW_STATUS_ID,
  CLOSED_STATUS_ID,
  getWorkOrderStatusDescription,
  getWorkOrderStatusColor,
  workOrderStatusTypes,
};

export default workOrderStatusUtilities;
