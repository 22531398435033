import React, { useContext } from 'react';
import * as R from 'ramda';

import InventoryAssetTree from '@atom/components/common/inventoryAssetTree/InventoryAssetTree';
import LocationsAndAssetsContext from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsContext';

import './taskAssetModal.css';

interface Props {
  cartAssetIds: string[];
  taskAssetIds: string[];
  onAssetToggle: (id: string) => void;
  setSelectedAssetIds: (ids: string[]) => void;
}

const TaskAssetTree = ({
  cartAssetIds,
  taskAssetIds,
  onAssetToggle,
  setSelectedAssetIds,
}: Props) => {
  const { task } = useContext(LocationsAndAssetsContext);

  return (
    <InventoryAssetTree
      selectedAssetIds={new Set([...cartAssetIds, ...taskAssetIds])}
      schemaIds={R.pathOr([], ['allowedMultiAssetSchemaIds'], task)}
      onAssetToggle={asset => onAssetToggle(asset?.id)}
      setSelectedAssetIds={ids => setSelectedAssetIds([...ids])}
      disabledAssetIds={new Set(taskAssetIds)}
      includeMaterials={false}
      includeInactive={false}
      search="assets"
    />
  );
};

export default TaskAssetTree;
