import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import { inventoryAssetUpdateSuccess } from '@atom/actions/inventoryAssetActions';
import { Icon, Menu, Modal, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventoryAssetDetailType } from '@atom/types/inventory';
import api from '@atom/utilities/api';
import { INVENTORY_ASSETS_ENDPOINT } from '@atom/utilities/endpoints';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './header.css';

interface Props {
  asset: InventoryAssetDetailType;
  setOpenDeleteModal: (open: boolean) => void;
  canDelete: boolean;
}

interface AssetCopyPayload {
  assetId: string;
  schemaId: string;
  attributes: {
    [id: string]: {
      value: any;
      newValue: any;
    };
  };
}

const styles = {
  icon: {
    marginRight: '1rem',
  },
};

const ASSET_COPY_PASTE_KEY = 'ASSET_COPY_PASTE_KEY';

const AssetOptionMenu = ({ asset, setOpenDeleteModal, canDelete }: Props) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const copyAsset = useCallback(() => {
    const attributes = Object.entries(asset.attributes || {})
      .filter(([, attribute]) => attribute.isEditable)
      .reduce((acc, [id, attribute]) => {
        return {
          ...acc,
          [id]: {
            ...(!R.isNil(attribute.value) && {
              value: attribute.value,
            }),
            ...(!R.isNil(attribute.newValue) && {
              newValue: attribute.newValue,
            }),
          },
        };
      }, {});

    window.sessionStorage.setItem(
      ASSET_COPY_PASTE_KEY,
      JSON.stringify({
        assetId: asset.id,
        schemaId: asset.schemaId,
        attributes,
      }),
    );

    Snackbar.info({
      message: `Copied ${textDisplayUtilities.toOwnership(
        asset.name,
      )} data to clipboard.`,
    });
  }, [asset]);

  const copiedPayload = useMemo((): AssetCopyPayload => {
    return JSON.parse(
      window.sessionStorage.getItem(ASSET_COPY_PASTE_KEY || '{}'),
    );
  }, [window.sessionStorage.getItem(ASSET_COPY_PASTE_KEY)]);

  const pasteAsset = async () => {
    setLoading(true);

    const attributes = Object.entries(copiedPayload.attributes).reduce(
      (acc, [id, update]) => {
        const currentValue = asset.attributes[id]?.value || null;
        const currentPendingValue = asset.attributes[id]?.newValue;

        const isCurrentValueUpdated = currentValue !== update.value;
        const isPendingValueUpdated =
          (!R.isNil(update.newValue) || !R.isNil(currentPendingValue)) &&
          currentPendingValue !== update.newValue;

        // if both value and newValue would stay the same omit from the request payload
        if (!isCurrentValueUpdated && !isPendingValueUpdated) {
          return acc;
        }

        return { ...acc, [id]: update };
      },
      {},
    );

    if (!R.isEmpty(attributes)) {
      const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${asset.id}/attributes`;
      const { data } = await api.patch(endpoint, attributes);
      dispatch(inventoryAssetUpdateSuccess(data));
    }

    Snackbar.info({ message: `Pasted data to ${asset.name}.` });

    setLoading(false);
    setOpen(false);
  };

  return (
    <>
      <Menu IconProps={{ color: colors.neutral.white }}>
        <Menu.MenuItem
          onClick={copyAsset}
          disabled={isNilOrEmpty(asset.attributes)}
        >
          <Icon style={styles.icon} color={colors.neutral.gray}>
            content_copy
          </Icon>
          Copy Data To Clipboard
        </Menu.MenuItem>
        <Menu.MenuItem
          disabled={
            isNilOrEmpty(copiedPayload) ||
            copiedPayload?.assetId === asset.id ||
            copiedPayload.schemaId !== asset.schemaId
          }
          onClick={() => setOpen(true)}
        >
          <Icon style={styles.icon} color={colors.neutral.gray}>
            content_paste
          </Icon>
          Paste Data
        </Menu.MenuItem>
        <Menu.MenuItem
          disabled={!canDelete}
          onClick={() => setOpenDeleteModal(true)}
        >
          <Icon style={styles.icon} color={colors.neutral.gray}>
            delete
          </Icon>
          Delete
        </Menu.MenuItem>
      </Menu>
      <Modal
        title="Paste Data?"
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={pasteAsset}
        confirmButtonText="Paste"
        loading={loading}
      >
        <div>
          All existing attributes on <strong>{asset.name}</strong> will be
          overwritten with the copied data. This action cannot be undone. Are
          you sure?
        </div>
      </Modal>
    </>
  );
};

export default AssetOptionMenu;
