import React from 'react';

import { Menu } from '@atom/mui';
import { WorkPriorityType } from '@atom/types/work';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import workOrderPriorityUtilities from '@atom/utilities/workOrderPriorityUtilities';

import './priorityControl.css';

const { MenuItem } = Menu;

interface Props {
  priorityId: WorkPriorityType;
  disabled?: boolean;
  updateWorkOrder?: (params: any) => void;
}

const PriorityControl = ({ priorityId, disabled, updateWorkOrder }: Props) => {
  const updatePriorityId = (value: number) => {
    updateWorkOrder({ priorityId: value });
  };

  return (
    <Menu
      icon={workOrderPriorityUtilities.getWorkPriorityStatusIcon(priorityId)}
      IconButtonProps={{ tooltip: 'Priority', edge: 'end' }}
      disabled={!hasRolePermissions(ROLE_SETS.INSPECTOR)}
    >
      {workOrderPriorityUtilities.workOrderPriorityTypes.map(
        (priority: any) => {
          const selected = priority.value === priorityId;

          return (
            <MenuItem
              key={priority.value}
              onClick={() => updatePriorityId(priority.value)}
              selected={selected}
              disabled={disabled}
              startAdornment={workOrderPriorityUtilities.getWorkPriorityStatusIcon(
                priority.value,
              )}
            >
              {priority.label}
            </MenuItem>
          );
        },
      )}
    </Menu>
  );
};

export default PriorityControl;
