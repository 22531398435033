import * as React from 'react';

import AddElementModal from './AddElementModal';

import './elements.css';

interface PassedProps {
  assetId?: string;
  assetName?: string;
  schemaId?: string;
  label: string;
  isEditDisabled: boolean;
  onBatchCreateAction: (payload: Object) => void;
}

type Props = PassedProps;

const ElementsHeader = ({
  label,
  assetName,
  assetId,
  isEditDisabled = false,
  schemaId,
  onBatchCreateAction,
}: Props) => {
  return (
    <div styleName="header-container">
      <div styleName="header-label">{label}</div>
      <div styleName="header-icon">
        {!isEditDisabled && (
          <AddElementModal
            title="Add elements to asset"
            assetId={assetId}
            assetName={assetName}
            schemaId={schemaId}
            onBatchCreateAction={onBatchCreateAction}
          />
        )}
      </div>
    </div>
  );
};

export default ElementsHeader;
