import React, { createContext, useContext, useState } from 'react';
import { useQuery } from '@apollo/client';

import { GET_KML_LAYERS } from '@atom/graph/kml';
import { KmlLayer } from '@atom/types/map';

type KmlLayerSetter = (current: KmlLayer[]) => KmlLayer[];

interface ContextValue {
  layers: KmlLayer[];
  setLayers: (setts: KmlLayerSetter) => void;
  selectedLayer: KmlLayer;
  selectedPlacemark: { [key: string]: string };
  onLayerClick: (layer: KmlLayer, placemark: { [key: string]: string }) => void;
  loading: boolean;
}

const Context = createContext<ContextValue>({
  layers: [],
  setLayers: (setter: KmlLayerSetter) => {},
  selectedPlacemark: null,
  selectedLayer: null,
  onLayerClick: () => {},
  loading: false,
});

const Provider = Context.Provider;

export const useKmlLayers = () => useContext(Context);

export const withKmlLayers = WrappedComponent => {
  return props => {
    const [layers, setLayers] = useState<KmlLayer[]>([]);
    const [selectedLayer, setSelectedLayer] = useState<KmlLayer>(null);
    const [selectedPlacemark, setSelectedPlacemark] = useState(null);

    const { loading } = useQuery<{ kmlLayers: KmlLayer[] }>(GET_KML_LAYERS, {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: data =>
        setLayers(
          data.kmlLayers.map(layer =>
            layer.pollingInterval
              ? { ...layer, url: `${layer.url}?rev=${Date.now()}` }
              : layer,
          ),
        ),
    });

    const onLayerClick = (
      layer: KmlLayer,
      placemark: { [key: string]: string },
    ) => {
      setSelectedLayer(layer);
      setSelectedPlacemark(placemark);
    };

    return (
      <Provider
        value={{
          layers,
          setLayers,
          selectedLayer,
          selectedPlacemark,
          onLayerClick,
          loading,
        }}
      >
        <WrappedComponent {...props} />
      </Provider>
    );
  };
};
