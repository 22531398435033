import React, { useState } from 'react';
import * as R from 'ramda';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { UserCertificationItem } from '@atom/types/user';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import AddEditCertModal from './AddEditCertModal';
import DeleteCertModal from './DeleteCertModal';

import './certificationsTab.css';

export interface Props {
  certification: UserCertificationItem;
}

const UserCertTile = ({ certification }: Props) => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  return (
    <>
      <div styleName="cert-tile">
        <div styleName="cert-title-container">
          <Icon color={colors.brand.green}>card_membership</Icon>
          <div styleName="cert-name-container">
            <div styleName="cert-name">{certification.name}</div>
            <div>{certification.description}</div>
            {!R.isNil(certification.groupPath) &&
              !R.isEmpty(certification.groupPath) && (
                <div styleName="subtext-container">
                  <Icon
                    color={colors.neutral.gray}
                    style={{ fontSize: '16px' }}
                  >
                    group
                  </Icon>
                  <div styleName="subtext">
                    {certification.groupPath.join(' / ')}
                  </div>
                </div>
              )}
          </div>
        </div>
        {certification.isEditable && (
          <div>
            {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
              <IconButton onClick={() => setOpenEdit(true)}>
                <Icon color={colors.neutral.gray}>edit</Icon>
              </IconButton>
            )}
            {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
              <IconButton onClick={() => setOpenDelete(true)}>
                <Icon color={colors.neutral.gray}>delete</Icon>
              </IconButton>
            )}
          </div>
        )}
      </div>
      <AddEditCertModal
        isEdit
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        certification={certification}
      />
      <DeleteCertModal
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        certificationId={certification.id}
      />
    </>
  );
};

export default UserCertTile;
