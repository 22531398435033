import React, { useContext, useState } from 'react';

import UserThumbnail from '@atom/components/common/UserThumbnail';
import { Button, Icon, IconButton, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import { Task, TaskUser } from '@atom/types/task';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';

import BulkContext from '../../../../BulkContext';

import './teamSection.css';

const styles = {
  removeButton: {
    background: colors.brand.red,
    color: colors.neutral.white,
  },
};

export interface Props {
  taskIndex: number;
  user: TaskUser;
}

const TaskUserTile = ({ taskIndex, user }: Props) => {
  const { workOrder, setWorkOrder } = useContext(BulkContext);
  const { tasks } = workOrder;
  const task = tasks[taskIndex];

  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);

  const removeUser = () => {
    const newUsers = task.users.filter(userItem => userItem.id !== user.id);

    const newWorkOrder = {
      ...workOrder,
      tasks: tasks.map((taskItem: Task, indexValue: number) =>
        indexValue === taskIndex ? { ...taskItem, users: newUsers } : taskItem,
      ),
    };

    setWorkOrder(newWorkOrder);
    setRemoveModalOpen(false);
  };

  const userName = `${user.firstName} ${user.lastName}`;

  const isEditDisabled = doesNotHaveRolePermissions(ROLE_SETS.MANAGER);

  const getFooter = () => (
    <div>
      <Button
        onClick={() => setRemoveModalOpen(false)}
        style={{ marginRight: '0.5rem' }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        onClick={removeUser}
        style={styles.removeButton}
      >
        Remove
      </Button>
    </div>
  );

  return (
    <>
      <div styleName="user-container">
        <div styleName="user-content-container">
          <UserThumbnail
            taskUserStatus={user.status}
            image={user.photoUrl}
            alt={userName}
          />
          <div styleName="user-details">
            <div styleName="name">{userName}</div>
          </div>
        </div>
        {!isEditDisabled && (
          <IconButton
            onClick={() => setRemoveModalOpen(true)}
            tooltip="Remove"
            edge="end"
          >
            <Icon>close</Icon>
          </IconButton>
        )}
      </div>
      <Modal
        open={removeModalOpen}
        title="Remove User From Task"
        footer={getFooter()}
      >
        Are you sure you want to remove this user from the task?
      </Modal>
    </>
  );
};

export default TaskUserTile;
