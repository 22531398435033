import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import textConstants from '@atom/constants/textConstants';
import {
  SCHEMA_CREATE,
  SCHEMA_DELETE,
  SCHEMA_DUPLICATE,
} from '@atom/graph/schema';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Snackbar } from '@atom/mui';
import { InventorySchemaItem } from '@atom/types/inventory';
import { SchemaCreateInput, SchemaSavePayload } from '@atom/types/schema';
import api from '@atom/utilities/api';
import { INVENTORY_SCHEMAS_ENDPOINT } from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';
import { getPortals } from '@atom/utilities/portalUtilities';

import DataManagementPortalHeader from './DataManagementPortalHeader';
import SchemaTile from './SchemaTile';

import './dataManagement.css';

const DataManagementPortal = () => {
  const preferences = usePreferences();
  const landingPortal = getPortals(preferences)[preferences.landingPortal];

  const [loadingCopy, setLoadingCopy] = useState<string>('');
  const [schemas, setSchemas] = useState<InventorySchemaItem[]>([]);

  const [deleteSchema] = useMutation<{ schemaDelete: boolean }, { id: string }>(
    SCHEMA_DELETE,
  );

  const [createSchema] = useMutation<
    { schemaCreate: InventorySchemaItem },
    { input: SchemaCreateInput }
  >(SCHEMA_CREATE);

  const [duplicateSchema] = useMutation<
    { schemaDuplicate: InventorySchemaItem },
    { id: string }
  >(SCHEMA_DUPLICATE);

  const navigateBack = (): void => {
    const state = history.location.state;
    if (R.isNil(state) || R.isEmpty(state)) {
      return history.push(landingPortal.urlPath);
    }
    return history.push('/inventory', state);
  };

  const getSchemas = async () => {
    const { data } = await api.get<InventorySchemaItem[]>(
      INVENTORY_SCHEMAS_ENDPOINT,
      {
        rootSchemas: true,
        isPublished: 'true,false',
      },
    );
    setSchemas(data);
  };

  const handleDeleteSchema = async (id: string) => {
    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
    });

    await deleteSchema({
      variables: {
        id,
      },
    });

    getSchemas();

    Snackbar.info({
      message: 'Successfully deleted inventory type.',
    });
  };

  const handleDuplicateSchema = async (id: string) => {
    setLoadingCopy(id);

    const { data } = await duplicateSchema({
      variables: {
        id,
      },
    });

    getSchemas();
    setLoadingCopy('');

    Snackbar.info({
      message: `Created inventory type: ${data?.schemaDuplicate?.name}`,
    });
  };

  const handleAddSchema = async (payload: SchemaSavePayload) => {
    const { data } = await createSchema({
      variables: {
        input: {
          name: payload?.name,
          assetType: payload?.name,
          locationType: payload?.locationType,
          colorId: payload?.colorId,
          markerId: payload?.markerId,
          isMaterial: payload?.isMaterial,
          isPublished: false,
        },
      },
    });

    const newSchema = data?.schemaCreate;

    history.push(`/admin/inventoryTypes/${newSchema.id}`);
  };

  useEffect(() => {
    getSchemas();
  }, []);

  return (
    <div styleName="portal-wrapper">
      <DataManagementPortalHeader
        navigateBack={navigateBack}
        addSchema={handleAddSchema}
      />
      <div styleName="portal-container">
        <div styleName="schemas-container">
          {schemas.map((schema: InventorySchemaItem) => (
            <SchemaTile
              key={schema.id}
              schema={schema}
              onDelete={handleDeleteSchema}
              onCopy={handleDuplicateSchema}
              loading={loadingCopy === schema.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DataManagementPortal;
