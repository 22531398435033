import React from 'react';

import { InventoryAssetTreeStateType } from '@atom/types/inventory';
import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';

import '../workOrderDetailPdfReport.css';

interface Props {
  element: InventoryAssetTreeStateType;
  schemaIds: string[];
}

const ElementAssessmentRowDeck = ({ element, schemaIds }: Props) => {
  const getDeckElementTableRows = () => {
    return (
      <tr styleName="table-value">
        <td>
          {attributeDisplayUtility.displayElementNameOnChange(
            element.name,
            element.changeType,
          )}
        </td>
        {schemaIds.map(key => (
          <td>
            {attributeDisplayUtility.display(
              element.attributes[key].value,
              element.attributes[key].dataType,
              element.attributes[key].unit,
              !!element.attributes[key].changeId,
              element.attributes[key].newValue,
            )}
          </td>
        ))}
      </tr>
    );
  };

  return <>{getDeckElementTableRows()}</>;
};

export default ElementAssessmentRowDeck;
