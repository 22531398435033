import * as R from 'ramda';

import {
  ColumnsData,
  DashboardCard,
  DashboardCardSize,
} from '@atom/types/dashboard';

import {
  CARD_CONFIG,
  DEFAULT_LOAD_HEIGHT,
  initUIStateProps,
} from './dashboardSettings';

// Dashboard Helper Methods
export const getColumnIndex = (size: DashboardCardSize): number =>
  size === 'LG' ? 0 : 1;

export const getColumn = (
  size: DashboardCardSize,
  allCards: DashboardCard[],
): DashboardCard[] => {
  const columnCards = allCards.filter(card => card.size === size);
  return R.sort((card1, card2) => card1.order - card2.order, columnCards);
};

export const getCardHeight = (
  card: DashboardCard,
  margin: number = 0,
): string =>
  card.emptyHeight ? `${card.emptyHeight - margin}px` : DEFAULT_LOAD_HEIGHT;

export const getColumnsData = (
  lgColumn: DashboardCard[],
  smColumn: DashboardCard[],
): ColumnsData => [
  lgColumn.map(card => card.id),
  smColumn.map(card => card.id),
];

// Initialization methods
const applyInitCardState = (card: DashboardCard, layout: ColumnsData) => {
  // TODO: improve logic to detect column
  //       - this works as long as we can map size to column
  const column = getColumnIndex(card.size);
  const order = layout[column].indexOf(card.id);
  return { ...card, ...initUIStateProps, ...CARD_CONFIG[card.type], order };
};

export const initCards = (cards: DashboardCard[], layout: ColumnsData) => {
  return cards.map(card => applyInitCardState(card, layout));
};
