/* eslint-disable no-process-env */

export default {
  environment: process.env.NODE_ENV,
  port: process.env.PORT || 8080,
  host: process.env.HOST || 'local-atom.com',
  domainName: process.env.DOMAIN_NAME || 'local-atom.com',

  gcloudProject: process.env.GCLOUD_PROJECT || 'sada-atom',
  atomEnvironment: process.env.ATOM_ENVIRONMENT || 'dev',
  clusterName: process.env.CLUSTER_NAME || '',
  podId: process.env.POD_ID || '',
  containerName: process.env.CONTAINER_NAME || '',
  clusterZone: process.env.CLUSTER_ZONE || '',

  redisHost: process.env.ATOM_REDIS_HOST || 'localhost',
  redisPort: process.env.ATOM_REDIS_PORT || 6379,

  AUTH0_DOMAIN: 'atomapp.auth0.com',
  AUTH0_CALLBACK_URL:
    process.env.AUTH0_CALLBACK_URL ||
    'http://www.local-atom.com:8080/api/v1/auth/callback',
  AUTH0_CLIENT_ID: 'L-H4RX8S9-qitlUrEBJfC3XCrhCKW_pN',
  AUTH0_CLIENT_SECRET:
    'eNTg5r7bXlF5YThh5GDI2hK5nPP7pblzYBIyOOEvPRbKn9uw3cn4CIE9Dv4UDX2h',
  AUTH0_AUTHENTICATION_CLIENT_ID: 'TUvdcngFfEleLUBmPmx7MFeiUPjj09TH',
  AUTH0_AUTHENTICATION_CLIENT_SECRET:
    'gbfElJAx7tCP8Ot7-77AX7JOTCZ-GxjoLw_nw84j1XdvraLKdIWiwJKNi0OPdjVs',

  authSecret: process.env.AUTH_SECRET || 'atom',
  authTokenExpiration: process.env.AUTH_TOKEN_EXPIRATION || '30d',

  rejectMimeTypes: [
    'application/x-msdownload',
    'application/x-msdos-program',
    'application/x-msdos-windows',
    'application/x-download',
    'application/bat',
    'application/x-bat',
    'application/com',
    'application/x-com',
    'application/exe',
    'application/x-exe',
    'application/x-winexe',
    'application/x-winhlp',
    'application/x-winhelp',
    'application/x-javascript',
    'application/hta',
    'application/x-ms-shortcut',
    'vms/exe',
    'application/zip',
  ],
  /**
   * if file size upload limit is changed the following should also be updated:
   * - body-parser config in dev, prod express configurations (application.dev.js, application.production.js)
   * - file size limit error in fileSagas.ts
   * - additionally you may need to update the request and response timeouts in sendUploadToGCS middleware (storageUtilities.js)
   */
  uploadFileSizeLimit: 300 * 1024 * 1024,
  numberOfUploadFilesLimit: 5,
  numberOfCoverPhotoUploadFilesLimit: 1,
  validSubjectTypes: [
    'workOrder',
    'workOrderAsset',
    'workOrderTemplate',
    'inventoryAsset',
    'formInstanceField',
    'formInstanceAsset',
  ],

  isLocal: process.env.LOCAL_DEV === 'true',
};
