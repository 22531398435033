import React from 'react';

import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import ReportStartEndRow from './ReportStartEndRow';
import ReportUsageRow from './ReportUsageRow';
import { ReportMaterial } from './reportUtilities';

import './workOrderReport.css';

interface Props {
  startEndMaterials: ReportMaterial[];
  usageMaterials: ReportMaterial[];
  isCompletedOrAbove: boolean;
}

const ReportMaterials = ({
  startEndMaterials,
  usageMaterials,
  isCompletedOrAbove,
}: Props) => {
  const showStartEndSection = !isNilOrEmpty(startEndMaterials);
  const showUsageSection = !isNilOrEmpty(usageMaterials);

  return (
    <div>
      {showStartEndSection && (
        <>
          <div styleName="material-table-header">
            <div styleName="material-table-sub">
              <div styleName="material-header-type">Type</div>
              <div styleName="material-header-name">Name</div>
            </div>
            <div styleName="material-table-sub">
              <div styleName="material-header-cell">Start Reading</div>
              <div styleName="material-header-cell">End Reading</div>
              <div styleName="material-header-cell used">Used</div>
              {isCompletedOrAbove && (
                <div styleName="material-header-cell">Cost</div>
              )}
            </div>
          </div>
          <div>
            {startEndMaterials.map(reportMaterial => (
              <ReportStartEndRow
                key={reportMaterial?.assetId}
                reportMaterial={reportMaterial}
                isCompletedOrAbove={isCompletedOrAbove}
              />
            ))}
          </div>
        </>
      )}
      {showUsageSection && (
        <>
          <div styleName="material-table-header">
            <div styleName="material-table-sub">
              <div styleName="material-header-type">Type</div>
              <div styleName="material-header-name">Name</div>
            </div>
            <div styleName="material-table-sub">
              <div styleName="material-header-cell used">Used</div>
              {isCompletedOrAbove && (
                <div styleName="material-header-cell">Cost</div>
              )}
            </div>
          </div>
          <div>
            {usageMaterials.map(reportMaterial => (
              <ReportUsageRow
                key={reportMaterial?.assetId}
                reportMaterial={reportMaterial}
                isCompletedOrAbove={isCompletedOrAbove}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ReportMaterials;
