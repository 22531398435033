import React, { useState } from 'react';

import RenameModal from '@atom/components/common/RenameModal';
import { Icon, Menu, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import { MediaItem } from '@atom/types/media';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';

import './ci.css';

const { MenuItem } = Menu;

interface Props {
  media: MediaItem;
  assetId: string;
  removeFile: (mediaId: string, subjectId: string) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
}

enum ModalType {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  NONE = 'NONE',
}

type OpenModal = { type: ModalType; mediaId: string | null };

const CiMediaTile = ({ media, assetId, removeFile, renameMedia }: Props) => {
  const [name, setName] = useState<string>(media.name);
  const [openModal, setOpenModal] = useState<OpenModal>({
    type: ModalType.NONE,
    mediaId: null,
  });

  const toggleModal = (modalType: ModalType, id: any) => {
    setOpenModal({ type: modalType, mediaId: id });
  };

  const isModalOpen = (modalType: ModalType, id: any) => {
    return openModal.type === modalType && openModal.mediaId === id;
  };

  const { id, type, fileId, fileExtension } = media;

  const handleRename = (mediaId: string, updatedName: string) => {
    setName(updatedName);
    renameMedia(media.id, assetId, updatedName);
  };

  const handleRemove = () => {
    removeFile(media.id, assetId);
  };

  const onConfirmDeleteModal = () => {
    handleRemove();
    toggleModal(ModalType.NONE, null);
  };

  return (
    <>
      <div styleName="media-tile-container">
        <div styleName="media-image-container">
          <img
            styleName="image"
            src={`${FILES_ENDPOINT}/${fileId}_180`}
            alt={name}
          />
        </div>
        <div styleName="media-tile-info">
          <div styleName="file-name">{`${name}.${fileExtension}`}</div>
          <Menu>
            <MenuItem
              startAdornment={<Icon>edit</Icon>}
              onClick={() => toggleModal(ModalType.EDIT, id)}
            >
              Edit
            </MenuItem>
            <MenuItem
              startAdornment={<Icon>delete</Icon>}
              onClick={() => toggleModal(ModalType.DELETE, id)}
            >
              Delete
            </MenuItem>
          </Menu>
        </div>
        <RenameModal
          open={isModalOpen(ModalType.EDIT, id)}
          closeModal={() => toggleModal(ModalType.NONE, null)}
          id={id}
          name={name}
          type={type}
          renameAction={handleRename}
        />
        <Modal
          open={isModalOpen(ModalType.DELETE, id)}
          title={`Delete ${name}`}
          onCancel={() => toggleModal(ModalType.NONE, null)}
          confirmButtonText="Delete"
          ConfirmButtonProps={{
            style: { background: colors.brand.red },
          }}
          onConfirm={onConfirmDeleteModal}
        >
          Are you sure you want to delete this photo?
        </Modal>
      </div>
    </>
  );
};

export default CiMediaTile;
