import React from 'react';

import { View } from './InventoryExportContext';

import './inventoryExport.css';

interface Props {
  activeView: View;
  setActiveView: (view: View) => void;
}

const InventoryExportSidebar = ({ activeView, setActiveView }: Props) => {
  const rowStyle = view => (view === activeView ? 'row selected' : 'row');

  return (
    <div style={{ flexDirection: 'column' }}>
      <div style={{ height: '0.5rem' }} />
      <div
        styleName={rowStyle(View.DATA_EXPORTS)}
        onClick={() => setActiveView(View.DATA_EXPORTS)}
      >
        <div styleName="row-name">Data Exports</div>
      </div>
      <div
        styleName={rowStyle(View.CUSTOM_EXPORTS)}
        onClick={() => setActiveView(View.CUSTOM_EXPORTS)}
      >
        <div styleName="row-name">Custom Exports</div>
      </div>
    </div>
  );
};

export default InventoryExportSidebar;
