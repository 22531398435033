import React from 'react';
import * as R from 'ramda';
import { StringParam, useQueryParams } from 'use-query-params';

import WorkOrderList from '@atom/components/mapPortal/drawerContent/WorkOrdersList';

import { useListView } from '../hooks/listViewHook';
import { initialState } from '../hooks/mapDataHook';

const WorkOrderGroupListWrapper = () => {
  const [listView = initialState] = useListView();
  const [queryParams, setQueryParams] = useQueryParams({
    id: StringParam,
    view: StringParam,
  });

  const workOrders = R.pipe(
    R.propOr([], 'workOrders'),
    // @ts-ignore
    R.filter(R.propSatisfies(R.includes(R.__, queryParams.id), 'id')),
    // @ts-ignore
  )(listView);

  const onClick = id => {
    setQueryParams({ id, view: 'workOrder' });
  };

  return (
    /* @ts-ignore */
    <WorkOrderList workOrders={workOrders} navigateToWorkOrder={onClick} />
  );
};

export default WorkOrderGroupListWrapper;
