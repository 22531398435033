import React, { useContext, useEffect, useState } from 'react';

import { usePreferences } from '@atom/hooks/usePreferences';
import { Modal, Select } from '@atom/mui';
import {
  ciFormModule,
  formTemplateContainsAnyModule,
  getFormModulesList,
} from '@atom/selectors/formModuleSelectors';
import {
  ConditionInspection,
  FormModuleKeys,
  FormModuleType,
  FormTemplateType,
} from '@atom/types/form';
import { InventorySchemaItem } from '@atom/types/inventory';
import api from '@atom/utilities/api';
import { INVENTORY_SCHEMAS_ENDPOINT } from '@atom/utilities/endpoints';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import FormBuilderContext from '../FormBuilderContext';

import FormModuleTile from './FormModuleTile';

import '../formBuilder.css';

const { MenuItem } = Select;

const styles = {
  menu: {
    maxHeight: '30rem',
  },
  select: {
    margin: '2rem 0',
    width: '50%',
  },
};

type ModuleDisabledMap = {
  [key in FormModuleKeys]: (
    formTemplate: FormTemplateType,
    schemas?: InventorySchemaItem[],
  ) => boolean;
};

enum ModalViews {
  MAIN = 'Main',
  CI = 'CI',
}

interface Props {
  open: boolean;
  onClose: () => void;
  addFormModule: (
    type: string,
    inspectionId?: string,
    schemaId?: string,
  ) => void;
}

const moduleDisabledMap: ModuleDisabledMap = {
  [FormModuleKeys.FHWA]: (formTemplate: FormTemplateType) =>
    formTemplateContainsAnyModule(formTemplate),
  [FormModuleKeys.CI]: (
    formTemplate: FormTemplateType,
    schemas: InventorySchemaItem[],
  ) => {
    if (formTemplateContainsAnyModule(formTemplate)) {
      return true;
    }

    if (formTemplate.schemaId) {
      const schemaSupported = schemas.find(
        schema => schema.id === formTemplate.schemaId,
      )?.ciEnabled;

      return !schemaSupported;
    }

    const hasCiEnabledSchema = schemas.reduce((acc, schema) => {
      return acc || schema.ciEnabled;
    }, false);

    return !hasCiEnabledSchema;
  },
  [FormModuleKeys.PCI]: (
    formTemplate: FormTemplateType,
    schemas: InventorySchemaItem[],
  ) => {
    if (formTemplateContainsAnyModule(formTemplate)) {
      return true;
    }

    if (formTemplate.schemaId) {
      const schemaSupported = schemas.find(
        schema => schema.id === formTemplate.schemaId,
      )?.pciEnabled;

      return !schemaSupported;
    }

    const hasPciEnabledSchema = schemas.reduce((acc, schema) => {
      return acc || schema.pciEnabled;
    }, false);

    return !hasPciEnabledSchema;
  },
  [FormModuleKeys.PCI_SUMMARY]: null,
};

const FormModulesModal = ({ open, onClose, addFormModule }: Props) => {
  const preferences = usePreferences();
  const { formTemplate, schemas, selectedSchema } = useContext(
    FormBuilderContext,
  );

  const [view, setView] = useState<ModalViews>(ModalViews.MAIN);
  const [selectedModule, setSelectedModule] = useState<FormModuleKeys>();
  const [selectedInspectionId, setSelectedInspectionId] = useState<string>('');

  const [ciLoading, setCiLoading] = useState<boolean>(false);
  const [filteredCiOptions, setFilteredCiOptions] = useState<
    ConditionInspection[]
  >([]);

  const formModules = getFormModulesList(preferences);

  useEffect(() => {
    const getConditionInspections = async () => {
      setCiLoading(true);

      const { data = [] } = await api.get(`${INVENTORY_SCHEMAS_ENDPOINT}/ci`);

      const filteredBySelectedSchema = isNilOrEmpty(selectedSchema)
        ? data
        : data.filter(item => item.id === selectedSchema);

      setFilteredCiOptions(filteredBySelectedSchema);

      if (filteredBySelectedSchema.length === 1) {
        setSelectedInspectionId(filteredBySelectedSchema[0].inspectionId);
      }

      setCiLoading(false);
    };

    if (view === ModalViews.CI) {
      getConditionInspections();
    } else {
      setFilteredCiOptions([]);
    }
  }, [view]);

  const resetState = () => {
    setView(ModalViews.MAIN);
    setSelectedModule(null);
    setFilteredCiOptions([]);
    setSelectedInspectionId('');
  };

  const handleClick = (formModule: FormModuleKeys) => {
    if (formModule === FormModuleKeys.CI) {
      setView(ModalViews.CI);
    }

    setSelectedModule(formModule);
  };

  const handleClose = () => {
    resetState();
    onClose();
  };

  const confirm = () => {
    if (selectedModule === FormModuleKeys.CI) {
      const inspection = filteredCiOptions.find(
        option => option?.inspectionId === selectedInspectionId,
      );

      addFormModule(selectedModule, inspection?.inspectionId, inspection?.id);
    } else {
      addFormModule(selectedModule);
    }

    handleClose();
  };

  const isSavedDisabled =
    isNilOrEmpty(selectedModule) ||
    (selectedModule === FormModuleKeys.CI &&
      isNilOrEmpty(selectedInspectionId));

  const content = {
    [ModalViews.MAIN]: (
      <>
        <div styleName="form-module-content">
          Form modules are templates with predefined questions for common types
          of inspections.
        </div>
        {formModules.map((formModule: FormModuleType) => {
          const isDisabled = moduleDisabledMap[formModule.type]
            ? moduleDisabledMap[formModule.type](formTemplate, schemas)
            : false;

          return (
            <FormModuleTile
              key={formModule.type}
              selectedModule={selectedModule}
              selectModule={handleClick}
              formModule={formModule}
              disabled={isDisabled}
            />
          );
        })}
      </>
    ),
    [ModalViews.CI]: (
      <div styleName="ci-view">
        <FormModuleTile
          key={ciFormModule.type}
          selectedModule={null}
          selectModule={() => {}}
          formModule={ciFormModule}
          displayOnly
        />
        <div>
          This inspection's inventory type will be assigned to the form.
        </div>
        <Select
          label="Select Inspection"
          value={selectedInspectionId}
          onChange={event => setSelectedInspectionId(event.target.value)}
          disabled={ciLoading}
          defaultValue=""
          MenuProps={{ style: styles.menu }}
          style={styles.select}
        >
          {filteredCiOptions.map(option => {
            return (
              <MenuItem key={option.inspectionId} value={option.inspectionId}>
                {option.name}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    ),
  };

  return (
    <Modal
      title="Insert Module"
      onConfirm={confirm}
      onCancel={handleClose}
      confirmButtonText="Save"
      open={open}
      disabled={isSavedDisabled}
    >
      {content[view]}
    </Modal>
  );
};

export default FormModulesModal;
