export enum CsvErrorLevel {
  INFO = 'INFO',
  WARNING = 'WARNING',
  CRITICAL = 'CRITICAL',
}

export interface CsvErrorItem {
  row?: number;
  column?: string;
  message: string;
  level?: CsvErrorLevel;
}
