import React, { useContext } from 'react';
import * as R from 'ramda';

import WorkOrderPreviewContext, {
  View,
} from '@atom/components/workOrderPreview/WorkOrderPreviewContext';
import { Icon, IconButton } from '@atom/mui';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import CustomSection from './CustomSection';
import DetailSection from './DetailSection';
import LocationSection from './LocationSection';
import SummarySection from './SummarySection';

import './infoView.css';

const InfoView = () => {
  const { setActiveView, workOrderDetail } = useContext(
    WorkOrderPreviewContext,
  );

  const showCustomFields =
    hasRolePermissions(ROLE_SETS.INSPECTOR) &&
    (!workOrderDetail.workTemplateId || R.length(workOrderDetail.fields) > 0);

  return (
    <>
      <div styleName="header-container">
        <IconButton onClick={() => setActiveView(View.MAIN)}>
          <Icon>arrow_back</Icon>
        </IconButton>
        <div styleName="title">Info</div>
      </div>
      <div styleName="info-section">
        {workOrderDetail.location && <LocationSection />}
        <SummarySection />
        {showCustomFields && <CustomSection />}
        <DetailSection />
      </div>
    </>
  );
};

export default InfoView;
