import React, { useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import { WORK_ORDER_BULK_CREATE } from '@atom/graph/work';
import {
  Button,
  Checkbox,
  DatePicker,
  Icon,
  Modal,
  Select,
  Snackbar,
  TextField,
} from '@atom/mui';
import { InventoryCategoryTree } from '@atom/types/inventory';
import { PolicyAction } from '@atom/types/policy';
import {
  WorkOrderBulkCounts,
  WorkOrderBulkCreateInput,
  WorkOrderCustomDueDate,
  WorkOrderCustomDueDateStatus,
  WorkOrderCustomDueDateUnit,
} from '@atom/types/work';
import {
  SearchWorkTemplate,
  WorkTemplateListItem,
} from '@atom/types/workTemplate';
import { getCategoryDescendantSchemaIds } from '@atom/utilities/categoryUtilities';
import history from '@atom/utilities/history';
import { convertDateToMillisGMTMidday } from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import CreateWorkTemplateSelection from './CreateWorkTemplateSelection';

import './createWorkModals.css';

const { MenuItem } = Select;

interface Props {
  open: boolean;
  onClose: () => void;
  category: InventoryCategoryTree;
}

enum View {
  WORK_TEMPLATE,
  DUE_DATE,
  CREATE_CONFIRMATION,
}

type WorkTemplate = WorkTemplateListItem | SearchWorkTemplate;

const DEFAULT_CUSTOM_DUE_DATE: WorkOrderCustomDueDate = {
  duration: 1,
  unit: WorkOrderCustomDueDateUnit.Year,
  status: WorkOrderCustomDueDateStatus.Completed,
};

const styles = {
  modal: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
  },
  icon: {
    marginRight: '2rem',
  },
  duration: {
    width: '2rem',
  },
  unit: {
    width: '5rem',
  },
  status: {
    width: '6rem',
  },
};

const CreateWorkForCategoryModal = ({ open, onClose, category }: Props) => {
  const [view, setView] = useState<View>(View.WORK_TEMPLATE);
  const [dueDate, setDueDate] = useState<Date>(new Date());
  const [customDueDate, setCustomDueDate] = useState<WorkOrderCustomDueDate>();
  const [workTemplate, setWorkTemplate] = useState<WorkTemplate>(null);

  const [bulkCreateWork, { loading }] = useMutation<
    {
      workOrderBulkCreate: WorkOrderBulkCounts;
    },
    { input: WorkOrderBulkCreateInput }
  >(WORK_ORDER_BULK_CREATE);

  const resetState = () => {
    setView(View.WORK_TEMPLATE);
    setDueDate(new Date());
    setCustomDueDate(null);
    setWorkTemplate(null);
  };

  const isValid = () => {
    return !!workTemplate;
  };

  // determine if category has single descendant schema
  // if it does allow work templates with that schema for bulk creation
  const schemaId = useMemo(() => {
    if (!open) {
      return category.schemaId;
    }

    const descendantSchemaIds = getCategoryDescendantSchemaIds([category]);

    return new Set(descendantSchemaIds).size === 1
      ? descendantSchemaIds[0]
      : null;
  }, [open, category]);

  const handleSubmit = async () => {
    try {
      const res = await bulkCreateWork({
        variables: {
          // @ts-ignore
          input: R.reject(isNilOrEmpty, {
            customDueDate,
            dueDate: dueDate ? convertDateToMillisGMTMidday(dueDate) : null,
            workTemplateId: workTemplate.id,
            categoryId: category.id,
          }),
        },
      });

      Snackbar.info({
        message: `Created ${
          res?.data?.workOrderBulkCreate?.totalWorkOrders || 0
        } work.`,
        action: 'View',
        onActionClick: () => history.push('/workOrders'),
      });

      onClose();
    } catch (error) {
      Snackbar.error({
        message:
          'Something went wrong. Please try again or contact administrator.',
      });
    }
  };

  const handleCustomDateToggle = () => {
    if (R.isNil(customDueDate)) {
      setCustomDueDate(DEFAULT_CUSTOM_DUE_DATE);
      setDueDate(null);
    } else {
      setCustomDueDate(null);
      setDueDate(new Date());
    }
  };

  const handleCustomDueDateChange = (
    update: Partial<WorkOrderCustomDueDate>,
  ) => {
    setCustomDueDate(current => ({ ...current, ...update }));
  };

  const footers: { [key in View]: React.ReactNode } = {
    [View.WORK_TEMPLATE]: <Button onClick={onClose}>Cancel</Button>,
    [View.DUE_DATE]: (
      <div styleName="footer">
        <div>
          <Button onClick={resetState}>Back</Button>
        </div>
        <div>
          <Button onClick={onClose} style={{ marginRight: '0.5rem' }}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!isValid()}
            onClick={() => setView(View.CREATE_CONFIRMATION)}
          >
            Create
          </Button>
        </div>
      </div>
    ),
    [View.CREATE_CONFIRMATION]: (
      <div>
        <Button onClick={onClose} style={{ marginRight: '0.5rem' }}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={loading}
          onClick={handleSubmit}
        >
          Create
        </Button>
      </div>
    ),
  };

  return (
    <Modal
      title={`Create Work for ${category.name}`}
      open={open}
      onCancel={onClose}
      onConfirm={handleSubmit}
      footer={footers[view]}
      onExited={resetState}
      contentStyle={
        view === View.WORK_TEMPLATE
          ? { ...styles.modal, height: '50vh', padding: '2rem 3.875rem' }
          : styles.modal
      }
    >
      {view === View.WORK_TEMPLATE ? (
        <CreateWorkTemplateSelection
          onClick={template => {
            setWorkTemplate(template);
            setView(View.DUE_DATE);
          }}
          label="Start with a work template:"
          emptyContentLabel="There are 0 matching work templates."
          schemaId={schemaId}
          hasSchema={false}
          action={PolicyAction.BULK_CREATE}
        />
      ) : view === View.DUE_DATE ? (
        <>
          <div styleName="modal-row">
            <Icon children="event" style={styles.icon} />
            <DatePicker
              name="dueDate"
              label="Due Date"
              value={dueDate}
              placeholder="mm/dd/yyyy"
              onChange={(date: Date) => setDueDate(date || null)}
              disabled={!R.isNil(customDueDate)}
            />
          </div>
          <div styleName="modal-row checkbox">
            <Checkbox
              checked={!R.isNil(customDueDate)}
              onChange={handleCustomDateToggle}
            />
            <div styleName="modal-checkbox-label">Custom Due Date</div>
          </div>
          {!R.isNil(customDueDate) && (
            <div styleName="modal-custom-due-date">
              <TextField
                name="duration"
                type="number"
                inputProps={{
                  min: 1,
                }}
                style={styles.duration}
                value={customDueDate?.duration || ''}
                onChange={({ target: { value } }) =>
                  handleCustomDueDateChange({
                    duration: value ? +value : null,
                  })
                }
              />
              <Select
                style={styles.unit}
                value={customDueDate?.unit}
                onChange={event =>
                  handleCustomDueDateChange({ unit: event.target.value })
                }
              >
                {Object.entries(WorkOrderCustomDueDateUnit).map(
                  ([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {customDueDate?.duration === 1 ? key : `${key}s`}
                    </MenuItem>
                  ),
                )}
              </Select>
              <div styleName="modal-custom-date-label">After work is</div>
              <Select
                style={styles.status}
                value={customDueDate?.status}
                onChange={event =>
                  handleCustomDueDateChange({ status: event.target.value })
                }
              >
                {Object.entries(WorkOrderCustomDueDateStatus).map(
                  ([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {key}
                    </MenuItem>
                  ),
                )}
              </Select>
            </div>
          )}
        </>
      ) : (
        <>
          <div>
            This action will create work for all inventory items in this folder
            and all of its sub-folders. Are you sure?
          </div>
        </>
      )}
    </Modal>
  );
};

export default CreateWorkForCategoryModal;
