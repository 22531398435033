import React, { useContext, useEffect, useMemo } from 'react';
import * as R from 'ramda';

import { Select } from '@atom/mui';
import {
  GrantType,
  Policy,
  PolicyAction,
  PolicyResource,
} from '@atom/types/policy';
import {
  getPolicyActionLabel,
  POLICY_RESOURCE_OPTIONS,
} from '@atom/utilities/policyUtilities';

import PolicyGrantsSelection from './PolicyGrantsSelection';
import PolicyGrantTypeSelection from './PolicyGrantTypeSelection';
import PolicyModalContext from './PolicyModalContext';

import './policyModals.css';

const { MenuItem } = Select;

interface Props {
  isEdit: boolean;
}

const renderValue = (value: PolicyResource) => {
  return !value ? (
    'Select'
  ) : (
    <div styleName="selected">
      <span style={{ marginRight: '1rem' }}>
        {POLICY_RESOURCE_OPTIONS[value]?.icon}
      </span>
      {POLICY_RESOURCE_OPTIONS[value]?.label}
    </div>
  );
};

const getActions = (
  resource: PolicyResource,
  existingPolicies: Policy[],
  grantType: GrantType,
  existingPolicy?: Policy,
): React.ReactNode => {
  const option = POLICY_RESOURCE_OPTIONS[resource];

  if (!option) {
    return null;
  }

  const disabledActions = new Set<PolicyAction>(
    existingPolicies
      .filter(({ grant }) => grant.type === grantType)
      .map(({ action }) => action),
  );

  // allow policy being edited to have the same action
  if (existingPolicy) {
    disabledActions.delete(existingPolicy.action);
  }

  // BUDGETING policies get operation actions options from sub source
  const actions =
    option.value !== PolicyResource.BUDGETING
      ? R.pathOr([], ['actions'], option)
      : R.pathOr([], [grantType, 'actions'], POLICY_RESOURCE_OPTIONS);

  return (
    actions
      .map(action => ({ action, label: getPolicyActionLabel(action) }))
      // eslint-disable-next-line id-length
      .sort((a, b) => a.label.localeCompare(b.label))
      .map(({ action, label }) => (
        <MenuItem
          key={action}
          value={action}
          disabled={disabledActions.has(action)}
        >
          {disabledActions.has(action) ? (
            <div styleName="action-row">
              <div>{label}</div>
              <div styleName="action-exists">Already Exists</div>
            </div>
          ) : (
            label
          )}
        </MenuItem>
      ))
  );
};

const PolicyModalMain = ({ isEdit }: Props) => {
  const { state, updateState, policy } = useContext(PolicyModalContext);

  const {
    resource,
    actions,
    grantType,
    existingPolicies,
    loadingExistingPolicies,
  } = state;

  const availableGrantTypes = useMemo(() => {
    return POLICY_RESOURCE_OPTIONS[resource]?.grantTypes || [];
  }, [resource]);

  const handleResourceChange = (updatedResource: PolicyResource) => {
    const option = POLICY_RESOURCE_OPTIONS[updatedResource];
    const updatedGrantType =
      option?.grantTypes?.length === 1 ? option.grantTypes[0] : null;

    updateState({
      resource: updatedResource,
      grantType: updatedGrantType,
      actions: [],
      grants: [],
    });
  };

  const availableActions = useMemo(() => {
    return getActions(resource, existingPolicies, grantType, policy);
  }, [resource, existingPolicies, grantType, policy]);

  useEffect(() => {
    // Auto-select action on Create if only one operation exists
    const option =
      POLICY_RESOURCE_OPTIONS[
        resource === PolicyResource.BUDGETING ? grantType : resource
      ];
    if (!isEdit && option?.actions?.length === 1) {
      const hasExisting = existingPolicies.some(
        existingPolicy => existingPolicy.action === option?.actions[0],
      );
      updateState({ actions: hasExisting ? [] : [option?.actions[0]] });
    }
  }, [availableActions, existingPolicies]);

  return (
    <>
      <div styleName="input-container">
        <Select
          label="Source"
          value={resource || ''}
          onChange={event => handleResourceChange(event.target.value)}
          renderValue={renderValue}
          placeholder="Select"
          loading={loadingExistingPolicies}
          displayEmpty
        >
          {Object.values(POLICY_RESOURCE_OPTIONS)
            .filter(({ hide, subSourceOnly }) => !hide && !subSourceOnly)
            .map(option => (
              <MenuItem key={option.value} value={option.value}>
                <span style={{ marginRight: '1rem' }}>{option.icon}</span>
                {option.label}
              </MenuItem>
            ))}
        </Select>
      </div>
      {!!resource && availableGrantTypes.length > 1 && (
        <div styleName="input-container">
          <PolicyGrantTypeSelection />
        </div>
      )}
      {!!grantType && <PolicyGrantsSelection />}
      {!!grantType && !!resource && (
        <div styleName="input-container">
          <Select
            multiple={!isEdit}
            label="Operations"
            value={actions || []}
            onChange={event => {
              if (isEdit) {
                updateState({ actions: [event.target.value] });
              } else {
                updateState({ actions: event.target.value });
              }
            }}
            disabled={loadingExistingPolicies}
          >
            {availableActions}
          </Select>
        </div>
      )}
    </>
  );
};

export default PolicyModalMain;
