import { convertDateToMillisGMTMidday } from '@atom/utilities/timeUtilities';

// Only use matchValue and nestedField from SubFields,
// since we don't need to send __typename to the API.
const convertSubFields = fields =>
  fields.map(({ matchValue, nestedField }) => ({ matchValue, nestedField }));

// Skip __typename for summaries too:
const convertSummary = summary =>
  summary.map(({ title, contents }) => ({ title, contents }));

const fieldsToBulk = fields =>
  fields.map(
    ({
      id,
      dataType,
      title,
      value,
      enumeration,
      defaultValue,
      subFields,
      units,
    }) => ({
      id,
      dataType,
      title,
      value,
      enumeration,
      defaultValue,
      subFields: convertSubFields(subFields),
      units,
    }),
  );

const tasksToBulk = tasks =>
  tasks.map(({ id, description, dueDate, startTime, fields, users }) => ({
    taskId: id,
    description,
    dueDate: convertDateToMillisGMTMidday(dueDate),
    startDate: convertDateToMillisGMTMidday(startTime),
    fields: fieldsToBulk(fields),
    userIds: users.map(user => user.id),
  }));

export const workOrderToBulkMapCreate = ({
  workTemplateId,
  assets,
  statusId,
  dueDate,
  completionDate,
  completedBy,
  closedDate,
  closedBy,
  summary,
  fields,
  tasks,
}) => ({
  workTemplateId,
  assetIds: assets.map(asset => asset.id),
  statusId,
  dueDate: convertDateToMillisGMTMidday(dueDate),
  completionDate: convertDateToMillisGMTMidday(completionDate),
  completedBy: completedBy?.id,
  closedDate: convertDateToMillisGMTMidday(closedDate),
  closedBy: closedBy?.id,
  summary: convertSummary(summary),
  fields: fieldsToBulk(fields),
  tasks: tasksToBulk(tasks),
});

export const hasAssignees = tasks =>
  tasks.reduce((count, task) => task.users.length + count, 0) > 0;
