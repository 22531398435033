import React, { useCallback } from 'react';
import {
  TablePagination as MUITablePagination,
  TablePaginationProps as MUITablePaginationProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

export type TablePaginationProps = {
  /**
   * 1 based index number of current page
   */
  page: number;
  onPageChange: (page: number) => void;
} & Omit<MUITablePaginationProps, 'page' | 'onChangePage'>;

const useStyles = makeStyles({
  toolbar: () => ({
    /**
     * overflow will be hidden by TableFooter so simply set max width
     * to ensure it always "appears" full width
     */
    padding: 0,
  }),
});

const TablePagination = (props: TablePaginationProps) => {
  const classes = useStyles(props);

  const { page, onPageChange, ...data } = props;

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<any>, updatedPage: number) => {
      onPageChange(updatedPage + 1);
    },
    [onPageChange],
  );

  return (
    <MUITablePagination
      page={page - 1}
      onPageChange={handlePageChange}
      classes={{
        toolbar: classes.toolbar,
        ...props.classes,
      }}
      {...data}
    />
  );
};

export default TablePagination;
