import React, { useState } from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { UserGroupBudget } from '@atom/types/userGroups';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';

import AddEditBudgetModal from './AddEditBudgetModal';
import DeleteBudgetModal from './DeleteBudgetModal';

import './userGroupBudgets.css';

interface Props {
  budget: UserGroupBudget;
}

const UserGroupBudgetTile = ({ budget }: Props) => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  return (
    <>
      <div styleName="budget-tile">
        <div styleName="budget-title-container">
          <Icon color={colors.brand.blue}>monetization_on</Icon>
          <div styleName="title">{`${budget.name}: ${numberToLocaleString(
            budget.rate,
          )}/Hours`}</div>
        </div>
        {!budget.externalId && (
          <div>
            {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
              <IconButton onClick={() => setOpenEdit(true)}>
                <Icon color={colors.neutral.gray}>edit</Icon>
              </IconButton>
            )}
            {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
              <IconButton onClick={() => setOpenDelete(true)}>
                <Icon color={colors.neutral.gray}>delete</Icon>
              </IconButton>
            )}
          </div>
        )}
      </div>
      <AddEditBudgetModal
        isEdit
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        budget={budget}
      />
      <DeleteBudgetModal
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        budgetId={budget.id}
      />
    </>
  );
};

export default UserGroupBudgetTile;
