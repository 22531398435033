import React from 'react';

import WorkTemplateTree from '@atom/components/common/workTemplateTree/WorkTemplateTree';
import { Icon } from '@atom/mui';
import { PolicyAction } from '@atom/types/policy';
import { WorkOrderType } from '@atom/types/work';
import {
  SearchWorkTemplate,
  WorkTemplateListItem,
} from '@atom/types/workTemplate';
import { getWorkTypeIconSvg } from '@atom/utilities/workTypeIconUtilities';

import './createWorkModals.css';

interface Props {
  onClick: (workTemplate: WorkTemplateListItem | SearchWorkTemplate) => void;
  label?: React.ReactNode;
  emptyContentLabel?: React.ReactNode;
  schemaId?: string;
  hasSchema?: boolean;
  action: PolicyAction;
  type?: WorkOrderType;
}

const styles = {
  icon: {
    fontSize: '38px',
    margin: '1.5rem 0',
  },
};

const CreateWorkTemplateSelection = ({
  onClick,
  label,
  schemaId,
  hasSchema,
  action,
  emptyContentLabel = 'There are 0 work templates.',
  type,
}: Props) => {
  const emptyContent = (
    <div styleName="empty-work-templates-container">
      <Icon style={styles.icon}>
        <img src={getWorkTypeIconSvg(null, null, true)} />
      </Icon>
      <div styleName="empty-work-templates">{emptyContentLabel}</div>
    </div>
  );

  return (
    <>
      {label && <div style={{ marginBottom: '1.25rem' }}>{label}</div>}
      <WorkTemplateTree
        includeSearch
        hasSchema={hasSchema}
        schemaId={schemaId}
        emptyContent={emptyContent}
        onClick={onClick}
        action={action}
        type={type}
      />
    </>
  );
};

export default CreateWorkTemplateSelection;
