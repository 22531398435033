import React from 'react';

import colors from '@atom/styles/colors';

import '../../../../styles/body-pane.css';

// TODO: AM-7339 Remove analytics tab component

const styles = {
  centerPane: {
    backgroundColor: colors.neutral.white,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)',
    width: '80%',
  },
};

const AnalyticsTab = () => {
  return (
    <div styleName="body-container">
      <div styleName="center-pane-container">
        <div style={styles.centerPane}>
          <iframe
            width="100%"
            height="100%"
            src={
              'https://datastudio.google.com/embed/reporting/65de8761-42c2-45b1-9cf1-49021fe593f2/page/yySRC'
            }
            frameBorder="0"
            style={{ border: 0, overflow: 'hidden' }}
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
};

export default AnalyticsTab;
