import React, { useContext, useEffect, useState } from 'react';
import * as R from 'ramda';

import WorkOrderAssetViewContext from '@atom/components/common/workOrderDetail/workOrderAssetView/WorkOrderAssetViewContext';
import { Modal, Progress } from '@atom/mui';
import { SchemaType } from '@atom/types/schema';
import { WorkOrderAssetTreeElement } from '@atom/types/work';
import api from '@atom/utilities/api';
import {
  INVENTORY_SCHEMAS_ENDPOINT,
  WORK_ORDERS_ENDPOINT,
} from '@atom/utilities/endpoints';

import AddElementGroupRow from './AddElementGroupRow';
import AddElementRow from './AddElementRow';

import './addElementModal.css';

const styles = {
  progress: {
    height: '100%',
    alignItems: 'center',
  },
};

interface Props {
  open: boolean;
  closeModal: () => void;
  element: WorkOrderAssetTreeElement;
}

const AddElementModal = ({ open, closeModal, element }: Props) => {
  const { workOrderDetail, refetchWorkOrderAssetTree } = useContext(
    WorkOrderAssetViewContext,
  );

  const [selectedElements, setSelectedElements] = useState<any>({});
  const [loadingSchema, setLoadingSchema] = useState<boolean>(false);
  // @ts-ignore
  const [schema, setSchema] = useState<SchemaType>({});

  useEffect(() => {
    const getSchema = async () => {
      const schemaEndpoint = `${INVENTORY_SCHEMAS_ENDPOINT}/${element.schemaId}/tree`;
      const { data } = await api.get(schemaEndpoint);

      setSchema(data);
      setLoadingSchema(false);
    };

    if (open) {
      setLoadingSchema(true);
      getSchema();
    }
  }, [element.schemaId, open]);

  const onCloseModal = () => {
    // @ts-ignore
    setSchema({});
    setSelectedElements({});
    closeModal();
  };

  const toggleElement = (id: string) => {
    if (R.has(id)(selectedElements)) {
      setSelectedElements(R.omit([id], selectedElements));
    } else {
      setSelectedElements({ ...selectedElements, [id]: 1 });
    }
  };

  const updateQuantity = (id: string, quantity: number) => {
    setSelectedElements({ ...selectedElements, [id]: quantity });
  };

  const confirm = async () => {
    if (!R.isEmpty(selectedElements)) {
      const elements = R.keys(selectedElements).reduce((acc, schemaId) => {
        const quantity = selectedElements[schemaId];
        return quantity !== 0 ? [...acc, { schemaId, quantity }] : acc;
      }, []);

      const endpoint = `${WORK_ORDERS_ENDPOINT}/${workOrderDetail.id}/assets/${element.id}/elements`;

      await api.post(endpoint, { elements });
      refetchWorkOrderAssetTree();
    }

    onCloseModal();
  };

  const noSubElement =
    R.isEmpty(schema.elementGroups) || R.isNil(schema.elementGroups);

  const confirmButtonText = noSubElement ? 'Ok' : 'Add';
  const confirmAction = noSubElement ? onCloseModal : confirm;
  const titleText = noSubElement
    ? 'There are no elements under '
    : 'Select elements and quantities from inventory type for ';

  return (
    <Modal
      title="Add elements to asset"
      width="lg"
      onCancel={onCloseModal}
      confirmButtonText={confirmButtonText}
      onConfirm={confirmAction}
      open={open}
    >
      <div styleName="modal-content-container">
        {loadingSchema || R.isEmpty(schema) ? (
          <div styleName="spinner-container">
            <Progress style={styles.progress} size={30} thickness={1.5} />
          </div>
        ) : (
          <>
            <div>
              {titleText}
              <span styleName="element-name-text">{element.name}</span>
            </div>
            {!noSubElement && (
              <div styleName="tree-container">
                {schema.elementGroups.map((elementItem, index) => {
                  return elementItem.isGroup ? (
                    <AddElementGroupRow
                      key={`${elementItem.name}${index}`}
                      elementGroup={elementItem}
                      indentation={0}
                      toggleElement={toggleElement}
                      updateQuantity={updateQuantity}
                    />
                  ) : (
                    <AddElementRow
                      key={`${elementItem.name}${index}`}
                      // @ts-ignore
                      element={elementItem.elements[0]}
                      indentation={0}
                      toggleElement={toggleElement}
                      updateQuantity={updateQuantity}
                    />
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default AddElementModal;
