import React, { useRef, useState } from 'react';
import * as R from 'ramda';

import { Popover } from '@atom/components/common/mui';
import TimeEntryStatusIcon from '@atom/components/common/timeEntryStatusIcon/TimeEntryStatusIcon';
import { TimeEntriesConnection, TimeEntryStatus } from '@atom/types/timeEntry';
import {
  convertMillisToExactHours,
  convertMillisToHours,
} from '@atom/utilities/timeUtilities';

import './timeHeaders.css';

interface Props {
  timeEntriesConnection: TimeEntriesConnection;
}

const SummaryHeader = ({ timeEntriesConnection }: Props) => {
  const status = timeEntriesConnection?.status || TimeEntryStatus.draft;
  const hasNoTimeEntries = R.isEmpty(timeEntriesConnection?.timeEntries);
  const wageTypeSummaries = R.pathOr([], ['summary'], timeEntriesConnection);
  const popoverAnchor = useRef(null);

  const [openCutoffTypes, setOpenCutoffTypes] = useState<boolean>(false);

  return (
    <div styleName="summary-row">
      <div styleName="common-cells">
        <div styleName="summary-item">
          <div styleName="summary-item-title status">Status</div>
          {hasNoTimeEntries ? (
            <div styleName="empty-title">Not Started</div>
          ) : (
            <div styleName="status-container">
              <TimeEntryStatusIcon status={status} />
              <div styleName="status-name">{status}</div>
            </div>
          )}
        </div>
        <div styleName="summary-item">
          <div styleName="summary-item-title">Total Time</div>
          <div styleName="summary-item-value">
            {convertMillisToExactHours(
              timeEntriesConnection?.totalDuration || 0,
            )}
          </div>
        </div>
        <div styleName="summary-item">
          <div styleName="summary-item-title">Total Work Templates</div>
          <div styleName="summary-item-value">
            {timeEntriesConnection?.totalWorkTypes || 0}
          </div>
        </div>
        <div styleName="summary-item">
          <div styleName="summary-item-title">Total Work</div>
          <div styleName="summary-item-value">
            {timeEntriesConnection?.totalWorkOrders || 0}
          </div>
        </div>
      </div>
      <div styleName="elastic-cells">
        {wageTypeSummaries.map(type => (
          <div styleName="summary-item">
            <div styleName="summary-item-title">{type.title}</div>
            <div styleName="summary-item-value">
              {convertMillisToHours(type.duration) || 0} Hours
            </div>
          </div>
        ))}
      </div>
      <div styleName="link-cell">
        <div styleName="summary-item" ref={popoverAnchor}>
          <div styleName="summary-item-value">
            {!R.isEmpty(wageTypeSummaries) && (
              <button onClick={() => setOpenCutoffTypes(true)}>
                View All Wage Types ({wageTypeSummaries.length})
              </button>
            )}
          </div>
        </div>
        <Popover
          open={openCutoffTypes}
          anchorEl={popoverAnchor.current}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          onClose={() => setOpenCutoffTypes(false)}
        >
          <ul styleName="cutoff-types-list">
            {wageTypeSummaries.map(type => (
              <li>
                {type.title}:{' '}
                <strong>{convertMillisToHours(type.duration)} Hours</strong>
              </li>
            ))}
          </ul>
        </Popover>
      </div>
    </div>
  );
};

export default SummaryHeader;
