import { useEffect } from 'react';
import { ApolloError } from '@apollo/client';

import { Snackbar } from '@atom/mui';

type ErrorMessageMap = {
  [code: number]: string;
};

const DEFAULT_ERROR_MESSAGE = 'An unknown error occurred. Please try again.';

export const useErrorNotification = (
  error: ApolloError,
  errorMessageMap: ErrorMessageMap,
  defaultMessage?: string,
) => {
  useEffect(() => {
    if (error) {
      // @ts-ignore
      const statusCode = error.networkError?.statusCode;

      const message =
        errorMessageMap[statusCode] || defaultMessage || DEFAULT_ERROR_MESSAGE;

      Snackbar.error({
        message,
      });
    }
  }, [error]);
};
