import React from 'react';

// @ts-ignore
import workTypeLocationIcon from '@atom/components/common/svgIcons/workTypeLocationIcon.svg';

import './workTemplateInfo.css';

const WorkTemplateMap = () => {
  return (
    <div styleName="location-section">
      <div styleName="location-content">
        <img styleName="location-image" src={workTypeLocationIcon} />
        <div styleName="location-title">
          Location is determined during work creation.
        </div>
        <div styleName="location-text">
          When location is enabled in work, this section will display the
          location of that work on a map. For work with inventory, the map will
          display the location of the work and the location of the inventory in
          the same location, unless modified.
        </div>
      </div>
    </div>
  );
};

export default WorkTemplateMap;
