import React, { useContext } from 'react';

import TeamContext, { View } from '@atom/components/teamPortal/TeamContext';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import './teamPortal.css';

const TeamHeader = () => {
  const {
    isMapView,
    setIsMapView,
    csvDownloadEndpoint,
    activeView,
  } = useContext(TeamContext);

  const displayDownloadLink =
    hasRolePermissions(ROLE_SETS.INSPECTOR) &&
    !isMapView &&
    activeView !== View.DIRECTORY;

  return (
    <div styleName="header-container">
      <div styleName="name-container">Team</div>
      <div>
        {displayDownloadLink && (
          <IconButton
            tooltip="Download CSV"
            // @ts-ignore
            href={csvDownloadEndpoint}
            download
          >
            <Icon color={colors.neutral.white}>file_download</Icon>
          </IconButton>
        )}
        <IconButton
          tooltip={`${isMapView ? 'List' : 'Map'} View`}
          onClick={() => setIsMapView(!isMapView)}
        >
          <Icon color={colors.neutral.white}>{isMapView ? 'list' : 'map'}</Icon>
        </IconButton>
      </div>
    </div>
  );
};

export default TeamHeader;
