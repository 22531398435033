import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import AddSummaryModal from '@atom/components/common/workOrderDetail/workOrderSummary/AddSummaryModal';
import InheritanceLockIcon from '@atom/components/workTemplate/InheritanceLockIcon';
import WorkTemplateContext, {
  WorkTemplateActionTypes,
} from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_UPDATE } from '@atom/graph/workTemplate';
import { Icon, IconButton } from '@atom/mui';
import { Summary } from '@atom/types/work';
import {
  InheritedComponentType,
  WorkOrderTemplateUpdateInput,
  WorkTemplate,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { isComponentInherited } from '@atom/utilities/workTemplateUtilities';

import WorkTemplateSummaryRow from './WorkTemplateSummaryRow';

import './workTemplateInfo.css';

const WorkTemplateSummary = () => {
  const { workTemplate, dispatch } = useContext(WorkTemplateContext);

  const [open, setOpen] = useState<boolean>(false);
  const [summary, setSummary] = useState<Summary[]>(workTemplate.summary || []);

  const [updateWorkTemplate] = useMutation<
    { workOrderTemplateUpdate: WorkTemplate },
    { input: WorkOrderTemplateUpdateInput }
  >(WORK_TEMPLATE_UPDATE);

  const isInherited = isComponentInherited(
    InheritedComponentType.SUMMARY,
    workTemplate?.inheritedComponents || [],
  );

  useEffect(() => {
    setSummary(workTemplate.summary);
  }, [workTemplate.summary]);

  const updateSummary = async (updatedSummary: Summary[]) => {
    const { data } = await updateWorkTemplate({
      variables: {
        input: {
          workOrderTemplateId: workTemplate.id,
          summary: updatedSummary.map(item => R.omit(['__typename'], item)),
        },
      },
    });

    dispatch({
      type: WorkTemplateActionTypes.UPDATE_WORK_TEMPLATE_PROPERTY,
      data: {
        property: 'summary',
        value: data?.workOrderTemplateUpdate?.summary,
      },
    });
  };

  const addSummary = (newSummary: Summary) => {
    updateSummary([...summary, newSummary]);
  };

  const isDisabled =
    workTemplate?.published || doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  const titleStyle = isInherited ? 'summary-title disabled' : 'summary-title';

  return (
    <>
      <div styleName="section-header">
        <div styleName={titleStyle}>Summary</div>
        {isInherited ? (
          <InheritanceLockIcon
            tooltip="Inherited Summary Section cannot be removed or edited."
            placement="bottom-end"
          />
        ) : (
          <IconButton onClick={() => setOpen(true)} disabled={isDisabled}>
            <Icon>add</Icon>
          </IconButton>
        )}
      </div>
      <div styleName="section-content summary">
        {R.isEmpty(summary) ? (
          <div styleName="no-summary">There is no summary.</div>
        ) : (
          summary.map((summaryItem: Summary, index: number) => (
            <WorkTemplateSummaryRow
              key={`${summaryItem.title}-${index}`}
              summary={summaryItem}
              summaries={summary}
              index={index}
              updateSummary={updateSummary}
            />
          ))
        )}
      </div>
      <AddSummaryModal
        open={open}
        onClose={() => setOpen(false)}
        addSummary={addSummary}
      />
    </>
  );
};

export default WorkTemplateSummary;
