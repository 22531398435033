import React from 'react';
import * as R from 'ramda';

import { gwrrSchema } from '@atom/constants/customReportSchemaConstants';
import { InventoryAssetTreeStateType } from '@atom/types/inventory';

import ElementAssessmentRow from './ElementAssessmentRow';
import ElementAssessmentRowDeck from './ElementAssessmentRowDeck';

import '../workOrderDetailPdfReport.css';

interface Props {
  elements: InventoryAssetTreeStateType[];
  elementName: string;
  elementGroupName?: string;
}

const ElementAssessmentTable = ({ elements, elementName }: Props) => {
  const containsSpecificAttributes = (attributeIds: string[]) => {
    return elements.reduce((acc, element) => {
      const hasAttributes = R.keys(element.attributes).reduce(
        (innerAcc, attributeId) => {
          return attributeIds.includes(String(attributeId)) || innerAcc;
        },
        false,
      );

      return hasAttributes || acc;
    }, false);
  };

  const specificAttributeIds = R.values(
    R.pick(['boredAtId', 'shellId', 'voidId'], gwrrSchema.poolAttributes),
  );

  const showColumns = containsSpecificAttributes(specificAttributeIds);

  const schemaIds = R.values(
    showColumns
      ? gwrrSchema.poolAttributes
      : R.omit(['boredAtId', 'shellId', 'voidId'], gwrrSchema.poolAttributes),
  );

  const getElementValueRows = () =>
    elements.map(element =>
      elementName.includes('Deck') ? (
        <ElementAssessmentRowDeck
          element={element}
          schemaIds={Object.values(schemaIds)}
        />
      ) : (
        <ElementAssessmentRow
          element={element}
          schemaIds={Object.values(schemaIds)}
        />
      ),
    );

  return (
    <div styleName="table-wrapper">
      <table>
        <thead>
          <tr>
            <th colSpan={schemaIds.length + 1}>
              <div styleName="table-header">
                {elementName} Element Assessment Details
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr styleName="table-subheader">
            <th styleName="table-header-cell">Element</th>
            <th styleName="table-header-cell">Condition Rating</th>
            {showColumns && (
              <>
                <th styleName="table-header-cell">Bored At</th>
                <th styleName="table-header-cell">Shell</th>
                <th styleName="table-header-cell">Void</th>
              </>
            )}
            <th styleName="table-header-cell comment">Comment</th>
            <th styleName="table-header-cell">Repair Recommendation</th>
            <th styleName="table-header-cell">Priority Rating</th>
            <th styleName="table-header-cell">Assessment Flag</th>
          </tr>
          {getElementValueRows()}
        </tbody>
      </table>
    </div>
  );
};

export default ElementAssessmentTable;
