import actionTypes from '@atom/actions/actionTypes';
import { Portal } from '@atom/types/portals';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import initialState from './initialState';

export default function preferencesReducer(
  state = initialState.preferences,
  action,
) {
  switch (action.type) {
    case actionTypes.LOGGED_IN_USER_GET_SUCCESS: {
      return {
        ...action.data.preferences,
        ...(isNilOrEmpty(action.data.preferences.landingPortal) && {
          landingPortal: Portal.DASHBOARD,
        }),
      };
    }
    default: {
      return state;
    }
  }
}
