import React from 'react';

import { DashboardCard } from '@atom/types/dashboard';

import { getCardHeight } from '../dashboardUtilities';

import CardAssetCount from './CardAssetCount';
import CardCustomEmbed from './CardCustomEmbed';
import CardPendingUserRequests from './CardPendingUserRequests';
import CardRecentlyUpdated from './CardRecentlyUpdated';
import CardWorkOrderTable from './CardWorkOrderTable';

import '../dashboardCard.css';

interface Props {
  card: DashboardCard;
  cardData?: any;
  setCardData?: any;
}

const CardContent = ({ card, cardData, setCardData }: Props) => {
  const style = {
    height: getCardHeight(card),
  };
  switch (card.type) {
    case 'PENDING_USERS':
      return <CardPendingUserRequests cardData={cardData} card={card} />;
    case 'ASSET_COUNT':
      return <CardAssetCount cardData={cardData} card={card} />;
    case 'RECENTLY_UPDATED':
      return <CardRecentlyUpdated cardData={cardData} card={card} />;
    case 'EMBED':
      return <CardCustomEmbed card={card} embedLink={cardData} />;
    case 'OPEN_WORK':
    case 'PAST_DUE_WORK':
      return (
        <CardWorkOrderTable
          cardData={cardData}
          setCardData={setCardData}
          card={card}
        />
      );
    default:
      return (
        <div style={style} styleName="card-content">
          {card.title} is not yet handled
        </div>
      );
  }
};

export default CardContent;
