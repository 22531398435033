import * as React from 'react';
import * as R from 'ramda';

import WorkOrderStatusPill from '@atom/components/common/workOrderDetail/workOrderStatusPill/WorkOrderStatusPill';
import { WorkCollectionItem } from '@atom/types/work';
import history from '@atom/utilities/history';
import timeUtilities from '@atom/utilities/timeUtilities';
import userUtilities from '@atom/utilities/userUtilities';

import './mapDrawerContent.css';

interface Props {
  work: WorkCollectionItem;
}

const WorkRow = ({ work }: Props) => {
  const assigneeCount = userUtilities.rollUpTaskUsers(work.tasks).length;
  const taskCount = R.isEmpty(work.tasks) ? 0 : work.tasks.length;

  return (
    <div
      styleName="work-row-container"
      onClick={() => history.push(`/workOrders/${work.id}`)}
    >
      <div styleName="work-row-content">
        <div styleName="work-row-name">{work.name}</div>
        <WorkOrderStatusPill
          statusId={work.statusId}
          className="dashboard-map-list"
        />
        <div styleName="work-row-details-container">
          <div styleName="work-detail-content">
            Due Date: {timeUtilities.setDisplayDate(work.dueDate)}
          </div>
          <div styleName="work-detail-content">Team: {assigneeCount}</div>
          <div styleName="work-detail-content">Tasks: {taskCount}</div>
        </div>
      </div>
    </div>
  );
};

export default WorkRow;
