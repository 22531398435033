import React, { useEffect, useState } from 'react';

import { FhwaModuleElementType } from '@atom/types/form';
import iconUtilities from '@atom/utilities/iconUtilities';

import '../workOrderDetailPdfReport.css';

const FHWA_ATTRIBUTE_MAP: { [key in string]: string } = {
  environment: 'Environment',
  totalQuantity: 'Total Qty.',
  cs1: 'CS1',
  cs2: 'CS2',
  cs3: 'CS3',
  cs4: 'CS4',
};

const styles = {
  attributeWidth: {
    width: '8rem',
  },
};

interface Props {
  element: FhwaModuleElementType;
  attributes: string[];
  indentLevel: number;
}

const FhwaElementTextbox = ({ element, attributes, indentLevel }: Props) => {
  const [fhwaElements, setFhwaElements] = useState<FhwaModuleElementType[]>([]);

  useEffect(() => {
    if (element.elements) {
      const values = element.order.map(id => element.elements[id]);
      setFhwaElements(values);
    }
  }, [element.elements]);

  const elementIcon = iconUtilities.buildFHWAElementIcon(
    element.markerId,
    true,
  );

  const getIndentStyle = () => {
    const BASE_INDENT_REMS = 0.75;
    const NESTED_INDENT_REMS = 1.75;
    const indentPadding = NESTED_INDENT_REMS * indentLevel;
    return {
      paddingLeft: `${BASE_INDENT_REMS + indentPadding}rem`,
    };
  };

  return (
    <table styleName="collapse-border">
      <thead>
        <tr>
          {element.markerId ? (
            <td styleName="fhwa-element-title">
              <div style={getIndentStyle()}>{elementIcon}</div>
              <div>{element.name}</div>
            </td>
          ) : (
            <td styleName="header-title">{element.name}</td>
          )}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div styleName="row content">
              {attributes.map(attribute => (
                <div style={styles.attributeWidth}>
                  <div>{FHWA_ATTRIBUTE_MAP[attribute]}</div>
                  <div>
                    {element.attributes[attribute].value}{' '}
                    {element.attributes[attribute].value
                      ? element.attributes[attribute].unit
                      : ''}
                  </div>
                </div>
              ))}
            </div>
            <div styleName="textbox-table-row content">
              <div style={{ fontWeight: '500' }}>Notes:</div>
              <div>{element.attributes.remarks.value}</div>
            </div>
          </td>
        </tr>
      </tbody>

      {fhwaElements.map(fhwaElement => (
        <FhwaElementTextbox
          element={fhwaElement}
          attributes={[
            'environment',
            'totalQuantity',
            'cs1',
            'cs2',
            'cs3',
            'cs4',
          ]}
          indentLevel={indentLevel + 1}
        />
      ))}
    </table>
  );
};

export default FhwaElementTextbox;
