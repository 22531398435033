import React, { useRef, useState } from 'react';
import * as R from 'ramda';

// @ts-ignore
import favoriteListIcon from '@atom/components/common/svgIcons/favoriteListIcon.svg';
import { Icon, IconButton, List, Popover } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  FavoriteDetail,
  FAVORITES_LIMIT,
  FavoritesList,
} from '@atom/types/favorite';
import { equalSets, toggleFromSet } from '@atom/utilities/setUtilities';

import NewFavoritesListButton from './NewFavoritesListButton';
import NewFavoritesListModal from './NewFavoritesListModal';

import './favorites.css';

const { ListItemButton } = List;

export interface Props {
  item: any;
  createFavorite?: (item: any) => void;
  updateFavorite?: (favorite: FavoriteDetail) => void;
  favorites: FavoriteDetail[];
  favoritesLists: FavoritesList[];
  typeName: string;
  handleAddList: (listName: string) => void;
  canAddFavorite: boolean;
  canAddList: boolean;
}

const existingFavoriteLists = favorite =>
  new Set(R.pathOr([], ['listIds'], favorite));

const FavoritesIconButton = ({
  item,
  createFavorite,
  updateFavorite,
  favorites,
  typeName,
  favoritesLists = [],
  handleAddList,
  canAddFavorite,
  canAddList,
}: Props) => {
  const [showLists, setShowLists] = useState(false);
  const [openNewListModal, setOpenNewListModal] = useState(false);
  const anchor = useRef();

  const favoriteItem = favorites.find(favorite => favorite.id === item.id);
  const isFavorite = !!favoriteItem;

  const [listIdsCart, setListIdsCart] = useState(
    existingFavoriteLists(favoriteItem),
  );

  const iconColor = isFavorite ? colors.brand.blue : colors.neutral.gray;
  const icon = isFavorite ? 'star' : 'star_outline';
  const buildTooltipText = () => {
    if (!isFavorite && canAddFavorite) {
      return 'Add favorite';
    }
    if (!isFavorite && !canAddFavorite) {
      return `You can only favorite ${FAVORITES_LIMIT} ${typeName}`;
    }
    return 'Edit favorite';
  };

  const disableRipple = !canAddFavorite && !isFavorite && showLists;
  const favoritesListNames = favoritesLists.map(list => list.name);

  const closeAndHandleAddList = (listName: string): void => {
    setOpenNewListModal(false);
    handleAddList(listName);
  };

  const onFavoriteClick = event => {
    event.stopPropagation();
    if (!canAddFavorite && !isFavorite) {
      return false;
    }
    setShowLists(true);
    return false;
  };

  const handleListClick = (event: React.MouseEvent, list: FavoritesList) => {
    event.stopPropagation();
    setListIdsCart(toggleFromSet(listIdsCart, list.id));
  };

  const handleNewListClick = event => {
    event.stopPropagation();
    setOpenNewListModal(canAddList);
  };

  const handleListClose = event => {
    event.stopPropagation();
    setShowLists(false);
    if (
      isFavorite &&
      !equalSets(listIdsCart, existingFavoriteLists(favoriteItem))
    ) {
      return updateFavorite({ ...favoriteItem, listIds: [...listIdsCart] });
    }
    if (!isFavorite && listIdsCart.size) {
      return createFavorite({ ...item, listIds: [...listIdsCart] });
    }
    return false;
  };

  const onPopoverClick = event => {
    event.stopPropagation();
  };

  return (
    <div ref={anchor}>
      <div onClick={onFavoriteClick}>
        <IconButton
          disableRipple={disableRipple}
          disableFocusRipple={disableRipple}
          disableTouchRipple={disableRipple}
          tooltip={buildTooltipText()}
        >
          <Icon color={iconColor}>{icon}</Icon>
        </IconButton>
      </div>
      <Popover
        open={showLists}
        anchorEl={anchor.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={handleListClose}
        onClick={onPopoverClick}
      >
        <div styleName="list-popover" data-cy="editFavoritesPopOver">
          <div styleName="list-popover-header">ADD TO LISTS</div>
          <List>
            {favoritesLists.map(list => (
              <ListItemButton
                key={list.id}
                onClick={event => handleListClick(event, list)}
                styleName="list-button"
                data-cy={`favoriteFolderOption-${list.name}`}
              >
                <Icon color={colors.brand.blue}>
                  {listIdsCart.has(list.id) ? 'check' : 'blank_icon'}
                </Icon>
                <img src={favoriteListIcon} />
                <span styleName="list-button-text">{list.name}</span>
              </ListItemButton>
            ))}
          </List>
          <div styleName="list-popover-footer">
            <NewFavoritesListButton
              canAddList={canAddList}
              handleClick={handleNewListClick}
            />
          </div>
        </div>
      </Popover>
      <NewFavoritesListModal
        open={openNewListModal}
        onClose={() => setOpenNewListModal(false)}
        handleAddList={closeAndHandleAddList}
        favoritesListNames={favoritesListNames}
      />
    </div>
  );
};

export default FavoritesIconButton;
