import React from 'react';
import { Icon as MUIIcon, IconProps as MUIIconProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

import colors from '@atom/styles/colors';

type CustomProps = {
  /**
   * color to apply to the icon
   * defaults to #999
   */
  color?: string;
  /**
   * optional styles to apply to the
   * the root of the component
   */
  style?: any;
  /**
   * generate an invisible icon
   */
  invisible?: boolean;
};

export type IconProps = CustomProps & Omit<MUIIconProps, 'color'>;

const useStyles = makeStyles({
  root: ({ color, style, invisible }: IconProps) => ({
    color: color || colors.neutral.gray,
    fontSize: '24px',
    height: 'auto',
    paddingLeft: '0',
    visibility: invisible ? 'hidden' : 'inherit',
    ...style,
  }),
});

export const Icon = (props: IconProps) => {
  const classes = useStyles(props);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { color: _, ...data } = props;

  return (
    <MUIIcon {...data} classes={{ root: classes.root, ...props.classes }} />
  );
};

export default Icon;
