import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import {
  useGoogleMapsStateUpdater,
  useGoogleMapsStateValue,
  withGoogleMapsState,
} from '@atom/components/common/map/hooks/googleMapsStateHook';
import Map from '@atom/components/common/map/Map';
import MapMarkers from '@atom/components/common/map/MapMarkers';
import MapPinMarker from '@atom/components/common/map/markers/MapPinMarker';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Progress } from '@atom/mui';
import { getLoggedInUserMapLocationSelector } from '@atom/selectors/mapSelectors';
import {
  LocationUpdatePayload,
  MapEditMetadata,
  MapEditMetadataType,
} from '@atom/types/map';
import { PolicyAction } from '@atom/types/policy';
import { InventoryAssetDetailState } from '@atom/types/store';
import { hasAccess } from '@atom/utilities/accessUtilities';
import history from '@atom/utilities/history';
import {
  getGeoJsonFromMapBounds,
  getGoogleShapeCenter,
  getMapCenterAndZoomParams,
  getMapEditMetadata,
  urlValueToLatLng,
} from '@atom/utilities/mapUtilities';

const INVENTORY_DETAIL_ZOOM = 14;

interface Props {
  onSave: (payload: LocationUpdatePayload) => void;
  asset: InventoryAssetDetailState;
}

const InventoryDetailMap = ({ asset, onSave }: Props) => {
  const currentUserLocation = useSelector(getLoggedInUserMapLocationSelector);

  const dispatch = useGoogleMapsStateUpdater();
  const { isLoaded } = useGoogleMapsStateValue();
  const preferences = usePreferences();

  const [map, setMap] = useState<google.maps.Map>(null);
  const [coordinates, setCoordinates] = useState<string>(null);

  useEffect(() => {
    dispatch({
      type: 'SET',
      payload: {
        key: 'grab',
        data: false,
      },
    });
  }, []);

  const [queryParams, setQueryParams] = useQueryParams({
    id: StringParam,
    center: StringParam,
    zoom: NumberParam,
    searchPoint: StringParam,
  });

  useEffect(() => {
    const center = getGoogleShapeCenter(asset?.location);
    setQueryParams({
      ...queryParams,
      zoom: INVENTORY_DETAIL_ZOOM,
      center: `${center?.lat},${center?.lng}`,
    });

    history.handleHistoryChange(location, 'POP');
  }, []);

  const onLoad = useCallback(setMap, []);

  const onIdle = useCallback(() => {
    try {
      const params = getMapCenterAndZoomParams(
        map,
        queryParams,
        preferences,
        currentUserLocation,
      );

      if (!R.equals(queryParams, params) || !coordinates) {
        setQueryParams(params);
        R.pipe(
          getGeoJsonFromMapBounds,
          R.prop('coordinates'),
          JSON.stringify,
          setCoordinates,
        )(map);

        history.handleHistoryChange(location, 'POP');
      }
    } catch {
      setQueryParams(null);

      history.handleHistoryChange(location, 'POP');
    }
  }, [map, setQueryParams, queryParams, coordinates, preferences]);

  const canUpdateLocation = hasAccess(
    PolicyAction.UPDATE_LOCATION,
    asset.actions,
  );

  const mapEditMetadata: MapEditMetadata[] = useMemo(
    () => [
      getMapEditMetadata(asset, canUpdateLocation, MapEditMetadataType.ASSET),
    ],
    [asset],
  );

  const mapData = { assets: [asset] };

  return !isLoaded ? (
    <Progress style={{ height: '100%' }} />
  ) : (
    <Map
      onIdle={onIdle}
      onLoad={onLoad}
      // @ts-ignore
      center={queryParams.center && urlValueToLatLng(queryParams.center)}
      zoom={queryParams.zoom}
      mapStyleControl
      layerControls
      mapSearchBox
      editControls
      mapEditMetadata={mapEditMetadata}
      onSave={onSave}
      locationEditZoom={INVENTORY_DETAIL_ZOOM}
    >
      {/* @ts-ignore */}
      <MapMarkers onClick={() => {}} data={JSON.stringify(mapData)} />
      {queryParams.searchPoint && (
        <MapPinMarker center={queryParams.searchPoint} />
      )}
    </Map>
  );
};

export default withGoogleMapsState<Props>(InventoryDetailMap);
