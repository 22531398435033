import React from 'react';

import { Skeleton } from '@atom/mui';

import './skeletonRow.css';

const SkeletonTableRow = () => (
  <div styleName="skeleton-table-row">
    <div styleName="skeleton-table-cell-1">
      <Skeleton />
    </div>
    <div styleName="skeleton-table-cell-2">
      <Skeleton />
    </div>
    <div styleName="skeleton-table-cell-3">
      <Skeleton />
    </div>
    <div styleName="skeleton-table-cell-4">
      <Skeleton />
    </div>
  </div>
);

export default SkeletonTableRow;
