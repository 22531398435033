import React, { useContext } from 'react';

import WorkOrderFields from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderFields';
import WorkOrderPreviewContext, {
  ExpandedInfo,
  WorkOrderActionTypes,
} from '@atom/components/workOrderPreview/WorkOrderPreviewContext';
import { Icon, IconButton } from '@atom/mui';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import './infoView.css';

const CustomSection = () => {
  const {
    expandedInfo,
    setExpandedInfo,
    workOrderDetail,
    dispatch,
  } = useContext(WorkOrderPreviewContext);

  const toggleSection = () => {
    setExpandedInfo(toggleFromSet(expandedInfo, ExpandedInfo.CUSTOM));
  };

  const icon = expandedInfo.has(ExpandedInfo.CUSTOM)
    ? 'expand_less'
    : 'expand_more';

  return (
    <>
      <div styleName="section-header">
        <div styleName="section-title">Custom Fields Section</div>
        <IconButton onClick={toggleSection}>
          <Icon>{icon}</Icon>
        </IconButton>
      </div>
      {expandedInfo.has(ExpandedInfo.CUSTOM) && (
        <div styleName="section-content custom">
          <WorkOrderFields
            workOrderDetail={workOrderDetail}
            dispatch={dispatch}
            WorkOrderActionTypes={WorkOrderActionTypes}
          />
        </div>
      )}
    </>
  );
};

export default CustomSection;
