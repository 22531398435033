import * as R from 'ramda';
import { call, fork, put, take, takeEvery } from 'redux-saga/effects';

import actionTypes from '@atom/actions/actionTypes';
import * as inventoryCategoryActions from '@atom/actions/inventoryCategoryActions';
import textConstants from '@atom/constants/textConstants';
import { Snackbar } from '@atom/mui';
import accountUtilities from '@atom/utilities/accountUtilities';
import api from '@atom/utilities/api';
import { CATEGORIES_ENDPOINT } from '@atom/utilities/endpoints';
import { TOTAL_COUNT_HEADER } from '@atom/utilities/requestUtilities';

export function* getInventoryCategoryTreeNode(category) {
  try {
    // TODO: [ATOM-3117]: Persist the field name across the board

    const params = {
      parentCategoryId: category.id,
      page: 1,
      limit: 100,
    };

    const { data } = yield call(api.get, CATEGORIES_ENDPOINT, params);

    yield put(
      inventoryCategoryActions.getInventoryCategoryTreeNodeSuccess({
        category,
        children: data,
      }),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryCategoryActions.getInventoryCategoryTreeNodeFailure,
    );
  }
}

export function* removeInventoryFilterCategoryTreeNode() {
  try {
    yield put(
      inventoryCategoryActions.clearInventoryFilterCategoryTreeNodeSuccess(),
    );
  } catch (error) {
    yield fork(
      error,
      inventoryCategoryActions.clearInventoryFilterCategoryTreeNodeFailure,
    );
  }
}

export function* getInventoryFilterCategoryTreeNode({ data: body }) {
  try {
    const { id: parentCategoryId, categoryPath, expanded } = body;
    const fields = 'id,name,hasCategories,schemaId';
    const params = {
      parentCategoryId,
      fields,
      page: 1,
      limit: 250,
      sortBy: 'name',
      isAscending: true,
    };

    const { data } = yield call(api.get, CATEGORIES_ENDPOINT, params);

    yield put(
      inventoryCategoryActions.getInventoryFilterCategoryTreeNodeSuccess({
        subCategories: data,
        categoryPath,
        expanded,
      }),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryCategoryActions.getInventoryFilterCategoryTreeNodeFailure,
    );
  }
}

export function* getInventoryCategories(request) {
  try {
    const {
      parentCategoryId,
      columns,
      page,
      limit,
      sortBy,
      isAscending,
    } = request;
    // TODO: [ATOM-3117]: Persist the field name across the board
    const params = {
      ...(parentCategoryId ? { parentCategoryId } : {}),
      ...(page ? { page } : {}),
      ...(limit ? { limit } : {}),
      ...(sortBy ? { sortBy } : {}),
      ...(!R.isNil(isAscending) ? { isAscending } : {}),
    };

    const { data, headers } = yield call(api.get, CATEGORIES_ENDPOINT, params);
    const total = Number(headers[TOTAL_COUNT_HEADER]) || 0;

    yield put(
      inventoryCategoryActions.getInventoryCategoriesSuccess({
        parentCategoryId,
        columns,
        data,
        total,
      }),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryCategoryActions.getInventoryCategoriesFailure,
    );
  }
}

export function* getInventoryCategory(categoryId) {
  try {
    const endpoint = `${CATEGORIES_ENDPOINT}/${categoryId}`;
    const { data } = yield call(api.get, endpoint);

    yield put(inventoryCategoryActions.getInventoryCategorySuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryCategoryActions.getInventoryCategoryError,
    );
  }
}

export function* inventoryCategoryTreeUpdate({ data: payload }) {
  try {
    const endpoint = `${CATEGORIES_ENDPOINT}/${payload.categoryId}`;
    const { data } = yield call(api.patch, endpoint, {
      name: payload.name,
    });

    yield put(
      inventoryCategoryActions.updateCategoryTreeSuccess({
        ...data,
        path: payload.path,
      }),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryCategoryActions.updateCategoryTreeFailure,
    );
  }
}

export function* inventoryCategoryUpdate(payload) {
  try {
    const { name, categoryId, path, schemaId } = payload.data;
    const endpoint = `${CATEGORIES_ENDPOINT}/${categoryId}`;
    const { data } = yield call(api.patch, endpoint, {
      ...(schemaId ? { schemaId } : {}),
      ...(name ? { name } : {}),
    });

    yield put(
      inventoryCategoryActions.inventoryCategoryUpdateSuccess({
        ...data,
        path,
      }),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryCategoryActions.inventoryCategoryUpdateFailure,
    );
  }
}

export function* inventoryCategoryCreate(payload) {
  try {
    const { parentCategoryId, name, categoryPath, schemaId } = payload.data;
    const body = {
      ...(parentCategoryId ? { parentCategoryId } : {}),
      ...(schemaId ? { schemaId } : {}),
      name,
    };
    const { data } = yield call(api.post, CATEGORIES_ENDPOINT, body);
    yield put(
      inventoryCategoryActions.inventoryCategoryCreateSuccess({
        data,
        parentPath: categoryPath,
      }),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryCategoryActions.inventoryCategoryCreateFailure,
    );
  }
}

export function* updateInventoryPortalPath({ data }) {
  yield put(inventoryCategoryActions.inventoryPortalPathUpdateSuccess(data));
}

export function* createInventoryCategory(payload) {
  try {
    const body = {
      parentCategoryId: payload.data.parentCategoryId,
      name: payload.data.name,
      ...(payload.data.schemaId && { schemaId: payload.data.schemaId }),
    };
    const { data } = yield call(api.post, CATEGORIES_ENDPOINT, body);
    yield put(
      inventoryCategoryActions.createCategorySuccess({
        child: data,
        categoryPath: payload.data.categoryPath,
      }),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryCategoryActions.createCategoryFailure,
    );
  }
}

export function* deleteInventoryCategory(payload) {
  try {
    const { id, path } = payload.data;
    const endpoint = `${CATEGORIES_ENDPOINT}/${id}`;

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
    });

    yield call(api.delete, endpoint);

    yield put(
      inventoryCategoryActions.inventoryCategoryDeleteSuccess({
        path,
        id,
      }),
    );

    Snackbar.info({
      message: 'Category Deleted',
    });
  } catch (error) {
    if (error.response && error.response.status === 422) {
      Snackbar.error({
        message:
          'Folders containing subfolders or inventory items tied to work cannot be deleted.',
      });
    }

    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryCategoryActions.inventoryCategoryDeleteFailure,
    );
  }
}

export function* getFullCategoryTree() {
  try {
    const endpoint = `${CATEGORIES_ENDPOINT}/tree/temp`;
    const { data } = yield call(api.get, endpoint);

    yield put(inventoryCategoryActions.getFullCategoryTreeSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryCategoryActions.getFullCategoryTreeFailure,
    );
  }
}

/* WATCHERS */
export function* retrieveInventoryCategoryTreeNode() {
  while (true) {
    const { data } = yield take(
      actionTypes.REQUEST_INVENTORY_CATEGORY_TREE_NODE,
    );
    yield fork(getInventoryCategoryTreeNode, data);
  }
}

export function* retrieveInventoryFilterCategoryTreeNode() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_INVENTORY_FILTER_CATEGORY_TREE_NODE,
    getInventoryFilterCategoryTreeNode,
  );
}

export function* clearInventoryFilterCategoryTreeNode() {
  yield takeEvery(
    actionTypes.CLEAR_INVENTORY_FILTER_CATEGORY_TREE_NODE,
    removeInventoryFilterCategoryTreeNode,
  );
}

export function* requestInventoryCategoryUpdate() {
  yield takeEvery(
    actionTypes.REQUEST_INVENTORY_CATEGORY_UPDATE,
    inventoryCategoryUpdate,
  );
}

export function* requestInventoryCategoryCreate() {
  yield takeEvery(
    actionTypes.REQUEST_INVENTORY_CATEGORY_CREATE,
    inventoryCategoryCreate,
  );
}

export function* requestInventoryCategoryTreeUpdate() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_CATEGORY_TREE_UPDATE,
    inventoryCategoryTreeUpdate,
  );
}

export function* retrieveInventoryCategories() {
  while (true) {
    const { data } = yield take(actionTypes.REQUEST_INVENTORY_CATEGORIES);
    yield fork(getInventoryCategories, data);
  }
}

export function* retrieveInventoryCategory() {
  while (true) {
    const { data } = yield take(actionTypes.REQUEST_INVENTORY_CATEGORY);
    yield fork(getInventoryCategory, data.id);
  }
}

export function* requestInventoryPortalPathUpdate() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_INVENTORY_PORTAL_PATH_UPDATE,
    updateInventoryPortalPath,
  );
}

export function* requestCreateInventoryCategory() {
  yield takeEvery(actionTypes.REQUEST_CREATE_CATEGORY, createInventoryCategory);
}

export function* requestInventoryCategoryDelete() {
  yield takeEvery(
    actionTypes.REQUEST_INVENTORY_CATEGORY_DELETE,
    deleteInventoryCategory,
  );
}

export function* requestFullCategoryTree() {
  yield takeEvery(actionTypes.REQUEST_FULL_CATEGORY_TREE, getFullCategoryTree);
}

export default [
  clearInventoryFilterCategoryTreeNode,
  requestInventoryPortalPathUpdate,
  retrieveInventoryCategoryTreeNode,
  retrieveInventoryFilterCategoryTreeNode,
  retrieveInventoryCategories,
  retrieveInventoryCategory,
  requestInventoryCategoryUpdate,
  requestInventoryCategoryCreate,
  requestInventoryCategoryTreeUpdate,
  requestCreateInventoryCategory,
  requestInventoryCategoryDelete,
  requestFullCategoryTree,
];
