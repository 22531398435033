import React, { useContext } from 'react';

import { ListTable } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import {
  Environment,
  isCurrentEnvironment,
} from '@atom/utilities/featureToggleUtilities';

import BudgetDetailContext from '../BudgetDetailContext';
import { BUDGET_COL_WIDTH, ICON_COL_WIDTH } from '../budgetDetailUtils';

import BudgetDetailChildUnitRow from './BudgetDetailChildUnitRow';

import '../budgetDetail.css';

const { TableHead, TableRow, TableCell, TableBody } = ListTable;

const styles = {
  itemCount: {
    color: colors.neutral.gray,
    paddingLeft: '0.25rem',
    fontSize: fonts.lg,
    fontWeight: '500',
  },
  controlsDivider: {
    borderRight: `1px solid ${colors.neutral.gray}`,
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    margin: '0.75rem 0',
  },
  headingText: {
    fontSize: fonts.lg,
    fontWeight: '500',
  },
};

const BudgetDetailChildUnits = () => {
  const { childBudgetUnits, showTracking } = useContext(BudgetDetailContext);

  return (
    <div>
      <div style={styles.heading}>
        <span>
          <span style={styles.headingText}>Budget Units</span>
          <span style={styles.itemCount}> {childBudgetUnits?.length || 0}</span>
        </span>
      </div>
      <ListTable fullHeight={false}>
        <TableHead>
          <TableRow>
            <TableCell variant="head">NAME</TableCell>
            {/*
                TODO: Replace with Budgeting preference
                https://atomai.atlassian.net/browse/AM-15653
              */}
            {isCurrentEnvironment([Environment.DEV]) && (
              <TableCell width={BUDGET_COL_WIDTH} variant="head">
                STATUS
              </TableCell>
            )}
            <TableCell width={BUDGET_COL_WIDTH} align="right" variant="head">
              BUDGET
            </TableCell>
            {showTracking && (
              <>
                <TableCell
                  width={BUDGET_COL_WIDTH}
                  align="right"
                  variant="head"
                >
                  ACTUAL
                </TableCell>
                <TableCell
                  width={BUDGET_COL_WIDTH}
                  align="right"
                  variant="head"
                >
                  FUTURE FIXED
                </TableCell>
                <TableCell
                  width={BUDGET_COL_WIDTH}
                  align="right"
                  variant="head"
                >
                  REMAINING
                </TableCell>
              </>
            )}
            <TableCell width={ICON_COL_WIDTH} variant="head" />
          </TableRow>
        </TableHead>
        <TableBody>
          {childBudgetUnits.map(budgetUnit => (
            <BudgetDetailChildUnitRow
              key={budgetUnit.id}
              budgetUnit={budgetUnit}
            />
          ))}
        </TableBody>
      </ListTable>
    </div>
  );
};

export default BudgetDetailChildUnits;
