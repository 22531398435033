import * as R from 'ramda';

// Append a `children` element between all the other paths and drop the HEAD element
export const splitPath = R.pipe(
  R.split(','),
  R.join(',children,'),
  R.split(','),
  R.drop(1),
);

// Similar to splitPath, except now one level up at the parentCategoryId
export const splitParentPath = R.pipe(
  R.split(','),
  R.dropLast(1),
  R.join(',children,'),
  R.split(','),
  R.drop(1),
);

export default {
  splitPath,
  splitParentPath,
};

export const flattenTreeChildren = (treeRoot, childKey = 'children'): any[] => {
  const flatten = (children, extractChildren) => {
    return Array.prototype.concat.apply(
      children,
      children?.map(x => flatten(extractChildren(x) || [], extractChildren)),
    );
  };
  const extractChildren = x => x[childKey] || [];

  return flatten(extractChildren(treeRoot), extractChildren);
};
