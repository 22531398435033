import React from 'react';

import MoveInventoryModal from '@atom/components/common/MoveInventoryModal';
// @ts-ignore
import folderMoveIcon from '@atom/components/common/svgIcons/folderMove.svg';
import { Icon, Menu } from '@atom/mui';
import { ApplicationSearchInventoryState } from '@atom/types/store';
import history from '@atom/utilities/history';
import schemaUtilities from '@atom/utilities/schemaUtilities';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';

import './searchPortal.css';

const { MenuItem } = Menu;

interface Props {
  query: string;
  assets: ApplicationSearchInventoryState[];
}

class InventorySearchResultsRows extends React.Component<Props> {
  state: { assetId: any } = { assetId: null };

  toggleModal = (idToOpen: any) => {
    this.setState({ assetId: idToOpen });
  };

  navigate = (id: string) => {
    history.push(`/inventory/${id}`);
  };

  render() {
    const { query, assets } = this.props;

    return (
      <div styleName="search-entity-results-container">
        {assets.map((asset: ApplicationSearchInventoryState) => {
          const icon = schemaUtilities.getSchemaIconFromSchemaOrAsset(asset);

          const assetName = textDisplayUtilities.highlightedText(
            query,
            asset.name,
          );

          return (
            <>
              <div
                styleName="search-entity-row additional-options"
                key={asset.id}
                onClick={(): void => this.navigate(asset.id)}
              >
                <div styleName="search-entity-row-icon-text-container">
                  {icon}
                  <div styleName="search-entity-row-text-container">
                    <div styleName="search-entity-row-text">{assetName}</div>
                  </div>
                </div>
                <Menu>
                  <MenuItem
                    startAdornment={
                      <Icon>
                        <img src={folderMoveIcon} />
                      </Icon>
                    }
                    onClick={() => this.toggleModal(asset.id)}
                  >
                    Move To Folder
                  </MenuItem>
                </Menu>
              </div>
              <MoveInventoryModal
                open={this.state.assetId === asset.id}
                id={asset.id}
                closeModal={() => this.toggleModal(null)}
              />
            </>
          );
        })}
      </div>
    );
  }
}

export default InventorySearchResultsRows;
