import * as React from 'react';
import * as R from 'ramda';

import {
  Checkbox,
  Collapse,
  Icon,
  IconButton,
  List,
  Progress,
} from '@atom/mui';
import {
  isSchemaChecked,
  isSchemaPreviouslySelected,
} from '@atom/selectors/formSelectors';
import colors from '@atom/styles/colors';
import fontStyles from '@atom/styles/fonts';
import { AncestorsItem, FormFieldType } from '@atom/types/form';
import {
  DetailElementGroupsItem,
  InventorySchemaDetail,
  InventorySchemaTreeType,
} from '@atom/types/inventory';
import { SchemaType } from '@atom/types/schema';

import './formBuilder.css';

const { ListItem, ListItemText } = List;

const styles = {
  listStyle: {
    padding: '0',
    backgroundColor: colors.neutral.typhoon,
    overflowX: 'hidden',
  },
  rootListItemStyle: {
    boxSizing: 'border-box',
    borderLeft: '3px solid transparent',
    fontSize: fontStyles.md,
    height: '3.125rem',
    paddingTop: '0.6rem',
  },
  rootGroupItemStyle: {
    boxSizing: 'border-box',
    borderLeft: '3px solid transparent',
    fontSize: fontStyles.md,
    textTransform: 'uppercase',
    color: colors.neutral.gray,
    height: '3.125rem',
    paddingTop: '0.6rem',
  },
  innerItem: {
    paddingLeft: '3rem',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row nowrap',
    alignItems: 'center',
  },
  nestedListStyle: {
    padding: '0px',
    display: 'inline-block',
    minWidth: '100%',
  },
};

interface PassedProps {
  loading: boolean;
  rootSchema?: SchemaType;
  inventorySchemaTree: InventorySchemaTreeType;
  formAsset: FormFieldType;
  selectedSchemaIds: string[];
  onToggle: (path: any[], expanded: boolean) => void;
  toggleCheckbox: (assetSchema: Object) => void;
}

type Props = PassedProps;

class LinkInventoryTree extends React.Component<Props> {
  renderNestedElements = (
    previousId: string,
    previousName: string,
    elements: Object[],
    elementPath: any[],
    ancestors: AncestorsItem[],
    level: number = 0,
  ): any[] => {
    if (R.isEmpty(elements) || R.isNil(elements)) {
      return [];
    }

    return elements.map((element: InventorySchemaDetail, index: number) => {
      const {
        onToggle,
        toggleCheckbox,
        formAsset,
        selectedSchemaIds,
      } = this.props;
      const nestedAsset = element.elements
        ? element
        : R.pathOr([{}], ['elements'], element)[0];

      const { name, expanded } = element;
      const id = R.pathOr('', ['id'], element);

      const nextAncestors = [
        ...ancestors,
        ...(previousId ? [{ id: previousId, name: previousName }] : []),
      ];
      const assetSchema = { id, name, ancestors: nextAncestors };
      const isChecked =
        isSchemaChecked(id, formAsset) ||
        isSchemaPreviouslySelected(id, selectedSchemaIds);

      const isDisabled = isSchemaPreviouslySelected(id, selectedSchemaIds);

      const nestedElements = R.pathOr([], ['elements'], nestedAsset);
      const path = [...elementPath, 'elements', index];

      const primaryText = (
        <Checkbox
          checked={isChecked}
          onChange={(): void => toggleCheckbox(assetSchema)}
          disabled={isDisabled}
          label={name}
        />
      );

      const leftIcon =
        R.isEmpty(nestedElements) || R.isNil(nestedElements) ? (
          <div style={{ width: '1.5rem' }} />
        ) : (
          <IconButton onClick={(): void => onToggle(path, expanded)}>
            <Icon color={colors.neutral.dim}>
              {expanded ? 'arrow_drop_down' : 'arrow_right'}
            </Icon>
          </IconButton>
        );

      return (
        <>
          <ListItem
            disableGutters
            key={id}
            style={{
              ...styles.rootListItemStyle,
              paddingLeft: `${level * 1.5}rem`,
            }}
          >
            {leftIcon}
            <ListItemText
              primaryTextStyle={{ fontWeight: 0 }}
              primary={primaryText}
            />
          </ListItem>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List disablePadding>
              {this.renderNestedElements(
                id,
                name,
                nestedElements,
                path,
                nextAncestors,
                level + 1,
              )}
            </List>
          </Collapse>
        </>
      );
    });
  };

  renderTopLevelElements = (
    previousId: string,
    previousName: string,
    level: number = 0,
  ) => {
    const {
      inventorySchemaTree,
      onToggle,
      toggleCheckbox,
      formAsset,
      selectedSchemaIds,
    } = this.props;

    const elementGroups = R.pathOr([], ['elementGroups'], inventorySchemaTree);

    return elementGroups.map(
      (elementGroup: DetailElementGroupsItem, index: number) => {
        const { isGroup } = elementGroup;
        const topLevelElement = isGroup
          ? elementGroup
          : elementGroup.elements[0];

        const nextAncestors = [{ id: previousId, name: previousName }];

        const assetSchema = {
          id: topLevelElement.id,
          name: topLevelElement.name,
          ancestors: nextAncestors,
        };

        const isChecked =
          isSchemaChecked(topLevelElement.id, formAsset) ||
          isSchemaPreviouslySelected(topLevelElement.id, selectedSchemaIds);

        const isDisabled = isSchemaPreviouslySelected(
          topLevelElement.id,
          selectedSchemaIds,
        );

        const elements = R.pathOr([], ['elements'], topLevelElement);
        const elementPath = isGroup
          ? ['elementGroups', index]
          : ['elementGroups', index, 'elements', 0];

        const leftIcon =
          !isGroup && !topLevelElement.elements ? (
            <div />
          ) : (
            <IconButton
              onClick={(): void =>
                onToggle(elementPath, topLevelElement.expanded)
              }
            >
              <Icon color={colors.neutral.dim}>
                {topLevelElement.expanded ? 'arrow_drop_down' : 'arrow_right'}
              </Icon>
            </IconButton>
          );

        const listItemStyle = isGroup
          ? styles.rootGroupItemStyle
          : styles.rootListItemStyle;

        const primaryText = !isGroup ? (
          <Checkbox
            checked={isChecked}
            onChange={(): void => toggleCheckbox(assetSchema)}
            disabled={isDisabled}
            label={topLevelElement.name}
          />
        ) : (
          topLevelElement.name
        );

        return (
          <>
            <ListItem
              disableGutters
              key={index}
              style={{ ...listItemStyle, paddingLeft: `${level * 1.5}rem` }}
            >
              {leftIcon}
              <ListItemText
                primaryTextStyle={{
                  fontWeight: 0,
                  color: isGroup ? colors.neutral.gray : colors.neutral.dark,
                }}
                primary={primaryText}
              />
            </ListItem>
            <Collapse
              in={topLevelElement.expanded}
              timeout="auto"
              unmountOnExit
            >
              <List disablePadding>
                {this.renderNestedElements(
                  topLevelElement.id,
                  topLevelElement.name,
                  elements,
                  elementPath,
                  nextAncestors,
                  level + 1,
                )}
              </List>
            </Collapse>
          </>
        );
      },
    );
  };

  render() {
    const {
      inventorySchemaTree,
      loading,
      onToggle,
      toggleCheckbox,
      formAsset,
      selectedSchemaIds,
    } = this.props;
    const { id, name, expanded } = inventorySchemaTree;

    const path = [];
    const assetSchema = { id, name };
    const isChecked =
      isSchemaChecked(id, formAsset) ||
      isSchemaPreviouslySelected(id, selectedSchemaIds);

    const isDisabled = isSchemaPreviouslySelected(id, selectedSchemaIds);

    const leftIcon = (
      <IconButton onClick={(): void => onToggle(path, expanded)}>
        <Icon color={colors.neutral.dim}>
          {expanded ? 'arrow_drop_down' : 'arrow_right'}
        </Icon>
      </IconButton>
    );

    const primaryText = (
      <Checkbox
        checked={isChecked}
        onChange={(): void => toggleCheckbox(assetSchema)}
        disabled={isDisabled}
        label={name}
      />
    );

    return loading && R.isEmpty(inventorySchemaTree) ? (
      <Progress />
    ) : (
      <React.Fragment>
        <div styleName="modal-section-title">Inventory Details:</div>
        <List disablePadding>
          <ListItem disableGutters key={id} style={styles.rootListItemStyle}>
            {leftIcon}
            <ListItemText
              primaryTextStyle={{ fontWeight: 0 }}
              primary={primaryText}
            />
          </ListItem>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List disablePadding>
              {this.renderTopLevelElements(id, name, 1)}
            </List>
          </Collapse>
        </List>
      </React.Fragment>
    );
  }
}

export default LinkInventoryTree;
