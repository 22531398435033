import actionTypes from './actionTypes';

export const requestCreateTask = data => ({
  type: actionTypes.REQUEST_CREATE_TASK,
  data,
});

export const createTaskSuccess = data => ({
  type: actionTypes.CREATE_TASK_SUCCESS,
  data,
});

export const createTaskFailure = () => ({
  type: actionTypes.CREATE_TASK_FAILURE,
});

export const retrieveTask = data => ({
  type: actionTypes.REQUEST_TASK,
  data,
});

export const getTaskSuccess = data => ({
  type: actionTypes.GET_TASK_SUCCESS,
  data,
});

export const getTaskFailure = () => ({
  type: actionTypes.GET_TASK_FAILURE,
});

export const requestPatchTask = data => ({
  type: actionTypes.REQUEST_PATCH_TASK,
  data,
});

export const patchTaskSuccess = data => ({
  type: actionTypes.PATCH_TASK_SUCCESS,
  data,
});

export const patchTaskFailure = () => ({
  type: actionTypes.PATCH_TASK_FAILURE,
});

export const requestDeleteTask = data => ({
  type: actionTypes.REQUEST_DELETE_TASK,
  data,
});

export const deleteTaskSuccess = data => ({
  type: actionTypes.DELETE_TASK_SUCCESS,
  data,
});

export const deleteTaskFailure = () => ({
  type: actionTypes.DELETE_TASK_FAILURE,
});

export const requestTaskMaterialDelete = data => ({
  type: actionTypes.REQUEST_TASK_MATERIAL_DELETE,
  data,
});

export const deleteTaskMaterialSuccess = data => ({
  type: actionTypes.DELETE_TASK_MATERIAL_SUCCESS,
  data,
});

export const deleteTaskMaterialFailure = () => ({
  type: actionTypes.DELETE_TASK_MATERIAL_FAILURE,
});

export const requestTaskMaterialUpdate = data => ({
  type: actionTypes.REQUEST_TASK_MATERIAL_UPDATE,
  data,
});

export const updateTaskMaterialSuccess = data => ({
  type: actionTypes.UPDATE_TASK_MATERIAL_SUCCESS,
  data,
});

export const updateTaskMaterialFailure = () => ({
  type: actionTypes.UPDATE_TASK_MATERIAL_FAILURE,
});

export const requestTaskMaterialCreate = data => ({
  type: actionTypes.REQUEST_TASK_MATERIAL_CREATE,
  data,
});

export const createTaskMaterialSuccess = data => ({
  type: actionTypes.CREATE_TASK_MATERIAL_SUCCESS,
  data,
});

export const createTaskMaterialFailure = () => ({
  type: actionTypes.CREATE_TASK_MATERIAL_FAILURE,
});

export const requestAssigneeDelete = data => ({
  type: actionTypes.REQUEST_ASSIGNEE_DELETE,
  data,
});

export const deleteAssigneeSuccess = data => ({
  type: actionTypes.DELETE_ASSIGNEE_SUCCESS,
  data,
});

export const deleteAssigneeFailure = () => ({
  type: actionTypes.DELETE_ASSIGNEE_FAILURE,
});

export const requestTaskUserAdd = data => ({
  type: actionTypes.REQUEST_TASK_USER_ADD,
  data,
});

export const addTaskUserSuccess = data => ({
  type: actionTypes.ADD_TASK_USER_SUCCESS,
  data,
});

export const addTaskUserFailure = () => ({
  type: actionTypes.ADD_TASK_USER_FAILURE,
});

export const requestTaskAssetAdd = data => ({
  type: actionTypes.REQUEST_TASK_ASSET_ADD,
  data,
});

export const addTaskAssetSuccess = data => ({
  type: actionTypes.ADD_TASK_ASSET_SUCCESS,
  data,
});

export const addTaskAssetFailure = () => ({
  type: actionTypes.ADD_TASK_ASSET_FAILURE,
});

export const requestTaskAssetDelete = data => ({
  type: actionTypes.REQUEST_TASK_ASSET_DELETE,
  data,
});

export const deleteTaskAssetSuccess = data => ({
  type: actionTypes.DELETE_TASK_ASSET_SUCCESS,
  data,
});

export const deleteTaskAssetFailure = () => ({
  type: actionTypes.DELETE_TASK_ASSET_FAILURE,
});

export const updateWorkOrderData = data => ({
  type: actionTypes.UPDATE_WORK_ORDER_DATA_SUCCESS,
  data,
});
