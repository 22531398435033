import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as R from 'ramda';

import CreateWorkModal from '@atom/components/common/createWorkModals/CreateWorkModal';
import NewWorkOrderIcon from '@atom/components/common/svgIcons/NewWorkOrderIcon';
import TeamContext from '@atom/components/teamPortal/TeamContext';
import { GET_TIME_SHEET_ENTRIES } from '@atom/graph/timeEntry';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { Button, Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  TimeEntriesConnection,
  TimeEntriesConnectionInput,
  TimeEntryStatus,
} from '@atom/types/timeEntry';
import { WorkOrderDetailType } from '@atom/types/work';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import PayPeriodControls from '../timeHeaders/PayPeriodControls';
import SummaryHeader from '../timeHeaders/SummaryHeader';
import TitleHeader from '../timeHeaders/TitleHeader';

import TimeSheetTable from './TimeSheetTable';
import TimeSheetTableCondensed from './TimeSheetTableCondensed';

import './timeSheet.css';

const TimeSheet = () => {
  const userProfile = useUserProfile();
  const { selectedPayPeriod, selectedTimeSheetUser } = useContext(TeamContext);

  const [editTimeEntryId, setEditTimeEntryId] = useState<string>();
  const [createTimeEntry, setCreateTimeEntry] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [newWorkOrder, setNewWorkOrder] = useState<WorkOrderDetailType>();
  const [condensedView, setCondensedView] = useState<boolean>(false);

  const userId = R.isNil(selectedTimeSheetUser)
    ? userProfile.userId
    : selectedTimeSheetUser.id;

  const [getTimeSheetEntries, { loading, data, refetch }] = useLazyQuery<
    { timeEntries: TimeEntriesConnection },
    { input: TimeEntriesConnectionInput }
  >(GET_TIME_SHEET_ENTRIES, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!createTimeEntry) {
      setNewWorkOrder(null);
    }
  }, [createTimeEntry]);

  useEffect(() => {
    if (selectedPayPeriod) {
      getTimeSheetEntries({
        variables: {
          input: {
            userId,
            dateStart: selectedPayPeriod?.startDate,
            dateEnd: selectedPayPeriod?.endDate,
          },
        },
      });
      setEditTimeEntryId(null);
      setCreateTimeEntry(false);
      setNewWorkOrder(null);
    }
  }, [selectedPayPeriod, selectedTimeSheetUser]);

  const timeEntriesConnection = data?.timeEntries;
  const timeEntries = data?.timeEntries?.timeEntries;

  const isMyTimeSheet = R.isNil(selectedTimeSheetUser);
  const disableSubmitButton =
    R.isEmpty(timeEntries) ||
    !isNilOrEmpty(editTimeEntryId) ||
    createTimeEntry ||
    timeEntriesConnection?.status === TimeEntryStatus.pending ||
    timeEntriesConnection?.status === TimeEntryStatus.approved;
  const disableAddRowButton =
    doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR) ||
    !isNilOrEmpty(editTimeEntryId) ||
    createTimeEntry ||
    timeEntriesConnection?.status === TimeEntryStatus.approved;

  const title = isMyTimeSheet ? 'My Timesheet' : selectedTimeSheetUser.name;
  const buttonIconColor = disableAddRowButton
    ? colors.neutral.silver
    : colors.brand.blue;

  const onWorkOrderCreate = (workOrder: WorkOrderDetailType) => {
    setCreateTimeEntry(true);
    setNewWorkOrder(workOrder);
  };

  return (
    <>
      <TitleHeader
        title={title}
        showBackButton={!R.isNil(selectedTimeSheetUser)}
      />
      <PayPeriodControls
        showSubmitButton={isMyTimeSheet}
        showCondensedViewButton
        disableSubmitButton={disableSubmitButton}
        condensedView={condensedView}
        setCondensedView={setCondensedView}
        refetchTimeSheet={refetch}
        loading={loading}
      />
      <SummaryHeader timeEntriesConnection={timeEntriesConnection} />
      {condensedView ? (
        <TimeSheetTableCondensed
          timeEntries={timeEntries}
          refetchTimeSheet={refetch}
          dailyTotals={timeEntriesConnection?.dailyTotals}
        />
      ) : (
        <TimeSheetTable
          userId={userId}
          timeEntriesConnection={timeEntriesConnection}
          loading={loading}
          refetchTimeSheet={refetch}
          editTimeEntryId={editTimeEntryId}
          setEditTimeEntryId={setEditTimeEntryId}
          createTimeEntry={createTimeEntry}
          setCreateTimeEntry={setCreateTimeEntry}
          newWorkOrder={newWorkOrder}
        />
      )}
      {!condensedView && (
        <div styleName="table-footer">
          <Button
            color="primary"
            startIcon={<Icon color={buttonIconColor}>add</Icon>}
            disabled={disableAddRowButton}
            onClick={() => setCreateTimeEntry(true)}
            data-cy="timesheetAddRowButton"
          >
            Add Row
          </Button>
          <Button
            color="primary"
            startIcon={<NewWorkOrderIcon color={buttonIconColor} />}
            disabled={disableAddRowButton}
            onClick={() => setOpen(true)}
            data-cy="timesheetCreateWorkButton"
          >
            Create Work
          </Button>
        </div>
      )}
      <CreateWorkModal
        open={open}
        onClose={() => setOpen(false)}
        type="SINGLE"
        workTemplateRequired
        onWorkOrderCreate={onWorkOrderCreate}
        data-cy="timesheetCreateWorkModal"
      />
    </>
  );
};

export default TimeSheet;
