import React from 'react';
import * as R from 'ramda';

import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventoryAssetDetailType } from '@atom/types/inventory';
import schemaUtilities from '@atom/utilities/schemaUtilities';

import { Tree } from './TreeContext';

export interface Props {
  tree: Tree;
}

const styles = {
  icon: {
    marginRight: '0.5rem',
  },
};

const isAsset = (tree: Tree): tree is InventoryAssetDetailType => {
  // @ts-ignore
  return !R.isNil(tree.isMaterial);
};

const TreeRowIcon = ({ tree }: Props) => {
  // category icon
  if (!isAsset(tree)) {
    return (
      <Icon
        color={
          tree.colorId
            ? schemaUtilities.getSchemaColorFromColorId(tree.colorId)
            : colors.neutral.gray
        }
        style={styles.icon}
      >
        folder
      </Icon>
    );
  }

  // material asset icon
  if (tree.isMaterial) {
    return (
      <Icon
        color={schemaUtilities.getSchemaColorFromColorId(tree.colorId)}
        style={styles.icon}
      >
        category
      </Icon>
    );
  }

  // location based asset icon
  return (
    <img
      style={styles.icon}
      src={schemaUtilities.getSchemaIconFromMarkerId(tree.markerId)}
    />
  );
};

export default TreeRowIcon;
