import React from 'react';

import { Icon, TextField } from '@atom/mui';

const styles = {
  close: {
    cursor: 'pointer',
  },
};

export interface Props {
  assetSearchEnabled: boolean;
  categorySearchEnabled: boolean;
  query: string;
  setQuery: (query: string) => void;
  dataCyPrefix?: string;
}

const SearchInput = ({
  assetSearchEnabled,
  categorySearchEnabled,
  query,
  setQuery,
  dataCyPrefix = '',
}: Props) => (
  <>
    {(assetSearchEnabled || categorySearchEnabled) && (
      <TextField
        value={query}
        onChange={event => setQuery(event.target.value)}
        placeholder={assetSearchEnabled ? 'Search' : 'Search by folder name'}
        data-cy={`${dataCyPrefix}SearchInput`}
        InputProps={{
          startAdornment: <Icon>search</Icon>,
          endAdornment: query && (
            <Icon style={styles.close} onClick={() => setQuery('')}>
              close
            </Icon>
          ),
        }}
      />
    )}
  </>
);

export default SearchInput;
