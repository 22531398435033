import React, { useEffect, useReducer, useState } from 'react';
import { match as Match } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import { StringParam, useQueryParams } from 'use-query-params';

import { GET_WORK_ORDER } from '@atom/graph/work';
import { usePreferences } from '@atom/hooks/usePreferences';
import { usePreviousValue } from '@atom/hooks/usePreviousValue';
import {
  INITIAL_WORK_VALIDATIONS,
  useWorkValidations,
} from '@atom/hooks/useWorkValidations';
import { Progress } from '@atom/mui';
import { Task } from '@atom/types/task';
import {
  WorkOrderAssetTreeType,
  WorkOrderDetailType,
  WorkOrderTab,
  WorkOrderType,
  WorkValidations,
} from '@atom/types/work';
import api from '@atom/utilities/api';
import { WORK_ORDERS_ENDPOINT } from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';

import WorkOrderDetailHeader from './workOrderDetailHeader/WorkOrderDetailHeader';
import WorkOrderDocuments from './workOrderDocuments/WorkOrderDocuments';
import WorkOrderInfo from './workOrderInfo/WorkOrderInfo';
import WorkOrderPhotos from './workOrderPhotos/WorkOrderPhotos';
import WorkOrderTasks from './workOrderTasks/WorkOrderTasks';
import WorkOrderTeam from './workOrderTeam/WorkOrderTeam';
import WorkOrderVideos from './workOrderVideos/WorkOrderVideos';
import WorkOrderContext, {
  reducer,
  WorkOrderActionTypes,
} from './WorkOrderContext';

import './workOrderDetail.css';

const DEFAULT_TAB = WorkOrderTab.TASKS;

interface Props {
  match: Match<{ workOrderId: string }>;
}

const WorkOrderDetail = ({ match }: Props) => {
  const preferences = usePreferences();
  const [queryParams, setQueryParams] = useQueryParams({
    view: StringParam,
    task: StringParam,
  });

  // TODO: [AM-15154] Remove old validation and rename new
  const {
    workValidations: workValidationsNew,
    setWorkValidations: setWorkValidationsNew,
  } = useWorkValidations();

  const [workOrderDetail, dispatch] = useReducer(
    reducer,
    {} as WorkOrderDetailType,
  );
  // @ts-ignore
  const [task, setTask] = useState<Task>({});
  const [activeWorkOrderAsset, setActiveWorkOrderAsset] = useState<string>('');
  const [workOrderAsset, setWorkOrderAsset] = useState<WorkOrderAssetTreeType>(
    // @ts-ignore
    {},
  );
  const previousWorkOrderAsset = usePreviousValue(workOrderAsset);
  const [workOrderAssetLoading, setWorkOrderAssetLoading] = useState<boolean>(
    false,
  );
  const [
    refetchWorkOrderAssetTreeLoading,
    setRefetchWorkOrderAssetTreeLoading,
  ] = useState<boolean>(false);
  const [refetchMedia, setRefetchMedia] = useState<boolean>(false);
  const [workValidations, setWorkValidations] = useState<WorkValidations>({});

  useEffect(() => {
    // If the current work order does not match the work order with validation
    // errors in global state, then clear it.
    if (match.params.workOrderId !== workValidationsNew.workOrderId) {
      setWorkValidationsNew(INITIAL_WORK_VALIDATIONS);
    }
  }, [match]);

  const setActiveView = async (view: WorkOrderTab) => {
    await setQueryParams({ view });

    history.handleHistoryChange(location, 'POP');
  };

  const setActiveTask = async (activeTaskId: string) => {
    await setQueryParams({ task: activeTaskId });

    history.handleHistoryChange(location, 'POP');
  };

  const onCompleted = data => {
    const mappedWorkOrder = {
      ...data?.workOrder,
      type: R.pathOr(WorkOrderType.DEFAULT, ['workOrder', 'type'], data),
    };

    dispatch({
      type: WorkOrderActionTypes.SET_WORK_ORDER,
      data: mappedWorkOrder,
    });

    setActiveTask(
      !R.isEmpty(queryParams?.task)
        ? queryParams?.task
        : R.isEmpty(mappedWorkOrder.tasks)
        ? ''
        : mappedWorkOrder.tasks[0].id,
    );
    setTask(R.isEmpty(task) ? mappedWorkOrder.tasks[0] : task);
  };

  const { loading, error, refetch } = useQuery(GET_WORK_ORDER, {
    variables: {
      id: match.params.workOrderId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted,
  });

  const workOrderDetailLoading = error || !workOrderDetail.id;

  const refetchWorkOrderAssetTree = async () => {
    setRefetchWorkOrderAssetTreeLoading(true);
    const endpoint = `${WORK_ORDERS_ENDPOINT}/${workOrderDetail.id}/assets/${activeWorkOrderAsset}/tree`;
    const { data } = await api.get(endpoint);

    setRefetchWorkOrderAssetTreeLoading(false);
    return setWorkOrderAsset(data);
  };

  useEffect(() => {
    if (!R.isEmpty(queryParams?.task) && !R.isEmpty(workOrderDetail)) {
      setTask(R.find(R.propEq('id', queryParams?.task))(workOrderDetail.tasks));
    }
  }, [queryParams?.task, workOrderDetail]);

  useEffect(() => {
    const updateParams = async () => {
      await setQueryParams({
        view:
          queryParams?.view ||
          R.pathOr(
            DEFAULT_TAB,
            ['workOrders', 'defaultWorkOrderTab'],
            preferences,
          ),
        task: queryParams?.task || '',
      });

      history.handleHistoryChange(location, 'POP');
    };

    updateParams();
  }, []);

  useEffect(() => {
    setWorkOrderAssetLoading(true);

    const getWorkOrderAssetTree = async () => {
      if (R.isEmpty(activeWorkOrderAsset)) {
        if (!R.isEmpty(previousWorkOrderAsset)) {
          refetch();
        }

        // @ts-ignore
        return setWorkOrderAsset({});
      }

      const endpoint = `${WORK_ORDERS_ENDPOINT}/${workOrderDetail.id}/assets/${activeWorkOrderAsset}/tree`;
      const { data } = await api.get(endpoint);

      return setWorkOrderAsset(data);
    };

    getWorkOrderAssetTree();
    setWorkOrderAssetLoading(false);
  }, [activeWorkOrderAsset, workOrderDetail.id]);

  const getActiveTab = () => {
    const tabs = {
      [WorkOrderTab.INFO]: <WorkOrderInfo />,
      [WorkOrderTab.TASKS]: <WorkOrderTasks />,
      [WorkOrderTab.TEAM]: <WorkOrderTeam />,
      [WorkOrderTab.DOCUMENTS]: <WorkOrderDocuments />,
      [WorkOrderTab.PHOTOS]: <WorkOrderPhotos />,
      [WorkOrderTab.VIDEOS]: <WorkOrderVideos />,
    };

    return tabs[queryParams?.view];
  };

  return (
    <WorkOrderContext.Provider
      value={{
        refetch,
        refetchWorkOrderAssetTree,
        refetchWorkOrderAssetTreeLoading,
        workOrderDetail,
        dispatch,
        loading,
        workOrderAssetLoading,
        task,
        workOrderAsset,
        setActiveTask,
        setActiveWorkOrderAsset,
        refetchMedia,
        setRefetchMedia,
        workValidations,
        setWorkValidations,
        setTask,
      }}
    >
      {workOrderDetailLoading ? (
        <Progress />
      ) : (
        <>
          <WorkOrderDetailHeader
            // @ts-ignore
            activeView={queryParams?.view}
            setActiveView={setActiveView}
          />
          <div styleName="tab">{getActiveTab()}</div>
        </>
      )}
    </WorkOrderContext.Provider>
  );
};

export default WorkOrderDetail;
