import * as React from 'react';
import { connect } from 'react-redux';
import { AppBar, Box, Toolbar } from '@mui/material';
import { bindActionCreators, Dispatch } from 'redux';

import * as userActionCreators from '@atom/actions/userActions';
import Logo from '@atom/components/common/navigation/Logo';
import { Button, Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import layoutStyles from '@atom/styles/layout';
import { UserActions } from '@atom/types/actions';
import accountUtilities from '@atom/utilities/accountUtilities';
import history from '@atom/utilities/history';

import LoginFooter from './LoginFooter';

import './login.css';

const navBarStyle = {
  height: layoutStyles.topNavHeight,
  backgroundColor: colors.neutral.white,
  boxShadow: '0 2px 4px 1px',
  color: colors.utility.boxShadow,
  position: 'fixed',
  top: 0,
};

const styles = {
  titleStyle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  activeIcon: {
    fontSize: '24px',
    color: colors.neutral.white,
  },
  inactiveIcon: {
    fontSize: '24px',
    color: colors.neutral.gray,
  },
};

interface ReduxDispatchProps {
  userActions: UserActions;
}

type Props = ReduxDispatchProps;

interface State {
  email: string;
}

class VerifyEmail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state.email = this.getEmailAccount();
  }

  state = {
    email: '',
  };

  getEmailAccount = (): string => {
    const email = accountUtilities.getLoggedInEmail();
    if (!email) {
      history.push('/login');
    }

    return email;
  };

  submit = () => {
    const { userActions } = this.props;
    userActions.requestEmailVerificationLink();
  };

  logout = () => {
    const { userActions } = this.props;
    userActions.triggerLogout();
  };

  render() {
    const { email } = this.state;

    return (
      <React.Fragment>
        <AppBar style={navBarStyle}>
          <Toolbar>
            <div>
              <Logo />
            </div>
            <Box sx={{ flexGrow: 1 }} />
            <div styleName="nav-right-container">
              <div styleName="nav-right-text">{email}</div>
              <div styleName="nav-right-login" onClick={this.logout}>
                Sign Out
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <div styleName="container">
          <div styleName="auth-background partial" />
          <div styleName="heading-text">Verify</div>
          <div styleName="registration-icon-container">
            <div styleName="registration-icon active">
              <Icon className="material-icons" style={styles.activeIcon}>
                person
              </Icon>
            </div>
            <div styleName="registration-icon active">
              <Icon className="material-icons" style={styles.activeIcon}>
                email
              </Icon>
            </div>
            <div styleName="registration-icon">
              <Icon className="material-icons" style={styles.inactiveIcon}>
                link
              </Icon>
            </div>
          </div>
          <div styleName="icon-horizontal-bar-container">
            <div styleName="icon-horizontal-bar-half active" />
            <div styleName="icon-horizontal-bar-half" />
          </div>
          <div styleName="text-field-container">
            <div>
              Thank you for signing up! You will receive an email with a
              verification link. Please continue your signup process after
              verifying.
            </div>
            <div styleName="text-block">
              If you have any questions, please contact Atom customer service
              at&nbsp;
              <a href="mailto:support@goatomapp.com">support@goatomapp.com</a>.
            </div>
          </div>
          <div styleName="verify-email-button-section">
            <div styleName="verify-email-text">
              Already Verified? Please log back in again.
            </div>
            <Button color="primary" variant="contained" onClick={this.logout}>
              Back To Login
            </Button>
          </div>
          <div styleName="verify-email-button-section">
            <div styleName="verify-email-text">
              Didn't receive an email from us?
            </div>
            <Button color="inherit" variant="contained" onClick={this.submit}>
              Resend Email
            </Button>
          </div>
          <LoginFooter />
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  userActions: bindActionCreators(userActionCreators, dispatch),
});

export default connect(null, mapDispatchToProps)(VerifyEmail);
