import React, { useContext } from 'react';

import WorkOrdersContext, {
  FilterSection,
} from '@atom/components/workOrders/WorkOrdersContext';
import { Icon, IconButton } from '@atom/mui';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import WorkOrdersAssigneeFilter from './WorkOrdersAssigneeFilter';
import WorkOrdersUserFilter from './WorkOrdersUserFilter';

import './workOrdersFilters.css';

const styles = {
  icon: {
    marginRight: '1rem',
  },
};

const WorkOrdersFiltersTeamSection = () => {
  const { collapsedSections, setCollapsedSections } = useContext(
    WorkOrdersContext,
  );

  const onToggle = (section: FilterSection) => {
    setCollapsedSections(toggleFromSet(collapsedSections, section));
  };

  return (
    <div styleName="filters-section">
      <div styleName="filters-section-header">
        <div>
          <Icon style={styles.icon}>people</Icon>
          Team
        </div>
        <IconButton onClick={() => onToggle(FilterSection.TEAM)} edge="end">
          <Icon>
            {collapsedSections.has(FilterSection.TEAM)
              ? 'keyboard_arrow_down'
              : 'keyboard_arrow_up'}
          </Icon>
        </IconButton>
      </div>
      {!collapsedSections.has(FilterSection.TEAM) && (
        <div styleName="filters-section-content">
          <div styleName="filter-container">
            <WorkOrdersAssigneeFilter />
          </div>
          <div styleName="filter-container">
            <WorkOrdersUserFilter
              property="completedByIds"
              placeholder="Search for user"
              label="Completed By"
            />
          </div>
          <div styleName="filter-container">
            <WorkOrdersUserFilter
              property="createdByIds"
              placeholder="Search for user"
              label="Created By"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkOrdersFiltersTeamSection;
