import React, { useState } from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { UserGroupCertification } from '@atom/types/userGroups';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import AddEditCertModal from './AddEditCertModal';
import DeleteCertModal from './DeleteCertModal';

import './userGroupCerts.css';

interface Props {
  certification: UserGroupCertification;
}

const UserGroupCertTile = ({ certification }: Props) => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  return (
    <>
      <div styleName="cert-tile">
        <div styleName="cert-title-container">
          <Icon color={colors.brand.green}>card_membership</Icon>
          <div styleName="cert-name-container">
            <div styleName="cert-name">{certification.name}</div>
            <div>{certification.description}</div>
          </div>
        </div>
        <div>
          {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
            <IconButton onClick={() => setOpenEdit(true)}>
              <Icon color={colors.neutral.gray}>edit</Icon>
            </IconButton>
          )}
          {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
            <IconButton onClick={() => setOpenDelete(true)}>
              <Icon color={colors.neutral.gray}>delete</Icon>
            </IconButton>
          )}
        </div>
      </div>
      <AddEditCertModal
        isEdit
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        certification={certification}
      />
      <DeleteCertModal
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        certificationId={certification.id}
      />
    </>
  );
};

export default UserGroupCertTile;
