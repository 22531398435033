import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import UserContext from '@atom/components/userDetail/UserContext';
import {
  CREATE_USER_CERTIFICATION,
  UPDATE_USER_CERTIFICATION,
} from '@atom/graph/user';
import { Modal, Progress, TextField } from '@atom/mui';
import {
  UserCertificationCreateInput,
  UserCertificationItem,
  UserCertificationUpdateInput,
  UserDetail,
} from '@atom/types/user';

import './certificationsTab.css';

const styles = {
  descriptionFieldStyle: {
    paddingTop: '2rem',
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
  isEdit?: boolean;
  certification?: UserCertificationItem;
}

const AddEditBudgetModal = ({
  open,
  onClose,
  isEdit,
  certification,
}: Props) => {
  const { user } = useContext(UserContext);

  const [name, setName] = useState<string>(isEdit ? certification.name : '');
  const [description, setDescription] = useState<string>(
    isEdit ? certification.description : '',
  );

  useEffect(() => {
    if (open) {
      setName(isEdit ? certification.name : '');
      setDescription(isEdit ? certification.description : '');
    } else {
      setName('');
      setDescription('');
    }
  }, [open]);

  const [createCert, { loading: createCertLoading }] = useMutation<
    { userCertificationCreate: UserDetail },
    { input: UserCertificationCreateInput }
  >(CREATE_USER_CERTIFICATION);

  const [updateCert, { loading: updateCertLoading }] = useMutation<
    { userCertificationUpdate: UserDetail },
    { input: UserCertificationUpdateInput }
  >(UPDATE_USER_CERTIFICATION);

  const confirm = () => {
    if (isEdit) {
      updateCert({
        variables: {
          input: {
            id: certification.id,
            userId: user.id,
            name,
            description,
          },
        },
      });
    } else {
      createCert({
        variables: {
          input: {
            userId: user.id,
            name,
            description,
          },
        },
      });
    }

    onClose();
  };

  return (
    <Modal
      title={isEdit ? 'Edit Certification' : 'Add Certification'}
      onConfirm={confirm}
      onCancel={onClose}
      confirmButtonText={isEdit ? 'Save' : 'Add'}
      disabled={R.isEmpty(name)}
      open={open}
    >
      {createCertLoading || updateCertLoading ? (
        <Progress />
      ) : (
        <div styleName="modal-container">
          <TextField
            label="Name"
            value={name}
            name="name"
            onChange={event => setName(event.target.value)}
          />
          <TextField
            label="Description"
            style={styles.descriptionFieldStyle}
            value={description}
            name="description"
            onChange={event => setDescription(event.target.value)}
          />
        </div>
      )}
    </Modal>
  );
};

export default AddEditBudgetModal;
