import React from 'react';
import { makeStyles } from '@mui/styles';
import * as R from 'ramda';

import WorkOrderEnumMultipleField from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderEnumMultipleField';
import WorkOrderEnumSingleField from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderEnumSingleField';
import WorkOrdersFiltersDatePicker from '@atom/components/workOrders/workOrdersFilters/WorkOrdersFiltersDatePicker';
import WorkOrdersFiltersNumericRange from '@atom/components/workOrders/workOrdersFilters/WorkOrdersFiltersNumericRange';
import colors from '@atom/styles/colors';
import { DataType } from '@atom/types/dataType';
import { FieldFilter, WorkOrderField } from '@atom/types/work';
import { WorkTemplate } from '@atom/types/workTemplate';
import {
  convertDateToLocalDayEnd,
  convertDateToLocalDayStart,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import { isCascadingField } from '@atom/utilities/workOrderFieldUtilities';

import { AdditionalFieldFilters } from '../AssetWorkOrdersFilterPanel';

import './../assetWorkOrdersFilterPanel.css';

const useClasses = makeStyles({
  label: {
    position: 'unset',
    color: colors.neutral.dim,
  },
});

interface Props {
  areFiltersDisabled: boolean;
  workOrderTemplate?: WorkTemplate;
  fieldFilters: AdditionalFieldFilters;
  updateFieldFilters: (fieldFiltersUpdate: AdditionalFieldFilters) => void;
}

const WorkTemplateWorkFieldsFilter = ({
  areFiltersDisabled,
  workOrderTemplate,
  fieldFilters,
  updateFieldFilters,
}: Props) => {
  const classes = useClasses();

  const updateFilterState = (
    id?: string,
    fieldProperty?: keyof FieldFilter,
    value?: number | string | string[],
  ) => {
    const updatedFieldFilters = {
      ...fieldFilters,
      [id]: {
        ...fieldFilters[id],
        id,
        [fieldProperty]: value,
      },
    };

    updateFieldFilters(updatedFieldFilters);
  };

  const getField = (id?: string): FieldFilter | null => {
    const foundField: FieldFilter = fieldFilters[id];

    return foundField || null;
  };

  const getDateValue = (id?: string, fieldProperty?: keyof FieldFilter) => {
    const foundField = getField(id);

    if (foundField && !isNilOrEmpty(foundField[fieldProperty])) {
      return new Date(Number(foundField[fieldProperty]));
    }

    return null;
  };

  const handleDateChange = (id?: string, fieldProperty?: keyof FieldFilter) => (
    _,
    dateValue: Date,
  ) => {
    const dateMillis = dateValue
      ? fieldProperty === 'valueStart'
        ? convertDateToLocalDayStart(dateValue)
        : convertDateToLocalDayEnd(dateValue)
      : null;

    updateFilterState(id, fieldProperty, dateMillis);
  };

  const handleDateTimeChange = (
    id?: string,
    fieldProperty?: keyof FieldFilter,
  ) => (_, dateValue: Date) => {
    const dateMillis = dateValue ? dateValue.getTime() : null;

    updateFilterState(id, fieldProperty, dateMillis);
  };

  const handleSelectChange = (
    id: string,
    fieldProperty?: keyof FieldFilter,
  ) => (_, value: any) => {
    const sanitizedValue = R.isEmpty(value) ? null : value;
    updateFilterState(id, fieldProperty, sanitizedValue);
  };

  const handleNumericFieldChange = (
    id: string,
    fieldProperty?: keyof FieldFilter,
  ) => (_, value: any) => {
    const numValue = value === '' ? null : Number(value);

    updateFilterState(id, fieldProperty, numValue);
  };

  const getContent = (field: WorkOrderField) => {
    if (isCascadingField(field)) {
      return <div />;
    }

    const components = {
      [DataType.DATE]: (
        <WorkOrdersFiltersDatePicker
          field={field}
          startDateValue={getDateValue(field.id, 'valueStart')}
          endDateValue={getDateValue(field.id, 'valueEnd')}
          onStartDateChange={handleDateChange(field.id, 'valueStart')}
          onEndDateChange={handleDateChange(field.id, 'valueEnd')}
          isEditable={!areFiltersDisabled}
        />
      ),
      [DataType.DATE_TIME]: (
        <WorkOrdersFiltersDatePicker
          field={field}
          startDateValue={getDateValue(field.id, 'valueStart')}
          endDateValue={getDateValue(field.id, 'valueEnd')}
          onStartDateChange={handleDateTimeChange(field.id, 'valueStart')}
          onEndDateChange={handleDateTimeChange(field.id, 'valueEnd')}
          isEditable={!areFiltersDisabled}
        />
      ),
      [DataType.ENUM_SINGLE]: (
        <WorkOrderEnumSingleField
          field={{ ...field, value: getField(field.id)?.value }}
          onChange={handleSelectChange(field.id, 'value')}
          boldLabel
          isDisabled={areFiltersDisabled}
        />
      ),
      [DataType.ENUM_MULTIPLE]: (
        <WorkOrderEnumMultipleField
          field={{ ...field, value: getField(field.id)?.values }}
          onChange={handleSelectChange(field.id, 'values')}
          isDisabled={areFiltersDisabled}
          InputLabelProps={{ classes: { root: classes.label } }}
        />
      ),
      [DataType.NUMBER]: (
        <WorkOrdersFiltersNumericRange
          field={field}
          startValue={getField(field.id)?.valueStart}
          endValue={getField(field.id)?.valueEnd}
          onStartChange={handleNumericFieldChange(field.id, 'valueStart')}
          onEndChange={handleNumericFieldChange(field.id, 'valueEnd')}
          isEditable={areFiltersDisabled}
        />
      ),
    };

    return components[field.dataType];
  };

  return (
    <>
      {workOrderTemplate?.fields?.map(field => (
        <div styleName="filter-container" key={field.id}>
          {getContent(field)}
        </div>
      ))}
    </>
  );
};

export default WorkTemplateWorkFieldsFilter;
