import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import WorkTemplateCreationModalContext, {
  EMPTY_SCHEMA,
  InheritanceType,
  ModalStep,
  ModalView,
} from '@atom/components/common/workTemplateCreationModal/WorkTemplateCreationModalContext';
import WorkTypeFolderTree from '@atom/components/common/workTemplateFolderTree/WorkTypeFolderTree';
import { WORK_TEMPLATE_CREATE } from '@atom/graph/workTemplate';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Button, Icon, IconButton, Modal, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import { WorkOrderType } from '@atom/types/work';
import {
  WorkOrderTemplateCreateInput,
  WorkTemplate,
  WorkTemplateFolderTree,
} from '@atom/types/workTemplate';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';
import history from '@atom/utilities/history';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import MainCreationModalView from './MainCreationModalView';
import {
  MODAL_STEPS,
  MODAL_STEPS_ARRAY,
  MODAL_STEPS_ARRAY_NO_INHERITANCE,
} from './workTemplateOptions';

import './workTemplateCreationModal.css';

const HTTP_RESOURCE_CONFLICT = 409;

const styles = {
  modalContent: {
    padding: 0,
  },
  back: {
    marginRight: '1rem',
  },
};

interface Props {
  open: boolean;
  closeModal: () => void;
}

const WorkTemplateCreationModal = ({ open, closeModal }: Props) => {
  const preferences = usePreferences();
  const multiLocationEnabled = preferences?.workOrders?.multiLocationEnabled;
  const INITIAL_TYPE = multiLocationEnabled
    ? WorkOrderType.TASK_ASSETS_AND_LOCATIONS
    : WorkOrderType.DEFAULT;

  const [activeView, setActiveView] = useState<ModalView>(ModalView.MAIN);
  const [activeStep, setActiveStep] = useState<ModalStep>(ModalStep.DETAILS);
  const [error, setError] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [workTemplateFolder, setWorkTemplateFolder] = useState<
    WorkTemplateFolderTree
  >();
  const [type, setType] = useState<WorkOrderType>(INITIAL_TYPE);
  const [schemaId, setSchemaId] = useState<string>(EMPTY_SCHEMA);
  const [inheritanceType, setInheritanceType] = useState<InheritanceType>(
    InheritanceType.REGULAR,
  );

  const STEPS_ARRAY = isCurrentClient([Client.ALDOT])
    ? MODAL_STEPS_ARRAY_NO_INHERITANCE
    : MODAL_STEPS_ARRAY;

  const [workOrderTemplateCreate, { loading: createLoading }] = useMutation<
    { workOrderTemplateCreate: WorkTemplate },
    { input: WorkOrderTemplateCreateInput }
  >(WORK_TEMPLATE_CREATE);

  const onCloseModal = () => {
    setActiveView(ModalView.MAIN);
    setActiveStep(ModalStep.DETAILS);
    setError('');
    setName('');
    setWorkTemplateFolder(null);
    setType(INITIAL_TYPE);
    setSchemaId(EMPTY_SCHEMA);
    setInheritanceType(InheritanceType.REGULAR);
    closeModal();
  };

  const confirm = async () => {
    try {
      const selectedSchemaId = schemaId !== EMPTY_SCHEMA ? schemaId : null;
      const isParentValue =
        inheritanceType === InheritanceType.REGULAR ? null : true;

      const { data } = await workOrderTemplateCreate({
        variables: {
          input: {
            name,
            folderId: workTemplateFolder.id,
            type,
            ...(selectedSchemaId && { schemaId: selectedSchemaId }),
            ...(isParentValue && { isParent: isParentValue }),
          },
        },
      });

      history.push(`/work-templates/${data?.workOrderTemplateCreate?.id}`);

      onCloseModal();
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_RESOURCE_CONFLICT) {
        setActiveStep(ModalStep.DETAILS);
        setError('This name is already in use.');
      } else {
        Snackbar.error({ message: 'An unknown error occurred' });
        onCloseModal();
      }
    }
  };

  const getTitle = (): React.ReactNode => {
    const titles = {
      [ModalView.MAIN]: 'Create Work Template',
      [ModalView.FOLDER_TREE]: (
        <>
          <IconButton
            style={styles.back}
            onClick={() => setActiveView(ModalView.MAIN)}
            size="small"
          >
            <Icon>arrow_back</Icon>
          </IconButton>
          Choose Folder
        </>
      ),
    };

    return titles[activeView];
  };

  const getContent = (): React.ReactNode => {
    const views = {
      [ModalView.MAIN]: <MainCreationModalView />,
      [ModalView.FOLDER_TREE]: (
        <WorkTypeFolderTree
          setWorkTemplateFolder={setWorkTemplateFolder}
          setActiveView={() => setActiveView(ModalView.MAIN)}
        />
      ),
    };

    return views[activeView];
  };

  const handleStep = (value: number) => {
    const currentStep = MODAL_STEPS[activeStep];
    const newStep = STEPS_ARRAY[currentStep.index + value];
    setActiveStep(newStep.value);
  };

  const isConfirmDisabled = () => {
    if (activeView === ModalView.FOLDER_TREE) {
      return true;
    }

    switch (activeStep) {
      case ModalStep.DETAILS: {
        return isNilOrEmpty(name) || isNilOrEmpty(workTemplateFolder);
      }
      case ModalStep.TEMPLATE_TYPE: {
        return isNilOrEmpty(type);
      }
      case ModalStep.INHERITANCE: {
        return isNilOrEmpty(inheritanceType);
      }
      default: {
        return true;
      }
    }
  };

  const getFooter = () => {
    // Show create button is activeStep is last step
    const showCreateButton =
      R.findIndex(R.propEq('value', activeStep), STEPS_ARRAY) ===
      STEPS_ARRAY.length - 1;
    const showBackButton =
      activeStep !== ModalStep.DETAILS && activeView !== ModalView.FOLDER_TREE;

    const rightArrowColor = isConfirmDisabled()
      ? colors.neutral.gray
      : colors.neutral.white;

    return (
      <div styleName="footer">
        {showBackButton ? (
          <Button onClick={() => handleStep(-1)}>
            <Icon>chevron_left</Icon>Back
          </Button>
        ) : (
          <div />
        )}
        <div>
          <Button
            onClick={onCloseModal}
            style={{ marginRight: '0.5rem' }}
            data-cy="createWorkTemplateModalCancelButton"
          >
            Cancel
          </Button>
          {showCreateButton ? (
            <Button
              color="primary"
              variant="contained"
              onClick={confirm}
              disabled={isConfirmDisabled()}
              data-cy="createWorkTemplateModalCreateButton"
            >
              Create
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleStep(1)}
              disabled={isConfirmDisabled()}
              data-cy="createWorkTemplateModalNextButton"
            >
              Next<Icon color={rightArrowColor}>chevron_right</Icon>
            </Button>
          )}
        </div>
      </div>
    );
  };

  const modalSize = activeView === ModalView.FOLDER_TREE ? 'sm' : 'lg';

  return (
    <WorkTemplateCreationModalContext.Provider
      value={{
        activeView,
        setActiveView,
        activeStep,
        setActiveStep,
        error,
        setError,
        name,
        setName,
        workTemplateFolder,
        setWorkTemplateFolder,
        schemaId,
        setSchemaId,
        type,
        setType,
        inheritanceType,
        setInheritanceType,
      }}
    >
      <Modal
        title={getTitle()}
        footer={getFooter()}
        open={open}
        onCancel={() => onCloseModal()}
        loading={createLoading}
        contentStyle={styles.modalContent}
        width={modalSize}
      >
        {getContent()}
      </Modal>
    </WorkTemplateCreationModalContext.Provider>
  );
};

export default WorkTemplateCreationModal;
