import { call, fork, put, takeEvery } from 'redux-saga/effects';

import actionTypes from '@atom/actions/actionTypes';
import * as poolAttributeActions from '@atom/actions/poolAttributeActions';
import accountUtilities from '@atom/utilities/accountUtilities';
import api from '@atom/utilities/api';
import { POOL_ATTRIBUTES_ENDPOINT } from '@atom/utilities/endpoints';

export function* getPoolAttributes() {
  try {
    const endpoint = POOL_ATTRIBUTES_ENDPOINT;
    const { data } = yield call(api.get, endpoint);

    yield put(poolAttributeActions.getPoolAttributesSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      poolAttributeActions.getPoolAttributesFailure,
    );
  }
}

/* WATCHERS */

export function* retrievePoolAttributes() {
  yield takeEvery(actionTypes.REQUEST_POOL_ATTRIBUTES, getPoolAttributes);
}

export default [retrievePoolAttributes];
