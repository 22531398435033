import React, { useContext, useMemo } from 'react';
import * as R from 'ramda';

import { useCategoriesSearch } from '@atom/hooks/useCategoriesSearch';
import { Checkbox, List, Progress } from '@atom/mui';
import { InventoryCategoryTree, SearchCategory } from '@atom/types/inventory';
import {
  getAllDescendantCategoryIds,
  getCategoryById,
} from '@atom/utilities/categoryUtilities';

import LoadAssetsButton from './LoadAssetsButton';
import TreeContext from './TreeContext';
import TreeRowIcon from './TreeRowIcon';

import './inventoryAssetTree.css';

const { ListItemButton, ListItemText } = List;

interface Props {
  /**
   * denotes whether location based categories and categories should be
   * included in the tree and search. Default is true.
   */
  includeLocationBasedCategories?: boolean;
  /**
   * Denotes whether material categories should be included in search.
   * Default is true.
   */
  includeMaterialCategories?: boolean;
  /**
   * optional callback to be called on category click
   */
  onCategoryClick?: (category: InventoryCategoryTree) => void;
  /**
   * Optional callback to call when category selection is toggled. Will
   * only be called when category multi selection is enabled by
   * providing selectedCategoryIds.
   */
  onCategoryToggle?: (category: InventoryCategoryTree) => void;
  query: string;
  /**
   * Inventory category tree
   */
  tree: InventoryCategoryTree;
}

const SEARCH_CATEGORIES_LIMIT = 12;

const InventoryCategorySearch = ({
  includeLocationBasedCategories = true,
  includeMaterialCategories = true,
  onCategoryClick,
  onCategoryToggle,
  query,
  tree,
}: Props) => {
  const {
    autoSelectChildCategories,
    disabledCategoryIds,
    schemaId,
    selectedCategoryIds,
  } = useContext(TreeContext);

  const [
    { categories },
    { loadingSearch, loadingPagination, error, hasMore, fetchMore },
  ] = useCategoriesSearch({
    query,
    schemaId,
    includeMaterialCategories,
    includeLocationBasedCategories,
    limit: SEARCH_CATEGORIES_LIMIT,
    characterMin: 3,
  });

  const handleCategoryClick = (category: SearchCategory) => {
    if (onCategoryClick) {
      onCategoryClick(getCategoryById(category.id, [tree]));
    }
  };

  const handleCategoryToggle = (
    event: React.MouseEvent<any>,
    category: SearchCategory,
  ) => {
    event.stopPropagation();

    if (onCategoryToggle) {
      onCategoryToggle(getCategoryById(category.id, [tree]));
    }
  };

  const autoSelectedIds = useMemo(() => {
    if (autoSelectChildCategories && selectedCategoryIds) {
      return new Set(
        R.flatten(
          [...selectedCategoryIds].map(id =>
            getAllDescendantCategoryIds(getCategoryById(id, [tree])),
          ),
        ),
      );
    }

    return new Set([]);
  }, [selectedCategoryIds]);

  return (
    <>
      {loadingSearch ? (
        <div styleName="progress-container">
          <Progress />
        </div>
      ) : (
        <List>
          {categories.map(category => (
            <ListItemButton
              key={category.id}
              onClick={() => handleCategoryClick(category)}
              disabled={schemaId && category.schemaId !== schemaId}
            >
              {selectedCategoryIds && (
                <Checkbox
                  checked={
                    selectedCategoryIds?.has(category.id) ||
                    autoSelectedIds?.has(category.id)
                  }
                  onClick={event => handleCategoryToggle(event, category)}
                  disabled={
                    disabledCategoryIds?.has(category.id) ||
                    autoSelectedIds?.has(category.id)
                  }
                  style={{ marginRight: '0.5rem' }}
                />
              )}
              <TreeRowIcon tree={category} />
              <ListItemText
                primary={category.name}
                secondary={category.categoryPath
                  .map(({ name }) => name)
                  .join(' / ')}
              />
            </ListItemButton>
          ))}
        </List>
      )}
      {hasMore && (
        <div styleName="load-button-container">
          <LoadAssetsButton
            loading={loadingPagination}
            error={error}
            onClick={fetchMore}
          />
        </div>
      )}
    </>
  );
};

export default InventoryCategorySearch;
