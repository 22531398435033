import React, { useContext, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';

import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import {
  GET_WORK_ORDER_TEMPLATES,
  WORK_TEMPLATE_TASK_CREATE,
} from '@atom/graph/workTemplate';
import { Icon, IconButton, Progress, Snackbar, Tooltip } from '@atom/mui';
import { PolicyAction } from '@atom/types/policy';
import {
  WorkOrderTemplatesConnectionInput,
  WorkOrderTemplateTaskCreateInput,
  WorkTemplatesConnection,
  WorkTemplateTaskItem,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { WorkTypeVariant } from '@atom/utilities/workTemplateUtilities';

import TaskListItem from './TaskListItem';

import './workTemplateTasks.css';

const HTTP_RESOURCE_CONFLICT = 409;

const styles = {
  progress: {
    height: '100%',
  },
};

const TaskList = () => {
  const { workTemplate, workTypeVariant, refetch } = useContext(
    WorkTemplateContext,
  );

  const [createWorkTemplateTask, { loading: loadingCreate }] = useMutation<
    { workOrderTemplateTaskCreate: WorkTemplateTaskItem },
    { input: WorkOrderTemplateTaskCreateInput }
  >(WORK_TEMPLATE_TASK_CREATE);

  const [
    getChildWorkTypes,
    { data: workOrderTemplatesData, loading: workOrderTemplatesLoading },
  ] = useLazyQuery<
    { workOrderTemplates: WorkTemplatesConnection },
    { input: WorkOrderTemplatesConnectionInput }
  >(GET_WORK_ORDER_TEMPLATES, {
    variables: {
      input: {
        page: 1,
        limit: 1,
        action: PolicyAction.READ,
        parentId: workTemplate?.id,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getChildWorkTypes({
      variables: {
        input: {
          page: 1,
          limit: 1,
          action: PolicyAction.READ,
          parentId: workTemplate?.id,
        },
      },
    });
  }, [workTemplate?.id]);

  const errorText =
    workTypeVariant === WorkTypeVariant.CHILD
      ? 'You cannot add new tasks to a child work template after it has been created.'
      : 'You cannot add new tasks to the parent work template when any child work template exists.';

  const createTask = async () => {
    try {
      await createWorkTemplateTask({
        variables: {
          input: {
            workTemplateId: workTemplate.id,
            name: 'Untitled Task',
          },
        },
      });

      refetch();
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_RESOURCE_CONFLICT) {
        Snackbar.error({ message: errorText });
      } else {
        Snackbar.error({ message: 'An unknown error occurred' });
      }
    }
  };

  const taskAmount = R.length(workTemplate.tasks);
  const childWorkTypeCount =
    workOrderTemplatesData?.workOrderTemplates?.totalCount;

  const isDisabled =
    workTemplate?.published ||
    childWorkTypeCount > 0 ||
    workTypeVariant === WorkTypeVariant.CHILD ||
    workOrderTemplatesLoading ||
    doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  const showTooltip =
    childWorkTypeCount > 0 || workTypeVariant === WorkTypeVariant.CHILD;

  const addButton = showTooltip ? (
    <Tooltip title={errorText}>
      <span>
        <IconButton
          tooltip="Create Task"
          disabled={isDisabled}
          onClick={() => createTask()}
        >
          <Icon>add</Icon>
        </IconButton>
      </span>
    </Tooltip>
  ) : (
    <IconButton
      tooltip="Create Task"
      disabled={isDisabled}
      onClick={() => createTask()}
    >
      <Icon>add</Icon>
    </IconButton>
  );

  return (
    <>
      <div styleName="task-list-header">
        <div>{`${taskAmount} tasks`}</div>
        {addButton}
      </div>
      <div styleName="task-list-container">
        {loadingCreate && (
          <div styleName="task-list-loading-container">
            <Progress size={30} thickness={2} style={styles.progress} />
          </div>
        )}
        <div styleName={loadingCreate ? 'task-list-item-loading-mask' : ''}>
          {workTemplate.tasks.map(task => {
            return <TaskListItem key={task.id} task={task} />;
          })}
        </div>
      </div>
    </>
  );
};

export default TaskList;
