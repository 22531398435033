/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from 'react';
import { AxiosResponse } from 'axios';
import * as R from 'ramda';

import { TOTAL_COUNT_HEADER } from '@atom/utilities/requestUtilities';
import { addToSet, removeFromSet } from '@atom/utilities/setUtilities';

type Options<T> = {
  limit: number;
  fetcher: (
    id: string,
    page: number,
    limit: number,
  ) => Promise<AxiosResponse<T[]>>;
};

type Data<T> = {
  [key: string]: {
    data: T[];
    total: number;
  };
};

type ReturnData<T> = {
  getData: (id: string) => T[];
  hasMore: (id: string) => boolean;
  fetchMore: (id: string) => void;
  isLoading: (id: string) => boolean;
  hasError: (id: string) => boolean;
  reset: () => void;
};

export function useGroupedPagination<T>({
  limit,
  fetcher,
}: Options<T>): ReturnData<T> {
  const [data, setData] = useState<Data<T>>({});
  const [loading, setLoading] = useState<Set<string>>(new Set());
  const [errors, setErrors] = useState<Set<string>>(new Set());

  const hasMore = (id: string) => {
    const datum = data[id];
    return !datum || datum.data.length !== datum.total;
  };

  const reset = () => {
    setData({});
    setLoading(new Set());
    setErrors(new Set());
  };

  const fetchMore = async (id: string) => {
    if (!hasMore(id)) {
      return;
    }

    setLoading(loading => addToSet(loading, id));
    setErrors(errors => removeFromSet(errors, id));

    const loaded = R.pathOr<T[]>([], [id, 'data'], data);
    const currentPage = !loaded ? 0 : Math.ceil(loaded.length / limit);

    try {
      const response = await fetcher(id, currentPage + 1, limit);
      const total = response.headers[TOTAL_COUNT_HEADER] || 0;

      const lens = R.lensPath([id]);

      setData(data =>
        R.over(lens, datum => ({
          ...datum,
          data: R.concat(loaded, response.data),
          total: Number(total),
        }))(data),
      );
    } catch (error) {
      setErrors(errors => addToSet(errors, id));
    }

    setLoading(loading => removeFromSet(loading, id));
  };

  return {
    fetchMore,
    hasMore,
    reset,
    getData: (id: string) => R.pathOr<T[]>([], [id, 'data'], data),
    isLoading: (id: string) => loading.has(id),
    hasError: (id: string) => errors.has(id),
  };
}
