import * as R from 'ramda';
import { createSelector } from 'reselect';

export const inventorySchemasSelector = R.pathOr([], ['inventorySchemas']);
export const inventorySchemasByIdsSelector = R.pathOr({}, [
  'inventorySchemasByIds',
]);

const newAssetType = {
  id: null,
  name: 'NEW ASSET TYPE',
  createNew: true,
};

export const getLocationBasedSchemas = createSelector(
  state => inventorySchemasSelector(state),
  inventorySchemas => inventorySchemas.filter(schema => !schema.isMaterial),
);

export const getHydratedInventorySchemas = createSelector(
  state => inventorySchemasSelector(state),
  inventorySchemas => {
    const locationBasedSchemas = inventorySchemas.filter(
      schema => !schema.isMaterial,
    );
    return [newAssetType, ...locationBasedSchemas];
  },
);

export const getHydratedInventorySchemaByIds = createSelector(
  state => inventorySchemasByIdsSelector(state),
  inventorySchemasByIds => {
    const fieldIds = Object.keys(inventorySchemasByIds);

    return fieldIds.reduce((acc, fieldId) => {
      return {
        ...acc,
        [fieldId]: [
          ...inventorySchemasByIds[fieldId].map(schema => {
            return {
              ...schema,
              attributeGroups: schema.attributeGroups.map(group => {
                return {
                  ...group,
                  attributes: group.attributes.map(id => {
                    return { id, ...schema.attributes[id] };
                  }),
                };
              }),
            };
          }),
        ],
      };
    }, {});
  },
);
