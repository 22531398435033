import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import TextField from '@atom/components/common/TextField';
import WorkTemplateContext, {
  WorkTemplateActionTypes,
} from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_TASK_UPDATE } from '@atom/graph/workTemplate';
import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import {
  WorkOrderTemplateTaskUpdateInput,
  WorkTemplateTaskItem,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';

import './taskHeader.css';

const styles = {
  input: {
    fontSize: fonts.xl,
    fontWeight: '500',
  },
  textField: {
    color: colors.neutral.white,
  },
  underline: {
    borderColor: colors.neutral.gray,
  },
};

const TaskName = () => {
  const { workTemplate, task, dispatch } = useContext(WorkTemplateContext);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [name, setName] = useState<string>(task.name);

  useEffect(() => {
    setName(task.name);
    setEditMode(false);
  }, [task.name, task.id]);

  const [taskTemplateUpdate] = useMutation<
    { workOrderTemplateTaskUpdate: WorkTemplateTaskItem },
    { input: WorkOrderTemplateTaskUpdateInput }
  >(WORK_TEMPLATE_TASK_UPDATE);

  const setTask = (updatedTask: WorkTemplateTaskItem) => {
    dispatch({
      type: WorkTemplateActionTypes.SET_TASK,
      data: {
        ...updatedTask,
      },
    });
  };

  const handleSubmit = async () => {
    const res = await taskTemplateUpdate({
      variables: {
        input: {
          workTemplateId: workTemplate.id,
          taskId: task.id,
          name,
        },
      },
    });

    setEditMode(false);

    setTask(res?.data?.workOrderTemplateTaskUpdate);
  };

  const toggleEditMode =
    workTemplate?.published || doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN)
      ? () => {}
      : () => setEditMode(true);

  return (
    <>
      {editMode ? (
        <div styleName="task-name-textfield-container">
          <TextField
            autoComplete="off"
            fullWidth
            autoFocus
            name="name"
            value={name}
            onChange={event => setName(event.target.value)}
            style={styles.input}
            textareaStyle={styles.textField}
            underlineStyle={styles.underline}
          />
          <div styleName="button-container">
            <div styleName="button">
              <Icon
                color={colors.brand.blue}
                onClick={() => setEditMode(false)}
              >
                close
              </Icon>
            </div>
            <div styleName="button">
              <Icon color={colors.brand.blue} onClick={handleSubmit}>
                check
              </Icon>
            </div>
          </div>
        </div>
      ) : (
        <div styleName="task-header-title" onClick={toggleEditMode}>
          {name}
        </div>
      )}
    </>
  );
};

export default TaskName;
