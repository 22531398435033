import React, { useState } from 'react';
import * as R from 'ramda';

import { MediaItem } from '@atom/types/media';

import Carousel from './Carousel';
import CarouselHeader from './CarouselHeader';
import InfoSideBar from './InfoSideBar';

import './carousel.css';

const ESC_KEY_CODE = 27;

interface Props {
  media: MediaItem[];
  selectedMediaId: string;
  setSelectedMediaId: (id: string) => void;
  open: boolean;
  onClose: () => void;
  removeFile: (mediaId: string, subjectId: string) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  subjectId: string;
  setAsPrimaryMedia?: (mediaId: string) => void;
  primaryMediaId?: string;
  canMarkAsCoverPhoto?: boolean;
  canUpdateMedia: boolean;
  canDeleteMedia: boolean;
  refetchMedia?: () => void;
}

const CarouselModal = ({
  open,
  onClose,
  media,
  subjectId,
  renameMedia,
  selectedMediaId,
  setSelectedMediaId,
  setAsPrimaryMedia,
  primaryMediaId,
  canMarkAsCoverPhoto,
  canUpdateMedia,
  canDeleteMedia,
  removeFile,
  refetchMedia,
}: Props) => {
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(false);

  const handleCloseModal = event => {
    if (event.keyCode === ESC_KEY_CODE) {
      onClose();
    }
  };

  window.addEventListener('keyup', handleCloseModal, false);

  const deleteMedia = (mediaId: string) => {
    removeFile(mediaId, subjectId);
    onClose();
  };

  const updateSelectedMediaId = (selectedMediaIndex: number) => {
    setSelectedMediaId(media[selectedMediaIndex].id);
  };

  const selectedMedia = R.find(R.propEq('id', selectedMediaId), media) || {};
  const selectedMediaIndex =
    R.findIndex(R.propEq('id', selectedMediaId), media) || 0;

  const modalStyle = {
    ...(!open ? { display: 'none' } : {}),
  };

  return (
    <div styleName="modal-container" style={modalStyle}>
      <div styleName="left-pane-container">
        <CarouselHeader
          onClose={onClose}
          openSideBar={() => setSideBarOpen(true)}
          selectedMedia={selectedMedia}
          deleteMedia={deleteMedia}
          subjectId={subjectId}
          renameMedia={renameMedia}
          setAsPrimaryMedia={setAsPrimaryMedia}
          primaryMediaId={primaryMediaId}
          canMarkAsCoverPhoto={canMarkAsCoverPhoto}
          canUpdateMedia={canUpdateMedia}
          canDeleteMedia={canDeleteMedia}
        />
        {open && (
          <Carousel
            media={media}
            selectedMediaIndex={selectedMediaIndex}
            updateSelectedMediaId={updateSelectedMediaId}
            sideBarOpen={sideBarOpen}
          />
        )}
      </div>
      <InfoSideBar
        selectedMedia={selectedMedia}
        sideBarOpen={sideBarOpen}
        onClose={() => setSideBarOpen(false)}
        canUpdateMedia={canUpdateMedia}
        refetchMedia={refetchMedia}
      />
    </div>
  );
};

export default CarouselModal;
