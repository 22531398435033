import React from 'react';

import { DashboardCard } from '@atom/types/dashboard';

import DashboardCardLoader from './DashboardCardLoader';
import DashboardCardTitlebar from './DashboardCardTitlebar';

import './dashboardCard.css';

interface Props {
  card: DashboardCard;
}

// Prevent clicks on the card body from triggering drag
const handleBodyMouseDown = (event: React.MouseEvent) => {
  event.stopPropagation();
};

const DashboardCardContainer = ({ card }: Props) => {
  const style =
    card.contentState === 'LOADING'
      ? {
          boxShadow: 'none',
          background: 'none',
        }
      : {};
  return (
    <div styleName="card" style={style}>
      <DashboardCardTitlebar card={card} />
      <div
        style={{ position: 'relative', cursor: 'default' }}
        onMouseDown={handleBodyMouseDown}
      >
        <DashboardCardLoader card={card} />
      </div>
    </div>
  );
};

export default DashboardCardContainer;
