import React, { useMemo, useState } from 'react';

import InventoryAssetTree from '@atom/components/common/inventoryAssetTree/InventoryAssetTree';
import { Modal } from '@atom/mui';
import { Task } from '@atom/types/task';
import { WorkTemplateTaskItem } from '@atom/types/workTemplate';
import { toggleFromSet } from '@atom/utilities/setUtilities';

export interface Props {
  open: boolean;
  onClose: () => void;
  rootCategory?: { id: string; name: string; categoryPath: string[] };
  task: Task | WorkTemplateTaskItem;
  handleAddMaterials: (assetIds: string[]) => void;
  favoritesEnabled: boolean;
}

const styles = {
  modal: {
    height: '50vh',
  },
};

const AddMaterialAssetsModal = ({
  open,
  onClose,
  rootCategory,
  task,
  handleAddMaterials,
  favoritesEnabled,
}: Props) => {
  const [selectedAssetIds, setSelectedAssetIds] = useState<Set<string>>(
    new Set(),
  );

  const handleSubmit = () => {
    handleAddMaterials([...selectedAssetIds]);
  };

  const disabledAssetIds = useMemo(() => {
    return new Set<string>(task.materials.map(material => material.assetId));
  }, [task]);

  return (
    <Modal
      title="Add Equipment and Material"
      open={open}
      onCancel={onClose}
      onConfirm={handleSubmit}
      contentStyle={{
        ...styles.modal,
        padding: '2rem',
      }}
      onExited={() => setSelectedAssetIds(new Set())}
      confirmButtonText="Add"
      data-cy="addEquipmentMaterialsModal"
    >
      <InventoryAssetTree
        favoritesEnabled={favoritesEnabled}
        selectedAssetIds={new Set([...selectedAssetIds, ...disabledAssetIds])}
        disabledAssetIds={disabledAssetIds}
        onAssetToggle={asset =>
          setSelectedAssetIds(toggleFromSet(selectedAssetIds, asset.id))
        }
        setSelectedAssetIds={setSelectedAssetIds}
        includeLocationBased={false}
        includeInactive={false}
        rootCategory={rootCategory}
        search="assets"
      />
    </Modal>
  );
};

export default AddMaterialAssetsModal;
