import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import debounce from 'lodash.debounce';

import WorkTemplateContext, {
  WorkTemplateActionTypes,
} from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_TASK_UPDATE } from '@atom/graph/workTemplate';
import { Icon, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  WorkOrderTemplateTaskUpdateInput,
  WorkTemplateTaskItem,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';

import './workTemplateTaskDetail.css';

const DEBOUNCE_TIME = 1000;

const styles = {
  icon: {
    paddingTop: '0.5rem',
  },
};

const TaskDescription = () => {
  const { workTemplate, task, dispatch } = useContext(WorkTemplateContext);

  const [description, setDescription] = useState<string>(
    task?.description || '',
  );

  const [taskTemplateUpdate] = useMutation<
    { workOrderTemplateTaskUpdate: WorkTemplateTaskItem },
    { input: WorkOrderTemplateTaskUpdateInput }
  >(WORK_TEMPLATE_TASK_UPDATE);

  useEffect(() => {
    setDescription(task?.description || '');
  }, [task.id]);

  const updateTaskProperty = (
    property: keyof WorkTemplateTaskItem,
    value: any,
  ) => {
    dispatch({
      type: WorkTemplateActionTypes.UPDATE_TASK_PROPERTY,
      data: {
        taskId: task.id,
        property,
        value,
      },
    });
  };

  const progressiveUpdate = useCallback(
    debounce(async (value: string) => {
      const res = await taskTemplateUpdate({
        variables: {
          input: {
            workTemplateId: workTemplate.id,
            taskId: task.id,
            description: value,
          },
        },
      });

      updateTaskProperty(
        'description',
        res?.data?.workOrderTemplateTaskUpdate?.description,
      );
    }, DEBOUNCE_TIME),
    [workTemplate.id, task.id],
  );

  const handleChange = event => {
    setDescription(event.target.value);
    progressiveUpdate(event.target.value);
  };

  const isDisabled =
    workTemplate?.published || doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  return (
    <>
      <Icon style={styles.icon} color={colors.neutral.gray}>
        subject
      </Icon>
      <div styleName="task-detail-row-content">
        <TextField
          id={description}
          label="Description"
          value={description}
          disabled={isDisabled}
          onChange={handleChange}
          fullWidth
          multiline
        />
      </div>
    </>
  );
};

export default TaskDescription;
