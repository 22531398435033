import React, { useContext, useState } from 'react';

import DeleteModal from '@atom/components/common/DeleteModal';
import CarouselWrapper from '@atom/components/common/photoCarousel/CarouselWrapper';
import RenameModal from '@atom/components/common/RenameModal';
import WorkOrderAssetViewContext from '@atom/components/common/workOrderDetail/workOrderAssetView/WorkOrderAssetViewContext';
import { Checkbox, Icon, ListTable, Menu, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import api from '@atom/utilities/api';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import {
  FILES_ENDPOINT,
  WORK_ORDERS_ENDPOINT,
} from '@atom/utilities/endpoints';
import { getMediaIcon } from '@atom/utilities/iconUtilities';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';

import '../../../../inventoryDetail/bodyPanes/folders/folders.css';

const { TableHead, TableRow, TableCell, TableBody } = ListTable;

const { MenuItem } = Menu;

const styles = {
  progress: {
    height: '100%',
    alignSelf: 'center',
  },
};

interface Props {
  media?: any[];
  renameMedia: (id: string, name: string) => any;
  deleteMedia: (id: string) => any;
  loading?: boolean;
  toggleCheck: (id: string, checked: boolean) => any;
  selectedItems: any[];
}

const ElementMediaTable = ({
  media,
  renameMedia,
  deleteMedia,
  loading,
  toggleCheck,
  selectedItems,
}: Props) => {
  const { workOrderDetail, refetch, previewView } = useContext(
    WorkOrderAssetViewContext,
  );
  const [isRenameModalOpen, setIsRenameModalOpen] = useState<object>({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<object>({});

  const setAsPrimaryMedia = async mediaId => {
    const mainPhotoId = workOrderDetail.mainPhotoId === mediaId ? '' : mediaId;

    await api.patch(`${WORK_ORDERS_ENDPOINT}/${workOrderDetail.id}`, {
      mainPhotoId,
    });

    refetch();
  };

  const renameAction = (id: string, subjectId: string, name: string) => {
    renameMedia(id, name);
  };

  return (
    <div styleName="main-pane detail">
      {!loading ? (
        <ListTable offsetTop="165px">
          <TableHead>
            <TableRow header>
              {!previewView && <TableCell variant="head" />}{' '}
              <TableCell variant="head">name</TableCell>
              <TableCell variant="head">uploaded by</TableCell>
              <TableCell variant="head">uploaded on</TableCell>
              <TableCell variant="head" />
            </TableRow>
          </TableHead>
          <TableBody>
            {media.map(row => {
              const isMainMedia = workOrderDetail.mainPhotoId === row.id;
              return (
                <TableRow key={row.id}>
                  {!previewView && (
                    <TableCell>
                      <Checkbox
                        checked={selectedItems.includes(row.id)}
                        onChange={() =>
                          toggleCheck(row.id, selectedItems.includes(row.id))
                        }
                      />
                    </TableCell>
                  )}
                  {previewView ? (
                    <TableCell>
                      <div styleName="name-row-container">
                        <div>{`${row.name}.${row.fileExtension}`}</div>
                      </div>
                    </TableCell>
                  ) : (
                    <TableCell>
                      <CarouselWrapper
                        media={media}
                        renameMedia={renameAction}
                        selectedMediaId={row.id}
                        removeFile={deleteMedia}
                        subjectId={row.subjectId}
                        setAsPrimaryMedia={setAsPrimaryMedia}
                        primaryMediaId={workOrderDetail.mainPhotoId}
                        canMarkAsCoverPhoto
                        canUpdateMedia
                        canDeleteMedia
                      >
                        <div styleName="name-row-container">
                          {!previewView && (
                            <>
                              <div styleName="name-row-icon">
                                {getMediaIcon(
                                  row.fileExtension,
                                  row.fileId,
                                  row.name,
                                )}
                              </div>
                              {isMainMedia && (
                                <Icon
                                  color={colors.brand.blue}
                                  style={{ marginRight: '5px' }}
                                >
                                  star
                                </Icon>
                              )}
                            </>
                          )}
                          <div>{`${row.name}.${row.fileExtension}`}</div>
                        </div>
                      </CarouselWrapper>
                    </TableCell>
                  )}
                  <TableCell>{`${row.createdBy.firstName} ${row.createdBy.lastName}`}</TableCell>
                  <TableCell>{setDisplayDate(row.createdDate)}</TableCell>
                  <TableCell>
                    <Menu>
                      <a
                        href={`${FILES_ENDPOINT}/${row.fileId}`}
                        download={`${row.name}.${row.fileExtension}`}
                        target="_blank"
                      >
                        <MenuItem startAdornment={<Icon>file_download</Icon>}>
                          Download
                        </MenuItem>
                      </a>
                      <MenuItem
                        startAdornment={<Icon>edit</Icon>}
                        onClick={() => setIsRenameModalOpen({ [row.id]: true })}
                      >
                        Edit
                      </MenuItem>
                      {hasRolePermissions(ROLE_SETS.INSPECTOR) && (
                        <MenuItem
                          startAdornment={
                            <Icon color={isMainMedia && colors.brand.blue}>
                              star
                            </Icon>
                          }
                          onClick={() => setAsPrimaryMedia(row.id)}
                        >
                          {isMainMedia
                            ? 'Unmark As Cover Photo'
                            : 'Mark As Cover Photo'}
                        </MenuItem>
                      )}
                      <MenuItem
                        startAdornment={<Icon>delete</Icon>}
                        onClick={() => setIsDeleteModalOpen({ [row.id]: true })}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                    <RenameModal
                      id={row.id}
                      name={row.name}
                      type={row.type}
                      open={isRenameModalOpen[row.id] ?? false}
                      renameAction={renameMedia}
                      closeModal={() =>
                        setIsRenameModalOpen({ [row.id]: false })
                      }
                    />
                    <DeleteModal
                      title={`Delete ${textDisplayUtilities.capitalize(
                        row.type,
                      )}s`}
                      content={`Are you sure you want to delete this ${row.type}?`}
                      open={isDeleteModalOpen[row.id] ?? false}
                      onCancel={() => setIsDeleteModalOpen({ [row.id]: false })}
                      onConfirm={() => deleteMedia(row.id)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </ListTable>
      ) : (
        <Progress style={styles.progress} />
      )}
    </div>
  );
};

export default ElementMediaTable;
