import React, { useContext, useState } from 'react';

import WorkTemplateCreationModal from '@atom/components/common/workTemplateCreationModal/WorkTemplateCreationModal';
import WorkTemplatesContext from '@atom/components/workTemplates/WorkTemplatesContext';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import history from '@atom/utilities/history';

import './workTemplates.css';

const WorkTemplatesHeader = () => {
  const { setActiveFolderId } = useContext(WorkTemplatesContext);
  const [open, setOpen] = useState<boolean>(false);

  const navigateBack = (): void => {
    return history.goBackWithState('/workOrders');
  };

  const handleClose = () => {
    setOpen(false);
    setActiveFolderId('workTypes');
  };

  const showCreate = hasRolePermissions(ROLE_SETS.ORG_ADMIN);

  return (
    <>
      <div styleName="header-container">
        <div>
          <IconButton onClick={navigateBack}>
            <Icon color={colors.neutral.white}>arrow_back</Icon>
          </IconButton>
          <div styleName="breadcrumb-name-container">
            <span>Work Templates</span>
          </div>
        </div>
        {showCreate && (
          <IconButton
            onClick={() => setOpen(true)}
            disabled={!showCreate}
            data-cy="addTemplateButton"
          >
            <Icon color={colors.neutral.white}>add</Icon>
          </IconButton>
        )}
      </div>
      <WorkTemplateCreationModal open={open} closeModal={handleClose} />
    </>
  );
};

export default WorkTemplatesHeader;
