import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import { requestUpdateExportTemplateSchema } from '@atom/actions/inventoryExportActions';
import { getSubItemCheckedValue } from '@atom/selectors/inventoryExportSelectors';
import {
  DetailAttributeGroupsItem,
  InventorySchemaDetail,
} from '@atom/types/inventory';
import { ExportTemplateDetailType } from '@atom/types/inventoryExport';

import AttributeGroupRow from './AttributeGroupRow';

import './inventoryExportDetail.css';

type AttributeFilters = {
  [id: string]: string[];
};

export interface Props {
  schema: InventorySchemaDetail;
  exportTemplateDetail: ExportTemplateDetailType;
}

const DEBOUNCE_TIME = 1000;

const AttributePane = ({ schema, exportTemplateDetail }: Props) => {
  const dispatch = useDispatch();

  const [selectedAttributes, setSelectedAttributes] = useState<Set<string>>(
    new Set(
      R.pathOr(
        [],
        ['schemas', schema.id, 'attributeIds'],
        exportTemplateDetail,
      ),
    ),
  );

  const [attributeFilters, setAttributeFilters] = useState<AttributeFilters>(
    R.pathOr({}, ['schemas', schema.id, 'filters'], exportTemplateDetail),
  );

  const updateExportTemplateSchema = useCallback(
    debounce((attributeIds: string[], filters: AttributeFilters) => {
      dispatch(
        requestUpdateExportTemplateSchema({
          attributeIds,
          filters,
          exportTemplateId: exportTemplateDetail.id,
          schemaId: schema.id,
        }),
      );
    }, DEBOUNCE_TIME),
    [],
  );

  const handleAttributeToggle = (id: string) => {
    const updatedFilters = selectedAttributes.has(id)
      ? R.dissoc<AttributeFilters>(id, attributeFilters)
      : attributeFilters;

    if (selectedAttributes.has(id)) {
      selectedAttributes.delete(id);
    } else {
      selectedAttributes.add(id);
    }

    const updatedAttributes = [...selectedAttributes];
    setSelectedAttributes(new Set(updatedAttributes));
    setAttributeFilters(updatedFilters);
    updateExportTemplateSchema(updatedAttributes, updatedFilters);
  };

  const handleAttributesFiltersChange = (id: string, filters: string[]) => {
    const updatedFilters = R.isEmpty(filters)
      ? R.dissoc<AttributeFilters>(id, attributeFilters)
      : {
          ...attributeFilters,
          [id]: filters,
        };

    setAttributeFilters(updatedFilters);
    updateExportTemplateSchema([...selectedAttributes], updatedFilters);
  };

  const isDisabled = !getSubItemCheckedValue(schema.id, exportTemplateDetail);

  return (
    <div styleName="section-pane right">
      <div styleName="pane-header">Select Attribute(s) and Value(s)</div>
      <div styleName="item-tree-container">
        {schema.attributeGroups.map(
          (attributeGroup: DetailAttributeGroupsItem) => {
            return (
              <AttributeGroupRow
                key={attributeGroup.id}
                attributeGroup={attributeGroup}
                attributeFilters={attributeFilters}
                selectedAttributes={selectedAttributes}
                onAttributeToggle={handleAttributeToggle}
                onAttributeFiltersChange={handleAttributesFiltersChange}
                isDisabled={isDisabled}
              />
            );
          },
        )}
      </div>
    </div>
  );
};

export default AttributePane;
