import React, { useState } from 'react';

import { useSchemas } from '@atom/hooks/useSchemas';
import { Icon, Modal, Select, TextField } from '@atom/mui';
import {
  InventoryCategoryTree,
  InventorySchemaItem,
} from '@atom/types/inventory';
import schemaUtilities from '@atom/utilities/schemaUtilities';

const { MenuItem } = Select;

export interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (name: string, schemaId?: string) => void;
  loading: boolean;
  category: InventoryCategoryTree;
}

const styles = {
  input: {
    margin: '2rem 0',
  },
  icon: {
    marginRight: '0.5rem',
  },
  modal: {
    padding: '0 2rem',
  },
};

const renderSchema = ({ id, name, colorId }: InventorySchemaItem) => {
  const color = schemaUtilities.getSchemaColorFromColorId(colorId);

  return (
    <MenuItem key={id} value={id}>
      <Icon color={color} style={styles.icon}>
        lens
      </Icon>
      {name}
    </MenuItem>
  );
};

const EditMediaFolder = ({
  open,
  onSubmit,
  onClose,
  loading,
  category,
}: Props) => {
  const [name, setName] = useState<string>(category.name);
  const [schemaId, setSchemaId] = useState<string>(category.schemaId || '');

  const { schemas = [], loading: loadingSchemas } = useSchemas();

  // @ts-ignore
  const isDisabled = category.hasAssets || category.hasItems || !schemas.length;

  const resetState = () => {
    setName(category.name || '');
    setSchemaId(category.schemaId || '');
  };

  const renderValue = id => {
    const selected = schemas.find(schema => schema.id === id);
    return selected ? selected.name : '';
  };

  return (
    <Modal
      title={isDisabled ? 'Rename Folder' : 'Edit Folder'}
      open={open}
      onCancel={onClose}
      onExited={resetState}
      onConfirm={() => onSubmit(name, schemaId)}
      loading={loading}
      contentStyle={styles.modal}
    >
      <div style={styles.input}>
        <TextField
          label="Name"
          value={name}
          name="name"
          onChange={event => setName(event.target.value)}
        />
      </div>
      <div style={styles.input}>
        <Select
          label="Inventory Type (Optional)"
          placeholder="Select"
          onChange={event => setSchemaId(event.target.value)}
          value={schemaId}
          disabled={isDisabled || loadingSchemas}
          renderValue={renderValue}
        >
          {schemas.map(renderSchema)}
        </Select>
      </div>
    </Modal>
  );
};

export default EditMediaFolder;
