import React, { useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import MapSideNavigation from '@atom/components/common/map/mapSideNavigation/MapSideNavigation';

const MapPortalSideNavigation = () => {
  const [navigation, setNavigation] = useQueryParam('view', StringParam);

  useEffect(() => {
    if (!navigation) {
      setNavigation('layers');
    }
  }, [navigation, setNavigation]);

  return (
    <MapSideNavigation
      activeNavigationState={navigation}
      onNavigation={setNavigation}
    />
  );
};

export default MapPortalSideNavigation;
