import React from 'react';

import { Checkbox, Modal } from '@atom/mui';

import './userGroupInfo.css';

interface Props {
  open: boolean;
  loading: boolean;
  removeApproversFromGroup: boolean;
  setRemoveApproversFromGroup: (removeApproversFromGroup: boolean) => void;
  onConfirm: () => void;
  onClose: () => void;
}

const TimeApproversModal = ({
  open,
  loading,
  removeApproversFromGroup,
  setRemoveApproversFromGroup,
  onConfirm,
  onClose,
}: Props) => {
  return (
    <Modal
      title="Time Approvers Changes"
      open={open}
      onConfirm={onConfirm}
      onCancel={onClose}
      loading={loading}
      onExited={onClose}
    >
      <div styleName="time-approvers-modal">
        <div>
          Selected Time Approvers will be added to the group. Any removed Time
          Approvers can also be removed from the group using the option below.
        </div>
        <Checkbox
          label="Remove Time Approvers from Groups"
          checked={removeApproversFromGroup}
          onClick={() => setRemoveApproversFromGroup(!removeApproversFromGroup)}
        />
      </div>
    </Modal>
  );
};

export default TimeApproversModal;
