import React from 'react';

import { usePreferences } from '@atom/hooks/usePreferences';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { Icon, IconButton, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import { TimeEntryStatus, WorkOrderTimeEntry } from '@atom/types/timeEntry';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import {
  convertMillisToExactHours,
  setDisplayDate,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './userTimeEntryModal.css';

interface Props {
  timeEntry: WorkOrderTimeEntry;
  isTimeEntryDisabled: boolean;
  editTimeEntryId: string;
  setEditTimeEntryId: (id: string) => void;
  handleTimeEntryDelete: (id: string) => void;
}

const UserTimeEntryDisplayRow = ({
  timeEntry,
  isTimeEntryDisabled,
  handleTimeEntryDelete,
  editTimeEntryId,
  setEditTimeEntryId,
}: Props) => {
  const userProfile = useUserProfile();
  const preferences = usePreferences();

  const budgetNames = timeEntry.computedBudgets
    .map(budget => `${budget.name}${budget.restored ? ' (snapshot)' : ''}`)
    .join(', ');

  const showTooltip = timeEntry.computedBudgets.length > 1;

  const budgetsTooltipTitle = (
    <div styleName="tooltip-container">
      {timeEntry.computedBudgets.map(budget => (
        <div key={budget.id} styleName="tooltip-row">
          <span>
            {`${budget.name}: `}
            <strong>{`${convertMillisToExactHours(
              budget.duration,
            )} Hour(s)`}</strong>
            {budget.restored && (
              <span styleName="snapshot-suffix">(snapshot)</span>
            )}
          </span>
        </div>
      ))}
    </div>
  );

  const workTimeStyle = showTooltip
    ? { borderBottom: `1px dotted ${colors.neutral.gray}` }
    : {};

  const isEditDisabled =
    isTimeEntryDisabled ||
    !!editTimeEntryId ||
    timeEntry.status === TimeEntryStatus.approved ||
    (timeEntry.userId !== userProfile.userId &&
      doesNotHaveRolePermissions(ROLE_SETS.MANAGER));
  const isDeleteDisabled =
    isTimeEntryDisabled ||
    timeEntry.status === TimeEntryStatus.approved ||
    (timeEntry.userId !== userProfile.userId &&
      doesNotHaveRolePermissions(ROLE_SETS.MANAGER));

  const duration = convertMillisToExactHours(timeEntry.duration);
  const hoursLabel = duration === 1 ? 'Hour' : 'Hours';

  return (
    <div styleName="time-entry-row">
      <div styleName="time-entry-data-section">
        <div styleName="display-container">
          <div styleName="label">Start Date</div>
          <div>{setDisplayDate(timeEntry?.date) || '-'}</div>
        </div>
        {preferences?.timeTracking?.timesheet && (
          <div styleName="display-container">
            <div styleName="label">Group</div>
            <div>{timeEntry?.userGroup?.name || '-'}</div>
          </div>
        )}
        <div styleName="display-container work-time">
          <div styleName="label">Work Time</div>
          {showTooltip ? (
            <Tooltip lightTooltip title={budgetsTooltipTitle}>
              <div style={workTimeStyle}>{`${duration} ${hoursLabel}`}</div>
            </Tooltip>
          ) : (
            <div>{`${duration} ${hoursLabel}`}</div>
          )}
        </div>
        <div styleName="display-container">
          <div styleName="label">
            {isNilOrEmpty(preferences?.timeTracking?.typeEnumeration)
              ? 'Budget'
              : 'Wage Type'}
          </div>
          <div>{budgetNames || '-'}</div>
        </div>
      </div>
      <div styleName="time-entry-button-section">
        <IconButton
          tooltip="Edit"
          onClick={() => setEditTimeEntryId(timeEntry.id)}
          disabled={isEditDisabled}
        >
          <Icon>edit</Icon>
        </IconButton>
        <Tooltip
          title={
            timeEntry.status === TimeEntryStatus.approved
              ? 'Approved time cannot be deleted.'
              : 'Delete'
          }
          lightTooltip={timeEntry.status === TimeEntryStatus.approved}
        >
          <span>
            <IconButton
              onClick={() => handleTimeEntryDelete(timeEntry.id)}
              disabled={isDeleteDisabled}
            >
              <Icon>delete</Icon>
            </IconButton>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default UserTimeEntryDisplayRow;
