import * as React from 'react';

import MapDrawerLayersAssetsSection from './MapDrawerLayersAssetsSection';
import MapDrawerLayersKmlSection from './MapDrawerLayersKmlSection';
import MapDrawerLayersMainHeader from './MapDrawerLayersMainHeader';
import MapDrawerLayersUsersSection from './MapDrawerLayersUsersSection';
import MapDrawerLayersWorkSection from './MapDrawerLayersWorkSection';

import './mapDrawerLayers.css';

interface Props {
  assetSchemas: any;
  workStatusIds: any;
  workTemplateIds: any;
  workTemplateFolderIds: any;
  schemas: any[];
  workToggled: boolean;
  usersToggled: boolean;
  assetsToggled: boolean;
  kmlToggled: boolean;
  onAssetUpdate: (toggled: boolean, selected: Set<string>) => void;
  onWorkUpdate: (toggled: boolean, selected: Set<string>) => void;
  onUserUpdate: (toggled: boolean, selected: Set<string>) => void;
  onKmlUpdate: (toggled: boolean, selected: Set<string>) => void;
  onWorkTemplatesUpdate: (toggled: boolean, selected: Set<any>) => any;
  onWorkTemplateFoldersUpdate: (toggled: boolean, selected: Set<any>) => any;
}

const MapDrawerLayers = ({
  schemas,
  workToggled,
  usersToggled,
  assetsToggled,
  kmlToggled,
  assetSchemas,
  workStatusIds,
  workTemplateIds,
  workTemplateFolderIds,
  onAssetUpdate,
  onWorkUpdate,
  onUserUpdate,
  onKmlUpdate,
  onWorkTemplatesUpdate,
  onWorkTemplateFoldersUpdate,
}: Props) => (
  <div styleName="container">
    <MapDrawerLayersMainHeader />
    <MapDrawerLayersAssetsSection
      schemas={schemas}
      assetSchemas={assetSchemas}
      toggled={assetsToggled}
      // @ts-ignore
      onUpdate={onAssetUpdate}
    />
    <MapDrawerLayersWorkSection
      toggled={workToggled}
      workStatusIds={workStatusIds}
      workTemplateIds={workTemplateIds}
      workTemplateFolderIds={workTemplateFolderIds}
      // @ts-ignore
      onUpdate={onWorkUpdate}
      onWorkTemplatesUpdate={onWorkTemplatesUpdate}
      onWorkTemplateFoldersUpdate={onWorkTemplateFoldersUpdate}
    />
    <MapDrawerLayersUsersSection
      toggled={usersToggled}
      // @ts-ignore
      onUpdate={onUserUpdate}
    />
    <MapDrawerLayersKmlSection
      toggled={kmlToggled}
      //@ts-ignore
      onUpdate={onKmlUpdate}
    />
    <div styleName="container-footer" />
  </div>
);

export default MapDrawerLayers;
