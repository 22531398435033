import * as React from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';

import './workSummaryTray.css';

interface Props {
  isCompleted: boolean;
}

const TaskCompletionIcon = ({ isCompleted }: Props) => {
  return (
    <div styleName="task-completion-icon">
      <IconButton>
        <Icon color={isCompleted ? colors.brand.green : colors.neutral.silver}>
          {isCompleted ? 'check_circle' : 'check_circle_outline'}
        </Icon>
      </IconButton>
    </div>
  );
};

export default TaskCompletionIcon;
