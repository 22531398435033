import * as React from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router-dom';
import { AppBar } from 'material-ui';

import colors from '@atom/styles/colors';
import layoutStyles from '@atom/styles/layout';
import { MenuState, ReduxStore } from '@atom/types/store';

import SearchBar from './search/SearchBar';
import Logo from './Logo';
import NavControls from './NavControls';

interface ReduxStateProps {
  menuState: MenuState;
}

interface PassedProps {
  match: match;
  pathname: string;
}

type Props = ReduxStateProps & PassedProps;

const navBarStyle = {
  height: layoutStyles.topNavHeight,
  backgroundColor: colors.neutral.white,
  boxShadow: '0 2px 4px 1px',
  color: colors.utility.boxShadow,
  position: 'fixed',
  top: 0,
};

const styles = {
  titleStyle: {
    marginTop: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  extendedTitleStyle: {
    marginTop: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
  },
};

const iconStyle = {
  marginTop: '10px',
};

class TopNavigationBar extends React.Component<Props> {
  render() {
    const { menuState, pathname } = this.props;

    const titleStyle = menuState.search
      ? styles.extendedTitleStyle
      : styles.titleStyle;

    return (
      <AppBar
        style={navBarStyle}
        iconStyleLeft={iconStyle}
        iconStyleRight={iconStyle}
        title={<SearchBar />}
        titleStyle={titleStyle}
        iconElementLeft={<Logo pathname={pathname} />}
        iconElementRight={<NavControls pathname={pathname} />}
      />
    );
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  menuState: state.menuState,
});

export default connect(mapStateToProps, null)(TopNavigationBar);
