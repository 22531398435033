import * as React from 'react';
import * as R from 'ramda';

import RenameModal from '@atom/components/common/RenameModal';
// @ts-ignore
import folderMoveIcon from '@atom/components/common/svgIcons/folderMove.svg';
// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import { Checkbox, Icon, Menu, Modal, Progress } from '@atom/mui';
import config from '@atom/selectors/config';
import { sortMedia } from '@atom/selectors/mediaSelectors';
import colors from '@atom/styles/colors';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';
import { getMediaIcon } from '@atom/utilities/iconUtilities';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import { getUserFullName } from '@atom/utilities/userUtilities';

import CreateMediaFolderModal from './CreateMediaFolderModal';
import EditMediaFolderModal from './EditMediaFolderModal';
import MoveMediaFolderModal from './MoveMediaFolderModal';

import './folders.css';

const { MenuItem } = Menu;

interface Props {
  media?: any[];
  mediaFolders?: any[];
  selectedType?: string;
  onToggle: (id: string, path: any[], expanded: boolean, name?: string) => void;
  folderPath?: any[];
  mediaFolderTree?: any;
  onEdit: (folderId: string, path: any[], folderName: string) => void;
  onCreate: (folderId: string, path: any[], folderName: string) => void;
  onDelete: (id: string, path: any[]) => void;
  onMoveComplete: (id: string, destId: string, destPath: any[]) => void;
  renameMedia: (id: any, name: any) => void;
  deleteMedia: (id: string) => void;
  loading?: boolean;
  toggleCheck: (id: string, data: boolean) => void;
  selectedItems: any[];
  canUpdateMedia: boolean;
  canDeleteMedia: boolean;
}

enum ModalType {
  EDIT_FOLDER = 'EDIT_FOLDER',
  ADD_SUB_FOLDER = 'ADD_SUB_FOLDER',
  MOVE_FOLDER = 'MOVE_FOLDER',
  DELETE_FOLDER = 'DELETE_FOLDER',
  EDIT_FILE = 'EDIT_FILE',
  DELETE_FILE = 'DELETE_FILE',
  NONE = 'NONE',
}

interface State {
  sortColumn: string | undefined;
  isAscending: boolean;
  openModal: { type: ModalType; id: any };
}

const initialState: State = {
  sortColumn: '',
  isAscending: false,
  openModal: { type: ModalType.NONE, id: null },
};

const tableDisplayHeaders = [
  '',
  'name',
  'file type',
  'created by',
  'created on',
  '',
];

const tableFields = [
  'checkbox',
  'name',
  'fileExtension',
  'createdBy',
  'createdDate',
  'additionalOptions',
];

class MediaTable extends React.Component<Props> {
  state = initialState;

  toggleModal = (modalType: ModalType, openId: any) => {
    this.setState({ openModal: { type: modalType, id: openId } });
  };

  isModalOpen = (modalType: ModalType, openId: any) => {
    const { type, id } = this.state.openModal;
    return type === modalType && id === openId;
  };

  toggleSort = headerIndex => {
    const { sortColumn, isAscending } = this.state;
    const sortProperty = tableFields[headerIndex];

    const sortDirection = sortColumn === sortProperty ? !isAscending : false;

    this.setState({
      sortColumn: sortProperty,
      isAscending: sortDirection,
    });
  };

  buildTableHeaders = displayHeaders => {
    return displayHeaders.map((header, headerIndex) => {
      const { sortColumn, isAscending } = this.state;
      const isSortable = header !== '';
      const sortDirection =
        isAscending && sortColumn === tableFields[headerIndex]
          ? 'sort-ascending'
          : 'sort-descending';

      const isSelected =
        sortColumn === tableFields[headerIndex] ? 'selected' : '';

      const sortClass = isSortable
        ? `header-cell ${sortDirection} ${isSelected}`
        : 'header-cell';

      const onClick = isSortable
        ? () => this.toggleSort(headerIndex)
        : () => {};

      return (
        <th styleName={sortClass} key={headerIndex} onClick={onClick}>
          {header}
        </th>
      );
    });
  };

  buildRowFields = (row, fields) => {
    return fields.map((field, fieldIndex) => {
      return this.buildCell(row, field, fieldIndex);
    });
  };

  buildRows = (dataRows, fields) => {
    return dataRows.map((row, rowIndex) => {
      return <tr key={rowIndex}>{this.buildRowFields(row, fields)}</tr>;
    });
  };

  getPath = (mediaFolderTree, ancestors, folderPath) => {
    const child = R.pathOr(null, ['children'], mediaFolderTree);
    if (R.isNil(child) || R.isEmpty(ancestors)) {
      return folderPath;
    }
    const index = child.findIndex(item => item.id === ancestors[0]);
    const path = [...folderPath, 'children', index];
    const nextAncestors = R.remove(0, 1, ancestors);

    return this.getPath(child[index], nextAncestors, path);
  };

  onFolderClick = row => {
    const { onToggle, mediaFolderTree } = this.props;
    const expanded = true;
    const ancestors = [...R.pathOr([], ['ancestors'], row), row.id];
    const path = this.getPath(mediaFolderTree, ancestors, []);

    onToggle(row.id, path, expanded);
  };

  buildCell = (row, field, index) => {
    const {
      mediaFolderTree,
      onEdit,
      onCreate,
      onDelete,
      onMoveComplete,
      renameMedia,
      deleteMedia,
      toggleCheck,
      selectedItems,
      loading,
      canUpdateMedia,
      canDeleteMedia,
    } = this.props;

    const ancestors = [...R.pathOr([], ['ancestors'], row), row.id];
    const folderPath = this.getPath(mediaFolderTree, ancestors, []);
    const isCreateDisabled = folderPath.length >= config.MAX_FOLDER_DEPTH * 2;
    const extension = row.fileId
      ? R.pathOr(null, ['fileExtension'], row)
      : 'folder';

    const nameLink =
      extension !== 'folder' ? (
        <a
          styleName="clickable-link"
          href={`${FILES_ENDPOINT}/${row.fileId}`}
          target="_blank"
        >{`${row.name}.${extension}`}</a>
      ) : (
        <div styleName="clickable-link" onClick={() => this.onFolderClick(row)}>
          {row.name}
        </div>
      );

    const onConfirmDeleteFile = () => {
      deleteMedia(row.id);
      this.toggleModal(ModalType.NONE, null);
    };

    const onConfirmDeleteFolder = () => {
      onDelete(row.id, folderPath);
      this.toggleModal(ModalType.NONE, null);
    };

    const folderMenu = (
      <>
        <Menu>
          {canUpdateMedia && (
            <MenuItem
              startAdornment={
                <Icon>
                  <img src={renameIcon} />
                </Icon>
              }
              onClick={() => this.toggleModal(ModalType.EDIT_FOLDER, row.id)}
            >
              Rename
            </MenuItem>
          )}
          {!isCreateDisabled && (
            <MenuItem
              startAdornment={<Icon>create_new_folder</Icon>}
              onClick={() => this.toggleModal(ModalType.ADD_SUB_FOLDER, row.id)}
            >
              Create Folder
            </MenuItem>
          )}
          {canUpdateMedia && (
            <MenuItem
              startAdornment={
                <Icon>
                  <img src={folderMoveIcon} />
                </Icon>
              }
              onClick={() => this.toggleModal(ModalType.MOVE_FOLDER, row.name)}
            >
              Move To Folder
            </MenuItem>
          )}
          {canDeleteMedia && (
            <MenuItem
              startAdornment={<Icon>delete</Icon>}
              onClick={() => this.toggleModal(ModalType.DELETE_FOLDER, row.id)}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
        {canUpdateMedia && (
          <EditMediaFolderModal
            open={this.isModalOpen(ModalType.EDIT_FOLDER, row.id)}
            closeModal={() => this.toggleModal(ModalType.NONE, null)}
            folderId={row.id}
            folderPath={folderPath}
            name={row.name}
            type="folder"
            editAction={(folderId, path, folderName) =>
              onEdit(folderId, path, folderName)
            }
          />
        )}
        {!isCreateDisabled && (
          <CreateMediaFolderModal
            open={this.isModalOpen(ModalType.ADD_SUB_FOLDER, row.id)}
            closeModal={() => this.toggleModal(ModalType.NONE, null)}
            folderId={row.id}
            folderPath={folderPath}
            type="folder"
            createAction={(folderId, path, folderName) =>
              onCreate(folderId, path, folderName)
            }
          />
        )}
        {canUpdateMedia && (
          <MoveMediaFolderModal
            open={this.isModalOpen(ModalType.MOVE_FOLDER, row.id)}
            closeModal={() => this.toggleModal(ModalType.NONE, null)}
            mediaFolderTree={mediaFolderTree}
            type="folder"
            ids={[row.id]}
            loading={loading}
            onEdit={onEdit}
            onCreate={onCreate}
            onComplete={onMoveComplete}
            disabled={[
              row.id,
              ...(row.parentFolderId ? [row.parentFolderId] : []),
              ...(row.ancestors && row.ancestors.length
                ? [R.last(row.ancestors)]
                : ['root']),
            ]}
          />
        )}
        {canDeleteMedia && (
          <Modal
            open={this.isModalOpen(ModalType.DELETE_FOLDER, row.id)}
            title="Delete Folder"
            onCancel={() => this.toggleModal(ModalType.NONE, null)}
            confirmButtonText="Delete"
            ConfirmButtonProps={{ style: { background: colors.brand.red } }}
            onConfirm={onConfirmDeleteFolder}
          >
            Are you sure you want to delete this folder? If the folder has any
            subfolders or files saved below this folder, everything will be
            deleted.
          </Modal>
        )}
      </>
    );

    const fileMenu = (
      <>
        <Menu>
          {canUpdateMedia && (
            <MenuItem
              startAdornment={
                <Icon>
                  <img src={renameIcon} />
                </Icon>
              }
              onClick={() => this.toggleModal(ModalType.EDIT_FILE, row.id)}
            >
              Rename
            </MenuItem>
          )}
          <MenuItem startAdornment={<Icon>file_download</Icon>}>
            Download
            <a
              styleName="download-link"
              href={`${FILES_ENDPOINT}/${row.fileId}`}
              download={`${row.name}.${row.fileExtension}`}
            />
          </MenuItem>
          {canDeleteMedia && (
            <MenuItem
              startAdornment={<Icon>delete</Icon>}
              onClick={() => this.toggleModal(ModalType.DELETE_FILE, row.id)}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
        {canUpdateMedia && (
          <RenameModal
            open={this.isModalOpen(ModalType.EDIT_FILE, row.id)}
            closeModal={() => this.toggleModal(ModalType.NONE, null)}
            id={row.id}
            name={row.name}
            type={row.type}
            renameAction={renameMedia}
          />
        )}
        {canDeleteMedia && (
          <Modal
            open={this.isModalOpen(ModalType.DELETE_FILE, row.id)}
            title={`Delete ${textDisplayUtilities.capitalize(row.type)}s`}
            onCancel={() => this.toggleModal(ModalType.NONE, null)}
            confirmButtonText="Delete"
            ConfirmButtonProps={{
              style: { background: colors.brand.red },
            }}
            onConfirm={onConfirmDeleteFile}
          >
            {`Are you sure you want to delete this ${row.type}?`}
          </Modal>
        )}
      </>
    );

    const optionsMenu = extension === 'folder' ? folderMenu : fileMenu;
    const showFolderOptions =
      canUpdateMedia || canDeleteMedia || !isCreateDisabled;
    const showOptions = extension === 'folder' ? showFolderOptions : true;

    const fieldTemplates = {
      checkbox: (
        <td key={index} styleName="checkbox">
          {extension !== 'folder' && (
            <Checkbox
              checked={selectedItems.includes(row.id)}
              onChange={() =>
                toggleCheck(row.id, selectedItems.includes(row.id))
              }
            />
          )}
        </td>
      ),
      name: (
        <td key={index} styleName="clickable">
          <div styleName="name-row-container">
            <div styleName="name-row-icon">
              {getMediaIcon(extension, row.fileId, row.name)}
            </div>
            {nameLink}
          </div>
        </td>
      ),
      fileExtension: <td key={index}>{row.fileExtension}</td>,
      createdBy: (
        <td key={index}>
          {row.createdBy ? getUserFullName(row.createdBy) : '-'}
        </td>
      ),
      createdDate: <td key={index}>{setDisplayDate(row.createdDate)}</td>,
      additionalOptions: (
        <td key={index} styleName="additional-options">
          {showOptions && optionsMenu}
        </td>
      ),
    };
    return fieldTemplates[field] || <td key={index}>{row[field]}</td>;
  };

  render() {
    const { sortColumn, isAscending } = this.state;
    const { media, mediaFolders, selectedType, loading } = this.props;

    const sortedMedia = sortMedia(sortColumn, isAscending, media);
    const sortedMediaFolders = sortMedia(sortColumn, isAscending, mediaFolders);

    const mediaList = selectedType
      ? sortedMedia
      : [...sortedMediaFolders, ...sortedMedia];

    return (
      <div styleName="main-pane detail">
        <table styleName="sticky">
          <thead>
            <tr>{this.buildTableHeaders(tableDisplayHeaders)}</tr>
          </thead>
          {!loading && <tbody>{this.buildRows(mediaList, tableFields)}</tbody>}
        </table>
        {loading && <Progress style={{ height: '100%' }} />}
      </div>
    );
  }
}

export default MediaTable;
