import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import debounce from 'lodash.debounce';

import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { ATTRIBUTE_UPDATE } from '@atom/graph/schema';
import { TextField } from '@atom/mui';
import { AttributeUpdateInput, SchemaTree } from '@atom/types/schema';

import { updatePendingCreation } from '../subItemDetail/subItemDetailUtilities';

import './attributeDetail.css';

const styles = {
  input: {
    paddingBottom: '0.5rem',
  },
};

const DEBOUNCE_TIME = 500;

const UnitInput = () => {
  const {
    schemaTree,
    selectedAttribute,
    setSelectedAttribute,
    selectedSubItem,
    selectedAttributeRoute,
    refetchSchemaTree,
    setPendingCreations,
  } = useContext(SchemaDetailContext);

  const [unit, setUnit] = useState<string>(selectedAttribute.unit);

  useEffect(() => {
    setUnit(selectedAttribute.unit);
  }, [selectedAttribute]);

  const [updateAttribute] = useMutation<
    { attributeUpdate: SchemaTree },
    { input: AttributeUpdateInput }
  >(ATTRIBUTE_UPDATE);

  // If attribute is a temp attribute, values must be updated in pendingCreations state
  const handlePendingCreationUpdate = (value: string) => {
    const updatedAttribute = { ...selectedAttribute, unit: value };

    setPendingCreations(prev =>
      updatePendingCreation(
        selectedSubItem.id,
        selectedAttributeRoute,
        prev,
        updatedAttribute,
      ),
    );

    setSelectedAttribute(updatedAttribute);
  };

  const handleAttributeUpdate = async (value: string) => {
    if (selectedAttribute.isTempAttribute) {
      handlePendingCreationUpdate(value);
    } else {
      setSelectedAttribute(prev => ({ ...prev, unit: value }));

      await updateAttribute({
        variables: {
          input: {
            schemaId: selectedSubItem.id,
            attributeGroupId: selectedAttributeRoute.attributeGroupId,
            attributeId: selectedAttribute.id,
            unit: value,
          },
        },
      });

      refetchSchemaTree();
    }
  };

  const updateAttributeDebounced = useCallback(
    debounce((newUnit: string) => {
      handleAttributeUpdate(newUnit);
    }, DEBOUNCE_TIME),
    [selectedAttribute],
  );

  const handleUnitChange = event => {
    setUnit(event.target.value);

    if (!event.target.value) {
      updateAttributeDebounced.cancel();
    } else {
      updateAttributeDebounced(event.target.value);
    }
  };

  const isDisabled =
    schemaTree.isPublished && !selectedAttribute.isTempAttribute;

  return (
    <TextField
      variant="standard"
      label="Unit"
      value={unit}
      name="unit"
      style={styles.input}
      onChange={handleUnitChange}
      disabled={isDisabled}
    />
  );
};

export default UnitInput;
