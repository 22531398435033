import React, { useEffect, useState } from 'react';

import { Modal, TextField } from '@atom/mui';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';

import './renameModal.css';

const styles = {
  content: {
    minHeight: '5rem',
  },
};

interface Props {
  id: string;
  name: string;
  type: string;
  renameAction: (id: string, name: string) => any;
  open?: boolean;
  closeModal?: () => any;
  confirmText?: string;
}

const RenameModal = ({
  id,
  name,
  type,
  renameAction,
  open,
  closeModal,
  confirmText = 'Rename',
}: Props) => {
  const [nameValue, setNameValue] = useState<string>('');

  useEffect(() => {
    setNameValue(name);
  }, [open, name]);

  const onClose = () => {
    setNameValue('');
    closeModal();
  };

  const onConfirm = () => {
    renameAction(id, nameValue);
    closeModal();
  };

  const helperText =
    nameValue.length === 0 ? `The ${type} name cannot be blank` : '';

  return !open ? (
    <div />
  ) : (
    <Modal
      open={open}
      title={`Rename ${textDisplayUtilities.capitalize(type)}`}
      onConfirm={onConfirm}
      onCancel={onClose}
      confirmButtonText={confirmText}
      cancelButtonText="Cancel"
      contentStyle={styles.content}
    >
      <TextField
        id="name"
        key="name"
        label="Name"
        value={nameValue}
        onChange={event => setNameValue(event.target.value)}
        error={nameValue.length === 0}
        helperText={helperText}
      />
    </Modal>
  );
};

export default RenameModal;
