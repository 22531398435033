import { gql } from '@apollo/client';

export const BASE_MEDIA = gql`
  fragment BaseMedia on Media {
    id
    name
    type
    fileId
    fileExtension
    location {
      type
      coordinates
    }
    description
    parentSubjectId
    subjectId
    subjectType
    folderId
    folderPath
    capturedDate
    createdDate
    createdBy {
      firstName
      lastName
    }
    updatedDate
    updatedBy {
      firstName
      lastName
    }
  }
`;

export const GET_MEDIUM = gql`
  query medium($id: ID!) {
    medium(id: $id) {
      ...BaseMedia
    }
  }
  ${BASE_MEDIA}
`;

export const GET_MEDIA = gql`
  query media($input: MediaConnectionInput!) {
    media(input: $input) {
      totalCount
      media {
        ...BaseMedia
      }
    }
  }
  ${BASE_MEDIA}
`;

export const MEDIA_UPDATE = gql`
  mutation mediaUpdate($input: MediaUpdateInput!) {
    mediaUpdate(input: $input) {
      ...BaseMedia
    }
  }
  ${BASE_MEDIA}
`;

export const MEDIA_DELETE = gql`
  mutation mediaDelete($id: ID!) {
    mediaDelete(id: $id)
  }
`;

export const MEDIA_BULK_DELETE = gql`
  mutation mediaBulkDelete($input: MediaBulkDeleteInput!) {
    mediaBulkDelete(input: $input)
  }
`;
