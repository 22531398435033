import React, { useContext } from 'react';

import { Icon } from '@atom/components/common/mui';
import colors from '@atom/styles/colors';
import {
  DashboardCard,
  DashboardCardContentState,
} from '@atom/types/dashboard';

import DashboardContext from '../DashboardContext';
import { getCardHeight } from '../dashboardUtilities';

import '../dashboardCard.css';

interface Props {
  card: DashboardCard;
}

const CardError = ({ card }: Props) => {
  const { updateCardState } = useContext(DashboardContext);

  const handleRefresh = () => {
    updateCardState(card, DashboardCardContentState.LOADING);
  };

  const style = {
    buttonLink: {
      paddingLeft: '0.25rem',
      color: colors.brand.blue,
      fontWeight: '500',
    },
    cardBody: {
      height: getCardHeight(card),
    },
  };

  return (
    <div style={style.cardBody} styleName="card-body-empty">
      <div>
        <Icon style={{ fontSize: '2rem' }} color={colors.neutral.ash}>
          warning
        </Icon>
      </div>
      <div>
        Cannot Load Data.
        <a style={style.buttonLink} onClick={handleRefresh}>
          Refresh
        </a>
      </div>
    </div>
  );
};

export default CardError;
