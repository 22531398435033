import React, { useContext } from 'react';

import { DatePicker } from '@atom/mui';
import colors from '@atom/styles/colors';

import BulkContext from '../../../BulkContext';
import { useWorkOrderValidation } from '../../../hooks/bulkWorkOrderHook';

export interface Props {
  error?: boolean;
  label: string;
  name: string;
  required?: boolean;
}

const styles = {
  label: {
    position: 'unset',
    color: colors.neutral.dim,
  },
};

const DateField = ({ error = false, label, name, required = false }: Props) => {
  const { workOrder, setWorkOrder } = useContext(BulkContext);
  const { validateField } = useWorkOrderValidation();

  const handleChange = (date?: Date) => {
    const value = date && !Number.isNaN(date.valueOf()) ? date.valueOf() : null;

    const newWorkOrder = {
      ...workOrder,
      [name]: value,
    };

    setWorkOrder(newWorkOrder);
    if (required) {
      validateField(newWorkOrder, name);
    }
  };

  const errorFieldName = label?.toLowerCase().replace(/^\* /, '');

  return (
    <DatePicker
      label={label}
      labelStyle={styles.label}
      onChange={handleChange}
      value={workOrder?.[name]}
      errorHelperText={`Please choose a ${errorFieldName}`}
      TextFieldProps={{ error }}
    />
  );
};

export default DateField;
