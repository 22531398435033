import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';

import WorkExportProjections from '@atom/components/common/workExport/WorkExportProjections';
import {
  EXPORT_EVALUATE,
  EXPORT_UPDATE,
  GET_PROJECTION_OPTIONS,
} from '@atom/graph/export';
import { GET_WORK_ORDER_TEMPLATE } from '@atom/graph/workTemplate';
import { useWorkOrdersFilters } from '@atom/hooks/useWorkOrdersFilters';
import { Button, Icon, Modal, Progress, Snackbar, TextField } from '@atom/mui';
import {
  Export,
  ExportEvaluateInput,
  ExportType,
  ExportUpdateInput,
  ProjectionOption,
  ProjectionOptionsConnection,
} from '@atom/types/export';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import WorkExportNotification from '../common/workExport/WorkExportNotification';

import './workExportDetail.css';

const TYPENAME = '__typename';

interface Props {
  export: Export;
  open: boolean;
  onClose: () => void;
}

const getSelectedProjections = (
  exp: Export,
): { [key: string]: Set<string> } => {
  return exp.projections.reduce((acc, section) => {
    return {
      ...acc,
      [section.id]: new Set(section.selectedFields),
    };
  }, {});
};

const WorkExportDetailModal = ({ open, onClose, export: exp }: Props) => {
  const { input, workExportFilters } = useWorkOrdersFilters();

  const [search, setSearch] = useState<string>('');
  const [template, setTemplate] = useState<{ id: string; name: string }>(null);
  const [exportedMessage, setExportedMessage] = useState<string>('');
  const [projectionsChanged, setProjectionsChanged] = useState<boolean>(false);
  const [projections, setProjections] = useState<ProjectionOption[]>([]);
  const [filteredProjections, setFilteredProjections] = useState<
    ProjectionOption[]
  >([]);
  const [selectedProjections, setSelectedProjections] = useState<{
    [id: string]: Set<string>;
  }>(getSelectedProjections(exp));

  const [updateExport, { loading: loadingUpdate }] = useMutation<
    { export: Export },
    { input: ExportUpdateInput }
  >(EXPORT_UPDATE);

  const [evaluateExport, { loading: loadingEvaluation }] = useMutation<
    { exportCreate: Export },
    { input: ExportEvaluateInput }
  >(EXPORT_EVALUATE, {});

  const [
    loadProjectionOptions,
    { loading: loadingProjections },
  ] = useLazyQuery<{
    projectionOptions: ProjectionOptionsConnection;
  }>(GET_PROJECTION_OPTIONS, {
    onCompleted: res => {
      const opts = res?.projectionOptions?.projectionOptions || [];
      setProjections(opts);
      setFilteredProjections(opts);
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [
    getWorkOrderTemplate,
    { data: workOrderTemplateData, loading: loadingTemplate },
  ] = useLazyQuery<
    { workOrderTemplate: { id: string; name: string } },
    { id: string }
  >(GET_WORK_ORDER_TEMPLATE);

  useEffect(() => {
    if (workOrderTemplateData) {
      setTemplate(workOrderTemplateData.workOrderTemplate);
    }
  }, [workOrderTemplateData]);

  useEffect(() => {
    if (open) {
      loadProjectionOptions({
        variables: {
          input: {
            type: ExportType.WORK,
            filters: workExportFilters,
          },
        },
      });
    }
  }, [open]);

  useEffect(() => {
    if (open && input.workTemplateIds.length === 1) {
      getWorkOrderTemplate({
        variables: {
          id: input.workTemplateIds.join(''),
        },
      });
    } else if (input.workTemplateIds.length !== 1) {
      setTemplate(null);
    }
  }, [open, input.workTemplateIds]);

  useEffect(() => {
    const filtered = projections.map(projection => ({
      ...projection,
      availableFields: projection.availableFields.filter(field =>
        field.toLowerCase().includes(search.toLowerCase()),
      ),
    }));

    setFilteredProjections(filtered);
  }, [search, projections]);

  const getProjectionsForInput = () =>
    projections
      .filter(projection => !!selectedProjections[projection.id])
      .map(projection => ({
        id: projection.id,
        name: projection.name,
        selectedFields: [...selectedProjections[projection.id]],
      }));

  const resetState = () => {
    setSearch('');
    setExportedMessage('');
    setProjectionsChanged(false);
    setSelectedProjections(getSelectedProjections(exp));
  };

  const handleToggle = (id: string, field: string) => {
    setProjectionsChanged(true);

    setSelectedProjections(
      R.over(
        R.lensPath([id]),
        selected =>
          selected ? toggleFromSet(selected, field) : new Set([field]),
        selectedProjections,
      ),
    );
  };

  const handleSectionToggle = (id: string, fieldLength: number) => {
    setProjectionsChanged(true);

    setSelectedProjections(
      selectedProjections[id]?.size === fieldLength
        ? R.omit([id], selectedProjections)
        : {
            ...selectedProjections,
            [id]: new Set(
              projections.find(section => section.id === id).availableFields,
            ),
          },
    );
  };

  const isSelected = Object.values(selectedProjections)?.filter(
    projection => projection.size > 0,
  )?.length;

  const handleSelectAll = () => {
    setProjectionsChanged(true);

    setSelectedProjections(
      isSelected
        ? filteredProjections.reduce((acc, section) => {
            return {
              ...acc,
              [section.id]: new Set([]),
            };
          }, {})
        : filteredProjections.reduce((acc, section) => {
            return {
              ...acc,
              [section.id]: new Set(section.availableFields),
            };
          }, {}),
    );
  };

  const handleEvaluate = async () => {
    try {
      await evaluateExport({
        variables: {
          input: {
            exportId: exp.id,
          },
        },
      });

      setExportedMessage('Export in progress!');
    } catch (error) {
      Snackbar.error({
        message: `Export ${exp.name} Failed`,
      });
    }
  };

  const handleSave = async () => {
    try {
      await updateExport({
        variables: {
          input: {
            id: exp.id,
            type: exp.type,
            name: exp.name,
            filter: R.omit([TYPENAME], exp.filter),
            projections: getProjectionsForInput(),
          },
        },
      });

      Snackbar.info({
        message: `Saved ${exp.name}`,
      });

      onClose();
    } catch (error) {
      Snackbar.error({
        message: `Save ${exp.name} Failed`,
      });
    }
  };

  const handleSaveAndEvaluate = async () => {
    try {
      await evaluateExport({
        variables: {
          input: {
            exportId: exp.id,
            inline: {
              save: true,
              export: {
                type: exp.type,
                name: exp.name,
                filter: R.omit([TYPENAME], exp.filter),
                projections: getProjectionsForInput(),
              },
            },
          },
        },
      });

      setExportedMessage('Export is saved and in progress!');
    } catch (error) {
      Snackbar.error({
        message: `Save and export ${exp.name} Failed`,
      });
    }
  };

  const loading = loadingProjections || loadingEvaluation || loadingUpdate;

  const footer = exportedMessage ? (
    <Button onClick={() => onClose()}>OK</Button>
  ) : projectionsChanged ? (
    <>
      <Button onClick={() => onClose()} disabled={loading}>
        Cancel
      </Button>
      <Button
        onClick={handleEvaluate}
        disabled={loading}
        color="primary"
        variant="contained"
      >
        Export
      </Button>
      <Button
        onClick={handleSave}
        disabled={loading}
        color="primary"
        variant="contained"
      >
        Save
      </Button>
      <Button
        onClick={handleSaveAndEvaluate}
        disabled={loading}
        color="primary"
        variant="contained"
      >
        Save & Export
      </Button>
    </>
  ) : null;

  return (
    <Modal
      title={exportedMessage ? 'Export in Progress' : 'Export Work'}
      open={open}
      onCancel={onClose}
      onExited={resetState}
      confirmButtonText="Export"
      footer={footer}
      loading={loadingProjections || loadingEvaluation || loadingUpdate}
      onConfirm={handleEvaluate}
    >
      {exportedMessage ? (
        <WorkExportNotification message={exportedMessage} />
      ) : (
        <>
          <div>
            {template && !loadingTemplate ? (
              <span>
                Customize columns for work template{' '}
                <strong>{template.name}</strong>
              </span>
            ) : (
              'Customize columns'
            )}
          </div>
          <div styleName="search-box">
            <div styleName="search-box-spacing">
              <div styleName="search-input">
                <Icon>search</Icon>
                <TextField
                  autoComplete="off"
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                  }}
                  placeholder="Search data"
                  value={search}
                  onChange={event => setSearch(event.target.value)}
                />
              </div>
              <div styleName="select-all-button-container">
                <div styleName="select-all-button" onClick={handleSelectAll}>
                  {isSelected ? 'Deselect All' : 'Select All'}
                </div>
                {!!input.workTemplateIds.length && (
                  <div styleName="custom-fields-description">
                    * denote custom fields
                  </div>
                )}
              </div>
              {loadingProjections ? (
                <div styleName="progress">
                  <Progress style={{ flex: 1 }} />
                </div>
              ) : (
                filteredProjections.map((filteredProjection, index) => (
                  <WorkExportProjections
                    key={index}
                    projection={filteredProjection}
                    filterCounter={
                      projections[index].availableFields.length -
                      filteredProjection.availableFields.length
                    }
                    fields={filteredProjection.availableFields}
                    customFields={filteredProjection.customFields}
                    fieldLength={filteredProjection.availableFields.length}
                    selectedProjections={selectedProjections}
                    handleToggle={handleToggle}
                    handleSectionToggle={handleSectionToggle}
                  />
                ))
              )}
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default WorkExportDetailModal;
