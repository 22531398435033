import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_TASK_UPDATE } from '@atom/graph/workTemplate';
import { Button, Icon, Modal, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  AssetSettingsView,
  RequiredOption,
  WorkOrderTemplateTaskUpdateInput,
  WorkTemplateTaskItem,
} from '@atom/types/workTemplate';
import { WorkTypeVariant } from '@atom/utilities/workTemplateUtilities';

import AllowedSchemaView from './AllowedSchemaView';
import RequiredSchemaView from './RequiredSchemaView';

import './multiSchemaSection.css';

const styles = {
  modal: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem 1.5rem 0 1.5rem',
    height: '50vh',
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
}

const SchemaSettingsModal = ({ open, onClose }: Props) => {
  const { workTemplate, task, refetch, workTypeVariant } = useContext(
    WorkTemplateContext,
  );

  const [activeView, setActiveView] = useState<AssetSettingsView>(
    AssetSettingsView.ALLOWED,
  );
  const [allowedMultiAssetSchemaIds, setAllowedMultiAssetSchemaIds] = useState<
    Set<string>
  >(new Set(task?.allowedMultiAssetSchemaIds));
  const [
    requiredMultiAssetSchemaIds,
    setRequiredMultiAssetSchemaIds,
  ] = useState<Set<string>>(new Set(task?.requiredMultiAssetSchemaIds));
  const [
    requireAtLeastOneMultiAsset,
    setRequireAtLeastOneMultiAsset,
  ] = useState<boolean>(task?.requireAtLeastOneMultiAsset);
  const [requiredOption, setRequiredOption] = useState<RequiredOption>(
    RequiredOption.NONE,
  );

  const [taskTemplateUpdate] = useMutation<
    { workOrderTemplateTaskUpdate: WorkTemplateTaskItem },
    { input: WorkOrderTemplateTaskUpdateInput }
  >(WORK_TEMPLATE_TASK_UPDATE, { onCompleted: res => refetch() });

  useEffect(() => {
    setAllowedMultiAssetSchemaIds(new Set(task?.allowedMultiAssetSchemaIds));
    setRequiredMultiAssetSchemaIds(new Set(task?.requiredMultiAssetSchemaIds));
    setRequireAtLeastOneMultiAsset(task?.requireAtLeastOneMultiAsset);
    setActiveView(AssetSettingsView.ALLOWED);
  }, [open]);

  const handleSave = async () => {
    if (!task?.multiAssetsEnabled) {
      await taskTemplateUpdate({
        variables: {
          input: {
            taskId: task.id,
            multiAssetsEnabled: true,
            workTemplateId: workTemplate.id,
          },
        },
      });
    }

    try {
      await taskTemplateUpdate({
        variables: {
          input: {
            taskId: task.id,
            multiAssetsEnabled: true,
            workTemplateId: workTemplate.id,
            allowedMultiAssetSchemaIds: [...allowedMultiAssetSchemaIds],
            requiredMultiAssetSchemaIds: [...requiredMultiAssetSchemaIds],
            requireAtLeastOneMultiAsset,
          },
        },
      });

      onClose();
    } catch (err) {
      Snackbar.error({
        message: 'Failed to save asset settings. Please try again.',
      });

      onClose();
    }
  };

  const isNextDisabled = allowedMultiAssetSchemaIds.size === 0;
  const isSaveDisabled =
    (requiredMultiAssetSchemaIds.size === 0 &&
      requiredOption === RequiredOption.MANY) ||
    workTypeVariant === WorkTypeVariant.CHILD;

  const getFooter = () => {
    const showCreateButton = activeView === AssetSettingsView.REQUIRED;
    const showBackButton = activeView === AssetSettingsView.REQUIRED;

    const rightArrowColor = isNextDisabled
      ? colors.neutral.gray
      : colors.neutral.white;

    return (
      <div styleName="footer">
        {showBackButton ? (
          <Button onClick={() => setActiveView(AssetSettingsView.ALLOWED)}>
            <Icon>chevron_left</Icon>Back
          </Button>
        ) : (
          <div />
        )}
        <div>
          <Button
            onClick={onClose}
            style={{ marginRight: '0.5rem' }}
            data-cy="assetSettingsModalCancelButton"
          >
            Cancel
          </Button>
          {showCreateButton ? (
            <Button
              color="primary"
              variant="contained"
              onClick={handleSave}
              data-cy="assetSettingsModalCreateButton"
              disabled={isSaveDisabled}
            >
              Save
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={() => setActiveView(AssetSettingsView.REQUIRED)}
              data-cy="assetSettingsModalNextButton"
              disabled={isNextDisabled}
            >
              Next<Icon color={rightArrowColor}>chevron_right</Icon>
            </Button>
          )}
        </div>
      </div>
    );
  };

  const getContent = (): React.ReactNode => {
    const views = {
      [AssetSettingsView.ALLOWED]: (
        <AllowedSchemaView
          allowedMultiAssetSchemaIds={allowedMultiAssetSchemaIds}
          setAllowedMultiAssetSchemaIds={setAllowedMultiAssetSchemaIds}
          requiredMultiAssetSchemaIds={requiredMultiAssetSchemaIds}
          setRequiredMultiAssetSchemaIds={setRequiredMultiAssetSchemaIds}
        />
      ),
      [AssetSettingsView.REQUIRED]: (
        <RequiredSchemaView
          requiredOption={requiredOption}
          setRequiredOption={setRequiredOption}
          allowedMultiAssetSchemaIds={allowedMultiAssetSchemaIds}
          requiredMultiAssetSchemaIds={requiredMultiAssetSchemaIds}
          setRequiredMultiAssetSchemaIds={setRequiredMultiAssetSchemaIds}
          requireAtLeastOneMultiAsset={requireAtLeastOneMultiAsset}
          setRequireAtLeastOneMultiAsset={setRequireAtLeastOneMultiAsset}
        />
      ),
    };

    return views[activeView];
  };

  return (
    <Modal
      title="Asset Settings"
      footer={getFooter()}
      open={open}
      onCancel={onClose}
      contentStyle={styles.modal}
    >
      {getContent()}
    </Modal>
  );
};

export default SchemaSettingsModal;
