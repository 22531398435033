import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import FontIconButton from '@atom/components/common/FontIconButton';
import { Icon, Switch } from '@atom/mui';
import {
  addFieldOption,
  getBuilderInputValidation,
  getTypeIconData,
  MAX_CHAR_SHORT_INPUT,
  removeFieldOption,
  updateFieldOption,
} from '@atom/selectors/formSelectors';
import colors from '@atom/styles/colors';
import fontStyles from '@atom/styles/fonts';
import { EventType } from '@atom/types/event';
import { AttributeGroupItem, FormFieldType } from '@atom/types/form';
import { AttributesType } from '@atom/types/inventory';
import { SchemaType } from '@atom/types/schema';

import AssetBlock from './assetField/AssetBlock';
import FieldInputs from './regularInputs/FieldInputs';

import './formBuilder.css';

const styles = {
  icon: {
    padding: '0px',
    paddingLeft: '1rem',
    height: '40px',
    width: '40px',
  },
  toggleLabelStyles: {
    fontSize: fontStyles.md,
    color: colors.neutral.gray,
    whiteSpace: 'nowrap',
    paddingLeft: '1.5rem',
  },
  fillerStyles: {
    position: 'relative',
  },
};

interface PassedProps {
  field: FormFieldType;
  rootSchema: SchemaType;
  id: string;
  index: number;
  deleteField: (id: string) => void;
  duplicateField: (id: string) => void;
  updateFormTemplate: (id: string, data: Object) => void;
  updateAttributeGroup: (
    fieldId: string,
    schemaId: string,
    addGroup: boolean,
    attributeGroup: AttributeGroupItem,
  ) => void;
  updateAttribute: (
    fieldId: string,
    schemaId: string,
    addAttribute: boolean,
    attributeGroupName: string,
    attribute: AttributesType,
  ) => void;
  addAllAttributes: (
    fieldId: string,
    schemaId: string,
    attributeGroupName: string,
    attributes: AttributesType[],
  ) => void;
  removeAssetSchema: (fieldId: string, schemaId: string) => void;
}

type Props = PassedProps;

type State = {
  required: boolean;
  text: string;
  title: string;
  description: string;
  unit: string;
  fileUpload: boolean;
  enumValue: string[];
  url?: string;
  width?: number;
};

const FIELD_TYPES_WITHOUT_UPLOAD = new Set<string>([
  'title',
  'description',
  'asset',
  'iframe',
]);

class FieldContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      title: props.field.title,
      description: props.field.description,
      required: props.field.required,
      text: props.field.text,
      unit: props.field.unit,
      enumValue: props.field.enum,
      fileUpload: props.field.fileUpload,
      url: props.field.url,
      width: props.field.width,
    };
  }

  onToggle = (event: any, isInputChecked: boolean) => {
    const { updateFormTemplate, id } = this.props;

    // @ts-ignore
    this.setState({ [event.target.name]: isInputChecked }, () => {
      updateFormTemplate(id, this.state);
    });
  };

  onDelete = () => {
    const { id, deleteField } = this.props;
    deleteField(id);
  };

  onDuplicate = () => {
    const { id, duplicateField } = this.props;
    duplicateField(id);
  };

  onChange = (event: EventType) => {
    const { updateFormTemplate, id, field } = this.props;
    const isValidated = getBuilderInputValidation(
      field.type,
      event.target.name,
      event.target.value,
    );

    if (isValidated) {
      // @ts-ignore
      this.setState({ [event.target.name]: event.target.value }, () => {
        updateFormTemplate(id, this.state);
      });
    }
  };

  addOption = () => {
    const { enumValue } = this.state;
    const { updateFormTemplate, id } = this.props;

    const newEnumValue = addFieldOption(enumValue);

    this.setState({ enumValue: newEnumValue }, () => {
      updateFormTemplate(id, this.state);
    });
  };

  removeOption = (index: number) => {
    const { enumValue } = this.state;
    const { updateFormTemplate, id } = this.props;

    const newEnumValue = removeFieldOption(index, enumValue);

    this.setState({ enumValue: newEnumValue }, () => {
      updateFormTemplate(id, this.state);
    });
  };

  updateOption = (event: EventType) => {
    const { enumValue } = this.state;
    const { updateFormTemplate, id } = this.props;

    const newEnumValue = updateFieldOption(
      event.target.id,
      event.target.value,
      enumValue,
    );

    if (event.target.value.length <= MAX_CHAR_SHORT_INPUT) {
      this.setState({ enumValue: newEnumValue }, () => {
        updateFormTemplate(id, this.state);
      });
    }
  };

  getFieldStyle = (isDragging: boolean, draggableStyle: Object): Object => ({
    borderColor: isDragging ? colors.neutral.ash : colors.neutral.fog,
    ...draggableStyle,
  });

  getHeaderStyle = (isDragging: boolean): Object => ({
    backgroundColor: isDragging ? colors.neutral.ash : colors.neutral.fog,
  });

  render() {
    const {
      field,
      id,
      index,
      rootSchema,
      updateAttributeGroup,
      updateAttribute,
      addAllAttributes,
      removeAssetSchema,
    } = this.props;

    const {
      text,
      title,
      description,
      unit,
      enumValue,
      fileUpload,
      url,
      width,
    } = this.state;

    const leftIconData = getTypeIconData(field.type);
    const leftIconColor =
      field.type === 'asset' ? colors.brand.purple : colors.neutral.gray;

    const toggleControl = !FIELD_TYPES_WITHOUT_UPLOAD.has(field.type) ? (
      <div styleName="toggle-container">
        {/* TODO: [ATOM-4829] Enable required toggle once business rules are sorted out */}
        {/* <Toggle
            labelStyle={styles.toggleLabelStyles}
            label="Required"
            name="required"
            toggled={required}
            onToggle={this.onToggle}
          /> */}
        <Switch
          style={styles.toggleLabelStyles}
          label="File Upload"
          name="fileUpload"
          checked={fileUpload}
          onChange={this.onToggle}
        />
      </div>
    ) : (
      <div style={styles.fillerStyles} />
    );

    return (
      <Draggable key={id} draggableId={id} index={index}>
        {(provided: any, snapshot: any) => (
          <div
            styleName="field-container"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={this.getFieldStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
            )}
          >
            <div
              styleName="field-box-header"
              style={this.getHeaderStyle(snapshot.isDragging)}
            >
              <div styleName="field-left-icons">
                <Icon color={colors.neutral.ash}>drag_indicator</Icon>
                <div styleName="type-title-container">
                  <Icon color={leftIconColor}>{leftIconData.icon}</Icon>
                  <div
                    styleName="type-title-text"
                    style={{ color: leftIconColor }}
                  >
                    {leftIconData.text}
                  </div>
                </div>
              </div>
              <div styleName="field-right-icons">
                {toggleControl}
                {field.type !== 'asset' && (
                  <FontIconButton
                    style={styles.icon}
                    tooltip="Duplicate"
                    onClick={this.onDuplicate}
                    color={colors.neutral.gray}
                    icon="content_copy"
                    tooltipPosition="bottom-left"
                  />
                )}
                <FontIconButton
                  style={styles.icon}
                  tooltip="Delete"
                  onClick={this.onDelete}
                  color={colors.neutral.gray}
                  icon="delete"
                  tooltipPosition="bottom-left"
                />
              </div>
            </div>
            <div styleName="field-content-container">
              {field.type === 'asset' ? (
                <AssetBlock
                  fieldId={id}
                  assetSchemas={field.assetSchemas}
                  rootSchema={rootSchema}
                  updateAttributeGroup={updateAttributeGroup}
                  updateAttribute={updateAttribute}
                  addAllAttributes={addAllAttributes}
                  removeAssetSchema={removeAssetSchema}
                />
              ) : (
                <FieldInputs
                  field={field}
                  text={text}
                  title={title}
                  description={description}
                  unit={unit}
                  enumValue={enumValue}
                  url={url}
                  width={width}
                  onChange={this.onChange}
                  updateOption={this.updateOption}
                  removeOption={this.removeOption}
                  addOption={this.addOption}
                />
              )}
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

export default FieldContainer;
