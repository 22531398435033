import React, { useContext } from 'react';

import FavoritesIconButton from '@atom/components/common/favorites/FavoritesIconButton';
import { useAssetsSearch } from '@atom/hooks/useAssetsSearch';
import { Checkbox, List, Progress } from '@atom/mui';
import { SearchAsset } from '@atom/types/inventory';
import { getCategoryPathNames } from '@atom/utilities/categoryUtilities';

import LoadAssetsButton from './LoadAssetsButton';
import TreeContext from './TreeContext';
import TreeRowIcon from './TreeRowIcon';

import './inventoryAssetTree.css';

const { ListItemButton, ListItemText } = List;

interface Props {
  /**
   * denotes whether location based assets and categories should be
   * included in the tree and search. Default is true.
   */
  includeLocationBasedAssets?: boolean;
  /**
   * Denotes whether material assets should be included in search.
   * Default is true.
   */
  includeMaterialAssets?: boolean;
  /**
   * Denotes whether to include inactive assets or not.
   * Default is true.
   */
  includeInactive?: boolean;
  /**
   * Optional callback to call when asset selection is toggled. Will
   * only be called when asset multi selection is enabled by
   * providing selectedAssetIds.
   */
  onAssetToggle?: (asset: any) => void;
  /**
   * if defined only assets with the given parent category id will be rendered.
   */
  parentCategoryId?: string;
  /**
   * If defined only assets with the given schema ids will be rendered.
   */
  schemaIds?: string[];
  query: string;
}

const SEARCH_ASSETS_LIMIT = 12;

const InventoryAssetSearch = ({
  includeLocationBasedAssets = true,
  includeMaterialAssets = true,
  includeInactive = true,
  onAssetToggle,
  parentCategoryId,
  query,
  schemaIds,
}: Props) => {
  const {
    allAssetsSelected,
    createFavorite,
    disabledAssetIds,
    favoritesEnabled,
    favoriteMaterials,
    handleAssetClick,
    rootTree: tree,
    schemaId,
    selectedAssetIds,
    favoritesLists,
    handleAddList,
    updateFavorite,
    canAddFavorite,
    canAddList,
  } = useContext(TreeContext);

  const [
    { assets },
    { loadingSearch, loadingPagination, error, hasMore, fetchMore },
  ] = useAssetsSearch({
    query,
    parentCategoryId,
    schemaId,
    schemaIds,
    includeMaterialAssets,
    includeInactive,
    includeLocationBasedAssets,
    limit: SEARCH_ASSETS_LIMIT,
    characterMin: 3,
  });

  const handleAssetToggle = (
    event: React.MouseEvent<any>,
    asset: SearchAsset,
  ) => {
    event.stopPropagation();

    if (onAssetToggle) {
      onAssetToggle(asset);
    }
  };

  return (
    <>
      {loadingSearch || !tree ? (
        <div styleName="progress-container">
          <Progress />
        </div>
      ) : (
        <List>
          {assets.map(asset => (
            <ListItemButton
              key={asset.id}
              onClick={() => handleAssetClick(asset)}
              disabled={schemaId && asset.schemaId !== schemaId}
            >
              {selectedAssetIds && (
                <Checkbox
                  checked={selectedAssetIds.has(asset.id) || allAssetsSelected}
                  onClick={event => handleAssetToggle(event, asset)}
                  disabled={
                    disabledAssetIds?.has(asset.id) || allAssetsSelected
                  }
                />
              )}
              <TreeRowIcon tree={asset} />
              <ListItemText
                primary={asset.name}
                secondary={`${asset.assetType} \u2022 ${getCategoryPathNames(
                  tree,
                  asset.categoryPath,
                ).join(' / ')}`}
              />
              {favoritesEnabled && (
                <FavoritesIconButton
                  item={asset}
                  handleAddList={handleAddList}
                  updateFavorite={updateFavorite}
                  createFavorite={createFavorite}
                  favorites={favoriteMaterials}
                  favoritesLists={favoritesLists}
                  typeName="items"
                  canAddFavorite={canAddFavorite}
                  canAddList={canAddList}
                />
              )}
            </ListItemButton>
          ))}
        </List>
      )}
      {hasMore && (
        <div styleName="load-button-container">
          <LoadAssetsButton
            loading={loadingPagination}
            error={error}
            onClick={fetchMore}
          />
        </div>
      )}
    </>
  );
};

export default InventoryAssetSearch;
