import React, { useContext, useEffect, useRef, useState } from 'react';
import * as R from 'ramda';

import WorkTemplatesContext from '@atom/components/workTemplates/WorkTemplatesContext';
import { Icon, IconButton, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import { WorkTemplateFolderTree } from '@atom/types/workTemplate';

import FolderOptions from './FolderOptions';

import './workTemplatesFolderTree.css';

const PADDING_OFFSET = 2;

const styles = {
  iconStyle: {
    fontSize: '24px',
    margin: 0,
  },
  blankStyle: {
    boxSizing: 'border-box',
    padding: '24px',
  },
};

export interface Props {
  folder: WorkTemplateFolderTree;
  indentation: number;
}

const WorkTemplatesFolderRow = ({ folder, indentation }: Props) => {
  const {
    open,
    toggleOpen,
    setActiveFolderId,
    activeFolderId,
    setIsActiveFolderLeafNode,
  } = useContext(WorkTemplatesContext);
  const expanded = open.has(folder.id);
  const [overflowing, setOverflowing] = useState<boolean>(false);
  const container = useRef<HTMLDivElement>();

  useEffect(() => {
    const { offsetWidth, scrollWidth } = container.current;
    setOverflowing(offsetWidth < scrollWidth);
  }, [folder.name]);

  useEffect(() => {
    if (activeFolderId === folder.id) {
      setIsActiveFolderLeafNode(R.isEmpty(folder.children));
    }
  }, [setIsActiveFolderLeafNode, folder.children, activeFolderId, folder.id]);

  const arrowDirection = expanded ? 'arrow_drop_down' : 'arrow_right';

  const leftIcon = (
    <>
      {!R.isEmpty(folder.children) ? (
        <IconButton
          onClick={() => toggleOpen(folder.id)}
          style={styles.iconStyle}
        >
          <Icon color={colors.neutral.dim}>{arrowDirection}</Icon>
        </IconButton>
      ) : (
        <div style={styles.blankStyle} />
      )}
      <Icon style={styles.iconStyle} color={colors.neutral.gray}>
        folder
      </Icon>
    </>
  );

  const rowStyle =
    folder.id === activeFolderId ? 'folder-row selected' : 'folder-row';

  const containerStyle = {
    paddingLeft: `calc(1rem + ${PADDING_OFFSET * indentation}rem)`,
  };

  const onClick = () => {
    setActiveFolderId(folder.id);
    setIsActiveFolderLeafNode(R.isEmpty(folder.children));
  };

  const overflowStyle = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: `calc(12rem - (1rem + ${PADDING_OFFSET * indentation}rem))`,
  };

  return (
    <>
      <div styleName={rowStyle} style={containerStyle} onClick={onClick}>
        <div ref={container} styleName="folder-name-container">
          {leftIcon}
          <div styleName="row-name-container">
            <div>
              {!overflowing ? (
                folder.name
              ) : (
                <Tooltip
                  lightTooltip
                  title={folder.name}
                  placement="right-start"
                >
                  <p style={overflowStyle}>{folder.name}</p>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <FolderOptions folder={folder} />
      </div>
      {expanded ? (
        folder.children.map((child: WorkTemplateFolderTree) => {
          return (
            <WorkTemplatesFolderRow
              key={child.id}
              folder={child}
              indentation={indentation + 1}
            />
          );
        })
      ) : (
        <div />
      )}
    </>
  );
};

export default WorkTemplatesFolderRow;
