import React, { useContext, useState } from 'react';

import UserGroupContext, {
  View,
} from '@atom/components/userGroupDetail/UserGroupContext';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import history from '@atom/utilities/history';

import DeleteUserGroupModal from './DeleteUserGroupModal';
import EditUserGroupModal from './EditUserGroupModal';
import NavigationButton from './NavigationButton';

import './userGroupHeader.css';

const UserGroupHeader = () => {
  const { userGroup } = useContext(UserGroupContext);

  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const views = [
    {
      value: View.INFO,
      label: 'info',
      icon: 'info',
    },
    {
      value: View.ROLES,
      label: 'roles',
      icon: 'account_circle',
    },
    {
      value: View.BUDGETS,
      label: 'budget',
      icon: 'monetization_on',
    },
    {
      value: View.CERTIFICATIONS,
      label: 'certifications',
      icon: 'card_membership',
    },
  ];

  return (
    <>
      <div styleName="header-container">
        <div styleName="top-bar">
          <div styleName="title-container">
            <IconButton onClick={() => history.goBackWithState()}>
              <Icon color={colors.neutral.white}>arrow_back</Icon>
            </IconButton>
            <Icon color={colors.neutral.white}>group</Icon>
            <div styleName="title">{userGroup.name}</div>
          </div>
          <div>
            {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
              <IconButton onClick={() => setOpenEdit(true)}>
                <Icon color={colors.neutral.white}>edit</Icon>
              </IconButton>
            )}
            {!userGroup.hasChildGroups &&
              hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
                <IconButton onClick={() => setOpenDelete(true)}>
                  <Icon color={colors.neutral.white}>delete</Icon>
                </IconButton>
              )}
          </div>
        </div>
        <div styleName="navigation-bar">
          {views.map(view => (
            <NavigationButton key={view.value} view={view} />
          ))}
        </div>
      </div>
      <EditUserGroupModal open={openEdit} onClose={() => setOpenEdit(false)} />
      <DeleteUserGroupModal
        open={openDelete}
        onClose={() => setOpenDelete(false)}
      />
    </>
  );
};

export default UserGroupHeader;
