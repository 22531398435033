import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Select, Switch, TextField } from '@atom/mui';
import { budgetUnitsSelector } from '@atom/selectors/preferencesSelectors';
import {
  SchemaBudgetType,
  StartEndReadingOption,
  StockBasedOption,
} from '@atom/types/schema';

import CreateAssetModalContext from './CreateAssetModalContext';

import './createAssetModal.css';

const { MenuItem } = Select;

const styles = {
  icon: {
    marginRight: '1.5rem',
  },
  unitOption: {
    textTransform: 'uppercase',
  },
};

const CostSettingsTab = () => {
  const {
    schema,
    rate,
    setRate,
    unit,
    setUnit,
    isStockBased,
    setIsStockBased,
    isStartEndReading,
    setIsStartEndReading,
    isStartEndCalculated,
    setIsStartEndCalculated,
    quantityOnHand,
    setQuantityOnHand,
  } = useContext(CreateAssetModalContext);

  const budgetUnits = useSelector(budgetUnitsSelector);

  useEffect(() => {
    if (schema?.budget) {
      setRate(schema?.budget?.rate);
      setUnit(schema?.budget?.unit);
      setIsStockBased(
        schema?.budget?.stockBasedOption === StockBasedOption.STOCK_BASED,
      );
      setIsStartEndReading(
        schema?.budget?.startEndReadingOption ===
          StartEndReadingOption.START_END_READING,
      );
    }
  }, [schema?.budget]);

  const updateRate = event => {
    const newTimeRate = event.target.value
      ? Math.abs(Number(event.target.value))
      : null;

    setRate(newTimeRate);
  };

  const updateQuantityOnHand = event => {
    const newQuantityOnHand = event.target.value
      ? Math.abs(Number(event.target.value))
      : null;

    setQuantityOnHand(newQuantityOnHand);
  };

  const handleStockBasedToggle = () => {
    setIsStockBased(!isStockBased);
    setQuantityOnHand(isStockBased ? null : quantityOnHand);
  };

  const handleStartEndReadingToggle = () => {
    setIsStartEndReading(!isStartEndReading);
  };

  const handleStartEndCalculatedToggle = () => {
    setIsStartEndCalculated(!isStartEndCalculated);
  };

  const isRateDisabled = schema?.budget?.type === SchemaBudgetType.FIXED;
  const isUnitDisabled =
    schema?.budget?.type === SchemaBudgetType.FIXED ||
    schema?.budget?.type === SchemaBudgetType.VARIABLE_COST;
  const isStockBasedToggleDisabled =
    schema?.budget?.stockBasedOption === StockBasedOption.STOCK_BASED;
  const isStartEndReadingToggleDisabled =
    schema?.budget?.startEndReadingOption ===
    StartEndReadingOption.START_END_READING;

  const showStockBased =
    schema?.budget?.stockBasedOption !== StockBasedOption.NONE;

  const showStartEndReading =
    !isStockBased &&
    schema?.budget?.startEndReadingOption !== StartEndReadingOption.NONE;

  const options = ['', ...budgetUnits];

  return (
    <div styleName="tab-container">
      <div styleName="tab-title">Cost Settings</div>
      <div styleName="inventory-row">
        <div styleName="cost-input-container">
          <TextField
            label="Cost"
            value={rate}
            type="number"
            fullWidth
            onChange={updateRate}
            InputProps={{
              startAdornment: '$',
            }}
            disabled={isRateDisabled}
            data-cy="globalCreateAssetModalRateInput"
          />
          <div styleName="separator">per</div>
          <Select
            label="Unit"
            fullWidth
            InputProps={{
              style: styles.unitOption,
            }}
            onChange={event => setUnit(event.target.value)}
            value={unit}
            disabled={isUnitDisabled}
            data-cy="globalCreateAssetModalUnitInput"
          >
            {options.map(option => {
              return (
                <MenuItem
                  key={option}
                  value={option || null}
                  style={styles.unitOption}
                >
                  {option}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>
      {showStockBased && (
        <>
          <div styleName="inventory-row">
            <Switch
              checked={isStockBased}
              onChange={handleStockBasedToggle}
              label="Stock-based Inventory"
              disabled={isStockBasedToggleDisabled}
              data-cy="globalCreateAssetModalStockBasedInput"
            />
          </div>
          {isStockBased && (
            <div styleName="inventory-row">
              <TextField
                label="Total Available"
                value={quantityOnHand}
                type="number"
                fullWidth
                onChange={updateQuantityOnHand}
                data-cy="globalCreateAssetModalQuantityOnHandInput"
              />
              <div styleName="quantity-suffix">{unit}</div>
            </div>
          )}
        </>
      )}
      {showStartEndReading && (
        <>
          <div styleName="inventory-row">
            <Switch
              checked={isStartEndReading}
              onChange={handleStartEndReadingToggle}
              label="Enable Start and End Readings"
              disabled={isStartEndReadingToggleDisabled}
              data-cy="globalCreateAssetModalStartReadingsEnableInput"
            />
          </div>
          {isStartEndReading && (
            <div styleName="inventory-row">
              <Switch
                checked={isStartEndCalculated}
                onChange={handleStartEndCalculatedToggle}
                label="Used Calculated from Start and End Readings"
                data-cy="globalCreateAssetModalStartReadingsCalcInput"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CostSettingsTab;
