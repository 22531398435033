import * as R from 'ramda';

import { Task, TaskUser } from '@atom/types/task';
import { getFieldPathById } from '@atom/utilities/workOrderFieldUtilities';

export const getTaskUser = (userId: string, task: Task): TaskUser => {
  const users = R.pathOr([], ['users'], task);
  return R.find(R.propEq('id', userId))(users);
};

const taskUserToUserDetail = users =>
  users.map(
    ({
      id,
      email,
      employeeId,
      firstName,
      lastName,
      photoUrl,
      status,
      title,
    }) => ({
      id,
      email,
      employeeId,
      firstName,
      lastName,
      photoUrl,
      status,
      title,
    }),
  );

const taskUserGroupToTemplateUserGroup = userGroups =>
  userGroups.map(
    ({ userGroupId, name, quantity, workTime, colorId, budget }) => ({
      userGroupId,
      name,
      quantity,
      workTime,
      colorId,
      budget,
    }),
  );

export const templateTaskToTask = tasks =>
  tasks.map(
    ({
      description,
      dueDate,
      duration,
      estimatedCost,
      fieldOrder,
      fields,
      id,
      isEstimatedCostOverridden,
      name,
      users,
      userGroups,
    }) => ({
      description,
      dueDate,
      duration,
      estimatedCost,
      fieldOrder,
      fields: [...fields],
      id,
      isEstimatedCostOverridden,
      name,
      users: taskUserToUserDetail(users),
      userGroups: taskUserGroupToTemplateUserGroup(userGroups),
    }),
  );

// Updates a value in a field on a task, taking care of nested fields.
export const updateFieldInTask = (task: Task, fieldId: string, value: any) => {
  // Get path to field (checking for nested fields).
  const nestedFieldPath = getFieldPathById(fieldId, task.fields);

  // Ramda.over() the path to set the new field's value,
  // returning the updated task.
  return R.over(R.lensPath(nestedFieldPath), nestedField => ({
    ...nestedField,
    value,
  }))(task);
};
