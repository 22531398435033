import React, { useCallback, useEffect, useRef } from 'react';
import { useQueryParam } from 'use-query-params';

import { useKmlLayers } from '@atom/components/mapPortal/hooks/kmlHook';
import { KmlLayer } from '@atom/types/map';

interface Props {
  map: google.maps.Map;
  layer: KmlLayer;
}

const KmlGeoXml3Layer = ({ map, layer }: Props) => {
  const { onLayerClick } = useKmlLayers();

  const [, setView] = useQueryParam('view');
  const geoXml3LayerRef = useRef<any>(null);

  const addOnClickHandler = useCallback((placemark: any) => {
    google.maps.event.addListener(placemark.marker, 'click', () => {
      onLayerClick(layer, placemark.vars.val);
      setView('kml');
    });
  }, []);

  const afterParse = useCallback(
    (docs: any[], newGeoXml3Layer: any) => {
      if (layer.suppressInfoWindows) {
        docs?.[0]?.placemarks?.forEach(placemark => {
          // marker creation is asynchornous and there's no real way to await it here
          // instead, retry every 200 milliseconds until the marker is defined
          const interval = setInterval(() => {
            if (placemark.marker) {
              addOnClickHandler(placemark);
              clearInterval(interval);
            }
          }, 200);
        });
      }

      if (geoXml3LayerRef.current) {
        geoXml3LayerRef.current.hideDocument();
      }

      geoXml3LayerRef.current = newGeoXml3Layer;
    },
    [geoXml3LayerRef.current],
  );

  useEffect(() => {
    // @ts-ignore
    // eslint-disable-next-line new-cap
    const geoXml = new window.geoXML3.parser({
      map,
      processStyles: true,
      zoom: false,
      suppressInfoWindows: layer.suppressInfoWindows,
      afterParse: docs => afterParse(docs, geoXml),
    });

    geoXml.parse(layer.url);
  }, [layer.url]);

  useEffect(() => {
    // teardown function for removing the layer from the map
    return () => {
      // NOTE ref value should be referenced as geoXml3LayerRef.current
      // Destructuring or assigning to a new variable can cause side effects
      if (geoXml3LayerRef.current) {
        geoXml3LayerRef.current.hideDocument();
      }
    };
  }, []);

  return null;
};

export default React.memo(
  KmlGeoXml3Layer,
  // memoize to only re-render on URL changes
  // polling is setup to modify the URL at the given polling interval
  // which will cause a re-render and a refetch
  (prevProps, nextProps) => prevProps.layer.url === nextProps.layer.url,
);
