import React, { useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
import UserGroupIcon from '@atom/components/common/UserGroupIcon';
import UserThumbnail from '@atom/components/common/UserThumbnail';
import { USER_UPDATE } from '@atom/graph/user';
import { UPDATE_USER_GROUP } from '@atom/graph/userGroup';
import { Icon, IconButton, ListTable, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import { RoleAssignee } from '@atom/types/role';
import { UserDetail, UserUpdateInput } from '@atom/types/user';
import { UserGroup, UserGroupUpdateInput } from '@atom/types/userGroups';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import { getUserFullName } from '@atom/utilities/userUtilities';

import RoleContext from '../RoleContext';

import AddAssigneesModal from './AddAssigneesModal';

import './roleAssignees.css';

const {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} = ListTable;

type SortDirection = 'asc' | 'desc';
type SortField = 'name' | 'assignedDate';

const getGroupLanguage = (count: number) => (count === 1 ? 'group' : 'groups');
const getUserLanguage = (count: number) => (count === 1 ? 'user' : 'users');

const styles = {
  icon: {
    marginRight: '1rem',
  },
  emptyIcon: {
    fontSize: '5.25rem',
    marginBottom: '1rem',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },
};

const RoleAssignees = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<RoleAssignee>(null);

  const {
    role,
    loading,
    assigneesInput: input,
    setAssigneesInput: setInput,
    refetch,
  } = useContext(RoleContext);

  const {
    totalCount,
    totalUsersCount,
    totalUserGroupsCount,
    assignees,
  } = role.assignees;

  const [updateUserGroup, { loading: loadingUserGroupUpdate }] = useMutation<
    { userGroupUpdate: UserGroup },
    { input: UserGroupUpdateInput }
  >(UPDATE_USER_GROUP);

  const [updateUser, { loading: loadingUserUpdate }] = useMutation<
    { userUpdate: UserDetail },
    { input: UserUpdateInput }
  >(USER_UPDATE);

  const getSortDirection = useCallback(
    (field: SortField) => {
      if (input.sortBy !== field) {
        return false;
      }

      return input.sortDirection;
    },
    [input.sortBy, input.sortDirection],
  );

  const handleSortDirectionChange = (sortBy: SortField) => (
    sortDirection: SortDirection,
  ) => {
    setInput({ ...input, sortBy, sortDirection });
  };

  const handleDelete = async () => {
    const roles = selected.roles
      .filter(({ id }) => id !== role.id)
      .map(({ id }) => id);

    const variables = { variables: { input: { id: selected.id, roles } } };

    if (selected.type === 'USER') {
      await updateUser(variables);
    } else {
      await updateUserGroup(variables);
    }

    setSelected(null);
    refetch();
  };

  const hasUsersOrGroups = totalCount > 0;

  return (
    <>
      <div styleName="header-container">
        <div>
          {!hasUsersOrGroups
            ? '0 groups and 0 users'
            : `Assigned to ${totalUserGroupsCount} ${getGroupLanguage(
                totalUserGroupsCount,
              )} and ${totalUsersCount} ${getUserLanguage(totalUsersCount)}`}
        </div>
        {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && !role.isDefault && (
          <IconButton onClick={() => setOpen(true)}>
            <Icon>add</Icon>
          </IconButton>
        )}
      </div>
      <div styleName="content">
        {loading ? (
          <Progress style={{ flex: 1 }} />
        ) : (
          <ListTable offsetTop="310px" fullHeight={hasUsersOrGroups}>
            <TableHead>
              <TableRow header>
                <TableCell
                  variant="head"
                  sortDirection={getSortDirection('name')}
                  onSortChange={handleSortDirectionChange('name')}
                >
                  Name
                </TableCell>
                <TableCell variant="head">Assigned By</TableCell>
                <TableCell
                  variant="head"
                  sortDirection={getSortDirection('assignedDate')}
                  onSortChange={handleSortDirectionChange('assignedDate')}
                >
                  Assigned On
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {assignees.map(assignee => (
                <TableRow key={assignee.id}>
                  <TableCell>
                    <Link
                      style={styles.link}
                      to={
                        assignee.type === 'USER_GROUP'
                          ? `/team/group/${assignee.id}`
                          : `/team/${assignee.id}`
                      }
                    >
                      {assignee.type === 'USER_GROUP' ? (
                        <div style={styles.icon}>
                          <UserGroupIcon colorId={assignee.colorId} />
                        </div>
                      ) : (
                        <div style={styles.icon}>
                          <UserThumbnail image={assignee.photoUrl} />
                        </div>
                      )}
                      {assignee.name}
                    </Link>
                  </TableCell>
                  {/* @ts-ignore */}
                  <TableCell>{getUserFullName(assignee.assignedBy)}</TableCell>
                  <TableCell>{setDisplayDate(assignee.assignedDate)}</TableCell>
                  <TableCell width="48px">
                    {hasRolePermissions(ROLE_SETS.ORG_ADMIN) &&
                    !role.isDefault ? (
                      <IconButton onClick={() => setSelected(assignee)}>
                        <Icon>delete</Icon>
                      </IconButton>
                    ) : (
                      <div style={{ height: '48px' }} />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            {hasUsersOrGroups && (
              <TableFooter width="70%">
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[15, 25, 50]}
                    count={totalCount}
                    rowsPerPage={input.limit}
                    page={input.page}
                    onPageChange={page => setInput({ ...input, page })}
                    onRowsPerPageChange={event =>
                      setInput({
                        ...input,
                        limit: +event.target.value,
                        page: 1,
                      })
                    }
                  />
                </TableRow>
              </TableFooter>
            )}
          </ListTable>
        )}
        {!hasUsersOrGroups && !loading && (
          <div styleName="empty-container">
            <Icon style={styles.emptyIcon} color={colors.neutral.silver}>
              group
            </Icon>
            <div>There are no groups or users assigned to this role.</div>
          </div>
        )}
      </div>
      <AddAssigneesModal open={open} onClose={() => setOpen(false)} />
      <DeleteModal
        title={selected?.type === 'USER' ? 'Remove User?' : 'Remove Group?'}
        open={!!selected}
        onCancel={() => setSelected(null)}
        onConfirm={handleDelete}
        loading={loadingUserUpdate || loadingUserGroupUpdate}
        content={
          selected?.type === 'USER'
            ? 'Are you sure you want to remove this user from this role?'
            : 'Are you sure you want to remove this group from this role?'
        }
      />
    </>
  );
};

export default RoleAssignees;
