import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import { GET_BUDGET_TEMPLATES } from '@atom/graph/budget';
import { Select } from '@atom/mui';
import { BudgetTemplatesConnection } from '@atom/types/budget';
import { POLICY_GRANT_IDS_ALL } from '@atom/types/policy';

import PolicyModalContext from '../PolicyModalContext';

const { MenuItem } = Select;

const BudgetingTemplateSelection = () => {
  const { data: templatesData, loading: loadingTemplates } = useQuery<{
    budgetTemplates: BudgetTemplatesConnection;
  }>(GET_BUDGET_TEMPLATES);

  const templates = R.pathOr(
    [],
    ['budgetTemplates', 'budgetTemplates'],
    templatesData,
  );

  const { state, updateState } = useContext(PolicyModalContext);
  const { grants, grantType, loadingExistingPolicies } = state;

  const handleChange = event => {
    const values: string[] = event.target.value;
    const isAllSelected = grants.some(({ id }) => id === POLICY_GRANT_IDS_ALL);

    if (isAllSelected) {
      return updateState({
        grants: values
          .filter(val => val !== POLICY_GRANT_IDS_ALL)
          .map(id => ({ id, name: grantType })),
      });
    }

    return updateState({
      grants: values.includes(POLICY_GRANT_IDS_ALL)
        ? [{ id: POLICY_GRANT_IDS_ALL, name: grantType }]
        : values.map(id => ({ id, name: grantType })),
    });
  };

  return (
    <Select
      multiple
      loading={loadingExistingPolicies || loadingTemplates}
      label="Budget Template(s)"
      value={grants.map(({ id }) => id) || []}
      onChange={handleChange}
    >
      <MenuItem value={POLICY_GRANT_IDS_ALL}>All</MenuItem>
      {templates.map(template => (
        <MenuItem key={template.id} value={template.id}>
          {template.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default BudgetingTemplateSelection;
