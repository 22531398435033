import * as React from 'react';

import { Checkbox, Progress } from '@atom/mui';
import iconUtilities from '@atom/utilities/iconUtilities';

import './categoryFilter.css';

const RIGHT_ARROW = 'right-arrow';
const DOWN_ARROW = 'down-arrow';
const VISIBLE = 'inherit';
const HIDDEN = 'none';

interface Category {
  id: string;
  name: string;
  subCategories: Category[];
}

interface PassedProps {
  category: Category;
  isChecked?: boolean;
  onClick: (id: string) => any;
  selectedItems: object;
  categoryPath: any[];
  parentId?: string;
  isParentDisabled?: boolean;
}

interface ReduxStateProps {
  loading: boolean;
}

type ReduxDispatchProps = {
  inventoryCategoryActions: object;
};

type Props = PassedProps & ReduxStateProps & ReduxDispatchProps;

class CategoryListRow extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.state = {
      icon: iconUtilities.leftIcon(RIGHT_ARROW),
      expanded: HIDDEN,
    };
  }

  retrieveTreeNode = () => {
    const { category, categoryPath, inventoryCategoryActions } = this.props;
    // @ts-ignore
    if (!category.subCategories) {
      const data = {
        // @ts-ignore
        id: category.id,
        categoryPath,
      };

      // @ts-ignore
      return inventoryCategoryActions.retrieveInventoryFilterCategoryTreeNode(
        data,
      );
    }
    return false;
  };

  toggleSection = () => {
    // @ts-ignore
    const { expanded } = this.state;

    if (expanded === HIDDEN) {
      this.setState({
        icon: iconUtilities.leftIcon(DOWN_ARROW),
        expanded: VISIBLE,
      });
      this.retrieveTreeNode();
    } else {
      this.setState({
        icon: iconUtilities.leftIcon(RIGHT_ARROW),
        expanded: HIDDEN,
      });
    }
  };

  render() {
    const {
      category,
      onClick,
      isChecked,
      loading,
      inventoryCategoryActions,
      categoryPath,
      selectedItems,
      parentId,
      isParentDisabled,
    } = this.props;
    // @ts-ignore
    const { icon, expanded } = this.state;
    const isDisabled =
      // @ts-ignore
      (parentId && selectedItems.has(parentId)) || isParentDisabled;
    return (
      <div>
        <div styleName="category-list-row">
          {/* 
            // @ts-ignore */}
          {category.hasCategories && (
            <span styleName="category-toggle-icon" onClick={this.toggleSection}>
              {loading ? <Progress thickness={2} size={16} /> : icon}
            </span>
          )}
          <div>
            <Checkbox
              checked={isChecked || isDisabled}
              onChange={() => onClick(category.id)}
              disabled={isDisabled}
            />
          </div>
          <div
            styleName={`category-label${isDisabled ? ' disabled' : ''}`}
            // @ts-ignore
            onClick={!isDisabled ? () => onClick(category.id) : () => {}}
          >
            {category.name}
          </div>
        </div>
        <div
          styleName="sub-category-list-container"
          style={{ display: expanded }}
        >
          {category.subCategories &&
            category.subCategories.map((subCategory, subCategoryIndex) => {
              return (
                <CategoryListRow
                  key={subCategory.id}
                  loading={loading}
                  // @ts-ignore
                  isChecked={selectedItems.has(subCategory.id) || isDisabled}
                  isParentDisabled={isDisabled}
                  selectedItems={selectedItems}
                  category={subCategory}
                  onClick={onClick}
                  inventoryCategoryActions={inventoryCategoryActions}
                  parentId={category.id}
                  categoryPath={[
                    ...categoryPath,
                    'subCategories',
                    subCategoryIndex,
                  ]}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

export default CategoryListRow;
