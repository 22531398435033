import * as React from 'react';
import { format } from 'date-fns';
import BaseDatePicker from 'material-ui/DatePicker';
import * as R from 'ramda';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  convertDateToLocalDayEnd,
  convertDateToLocalDayStart,
  convertDateToMillisGMTMidday,
  DateType,
} from '@atom/utilities/timeUtilities';

import './datePicker.css';

interface Props {
  name: string;
  onDataChange: (string, any) => any;
  valueMillis?: any;
  fullWidth?: boolean;
  hintText?: string;
  floatingLabelFixed?: boolean;
  floatingLabelText?: string;
  floatingLabelStyle?: object;
  style?: object;
  textFieldStyle?: object;
  underlineStyle?: object;
  errorText?: any;
  disabled?: boolean;
  minDate?: object;
  dateType?: DateType;
}

const styles = {
  iconContainer: {
    padding: 0,
    height: '30px',
    width: '30px',
    marginBottom: '10px',
    marginLeft: '-30px',
  },
  icon: {
    fontSize: 20,
    color: colors.neutral.gray,
  },
};

class DatePicker extends React.Component<Props> {
  static defaultProps = {
    dateType: DateType.GMT_MIDDAY,
  };

  // Note that the default value for a MaterialUI DatePicker is {}
  // NOT an empty string or any other value type
  convertToDateString = dateMillis => {
    if (dateMillis === null) {
      return {};
    }

    if (dateMillis !== null && typeof dateMillis === 'object') {
      return dateMillis;
    }

    return new Date(dateMillis);
  };

  formatDate = date => {
    if (date instanceof Date) {
      return format(date, 'MM/dd/yyyy');
    }
    return {};
  };

  getDateValue = (date: Date): number => {
    const { dateType } = this.props;

    switch (dateType) {
      case DateType.LOCAL_START:
        return convertDateToLocalDayStart(date);
      case DateType.LOCAL_END:
        return convertDateToLocalDayEnd(date);
      default:
        return convertDateToMillisGMTMidday(date);
    }
  };

  onChange = (event, date) => {
    const { onDataChange, name } = this.props;
    const value = !date ? null : this.getDateValue(date);

    onDataChange(name, value);
  };

  render() {
    const {
      name,
      valueMillis,
      fullWidth,
      hintText,
      floatingLabelFixed,
      floatingLabelText,
      floatingLabelStyle,
      underlineStyle,
      textFieldStyle,
      style,
      errorText,
      disabled,
      minDate,
    } = this.props;

    const value = this.convertToDateString(valueMillis);
    const firstDayOfWeek = 0;

    const isDisabled = R.isNil(disabled) ? false : disabled;

    const fullStyle = {
      ...style,
      flex: '1 1 auto',
    };

    return (
      <div styleName="date-picker-container">
        <BaseDatePicker
          onChange={this.onChange}
          name={name}
          value={value}
          firstDayOfWeek={firstDayOfWeek}
          fullWidth={fullWidth}
          // @ts-ignore
          formatDate={this.formatDate}
          hintText={hintText}
          floatingLabelFixed={floatingLabelFixed}
          floatingLabelText={floatingLabelText}
          floatingLabelStyle={floatingLabelStyle}
          underlineStyle={underlineStyle}
          textFieldStyle={textFieldStyle}
          style={fullStyle}
          errorText={errorText}
          disabled={isDisabled}
          minDate={minDate}
        />
        <IconButton
          style={styles.iconContainer}
          // @ts-ignore
          onClick={this.onChange}
          iconStyle={styles.icon}
        >
          <Icon>close</Icon>
        </IconButton>
      </div>
    );
  }
}

export default DatePicker;
