import React from 'react';

import DoubleRow from './DoubleRow';

import './scdotPdfReport.css';

interface Props {
  title: string;
  rows: any[][];
}

const DoubleSection = ({ title, rows }: Props) => {
  return (
    <div styleName="double-section-container">
      <div styleName="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>{title}</th>
              <th />
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <DoubleRow key={index + title} row={row} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DoubleSection;
