const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'symbol',
};

export function numberToLocaleString(number) {
  const value = number || 0;
  return Number(value).toLocaleString('en-US', options);
}
