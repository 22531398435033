import React from 'react';
import {
  CircularProgress as MUICircularProgress,
  CircularProgressProps as MUICircularProgressProps,
} from '@mui/material';

import colors from '@atom/styles/colors';

type CustomProps = {
  style?: React.CSSProperties;
  progressColor?: string;
};

export type ProgressProps = CustomProps & MUICircularProgressProps;

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
};

export const Progress = (props: ProgressProps) => {
  const {
    size = 50,
    thickness = 3,
    progressColor = colors.brand.blue,
    style = {},
    ...data
  } = props;

  return (
    <div style={{ ...styles.container, ...style }}>
      <MUICircularProgress
        size={size}
        thickness={thickness}
        style={{ color: progressColor }}
        {...data}
      />
    </div>
  );
};

export default Progress;
