import React, { useEffect, useMemo } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import { KmlLayer } from '@atom/types/map';

import KmlPlacemark from '../drawerContent/KmlPlacemark';
import { useKmlLayers } from '../hooks/kmlHook';

const parseSelectedPlacemark = (
  layer: KmlLayer,
  placemark: { [key: string]: string },
) => {
  return layer.extendedData.reduce(
    (acc, data) => {
      switch (data.type) {
        case 'TITLE': {
          return { ...acc, title: placemark[data.key] || '' };
        }
        case 'SUBTITLE': {
          return { ...acc, subtitle: placemark[data.key] || '' };
        }
        case 'MARKER_ID': {
          return {
            ...acc,
            markerId: Number.isNaN(placemark[data.key])
              ? null
              : +placemark[data.key],
          };
        }
        case 'ATTRIBUTE': {
          return {
            ...acc,
            attributes: {
              ...acc.attributes,
              [data.name]: placemark[data.key] || '',
            },
          };
        }
        default:
          return acc;
      }
    },
    { attributes: {} },
  );
};

const KmlPlacemarkWrapper = () => {
  const { selectedLayer, selectedPlacemark } = useKmlLayers();

  const placemark = useMemo(() => {
    return selectedPlacemark
      ? parseSelectedPlacemark(selectedLayer, selectedPlacemark)
      : null;
  }, [selectedLayer, selectedPlacemark]);

  const [, setParams] = useQueryParams({
    view: StringParam,
    id: StringParam,
  });

  const onCancel = () => {
    setParams({
      view: 'layers',
      id: '',
    });
  };

  useEffect(() => {
    if (!selectedPlacemark) {
      onCancel();
    }
  }, []);

  return placemark && <KmlPlacemark {...placemark} onCancel={onCancel} />;
};

export default KmlPlacemarkWrapper;
