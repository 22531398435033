import actionTypes from './actionTypes';

export const requestWorkTemplates = data => ({
  type: actionTypes.REQUEST_WORK_TEMPLATES,
  data,
});

export const getWorkTemplatesSuccess = data => ({
  type: actionTypes.GET_WORK_TEMPLATES_SUCCESS,
  data,
});

export const getWorkTemplatesFailure = () => ({
  type: actionTypes.GET_WORK_TEMPLATES_FAILURE,
});

export const getWorkTemplatesTotalSuccess = data => ({
  type: actionTypes.GET_WORK_TEMPLATES_TOTAL_SUCCESS,
  data,
});

export const requestWorkTemplateDeletion = data => ({
  type: actionTypes.REQUEST_WORK_TEMPLATE_DELETION,
  data,
});

export const deleteWorkTemplateSuccess = data => ({
  type: actionTypes.DELETE_WORK_TEMPLATE_SUCCESS,
  data,
});

export const deleteWorkTemplateFailure = () => ({
  type: actionTypes.DELETE_WORK_TEMPLATE_FAILURE,
});

export const requestWorkTemplateCreation = data => ({
  type: actionTypes.REQUEST_WORK_TEMPLATE_CREATION,
  data,
});

export const workTemplateCreationSuccess = () => ({
  type: actionTypes.CREATE_WORK_TEMPLATE_SUCCESS,
});

export const workTemplateCreationFailure = () => ({
  type: actionTypes.CREATE_WORK_TEMPLATE_FAILURE,
});
