import React from 'react';

import { Icon } from '@atom/mui';
import workOrderPriorityUtilities from '@atom/utilities/workOrderPriorityUtilities';

interface Props {
  priorityId: number;
}

const WorkPriorityIcon = ({ priorityId }: Props) => {
  return (
    <Icon>
      {workOrderPriorityUtilities.getWorkPriorityStatusIcon(priorityId)}
    </Icon>
  );
};

export default WorkPriorityIcon;
