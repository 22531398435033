import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppBar, Box, Toolbar } from '@mui/material';
import { bindActionCreators, Dispatch } from 'redux';

import * as organizationActionCreators from '@atom/actions/organizationActions';
import * as userActionCreators from '@atom/actions/userActions';
import Logo from '@atom/components/common/navigation/Logo';
import { Progress } from '@atom/mui';
import { getUserTenantsSelector } from '@atom/selectors/userSelectors';
import colors from '@atom/styles/colors';
import layoutStyles from '@atom/styles/layout';
import { OrganizationActions, UserActions } from '@atom/types/actions';
import { ReduxStore } from '@atom/types/store';
import accountUtilities from '@atom/utilities/accountUtilities';
import history from '@atom/utilities/history';

import LoginFooter from './LoginFooter';
import TenantSelectionRow from './TenantSelectionRow';

import './login.css';

const navBarStyle = {
  height: layoutStyles.topNavHeight,
  backgroundColor: colors.neutral.white,
  boxShadow: '0 2px 4px 1px',
  color: colors.utility.boxShadow,
  position: 'fixed',
  top: 0,
};

interface Organization {
  domain: string;
  id: string;
  isActive: boolean;
  isVerified: boolean;
  name: string;
  role: string;
}

interface ReduxStateProps {
  organizations: Organization[];
  loading: boolean;
}

interface ReduxDispatchProps {
  organizationActions: OrganizationActions;
  userActions: UserActions;
}

type Props = ReduxDispatchProps & ReduxStateProps;

interface State {
  email: string;
}

class TenantSelection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state.email = this.getEmailAccount();
  }

  state = {
    email: '',
  };

  componentDidMount() {
    const { organizationActions } = this.props;
    organizationActions.requestUserOrganizations();
  }

  getEmailAccount = (): string => {
    const email = accountUtilities.getLoggedInEmail();
    if (!email) {
      history.push('/login');
    }

    return email;
  };

  logout = () => {
    const { userActions } = this.props;
    userActions.triggerLogout();
  };

  render() {
    const { organizations, loading } = this.props;
    const { email } = this.state;

    const content = loading ? (
      <div styleName="spinner-container">
        <Progress />
      </div>
    ) : (
      <div styleName="tenants-container">
        {organizations.map((organization: Organization, index: number) => (
          <TenantSelectionRow
            key={`${organization.id}-${index}`}
            organization={organization}
          />
        ))}
        <div styleName="tenant-selection-container">
          <div>Cannot find the team you are looking for?</div>
          <div styleName="tenant-decision-container">
            <Link to="/tenant-entry">
              <div styleName="tenant-selection-link">Join a workspace</div>
            </Link>
            or
            <Link to="/tenant-creation">
              <div styleName="tenant-selection-link">Create a workspace</div>
            </Link>
          </div>
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <AppBar style={navBarStyle}>
          <Toolbar>
            <div>
              <Logo />
            </div>
            <Box sx={{ flexGrow: 1 }} />
            <div styleName="nav-right-container">
              <div styleName="nav-right-text">{email}</div>
              <div styleName="nav-right-login" onClick={this.logout}>
                Sign Out
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <div styleName="container">
          <div styleName="auth-background partial" />
          <div styleName="heading-text">Choose a workspace</div>
          {content}
          <LoginFooter />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  organizations: getUserTenantsSelector(state),
  loading: state.loading.loadingOrganizations,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  organizationActions: bindActionCreators(organizationActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TenantSelection);
