import { AnalyticsFolderTree } from '@atom/types/analytics';

export const styles = {
  folderIcon: { paddingRight: '0.75rem' },
  progress: { marginTop: '2rem' },
  indentation: {
    paddingLeft: '2rem',
  },
  iconStyle: {
    fontSize: '24px',
    margin: 0,
  },
  blankStyle: {
    boxSizing: 'border-box',
    padding: '24px',
  },
  folderRowContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  nameContainer: { paddingLeft: '0.75rem' },
};

export const MIN_SEARCH_CHARS = 3;
export const PADDING_OFFSET = 2;
export const ROOT_TREE_ID = 'analytics';

export const getAllDescendantFolderIds = (
  folder: AnalyticsFolderTree,
): string[] => {
  return folder.children.reduce(
    (acc: string[], child): string[] => [
      ...acc,
      child.id,
      ...getAllDescendantFolderIds(child),
    ],
    [],
  );
};
