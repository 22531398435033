import React, { useRef, useState } from 'react';

// @ts-ignore
import mapStyleDarkIcon from '@atom/components/common/svgIcons/mapStyleDark.svg';
// @ts-ignore
import mapStyleRetroIcon from '@atom/components/common/svgIcons/mapStyleRetro.svg';
// @ts-ignore
import mapStyleSilverIcon from '@atom/components/common/svgIcons/mapStyleSilver.svg';
// @ts-ignore
import mapStyleStandardIcon from '@atom/components/common/svgIcons/mapStyleStandard.svg';
import { Icon, Popover } from '@atom/mui';
import colors from '@atom/styles/colors';
import { MapStyle } from '@atom/types/map';
import textUtilities from '@atom/utilities/textDisplayUtilities';

import './styleControls.css';

export interface Props {
  mapStyle: MapStyle;
  onChange: (mapStyle: MapStyle) => void;
}

const styles = {
  inlineIcon: {
    marginRight: '1rem',
  },
};

const ALL_STYLES: MapStyle[] = ['default', 'silver', 'dark', 'retro'];

const getIcon = (style: MapStyle) => {
  switch (style) {
    case 'default':
      return mapStyleStandardIcon;
    case 'silver':
      return mapStyleSilverIcon;
    case 'dark':
      return mapStyleDarkIcon;
    case 'retro':
      return mapStyleRetroIcon;
    default:
      return mapStyleStandardIcon;
  }
};

const StyleControls = ({ mapStyle, onChange }: Props) => {
  const anchor = useRef(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = (style: MapStyle, event) => {
    event?.stopPropagation();
    setOpen(false);
    onChange(style);
  };

  return (
    <div styleName="tooltip-wrapper">
      <div styleName="container" ref={anchor} onClick={() => setOpen(true)}>
        <img src={getIcon(mapStyle)} />
        <Icon
          style={{ color: colors.neutral.gray }}
          className="material-icons"
          children="arrow_drop_down"
        />
        <Popover
          open={open}
          anchorEl={anchor.current}
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          onClose={() => setOpen(false)}
        >
          <div styleName="menu-container">
            {ALL_STYLES.map(style => {
              const isSelected = mapStyle === style;

              return (
                <div
                  key={style}
                  styleName={`row ${isSelected ? 'selected' : ''}`}
                  onClick={event => handleClick(style, event)}
                >
                  <img style={styles.inlineIcon} src={getIcon(style)} />
                  {textUtilities.capitalize(style)}
                </div>
              );
            })}
          </div>
        </Popover>
      </div>
      {!open && <div styleName="tooltip-container">Map Styles</div>}
    </div>
  );
};

export default StyleControls;
