import React from 'react';

import { WorkOrderAssetTreeType } from '@atom/types/work';
import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';

import '../workOrderDetailPdfReport.css';

interface Props {
  workOrderAsset: WorkOrderAssetTreeType;
}

const PhotoLogAttachmentsTable = ({ workOrderAsset }: Props) => {
  return (
    <table styleName="collapse-border">
      <thead>
        <tr>
          <th styleName="textbox-table-title">Photograph Log</th>
        </tr>
      </thead>
      <tbody styleName="content">
        {workOrderAsset.elementGroups.map(
          elementGroup =>
            elementGroup.name === 'Photograph Log' &&
            elementGroup.elements.map(element => (
              <>
                {element.attributeGroups.map(attributeGroup => {
                  const attributeGroupIds = attributeGroup.attributes;

                  return attributeGroupIds.map(key => (
                    <tr styleName="textbox-table-row">
                      <td styleName="value" style={{ paddingLeft: '0.625rem' }}>
                        {attributeDisplayUtility.display(
                          element.attributes[key].value,
                          element.attributes[key].dataType,
                          element.attributes[key].unit,
                          !!element.attributes[key].changeId,
                          element.attributes[key].newValue,
                        )}
                      </td>
                    </tr>
                  ));
                })}
              </>
            )),
        )}
      </tbody>
    </table>
  );
};

export default PhotoLogAttachmentsTable;
