import React, { useContext, useEffect, useMemo, useState } from 'react';
import { DragDrop } from 'react-beautiful-dnd';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';

import {
  DragDropContext,
  Draggable,
  Droppable,
} from '@atom/components/common/dragAndDrop';
import {
  GET_WORK_ORDER_AVAILABLE_COLUMNS,
  WORK_ORDER_COLUMNS_UPDATE,
} from '@atom/graph/workColumns';
import { GET_WORK_ORDER_TEMPLATE } from '@atom/graph/workTemplate';
import { Button, Icon, Menu, Modal, Popover, TextField } from '@atom/mui';
import {
  AdditionalColumnSection,
  getAdditionalColumns,
  getUniqueColumnId,
  groupAdditionalColumns,
} from '@atom/selectors/workColumnSelectors';
import colors from '@atom/styles/colors';
import {
  WorkOrderColumn,
  WorkOrderColumnsUpdateInput,
} from '@atom/types/workColumns';
import { WorkTemplateTaskItem } from '@atom/types/workTemplate';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import WorkOrdersContext from './WorkOrdersContext';

import './workOrders.css';

const { MenuItem } = Menu;

const TYPENAME = '__typename';

const styles = {
  icon: {
    backgroundColor: colors.neutral.gray,
    color: colors.neutral.white,
    fontSize: 'medium',
    padding: '0.1rem',
    marginLeft: '0.625rem',
  },
  dragIcon: {
    marginLeft: '1rem',
  },
  menuItem: { textTransform: 'capitalize' },
  addButton: { marginTop: '0.5rem' },
};

interface Props {
  open: boolean;
  onClose: () => void;
}

const MAX_COLUMNS = 24;

const WorkOrdersColumnSettingsModal = ({ open, onClose }: Props) => {
  const { input, refetchColumns, workOrderColumns } = useContext(
    WorkOrdersContext,
  );

  const [columns, setColumns] = useState<WorkOrderColumn[]>(workOrderColumns);
  const [additionalColumns, setAdditionalColumns] = useState<WorkOrderColumn[]>(
    [],
  );
  const [workTemplate, setWorkTemplate] = useState<WorkTemplateTaskItem>();
  const [query, setQuery] = useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopover = Boolean(anchorEl);

  const [getWorkOrderTemplate] = useLazyQuery(GET_WORK_ORDER_TEMPLATE, {
    onCompleted: res => {
      setWorkTemplate(res?.workOrderTemplate);
    },
  });

  const [
    getWorkOrderAvailableColumns,
    { data: workOrderAvailableColumnsData },
  ] = useLazyQuery(GET_WORK_ORDER_AVAILABLE_COLUMNS);

  const [updateWorkOrderColumns, { loading }] = useMutation<
    {},
    { input: WorkOrderColumnsUpdateInput }
  >(WORK_ORDER_COLUMNS_UPDATE, {
    onCompleted: () => {
      refetchColumns();
    },
  });

  useEffect(() => {
    setColumns(workOrderColumns);
  }, [workOrderColumns]);

  const setInitialState = () => {
    setColumns(workOrderColumns);
    setWorkTemplate(null);
    setQuery('');
    setAnchorEl(null);
  };

  useEffect(() => {
    setInitialState();
  }, []);

  useEffect(() => {
    if (R.length(input?.workTemplateIds) === 1) {
      getWorkOrderTemplate({
        variables: {
          id: input.workTemplateIds.join(''),
        },
      });
    }
  }, [input?.workTemplateIds]);

  useEffect(() => {
    if (open) {
      const workTemplateId =
        R.length(input?.workTemplateIds) === 1
          ? R.pathOr([], ['workTemplateIds'], input).join('')
          : 'none';

      getWorkOrderAvailableColumns({
        variables: {
          workTemplateId,
        },
      });
    }
  }, [open, workTemplate?.id, input?.workTemplateIds]);

  useEffect(() => {
    setAdditionalColumns(
      getAdditionalColumns(
        columns,
        workOrderAvailableColumnsData?.workOrderAvailableColumns
          ?.workOrderColumns,
      ),
    );
  }, [workOrderAvailableColumnsData, JSON.stringify(columns)]);

  const handleSave = () => {
    const workTemplateId =
      R.length(input?.workTemplateIds) === 1
        ? R.pathOr([], ['workTemplateIds'], input).join('')
        : 'none';

    updateWorkOrderColumns({
      variables: {
        input: {
          workTemplateId,
          workOrderColumns: columns.map(column => R.omit([TYPENAME], column)),
        },
      },
    });

    onClose();
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleClickPopover = event => {
    setAnchorEl(event.currentTarget);
  };

  const onDragEnd = (result: DragDrop) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const newColumns = R.move(source.index, destination.index, columns);
    setColumns(newColumns);
  };

  const handleRemoveColumn = (index: number) => {
    const newColumns = R.remove(index, 1, columns);
    setColumns(newColumns);
  };

  const handleAddColumn = (column: WorkOrderColumn) => {
    setColumns([...columns, column]);
    setQuery(null);
    handleClosePopover();
  };

  const searchedAdditionalColumns = useMemo(() => {
    if (!query) {
      return additionalColumns;
    }

    return additionalColumns.filter(
      column =>
        column.label.toLowerCase().includes(query.toLowerCase()) ||
        column.taskName?.toLowerCase().includes(query.toLowerCase()),
    );
  }, [query, additionalColumns]);

  const groupedAdditionalColumns = groupAdditionalColumns(
    searchedAdditionalColumns,
    workTemplate?.name,
  );

  const getAdditionalColumnSection = (section: AdditionalColumnSection) => {
    if (isNilOrEmpty(section)) {
      return <div />;
    }

    return section.sectionName === 'WORK_COLUMNS' ? (
      section.columns.map(column => (
        <div onClick={() => handleAddColumn(column)}>
          <MenuItem style={styles.menuItem}>{column.label}</MenuItem>
        </div>
      ))
    ) : (
      <div>
        <div styleName="additional-column-section-name">
          {section.sectionName}
        </div>
        {section.columns.map(column => (
          <div onClick={() => handleAddColumn(column)}>
            <MenuItem style={styles.menuItem}>{column.label}</MenuItem>
          </div>
        ))}
      </div>
    );
  };

  const getRow = (column: WorkOrderColumn, index: number) => {
    const title = column.taskName
      ? `${column.label} (${column.taskName})`
      : column.label;

    return (
      <div styleName="column-row">
        <div styleName="column-row-description">
          <div styleName="column-row-number">{index + 2}</div>
          <Icon color={colors.neutral.ash} style={styles.dragIcon}>
            drag_indicator
          </Icon>
          {column.fieldId && column.taskId && (
            <Icon style={styles.icon}>playlist_add_check</Icon>
          )}
          {column.fieldId && !column.taskId && (
            <Icon style={styles.icon}>work</Icon>
          )}
          <div styleName="column-title">{title}</div>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => handleRemoveColumn(index)}
        >
          <Icon>close</Icon>
        </div>
      </div>
    );
  };

  const addColumnsDisabled =
    R.length(columns) === MAX_COLUMNS || R.length(additionalColumns) === 0;
  const addColumnsIconColor = addColumnsDisabled
    ? colors.neutral.ash
    : colors.brand.blue;

  return (
    <Modal
      onCancel={onClose}
      onExited={setInitialState}
      open={open}
      onConfirm={handleSave}
      confirmButtonText="Save"
      title="Column Settings"
      loading={loading}
    >
      <div style={{ paddingBottom: '1rem' }}>
        You can select up to {MAX_COLUMNS + 1} columns. You can add custom
        fields columns when filtered by one work template.
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="Columns">
          <div styleName="column-row">
            <div styleName="column-row-description">
              <div styleName="column-row-number">1</div>
              <div styleName="column-title">Work ID</div>
            </div>
          </div>
          {columns.map((column: WorkOrderColumn, index: number) => (
            <Draggable
              key={`${getUniqueColumnId(column)}-${index}`}
              draggableId={getUniqueColumnId(column)}
              index={index}
            >
              {getRow(column, index)}
            </Draggable>
          ))}
        </Droppable>
      </DragDropContext>
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        onClose={handleClosePopover}
      >
        <div styleName="add-search-container">
          <TextField
            value={query}
            onChange={event => setQuery(event.target.value)}
            placeholder="Search"
            InputProps={{
              startAdornment: <Icon>search</Icon>,
              endAdornment: query && (
                <Icon onClick={() => setQuery('')}>close</Icon>
              ),
            }}
          />
        </div>
        <div styleName="add-list-container">
          {groupedAdditionalColumns.map(section =>
            getAdditionalColumnSection(section),
          )}
        </div>
      </Popover>
      <Button
        color="primary"
        startIcon={<Icon color={addColumnsIconColor}>add</Icon>}
        onClick={handleClickPopover}
        disabled={addColumnsDisabled}
        style={styles.addButton}
      >
        Add Column
      </Button>
    </Modal>
  );
};

export default WorkOrdersColumnSettingsModal;
