import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import {
  MATERIAL_ENTRY_CREATE,
  MATERIAL_ENTRY_UPDATE,
} from '@atom/graph/materialEntry';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import {
  DatePicker,
  Icon,
  IconButton,
  Progress,
  Select,
  Snackbar,
  TextField,
} from '@atom/mui';
import {
  MaterialEntry,
  MaterialEntryCreateInput,
  MaterialEntryUpdate,
} from '@atom/types/materialEntry';
import { TaskMaterialItem, TaskUser } from '@atom/types/task';
import { WorkOrderDetailType } from '@atom/types/work';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import {
  convertDateToMillisGMTMidday,
  getStartEndTimezoneDay,
} from '@atom/utilities/timeUtilities';

import './materialModal.css';

const { MenuItem } = Select;

const HTTP_STATUS_CONFLICT = 409;

const styles = {
  progress: {
    marginLeft: '12px',
  },
};

export interface Props {
  materialEntry?: MaterialEntry;
  materialEntries: MaterialEntry[];
  createMaterialEntry?: boolean;
  createStartReading?: number;
  setEditMaterialEntryId?: (id: string) => void;
  setCreateMaterialEntry?: (createMaterialEntry: boolean) => void;
  refetchMaterialEntries: () => void;
  editMaterialEntryId: string;
  workOrderDetail: WorkOrderDetailType;
  taskId: string;
  availableUsers: TaskUser[];
  material: TaskMaterialItem;
  totalRemaining: number;
  getUsageSummary: () => void;
  loadingUsageSummary: boolean;
}

const MaterialEntryEditRow = ({
  materialEntry,
  materialEntries,
  createMaterialEntry,
  createStartReading = 0,
  setEditMaterialEntryId = () => {},
  setCreateMaterialEntry = () => {},
  refetchMaterialEntries,
  workOrderDetail,
  editMaterialEntryId,
  taskId,
  availableUsers,
  material,
  totalRemaining,
  getUsageSummary,
  loadingUsageSummary,
}: Props) => {
  const userProfile = useUserProfile();

  const [date, setDate] = useState<Date>(
    materialEntry ? new Date(materialEntry.date) : new Date(),
  );
  const [userId, setUserId] = useState<string>(() => {
    if (materialEntry) {
      return materialEntry.userId;
    }

    const userOnTask = availableUsers.find(
      user => user.id === userProfile.userId,
    );

    return userOnTask ? userProfile.userId : null;
  });
  const [amount, setAmount] = useState<number>(
    materialEntry ? materialEntry.amount : 0,
  );
  const [startReading, setStartReading] = useState<number>(
    materialEntry
      ? materialEntry.startReading
      : material?.isStartEndCalculated
      ? createStartReading
      : null,
  );
  const [endReading, setEndReading] = useState<number>(
    materialEntry
      ? materialEntry.endReading
      : material?.isStartEndCalculated
      ? 0
      : null,
  );

  useEffect(() => {
    if (createStartReading > 0) {
      setStartReading(createStartReading);
    }
  }, [createStartReading]);

  const [materialEntryCreate, { loading: createLoading }] = useMutation<
    {},
    { input: MaterialEntryCreateInput }
  >(MATERIAL_ENTRY_CREATE);

  const [materialEntryUpdate, { loading: editLoading }] = useMutation<
    {},
    { input: MaterialEntryUpdate }
  >(MATERIAL_ENTRY_UPDATE);

  const handleAmount = (value: string) => {
    if (value === '' && !material?.isStartEndCalculated) {
      setAmount(null);
      return;
    }

    const newAmount = parseFloat(value) || 0;

    if (newAmount >= 0) {
      setAmount(newAmount);
      if (material?.isStartEndCalculated) {
        setStartReading(Number(endReading - newAmount));
      }
    }
  };

  const handleStartReading = (value: string) => {
    if (value === '' && !material?.isStartEndCalculated) {
      setStartReading(null);
      return;
    }

    const newReading = parseFloat(value) || 0;
    setStartReading(newReading);

    if (newReading >= 0 && material?.isStartEndCalculated) {
      setAmount(endReading - newReading);
    }
  };

  const handleEndReading = (value: string) => {
    if (value === '' && !material?.isStartEndCalculated) {
      setEndReading(null);
      return;
    }

    const newReading = parseFloat(value) || 0;
    setEndReading(newReading);

    if (newReading >= 0 && material?.isStartEndCalculated) {
      setAmount(newReading - startReading);
    }
  };

  const onCompletedAction = () => {
    refetchMaterialEntries();
    setEditMaterialEntryId(null);
    setCreateMaterialEntry(false);
    getUsageSummary();
  };

  const handleMaterialEntryUpdate = async () => {
    try {
      await materialEntryUpdate({
        variables: {
          input: {
            id: materialEntry.id,
            userId,
            date: convertDateToMillisGMTMidday(date),
            amount: Number(amount.toFixed(2)),
            // For the update call (PATCH) only (not for the POST one),
            // set the reading values to -1 if they are null:
            startReading:
              startReading !== null ? Number(startReading.toFixed(2)) : -1,
            endReading:
              endReading !== null ? Number(endReading.toFixed(2)) : -1,
          },
        },
      });

      onCompletedAction();
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_STATUS_CONFLICT) {
        getUsageSummary();
      } else {
        Snackbar.error({ message: 'An unknown error occurred' });
      }
    }
  };

  const handleMaterialEntryCreate = async () => {
    try {
      await materialEntryCreate({
        variables: {
          input: {
            workOrderId: workOrderDetail.id,
            taskId,
            assetId: material.assetId,
            userId,
            date: convertDateToMillisGMTMidday(date),
            amount: Number(amount.toFixed(2)),
            startReading:
              startReading !== null ? Number(startReading.toFixed(2)) : null,
            endReading:
              endReading !== null ? Number(endReading.toFixed(2)) : null,
          },
        },
      });

      onCompletedAction();
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_STATUS_CONFLICT) {
        getUsageSummary();
      } else {
        Snackbar.error({ message: 'An unknown error occurred' });
      }
    }
  };

  const handleCancel = () => {
    setEditMaterialEntryId(null);
    setCreateMaterialEntry(false);
  };

  const handleSave = async () => {
    if (createMaterialEntry) {
      await handleMaterialEntryCreate();
    } else {
      await handleMaterialEntryUpdate();
    }
  };

  const isUserValid = useMemo(() => {
    const selectedDate = convertDateToMillisGMTMidday(date);

    const selectedUserEntries = materialEntries.filter(
      entry => entry.userId === userId && entry.id !== editMaterialEntryId,
    );

    return !R.find(R.propEq('date', selectedDate))(selectedUserEntries);
  }, [date, userId]);

  const isAmountValid = useMemo(() => {
    if (!date) {
      return true;
    }

    // If the date has been changed, then the currently saved
    // amount should not be added to the total remaining for validation.

    // If the date has not been changed, then the currently saved amount
    // needs to be added to the total remaining for validation.
    const remaining =
      R.pathOr(null, ['date'], materialEntry) !== date.valueOf()
        ? totalRemaining
        : totalRemaining + R.pathOr(0, ['amount'], materialEntry);

    return amount <= remaining;
  }, [amount, date, totalRemaining, materialEntry]);

  const validateReading = useMemo(() => {
    if (startReading !== null && endReading !== null) {
      if (startReading >= endReading && startReading !== 0) {
        return {
          valid: false,
          error: 'End reading must be greater than start reading',
        };
      }
      if (startReading < 0 || endReading < 0) {
        return { valid: false, error: 'Readings cannot be negative values' };
      }
    }
    const defaultError = material?.isStartEndCalculated
      ? 'Used calculated from start and end readings'
      : '';
    return {
      valid: true,
      error: defaultError,
    };
  }, [startReading, endReading]);

  const { valid: isReadingValid, error: readingError } = validateReading;

  const isDateValid = useMemo(() => {
    if (!date) {
      return false;
    }

    // If date is in the future, it is invalid
    const startEndDate = getStartEndTimezoneDay(userProfile.timezoneLocation);

    return date.valueOf() <= startEndDate.end;
  }, [date, materialEntry]);

  const isTeamMemberSelectDisabled = doesNotHaveRolePermissions(
    ROLE_SETS.MANAGER,
  );

  const isSaveDisabled =
    editLoading ||
    createLoading ||
    !userId ||
    !date ||
    !amount ||
    !isUserValid ||
    (material.isStartEndReading && !isReadingValid) ||
    (material.isStockBased && !isDateValid) ||
    (material.isStockBased && !isAmountValid);

  const cost = useMemo(() => {
    const rawCost =
      R.pathOr(0, ['rate'], material) * R.pathOr(0, ['amount'], materialEntry);

    return numberToLocaleString(rawCost.toFixed(2));
  }, [material, materialEntry]);

  const validationLoading = createLoading || editLoading || loadingUsageSummary;

  const amountLabel = (
    <span>
      <span>Used (</span>
      <span styleName="material-unit">{material?.unit}</span>
      <span>)</span>
    </span>
  );

  // Select (highlight) number fields when they receive focus.
  const onFocus = event => (event.target as HTMLInputElement).select();

  // Don't allow entering negative values on the number fields.
  const onKeyDown = event => {
    if (event.key === '-') {
      event.preventDefault();
    }
  };

  return (
    <div styleName="entry-row">
      <div styleName="entry-data-section">
        <div styleName="input-container name">
          <Select
            fullWidth
            label="Team Member"
            value={userId || ''}
            onChange={event => setUserId(event.target.value)}
            error={!isUserValid}
            helperText={!isUserValid && 'User has existing entry'}
            disabled={isTeamMemberSelectDisabled}
          >
            {availableUsers.map(user => {
              return (
                <MenuItem key={user.id} value={user.id}>
                  {`${user.firstName} ${user.lastName}`}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <div styleName="input-container">
          <DatePicker
            label="Date"
            value={date}
            onChange={setDate}
            format="MM/dd/yyyy"
            disableFuture
            TextFieldProps={{
              error: !isDateValid,
            }}
            errorHelperText={
              !isDateValid && 'Cannot select dates in the future'
            }
          />
        </div>
        {material?.isStartEndReading && (
          <div styleName="readings-container">
            <div styleName="readings-input-container">
              <div styleName="input-container">
                <TextField
                  type="number"
                  value={startReading}
                  label="Start Reading"
                  error={!isReadingValid}
                  onChange={event => handleStartReading(event.target.value)}
                  onFocus={onFocus}
                  onKeyDown={onKeyDown}
                />
              </div>
              <div styleName="input-container">
                <TextField
                  type="number"
                  value={endReading}
                  label="End Reading"
                  error={!isReadingValid}
                  onChange={event => handleEndReading(event.target.value)}
                  onFocus={onFocus}
                  onKeyDown={onKeyDown}
                />
              </div>
            </div>
            <div
              styleName={`readings-helper-text-container ${
                isReadingValid ? '' : 'readings-error'
              }`}
            >
              {readingError}
            </div>
          </div>
        )}
        <div styleName="input-container">
          <TextField
            type="number"
            value={amount}
            label={amountLabel}
            error={material.isStockBased && !isAmountValid}
            helperText={
              material.isStockBased &&
              !isAmountValid &&
              'Used exceeds Total Remaining'
            }
            onChange={event => handleAmount(event.target.value)}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
          />
        </div>
        <div styleName="display-container cost">
          <div styleName="label">Cost</div>
          <div>{cost}</div>
        </div>
      </div>
      <div styleName="entry-button-section">
        <IconButton tooltip="Cancel" onClick={handleCancel}>
          <Icon>close</Icon>
        </IconButton>
        {validationLoading ? (
          <Progress size={20} style={styles.progress} />
        ) : (
          <IconButton
            tooltip="Save"
            onClick={handleSave}
            disabled={isSaveDisabled}
          >
            <Icon>done</Icon>
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default MaterialEntryEditRow;
