import React, { useContext } from 'react';
import { Collapse } from '@mui/material';

import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { List } from '@atom/mui';
import { SchemaTree, SchemaTreeElementGroup } from '@atom/types/schema';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import ElementGroupRow from './ElementGroupRow';
import ElementRow from './ElementRow';
import RootRow from './RootRow';

import './subItemTree.css';

const styles = {
  list: {
    padding: '0',
  },
};

const SubItemTree = () => {
  const { schemaTree, collapsedSubItems } = useContext(SchemaDetailContext);

  const renderElementList = (
    elements: SchemaTree[],
    level: number,
    path: any[],
  ) => {
    if (isNilOrEmpty(elements)) {
      return null;
    }

    return elements.map((element, index) => {
      const hasChildren = !isNilOrEmpty(element?.elements);
      const updatedPath = [...path, 'elements', index];

      return (
        <React.Fragment key={element.id}>
          <ElementRow element={element} level={level} path={updatedPath} />
          {hasChildren && (
            <Collapse
              in={!collapsedSubItems.has(element?.id)}
              timeout="auto"
              unmountOnExit
            >
              {renderElementList(element?.elements, level + 1, updatedPath)}
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };

  const renderElementGroupList = (
    elementGroups: SchemaTreeElementGroup[],
    level: number,
    path: any[],
  ) => {
    if (isNilOrEmpty(elementGroups)) {
      return null;
    }

    return elementGroups.map((elementGroup, index) => {
      const hasChildren = !isNilOrEmpty(elementGroup?.elements);
      const updatedPath = [...path, 'elementGroups', index];

      return (
        <React.Fragment key={elementGroup.id}>
          <ElementGroupRow elementGroup={elementGroup} />
          {hasChildren && (
            <Collapse
              in={!collapsedSubItems.has(elementGroup?.id)}
              timeout="auto"
              unmountOnExit
            >
              {renderElementList(
                elementGroup?.elements,
                level + 1,
                updatedPath,
              )}
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <List style={styles.list}>
      <RootRow />
      <div styleName="item-tree-container">
        {renderElementList(schemaTree?.elements, 0, [])}
        {renderElementGroupList(schemaTree?.elementGroups, 0, [])}
      </div>
    </List>
  );
};

export default SubItemTree;
