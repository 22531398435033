import React from 'react';

import LoadMoreButton from '@atom/components/common/loadMoreButton/LoadMoreButton';
import { useSchemas } from '@atom/hooks/useSchemas';
import { useWorkTemplateSearch } from '@atom/hooks/useWorkTemplateSearch';
import { Checkbox, Icon, List, Progress } from '@atom/mui';
import { InventorySchemaItem } from '@atom/types/inventory';
import { PolicyAction } from '@atom/types/policy';
import { WorkOrderType } from '@atom/types/work';
import { SearchWorkTemplate } from '@atom/types/workTemplate';
import { getWorkTypeIconSvg } from '@atom/utilities/workTypeIconUtilities';

const { ListItemButton, ListItemText } = List;

interface Props {
  query: string;
  /**
   * If defined, only work templates applicable to the given schema will be rendered.
   * This includes work templates with the given schema or with no schema.
   */
  schemaId?: string;
  /**
   * If defined, only work templates with/without schema will be returned.
   * If used in conjunction with schemaId the two filters are combined into a logical OR operator.
   * This allows for filters such as schemaId=1 OR schemaId=null
   */
  hasSchema?: boolean;

  /**
   * If defined, only work templates that the user has access with the defined action
   * will be returned in the search. The default is "READ"
   */
  action?: PolicyAction;

  /**
   * Optional callback to be called on work template click.
   */
  onClick?: (template: SearchWorkTemplate) => void;
  /**
   * Optional callback called for each work template in the tree
   * to determine if it should be marked selected.
   * If provided, work template selection will be enabled via checkboxes.
   */
  getTemplateSelected?: (template: SearchWorkTemplate) => boolean;
  /**
   * Optional callback called for each work template in the tree
   * to determine if it should be disabled. If not provided,
   * all work templates will be enabled by default.
   */
  getTemplateDisabled?: (template: SearchWorkTemplate) => boolean;
  /**
   * Optional callback to call when work template is toggled. Will only be called
   * when multi selection is enabled by providing selectedIds
   */
  onToggle?: (template: SearchWorkTemplate) => void;
  /**
   * type allows the template lists to be filtered by WorkOrderType
   */
  type?: WorkOrderType;
}

const LIMIT = 12;

const styles = {
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  button: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    marginRight: '0.5rem',
  },
};

const getTemplateSubtext = (
  template: SearchWorkTemplate,
  schemas: InventorySchemaItem[],
) => {
  const schema = schemas.find(({ id }) => id === template.schemaId);
  const assetType = schema ? schema.assetType : '';
  const path = template.folderPath.map(folder => folder.name).join(' / ');
  return assetType ? `${assetType} \u2022 ${path}` : path;
};

const WorkTemplateSearch = ({
  query,
  schemaId,
  hasSchema,
  action,
  onClick,
  onToggle,
  getTemplateSelected,
  getTemplateDisabled,
  type,
}: Props) => {
  const { schemas = [] } = useSchemas();

  const [
    { workOrderTemplates },
    { loadingSearch, loadingPagination, error, hasMore, fetchMore },
  ] = useWorkTemplateSearch({
    query,
    schemaId,
    hasSchema,
    action,
    limit: LIMIT,
    type,
  });

  const handleClick = (template: SearchWorkTemplate) => {
    if (onClick) {
      onClick(template);
    }
  };

  const handleWorkTemplateToggle = (template: SearchWorkTemplate) => {
    if (onToggle) {
      onToggle(template);
    }
  };

  return (
    <>
      {loadingSearch ? (
        <div style={styles.progress}>
          <Progress />
        </div>
      ) : (
        <List>
          {workOrderTemplates.map(template => {
            const isValid =
              !getTemplateDisabled || !getTemplateDisabled(template);

            const isSelected =
              getTemplateSelected && getTemplateSelected(template);

            return (
              <ListItemButton
                key={template.id}
                onClick={() => handleClick(template)}
                disabled={!isValid}
              >
                {getTemplateSelected && (
                  <Checkbox
                    checked={isSelected}
                    disabled={!isValid}
                    onChange={() => handleWorkTemplateToggle(template)}
                  />
                )}
                <Icon style={styles.icon}>
                  <img
                    src={getWorkTypeIconSvg(
                      template?.isParent,
                      template?.parentId,
                      !isValid,
                    )}
                  />
                </Icon>
                <ListItemText
                  primary={template.name}
                  secondary={getTemplateSubtext(template, schemas)}
                />
              </ListItemButton>
            );
          })}
        </List>
      )}
      {hasMore && (
        <div style={styles.button}>
          <LoadMoreButton
            loading={loadingPagination}
            error={error}
            onClick={fetchMore}
          />
        </div>
      )}
    </>
  );
};

export default WorkTemplateSearch;
