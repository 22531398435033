import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
// @ts-ignore
import addItemIcon from '@atom/components/common/svgIcons/add_subItem.svg';
import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import {
  ELEMENT_CREATE,
  ELEMENT_GROUP_DELETE,
  ELEMENT_GROUP_UPDATE,
} from '@atom/graph/schema';
import { Icon, IconButton, List, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import {
  ElementCreateInput,
  ElementGroupDeleteInput,
  ElementGroupUpdateInput,
  SchemaTree,
  SchemaTreeElementGroup,
} from '@atom/types/schema';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import AddItemModal from '../AddItemModal';
import EditItemModal from '../EditItemModal';

import ListItemWrapper from './ListItemWrapper';

import './subItemTree.css';

const { ListItemText } = List;

const styles = {
  listItemText: {
    fontSize: fonts.md,
    fontWeight: '400',
    color: colors.neutral.gray,
    textTransform: 'uppercase',
  },
};

interface Props {
  elementGroup: SchemaTreeElementGroup;
}

const ElementGroupRow = ({ elementGroup }: Props) => {
  const container = useRef<HTMLDivElement>();

  const {
    schemaTree,
    collapsedSubItems,
    setCollapsedSubItems,
    refetchSchemaTree,
    setSelectedSubItemPath,
    setSelectedSubItem,
  } = useContext(SchemaDetailContext);

  const [hovering, setHovering] = useState<boolean>(false);
  const [openSubItemCreate, setOpenSubItemCreate] = useState<boolean>(false);
  const [openGroupEdit, setOpenGroupEdit] = useState<boolean>(false);
  const [openGroupDelete, setOpenGroupDelete] = useState<boolean>(false);
  const [overflowing, setOverflowing] = useState<boolean>(false);

  const [createElement] = useMutation<
    { elementCreate: SchemaTree },
    { input: ElementCreateInput }
  >(ELEMENT_CREATE);

  const [updateElementGroup] = useMutation<
    { elementGroupUpdate: SchemaTree },
    { input: ElementGroupUpdateInput }
  >(ELEMENT_GROUP_UPDATE);

  const [deleteElementGroup] = useMutation<
    { elementDelete: boolean },
    { input: ElementGroupDeleteInput }
  >(ELEMENT_GROUP_DELETE);

  useEffect(() => {
    const { offsetWidth, scrollWidth } = container.current;
    setOverflowing(offsetWidth < scrollWidth);
  }, [elementGroup.name]);

  const toggleListItem = event => {
    event?.stopPropagation();
    setCollapsedSubItems(toggleFromSet(collapsedSubItems, elementGroup?.id));
  };

  const handleAddSubItem = event => {
    event?.stopPropagation();
    setOpenSubItemCreate(true);
  };

  const handleSubItemCreate = async (name: string) => {
    await createElement({
      variables: {
        input: {
          rootSchemaId: schemaTree?.id,
          parentSchemaId: schemaTree?.id,
          assetType: name,
          name,
          group: elementGroup?.name,
          groupId: elementGroup?.id,
        },
      },
    });

    await refetchSchemaTree();

    setSelectedSubItemPath(null);
    setSelectedSubItem(null);
    setOpenSubItemCreate(false);
  };

  const handleRename = event => {
    event?.stopPropagation();
    setOpenGroupEdit(true);
  };

  const handleGroupEdit = async (name: string) => {
    await updateElementGroup({
      variables: {
        input: {
          rootSchemaId: schemaTree?.id,
          groupId: elementGroup?.id,
          name,
        },
      },
    });

    await refetchSchemaTree();
    setOpenGroupEdit(false);
  };

  const handleDelete = event => {
    event?.stopPropagation();
    setOpenGroupDelete(true);
  };

  const handleGroupDelete = async () => {
    await deleteElementGroup({
      variables: {
        input: {
          rootSchemaId: schemaTree.id,
          groupId: elementGroup.id,
        },
      },
    });

    setSelectedSubItemPath(null);
    setSelectedSubItem(null);
    setOpenGroupDelete(false);

    await refetchSchemaTree();
  };

  const showActionButtons = hovering && !schemaTree?.isPublished;
  const expandedIcon = !collapsedSubItems.has(elementGroup?.id)
    ? 'arrow_drop_down'
    : 'arrow_right';

  const content = (
    <div styleName="sub-item-content">
      <div styleName="sub-item-name group" ref={container}>
        {!overflowing ? (
          elementGroup.name
        ) : (
          <Tooltip title={elementGroup.name} placement="bottom-end">
            <p styleName="sub-item-overflow-container">{elementGroup.name}</p>
          </Tooltip>
        )}
      </div>
      {showActionButtons && (
        <div styleName="sub-item-action-buttons">
          <IconButton
            tooltip="Add Sub Item"
            onClick={handleAddSubItem}
            size="small"
          >
            <img src={addItemIcon} />
          </IconButton>
          <IconButton tooltip="Rename" onClick={handleRename} size="small">
            <Icon>edit</Icon>
          </IconButton>
          <IconButton tooltip="Delete" onClick={handleDelete} size="small">
            <Icon>delete</Icon>
          </IconButton>
        </div>
      )}
    </div>
  );

  return (
    <>
      <div
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <ListItemWrapper hasChildren>
          <>
            <IconButton size="small" onClick={toggleListItem}>
              <Icon>{expandedIcon}</Icon>
            </IconButton>
            <ListItemText
              primary={content}
              primaryTextStyle={styles.listItemText}
            />
          </>
        </ListItemWrapper>
      </div>
      <AddItemModal
        open={openSubItemCreate}
        closeModal={() => setOpenSubItemCreate(false)}
        type="Sub Item"
        handleSave={handleSubItemCreate}
      />
      <EditItemModal
        name={elementGroup.name}
        type="Group"
        handleSave={handleGroupEdit}
        open={openGroupEdit}
        closeModal={() => setOpenGroupEdit(false)}
      />
      <DeleteModal
        open={openGroupDelete}
        onConfirm={handleGroupDelete}
        onCancel={() => setOpenGroupDelete(false)}
        title="Delete Group?"
        content={`Deleting ${elementGroup.name} will remove the group, but not the sub items.`}
        cancelText="Cancel"
        confirmText="Delete"
      />
    </>
  );
};

export default ElementGroupRow;
