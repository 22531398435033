import { gql } from '@apollo/client';

export const BASE_WORK_ORDER_COLUMN = gql`
  fragment BaseWorkOrderColumn on WorkOrderColumn {
    property
    taskId
    taskName
    fieldId
    label
    sortable
  }
`;

export const GET_WORK_ORDER_COLUMNS = gql`
  query workOrderColumns($workTemplateId: ID!) {
    workOrderColumns(workTemplateId: $workTemplateId) {
      totalCount
      workOrderColumns {
        ...BaseWorkOrderColumn
      }
    }
  }
  ${BASE_WORK_ORDER_COLUMN}
`;

export const GET_WORK_ORDER_AVAILABLE_COLUMNS = gql`
  query workOrderAvailableColumns($workTemplateId: ID!) {
    workOrderAvailableColumns(workTemplateId: $workTemplateId) {
      totalCount
      workOrderColumns {
        ...BaseWorkOrderColumn
      }
    }
  }
  ${BASE_WORK_ORDER_COLUMN}
`;

export const WORK_ORDER_COLUMNS_UPDATE = gql`
  mutation workOrderColumnsUpdate($input: WorkOrderColumnsUpdateInput!) {
    workOrderColumnsUpdate(input: $input)
  }
`;
