import React, { useContext } from 'react';

import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';

import WorkTemplateDetails from './WorkTemplateDetails';
import WorkTemplateFields from './WorkTemplateFields';
import WorkTemplateMap from './WorkTemplateMap';
import WorkTemplateSummary from './WorkTemplateSummary';

import './workTemplateInfo.css';

const WorkTemplateInfo = () => {
  const { workTemplate } = useContext(WorkTemplateContext);

  return (
    <div styleName="info-container">
      {workTemplate?.schemaId ? (
        <>
          <div styleName="pane left">
            <WorkTemplateSummary />
            <WorkTemplateFields />
            <WorkTemplateDetails />
          </div>
          <div styleName="pane">
            <WorkTemplateMap />
          </div>
        </>
      ) : (
        <div styleName="no-location-pane">
          <WorkTemplateSummary />
          <WorkTemplateFields />
          <WorkTemplateDetails />
        </div>
      )}
    </div>
  );
};

export default WorkTemplateInfo;
