import React, { useContext, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import * as R from 'ramda';

import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { GET_WORK_ORDER_TEMPLATE } from '@atom/graph/workTemplate';
import { useSchemas } from '@atom/hooks/useSchemas';
import { WorkOrderType } from '@atom/types/work';
import { WorkTemplate } from '@atom/types/workTemplate';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { WorkTypeVariant } from '@atom/utilities/workTemplateUtilities';

import './workTemplateHeader.css';

const WorkTemplateHeaderInfo = () => {
  const { workTemplate, workTypeVariant } = useContext(WorkTemplateContext);

  const { schemas = [] } = useSchemas();

  const [getParentWorkType, { data }] = useLazyQuery<
    { workOrderTemplate: WorkTemplate },
    { id: string }
  >(GET_WORK_ORDER_TEMPLATE, {
    variables: {
      id: workTemplate?.parentId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (workTypeVariant === WorkTypeVariant.CHILD) {
      getParentWorkType();
    }
  }, [workTypeVariant]);

  const schemaName = useMemo(() => {
    const foundSchema = schemas.find(
      schema => schema.id === workTemplate?.schemaId,
    );

    return foundSchema?.name || '-';
  }, [workTemplate?.schemaId, schemas]);

  const parentName = R.pathOr('', ['workOrderTemplate', 'name'], data);

  const isWorkTemplateDefaultType =
    workTemplate?.type === WorkOrderType.DEFAULT;

  return (
    <div styleName="header-info-section">
      {isWorkTemplateDefaultType && (
        <div styleName="info-section-block">
          <div styleName="info-label-container">
            <div>Asset Name:</div>
            <div>Asset Type:</div>
          </div>
          <div>
            <div>-</div>
            <div>{schemaName}</div>
          </div>
        </div>
      )}
      <div styleName="info-section-block">
        <div styleName="info-label-container">
          <div>Start Date:</div>
          <div>Completed Date:</div>
        </div>
        <div>
          <div>-</div>
          <div>-</div>
        </div>
      </div>
      <div styleName="info-section-block">
        <div styleName="info-label-container">
          <div>Due Date:</div>
          <div>Duration:</div>
        </div>
        <div>
          <div>-</div>
          <div>-</div>
        </div>
      </div>
      <div styleName="info-section-block">
        <div styleName="info-label-container">
          <div>Estimated Cost:</div>
          <div>Actual Cost:</div>
        </div>
        <div>
          <div>{numberToLocaleString(workTemplate?.estimatedCost)}</div>
          <div>-</div>
        </div>
      </div>
      {workTypeVariant === WorkTypeVariant.CHILD && (
        <div styleName="info-section-block">
          <div styleName="info-label-container">
            <div>Parent Work Template:</div>
          </div>
          <div>
            <Link to={`/work-templates/${workTemplate?.parentId}`}>
              {parentName || '-'}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkTemplateHeaderInfo;
