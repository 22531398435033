import React, { useState } from 'react';

import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import { FormTemplateInfo } from '@atom/types/form';

import './addFormsModal.css';

interface Props {
  formTemplate: FormTemplateInfo;
  subtext?: string;
  isSelected: boolean;
  availableForms: Set<string>;
  toggleFormSelect: (id: string) => void;
}

const FormRow = ({
  formTemplate,
  subtext,
  isSelected,
  toggleFormSelect,
  availableForms,
}: Props) => {
  const [selected, setSelected] = useState<boolean>(isSelected);

  const isAvailable = availableForms.has(formTemplate.id);

  const toggleForm = () => {
    toggleFormSelect(formTemplate.id);
    setSelected(!selected);
  };

  const nameStyle = (): string => {
    if (selected) {
      return 'form-row-title selected';
    }

    if (!isAvailable) {
      return 'form-row-title disabled';
    }

    return 'form-row-title';
  };

  const subtextStyle = isAvailable ? 'title-subtext' : 'title-subtext disabled';
  const buttonIcon = selected ? 'check' : 'add';
  const iconStyle = {
    color: selected ? colors.brand.blue : colors.neutral.gray,
    cursor: 'pointer',
  };
  const iconColor = isAvailable ? colors.brand.blue : colors.neutral.silver;

  return (
    <div styleName="form-row">
      <div styleName="form-title-container">
        <Icon color={iconColor}>library_books</Icon>
        <div styleName="form-title-content">
          <div styleName={nameStyle()}>{formTemplate.name}</div>
          <div styleName={subtextStyle}>{subtext}</div>
        </div>
      </div>
      {isAvailable && (
        <Icon style={iconStyle} onClick={toggleForm}>
          {buttonIcon}
        </Icon>
      )}
    </div>
  );
};

export default FormRow;
