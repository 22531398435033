import React from 'react';

import { Modal } from '@atom/mui';

import './teamErrorModal.css';

interface Props {
  open: boolean;
  onClose: () => void;
  type: string;
}

const TeamErrorModal = ({ open, onClose, type }: Props) => {
  return (
    <Modal
      title={`Cannot delete ${type}`}
      open={open}
      cancelButtonText="OK"
      onCancel={onClose}
      width="sm"
      ConfirmButtonProps={{ style: { display: `none` } }}
    >
      <div styleName="modal-container">
        {`The ${type} cannot be deleted because it is in use on one or more work. Please remove the ${type} from all work and try again.`}
      </div>
    </Modal>
  );
};

export default TeamErrorModal;
