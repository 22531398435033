import React, { useContext, useState } from 'react';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import EditSummaryModal from '@atom/components/common/workOrderDetail/workOrderSummary/EditSummaryModal';
import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { Icon, Menu } from '@atom/mui';
import { Summary } from '@atom/types/work';
import { InheritedComponentType } from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { isComponentInherited } from '@atom/utilities/workTemplateUtilities';

import './workTemplateInfo.css';

const { MenuItem } = Menu;

const styles = {
  icon: {
    marginRight: '0.5rem',
  },
};

interface Props {
  summary: Summary;
  summaries: Summary[];
  index: number;
  updateSummary: (updatedSummary: Summary[]) => void;
}

const WorkTemplateSummaryRow = ({
  summary,
  summaries,
  index,
  updateSummary,
}: Props) => {
  const { workTemplate } = useContext(WorkTemplateContext);

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const isInherited = isComponentInherited(
    InheritedComponentType.SUMMARY,
    workTemplate?.inheritedComponents || [],
  );

  const deleteSummary = () => {
    const updatedSummary = R.remove(index, 1, summaries);
    updateSummary(updatedSummary);
    setDeleteModalOpen(false);
  };

  const editSummary = (newSummary: Summary) => {
    const updatedSummary = summaries.map(
      (summaryItem: Summary, indexValue: number) =>
        indexValue === index ? newSummary : summaryItem,
    );
    updateSummary(updatedSummary);
  };

  const isDisabled =
    workTemplate?.published ||
    isInherited ||
    doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  const titleStyle = isInherited ? 'summary-title disabled' : 'summary-title';
  const contentStyle = isInherited
    ? 'summary-item-content disabled'
    : 'summary-item-content';

  return (
    <>
      <div styleName="summary-item-header">
        <div styleName={titleStyle}>{summary.title}</div>
        {!isDisabled && (
          <Menu disabled={isDisabled}>
            <MenuItem onClick={() => setEditModalOpen(true)}>
              <Icon style={styles.icon}>edit</Icon>
              Edit
            </MenuItem>
            <MenuItem onClick={() => setDeleteModalOpen(true)}>
              <Icon style={styles.icon}>delete</Icon>
              Delete
            </MenuItem>
          </Menu>
        )}
      </div>
      <div styleName={contentStyle}>{summary.contents}</div>
      <DeleteModal
        open={deleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={() => deleteSummary()}
        title="Delete Section?"
        content={`Are you sure you want to delete the ${summary.title} section?`}
      />
      <EditSummaryModal
        open={editModalOpen}
        closeModal={() => setEditModalOpen(false)}
        summary={summary}
        editSummary={editSummary}
      />
    </>
  );
};

export default WorkTemplateSummaryRow;
