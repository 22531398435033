import * as R from 'ramda';

type IdObject = {
  id: string;
};

export const objectIdsMatch = (
  list1: IdObject[],
  list2: IdObject[],
): boolean => {
  if (list1.length !== list2.length) {
    return false;
  }

  const list1Ids = R.pluck('id', list1);
  const list2Ids = R.pluck('id', list2);

  const list1Set = new Set(list1Ids);
  const list2MatchingIds = list2Ids.filter(id => list1Set.has(id));
  return list1Ids.length === list2MatchingIds.length;
};
