import React from 'react';

import { Icon, Menu } from '@atom/mui';
import { WorkOrderField } from '@atom/types/work';

const { MenuItem } = Menu;

interface Props {
  field: WorkOrderField;
  onEdit: (field: WorkOrderField) => void;
  onDelete: (field: WorkOrderField) => void;
  isWorkTemplate?: boolean;
}

const styles = {
  icon: {
    marginRight: '0.5rem',
  },
};

const WorkOrderFieldMenu = ({
  field,
  onEdit,
  onDelete,
  isWorkTemplate,
}: Props) => {
  const handleEditClick = (selectedField: WorkOrderField) => {
    onEdit(selectedField);
  };

  const handleDeleteClick = (selectedField: WorkOrderField) => {
    onDelete(selectedField);
  };

  return (
    <Menu>
      <MenuItem onClick={() => handleEditClick(field)}>
        <Icon style={styles.icon}>create</Icon>
        Edit
      </MenuItem>
      {!isWorkTemplate && (
        <MenuItem onClick={() => handleDeleteClick(field)}>
          <Icon style={styles.icon}>delete</Icon>
          Delete
        </MenuItem>
      )}
    </Menu>
  );
};

export default WorkOrderFieldMenu;
