import React, { useContext, useState } from 'react';

import DeleteModal from '@atom/components/common/DeleteModal';
import MaterialModal from '@atom/components/common/workOrderDetail/materialModal/MaterialModal';
import TaskContext from '@atom/components/workOrderPreview/taskView/TaskContext';
import WorkOrderPreviewContext from '@atom/components/workOrderPreview/WorkOrderPreviewContext';
import { Icon, IconButton, Snackbar, Tooltip } from '@atom/mui';
import { TaskMaterialItem } from '@atom/types/task';
import api from '@atom/utilities/api';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { WORK_ORDERS_ENDPOINT } from '@atom/utilities/endpoints';
import schemaUtilities from '@atom/utilities/schemaUtilities';

import './materialSection.css';

interface Props {
  material: TaskMaterialItem;
}

const MaterialTile = ({ material }: Props) => {
  const { workOrderDetail, refetch } = useContext(WorkOrderPreviewContext);
  const { task } = useContext(TaskContext);

  const [materialModalOpen, setMaterialModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const iconStyle = {
    color: schemaUtilities.getSchemaColorFromColorId(material.colorId),
  };

  const costSubtext = `${material.quantity.toFixed(2) || 0} ${
    material.unit
  } - ${numberToLocaleString(material.cost.toFixed(2))}`;

  const isEditDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  const removeMaterial = async () => {
    setDeleteModalOpen(false);

    Snackbar.info({
      message: 'Deleting...',
    });

    const endpoint = `${WORK_ORDERS_ENDPOINT}/${workOrderDetail.id}/tasks/${task.id}/materials/${material.assetId}`;

    await api.delete(endpoint);

    Snackbar.info({
      message: `Removed Material.`,
    });

    refetch();
  };

  return (
    <>
      <div styleName="tile-container">
        <div
          styleName="tile-content-container"
          onClick={() => setMaterialModalOpen(true)}
        >
          <div styleName="tile-icon-container">
            <Icon style={iconStyle}>category</Icon>
          </div>
          <div styleName="tile-content">
            <Tooltip title={material.name}>
              <div styleName="tile-title">{material.name}</div>
            </Tooltip>
            <div styleName="tile-subtext">{costSubtext}</div>
          </div>
        </div>
        {!isEditDisabled && (
          <IconButton
            onClick={() => setDeleteModalOpen(true)}
            tooltip="Remove"
            edge="end"
          >
            <Icon>close</Icon>
          </IconButton>
        )}
      </div>
      <MaterialModal
        onClose={() => setMaterialModalOpen(false)}
        open={materialModalOpen}
        material={material}
        task={task}
        workOrderDetail={workOrderDetail}
        refetch={refetch}
      />
      <DeleteModal
        open={deleteModalOpen}
        onConfirm={removeMaterial}
        onCancel={() => setDeleteModalOpen(false)}
        title="Remove Equipment or Material From Task"
        content="Are you sure you want to remove this item from the task?"
        cancelText="Cancel"
        confirmText="Remove"
      />
    </>
  );
};

export default MaterialTile;
