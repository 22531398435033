/* eslint-disable @typescript-eslint/no-shadow */
import { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import { SEARCH_ASSETS } from '@atom/graph/asset';
import { SearchAsset, SearchAssetsConnection } from '@atom/types/inventory';

import client from '../graph/client';

export interface AssetsSearchOptions {
  // search query
  query: string;
  // schema id of assets to search for
  schemaId?: string;
  // schema ids of assets to search for
  schemaIds?: string[];
  // parentCategory id of assets to search for
  parentCategoryId?: string;
  // number of milliseconds to debounce search at
  // defaults to 500
  debounceTime?: number;
  // character min before querying the API for results
  characterMin?: number;
  // results limit
  limit?: number;
  // denotes that only material assets should be returned
  includeMaterialAssets?: boolean;
  // denotes that only location based assets should be returned
  includeLocationBasedAssets?: boolean;
  // denotes whether to include inactive assets
  includeInactive?: boolean;
}

export type Data = [
  SearchAssetsConnection,
  {
    fetchMore: () => void;
    hasMore: boolean;
    loadingSearch: boolean;
    loadingPagination: boolean;
    error: boolean;
  },
];

type DebouncedSearch = (
  query: string,
  assets: SearchAsset[],
  schemaId?: string,
  schemaIds?: string[],
  parentCategoryId?: string,
  limit?: number,
  page?: number,
) => void;

export const useAssetsSearch = ({
  query,
  schemaId,
  schemaIds,
  parentCategoryId,
  limit,
  characterMin = 1,
  debounceTime = 500,
  includeMaterialAssets,
  includeLocationBasedAssets,
  includeInactive,
}: AssetsSearchOptions): Data => {
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [loadingPagination, setLoadingPagination] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [connection, setConnection] = useState<SearchAssetsConnection>({
    totalCount: 0,
    assets: [],
  });

  const nextPage = useMemo(() => {
    if (!limit || !connection.totalCount) {
      return 1;
    }

    return Math.floor(connection.assets.length / limit) + 1;
  }, [connection, limit]);

  const searchAssets = async (
    search: string,
    assets: SearchAsset[],
    schemaId?: string,
    schemaIds?: string[],
    parentCategoryId?: string,
    limit?: number,
    page?: number,
  ) => {
    setError(false);

    try {
      const { data } = await client.query<{
        assetsSearch: SearchAssetsConnection;
      }>({
        query: SEARCH_ASSETS,
        variables: {
          input: {
            query: search,
            ...R.reject(R.isNil, {
              limit,
              page,
              schemaId,
              schemaIds,
              parentCategoryId,
              includeMaterialAssets,
              includeLocationBasedAssets,
              includeInactive,
            }),
          },
        },
      });

      setConnection({
        totalCount: data.assetsSearch.totalCount,
        assets: [...assets, ...data.assetsSearch.assets],
      });
    } catch (err) {
      setError(true);
    }

    setLoadingSearch(false);
    setLoadingPagination(false);
  };

  const searchAssetsDebounced = useCallback<DebouncedSearch>(
    debounce(searchAssets, debounceTime),
    [],
  );

  useEffect(() => {
    if (query.length >= characterMin) {
      setLoadingSearch(true);
      searchAssetsDebounced(
        query,
        [],
        schemaId,
        schemaIds,
        parentCategoryId,
        limit,
      );
    } else {
      setConnection({ totalCount: 0, assets: [] });
    }
  }, [query]);

  const hasMore = connection.assets.length < connection.totalCount;

  const fetchMore = () => {
    if (hasMore) {
      setLoadingPagination(true);
      searchAssets(
        query,
        connection.assets,
        schemaId,
        schemaIds,
        parentCategoryId,
        limit,
        nextPage,
      );
    }
  };

  return [
    connection,
    {
      fetchMore,
      hasMore,
      loadingSearch,
      loadingPagination,
      error,
    },
  ];
};
