import React, { useContext, useEffect, useRef, useState } from 'react';
import * as R from 'ramda';

import TeamContext from '@atom/components/teamPortal/TeamContext';
import { Icon, IconButton, Popover } from '@atom/mui';
import colors from '@atom/styles/colors';
import { UserGroupTreeType } from '@atom/types/userGroups';

import './userGroupsTable.css';

interface Breadcrumb {
  id: string;
  name: string;
  colorId: number;
  path: any[];
}

const hydratePaths = (activePath: any[]): Breadcrumb[] => {
  return activePath.reduce((acc, item, index) => {
    const path = R.slice(0, R.inc(index), activePath);

    return [...acc, { ...item, path }];
  }, []);
};

const UserGroupsBreadcrumbs = () => {
  const container = useRef<HTMLDivElement>();
  const [anchor, setAnchor] = useState<any>(null);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [overflowing, setOverflowing] = useState<boolean>(false);

  const { activePath, handleGroup } = useContext(TeamContext);

  const breadcrumbs = hydratePaths(activePath);
  const activeBreadcrumb = R.last(breadcrumbs);
  const lastIndex = R.dec(breadcrumbs.length);

  const overflowBreadcrumbs = R.slice(
    1,
    R.dec(breadcrumbs.length),
    breadcrumbs,
  );

  const handleToggle = event => {
    setAnchor(event.currentTarget);
    setPopoverOpen(true);
  };

  const handleClick = (
    selectedUserGroup: UserGroupTreeType,
    path: UserGroupTreeType[],
  ) => {
    handleGroup(selectedUserGroup, path);
    setPopoverOpen(false);
  };

  useEffect(() => {
    setOverflowing(
      container.current.offsetHeight < container.current.scrollHeight,
    );
  }, [activePath]);

  const arrow = <Icon color={colors.neutral.dim}>keyboard_arrow_right</Icon>;

  const directoryBreadcrumb = (
    <>
      <div
        styleName="breadcrumb"
        onClick={() => handleClick(breadcrumbs[0], [])}
      >
        Directory
      </div>
      {Boolean(breadcrumbs.length) && arrow}
    </>
  );

  const overflowContent = (
    <div
      styleName="breadcrumb-container"
      style={{ visibility: overflowing ? 'visible' : 'hidden' }}
    >
      {directoryBreadcrumb}
      <IconButton onClick={handleToggle}>
        <Icon color={colors.neutral.dim}>more_horiz</Icon>
      </IconButton>
      <Popover
        open={popoverOpen}
        anchorEl={anchor}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        onClose={() => setPopoverOpen(false)}
      >
        {overflowBreadcrumbs.map(breadcrumb => (
          <div
            key={breadcrumb.id}
            onClick={() => handleClick(breadcrumb, breadcrumb.path)}
            styleName="breadcrumb-menu-item"
          >
            {breadcrumb.name}
          </div>
        ))}
      </Popover>
      {arrow}
      <span
        styleName="breadcrumb"
        onClick={() => handleClick(activeBreadcrumb, activeBreadcrumb?.path)}
      >
        {activeBreadcrumb?.name}
      </span>
    </div>
  );

  const regularContent = (
    <div
      styleName="breadcrumb-container"
      ref={container}
      style={{ visibility: overflowing ? 'hidden' : 'visible' }}
    >
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={breadcrumb.id}>
          <div
            styleName="breadcrumb"
            onClick={() => handleClick(breadcrumb, breadcrumb.path)}
          >
            {breadcrumb.name}
          </div>
          {index !== lastIndex && arrow}
        </React.Fragment>
      ))}
    </div>
  );

  return overflowing ? (
    <>
      {overflowContent}
      {regularContent}
    </>
  ) : (
    <>
      {regularContent}
      {overflowContent}
    </>
  );
};

export default UserGroupsBreadcrumbs;
