import { LineCoordinates, LineStringCoordinates } from '@atom/types/map';

// returns length of a line made up of two points
export const getLineLength = (coordinates: LineCoordinates) => {
  const xd = coordinates[0][0] - coordinates[1][0];
  const yd = coordinates[0][1] - coordinates[1][1];
  return Math.sqrt(xd * xd + yd * yd);
};

// returns total length of LineString coordinates
export const getTotalLineLength = (coordinates: LineStringCoordinates) =>
  coordinates.reduce(
    (acc, point, index) =>
      !index ? acc : acc + getLineLength([coordinates[index - 1], point]),
    0,
  );

// returns a point on a line made up of two points by the given distance
export const getPointOnLine = (line: LineCoordinates, distance: number) => {
  // eslint-disable-next-line id-length
  const t = distance / getLineLength(line);
  const xt = (1 - t) * line[0][0] + t * line[1][0];
  const yt = (1 - t) * line[0][1] + t * line[1][1];
  return [xt, yt];
};

// returns a point on a line string (multiple points) by the given distance
const getPointByDistance = (
  coordinates: LineStringCoordinates,
  distance: number,
) => {
  let cl = 0;
  let ol = 0;

  for (let index = 1; index < coordinates.length; index++) {
    ol = cl;

    const previousPoint = coordinates[index - 1];
    const point = coordinates[index];
    cl += getLineLength([previousPoint, point]);

    if (distance <= cl && distance > ol) {
      const dd = distance - ol;
      return getPointOnLine([previousPoint, point], dd);
    }
  }

  return [0, 0];
};

export const getLineStringCenter = (coordinates: LineStringCoordinates) => {
  const totalLength = getTotalLineLength(coordinates);
  const midDistance = totalLength / 2;
  return getPointByDistance(coordinates, midDistance);
};

export default {
  getLineStringCenter,
};
