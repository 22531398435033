import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';

import UserContext from '@atom/components/userDetail/UserContext';
import { USER_UPDATE } from '@atom/graph/user';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { RadioGroup } from '@atom/mui';
import { RoleType, UserDetail, UserUpdateInput } from '@atom/types/user';
import authUtilities, {
  hasInferiorUserLevel,
  hasRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';

import PermissionsModal from './PermissionsModal';

import './permissionsTab.css';

const { RadioButton } = RadioGroup;

interface Role {
  label: string;
  role: RoleType;
  description: string;
  level: number;
}

export const roles: Role[] = [
  {
    label: 'Admin',
    role: 'Admin',
    description: 'Full application Access.',
    level: 1,
  },
  {
    label: 'Organization Admin',
    role: 'OrgAdmin',
    description: 'Full application access in this workspace.',
    level: 2,
  },
  {
    label: 'Manager',
    role: 'Manager',
    description: 'Full account access.',
    level: 3,
  },
  {
    label: 'Field',
    role: 'Inspector',
    description:
      'Field team members can work on work, edit inventory items and access forms and teams.',
    level: 4,
  },
  {
    label: 'Read Only',
    role: 'ReadOnly',
    description: 'Can be assigned work to review.',
    level: 5,
  },
];

const PermissionsTab = () => {
  const { user } = useContext(UserContext);
  const userProfile = useUserProfile();

  const [updateUser, { loading }] = useMutation<
    { userUpdate: UserDetail },
    { input: UserUpdateInput }
  >(USER_UPDATE);

  const onChange = async (level: number) => {
    const userRole = roles.find(
      (roleItem: Role): boolean => roleItem.level === level,
    );

    const { data } = await updateUser({
      variables: {
        input: {
          id: user.id,
          role: userRole.role,
        },
      },
    });

    if (userProfile.userId === user.id) {
      authUtilities.setUserRole(data.userUpdate.role);
      authUtilities.setUserRoleLevel(data.userUpdate.level);
    }
  };

  const userRole =
    roles.find((roleItem: Role): boolean => user.role === roleItem.role)
      .level || 5;

  const canEdit = hasRolePermissions(ROLE_SETS.ORG_ADMIN);

  return (
    <>
      <div styleName="header">
        <div>Permission</div>
        <PermissionsModal />
      </div>
      <div styleName="content">
        <RadioGroup
          name="role"
          defaultValue={userRole}
          onChange={event => onChange(+event.target.value)}
        >
          {roles.map((item: Role) => {
            const disabled =
              hasInferiorUserLevel(user.level) ||
              hasInferiorUserLevel(item.level);
            const containerStyle = disabled
              ? 'role-label-container disabled'
              : 'role-label-container';

            return (
              <RadioButton
                key={item.role}
                value={item.level.toString()}
                disabled={!canEdit || loading || disabled}
                label={
                  <div styleName={containerStyle}>
                    {item.label}
                    <div styleName="role-description">{item.description}</div>
                  </div>
                }
              />
            );
          })}
        </RadioGroup>
      </div>
    </>
  );
};

export default PermissionsTab;
