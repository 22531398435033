import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import * as R from 'ramda';

import InputDateRange from '@atom/components/common/inputDateRange/InputDateRange';
import WorkOrdersContext, {
  FilterSection,
  WorkOrdersInputActionTypes,
} from '@atom/components/workOrders/WorkOrdersContext';
import { WorkOrdersInputState } from '@atom/hooks/useWorkOrdersFilters';
import { Icon, IconButton, Select, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { WorkOrdersConnectionInput } from '@atom/types/work';
import { WorkDatePresetKey } from '@atom/types/workFilters';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import {
  convertDateToLocalDayEnd,
  convertDateToLocalDayStart,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import workOrderPriorityUtilities from '@atom/utilities/workOrderPriorityUtilities';
import {
  DateRangePresetOption,
  DateRangeTerminator,
} from '@atom/utilities/workOrdersDateFilterUtilities';
import { workOrderStatusTypes } from '@atom/utilities/workOrderStatusUtilities';

import WorkOrdersTemplateFilter from './WorkOrdersTemplateFilter';
import WorkOrdersTemplateFolderFilter from './WorkOrdersTemplateFolderFilter';
import WorkOrdersWorkFieldsFilter from './WorkOrdersWorkFieldsFilter';

import './workOrdersFilters.css';

const { MenuItem } = Select;

const DAY_IN_MILLISECONDS = 8.64e7;

const styles = {
  icon: {
    marginRight: '1rem',
  },
  separator: {
    margin: '0 1rem',
    paddingBottom: '0.5rem',
  },
};

const useClasses = makeStyles({
  label: {
    position: 'unset',
    color: `${colors.neutral.dim} !important`,
  },
});

const WorkOrdersFiltersWorkSection = () => {
  const classes = useClasses();
  const {
    collapsedSections,
    setCollapsedSections,
    workOrdersInputCart,
    dispatch,
    filtersDisabled,
  } = useContext(WorkOrdersContext);

  const { dateFilterPresetsCart } = workOrdersInputCart;

  const onToggle = (section: FilterSection) => {
    setCollapsedSections(toggleFromSet(collapsedSections, section));
  };

  const handleUpdate = (value: any, property: keyof WorkOrdersInputState) => {
    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_INPUT_PROPERTY,
      data: {
        property,
        value,
      },
    });
  };

  const handleDateChange = (
    property: keyof WorkOrdersConnectionInput,
    terminator: DateRangeTerminator,
  ) => (val: Date) => {
    const dateMillis = val
      ? terminator === DateRangeTerminator.START
        ? convertDateToLocalDayStart(val)
        : convertDateToLocalDayEnd(val)
      : null;
    handleUpdate(dateMillis, property);
  };

  const handleDatePresetChange = (
    type: WorkDatePresetKey,
    property: string,
  ) => (option: DateRangePresetOption) => {
    const newDateFilterPresets = {
      ...dateFilterPresetsCart,
      [type]: {
        ...dateFilterPresetsCart[type],
        [property]: option.value,
      },
    };
    handleUpdate(newDateFilterPresets, 'dateFilterPresetsCart');
  };

  const handleDurationChange = (property: keyof WorkOrdersConnectionInput) => (
    event: React.ChangeEvent<any>,
  ) => {
    const value =
      event.target.value === ''
        ? null
        : Number(event.target.value) * DAY_IN_MILLISECONDS;

    handleUpdate(value, property);
  };

  const handleMultiSelectChange = (
    property: keyof WorkOrdersConnectionInput,
  ) => (event: React.ChangeEvent<any>) => {
    handleUpdate(event.target.value, property);
  };

  const getDateValue = (property: keyof WorkOrdersConnectionInput) => {
    if (workOrdersInputCart && !isNilOrEmpty(workOrdersInputCart[property])) {
      return new Date(Number(workOrdersInputCart[property]));
    }

    return null;
  };

  const convertMillisecondsToDays = (daysMillis?: number) => {
    return daysMillis ? daysMillis / DAY_IN_MILLISECONDS : daysMillis;
  };

  const handleNoDueDateSelect = (selected: boolean) => {
    handleUpdate(selected, 'withoutDueDate');
  };

  return (
    <div styleName="filters-section">
      <div styleName="filters-section-header">
        <div>
          <Icon style={styles.icon}>work</Icon>
          Work
        </div>
        <IconButton onClick={() => onToggle(FilterSection.WORK)} edge="end">
          <Icon>
            {collapsedSections.has(FilterSection.WORK)
              ? 'keyboard_arrow_down'
              : 'keyboard_arrow_up'}
          </Icon>
        </IconButton>
      </div>
      {!collapsedSections.has(FilterSection.WORK) && (
        <div styleName="filters-section-content">
          <div styleName="filter-container">
            <InputDateRange
              label="Due Date"
              disabled={filtersDisabled}
              presetSelected={
                dateFilterPresetsCart?.[WorkDatePresetKey.WORK]?.dueDatePreset
              }
              startDateValue={getDateValue('dueDateStart')}
              handleStartChange={handleDateChange(
                'dueDateStart',
                DateRangeTerminator.START,
              )}
              endDateValue={getDateValue('dueDateEnd')}
              handleEndChange={handleDateChange(
                'dueDateEnd',
                DateRangeTerminator.END,
              )}
              handleNoDueDateSelect={handleNoDueDateSelect}
              handleSelectDatePreset={handleDatePresetChange(
                WorkDatePresetKey.WORK,
                'dueDatePreset',
              )}
            />
          </div>
          <div styleName="filter-container">
            <InputDateRange
              label="Created Date"
              disabled={filtersDisabled}
              presetSelected={
                dateFilterPresetsCart?.[WorkDatePresetKey.WORK]
                  ?.createdDatePreset
              }
              startDateValue={getDateValue('createdDateStart')}
              handleStartChange={handleDateChange(
                'createdDateStart',
                DateRangeTerminator.START,
              )}
              endDateValue={getDateValue('createdDateEnd')}
              handleEndChange={handleDateChange(
                'createdDateEnd',
                DateRangeTerminator.END,
              )}
              handleSelectDatePreset={handleDatePresetChange(
                WorkDatePresetKey.WORK,
                'createdDatePreset',
              )}
            />
          </div>
          <div styleName="filter-container">
            <InputDateRange
              label="Start Date"
              disabled={filtersDisabled}
              presetSelected={
                dateFilterPresetsCart?.[WorkDatePresetKey.WORK]?.startDatePreset
              }
              startDateValue={getDateValue('startDateStart')}
              handleStartChange={handleDateChange(
                'startDateStart',
                DateRangeTerminator.START,
              )}
              endDateValue={getDateValue('startDateEnd')}
              handleEndChange={handleDateChange(
                'startDateEnd',
                DateRangeTerminator.END,
              )}
              handleSelectDatePreset={handleDatePresetChange(
                WorkDatePresetKey.WORK,
                'startDatePreset',
              )}
            />
          </div>
          <div styleName="filter-container">
            <InputDateRange
              label="Completed Date"
              disabled={filtersDisabled}
              presetSelected={
                workOrdersInputCart?.dateFilterPresetsCart?.[
                  WorkDatePresetKey.WORK
                ]?.completionDatePreset
              }
              startDateValue={getDateValue('completionDateStart')}
              handleStartChange={handleDateChange(
                'completionDateStart',
                DateRangeTerminator.START,
              )}
              endDateValue={getDateValue('completionDateEnd')}
              handleEndChange={handleDateChange(
                'completionDateEnd',
                DateRangeTerminator.END,
              )}
              handleSelectDatePreset={handleDatePresetChange(
                WorkDatePresetKey.WORK,
                'completionDatePreset',
              )}
            />
          </div>
          <div styleName="filters-date-range filter-container">
            <TextField
              value={convertMillisecondsToDays(
                workOrdersInputCart?.durationStart,
              )}
              onChange={handleDurationChange('durationStart')}
              label="Duration"
              type="number"
              disabled={filtersDisabled}
              variant="standard"
              InputLabelProps={{
                classes: { root: classes.label },
              }}
            />
            <span style={styles.separator}>to</span>
            <TextField
              value={convertMillisecondsToDays(
                workOrdersInputCart?.durationEnd,
              )}
              onChange={handleDurationChange('durationEnd')}
              label=""
              type="number"
              disabled={filtersDisabled}
              variant="standard"
            />
            <span style={styles.separator}>Days</span>
          </div>
          <div styleName="filter-container">
            <Select
              multiple
              displayEmpty
              label="Priority"
              value={workOrdersInputCart?.priorityIds || []}
              onChange={handleMultiSelectChange('priorityIds')}
              renderValue={
                R.length(workOrdersInputCart?.priorityIds) > 0
                  ? null
                  : () => 'All'
              }
              disabled={filtersDisabled}
              InputLabelProps={{ classes: { root: classes.label } }}
            >
              {workOrderPriorityUtilities.workOrderPriorityTypes.map(
                priority => (
                  <MenuItem key={priority.value} value={priority.value}>
                    {priority.label}
                  </MenuItem>
                ),
              )}
            </Select>
          </div>
          <div styleName="filter-container">
            <Select
              multiple
              displayEmpty
              label="Status"
              value={workOrdersInputCart?.statusIds || []}
              onChange={handleMultiSelectChange('statusIds')}
              renderValue={
                R.length(workOrdersInputCart?.statusIds) > 0
                  ? null
                  : () => 'All'
              }
              disabled={filtersDisabled}
              InputLabelProps={{ classes: { root: classes.label } }}
            >
              {workOrderStatusTypes.map(status => (
                <MenuItem key={+status.value} value={+status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div styleName="filter-container">
            <WorkOrdersTemplateFolderFilter />
          </div>
          <div styleName="filter-container">
            <WorkOrdersTemplateFilter />
          </div>
          {R.length(workOrdersInputCart?.workTemplateIds) === 1 && (
            <div styleName="filter-container">
              <WorkOrdersWorkFieldsFilter />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WorkOrdersFiltersWorkSection;
