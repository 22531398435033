import React, { useContext } from 'react';
import {
  MenuItem as MUIMenuItem,
  MenuItemProps as MUIMenuItemProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';

import { MenuContext } from './Menu';

type CustomProps = {
  /**
   * whether to close the parent Menu on click
   * defaults to true
   */
  closeMenuOnClick?: boolean;
  /**
   * optional node to render at the start of the menu item
   */
  startAdornment?: React.ReactNode;
  /**
   * optional styles to apply to the container of the given startAdornment
   */
  startAdornmentStyle?: React.CSSProperties;
  /**
   * content to render as MenuItem children
   */
  children?: React.ReactNode;
};

type MenuItemProps = CustomProps & MUIMenuItemProps;

const useStyles = makeStyles({
  root: {
    minHeight: '2.25rem',
    fontWeight: 500,
    fontSize: fonts.md,
    '&:focus-visible': {
      outline: 'none',
    },
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: 'auto',
  },
  selected: {
    color: colors.brand.blue,
  },
});

const styles = {
  startAdornment: {
    marginRight: '1rem',
  },
};

// forwardRef needed to forward ref passed by Menu component
const MenuItem = React.forwardRef(
  (props: MenuItemProps, ref: React.Ref<any>) => {
    const classes = useStyles();

    const { open, close } = useContext(MenuContext);

    const {
      closeMenuOnClick = true,
      children,
      startAdornment,
      startAdornmentStyle = {},
      ...data
    } = props;

    const handleClick = (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    ) => {
      if (props.onClick) {
        props.onClick(event);
      }

      if (open && closeMenuOnClick) {
        close(event);
      }
    };

    return (
      // @ts-ignore
      <MUIMenuItem
        {...data}
        onClick={handleClick}
        classes={{
          root: classes.root,
          selected: classes.selected,
          ...props?.classes,
        }}
        ref={ref}
      >
        {startAdornment && (
          <div style={{ ...styles.startAdornment, ...startAdornmentStyle }}>
            {startAdornment}
          </div>
        )}
        {children}
      </MUIMenuItem>
    );
  },
);

export default MenuItem;
