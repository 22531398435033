import React, { useState } from 'react';

import colors from '@atom/styles/colors';
import { DashboardCard } from '@atom/types/dashboard';

import CardLoading from '../commonCardStates/CardLoading';
import { getCardHeight } from '../dashboardUtilities';

import '../dashboardCard.css';

interface Props {
  card: DashboardCard;
  embedLink: string;
}

const CardCustomEmbed = ({ card, embedLink }: Props) => {
  const [loading, setLoading] = useState(true);
  const style = {
    height: getCardHeight(card),
    borderTop: `1px solid ${colors.neutral.ash}`,
  };

  const iFrameHeight = loading ? '1%' : '100%';

  return (
    <div style={style} styleName="card-content">
      {loading && (
        <CardLoading card={card} style={{ height: getCardHeight(card) }} />
      )}
      <iframe
        width="100%"
        height={iFrameHeight}
        src={embedLink}
        style={{ border: 0, overflow: 'hidden' }}
        allowFullScreen
        onLoad={() => setLoading(false)}
      />
    </div>
  );
};

export default React.memo(CardCustomEmbed);
