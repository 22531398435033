import * as R from 'ramda';
import {
  call,
  fork,
  put,
  select,
  takeEvery,
  throttle,
} from 'redux-saga/effects';

import actionTypes from '@atom/actions/actionTypes';
import * as formInstanceActions from '@atom/actions/formInstanceActions';
import * as inventoryAssetActions from '@atom/actions/inventoryAssetActions';
import textConstants from '@atom/constants/textConstants';
import { Snackbar } from '@atom/mui';
import accountUtilities from '@atom/utilities/accountUtilities';
import api from '@atom/utilities/api';
import {
  INVENTORY_ASSETS_ENDPOINT,
  INVENTORY_ASSETS_FILTER_ENDPOINT,
  WORK_ORDERS_ENDPOINT,
} from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';
import { TOTAL_COUNT_HEADER } from '@atom/utilities/requestUtilities';

const getRootInventoryAssetId = state =>
  R.pathOr('', ['inventoryAssetDetail', 'id'])(state);

export function* getInventoryAssets({ data: request }) {
  try {
    const {
      parentCategoryId,
      columns,
      page,
      limit,
      sortBy,
      isAscending,
      includeInactive,
    } = request;

    const baseFields = columns.map(field => field.fieldName);
    const filteredFields = baseFields.includes('budget.rate')
      ? [...baseFields, 'budget.unit']
      : baseFields;
    const fields = filteredFields.join(',');

    const params = {
      ...(page ? { page } : {}),
      ...(limit ? { limit } : {}),
      ...(fields ? { fields } : {}),
      ...(sortBy ? { sortBy } : {}),
      ...(!R.isNil(isAscending) ? { isAscending } : {}),
      ...(parentCategoryId ? { parentCategoryId } : {}),
      ...(!R.isNil(includeInactive) ? { includeInactive } : {}),
    };

    const { data, headers } = yield call(
      api.get,
      INVENTORY_ASSETS_ENDPOINT,
      params,
    );
    const total = Number(headers[TOTAL_COUNT_HEADER]) || 0;

    yield put(
      inventoryAssetActions.getInventoryAssetsSuccess({
        parentCategoryId,
        columns,
        data,
        total,
      }),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.getInventoryAssetsFailure,
    );
  }
}

export function* getInventoryAssetsFilter({ data: request }) {
  try {
    const {
      rootAssets,
      schemaId,
      categoryIds,
      columns,
      sortBy,
      isAscending,
      body,
      page,
      limit,
      hasPendingChangesOnly,
      includeInactive,
    } = request;

    const baseFields = columns.map(field => field.fieldName);
    const filteredFields = baseFields.includes('budget.rate')
      ? [...baseFields, 'budget.unit']
      : baseFields;
    const fields = filteredFields.join(',');

    const params = {
      ...(schemaId ? { schemaId } : {}),
      ...(!R.isNil(rootAssets) ? { rootAssets } : {}),
      ...(categoryIds ? { categoryIds: categoryIds.join(',') } : {}),
      ...(page ? { page } : {}),
      ...(limit ? { limit } : {}),
      ...(fields ? { fields } : {}),
      ...(sortBy ? { sortBy } : {}),
      ...(!R.isNil(isAscending) ? { isAscending } : {}),
      ...(!R.isNil(hasPendingChangesOnly) ? { hasPendingChangesOnly } : {}),
      ...(!R.isNil(includeInactive) ? { includeInactive } : {}),
    };

    const { data, headers } = yield call(
      api.post,
      INVENTORY_ASSETS_FILTER_ENDPOINT,
      body,
      params,
    );
    const total = Number(headers[TOTAL_COUNT_HEADER]) || 0;

    yield put(
      inventoryAssetActions.getInventoryAssetsFilterSuccess({
        columns,
        data,
        total,
      }),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.getInventoryAssetsFilterFailure,
    );
  }
}

export function* getInventorySearch(action) {
  try {
    const { data } = yield call(
      api.get,
      INVENTORY_ASSETS_ENDPOINT,
      action.data,
    );

    yield put(inventoryAssetActions.getInventorySearchSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.getInventorySearchFailure,
    );
  }
}

export function* getInventoryAssetDetail({ data: { id } }) {
  try {
    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${id}`;
    const { data: inventoryAssetDetail } = yield call(api.get, endpoint, {
      includeCursor: true,
    });

    const workOrderParams = {
      assetId: id,
      statusIds: '3',
      page: 1,
      limit: 1,
    };

    const { data: workOrders } = yield call(
      api.get,
      WORK_ORDERS_ENDPOINT,
      workOrderParams,
    );

    if (!R.isEmpty(workOrders)) {
      Snackbar.info({
        message:
          'Work is in progress, any changes made could impact this work.',
      });
    }

    yield put(
      inventoryAssetActions.getInventoryAssetDetailSuccess(
        inventoryAssetDetail,
      ),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.getInventoryAssetDetailFailure,
    );
  }
}

export function* getInventoryAssetTreeNode({ data: { id, elementPath } }) {
  try {
    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${id}`;
    const {
      data: { elementGroups },
    } = yield call(api.get, endpoint);
    const data = {
      elementGroups,
      elementPath,
    };

    yield put(inventoryAssetActions.getInventoryAssetTreeNodeSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.getInventoryAssetTreeNodeFailure,
    );
  }
}

export function* getInventoryElementTreeNode({ data: { id, elementPath } }) {
  try {
    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${id}`;
    const {
      data: { elementGroups },
    } = yield call(api.get, endpoint);
    const data = {
      elementGroups,
      elementPath,
    };

    yield put(inventoryAssetActions.getInventoryElementTreeNodeSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.getInventoryElementTreeNodeFailure,
    );
  }
}

export function* updateInventoryAssetTreeNode({ data: updatedAsset }) {
  try {
    const { elementPath, ...body } = updatedAsset;
    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${updatedAsset.id}`;

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_UPDATING_TEXT,
    });

    const { data } = yield call(api.patch, endpoint, body);

    yield put(
      inventoryAssetActions.inventoryAssetTreeNodeUpdateSuccess({
        data,
        elementPath,
      }),
    );

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_UPDATED_TEXT,
      autoHideDuration: 3000,
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.inventoryAssetTreeNodeUpdateFailure,
    );
  }
}

export function* updateInventoryElementTreeNode({ data: updatedAsset }) {
  try {
    const { elementPath, ...body } = updatedAsset;
    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${updatedAsset.id}`;

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_UPDATING_TEXT,
    });
    const { data } = yield call(api.patch, endpoint, body);

    yield put(
      inventoryAssetActions.inventoryElementTreeNodeUpdateSuccess({
        data,
        elementPath,
      }),
    );

    Snackbar.info({ message: textConstants.GENERIC_APPLICATION_UPDATED_TEXT });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.inventoryElementTreeNodeUpdateFailure,
    );
  }
}

export function* getInventoryElementDetail({ data: { id } }) {
  try {
    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${id}`;
    const { data } = yield call(api.get, endpoint);

    yield put(inventoryAssetActions.getInventoryElementDetailSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.getInventoryElementDetailFailure,
    );
  }
}

export function* updateInventoryAsset({ data: updatedAsset }) {
  try {
    const { attributeGroupName, ...body } = updatedAsset;
    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${updatedAsset.id}`;

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_UPDATING_TEXT,
    });

    const { data } = yield call(api.patch, endpoint, body, {
      includeCursor: !!body.name,
    });

    yield put(
      inventoryAssetActions.inventoryAssetUpdateSuccess({
        ...data,
        attributeGroupName,
      }),
    );

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_UPDATED_TEXT,
      autoHideDuration: 3000,
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.inventoryAssetUpdateFailure,
    );
  }
}

export function* updateInventoryElement({ data: updatedElement }) {
  try {
    const { rootAssetId, attributeGroupName, ...body } = updatedElement;
    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${body.id}`;
    const elementEndpoint = `${INVENTORY_ASSETS_ENDPOINT}/${rootAssetId}`;

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_UPDATING_TEXT,
    });
    /*
      TODO: ATOM-2079 - Redesign and refactor this so that only ONE call is made
      to the API layer
     */
    yield call(api.patch, endpoint, body);
    const { data } = yield call(api.get, elementEndpoint);

    const mainAssetId = yield select(getRootInventoryAssetId);
    const rootAssetEndpoint = `${INVENTORY_ASSETS_ENDPOINT}/${mainAssetId}`;
    const { data: mainAssetData } = yield call(api.get, rootAssetEndpoint);
    yield put(
      inventoryAssetActions.getInventoryAssetDetailSuccess({
        ...mainAssetData,
      }),
    );

    yield put(
      inventoryAssetActions.inventoryElementUpdateSuccess({
        ...data,
        attributeGroupName,
      }),
    );

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_UPDATED_TEXT,
      autoHideDuration: 3000,
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.inventoryElementUpdateFailure,
    );
  }
}

export function* updateInventoryElementAttributeFromPendingUpdate({
  data: payload,
}) {
  try {
    const { action, attributeGroupName, assetId, attribute } = payload;

    const assetEndpoint = `${INVENTORY_ASSETS_ENDPOINT}/${assetId}`;
    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${assetId}/attributes/${
      attribute.id
    }/changes/${attribute.changeId}/${
      action === 'accept' ? 'approve' : 'reject'
    }`;

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_UPDATING_TEXT,
    });
    /*
      TODO: ATOM-2079 - Redesign and refactor this so that only ONE call is made
      to the API layer
     */
    yield call(api.post, endpoint);
    const { data } = yield call(api.get, assetEndpoint);

    yield put(
      inventoryAssetActions.inventoryElementUpdateSuccess({
        ...data,
        attributeGroupName,
      }),
    );

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_UPDATED_TEXT,
      autoHideDuration: 3000,
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.inventoryElementUpdateFailure,
    );
  }
}

export function* updateInventoryAssetAttributeFromPendingUpdate({
  data: payload,
}) {
  try {
    const { action, attributeGroupName, assetId, attribute } = payload;

    const assetEndpoint = `${INVENTORY_ASSETS_ENDPOINT}/${assetId}`;
    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${assetId}/attributes/${
      attribute.id
    }/changes/${attribute.changeId}/${
      action === 'accept' ? 'approve' : 'reject'
    }`;

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_UPDATING_TEXT,
    });
    /*
      TODO: ATOM-2079 - Redesign and refactor this so that only ONE call is made
      to the API layer
     */
    yield call(api.post, endpoint);
    const { data } = yield call(api.get, assetEndpoint);

    yield put(
      inventoryAssetActions.inventoryAssetUpdateSuccess({
        ...data,
        attributeGroupName,
      }),
    );

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_UPDATED_TEXT,
      autoHideDuration: 3000,
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.inventoryAssetUpdateFailure,
    );
  }
}

export function* updateAssetFromPendingUpdate({ data: payload }) {
  try {
    const action = payload.action === 'accept' ? 'approve' : 'reject';
    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${payload.element.id}/${action}`;

    const rootAssetId = yield select(getRootInventoryAssetId);
    const rootAssetEndpoint = `${INVENTORY_ASSETS_ENDPOINT}/${rootAssetId}`;

    const requestPayload = {
      includeChangesBefore: payload.element.includeChangesBefore,
    };

    yield call(api.post, endpoint, requestPayload);
    const { data } = yield call(api.get, rootAssetEndpoint);

    yield put(inventoryAssetActions.assetPendingApprovalSuccess());
    yield put(inventoryAssetActions.getInventoryAssetDetailSuccess(data));
  } catch (error) {
    yield put(inventoryAssetActions.assetPendingApprovalFailure());
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.getInventoryAssetsFailure,
    );
  }
}

export function* inventoryRootAssetBatchCreate({ data: batchPayload }) {
  try {
    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${batchPayload.assetId}/elements`;

    const rootAssetId = yield select(getRootInventoryAssetId);
    const rootAssetEndpoint = `${INVENTORY_ASSETS_ENDPOINT}/${rootAssetId}`;

    // eslint-disable-next-line
    const { assetId, ...requestPayload } = batchPayload;

    yield call(api.post, endpoint, requestPayload);
    const { data } = yield call(api.get, rootAssetEndpoint);

    yield put(inventoryAssetActions.createRootAssetBatchSuccess());
    yield put(inventoryAssetActions.getInventoryAssetDetailSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.createRootAssetBatchFailure,
    );
  }
}

export function* inventoryRootAssetBatchCreateFHWA({ data: batchPayload }) {
  try {
    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${batchPayload.assetId}/elements`;

    // eslint-disable-next-line
    const { assetId, formInstanceId, ...requestPayload } = batchPayload;

    yield call(api.post, endpoint, requestPayload);

    yield put(inventoryAssetActions.createRootAssetBatchFHWASuccess());

    yield put(
      formInstanceActions.retrieveFormInstance({
        formInstanceId: batchPayload.formInstanceId,
      }),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.createRootAssetBatchFHWAFailure,
    );
  }
}

export function* deleteInventoryAsset({ data: { id, rootAssetId } }) {
  try {
    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${id}`;
    const rootAssetEndpoint = `${INVENTORY_ASSETS_ENDPOINT}/${rootAssetId}`;

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
    });

    yield call(api.delete, endpoint);
    const { data } = yield call(api.get, rootAssetEndpoint);

    yield put(inventoryAssetActions.getInventoryAssetDetailSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.inventoryAssetDeletionFailure,
    );
  }
}

export function* deleteInventoryElement({ data: { id, elementPath } }) {
  try {
    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${id}`;

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
    });

    yield call(api.delete, endpoint);

    yield put(
      inventoryAssetActions.inventoryElementDeletionSuccess({
        id,
        elementPath,
      }),
    );

    Snackbar.info({
      message: 'Element Deleted',
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.inventoryElementDeletionFailure,
    );
  }
}

export function* deleteFHWAElement({ data: { assetId, formInstanceId } }) {
  try {
    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${assetId}`;

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
    });

    yield call(api.delete, endpoint);

    yield put(inventoryAssetActions.deleteFhwaElementSuccess());

    Snackbar.info({
      message: 'Element Deleted',
    });

    yield put(
      formInstanceActions.retrieveFormInstance({
        formInstanceId,
      }),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.deleteFhwaElementFailure,
    );
  }
}

export function* createAsset(action) {
  try {
    const { data } = yield call(
      api.post,
      INVENTORY_ASSETS_ENDPOINT,
      action.data,
    );

    yield put(inventoryAssetActions.createAssetSuccess(data));

    Snackbar.info({
      message: `Created asset ${data.name}.`,
      action: 'View',
      onActionClick: () => history.push(`/inventory/${data.id}`),
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.createAssetFailure,
    );
  }
}

export function* deleteRootInventoryAsset(action) {
  try {
    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${action.data}`;

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
    });

    yield call(api.delete, endpoint);

    yield put(
      inventoryAssetActions.rootInventoryAssetDeletionSuccess(action.data),
    );

    Snackbar.info({
      message: 'Root Asset Deleted',
    });
  } catch (error) {
    if (error.response && error.response.status === 422) {
      Snackbar.error({
        message:
          'Work is currently associated with this inventory asset. Deletion is not allowed.',
      });
    }

    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.rootInventoryAssetDeletionFailure,
    );
  }
}

export function* getInventoryAssetTree(action) {
  try {
    const { assetId } = action.data;
    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${assetId}/tree`;

    const { data } = yield call(api.get, endpoint);

    yield put(inventoryAssetActions.getInventoryAssetTreeSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventoryAssetActions.getInventoryAssetTreeFailure,
    );
  }
}

export function* updateInventoryAssetTreeNodeExpanded(action) {
  yield put(
    inventoryAssetActions.inventoryAssetTreeNodeExpandedUpdateSuccess(
      action.data,
    ),
  );
}

/* WATCHERS */
export function* retrieveInventoryAssets() {
  // @ts-ignore
  yield takeEvery(actionTypes.REQUEST_INVENTORY_ASSETS, getInventoryAssets);
}

export function* retrieveInventoryAssetsFilter() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_INVENTORY_ASSETS_FILTER,
    getInventoryAssetsFilter,
  );
}

export function* retrieveInventorySearch() {
  yield throttle(500, actionTypes.REQUEST_INVENTORY_SEARCH, getInventorySearch);
}

export function* retrieveInventoryAssetDetail() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_INVENTORY_ASSET_DETAIL,
    getInventoryAssetDetail,
  );
}

export function* retrieveInventoryAssetTreeNode() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_INVENTORY_ASSET_TREE_NODE,
    getInventoryAssetTreeNode,
  );
}

export function* retrieveInventoryElementTreeNode() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_INVENTORY_ELEMENT_TREE_NODE,
    getInventoryElementTreeNode,
  );
}

export function* requestInventoryAssetUpdate() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_INVENTORY_ASSET_DETAIL_UPDATE,
    updateInventoryAsset,
  );
}

export function* requestInventoryAssetTreeNodeUpdate() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_INVENTORY_ASSET_TREE_NODE_UPDATE,
    updateInventoryAssetTreeNode,
  );
}

export function* retrieveInventoryElementDetail() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_INVENTORY_ELEMENT_DETAIL,
    getInventoryElementDetail,
  );
}

export function* requestInventoryElementUpdate() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_INVENTORY_ELEMENT_DETAIL_UPDATE,
    updateInventoryElement,
  );
}

export function* requestElementAttributePendingApproval() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_ELEMENT_ATTRIBUTE_PENDING_APPROVAL,
    updateInventoryElementAttributeFromPendingUpdate,
  );
}

export function* requestAssetAttributePendingApproval() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_ASSET_ATTRIBUTE_PENDING_APPROVAL,
    updateInventoryAssetAttributeFromPendingUpdate,
  );
}

export function* requestInventoryElementTreeNodeUpdate() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_INVENTORY_ELEMENT_TREE_NODE_UPDATE,
    updateInventoryElementTreeNode,
  );
}

export function* requestRootAssetBatchCreate() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_ROOT_ASSET_BATCH_CREATE,
    inventoryRootAssetBatchCreate,
  );
}

export function* requestRootAssetBatchCreateFHWA() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_ROOT_ASSET_BATCH_CREATE_FHWA,
    inventoryRootAssetBatchCreateFHWA,
  );
}

export function* requestFHWAElementDeletion() {
  // @ts-ignore
  yield takeEvery(actionTypes.REQUEST_FHWA_ELEMENT_DELETE, deleteFHWAElement);
}

export function* requestElementBatchCreate() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_ELEMENT_BATCH_CREATE,
    inventoryRootAssetBatchCreate,
  );
}

export function* requestInventoryAssetDeletion() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_INVENTORY_ASSET_DELETE,
    deleteInventoryAsset,
  );
}

export function* requestInventoryElementDeletion() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_INVENTORY_ELEMENT_DELETE,
    deleteInventoryElement,
  );
}

export function* requestRootInventoryAssetDeletion() {
  yield takeEvery(
    actionTypes.REQUEST_ROOT_INVENTORY_ASSET_DELETION,
    deleteRootInventoryAsset,
  );
}

export function* requestCreateAsset() {
  yield takeEvery(actionTypes.REQUEST_CREATE_ASSET, createAsset);
}

export function* requestInventoryAssetTreeNodeExpandedUpdate() {
  yield takeEvery(
    actionTypes.REQUEST_INVENTORY_ASSET_TREE_NODE_EXPANDED_UPDATE,
    updateInventoryAssetTreeNodeExpanded,
  );
}

export function* requestAssetPendingApproval() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_ASSET_PENDING_APPROVAL,
    updateAssetFromPendingUpdate,
  );
}

export function* requestInventoryAssetTree() {
  yield takeEvery(
    actionTypes.REQUEST_INVENTORY_ASSET_TREE,
    getInventoryAssetTree,
  );
}

export default [
  retrieveInventoryAssets,
  retrieveInventoryAssetsFilter,
  retrieveInventorySearch,
  retrieveInventoryAssetDetail,
  retrieveInventoryAssetTreeNode,
  retrieveInventoryElementTreeNode,
  requestInventoryAssetUpdate,
  requestInventoryAssetTreeNodeExpandedUpdate,
  requestInventoryAssetTreeNodeUpdate,
  requestInventoryElementTreeNodeUpdate,
  retrieveInventoryElementDetail,
  requestInventoryElementUpdate,
  requestElementAttributePendingApproval,
  requestAssetAttributePendingApproval,
  requestAssetPendingApproval,
  requestRootAssetBatchCreate,
  requestRootAssetBatchCreateFHWA,
  requestElementBatchCreate,
  requestInventoryAssetDeletion,
  requestInventoryElementDeletion,
  requestRootInventoryAssetDeletion,
  requestCreateAsset,
  requestFHWAElementDeletion,
  requestInventoryAssetTree,
];
