import React, { useMemo } from 'react';
import * as R from 'ramda';

// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import { useSchemas } from '@atom/hooks/useSchemas';
import { DatePicker, Icon, List, Switch, TextField } from '@atom/mui';
import { WorkOrderType } from '@atom/types/work';
import {
  SearchWorkTemplate,
  WorkTemplateListItem,
} from '@atom/types/workTemplate';
import { getWorkTypeIconSvg } from '@atom/utilities/workTypeIconUtilities';

import './createWorkModals.css';

const { ListItem, ListItemText } = List;

interface Props {
  dueDate?: Date;
  onDueDateChange: (date: Date) => void;
  quantity: number;
  onQuantityChange: (quantity: number) => void;
  workTemplate: WorkTemplateListItem | SearchWorkTemplate;
  isLocationBased: boolean;
  onLocationBasedChange: (value: boolean) => void;
}

const styles = {
  icon: {
    marginRight: '2rem',
  },
};

const CreateBulkWorkByQuantity = ({
  dueDate,
  onDueDateChange,
  quantity,
  onQuantityChange,
  isLocationBased,
  onLocationBasedChange,
  workTemplate,
}: Props) => {
  const { schemas = [] } = useSchemas();

  const handleQuantityChange = event => {
    const { value } = event.target;
    onQuantityChange(value ? +value : null);
  };

  const handleDueDateChange = (date?: Date) => {
    if (!date) {
      onDueDateChange(null);
    } else {
      onDueDateChange(date);
    }
  };

  const workTemplateSchema = useMemo(() => {
    return schemas.find(({ id }) => id === workTemplate?.schemaId);
  }, [workTemplate]);

  const showLocation = workTemplate?.type === WorkOrderType.DEFAULT;

  return (
    <>
      {workTemplate && (
        <div styleName="modal-row work-template">
          <Icon style={styles.icon}>
            <img
              src={getWorkTypeIconSvg(
                workTemplate?.isParent,
                workTemplate?.parentId,
              )}
            />
          </Icon>
          <ListItem disableGutters style={{ margin: 0, padding: 0 }}>
            <ListItemText
              style={{ margin: 0 }}
              primary={workTemplate.name}
              secondary={workTemplateSchema?.name}
            />
          </ListItem>
        </div>
      )}
      <div styleName="modal-row name">
        <Icon style={styles.icon}>
          <img src={renameIcon} />
        </Icon>
        <TextField
          required
          id="workOrderQuantity"
          label="Quantity"
          value={R.isNil(quantity) ? '' : quantity}
          type="number"
          onChange={handleQuantityChange}
          error={quantity <= 0 || quantity > 100}
          helperText={
            quantity <= 0 || quantity > 100
              ? 'Quantity must be between 1 and 100'
              : ''
          }
          inputProps={{
            min: 1,
            max: 100,
          }}
        />
      </div>
      <div styleName="modal-row due-date">
        <Icon style={styles.icon}>event</Icon>
        <DatePicker
          label="Due Date"
          value={dueDate}
          onChange={handleDueDateChange}
        />
      </div>
      {showLocation && (
        <div style={{ marginTop: '0.5rem' }}>
          <Switch
            checked={isLocationBased}
            onChange={() => onLocationBasedChange(!isLocationBased)}
            name="isLocationBased"
            label="Location"
          />
        </div>
      )}
    </>
  );
};

export default CreateBulkWorkByQuantity;
