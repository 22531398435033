import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import * as inventoryExportActions from '@atom/actions/inventoryExportActions';
import { Checkbox, Icon } from '@atom/mui';
import {
  getAttributeCount,
  getSubItemCheckedValue,
} from '@atom/selectors/inventoryExportSelectors';
import colors from '@atom/styles/colors';
import { ElementsType } from '@atom/types/inventory';
import { ExportTemplateDetailType } from '@atom/types/inventoryExport';
import iconUtilities from '@atom/utilities/iconUtilities';

import './inventoryExportDetail.css';

const PADDING_OFFSET = 2;

const styles = {
  arrowStyle: {
    fontSize: '24px',
    margin: 0,
  },
  blankStyle: {
    width: '24px',
    height: '24px',
  },
};

interface Props {
  element: any;
  indentation: number;
  exportTemplateDetail: ExportTemplateDetailType;
  selectedSubItemId: string;
  updateSelectedSubItem: (subItemId: string) => void;
}

const SchemaTreeRow = ({
  element,
  indentation,
  exportTemplateDetail,
  updateSelectedSubItem,
  selectedSubItemId,
}: Props) => {
  const dispatch = useDispatch();
  const [isChecked, setChecked] = useState(
    getSubItemCheckedValue(element.id, exportTemplateDetail),
  );

  const toggleCheck = () => {
    if (isChecked) {
      dispatch(
        inventoryExportActions.requestDeleteExportTemplateSchema({
          exportTemplateId: exportTemplateDetail.id,
          schemaId: element.id,
        }),
      );
    } else {
      dispatch(
        inventoryExportActions.requestAddExportTemplateSchema({
          exportTemplateId: exportTemplateDetail.id,
          schemaId: element.id,
        }),
      );
    }

    setChecked(!isChecked);
  };

  const elementIcon = iconUtilities.getDataManagementElementIcon(
    element.markerId,
    isChecked,
  );

  const leftIcon = (
    <>
      {!R.isNil(element.elements) ? (
        <Icon style={styles.arrowStyle} color={colors.neutral.gray}>
          arrow_drop_down
        </Icon>
      ) : (
        <div style={styles.blankStyle} />
      )}
      <Checkbox checked={isChecked} onChange={toggleCheck} />
      {elementIcon}
    </>
  );

  const onClick = () => {
    updateSelectedSubItem(element.id);
  };

  const style = {
    paddingLeft: `calc(1rem + ${PADDING_OFFSET * indentation}rem)`,
  };

  const selectedRowStyle =
    element.id === selectedSubItemId ? 'schema-row selected' : 'schema-row';

  const attributeCount = getAttributeCount(exportTemplateDetail, element.id);

  return (
    <>
      <div styleName={selectedRowStyle} style={style} onClick={onClick}>
        {leftIcon}
        <div styleName="schema-row-name-container">
          <div styleName="schema-row-name">{element.name}</div>
          {attributeCount > 0 && (
            <div styleName="schema-row-count">{attributeCount}</div>
          )}
        </div>
      </div>
      {element.elements ? (
        element.elements.map((elem: ElementsType) => {
          return (
            <SchemaTreeRow
              key={elem.id}
              element={elem}
              indentation={indentation + 1}
              exportTemplateDetail={exportTemplateDetail}
              updateSelectedSubItem={updateSelectedSubItem}
              selectedSubItemId={selectedSubItemId}
            />
          );
        })
      ) : (
        <div />
      )}
    </>
  );
};

export default SchemaTreeRow;
