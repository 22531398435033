import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SortDirection } from '@mui/material';
import * as R from 'ramda';

import { GET_TIME_ENTRIES_STATUS } from '@atom/graph/timeEntry';
import { WORK_ORDER_DELETE, WORK_ORDER_DUPLICATE } from '@atom/graph/work';
import { Button, Icon, ListTable, Modal, Progress, Snackbar } from '@atom/mui';
import { getDataAccessByProperty } from '@atom/selectors/workColumnSelectors';
import colors from '@atom/styles/colors';
import layout from '@atom/styles/layout';
import {
  TimeEntriesConnectionInput,
  TimeEntriesStatusConnection,
  TimeEntryStatus,
} from '@atom/types/timeEntry';
import { WorkOrder } from '@atom/types/work';
import { WorkOrderColumn } from '@atom/types/workColumns';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import history from '@atom/utilities/history';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import WorkOrdersColumnSettingsModal from './WorkOrdersColumnSettingsModal';
import WorkOrdersContext from './WorkOrdersContext';
import WorkOrdersTableRow from './WorkOrdersTableRow';

import './workOrders.css';

const {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} = ListTable;

const styles = {
  removeButton: {
    background: colors.brand.red,
    color: colors.neutral.white,
  },
  icon: {
    backgroundColor: colors.neutral.gray,
    color: colors.neutral.white,
    fontSize: '12px',
    marginRight: '6px',
    padding: '0.1rem',
  },
};

const WorkOrdersTable = () => {
  const {
    workOrders,
    workOrderColumns,
    totalCount,
    input,
    setInput,
    refetch,
  } = useContext(WorkOrdersContext);

  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openDuplicate, setOpenDuplicate] = useState<boolean>(false);
  const [isColumnSettingsOpen, setIsColumnSettingsOpen] = useState<boolean>(
    false,
  );
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<WorkOrder>(null);

  const [deleteWorkOrder, { loading: loadingDelete }] = useMutation(
    WORK_ORDER_DELETE,
  );
  const [duplicateWorkOrder, { loading: loadingDuplicate }] = useMutation(
    WORK_ORDER_DUPLICATE,
  );

  const [
    getTimeEntriesStatus,
    { loading: timeEntriesStatusLoading, data: timeEntriesStatusData },
  ] = useLazyQuery<
    { timeEntries: TimeEntriesStatusConnection },
    { input: TimeEntriesConnectionInput }
  >(GET_TIME_ENTRIES_STATUS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (openDelete || openDuplicate) {
      getTimeEntriesStatus({
        variables: {
          input: {
            workOrderId: selectedWorkOrder.id,
            statuses: [TimeEntryStatus.approved],
          },
        },
      });
    }
  }, [openDelete, openDuplicate]);

  const handleConfirm = async () => {
    try {
      await deleteWorkOrder({
        variables: {
          id: selectedWorkOrder.id,
        },
      });

      Snackbar.info({ message: 'Work deleted.' });
    } catch (error) {
      const message =
        error?.networkError?.statusCode === 423
          ? 'Cannot delete a work used to create a work template.'
          : 'An error occurred. Please try again.';

      Snackbar.error({ message });
    }

    setOpenDelete(false);
    setSelectedWorkOrder(null);
    refetch();
  };

  const handleDuplicate = async () => {
    const { id, name } = selectedWorkOrder;

    Snackbar.info({ message: `Duplicating work ${name}...` });

    try {
      const { data } = await duplicateWorkOrder({ variables: { id } });

      Snackbar.info({
        message: `Duplicated work ${name}.`,
        action: 'View',
        onActionClick: () =>
          history.push(`/workOrders/${data.workOrderDuplicate}`),
      });
    } catch (error) {
      Snackbar.error({ message: `Failed to duplicate work ${name}.` });
    }

    setOpenDuplicate(false);
    setSelectedWorkOrder(null);
    refetch();
  };

  const getSortDirection = useCallback(
    (field: string): SortDirection => {
      if (!input.sortBy?.includes(field)) {
        return false;
      }

      return R.pathOr('asc', [1], input.sortBy.split(','));
    },
    [input.sortBy],
  );

  const handleSortDirectionChange = (field: string) => (
    sortBy: SortDirection,
  ) => {
    setInput({ ...input, sortBy: `${field},${sortBy}` });
  };

  const hasApprovedTimeEntries =
    timeEntriesStatusData?.timeEntries?.totalCount > 0;

  const getDeleteModalValue = () => {
    return hasApprovedTimeEntries
      ? {
          title: `Cannot Delete Work`,
          content: 'Work with approved work time cannot be deleted.',
        }
      : {
          title: 'Delete Work',
          content: 'Are you sure you want to delete this work?',
        };
  };

  const getDuplicateModalValue = () => {
    return {
      title: `Duplicate Work`,
      content: 'Are you sure you want to duplicate this work?',
    };
  };

  const deleteModalLoading = loadingDelete || timeEntriesStatusLoading;
  const duplicateModalLoading = loadingDuplicate || timeEntriesStatusLoading;

  const getDeleteFooter = () => {
    return hasApprovedTimeEntries ? (
      <Button
        onClick={() => {
          setOpenDelete(false);
        }}
      >
        OK
      </Button>
    ) : (
      <div>
        <Button
          onClick={() => {
            setOpenDelete(false);
          }}
          style={{ marginRight: '0.5rem' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={deleteModalLoading}
          onClick={handleConfirm}
          style={styles.removeButton}
        >
          Delete
        </Button>
      </div>
    );
  };

  const getDuplicateFooter = () => {
    return (
      <div>
        <Button
          onClick={() => {
            setOpenDuplicate(false);
          }}
          style={{ marginRight: '0.5rem' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={duplicateModalLoading}
          onClick={handleDuplicate}
          color="primary"
        >
          Duplicate
        </Button>
      </div>
    );
  };

  const deleteModalValue = getDeleteModalValue();
  const duplicateModalValue = getDuplicateModalValue();

  const getFooterWidth = () => {
    switch (true) {
      case input.workOrderId && input.expanded:
        return `calc(100% - ${layout.sideAndWorkPreviewWidth})`;
      case !!input.workOrderId:
        return `calc(100% - ${layout.workPreviewWidth})`;
      case input.expanded:
        return `calc(100% - ${layout.sidePaneWidth})`;
      default:
        return '100%';
    }
  };

  const getHeaderColumn = (column: WorkOrderColumn) => {
    const sortProps = {
      ...(column.sortable && {
        sortDirection: getSortDirection(
          getDataAccessByProperty(column.property),
        ),
      }),
      ...(column.sortable && {
        onSortChange: handleSortDirectionChange(
          getDataAccessByProperty(column.property),
        ),
      }),
    };

    const title = column.taskName
      ? `${column.label} (${column.taskName})`
      : column.label;

    return (
      <TableCell key={column?.label} {...sortProps} variant="head">
        <div styleName="column-header">
          {column.fieldId && column.taskId && (
            <Icon style={styles.icon}>playlist_add_check</Icon>
          )}
          {column.fieldId && !column.taskId && (
            <Icon style={styles.icon}>work</Icon>
          )}
          <div>{title}</div>
        </div>
      </TableCell>
    );
  };

  const showColumnSettings = hasRolePermissions(ROLE_SETS.ORG_ADMIN);

  const settingsIconStyles = {
    cursor: 'pointer',
    fontSize: '20px',
    paddingLeft: isNilOrEmpty(workOrders) ? '0' : '0.8rem',
  };

  return (
    <div styleName="table-container">
      <ListTable offsetTop="165px">
        <TableHead>
          <TableRow header>
            <TableCell
              variant="head"
              sortDirection={getSortDirection('name')}
              onSortChange={handleSortDirectionChange('name')}
            >
              Work ID
            </TableCell>
            {workOrderColumns.map(column => getHeaderColumn(column))}
            <TableCell variant="head" width="24px">
              {showColumnSettings && (
                <Icon
                  style={settingsIconStyles}
                  onClick={() => setIsColumnSettingsOpen(true)}
                >
                  settings
                </Icon>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workOrders.map(workOrder => (
            <WorkOrdersTableRow
              key={workOrder.id}
              workOrder={workOrder}
              loadingDuplicate={loadingDuplicate}
              onDuplicate={() => {
                setSelectedWorkOrder(workOrder);
                setOpenDuplicate(true);
              }}
              onDelete={() => {
                setSelectedWorkOrder(workOrder);
                setOpenDelete(true);
              }}
            />
          ))}
        </TableBody>
        <TableFooter width={getFooterWidth()}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              count={totalCount}
              rowsPerPage={input.limit}
              page={input.page}
              onPageChange={page => setInput({ ...input, page })}
              onRowsPerPageChange={event =>
                setInput({ ...input, limit: +event.target.value, page: 1 })
              }
            />
          </TableRow>
        </TableFooter>
      </ListTable>
      <Modal
        open={openDelete}
        title={deleteModalLoading ? '' : deleteModalValue.title}
        footer={deleteModalLoading ? '' : getDeleteFooter()}
      >
        {deleteModalLoading ? (
          <div styleName="loading-work-type">
            <Progress />
          </div>
        ) : (
          deleteModalValue.content
        )}
      </Modal>
      <Modal
        open={openDuplicate}
        title={duplicateModalLoading ? '' : duplicateModalValue.title}
        footer={duplicateModalLoading ? '' : getDuplicateFooter()}
      >
        {duplicateModalLoading ? (
          <div styleName="loading-work-type">
            <Progress />
          </div>
        ) : (
          duplicateModalValue.content
        )}
      </Modal>
      <WorkOrdersColumnSettingsModal
        open={isColumnSettingsOpen}
        onClose={() => setIsColumnSettingsOpen(false)}
      />
    </div>
  );
};

export default WorkOrdersTable;
