import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import { GET_MEDIA } from '@atom/graph/media';
import { MediaConnection, MediaItem } from '@atom/types/media';

import client from '../graph/client';

export interface MediaSearchOptions {
  // search query
  query: string;
  // type of media to search for
  type?: string;
  // array of parentSubjectIds to refine search by
  parentSubjectIds?: string[];
  // array of subjectTypes to refine search by
  subjectTypes?: string[];
  // number of milliseconds to debounce search at
  // defaults to 500
  debounceTime?: number;
  // character min before querying the API for results
  characterMin?: number;
  // results limit
  limit?: number;
}

export type Data = [
  MediaConnection,
  {
    loadingSearch: boolean;
    error: boolean;
  },
];

type DebouncedSearch = (
  query: string,
  media: MediaItem[],
  limit?: number,
  page?: number,
) => void;

export const useMediaSearch = ({
  query,
  limit,
  type,
  parentSubjectIds,
  subjectTypes,
  characterMin = 1,
  debounceTime = 500,
}: MediaSearchOptions): Data => {
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [connection, setConnection] = useState<MediaConnection>({
    totalCount: 0,
    media: [],
  });

  const searchMedia = async (
    search: string,
    media: MediaItem[],
    // eslint-disable-next-line @typescript-eslint/no-shadow
    limit?: number,
  ) => {
    setError(false);

    try {
      const { data } = await client.query<{
        media: MediaConnection;
      }>({
        query: GET_MEDIA,
        variables: {
          input: {
            name: search,
            page: 1,
            ...R.reject(R.isNil, {
              limit,
              type,
              parentSubjectIds,
              subjectTypes,
            }),
          },
        },
      });

      setConnection({
        totalCount: data.media.totalCount,
        media: [...media, ...data.media.media],
      });
    } catch (err) {
      setError(true);
    }

    setLoadingSearch(false);
  };

  const searchMediaDebounced = useCallback<DebouncedSearch>(
    debounce(searchMedia, debounceTime),
    [],
  );

  useEffect(() => {
    if (query.length >= characterMin) {
      setLoadingSearch(true);
      searchMediaDebounced(query, [], limit);
    } else {
      setConnection({ totalCount: 0, media: [] });
    }
  }, [query]);

  return [
    connection,
    {
      loadingSearch,
      error,
    },
  ];
};
