import React from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';

import './workTemplateWalkThrough.css';

const styles = {
  icon: {
    fontSize: '12px',
  },
  iconButton: {
    margin: '0 0.25rem',
  },
};

interface Props {
  page: number;
  pages: {
    title: string;
    content: string;
    imageUrl: string;
  }[];
  setPage: (page: number) => void;
}

const WalkThroughPage = ({ page, pages, setPage }: Props) => {
  const currentPage = pages[page];

  return (
    <div>
      <div styleName="page-title">{`${page + 1}. ${currentPage?.title}`}</div>
      <div styleName="page-content">{currentPage?.content}</div>
      <div styleName="page-image">
        <img src={currentPage?.imageUrl} />
      </div>
      <div styleName="page-navigation">
        {pages.map((_, index) => {
          const buttonColor =
            index > page ? colors.neutral.typhoon : colors.neutral.gray;

          return (
            <IconButton
              style={styles.iconButton}
              onClick={() => setPage(index)}
              size="small"
            >
              <Icon style={styles.icon} color={buttonColor}>
                lens
              </Icon>
            </IconButton>
          );
        })}
      </div>
    </div>
  );
};

export default WalkThroughPage;
