import * as R from 'ramda';

import colors from '@atom/styles/colors';
import { Task, TaskUser, TaskUserStatus } from '@atom/types/task';
import { UserDetail } from '@atom/types/user';
import { WorkOrderTask } from '@atom/types/work';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

export const getCurrentUserTaskStatusId = (
  task: Task | WorkOrderTask,
  currentUserId: string,
): TaskUserStatus | null => {
  if (isNilOrEmpty(task) || R.isEmpty(task.users)) {
    return null;
  }

  const user = R.find(R.propEq('id', currentUserId))(task.users);

  if (isNilOrEmpty(user)) {
    return null;
  }

  return user.status;
};

export const canUserCheckInToTask = (
  task: Task | WorkOrderTask,
  currentUserId: string,
): boolean => {
  const userTaskStatus = getCurrentUserTaskStatusId(task, currentUserId);
  return !userTaskStatus || userTaskStatus === TaskUserStatus.ASSIGNED;
};

export const getTaskUserStatusValues = (status: TaskUserStatus) => {
  if (isNilOrEmpty(status)) {
    return null;
  }

  const taskUserStatus = {
    [TaskUserStatus.ASSIGNED]: {
      text: 'Assigned',
      color: colors.status.assigned,
    },
    [TaskUserStatus.IN_PROGRESS]: {
      text: 'In Progress',
      color: colors.status.inprogress,
    },
    [TaskUserStatus.COMPLETED]: {
      text: 'Completed',
      color: colors.status.completed,
    },
  };

  return taskUserStatus[status];
};

export const sortByFullName = (current: TaskUser, next: TaskUser): number =>
  `${current.firstName} ${current.lastName}`.localeCompare(
    `${next.firstName} ${next.lastName}`,
  );

export const sortTaskUsersByName = (
  users: TaskUser[] | UserDetail[],
): TaskUser[] | UserDetail[] => {
  if (!users) {
    return [];
  }

  return R.sort(sortByFullName)(users);
};
