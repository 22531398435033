/* eslint-disable */
import * as R from 'ramda';
import axios from 'axios';
import auth0 from 'auth0-js';

import { put } from 'redux-saga/effects';
import jwt from 'jwt-decode';
import Cookies from 'js-cookie';

import * as userActions from '@atom/actions/userActions';
import { ATOM_USER_HEADER } from './requestUtilities';
import { CURRENT_SUBDOMAIN, CALLBACK_ENDPOINT } from './endpoints';

const getAuth0WebAuth = (): any => {
  return new auth0.WebAuth({
    domain: 'atomapp.auth0.com',
    clientID: 'FSRoJcghW3oUI6iyqno670fkUc2T4DdW',
    audience: 'https://atomapp.auth0.com/userinfo',
    scope: 'openid profile email app_metadata user_metadata',
    responseType: 'token id_token',
    redirectUri: CALLBACK_ENDPOINT,
  });
};

export const getToken = (): any => {
  return Cookies.get('access_token');
};

const individualAccountLogin = async (
  email: string,
  password: string,
): Promise<any> => {
  return new Promise(
    (resolve: (data: any) => void, reject: (data: any) => void) => {
      try {
        const webAuth = getAuth0WebAuth();

        webAuth.client.login(
          {
            realm: 'Username-Password-Authentication',
            scope: 'openid profile email app_metadata user_metadata',
            username: email,
            password,
          },
          (err: any, result: any) => {
            if (err) {
              reject(err);
            }

            resolve(result);
          },
        );
      } catch (error) {
        reject(error);
      }
    },
  );
};

export async function getAccountPayload(): Promise<any> {
  return new Promise(
    (resolve: (data: any) => void, reject: (data: any) => void) => {
      try {
        const accessToken = getToken();
        const token = jwt(accessToken);
        // @ts-ignore
        axios.defaults.headers[ATOM_USER_HEADER] = token.email;

        resolve(token);
      } catch (error) {
        reject(error);
      }
    },
  );
}

export const getAccountPayloadSync = (): any => {
  const accessToken = getToken();
  const token = jwt(accessToken);
  return token;
};

export const updateRequestHeaders = async (): Promise<any> => {
  const token = await getAccountPayload();
  axios.defaults.headers[ATOM_USER_HEADER] = token.email;
};

export const persistAccountPayload = async (
  tokenString: string,
): Promise<any> => {
  return new Promise(
    (resolve: (data?: any) => void, reject: (data: any) => void) => {
      try {
        Cookies.set('access_token', tokenString);
        resolve();
      } catch (error) {
        reject(error);
      }
    },
  );
};

export const getUserId = async () => {
  const accountPayload = await getAccountPayload();

  if (R.isNil(accountPayload.organizations)) {
    return null;
  }
  const organization = accountPayload.organizations.find(
    (org: any): boolean => org.name === CURRENT_SUBDOMAIN,
  );

  if (!organization) {
    return null;
  }

  return organization.id;
};

export const getUserIdSync = () => {
  const accountPayload = getAccountPayloadSync();

  if (R.isNil(accountPayload.organizations)) {
    return null;
  }
  const organization = accountPayload.organizations.find(
    (org: any): boolean => org.name === CURRENT_SUBDOMAIN,
  );

  if (!organization) {
    return null;
  }

  return organization.id;
};

export const getLoggedInEmail = (): any => {
  const accessToken = getToken();
  if (!accessToken) {
    return null;
  }
  const token = jwt(accessToken);

  if (R.isNil(token)) {
    return null;
  }

  // @ts-ignore
  return token.email;
};

export const tokenExists = (): boolean => {
  const accessToken = getToken();
  return !!accessToken;
};

export const isEmailVerified = (): any => {
  const accessToken = getToken();
  const token = jwt(accessToken);

  // @ts-ignore
  return token.emailVerified;
};

export const logoutUser = (): Promise<any> => {
  return new Promise(
    (resolve: (data?: any) => void, reject: (data: any) => void) => {
      try {
        const allCookies = Cookies.get();

        // @ts-ignore
        Object.keys(allCookies).map(Cookies.remove);
        resolve();
      } catch (error) {
        reject(error);
      }
    },
  );
};

const isValidEmail = (email: string): boolean => {
  const regex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

  return regex.test(email);
};

export function* apiErrorHandler(error: any, actionCreator: any): any {
  if (error.response && error.response.status === 401) {
    yield put(userActions.triggerLogout());
  } else {
    yield put(actionCreator());
  }
}

const accountUtilities = {
  apiErrorHandler,
  getAccountPayload,
  getAuth0WebAuth,
  getLoggedInEmail,
  getToken,
  getUserId,
  individualAccountLogin,
  isValidEmail,
  isEmailVerified,
  logoutUser,
  tokenExists,
};

export default accountUtilities;
