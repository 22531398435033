import React, { useState } from 'react';

import { Icon, IconButton } from '@atom/mui';
import { ElementGroupsTreeItem } from '@atom/types/inventory';
import { WorkOrderAssetTreeElement } from '@atom/types/work';

import AddElementRow from './AddElementRow';

import './addElementModal.css';

const PADDING_OFFSET = 2;

interface Props {
  elementGroup: ElementGroupsTreeItem;
  indentation: number;
  toggleElement: (id: string) => void;
  updateQuantity: (id: string, quantity: number) => void;
}

const AddElementGroupRow = ({
  elementGroup,
  indentation,
  toggleElement,
  updateQuantity,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const style = {
    paddingLeft: `calc(1rem + ${PADDING_OFFSET * indentation}rem)`,
  };
  const arrowDirection = expanded ? 'arrow_drop_down' : 'arrow_right';

  return (
    <>
      <div styleName="group-row" style={style}>
        <IconButton onClick={() => setExpanded(!expanded)}>
          <Icon>{arrowDirection}</Icon>
        </IconButton>
        <div styleName="row-name group">{elementGroup.name}</div>
      </div>
      {expanded &&
        elementGroup.elements.map((elem: WorkOrderAssetTreeElement) => {
          return (
            <AddElementRow
              key={elem.id}
              element={elem}
              indentation={indentation + 1}
              toggleElement={toggleElement}
              updateQuantity={updateQuantity}
            />
          );
        })}
    </>
  );
};

export default AddElementGroupRow;
