import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function authReducer(state = initialState.auth, action) {
  switch (action.type) {
    case actionTypes.LOGGED_IN_USER_GET_FAILURE:
    case actionTypes.LOGOUT_SUCCESS: {
      return initialState.auth;
    }
    case actionTypes.LOGGED_IN_USER_GET_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
        isVerified: action.data.isVerified,
      };
    }
    case actionTypes.LOGGED_IN_USER_FORBIDDEN: {
      return {
        ...state,
        isLoggedIn: true,
      };
    }
    default: {
      return state;
    }
  }
}
