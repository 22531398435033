import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';

import UserGroupIcon from '@atom/components/common/UserGroupIcon';
import {
  TASK_USER_GROUP_REMOVE,
  TASK_USER_GROUP_UPDATE,
} from '@atom/graph/task';
import { GET_USER_GROUP } from '@atom/graph/userGroup';
import { Button, Icon, IconButton, Modal, Select, TextField } from '@atom/mui';
import {
  Task,
  TaskUserGroupRemoveInput,
  TaskUserGroupUpdateInput,
} from '@atom/types/task';
import { UserGroup } from '@atom/types/userGroups';
import { WorkOrderDetailType } from '@atom/types/work';
import {
  doesNotHaveRolePermissions,
  hasRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import {
  convertHoursToMillis,
  convertMillisToExactHours,
} from '@atom/utilities/timeUtilities';

import './editTaskUserGroupModal.css';

const MenuItem = Select.MenuItem;

const styles = {
  textFieldStyle: {
    width: '10rem',
    marginLeft: '1rem',
  },
  selectStyle: {
    width: '75%',
    marginLeft: '2rem',
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
  userGroupId: string;
  quantity: number;
  selectedBudgetId: string;
  workTime: number;
  task: Task;
  workOrderDetail: WorkOrderDetailType;
  refetch: () => void;
}

const EditTaskUserGroupModal = (props: Props) => {
  const [hours, setHours] = useState<number>(() =>
    convertMillisToExactHours(props.workTime),
  );
  const [quantity, setQuantity] = useState<number>(props.quantity || 0);
  const [selectedBudgetId, setSelectedBudgetId] = useState<string>(
    props.selectedBudgetId || '',
  );

  const [getUserGroup, { data, error, loading }] = useLazyQuery<{
    userGroup: UserGroup;
  }>(GET_USER_GROUP, {
    fetchPolicy: 'network-only',
  });

  const [taskUserGroupUpdate] = useMutation<
    { taskUserGroupUpdate: Task },
    { input: TaskUserGroupUpdateInput }
  >(TASK_USER_GROUP_UPDATE, {
    onCompleted: () => {
      props.refetch();
    },
  });

  const [taskUserGroupRemove] = useMutation<
    { taskUserGroupRemove: Task },
    { input: TaskUserGroupRemoveInput }
  >(TASK_USER_GROUP_REMOVE, {
    onCompleted: () => {
      props.refetch();
    },
  });

  useEffect(() => {
    if (props.open) {
      getUserGroup({
        variables: {
          id: props.userGroupId,
          input: {
            includeRestoredBudgets:
              props.workOrderDetail.reopened && !props.workOrderDetail.isClosed,
          },
        },
      });
    }
  }, [props.open, getUserGroup]);

  useEffect(() => {
    setHours(convertMillisToExactHours(props.workTime));
    setQuantity(props.quantity || 0);
    setSelectedBudgetId(props.selectedBudgetId || '');
  }, [props.open]);

  const confirm = async () => {
    await taskUserGroupUpdate({
      variables: {
        input: {
          id: props.task.id,
          workOrderId: props.workOrderDetail.id,
          userGroupId: props.userGroupId,
          quantity,
          workTime: convertHoursToMillis(hours),
          budgetId: selectedBudgetId,
        },
      },
    });

    props.refetch();
    props.onClose();
  };

  const removeUserGroup = async () => {
    await taskUserGroupRemove({
      variables: {
        input: {
          id: props.task.id,
          userGroupId: props.userGroupId,
          workOrderId: props.workOrderDetail.id,
        },
      },
    });

    props.refetch();
    props.onClose();
  };

  const userGroup = data?.userGroup;
  const isLoading = loading || error || R.isNil(userGroup);
  const fullGroupPath = [
    'Directory',
    ...R.pathOr([], ['groupPath'], userGroup),
  ];

  const modalTitle = (
    <div styleName="header-container">
      <div styleName="header-left">
        {isLoading ? (
          <div />
        ) : (
          <>
            <div>
              <UserGroupIcon colorId={userGroup.colorId} />
            </div>
            <div styleName="title-container">
              <div styleName="header-title">{userGroup.name}</div>
              <div styleName="header-path">{fullGroupPath.join(' / ')}</div>
            </div>
          </>
        )}
      </div>
      <Link to={`/team/group/${props.userGroupId}`}>
        <IconButton>
          <Icon>launch</Icon>
        </IconButton>
      </Link>
    </div>
  );

  const handleQuantity = event => {
    if (event.target.value >= 0) {
      setQuantity(event.target.value);
    }
  };

  const handleHours = event => {
    if (event.target.value >= 0) {
      setHours(event.target.value);
    }
  };

  const budgetOptions = useMemo(() => {
    const budgets = R.pathOr([], ['budgets'], userGroup).filter(
      budget =>
        !budget.restored ||
        (budget.restored &&
          budget.reopenedWorkOrderIds.includes(props.workOrderDetail.id)),
    );

    return [{ id: '', name: '', rate: 0 }, ...budgets];
  }, [userGroup, props.workOrderDetail]);

  const renderFooter = (
    <div styleName="modal-footer">
      <div>
        {hasRolePermissions(ROLE_SETS.MANAGER) && (
          <Button onClick={removeUserGroup} disabled={!!isLoading}>
            Remove From Task
          </Button>
        )}
      </div>
      <div>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={confirm}
          disabled={!!isLoading}
        >
          Save
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      title={modalTitle}
      open={props.open}
      footer={renderFooter}
      loading={loading}
    >
      <>
        <div styleName="modal-row">
          <Icon>group</Icon>
          <TextField
            type="number"
            value={quantity}
            style={styles.textFieldStyle}
            label="Number of People"
            onChange={event => handleQuantity(event)}
            disabled={doesNotHaveRolePermissions(ROLE_SETS.MANAGER)}
          />
        </div>
        <div styleName="modal-row">
          <Icon>schedule</Icon>
          <TextField
            type="number"
            value={hours}
            style={styles.textFieldStyle}
            label="Work Time per Person"
            onChange={event => handleHours(event)}
          />
          <div styleName="work-time-label">Hour(s)</div>
        </div>
        <div styleName="modal-row">
          <Icon>monetization_on</Icon>
          <Select
            value={selectedBudgetId}
            onChange={event => setSelectedBudgetId(event.target.value)}
            label="Budget"
            style={styles.selectStyle}
          >
            {budgetOptions.map(option => {
              const budgetData = R.pathOr([], ['budgets'], userGroup).find(
                item => item.id === option.id,
              );

              const primaryText = option.id ? (
                <>
                  <span>
                    {`${budgetData.name}: ${numberToLocaleString(
                      budgetData.rate,
                    )}/Hours `}
                  </span>
                  {option.restored && (
                    <span styleName="snapshot-suffix">(snapshot)</span>
                  )}
                </>
              ) : (
                'No Budget'
              );

              return (
                <MenuItem key={option.id} value={option.id}>
                  {primaryText}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </>
    </Modal>
  );
};

export default EditTaskUserGroupModal;
