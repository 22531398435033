import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import { TASK_ASSETS_ADD } from '@atom/graph/task';
import { Checkbox, Collapse, Icon, List, Modal, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  ElementGroupsTreeItem,
  InventoryAssetTreeStateType,
} from '@atom/types/inventory';
import { Task, TaskAssetsAddInput } from '@atom/types/task';
import {
  WorkOrderAssetTreeElement,
  WorkOrderAssetTreeType,
  WorkOrderDetailType,
} from '@atom/types/work';
import api from '@atom/utilities/api';
import {
  INVENTORY_ASSETS_ENDPOINT,
  WORK_ORDERS_ENDPOINT,
} from '@atom/utilities/endpoints';
import iconUtilities from '@atom/utilities/iconUtilities';
import schemaUtilities from '@atom/utilities/schemaUtilities';
import { toggleFromSet } from '@atom/utilities/setUtilities';

const { ListItem, ListItemText } = List;

type Tree =
  | InventoryAssetTreeStateType
  | ElementGroupsTreeItem
  | WorkOrderAssetTreeType
  | WorkOrderAssetTreeElement;

interface Props {
  open: boolean;
  onClose: () => void;
  workOrderDetail: WorkOrderDetailType;
  task: Task;
  refetch: () => void;
}

const styles = {
  modal: {
    height: '50vh',
    padding: 0,
  },
  progress: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toggle: {
    cursor: 'pointer',
    marginRight: '0.5rem',
  },
  icon: {
    marginRight: '0.5rem',
  },
};

const getListItemStyle = (hasChildren: boolean, level: number) =>
  hasChildren
    ? { paddingLeft: `${level}rem`, paddingRight: '1rem' }
    : { paddingLeft: `${level + 2}rem`, paddingRight: '1rem' };

const isAsset = (tree: Tree): tree is InventoryAssetTreeStateType => {
  return tree.hasOwnProperty('assetType');
};

const AddWorkAssetsModal = (props: Props) => {
  const { onClose, workOrderDetail, task, refetch } = props;
  const { inventoryAssetId } = props.workOrderDetail;

  const [rootTree, setRootTree] = useState<
    InventoryAssetTreeStateType | WorkOrderAssetTreeType
  >({});

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<Set<string>>(new Set());
  const [selectedAssetIds, setSelectedAssetIds] = useState<Set<string>>(
    new Set(),
  );

  const [addAsset, { loading: loadingAdd }] = useMutation<
    { taskAssetsAdd: string[] },
    { input: TaskAssetsAddInput }
  >(TASK_ASSETS_ADD);

  const resetState = () => {
    setOpen(new Set([inventoryAssetId]));
    setSelectedAssetIds(new Set());
  };

  useEffect(() => {
    const getInventoryAssetTree = async () => {
      setLoading(true);
      setOpen(new Set([inventoryAssetId]));

      // If the workOrder has been reopened, asset data should be pulled from
      // the work asset. If the work has not been reopened, it should
      // pull from the inventory asset.
      const inventoryAssetEndpoint = `${INVENTORY_ASSETS_ENDPOINT}/${inventoryAssetId}/tree`;
      const workOrderAssetEndpoint = `${WORK_ORDERS_ENDPOINT}/${workOrderDetail.id}/assets/${inventoryAssetId}/tree`;

      const { data } = await api.get<
        InventoryAssetTreeStateType | WorkOrderAssetTreeType
      >(
        workOrderDetail.reopened
          ? workOrderAssetEndpoint
          : inventoryAssetEndpoint,
      );

      setRootTree(data);
      setLoading(false);
    };

    if (props.open && !loading && rootTree.id !== inventoryAssetId) {
      getInventoryAssetTree();
    }
  }, [props.open, inventoryAssetId]);

  const disabledAssetIds = useMemo(() => {
    return new Set([...task.assetIds]);
  }, [task]);

  const handleToggle = (id: string) => {
    setOpen(toggleFromSet(open, id));
  };

  const handleCheck = (event: React.MouseEvent<any>, id: string) => {
    event.stopPropagation();
    setSelectedAssetIds(toggleFromSet(selectedAssetIds, id));
  };

  const handleSubmit = async () => {
    if (selectedAssetIds.size) {
      await addAsset({
        variables: {
          input: {
            workOrderId: workOrderDetail.id,
            taskId: task.id,
            assetIds: [...selectedAssetIds],
          },
        },
      });

      refetch();
    }

    onClose();
  };

  const renderList = (trees: Tree[], level: number) => {
    return trees.map((tree, index) => {
      const hasChildren = isAsset(tree)
        ? !!tree.elements?.length || !!tree.elementGroups?.length
        : !!tree.elements?.length;

      return (
        <React.Fragment key={index}>
          <ListItem
            disableGutters
            style={getListItemStyle(hasChildren, level)}
            onClick={() => (hasChildren ? handleToggle(tree.id) : () => {})}
          >
            {hasChildren && (
              <Icon style={styles.toggle}>
                {open.has(tree.id) ? 'arrow_drop_down' : 'arrow_right'}
              </Icon>
            )}
            {isAsset(tree) && (
              <Checkbox
                checked={
                  selectedAssetIds.has(tree.id) || disabledAssetIds.has(tree.id)
                }
                disabled={disabledAssetIds.has(tree.id)}
                onClick={event => handleCheck(event, tree.id)}
              />
            )}
            {!isAsset(tree) ? null : tree.id === inventoryAssetId ? (
              <img
                style={styles.icon}
                src={schemaUtilities.getSchemaIconFromMarkerId(tree.markerId)}
              />
            ) : (
              <Icon style={styles.icon} color={colors.brand.blue}>
                {iconUtilities.getDataManagementElementIcon(
                  tree.markerId,
                  true,
                )}
              </Icon>
            )}
            <ListItemText
              primary={tree.name}
              secondary={(isAsset(tree) && tree.assetType) || ''}
            />
          </ListItem>
          {hasChildren && (
            <Collapse in={open.has(tree.id)} timeout="auto" unmountOnExit>
              {renderList(R.pathOr([], ['elements'], tree), level + 1)}
              {renderList(R.pathOr([], ['elementGroups'], tree), level + 1)}
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <Modal
      title="Add Assets"
      open={props.open}
      onCancel={onClose}
      onConfirm={handleSubmit}
      contentStyle={styles.modal}
      loading={loadingAdd}
      onExited={resetState}
      data-cy="addWorkInventoryModal"
    >
      {loading || !rootTree ? (
        <div style={styles.progress}>
          <Progress />
        </div>
      ) : (
        <>{renderList([rootTree], 1)}</>
      )}
    </Modal>
  );
};

export default AddWorkAssetsModal;
