import React from 'react';

import { TextField } from '@atom/mui';
import { DataType } from '@atom/types/dataType';

interface Props {
  id: string;
  dataType: DataType;
  value: any;
  onChange: (value: any) => void;
  disabled: boolean;
  error: boolean;
  dataCyPrefix: string;
}

const TextInput = ({
  id,
  value,
  onChange,
  dataType,
  disabled,
  error,
  dataCyPrefix,
}: Props) => {
  return (
    <TextField
      key={id}
      value={value || ''}
      id="text"
      onChange={event => onChange(event?.target?.value)}
      variant="standard"
      multiline={dataType === DataType.LONG_TEXT}
      disabled={disabled}
      error={error}
      data-cy={`${dataCyPrefix}TextInput`}
    />
  );
};

export default TextInput;
