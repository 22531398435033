import React from 'react';

import Logo from '@atom/components/common/navigation/Logo';
import { Button, Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import history from '@atom/utilities/history';

import './errorPages.css';

const styles = {
  icon: {
    fontSize: '75px',
  },
  button: {
    marginTop: '2rem',
  },
};

const NoPermission = () => {
  return (
    <div styleName="page">
      <div styleName="header">
        <Logo pathname={location.pathname} />
      </div>
      <div styleName="content-container">
        <div styleName="content">
          <Icon style={styles.icon} color={colors.neutral.silver}>
            lock
          </Icon>
          <div styleName="title">No Permission</div>
          <div styleName="subtext">
            You don't have permission to view this page.
          </div>
          <div styleName="subtext">
            Please contact your Organization Admin to obtain access.
          </div>
          <Button
            variant="contained"
            color="primary"
            style={styles.button}
            onClick={history.goBack}
          >
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NoPermission;
