import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { QueryParamProvider } from 'use-query-params';

import Application from '@atom/components/Application';
import { Snackbar } from '@atom/mui';

const initializeApplication = async (store: any, history: any, client: any) => {
  render(
    <Provider store={store}>
      <Router history={history}>
        <QueryParamProvider
          ReactRouterRoute={Route}
          stringifyOptions={{ encode: false }}
        >
          <ApolloProvider client={client}>
            <Snackbar.Provider />
            <Application />
          </ApolloProvider>
        </QueryParamProvider>
      </Router>
    </Provider>,
    document.getElementById('application'),
  );
};

const Root = {
  initializeApplication,
};

export default Root;
