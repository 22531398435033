import * as R from 'ramda';

import { InventoryAssetTreeStateType } from '@atom/types/inventory';

export const formatAssetAttributeFilters = (filters?: object): object => {
  if (!filters) {
    return {};
  }

  return Object.entries(filters).reduce((accumulator, [id, value]) => {
    const key = `attributes.${id}`;

    if (!Array.isArray(value) && R.is(Object, value)) {
      const from = value.from ? Number(value.from) : null;
      const to = value.to ? Number(value.to) : null;
      const valueArray = [from, to];

      if (!from && !to) {
        return accumulator;
      }

      return {
        ...accumulator,
        [key]: valueArray,
      };
    }

    return R.isEmpty(value) ? accumulator : { ...accumulator, [key]: value };
  }, {});
};

export const assetTreeHasDownstreamChanges = (
  tree: InventoryAssetTreeStateType,
  isRoot: boolean = true,
) => {
  if (!!tree.changeType && !isRoot) {
    return true;
  }

  if (
    tree.elements &&
    tree.elements.length &&
    tree.elements.some(element => assetTreeHasDownstreamChanges(element, false))
  ) {
    return true;
  }

  if (
    tree.elementGroups &&
    tree.elementGroups.length &&
    tree.elementGroups.some(group =>
      assetTreeHasDownstreamChanges(group, false),
    )
  ) {
    return true;
  }

  return false;
};
