import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function mediaTotalsReducer(
  state = initialState.mediaTotals,
  action,
) {
  const { data } = action;
  switch (action.type) {
    case actionTypes.GET_MEDIA_TOTALS_SUCCESS: {
      return data;
    }
    default: {
      return state;
    }
  }
}
