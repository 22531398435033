import React, { useContext } from 'react';
import * as R from 'ramda';

import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import {
  getInheritedTypeTitle,
  taskInheritedTypes,
  workInheritedTypes,
} from '@atom/components/workTemplate/WorkTemplateInheritedTypes';
import { Checkbox } from '@atom/mui';
import { InheritedComponentType } from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import { WorkTypeVariant } from '@atom/utilities/workTemplateUtilities';

import './workTemplateChildCreate.css';

interface Props {
  additionalInheritedComponents: Set<InheritedComponentType>;
  setAdditionalInheritedComponents: (
    additionalInheritedComponents: Set<InheritedComponentType>,
  ) => void;
  isChild?: boolean;
}

const ChildCreateInheritance = ({
  additionalInheritedComponents,
  setAdditionalInheritedComponents,
  isChild = false,
}: Props) => {
  const { workTemplate, workTypeVariant } = useContext(WorkTemplateContext);
  const requiredInheritedComponents = new Set(
    workTemplate?.requiredInheritedComponents || [],
  );

  const extraInheritedComponents = R.dropWhile(
    item => requiredInheritedComponents.has(item),
    [...workInheritedTypes, ...taskInheritedTypes],
  );

  const disableSelect =
    workTypeVariant === WorkTypeVariant.PARENT
      ? doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN)
      : workTemplate?.published ||
        doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  const disableEnableAll =
    additionalInheritedComponents.size ===
      new Set(extraInheritedComponents).size || disableSelect;
  const disableDisableAll =
    additionalInheritedComponents.size === 0 || disableSelect;

  const enableStyle = `settings-button left ${
    disableEnableAll ? '' : 'enabled'
  }`;
  const disableStyle = `settings-button ${disableDisableAll ? '' : 'enabled'}`;

  const onClickEnableAll = () => {
    setAdditionalInheritedComponents(new Set([...extraInheritedComponents]));
  };

  const onClickDisableAll = () => {
    setAdditionalInheritedComponents(new Set([]));
  };

  const onToggle = (type: InheritedComponentType) => {
    setAdditionalInheritedComponents(
      toggleFromSet(additionalInheritedComponents, type),
    );
  };

  return (
    <div styleName="create-container">
      <div>
        <div styleName="settings-content-row">
          Select sections which contain the content that you want to inherit
          from this parent work template. When a section is selected, the
          content from that section will appear on the child work template and
          it will not be editable.
        </div>
        <div styleName="settings-content-row">
          Some sections may be selected by default. See the help icon for more.
        </div>
        {isChild && (
          <div styleName="settings-content-row italics">
            * Child Work Template Settings can only be changed when the Child
            Work Template is in Draft status.
          </div>
        )}
      </div>
      <div>
        <div styleName="selection-title">Selection Sections:</div>
        <div styleName="settings-buttons-container">
          <div
            styleName={enableStyle}
            onClick={disableEnableAll ? () => {} : onClickEnableAll}
          >
            Select All
          </div>
          <div
            styleName={disableStyle}
            onClick={disableDisableAll ? () => {} : onClickDisableAll}
          >
            Clear All
          </div>
        </div>
      </div>
      <div styleName="selection-container">
        <div styleName="section-title">Work</div>
        {workInheritedTypes.map(type => {
          return (
            <Checkbox
              key={type}
              checked={
                requiredInheritedComponents.has(type) ||
                additionalInheritedComponents.has(type)
              }
              disabled={requiredInheritedComponents.has(type) || disableSelect}
              onChange={() => onToggle(type)}
              label={getInheritedTypeTitle(type)}
            />
          );
        })}
      </div>
      <div styleName="selection-container">
        <div styleName="section-title">Task</div>
        {taskInheritedTypes.map(type => {
          return (
            <Checkbox
              key={type}
              checked={
                requiredInheritedComponents.has(type) ||
                additionalInheritedComponents.has(type)
              }
              disabled={requiredInheritedComponents.has(type) || disableSelect}
              onChange={() => onToggle(type)}
              label={getInheritedTypeTitle(type)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ChildCreateInheritance;
