import React, { useContext } from 'react';
import * as R from 'ramda';

import WorkOrderAssetView from '@atom/components/common/workOrderDetail/workOrderAssetView/WorkOrderAssetView';
import WorkOrderContext from '@atom/components/workOrderDetail/WorkOrderContext';

import TaskDetailView from './taskDetailView/TaskDetailView';
import TaskList from './TaskList';

import './workOrderTasks.css';

const WorkOrderTasks = () => {
  const {
    workOrderDetail,
    workOrderAsset,
    task,
    setActiveWorkOrderAsset,
    refetchWorkOrderAssetTreeLoading,
    refetchWorkOrderAssetTree,
    refetch,
  } = useContext(WorkOrderContext);

  const isTaskView = R.isEmpty(workOrderAsset) || R.isNil(workOrderAsset);
  const displayTaskDetail = !R.isNil(task) && !R.isEmpty(task);

  return (
    <div styleName="tasks-container">
      {isTaskView ? (
        <>
          <div styleName="pane left">
            <TaskList />
          </div>
          <div styleName="pane right">
            {displayTaskDetail && <TaskDetailView />}
          </div>
        </>
      ) : (
        <WorkOrderAssetView
          workOrderDetail={workOrderDetail}
          workOrderAsset={workOrderAsset}
          setActiveWorkOrderAsset={setActiveWorkOrderAsset}
          refetchWorkOrderAssetTreeLoading={refetchWorkOrderAssetTreeLoading}
          refetchWorkOrderAssetTree={refetchWorkOrderAssetTree}
          refetch={refetch}
          taskId={task.id}
        />
      )}
    </div>
  );
};

export default WorkOrderTasks;
