import actionTypes from './actionTypes';

export const requestUserOrganizations = () => ({
  type: actionTypes.REQUEST_USER_ORGANIZATIONS,
});

export const getUserOrganizationsSuccess = data => ({
  type: actionTypes.USER_ORGANIZATIONS_GET_SUCCESS,
  data,
});

export const getUserOrganizationsFailure = () => ({
  type: actionTypes.USER_ORGANIZATIONS_GET_FAILURE,
});

export const requestTenantCreation = data => ({
  type: actionTypes.REQUEST_TENANT_CREATION,
  data,
});

export const createTenantSuccess = (data?: object) => ({
  type: actionTypes.CREATE_TENANT_SUCCESS,
  data,
});

export const createTenantFailure = () => ({
  type: actionTypes.CREATE_TENANT_FAILURE,
});
