import React, { useEffect, useReducer, useState } from 'react';
import { match as Match } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import WorkTemplateContext, {
  reducer,
  WorkTemplateActionTypes,
  WorkTemplateView,
} from '@atom/components/workTemplate/WorkTemplateContext';
import { GET_WORK_ORDER_TEMPLATE } from '@atom/graph/workTemplate';
import { Progress } from '@atom/mui';
import { WorkOrderType } from '@atom/types/work';
import {
  WorkTemplate as WorkTemplateType,
  WorkTemplateTaskItem,
} from '@atom/types/workTemplate';
import {
  getWorkTypeVariant,
  WorkTypeVariant,
} from '@atom/utilities/workTemplateUtilities';

import WorkTemplateChildTypes from './workTemplateChildTypes/WorkTemplateChildTypes';
import WorkTemplateDocuments from './workTemplateDocuments/WorkTemplateDocuments';
import WorkTemplateHeader from './workTemplateHeader/WorkTemplateHeader';
import WorkTemplateInfo from './workTemplateInfo/WorkTemplateInfo';
import WorkTemplatePhotos from './workTemplatePhotos/WorkTemplatePhotos';
import WorkTemplateTasks from './workTemplateTasks/WorkTemplateTasks';
import WorkTemplateTeam from './workTemplateTeam/WorkTemplateTeam';
import WorkTemplateVideos from './workTemplateVideos/WorkTemplateVideos';
import WorkTemplateWalkThrough from './workTemplateWalkThrough/WorkTemplateWalkThrough';

import './workTemplate.css';

interface Props {
  match: Match<{ id: string }>;
}

const WorkTemplate = ({ match }: Props) => {
  const [workTemplate, dispatch] = useReducer(reducer, {} as WorkTemplateType);

  const [activeView, setActiveView] = useState<WorkTemplateView>(
    WorkTemplateView.INFO,
  );
  const [activeTask, setActiveTask] = useState<string>('');
  // @ts-ignore
  const [task, setTask] = useState<WorkTemplateTaskItem>({});
  const [open, setOpen] = useState<boolean>(false);
  const [workTypeVariant, setWorkTypeVariant] = useState<WorkTypeVariant>();

  const onCompleted = data => {
    const workOrderTemplate = {
      ...data?.workOrderTemplate,
      type: R.pathOr(
        WorkOrderType.DEFAULT,
        ['workOrderTemplate', 'type'],
        data,
      ),
    };

    dispatch({
      type: WorkTemplateActionTypes.SET_WORK_TEMPLATE,
      data: workOrderTemplate,
    });

    setActiveTask(
      !R.isEmpty(activeTask)
        ? activeTask
        : R.isEmpty(workOrderTemplate?.tasks)
        ? ''
        : workOrderTemplate?.tasks[0].id,
    );

    setTask(R.isEmpty(task) ? workOrderTemplate?.tasks[0] : task);
  };

  const { loading, error, refetch } = useQuery<
    { workOrderTemplate: WorkTemplateType },
    { id: string }
  >(GET_WORK_ORDER_TEMPLATE, {
    variables: {
      id: match.params.id,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted,
  });

  useEffect(() => {
    const workTypeVariantData = getWorkTypeVariant(
      workTemplate.isParent,
      workTemplate.parentId,
    );

    setWorkTypeVariant(workTypeVariantData);
    setOpen(workTypeVariantData === WorkTypeVariant.PARENT);
  }, [workTemplate?.isParent, workTemplate?.parentId]);

  useEffect(() => {
    if (!R.isEmpty(activeTask) || !R.isEmpty(workTemplate)) {
      setTask(R.find(R.propEq('id', activeTask))(workTemplate.tasks));
    }
  }, [activeTask, workTemplate]);

  useEffect(() => {
    const workTypeVariantData = getWorkTypeVariant(
      workTemplate.isParent,
      workTemplate.parentId,
    );

    setWorkTypeVariant(workTypeVariantData);
    setOpen(workTypeVariantData === WorkTypeVariant.PARENT);
  }, [workTemplate?.isParent, workTemplate?.parentId]);

  const workTemplateLoading = error || !workTemplate.id;

  const getActiveTab = () => {
    const tabs = {
      [WorkTemplateView.INFO]: <WorkTemplateInfo />,
      [WorkTemplateView.TASKS]: <WorkTemplateTasks />,
      [WorkTemplateView.TEAM]: <WorkTemplateTeam />,
      [WorkTemplateView.DOCUMENTS]: <WorkTemplateDocuments />,
      [WorkTemplateView.PHOTOS]: <WorkTemplatePhotos />,
      [WorkTemplateView.VIDEOS]: <WorkTemplateVideos />,
      [WorkTemplateView.CHILD_TYPES]: <WorkTemplateChildTypes />,
    };

    return tabs[activeView];
  };

  return (
    <WorkTemplateContext.Provider
      value={{
        workTemplate,
        workTypeVariant,
        task,
        setActiveTask,
        dispatch,
        loading,
        activeView,
        setActiveView,
        refetch,
      }}
    >
      <div>
        {workTemplateLoading ? (
          <div>
            <Progress />
          </div>
        ) : (
          <>
            <WorkTemplateHeader />
            <div styleName="tab">{getActiveTab()}</div>
          </>
        )}
        <WorkTemplateWalkThrough open={open} onClose={() => setOpen(false)} />
      </div>
    </WorkTemplateContext.Provider>
  );
};

export default WorkTemplate;
