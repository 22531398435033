import React from 'react';

import { AttributeGroupItem } from '@atom/types/form';
import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';

import '../workOrderDetailPdfReport.css';

interface Props {
  header: string;
  formAttributeGroups: AttributeGroupItem[];
}

const FormAttributesTextbox = ({ header, formAttributeGroups }: Props) => {
  const renderAttributeRow = () => {
    return formAttributeGroups.map(group => {
      if (group.name === header) {
        return group.attributes.map(attribute => {
          return (
            <tr key={attribute.id} styleName="textbox-table-row">
              <td
                styleName="textbox-table-row-title"
                style={{ width: '15rem' }}
              >
                {attribute.name}:
              </td>
              <td styleName="value">
                {attributeDisplayUtility.display(
                  attribute.value,
                  attribute.dataType,
                  attribute.unit,
                )}
              </td>
            </tr>
          );
        });
      }
      return <></>;
    });
  };

  return (
    <table styleName="collapse-border">
      <thead>
        <tr>
          <th styleName="textbox-table-title" colSpan={2}>
            {header}
          </th>
        </tr>
      </thead>
      <tbody styleName="content">{renderAttributeRow()}</tbody>
    </table>
  );
};

export default FormAttributesTextbox;
