import React from 'react';

import './analyticsDetail.css';

interface Props {
  url: any;
  tileSize: string;
}

const AnalyticsTile = ({ url, tileSize }: Props) => {
  return (
    <div styleName={`analytics-tile-container ${tileSize}`}>
      <iframe
        width="100%"
        height="100%"
        src={url}
        frameBorder="0"
        style={{ border: 0, overflow: 'hidden' }}
        allowFullScreen
      />
    </div>
  );
};

export default AnalyticsTile;
