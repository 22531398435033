import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function usersReducer(state = initialState.users, action) {
  switch (action.type) {
    case actionTypes.USERS_GET_SUCCESS: {
      return action.data ? [...action.data] : [];
    }
    case actionTypes.DEACTIVATE_USER_SUCCESS:
    case actionTypes.ACTIVATE_USER_SUCCESS:
    case actionTypes.DELETE_USER_SUCCESS:
    case actionTypes.VERIFY_USER_SUCCESS: {
      const removedUserId = action.data.id;
      return state.filter(user => user.id !== removedUserId);
    }
    default: {
      return state;
    }
  }
}
