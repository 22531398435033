import React from 'react';

import { Checkbox, Select } from '@atom/mui';

const { MenuItem } = Select;

interface Props {
  id: string;
  value: any;
  onChange: (value: any) => void;
  options?: string[];
  disabled: boolean;
  error: boolean;
  dataCyPrefix: string;
}

const EnumMultipleInput = ({
  id,
  value = [],
  onChange,
  options = [],
  disabled,
  error,
  dataCyPrefix,
}: Props) => {
  const handleChange = event => {
    onChange(event.target.value);
  };

  const selectValue = Array.isArray(value) ? value : [];

  return (
    <Select
      key={id}
      fullWidth
      multiple
      onChange={handleChange}
      value={selectValue}
      disabled={disabled}
      error={error}
      data-cy={`${dataCyPrefix}EnumMultipleInput`}
      renderValue={(selected: string[]) =>
        options
          .filter(item => selected.indexOf(item) !== -1)
          .map(item => item)
          .join(', ')
      }
    >
      {options.map((option: string, index: number) => {
        return (
          <MenuItem key={index} value={option}>
            <Checkbox
              checked={selectValue && selectValue.indexOf(option) > -1}
            />
            {option}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default EnumMultipleInput;
