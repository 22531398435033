import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as inventorySchemaActionCreators from '@atom/actions/inventorySchemaActions';
import { Progress } from '@atom/mui';
import { getHydratedInventorySchemaByIds } from '@atom/selectors/inventorySchemasSelectors';
import { InventorySchemaActions } from '@atom/types/actions';
import { AssetSchemaType, AttributeGroupItem } from '@atom/types/form';
import { AttributesType } from '@atom/types/inventory';
import { SchemaByIdsType, SchemaType } from '@atom/types/schema';
import { ReduxStore } from '@atom/types/store';

import AssetField from './AssetField';

import '../formBuilder.css';

interface ReduxStateProps {
  inventorySchemasByIds: SchemaByIdsType;
  loading: boolean;
}

interface ReduxDispatchProps {
  inventorySchemaActions: InventorySchemaActions;
}

interface PassedProps {
  fieldId: string;
  rootSchema: SchemaType;
  assetSchemas: AssetSchemaType[];
  updateAttributeGroup: (
    fieldId: string,
    schemaId: string,
    addGroup: boolean,
    attributeGroup: AttributeGroupItem,
  ) => void;
  updateAttribute: (
    fieldId: string,
    schemaId: string,
    addAttribute: boolean,
    attributeGroupName: string,
    attribute: AttributesType,
  ) => void;
  addAllAttributes: (
    fieldId: string,
    schemaId: string,
    attributeGroupName: string,
    attributes: AttributesType[],
  ) => void;
  removeAssetSchema: (fieldId: string, schemaId: string) => void;
}

type Props = ReduxStateProps & ReduxDispatchProps & PassedProps;

class AssetBlock extends React.Component<Props> {
  componentDidMount() {
    const { inventorySchemaActions, rootSchema, fieldId } = this.props;

    inventorySchemaActions.requestSchemasByIds({
      parentSchemaId: rootSchema.id,
      fieldId,
      isPublished: 'true,false',
    });
  }

  render() {
    const {
      assetSchemas,
      inventorySchemasByIds,
      fieldId,
      loading,
      rootSchema,
      updateAttributeGroup,
      updateAttribute,
      addAllAttributes,
      removeAssetSchema,
    } = this.props;

    const content = loading ? (
      <Progress />
    ) : (
      assetSchemas.map((assetSchema: AssetSchemaType) => {
        const fullSchema = inventorySchemasByIds[fieldId]
          ? inventorySchemasByIds[fieldId].find(
              (inventorySchema: SchemaType): boolean =>
                inventorySchema.id === assetSchema.id,
            )
          : { attributeGroups: [] };

        return (
          <AssetField
            rootSchema={rootSchema}
            key={assetSchema.id}
            // @ts-ignore
            fullSchema={fullSchema}
            assetSchema={assetSchema}
            fieldId={fieldId}
            updateAttributeGroup={updateAttributeGroup}
            updateAttribute={updateAttribute}
            addAllAttributes={addAllAttributes}
            removeAssetSchema={removeAssetSchema}
          />
        );
      })
    );

    return <React.Fragment>{content}</React.Fragment>;
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  inventorySchemasByIds: getHydratedInventorySchemaByIds(state),
  loading: state.loading.loadingInventorySchemasByIds,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  inventorySchemaActions: bindActionCreators(
    inventorySchemaActionCreators,
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetBlock);
