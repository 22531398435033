import { AttributesType } from '@atom/types/inventory';
import api from '@atom/utilities/api';
import { INVENTORY_ASSETS_ENDPOINT } from '@atom/utilities/endpoints';

import actionTypes from './actionTypes';

export interface RequestAttributePendingApprovalPayload {
  action: string;
  attributeGroupName: string;
  attribute: AttributesType;
  assetId: string;
}

export interface RequestAssetPendingApprovalPayload {
  action: string;
  element: any;
}

export const retrieveInventoryAssets = data => ({
  type: actionTypes.REQUEST_INVENTORY_ASSETS,
  data,
});

export const getInventoryAssetsSuccess = data => ({
  type: actionTypes.GET_INVENTORY_ASSETS_SUCCESS,
  data,
});

export const getInventoryAssetsFailure = () => ({
  type: actionTypes.GET_INVENTORY_ASSETS_FAILURE,
});

export const retrieveInventoryAssetsFilter = data => ({
  type: actionTypes.REQUEST_INVENTORY_ASSETS_FILTER,
  data,
});

export const getInventoryAssetsFilterSuccess = data => ({
  type: actionTypes.GET_INVENTORY_ASSETS_FILTER_SUCCESS,
  data,
});

export const getInventoryAssetsFilterFailure = () => ({
  type: actionTypes.GET_INVENTORY_ASSETS_FILTER_FAILURE,
});

export const retrieveInventoryAssetDetail = data => ({
  type: actionTypes.REQUEST_INVENTORY_ASSET_DETAIL,
  data,
});

export const getInventoryAssetDetailSuccess = data => ({
  type: actionTypes.GET_INVENTORY_ASSET_DETAIL_SUCCESS,
  data,
});

export const getInventoryAssetDetailFailure = () => ({
  type: actionTypes.GET_INVENTORY_ASSET_DETAIL_FAILURE,
});

export const retrieveInventoryAssetTree = data => ({
  type: actionTypes.REQUEST_INVENTORY_ASSET_TREE,
  data,
});

export const getInventoryAssetTreeSuccess = data => ({
  type: actionTypes.GET_INVENTORY_ASSET_TREE_SUCCESS,
  data,
});

export const getInventoryAssetTreeFailure = () => ({
  type: actionTypes.GET_INVENTORY_ASSET_TREE_FAILURE,
});

export const retrieveInventoryAssetTreeNode = data => ({
  type: actionTypes.REQUEST_INVENTORY_ASSET_TREE_NODE,
  data,
});

export const getInventoryAssetTreeNodeSuccess = data => ({
  type: actionTypes.GET_INVENTORY_ASSET_TREE_NODE_SUCCESS,
  data,
});

export const getInventoryAssetTreeNodeFailure = () => ({
  type: actionTypes.GET_INVENTORY_ASSET_TREE_NODE_FAILURE,
});

export const retrieveInventoryElementTreeNode = data => ({
  type: actionTypes.REQUEST_INVENTORY_ELEMENT_TREE_NODE,
  data,
});

export const getInventoryElementTreeNodeSuccess = data => ({
  type: actionTypes.GET_INVENTORY_ELEMENT_TREE_NODE_SUCCESS,
  data,
});

export const getInventoryElementTreeNodeFailure = () => ({
  type: actionTypes.GET_INVENTORY_ELEMENT_TREE_NODE_FAILURE,
});

export const requestInventoryAssetDetailUpdate = data => ({
  type: actionTypes.REQUEST_INVENTORY_ASSET_DETAIL_UPDATE,
  data,
});

export const inventoryAssetUpdateSuccess = data => ({
  type: actionTypes.GET_INVENTORY_ASSET_DETAIL_UPDATE_SUCCESS,
  data,
});

export const inventoryAssetUpdateFailure = () => ({
  type: actionTypes.GET_INVENTORY_ASSET_DETAIL_UPDATE_FAILURE,
});

export const requestInventoryElementDetailUpdate = data => ({
  type: actionTypes.REQUEST_INVENTORY_ELEMENT_DETAIL_UPDATE,
  data,
});

export const inventoryElementUpdateSuccess = data => ({
  type: actionTypes.GET_INVENTORY_ELEMENT_DETAIL_UPDATE_SUCCESS,
  data,
});

export const inventoryElementUpdateFailure = () => ({
  type: actionTypes.GET_INVENTORY_ELEMENT_DETAIL_UPDATE_FAILURE,
});

export const requestInventoryAssetTreeNodeUpdate = data => ({
  type: actionTypes.REQUEST_INVENTORY_ASSET_TREE_NODE_UPDATE,
  data,
});

export const inventoryAssetTreeNodeUpdateSuccess = data => ({
  type: actionTypes.GET_INVENTORY_ASSET_TREE_NODE_UPDATE_SUCCESS,
  data,
});

export const inventoryAssetTreeNodeUpdateFailure = () => ({
  type: actionTypes.GET_INVENTORY_ASSET_TREE_NODE_UPDATE_FAILURE,
});

export const requestInventoryAssetTreeNodeExpandedUpdate = data => ({
  type: actionTypes.REQUEST_INVENTORY_ASSET_TREE_NODE_EXPANDED_UPDATE,
  data,
});

export const inventoryAssetTreeNodeExpandedUpdateSuccess = data => ({
  type: actionTypes.GET_INVENTORY_ASSET_TREE_NODE_EXPANDED_UPDATE_SUCCESS,
  data,
});

export const inventoryAssetTreeNodeExpandedUpdateFailure = () => ({
  type: actionTypes.GET_INVENTORY_ASSET_TREE_NODE_EXPANDED_UPDATE_FAILURE,
});

export const requestInventoryElementTreeNodeUpdate = data => ({
  type: actionTypes.REQUEST_INVENTORY_ELEMENT_TREE_NODE_UPDATE,
  data,
});

export const inventoryElementTreeNodeUpdateSuccess = data => ({
  type: actionTypes.GET_INVENTORY_ELEMENT_TREE_NODE_UPDATE_SUCCESS,
  data,
});

export const inventoryElementTreeNodeUpdateFailure = () => ({
  type: actionTypes.GET_INVENTORY_ELEMENT_TREE_NODE_UPDATE_FAILURE,
});

export const retrieveInventoryElementDetail = data => ({
  type: actionTypes.REQUEST_INVENTORY_ELEMENT_DETAIL,
  data,
});

export const getInventoryElementDetailSuccess = data => ({
  type: actionTypes.GET_INVENTORY_ELEMENT_DETAIL_SUCCESS,
  data,
});

export const getInventoryElementDetailFailure = () => ({
  type: actionTypes.GET_INVENTORY_ELEMENT_DETAIL_FAILURE,
});

export const requestRootAssetBatchCreate = data => ({
  type: actionTypes.REQUEST_ROOT_ASSET_BATCH_CREATE,
  data,
});

export const createRootAssetBatchSuccess = (data?: object) => ({
  type: actionTypes.CREATE_ROOT_ASSET_BATCH_SUCCESS,
  data,
});

export const createRootAssetBatchFailure = () => ({
  type: actionTypes.CREATE_ROOT_ASSET_BATCH_FAILURE,
});

export const requestRootAssetBatchCreateFHWA = data => ({
  type: actionTypes.REQUEST_ROOT_ASSET_BATCH_CREATE_FHWA,
  data,
});

export const createRootAssetBatchFHWASuccess = (data?: object) => ({
  type: actionTypes.CREATE_ROOT_ASSET_BATCH_FHWA_SUCCESS,
  data,
});

export const createRootAssetBatchFHWAFailure = () => ({
  type: actionTypes.CREATE_ROOT_ASSET_BATCH_FHWA_FAILURE,
});

export const requestElementBatchCreate = data => ({
  type: actionTypes.REQUEST_ELEMENT_BATCH_CREATE,
  data,
});

export const createElementBatchSuccess = data => ({
  type: actionTypes.CREATE_ELEMENT_BATCH_SUCCESS,
  data,
});

export const createElementBatchFailure = () => ({
  type: actionTypes.CREATE_ELEMENT_BATCH_FAILURE,
});

export const requestInventoryAssetDeletion = data => ({
  type: actionTypes.REQUEST_INVENTORY_ASSET_DELETE,
  data,
});

export const inventoryAssetDeletionSuccess = data => ({
  type: actionTypes.DELETE_INVENTORY_ASSET_SUCCESS,
  data,
});

export const inventoryAssetDeletionFailure = () => ({
  type: actionTypes.DELETE_INVENTORY_ASSET_FAILURE,
});

export const requestRootInventoryAssetDeletion = data => ({
  type: actionTypes.REQUEST_ROOT_INVENTORY_ASSET_DELETION,
  data,
});

export const rootInventoryAssetDeletionSuccess = data => ({
  type: actionTypes.ROOT_INVENTORY_ASSET_DELETION_SUCCESS,
  data,
});

export const rootInventoryAssetDeletionFailure = data => ({
  type: actionTypes.ROOT_INVENTORY_ASSET_DELETION_FAILURE,
  data,
});

export const requestInventoryElementDeletion = data => ({
  type: actionTypes.REQUEST_INVENTORY_ELEMENT_DELETE,
  data,
});

export const inventoryElementDeletionSuccess = data => ({
  type: actionTypes.DELETE_INVENTORY_ELEMENT_SUCCESS,
  data,
});

export const inventoryElementDeletionFailure = () => ({
  type: actionTypes.DELETE_INVENTORY_ELEMENT_FAILURE,
});

export const requestFhwaElementDeletion = data => ({
  type: actionTypes.REQUEST_FHWA_ELEMENT_DELETE,
  data,
});

export const deleteFhwaElementSuccess = () => ({
  type: actionTypes.DELETE_FHWA_ELEMENT_SUCCESS,
});

export const deleteFhwaElementFailure = () => ({
  type: actionTypes.DELETE_FHWA_ELEMENT_FAILURE,
});

export const getInventorySearchSuccess = data => ({
  type: actionTypes.GET_INVENTORY_SEARCH_SUCCESS,
  data,
});

export const getInventorySearchFailure = () => ({
  type: actionTypes.GET_INVENTORY_SEARCH_FAILURE,
});

export const retrieveInventorySearch = data => ({
  type: actionTypes.REQUEST_INVENTORY_SEARCH,
  data,
});

export const requestCreateAsset = data => ({
  type: actionTypes.REQUEST_CREATE_ASSET,
  data,
});

export const createAssetSuccess = data => ({
  type: actionTypes.CREATE_ASSET_SUCCESS,
  data,
});

export const createAssetFailure = () => ({
  type: actionTypes.CREATE_ASSET_FAILURE,
});

export const requestElementAttributePendingApproval = (
  data: RequestAttributePendingApprovalPayload,
) => ({
  type: actionTypes.REQUEST_ELEMENT_ATTRIBUTE_PENDING_APPROVAL,
  data,
});

export const requestAssetAttributePendingApproval = (
  data: RequestAttributePendingApprovalPayload,
) => ({
  type: actionTypes.REQUEST_ASSET_ATTRIBUTE_PENDING_APPROVAL,
  data,
});

export const requestAssetPendingApproval = (
  data: RequestAssetPendingApprovalPayload,
) => ({
  type: actionTypes.REQUEST_ASSET_PENDING_APPROVAL,
  data,
});

export const assetPendingApprovalSuccess = () => ({
  type: actionTypes.ASSET_PENDING_APPROVAL_SUCCESS,
});

export const assetPendingApprovalFailure = () => ({
  type: actionTypes.ASSET_PENDING_APPROVAL_FAILURE,
});

export function getInventoryAssetDetail({ data: { id } }) {
  return async function (dispatch) {
    try {
      const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${id}`;
      const { data } = await api.get(endpoint);
      dispatch(getInventoryAssetDetailSuccess(data));
      return data;
    } catch (error) {
      dispatch(getInventoryAssetDetailFailure());
      throw error;
    }
  };
}
