import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
import LocationsAndAssetsContext, {
  ProceedAction,
} from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsContext';
import RequiredFieldsConfirmModal from '@atom/components/common/workOrderDetail/requiredFieldsConfirmModal/RequiredFieldsConfirmModal';
import {
  TASK_LOCATION_DELETE,
  TASK_LOCATION_DUPLICATE,
} from '@atom/graph/taskLocation';
import { Icon, IconButton, Snackbar } from '@atom/mui';
import { TaskLocation } from '@atom/types/taskLocation';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';

import './taskMapModal.css';

interface Props {
  location: TaskLocation;
  setHoverId: (hoverId: string) => void;
  handleEditLocationClickFromMap: (location: TaskLocation) => void;
}

const LocationRow = ({
  location,
  setHoverId,
  handleEditLocationClickFromMap,
}: Props) => {
  const { workOrderDetail, task, refetch, dataCySuffix } = useContext(
    LocationsAndAssetsContext,
  );

  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openFieldsConfirm, setOpenFieldsConfirm] = useState<boolean>(false);
  const [proceedAction, setProceedAction] = useState<ProceedAction>();

  const [deleteLocation, { loading: loadingDelete }] = useMutation(
    TASK_LOCATION_DELETE,
  );
  const [duplicateLocation, { loading: loadingDuplicate }] = useMutation(
    TASK_LOCATION_DUPLICATE,
  );

  const handleDuplicate = async () => {
    Snackbar.info({ message: `Duplicating ${location.name}...` });

    try {
      await duplicateLocation({
        variables: { id: location.id },
      });

      refetch();

      Snackbar.info({ message: `Duplicated ${location.name}.` });
    } catch (error) {
      Snackbar.error({ message: `Failed to duplicate ${location.name}.` });
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    Snackbar.info({ message: `Deleting ${location.name}...` });

    try {
      await deleteLocation({
        variables: { id: location.id },
      });

      refetch();

      Snackbar.info({ message: `Deleted ${location.name}.` });
    } catch (error) {
      Snackbar.error({ message: `Failed to delete ${location.name}.` });
    }
  };

  const handleDuplicateClick = () => {
    if (task.isCompleted && task?.requireAtLeastOneLocation) {
      setOpenFieldsConfirm(true);
      setProceedAction(ProceedAction.DUPLICATE);
    } else {
      handleDuplicate();
    }
  };

  const handleDeleteClick = () => {
    if (task.isCompleted && task?.requireAtLeastOneLocation) {
      setOpenFieldsConfirm(true);
      setProceedAction(ProceedAction.DELETE);
    } else {
      setOpenDelete(true);
    }
  };

  const onProceedComplete = async () => {
    await refetch();

    setOpenFieldsConfirm(false);

    if (proceedAction === ProceedAction.DELETE) {
      setProceedAction(null);
      setOpenDelete(true);
    } else {
      setProceedAction(null);
      handleDuplicate();
    }
  };

  const isEditDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  const showActionButton = !isEditDisabled;
  const loading = loadingDelete || loadingDuplicate;

  return (
    <>
      <div
        styleName="location-row"
        onMouseEnter={() => setHoverId(location?.id)}
        onMouseLeave={() => setHoverId('')}
      >
        <div styleName="row-title">
          <Icon>place</Icon>
          <div styleName="title">{location.name}</div>
        </div>
        <div styleName="action-buttons">
          <IconButton
            edge="end"
            tooltip="Edit Location"
            onClick={() => handleEditLocationClickFromMap(location)}
            disabled={loading}
            data-cy={`taskMapLocationEdit_${dataCySuffix}`}
          >
            <Icon>edit</Icon>
          </IconButton>
          {showActionButton && (
            <IconButton
              edge="end"
              tooltip="Duplicate Location"
              onClick={handleDuplicateClick}
              disabled={loading || isEditDisabled}
              data-cy={`taskMapLocationDuplicate_${dataCySuffix}`}
            >
              <Icon>content_copy</Icon>
            </IconButton>
          )}
          {showActionButton && (
            <IconButton
              edge="end"
              tooltip="Delete Location"
              onClick={handleDeleteClick}
              disabled={loading || isEditDisabled}
              data-cy={`taskMapLocationDelete_${dataCySuffix}`}
            >
              <Icon>delete</Icon>
            </IconButton>
          )}
        </div>
      </div>
      <DeleteModal
        open={openDelete}
        onConfirm={handleDelete}
        onCancel={() => setOpenDelete(false)}
        title="Delete Location?"
        content="Are you sure you want to delete this location?"
        cancelText="Cancel"
        confirmText="Delete"
      />
      <RequiredFieldsConfirmModal
        open={openFieldsConfirm}
        onCancel={() => setOpenFieldsConfirm(false)}
        onComplete={onProceedComplete}
        task={task}
        workOrderId={workOrderDetail?.id}
      />
    </>
  );
};

export default LocationRow;
