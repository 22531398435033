import React, { useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';

import { Modal, TextField } from '@atom/mui';
import { FavoritesList } from '@atom/types/favorite';

export interface Props {
  open: boolean;
  onClose: () => void;
  handleRenameList: (listName: string) => void;
  list: FavoritesList | null;
  favoritesListNames: string[];
}

const CHAR_LIMIT = 40;

const RenameFavoritesListModal = ({
  open,
  onClose,
  handleRenameList,
  list,
  favoritesListNames,
}: Props) => {
  const [listName, setListName] = useState<string>('');

  useEffect(() => setListName(list?.name || ''), [list]);

  const handleSubmit = (): void => {
    handleRenameList(listName);
  };

  const isNameInUse = useMemo(() => {
    return R.without([list?.name], favoritesListNames).some(
      name => name.toLowerCase() === listName.toLowerCase(),
    )
      ? 'This list name is already in use.'
      : '';
  }, [favoritesListNames, listName, list]);

  const hasError =
    R.isEmpty(listName) ||
    !R.isEmpty(isNameInUse) ||
    listName.length > CHAR_LIMIT;
  const helperText = isNameInUse
    ? isNameInUse
    : `${listName?.length}/${CHAR_LIMIT}`;

  return (
    <Modal
      title="Rename Favorite List"
      open={open}
      onCancel={onClose}
      onConfirm={handleSubmit}
      onExited={onClose}
      data-cy="renameFavoriteListModal"
      width="sx"
      disabled={hasError}
    >
      <TextField
        id="favorites_list_name_input"
        label="Name"
        value={listName}
        onChange={event => setListName(event.target.value)}
        helperText={helperText}
        error={hasError}
        FormHelperTextProps={{
          style: { textAlign: R.isEmpty(isNameInUse) ? 'right' : 'left' },
        }}
        required
      />
    </Modal>
  );
};

export default RenameFavoritesListModal;
