import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_UPDATE } from '@atom/graph/workTemplate';
import { Button, Modal, Snackbar } from '@atom/mui';
import {
  InheritedComponentType,
  WorkOrderTemplateUpdateInput,
  WorkTemplate,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { getUniqueInheritedComponentTypes } from '@atom/utilities/workTemplateUtilities';

import ChildCreateInheritance from '../workTemplateChildCreate/ChildCreateInheritance';

import ChildSettingsTitle from './ChildSettingsTitle';

import './workTemplateChildSettings.css';

const styles = {
  modalContent: {
    padding: 0,
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
}

const WorkTemplateChildSettings = ({ open, onClose }: Props) => {
  const { workTemplate, refetch } = useContext(WorkTemplateContext);

  const getFilteredAdditionalInheritedComponents = () => {
    return R.dropWhile(
      item =>
        new Set(workTemplate?.requiredInheritedComponents || []).has(item),
      getUniqueInheritedComponentTypes(workTemplate),
    );
  };

  const [
    additionalInheritedComponents,
    setAdditionalInheritedComponents,
  ] = useState<Set<InheritedComponentType>>(
    new Set(getFilteredAdditionalInheritedComponents()),
  );

  const [workTemplateUpdate, { loading: editLoading }] = useMutation<
    { workOrderTemplateUpdate: WorkTemplate },
    { input: WorkOrderTemplateUpdateInput }
  >(WORK_TEMPLATE_UPDATE);

  const resetState = () => {
    setAdditionalInheritedComponents(
      new Set(getFilteredAdditionalInheritedComponents()),
    );
  };

  useEffect(() => {
    if (open) {
      resetState();
    }
  }, [open]);

  const onModalClose = () => {
    onClose();
  };

  const confirm = async () => {
    try {
      await workTemplateUpdate({
        variables: {
          input: {
            workOrderTemplateId: workTemplate?.id,
            additionalInheritedComponents: [...additionalInheritedComponents],
          },
        },
      });

      refetch();
      onModalClose();
    } catch (err) {
      Snackbar.error({ message: 'An unknown error occurred' });
      onModalClose();
    }
  };

  const disableSave =
    doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN) || workTemplate?.published;

  const footer = (
    <div>
      <Button onClick={onModalClose} style={{ marginRight: '0.5rem' }}>
        Cancel
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={confirm}
        disabled={disableSave}
      >
        Save
      </Button>
    </div>
  );

  return (
    <Modal
      open={open}
      footer={footer}
      title={<ChildSettingsTitle />}
      contentStyle={styles.modalContent}
      loading={editLoading}
    >
      <ChildCreateInheritance
        additionalInheritedComponents={additionalInheritedComponents}
        setAdditionalInheritedComponents={setAdditionalInheritedComponents}
        isChild
      />
    </Modal>
  );
};

export default WorkTemplateChildSettings;
