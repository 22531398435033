import React from 'react';
import { connect } from 'react-redux';
import { actions, LocalForm } from 'react-redux-form';
import { SelectChangeEvent } from '@mui/material';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';

import * as inventoryAssetActionCreators from '@atom/actions/inventoryAssetActions';
import * as inventoryCategoryActionCreators from '@atom/actions/inventoryCategoryActions';
import * as inventorySchemaActionCreators from '@atom/actions/inventorySchemaActions';
import * as poolAttributeActionCreators from '@atom/actions/poolAttributeActions';
import CategoryFilterModal from '@atom/components/common/categoryFilterModal/CategoryFilterModal';
// @ts-ignore
import inventoryPendingIcon from '@atom/components/common/svgIcons/inventoryPendingIcon.svg';
import { Button, Select, Switch } from '@atom/mui';
import {
  getPoolAttributeColumnsSelector,
  getPoolAttributeFilterSelector,
} from '@atom/selectors/poolAttributeSelectors';
import {
  inventoryPortalAssetColumnsSelector,
  inventoryPreferencesSelector,
} from '@atom/selectors/preferencesSelectors';
import {
  getInventorySchemasSelector,
  getSchemaAttributeFilterGroupsSelector,
} from '@atom/selectors/schemaSelectors';
import colors from '@atom/styles/colors';
import {
  InventoryAssetActions,
  InventoryCategoryActions,
  InventorySchemaActions,
  PoolAttributeActions,
} from '@atom/types/actions';
import { InventoryPortalView } from '@atom/types/inventory';
import { ReduxStore } from '@atom/types/store';
import { formatAssetAttributeFilters } from '@atom/utilities/assetUtilities';

import { filterStyle } from './attributeFilterControls/attributeStyle';
import InventoryFilterAttributeGroup from './InventoryFilterAttributeGroup';
import TopLevelAssetFilterToggle from './TopLevelAssetFilterToggle';

import '../../styles/left-pane.css';

const { MenuItem } = Select;

const MODEL_NAME = 'assets-filter';
const INITIAL_SCHEMA = {
  id: 'all',
  name: 'All',
  attributes: {},
  attributeGroups: [],
};

const initialState = {
  categoryIdsFilters: null,
  dispatch: null,
  resetKey: null,
  formState: {},
  topLevelAssetsOnly: true,
  hasPendingChangesOnly: false,
  schemaId: '',
};

interface PassedProps {
  updatePortalState: (data: object) => void;
  limit: number;
  rootSchema?: any;
  filters?: any[];
  topLevelAssetsOnly?: boolean;
  hasPendingChangesOnly: boolean;
  assetFilters?: any;
  categoryIdsFilters?: any[];
  view: InventoryPortalView;
  resetSelectedAssets: () => void;
}

interface ReduxStateProps {
  schemaFilters: any;
  poolAttributes: any;
  poolAttributesColumns: any;
  inventorySchemas: any[];
  preferences: any;
  inventoryFilterCategoryTree: any;
}

interface ReduxDispatchProps {
  poolAttributeActions: PoolAttributeActions;
  inventorySchemaActions: InventorySchemaActions;
  inventoryAssetActions: InventoryAssetActions;
  inventoryCategoryActions: InventoryCategoryActions;
}

type Props = PassedProps & ReduxStateProps & ReduxDispatchProps;

interface State {
  categoryIdsFilters: any;
  dispatch: any;
  resetKey: any;
  formState: any;
  topLevelAssetsOnly: boolean;
  hasPendingChangesOnly: boolean;
  assetFilters: any;
  filters: any[];
  schemaId: any;
}

const styles = {
  icon: {
    color: colors.neutral.gray,
    marginRight: '1rem',
  },
  toggle: {
    width: 'auto',
  },
};

class InventoryAssetFilters extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      schemaId: R.pathOr(null, ['rootSchema'], props),
      assetFilters: R.pathOr(null, ['assetFilters'], props),
      categoryIdsFilters: R.pathOr(null, ['categoryIdsFilters'], props),
      filters: R.pathOr(
        R.pathOr(null, ['poolAttributes'], props),
        ['filters'],
        props,
      ),
      topLevelAssetsOnly: R.pathOr(true, ['topLevelAssetsOnly'], props),
      hasPendingChangesOnly: R.pathOr(false, ['hasPendingChangesOnly'], props),
    };
  }

  componentDidMount() {
    const {
      inventorySchemaActions,
      poolAttributes,
      poolAttributeActions,
      inventorySchemas,
      updatePortalState,
    } = this.props;

    const { filters, schemaId } = this.state;

    if (R.isNil(inventorySchemas) || R.isEmpty(inventorySchemas)) {
      inventorySchemaActions.retrieveInventorySchemas({
        rootSchemas: true,
      });
    }

    const commonAttributes = poolAttributes[0];

    if (!R.isEmpty(commonAttributes.attributes)) {
      if (R.isEmpty(filters) && !schemaId) {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ filters: poolAttributes });
        updatePortalState({ filters: poolAttributes });
      }
    } else {
      poolAttributeActions.retrievePoolAttributes();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { poolAttributes, updatePortalState, filters } = this.props;

    if (
      R.isEmpty(poolAttributes[0].attributes) &&
      !R.isEmpty(nextProps.poolAttributes[0].attributes)
    ) {
      this.setState({ filters: nextProps.poolAttributes });

      updatePortalState({ filters });
    }
  }

  clearSchemaFilter = async () => {
    const { updatePortalState, poolAttributes } = this.props;
    const { dispatch } = this.state;

    updatePortalState({
      rootSchema: INITIAL_SCHEMA,
      filters: poolAttributes,
    });

    await dispatch(actions.change(MODEL_NAME, {}));
    this.setState({
      schemaId: null,
      filters: poolAttributes,
      resetKey: new Date().valueOf(),
    });
  };

  populateAttributeFilters = async (event: SelectChangeEvent) => {
    const { value } = event.target;
    const { topLevelAssetsOnly, dispatch } = this.state;
    const { updatePortalState, schemaFilters, poolAttributes } = this.props;

    await dispatch(actions.change(MODEL_NAME, {}));
    if (value === 'all') {
      this.clearSchemaFilter();
      return;
    }

    const { inventorySchemas } = this.props;
    const schema = inventorySchemas.find(item => item.id === value);

    if (topLevelAssetsOnly) {
      this.setState({
        filters: getSchemaAttributeFilterGroupsSelector({ rootSchema: schema }),
      });
    }

    updatePortalState({
      rootSchema: schema,
      filters: topLevelAssetsOnly ? schemaFilters : poolAttributes,
    });
  };

  attachDispatch = dispatch => {
    this.setState({ dispatch });
  };

  onChange = formState => {
    this.setState({ formState });
  };

  onTopLevelAssetFilterToggle = async () => {
    const {
      rootSchema,
      schemaFilters,
      poolAttributes,
      updatePortalState,
    } = this.props;
    const { dispatch } = this.state;

    const topLevelAssetsOnly = !this.state.topLevelAssetsOnly;

    const filters =
      topLevelAssetsOnly && rootSchema ? schemaFilters : poolAttributes;

    await dispatch(actions.change(MODEL_NAME, {}));

    updatePortalState({
      topLevelAssetsOnly,
      assetFilters: {},
      filters,
    });

    this.setState({
      topLevelAssetsOnly,
      filters,
      resetKey: new Date().valueOf(),
    });
  };

  onHasPendingChangesToggle = () => {
    const { hasPendingChangesOnly } = this.state;
    this.setState({ hasPendingChangesOnly: !hasPendingChangesOnly });
  };

  onSubmit = assetFilters => {
    const {
      rootSchema: schema,
      inventorySchemas,
      preferences,
      inventoryAssetActions,
      updatePortalState,
      poolAttributesColumns,
      limit,
      view,
      resetSelectedAssets,
    } = this.props;

    const {
      categoryIdsFilters,
      topLevelAssetsOnly,
      filters,
      hasPendingChangesOnly,
    } = this.state;

    const isListView = view === InventoryPortalView.LIST;
    const rootSchema = schema || {};

    const body = formatAssetAttributeFilters(assetFilters);
    const columns =
      R.isNil(schema) || schema?.id === 'all' || !topLevelAssetsOnly
        ? poolAttributesColumns
        : inventoryPortalAssetColumnsSelector(
            rootSchema.assetType,
            preferences.assets,
            inventorySchemas,
          );

    if (isListView) {
      const data = {
        schemaId: rootSchema?.id === 'all' ? null : rootSchema.id,
        categoryIds: categoryIdsFilters,
        body,
        columns,
        rootAssets: topLevelAssetsOnly,
        hasPendingChangesOnly,
        page: 1,
        limit,
        includeInactive: false,
      };

      inventoryAssetActions.retrieveInventoryAssetsFilter(data);
    }

    updatePortalState({
      schemaId: rootSchema?.id === 'all' ? null : rootSchema.id,
      columns,
      filters,
      assetFilters,
      poolAttributesColumns,
      topLevelAssetsOnly,
      categoryIdsFilters,
      context: 'assetsFilter',
      page: 1,
      downloadDisabled: false,
      isFilterApplied: true,
      hasPendingChangesOnly,
    });

    resetSelectedAssets();
  };

  resetFilters = async () => {
    const { dispatch } = this.state;
    const {
      inventoryAssetActions,
      updatePortalState,
      poolAttributesColumns,
      poolAttributes,
      limit,
    } = this.props;

    const data = {
      schemaId: null,
      rootAssets: true,
      hasPendingChangesOnly: false,
      body: {},
      columns: poolAttributesColumns,
      page: 1,
      limit,
      sortBy: 'assetName',
      isAscending: true,
      includeInactive: false,
    };

    inventoryAssetActions.retrieveInventoryAssetsFilter(data);

    updatePortalState({
      schemaId: null,
      rootSchema: INITIAL_SCHEMA,
      columns: poolAttributesColumns,
      categoryIdsFilters: null,
      assetFilters: {},
      filters: poolAttributes,
      topLevelAssetsOnly: true,
      hasPendingChangesOnly: false,
      context: 'assetsFilter',
      page: 1,
      rootAssets: true,
      sortBy: 'assetName',
      isAscending: true,
    });

    await dispatch(actions.change(MODEL_NAME, {}));
    this.setState({
      schemaId: null,
      resetKey: new Date().valueOf(),
      assetFilters: {},
      categoryIdsFilters: null,
      topLevelAssetsOnly: true,
      hasPendingChangesOnly: false,
      filters: poolAttributes,
    });
  };

  updateFiltersState = stateUpdate => {
    this.setState({
      ...stateUpdate,
    });
  };

  render() {
    const {
      inventorySchemas,
      inventoryFilterCategoryTree,
      rootSchema,
      view,
    } = this.props;

    const {
      resetKey,
      assetFilters,
      filters,
      formState,
      categoryIdsFilters,
      topLevelAssetsOnly,
      hasPendingChangesOnly,
    } = this.state;

    const initialFormState = !R.isNil(assetFilters) ? assetFilters : {};

    return (
      <div styleName="left-pane">
        <LocalForm
          model={MODEL_NAME}
          initialState={initialFormState}
          onChange={this.onChange}
          getDispatch={dispatch => this.attachDispatch(dispatch)}
          onSubmit={values => this.onSubmit(values)}
        >
          <div styleName="filters-header">
            <div styleName="filters-header-label">Filter</div>
            <Button
              style={filterStyle.resetButtonStyle}
              onClick={this.resetFilters}
            >
              Reset
            </Button>
            <Button
              type="submit"
              color="primary"
              style={filterStyle.applyButtonStyle}
            >
              Apply
            </Button>
          </div>
          {view === InventoryPortalView.LIST && (
            <div styleName="top-level-asset-filter-toggle">
              <TopLevelAssetFilterToggle
                toggled={topLevelAssetsOnly}
                onToggle={this.onTopLevelAssetFilterToggle}
              />
            </div>
          )}
          <div styleName="select-filter-wrapper">
            <Select
              fullWidth
              label="Inventory Type"
              onChange={this.populateAttributeFilters}
              value={rootSchema?.id}
            >
              {inventorySchemas.map(schema => {
                return (
                  <MenuItem key={schema.id} value={schema.id}>
                    {schema.name}
                  </MenuItem>
                );
              })}
            </Select>
            <div styleName="select-filter-description-text">
              Selecting an asset type will show related filters.
            </div>
          </div>
          <div styleName="category-filter-container">
            <CategoryFilterModal
              categories={inventoryFilterCategoryTree}
              // @ts-ignore
              updateFiltersState={this.updateFiltersState}
              selectedItems={categoryIdsFilters}
            />
          </div>
          <div styleName="toggle-container">
            <div styleName="toggle-text">
              <img src={inventoryPendingIcon} style={styles.icon} />
              Has Pending Changes
            </div>
            <Switch
              style={styles.toggle}
              checked={hasPendingChangesOnly}
              onChange={this.onHasPendingChangesToggle}
            />
          </div>
          <div styleName="attribute-filters-container" key={resetKey}>
            {filters.map((attributeGroup, index) => (
              <InventoryFilterAttributeGroup
                key={`${attributeGroup.name}-${index}`}
                attributeGroup={attributeGroup}
                formState={R.isEmpty(formState) ? initialFormState : formState}
                assetFilters={assetFilters}
              />
            ))}
          </div>
        </LocalForm>
      </div>
    );
  }
}

const mapStateToProps = (
  state: ReduxStore,
  ownProps: Props,
): ReduxStateProps => ({
  schemaFilters: getSchemaAttributeFilterGroupsSelector(ownProps),
  poolAttributes: getPoolAttributeFilterSelector(state),
  poolAttributesColumns: getPoolAttributeColumnsSelector(state),
  inventorySchemas: getInventorySchemasSelector(state),
  preferences: inventoryPreferencesSelector(state),
  inventoryFilterCategoryTree: state.inventoryFilterCategoryTree,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  poolAttributeActions: bindActionCreators(
    poolAttributeActionCreators,
    dispatch,
  ),
  inventorySchemaActions: bindActionCreators(
    inventorySchemaActionCreators,
    dispatch,
  ),
  inventoryAssetActions: bindActionCreators(
    inventoryAssetActionCreators,
    dispatch,
  ),
  inventoryCategoryActions: bindActionCreators(
    inventoryCategoryActionCreators,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InventoryAssetFilters);
