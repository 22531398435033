import * as R from 'ramda';

import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function formInstanceReducer(
  state = initialState.formInstance,
  action,
) {
  const { data, type } = action;
  switch (type) {
    case actionTypes.GET_FORM_INSTANCE_SUCCESS: {
      return data;
    }
    case actionTypes.UPDATE_FORM_INSTANCE_CI_SUCCESS: {
      return data;
    }
    case actionTypes.UPDATE_FORM_INSTANCE_PCI_SUCCESS: {
      return data;
    }
    case actionTypes.ADD_FORM_INSTANCE_MEDIA_SUCCESS: {
      return {
        ...state,
        media: data,
      };
    }
    case actionTypes.DELETE_FORM_INSTANCE_MEDIA_SUCCESS: {
      const { subjectId, mediaId } = data;
      const updatedMedia = state.media[subjectId].filter(
        media => media.id !== mediaId,
      );

      return {
        ...state,
        media: {
          ...state.media,
          [subjectId]: updatedMedia,
        },
      };
    }
    case actionTypes.PATCH_MEDIA_SUCCESS: {
      const { name, id } = data;
      const subjectId = R.keys(state.media).reduce((acc, innerSubjectId) => {
        return state.media[innerSubjectId].some(medium => medium.id === id)
          ? innerSubjectId
          : acc;
      }, '');

      if (!subjectId) {
        return state;
      }

      const updatedMedia = {
        ...state.media,
        [subjectId]: state.media[subjectId].map(medium =>
          medium.id === id ? { ...medium, name } : medium,
        ),
      };

      return {
        ...state,
        media: updatedMedia,
      };
    }
    case actionTypes.CLEAR_FORM_INSTANCE_SUCCESS: {
      return initialState.formInstance;
    }
    default: {
      return state;
    }
  }
}
