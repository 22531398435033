import React from 'react';
import {
  Checkbox as MUICheckBox,
  CheckboxProps as MUICheckboxProps,
  FormControlLabel as MUIFormControlLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import fonts from '@atom/styles/fonts';

export type CustomProps = {
  /**
   * optional checkbox label
   */
  label?: React.ReactNode;
};

export type CheckboxProps = MUICheckboxProps & CustomProps;

const useStyles = makeStyles({
  label: {
    fontSize: fonts.md,
  },
});

export const Checkbox = ({
  color = 'primary',
  label = '',
  checked = false,
  ...data
}: CheckboxProps) => {
  const classes = useStyles();

  return label ? (
    <MUIFormControlLabel
      label={label}
      classes={classes}
      control={<MUICheckBox color={color} checked={checked} {...data} />}
    />
  ) : (
    <MUICheckBox color={color} checked={checked} {...data} />
  );
};
