import React, { useEffect, useRef, useState } from 'react';

import GooglePlacesAutoComplete from '@atom/components/common/GooglePlacesAutoComplete';
import { useGoogleMapsStateValue } from '@atom/components/common/map/hooks/googleMapsStateHook';
import CurrentLocation from '@atom/components/common/map/mapTools/CurrentLocation';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { MapEditMetadata, MapShapeType } from '@atom/types/map';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import LocationEditControls from './LocationEditControls';

import './mapSearchBox.css';
interface Props {
  onLocationChange: (
    location: { lat: number; lng: number },
    searchTerm?: string,
  ) => void;
  hintText: string;
  searchTerm?: string;
  onClearSearch?: () => void;
  editControls?: boolean;
  mapEditMetadata?: MapEditMetadata[];
  onCenter?: ({ lat, lng }, zoom: number) => void;
  onChange?: (drawingMode: MapShapeType) => void;
  editItem?: MapEditMetadata;
  setEditItem?: (editItem: MapEditMetadata) => void;
  clearDrawing?: () => void;
  onEditSave?: () => void;
  locationEditZoom?: number;
  initialOpenEditList?: boolean;
}

const MapSearchBox = ({
  onLocationChange,
  hintText,
  searchTerm,
  onClearSearch,
  editControls,
  mapEditMetadata,
  onCenter,
  onChange,
  editItem,
  setEditItem,
  clearDrawing,
  onEditSave,
  locationEditZoom,
  initialOpenEditList,
}: Props) => {
  const anchor = useRef();
  const { shape } = useGoogleMapsStateValue();

  const [updateKey, setUpdateKey] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (anchor) {
      setOpen(initialOpenEditList);
    }
  }, [anchor]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClear = () => {
    setUpdateKey(updateKey + 1);
    onClearSearch();
  };

  return (
    <>
      <div styleName="container" ref={anchor}>
        <div styleName="search-box-container">
          <div styleName="content">
            {editControls && isNilOrEmpty(editItem) ? (
              <IconButton onClick={handleToggle} size="small">
                <Icon>
                  {open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                </Icon>
              </IconButton>
            ) : (
              <div styleName="icon">
                <Icon>search</Icon>
              </div>
            )}
            <div styleName="search-input">
              <GooglePlacesAutoComplete
                key={updateKey}
                floatingLabelFixed
                hintText={hintText}
                initialValue={searchTerm}
                onSelect={onLocationChange}
                anchor={anchor.current}
                popoverWidth="23rem"
              />
            </div>
            <div styleName="icon clickable">
              {searchTerm ? (
                <Icon onClick={handleClear}>close</Icon>
              ) : (
                <CurrentLocation onLocationChange={onLocationChange} />
              )}
            </div>
          </div>
        </div>
        {editControls && isNilOrEmpty(editItem) && (
          <LocationEditControls
            mapEditMetadata={mapEditMetadata}
            anchor={anchor}
            open={open}
            onCenter={onCenter}
            onChange={onChange}
            setEditItem={setEditItem}
            locationEditZoom={locationEditZoom}
          />
        )}
      </div>
      {editControls && !isNilOrEmpty(editItem) && (
        <div styleName="edit-action-controls-container">
          <IconButton
            tooltip="Cancel"
            size="small"
            onClick={clearDrawing}
            disabled={isNilOrEmpty(shape)}
          >
            <Icon>close</Icon>
          </IconButton>
          <IconButton
            tooltip="Save"
            size="small"
            onClick={onEditSave}
            disabled={isNilOrEmpty(shape)}
          >
            <Icon color={colors.brand.blue}>check</Icon>
          </IconButton>
        </div>
      )}
    </>
  );
};

export default MapSearchBox;
