import { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';

import client from '@atom/graph/client';
import { GET_WORK_ORDERS } from '@atom/graph/work';
import {
  WorkOrder,
  WorkOrdersConnection,
  WorkOrdersConnectionInput,
} from '@atom/types/work';

export type DebouncedSearch = (
  input: WorkOrdersConnectionInput,
  workOrders: WorkOrder[],
) => Promise<void>;

export interface WorkOrderSearchOptions {
  query: string;
  workTemplateIds?: string[];
  statusIds?: number[];
  limit?: number;
  sortBy?: string;
  debounceTime?: number;
  characterMin?: number;
}

type ReturnData = [
  WorkOrdersConnection,
  {
    initialSearch: () => void;
    fetchMore: () => void;
    hasMore: boolean;
    loadingSearch: boolean;
    loadingPagination: boolean;
    error: boolean;
  },
];

export const useWorkOrderSearch = ({
  query,
  workTemplateIds,
  statusIds,
  limit,
  sortBy,
  debounceTime = 300,
  characterMin = 3,
}: WorkOrderSearchOptions): ReturnData => {
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [loadingPagination, setLoadingPagination] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [connection, setConnection] = useState<WorkOrdersConnection>({
    totalCount: 0,
    workOrders: [],
  });

  const nextPage = useMemo(() => {
    if (!limit || !connection.totalCount) {
      return 1;
    }

    return Math.floor(connection.workOrders.length / limit) + 1;
  }, [connection, limit]);

  const searchWorkOrders = async (
    input: WorkOrdersConnectionInput,
    loaded: WorkOrder[],
  ) => {
    setError(false);

    try {
      const { data } = await client.query<
        { workOrders: WorkOrdersConnection },
        { input: WorkOrdersConnectionInput }
      >({
        query: GET_WORK_ORDERS,
        variables: { input },
      });

      setConnection({
        totalCount: data.workOrders.totalCount,
        workOrders: [...loaded, ...data.workOrders.workOrders],
      });
    } catch (err) {
      setError(true);
    }

    setLoadingSearch(false);
    setLoadingPagination(false);
  };

  const searchWorkOrdersDebounced = useCallback<DebouncedSearch>(
    debounce(searchWorkOrders, debounceTime),
    [],
  );

  const initialSearch = () => {
    searchWorkOrdersDebounced(
      {
        name: query,
        limit,
        page: 1,
        workTemplateIds,
        statusIds,
        sortBy,
      },
      [],
    );
  };

  useEffect(() => {
    if (query.length >= characterMin) {
      setLoadingSearch(true);
      searchWorkOrdersDebounced(
        { name: query, limit, page: 1, workTemplateIds, statusIds, sortBy },
        [],
      );
    } else {
      initialSearch();
    }
  }, [query]);

  const hasMore = connection.workOrders.length < connection.totalCount;

  const fetchMore = () => {
    if (hasMore) {
      setLoadingPagination(true);
      searchWorkOrders(
        {
          name: query,
          limit,
          page: nextPage,
          workTemplateIds,
          statusIds,
          sortBy,
        },
        connection.workOrders,
      );
    }
  };

  return [
    connection,
    {
      initialSearch,
      fetchMore,
      hasMore,
      loadingSearch,
      loadingPagination,
      error,
    },
  ];
};
