import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function formTemplateDetailReducer(
  state = initialState.formTemplateDetail,
  action,
) {
  switch (action.type) {
    case actionTypes.FORM_TEMPLATE_GET_SUCCESS:
    case actionTypes.CREATE_FORM_TEMPLATE_SUCCESS:
    case actionTypes.DUPLICATE_FORM_TEMPLATE_SUCCESS:
    case actionTypes.UPDATE_FORM_TEMPLATE_SUCCESS: {
      return action.data;
    }
    case actionTypes.FORM_TEMPLATE_CLEAR_SUCCESS: {
      return initialState.formTemplateDetail;
    }
    default: {
      return state;
    }
  }
}
