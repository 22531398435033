const layout = {
  topNavHeight: '60px',
  subNavHeight: '50px',
  fixedHeaderOffset: '110px',
  sidePaneWidth: '340px',
  workPreviewWidth: '760px',
  sideAndWorkPreviewWidth: '1100px',
  modalWidth: '768px',
  timeEntryModalWidth: '670px',
  checkInModal: '640px',
  mediumModalWidth: '550px',
  smallModalWidth: '400px',
  formModuleModalWidth: '540px',
  formModuleMediaModalWidth: '860px',
  formModuleMediaModalHeight: '420px',
  permissionDetailModalWidth: '1000px',
  permissionDetailModalHeight: '700px',
  muiDropdownBoxShadow:
    'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
};

export default layout;
