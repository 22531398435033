import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import * as R from 'ramda';

import CreateWorkModal from '@atom/components/common/createWorkModals/CreateWorkModal';
import NewWorkOrderIcon from '@atom/components/common/svgIcons/NewWorkOrderIcon';
// @ts-ignore
import workExportsIcon from '@atom/components/common/svgIcons/workExports.svg';
// @ts-ignore
import workTypeIcon from '@atom/components/common/svgIcons/workTypeIcon.svg';
import { usePreferences } from '@atom/hooks/usePreferences';
import { WorkOrdersView } from '@atom/hooks/useWorkOrdersFilters';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import WorkOrdersContext from './WorkOrdersContext';
import WorkOrdersExport from './WorkOrdersExport';

import './workOrders.css';

const WorkOrdersHeader = () => {
  const preferences = usePreferences();
  const requireWorkType = R.pathOr(
    false,
    ['workOrders', 'requireWorkType'],
    preferences,
  );

  const { input, setInput } = useContext(WorkOrdersContext);

  const [createWorkModalOpen, setCreateWorkModalOpen] = useState<boolean>(
    false,
  );

  const toggleCreateWorkModal = () => {
    setCreateWorkModalOpen(!createWorkModalOpen);
  };

  return (
    <div styleName="header-container">
      <div styleName="left-container">
        <div styleName="name-container">Work</div>
        <div>
          {Object.values(WorkOrdersView).map((view, key) => (
            <div
              key={key}
              onClick={() => setInput({ ...input, view })}
              styleName={`tab-button ${input.view === view ? 'selected' : ''}`}
            >
              {view}
            </div>
          ))}
        </div>
      </div>
      <div styleName="button-container">
        {hasRolePermissions(ROLE_SETS.INSPECTOR) && (
          <>
            <IconButton
              tooltip="Create Work"
              onClick={toggleCreateWorkModal}
              children={<NewWorkOrderIcon />}
              data-cy="workOrdersHeaderCreateWorkButton"
            />
            <CreateWorkModal
              redirectOnCreate
              open={createWorkModalOpen}
              workTemplateRequired={requireWorkType}
              onClose={() => setCreateWorkModalOpen(false)}
            />
          </>
        )}
        {hasRolePermissions(ROLE_SETS.INSPECTOR) && <WorkOrdersExport />}
        {hasRolePermissions(ROLE_SETS.INSPECTOR) && (
          <Link to="/workExports">
            <IconButton
              tooltip="Work Exports"
              data-cy="workOrdersHeaderWorkExportsButton"
            >
              <Icon>
                <img src={workExportsIcon} />
              </Icon>
            </IconButton>
          </Link>
        )}
        {hasRolePermissions(ROLE_SETS.INSPECTOR) && (
          <Link to="/form-repository">
            <IconButton tooltip="Forms" data-cy="workOrdersHeaderFormsButton">
              <Icon color={colors.neutral.white}>library_books</Icon>
            </IconButton>
          </Link>
        )}
        <Link to="/work-templates">
          <IconButton
            tooltip="Work Templates"
            data-cy="workOrdersHeaderWorkTemplatesButton"
          >
            <img src={workTypeIcon} />
          </IconButton>
        </Link>
      </div>
    </div>
  );
};

export default WorkOrdersHeader;
