import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';

import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { ATTRIBUTE_UPDATE } from '@atom/graph/schema';
import { Checkbox } from '@atom/mui';
import {
  AttributeUpdateInput,
  SchemaTree,
  SchemaTreeAttribute,
} from '@atom/types/schema';
// TODO: [AM-15154] Remove required feature toggle
import {
  Environment,
  isCurrentEnvironment,
} from '@atom/utilities/featureToggleUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import { updatePendingCreation } from '../subItemDetail/subItemDetailUtilities';

import {
  mergePendingChanges,
  updatePendingUpdates,
} from './attributeDetailUtilities';
import TypeSettings from './TypeSettings';

import './attributeDetail.css';

const TypeSection = () => {
  const {
    schemaTree,
    selectedAttribute,
    setSelectedAttribute,
    selectedAttributeRoute,
    selectedSubItem,
    refetchSchemaTree,
    selectedSubItemPath,
    pendingUpdates,
    setPendingUpdates,
    setPendingCreations,
  } = useContext(SchemaDetailContext);

  // If pending changes exist, they will be merged with the
  // selected attribute data to display
  const mergedAttribute = mergePendingChanges(
    selectedSubItem.id,
    selectedAttribute,
    pendingUpdates,
  );

  const [updateAttribute] = useMutation<
    { attributeUpdate: SchemaTree },
    { input: AttributeUpdateInput }
  >(ATTRIBUTE_UPDATE);

  const handleAttributeUpdate = async (payload: object) => {
    await updateAttribute({
      variables: {
        input: {
          schemaId: selectedSubItem.id,
          attributeGroupId: selectedAttributeRoute.attributeGroupId,
          attributeId: selectedAttributeRoute.attributeId,
          ...payload,
        },
      },
    });

    refetchSchemaTree();
  };

  // If schema is published, changes must be saved to the pending updates cart
  const handlePendingAttributeUpdate = (
    property: keyof SchemaTreeAttribute,
    value: any,
  ) => {
    setPendingUpdates(prev =>
      updatePendingUpdates(
        selectedSubItem.id,
        prev,
        mergedAttribute,
        property,
        value,
      ),
    );
  };

  // If attribute is a temp attribute, values must be updated in pendingCreations state
  const handlePendingCreationUpdate = (
    isRequired: boolean,
    isEditable: boolean,
  ) => {
    const updatedAttribute = { ...selectedAttribute, isRequired, isEditable };

    setPendingCreations(prev =>
      updatePendingCreation(
        selectedSubItem.id,
        selectedAttributeRoute,
        prev,
        updatedAttribute,
      ),
    );

    setSelectedAttribute(updatedAttribute);
  };

  const toggleRequired = async () => {
    const isRequired = !Boolean(mergedAttribute.isRequired);
    // If isRequired is set to true, isEditable must also be set to true.
    const isEditable = isRequired ? true : mergedAttribute.isEditable;

    if (selectedAttribute.isTempAttribute) {
      handlePendingCreationUpdate(isRequired, isEditable);
    } else {
      setSelectedAttribute(prev => ({ ...prev, isRequired, isEditable }));
      await handleAttributeUpdate({ isRequired, isEditable });
    }
  };

  const toggleEditable = async () => {
    const isEditable = !Boolean(mergedAttribute.isEditable);
    // If isEditable is set to false, isRequired must also be set to false
    const isRequired = isEditable ? mergedAttribute.isRequired : false;

    if (selectedAttribute.isTempAttribute) {
      handlePendingCreationUpdate(isRequired, isEditable);
    } else if (schemaTree.isPublished) {
      handlePendingAttributeUpdate('isEditable', isEditable);
    } else {
      setSelectedAttribute(prev => ({ ...prev, isRequired, isEditable }));
      await handleAttributeUpdate({ isEditable, isRequired });
    }
  };

  const isRequiredDisabled =
    schemaTree?.isPublished && !selectedAttribute.isTempAttribute;

  // If published attribute is required, editable cannot be changed.
  const isEditableDisabled =
    schemaTree?.isPublished &&
    mergedAttribute.isRequired &&
    !selectedAttribute.isTempAttribute;

  // isRequired can only be set on attributes on the root of the schema
  const showRequired =
    isNilOrEmpty(selectedSubItemPath) &&
    isCurrentEnvironment([Environment.DEV, Environment.QA]);

  return (
    <div styleName="attribute-section">
      <TypeSettings />
      {showRequired && (
        <Checkbox
          checked={mergedAttribute.isRequired}
          onChange={toggleRequired}
          disabled={isRequiredDisabled}
          label="Attribute is required"
        />
      )}
      <Checkbox
        checked={!mergedAttribute.isEditable}
        onChange={toggleEditable}
        disabled={isEditableDisabled}
        label="Attribute is uneditable"
      />
    </div>
  );
};

export default TypeSection;
