import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import TimeEntryStatusIcon from '@atom/components/common/timeEntryStatusIcon/TimeEntryStatusIcon';
import TeamContext from '@atom/components/teamPortal/TeamContext';
import { TIME_ENTRY_DELETE, TIME_ENTRY_UPDATE } from '@atom/graph/timeEntry';
import { WORK_ORDER_UPDATE } from '@atom/graph/work';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { Icon, Menu, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  TimeEntryDetail,
  TimeEntryStatus,
  TimeEntryUpdate,
  WorkOrderTimeEntry,
} from '@atom/types/timeEntry';
import { WorkOrderUpdate, WorkOrderUpdateInput } from '@atom/types/work';
import {
  doesNotHaveRolePermissions,
  hasRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import {
  CLOSED_STATUS_ID,
  IN_PROGRESS_STATUS_ID,
} from '@atom/utilities/workOrderStatusUtilities';

import './timeSheet.css';

const MenuItem = Menu.MenuItem;

const styles = {
  rejectButton: {
    background: colors.brand.red,
    color: colors.neutral.white,
  },
  modal: {
    padding: '1rem',
  },
};

interface Props {
  timeEntry: TimeEntryDetail;
  disabled: boolean;
  setEditTimeEntryId: (id: string) => void;
  refetchTimeSheet: () => void;
}

const TimeEntryOptions = ({
  timeEntry,
  disabled,
  setEditTimeEntryId,
  refetchTimeSheet,
}: Props) => {
  const userProfile = useUserProfile();
  const { selectedTimeSheetUser, timeEntryApproverUserGroups } = useContext(
    TeamContext,
  );

  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [rejectOpen, setRejectOpen] = useState<boolean>(false);

  const [timeEntryDelete, { loading: timeEntryDeleteLoading }] = useMutation<
    { timeEntryDelete: WorkOrderTimeEntry },
    { id: string }
  >(TIME_ENTRY_DELETE);

  const [timeEntryUpdate, { loading: timeEntryRejectLoading }] = useMutation<
    { timeEntryUpdate: WorkOrderTimeEntry },
    { input: TimeEntryUpdate }
  >(TIME_ENTRY_UPDATE);

  const [updateWorkDetail, { loading: workDetailUpdateLoading }] = useMutation<
    { workOrderUpdate: WorkOrderUpdate },
    { workOrder: WorkOrderUpdateInput }
  >(WORK_ORDER_UPDATE);

  const timeApproverGroupIds = new Set([
    ...timeEntryApproverUserGroups.map(group => group.id),
  ]);

  const canReject =
    (timeEntry.status === TimeEntryStatus.pending &&
      hasRolePermissions(ROLE_SETS.MANAGER)) ||
    (timeEntry.status === TimeEntryStatus.approved &&
      hasRolePermissions(ROLE_SETS.ORG_ADMIN));

  const showReject =
    timeEntry.status !== TimeEntryStatus.draft &&
    timeEntry.status !== TimeEntryStatus.rejected &&
    timeApproverGroupIds.has(timeEntry.userGroupId) &&
    canReject &&
    !R.isNil(selectedTimeSheetUser);

  const disableAction =
    timeEntry.workOrder.statusId === CLOSED_STATUS_ID ||
    timeEntry.status === TimeEntryStatus.approved ||
    (timeEntry.userId !== userProfile.userId &&
      doesNotHaveRolePermissions(ROLE_SETS.MANAGER));

  const handleDelete = async () => {
    await timeEntryDelete({
      variables: {
        id: timeEntry.id,
      },
    });

    refetchTimeSheet();
    setDeleteOpen(false);
  };

  const handleReject = async () => {
    await timeEntryUpdate({
      variables: {
        input: {
          id: timeEntry.id,
          status: TimeEntryStatus.rejected,
        },
      },
    });

    if (timeEntry?.workOrder?.statusId === CLOSED_STATUS_ID) {
      await updateWorkDetail({
        variables: {
          workOrder: {
            id: timeEntry.workOrder.id,
            statusId: IN_PROGRESS_STATUS_ID,
          },
        },
      });
    }

    refetchTimeSheet();
    setRejectOpen(false);
  };

  const rejectContent =
    timeEntry?.workOrder?.statusId === CLOSED_STATUS_ID
      ? 'Are you sure you want to reject this time entry? Rejecting the time entry will reopen work.'
      : ' Are you sure you want to reject this time entry?';

  const rejectModalLoading = timeEntryRejectLoading || workDetailUpdateLoading;

  return (
    <>
      <Menu
        noMinWidth
        disabled={disabled}
        IconButtonProps={{ size: 'small' }}
        data-cy="timeEntryMenu"
      >
        {showReject && (
          <MenuItem
            startAdornment={
              <TimeEntryStatusIcon status={TimeEntryStatus.rejected} />
            }
            onClick={() => setRejectOpen(true)}
            data-cy="timeEntryRejectButton"
          >
            Reject
          </MenuItem>
        )}
        <MenuItem
          startAdornment={<Icon>edit</Icon>}
          onClick={() => setEditTimeEntryId(timeEntry.id)}
          disabled={disableAction}
          data-cy="timeEntryEditButton"
        >
          Edit
        </MenuItem>
        <MenuItem
          startAdornment={<Icon>delete</Icon>}
          onClick={() => setDeleteOpen(true)}
          disabled={disableAction}
          data-cy="timeEntryDeleteButton"
        >
          Delete
        </MenuItem>
      </Menu>
      <Modal
        title="Reject Time Entry"
        onConfirm={handleReject}
        onCancel={() => setRejectOpen(false)}
        confirmButtonText="Reject"
        open={rejectOpen}
        disabled={!showReject}
        loading={rejectModalLoading}
        contentStyle={styles.modal}
        ConfirmButtonProps={{ style: styles.rejectButton }}
        data-cy="timeEntryRejectModal"
      >
        {rejectContent}
      </Modal>
      <DeleteModal
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={handleDelete}
        title="Delete Time Entry"
        content={<div>Are you sure you want to delete this time entry?</div>}
        loading={timeEntryDeleteLoading}
        data-cy="timeEntryDeleteModal"
      />
    </>
  );
};

export default TimeEntryOptions;
