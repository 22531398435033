import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';

import { requestDownloadFiles } from '@atom/actions/fileActions';
import MediaTable from '@atom/components/common/mediaTable/MediaTable';
import MediaTableHeader from '@atom/components/common/mediaTable/MediaTableHeader';
import WorkTemplateContext, {
  WorkTemplateActionTypes,
} from '@atom/components/workTemplate/WorkTemplateContext';
import textConstants from '@atom/constants/textConstants';
import {
  GET_MEDIA,
  MEDIA_BULK_DELETE,
  MEDIA_DELETE,
  MEDIA_UPDATE,
} from '@atom/graph/media';
import { WORK_TEMPLATE_UPDATE } from '@atom/graph/workTemplate';
import { useFileUpload } from '@atom/hooks/useFileUpload';
import { Snackbar } from '@atom/mui';
import {
  Media,
  MediaBulkDeleteInput,
  MediaType,
  MediaUpdateInput,
  SubjectType,
} from '@atom/types/media';
import {
  InheritedComponentType,
  WorkOrderTemplateUpdateInput,
  WorkTemplate,
  WorkTemplateMedia,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import { isComponentInherited } from '@atom/utilities/workTemplateUtilities';

import './workTemplateDocuments.css';

const WorkTemplateDocuments = () => {
  const reduxDispatch = useDispatch();
  const [uploadFiles] = useFileUpload();
  const { workTemplate, dispatch } = useContext(WorkTemplateContext);

  const [media, setMedia] = useState<Media[]>([]);
  const [mediaTotal, setMediaTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(25);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set([]));

  const onCompleted = data => {
    setMedia(data?.media?.media);
    setMediaTotal(data?.media?.totalCount);
  };

  const isInherited = isComponentInherited(
    InheritedComponentType.MEDIA,
    workTemplate?.inheritedComponents || [],
  );

  const parentSubjectIds = isInherited
    ? [workTemplate.id, workTemplate.parentId]
    : [workTemplate.id];

  const { refetch, loading: loadingMedia } = useQuery(GET_MEDIA, {
    variables: {
      input: {
        parentSubjectIds,
        type: MediaType.DOCUMENT,
        page,
        limit,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted,
  });

  const [workTemplateUpdate] = useMutation<
    { workOrderTemplateUpdate: WorkTemplate },
    { input: WorkOrderTemplateUpdateInput }
  >(WORK_TEMPLATE_UPDATE);

  const [mediaUpdate, { loading: loadingUpdate }] = useMutation<
    { mediaUpdate: Media },
    { input: MediaUpdateInput }
  >(MEDIA_UPDATE);

  const [mediaDelete, { loading: loadingDelete }] = useMutation<
    { mediaDelete: boolean },
    { id: string }
  >(MEDIA_DELETE);

  const [mediaBulkDelete, { loading: loadingBulkDelete }] = useMutation<
    { mediaBulkDelete: boolean },
    { input: MediaBulkDeleteInput }
  >(MEDIA_BULK_DELETE);

  const updateWorkTemplateMedia = async (updatedMedia: WorkTemplateMedia[]) => {
    const { data } = await workTemplateUpdate({
      variables: {
        input: {
          workOrderTemplateId: workTemplate.id,
          media: updatedMedia,
        },
      },
    });

    dispatch({
      type: WorkTemplateActionTypes.UPDATE_WORK_TEMPLATE_PROPERTY,
      data: {
        property: 'media',
        value: data?.workOrderTemplateUpdate?.media,
      },
    });
  };

  const toggleMedia = (id: string) => {
    setSelectedItems(toggleFromSet(selectedItems, id));
  };

  const resetToFirstPage = () => {
    if (page === 1) {
      refetch({
        input: {
          parentSubjectIds,
          type: MediaType.DOCUMENT,
          page: 1,
          limit,
        },
      });
    } else {
      setPage(1);
    }
  };

  const handleUpload = async event => {
    const { files } = event.target;
    if (!files.length) {
      return;
    }

    const uploadedMedia = await uploadFiles({
      files,
      subjectId: workTemplate.id,
      parentSubjectId: workTemplate.id,
      subjectType: SubjectType.WORK_ORDER_TEMPLATE,
    });

    await updateWorkTemplateMedia([
      ...workTemplate?.media.map(item => R.omit(['__typename'], item)),
      ...uploadedMedia.map(medium => ({
        fileId: medium.fileId,
        name: medium.name,
        type: medium.type,
        fileExtension: medium.fileExtension,
        mediaId: medium.id,
      })),
    ]);

    refetch();
  };

  const renameMedia = async (id: string, name: string) => {
    const res = await mediaUpdate({
      variables: {
        input: {
          id,
          name,
        },
      },
    });

    const updatedMedia = media.map((mediaItem: Media) => {
      return mediaItem.id === id ? res?.data?.mediaUpdate : mediaItem;
    });

    const otherMedia = workTemplate?.media
      .filter(item => item.type !== MediaType.DOCUMENT)
      .map(item => R.omit(['__typename'], item));

    await updateWorkTemplateMedia([
      ...otherMedia,
      ...updatedMedia.map(medium => ({
        fileId: medium.fileId,
        name: medium.name,
        type: medium.type,
        fileExtension: medium.fileExtension,
        mediaId: medium.id,
      })),
    ]);

    setMedia(updatedMedia);
  };

  const deleteMedia = async (id: string) => {
    try {
      Snackbar.info({
        message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
      });

      await mediaDelete({
        variables: {
          id,
        },
      });

      Snackbar.info({
        message: 'Successfully deleted file',
      });

      await updateWorkTemplateMedia(
        workTemplate.media
          .filter(item => item.mediaId !== id)
          .map(item => R.omit(['__typename'], item)),
      );

      setSelectedItems(new Set([]));
      resetToFirstPage();
    } catch (err) {
      Snackbar.error({
        message: 'Failed to delete file',
      });
    }
  };

  const downloadMultipleFiles = async () => {
    const selectedMedia = media.filter((medium: any) =>
      selectedItems.has(medium.id),
    );

    reduxDispatch(requestDownloadFiles(selectedMedia));
  };

  const deleteMultipleMedia = async () => {
    await mediaBulkDelete({
      variables: {
        input: {
          ids: [...selectedItems],
        },
      },
    });

    await updateWorkTemplateMedia(
      workTemplate.media
        .filter(item => !selectedItems.has(item.mediaId))
        .map(item => R.omit(['__typename'], item)),
    );

    setSelectedItems(new Set([]));
    resetToFirstPage();
  };

  const isEditDisabled =
    workTemplate?.published || doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  const loading =
    loadingMedia || loadingUpdate || loadingDelete || loadingBulkDelete;

  return (
    <div styleName="documents-container">
      <div styleName="center-pane">
        <MediaTableHeader
          type={MediaType.DOCUMENT}
          totalCount={mediaTotal}
          selectedItems={selectedItems}
          isEditDisabled={isEditDisabled}
          isInherited={isInherited}
          handleUpload={handleUpload}
          handleDownloadMultipleFiles={downloadMultipleFiles}
          handleDeleteMultipleMedia={deleteMultipleMedia}
          loading={loading}
        />
        <MediaTable
          media={media}
          totalCount={mediaTotal}
          page={page}
          limit={limit}
          selectedItems={selectedItems}
          setPage={setPage}
          setLimit={setLimit}
          toggleMedia={toggleMedia}
          isEditDisabled={isEditDisabled}
          handleDelete={deleteMedia}
          handleRename={renameMedia}
          isInherited={isInherited}
          inheritedComponents={workTemplate?.inheritedComponents || []}
        />
      </div>
    </div>
  );
};

export default WorkTemplateDocuments;
