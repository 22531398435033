import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { SortDirection } from '@mui/material';
import * as R from 'ramda';

import { Progress } from '@atom/components/common/mui';
import { SEARCH_ANALYTICS } from '@atom/graph/analytics';
import {
  AnalyticsSearchConnection,
  AnalyticsSearchInput,
  AnalyticsSortByOptions,
} from '@atom/types/analytics';

import AnalyticsContext from './AnalyticsContext';
import AnalyticsListEmpty from './AnalyticsListEmpty';
import AnalyticsTable from './AnalyticsTable';

import './analyticsDashboard.css';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 25;

const AnalyticsSearchResults = () => {
  const { query } = useContext(AnalyticsContext);

  const [page, setPage] = useState<number>(INITIAL_PAGE);
  const [limit, setLimit] = useState<number>(INITIAL_LIMIT);
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [sortBy, setSortBy] = useState<AnalyticsSortByOptions>('name');

  const [getData, { data, loading, refetch }] = useLazyQuery<
    { analyticsSearch: AnalyticsSearchConnection },
    { input: AnalyticsSearchInput }
  >(SEARCH_ANALYTICS, {
    variables: {
      input: {
        page,
        limit,
        sortBy,
        isAscending: sortDirection === 'asc',
        query,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (query?.length > 0) {
      getData();
    }
  }, [query]);

  const getSortDirection = (field: string) =>
    field === sortBy ? sortDirection : 'desc';

  const handleSortDirectionChange = (field: AnalyticsSortByOptions) => (
    newSortDirection: SortDirection,
  ) => {
    setSortBy(field);
    setSortDirection(newSortDirection);
  };
  const analyticsCards = data?.analyticsSearch?.analytics || [];
  const totalCount = data?.analyticsSearch?.totalCount || 0;
  const message = R.isEmpty(query)
    ? 'Please Enter a Search Term'
    : 'No Results Found';

  return loading ? (
    <div styleName="loading-container">
      <Progress />
    </div>
  ) : totalCount === 0 ? (
    <AnalyticsListEmpty icon="search" message={message} />
  ) : (
    <AnalyticsTable
      getSortDirection={getSortDirection}
      handleSortDirectionChange={handleSortDirectionChange}
      analyticsCards={analyticsCards}
      refetch={refetch}
      totalCount={totalCount}
      limit={limit}
      setLimit={setLimit}
      page={page}
      setPage={setPage}
    />
  );
};

export default AnalyticsSearchResults;
