import React from 'react';

import { Icon, Menu } from '@atom/mui';
import { TaskField } from '@atom/types/task';

const { MenuItem } = Menu;

interface Props {
  field: TaskField;
  onEdit: (field: TaskField) => void;
  onDelete: (field: TaskField) => void;
}

const styles = {
  icon: {
    marginRight: '0.5rem',
  },
};

const TaskFieldMenu = ({ field, onEdit, onDelete }: Props) => {
  const handleEditClick = (selectedField: TaskField) => {
    onEdit(selectedField);
  };

  const handleDeleteClick = (selectedField: TaskField) => {
    onDelete(selectedField);
  };

  return (
    <Menu>
      <MenuItem onClick={() => handleEditClick(field)}>
        <Icon style={styles.icon}>edit</Icon>
        Edit
      </MenuItem>
      <MenuItem onClick={() => handleDeleteClick(field)}>
        <Icon style={styles.icon}>delete</Icon>
        Delete
      </MenuItem>
    </Menu>
  );
};

export default TaskFieldMenu;
