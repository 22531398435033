import React from 'react';

import { Progress } from '@atom/mui';
import { UserDetail } from '@atom/types/user';

import UserRow from './UserRow';

import './usersTab.css';

interface Props {
  users: UserDetail[];
  loading: boolean;
  errors: any;
}

const UsersList = ({ users, loading, errors }: Props) => (
  <div styleName="users-list">
    {users.map((user: UserDetail) => (
      <UserRow key={user.id} user={user} />
    ))}
    {(loading || errors) && <Progress />}
  </div>
);

export default UsersList;
