import React, { useState } from 'react';
import * as R from 'ramda';

// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import { Modal, TextField } from '@atom/mui';
import { AnalyticsCard, AnalyticsCardUpdateInput } from '@atom/types/analytics';
import { isValidUrl } from '@atom/utilities/validationUtilities';

import { styles } from './CreateAnalyticsCardModal';
import EmbedLinksField from './EmbedLinksField';

import './analyticsCardModal.css';

export interface Props {
  open: boolean;
  onClose: () => void;
  handleUpdateCard: (data: AnalyticsCardUpdateInput) => void;
  card: AnalyticsCard;
}

const EditAnalyticsCardModal = ({
  open,
  onClose,
  handleUpdateCard,
  card,
}: Props) => {
  const [name, setName] = useState<string>(card.name);
  const [embedLinks, setEmbedLinks] = useState<string[]>(card.embedLinks);

  const handleSubmit = (): void => {
    handleUpdateCard({ id: card.id, name, embedLinks });
  };

  const disabled = R.isEmpty(name) || embedLinks.some(url => !isValidUrl(url));

  return (
    <Modal
      title="Edit Card"
      open={open}
      onCancel={onClose}
      onConfirm={handleSubmit}
      onExited={onClose}
      data-cy="editAnalyticsCardModal"
      width="sm"
      disabled={disabled}
    >
      <div style={styles.fieldRow}>
        <div>
          <img src={renameIcon} />
        </div>
        <TextField
          id="card_name_input"
          label="Name"
          value={name}
          onChange={event => setName(event.target.value)}
        />
      </div>
      <EmbedLinksField embedLinks={embedLinks} setEmbedLinks={setEmbedLinks} />
    </Modal>
  );
};

export default EditAnalyticsCardModal;
