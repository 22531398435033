import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import UserGroupContext from '@atom/components/userGroupDetail/UserGroupContext';
import {
  CREATE_USER_GROUP_CERTIFICATION,
  UPDATE_USER_GROUP_CERTIFICATION,
} from '@atom/graph/userGroup';
import { Modal, Progress, TextField } from '@atom/mui';
import {
  UserGroup,
  UserGroupCertification,
  UserGroupCertificationCreateInput,
  UserGroupCertificationUpdateInput,
} from '@atom/types/userGroups';

import './userGroupCerts.css';

const styles = {
  descriptionFieldStyle: {
    paddingTop: '1rem',
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
  isEdit?: boolean;
  certification?: UserGroupCertification;
}

const AddEditCertModal = ({ open, onClose, isEdit, certification }: Props) => {
  const { userGroup } = useContext(UserGroupContext);

  const [name, setName] = useState<string>(isEdit ? certification.name : '');
  const [description, setDescription] = useState<string>(
    isEdit ? certification.description : '',
  );

  useEffect(() => {
    if (open) {
      setName(isEdit ? certification.name : '');
      setDescription(isEdit ? certification.description : '');
    } else {
      setName('');
      setDescription('');
    }
  }, [open]);

  const [createCert, { loading: createCertLoading }] = useMutation<
    { userGroupCertificationCreate: UserGroup },
    { input: UserGroupCertificationCreateInput }
  >(CREATE_USER_GROUP_CERTIFICATION);

  const [updateCert, { loading: updateCertLoading }] = useMutation<
    { userGroupCertificationUpdate: UserGroup },
    { input: UserGroupCertificationUpdateInput }
  >(UPDATE_USER_GROUP_CERTIFICATION);

  const confirm = () => {
    if (isEdit) {
      updateCert({
        variables: {
          input: {
            id: certification.id,
            userGroupId: userGroup.id,
            name,
            description,
          },
        },
      });
    } else {
      createCert({
        variables: {
          input: {
            userGroupId: userGroup.id,
            name,
            description,
          },
        },
      });
    }

    onClose();
  };

  return (
    <Modal
      title={isEdit ? 'Edit Certification' : 'Add Certification'}
      confirmButtonText={isEdit ? 'Save' : 'Add'}
      onConfirm={confirm}
      onCancel={onClose}
      disabled={R.isEmpty(name)}
      open={open}
    >
      {createCertLoading || updateCertLoading ? (
        <Progress />
      ) : (
        <div styleName="modal-container">
          <TextField
            label="Name"
            value={name}
            name="name"
            onChange={event => setName(event.target.value)}
          />
          <TextField
            label="Description"
            style={styles.descriptionFieldStyle}
            value={description}
            name="description"
            onChange={event => setDescription(event.target.value)}
          />
        </div>
      )}
    </Modal>
  );
};

export default AddEditCertModal;
