import * as React from 'react';

import FontIconButton from '@atom/components/common/FontIconButton';
import colors from '@atom/styles/colors';
import { AttributesType } from '@atom/types/inventory';

import '../formBuilder.css';

const styles = {
  icon: {
    padding: '0px',
    marginLeft: '0.5rem',
    height: '35px',
    width: '35px',
  },
};

interface PassedProps {
  fullAttribute: AttributesType;
  isAttributeChecked: boolean;
  selectAll: boolean;
  resetSelectAll: () => void;
  fieldId: string;
  schemaId: string;
  attributeGroupName: string;
  updateAttribute: (
    fieldId: string,
    schemaId: string,
    addAttribute: boolean,
    attributeGroupName: string,
    attribute: AttributesType,
  ) => void;
}

type Props = PassedProps;

interface State {
  checked: boolean;
}

class AttributeBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      checked: props.isAttributeChecked,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { resetSelectAll } = this.props;
    if (nextProps.selectAll) {
      this.setState({ checked: true });
      resetSelectAll();
    }
  }

  toggleCheck = () => {
    const {
      updateAttribute,
      fieldId,
      schemaId,
      attributeGroupName,
      fullAttribute,
    } = this.props;
    const { checked } = this.state;
    const addAttribute = !checked;

    updateAttribute(
      fieldId,
      schemaId,
      addAttribute,
      attributeGroupName,
      fullAttribute,
    );

    this.setState({ checked: !checked });
  };

  render() {
    const { fullAttribute } = this.props;
    const { checked } = this.state;

    const color = checked ? colors.brand.blue : colors.neutral.gray;
    const backgroundColor = checked
      ? colors.neutral.ash
      : colors.neutral.typhoon;

    return (
      <div
        styleName="attribute-block"
        style={{ backgroundColor }}
        onClick={this.toggleCheck}
      >
        <div>{fullAttribute.name}</div>
        <FontIconButton
          style={styles.icon}
          onClick={this.toggleCheck}
          color={color}
          icon="check"
        />
      </div>
    );
  }
}

export default AttributeBlock;
