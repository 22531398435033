import * as React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { bindActionCreators } from 'redux';

import * as fileActionCreators from '@atom/actions/fileActions';
import * as mediaActionCreators from '@atom/actions/mediaActions';
import { Icon, IconButton, Modal, Select } from '@atom/mui';
import colors from '@atom/styles/colors';
import { FileActions, MediaActions } from '@atom/types/actions';
import { UserProfile } from '@atom/types/user';
import { addItem, removeItem } from '@atom/utilities/listViewTableUtilities';

import ElementMediaTable from './ElementMediaTable';

import './elements.css';

const { MenuItem: SelectMenuItem } = Select;

const headerButtonStyle = {
  root: {
    marginRight: '1em',
    width: '2em',
    minWidth: '2em',
  },
  icon: {
    color: colors.neutral.gray,
  },
  input: {
    display: 'none',
  },
};

const styles = {
  deleteModalConfirmButton: {
    backgroundColor: colors.brand.red,
  },
  selectContainer: {
    width: 'auto',
    minWidth: '9rem',
  },
};

const initialState = {
  selectedItems: [],
  selectedType: 'All Files',
  name: '',
  isDeleteModalOpen: false,
  mediaCount: 0,
};

interface Props {
  subjectId: string;
  parentSubjectId?: string;
  subjectType: string;
  mediaActions: MediaActions;
  fileActions: FileActions;
  user: UserProfile;
  mediaTotals?: object;
  media: any[];
  loadingMedia?: boolean;
  canCreateMedia: boolean;
  canUpdateMedia: boolean;
  canDeleteMedia: boolean;
}

class ElementMedia extends React.Component<Props> {
  state = initialState;

  componentDidMount() {
    const {
      mediaActions,
      subjectId,
      subjectType,
      parentSubjectId,
    } = this.props;

    mediaActions.retrieveMedia({
      subjectIds: subjectId,
      subjectTypes: subjectType,
      ...(parentSubjectId ? { parentSubjectId } : {}),
    });
    mediaActions.requestMediaTotals({
      subjectIds: subjectId,
      subjectTypes: subjectType,
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.media !== nextProps.media) {
      this.setState({ mediaCount: R.length(nextProps.media) });
    }
  }

  componentWillUnmount() {
    this.setState({ mediaCount: 0 });
  }

  openDeleteModal = () => {
    this.setState({ isDeleteModalOpen: true });
  };

  closeDeleteModal = () => {
    this.setState({ isDeleteModalOpen: false });
  };

  onNameChange = event => {
    const { value } = event.target;

    this.setState({ name: value });
  };

  getTypeByName = name => {
    if (name === 'documents') {
      return 'document';
    }
    if (name === 'photos') {
      return 'image';
    }
    if (name === 'videos') {
      return 'video';
    }
    return null;
  };

  onTypeChange = event => {
    const {
      mediaActions,
      subjectId,
      subjectType,
      parentSubjectId,
    } = this.props;
    const type = this.getTypeByName(event.target.value as string);

    this.setState({ selectedType: event.target.value, mediaCount: 0 });

    mediaActions.retrieveMedia({
      subjectIds: subjectId,
      subjectTypes: subjectType,
      ...(parentSubjectId && { parentSubjectId }),
      type,
    });
  };

  uploadFiles = event => {
    const { fileActions, subjectId, subjectType, parentSubjectId } = this.props;
    const files = event.target.files;
    const widths = [180, 1440];

    const data = {
      files,
      widths,
      subjectId,
      subjectType,
      ...(parentSubjectId ? { parentSubjectId } : {}),
      queryParams: {
        subjectIds: subjectId,
        subjectTypes: subjectType,
        ...(parentSubjectId ? { parentSubjectId } : {}),
      },
    };

    fileActions.requestUploadFiles(data);
  };

  deleteMedia = id => {
    const { mediaActions, subjectId, subjectType } = this.props;
    const data = {
      id,
      subjectId,
      subjectType,
    };
    mediaActions.requestDeleteMedia(data);
    return true;
  };

  renameMedia = (id: string, name: string) => {
    const { mediaActions } = this.props;

    const data = {
      id,
      name,
    };

    return mediaActions.requestPatchMedia(data);
  };

  toggleCheck = (itemId, isChecked) => {
    // eslint-disable-next-line
    isChecked
      ? this.setState(removeItem(itemId))
      : this.setState(addItem(itemId));
  };

  downloadMultipleFiles = () => {
    const { fileActions, media } = this.props;
    const { selectedItems } = this.state;

    const selectedMedia = media.filter(medium =>
      selectedItems.includes(medium.id),
    );

    fileActions.requestDownloadFiles(selectedMedia);
  };

  deleteMultipleFiles = () => {
    const { mediaActions, subjectId, subjectType } = this.props;
    const { selectedItems } = this.state;

    selectedItems.forEach(id => {
      const data = {
        id,
        subjectId,
        subjectType,
      };
      mediaActions.requestDeleteMedia(data);
    });
    this.setState({ selectedItems: [] });
  };

  getHeaderButtons = () => {
    const { canDeleteMedia, canCreateMedia } = this.props;
    const { selectedItems, isDeleteModalOpen } = this.state;

    const bulkButtons = (
      <div styleName="files-controls">
        <IconButton
          style={headerButtonStyle.root}
          onClick={this.downloadMultipleFiles}
        >
          <Icon color={headerButtonStyle.icon.color}>file_download</Icon>
        </IconButton>
        {canDeleteMedia && (
          <>
            <IconButton
              style={headerButtonStyle.root}
              onClick={() => {
                this.openDeleteModal();
              }}
            >
              <Icon color={headerButtonStyle.icon.color}>delete</Icon>
            </IconButton>
            <Modal
              open={isDeleteModalOpen}
              title={'Delete Files'}
              onCancel={() => this.closeDeleteModal()}
              confirmButtonText="Delete"
              onConfirm={() => {
                this.deleteMultipleFiles();
                this.closeDeleteModal();
              }}
              ConfirmButtonProps={{
                style: styles.deleteModalConfirmButton,
              }}
            >
              Are you sure you want to delete these files?
            </Modal>
          </>
        )}
      </div>
    );

    const headerButtons = (
      <div styleName="files-controls">
        {canCreateMedia && (
          <IconButton component="label">
            <Icon color={headerButtonStyle.icon.color}>file_upload</Icon>
            <input
              type="file"
              style={headerButtonStyle.input}
              multiple
              onChange={this.uploadFiles}
            />
          </IconButton>
        )}
      </div>
    );

    return R.isEmpty(selectedItems) ? headerButtons : bulkButtons;
  };

  render() {
    const {
      media,
      user,
      loadingMedia,
      mediaTotals,
      canUpdateMedia,
      canDeleteMedia,
    } = this.props;
    const { selectedItems, selectedType, mediaCount } = this.state;

    const headerButtons = this.getHeaderButtons();
    const dropdownOptions = [
      'All Files',
      // @ts-ignore
      ...R.keys(mediaTotals).map(type => mediaTotals[type].name),
    ];
    const mediaType = selectedType === 'All Files' ? 'Files' : selectedType;

    return (
      <div styleName="files-container">
        <div styleName="files-header">
          <div styleName="dropdown-container">
            <Select
              value={selectedType}
              onChange={this.onTypeChange}
              style={styles.selectContainer}
            >
              {dropdownOptions.map(type => (
                <SelectMenuItem key={type} value={type}>
                  {type}
                </SelectMenuItem>
              ))}
            </Select>
            <div styleName="media-total-text">{`${mediaCount} ${mediaType}`}</div>
          </div>
          {headerButtons}
        </div>
        <ElementMediaTable
          media={media}
          loading={loadingMedia}
          userProfile={user}
          renameMedia={this.renameMedia}
          deleteMedia={this.deleteMedia}
          toggleCheck={this.toggleCheck}
          selectedItems={selectedItems}
          canUpdateMedia={canUpdateMedia}
          canDeleteMedia={canDeleteMedia}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  mediaTotals: state.mediaTotals,
  media: state.media,
  user: state.userProfile,
  loadingMedia: state.loading.loadingMedia,
});

const mapDispatchToProps = dispatch => ({
  mediaActions: bindActionCreators(mediaActionCreators, dispatch),
  fileActions: bindActionCreators(fileActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ElementMedia);
