import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { debounce } from 'lodash';
import * as R from 'ramda';

import CustomFieldEnumMultipleEdit from '@atom/components/common/customFieldEnumEdit/customFieldEnumMultipleEdit';
import CustomFieldEnumNestedEdit from '@atom/components/common/customFieldEnumEdit/CustomFieldEnumNestedEdit';
import CustomFieldEnumRootEdit from '@atom/components/common/customFieldEnumEdit/CustomFieldEnumRootEdit';
import { Icon, IconButton, Modal, Select, TextField } from '@atom/mui';
import { customFieldUnitsSelector } from '@atom/selectors/preferencesSelectors';
import { DataType, WorkOrderFieldDataType } from '@atom/types/dataType';
import {
  SubField,
  ValueDictionary,
  WORK_ORDER_FIELD_DATA_TYPE_OPTIONS,
  WorkOrderDropdownField,
  WorkOrderField,
  WorkOrderFieldCreatePayload,
} from '@atom/types/work';
import {
  isNilOrEmpty,
  isValidUrlWithMessage,
  MAX_HYPERLINK_CHARS,
  VALIDATE_HYPERLINK_DEBOUNCE_MS,
} from '@atom/utilities/validationUtilities';
import {
  getActiveSubFieldPathLabel,
  getNestedDepth,
  getPath,
  hydrateDropdownField,
  mapDropdownField,
  removeSubFieldByMatchValue,
  updateSubFields,
} from '@atom/utilities/workOrderFieldUtilities';

import './workOrderFields.css';

const { MenuItem } = Select;

const enum ModalView {
  ROOT = 'ROOT',
  NESTED = 'NESTED',
}

const initialDropdownField: WorkOrderDropdownField = {
  enumeration: ['', ''],
  subFields: [],
};

const styles = {
  inputContainer: {
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  inputContainerInline: {
    display: 'inline-block',
    width: '48%',
    marginTop: '1rem',
    marginRight: '1rem',
  },
  fieldTypeSelect: {
    width: '65%',
  },
  unitSelect: {
    display: 'inline-block',
    width: '48%',
    marginTop: '1rem',
  },
  contentStyle: {
    padding: '3rem',
    overflow: 'auto',
  },
  upper: {
    textTransform: 'uppercase',
  },
};

const useClasses = makeStyles({
  upper: {
    textTransform: 'uppercase',
  },
});

interface Props {
  fields: WorkOrderField[];
  handleCreate: (payload: WorkOrderFieldCreatePayload) => void;
  loadingCreate: boolean;
  onClose: () => void;
  open: boolean;
  isWorkTemplate?: boolean;
  errorText?: string;
}

const WorkOrderFieldCreateModal = ({
  fields,
  handleCreate,
  loadingCreate,
  onClose,
  open,
  isWorkTemplate = false,
  errorText = '',
}: Props) => {
  const unitTypes = useSelector(customFieldUnitsSelector);
  const classes = useClasses();

  const [view, setView] = useState<ModalView>(ModalView.ROOT);
  const [title, setTitle] = useState<string>('');
  const [hyperlink, setHyperlink] = useState<string>('');
  const [urlErrorMsg, setUrlErrorMsg] = useState<string>('');
  const [dataType, setDataType] = useState<WorkOrderFieldDataType>(
    DataType.SUMMARY,
  );
  const [value, setValue] = useState<string>('');
  const [dropdownField, setDropdownField] = useState<WorkOrderDropdownField>();
  const [activeKey, setActiveKey] = useState<string>();
  const [activePath, setActivePath] = useState<any[]>([]);

  const [valueDictionary, setValueDictionary] = useState<ValueDictionary>();
  const [enumeration, setEnumeration] = useState<string[]>([]);
  const [subFields, setSubFields] = useState<SubField[]>([]);
  const [units, setUnits] = useState<string>('');

  // Applies value dictionary to mapped field tree to produce
  // the properly hydrated field tree.
  useEffect(() => {
    const hydratedField = hydrateDropdownField(
      { enumeration, subFields },
      valueDictionary,
    );

    setDropdownField(hydratedField);
  }, [enumeration, subFields, valueDictionary]);

  useEffect(() => {
    const newActivePath = getPath(activeKey, subFields);
    const newView =
      R.length(newActivePath) === 0 ? ModalView.ROOT : ModalView.NESTED;

    setActivePath(newActivePath);
    setView(newView);
  }, [activeKey]);

  const resetState = () => {
    setDataType(DataType.SUMMARY);
    setView(ModalView.ROOT);
    setTitle('');
    setValue('');
    setHyperlink('');
    setDropdownField(null);
    setValueDictionary(null);
    setEnumeration([]);
    setSubFields([]);
    setActivePath([]);
    setActiveKey(null);
    setUnits('');
  };

  const onDataTypeChange = (type: WorkOrderFieldDataType) => {
    setTitle('');
    setValue('');
    setDataType(type);

    if (type === DataType.ENUM_SINGLE || type === DataType.ENUM_MULTIPLE) {
      // Transforms field tree into mapped field tree with value
      // dictionary for ease of create and edit.
      const mappedField = mapDropdownField(initialDropdownField);

      setDropdownField(initialDropdownField);
      setValueDictionary(mappedField.valueDictionary);
      setEnumeration(mappedField.enumeration);
      setSubFields(mappedField.subFields);
    } else {
      setDropdownField(null);
    }
  };

  const handleSubmit = () => {
    handleCreate({
      dataType,
      title,
      ...R.reject(isNilOrEmpty, {
        value,
        units,
        hyperlink,
        enumeration: dropdownField?.enumeration,
        subFields: dropdownField?.subFields,
      }),
    });
  };

  const debouncedSetHyperlink = useMemo(
    () =>
      debounce(event => {
        setHyperlink(event.target.value);
      }, VALIDATE_HYPERLINK_DEBOUNCE_MS),
    [],
  );

  const isNameInUse = useMemo(() => {
    return fields.some(
      field => field.title.toLowerCase() === title.toLowerCase(),
    )
      ? 'This name is already in use.'
      : '';
  }, [title, fields]);

  const isValid = useMemo(() => {
    const validEnums = dropdownField?.enumeration.reduce(
      (acc, option) => (!R.isEmpty(option.trim()) ? acc + 1 : acc),
      0,
    );

    const isNameValid = isWorkTemplate || !isNameInUse;
    const [isURLValid, urlError] = isValidUrlWithMessage(hyperlink);
    setUrlErrorMsg(urlError);
    const isHyperlinkValid =
      dataType === DataType.HYPERLINK
        ? !R.isEmpty(hyperlink) &&
          isURLValid &&
          !R.isEmpty(value) &&
          value?.length <= MAX_HYPERLINK_CHARS
        : true;

    return (
      isNameValid &&
      isHyperlinkValid &&
      !R.isEmpty(title) &&
      (dataType !== DataType.ENUM_SINGLE || validEnums >= 2) &&
      (dataType !== DataType.ENUM_MULTIPLE || validEnums >= 2)
    );
  }, [title, value, hyperlink, isNameInUse, dropdownField, isWorkTemplate]);

  const navigateBack = async () => {
    // drops the last 3 entries in the activePath in order
    // to access the parent nested field;
    const parentPath = R.dropLast(3, activePath);
    const parent: SubField = R.view(R.lensPath(parentPath), subFields);
    await setActiveKey(parent.matchValue);
  };

  const updateSubField = (subField: SubField) => {
    setSubFields(updateSubFields(subField, subFields));
  };

  const removeSubField = async (key: string) => {
    await navigateBack();
    setSubFields(removeSubFieldByMatchValue(subFields, key));
  };

  const activeSubField = R.view(R.lensPath(activePath), subFields);
  const nestedDepth = getNestedDepth(activeKey, subFields);
  const pathLabel = getActiveSubFieldPathLabel(
    title,
    activePath,
    subFields,
    valueDictionary,
  );

  const modalTitle =
    view === ModalView.ROOT ? (
      'Add Field'
    ) : (
      <div styleName="modal-title">
        <IconButton onClick={navigateBack} size="small">
          <Icon>arrow_back</Icon>
        </IconButton>
        <div styleName="modal-title-path">{pathLabel}</div>
      </div>
    );

  const error = isWorkTemplate ? errorText : isNameInUse;

  const content = {
    [ModalView.ROOT]: (
      <>
        <Select
          label="Field Type"
          value={dataType}
          style={styles.fieldTypeSelect}
          onChange={event => onDataTypeChange(event.target.value)}
        >
          {WORK_ORDER_FIELD_DATA_TYPE_OPTIONS.map(option => (
            <MenuItem key={option.dataType} value={option.dataType}>
              <div styleName="menu-item">
                <Icon style={{ marginRight: '0.5rem' }}>{option.icon}</Icon>
                {option.text}
              </div>
            </MenuItem>
          ))}
        </Select>
        <TextField
          id="title"
          label="Title"
          value={title}
          onChange={event => setTitle(event.target.value)}
          style={
            dataType === DataType.NUMBER
              ? styles.inputContainerInline
              : styles.inputContainer
          }
          // ensure modal is open to prevent "flash" of error during close animation
          error={open && !!error}
          helperText={open ? error : ''}
          required
        />
        {dataType === DataType.NUMBER && (
          <Select
            label="Unit"
            value={units || ''}
            style={styles.unitSelect}
            classes={{ root: classes.upper }}
            onChange={event => setUnits(event.target.value)}
          >
            <MenuItem value="" />
            {unitTypes.map(type => (
              <MenuItem key={type.unit} value={type.unit} style={styles.upper}>
                {type.unit}
              </MenuItem>
            ))}
          </Select>
        )}
        {dataType === DataType.SUMMARY && (
          <TextField
            id="description"
            label="Description Text"
            value={value}
            onChange={event => setValue(event.target.value)}
            style={styles.inputContainer}
            multiline
          />
        )}
        {dataType === DataType.ENUM_SINGLE && (
          <CustomFieldEnumRootEdit
            valueDictionary={valueDictionary}
            setValueDictionary={setValueDictionary}
            enumeration={enumeration}
            setEnumeration={setEnumeration}
            subFields={subFields}
            setSubFields={setSubFields}
            setActiveKey={setActiveKey}
          />
        )}
        {dataType === DataType.ENUM_MULTIPLE && (
          <CustomFieldEnumMultipleEdit
            valueDictionary={valueDictionary}
            setValueDictionary={setValueDictionary}
            enumeration={enumeration}
            setEnumeration={setEnumeration}
          />
        )}
        {dataType === DataType.HYPERLINK && (
          <>
            <TextField
              id="hyperlink"
              label="URL"
              defaultValue={hyperlink}
              onChange={debouncedSetHyperlink}
              style={styles.inputContainer}
              error={urlErrorMsg.length > 0}
              helperText={urlErrorMsg}
              required
            />
            <TextField
              id="text_to_display"
              label="Text to Display"
              value={value}
              onChange={event => setValue(event.target.value)}
              style={styles.inputContainer}
              helperText={`${value?.length}/${MAX_HYPERLINK_CHARS}`}
              error={value?.length > MAX_HYPERLINK_CHARS}
              FormHelperTextProps={{ style: { textAlign: 'right' } }}
              required
            />
          </>
        )}
      </>
    ),
    [ModalView.NESTED]: (
      <CustomFieldEnumNestedEdit
        // @ts-ignore
        subField={activeSubField}
        updateSubField={updateSubField}
        valueDictionary={valueDictionary}
        setValueDictionary={setValueDictionary}
        removeSubField={removeSubField}
        setActiveKey={setActiveKey}
        nestedDepth={nestedDepth}
      />
    ),
  };

  return (
    <Modal
      title={modalTitle}
      open={open}
      onExited={resetState}
      onCancel={onClose}
      onConfirm={handleSubmit}
      confirmButtonText="Add"
      loading={loadingCreate}
      disabled={!isValid}
      contentStyle={styles.contentStyle}
      disableFooter={view === ModalView.NESTED}
    >
      {content[view]}
    </Modal>
  );
};

export default WorkOrderFieldCreateModal;
