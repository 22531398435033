import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import { UserGroup, UserGroupsConnectionInput } from 'client/types/userGroups';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import AddTeamModalContext, {
  SelectedUserGroup,
  View,
} from '@atom/components/common/workOrderDetail/addTeamModal/AddTeamModalContext';
import client from '@atom/graph/client';
import { CREATE_FAVORITE, UPDATE_FAVORITE } from '@atom/graph/favorite';
import {
  CREATE_FAVORITES_LIST,
  GET_FAVORITES_LIST_TREE,
} from '@atom/graph/favoritesList';
import { GET_USERS } from '@atom/graph/user';
import { GET_USER_GROUP_TREE, GET_USER_GROUPS } from '@atom/graph/userGroup';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { Icon, Modal, Progress, Snackbar, TextField } from '@atom/mui';
import fonts from '@atom/styles/fonts';
import {
  FavoriteCreateInput,
  FavoriteDetail,
  FavoritesList,
  FavoritesListCreateInput,
  FavoritesListsTreeConnectionInput,
  FavoritesListTreeConnection,
  FavoriteSubjectType,
  FavoriteUpdateInput,
  flattenFavoriteData,
} from '@atom/types/favorite';
import { TaskUserGroupInputItem } from '@atom/types/task';
import {
  UserDetail,
  UsersConnection,
  UsersConnectionInput,
} from '@atom/types/user';
import {
  UserGroupsConnection,
  UserGroupTreeType,
} from '@atom/types/userGroups';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import SelectedSection from './SelectedSection';
import TabSection from './TabSection';

import './addTeamModal.css';

const useStyles = makeStyles({
  root: {
    fontSize: fonts.lg,
    height: '3rem',
    paddingLeft: '1rem',
  },
  input: {
    paddingLeft: '1rem',
  },
});

const styles = {
  progress: {
    height: '100%',
    alignItems: 'center',
  },
};

const LIMIT = 25;
const SEARCH_LIMIT = 250;
const DEBOUNCE_TIME = 500;
const SEARCH_PAGE = 1;
const HTTP_STATUS_CONFLICT = 409;

const baseUserGroup = {
  id: 'root',
  name: 'directory',
  colorId: 0,
  userGroups: [],
};

export interface Props {
  groupsEnabled?: boolean;
  open: boolean;
  userGroupsOnTask: string[];
  usersOnTask: string[];
  onClose: () => void;
  handleAddUsers: (users: UserDetail[]) => void;
  handleAddUserGroups: (userGroups: TaskUserGroupInputItem[]) => void;
  loading: boolean;
  favoritesEnabled: boolean;
}

const AddTeamModal = ({
  groupsEnabled = true,
  open,
  userGroupsOnTask,
  usersOnTask,
  onClose,
  handleAddUsers,
  handleAddUserGroups,
  loading,
  favoritesEnabled,
}: Props) => {
  const userProfile = useUserProfile();
  const ref = useRef(null);
  const classes = useStyles();

  const defaultView = favoritesEnabled ? View.FAVORITES : View.USERS;
  const [activeView, setActiveView] = useState<View>(defaultView);
  const [search, setSearch] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<UserDetail[]>([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState<
    SelectedUserGroup[]
  >([]);
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
  const [userGroupsLoading, setUserGroupsLoading] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<Set<string>>(new Set(['root']));
  const [users, setUsers] = useState<any>([]);
  const [searchUsers, setSearchUsers] = useState<any>([]);
  const [favoriteUsers, setFavoriteUsers] = useState<any>([]);
  const [favoritesLists, setFavoritesLists] = useState<FavoritesList[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [uniqueFavoritesCount, setUniqueFavoritesCount] = useState<number>();

  const [getUsers, { loading: usersLoading, error: usersError }] = useLazyQuery<
    { users: UsersConnection },
    { input: UsersConnectionInput }
  >(GET_USERS, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const nextUsers = R.pathOr([], ['users', 'users'], data);

      if (search) {
        setSearchUsers(nextUsers);
      } else {
        setUsers([...users, ...nextUsers]);
        setTotal(data?.users?.totalCount || 0);
      }
    },
  });

  const [
    getFavoritesListTree,
    { loading: favoritesLoading, error: favoritesError },
  ] = useLazyQuery<
    { favoritesListTree: FavoritesListTreeConnection },
    { input: FavoritesListsTreeConnectionInput }
  >(GET_FAVORITES_LIST_TREE, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const favoritesListsResponse = R.pathOr(
        [],
        ['favoritesListTree', 'favoritesLists'],
        data,
      );

      const favoritesListsData = favoritesListsResponse.map(list => ({
        ...list,
        favorites: list.favorites.map(flattenFavoriteData),
      }));
      const allFavorites = R.uniq(
        favoritesListsData.reduce((acc, currentList) => {
          return isNilOrEmpty(currentList.favorites)
            ? acc
            : [...acc, ...currentList.favorites];
        }, []),
      );
      setUniqueFavoritesCount(data.favoritesListTree.totalUniqueFavorites);
      setFavoritesLists(favoritesListsData);
      setFavoriteUsers(allFavorites);
    },
  });

  const [
    getUserGroupTree,
    { data: userGroupTreeData, loading: treeLoading },
  ] = useLazyQuery<{
    userGroupsTree: UserGroupTreeType;
  }>(GET_USER_GROUP_TREE, {
    fetchPolicy: 'network-only',
  });

  const getUserGroups = async (name: string) => {
    if (name) {
      setUserGroupsLoading(true);

      const { data } = await client.query<
        { userGroups: UserGroupsConnection },
        { input: UserGroupsConnectionInput }
      >({
        query: GET_USER_GROUPS,
        variables: {
          input: {
            limit: SEARCH_LIMIT,
            page: SEARCH_PAGE,
            name,
          },
        },
      });

      setUserGroupsLoading(false);
      setUserGroups(data?.userGroups?.userGroups);
    }
  };

  const handleGetUsers = async () => {
    await getUsers({
      variables: {
        input: {
          showAdmin: hasRolePermissions(ROLE_SETS.ADMIN),
          limit: search ? SEARCH_LIMIT : LIMIT,
          page,
          ...(search && { name: search }),
        },
      },
    });
  };

  const handleGetFavorites = async () => {
    await getFavoritesListTree({
      variables: {
        input: {
          userId: userProfile.userId,
          subjectType: FavoriteSubjectType.USER,
        },
      },
    });
  };

  const [createList] = useMutation<{ input: FavoritesListCreateInput }>(
    CREATE_FAVORITES_LIST,
    {
      onCompleted: () => handleGetFavorites(),
    },
  );

  const [createFavorite] = useMutation<{ input: FavoriteCreateInput }>(
    CREATE_FAVORITE,
    {
      onCompleted: () => handleGetFavorites(),
    },
  );

  const [updateFavorite] = useMutation<{ input: FavoriteUpdateInput }>(
    UPDATE_FAVORITE,
    {
      onCompleted: () => handleGetFavorites(),
    },
  );

  const handleCreateFavorite = async user => {
    try {
      await createFavorite({
        variables: {
          input: {
            userId: userProfile.userId,
            subjectId: user.id,
            subjectType: FavoriteSubjectType.USER,
            subjectName: `${user.firstName} ${user.lastName}`,
            listIds: user.listIds,
          },
        },
      });
    } catch (err) {
      const message =
        err?.networkError?.statusCode === HTTP_STATUS_CONFLICT
          ? 'You have already favorited this item.'
          : 'Failed to add favorite. Please try again.';

      Snackbar.error({ message });
    }
  };

  const handleUpdateFavorite = async (favorite: FavoriteDetail) => {
    try {
      await updateFavorite({
        variables: {
          input: {
            userId: userProfile.userId,
            id: favorite.favoriteId,
            listIds: favorite.listIds || [],
          },
        },
      });
    } catch (err) {
      Snackbar.error({
        message: 'Failed to update favorite. Please try again.',
      });
    }
  };

  const handleAddList = async listName => {
    try {
      await createList({
        variables: {
          input: {
            userId: userProfile.userId,
            subjectType: FavoriteSubjectType.USER,
            name: listName,
          },
        },
      });
    } catch (err) {
      const status = err?.networkError?.statusCode;
      let message = 'Failed to add list. Please try again.';
      if (status === 409) {
        message = 'A list with that name already exists';
      }
      Snackbar.error({ message });
    }
  };

  useEffect(() => {
    if (open) {
      if (groupsEnabled) {
        getUserGroupTree();
      }
      handleGetUsers();
      handleGetFavorites();
    } else {
      setActiveView(defaultView);
      setFavoriteUsers([]);
      setUsers([]);
      setPage(1);
      setTotal(0);
      setSearch('');
      setSelectedUsers([]);
      if (groupsEnabled) {
        setSelectedUserGroups([]);
      }
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      if (R.isEmpty(search) || R.isNil(search)) {
        setActiveView(defaultView);
        setSearchUsers([]);
        handleGetUsers();
      } else {
        setActiveView(View.SEARCH);
        setUsers([]);
        handleGetUsers();
      }
    }
  }, [search, open]);

  useEffect(() => {
    if (open) {
      handleGetUsers();
    }
  }, [page, open]);

  const userGroupTree = R.pathOr(
    baseUserGroup,
    ['userGroupsTree'],
    userGroupTreeData,
  );

  const addUserToCart = (user: UserDetail) => {
    const selectedUserIds = selectedUsers.map(group => group.id);

    if (!selectedUserIds.includes(user.id)) {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const removeUserFromCart = (userId: string) => {
    const updatedSelectedUsers = selectedUsers.filter(
      selectedUser => selectedUser.id !== userId,
    );

    setSelectedUsers(updatedSelectedUsers);
  };

  const addUserGroupToCart = (userGroup: UserGroupTreeType) => {
    const selectedUserGroupIds = selectedUserGroups.map(group => group.id);

    if (!selectedUserGroupIds.includes(userGroup.id)) {
      setSelectedUserGroups([
        ...selectedUserGroups,
        { ...userGroup, quantity: 0 },
      ]);
    }
  };

  const removeUserGroupFromCart = (userGroupId: string) => {
    const updatedSelectedUserGroups = selectedUserGroups.filter(
      selectedUserGroup => selectedUserGroup.id !== userGroupId,
    );

    setSelectedUserGroups(updatedSelectedUserGroups);
  };

  const updateUserGroupQuantity = (userGroupId: string, quantity: number) => {
    if (quantity < 0) {
      return;
    }

    const updatedSelectedUserGroups = selectedUserGroups.map(
      selectedUserGroup =>
        selectedUserGroup.id === userGroupId
          ? { ...selectedUserGroup, quantity }
          : selectedUserGroup,
    );

    setSelectedUserGroups(updatedSelectedUserGroups);
  };

  const toggleExpanded = (id: string) => {
    if (expanded.has(id)) {
      expanded.delete(id);
      setExpanded(new Set([...expanded]));
    } else {
      setExpanded(new Set([...expanded.add(id)]));
    }
  };

  const handleSearch = useCallback(
    debounce(value => {
      setSearch(value);
      setPage(1);
      if (groupsEnabled) {
        getUserGroups(value);
      }
    }, DEBOUNCE_TIME),
    [],
  );

  const addUsers = () => {
    handleAddUsers(selectedUsers);
  };

  const addUserGroups = () => {
    handleAddUserGroups(
      selectedUserGroups.map((userGroup: SelectedUserGroup) => ({
        userGroupId: userGroup.id,
        quantity: userGroup.quantity || 0,
      })),
    );
  };

  const onConfirm = async () => {
    await Promise.all([
      ...(!R.isEmpty(selectedUsers) && [addUsers()]),
      ...(!R.isEmpty(selectedUserGroups) && [addUserGroups()]),
    ]);

    onClose();
  };

  const contextValue = {
    activeView,
    expanded,
    toggleExpanded,
    setActiveView,
    userGroupTree,
    selectedUsers,
    selectedUserGroups,
    addUserToCart,
    removeUserFromCart,
    addUserGroupToCart,
    removeUserGroupFromCart,
    updateUserGroupQuantity,
    userGroupsOnTask,
    usersOnTask,
    users,
    setPage,
    total,
    favoritesLoading,
    usersLoading,
    page,
    favoritesError,
    usersError,
    ref,
    addLoading: loading,
    userGroups,
    userGroupsLoading,
    favoriteUsers,
    searchUsers,
    updateFavorite: handleUpdateFavorite,
    createFavorite: handleCreateFavorite,
    favoritesEnabled,
    favoritesLists,
    uniqueFavoritesCount,
    handleAddList,
    setSelectedUsers,
    handleGetFavorites,
  };

  return (
    <Modal
      title="Add Team"
      width="lg"
      onConfirm={onConfirm}
      confirmButtonText="Add"
      onCancel={onClose}
      open={open}
    >
      <AddTeamModalContext.Provider value={contextValue}>
        <div>
          <div styleName="search-content">
            <div styleName="search-box">
              <TextField
                InputProps={{
                  startAdornment: <Icon>search</Icon>,
                  classes: {
                    root: classes.root,
                    input: classes.input,
                  },
                }}
                placeholder={
                  groupsEnabled
                    ? 'Search all users or groups'
                    : 'Search all users'
                }
                name="search"
                onChange={event => handleSearch(event.target.value)}
              />
            </div>
          </div>
          <div styleName="content-container" ref={ref}>
            <SelectedSection />
            {treeLoading || (R.isEmpty(users) && !search) ? (
              <Progress style={styles.progress} />
            ) : (
              <TabSection groupsEnabled={groupsEnabled} />
            )}
          </div>
        </div>
      </AddTeamModalContext.Provider>
    </Modal>
  );
};

export default AddTeamModal;
