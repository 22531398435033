import React from 'react';
import {
  StepButton as MUIStepButton,
  StepButtonProps as MUIStepButtonProps,
} from '@mui/material';

export type StepButtonProps = MUIStepButtonProps;

export const StepLabel = (props: StepButtonProps) => {
  return <MUIStepButton {...props} />;
};

export default StepLabel;
