import { gql } from '@apollo/client';

import { BASE_TASK, BASE_TASK_ITEM } from '@atom/graph/task';

export const BASE_WORK_ORDER_FIELD = gql`
  fragment BaseWorkOrderField on WorkOrderField {
    id
    dataType
    title
    required
    value
    enumeration
    defaultValue
    subFieldsTruncated
    subFields {
      matchValue
      nestedField
    }
    units
    hyperlink
  }
`;

export const BASE_WORK_ORDER = gql`
  fragment BaseWorkOrder on WorkOrder {
    id
    type
    name
    originalName
    inventoryAssetId
    inventoryAssetName
    inventoryAssetSchemaId
    inventoryAssetLocation
    inventoryAssetAddress
    inventoryAssetMarkerId
    inventoryAssetActions
    inventoryAssetGrants {
      type
      ids
    }
    isClosed
    dueDate
    completionDate
    startTime
    duration
    workTime
    actualCost
    estimatedCost
    reopened

    workTemplateName
    workTemplateFolderPath {
      name
    }
    workTemplateFolderActions
    workTemplateFolderGrants {
      type
      ids
    }
    workTemplateId
    workTemplate {
      id
      name
      schemaId
    }

    location
    isLocationBased

    statusId
    priorityId

    mainPhotoId
    mainPhotoFileId

    leadAssigneeId
    leadAssigneeName
    leadAssigneeUser {
      id
      firstName
      lastName
      employeeId
    }

    closedDate
    closedByName
    closedByUser {
      id
      firstName
      lastName
      employeeId
    }

    appliedBy
    appliedByName
    appliedDate

    completedBy
    completedByName
    completedByUser {
      id
      firstName
      lastName
      employeeId
    }

    createdBy
    createdByName
    createdDate

    assets
    multiAssets

    formInstances

    summary {
      title
      contents
    }

    fieldOrder
    fields {
      ...BaseWorkOrderField
    }

    tasks {
      ...BaseTask
    }
  }
  ${BASE_TASK}
  ${BASE_WORK_ORDER_FIELD}
`;

export const GET_WORK_ORDER = gql`
  query getWorkOrder($id: ID!) {
    workOrder(id: $id) {
      ...BaseWorkOrder
    }
  }
  ${BASE_WORK_ORDER}
`;

export const GET_WORK_ORDERS = gql`
  query workOrders($input: WorkOrdersConnectionInput!) {
    workOrders(input: $input) {
      totalCount
      workOrders {
        id
        type
        name
        originalName
        priorityId
        statusId
        reopened

        inventoryAssetId
        inventoryAssetName
        appliedDate

        workTemplateId
        workTemplate {
          id
          name
        }

        startTime
        duration
        dueDate
        completionDate
        completedBy {
          id
          firstName
          lastName
          email
          photoUrl
        }

        fields {
          ...BaseWorkOrderField
        }

        tasks {
          ...BaseTaskItem
        }

        createdDate
        createdBy {
          id
          firstName
          lastName
          email
          photoUrl
        }
      }
    }
  }
  ${BASE_TASK_ITEM}
  ${BASE_WORK_ORDER_FIELD}
`;

export const WORK_ORDER_CREATE = gql`
  mutation workOrderCreate($input: WorkOrderCreateInput!) {
    workOrderCreate(input: $input) {
      ...BaseWorkOrder
    }
  }
  ${BASE_WORK_ORDER}
`;

export const WORK_ORDER_UPDATE = gql`
  mutation workOrderUpdate($workOrder: WorkOrderUpdateInput!) {
    workOrderUpdate(workOrder: $workOrder) {
      id
      name
      dueDate

      location
      isLocationBased

      statusId
      priorityId

      completionDate
      leadAssigneeId
      mainPhotoId
    }
  }
`;

export const GET_WORK_REPORTS = gql`
  query getWorkReports($id: ID!) {
    workReports(id: $id) {
      totalCount
      workReports {
        workOrderId
        taskId
        acctId
        divId
        distId
        roadwayClassId
        funcId
      }
    }
  }
`;

export const WORK_ORDER_DELETE = gql`
  mutation workOrderDelete($id: ID!) {
    workOrderDelete(id: $id)
  }
`;

export const WORK_ORDER_DUPLICATE = gql`
  mutation workOrderDuplicate($id: ID!) {
    workOrderDuplicate(id: $id)
  }
`;

export const WORK_ORDER_BULK_CREATE = gql`
  mutation workOrderBulkCreate($input: WorkOrderBulkCreateInput!) {
    workOrderBulkCreate(input: $input) {
      totalAssets
      totalWorkOrders
    }
  }
`;

export const WORK_ORDER_BULK_MAP_CREATE = gql`
  mutation workOrderBulkMapCreate($input: WorkOrderBulkMapCreateInput!) {
    workOrderBulkMapCreate(input: $input)
  }
`;

export const WORK_ORDER_FIELD_CREATE = gql`
  mutation workOrderFieldCreate($input: WorkOrderFieldCreateInput!) {
    workOrderFieldCreate(input: $input) {
      ...BaseWorkOrderField
    }
  }
  ${BASE_WORK_ORDER_FIELD}
`;

export const WORK_ORDER_FIELD_UPDATE = gql`
  mutation workOrderFieldUpdate($input: WorkOrderFieldUpdateInput!) {
    workOrderFieldUpdate(input: $input) {
      ...BaseWorkOrderField
    }
  }
  ${BASE_WORK_ORDER_FIELD}
`;

export const WORK_ORDER_FIELD_DELETE = gql`
  mutation workOrderFieldDelete($input: WorkOrderFieldDeleteInput!) {
    workOrderFieldDelete(input: $input)
  }
`;
