import actionTypes from './actionTypes';

export const retrieveInventoryCategoryTreeNode = data => ({
  type: actionTypes.REQUEST_INVENTORY_CATEGORY_TREE_NODE,
  data,
});

export const getInventoryCategoryTreeNodeSuccess = data => ({
  type: actionTypes.GET_INVENTORY_CATEGORY_TREE_NODE_SUCCESS,
  data,
});

export const getInventoryCategoryTreeNodeFailure = () => ({
  type: actionTypes.GET_INVENTORY_CATEGORY_TREE_NODE_FAILURE,
});

export const retrieveInventoryFilterCategoryTreeNode = data => ({
  type: actionTypes.REQUEST_INVENTORY_FILTER_CATEGORY_TREE_NODE,
  data,
});

export const getInventoryFilterCategoryTreeNodeSuccess = data => ({
  type: actionTypes.GET_INVENTORY_FILTER_CATEGORY_TREE_NODE_SUCCESS,
  data,
});

export const getInventoryFilterCategoryTreeNodeFailure = () => ({
  type: actionTypes.GET_INVENTORY_FILTER_CATEGORY_TREE_NODE_FAILURE,
});

export const clearInventoryFilterCategoryTreeNode = () => ({
  type: actionTypes.CLEAR_INVENTORY_FILTER_CATEGORY_TREE_NODE,
});

export const clearInventoryFilterCategoryTreeNodeSuccess = () => ({
  type: actionTypes.CLEAR_INVENTORY_FILTER_CATEGORY_TREE_NODE_SUCCESS,
});

export const clearInventoryFilterCategoryTreeNodeFailure = () => ({
  type: actionTypes.CLEAR_INVENTORY_FILTER_CATEGORY_TREE_NODE_FAILURE,
});

export const retrieveInventoryCategory = data => ({
  type: actionTypes.REQUEST_INVENTORY_CATEGORY,
  data,
});

export const getInventoryCategorySuccess = data => ({
  type: actionTypes.GET_INVENTORY_CATEGORY_SUCCESS,
  data,
});

export const getInventoryCategoryError = () => ({
  type: actionTypes.GET_INVENTORY_CATEGORY_ERROR,
});

export const requestInventoryCategoryUpdate = data => ({
  type: actionTypes.REQUEST_INVENTORY_CATEGORY_UPDATE,
  data,
});

export const inventoryCategoryUpdateSuccess = data => ({
  type: actionTypes.UPDATE_INVENTORY_CATEGORY_SUCCESS,
  data,
});

export const inventoryCategoryUpdateFailure = () => ({
  type: actionTypes.UPDATE_INVENTORY_CATEGORY_FAILURE,
});

export const requestInventoryCategoryCreate = data => ({
  type: actionTypes.REQUEST_INVENTORY_CATEGORY_CREATE,
  data,
});

export const inventoryCategoryCreateSuccess = data => ({
  type: actionTypes.CREATE_INVENTORY_CATEGORY_SUCCESS,
  data,
});

export const inventoryCategoryCreateFailure = () => ({
  type: actionTypes.CREATE_INVENTORY_CATEGORY_FAILURE,
});

export const retrieveInventoryCategories = data => ({
  type: actionTypes.REQUEST_INVENTORY_CATEGORIES,
  data,
});

export const getInventoryCategoriesSuccess = data => ({
  type: actionTypes.GET_INVENTORY_CATEGORIES_SUCCESS,
  data,
});

export const getInventoryCategoriesFailure = () => ({
  type: actionTypes.GET_INVENTORY_CATEGORIES_FAILURE,
});

export const requestInventoryPortalPathUpdate = data => ({
  type: actionTypes.REQUEST_INVENTORY_PORTAL_PATH_UPDATE,
  data,
});

export const inventoryPortalPathUpdateSuccess = data => ({
  type: actionTypes.INVENTORY_PORTAL_PATH_UPDATE_SUCCESS,
  data,
});

export const requestCreateCategory = data => ({
  type: actionTypes.REQUEST_CREATE_CATEGORY,
  data,
});

export const createCategorySuccess = data => ({
  type: actionTypes.CREATE_CATEGORY_SUCCESS,
  data,
});

export const createCategoryFailure = () => ({
  type: actionTypes.CREATE_CATEGORY_FAILURE,
});

export const requestInventoryCategoryDelete = data => ({
  type: actionTypes.REQUEST_INVENTORY_CATEGORY_DELETE,
  data,
});

export const inventoryCategoryDeleteSuccess = data => ({
  type: actionTypes.DELETE_INVENTORY_CATEGORY_SUCCESS,
  data,
});

export const inventoryCategoryDeleteFailure = () => ({
  type: actionTypes.DELETE_INVENTORY_CATEGORY_FAILURE,
});

export const requestCategoryTreeUpdate = data => ({
  type: actionTypes.REQUEST_CATEGORY_TREE_UPDATE,
  data,
});

export const updateCategoryTreeSuccess = data => ({
  type: actionTypes.UPDATE_CATEGORY_TREE_SUCCESS,
  data,
});

export const updateCategoryTreeFailure = () => ({
  type: actionTypes.UPDATE_CATEGORY_TREE_FAILURE,
});

export const requestFullCategoryTree = () => ({
  type: actionTypes.REQUEST_FULL_CATEGORY_TREE,
});

export const getFullCategoryTreeSuccess = data => ({
  type: actionTypes.GET_FULL_CATEGORY_TREE_SUCCESS,
  data,
});

export const getFullCategoryTreeFailure = () => ({
  type: actionTypes.GET_FULL_CATEGORY_TREE_FAILURE,
});
