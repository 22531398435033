import React, { useContext } from 'react';
import * as R from 'ramda';

import TeamContext from '@atom/components/teamPortal/TeamContext';
import { Button, ListTable } from '@atom/mui';
import colors from '@atom/styles/colors';
import { TimeEntriesDailyTotal } from '@atom/types/timeEntry';
import { timeSheetTableWidths } from '@atom/utilities/timeSheetUtilities';
import { convertMillisToExactHours } from '@atom/utilities/timeUtilities';

import './timeSheet.css';

const { TableRow, TableCell } = ListTable;

const secondaryHeaderCell = {
  top: '47px', // height of condensed top header td with padding
  backgroundColor: colors.neutral.white,
};

const styles = {
  workInfo: {
    minWidth: timeSheetTableWidths.work,
    ...secondaryHeaderCell,
  },
  smallCell: {
    padding: '8px 4px',
    ...secondaryHeaderCell,
  },
  start: {
    padding: '8px',
    ...secondaryHeaderCell,
  },
  smallButton: {
    minWidth: '0',
    height: '24px',
    width: '24px',
    borderRadius: '12px',
  },
};

interface Props {
  dailyTotals: TimeEntriesDailyTotal[];
  dateFilter: number;
  setDateFilter: (date: number) => void;
}

const TimeSheetTableCondensedTotalsRow = ({
  dailyTotals = [],
  dateFilter,
  setDateFilter,
}: Props) => {
  const { payPeriodWeeks } = useContext(TeamContext);

  const fullPayPeriodWeeks = R.flatten(payPeriodWeeks);

  const toggleDateFilter = (date: number) => {
    setDateFilter(dateFilter === date ? null : date);
  };

  return (
    <>
      <TableRow header key="totals" hover={false}>
        <TableCell
          width={timeSheetTableWidths.status}
          style={styles.start}
          center
        />
        <TableCell
          width={timeSheetTableWidths.workType}
          style={secondaryHeaderCell}
        />
        <TableCell style={styles.workInfo} />
        <TableCell
          width={timeSheetTableWidths.task}
          style={secondaryHeaderCell}
        />
        <TableCell
          width={timeSheetTableWidths.userGroup}
          style={secondaryHeaderCell}
        />
        <TableCell
          width={timeSheetTableWidths.budget}
          style={secondaryHeaderCell}
        />
        {fullPayPeriodWeeks.map(day => {
          const duration = R.find(R.propEq('date', day))(dailyTotals)?.duration;

          const text = duration ? convertMillisToExactHours(duration) : '';
          const textColor =
            dateFilter === day
              ? colors.neutral.white
              : convertMillisToExactHours(duration) > 24
              ? colors.brand.red
              : colors.neutral.dim;

          const buttonStyles = {
            ...styles.smallButton,
            color: textColor,
            backgroundColor:
              dateFilter === day ? colors.brand.blue : colors.neutral.white,
          };

          return (
            <TableCell
              width={timeSheetTableWidths.small}
              center
              style={styles.smallCell}
              key={day}
            >
              {!!duration && (
                <Button
                  style={buttonStyles}
                  onClick={() => toggleDateFilter(day)}
                >
                  {text}
                </Button>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
};

export default TimeSheetTableCondensedTotalsRow;
