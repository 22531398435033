import React from 'react';
import * as R from 'ramda';

import { TaskLocation } from '@atom/types/taskLocation';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import LocationsRow from './LocationsRow';
import { getFilledLocations } from './reportUtilities';

import './workOrderReport.css';

interface Props {
  isCompletedOrAbove: boolean;
  reportLocations: TaskLocation[];
}

const ReportLocationsSection = ({
  isCompletedOrAbove,
  reportLocations,
}: Props) => {
  const filteredLocations = getFilledLocations(
    reportLocations,
    isCompletedOrAbove,
  );

  const showLocations = !isNilOrEmpty(filteredLocations);

  const rows = R.splitEvery(2, filteredLocations.locations);

  return showLocations ? (
    <>
      <div styleName="unified-data-row">
        <div styleName="data-cell">{`Management Unit: ${filteredLocations.managementUnit}`}</div>
        <div styleName="data-cell">{`County: ${filteredLocations.county}`}</div>
        <div styleName="data-cell">{`Road Class: ${filteredLocations.roadClass}`}</div>
      </div>
      <div styleName="location-table-header-container">
        <div styleName="location-table-header">
          <div styleName="location-header-route">Route</div>
          <div styleName="location-table-sub">
            <div styleName="location-header-cell">Direction</div>
            <div styleName="location-header-cell">Start MP</div>
            <div styleName="location-header-cell">End MP</div>
          </div>
        </div>
        <div styleName="location-table-header">
          <div styleName="location-header-route">Route</div>
          <div styleName="location-table-sub">
            <div styleName="location-header-cell">Direction</div>
            <div styleName="location-header-cell">Start MP</div>
            <div styleName="location-header-cell">End MP</div>
          </div>
        </div>
      </div>
      <div>
        {rows.map((rowLocations, index) => (
          <LocationsRow key={index} rowLocations={rowLocations} />
        ))}
      </div>
    </>
  ) : (
    <div />
  );
};

export default ReportLocationsSection;
