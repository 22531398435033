import * as R from 'ramda';

import { getUserFullName } from '@atom/utilities/userUtilities';

export const mediaSelector = R.pathOr([], ['media']);
export const mediaFoldersSelector = R.pathOr([], ['mediaFolders', 'folders']);

export const getOrderedMediaSelector = (state, ownProps) => {
  const mainMediaId = R.pathOr('', ['mainMediaId'])(ownProps);
  const media = mediaSelector(state);

  if (!mainMediaId || R.isNil(media) || R.isEmpty(media)) {
    return media;
  }

  const mainMedia = media.find(medium => medium.id === mainMediaId);
  const filteredMedia = media.filter(medium => medium.id !== mainMediaId);

  if (R.isNil(mainMedia) || R.isEmpty(mainMedia)) {
    return [...filteredMedia];
  }

  return [mainMedia, ...filteredMedia];
};

export const sortMedia = (sortColumn, isAscending, data) => {
  const sortData = data.map(item => ({
    ...item,
    createdByName: item.createdBy ? getUserFullName(item.createdBy) : '-',
  }));

  const sortKey = sortColumn === 'createdBy' ? 'createdByName' : sortColumn;

  const comparator = isAscending
    ? R.ascend(R.prop(sortKey))
    : R.descend(R.prop(sortKey));

  return R.sort(comparator, sortData);
};
