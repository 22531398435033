import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function workOrdersInput(
  state = initialState.workOrdersInput,
  action,
) {
  const { data, type } = action;
  switch (type) {
    case actionTypes.UPDATE_WORK_ORDERS_INPUT: {
      return {
        ...state,
        ...data,
        page: data?.page || 1,
        limit: data?.limit || 25,
        sortBy: data?.sortBy || 'name,asc',
      };
    }
    default: {
      return state;
    }
  }
}
