import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';

import {
  GET_HIGHEST_END_READING,
  GET_MATERIAL_ENTRIES,
  MATERIAL_ENTRY_DELETE,
} from '@atom/graph/materialEntry';
import { Button, Modal } from '@atom/mui';
import { AssetUsageSummary } from '@atom/types/assetUsage';
import {
  HighestEndReadingConnection,
  MaterialEntriesConnection,
  MaterialEntriesConnectionInput,
} from '@atom/types/materialEntry';
import { Task, TaskMaterialItem } from '@atom/types/task';
import { WorkOrderDetailType } from '@atom/types/work';
import api from '@atom/utilities/api';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { INVENTORY_ASSETS_ENDPOINT } from '@atom/utilities/endpoints';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import MaterialEntryDisplayRow from './MaterialEntryDisplayRow';
import MaterialEntryEditRow from './MaterialEntryEditRow';
import MaterialModalTitle from './MaterialModalTitle';

import './materialModal.css';

const initialUsageSummary = {
  totalUsage: 0,
  totalCost: 0,
  totalCostYear: 0,
  totalCostMonth: 0,
  totalUsageToday: 0,
  totalRemaining: 0,
  quantityOnHand: 0,
};

export interface Props {
  open: boolean;
  onClose: () => void;
  material: TaskMaterialItem;
  task: Task;
  workOrderDetail: WorkOrderDetailType;
  refetch: () => void;
}

const MaterialModal = ({
  open,
  onClose,
  material,
  task,
  workOrderDetail,
  refetch,
}: Props) => {
  const isAldot = isCurrentClient([Client.ALDOT]);
  const [createMaterialEntry, setCreateMaterialEntry] = useState<boolean>(
    false,
  );
  const [editMaterialEntryId, setEditMaterialEntryId] = useState<string>();
  const [loadingUsageSummary, setLoadingUsageSummary] = useState<boolean>(
    false,
  );
  const [usageSummary, setUsageSummary] = useState<AssetUsageSummary>(
    initialUsageSummary,
  );

  const [
    getMaterialEntries,
    {
      data: materialEntriesData,
      loading: materialEntriesLoading,
      refetch: refetchMaterialEntries,
    },
  ] = useLazyQuery<
    { materialEntries: MaterialEntriesConnection },
    { input: MaterialEntriesConnectionInput }
  >(GET_MATERIAL_ENTRIES, {
    fetchPolicy: 'network-only',
  });

  const [
    materialEntryDelete,
    { loading: materialEntryDeleteLoading },
  ] = useMutation<{}, { id: string }>(MATERIAL_ENTRY_DELETE, {
    onCompleted: () => {
      refetchMaterialEntries();
    },
  });

  const getUsageSummary = async () => {
    setLoadingUsageSummary(true);
    try {
      const { data } = await api.get(
        `${INVENTORY_ASSETS_ENDPOINT}/${material.assetId}/usageSummary`,
      );
      setUsageSummary(data);
    } catch (error) {
      setUsageSummary(initialUsageSummary);
    }
    setLoadingUsageSummary(false);
  };

  const [
    getHighestEndReading,
    { data: highestEndReadingData, loading: highestEndReadingLoading },
  ] = useLazyQuery<
    HighestEndReadingConnection,
    { input: MaterialEntriesConnectionInput }
  >(GET_HIGHEST_END_READING, {
    fetchPolicy: 'network-only',
  });

  const handleAddNewUsage = () => {
    setCreateMaterialEntry(true);
    if (isAldot) {
      getHighestEndReading({
        variables: {
          input: {
            assetId: material.assetId,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (open) {
      getMaterialEntries({
        variables: {
          input: {
            assetId: material.assetId,
            workOrderId: workOrderDetail.id,
            taskId: task.id,
          },
        },
      });
    }
  }, [open]);

  useEffect(() => {
    if (
      open &&
      !isNilOrEmpty(task?.users) &&
      R.isEmpty(materialEntriesData?.materialEntries?.materialEntries) &&
      !workOrderDetail?.isClosed
    ) {
      handleAddNewUsage();
    }
  }, [open, materialEntriesData]);

  useEffect(() => {
    if (open && material?.isStockBased) {
      getUsageSummary();
    }
  }, [material?.assetId, open]);

  const handleDone = () => {
    setUsageSummary(initialUsageSummary);
    setCreateMaterialEntry(false);
    setEditMaterialEntryId(null);
    onClose();
    refetch();
  };

  const handleMaterialEntryDelete = (id: string) => {
    materialEntryDelete({
      variables: {
        id,
      },
    });

    getUsageSummary();
  };

  const isLoading =
    loadingUsageSummary ||
    materialEntriesLoading ||
    materialEntryDeleteLoading ||
    highestEndReadingLoading;
  const isDoneDisabled =
    isLoading || !!editMaterialEntryId || createMaterialEntry;
  const isAddDisabled =
    isLoading ||
    !!editMaterialEntryId ||
    createMaterialEntry ||
    isNilOrEmpty(task?.users) ||
    workOrderDetail.isClosed ||
    doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  const showAddUsageButton = !isNilOrEmpty(task?.users);

  const materialEntries =
    materialEntriesData?.materialEntries?.materialEntries || [];

  const renderFooter = (
    <div styleName="modal-footer">
      <Button onClick={handleDone} disabled={isDoneDisabled}>
        Done
      </Button>
    </div>
  );

  // Start/End readings needs more space:
  const modalWidth = material?.isStartEndReading ? 'xxl' : 'lg';

  const createStartReading = isAldot
    ? R.pathOr(0, ['highestEndReading'], highestEndReadingData)
    : 0;

  return (
    <Modal
      title={
        <MaterialModalTitle
          material={material}
          totalRemaining={usageSummary.totalRemaining}
        />
      }
      open={open}
      footer={renderFooter}
      width={modalWidth}
    >
      <div>
        {isNilOrEmpty(task?.users) && (
          <div styleName="no-user-message">
            Please add a team member to the task prior to adding usage.
          </div>
        )}
        {materialEntries.map(materialEntry => {
          return materialEntry.id === editMaterialEntryId ? (
            <MaterialEntryEditRow
              key={materialEntry.id}
              materialEntry={materialEntry}
              materialEntries={materialEntries}
              setEditMaterialEntryId={setEditMaterialEntryId}
              editMaterialEntryId={editMaterialEntryId}
              refetchMaterialEntries={refetchMaterialEntries}
              workOrderDetail={workOrderDetail}
              taskId={task.id}
              availableUsers={task?.users || []}
              material={material}
              totalRemaining={usageSummary.totalRemaining}
              getUsageSummary={getUsageSummary}
              loadingUsageSummary={loadingUsageSummary}
            />
          ) : (
            <MaterialEntryDisplayRow
              key={materialEntry.id}
              materialEntry={materialEntry}
              material={material}
              workOrderDetail={workOrderDetail}
              availableUsers={task?.users || []}
              createMaterialEntry={createMaterialEntry}
              editMaterialEntryId={editMaterialEntryId}
              setEditMaterialEntryId={setEditMaterialEntryId}
              handleMaterialEntryDelete={handleMaterialEntryDelete}
            />
          );
        })}
        {createMaterialEntry && (
          <MaterialEntryEditRow
            key="create"
            materialEntries={materialEntries}
            createMaterialEntry={createMaterialEntry}
            setCreateMaterialEntry={setCreateMaterialEntry}
            refetchMaterialEntries={refetchMaterialEntries}
            editMaterialEntryId={editMaterialEntryId}
            workOrderDetail={workOrderDetail}
            taskId={task.id}
            availableUsers={task?.users || []}
            material={material}
            totalRemaining={usageSummary.totalRemaining}
            getUsageSummary={getUsageSummary}
            loadingUsageSummary={loadingUsageSummary}
            createStartReading={createStartReading}
          />
        )}
        {showAddUsageButton && (
          <Button
            onClick={handleAddNewUsage}
            disabled={isAddDisabled}
            color="primary"
          >
            Add Usage
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default MaterialModal;
