import { gql } from '@apollo/client';

export const BASE_MATERIAL_ENTRY = gql`
  fragment BaseMaterialEntry on MaterialEntry {
    id
    workOrderId
    taskId
    assetId
    userId
    user {
      firstName
      lastName
    }
    date
    amount
    startReading
    endReading
    createdBy {
      firstName
      lastName
    }
    updatedBy {
      firstName
      lastName
    }
    createdDate
    updatedDate
  }
`;

export const GET_MATERIAL_ENTRIES = gql`
  query materialEntries($input: MaterialEntriesConnectionInput!) {
    materialEntries(input: $input) {
      totalCount
      materialEntries {
        ...BaseMaterialEntry
      }
    }
  }
  ${BASE_MATERIAL_ENTRY}
`;

export const MATERIAL_ENTRY_CREATE = gql`
  mutation materialEntryCreate($input: MaterialEntryCreateInput!) {
    materialEntryCreate(input: $input) {
      ...BaseMaterialEntry
    }
  }
  ${BASE_MATERIAL_ENTRY}
`;

export const MATERIAL_ENTRY_UPDATE = gql`
  mutation materialEntryUpdate($input: MaterialEntryUpdate!) {
    materialEntryUpdate(input: $input) {
      ...BaseMaterialEntry
    }
  }
  ${BASE_MATERIAL_ENTRY}
`;

export const MATERIAL_ENTRY_DELETE = gql`
  mutation materialEntryDelete($id: ID!) {
    materialEntryDelete(id: $id)
  }
`;

export const GET_HIGHEST_END_READING = gql`
  query highestEndReading($input: MaterialEntriesConnectionInput!) {
    highestEndReading(input: $input)
  }
`;
