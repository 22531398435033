import React, { useState } from 'react';

import { Modal } from '@atom/mui';
import {
  AnalyticsFolderTree,
  AnalyticsFolderTree as FolderTree,
} from '@atom/types/analytics';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import SelectAnalyticsFolderTree from './SelectAnalyticsFolderTree';

import './analyticsCardModal.css';

export interface Props {
  title: string;
  open: boolean;
  onClose: () => void;
  handleMoveCard: (data: AnalyticsFolderTree) => void;
}

const MoveAnalyticsCardModal = ({
  open,
  onClose,
  title,
  handleMoveCard,
}: Props) => {
  const [folder, setFolder] = useState<FolderTree>();

  const handleSelectFolder = (selected: FolderTree) => setFolder(selected);
  const handleSubmit = (): void => {
    handleMoveCard(folder);
  };

  return (
    <Modal
      title={title}
      open={open}
      onCancel={onClose}
      onConfirm={handleSubmit}
      onExited={onClose}
      data-cy="moveAnalyticsCardModal"
      width="sm"
      confirmButtonText="Move"
      disabled={isNilOrEmpty(folder)}
    >
      <SelectAnalyticsFolderTree setFolder={handleSelectFolder} />
    </Modal>
  );
};

export default MoveAnalyticsCardModal;
