import { useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';

import { usePreferences } from '@atom/hooks/usePreferences';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import {
  DEFAULT_MAP_CENTER,
  getGeoJSONLocation,
} from '@atom/utilities/mapUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

const LOCATION_TIMEOUT = 5000;
const DEFAULT_LINE_STRING_LNG_OFFSET = 0.001;

export interface Location {
  type: string;
  coordinates: any;
}

interface Data {
  pointLocation: Location;
  lineStringLocation: Location;
  loading: boolean;
}

export const useCurrentLocation = (): Data => {
  const userProfile = useUserProfile();
  const preferences = usePreferences();

  const defaultLocation = useMemo(() => {
    if (!isNilOrEmpty(userProfile?.defaultLocation)) {
      return userProfile?.defaultLocation;
    }

    if (!isNilOrEmpty(preferences?.map?.center)) {
      return getGeoJSONLocation(preferences?.map?.center);
    }

    return getGeoJSONLocation(DEFAULT_MAP_CENTER);
  }, [userProfile?.defaultLocation, preferences?.map?.center]);

  const [location, setLocation] = useState<Location>();
  const [loading, setLoading] = useState<boolean>(false);

  const getCurrentLocation = (resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const currentLocation = {
          type: 'Point',
          coordinates: [
            R.pathOr(defaultLocation.coordinates[0], ['coords', 'longitude'])(
              position,
            ),
            R.pathOr(defaultLocation.coordinates[1], ['coords', 'latitude'])(
              position,
            ),
          ],
        };

        resolve(currentLocation);
      },
      reject,
      { timeout: LOCATION_TIMEOUT },
    );
  };

  const lineStringLocation = useMemo(() => {
    if (location && location?.coordinates) {
      return {
        type: 'LineString',
        coordinates: [
          location?.coordinates,
          [
            location.coordinates[0] + DEFAULT_LINE_STRING_LNG_OFFSET,
            location.coordinates[1],
          ],
        ],
      };
    }

    return null;
  }, [location]);

  const locationSuccess = (currentLocation: Location) => {
    setLocation(currentLocation);
    setLoading(false);
  };

  // Sets location to default location on failure
  const locationFailure = () => {
    setLocation(defaultLocation);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);

    getCurrentLocation(
      currentLocation => locationSuccess(currentLocation),
      () => locationFailure(),
    );
  }, []);

  return { pointLocation: location, lineStringLocation, loading };
};
