import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
import WorkUsers from '@atom/components/common/workUsers/WorkUsers';
import client from '@atom/graph/client';
import { DELETE_POLICY } from '@atom/graph/policy';
import { GET_USERS } from '@atom/graph/user';
import { Icon, IconButton, ListTable, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  GrantType,
  Policy,
  POLICY_GRANT_IDS_ALL,
  PolicyResource,
} from '@atom/types/policy';
import {
  UserDetail,
  UsersConnection,
  UsersConnectionInput,
} from '@atom/types/user';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import {
  getPolicyActionLabel,
  POLICY_RESOURCE_OPTIONS,
} from '@atom/utilities/policyUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';

import RoleContext from '../RoleContext';

import CreatePolicyModal from './policyModals/CreatePolicyModal';
import EditPolicyModal from './policyModals/EditPolicyModal';

import './rolePolicies.css';

const styles = {
  emptyIcon: {
    fontSize: '5.25rem',
    marginBottom: '1rem',
  },
};

const {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} = ListTable;

type SortField = 'resource' | 'createdDate';
type SortDirection = 'asc' | 'desc';

const RolePolicies = () => {
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [selectedForEdit, setSelectedForEdit] = useState<Policy>(null);
  const [selectedForDelete, setSelectedForDelete] = useState<string>('');
  const [budgetUsers, setBudgetUsers] = useState<{
    [id: string]: UserDetail[];
  }>({});

  const {
    role,
    refetch,
    loading,
    policiesInput: input,
    setPoliciesInput: setInput,
  } = useContext(RoleContext);

  const {
    policies: { policies, totalCount },
  } = role;

  const [deletePolicy, { loading: loadingDelete }] = useMutation<
    {},
    { id: string }
  >(DELETE_POLICY);

  const getUsers = async (
    // eslint-disable-next-line @typescript-eslint/no-shadow
    input: UsersConnectionInput,
  ) => {
    const { data } = await client.query<{ users: UsersConnection }>({
      query: GET_USERS,
      variables: {
        input: {
          ...input,
          showAdmin: hasRolePermissions(ROLE_SETS.ADMIN),
        },
      },
    });

    const allUsers = data?.users?.users || [];

    setBudgetUsers(
      policies.reduce((usersDict, policy) => {
        if (policy.resource === PolicyResource.BUDGET) {
          usersDict[policy.id] = allUsers.filter(user =>
            policy.grant.ids.includes(user.id),
          );
        }
        return usersDict;
      }, {}),
    );
  };

  useEffect(() => {
    getUsers({
      ids: policies.reduce((ids, policy) => {
        return policy.resource === PolicyResource.BUDGET
          ? [...ids, ...policy.grants.map(grant => grant.id)]
          : ids;
      }, []),
    });
  }, [policies]);

  const getSortDirection = useCallback(
    (field: SortField) => {
      if (input.sortBy !== field) {
        return false;
      }

      return input.sortDirection;
    },
    [input.sortBy, input.sortDirection],
  );

  const handleSortDirectionChange = (sortBy: SortField) => (
    sortDirection: SortDirection,
  ) => {
    setInput({ ...input, sortBy, sortDirection });
  };

  const handleDelete = async () => {
    await deletePolicy({ variables: { id: selectedForDelete } });
    setSelectedForDelete('');
    refetch();
  };

  const getIDComponent = (policy: Policy) => {
    if (policy.grant.type === GrantType.USER) {
      return <WorkUsers users={budgetUsers[policy.id]} userGroups={[]} />;
    }

    return policy.grant.ids === POLICY_GRANT_IDS_ALL
      ? 'All'
      : policy.grants.map(resource => resource.name).join(', ');
  };

  const hasPolicies = totalCount > 0;
  const policiesSuffix = totalCount === 1 ? 'policy' : 'policies';

  return (
    <>
      <div styleName="header-container">
        <div>{`${totalCount} ${policiesSuffix}`}</div>
        {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
          <IconButton onClick={() => setCreateModalOpen(true)}>
            <Icon>add</Icon>
          </IconButton>
        )}
      </div>
      <div styleName="content">
        {loading ? (
          <Progress style={{ flex: 1 }} />
        ) : (
          <ListTable offsetTop="310px" fullHeight={hasPolicies}>
            <TableHead>
              <TableRow header>
                <TableCell
                  variant="head"
                  sortDirection={getSortDirection('resource')}
                  onSortChange={handleSortDirectionChange('resource')}
                >
                  Source
                </TableCell>
                <TableCell variant="head">Sub Source</TableCell>
                <TableCell variant="head">ID</TableCell>
                <TableCell variant="head">Operation</TableCell>
                <TableCell
                  variant="head"
                  sortDirection={getSortDirection('createdDate')}
                  onSortChange={handleSortDirectionChange('createdDate')}
                >
                  Created On
                </TableCell>
                <TableCell variant="head" />
              </TableRow>
            </TableHead>
            <TableBody>
              {policies.map(policy => {
                const option = POLICY_RESOURCE_OPTIONS[policy.resource];

                if (!option) {
                  return null;
                }

                return (
                  <TableRow
                    key={[
                      policy.subjectId,
                      policy.resource,
                      policy.action,
                      policy.grant.type,
                    ].join('-')}
                  >
                    <TableCell>
                      <span style={{ marginRight: '1rem' }}>{option.icon}</span>
                      {option.label}
                    </TableCell>
                    <TableCell>
                      {option.grantTypes.length > 1
                        ? POLICY_RESOURCE_OPTIONS[policy.grant.type]?.label
                        : '-'}
                    </TableCell>
                    <TableCell>{getIDComponent(policy)}</TableCell>
                    <TableCell>{getPolicyActionLabel(policy.action)}</TableCell>
                    <TableCell>{setDisplayDate(policy.updatedDate)}</TableCell>
                    <TableCell width="96px">
                      {hasRolePermissions(ROLE_SETS.ORG_ADMIN) ? (
                        <>
                          <IconButton
                            onClick={() => setSelectedForEdit(policy)}
                          >
                            <Icon>edit</Icon>
                          </IconButton>
                          <IconButton
                            onClick={() => setSelectedForDelete(policy.id)}
                          >
                            <Icon>delete</Icon>
                          </IconButton>
                        </>
                      ) : (
                        <div style={{ height: '48px' }} />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            {hasPolicies && (
              <TableFooter width="70%">
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[15, 25, 50]}
                    count={totalCount}
                    rowsPerPage={input.limit}
                    page={input.page}
                    onPageChange={page => setInput({ ...input, page })}
                    onRowsPerPageChange={event =>
                      setInput({
                        ...input,
                        limit: +event.target.value,
                        page: 1,
                      })
                    }
                  />
                </TableRow>
              </TableFooter>
            )}
          </ListTable>
        )}
        {!hasPolicies && !loading && (
          <div styleName="empty-container">
            <Icon style={styles.emptyIcon} color={colors.neutral.silver}>
              settings
            </Icon>
            <div>There are no policies.</div>
          </div>
        )}
      </div>
      <CreatePolicyModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />
      <EditPolicyModal
        open={!!selectedForEdit}
        onClose={() => setSelectedForEdit(null)}
        policy={selectedForEdit}
      />
      <DeleteModal
        title="Delete Policy?"
        open={!!selectedForDelete}
        onCancel={() => setSelectedForDelete('')}
        onConfirm={handleDelete}
        loading={loadingDelete}
        content="Deleting this policy will remove it from all users and groups in this role. Are you sure?"
      />
    </>
  );
};

export default RolePolicies;
