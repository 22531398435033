import React from 'react';

import { Button, Modal } from '@atom/mui';

export interface Props {
  open: boolean;
  onClose: () => void;
  handleDeleteList: () => void;
  name?: string;
}

const DeleteFavoritesListModal = ({
  open,
  onClose,
  handleDeleteList,
  name = '',
}: Props) => {
  const getFooter = () => {
    return (
      <div>
        <Button onClick={onClose} style={{ marginRight: '0.5rem' }}>
          Cancel
        </Button>
        <Button color="error" variant="contained" onClick={handleDeleteList}>
          DELETE
        </Button>
      </div>
    );
  };

  return (
    <Modal
      title="Delete Favorite List?"
      open={open}
      onCancel={onClose}
      footer={getFooter()}
    >
      <div>
        Are you sure you want to delete favorite list "{name}"? Items will be
        unfavorited from this list.
      </div>
    </Modal>
  );
};

export default DeleteFavoritesListModal;
