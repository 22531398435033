import React, { useContext } from 'react';
import * as R from 'ramda';

// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import WorkTemplateCreationModalContext, {
  ModalView,
} from '@atom/components/common/workTemplateCreationModal/WorkTemplateCreationModalContext';
import WorkTypeFolderTile from '@atom/components/common/workTemplateFolderTree/WorkTypeFolderTile';
import { Icon, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';

import './workTemplateCreationModal.css';

const styles = {
  textFieldStyle: {
    width: '350px',
    marginLeft: '1rem',
    color: colors.neutral.dark,
  },
  icon: {
    marginTop: '1rem',
  },
};

const DetailsView = () => {
  const {
    name,
    setName,
    error,
    setError,
    workTemplateFolder,
    setWorkTemplateFolder,
    setActiveView,
  } = useContext(WorkTemplateCreationModalContext);

  const handleNameChange = event => {
    setName(event.target.value);
    setError('');
  };

  return (
    <>
      <div styleName="modal-row title">Details</div>
      <div styleName="modal-row">
        <img style={styles.icon} src={renameIcon} />
        <TextField
          id="name"
          label="Name *"
          value={name}
          onChange={handleNameChange}
          style={styles.textFieldStyle}
          error={!!error}
          helperText={error}
        />
      </div>
      <div styleName="modal-row">
        <Icon color={colors.neutral.gray}>folder</Icon>
        {!R.isNil(workTemplateFolder) ? (
          <div styleName="tile-container">
            <WorkTypeFolderTile
              workTemplateFolder={workTemplateFolder}
              removeWorkTemplateFolder={() => setWorkTemplateFolder(null)}
            />
          </div>
        ) : (
          <div
            styleName="choose-button"
            onClick={() => setActiveView(ModalView.FOLDER_TREE)}
          >
            Select Folder *
          </div>
        )}
      </div>
    </>
  );
};

export default DetailsView;
