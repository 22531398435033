import React, { useContext, useEffect, useState } from 'react';
import * as R from 'ramda';

import AddSummaryModal from '@atom/components/common/workOrderDetail/workOrderSummary/AddSummaryModal';
import WorkOrderContext from '@atom/components/workOrderDetail/WorkOrderContext';
import { Icon, IconButton } from '@atom/mui';
import { Summary } from '@atom/types/work';
import api from '@atom/utilities/api';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { WORK_ORDERS_ENDPOINT } from '@atom/utilities/endpoints';

import SummaryRow from './SummaryRow';

import './workOrderInfo.css';

const SummaryPane = () => {
  const { workOrderDetail, refetch } = useContext(WorkOrderContext);

  const [open, setOpen] = useState<boolean>(false);
  const [summary, setSummary] = useState<Summary[]>(
    workOrderDetail.summary || [],
  );

  useEffect(() => {
    setSummary(workOrderDetail.summary);
  }, [workOrderDetail.summary]);

  const updateSummary = async (updatedSummary: Summary[]) => {
    setSummary(updatedSummary);

    const endpoint = `${WORK_ORDERS_ENDPOINT}/${workOrderDetail.id}`;

    await api.patch(endpoint, { summary: updatedSummary });
    refetch();
  };

  const addSummary = (newSummary: Summary) => {
    updateSummary([...summary, newSummary]);
  };

  const canAddSummary = hasRolePermissions(ROLE_SETS.INSPECTOR);

  return (
    <>
      <div styleName="section-header">
        <div>Summary</div>
        {canAddSummary && (
          <IconButton onClick={() => setOpen(true)}>
            <Icon>add</Icon>
          </IconButton>
        )}
      </div>
      <div styleName="section-content summary">
        {R.isEmpty(summary) ? (
          <div styleName="no-summary">There is no summary.</div>
        ) : (
          summary.map((summaryItem: Summary, index: number) => (
            <SummaryRow
              key={`${summaryItem.title}-${index}`}
              summary={summaryItem}
              summaries={summary}
              index={index}
              updateSummary={updateSummary}
            />
          ))
        )}
      </div>
      <AddSummaryModal
        open={open}
        onClose={() => setOpen(false)}
        addSummary={addSummary}
      />
    </>
  );
};

export default SummaryPane;
