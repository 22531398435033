import * as React from 'react';

import { ApplicationSearchWorkOrdersState } from '@atom/types/store';
import history from '@atom/utilities/history';
import iconUtilities from '@atom/utilities/iconUtilities';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';

import './searchPortal.css';

interface Props {
  query: string;
  workOrders: ApplicationSearchWorkOrdersState[];
}

class WorkSearchResultsRows extends React.Component<Props> {
  navigate = (id: string) => {
    history.push(`/workOrders/${id}`);
  };

  render() {
    const { query, workOrders } = this.props;

    return (
      <div styleName="search-entity-results-container">
        {workOrders.map((workOrder: ApplicationSearchWorkOrdersState) => {
          const name = textDisplayUtilities.highlightedText(
            query,
            workOrder.name,
          );
          const icon = iconUtilities.getWorkIconByStatusId(workOrder.statusId);

          return (
            <div
              styleName="search-entity-row work"
              key={workOrder.id}
              onClick={(): void => this.navigate(workOrder.id)}
            >
              {icon}
              <div styleName="search-entity-row-text-container">
                <div styleName="search-entity-row-text">{name}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default WorkSearchResultsRows;
