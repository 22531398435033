import { call, fork, put, takeEvery } from 'redux-saga/effects';

import actionTypes from '@atom/actions/actionTypes';
import * as workTemplateActions from '@atom/actions/workTemplateActions';
import { Snackbar } from '@atom/mui';
import accountUtilities from '@atom/utilities/accountUtilities';
import api from '@atom/utilities/api';
import { WORK_TEMPLATES_ENDPOINT } from '@atom/utilities/endpoints';
import { TOTAL_COUNT_HEADER } from '@atom/utilities/requestUtilities';

export function* createWorkTemplate(action: any): any {
  try {
    const body = action.data;

    const { data } = yield call(api.post, WORK_TEMPLATES_ENDPOINT, body);

    Snackbar.info({
      message: `Template Created: ${data.name}`,
    });
    yield put(workTemplateActions.workTemplateCreationSuccess());
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      workTemplateActions.workTemplateCreationFailure,
    );
  }
}

export function* removeWorkTemplate(action: any): any {
  try {
    const { id } = action.data;
    const endpoint = `${WORK_TEMPLATES_ENDPOINT}/${action.data.id}`;
    yield call(api.delete, endpoint);

    yield put(workTemplateActions.deleteWorkTemplateSuccess({ id }));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      workTemplateActions.getWorkTemplatesFailure,
    );
  }
}

export function* getWorkTemplates(action: any): any {
  try {
    const { page, limit } = action.data;
    const params = {
      ...(page ? { page } : {}),
      ...(limit ? { limit } : {}),
    };

    const { data, headers } = yield call(
      api.get,
      WORK_TEMPLATES_ENDPOINT,
      params,
    );
    yield put(workTemplateActions.getWorkTemplatesSuccess(data));
    yield put(
      workTemplateActions.getWorkTemplatesTotalSuccess(
        Number(headers[TOTAL_COUNT_HEADER]),
      ),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      workTemplateActions.getWorkTemplatesFailure,
    );
  }
}

/* WATCHERS */
export function* requestCreateWorkTemplate(): any {
  yield takeEvery(
    actionTypes.REQUEST_WORK_TEMPLATE_CREATION,
    createWorkTemplate,
  );
}

export function* requestWorkTemplates(): any {
  yield takeEvery(actionTypes.REQUEST_WORK_TEMPLATES, getWorkTemplates);
}

export function* requestWorkTemplateDeletion(): any {
  yield takeEvery(
    actionTypes.REQUEST_WORK_TEMPLATE_DELETION,
    removeWorkTemplate,
  );
}

export default [
  requestCreateWorkTemplate,
  requestWorkTemplates,
  requestWorkTemplateDeletion,
];
