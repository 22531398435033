import * as React from 'react';

import SectionHeading from '@atom/components/common/map/mapDrawerContent/SectionHeading';
import WorkRow from '@atom/components/common/map/mapDrawerContent/WorkRow';
import { WorkCollectionItem } from '@atom/types/work';

import './userSummaryTray.css';

const getWorkSectionHeading = length => {
  if (!length) {
    return '0 active work';
  }

  return `${length} active work`;
};

interface Props {
  work: WorkCollectionItem[];
}

const UserSummaryWork = ({ work }: Props) => {
  const sectionHeading = getWorkSectionHeading(work.length);

  return (
    <div styleName="work-container">
      <SectionHeading title={sectionHeading} />
      <div styleName="work-detail-section">
        {work.map(workOrder => (
          <WorkRow key={workOrder.id} work={workOrder} />
        ))}
      </div>
    </div>
  );
};

export default UserSummaryWork;
