import React, { useState } from 'react';

import DeleteModal from '@atom/components/common/DeleteModal';
import RenameModal from '@atom/components/common/RenameModal';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { MediaItem } from '@atom/types/media';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';

import './carousel.css';

interface Props {
  onClose: () => void;
  openSideBar: () => void;
  selectedMedia: MediaItem;
  deleteMedia: (mediaId: string) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  subjectId: string;
  setAsPrimaryMedia?: (mediaId: string) => void;
  primaryMediaId?: string;
  canMarkAsCoverPhoto?: boolean;
  canUpdateMedia: boolean;
  canDeleteMedia: boolean;
}

const CarouselHeader = ({
  onClose,
  openSideBar,
  selectedMedia,
  deleteMedia,
  subjectId,
  renameMedia,
  setAsPrimaryMedia,
  primaryMediaId,
  canMarkAsCoverPhoto,
  canUpdateMedia,
  canDeleteMedia,
}: Props) => {
  const [renameOpen, setRenameOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

  const renameAction = (mediaId: string, name: string) => {
    renameMedia(mediaId, subjectId, name);
    setRenameOpen(false);
  };

  const handleDelete = () => {
    deleteMedia(selectedMedia.id);
    setDeleteOpen(false);
  };

  const primaryPhotoText =
    primaryMediaId === selectedMedia.id ||
    primaryMediaId === selectedMedia.fileId
      ? 'Unmark as Cover Photo'
      : 'Mark as Cover Photo';

  const primaryPhotoColor =
    primaryMediaId === selectedMedia.id ||
    primaryMediaId === selectedMedia.fileId
      ? colors.brand.blue
      : colors.neutral.white;

  return (
    <div styleName="modal-top-toolbar">
      <div styleName="back-button-container">
        <IconButton onClick={onClose}>
          <Icon color={colors.neutral.white}>arrow_back</Icon>
        </IconButton>
        <div styleName="file-name">{`${selectedMedia.name}.${selectedMedia.fileExtension}`}</div>
      </div>
      <div styleName="toolbar-button-container">
        {canUpdateMedia && (
          <>
            <IconButton onClick={() => setRenameOpen(true)} tooltip="Rename">
              <Icon color={colors.neutral.white}>edit</Icon>
            </IconButton>
            <RenameModal
              id={selectedMedia.id}
              name={selectedMedia.name || ''}
              type="media"
              renameAction={renameAction}
              open={renameOpen}
              closeModal={() => setRenameOpen(false)}
            />
          </>
        )}
        <a
          href={`${FILES_ENDPOINT}/${selectedMedia.fileId}`}
          download={`${selectedMedia.name}.${selectedMedia.fileExtension}`}
        >
          <IconButton tooltip="Download">
            <Icon color={colors.neutral.white}>file_download</Icon>
          </IconButton>
        </a>
        {setAsPrimaryMedia && canMarkAsCoverPhoto && (
          <IconButton
            onClick={() => setAsPrimaryMedia(selectedMedia.id)}
            tooltip={primaryPhotoText}
          >
            <Icon color={primaryPhotoColor}>star</Icon>
          </IconButton>
        )}
        {canDeleteMedia && (
          <>
            <IconButton onClick={() => setDeleteOpen(true)} tooltip="Delete">
              <Icon color={colors.neutral.white}>delete</Icon>
            </IconButton>
            <DeleteModal
              title="Delete File"
              open={deleteOpen}
              onCancel={() => setDeleteOpen(false)}
              onConfirm={handleDelete}
              confirmText="Delete"
              content="Are you sure you want to delete this file?"
            />
          </>
        )}
        <IconButton onClick={openSideBar} tooltip="Information">
          <Icon color={colors.neutral.white}>info</Icon>
        </IconButton>
      </div>
    </div>
  );
};

export default CarouselHeader;
