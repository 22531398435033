import React from 'react';

import UserThumbnail from '@atom/components/common/UserThumbnail';
import { List } from '@atom/mui';
import { ApplicationSearchUsersState } from '@atom/types/store';
import history from '@atom/utilities/history';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';

import './searchBar.css';

const { ListItemButton, ListItemText } = List;

const styles = {
  list: {
    padding: 0,
  },
  listItem: {
    paddingLeft: '2rem',
  },
  icon: {
    marginRight: '1rem',
  },
  primaryTextStyle: {
    fontWeight: 'none',
  },
};

interface Props {
  query: string;
  users: ApplicationSearchUsersState[];
}

const TeamSearchResultsContainer = ({ query, users }: Props) => {
  const navigate = (id: string) => {
    history.push(`/team/${id}`);
  };

  return (
    <>
      <div styleName="search-entity-results-heading">Team</div>
      <List style={styles.list}>
        {users.map((user: ApplicationSearchUsersState) => {
          const name = textDisplayUtilities.highlightedText(
            query,
            `${user.firstName} ${user.lastName}`,
          );

          const emailAddress = textDisplayUtilities.highlightedText(
            query,
            user.email,
          );

          return (
            <ListItemButton
              key={user.id}
              onClick={() => navigate(user.id)}
              style={styles.listItem}
            >
              <div style={styles.icon}>
                <UserThumbnail image={user.photoUrl} />
              </div>
              <ListItemText
                primary={name}
                secondary={emailAddress}
                primaryTextStyle={styles.primaryTextStyle}
              />
            </ListItemButton>
          );
        })}
      </List>
    </>
  );
};

export default TeamSearchResultsContainer;
