import React, { useState } from 'react';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import EditSummaryModal from '@atom/components/common/workOrderDetail/workOrderSummary/EditSummaryModal';
import { Icon, Menu } from '@atom/mui';
import { Summary } from '@atom/types/work';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import './workInfo.css';

const { MenuItem } = Menu;

const styles = {
  icon: {
    marginRight: '0.5rem',
  },
};

export interface Props {
  summary: Summary;
  summaries: Summary[];
  index: number;
  updateSummary: (updatedSummary: Summary[]) => void;
}

const SummaryRow = ({ summary, summaries, index, updateSummary }: Props) => {
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const deleteSummary = () => {
    const updatedSummary = R.remove(index, 1, summaries);
    updateSummary(updatedSummary);
    setDeleteModalOpen(false);
  };

  const editSummary = (newSummary: Summary) => {
    const updatedSummary = summaries.map(
      (summaryItem: Summary, indexValue: number) =>
        indexValue === index ? newSummary : summaryItem,
    );
    updateSummary(updatedSummary);
  };

  return (
    <>
      <div styleName="summary-item-header">
        <div>{summary.title}</div>
        {hasRolePermissions(ROLE_SETS.INSPECTOR) && (
          <Menu IconButtonProps={{ style: { padding: 0 } }}>
            <MenuItem onClick={() => setEditModalOpen(true)}>
              <Icon style={styles.icon}>edit</Icon>
              Edit
            </MenuItem>
            <MenuItem onClick={() => setDeleteModalOpen(true)}>
              <Icon style={styles.icon}>delete</Icon>
              Delete
            </MenuItem>
          </Menu>
        )}
      </div>
      <div styleName="summary-item-content">{summary.contents}</div>
      <DeleteModal
        open={deleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={() => deleteSummary()}
        title="Delete Section?"
        content={`Are you sure you want to delete the ${summary.title} section?`}
      />
      <EditSummaryModal
        open={editModalOpen}
        closeModal={() => setEditModalOpen(false)}
        summary={summary}
        editSummary={editSummary}
      />
    </>
  );
};

export default SummaryRow;
