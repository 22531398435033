import React, { useEffect, useState } from 'react';
import uuid from 'uuid/v4';

import DeleteModal from '@atom/components/common/DeleteModal';
import { Checkbox, Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  FormInstanceFieldItem,
  PciModuleDataUpdate,
  PciModuleDistress,
  PciModuleInspectionOptions,
} from '@atom/types/form';
import { MediaItem } from '@atom/types/media';

import AcInspectionRow from './AcInspectionRow';

import './pci.css';

interface Props {
  formInstanceId: string;
  fieldId: string;
  field: FormInstanceFieldItem;
  inventoryAssetId: string;
  preview: boolean;
  media: MediaItem[];
  options: PciModuleInspectionOptions;
  onChange: (update: PciModuleDataUpdate) => void;
  removeFile: (mediaId: string, subjectId: string) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  isEditable: boolean;
}

const styles = {
  checkbox: {
    marginLeft: '2rem',
  },
  icon: {
    marginRight: '0.5rem',
  },
};

const getInitialDistress = (): PciModuleDistress => {
  return {
    id: uuid(),
  };
};

// specific to PCI when surface type is "AC" or "AC over PCC"
const AcInspection = ({
  formInstanceId,
  fieldId,
  field,
  inventoryAssetId,
  preview,
  options,
  media,
  onChange,
  removeFile,
  renameMedia,
  isEditable,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const hasNoDistress = field?.pci?.hasNoDistress ?? false;

  const distresses = hasNoDistress
    ? []
    : field?.pci?.distress || [getInitialDistress()];

  useEffect(() => {
    if (!field?.pci?.distress?.length) {
      onChange({ fieldId, distress: [getInitialDistress()] });
    }
  }, []);

  const handleDistressChange = (distress: PciModuleDistress[]) => {
    onChange({ fieldId, distress, hasNoDistress: false });
  };

  const handleChange = (id: string, property: string, value: any) => {
    const updatedDistress = distresses.map(distress =>
      distress.id === id ? { ...distress, [property]: value } : distress,
    );

    handleDistressChange(updatedDistress);
  };

  const handleDelete = (id: string) => {
    const updatedDistress = distresses.filter(distress => distress.id !== id);
    handleDistressChange(updatedDistress);
  };

  const handleAdd = () => {
    const updatedDistress = [...distresses, getInitialDistress()];
    handleDistressChange(updatedDistress);
  };

  const handleNoDistress = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setOpen(true);
    } else {
      onChange({
        fieldId,
        hasNoDistress: false,
        distress: [getInitialDistress()],
      });
    }
  };

  const handleNoDistressConfirm = () => {
    setOpen(false);
    onChange({ fieldId, hasNoDistress: true, distress: [] });
  };

  return (
    <>
      <div styleName={`inspection-container ${hasNoDistress ? 'checked' : ''}`}>
        <div styleName="no-distress-container">
          <div styleName="subtitle">Inspection</div>
          <Checkbox
            style={styles.checkbox}
            label="No Distress"
            onChange={handleNoDistress}
            checked={hasNoDistress}
            disabled={!isEditable}
          />
        </div>
      </div>
      {!hasNoDistress && (
        <>
          <div styleName="table-header">
            <div styleName="column distress">distress</div>
            <div styleName="column severity">distress severity</div>
            <div styleName="column quantity">total qty</div>
            <div styleName="column notes">field notes</div>
            <div styleName="column delete" />
          </div>
          <div styleName="table-rows-container">
            {distresses.map(distress => (
              <AcInspectionRow
                key={distress.id}
                formInstanceId={formInstanceId}
                assetId={inventoryAssetId}
                preview={preview}
                options={options}
                distress={distress}
                media={
                  distress.mediaIds
                    ? media.filter(medium =>
                        distress.mediaIds.includes(medium.id),
                      )
                    : []
                }
                onChange={(property, value) =>
                  handleChange(distress.id, property, value)
                }
                onDelete={() => handleDelete(distress.id)}
                removeFile={removeFile}
                renameMedia={renameMedia}
                isEditable={isEditable}
              />
            ))}
          </div>
          {isEditable && (
            <div styleName="add-distress-button" onClick={handleAdd}>
              <Icon style={styles.icon} color={colors.neutral.gray}>
                add_circle_outline
              </Icon>
              <div>Add Distress</div>
            </div>
          )}
        </>
      )}
      <DeleteModal
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={handleNoDistressConfirm}
        title="No Distress?"
        confirmText="Confirm"
        content={
          <div>
            No distress applies an excellent condition rating of 100. Other
            distress types cannot be used and will be removed.
          </div>
        }
      />
    </>
  );
};

export default AcInspection;
