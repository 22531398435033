import { History } from 'history';
import createBrowserHistory from 'history/createBrowserHistory';
import * as R from 'ramda';

type Action = 'PUSH' | 'REPLACE' | 'POP';

// eslint-disable-next-line valid-jsdoc
/**
 * AtomHistory wraps the history API. It should only ever be exported
 * and used as a singleton. Underlying history object can be retrieved
 * via getHistory if needed. Note that public methods should always be
 * arrow functions to ensure correct binding of `this`.
 */
class AtomHistory {
  private _history: History<any>;
  // internally tracked stack of browser locations
  private _locations: Location[];

  constructor() {
    this._history = createBrowserHistory();
    // @ts-ignore
    this._locations = [this.location];
    // @ts-ignore
    this._history.listen(this.handleHistoryChange);
  }

  public getHistory = () => {
    return this._history;
  };

  public get location() {
    return this._history.location;
  }

  public push = (
    url: string | History.LocationDescriptor<any>,
    state?: object,
  ): void => {
    // @ts-ignore
    this._history.push(url, state);
  };

  public replace = (
    url: string | History.LocationDescriptor<any>,
    state?: object,
  ): void => {
    // @ts-ignore
    this._history.replace(url, state);
  };

  public goBack = () => {
    this._history.goBack();
  };

  public goForward = () => {
    this._history.goForward();
  };

  public get length() {
    return this._history.length;
  }

  // navigates back to the previous path passing the current location
  // state
  public goBackWithState = (defaultPath: string = '') => {
    return this.lastLocation
      ? this._history.replace({
          ...this.lastLocation,
          state: this.currentState,
        })
      : this._history.replace(defaultPath);
  };

  public handleHistoryChange = (location: Location, action: Action) => {
    if (action === 'PUSH') {
      this._locations = R.append(location, this._locations);
    }

    if (action === 'REPLACE') {
      this.handleLocationReplaced(location);
    }

    if (action === 'POP') {
      this._locations = R.dropLast(1, this._locations);
    }
  };

  private handleLocationReplaced = (location: Location) => {
    // if pathname remains the same only `search` (query params) have been modified
    // and the location can be truly "replaced"
    if (location.pathname === R.last(this._locations)?.pathname) {
      this._locations = R.append(location, R.dropLast(1, this._locations));
    } else {
      this._locations = R.dropLast(1, this._locations);
    }
  };

  private get lastLocation() {
    return this._locations[this._locations.length - 2];
  }

  private get currentState() {
    return R.pathOr(null, ['state'])(R.last(this._locations));
  }
}

export default new AtomHistory();
