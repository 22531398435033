import React, { useMemo } from 'react';

import { Task } from '@atom/types/task';
import { TaskLocation } from '@atom/types/taskLocation';
import { WorkOrderType, WorkReport } from '@atom/types/work';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import CustomFieldsSection from './CustomFieldsSection';
import ReportAssetsSection from './ReportAssetsSection';
import { EMPTY_TASK } from './reportConstants';
import ReportLocationsSection from './ReportLocationsSection';
import ReportMaterials from './ReportMaterials';
import ReportMultiAssetsSection from './ReportMultiAssetsSection';
import ReportSection from './ReportSection';
import ReportUsers from './ReportUsers';
import {
  getFilteredMaterials,
  getFilteredUsers,
  getNotesField,
  getSectionActualCost,
  ReportTaskMaterials,
  ReportUser,
} from './reportUtilities';

import './workOrderReport.css';

interface Props {
  task: Task;
  type: WorkOrderType;
  isCompletedOrAbove: boolean;
  reportUsers: ReportUser[];
  reportMaterials: ReportTaskMaterials;
  reportAssets: any[];
  aldotWorkReport: WorkReport;
  reportLocations: TaskLocation[];
}

const TaskDetailReport = ({
  task,
  type,
  reportUsers,
  reportMaterials,
  reportAssets,
  isCompletedOrAbove,
  aldotWorkReport,
  reportLocations,
}: Props) => {
  const filteredUsers = getFilteredUsers(reportUsers, isCompletedOrAbove);

  const filteredStartEndMaterials = getFilteredMaterials(
    reportMaterials?.startEndMaterials,
    isCompletedOrAbove,
  );

  const filteredUsageMaterials = getFilteredMaterials(
    reportMaterials?.usageMaterials,
    isCompletedOrAbove,
  );

  const actualCostData = getSectionActualCost(task);

  const showCustomFields =
    !isNilOrEmpty(task?.fields) || task?.id === EMPTY_TASK.id;

  const hideTaskName = task?.id === EMPTY_TASK.id;
  const showTitleSubtext = isCompletedOrAbove && aldotWorkReport;

  const titleSubtext = useMemo(() => {
    if (isNilOrEmpty(aldotWorkReport)) {
      return '';
    }

    const { acctId, divId, distId, roadwayClassId, funcId } = aldotWorkReport;
    return `${acctId}-${divId}-${
      isNilOrEmpty(distId) ? '00' : distId
    }-${roadwayClassId}-${funcId}`;
  }, [aldotWorkReport]);

  const notesField = getNotesField(task);
  const showCompletedNotesField =
    notesField && notesField?.value && isCompletedOrAbove;

  const showLocationsSection =
    type === WorkOrderType.TASK_ASSETS_AND_LOCATIONS &&
    (task.id === EMPTY_TASK.id || task?.locationsEnabled);

  const showMultiAssetsSection =
    type === WorkOrderType.TASK_ASSETS_AND_LOCATIONS &&
    (task.id === EMPTY_TASK.id || task?.multiAssetsEnabled);

  const showAssetsSection = type === WorkOrderType.DEFAULT;

  return (
    <div styleName="task-container">
      <div styleName="task-header">
        {!hideTaskName && (
          <div>
            <div styleName="task-title">{task?.name}</div>
            {showTitleSubtext && (
              <div styleName="task-title-subtext">{titleSubtext}</div>
            )}
          </div>
        )}
        {isCompletedOrAbove && (
          <div styleName="actual-cost-container">
            Actual Cost:
            <span styleName="actual-cost-value">
              {numberToLocaleString(task?.actualCost)}
            </span>
          </div>
        )}
      </div>
      {showCustomFields && (
        <CustomFieldsSection
          isCompletedOrAbove={isCompletedOrAbove}
          task={task}
          type={type}
        />
      )}
      <ReportSection
        title="Team"
        isCompletedOrAbove={isCompletedOrAbove}
        actualCost={actualCostData.team}
      >
        <ReportUsers
          users={filteredUsers}
          isCompletedOrAbove={isCompletedOrAbove}
        />
      </ReportSection>
      <ReportSection
        title="Equipment and Material"
        isCompletedOrAbove={isCompletedOrAbove}
        actualCost={actualCostData.material}
      >
        <ReportMaterials
          startEndMaterials={filteredStartEndMaterials}
          usageMaterials={filteredUsageMaterials}
          isCompletedOrAbove={isCompletedOrAbove}
        />
      </ReportSection>
      {showLocationsSection && (
        <ReportSection title="Locations">
          <ReportLocationsSection
            isCompletedOrAbove={isCompletedOrAbove}
            reportLocations={reportLocations}
          />
        </ReportSection>
      )}
      {showMultiAssetsSection && (
        <ReportSection title="Assets">
          <ReportMultiAssetsSection
            isCompletedOrAbove={isCompletedOrAbove}
            reportAssets={reportAssets}
          />
        </ReportSection>
      )}
      {showAssetsSection && (
        <ReportSection title="Assets">
          <ReportAssetsSection reportAssets={reportAssets} />
        </ReportSection>
      )}
      {!isCompletedOrAbove && (
        <ReportSection title="Notes" emptyHeight="4.5rem" />
      )}
      {showCompletedNotesField && (
        <ReportSection title="Notes">
          <div styleName="basic-container">{notesField?.value}</div>
        </ReportSection>
      )}
    </div>
  );
};

export default TaskDetailReport;
