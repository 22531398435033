import React, { useContext, useMemo } from 'react';

import WorkTemplateTree from '@atom/components/common/workTemplateTree/WorkTemplateTree';
import { POLICY_GRANT_IDS_ALL } from '@atom/types/policy';
import { WorkTemplateFolderTree } from '@atom/types/workTemplate';
import { getAllDescendantFolderIds } from '@atom/utilities/workTemplateUtilities';

import PolicyModalContext from './PolicyModalContext';

const ROOT_TREE_ID = 'workTypes';

const PolicyWorkTemplateFolderSelection = () => {
  const { state, updateState } = useContext(PolicyModalContext);
  const { grants } = state;

  const selectedFolderIds = useMemo(
    () =>
      grants.some(({ id }) => id === POLICY_GRANT_IDS_ALL)
        ? new Set([ROOT_TREE_ID])
        : new Set(grants.map(({ id }) => id)),
    [grants],
  );

  const handleChange = (folder: WorkTemplateFolderTree) => {
    if (folder.id === ROOT_TREE_ID) {
      const isAllSelected = grants.some(
        ({ id }) => id === POLICY_GRANT_IDS_ALL,
      );
      return updateState({
        grants: isAllSelected
          ? []
          : [{ id: POLICY_GRANT_IDS_ALL, name: 'All' }],
      });
    }

    const isSelected = selectedFolderIds.has(folder.id);
    const updated = isSelected
      ? grants.filter(({ id }) => id !== folder.id)
      : [...grants, { id: folder.id, name: folder.name }];

    const descendantIds = new Set(getAllDescendantFolderIds(folder));
    const filtered = updated.filter(({ id }) => !descendantIds.has(id));

    return updateState({ grants: filtered });
  };

  return (
    <WorkTemplateTree
      includeSearch={false}
      getFolderSelected={folder => selectedFolderIds.has(folder.id)}
      onFolderToggle={handleChange}
      rootTreeName="All"
      autoSelectChildFolders
    />
  );
};

export default PolicyWorkTemplateFolderSelection;
