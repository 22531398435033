import * as React from 'react';

import NavigationIcon from './NavigationIcon';

import './mapDrawerContent.css';

interface Props {
  views: any[];
  activeView: string;
  setActiveView: (view: string) => void;
}

const NavigationControls = ({ views, activeView, setActiveView }: Props) => (
  <div styleName="icon-row">
    {views.map(view => {
      return (
        // @ts-ignore
        <NavigationIcon
          key={view.value}
          value={view.value}
          // @ts-ignore
          label={view.label}
          icon={view.icon}
          activeView={activeView}
          setActiveView={setActiveView}
        />
      );
    })}
  </div>
);

export default NavigationControls;
