import React, { useState } from 'react';
import { MenuItem, OutlinedInput, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import {
  PciModuleDistress,
  PciModuleInspectionOptions,
} from '@atom/types/form';
import { MediaItem } from '@atom/types/media';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './pci.css';

interface Props {
  formInstanceId: string;
  assetId: string;
  preview: boolean;
  options: PciModuleInspectionOptions;
  distress: PciModuleDistress;
  media: MediaItem[];
  onChange: (property: string, value: any) => void;
  onDelete: () => void;
  removeFile: (mediaId: string, subjectId: string) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  isEditable: boolean;
}

const useStyles = makeStyles({
  menuItemRoot: {
    minHeight: '36px',
    fontWeight: 500,
    fontSize: fonts.md,
  },
  selected: {
    color: colors.brand.blue,
    backgroundColor: `${colors.neutral.white} !important`,
  },
  input: {
    padding: '8px 14px',
  },
});

const AcInspectionRow = ({
  formInstanceId,
  assetId,
  preview,
  options,
  distress,
  media,
  onChange,
  onDelete,
  removeFile,
  renameMedia,
  isEditable,
}: Props) => {
  const classes = useStyles();

  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

  const handleQuantityChange = (event: any) => {
    const { value } = event.target;

    onChange('quantity', value);
  };

  const handleDelete = () => {
    onDelete();
    setDeleteOpen(false);
  };

  return (
    <>
      <div styleName="table-row">
        <div styleName="column distress">
          <Select
            variant="outlined"
            fullWidth
            value={distress.distress || ''}
            onChange={event => onChange('distress', event.target.value)}
            input={<OutlinedInput classes={{ input: classes.input }} />}
            displayEmpty
            disabled={!isEditable}
          >
            {options.distress.map(option => (
              <MenuItem
                key={option.name}
                value={option.name}
                classes={{
                  root: classes.menuItemRoot,
                  selected: classes.selected,
                }}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div styleName="column severity">
          <Select
            variant="outlined"
            fullWidth
            value={distress.severity || ''}
            onChange={event => onChange('severity', event.target.value)}
            input={<OutlinedInput classes={{ input: classes.input }} />}
            disabled={!isEditable}
          >
            {options.severity.map(option => (
              <MenuItem
                key={option.name}
                value={option.name}
                classes={{
                  root: classes.menuItemRoot,
                  selected: classes.selected,
                }}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div styleName="column quantity">
          <OutlinedInput
            fullWidth
            value={distress.quantity || ''}
            classes={{ input: classes.input }}
            onChange={handleQuantityChange}
            inputProps={{
              onKeyPress: event => {
                if (isNilOrEmpty(R.match(/[0-9]/, event.key))) {
                  event.preventDefault();
                }

                if (event.key === '0' && !distress.quantity) {
                  event.preventDefault();
                }
              },
            }}
            disabled={!isEditable}
          />
        </div>
        <div styleName="column notes">
          <OutlinedInput
            fullWidth
            value={distress.notes || ''}
            classes={{
              multiline: classes.input,
            }}
            onChange={event => onChange('notes', event.target.value)}
            multiline
            disabled={!isEditable}
          />
        </div>
        {isEditable && (
          <div styleName="column delete">
            <IconButton onClick={() => setDeleteOpen(true)}>
              <Icon>delete</Icon>
            </IconButton>
          </div>
        )}
      </div>
      <DeleteModal
        title="Remove Distress"
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={handleDelete}
        confirmText="Delete"
        content="Are you sure you want to remove this distress?"
      />
    </>
  );
};

export default AcInspectionRow;
