import React, { useContext, useMemo } from 'react';

import InventoryAssetTree from '@atom/components/common/inventoryAssetTree/InventoryAssetTree';
import {
  InventoryAssetDetailType,
  InventoryCategoryTree,
  SearchAsset,
} from '@atom/types/inventory';
import { POLICY_GRANT_IDS_ALL } from '@atom/types/policy';

import PolicyModalContext from './PolicyModalContext';

const ROOT_TREE_ID = 'inventory';

const PolicyAssetSelection = () => {
  const { state, updateState } = useContext(PolicyModalContext);
  const { grants } = state;

  const selectedAssetIds = useMemo(
    () =>
      grants.some(({ id }) => id === POLICY_GRANT_IDS_ALL)
        ? new Set([ROOT_TREE_ID])
        : new Set(grants.map(({ id }) => id)),
    [grants],
  );

  const handleChange = (
    tree: InventoryCategoryTree | SearchAsset | InventoryAssetDetailType,
  ) => {
    if (tree.id === ROOT_TREE_ID) {
      const isAllSelected = grants.some(
        ({ id }) => id === POLICY_GRANT_IDS_ALL,
      );

      return updateState({
        grants: isAllSelected
          ? []
          : [{ id: POLICY_GRANT_IDS_ALL, name: 'All' }],
      });
    }

    const isSelected = selectedAssetIds.has(tree.id);
    const updated = isSelected
      ? grants.filter(({ id }) => id !== tree.id)
      : [...grants, { id: tree.id, name: tree.name }];

    return updateState({ grants: updated });
  };

  return (
    <InventoryAssetTree
      search="assets"
      includeAssets
      rootTreeName="All"
      selectedAssetIds={selectedAssetIds}
      onAssetToggle={handleChange}
      allAssetsSelected={grants.some(({ id }) => id === POLICY_GRANT_IDS_ALL)}
      onSelectAllAssetsToggle={() => handleChange({ id: ROOT_TREE_ID })}
      showRootCategoryCheckbox
    />
  );
};

export default PolicyAssetSelection;
