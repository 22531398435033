import React from 'react';

import { Modal } from '@atom/mui';

interface Props {
  open: boolean;
  onCancel: () => void;
  onReject: () => void;
}

const RejectChangesModal = ({ open, onCancel, onReject }: Props) => {
  return (
    <Modal
      open={open}
      cancelButtonText="Cancel"
      confirmButtonText="Reject"
      onCancel={onCancel}
      onConfirm={onReject}
      title="Reject Changes"
    >
      Are you sure you want to reject the changes on this attribute?
    </Modal>
  );
};

export default RejectChangesModal;
