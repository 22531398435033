import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import WorkTemplateContext, {
  WorkTemplateActionTypes,
} from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_UPDATE } from '@atom/graph/workTemplate';
import { Button, Icon, Menu, Modal, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  WorkOrderTemplateUpdateInput,
  WorkTemplate,
} from '@atom/types/workTemplate';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import { getModalValue, ModalVariant } from './WorkTemplateStatusModalValues';

import './workTemplateStatus.css';

const { MenuItem } = Menu;

const HTTP_STATUS_CONFLICT = 409;

interface Props {
  disabled: boolean;
}

const WorkTemplateStatus = ({ disabled }: Props) => {
  const { workTemplate, workTypeVariant, dispatch } = useContext(
    WorkTemplateContext,
  );

  const [modalVariant, setModalVariant] = useState<ModalVariant>();

  const [workTemplateUpdate] = useMutation<
    { workOrderTemplateUpdate: WorkTemplate },
    { input: WorkOrderTemplateUpdateInput }
  >(WORK_TEMPLATE_UPDATE);

  const updateWorkTemplate = async () => {
    const published = modalVariant === ModalVariant.PUBLISH_CONFIRM;

    try {
      const { data } = await workTemplateUpdate({
        variables: {
          input: {
            workOrderTemplateId: workTemplate.id,
            published,
          },
        },
      });

      dispatch({
        type: WorkTemplateActionTypes.UPDATE_WORK_TEMPLATE_PROPERTY,
        data: {
          property: 'published',
          value: data?.workOrderTemplateUpdate?.published,
        },
      });

      setModalVariant(null);
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_STATUS_CONFLICT && published) {
        setModalVariant(ModalVariant.PUBLISH_ERROR);
      } else if (
        err?.networkError?.statusCode === HTTP_STATUS_CONFLICT &&
        !published
      ) {
        setModalVariant(ModalVariant.DRAFT_ERROR);
      } else {
        Snackbar.error({ message: 'An unknown error occurred' });
      }
    }
  };

  const modalValue = getModalValue(workTypeVariant, modalVariant);

  const getFooter = () => {
    return modalVariant === ModalVariant.DRAFT_ERROR ||
      modalVariant === ModalVariant.PUBLISH_ERROR ? (
      <Button
        color="primary"
        variant="contained"
        onClick={() => setModalVariant(null)}
      >
        {modalValue?.button}
      </Button>
    ) : (
      <div>
        <Button
          onClick={() => setModalVariant(null)}
          style={{ marginRight: '0.5rem' }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={updateWorkTemplate}
        >
          {modalValue?.button}
        </Button>
      </div>
    );
  };

  const icon = workTemplate.published ? 'lock' : 'edit';
  const text = workTemplate.published ? 'published' : 'draft';

  return (
    <>
      <Menu
        icon={
          <div styleName="status-container">
            <Icon color={colors.neutral.white}>{icon}</Icon>
            <div styleName="status-text">{text}</div>
            {!disabled && (
              <Icon color={colors.neutral.white}>arrow_drop_down</Icon>
            )}
          </div>
        }
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        noIconButton
        disabled={disabled}
      >
        <MenuItem
          key="published"
          onClick={
            workTemplate.published
              ? () => {}
              : () => setModalVariant(ModalVariant.PUBLISH_CONFIRM)
          }
          selected={workTemplate.published}
          startAdornment={<Icon>lock</Icon>}
        >
          <div styleName="status-option">
            <div>PUBLISHED</div>
            {workTemplate.published && (
              <Icon color={colors.brand.blue}>check</Icon>
            )}
          </div>
        </MenuItem>
        <MenuItem
          key="draft"
          onClick={
            !workTemplate.published
              ? () => {}
              : () => setModalVariant(ModalVariant.DRAFT_CONFIRM)
          }
          selected={!workTemplate.published}
          startAdornment={<Icon>edit</Icon>}
        >
          <div styleName="status-option">
            <div>DRAFT</div>
            {!workTemplate.published && (
              <Icon color={colors.brand.blue}>check</Icon>
            )}
          </div>
        </MenuItem>
      </Menu>
      <Modal
        title={modalValue?.title}
        open={!isNilOrEmpty(modalVariant)}
        onCancel={() => setModalVariant(null)}
        footer={getFooter()}
      >
        {modalValue?.content}
      </Modal>
    </>
  );
};

export default WorkTemplateStatus;
