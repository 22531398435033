import React from 'react';
import {
  FavoriteDetail,
  FavoritesList,
  FavoriteSubjectType,
} from 'client/types/favorite';

import FavoritesIconButton from '@atom/components/common/favorites/FavoritesIconButton';
import { Checkbox, List } from '@atom/mui';

import FavoriteItemIcon from './FavoriteItemIcon';

const { ListItemButton, ListItemText } = List;

const styles = {
  listItemButton: { paddingLeft: '5rem', paddingRight: '1rem' },
  checkbox: { marginRight: '0.5rem' },
};

export interface Props {
  favorite: FavoriteDetail;
  subjectType: FavoriteSubjectType;
  favoritesLists: FavoritesList[];
  updateFavorite: (favorite: FavoriteDetail) => Promise<void>;
  allFavorites: FavoriteDetail[];
  handleAddList: (listName: string) => void;
  canAddFavorite: boolean;
  canAddList: boolean;
  handleFavoriteToggle: (
    event: React.MouseEvent<any>,
    favorite: FavoriteDetail,
  ) => void;
  handleListToggle: (list: FavoritesList) => void;
  selectedFavoriteIds: Set<string>;
  disabledFavoriteIds: Set<string>;
}

const FavoriteItem = ({
  subjectType,
  favorite,
  favoritesLists,
  updateFavorite,
  allFavorites,
  handleAddList,
  canAddFavorite,
  canAddList,
  handleFavoriteToggle,
  selectedFavoriteIds,
  disabledFavoriteIds,
}: Props) => {
  const isOnTask = disabledFavoriteIds.has(favorite.id);
  const disabled = favorite.inactive || isOnTask;
  const selected = isOnTask || selectedFavoriteIds?.has(favorite.id);

  const primaryText =
    subjectType === 'MATERIAL'
      ? favorite.name
      : `${favorite.firstName} ${favorite.lastName}`;

  const secondaryText =
    subjectType === 'MATERIAL' ? favorite.assetType : favorite.email;

  return (
    <ListItemButton style={styles.listItemButton} disableGutters>
      <Checkbox
        checked={selected}
        onClick={event => handleFavoriteToggle(event, favorite)}
        style={styles.checkbox}
        color="primary"
        disabled={disabled}
      />
      <FavoriteItemIcon subjectType={subjectType} favorite={favorite} />
      <ListItemText primary={primaryText} secondary={secondaryText} />
      <FavoritesIconButton
        handleAddList={handleAddList}
        item={favorite}
        updateFavorite={updateFavorite}
        favorites={allFavorites}
        favoritesLists={favoritesLists}
        typeName="items"
        canAddFavorite={canAddFavorite}
        canAddList={canAddList}
      />
    </ListItemButton>
  );
};

export default FavoriteItem;
