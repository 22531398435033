import React from 'react';

import { Icon, IconButton, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';

const styles = {
  icon: {
    width: '2.375rem',
    height: '2.375rem',
    padding: '0.4375rem',
    zIndex: '200',
  },
  smallIcon: {
    width: '1.75rem',
    height: '1.75rem',
    padding: '0',
    zIndex: '200',
  },
};

interface Props {
  icon: React.ReactNode;
  active: boolean;
  onClick: () => any;
  tooltip?: string;
}

const MapControl = ({ icon, active, onClick, tooltip }: Props) => {
  const iconColor = active ? colors.brand.blue : colors.neutral.gray;

  return (
    <IconButton style={styles.icon} onClick={onClick}>
      <Icon color={iconColor}>
        <Tooltip placement="bottom-end" title={tooltip}>
          <span>{icon}</span>
        </Tooltip>
      </Icon>
    </IconButton>
  );
};

export default MapControl;
