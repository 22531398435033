import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  requestClearFormTemplate,
  requestFormTemplateDelete,
} from '@atom/actions/formTemplateActions';
import DeleteModal from '@atom/components/common/DeleteModal';
import { Button, Icon, IconButton, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import { FormTemplateType } from '@atom/types/form';
import { FormTemplateDetailState } from '@atom/types/store';
import history from '@atom/utilities/history';

import EditFormModal from './EditFormModal';

import './formBuilder.css';

const styles = {
  button: {
    color: colors.brand.blue,
    backgroundColor: colors.neutral.white,
  },
};

interface Props {
  formTemplate: FormTemplateType;
  publishForm: () => void;
  saveFormTemplate: (data: FormTemplateDetailState) => void;
}

const FormBuilderHeader = ({
  formTemplate,
  publishForm,
  saveFormTemplate,
}: Props) => {
  const dispatch = useDispatch();

  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [publishOpen, setPublishOpen] = useState<boolean>(false);

  const navigateToFormRepository = () => {
    dispatch(requestClearFormTemplate());
    saveFormTemplate(formTemplate);
    history.goBackWithState('/form-repository');
  };

  const navigateToFormPreview = () => {
    saveFormTemplate(formTemplate);
    history.push(`/form-builder/${formTemplate.id}/preview`);
  };

  const removeFormTemplate = () => {
    dispatch(requestFormTemplateDelete(formTemplate.id));
    setDeleteOpen(false);
    history.push('/form-repository');
  };

  return (
    <>
      <div styleName="header-container">
        <div styleName="name-container">
          <IconButton onClick={navigateToFormRepository} tooltip="Go back">
            <Icon color={colors.neutral.white}>arrow_back</Icon>
          </IconButton>
          <div>{formTemplate.name}</div>
        </div>
        <div styleName="form-builder-header">
          <IconButton onClick={() => setEditOpen(true)} tooltip="Edit">
            <Icon color={colors.neutral.white}>edit</Icon>
          </IconButton>
          <IconButton onClick={navigateToFormPreview} tooltip="Preview">
            <Icon color={colors.neutral.white}>remove_red_eye</Icon>
          </IconButton>
          <IconButton onClick={() => setDeleteOpen(true)} tooltip="Delete">
            <Icon color={colors.neutral.white}>delete</Icon>
          </IconButton>
          <Button
            variant="contained"
            style={styles.button}
            onClick={() => setPublishOpen(true)}
          >
            Publish
          </Button>
        </div>
      </div>
      <EditFormModal open={editOpen} onClose={() => setEditOpen(false)} />
      <DeleteModal
        open={deleteOpen}
        title={`Delete ${formTemplate.name}`}
        content="Are you sure you want to delete this form template?"
        onCancel={() => setDeleteOpen(false)}
        onConfirm={removeFormTemplate}
      />
      <Modal
        open={publishOpen}
        cancelButtonText="Cancel"
        confirmButtonText="Publish"
        onCancel={() => setPublishOpen(false)}
        onConfirm={publishForm}
        title="Publish Form"
      >
        Published forms cannot be edited. Are you sure you want to publish this
        form?
      </Modal>
    </>
  );
};

export default FormBuilderHeader;
