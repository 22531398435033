import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import * as inventoryExportActionCreators from '@atom/actions/inventoryExportActions';
import { Icon, IconButton, Modal, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { EventType } from '@atom/types/event';

import './inventoryExport.css';

interface Props {
  loading: boolean;
}

const CreateTemplateModal = ({ loading }: Props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>('');

  const closeModal = () => {
    setOpen(false);
    setName('');
  };

  const openModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    closeModal();
  }, [loading]);

  const confirm = () => {
    dispatch(
      inventoryExportActionCreators.requestExportTemplateCreate({
        name,
      }),
    );
  };

  const onChange = (event: EventType) => {
    setName(event.target.value);
  };

  const creationDisabled = R.isEmpty(name);

  return (
    <div styleName="icon-container">
      <IconButton tooltip="Create" onClick={openModal}>
        <Icon color={colors.neutral.white}>add</Icon>
      </IconButton>
      <Modal
        title="Create Data Export"
        onCancel={closeModal}
        onConfirm={confirm}
        confirmButtonText="Create"
        disabled={creationDisabled}
        open={open}
      >
        <div styleName="modal-container">
          <TextField
            variant="standard"
            label="Name"
            value={name}
            name="name"
            onChange={onChange}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreateTemplateModal;
