import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as R from 'ramda';

import ListViewTable from '@atom/components/common/listViewTable/ListViewTable';
import WorkOrderStatusPill from '@atom/components/common/workOrderDetail/workOrderStatusPill/WorkOrderStatusPill';
import WorkUsers from '@atom/components/common/workUsers/WorkUsers';
import UserContext from '@atom/components/userDetail/UserContext';
import { Progress } from '@atom/mui';
import api from '@atom/utilities/api';
import { WORK_ORDERS_ENDPOINT } from '@atom/utilities/endpoints';
import { TOTAL_COUNT_HEADER } from '@atom/utilities/requestUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import { rollUpTaskUsers } from '@atom/utilities/userUtilities';

import WorkFilters from './WorkFilters';

// @ts-ignore
import bodyPaneStyles from '../../../styles/body-pane.css';
// @ts-ignore
import tableStyles from '../../../styles/table.css';
// @ts-ignore
import workTabStyles from './workTab.css';

// @ts-ignore
let styleReference = bodyPaneStyles;
styleReference = tableStyles;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
styleReference = workTabStyles;

const styles = {
  tableStyle: {
    width: '100%',
    marginTop: 0,
    marginBottom: '3.125rem',
    height: 'calc(100vh - 320px)',
  },
};

const displayHeaders = [
  'Work ID',
  'Status',
  'Asset Name',
  'Due Date',
  'Assigned To',
  'Tasks',
];

const fields = [
  'name',
  'statusId',
  'assetName',
  'dueDate',
  'users',
  'taskCount',
];

interface Filters {
  statusIds: string[];
}

const WorkTab = () => {
  const { user } = useContext(UserContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [workOrders, setWorkOrders] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(25);
  const [filters, setFilters] = useState<Filters>({ statusIds: [] });

  const getWorkOrders = async filterValue => {
    setLoading(true);

    const { data, headers } = await api.get(WORK_ORDERS_ENDPOINT, {
      page,
      limit,
      sortBy: 'name',
      isAscending: true,
      userIds: user.id,
      statusIds: filterValue.statusIds.join(','),
    });

    setTotalCount(Number(headers[TOTAL_COUNT_HEADER]));
    setWorkOrders(data);
    setLoading(false);
  };

  useEffect(() => {
    getWorkOrders(filters);
  }, [user.id, page, limit]);

  const buildCell = (row, field, index) => {
    const fieldTemplates = {
      name: (
        <td key={index} styleName="tableStyles.clickable" onClick={() => {}}>
          <Link to={`/workOrders/${row.id}`}>{row.name}</Link>
        </td>
      ),
      assetName: <td key={index}>{row.inventoryAssetName}</td>,
      taskCount: <td key={index}>{row.tasks.length}</td>,
      statusId: (
        <td key={index} styleName="tableStyles.status-pill">
          <WorkOrderStatusPill
            statusId={row.statusId}
            className="detail-pane"
          />
        </td>
      ),
      dueDate: <td key={index}>{setDisplayDate(row.dueDate)}</td>,
      users: (
        <td key={index} styleName="tableStyles.assigned-users">
          <WorkUsers users={rollUpTaskUsers(row.tasks)} />
        </td>
      ),
    };
    return fieldTemplates[field] || <td key={index}>{row[field]}</td>;
  };

  return (
    <div styleName="workTabStyles.content-container">
      <div styleName="workTabStyles.sidebar-container">
        <WorkFilters
          filters={filters}
          setFilters={setFilters}
          getWorkOrders={getWorkOrders}
        />
      </div>
      <div styleName="workTabStyles.right-pane">
        <div styleName="workTabStyles.title">{`${totalCount} work`}</div>
        {loading || R.isNil(workOrders) ? (
          <Progress />
        ) : (
          <ListViewTable
            style={styles.tableStyle}
            displayHeaders={displayHeaders}
            dataRows={workOrders}
            fields={fields}
            // @ts-ignore
            buildCell={buildCell}
            // @ts-ignore
            totalRows={totalCount}
            // @ts-ignore
            incrementPage={(increment: number) => setPage(page + increment)}
            // @ts-ignore
            updateLimit={(newLimit: number) => {
              setPage(1);
              setLimit(newLimit);
            }}
            page={page}
            limit={limit}
          />
        )}
      </div>
    </div>
  );
};

export default WorkTab;
