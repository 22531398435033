import React, { useState } from 'react';

import { TextField } from '@atom/mui';
import { DataType } from '@atom/types/dataType';
import { WorkOrderField } from '@atom/types/work';

interface Props {
  field: WorkOrderField;
  onChange: (fieldId: string, value: any) => void;
  onBlur: (fieldId: string, value: any) => void;
  isDisabled: boolean;
}

const WorkOrderTextField = ({ field, onChange, onBlur, isDisabled }: Props) => {
  const [value, setValue] = useState<string>(field?.value || '');

  const handleChange = event => {
    setValue(event.target.value);
    onChange(field.id, event.target.value);
  };

  const handleOnBlur = event => {
    if (field?.value !== event.target.value) {
      setValue(event.target.value);
      onBlur(field.id, event.target.value);
    }
  };

  const style = {
    flex: field.dataType === DataType.LONG_TEXT ? 1 : 0.66,
  };

  return (
    <TextField
      id={field.id}
      label={field.title}
      value={value}
      style={style}
      onChange={handleChange}
      onBlur={handleOnBlur}
      disabled={isDisabled}
      multiline={field.dataType === DataType.LONG_TEXT}
    />
  );
};

export default WorkOrderTextField;
