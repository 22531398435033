import { gql } from '@apollo/client';

export const GET_HISTORY = gql`
  query history($input: HistoryConnectionInput!) {
    history(input: $input) {
      totalCount
      history {
        id
        subjectId
        date
        message
        device
        user {
          id
          firstName
          lastName
          photoUrl
        }
      }
    }
  }
`;
