import React, { useContext } from 'react';
import * as R from 'ramda';

import LocationsAndAssetsContext from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsContext';
import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';

import {
  AddAssetError,
  getCrossValidationAttributes,
} from '../taskLocationUtilities';

import './taskAssetModal.css';

interface Props {
  assetErrorType: AddAssetError;
}

const AldotAssetError = ({ assetErrorType }: Props) => {
  const { locations, assets: assetsOnTask } = useContext(
    LocationsAndAssetsContext,
  );

  const crossAttributes = getCrossValidationAttributes(assetsOnTask, locations);

  const content =
    assetErrorType === AddAssetError.ALDOT_CART
      ? 'Management Unit, County, and Road Class for assets must remain the same on the task.'
      : 'All locations and assets added after must have the same management unit, county, road class.';

  const showAdditionalInfo = assetErrorType === AddAssetError.ALDOT_CROSS;

  return (
    <div styleName="error-container">
      <Icon color={colors.brand.red}>error_outline</Icon>
      <div styleName="content-container">
        <div>{content}</div>
        {showAdditionalInfo && (
          <div styleName="additional-info">
            {R.keys(crossAttributes).map(title => (
              <div key={title}>{`- ${title}: ${crossAttributes[title]}`}</div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AldotAssetError;
