import React from 'react';

import { Skeleton } from '@atom/mui';

import './skeletonRow.css';

const SkeletonListRow = () => (
  <div styleName="skeleton-list-row">
    <div styleName="skeleton-list-bullet">
      <Skeleton variant="circular" />
    </div>
    <div styleName="skeleton-list-item">
      <Skeleton />
    </div>
  </div>
);

export default SkeletonListRow;
