import * as R from 'ramda';

import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function (state = initialState.inventorySchemaTree, action) {
  switch (action.type) {
    case actionTypes.GET_SCHEMA_TREE_SUCCESS: {
      const { data } = action;
      return {
        ...data,
        expanded: true,
      };
    }
    case actionTypes.UPDATE_SCHEMA_TREE_SUCCESS: {
      const { path, expanded } = action.data;
      const lensPath = R.lensPath(path);

      return R.over(
        lensPath,
        element => ({
          ...element,
          expanded: !expanded,
        }),
        state,
      );
    }
    default: {
      return state;
    }
  }
}
