import { gql } from '@apollo/client';

export const BASE_POLICY = gql`
  fragment BasePolicy on Policy {
    id
    subjectType
    subjectId
    action
    resource
    grant {
      type
      ids
    }
    grants {
      id
      name
    }
    createdBy {
      id
      firstName
      lastName
      email
    }
    createdDate
    updatedBy {
      id
      firstName
      lastName
      email
    }
    updatedDate
  }
`;

export const GET_POLICIES_FOR_VALIDATION = gql`
  query policies($input: PoliciesConnectionInput!) {
    policies(input: $input) {
      totalCount
      policies {
        id
        subjectType
        subjectId
        action
        resource
        grant {
          type
          ids
        }
        grants {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_POLICY = gql`
  mutation policyCreate($input: PolicyCreateInput!) {
    policyCreate(input: $input) {
      ...BasePolicy
    }
  }
  ${BASE_POLICY}
`;

export const BULK_CREATE_POLICIES = gql`
  mutation policiesCreate($input: [PolicyCreateInput!]!) {
    policiesCreate(input: $input) {
      ...BasePolicy
    }
  }
  ${BASE_POLICY}
`;

export const UPDATE_POLICY = gql`
  mutation policyUpdate($input: PolicyUpdateInput!) {
    policyUpdate(input: $input) {
      ...BasePolicy
    }
  }
  ${BASE_POLICY}
`;

export const DELETE_POLICY = gql`
  mutation deletePolicy($id: ID!) {
    policyDelete(id: $id)
  }
`;
