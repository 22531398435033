import * as React from 'react';
import * as R from 'ramda';

import { Checkbox, Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  InventoryFullCategoryTreeType,
  InventorySchemaItem,
} from '@atom/types/inventory';
import { fullCategoryLeftTreeIcon } from '@atom/utilities/iconUtilities';
import schemaUtilities from '@atom/utilities/schemaUtilities';

import './inventoryExportDetail.css';

const PADDING_OFFSET = 2;

interface Props {
  category: InventoryFullCategoryTreeType;
  indentation: number;
  rootSchemas: InventorySchemaItem[];
  updateCategoryIds: (categoryId: string, schemaId: string) => void;
  selectedCategoryIds: Set<string>;
  selectedSchemaId: string;
}

const CategoryTreeRow = ({
  category,
  indentation,
  rootSchemas,
  updateCategoryIds,
  selectedCategoryIds,
  selectedSchemaId,
}: Props) => {
  const colorId = rootSchemas.reduce(
    (acc: any, schema: InventorySchemaItem): any => {
      return schema.id === category.schemaId ? schema.colorId : acc;
    },
    '',
  );

  const schemaColor = schemaUtilities.getSchemaColorFromColorId(colorId);
  const isDisabled = !R.isEmpty(selectedSchemaId)
    ? category.schemaId !== selectedSchemaId
    : false;

  const leftIcon = R.isNil(category.schemaId) ? (
    fullCategoryLeftTreeIcon()
  ) : (
    <>
      <Checkbox
        checked={selectedCategoryIds.has(category.id)}
        onChange={() => updateCategoryIds(category.id, category.schemaId)}
        disabled={isDisabled}
      />
      <Icon
        style={{
          fontSize: '24px',
          marginLeft: R.isNil(category.schemaId) ? '0.5rem' : '0rem',
        }}
        color={schemaColor}
      >
        folder
      </Icon>
    </>
  );

  const style = {
    paddingLeft: `calc(1rem + ${PADDING_OFFSET * indentation}rem)`,
  };

  const colorStyle = {
    color: selectedCategoryIds.has(category.id)
      ? colors.brand.blue
      : colors.neutral.dark,
  };

  const selectedStyle = selectedCategoryIds.has(category.id)
    ? 'row selected'
    : 'row';

  const subText =
    category.assetType && category.schemaId ? category.assetType : '';

  return (
    <>
      <div styleName={selectedStyle} style={style}>
        {leftIcon}
        <div styleName="name-container">
          <div styleName="category-row-name" style={colorStyle}>
            {category.name}
          </div>
          <div styleName="category-row-subtext">{subText}</div>
        </div>
      </div>
      {category.children ? (
        category.children.map((nextCategory: InventoryFullCategoryTreeType) => {
          return (
            <CategoryTreeRow
              key={nextCategory.id}
              category={nextCategory}
              indentation={indentation + 1}
              rootSchemas={rootSchemas}
              updateCategoryIds={updateCategoryIds}
              selectedCategoryIds={selectedCategoryIds}
              selectedSchemaId={selectedSchemaId}
            />
          );
        })
      ) : (
        <div />
      )}
    </>
  );
};

export default CategoryTreeRow;
