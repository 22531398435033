import React, { useContext } from 'react';

// @ts-ignore
import emptyDashboard from '@atom/images/emptyDashboard.svg';
import { Button } from '@atom/mui';
import colors from '@atom/styles/colors';

import DashboardContext from './DashboardContext';
import DashboardCustomizeIcon from './DashboardCustomizeIcon';

import './dashboardEmptyState.css';

const styles = {
  blueButton: {
    backgroundColor: colors.brand.blue,
    color: colors.neutral.white,
    padding: '0.5rem 1rem',
  },
};

const DashboardEmptyState = () => {
  const { setShowCustomize } = useContext(DashboardContext);
  return (
    <div styleName="empty-dashboard-container">
      <img src={emptyDashboard} />
      <div styleName="empty-dashboard-title">Start building your dashboard</div>
      <div styleName="empty-dashboard-subtitle">
        Your home dashboard is ready to take off! Customize it with cards to see
        what's important and keep your day on track.
      </div>
      <Button
        style={styles.blueButton}
        onClick={() => setShowCustomize(true)}
        startIcon={<DashboardCustomizeIcon />}
        data-cy="dashboardEmptyCustomizeIcon"
      >
        Customize
      </Button>
    </div>
  );
};

export default DashboardEmptyState;
