import {
  TaskField,
  TaskMaterialItem,
  TaskUserGroupInputItem,
} from '@atom/types/task';
import { UserDetail } from '@atom/types/user';
import {
  SubField,
  Summary,
  WorkOrderField,
  WorkOrderType,
  WorkPriorityType,
} from '@atom/types/work';

import { TaskFieldDataType, WorkOrderFieldDataType } from './dataType';
import { PolicyAction } from './policy';

export enum InheritedComponentType {
  CUSTOM_FIELD = 'CUSTOM_FIELD',
  SUMMARY = 'SUMMARY',
  MEDIA = 'MEDIA',
  TASK_CUSTOM_FIELD = 'TASK_CUSTOM_FIELD',
  TASK_USER_GROUP = 'TASK_USER_GROUP',
  TASK_ASSIGNEE = 'TASK_ASSIGNEE',
  TASK_SCHEMA = 'TASK_SCHEMA',
  TASK_MATERIAL = 'TASK_MATERIAL',
  TASK_CATEGORY = 'TASK_CATEGORY',
  TASK_FORM_TEMPLATE = 'TASK_FORM_TEMPLATE',
  TASK_ESTIMATED_COST = 'TASK_ESTIMATED_COST',
}

export enum ModuleType {
  COST_BASED_INVENTORY = 'COST_BASED_INVENTORY',
  WORK_INVENTORY = 'WORK_INVENTORY',
  FORMS = 'FORMS',
  CUSTOM_FIELDS = 'CUSTOM_FIELDS',
}

export enum AssetSettingsView {
  ALLOWED = 'ALLOWED',
  REQUIRED = 'REQUIRED',
}

export enum RequiredOption {
  NONE = 'NONE',
  AT_LEAST_ONE = 'AT_LEAST_ONE',
  MANY = 'MANY',
}

export interface WorkTemplateFolderTree {
  id: string;
  name: string;
  hasTemplates: boolean;
  folderPath: string[];
  children: WorkTemplateFolderTree[];
}

export interface WorkTemplateAssigneeItem {
  id: string;
}

export interface WorkTemplateAssignees {
  [key: string]: WorkTemplateAssigneeItem;
}

export interface WorkTemplateTaskBudget {
  budgetId: string;
  name: string;
  rate: number;
  groupPath?: string[];
}

export interface WorkTemplateTaskUserGroup {
  userGroupId: string;
  name?: string;
  quantity: number;
  workTime: number;
  colorId?: number;
  budget?: WorkTemplateTaskBudget;
}

export interface InheritedComponent {
  type: InheritedComponentType;
  parentId: string;
  ids: string[];
  required: boolean;
}

export interface WorkTemplateMedia {
  fileId: string;
  name: string;
  type: string;
  fileExtension: string;
  mediaId: string;
}

export interface WorkTemplateTaskCategory {
  id: string;
  name: string;
  categoryPath: string[];
  colorId: string;
}

export interface WorkTemplateSchema {
  id: string;
  name: string;
  colorId: number;
  markerId: number;
  ancestors?: string[];
}

export interface WorkTemplateTaskItem {
  id?: string;
  name: string;
  inheritedComponents?: InheritedComponent[];
  description: string;
  estimatedCost?: number;
  isEstimatedCostOverridden: boolean;
  formTemplateIds?: string[];
  formTemplates?: {
    id: string;
    name: string;
  }[];
  userIds?: string[];
  users?: UserDetail[];
  duration: number;
  dueDate?: number;
  materials: TaskMaterialItem[];
  assignees: WorkTemplateAssignees;
  userGroups: WorkTemplateTaskUserGroup[];
  fields: TaskField[];
  fieldOrder: string[];
  categories?: WorkTemplateTaskCategory[];
  categoryIds: string[];
  modules?: ModuleType[];
  schemaIds?: string[];
  schemas?: WorkTemplateSchema[];
  locationsEnabled?: boolean;
  requireAtLeastOneLocation?: boolean;
  multiAssetsEnabled?: boolean;
  allowedMultiAssetSchemaIds?: string[];
  allowedMultiAssetSchemas?: WorkTemplateSchema[];
  requireAtLeastOneMultiAsset?: boolean;
  requiredMultiAssetSchemaIds?: string[];
  requiredMultiAssetSchemas?: WorkTemplateSchema[];
}

export interface WorkTemplate {
  id: string;
  type?: WorkOrderType;
  externalId: string;
  published: boolean;
  parentId: string;
  isParent?: boolean;
  inheritedComponents?: InheritedComponent[];
  requiredInheritedComponents?: InheritedComponentType[];
  name: string;
  schemaId?: string;
  leadAssigneeId?: string;
  tasks: WorkTemplateTaskItem[];
  fields: WorkOrderField[];
  fieldOrder: string[];
  folderPath: string[];
  summary: Summary[];
  media: WorkTemplateMedia[];
  estimatedCost?: number;
  priorityId?: WorkPriorityType;
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
    photoUrl: string;
  };
  createdDate: number;
}

export interface WorkTemplateItem {
  id: string;
  type?: WorkOrderType;
  name: string;
  published: boolean;
  schemaId?: string;
  schemaName?: string;
  isParent?: boolean;
  parentId?: string;
  parentName?: string;
  priorityId?: WorkPriorityType;
  createdDate: number;
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
    photoUrl: string;
  };
}

export interface WorkTemplatesConnection {
  totalCount: number;
  workOrderTemplates: WorkTemplateItem[];
}

export interface WorkOrderTemplatesConnectionInput {
  ids?: string[];
  folderId?: string;
  schemaId?: string;
  published?: boolean;
  hasSchema?: boolean;
  parentId?: string;
  page?: number;
  limit?: number;
  sortBy?: string;
  isAscending?: boolean;
  name?: string;
  action?: PolicyAction;
  includeParents?: boolean;
  type?: WorkOrderType;
}

export interface WorkTemplateListItem {
  id: string;
  name: string;
  schemaId: string;
  schemaName: string;
  parentId?: string;
  isParent?: boolean;
  type?: WorkOrderType;
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
    photoUrl: string;
  };
  createdDate: number;
  budget: number;
  tasks: WorkTemplateTaskItem[];
}

export interface SearchWorkTemplatesConnection {
  totalCount: number;
  workOrderTemplates: SearchWorkTemplate[];
}

export interface SearchWorkTemplate {
  id: string;
  name: string;
  schemaId?: string;
  isParent?: boolean;
  parentId?: string;
  type?: WorkOrderType;
  folderPath?: Array<{ id: string; name: string }>;
}

export interface SearchWorkTemplatesConnectionInput {
  query: string;
  schemaId?: string;
  hasSchema?: boolean;
  limit?: number;
  page?: number;
  action?: PolicyAction;
  published?: boolean;
  type?: WorkOrderType;
}

export interface WorkOrderTemplateCreateInput {
  name: string;
  type?: WorkOrderType;
  folderId: string;
  schemaId?: string;
  priorityId?: WorkPriorityType;
  parentId?: string;
  isParent?: boolean;
  additionalInheritedComponents?: InheritedComponentType[];
  requiredInheritedComponents?: InheritedComponentType[];
}

export interface WorkOrderTemplateMedium {
  fileId: string;
  name: string;
  type: string;
  fileExtension: string;
}

export interface WorkOrderTemplateUpdateInput {
  workOrderTemplateId: string;
  type?: WorkOrderType;
  published?: boolean;
  schemaId?: string;
  name?: string;
  summary?: Summary[];
  folderId?: string;
  leadAssigneeId?: string;
  fieldOrder?: string[];
  media?: WorkOrderTemplateMedium[];
  priorityId?: WorkPriorityType;
  additionalInheritedComponents?: InheritedComponentType[];
  requiredInheritedComponents?: InheritedComponentType[];
  tasks?: { id: string; name: string }[];
}

export interface WorkOrderTemplateFieldCreateInput {
  workTemplateId: string;
  dataType: WorkOrderFieldDataType;
  title: string;
  required?: boolean;
  enumeration?: string[];
  value?: any;
  defaultValue?: any;
  subFields?: SubField[];
  units?: string;
  hyperlink?: string;
}

export interface WorkOrderTemplateFieldUpdateInput {
  workTemplateId: string;
  fieldId: string;
  title?: string;
  required?: boolean;
  enumeration?: string[];
  value?: any;
  defaultValue?: any;
  subFields?: SubField[];
  units?: string;
}

export interface WorkOrderTemplateFieldDeleteInput {
  workTemplateId: string;
  fieldId: string;
}

export interface WorkOrderTemplateTaskCreateInput {
  workTemplateId: string;
  name: string;
  templateId?: string;
  description?: string;
  copyFrom?: any;
  estimatedCost?: number;
  userIds?: string[];
  formTemplateIds?: string[];
  categoryIds?: string[];
  schemaIds?: string[];
  materials?: any[];
  userGroups?: any[];
  fields?: any[];
  fieldOrder?: string[];
  modules?: any[];
}

export interface WorkOrderTemplateTaskUpdateInput {
  workTemplateId: string;
  taskId: string;
  name?: string;
  description?: string;
  estimatedCost?: number;
  isEstimatedCostOverridden?: boolean;
  dueDate?: number;
  categoryIds?: string[];
  schemaIds?: string[];
  fieldOrder?: string[];
  modules?: any[];
  locationsEnabled?: boolean;
  requireAtLeastOneLocation?: boolean;
  multiAssetsEnabled?: boolean;
  allowedMultiAssetSchemaIds?: string[];
  requireAtLeastOneMultiAsset?: boolean;
  requiredMultiAssetSchemaIds?: string[];
}

export interface WorkOrderTemplateTaskDeleteInput {
  workTemplateId: string;
  taskId: string;
}

export interface WorkOrderTemplateTaskDuplicateInput {
  workTemplateId: string;
  taskId: string;
}

export interface WorkOrderTemplateTaskFieldCreateInput {
  workTemplateId: string;
  taskId: string;
  dataType: TaskFieldDataType;
  title: string;
  required: boolean;
  enumeration?: string[];
  defaultValue?: any;
  subFields?: SubField[];
  units?: string;
}

export interface WorkOrderTemplateTaskFieldCreatePayload {
  dataType: TaskFieldDataType;
  title: string;
  required: boolean;
  enumeration?: string[];
  defaultValue?: any;
  subFields?: SubField[];
  units?: string;
}

export interface WorkOrderTemplateTaskFieldUpdateInput {
  workTemplateId: string;
  taskId: string;
  fieldId: string;
  title?: string;
  required?: boolean;
  enumeration?: string[];
  value?: any;
  defaultValue?: any;
  subFields?: SubField[];
  units?: string;
}

export interface WorkOrderTemplateTaskFieldUpdatePayload {
  fieldId: string;
  title?: string;
  required?: boolean;
  enumeration?: string[];
  subFields?: SubField[];
  units?: string;
}

export interface WorkOrderTemplateTaskFieldDeleteInput {
  workTemplateId: string;
  taskId: string;
  fieldId: string;
}

export interface WorkOrderTemplateAssigneeCreateInput {
  workTemplateId: string;
  taskId: string;
  assignees: { id: string }[];
}

export interface WorkOrderTemplateAssigneeDeleteInput {
  workTemplateId: string;
  taskId: string;
  assigneeId: string;
}

export interface WorkOrderTemplateUserGroupCreateInput {
  workTemplateId: string;
  taskId: string;
  userGroups: TaskUserGroupInputItem[];
}

export interface WorkOrderTemplateUserGroupUpdateInput {
  workTemplateId: string;
  taskId: string;
  userGroupId: string;
  budgetId?: string;
  quantity?: number;
  workTime?: number;
}

export interface WorkOrderTemplateUserGroupDeleteInput {
  workTemplateId: string;
  taskId: string;
  userGroupId: string;
}

export interface WorkOrderTemplateMaterialCreateInput {
  workTemplateId: string;
  taskId: string;
  materials: { assetId: string }[];
}

export interface WorkOrderTemplateMaterialDeleteInput {
  workTemplateId: string;
  taskId: string;
  assetId: string;
}

export interface WorkOrderTemplateFormCreateInput {
  workTemplateId: string;
  taskId: string;
  formTemplateIds: string[];
}

export interface WorkOrderTemplateFormDeleteInput {
  workTemplateId: string;
  taskId: string;
  formTemplateId: string;
}

export interface WorkOrderTemplateFolder {
  id: string;
  name: string;
  parentFolderId?: string;
  ancestors?: string[];
  hasTemplates?: boolean;
  createdDate?: number;
  updatedDate?: number;
  createdBy?: string;
  updatedBy?: string;
  children: WorkOrderTemplateFolder[];
  templates?: WorkTemplate[];
  grants?: any[];
}

export interface WorkOrderTemplateFoldersConnection {
  totalCount: number;
  workOrderTemplateFolders: WorkOrderTemplateFolder[];
}

export interface WorkOrderTemplateFoldersConnectionInput {
  parentFolderId?: string;
  ids?: string[];
}
