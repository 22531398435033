import * as React from 'react';
import * as R from 'ramda';

// @ts-ignore
import priorityIconHigh from '@atom/components/common/svgIcons/priorityIconHigh.svg';
// @ts-ignore
import priorityIconLow from '@atom/components/common/svgIcons/priorityIconLow.svg';
// @ts-ignore
import priorityIconMedium from '@atom/components/common/svgIcons/priorityIconMedium.svg';
// @ts-ignore
import priorityIconNone from '@atom/components/common/svgIcons/priorityIconNone.svg';

export const NO_PRIORITY_ID = 0;
export const LOW_PRIORITY_ID = 1;
export const MEDIUM_PRIORITY_ID = 2;
export const HIGH_PRIORITY_ID = 3;

export function getWorkPriorityStatusIcon(priorityId: number) {
  const priorities = [
    <img src={priorityIconNone} />, // 0
    <img src={priorityIconLow} />, // 1
    <img src={priorityIconMedium} />, // 2
    <img src={priorityIconHigh} />, // 3
  ];

  return priorities[priorityId] || priorities[0];
}

export const workOrderPriorityTypes = [
  { name: 'priorityId', label: 'High Priority', value: HIGH_PRIORITY_ID },
  { name: 'priorityId', label: 'Medium Priority', value: MEDIUM_PRIORITY_ID },
  { name: 'priorityId', label: 'Low Priority', value: LOW_PRIORITY_ID },
  { name: 'priorityId', label: 'No Priority', value: NO_PRIORITY_ID },
];

export const workOrderListPriorityTypes = [
  { name: 'priorityId', label: 'High', value: HIGH_PRIORITY_ID },
  { name: 'priorityId', label: 'Medium', value: MEDIUM_PRIORITY_ID },
  { name: 'priorityId', label: 'Low', value: LOW_PRIORITY_ID },
  { name: 'priorityId', label: 'None', value: NO_PRIORITY_ID },
];

export const getWorkOrderPriorityLabel = (priorityId: number): string => {
  const priorities = R.reverse(workOrderPriorityTypes);
  const priority = priorities[priorityId];

  if (!priority) {
    return 'No Priority';
  }

  return priority.label;
};

export const getWorKOrderListPriorityLabel = (priorityId: number): string => {
  const priorities = R.reverse(workOrderListPriorityTypes);
  const priority = priorities[priorityId];

  if (!priority) {
    return 'None';
  }

  return priority.label;
};

const workOrderPriorityUtilities = {
  NO_PRIORITY_ID,
  LOW_PRIORITY_ID,
  MEDIUM_PRIORITY_ID,
  HIGH_PRIORITY_ID,
  getWorkOrderPriorityLabel,
  getWorKOrderListPriorityLabel,
  workOrderPriorityTypes,
  getWorkPriorityStatusIcon,
};

export default workOrderPriorityUtilities;
