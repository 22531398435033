import * as React from 'react';

import { Select } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { FhwaModuleElementType } from '@atom/types/form';

import '../formInstance.css';

const { MenuItem } = Select;

const styles = {
  input: {
    backgroundColor: colors.neutral.typhoon,
    borderRadius: '2px',
    width: '6.25rem',
    paddingLeft: '0.5rem',
  },
  menuItem: {
    height: '2.125rem',
  },
  underline: {
    display: 'none',
  },
  icon: {
    marginTop: '-6px',
    marginRight: '-5px',
    fill: colors.neutral.gray,
    padding: 0,
  },
  selected: {
    color: colors.brand.blue,
  },
  label: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    height: '2.125rem',
    paddingTop: '4px',
    paddingLeft: '0.5rem',
    fontSize: fonts.md,
    color: colors.neutral.dark,
  },
};

interface PassedProps {
  isEditable: boolean;
  element: FhwaModuleElementType;
  onChange: (event: any) => Promise<void>;
  environment: string;
}

type Props = PassedProps;

const FhwaDropDown = ({
  isEditable,
  environment,
  onChange,
  element,
}: Props) => {
  return (
    <Select
      style={styles.input}
      onChange={onChange}
      value={environment || ''}
      disabled={!isEditable}
      disableUnderline
    >
      {element.attributes.environment.enumeration.map((enumValue: string) => {
        return (
          <MenuItem key={enumValue} value={enumValue}>
            {enumValue}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default FhwaDropDown;
