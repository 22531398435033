import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import * as R from 'ramda';

import { requestDownloadFiles } from '@atom/actions/fileActions';
import DeleteModal from '@atom/components/common/DeleteModal';
import textConstants from '@atom/constants/textConstants';
import {
  GET_MEDIA,
  MEDIA_BULK_DELETE,
  MEDIA_DELETE,
  MEDIA_UPDATE,
} from '@atom/graph/media';
import { useFileUpload } from '@atom/hooks/useFileUpload';
import {
  Button,
  Icon,
  IconButton,
  Progress,
  Select,
  Snackbar,
} from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import {
  MediaBulkDeleteInput,
  MediaItem,
  MediaUpdateInput,
} from '@atom/types/media';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import ElementMediaTable from './ElementMediaTable';

import './workOrderElementView.css';

const { MenuItem } = Select;

const useStyles = makeStyles({
  root: {
    paddingLeft: '0.5rem',
  },
});

const headerButtonStyle = {
  root: {
    width: '2em',
    minWidth: '2em',
    color: colors.neutral.gray,
  },
  icon: {
    color: colors.neutral.gray,
  },
  input: {
    display: 'none',
  },
};

const styles = {
  progress: {
    marginLeft: '1rem',
  },
  select: {
    fontSize: fonts.md,
    backgroundColor: colors.neutral.fog,
    marginLeft: '0.5rem',
    width: '9.375rem',
    height: '2.125rem',
  },
  menuItem: {
    fontWeight: '400',
    paddingLeft: '1rem',
  },
};

const getTypeByName = name => {
  if (name === 'Documents') {
    return 'document';
  }
  if (name === 'Photos') {
    return 'image';
  }
  if (name === 'Videos') {
    return 'video';
  }
  return null;
};

const dropdownOptions = ['All Files', 'Documents', 'Photos', 'Videos'];

interface Props {
  subjectId: string;
  parentSubjectId?: string;
  subjectType: string;
}

const ElementMedia = ({ subjectId, parentSubjectId, subjectType }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [uploadFiles] = useFileUpload();

  const [media, setMedia] = useState<MediaItem[]>([]);
  const [mediaCount, setMediaCount] = useState<number>(0);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedType, setSelectedType] = useState<string>('All Files');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const onCompleted = data => {
    setMedia(data?.media?.media);
    setMediaCount(data?.media?.totalCount);
  };

  const { refetch, loading: loadingMedia } = useQuery(GET_MEDIA, {
    variables: {
      input: {
        subjectIds: [subjectId],
        subjectTypes: [subjectType],
        parentSubjectId,
        ...(selectedType !== 'All Files' && {
          type: getTypeByName(selectedType),
        }),
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted,
  });

  const [mediaUpdate] = useMutation<
    { mediaUpdate: MediaItem },
    { input: MediaUpdateInput }
  >(MEDIA_UPDATE);

  const [mediaDelete, { loading: loadingDelete }] = useMutation<
    { mediaDelete: boolean },
    { id: string }
  >(MEDIA_DELETE);

  const [mediaBulkDelete] = useMutation<
    { mediaBulkDelete: boolean },
    { input: MediaBulkDeleteInput }
  >(MEDIA_BULK_DELETE);

  const onTypeChange = (event: any) => {
    setSelectedType(event.target.value);

    refetch({
      input: {
        subjectIds: [subjectId],
        subjectTypes: [subjectType],
        parentSubjectId,
        type: getTypeByName(event.target.value),
      },
    });
  };

  const handleUpload = async event => {
    const { files } = event.target;
    if (!files.length) {
      return;
    }

    await uploadFiles({
      files,
      subjectId,
      parentSubjectId,
      subjectType,
    });

    refetch();
  };

  const renameMedia = async (id: string, name: string) => {
    const res = await mediaUpdate({
      variables: {
        input: {
          id,
          name,
        },
      },
    });

    const updatedMedia = media.map((mediaItem: MediaItem) => {
      return mediaItem.id === id ? res?.data?.mediaUpdate : mediaItem;
    });

    setMedia(updatedMedia);
  };

  const deleteMedia = async (id: string) => {
    try {
      Snackbar.info({
        message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
      });

      await mediaDelete({
        variables: {
          id,
        },
      });

      Snackbar.info({
        message: 'Successfully deleted file',
      });

      setSelectedItems([]);
      refetch();
    } catch (err) {
      Snackbar.error({
        message: 'Failed to delete file',
      });
    }
  };

  const deleteMultipleFiles = async () => {
    await mediaBulkDelete({
      variables: {
        input: {
          ids: [...selectedItems],
        },
      },
    });

    setSelectedItems([]);
    setOpenDeleteModal(false);
    refetch();
  };

  const toggleCheck = (itemId, isChecked) => {
    // eslint-disable-next-line
    isChecked
      ? setSelectedItems([...selectedItems.filter(id => id !== itemId)])
      : setSelectedItems([...selectedItems, itemId]);
  };

  const downloadMultipleFiles = () => {
    const selectedMedia = media.filter((medium: any) =>
      selectedItems.includes(medium.id),
    );

    dispatch(requestDownloadFiles(selectedMedia));
  };

  const getHeaderButtons = () => {
    const bulkButtons = (
      <div styleName="files-controls">
        <IconButton
          style={headerButtonStyle.root}
          onClick={downloadMultipleFiles}
        >
          <Icon>file_download</Icon>
        </IconButton>
        <IconButton onClick={() => setOpenDeleteModal(true)}>
          <Icon>delete</Icon>
        </IconButton>
        <DeleteModal
          title="Delete Files"
          open={openDeleteModal}
          onConfirm={deleteMultipleFiles}
          onCancel={() => setOpenDeleteModal(false)}
          content={
            loadingDelete ? (
              <Progress />
            ) : (
              'Are you sure you want to delete these files?'
            )
          }
        />
      </div>
    );

    const uploadButton = (
      <div styleName="files-controls">
        {hasRolePermissions(ROLE_SETS.INSPECTOR) && (
          <Button component="label" style={headerButtonStyle.root}>
            <Icon>file_upload</Icon>
            <input
              type="file"
              style={headerButtonStyle.input}
              multiple
              onChange={handleUpload}
            />
          </Button>
        )}
      </div>
    );

    return R.isEmpty(selectedItems) ? uploadButton : bulkButtons;
  };

  const headerButtons = getHeaderButtons();

  const mediaType = selectedType === 'All Files' ? 'Files' : selectedType;

  return (
    <div styleName="files-container">
      <div styleName="files-header">
        <div styleName="dropdown-container">
          <Select
            style={styles.select}
            onChange={onTypeChange}
            value={selectedType}
            inputProps={{
              classes: {
                root: classes.root,
              },
            }}
          >
            {dropdownOptions.map(type => (
              <MenuItem style={styles.menuItem} key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
          {loadingMedia ? (
            <Progress style={styles.progress} size={30} thickness={2} />
          ) : (
            <div styleName="media-total-text">{`${mediaCount} ${mediaType}`}</div>
          )}
        </div>
        {headerButtons}
      </div>
      <ElementMediaTable
        media={media}
        loading={loadingMedia}
        renameMedia={renameMedia}
        deleteMedia={deleteMedia}
        toggleCheck={toggleCheck}
        selectedItems={selectedItems}
      />
    </div>
  );
};

export default ElementMedia;
