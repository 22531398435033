import React from 'react';

// @ts-ignore
import taskTypeLargeIcon from '@atom/components/common/svgIcons/taskTypeLarge.svg';

import './taskCreate.css';

const EmptyTaskTemplate = () => {
  return (
    <div styleName="empty-task-template-container">
      <img src={taskTypeLargeIcon} />
      <div styleName="empty-title">No Task Templates</div>
      <div styleName="empty-subtext">
        Save any existing task as a template and use it in the future.
      </div>
    </div>
  );
};

export default EmptyTaskTemplate;
