import * as React from 'react';
import * as R from 'ramda';

import { Icon, IconButton } from '@atom/mui';
import { attributesIntersectWithForm } from '@atom/selectors/inventoryFilterSelectors';
import colors from '@atom/styles/colors';

import CurrencyAttribute from './attributeFilterControls/CurrencyAttribute';
import DateAttribute from './attributeFilterControls/DateAttribute';
import EnumMultipleAttribute from './attributeFilterControls/EnumMultipleAttribute';
import NumberAttribute from './attributeFilterControls/NumberAttribute';

import '../../styles/left-pane.css';

const ARROW_UP = 'keyboard_arrow_up';
const ARROW_DOWN = 'keyboard_arrow_down';

interface Props {
  assetFilters?: any;
  formState: any;
  attributeGroup: any;
}

class InventoryFilterAttributeGroup extends React.Component<Props> {
  state = {
    icon: ARROW_UP,
  };

  buildFilter = attribute => {
    const { assetFilters, formState } = this.props;

    const { id, dataType, enumeration, unit, name } = attribute;

    const value =
      R.pathOr(undefined, [id], assetFilters) ||
      R.pathOr(undefined, [id], formState);

    switch (dataType) {
      case 'boolean': {
        return (
          // @ts-ignore
          <EnumMultipleAttribute
            name={id}
            title={name}
            enumeration={['true', 'false']}
            value={value}
          />
        );
      }
      case 'date': {
        return <DateAttribute name={id} title={name} value={value} />;
      }
      case 'number': {
        return (
          <NumberAttribute name={id} title={name} unit={unit} value={value} />
        );
      }
      case 'currency': {
        return (
          <CurrencyAttribute name={id} title={name} unit={unit} value={value} />
        );
      }
      case 'enumsingle':
      case 'enummultiple': {
        return (
          <EnumMultipleAttribute
            name={id}
            title={name}
            enumeration={enumeration}
            values={value}
          />
        );
      }
      default: {
        return <span />;
      }
    }
  };

  toggleSection = () => {
    const { icon } = this.state;

    if (icon === ARROW_UP) {
      return this.setState({ icon: ARROW_DOWN });
    }
    return this.setState({ icon: ARROW_UP });
  };

  isDirty = () => {
    const { attributeGroup, formState } = this.props;
    return attributesIntersectWithForm(attributeGroup, formState);
  };

  render() {
    const { attributeGroup } = this.props;
    const { icon } = this.state;
    const expanded = icon === ARROW_UP;

    return (
      <div styleName="inventory-filter-group-container">
        <div styleName="inventory-filter-group-header">
          <div
            styleName="inventory-filter-group-name"
            style={{
              color: this.isDirty() ? colors.brand.blue : colors.neutral.dark,
            }}
          >
            {attributeGroup.name}
          </div>
          <div>
            <IconButton onClick={this.toggleSection}>
              <Icon color={colors.neutral.gray}>{icon}</Icon>
            </IconButton>
          </div>
        </div>
        {expanded &&
          attributeGroup.attributes
            .filter(attribute => attribute.isFilterable)
            .map(attribute => (
              <div key={`${attribute.id}`}>{this.buildFilter(attribute)}</div>
            ))}
      </div>
    );
  }
}

export default InventoryFilterAttributeGroup;
