import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import * as R from 'ramda';

import ListViewTable from '@atom/components/common/listViewTable/ListViewTable';
import TextOverflowTooltip from '@atom/components/common/tooltip/TextOverflowTooltip';
import UserGroupIcon from '@atom/components/common/UserGroupIcon';
import UserThumbnail from '@atom/components/common/UserThumbnail';
import TeamContext from '@atom/components/teamPortal/TeamContext';
import UserGroupOptions from '@atom/components/teamPortal/teamControls/UserGroupOptions';
import UserOptions from '@atom/components/teamPortal/teamControls/UserOptions';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { Progress } from '@atom/mui';
import { Budget, UserGroupTable } from '@atom/types/userGroups';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { setDisplayDate } from '@atom/utilities/timeUtilities';

import UserGroupsHeader from './UserGroupsHeader';

import './userGroupsTable.css';

const styles = {
  tableStyle: {
    width: '100%',
    marginTop: 0,
    marginBottom: '3.125rem',
    height: 'calc(100vh - 215px)',
  },
  columns: {
    large: {
      width: '15rem',
    },
    small: {
      width: '3.125rem',
    },
    extraSmall: {
      width: '2.75rem',
    },
    mediumMax: {
      width: '14rem',
    },
  },
};

enum Type {
  USER = 'user',
  USER_GROUP = 'userGroup',
}

const UserGroupsTable = () => {
  const userProfile = useUserProfile();

  const {
    table,
    setPage,
    page,
    setLimit,
    limit,
    totalCount,
    refetchTable,
    activePath,
    handleGroup,
    tableLoading,
  } = useContext(TeamContext);

  const displayHeaders = [
    '',
    'Name',
    'Roles',
    'Budget',
    'Last Updated By',
    'Updated On',
    '',
  ];

  const fields = [
    'icon',
    'name',
    'roles',
    'budget',
    'updatedBy',
    'updatedDate',
    'actionButtons',
  ];

  const getBudgets = (row: UserGroupTable) => {
    const formattedBudgets = row.budgets.map(
      (budget: Budget) =>
        `${budget.name}: ${numberToLocaleString(budget.rate)}/Hour`,
    );
    return (
      <div>
        <TextOverflowTooltip
          text={formattedBudgets.join(', ')}
          width="15rem"
          lightTooltip
        />
      </div>
    );
  };

  const buildCell = (row: UserGroupTable, field: string, index: number) => {
    const roles = [
      ...new Set([...row.roles, ...row.inheritedRoles].map(role => role.name)),
    ].join(', ');

    const fieldTemplates = {
      icon: (
        <td style={styles.columns.small} key={index}>
          {row.type === Type.USER ? (
            <UserThumbnail
              image={row.photoUrl}
              alt={row.name}
              pending={row.isPending}
            />
          ) : (
            <div>
              <UserGroupIcon colorId={row.colorId} />
            </div>
          )}
        </td>
      ),
      name: (
        <td style={styles.columns.large} key={index} styleName="clickable">
          {row.type === Type.USER ? (
            <Link to={`/team/${row.id}`}>
              <div styleName={`title ${row.isPending ? 'pending' : ''}`}>
                <TextOverflowTooltip
                  text={row.name}
                  width="14rem"
                  lightTooltip
                />
              </div>
            </Link>
          ) : (
            <div
              styleName="title"
              onClick={() => handleGroup(row, [...activePath, row])}
            >
              <TextOverflowTooltip text={row.name} width="14rem" lightTooltip />
            </div>
          )}
        </td>
      ),
      roles: (
        <td key={index} style={styles.columns.large}>
          <TextOverflowTooltip text={roles} width="14rem" lightTooltip />
        </td>
      ),
      budget: (
        <td key={index} style={styles.columns.large}>
          {row.type === Type.USER_GROUP ||
            userProfile.userId === row.id ||
            (hasRolePermissions(ROLE_SETS.MANAGER) && getBudgets(row))}
        </td>
      ),
      updatedBy: (
        <td key={index} style={styles.columns.mediumMax}>{`${R.pathOr(
          '',
          ['updatedBy', 'firstName'],
          row,
        )} ${R.pathOr('', ['updatedBy', 'lastName'], row)}`}</td>
      ),
      updatedDate: (
        <td key={index} style={styles.columns.mediumMax}>
          {setDisplayDate(row.updatedDate)}
        </td>
      ),
      actionButtons: (
        <td key={index} style={styles.columns.extraSmall}>
          {row.type === Type.USER_GROUP ? (
            <UserGroupOptions userGroup={row} />
          ) : (
            <UserOptions user={row} refetch={refetchTable} />
          )}
        </td>
      ),
    };
    return fieldTemplates[field] || <td key={index}>{row[field]}</td>;
  };

  return (
    <>
      <UserGroupsHeader />
      {tableLoading ? (
        <Progress />
      ) : (
        <ListViewTable
          style={styles.tableStyle}
          displayHeaders={displayHeaders}
          dataRows={table}
          fields={fields}
          // @ts-ignore
          buildCell={buildCell}
          // @ts-ignore
          totalRows={totalCount}
          // @ts-ignore
          incrementPage={(increment: number) => setPage(page + increment)}
          // @ts-ignore
          updateLimit={(newLimit: number) => {
            setPage(1);
            setLimit(newLimit);
          }}
          page={page}
          limit={limit}
        />
      )}
    </>
  );
};

export default UserGroupsTable;
