import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
import LocationsAndAssetsContext from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsContext';
import RequiredFieldsConfirmModal from '@atom/components/common/workOrderDetail/requiredFieldsConfirmModal/RequiredFieldsConfirmModal';
import { TASK_ASSET_REMOVE } from '@atom/graph/task';
import { Icon, IconButton, Snackbar } from '@atom/mui';
import { AssetConnectionItem } from '@atom/types/inventory';
import { TaskAssetRemoveInput } from '@atom/types/task';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';
import { getStaticImage } from '@atom/utilities/mapUtilities';
import { getSchemaIconFromMarkerId } from '@atom/utilities/schemaUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import AldotAttributeSection from './AldotAttributeSection';

import './locationsAndAssetsSection.css';

const styles = {
  icon: {
    height: '28px',
    width: '28px',
    marginRight: '5px',
  },
};

interface Props {
  asset: AssetConnectionItem;
  setHoverId: (id: string) => void;
  removeAsset?: (id: string) => void;
  selectedAssetId?: string;
  setSelectedAssetId?: (id: string) => void;
}

const TaskAssetRow = ({
  asset,
  removeAsset,
  setHoverId,
  selectedAssetId,
  setSelectedAssetId,
}: Props) => {
  const { workOrderDetail, task, refetch, dataCySuffix } = useContext(
    LocationsAndAssetsContext,
  );

  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openFieldsConfirm, setOpenFieldsConfirm] = useState<boolean>(false);

  const [deleteAsset, { loading: loadingDelete }] = useMutation<
    { taskAssetRemove: boolean },
    { input: TaskAssetRemoveInput }
  >(TASK_ASSET_REMOVE);

  const handleDelete = async () => {
    setOpenDelete(false);
    Snackbar.info({ message: `Deleting ${asset.name}...` });

    try {
      await deleteAsset({
        variables: {
          input: {
            workOrderId: workOrderDetail.id,
            taskId: task.id,
            assetId: asset.id,
          },
        },
      });

      refetch();

      Snackbar.info({ message: `Deleted ${asset.name}.` });
    } catch (error) {
      Snackbar.error({ message: `Failed to delete ${asset.name}.` });
    }
  };

  const handleDeleteClick = () => {
    if (removeAsset) {
      return removeAsset(asset?.id);
    }

    if (
      task.isCompleted &&
      (task?.requireAtLeastOneMultiAsset ||
        !isNilOrEmpty(task?.requiredMultiAssetSchemaIds))
    ) {
      return setOpenFieldsConfirm(true);
    }

    return setOpenDelete(true);
  };

  const onProceedComplete = async () => {
    await refetch();

    setOpenFieldsConfirm(false);
    setOpenDelete(true);
  };

  const getSummaryImage = () => {
    if (!asset) {
      return '';
    }

    return !asset.mainPhotoFileId
      ? getStaticImage(asset.location, '60x60')
      : `${FILES_ENDPOINT}/${asset.mainPhotoFileId}_180`;
  };

  const isEditDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);
  const showDeleteButton = !isEditDisabled;

  const onAssetClick = () => {
    if (setSelectedAssetId) {
      setSelectedAssetId(asset?.id);
      setHoverId(asset?.id);
    }
  };

  const onMouseLeave = () => {
    if (isNilOrEmpty(selectedAssetId)) {
      setHoverId('');
    }
  };

  const showAldotAttributesSection = isCurrentClient([Client.ALDOT]);

  const schemaName = asset?.assetType || asset?.schemaName;

  return (
    <>
      <div
        styleName="asset-row"
        onMouseEnter={() => setHoverId(asset?.id)}
        onMouseLeave={onMouseLeave}
      >
        <div styleName="asset-content" onClick={onAssetClick}>
          <div
            styleName="asset-image"
            style={{
              backgroundImage: `url('${getSummaryImage()}')`,
            }}
          />
          <div>
            <div styleName="asset-name">{asset?.name}</div>
            <div styleName="schema-section">
              <img
                style={styles.icon}
                src={getSchemaIconFromMarkerId(asset?.markerId)}
              />
              <div styleName="asset-subtext">{schemaName}</div>
            </div>
            {showAldotAttributesSection && (
              <AldotAttributeSection attributes={asset?.attributes} />
            )}
          </div>
        </div>
        <div styleName="action-buttons">
          {showDeleteButton && (
            <IconButton
              onClick={handleDeleteClick}
              disabled={loadingDelete || isEditDisabled}
              data-cy={`taskMapAssetDelete_${dataCySuffix}`}
            >
              <Icon>delete</Icon>
            </IconButton>
          )}
        </div>
      </div>
      <DeleteModal
        open={openDelete}
        onConfirm={handleDelete}
        onCancel={() => setOpenDelete(false)}
        title="Remove Asset?"
        content="Are you sure you want to remove this asset?"
        cancelText="Cancel"
        confirmText="Remove"
      />
      <RequiredFieldsConfirmModal
        open={openFieldsConfirm}
        onCancel={() => setOpenFieldsConfirm(false)}
        onComplete={onProceedComplete}
        task={task}
        workOrderId={workOrderDetail?.id}
      />
    </>
  );
};

export default TaskAssetRow;
