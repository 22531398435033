import React, { useCallback, useContext, useMemo, useState } from 'react';
import { SortDirection } from '@mui/material';
import * as R from 'ramda';

import TeamContext from '@atom/components/teamPortal/TeamContext';
import { usePreferences } from '@atom/hooks/usePreferences';
import { ListTable } from '@atom/mui';
import { TimeEntriesDailyTotal, TimeEntryDetail } from '@atom/types/timeEntry';
import {
  getCondensedTimeEntries,
  getSortedTimeEntries,
} from '@atom/utilities/timeSheetUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import TimeSheetTableCondensedRow from './TimeSheetTableCondensedRow';
import TimeSheetTableCondensedTotalsRow from './TimeSheetTableCondensedTotalsRow';

import './timeSheet.css';

const { TableBody, TableHead, TableRow, TableCell } = ListTable;

const styles = {
  center: {
    padding: '0.5rem',
  },
};

type SortField = 'userGroup' | 'budget';

interface Props {
  timeEntries: TimeEntryDetail[];
  dailyTotals: TimeEntriesDailyTotal[];
  refetchTimeSheet: () => void;
}

const TimeSheetTableCondensed = ({
  timeEntries = [],
  dailyTotals,
  refetchTimeSheet,
}: Props) => {
  const preferences = usePreferences();
  const { payPeriodWeeks } = useContext(TeamContext);

  const [sortBy, setSortBy] = useState<string>('');
  const [dateFilter, setDateFilter] = useState<number>();

  const condensedTimeEntries = useMemo(() => {
    return getCondensedTimeEntries(timeEntries);
  }, [timeEntries]);

  const getSortDirection = useCallback(
    (field: SortField) => {
      if (!sortBy?.includes(field)) {
        return false;
      }

      return R.pathOr('asc', [1], sortBy.split(','));
    },
    [sortBy],
  );

  const handleSortDirectionChange = (field: string) => (
    newSortDirection: SortDirection,
  ) => {
    setSortBy(`${field},${newSortDirection}`);
  };

  const getFilteredList = () => {
    return condensedTimeEntries.filter(timeEntry =>
      // @ts-ignore
      R.keys(timeEntry.dateDictionary).includes(dateFilter.toString()),
    );
  };

  const sortedTimeEntries = useMemo(() => {
    const filteredList = dateFilter ? getFilteredList() : condensedTimeEntries;

    return getSortedTimeEntries(filteredList, sortBy);
  }, [condensedTimeEntries, sortBy, dateFilter]);

  const fullPayPeriodWeeks = R.flatten(payPeriodWeeks);

  return (
    <ListTable fullHeight={false} offsetTop="300px">
      <TableHead>
        <TableRow header>
          <TableCell width="45px" center variant="head">
            Status
          </TableCell>
          <TableCell variant="head">Work Template</TableCell>
          <TableCell variant="head">Work</TableCell>
          <TableCell variant="head">Task</TableCell>
          <TableCell
            variant="head"
            sortDirection={getSortDirection('userGroup')}
            onSortChange={handleSortDirectionChange('userGroup')}
          >
            Group
          </TableCell>
          <TableCell
            variant="head"
            sortDirection={getSortDirection('budget')}
            onSortChange={handleSortDirectionChange('budget')}
          >
            {isNilOrEmpty(preferences?.timeTracking?.typeEnumeration)
              ? 'Budget'
              : 'Wage Type'}
          </TableCell>
          {fullPayPeriodWeeks.map(day => (
            <TableCell
              width="30px"
              center
              style={styles.center}
              key={day}
              variant="head"
            >
              <div styleName="header-date-container">
                <div styleName="header-date-day">
                  {setDisplayDate(day, 'EEE')}
                </div>
                <div styleName="header-date">
                  {setDisplayDate(day, 'MM/dd')}
                </div>
              </div>
            </TableCell>
          ))}
        </TableRow>
        <TimeSheetTableCondensedTotalsRow
          dailyTotals={dailyTotals}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
        />
      </TableHead>
      <TableBody>
        {sortedTimeEntries.map(timeEntry => (
          <TimeSheetTableCondensedRow
            key={timeEntry.id}
            timeEntry={timeEntry}
            refetchTimeSheet={refetchTimeSheet}
          />
        ))}
      </TableBody>
    </ListTable>
  );
};

export default TimeSheetTableCondensed;
