import React, { useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';

import { TASK_TEMPLATE_CREATE } from '@atom/graph/taskTemplate';
import { useSchemas } from '@atom/hooks/useSchemas';
import { Modal, Snackbar, TextField } from '@atom/mui';
import { Task } from '@atom/types/task';
import {
  TaskTemplate,
  TaskTemplateCreateInput,
} from '@atom/types/taskTemplate';
import { WorkOrderDetailType } from '@atom/types/work';
import schemaUtilities from '@atom/utilities/schemaUtilities';

import './taskOptions.css';

const HTTP_RESOURCE_CONFLICT = 409;

export interface Props {
  open: boolean;
  onClose: () => void;
  onCreate: (template: TaskTemplate) => void;
  workOrderDetail: WorkOrderDetailType;
  task: Task;
}

const styles = {
  modal: {
    padding: '2rem',
  },
};

const TaskTemplateCreationModal = ({
  open,
  onClose,
  onCreate,
  workOrderDetail,
  task,
}: Props) => {
  const { schemas = [] } = useSchemas();

  const [name, setName] = useState<string>('');
  const [error, setError] = useState<string>('');

  const [taskTemplateCreate, { loading: loadingCreate }] = useMutation<
    { taskTemplateCreate: TaskTemplate },
    { input: TaskTemplateCreateInput }
  >(TASK_TEMPLATE_CREATE);

  const resetState = () => {
    setName('');
    setError('');
  };

  const isValid = () => {
    if (!name) {
      setError('Please enter a task template name');
      return false;
    }

    setError('');
    return true;
  };

  const handleSubmit = async () => {
    if (isValid()) {
      try {
        const res = await taskTemplateCreate({
          variables: {
            input: {
              name,
              workOrderId: workOrderDetail.id,
              taskId: task.id,
            },
          },
        });

        const data = res?.data?.taskTemplateCreate;
        onCreate({ ...data, applicable: true });
      } catch (err) {
        if (err?.networkError?.statusCode === HTTP_RESOURCE_CONFLICT) {
          setError('This name is already in use.');
        } else {
          Snackbar.error({ message: 'An unknown error occurred' });
        }
      }
    }
  };

  const schema = useMemo(() => {
    return schemas.find(
      ({ id }) => id === workOrderDetail.inventoryAssetSchemaId,
    );
  }, [schemas, workOrderDetail.inventoryAssetSchemaId]);

  return (
    <Modal
      title="Save as Task Template"
      open={open}
      onConfirm={handleSubmit}
      onCancel={onClose}
      onExited={resetState}
      confirmButtonText="Save"
      loading={loadingCreate}
      contentStyle={styles.modal}
      disabled={!name}
    >
      <div styleName="task-template-creation-modal-content">
        <div styleName="subtext">
          {workOrderDetail.inventoryAssetSchemaId ? (
            <>
              <div>
                This task template can only be used in work linked to the
                following inventory type:
              </div>
              <div styleName="task-template-creation-modal-schema-container">
                {schema &&
                  schemaUtilities.getSchemaIconFromSchemaOrAsset(schema)}
                <div styleName="task-template-creation-modal-value">
                  {schema?.name}
                </div>
              </div>
            </>
          ) : (
            <div>This task template can be used in any work.</div>
          )}
        </div>
        <TextField
          label="Task Template Name *"
          value={name}
          name="name"
          error={!!error}
          helperText={error}
          onChange={event => {
            setName(event.target.value);
            setError('');
          }}
        />
      </div>
    </Modal>
  );
};

export default TaskTemplateCreationModal;
