import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import TeamErrorModal from '@atom/components/common/teamErrorModal/TeamErrorModal';
import TeamContext, {
  baseUserGroup,
} from '@atom/components/teamPortal/TeamContext';
import { GET_TIME_ENTRIES_STATUS } from '@atom/graph/timeEntry';
import { DELETE_USER_GROUP } from '@atom/graph/userGroup';
import { Button, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  TimeEntriesConnectionInput,
  TimeEntriesStatusConnection,
} from '@atom/types/timeEntry';

import './teamControls.css';

const styles = {
  delete: {
    background: colors.brand.red,
    color: colors.neutral.white,
  },
  modal: {
    paddingTop: '1rem',
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
  userGroup: any;
}

const DeleteUserGroupModal = ({ open, onClose, userGroup }: Props) => {
  const { refetchTree, setActiveGroup, refetchTable } = useContext(TeamContext);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);

  const [
    getTimeEntriesStatus,
    { loading: timeEntriesStatusLoading, data: timeEntriesStatusData },
  ] = useLazyQuery<
    { timeEntries: TimeEntriesStatusConnection },
    { input: TimeEntriesConnectionInput }
  >(GET_TIME_ENTRIES_STATUS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (open) {
      getTimeEntriesStatus({
        variables: {
          input: {
            userGroupId: userGroup.id,
            page: 1,
            limit: 1,
          },
        },
      });
    }
  }, [open]);

  const [
    deleteUserGroup,
    { loading: deleteUserGroupLoading, error },
  ] = useMutation(DELETE_USER_GROUP, {
    onCompleted: () => {
      setActiveGroup(baseUserGroup);
      refetchTree();
      refetchTable();
    },
  });

  const confirm = () => {
    deleteUserGroup({
      variables: {
        id: userGroup.id,
      },
    });

    onClose();
  };

  useEffect(() => {
    if (error) {
      // @ts-ignore
      const statusCode = error.networkError?.statusCode;

      if (statusCode === 422) {
        setErrorOpen(true);
      }
    }
  }, [error]);

  const loading = timeEntriesStatusLoading || deleteUserGroupLoading;
  const userGroupHasTimeEntries =
    timeEntriesStatusData?.timeEntries?.totalCount > 0;

  const getFooter = () => {
    return userGroupHasTimeEntries ? (
      <Button onClick={onClose}>Ok</Button>
    ) : (
      <div>
        <Button onClick={onClose} style={{ marginRight: '0.5rem' }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={loading}
          onClick={confirm}
          style={styles.delete}
        >
          Delete
        </Button>
      </div>
    );
  };

  const getContent = () => {
    if (userGroupHasTimeEntries) {
      return (
        <span>
          This group cannot be deleted because it is used on one or more time
          entries. Please remove the group from all time entries and try again.
        </span>
      );
    }

    return (
      <span>
        Deleting this group will not delete people in this group from the
        system.
      </span>
    );
  };

  const title = userGroupHasTimeEntries
    ? `Cannot Delete User Group`
    : 'Delete Group?';

  return (
    <>
      <Modal
        title={loading ? '' : title}
        onCancel={onClose}
        open={open}
        footer={loading ? <div /> : getFooter()}
        loading={loading}
      >
        <div style={styles.modal}>{getContent()}</div>
      </Modal>
      <TeamErrorModal
        open={errorOpen}
        onClose={() => setErrorOpen(false)}
        type="group"
      />
    </>
  );
};

export default DeleteUserGroupModal;
