import React, { useContext } from 'react';

import { TextField } from '@atom/mui';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';

import BulkContext from '../../../BulkContext';

export interface Props {
  taskIndex: number;
}

const TaskDescription = ({ taskIndex }: Props) => {
  const { workOrder, setWorkOrder } = useContext(BulkContext);
  const { tasks } = workOrder;

  const handleChange = event => {
    const newTasks = [...tasks];
    newTasks[taskIndex].description = event.target.value;

    const newWorkOrder = {
      ...workOrder,
      tasks: newTasks,
    };

    setWorkOrder(newWorkOrder);
  };

  const isDisabled = doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);
  const { description } = tasks[taskIndex];

  return (
    <TextField
      id={description}
      label="Description"
      value={description}
      disabled={isDisabled}
      onChange={handleChange}
      fullWidth
      multiline
    />
  );
};

export default TaskDescription;
