import React from 'react';

// @ts-ignore
import defaultProfileImage from '@atom/images/defaultProfileImage.png';
import { Icon } from '@atom/mui';
import { getTaskUserStatusValues } from '@atom/selectors/taskSelectors';
import colors from '@atom/styles/colors';
import { TaskUserStatus } from '@atom/types/task';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './userThumbnail.css';

const styles = {
  iconPending: {
    position: 'absolute',
    top: '19px',
    left: '21px',
    fontSize: '18px',
    padding: '0',
  },
  backgroundPending: {
    position: 'absolute',
    top: '18px',
    left: '20px',
    height: '20px',
    width: '20px',
    backgroundColor: colors.neutral.white,
    borderRadius: '50%',
  },
  iconStatus: {
    position: 'absolute',
    top: '25px',
    left: '23px',
    fontSize: '10px',
    padding: '0',
  },
  backgroundStatus: {
    position: 'absolute',
    top: '24px',
    left: '22px',
    height: '12px',
    width: '12px',
    backgroundColor: colors.neutral.white,
    borderRadius: '50%',
  },
};

interface PassedProps {
  image?: string;
  alt?: string;
  large?: boolean;
  pending?: boolean;
  disabled?: boolean;
  taskUserStatus?: TaskUserStatus;
}

const UserThumbnail = ({
  image,
  alt,
  large,
  pending,
  taskUserStatus,
  disabled,
}: PassedProps) => {
  const src = image ? image : defaultProfileImage;
  const thumbnailStyle = {
    opacity: disabled ? '0.5' : '1',
  };

  const icon = taskUserStatus ? 'lens' : 'watch_later';
  const iconStyle = taskUserStatus ? styles.iconStatus : styles.iconPending;
  const backgroundStyle = taskUserStatus
    ? styles.backgroundStatus
    : styles.backgroundPending;
  const iconColor = taskUserStatus
    ? getTaskUserStatusValues(taskUserStatus).color
    : colors.neutral.gray;

  return (
    <div styleName="thumbnail-container" style={thumbnailStyle}>
      <img
        styleName={`user-thumbnail ${large ? 'large' : ''}`}
        src={src}
        onError={(error: any) => {
          error.target.src = defaultProfileImage;
        }}
        alt={alt}
      />
      {(pending || !isNilOrEmpty(taskUserStatus)) && (
        <>
          <div style={backgroundStyle} />
          <Icon style={iconStyle} color={iconColor}>
            {icon}
          </Icon>
        </>
      )}
    </div>
  );
};

export default UserThumbnail;
