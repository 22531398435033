import React, { useContext } from 'react';

import WorkTemplateTree from '@atom/components/common/workTemplateTree/WorkTemplateTree';
import { PolicyAction } from '@atom/types/policy';
import { generateYYYYMMName } from '@atom/utilities/timeUtilities';

import BulkContext, { View, WorkTemplate } from './BulkContext';

import './workTypeSelectionView.css';

const WorkTypeSelectionView = () => {
  const {
    assets,
    setName,
    setView,
    setWorkTemplate,
    workOrder,
    setWorkOrder,
  } = useContext(BulkContext);

  const handleWorkTemplateClick = (template: WorkTemplate) => {
    setName(generateYYYYMMName(template));
    setView(View.FORM);
    setWorkTemplate(template);

    const newWorkOrder = {
      ...workOrder,
      workTemplateId: template.id,
    };

    setWorkOrder(newWorkOrder);
  };

  const sameAssetType =
    assets.length > 0 &&
    assets.every(asset => asset.assetType === assets[0].assetType);

  const schemaId =
    sameAssetType && assets[0].schemaId ? assets[0].schemaId : null;

  return (
    <div styleName="work-type-container">
      <div styleName="work-type-title">
        Choose a work template for the{' '}
        {assets.length === 1 ? 'work item' : `${assets.length} work items`}{' '}
        you're about to create in bulk:
      </div>
      <div styleName="work-type-hint">
        {sameAssetType && (
          <div>
            Inventory type "{assets[0].assetType}" selected, showing all the
            applicable work templates.
          </div>
        )}
        {!sameAssetType && (
          <div>
            Multiple inventory types selected, showing all applicable work
            templates.
          </div>
        )}
      </div>
      <WorkTemplateTree
        includeSearch
        onClick={template => handleWorkTemplateClick(template)}
        schemaId={schemaId}
        hasSchema={false}
        action={PolicyAction.BULK_CREATE}
      />
    </div>
  );
};

export default WorkTypeSelectionView;
