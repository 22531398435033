import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
import { EXPORT_DELETE } from '@atom/graph/export';
import { Icon, IconButton, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import { Export } from '@atom/types/export';
import history from '@atom/utilities/history';

import WorkExportDetailModal from './WorkExportDetailModal';

import '../../styles/detail-header.css';

interface Props {
  export: Export;
}

const styles = {
  icon: {
    marginTop: 2,
  },
};

const WorkExportsHeader = ({ export: exp }: Props) => {
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const navigateBack = (): void => {
    return history.goBackWithState('/workExports');
  };

  const [deleteExport, { loading: loadingDelete }] = useMutation(EXPORT_DELETE);

  const deleteWorkExport = async () => {
    try {
      await deleteExport({
        variables: {
          id: exp.id,
        },
      });

      Snackbar.info({
        message: `Deleted work export ${exp?.name}`,
      });

      history.replace('/workExports');
    } catch (err) {
      Snackbar.error({
        message: `Delete work export ${exp?.name} Failed`,
      });
    }

    setDeleteModalOpen(false);
  };

  return (
    <div styleName="header-container">
      <div styleName="name-container no-padding">
        <IconButton onClick={navigateBack}>
          <Icon color={colors.neutral.white}>arrow_back</Icon>
        </IconButton>
        <span>{exp?.name}</span>
      </div>
      <div styleName="button-container">
        <IconButton
          tooltip="Export Work"
          onClick={() => setExportModalOpen(true)}
        >
          <Icon style={styles.icon} color={colors.neutral.white}>
            file_download
          </Icon>
        </IconButton>
        <IconButton
          tooltip="Delete Work Export"
          onClick={() => setDeleteModalOpen(true)}
        >
          <Icon color={colors.neutral.white}>delete</Icon>
        </IconButton>
      </div>
      <WorkExportDetailModal
        export={exp}
        open={exportModalOpen}
        onClose={() => setExportModalOpen(false)}
      />
      <DeleteModal
        title="Delete Work Export"
        open={deleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={deleteWorkExport}
        content={`Are you sure you want to delete export ${exp?.name}?`}
        loading={loadingDelete}
      />
    </div>
  );
};

export default WorkExportsHeader;
