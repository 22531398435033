import React, { useEffect, useMemo, useState } from 'react';

// @ts-ignore
import workTypeWalkThrough1 from '@atom/images/workTypeWalkThrough1.svg';
// @ts-ignore
import workTypeWalkThrough2 from '@atom/images/workTypeWalkThrough2.svg';
// @ts-ignore
import workTypeWalkThrough3 from '@atom/images/workTypeWalkThrough3.svg';
// @ts-ignore
import workTypeWalkThrough4 from '@atom/images/workTypeWalkThrough4.svg';
import { Button, Modal } from '@atom/mui';

import WalkThroughPage from './WalkThroughPage';

import './workTemplateWalkThrough.css';

interface Props {
  open: boolean;
  onClose: () => void;
}

const pages = [
  {
    title: 'Create the parent work template',
    content:
      'Customize the work template with what you want the children to inherit.',
    imageUrl: workTypeWalkThrough1,
  },
  {
    title: 'Manage settings',
    content:
      'Decide sections that child work templates must inherit by going to Child Work Template Settings. You should update this prior to publishing but you may unpublish the work template and make changes as long as it does not have children that are in use in Work.',
    imageUrl: workTypeWalkThrough2,
  },
  {
    title: 'Publish',
    content:
      'You may unpublish this work template as long as its child work templates are not in use.',
    imageUrl: workTypeWalkThrough3,
  },
  {
    title: 'Create a child work template',
    content:
      'Review sections that the parent determined must be inherited by the child, make additional selections if available and as needed, and further customize the child work template.',
    imageUrl: workTypeWalkThrough4,
  },
];

const WorkTemplateWalkThrough = ({ open, onClose }: Props) => {
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    if (open) {
      setPage(0);
    }
  }, [open]);

  const isFirstPage = useMemo(() => {
    return page === 0;
  }, [page]);

  const isLastPage = useMemo(() => {
    return page === pages.length - 1;
  }, [page]);

  const onConfirmClick = () => (isLastPage ? onClose() : setPage(page + 1));

  const renderFooter = (
    <div styleName="modal-footer">
      <div>
        {!isFirstPage && (
          <Button onClick={() => setPage(page - 1)}>Back</Button>
        )}
      </div>
      <div>
        {!isLastPage && <Button onClick={onClose}>Skip</Button>}
        <Button color="primary" variant="contained" onClick={onConfirmClick}>
          {isLastPage ? 'Done' : 'Next'}
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      title="Create Work Template with Inheritance"
      open={open}
      footer={renderFooter}
    >
      <WalkThroughPage page={page} pages={pages} setPage={setPage} />
    </Modal>
  );
};

export default WorkTemplateWalkThrough;
