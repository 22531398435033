import React from 'react';

import { getWorkOrderStatusColor } from '@atom/utilities/workOrderStatusUtilities';

const WorkOrderIcon = ({ status }: { status: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
    >
      <defs>
        <path
          id="a"
          d="M18 4h-4V2c0-1.11-.89-2-2-2H8C6.89 0 6 .89 6 2v2H2C.89 4 .01 4.89.01 6L0 17c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm-6 0H8V2h4v2z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(2 2)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <g fill={getWorkOrderStatusColor(status)} mask="url(#b)">
          <path d="M-2-2h24v24H-2z" />
        </g>
      </g>
    </svg>
  );
};

export default WorkOrderIcon;
