import React from 'react';

import { DatePicker } from '@atom/mui';

interface Props {
  id: string;
  value: any;
  onChange: (value: any) => void;
  disabled: boolean;
  error: boolean;
  dataCyPrefix: string;
}

const DateInput = ({
  id,
  value,
  onChange,
  disabled,
  error,
  dataCyPrefix,
}: Props) => {
  const handleChange = (newDate: Date) => {
    const date = newDate ? newDate.valueOf() : '';
    onChange(date);
  };

  const dateValue = value ? new Date(value) : null;

  return (
    <DatePicker
      key={id}
      value={dateValue}
      onChange={(newDate: Date) => handleChange(newDate || null)}
      disabled={disabled}
      TextFieldProps={{ error }}
      data-cy={`${dataCyPrefix}DateInput`}
    />
  );
};

export default DateInput;
