export function incrementPage(increment) {
  return function update(state) {
    return {
      page: state.page + increment,
    };
  };
}

export function addItem(itemId) {
  return function update(state) {
    const { selectedItems } = state;
    return {
      selectedItems: [...selectedItems, itemId],
    };
  };
}

export function removeItem(itemId) {
  return function update(state) {
    const { selectedItems } = state;
    return {
      selectedItems: selectedItems.filter(id => id !== itemId),
    };
  };
}
