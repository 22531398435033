import * as R from 'ramda';

import { PolicyAction } from '@atom/types/policy';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

export const hasAccess = (
  action: PolicyAction,
  availableActions: PolicyAction[],
): boolean => {
  if (isNilOrEmpty(availableActions)) {
    return false;
  }

  return (
    R.includes(action, availableActions) ||
    R.includes(PolicyAction.ALL, availableActions)
  );
};

export const doesNotHaveAccess = (
  action: PolicyAction,
  availableActions: PolicyAction[],
): boolean => {
  if (isNilOrEmpty(availableActions)) {
    return true;
  }

  return (
    !R.includes(action, availableActions) &&
    !R.includes(PolicyAction.ALL, availableActions)
  );
};
