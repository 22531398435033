import React, { useContext } from 'react';

import UserThumbnail from '@atom/components/common/UserThumbnail';
import AddTeamModalContext from '@atom/components/common/workOrderDetail/addTeamModal/AddTeamModalContext';
import { Icon } from '@atom/mui';
import { UserDetail } from '@atom/types/user';

import './addTeamModal.css';

interface Props {
  user: UserDetail;
}

const UserCartTile = ({ user }: Props) => {
  const { removeUserFromCart } = useContext(AddTeamModalContext);

  const name = `${user.firstName} ${user.lastName}`;

  return (
    <div styleName="cart-tile-container">
      <div styleName="side-tile-container">
        <UserThumbnail image={user.photoUrl} alt={name} />
        <div>
          <div styleName="tile-title">{name}</div>
          <div styleName="tile-subtext">{user.email}</div>
        </div>
      </div>
      <Icon styleName="close-icon" onClick={() => removeUserFromCart(user.id)}>
        close
      </Icon>
    </div>
  );
};

export default UserCartTile;
