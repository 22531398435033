import * as R from 'ramda';

import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

const mapAttributeGroups = assetDetail => {
  const attributeGroups = assetDetail.attributeGroups;

  return attributeGroups.map(group => {
    return {
      ...group,
      attributes: group.attributes.map(key => {
        return {
          id: key,
          ...assetDetail.attributes[key],
        };
      }),
    };
  });
};

export const mapSubTextAttributeValuesFromDetail = (
  existingAttributes,
  newAttributes,
) => {
  if (!existingAttributes || !newAttributes) {
    return undefined;
  }

  return existingAttributes.map(attribute => ({
    id: attribute.id,
    ...newAttributes[attribute.id],
  }));
};

// TODO: [AM-1349] Add a robust test suite around this mapping function
export const mapElementWithinElementHierarchy = (element, updatedElement) => {
  if (element.id === updatedElement.id) {
    const attributes = mapSubTextAttributeValuesFromDetail(
      element.attributes,
      updatedElement.attributes,
    );

    return {
      ...element,
      attributes,
      elementGroups: null,
    };
  }

  if (R.isNil(element.elements) || R.isEmpty(element.elements)) {
    return {
      ...element,
      elementGroups: null,
    };
  }

  const elements = element.elements.map(elem => {
    const nestedAsset =
      R.isNil(elem.isGroup) || elem.isGroup
        ? elem
        : R.pathOr([{}], ['elements'], elem)[0];

    if (nestedAsset.id === updatedElement.id) {
      const attributes = mapSubTextAttributeValuesFromDetail(
        nestedAsset.attributes,
        updatedElement.attributes,
      );

      return {
        ...elem,
        attributes,
        elements: [{ ...nestedAsset, attributes, elementGroups: null }],
        elementGroups: null,
      };
    }

    if (R.isNil(elem.elements) || R.isEmpty(elem.elements)) {
      return mapElementWithinElementHierarchy(elem, updatedElement);
    }

    return {
      ...elem,
      elementGroups: null,
    };
  });

  return {
    ...element,
    elements,
    elementGroups: null,
  };
};

const mapInventoryAssetDetailElements = elements => {
  if (R.isEmpty(elements) || R.isNil(elements)) {
    return [];
  }
  return elements.map(element => {
    return {
      ...element,
      expanded: false,
      elements: mapInventoryAssetDetailElements(element.elements),
    };
  });
};

const mapInventoryAssetDetailElementGroups = inventoryAssetDetail => {
  return {
    ...inventoryAssetDetail,
    elementGroups: inventoryAssetDetail.elementGroups.map(element => {
      return {
        ...element,
        expanded: false,
        elements: mapInventoryAssetDetailElements(element.elements),
      };
    }),
  };
};

export default function (state = initialState.inventoryAssetDetail, action) {
  switch (action.type) {
    case actionTypes.GET_INVENTORY_ASSET_DETAIL_SUCCESS:
    case actionTypes.GET_INVENTORY_ASSET_DETAIL_UPDATE_SUCCESS: {
      const inventoryAssetDetail = action.data;
      const hydratedAttributeGroups = mapAttributeGroups(inventoryAssetDetail);
      const hydratedInventoryElementGroups = mapInventoryAssetDetailElementGroups(
        inventoryAssetDetail,
      );

      return {
        ...hydratedInventoryElementGroups,
        attributeGroups: hydratedAttributeGroups,
        cursor:
          state.id === inventoryAssetDetail.id && !inventoryAssetDetail.cursor
            ? state.cursor
            : inventoryAssetDetail.cursor,
      };
    }
    case actionTypes.REQUEST_INVENTORY_ELEMENT_DETAIL: {
      return { ...state, elementDetail: {} };
    }
    case actionTypes.GET_INVENTORY_ELEMENT_DETAIL_SUCCESS: {
      const inventoryAssetDetail = action.data;
      const hydratedAttributeGroups = mapAttributeGroups(inventoryAssetDetail);

      const updatedElement = {
        ...inventoryAssetDetail,
        attributeGroups: hydratedAttributeGroups,
      };

      // Resets all elementGroups on bulk creation
      const elementGroups = state.elementGroups.map(elementGroup => {
        return {
          ...elementGroup,
          elements: elementGroup.elements.map(element => {
            return {
              ...element,
              elementGroups: null,
            };
          }),
        };
      });

      return { ...state, elementGroups, elementDetail: updatedElement };
    }
    case actionTypes.GET_INVENTORY_ELEMENT_DETAIL_UPDATE_SUCCESS: {
      const inventoryAssetDetail = action.data;
      const hydratedAttributeGroups = mapAttributeGroups(inventoryAssetDetail);

      const updatedElement = {
        ...inventoryAssetDetail,
        attributeGroups: hydratedAttributeGroups,
      };

      // Resets all elementGroups on bulk creation
      const elementGroups = state.elementGroups.map(elementGroup => {
        return {
          ...elementGroup,
          elements: elementGroup.elements.map(element => {
            return mapElementWithinElementHierarchy(element, updatedElement);
          }),
        };
      });

      return { ...state, elementGroups, elementDetail: updatedElement };
    }
    case actionTypes.GET_INVENTORY_ASSET_TREE_NODE_SUCCESS: {
      const {
        data: { elementGroups, elementPath },
      } = action;

      const lensPath = R.lensPath(elementPath);
      return R.over(
        lensPath,
        element => ({
          ...element,
          elements: elementGroups,
        }),
        state,
      );
    }
    case actionTypes.GET_INVENTORY_ELEMENT_TREE_NODE_SUCCESS: {
      const {
        data: { elementGroups, elementPath },
      } = action;

      const lensPath = R.lensPath(elementPath);
      const elementDetail = R.over(
        lensPath,
        element => ({
          ...element,
          elementGroups,
        }),
        state.elementDetail,
      );
      return {
        ...state,
        elementDetail,
      };
    }
    case actionTypes.GET_INVENTORY_ASSET_TREE_NODE_EXPANDED_UPDATE_SUCCESS:
    case actionTypes.GET_INVENTORY_ASSET_TREE_NODE_UPDATE_SUCCESS: {
      const {
        data: { data, elementPath },
      } = action;
      const lensPath = R.lensPath(elementPath);

      return R.over(
        lensPath,
        element => {
          return {
            ...element,
            ...data,
            ...(element && element.elementGroups
              ? {
                  elementGroups: element.elementGroups,
                }
              : {}),
          };
        },
        state,
      );
    }
    case actionTypes.GET_INVENTORY_ELEMENT_TREE_NODE_UPDATE_SUCCESS: {
      const {
        data: { data, elementPath },
      } = action;
      const lensPath = R.lensPath(elementPath);

      const elementDetail = R.over(
        lensPath,
        element => ({
          ...element,
          ...data,
          ...(element.elementGroups
            ? {
                elementGroups: element.elementGroups,
              }
            : {}),
        }),
        state.elementDetail,
      );
      return {
        ...state,
        elementDetail,
      };
    }
    case actionTypes.DELETE_INVENTORY_ASSET_SUCCESS: {
      const {
        data: { elementPath },
      } = action;

      const lensPath = R.lensPath(R.init(elementPath));
      const elementIndex = R.last(elementPath);

      // @ts-ignore
      return R.over(lensPath, R.remove(elementIndex, 1), state);
    }
    case actionTypes.DELETE_INVENTORY_ELEMENT_SUCCESS: {
      const {
        data: { elementPath },
      } = action;
      const lensPath = R.lensPath(R.init(elementPath));
      const elementIndex = R.last(elementPath);

      const elementDetail = R.over(
        lensPath,
        // @ts-ignore
        R.remove(elementIndex, 1),
        state.elementDetail,
      );

      return {
        ...state,
        elementDetail,
      };
    }
    case actionTypes.CREATE_WORK_ORDER_SUCCESS: {
      const { id, name, updatedDate } = action.data;

      const lastWorkOrder = {
        id,
        name,
        updatedDate,
      };

      return { ...state, lastWorkOrder };
    }
    default: {
      return state;
    }
  }
}
