import React, { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import * as R from 'ramda';

import { Icon } from '@atom/mui';
import { pciFormModule } from '@atom/selectors/formModuleSelectors';
import colors from '@atom/styles/colors';
import { FormInstanceFieldItem, PciType } from '@atom/types/form';
import { InventoryAssetDetailType } from '@atom/types/inventory';

import AcSummary from './AcSummary';
import NbiSummary from './NbiSummary';
import PciFieldDetails from './PciFieldDetails';

import './pci.css';

interface Props {
  fieldId: string;
  field: FormInstanceFieldItem;
  inventoryAssetId: string;
  inventoryAsset: InventoryAssetDetailType;
}

const getSummary = (pciType: string, props: any) => {
  switch (pciType) {
    case PciType.PCC_NBI: {
      return <NbiSummary {...props} />;
    }
    case PciType.AC:
    case PciType.AC_OVER_PCC: {
      return <AcSummary {...props} />;
    }
    default: {
      return <div />;
    }
  }
};

const PciSummary = ({ fieldId, field, inventoryAsset }: Props) => {
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const pciType = R.pathOr(null, ['pci', 'type'], field);

  useEffect(() => {
    if (field.pci?.discrepancyWarning) {
      setAlertVisible(true);
    }
  }, [field.pci?.discrepancyWarning]);

  return (
    <>
      <div styleName="header">
        <div styleName="title-container">
          <Icon color={colors.brand.blue}>{pciFormModule.icon}</Icon>
          <div styleName="title">Pavement Condition Inspection (PCI)</div>
        </div>
      </div>
      {alertVisible && (
        <div styleName="alert-container">
          <Alert severity="warning" onClose={() => setAlertVisible(false)}>
            {field.pci.discrepancyWarning}
          </Alert>
        </div>
      )}
      <PciFieldDetails
        fieldId={fieldId}
        field={field}
        inventoryAsset={inventoryAsset}
        preview={false}
        schemas={[]}
        pciType={pciType}
        onPciTypeChange={() => {}}
        onChange={() => {}}
        isSummary
      />
      {getSummary(pciType, {
        field,
      })}
    </>
  );
};

export default PciSummary;
