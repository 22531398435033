import React, { useCallback } from 'react';

import ChipTab, { Tab } from './ChipTab';

import './tab.css';

interface Props {
  activeView: any;
  setActiveView: (view: any) => void;
  count?: number;
  tabs: Tab[];
  views: object;
}

const TabContainer = ({
  activeView,
  setActiveView,
  count,
  tabs,
  views,
}: Props) => {
  const getHeader = useCallback(
    (tab: Tab) => (
      <ChipTab
        key={tab.label}
        activeView={activeView}
        setActiveView={setActiveView}
        count={count}
        tab={tab}
      />
    ),
    [activeView, count, setActiveView],
  );

  const getContent = useCallback(() => views[activeView], [activeView, views]);

  return (
    <>
      <div styleName="tab-container">
        {tabs.map((tab: Tab) => getHeader(tab))}
      </div>
      <div>{getContent()}</div>
    </>
  );
};

export default TabContainer;
