import * as React from 'react';

import { MapUser } from '@atom/types/map';

import './drawerContent.css';

interface Props {
  user: MapUser;
}

const UserListItem = ({ user }: Props) => {
  const { photoUrl, firstName, lastName, role, email } = user;

  return (
    <div styleName="list-item-v2">
      <div styleName="icon-container">
        <img
          src={photoUrl}
          onError={event => {
            // @ts-ignore
            event.target.onerror = null;
            // @ts-ignore
            event.target.src =
              'https://lh3.googleusercontent.com/a/default-user=s140-p-k-rw-no';
          }}
        />
      </div>
      <div styleName="description-container">
        <div styleName="heading">
          {firstName} {lastName}
        </div>
        <div styleName="secondary tall">{email}</div>
        <div styleName="secondary tall">{role}</div>
      </div>
    </div>
  );
};

export default UserListItem;
