import React from 'react';

import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';

import './carousel.css';

// These props are injected by react-slick, and should remain defined as follows
interface Props {
  className?: string;
  onClick?: any;
}

const PrevArrow = ({ className, onClick }: Props) => {
  return (
    <div className={className} onClick={onClick}>
      <div styleName="arrow-container prev">
        <Icon color={colors.neutral.black}>keyboard_arrow_left</Icon>
      </div>
    </div>
  );
};

export default PrevArrow;
