import React from 'react';

import { Progress } from '@atom/mui';
import { TaskTemplate } from '@atom/types/taskTemplate';

import TaskTemplateRow from './TaskTemplateRow';

interface Props {
  taskTemplates: TaskTemplate[];
  taskTemplatesLoading: boolean;
  selectedTaskTemplate: TaskTemplate;
  setSelectedTaskTemplate: (selectedTaskTemplate: TaskTemplate) => void;
  handleDelete: (id: string) => void;
  disableDelete?: boolean;
}

const TaskTemplateList = ({
  taskTemplates,
  taskTemplatesLoading,
  selectedTaskTemplate,
  setSelectedTaskTemplate,
  handleDelete,
  disableDelete,
}: Props) => (
  <>
    {taskTemplates?.map(taskTemplate => (
      <TaskTemplateRow
        key={taskTemplate?.id}
        taskTemplate={taskTemplate}
        selectedTaskTemplate={selectedTaskTemplate}
        setSelectedTaskTemplate={setSelectedTaskTemplate}
        handleDelete={handleDelete}
        disableDelete={disableDelete}
      />
    ))}
    {taskTemplatesLoading && <Progress />}
  </>
);

export default TaskTemplateList;
