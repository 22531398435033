import React, { useContext } from 'react';
import * as R from 'ramda';

import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';

import WorkTemplateTaskDetail from './workTemplateTaskDetail/WorkTemplateTaskDetail';
import TaskList from './TaskList';

import './workTemplateTasks.css';

const WorkTemplateTasks = () => {
  const { task } = useContext(WorkTemplateContext);

  const displayTaskDetail = !R.isNil(task) && !R.isEmpty(task);

  return (
    <div styleName="tasks-container">
      <div styleName="pane left">
        <TaskList />
      </div>
      <div styleName="pane right">
        {displayTaskDetail && <WorkTemplateTaskDetail />}
      </div>
    </div>
  );
};

export default WorkTemplateTasks;
