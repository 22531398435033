import { gql } from '@apollo/client';

import { BASE_ASSIGNED_ROLE } from './role';
import { TIME_SHEET_ENTRIES } from './timeEntry';

export const BASE_USER_FRAGMENT = gql`
  fragment BaseUser on User {
    id
    employeeId
    baseLocation
    firstName
    lastName
    isVerified
    isActive
    photoUrl
    department
    title
    role
    level
    email
    location
    rate
    notifications
    timezoneLocation
    createdDate
    updatedDate
    updatedBy {
      firstName
      lastName
    }
    status
    budgets {
      id
      externalId
      name
      rate
      restored
      reopenedWorkOrderIds
    }
    certifications {
      id
      name
      description
    }
    userGroups {
      id
      name
      groupPath
      certifications {
        id
        name
        description
      }
      budgets {
        id
        name
        rate
        restored
        reopenedWorkOrderIds
      }
    }
    timeEntryApproverUserGroups {
      id
      name
      groupPath
      colorId
    }
    roles {
      ...BaseAssignedRole
    }
    inheritedRoles {
      ...BaseAssignedRole
    }
  }
  ${BASE_ASSIGNED_ROLE}
`;

export const GET_USER = gql`
  query user($id: ID!, $input: UserInput) {
    user(id: $id, input: $input) {
      ...BaseUser
    }
  }
  ${BASE_USER_FRAGMENT}
`;

export const GET_USERS = gql`
  query users($input: UsersConnectionInput!) {
    users(input: $input) {
      totalCount
      users {
        ...BaseUser
      }
    }
  }
  ${BASE_USER_FRAGMENT}
`;

export const GET_USERS_WITH_TIME_SHEET_ENTRIES = gql`
  query users(
    $input: UsersConnectionInput!
    $timeSheetEntriesInput: TimeEntriesConnectionInput!
    $timeEntriesTotalDurationInput: TimeEntriesTotalDurationInput!
  ) {
    users(input: $input) {
      totalCount
      users {
        ...BaseUser
        timeEntries(input: $timeSheetEntriesInput) {
          ...TimeSheetEntries
        }
        timeEntriesTotalDuration(input: $timeEntriesTotalDurationInput)
      }
    }
  }
  ${BASE_USER_FRAGMENT}
  ${TIME_SHEET_ENTRIES}
`;

export const USER_UPDATE = gql`
  mutation userUpdate($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      ...BaseUser
    }
  }
  ${BASE_USER_FRAGMENT}
`;

export const USER_DELETE = gql`
  mutation userDelete($id: ID!) {
    userDelete(id: $id)
  }
`;

export const CREATE_USER_BUDGET = gql`
  mutation userBudgetCreate($input: UserBudgetCreateInput!) {
    userBudgetCreate(input: $input) {
      ...BaseUser
    }
  }
  ${BASE_USER_FRAGMENT}
`;

export const UPDATE_USER_BUDGET = gql`
  mutation userBudgetUpdate($input: UserBudgetUpdateInput!) {
    userBudgetUpdate(input: $input) {
      ...BaseUser
    }
  }
  ${BASE_USER_FRAGMENT}
`;

export const DELETE_USER_BUDGET = gql`
  mutation userBudgetDelete($input: UserBudgetDeleteInput!) {
    userBudgetDelete(input: $input) {
      ...BaseUser
    }
  }
  ${BASE_USER_FRAGMENT}
`;

export const CREATE_USER_CERTIFICATION = gql`
  mutation userCertificationCreate($input: UserCertificationCreateInput!) {
    userCertificationCreate(input: $input) {
      ...BaseUser
    }
  }
  ${BASE_USER_FRAGMENT}
`;

export const UPDATE_USER_CERTIFICATION = gql`
  mutation userCertificationUpdate($input: UserCertificationUpdateInput!) {
    userCertificationUpdate(input: $input) {
      ...BaseUser
    }
  }
  ${BASE_USER_FRAGMENT}
`;

export const DELETE_USER_CERTIFICATION = gql`
  mutation userCertificationDelete($input: UserCertificationDeleteInput!) {
    userCertificationDelete(input: $input) {
      ...BaseUser
    }
  }
  ${BASE_USER_FRAGMENT}
`;
