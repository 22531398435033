import * as R from 'ramda';

import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function (state = initialState.inventorySchema, action) {
  const { type, data } = action;
  switch (type) {
    case actionTypes.GET_INVENTORY_SCHEMA_SUCCESS: {
      return data;
    }
    case actionTypes.CLEAR_INVENTORY_SCHEMA_SUCCESS: {
      return initialState.inventorySchema;
    }
    case actionTypes.GET_INVENTORY_SCHEMA_TREE_NODE_SUCCESS: {
      const { elementGroups, elementPath } = data;

      const lensPath = R.lensPath(elementPath);
      return R.over(
        lensPath,
        element => ({
          ...element,
          elementGroups,
        }),
        state,
      );
    }
    default: {
      return state;
    }
  }
}
