import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function workOrderReducer(
  state = initialState.workOrderDetail,
  action,
) {
  const { data, type } = action;
  switch (type) {
    case actionTypes.GET_WORK_ORDER_DETAIL_SUCCESS:
    case actionTypes.PATCH_WORK_ORDER_SUCCESS:
    case actionTypes.ADD_TASK_ASSET_SUCCESS:
    case actionTypes.UPDATE_WORK_ORDER_DATA_SUCCESS: {
      return data;
    }
    case actionTypes.WORK_STATUS_SUCCESS: {
      return { ...state, statusId: data.statusId };
    }
    case actionTypes.CREATE_TASK_SUCCESS: {
      return {
        ...state,
        tasks: [data, ...state.tasks],
      };
    }
    case actionTypes.PATCH_TASK_SUCCESS: {
      const tasks = state.tasks.map(task =>
        task.id === data.id ? { ...data, materials: task.materials } : task,
      );

      return {
        ...state,
        tasks,
      };
    }
    case actionTypes.DELETE_TASK_SUCCESS: {
      const tasks = [...state.tasks].filter(task => task.id !== data);

      return {
        ...state,
        tasks,
      };
    }
    case actionTypes.CREATE_FORM_INSTANCE_SUCCESS: {
      const formInstances = {
        ...state.formInstances,
        [data.id]: data,
      };

      return {
        ...state,
        formInstances,
      };
    }
    case actionTypes.PATCH_FORM_INSTANCE_SUCCESS: {
      const formInstances = {
        ...state.formInstances,
        [data.id]: data,
      };
      return {
        ...state,
        formInstances,
      };
    }
    case actionTypes.CREATE_WORK_ORDER_ASSET_SUCCESS: {
      const assets = {
        ...state.assets,
        [data.id]: data,
      };

      return {
        ...state,
        assets,
      };
    }
    case actionTypes.PATCH_WORK_ORDER_ASSET_SUCCESS: {
      const assets = {
        ...state.assets,
        [data.id]: data,
      };
      return {
        ...state,
        assets,
      };
    }
    case actionTypes.CREATE_TASK_MATERIAL_SUCCESS: {
      return {
        ...state,
        tasks: state.tasks.map(task =>
          task.id === data.taskId
            ? {
                ...task,
                materials: [...task.materials, data.material],
              }
            : task,
        ),
      };
    }
    case actionTypes.UPDATE_TASK_MATERIAL_SUCCESS: {
      return {
        ...state,
        tasks: state.tasks.map(task =>
          task.id === data.taskId
            ? {
                ...task,
                materials: task.materials.map(material =>
                  material.assetId === data.assetId ? data.material : material,
                ),
              }
            : task,
        ),
      };
    }
    case actionTypes.DELETE_TASK_MATERIAL_SUCCESS: {
      return {
        ...state,
        tasks: state.tasks.map(task =>
          task.id === data.taskId
            ? {
                ...task,
                materials: task.materials.filter(
                  material => material.assetId !== data.assetId,
                ),
              }
            : task,
        ),
      };
    }
    case actionTypes.DELETE_TASK_ASSET_SUCCESS: {
      return {
        ...state,
        tasks: state.tasks.map(task =>
          task.id === data.taskId
            ? {
                ...task,
                assetIds: task.assetIds.filter(
                  assetId => assetId !== data.assetId,
                ),
              }
            : task,
        ),
      };
    }
    case actionTypes.ADD_TASK_USER_SUCCESS:
    case actionTypes.DELETE_ASSIGNEE_SUCCESS: {
      return {
        ...state,
        tasks: state.tasks.map(task =>
          task.id === data.taskId
            ? {
                ...task,
                users: task.users.filter(user => user.id !== data.userId),
              }
            : task,
        ),
      };
    }
    default: {
      return state;
    }
  }
}
