import React from 'react';
import { Control } from 'react-redux-form';

import { DatePicker } from '@atom/mui';
import { convertDateToMillisGMTMidday } from '@atom/utilities/timeUtilities';

import './attributeControls.css';

interface Props {
  name: string;
  value: any;
  onChange: (date: number) => void;
  isEditable: boolean;
  onUpdate?: (hasUnsavedChanges: boolean) => void;
}

interface State {
  value: any;
}

const style = {
  dateStyle: {
    paddingRight: '1rem',
  },
};

export class DatePickerWrapper extends React.Component<Props> {
  onChange = date => {
    if (!date) {
      this.props.onChange(null);
    } else {
      this.props.onChange(convertDateToMillisGMTMidday(date));
    }
  };

  render() {
    return (
      // @ts-ignore
      <DatePicker
        {...this.props}
        onChange={this.onChange}
        value={this.props.value}
        TextFieldProps={{ InputProps: { style: style.dateStyle } }}
      />
    );
  }
}

class DateAttribute extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value ? props.value.valueOf() : null,
    };
  }

  onChange = value => {
    if (this.props.onUpdate) {
      this.props.onUpdate(true);
    }
    this.setState({ value });
  };

  render() {
    const { name, isEditable } = this.props;
    const { value } = this.state;

    return (
      <div key={name}>
        {/* 
          // @ts-ignore */}
        <Control
          model={`.${name}`}
          component={DatePickerWrapper}
          value={value}
          onChange={date => this.onChange(date)}
          disabled={!isEditable}
        />
      </div>
    );
  }
}

export default DateAttribute;
