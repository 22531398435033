import React from 'react';
import * as R from 'ramda';

import { Icon, IconButton } from '@atom/mui';
import { WorkTemplateFolderTree } from '@atom/types/workTemplate';

import './workTypeFolderTree.css';

export interface Props {
  workTemplateFolder: WorkTemplateFolderTree;
  removeWorkTemplateFolder: () => void;
  disabled?: boolean;
}

const WorkTypeFolderTile = ({
  workTemplateFolder,
  removeWorkTemplateFolder,
  disabled = false,
}: Props) => {
  return (
    <div styleName="template-tile">
      <div styleName="tile-content">
        <div styleName="tile-title">
          {R.pathOr([], ['folderPath'], workTemplateFolder).join(' / ')}
        </div>
      </div>
      <IconButton
        onClick={removeWorkTemplateFolder}
        disabled={disabled}
        size="small"
      >
        <Icon>close</Icon>
      </IconButton>
    </div>
  );
};

export default WorkTypeFolderTile;
