import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import MapDrawerLayers from '@atom/components/common/map/mapDrawerLayers/MapDrawerLayers';
import { getMapInventorySchemas } from '@atom/selectors/mapSelectors';

import { useLayersUpdater, useLayersValue } from '../hooks/layersHook';

const MapDrawerLayersWrapper = () => {
  const layers = useLayersValue();
  const dispatch = useLayersUpdater();

  const inventorySchemas = useSelector(getMapInventorySchemas);

  useEffect(() => {
    if (inventorySchemas.length) {
      dispatch({
        type: 'SET_ONCE',
        payload: {
          key: 'assetSchemas',
          data: new Set(R.pluck('id')(inventorySchemas)),
        },
      });
    }
  }, [inventorySchemas, dispatch]);

  const update = (group: string, keys = null) => (
    groupValue: boolean,
    keysValue: object = null,
  ) =>
    new Promise(resolve => {
      dispatch({ type: 'SET', payload: { key: group, data: groupValue } });
      if (keys) {
        dispatch({ type: 'SET', payload: { key: keys, data: keysValue } });
      }
      resolve(true);
    });

  return (
    <MapDrawerLayers
      assetSchemas={layers.assetSchemas}
      schemas={inventorySchemas}
      workStatusIds={layers.workStatusIds}
      workTemplateIds={layers.workTemplateIds}
      workTemplateFolderIds={layers.workTemplateFolderIds}
      workToggled={layers.work}
      usersToggled={layers.users}
      assetsToggled={layers.assets}
      kmlToggled={layers.kmlLayers}
      onWorkTemplatesUpdate={update('work', 'workTemplateIds')}
      onWorkTemplateFoldersUpdate={update('work', 'workTemplateFolderIds')}
      onAssetUpdate={update('assets', 'assetSchemas')}
      onWorkUpdate={update('work', 'workStatusIds')}
      onUserUpdate={update('users')}
      onKmlUpdate={update('kmlLayers', 'kmlLayerIds')}
    />
  );
};

export default MapDrawerLayersWrapper;
