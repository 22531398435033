import React, { useState } from 'react';

import FontIconButton from '@atom/components/common/FontIconButton';
import { Button, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import layout from '@atom/styles/layout';
import { MediaItem } from '@atom/types/media';

import CiMediaTile from './CiMediaTile';

import './ci.css';

interface Props {
  assetId: string;
  media: MediaItem[];
  onChange: (mediaIds: string[]) => void;
  uploadFiles: (
    files?: any[],
    subjectId?: string,
    subjectType?: string,
  ) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  disabled?: boolean;
  isEditable: boolean;
}

const styles = {
  icon: {
    padding: '0px',
    height: '24px',
    width: '24px',
  },
  input: {
    display: 'none',
  },
  dialog: {
    body: {
      margin: 0,
      padding: 0,
      maxWidth: layout.formModuleMediaModalWidth,
      minHeight: layout.formModuleMediaModalHeight,
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    uploadPhotoButton: {
      float: 'left',
    },
    title: {
      fontSize: fonts.xl,
      padding: '1.25rem',
      fontWeight: '500',
      lineHeight: '20px',
    },
    actionsContainerStyle: {
      paddingRight: '1rem',
      paddingBottom: '1rem',
    },
  },
};

const CiMediaModal = ({
  media,
  assetId,
  onChange,
  uploadFiles,
  renameMedia,
  disabled,
  isEditable,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleUpload = event => {
    const { files } = event.target;
    const subjectType = 'formInstanceAsset';
    uploadFiles(files, assetId, subjectType);
  };

  const handleRemove = (mediaId: string) => {
    const mediaIds = media
      .filter(({ id }) => id !== mediaId)
      .map(({ id }) => id);

    onChange(mediaIds);
  };

  const getFooter = () => {
    return (
      <div>
        <Button
          disabled={!isEditable}
          style={styles.dialog.uploadPhotoButton}
          color="primary"
          component="label"
        >
          upload photo
          <input
            type="file"
            style={styles.input}
            multiple
            onChange={handleUpload}
            disabled={!isEditable}
          />
        </Button>
        <Button
          onClick={() => setOpen(false)}
          color="primary"
          variant="contained"
        >
          done
        </Button>
      </div>
    );
  };

  return (
    <>
      <FontIconButton
        style={styles.icon}
        onClick={() => setOpen(true)}
        color={media.length ? colors.brand.blue : colors.neutral.gray}
        disabled={disabled}
        icon="photo"
      />
      <div styleName={`column-suffix-text ${media.length ? 'blue' : ''}`}>
        {media.length}
      </div>
      <Modal
        open={open}
        width="lg"
        footer={getFooter()}
        title="Photos"
        style={styles.dialog.body}
      >
        <div styleName="photo-modal-content">
          {media.map((medium: MediaItem) => (
            <CiMediaTile
              key={medium.id}
              media={medium}
              assetId={assetId}
              removeFile={handleRemove}
              renameMedia={renameMedia}
            />
          ))}
        </div>
      </Modal>
    </>
  );
};

export default CiMediaModal;
