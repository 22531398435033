import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';

import {
  clearApplicationSearch,
  requestApplicationSearch,
} from '@atom/actions/applicationSearchActions';
import { KeyPressEventType } from '@atom/types/event';

import InventorySearchResultsContainer from './InventorySearchResultsContainer';
import SearchInputControl from './SearchInputControl';
import SearchResultsNavigationControl from './SearchResultsNavigationControl';
import TeamSearchResultsContainer from './TeamSearchResultsContainer';
import WorkSearchResultsContainer from './WorkSearchResultsContainer';

import './searchBar.css';

type Props = {
  navigate: () => void;
  isSearchPortal: boolean;
};

const SearchResults = ({ navigate, isSearchPortal }: Props) => {
  const dispatch = useDispatch();
  const appSearch = useSelector(state => state.applicationSearch);
  const loadingAppSearch = useSelector(
    state => state.loading.loadingApplicationSearch,
  );

  const [query, setQuery] = useState<string>(appSearch.query);

  const onQueryChange = (value: string) => {
    if (!value) {
      dispatch(clearApplicationSearch());
    } else {
      dispatch(
        requestApplicationSearch({
          query: value,
          top: true,
        }),
      );
    }
  };

  const onPortalQueryChange = (value: string) => {
    setQuery(value);
  };

  // eslint-disable-next-line consistent-return
  const onKeyPress = (event: KeyPressEventType) => {
    if (event.key === 'Enter') {
      return isSearchPortal
        ? dispatch(requestApplicationSearch({ query, top: false }))
        : navigate();
    }
  };

  const isEmpty =
    R.isEmpty(appSearch.assets) &&
    R.isEmpty(appSearch.workOrders) &&
    R.isEmpty(appSearch.users);

  const content = loadingAppSearch ? (
    <div />
  ) : (
    <>
      {appSearch.query && !R.isEmpty(appSearch.assets) && (
        <InventorySearchResultsContainer
          query={appSearch.query}
          assets={appSearch.assets}
        />
      )}
      {appSearch.query && !R.isEmpty(appSearch.workOrders) && (
        <WorkSearchResultsContainer
          query={appSearch.query}
          workOrders={appSearch.workOrders}
        />
      )}
      {appSearch.query && !R.isEmpty(appSearch.users) && (
        <TeamSearchResultsContainer
          query={appSearch.query}
          users={appSearch.users}
        />
      )}
    </>
  );

  return (
    <div styleName="search-results-container">
      <SearchInputControl
        query={isSearchPortal ? query : appSearch.query}
        onChange={isSearchPortal ? onPortalQueryChange : onQueryChange}
        onKeyPress={onKeyPress}
      />
      {!isSearchPortal && appSearch.query && (
        <SearchResultsNavigationControl
          isEmpty={isEmpty}
          loading={loadingAppSearch}
          query={appSearch.query}
          navigate={navigate}
        />
      )}
      {!isSearchPortal && content}
    </div>
  );
};

export default SearchResults;
