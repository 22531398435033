import * as R from 'ramda';

import {
  InheritedComponent,
  InheritedComponentType,
  WorkTemplate,
  WorkTemplateFolderTree,
} from '@atom/types/workTemplate';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

export const getAllDescendantFolderIds = (
  folder: WorkTemplateFolderTree,
): string[] => {
  return folder.children.reduce(
    (acc: string[], child): string[] => [
      ...acc,
      child.id,
      ...getAllDescendantFolderIds(child),
    ],
    [],
  );
};

export const getNestedWorkTemplatePath = (
  folderTree: WorkTemplateFolderTree,
  folderPath: any[],
) => {
  const index = R.findIndex(R.propEq('id', folderPath[0]))(
    folderTree?.children || [],
  );

  if (index < 0) {
    return [];
  }

  const child = folderTree?.children[index];
  return [
    'children',
    index,
    ...(R.length(folderPath) > 1
      ? getNestedWorkTemplatePath(child, R.drop(1, folderPath))
      : []),
  ];
};

export const getWorkTemplateFolderByFolderPath = (
  folderTree: WorkTemplateFolderTree,
  folderPath: string[],
): WorkTemplateFolderTree => {
  if (isNilOrEmpty(folderTree)) {
    // @ts-ignore
    return {};
  }

  const index = R.findIndex(R.propEq('id', folderPath[1]))(
    folderTree?.children || [],
  );
  const child = folderTree?.children[index];

  const path = R.lensPath([
    'children',
    index,
    ...(R.length(folderPath) > 2
      ? getNestedWorkTemplatePath(child, R.drop(2, folderPath))
      : []),
  ]);

  return R.view(path, folderTree);
};

export enum WorkTypeVariant {
  REGULAR = 'REGULAR',
  PARENT = 'PARENT',
  CHILD = 'CHILD',
}

export const getWorkTypeVariant = (isParent: boolean, parentId: string) => {
  if (isParent) {
    return WorkTypeVariant.PARENT;
  }

  if (!isParent && parentId) {
    return WorkTypeVariant.CHILD;
  }

  return WorkTypeVariant.REGULAR;
};

export const isComponentInherited = (
  type: InheritedComponentType,
  inheritedComponents: InheritedComponent[],
  id?: string,
): boolean => {
  const inheritedComponent = R.find(
    R.propEq('type', type),
    inheritedComponents || [],
  );

  if (id) {
    return (
      !!inheritedComponent && R.includes(id, inheritedComponent?.ids || [])
    );
  }

  return !!inheritedComponent;
};

export const rollUpTaskInheritedComponent = (
  workTemplate: WorkTemplate,
  componentType: InheritedComponentType,
): InheritedComponent[] => {
  if (isNilOrEmpty(workTemplate?.tasks)) {
    return [];
  }

  const allInheritedComponents = workTemplate?.tasks.reduce((acc, task) => {
    return [...acc, ...(task?.inheritedComponents || [])];
  }, []);

  if (isNilOrEmpty(allInheritedComponents)) {
    return [];
  }

  const inheritedComponent = allInheritedComponents.reduce((acc, component) => {
    return component.type === componentType
      ? { ...component, ids: [...(acc?.ids || []), ...component.ids] }
      : acc;
  }, {});

  return [inheritedComponent];
};

export const getUniqueInheritedComponentTypes = (
  workTemplate: WorkTemplate,
): InheritedComponentType[] => {
  const workTypeTypes = R.pathOr([], ['inheritedComponents'], workTemplate).map(
    component => component.type,
  );

  const taskTypes = R.pathOr([], ['tasks'], workTemplate).reduce(
    (acc, task) => {
      const types = R.pathOr([], ['inheritedComponents'], task).map(
        component => component.type,
      );

      return [...acc, ...types];
    },
    [],
  );

  return [...new Set([...workTypeTypes, ...taskTypes])];
};
