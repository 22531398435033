import React, { createContext, useContext, useState } from 'react';

import { MapData } from '@atom/types/map';

import { initialState } from './mapDataHook';

const Context = createContext<
  [MapData, React.Dispatch<React.SetStateAction<MapData>>]
>([initialState, () => {}]);

const Provider = Context.Provider;

export const useListView = () => useContext(Context);

export const withListView = WrappedComponent => {
  const ListView = props => {
    const value = useState<MapData>(initialState);

    return (
      <Provider value={value}>
        <WrappedComponent {...props} />
      </Provider>
    );
  };

  return ListView;
};
