import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import * as userActionCreators from '@atom/actions/userActions';
import { Progress } from '@atom/mui';
import { UserActions } from '@atom/types/actions';
import { ReduxStore, UserProfileState } from '@atom/types/store';

import TopNavigationBar from './common/navigation/TopNavigationBar';

interface PassedProps {
  children?: any;
  userProfile: UserProfileState;
  auth: object;
  loading: object;
  userActions: object;
  component: any;
  path: string;
}

type ReduxDispatchProps = {
  userActions: UserActions;
};

type Props = PassedProps & ReduxDispatchProps;

class AdminRoute extends React.Component<Props> {
  componentDidMount() {
    const { userActions } = this.props;
    userActions.retrieveLoggedInUser();
  }

  render() {
    const { component: Component, userProfile, userActions, path } = this.props;
    const pathname = location.pathname;

    return (
      <Route
        path={path}
        render={props => {
          // Retrieve the current user if they are not currently logged in
          // At this point the user is verified, so if they dont exist they will be created
          if (userProfile.isVerified && !userProfile.userId) {
            userActions.retrieveLoggedInUser();
          }

          if (
            userProfile.isVerified &&
            userProfile.userId &&
            (userProfile.role === 'OrgAdmin' || userProfile.role === 'Admin')
          ) {
            return (
              <div style={{ height: '100%' }}>
                <TopNavigationBar pathname={pathname} />
                <Component {...props} />
              </div>
            );
          }

          return (
            <Progress size={80} thickness={3} style={{ height: '100%' }} />
          );
        }}
      />
    );
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStore => state;

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  userActions: bindActionCreators(userActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminRoute);
