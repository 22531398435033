import React, { useContext } from 'react';
import * as R from 'ramda';

// @ts-ignore
import activityIcon from '@atom/images/activityIcon.svg';
import { Icon, Snackbar, Switch } from '@atom/mui';
import {
  ColumnsData,
  DashboardCard,
  DashboardCardContentState,
  DashboardCardType,
} from '@atom/types/dashboard';
import { updateAtId } from '@atom/utilities/stateUtilities';

import DashboardContext from '../DashboardContext';
import { getColumnIndex, getColumnsData } from '../dashboardUtilities';

import { ModalView } from './CustomizeDashboardModal';
import TeamIcon from './TeamIcon';

import './customizeDashboardModal.css';

interface Props {
  setView: (view: ModalView) => void;
}

const styles = {
  switchLabel: {
    marginLeft: 0,
    width: '100%',
    justifyContent: 'space-between',
  },
};

const getOption = (card, onChange, disabled) => (
  <div key={card.id} styleName="customize-dashboard-option">
    <Switch
      labelPlacement="start"
      label={
        <div styleName="customize-dashboard-label">
          <div styleName="option-icon">{card.icon}</div>
          <div>
            <div styleName="customize-option-heading">{card.heading}</div>
            <span styleName="customize-option-subheading">
              {card.subheading}
            </span>
          </div>
        </div>
      }
      style={styles.switchLabel}
      checked={card.order !== -1}
      onChange={() => onChange(card)}
      disabled={disabled}
    />
  </div>
);

const DEFAULT_CARD_TYPE_OPTIONS = {
  [DashboardCardType.OPEN_WORK]: {
    icon: <Icon>work</Icon>,
    heading: 'My Open Work',
    subheading: 'Display a list of open work assigned to me.',
  },
  [DashboardCardType.PENDING_USERS]: {
    icon: <TeamIcon />,
    heading: 'Pending User Requests',
    subheading: 'Display the number of pending user requests.',
  },
  [DashboardCardType.ASSET_COUNT]: {
    icon: <Icon>category</Icon>,
    heading: 'Inventory Count By Inventory Type',
    subheading: 'Tracks the number of inventory per type.',
  },
  [DashboardCardType.PAST_DUE_WORK]: {
    icon: <Icon>work</Icon>,
    heading: 'Past Due Work',
    subheading: 'Displays a list of past due work.',
  },
  [DashboardCardType.RECENTLY_UPDATED]: {
    icon: <img src={activityIcon} />,
    heading: 'Recently Updated By Me',
    subheading: 'Display work, inventory and team recently updated by me.',
  },
};

const CustomizeDashboardView = ({ setView }: Props) => {
  const {
    allCards,
    setAllCards,
    lgColumn,
    smColumn,
    updateLayout,
    updatingLayout,
  } = useContext(DashboardContext);
  const optionCards: DashboardCard[] = allCards.filter(
    card => card.type !== 'EMBED',
  );

  const onChange = async cardUpdated => {
    const isEnabling = cardUpdated.order === -1;
    const targetColumnIdx = getColumnIndex(cardUpdated.size);
    const columns: ColumnsData = getColumnsData(lgColumn, smColumn);
    const newOrder = isEnabling ? columns[targetColumnIdx].length : -1;

    if (isEnabling) {
      columns[targetColumnIdx] = [...columns[targetColumnIdx], cardUpdated.id];
    } else {
      columns[targetColumnIdx] = R.without(
        [cardUpdated.id],
        columns[targetColumnIdx],
      );
    }

    try {
      updateLayout(columns);
      const updatedCards = updateAtId(
        cardUpdated.id,
        R.evolve({
          order: () => newOrder,
          contentState: () => DashboardCardContentState.LOADING,
        }),
      )(allCards);
      setAllCards(updatedCards);
    } catch (error) {
      Snackbar.error({
        message:
          'Something went wrong. Please try again or contact an administrator.',
      });
    }
  };

  const disabled = updatingLayout || R.isEmpty(allCards);

  return (
    <div styleName="customize-dashboard-options">
      <div
        styleName="customize-dashboard-option"
        onClick={() => setView(ModalView.ADD_CUSTOM)}
      >
        <div styleName="customize-dashboard-label">
          <div styleName="option-icon">
            <Icon>code</Icon>
          </div>
          <div>
            <div styleName="customize-option-heading">Custom Embed</div>
            <span styleName="customize-option-subheading">
              Insert an embed link.
            </span>
          </div>
        </div>
        <div styleName="customize-option-right">
          <Icon>chevron_right</Icon>
        </div>
      </div>
      {optionCards.map(card =>
        getOption(
          { ...card, ...DEFAULT_CARD_TYPE_OPTIONS[card.type] },
          onChange,
          disabled,
        ),
      )}
    </div>
  );
};

export default CustomizeDashboardView;
