import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as R from 'ramda';

import { Icon, IconButton, Popover } from '@atom/mui';
import colors from '@atom/styles/colors';

import AnalyticsContext from './AnalyticsContext';

import './analyticsBreadcrumbs.css';

interface Breadcrumb {
  id: string;
  name: string;
}

const AnalyticsBreadcrumbs = () => {
  const container = useRef<HTMLDivElement>();
  const [anchor, setAnchor] = useState<any>(null);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [overflowing, setOverflowing] = useState<boolean>(false);

  const {
    treeData,
    activeFolderId,
    setActiveFolderId,
    allFolders,
    setSearching,
  } = useContext(AnalyticsContext);

  const hydrateBreadcrumbs = (crumbs: string[]): Breadcrumb[] => {
    return crumbs.map(crumb => {
      const crumbFolder = allFolders.find(folder => crumb === folder?.name);
      return {
        id: crumbFolder?.id || 'analytics',
        name: crumb,
      };
    });
  };

  const activeFolder =
    allFolders.find(folder => activeFolderId === folder.id) || treeData;
  const breadcrumbs = useMemo(
    () => hydrateBreadcrumbs(activeFolder?.folderPath || ['Analytics']),
    [activeFolder],
  );
  const activeBreadcrumb = R.last(breadcrumbs);

  const lastIndex = R.dec(breadcrumbs.length);
  const overflowBreadcrumbs = R.slice(
    1,
    R.dec(breadcrumbs.length),
    breadcrumbs,
  );

  const handleToggle = event => {
    setAnchor(event.currentTarget);
    setPopoverOpen(true);
  };

  const handleClick = (id: string) => {
    setActiveFolderId(id);
    setPopoverOpen(false);
    setSearching(false);
  };

  useEffect(() => {
    setOverflowing(
      container.current?.offsetHeight < container.current?.scrollHeight,
    );
  }, [breadcrumbs]);

  const arrow = <Icon color={colors.neutral.gray}>keyboard_arrow_right</Icon>;

  const overflowContent = (
    <div styleName="breadcrumb-container">
      <span
        styleName="breadcrumb"
        onClick={() => handleClick('analytics')}
        data-cy="analyticsBreadcrumbLink_analytics"
      >
        Analytics
      </span>
      <IconButton onClick={handleToggle}>
        <Icon color={colors.neutral.gray}>more_horiz</Icon>
      </IconButton>
      <Popover
        open={popoverOpen}
        anchorEl={anchor}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        onClose={() => setPopoverOpen(false)}
      >
        {overflowBreadcrumbs.map(breadcrumb => (
          <div
            key={breadcrumb.id}
            onClick={() => handleClick(breadcrumb.id)}
            styleName="breadcrumb-menu-item"
            data-cy={`analyticsBreadcrumbLink_${breadcrumb?.name}`}
          >
            {breadcrumb?.name}
          </div>
        ))}
      </Popover>
      {arrow}
      <span
        styleName="breadcrumb"
        onClick={() => handleClick(activeBreadcrumb.id)}
        data-cy={`analyticsBreadcrumbLink_${activeBreadcrumb?.name}`}
      >
        {activeBreadcrumb?.name}
      </span>
    </div>
  );

  // regularContent element should always be in DOM for container ref check
  // - hide when overflow element is used
  const regularContent = (
    <div
      ref={container}
      styleName="breadcrumb-container"
      style={{
        visibility: overflowing ? 'hidden' : 'visible',
        position: overflowing ? 'absolute' : 'relative',
        left: overflowing ? '-99999' : '0',
      }}
    >
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={index}>
          <div
            styleName="breadcrumb"
            onClick={() => handleClick(breadcrumb.id)}
          >
            {breadcrumb?.name}
          </div>
          {index !== lastIndex && arrow}
        </React.Fragment>
      ))}
    </div>
  );

  const defaultTitle = (
    <div ref={container} styleName="breadcrumb-container">
      Analytics
    </div>
  );

  if (R.isEmpty(breadcrumbs)) {
    return defaultTitle;
  }

  return overflowing ? (
    <>
      {overflowContent}
      {regularContent}
    </>
  ) : (
    <>{regularContent}</>
  );
};

export default AnalyticsBreadcrumbs;
