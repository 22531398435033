import * as React from 'react';

import './mapDrawerContent.css';

interface Props {
  label: string;
  onClick: () => void;
}

const NavigationButton = ({ label, onClick }: Props) => {
  return (
    <div styleName="summary-button" onClick={onClick}>
      {label}
    </div>
  );
};

export default NavigationButton;
