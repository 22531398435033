import React, { useContext, useState } from 'react';
import * as R from 'ramda';

import UserContext from '@atom/components/userDetail/UserContext';
import { Icon, IconButton } from '@atom/mui';
import { userBudgetsSelector } from '@atom/selectors/userSelectors';
import colors from '@atom/styles/colors';
import { UserBudgetItem } from '@atom/types/user';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import {
  isCurrentTenant,
  Tenant,
} from '@atom/utilities/featureToggleUtilities';

import AddEditBudgetModal from './AddEditBudgetModal';
import UserBudgetTile from './UserBudgetTile';

import './budgetsTab.css';

const getBudgetHeaderLabel = (budgets: UserBudgetItem[]): string => {
  if (!budgets || !R.length(budgets)) {
    return 'No budget configured';
  }

  if (R.length(budgets) === 1) {
    return '1 budget';
  }

  return `${R.length(budgets)} budgets`;
};

const BudgetsTab = () => {
  const { user } = useContext(UserContext);

  const [open, setOpen] = useState<boolean>(false);

  const budgets = userBudgetsSelector(user);
  const budgetHeaderLabel = getBudgetHeaderLabel(budgets);

  const isAddingBudgetDisabled =
    hasRolePermissions(ROLE_SETS.ORG_ADMIN) &&
    !isCurrentTenant([Tenant.UDOT, Tenant.UAT_UDOT]);

  return (
    <>
      <div styleName="header-container">
        <div>{budgetHeaderLabel}</div>
        {isAddingBudgetDisabled && (
          <IconButton onClick={() => setOpen(true)}>
            <Icon color={colors.neutral.gray}>add</Icon>
          </IconButton>
        )}
      </div>
      <div styleName="budget-list-container">
        {budgets.map((budget: UserBudgetItem) => (
          <UserBudgetTile key={budget.id} budget={budget} />
        ))}
      </div>
      <AddEditBudgetModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default BudgetsTab;
