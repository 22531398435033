import React, { useState } from 'react';

import { useSchemas } from '@atom/hooks/useSchemas';
import { Icon, Modal, Select, TextField } from '@atom/mui';
import { InventorySchemaItem } from '@atom/types/inventory';
import schemaUtilities from '@atom/utilities/schemaUtilities';

const { MenuItem } = Select;

export interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (name: string, categoryId?: string) => void;
  loading: boolean;
}

const styles = {
  input: {
    margin: '2rem 0',
  },
  icon: {
    marginRight: '0.5rem',
  },
  modal: {
    padding: '0 2rem',
  },
};

const renderSchema = ({ id, name, colorId }: InventorySchemaItem) => {
  const color = schemaUtilities.getSchemaColorFromColorId(colorId);

  return (
    <MenuItem key={id} value={id}>
      <Icon color={color} style={styles.icon}>
        lens
      </Icon>
      {name}
    </MenuItem>
  );
};

const CreateFolderModal = ({ open, onClose, onSubmit, loading }: Props) => {
  const [name, setName] = useState<string>('');
  const [schemaId, setSchemaId] = useState<string>('');

  const { schemas = [], loading: loadingSchemas } = useSchemas();

  const resetState = () => {
    setName('');
    setSchemaId('');
  };

  const renderValue = id => {
    const selected = schemas.find(schema => schema.id === id);
    return selected ? selected.name : '';
  };

  return (
    <Modal
      title="Create Folder"
      open={open}
      onCancel={onClose}
      onExited={resetState}
      onConfirm={() => onSubmit(name, schemaId)}
      loading={loading}
      contentStyle={styles.modal}
    >
      <div style={styles.input}>
        <TextField
          label="Name"
          value={name}
          name="name"
          onChange={event => setName(event.target.value)}
        />
      </div>
      <div style={styles.input}>
        <Select
          label="Inventory Type (Optional)"
          placeholder="Select"
          onChange={event => setSchemaId(event.target.value)}
          value={schemaId}
          disabled={loadingSchemas}
          renderValue={renderValue}
        >
          {schemas.map(renderSchema)}
        </Select>
      </div>
    </Modal>
  );
};

export default CreateFolderModal;
