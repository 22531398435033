import React from 'react';

import { DataType } from '@atom/types/dataType';

import BooleanInput from './BooleanInput';
import CurrencyInput from './CurrencyInput';
import DateInput from './DateInput';
import EnumMultipleInput from './EnumMultipleInput';
import EnumSingleInput from './EnumSingleInput';
import NumberInput from './NumberInput';
import TextInput from './TextInput';

export enum BooleanOrientation {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}

interface Props {
  /**
   * Determines what input component will be returned
   */
  dataType: DataType;
  /**
   * id is used as key prop on inputs
   * This value should be unique
   */
  id: string;
  /**
   * value of the input
   * type of value is any and depends on the given dataType
   */
  value?: any;
  /**
   * callback for handling changes
   */
  onChange: (value: any) => void;
  /**
   * Determines whether the input is disabled or not
   */
  disabled?: boolean;
  /**
   * Determines whether the input is in an error state or not
   */
  error?: boolean;
  /**
   * Options is used for ENUM_SINGLE and ENUM_MULTIPLE dataTypes to
   * communicate dropdown options
   */
  options?: string[];
  /**
   * Determines whether the input is disabled or not
   */
  booleanOrientation?: BooleanOrientation;
  /**
   * String that will be prepended to each data input's data-cy
   * This allows data-cy to be custom per call site of the component
   */
  dataCyPrefix?: string;
}

const DataTypeInput = ({
  dataType,
  id,
  value = null,
  onChange,
  disabled = false,
  error = false,
  options,
  booleanOrientation,
  dataCyPrefix = '',
}: Props) => {
  const props = {
    dataType,
    id,
    value,
    onChange,
    disabled,
    error,
    dataCyPrefix,
  };

  const inputs = {
    [DataType.SHORT_TEXT]: <TextInput {...props} />,
    [DataType.LONG_TEXT]: <TextInput {...props} />,
    [DataType.DATE]: <DateInput {...props} />,
    [DataType.DATE_TIME]: <div />,
    [DataType.ENUM_SINGLE]: <EnumSingleInput {...props} options={options} />,
    [DataType.ENUM_MULTIPLE]: (
      <EnumMultipleInput {...props} options={options} />
    ),
    [DataType.CURRENCY]: <CurrencyInput {...props} />,
    [DataType.NUMBER]: <NumberInput {...props} />,
    [DataType.BOOLEAN]: (
      <BooleanInput {...props} booleanOrientation={booleanOrientation} />
    ),
    [DataType.HYPERLINK]: <div />,
    [DataType.SUMMARY]: <div />,
  };

  const input = inputs[dataType] || <div />;

  return input;
};

export default DataTypeInput;
