import * as R from 'ramda';
import { createSelector } from 'reselect';

import { inventorySchemas } from './schemaSelectors';

const KNOWLEDGE_CENTER_LINK =
  'https://sites.google.com/sadasystems.com/atom-help/home';

export const preferencesSelector = state => state.preferences;

type UnitScope = 'custom_fields' | 'assets';

export const budgetUnitsSelector = createSelector(
  preferencesSelector,
  preferences =>
    R.pathOr<{ unit: string; scope: UnitScope }[]>([], ['units'], preferences)
      .filter(({ scope }) => scope === 'assets')
      .map(({ unit }) => unit),
);

export const customFieldUnitsSelector = createSelector(
  preferencesSelector,
  preferences =>
    R.pathOr<{ unit: string; scope: UnitScope }[]>(
      [],
      ['units'],
      preferences,
    ).filter(({ scope }) => scope === 'custom_fields'),
);

export const inventoryPreferencesSelector = R.pathOr(
  [],
  ['preferences', 'inventory'],
);

export const inventoryAssetPreferencesSelector = R.pathOr(
  [],
  ['preferences', 'inventory', 'assets'],
);

const knowledgeCenterLink = R.pathOr(KNOWLEDGE_CENTER_LINK, [
  'preferences',
  'knowledgeCenter',
]);

export const knowledgeCenterLinkSelector = createSelector(
  knowledgeCenterLink,
  link => link,
);

export const inventoryPortalAssetColumnsSelector = (
  assetType,
  assetPreferences,
  schemas,
) => {
  if (
    R.isNil(assetType) ||
    R.isNil(assetPreferences) ||
    R.isEmpty(inventorySchemas)
  ) {
    return [];
  }

  const preferences = assetPreferences[assetType];

  if (!R.isNil(preferences)) {
    return preferences;
  }

  const schema = schemas.find(rootSchema => rootSchema.assetType === assetType);

  if (R.isNil(schema)) {
    return [];
  }

  const baseColumns = schema.isMaterial
    ? [
        {
          name: 'Inventory Name',
          fieldName: 'name',
          dataType: 'shorttext',
        },
        {
          name: 'Inventory Type',
          fieldName: 'assetType',
          dataType: 'shorttext',
        },
        {
          name: 'Cost',
          fieldName: 'budget.rate',
          dataType: 'cost',
        },
      ]
    : [
        {
          name: 'Inventory Name',
          fieldName: 'name',
          dataType: 'shorttext',
        },
        {
          name: 'Type',
          fieldName: 'assetType',
          dataType: 'shorttext',
        },
      ];

  const attributeColumns = R.keys(schema.attributes)
    .slice(0, 15)
    .map(key => {
      const { name, dataType } = schema.attributes[key];

      return {
        name,
        fieldName: `attributes.${String(key)}`,
        dataType,
      };
    });

  return [...baseColumns, ...attributeColumns];
};
