import React, { useEffect, useState } from 'react';

import { Checkbox } from '@atom/mui';
import colors from '@atom/styles/colors';
import { MediaItem } from '@atom/types/media';

import CarouselModal from './CarouselModal';

import './carousel.css';

interface Props {
  children: React.ReactNode;
  media: MediaItem[];
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  selectedMediaId: string;
  removeFile: (mediaId: string, subjectId: string) => void;
  subjectId: string;
  toggleCheckbox?: (id: string) => void;
  isChecked?: boolean;
  setAsPrimaryMedia?: (mediaId: string) => void;
  primaryMediaId?: string;
  canMarkAsCoverPhoto?: boolean;
  canUpdateMedia: boolean;
  canDeleteMedia: boolean;
  refetchMedia?: () => void;
}

const CarouselWrapper = ({
  children,
  removeFile,
  subjectId,
  renameMedia,
  toggleCheckbox,
  isChecked,
  setAsPrimaryMedia,
  primaryMediaId,
  canMarkAsCoverPhoto,
  canUpdateMedia,
  canDeleteMedia,
  refetchMedia,
  ...props
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [media, setMedia] = useState<MediaItem[]>(props.media);
  const [selectedMediaId, setSelectedMediaId] = useState<string>(
    props.selectedMediaId,
  );

  useEffect(() => {
    setMedia(props.media);
  }, [props.media]);

  const resetState = () => {
    setOpen(false);
    setMedia(props.media);
    setSelectedMediaId(props.selectedMediaId);
  };

  const maskStyleName = isChecked ? 'image-mask checked' : 'image-mask';
  const checkboxStyleName = isChecked
    ? 'checkbox-container checked'
    : 'checkbox-container';

  const onClick = toggleCheckbox ? () => {} : () => setOpen(true);

  return (
    <>
      <div styleName="wrapper-container">
        <div onClick={onClick}>{children}</div>
        {toggleCheckbox ? (
          <div styleName={maskStyleName}>
            <div
              styleName={checkboxStyleName}
              onClick={() => toggleCheckbox(selectedMediaId)}
            >
              <Checkbox
                checked={isChecked}
                onChange={() => {}}
                style={{ color: colors.neutral.white }}
              />
            </div>
            <div
              styleName="carousel-click-mask"
              onClick={() => setOpen(true)}
            />
          </div>
        ) : (
          <div />
        )}
      </div>
      <CarouselModal
        open={open}
        onClose={resetState}
        media={media}
        selectedMediaId={selectedMediaId}
        setSelectedMediaId={setSelectedMediaId}
        removeFile={removeFile}
        subjectId={subjectId}
        renameMedia={renameMedia}
        setAsPrimaryMedia={setAsPrimaryMedia}
        primaryMediaId={primaryMediaId}
        canMarkAsCoverPhoto={canMarkAsCoverPhoto}
        canUpdateMedia={canUpdateMedia}
        canDeleteMedia={canDeleteMedia}
        refetchMedia={refetchMedia}
      />
    </>
  );
};

export default CarouselWrapper;
