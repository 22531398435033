import React, { useEffect, useState } from 'react';

import { MediaItem } from '@atom/types/media';
import { WorkOrderAssetTreeType } from '@atom/types/work';
import api from '@atom/utilities/api';
import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';
import { MEDIA_ENDPOINT } from '@atom/utilities/endpoints';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import PhotoTile from './PhotoTile';

import '../workOrderDetailPdfReport.css';

interface Props {
  workOrderAsset: WorkOrderAssetTreeType;
  workOrderId: string;
  setOtherPhotosLoading: (currentLoading: { [id: string]: boolean }) => void;
  getFullPathName: (id: string) => void;
}

const RootAssetInfo = ({
  workOrderAsset,
  workOrderId,
  setOtherPhotosLoading,
  getFullPathName,
}: Props) => {
  const [photos, setPhotos] = useState<MediaItem[]>([]);

  useEffect(() => {
    const getMedia = async () => {
      const data = {
        subjectTypes: 'workOrderAsset',
        parentSubjectId: workOrderId,
        subjectIds: workOrderAsset.id,
      };
      const response = await api.get(MEDIA_ENDPOINT, data);

      setPhotos(response.data);
      // @ts-ignore
      setOtherPhotosLoading(currentLoading => ({
        ...currentLoading,
        attributeGroupAssessment: false,
      }));
    };
    getMedia();
  }, []);

  const getSummarySection = () => {
    const { attributeGroups, attributes } = workOrderAsset;
    const group = attributeGroups.filter(
      attributeGroup => attributeGroup.name === 'Summary',
    );

    if (isNilOrEmpty(group)) {
      return <div />;
    }

    const attributeGroupIds = group[0]?.attributes ? group[0]?.attributes : [];
    const key = attributeGroupIds[0];

    return (
      <div styleName="table-wrapper">
        <table>
          <thead>
            <tr>
              <th colSpan={attributeGroupIds.length + 1}>
                <div styleName="table-header">Summary</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr styleName="table-subheader left">
              <th>Remarks</th>
            </tr>
            <tr styleName="table-value">
              <td>
                {attributeDisplayUtility.display(
                  attributes[key].value,
                  attributes[key].dataType,
                  attributes[key].unit,
                  !!attributes[key].changeId,
                  attributes[key].newValue,
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const getTableAttributes = () => {
    const { attributeGroups, attributes } = workOrderAsset;
    const group = attributeGroups.filter(
      attributeGroup => attributeGroup.name === 'Assessment',
    );

    if (isNilOrEmpty(group)) {
      return <div />;
    }

    const attributeGroupIds = group[0]?.attributes ? group[0]?.attributes : [];

    return (
      <div styleName="table-wrapper">
        <table>
          <thead>
            <tr>
              <th colSpan={attributeGroupIds.length + 1}>
                <div styleName="table-header">Assessment Details</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr styleName="table-subheader">
              {attributeGroupIds.map(key => {
                const style =
                  attributes[key].name === 'Comment'
                    ? 'table-header-cell comment'
                    : 'table-header-cell';

                return <th styleName={style}>{attributes[key].name}</th>;
              })}
            </tr>
            <tr styleName="table-value">
              {attributeGroupIds.map(key => (
                <td>
                  {attributeDisplayUtility.display(
                    attributes[key].value,
                    attributes[key].dataType,
                    attributes[key].unit,
                    !!attributes[key].changeId,
                    attributes[key].newValue,
                  )}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div styleName="content">
      {getFullPathName(workOrderAsset.id)}
      <div>
        {!!photos.length && (
          <PhotoTile
            photos={photos}
            title={workOrderAsset.name}
            elements={[]}
            id={workOrderAsset.id}
            setOtherPhotosLoading={setOtherPhotosLoading}
          />
        )}
      </div>
      {getSummarySection()}
      {getTableAttributes()}
    </div>
  );
};

export default RootAssetInfo;
