/*
  This file references all of the the text constants that are reused throughout the
  application and are CONSTANT, meaning that they do not change in meaning and provide
  a single source of truth
*/
const textConstants = {
  GENERIC_APPLICATION_UPDATING_TEXT: 'Updating...',
  GENERIC_APPLICATION_DELETING_TEXT: 'Deleting...',
  GENERIC_APPLICATION_UPDATED_TEXT: 'Updated',
  MULTER_FILE_LIMIT_ERROR: 'LIMIT_FILE_SIZE',
  DEACTIVATE_USER_MODAL_COPY: `
    The user will not be able to log in to this tenant unless
    they are reactivated. Deactivated users do not get removed
    from any existing work but they cannot be added to work. Additionally,
    they will not be found in search or when filtering work by their name.
    Everything can be restored by reactivating the user.
  `,
};

export default textConstants;
