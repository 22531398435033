import React from 'react';
import {
  FormControl,
  FormControlProps as FormControlPropsType,
  FormHelperText,
  FormHelperTextProps as FormHelperTextPropsType,
  Input,
  InputLabel,
  InputLabelProps as InputLabelPropsType,
  InputProps as InputPropsType,
  Select as MUISelect,
  SelectProps as MUISelectProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as R from 'ramda';

import { Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';

import MenuItem from './MenuItem';

export interface SelectProps extends MUISelectProps {
  /**
   * style to be applied to the input container
   */
  style?: React.CSSProperties;
  /**
   * if input should be full width of container
   * defaults to true
   */
  fullWidth?: boolean;
  /**
   * Fix label to "floating" position (focused)
   * defaults to true
   */
  floatingLabelFixed?: boolean;
  /**
   * if select has error
   */
  error?: boolean;
  /**
   * helperText to be applied under the Select
   */
  helperText?: string;
  /**
   * if true, loading spinner is shown
   */
  loading?: boolean;
  /**
   * optional props to pass to InputLabel
   */
  InputLabelProps?: InputLabelPropsType;
  /**
   * optional props to pass to Input
   */
  InputProps?: InputPropsType;
  /**
   * optional props to pass to FormControl
   */
  FormControlProps?: FormControlPropsType;
  /**
   * optional props to pass to FormHelperText
   * if helperText prop is truthy
   */
  FormHelperTextProps?: FormHelperTextPropsType;
}

const getInputLabel = ({ floatingLabelFixed = true }: SelectProps): any => {
  return floatingLabelFixed
    ? { position: 'unset', color: colors.neutral.gray }
    : {
        color: colors.neutral.gray,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        bottom: '0px',
        maxWidth: '100%',
      };
};

const getInputLabelShrink = ({
  floatingLabelFixed = true,
}: SelectProps): any => {
  return floatingLabelFixed
    ? { transform: 'unset', fontSize: fonts.sm }
    : {
        fontSize: fonts.sm,
        overflow: 'unset',
        textOverflow: 'unset',
        whiteSpace: 'unset',
      };
};

const useStyles = makeStyles(theme => ({
  inputRoot: {
    fontSize: fonts.md,
  },
  inputUnderline: {
    '&:before': {
      borderBottom: `1px solid ${colors.neutral.silver}`,
    },
    '&:after': {
      borderBottom: `1px solid ${colors.neutral.silver}`,
    },
  },
  inputLabel: getInputLabel,
  inputLabelShrunk: getInputLabelShrink,
  inputLabelFocused: {
    color: `${colors.neutral.gray} !important`,
  },
  inputLabelError: {
    color: `${theme.palette.error.main} !important`,
  },
  helperText: {
    height: 0,
    marginTop: 0,
  },
  formControl: {
    'label + &': {
      marginTop: '0',
    },
  },
}));

const defaultStyle: React.CSSProperties = {
  width: '100%',
};

export const Select = (props: SelectProps) => {
  const classes = useStyles(R.omit(['classes'], props));

  const {
    style,
    helperText,
    multiple,
    input,
    disabled,
    loading = false,
    error = false,
    fullWidth = true,
    floatingLabelFixed = true,
    InputLabelProps = {},
    InputProps = {},
    FormControlProps = {},
    MenuProps = {},
    variant = 'standard',
    ...data
  } = props;

  return (
    <div style={{ ...defaultStyle, ...style }}>
      <FormControl fullWidth={fullWidth} error={error} {...FormControlProps}>
        <InputLabel
          {...InputLabelProps}
          variant={variant}
          classes={{
            root: classes.inputLabel,
            shrink: classes.inputLabelShrunk,
            focused: classes.inputLabelFocused,
            error: classes.inputLabelError,
            ...InputLabelProps?.classes,
          }}
          shrink={floatingLabelFixed}
        >
          {props.label}
        </InputLabel>
        <MUISelect
          {...data}
          multiple={multiple}
          disabled={disabled || loading}
          IconComponent={
            loading
              ? iconProps => <Progress {...iconProps} size={16} />
              : props.IconComponent
          }
          MenuProps={{
            ...(multiple && {
              // stops popover from changing position on select
              getContentAnchorEl: null,
            }),
            ...MenuProps,
          }}
          input={
            input || (
              <Input
                {...InputProps}
                classes={{
                  root: classes.inputRoot,
                  underline: classes.inputUnderline,
                  formControl: classes.formControl,
                  ...InputProps?.classes,
                }}
              />
            )
          }
        />
        {helperText && (
          <FormHelperText
            {...props.FormHelperTextProps}
            classes={{
              root: classes.helperText,
              ...props.FormHelperTextProps?.classes,
            }}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

Select.MenuItem = MenuItem;

export default Select;
