import naturalSort from 'natural-sort';
import * as R from 'ramda';

import {
  CondensedTimeEntryDetail,
  DateDictionary,
  TimeEntryDetail,
} from '@atom/types/timeEntry';

const sortTimeEntriesByUserGroupNameComparator = (
  firstElement,
  secondElement,
) => {
  const names = [firstElement.userGroup.name, secondElement.userGroup.name];
  const sortedNames = names.sort(naturalSort());

  return firstElement.userGroup.name === sortedNames[0] ? -1 : 1;
};

const sortTimeEntriesByBudgetsNameComparator = (
  firstElement,
  secondElement,
) => {
  const firstElementBudgetNames = firstElement.computedBudgets
    .map(budget => budget.name)
    .join(', ');

  const secondElementBudgetNames = secondElement.computedBudgets
    .map(budget => budget.name)
    .join(', ');

  const names = [firstElementBudgetNames, secondElementBudgetNames];
  const sortedNames = names.sort(naturalSort());

  return firstElementBudgetNames === sortedNames[0] ? -1 : 1;
};

// Sorts the provided timeEntries list by the
// passed in sortBy and sortDirection.
export const getSortedTimeEntries = (
  timeEntries: TimeEntryDetail[] | CondensedTimeEntryDetail[],
  sortBy: string,
): TimeEntryDetail[] | CondensedTimeEntryDetail[] => {
  switch (true) {
    case sortBy.includes('userGroup'): {
      const sorted = R.sort(
        sortTimeEntriesByUserGroupNameComparator,
        timeEntries,
      );
      return sortBy.includes('asc') ? sorted : sorted.reverse();
    }
    case sortBy.includes('budget'): {
      const sorted = R.sort(
        sortTimeEntriesByBudgetsNameComparator,
        timeEntries,
      );
      return sortBy.includes('asc') ? sorted : sorted.reverse();
    }
    default:
      return timeEntries;
  }
};

// Combines given date dictionary value with
// additional entries from the provided time entry.
export const getDateDictionary = (
  existingDateDictionary: DateDictionary,
  timeEntry: TimeEntryDetail,
): DateDictionary => {
  const existingTotalDuration = R.pathOr(
    0,
    [timeEntry.date, 'totalDuration'],
    existingDateDictionary,
  );

  const existingComputedBudgets = R.pathOr(
    [],
    [timeEntry.date, 'computedBudgets'],
    existingDateDictionary,
  );

  const mergedComputedBudgets = [
    ...existingComputedBudgets,
    ...timeEntry.computedBudgets,
  ].reduce((acc, budget) => {
    const existingDuration = R.pathOr(0, [budget.id, 'duration'], acc);

    return {
      ...acc,
      [budget.id]: {
        ...budget,
        duration: existingDuration + budget.duration,
      },
    };
  }, {});

  return {
    ...existingDateDictionary,
    [timeEntry.date]: {
      totalDuration: existingTotalDuration + timeEntry.duration,
      computedBudgets: R.values(mergedComputedBudgets),
    },
  };
};

// Combines timeEntries based on matching status, workTemplateId,
// workOrderId, taskId, userGroupId, and budgetIds.

// In place of a single date and duration, the newly minted
// CondensedTimeEntry has a date dictionary that contains totalDuration
// and combined computedBudgets array.

// The new id is also a comma separated string list of all the single
// timeEntries that were combined to make the new condensed timeEntry
export const getCondensedTimeEntries = (
  timeEntries: TimeEntryDetail[],
): CondensedTimeEntryDetail[] => {
  const condensedTimeEntriesDictionary = timeEntries.reduce(
    (acc, timeEntry) => {
      const status = timeEntry.status;
      const workTemplateId = timeEntry.workOrder.workTemplateId;
      const workOrderId = timeEntry.workOrder.id;
      const taskId = timeEntry.taskId;
      const userGroupId = timeEntry.userGroupId;
      const budgetIds = timeEntry.computedBudgets
        .map(budget => budget.id)
        .sort()
        .join('');

      // Creates a unique key to group all timeEntries that share status,
      // workTemplateId, workOrderId, taskId, userGroupId, and budgetIds
      const groupKey =
        status +
        workTemplateId +
        workOrderId +
        taskId +
        userGroupId +
        budgetIds;

      const existingId = acc[groupKey] ? acc[groupKey].id.split(',') : [];
      const existingDateDictionary = R.pathOr(
        {},
        ['dateDictionary'],
        acc[groupKey],
      );

      const condensedTimeEntry = {
        ...timeEntry,
        id: [...existingId, timeEntry.id].join(','),
        dateDictionary: getDateDictionary(existingDateDictionary, timeEntry),
      };

      const omittedProperties = [
        'date',
        'duration',
        'createdBy',
        'createdDate',
        'updatedBy',
        'updatedDate',
      ];

      return {
        ...acc,
        [groupKey]: R.omit(omittedProperties, condensedTimeEntry),
      };
    },
    {},
  );

  return R.values(condensedTimeEntriesDictionary);
};

// Table widths are designed to allow the timesheet table to fit within
// a 1440px screen monitor without scrolling. For larger monitors, the work
// column will grow while every other column remains the same width. All widths
// are used to set the width of the cell, work width is used to set a minWidth
export const timeSheetTableWidths = {
  status: '35px',
  workType: '90px',
  // work value is used as a minWidth
  work: '120px',
  task: '100px',
  userGroup: '100px',
  budget: '100px',
  small: '30px',
  action: '24px',
};
