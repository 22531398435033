import React, { useContext } from 'react';
import * as R from 'ramda';

import AddTeamModalContext from '@atom/components/common/workOrderDetail/addTeamModal/AddTeamModalContext';

import UserCartTile from './UserCartTile';
import UserGroupCartTile from './UserGroupCartTile';

import './addTeamModal.css';

const SelectedSection = () => {
  const { selectedUsers, selectedUserGroups } = useContext(AddTeamModalContext);

  const showSection =
    !R.isEmpty(selectedUsers) || !R.isEmpty(selectedUserGroups);

  return showSection ? (
    <div styleName="selected-container">
      <div styleName="selected-title">selected</div>
      {selectedUsers.map(user => (
        <UserCartTile key={user.id} user={user} />
      ))}
      {selectedUserGroups.map(userGroup => (
        <UserGroupCartTile key={userGroup.id} userGroup={userGroup} />
      ))}
    </div>
  ) : (
    <div />
  );
};

export default SelectedSection;
