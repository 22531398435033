import actionTypes from '@atom/actions/actionTypes';
import { replace } from '@atom/utilities/replaceUtilities';

import initialState from './initialState';

export default function mediaFoldersReducer(
  state = initialState.mediaFolders,
  action,
) {
  const { data, type } = action;
  switch (type) {
    case actionTypes.GET_MEDIA_FOLDERS_SUCCESS: {
      return data;
    }
    case actionTypes.CREATE_MEDIA_FOLDER_SUCCESS: {
      const { folderData } = data;
      const currentParentFolderId = state[0].parentFolderId || null;
      return currentParentFolderId === folderData.parentFolderId
        ? [...state, folderData]
        : state;
    }
    case actionTypes.UPDATE_MEDIA_FOLDER_SUCCESS: {
      const { folderData } = data;
      return replace(folder => folder.id === folderData.id, folderData, [
        ...state,
      ]);
    }
    case actionTypes.DELETE_MEDIA_FOLDER_SUCCESS: {
      const { folderId } = data;
      return state.filter(folder => folder.id !== folderId);
    }
    default: {
      return state;
    }
  }
}
