import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function workOrdersReducer(
  state = initialState.workOrders,
  action,
) {
  const { data, type } = action;
  switch (type) {
    case actionTypes.GET_WORK_ORDERS_SUCCESS: {
      return data ? [...data] : [];
    }
    case actionTypes.DELETE_WORK_ORDER_SUCCESS: {
      return state.filter(workOrder => workOrder.id !== data);
    }
    default: {
      return state;
    }
  }
}
