import * as R from 'ramda';

import { FullStoryUserAccount } from '@atom/types/user';

/**
 * The current user role.
 * This is set when the logged in user object is retrieved.
 */
export let currentUserRole = null;

export const setUserRole = (role: string): string => {
  return (currentUserRole = role);
};

/**
 * ROLES
 * To add a new role insert it below roles that have a higher permission.
 * Update the role immediately below to inherit properly.
 * Add the new role to the available ROLE_SETS.
 * Ex.
 * const ASSISTANT_MANAGER = [...MANAGER, 'AssistantManager'];
 * const INSPECTOR = [...ASSISTANT_MANAGER, 'Inspector'];
 * //
 * The role strings are exposed as properties here to minimize magic strings.
 */

const ADMIN_ROLE = 'Admin';
const ORGADMIN_ROLE = 'OrgAdmin';
const MANAGER_ROLE = 'Manager';
const INSPECTOR_ROLE = 'Inspector';
const READONLY_ROLE = 'ReadOnly';

const ADMIN = [ADMIN_ROLE];
const ORG_ADMIN = [...ADMIN, ORGADMIN_ROLE];
const MANAGER = [...ORG_ADMIN, MANAGER_ROLE];
const INSPECTOR = [...MANAGER, INSPECTOR_ROLE];

export const ROLE_SETS = {
  ADMIN,
  ORG_ADMIN,
  MANAGER,
  INSPECTOR,
};

export const ROLES = {
  ADMIN_ROLE,
  ORGADMIN_ROLE,
  MANAGER_ROLE,
  INSPECTOR_ROLE,
  READONLY_ROLE,
};

export let currentUserLevel = null;

export const setUserRoleLevel = (level: number): number => {
  return (currentUserLevel = level);
};

/**
 * @param {number} [testLevel] A user role level to test to the current user level against
 *
 * For example the INSPECTOR role level is 3,
 *
 * In this case, "Admin" and "Manager" are lower at 1 and 2.
 *
 * A call to hasSuperiorOrEqualUserLevel with 3 (Inspector) when the user is 3 (Inspector)
 * will return true.
 * A call to hasSuperiorOrEqualUserLevel with 2 (Manager) when the user is 3 (Inspector)
 * will return false.
 *
 * Usage: The deactivate button should be visible if the user has a superior or equal role
 *
 *  @returns {boolean} "true" if the user is at an equal or superior permission level.
 */

export const hasSuperiorOrEqualUserLevel = (testLevel: number): boolean => {
  return currentUserLevel <= testLevel;
};

/**
 * @param {number} [testLevel] A user role level to test to the current user level against
 *
 * For example the INSPECTOR role level is 3,
 *
 * In this case, "ReadOnly" is higher at 4.
 *
 * A call to hasInferiorUserLevel with 3 (Inspector) when the user is 4 (ReadOnly)
 * will return true.
 * A call to hasInferiorUserLevel with 3 (Inspector) when the user is 3 (Inspector)
 * will return false.
 *
 * Usage: We disable saving a button where the user does not have higher permissions that the viewed user's level
 *
 *  const disableSave = hasInferiorUserLevel(user.level);
 *  @returns {boolean} "true" if the user is at an equal or superior permission level.
 */

export const hasInferiorUserLevel = (testLevel: number): boolean => {
  return currentUserLevel > testLevel;
};

/**
 * @param {array} [roleSet] An array of unique roles that includes all roles that have
 * the request permission or greater.
 *
 * For example the INSPECTOR role set contains "Inspector",
 * and roles that have more elevated permissions.
 * In this case, "Admin" and "Manager".
 * So, INSPECTOR = ['Admin', 'Manager', 'Inspector'].
 *
 * A call to hasRolePermissions with ROLE_SETS.inspector will return true for:
 * 'Admin', 'Manager', or 'Inspector'.
 *
 * Usage: The Inventory Management button link should only be visible for "Admin" role.
 *
 * {hasRolePermissions(ROLE_SETS.ADMIN) && (
 *   <IconButton
 *     onClick={navigateToAdminInventoryManagement}
 *     iconClassName="material-icons"
 *     tooltip="Management"
 *     iconStyle={style.icon}
 *     children="perm_data_setting"
 *   />
 *  )}
 *  @returns {boolean} "true" if the user HAS the required permission.
 */
export const hasRolePermissions = (roleSet: string[]): boolean => {
  return roleSet.includes(currentUserRole);
};

/**
 * @param {array} [roleSet] An array of unique roles that includes all roles that have
 * the request permission or greater.
 *
 * The inverse of hasRolePermissions.
 * A call to doesNotHaveRolePermissions(ROLE_SETS.MANAGER) returns true for:
 * 'Inspector' or 'ReadOnly'.
 *
 * Usage: Summary section controls should be hidden if the work is closed
 * or the user is read only.
 *
 * const isReadOnly =
 *  workOrderIsClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);
 * {!isReadOnly && (
 *   <IconButton
 *     iconClassName="material-icons"
 *     iconStyle={headerButtonStyle.iconButtonIcon}
 *     style={headerButtonStyle.iconButton}
 *     onClick={() => setActiveView('create')}
 *     tooltip="Add Section"
 *     children="add"
 *   />
 * )}
 *  @returns {boolean} "true" if the user DOES NOT HAVE the required permission.
 */
export const doesNotHaveRolePermissions = (roleSet: string[]): boolean => {
  return !hasRolePermissions(roleSet);
};

export const setFullStoryAccount = (user: FullStoryUserAccount) => {
  // @ts-ignore
  if (!R.isNil(window.FS)) {
    // @ts-ignore
    window.FS.identify(user.id, {
      displayName: `${user.firstName} ${user.lastName}`,
      email: user.email,
    });
  }
};

export default {
  ROLES,
  ROLE_SETS,
  currentUserRole,
  setUserRole,
  hasRolePermissions,
  doesNotHaveRolePermissions,
  currentUserLevel,
  setFullStoryAccount,
  setUserRoleLevel,
  hasSuperiorOrEqualUserLevel,
  hasInferiorUserLevel,
};
