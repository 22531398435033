import React from 'react';

import {
  InventoryCategoryTree,
  InventorySchemaItem,
} from '@atom/types/inventory';

interface Context {
  schemas: InventorySchemaItem[];
  categoryTree: InventoryCategoryTree;
  loading: boolean;
  activeCategory: InventoryCategoryTree;
  setActiveCategory: (
    category: InventoryCategoryTree,
    activePath: any[],
  ) => void;
  activePath: any[];
  setActivePath: (path: any[]) => void;
  open: Set<string>;
  toggleOpen: (id: string) => void;
  setOpen: (open: Set<string>) => void;
  refetchCategoryFolderTree: () => Promise<InventoryCategoryTree>;
  selectedAssets: Set<string>;
  toggleSelectedAssets: (assetId: string) => void;
  resetSelectedAssets: () => void;
}

export const initialState: Context = {
  schemas: [],
  categoryTree: {
    id: 'inventory',
    name: 'Inventory',
  },
  loading: false,
  activeCategory: {
    id: 'inventory',
    name: 'Inventory',
  },
  setActiveCategory: () => {},
  activePath: [],
  setActivePath: () => {},
  open: new Set(['inventory']),
  toggleOpen: () => {},
  setOpen: () => {},
  // @ts-ignore
  refetchCategoryFolderTree: () => Promise.resolve({}),
  selectedAssets: new Set([]),
  toggleSelectedAssets: () => {},
  resetSelectedAssets: () => {},
};

const InventoryPortalContent = React.createContext<Context>(initialState);

export default InventoryPortalContent;
