import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import { getCiModuleScore } from '@atom/selectors/formInstanceSelectors';
import colors from '@atom/styles/colors';

import './ci.css';

export const getCiScoreColor = (score?: number) => {
  if (R.isNil(score)) {
    return colors.neutral.fog;
  }

  if (score >= 85) {
    return colors.ci.excellent;
  }

  if (score >= 60) {
    return colors.ci.good;
  }

  if (score >= 40) {
    return colors.ci.fair;
  }

  return colors.ci.poor;
};

const CiScore = () => {
  const score = useSelector(getCiModuleScore);

  return (
    <div styleName="score-container">
      <div styleName="score-title">CI Score</div>
      <div styleName="score" style={{ background: getCiScoreColor(score) }}>
        {!R.isNil(score) && score}
      </div>
    </div>
  );
};

export default CiScore;
