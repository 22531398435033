export const gwrrSchema = {
  poolAttributes: {
    conditionRatingId: '5b47d25e0220e700010dd107',
    boredAtId: '5b47d25e0220e700010dd10c',
    shellId: '5b47d25e0220e700010dd10d',
    voidId: '5b47d25e0220e700010dd10e',
    commentId: '5b47d25e0220e700010dd108',
    repairRecommendationId: '5b47d25e0220e700010dd109',
    priorityRatingId: '5b47d25e0220e700010dd10a',
    assessmentFlagId: '5b47d25e0220e700010dd10b',
  },
};
