import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import UserGroupIcon from '@atom/components/common/UserGroupIcon';
import EditTaskUserGroupModal from '@atom/components/common/workOrderDetail/editTaskUserGroupModal/EditTaskUserGroupModal';
import WorkOrderContext from '@atom/components/workOrderDetail/WorkOrderContext';
import { TASK_USER_GROUP_REMOVE } from '@atom/graph/task';
import { Icon, IconButton } from '@atom/mui';
import {
  Task,
  TaskUserGroupItem,
  TaskUserGroupRemoveInput,
} from '@atom/types/task';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import {
  convertMillisToExactHours,
  setWorkTimeDisplay,
} from '@atom/utilities/timeUtilities';

import './teamSection.css';

interface Props {
  userGroup: TaskUserGroupItem;
}

const TaskUserGroupTile = ({ userGroup }: Props) => {
  const { workOrderDetail, task, refetch } = useContext(WorkOrderContext);

  const [open, setOpen] = useState<boolean>(false);
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);

  const [taskUserGroupRemove] = useMutation<
    { taskUserGroupRemove: Task },
    { input: TaskUserGroupRemoveInput }
  >(TASK_USER_GROUP_REMOVE, {
    onCompleted: () => {
      refetch();
    },
  });

  const isEditDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.MANAGER);

  const removeUserGroup = async () => {
    await taskUserGroupRemove({
      variables: {
        input: {
          id: task.id,
          userGroupId: userGroup.userGroupId,
          workOrderId: workOrderDetail.id,
        },
      },
    });

    setRemoveModalOpen(false);
    refetch();
  };

  const usersLabel = userGroup.quantity === 1 ? 'User' : 'Users';

  const getTotalCost = () => {
    const workTime = convertMillisToExactHours(
      R.pathOr(0, ['workTime'], userGroup),
    );
    const quantity = R.pathOr(0, ['quantity'], userGroup);
    const rate = R.pathOr(0, ['budget', 'rate'], userGroup);

    return workTime * quantity * rate;
  };

  const totalCost = getTotalCost();

  return (
    <>
      <div styleName="user-container">
        <div
          styleName="user-content-container"
          onClick={() => setOpen(!workOrderDetail.isClosed)}
        >
          <UserGroupIcon colorId={userGroup.colorId} />
          <div styleName="user-details">
            <div styleName="name">{userGroup.name}</div>
            <div styleName="email">
              <div styleName="email">
                <span>{`${userGroup.quantity} ${usersLabel}`}</span>
                <span styleName="spacer">&#183;</span>
                <span>{setWorkTimeDisplay(userGroup.workTime)}</span>
                <span styleName="spacer">&#183;</span>
                <span>{numberToLocaleString(totalCost)}</span>
              </div>
            </div>
          </div>
        </div>
        {!isEditDisabled && (
          <IconButton
            onClick={() => setRemoveModalOpen(true)}
            tooltip="Remove"
            edge="end"
          >
            <Icon>close</Icon>
          </IconButton>
        )}
      </div>
      <EditTaskUserGroupModal
        open={open}
        onClose={() => setOpen(false)}
        userGroupId={userGroup.userGroupId}
        quantity={userGroup.quantity}
        workTime={userGroup?.workTime}
        selectedBudgetId={userGroup?.budget?.budgetId}
        workOrderDetail={workOrderDetail}
        task={task}
        refetch={refetch}
      />
      <DeleteModal
        open={removeModalOpen}
        onCancel={() => setRemoveModalOpen(false)}
        onConfirm={removeUserGroup}
        title="Remove User Group From Task"
        confirmText="Remove"
        content={
          <div>
            Are you sure you want to remove this user group from the task?
          </div>
        }
      />
    </>
  );
};

export default TaskUserGroupTile;
