import React, { useEffect, useState } from 'react';
import * as R from 'ramda';

import { AttributeGroupsItem } from '@atom/types/inventory';
import { MediaItem } from '@atom/types/media';
import { WorkOrderAssetTreeType } from '@atom/types/work';
import api from '@atom/utilities/api';
import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';
import { MEDIA_ENDPOINT } from '@atom/utilities/endpoints';
import { TOTAL_COUNT_HEADER } from '@atom/utilities/requestUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import ElementsSection from './ElementsSection';
import PhotoTile from './PhotoTile';

import '../workOrderDetailPdfReport.css';

const styles = {
  container: {
    marginBottom: '0.5rem',
  },
};

enum SubheaderNames {
  STRUCTURE = 'Structure',
  ELEMENT = 'Element',
}

enum AttributeGroupNames {
  ASSESSMENT = 'Assessment',
  GROUP_DETAILS = 'Group Details',
  REPAIR_HISTORY = 'Repair History',
}

const MEDIA_LIMIT = 250;

interface Props {
  isRootAsset: boolean;
  tree: WorkOrderAssetTreeType;
  workOrderId: string;
  lastTreeLoaded: boolean;
  getFullPathName: (id: string) => void;
  setOtherPhotosLoading: (currentLoading: { [id: string]: boolean }) => void;
}

const AttributeGroupTable = ({
  isRootAsset,
  tree,
  workOrderId,
  lastTreeLoaded,
  setOtherPhotosLoading,
  getFullPathName,
}: Props) => {
  const [photos, setPhotos] = useState<MediaItem[]>([]);

  const getTableHeader = (name, element) => {
    if (name === AttributeGroupNames.ASSESSMENT) {
      return `${element.name} ${name} Details`;
    }
    if (name === AttributeGroupNames.GROUP_DETAILS) {
      return name;
    }
    return element.name;
  };

  const getMediaPage = (page: number): Promise<MediaItem[]> => {
    return api
      .get<MediaItem[]>(MEDIA_ENDPOINT, {
        subjectTypes: 'workOrderAsset',
        parentSubjectId: workOrderId,
        limit: MEDIA_LIMIT,
        page,
      })
      .then(res => res.data);
  };

  useEffect(() => {
    const getMedia = async () => {
      const response = await api.get(MEDIA_ENDPOINT, {
        subjectTypes: 'workOrderAsset',
        parentSubjectId: workOrderId,
        page: 1,
        limit: MEDIA_LIMIT,
      });
      let totalPhotos = response.data;
      const total = Number(response.headers[TOTAL_COUNT_HEADER]);

      if (total > MEDIA_LIMIT) {
        const pages = Math.ceil(total / MEDIA_LIMIT);
        const promises: Array<Promise<MediaItem[]>> = [];

        for (let index = 2; index <= pages; index++) {
          promises.push(getMediaPage(index));
        }

        const allRemainingPhotos = await Promise.all(promises);
        totalPhotos = [...totalPhotos, ...R.flatten(allRemainingPhotos)];
      }

      const ids = [tree.id, ...tree.elements.map(each => each.id)];
      const filtered = totalPhotos.filter(file => ids.includes(file.subjectId));

      setPhotos(!!tree.elementGroups.length ? totalPhotos : filtered);

      if (lastTreeLoaded) {
        // @ts-ignore
        setOtherPhotosLoading(currentLoading => ({
          ...currentLoading,
          attributeGroupTable: false,
        }));
      }
    };
    getMedia();
  }, []);

  const getAttributeStructureValue = (element): string => {
    if (isNilOrEmpty(element.attributeGroups)) {
      return '';
    }

    const attributes = element.attributeGroups.reduce(
      (acc: string[], group: AttributeGroupsItem): string[] => {
        return [...acc, ...group.attributes];
      },
      [],
    );

    return attributes.reduce((acc: string, attributeId: string): string => {
      const attribute = element.attributes[attributeId];
      if (!attribute.isVisibleAsSubtext) {
        return acc;
      }

      const value = attributeDisplayUtility.display(
        attribute.value,
        attribute.dataType,
        attribute.unit,
      );

      return `${value}`;
    }, '');
  };

  const getTableAttributes = element => {
    return (
      <>
        <div>
          {!!photos.length && (
            <PhotoTile
              photos={photos}
              title={element.name}
              elements={element.elements}
              id={element.id}
              setOtherPhotosLoading={setOtherPhotosLoading}
            />
          )}
        </div>
        {element.attributeGroups.map(attributeGroup => {
          const attributeGroupIds = attributeGroup.attributes;

          if (attributeGroup.name === AttributeGroupNames.REPAIR_HISTORY) {
            return <div />;
          }

          return (
            <div styleName="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th colSpan={attributeGroupIds.length + 1}>
                      <div styleName="table-header">
                        {getTableHeader(attributeGroup.name, element)}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr styleName="table-subheader">
                    {
                      <th>
                        {attributeGroup.name === AttributeGroupNames.ASSESSMENT
                          ? SubheaderNames.STRUCTURE
                          : SubheaderNames.ELEMENT}
                      </th>
                    }
                    {attributeGroupIds.map(key => {
                      const style =
                        element.attributes[key].name === 'Comment'
                          ? 'table-header-cell comment'
                          : 'table-header-cell';

                      return (
                        <th styleName={style}>
                          {element.attributes[key].name}
                        </th>
                      );
                    })}
                  </tr>
                  <tr styleName="table-value">
                    <td>
                      {attributeGroup.name === AttributeGroupNames.ASSESSMENT
                        ? `${element.name} ${getAttributeStructureValue(
                            element,
                          )}`
                        : element.name}
                    </td>
                    {attributeGroupIds.map(key => (
                      <td>
                        {attributeDisplayUtility.display(
                          element.attributes[key].value,
                          element.attributes[key].dataType,
                          element.attributes[key].unit,
                          !!element.attributes[key].changeId,
                          element.attributes[key].newValue,
                        )}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          );
        })}
        <ElementsSection
          elements={element.elements}
          elementName={element.name}
        />
      </>
    );
  };

  return (
    <>
      <div styleName="content" style={styles.container}>
        {!isRootAsset && getFullPathName(tree.id)}

        {!!tree.elementGroups.length &&
          tree.elementGroups.map(elementGroup =>
            elementGroup.elements.map(element => getTableAttributes(element)),
          )}

        {!!tree.elements.length && getTableAttributes(tree)}

        {!tree.elements.length && !tree.elementGroups.length && (
          <>
            {!!photos.length && (
              <PhotoTile
                photos={photos}
                title={tree.name}
                elements={tree.elements}
                id={tree.id}
                setOtherPhotosLoading={setOtherPhotosLoading}
              />
            )}
            <ElementsSection elements={[tree]} elementName={tree.name} />
          </>
        )}
      </div>
    </>
  );
};

export default AttributeGroupTable;
