import * as React from 'react';

import { RadioGroup } from '@atom/mui';
import colors from '@atom/styles/colors';

import '../../formInstance.css';

const { RadioButton } = RadioGroup;

const styles = {
  label: {
    color: colors.neutral.dark,
    textTransform: 'capitalize',
  },
  leftControl: {
    marginTop: '0.5rem',
  },
  rightControl: {
    marginTop: '0.5rem',
    marginLeft: '4rem',
  },
};

interface PassedProps {
  type: string;
  value: any;
  isEditable: boolean;
  onChange: (event: any) => void;
}

type Props = PassedProps;

const BooleanAttribute = ({ type, value, onChange, isEditable }: Props) => {
  return (
    <div styleName="attribute-input">
      <RadioGroup
        style={{ display: 'flex' }}
        name={type}
        row
        defaultValue={value}
        onChange={onChange}
      >
        <RadioButton
          value="true"
          label="true"
          style={styles.label}
          disabled={!isEditable}
        />
        <RadioButton
          value="false"
          label="false"
          style={styles.label}
          disabled={!isEditable}
        />
      </RadioGroup>
    </div>
  );
};

export default BooleanAttribute;
