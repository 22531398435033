// @ts-ignore
import barrierAsset from '@atom/components/common/svgIcons/barrierAsset.svg';
// @ts-ignore
import barrierAssetActive from '@atom/components/common/svgIcons/barrierAssetActive.svg';
// @ts-ignore
import basinAsset from '@atom/components/common/svgIcons/basinAsset.svg';
// @ts-ignore
import basinAssetActive from '@atom/components/common/svgIcons/basinAssetActive.svg';
// @ts-ignore
import benchAsset from '@atom/components/common/svgIcons/benchAsset.svg';
// @ts-ignore
import benchAssetActive from '@atom/components/common/svgIcons/benchAssetActive.svg';
// @ts-ignore
import bikeAsset from '@atom/components/common/svgIcons/bikeAsset.svg';
// @ts-ignore
import bikeAssetActive from '@atom/components/common/svgIcons/bikeAssetActive.svg';
// @ts-ignore
import boatAsset from '@atom/components/common/svgIcons/boatAsset.svg';
// @ts-ignore
import boatAssetActive from '@atom/components/common/svgIcons/boatAssetActive.svg';
// @ts-ignore
import bollardsAsset from '@atom/components/common/svgIcons/bollardsAsset.svg';
// @ts-ignore
import bollardsAssetActive from '@atom/components/common/svgIcons/bollardsAssetActive.svg';
// @ts-ignore
import bookmarkAsset from '@atom/components/common/svgIcons/bookmarkAsset.svg';
// @ts-ignore
import bookmarkAssetActive from '@atom/components/common/svgIcons/bookmarkAssetActive.svg';
// @ts-ignore
import bridgeAsset from '@atom/components/common/svgIcons/bridgeAsset.svg';
// @ts-ignore
import bridgeAssetActive from '@atom/components/common/svgIcons/bridgeAssetActive.svg';
// @ts-ignore
import brushAsset from '@atom/components/common/svgIcons/brushAsset.svg';
// @ts-ignore
import brushAssetActive from '@atom/components/common/svgIcons/brushAssetActive.svg';
// @ts-ignore
import buildingAsset from '@atom/components/common/svgIcons/buildingAsset.svg';
// @ts-ignore
import buildingAssetActive from '@atom/components/common/svgIcons/buildingAssetActive.svg';
// @ts-ignore
import bulbAsset from '@atom/components/common/svgIcons/bulbAsset.svg';
// @ts-ignore
import bulbAssetActive from '@atom/components/common/svgIcons/bulbAssetActive.svg';
// @ts-ignore
import bumpAsset from '@atom/components/common/svgIcons/bumpAsset.svg';
// @ts-ignore
import bumpAssetActive from '@atom/components/common/svgIcons/bumpAssetActive.svg';
// @ts-ignore
import busAsset from '@atom/components/common/svgIcons/busAsset.svg';
// @ts-ignore
import busAssetActive from '@atom/components/common/svgIcons/busAssetActive.svg';
// @ts-ignore
import coneAsset from '@atom/components/common/svgIcons/coneAsset.svg';
// @ts-ignore
import coneAssetActive from '@atom/components/common/svgIcons/coneAssetActive.svg';
// @ts-ignore
import countAsset from '@atom/components/common/svgIcons/countAsset.svg';
// @ts-ignore
import countAssetActive from '@atom/components/common/svgIcons/countAssetActive.svg';
// @ts-ignore
import crossAsset from '@atom/components/common/svgIcons/crossAsset.svg';
// @ts-ignore
import crossAssetActive from '@atom/components/common/svgIcons/crossAssetActive.svg';
// @ts-ignore
import culvertAsset from '@atom/components/common/svgIcons/culvertAsset.svg';
// @ts-ignore
import culvertAssetActive from '@atom/components/common/svgIcons/culvertAssetActive.svg';
// @ts-ignore
import curbAsset from '@atom/components/common/svgIcons/curbAsset.svg';
// @ts-ignore
import curbAssetActive from '@atom/components/common/svgIcons/curbAssetActive.svg';
// @ts-ignore
import deviceAsset from '@atom/components/common/svgIcons/deviceAsset.svg';
// @ts-ignore
import deviceAssetActive from '@atom/components/common/svgIcons/deviceAssetActive.svg';
// @ts-ignore
import diamondAsset from '@atom/components/common/svgIcons/diamondAsset.svg';
// @ts-ignore
import diamondAssetActive from '@atom/components/common/svgIcons/diamondAssetActive.svg';
// @ts-ignore
import ditchAsset from '@atom/components/common/svgIcons/ditchAsset.svg';
// @ts-ignore
import ditchAssetActive from '@atom/components/common/svgIcons/ditchAssetActive.svg';
// @ts-ignore
import dotAsset from '@atom/components/common/svgIcons/dotAsset.svg';
// @ts-ignore
import dotAssetActive from '@atom/components/common/svgIcons/dotAssetActive.svg';
// @ts-ignore
import downAsset from '@atom/components/common/svgIcons/downAsset.svg';
// @ts-ignore
import downAssetActive from '@atom/components/common/svgIcons/downAssetActive.svg';
// @ts-ignore
import fanAsset from '@atom/components/common/svgIcons/fanAsset.svg';
// @ts-ignore
import fanAssetActive from '@atom/components/common/svgIcons/fanAssetActive.svg';
// @ts-ignore
import fiberAsset from '@atom/components/common/svgIcons/fiberAsset.svg';
// @ts-ignore
import fiberAssetActive from '@atom/components/common/svgIcons/fiberAssetActive.svg';
// @ts-ignore
import flowerAsset from '@atom/components/common/svgIcons/flowerAsset.svg';
// @ts-ignore
import flowerAssetActive from '@atom/components/common/svgIcons/flowerAssetActive.svg';
// @ts-ignore
import genericAsset from '@atom/components/common/svgIcons/genericAsset.svg';
// @ts-ignore
import genericAssetActive from '@atom/components/common/svgIcons/genericAssetActive.svg';
// @ts-ignore
import heartAsset from '@atom/components/common/svgIcons/heartAsset.svg';
// @ts-ignore
import heartAssetActive from '@atom/components/common/svgIcons/heartAssetActive.svg';
// @ts-ignore
import heightAsset from '@atom/components/common/svgIcons/heightAsset.svg';
// @ts-ignore
import heightAssetActive from '@atom/components/common/svgIcons/heightAssetActive.svg';
// @ts-ignore
import holeAsset from '@atom/components/common/svgIcons/holeAsset.svg';
// @ts-ignore
import holeAssetActive from '@atom/components/common/svgIcons/holeAssetActive.svg';
// @ts-ignore
import houseAsset from '@atom/components/common/svgIcons/houseAsset.svg';
// @ts-ignore
import houseAssetActive from '@atom/components/common/svgIcons/houseAssetActive.svg';
// @ts-ignore
import kmlLineBlue from '@atom/components/common/svgIcons/kmlLineBlue.svg';
// @ts-ignore
import kmlLineRed from '@atom/components/common/svgIcons/kmlLineRed.svg';
// @ts-ignore
import kmlLineYellow from '@atom/components/common/svgIcons/kmlLineYellow.svg';
// @ts-ignore
import kmlShapePurple from '@atom/components/common/svgIcons/kmlShapePurple.svg';
// @ts-ignore
import kmlShapeRed from '@atom/components/common/svgIcons/kmlShapeRed.svg';
// @ts-ignore
import kmlVehicleBlue from '@atom/components/common/svgIcons/kmlVehicleBlue.svg';
// @ts-ignore
import kmlVehicleGray from '@atom/components/common/svgIcons/kmlVehicleGray.svg';
// @ts-ignore
import linesAsset from '@atom/components/common/svgIcons/linesAsset.svg';
// @ts-ignore
import linesAssetActive from '@atom/components/common/svgIcons/linesAssetActive.svg';
// @ts-ignore
import liquidAsset from '@atom/components/common/svgIcons/liquidAsset.svg';
// @ts-ignore
import liquidAssetActive from '@atom/components/common/svgIcons/liquidAssetActive.svg';
// @ts-ignore
import medianAsset from '@atom/components/common/svgIcons/medianAsset.svg';
// @ts-ignore
import medianAssetActive from '@atom/components/common/svgIcons/medianAssetActive.svg';
// @ts-ignore
import paintAsset from '@atom/components/common/svgIcons/paintAsset.svg';
// @ts-ignore
import paintAssetActive from '@atom/components/common/svgIcons/paintAssetActive.svg';
// @ts-ignore
import pavementAsset from '@atom/components/common/svgIcons/pavementAsset.svg';
// @ts-ignore
import pavementAssetActive from '@atom/components/common/svgIcons/pavementAssetActive.svg';
// @ts-ignore
import questionAsset from '@atom/components/common/svgIcons/questionAsset.svg';
// @ts-ignore
import questionAssetActive from '@atom/components/common/svgIcons/questionAssetActive.svg';
// @ts-ignore
import railroadAsset from '@atom/components/common/svgIcons/railroadAsset.svg';
// @ts-ignore
import railroadAssetActive from '@atom/components/common/svgIcons/railroadAssetActive.svg';
// @ts-ignore
import signAsset from '@atom/components/common/svgIcons/signAsset.svg';
// @ts-ignore
import signAssetActive from '@atom/components/common/svgIcons/signAssetActive.svg';
// @ts-ignore
import squareAsset from '@atom/components/common/svgIcons/squareAsset.svg';
// @ts-ignore
import squareAssetActive from '@atom/components/common/svgIcons/squareAssetActive.svg';
// @ts-ignore
import starAsset from '@atom/components/common/svgIcons/starAsset.svg';
// @ts-ignore
import starAssetActive from '@atom/components/common/svgIcons/starAssetActive.svg';
// @ts-ignore
import sunAsset from '@atom/components/common/svgIcons/sunAsset.svg';
// @ts-ignore
import sunAssetActive from '@atom/components/common/svgIcons/sunAssetActive.svg';
// @ts-ignore
import trafficAsset from '@atom/components/common/svgIcons/trafficAsset.svg';
// @ts-ignore
import trafficAssetActive from '@atom/components/common/svgIcons/trafficAssetActive.svg';
// @ts-ignore
import treeAsset from '@atom/components/common/svgIcons/treeAsset.svg';
// @ts-ignore
import treeAssetActive from '@atom/components/common/svgIcons/treeAssetActive.svg';
// @ts-ignore
import upAsset from '@atom/components/common/svgIcons/upAsset.svg';
// @ts-ignore
import upAssetActive from '@atom/components/common/svgIcons/upAssetActive.svg';
// @ts-ignore
import user from '@atom/components/common/svgIcons/user.svg';
// @ts-ignore
import userActive from '@atom/components/common/svgIcons/userActive.svg';
// @ts-ignore
import walkAsset from '@atom/components/common/svgIcons/walkAsset.svg';
// @ts-ignore
import walkAssetActive from '@atom/components/common/svgIcons/walkAssetActive.svg';
// @ts-ignore
import wallAsset from '@atom/components/common/svgIcons/wallAsset.svg';
// @ts-ignore
import wallAssetActive from '@atom/components/common/svgIcons/wallAssetActive.svg';
// @ts-ignore
import warningAsset from '@atom/components/common/svgIcons/warningAsset.svg';
// @ts-ignore
import warningAssetActive from '@atom/components/common/svgIcons/warningAssetActive.svg';
// @ts-ignore
import waterAsset from '@atom/components/common/svgIcons/waterAsset.svg';
// @ts-ignore
import waterAssetActive from '@atom/components/common/svgIcons/waterAssetActive.svg';
// @ts-ignore
import weatherAsset from '@atom/components/common/svgIcons/weatherAsset.svg';
// @ts-ignore
import weatherAssetActive from '@atom/components/common/svgIcons/weatherAssetActive.svg';
// @ts-ignore
import workOrderAssigned from '@atom/components/common/svgIcons/workOrderAssigned.svg';
// @ts-ignore
import workOrderAssignedActive from '@atom/components/common/svgIcons/workOrderAssignedActive.svg';
// @ts-ignore
import workOrderClosed from '@atom/components/common/svgIcons/workOrderClosed.svg';
// @ts-ignore
import workOrderClosedActive from '@atom/components/common/svgIcons/workOrderClosedActive.svg';
// @ts-ignore
import workOrderCompleted from '@atom/components/common/svgIcons/workOrderCompleted.svg';
// @ts-ignore
import workOrderCompletedActive from '@atom/components/common/svgIcons/workOrderCompletedActive.svg';
// @ts-ignore
import workOrderInProgress from '@atom/components/common/svgIcons/workOrderInProgress.svg';
// @ts-ignore
import workOrderInProgressActive from '@atom/components/common/svgIcons/workOrderInProgressActive.svg';
// @ts-ignore
import workOrderInReview from '@atom/components/common/svgIcons/workOrderInReview.svg';
// @ts-ignore
import workOrderInReviewActive from '@atom/components/common/svgIcons/workOrderInReviewActive.svg';
// @ts-ignore
import workOrderRequested from '@atom/components/common/svgIcons/workOrderRequested.svg';
// @ts-ignore
import workOrderRequestedActive from '@atom/components/common/svgIcons/workOrderRequestedActive.svg';
// @ts-ignore
import workOrderUnassigned from '@atom/components/common/svgIcons/workOrderUnassigned.svg';
// @ts-ignore
import workOrderUnassignedActive from '@atom/components/common/svgIcons/workOrderUnassignedActive.svg';

const assetMarkers = [
  bridgeAsset,
  linesAsset,
  downAsset,
  upAsset,
  dotAsset,
  squareAsset,
  diamondAsset,
  heartAsset,
  genericAsset,
  bookmarkAsset,
  signAsset,
  treeAsset,
  waterAsset,
  benchAsset,
  deviceAsset,
  fanAsset,
  questionAsset,
  fiberAsset,
  liquidAsset,
  pavementAsset,
  barrierAsset,
  flowerAsset,
  warningAsset,
  starAsset,
  coneAsset,
  bulbAsset,
  trafficAsset,
  houseAsset,
  crossAsset,
  buildingAsset,
  busAsset,
  countAsset,
  walkAsset,
  medianAsset,
  bollardsAsset,
  sunAsset,
  bumpAsset,
  heightAsset,
  railroadAsset,
  curbAsset,
  boatAsset,
  weatherAsset,
  paintAsset,
  holeAsset,
  ditchAsset,
  wallAsset,
  bikeAsset,
  brushAsset,
  basinAsset,
  culvertAsset,
];

const activeAssetMarkers = [
  bridgeAssetActive,
  linesAssetActive,
  downAssetActive,
  upAssetActive,
  dotAssetActive,
  squareAssetActive,
  diamondAssetActive,
  heartAssetActive,
  genericAssetActive,
  bookmarkAssetActive,
  signAssetActive,
  treeAssetActive,
  waterAssetActive,
  benchAssetActive,
  deviceAssetActive,
  fanAssetActive,
  questionAssetActive,
  fiberAssetActive,
  liquidAssetActive,
  pavementAssetActive,
  barrierAssetActive,
  flowerAssetActive,
  warningAssetActive,
  starAssetActive,
  coneAssetActive,
  bulbAssetActive,
  trafficAssetActive,
  houseAssetActive,
  crossAssetActive,
  buildingAssetActive,
  busAssetActive,
  countAssetActive,
  walkAssetActive,
  medianAssetActive,
  bollardsAssetActive,
  sunAssetActive,
  bumpAssetActive,
  heightAssetActive,
  railroadAssetActive,
  curbAssetActive,
  boatAssetActive,
  weatherAssetActive,
  paintAssetActive,
  holeAssetActive,
  ditchAssetActive,
  wallAssetActive,
  bikeAssetActive,
  brushAssetActive,
  basinAssetActive,
  culvertAssetActive,
];

const kmlMarkers = [
  kmlVehicleBlue,
  kmlVehicleGray,
  kmlShapePurple,
  kmlLineBlue,
  kmlShapeRed,
  kmlLineRed,
  kmlLineYellow,
];

const getInactiveAssetIconFromSchemaMarkerId = markerId => {
  return assetMarkers[markerId] || genericAsset;
};

const getActiveAssetIconFromSchemaMarkerId = markerId => {
  return activeAssetMarkers[markerId] || genericAssetActive;
};

const getWorkOrderSvg = (status: any, isActive: boolean) => {
  const inactiveSvgIcons = [
    workOrderRequested,
    workOrderUnassigned,
    workOrderAssigned,
    workOrderInProgress,
    workOrderCompleted,
    workOrderClosed,
    workOrderInReview,
  ];

  const activeSvgIcons = [
    workOrderRequestedActive,
    workOrderUnassignedActive,
    workOrderAssignedActive,
    workOrderInProgressActive,
    workOrderCompletedActive,
    workOrderClosedActive,
    workOrderInReviewActive,
  ];

  return isActive
    ? status >= activeSvgIcons.length
      ? activeSvgIcons[0]
      : activeSvgIcons[status]
    : status >= inactiveSvgIcons.length
    ? inactiveSvgIcons[0]
    : inactiveSvgIcons[status];
};

const getUserSvg = (isActive: boolean) => (isActive ? userActive : user);

const getKmlSvg = (markerId: number) => kmlMarkers[markerId] || null;

const markerUtilities = {
  assetMarkers,
  getActiveAssetIconFromSchemaMarkerId,
  getInactiveAssetIconFromSchemaMarkerId,
  getWorkOrderSvg,
  getUserSvg,
  getKmlSvg,
};

export default markerUtilities;
