import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { ATTRIBUTE_UPDATE } from '@atom/graph/schema';
import { Icon, IconButton } from '@atom/mui';
import { AttributeUpdateInput, SchemaTree } from '@atom/types/schema';

import EditItemModal from '../EditItemModal';
import { updatePendingCreation } from '../subItemDetail/subItemDetailUtilities';

import './attributeDetail.css';

const AttributeDetailHeader = () => {
  const {
    schemaTree,
    selectedAttribute,
    setSelectedAttribute,
    selectedSubItem,
    selectedAttributeRoute,
    refetchSchemaTree,
    setPendingCreations,
  } = useContext(SchemaDetailContext);

  const [openAttributeEdit, setOpenAttributeEdit] = useState<boolean>(false);

  const [updateAttribute] = useMutation<
    { attributeUpdate: SchemaTree },
    { input: AttributeUpdateInput }
  >(ATTRIBUTE_UPDATE);

  // If attribute is a temp attribute, values must be updated in pendingCreations state
  const handlePendingCreationUpdate = (name: string) => {
    const updatedAttribute = { ...selectedAttribute, name };

    setPendingCreations(prev =>
      updatePendingCreation(
        selectedSubItem.id,
        selectedAttributeRoute,
        prev,
        updatedAttribute,
      ),
    );

    setSelectedAttribute(updatedAttribute);
  };

  const handleAttributeEdit = async (name: string) => {
    if (selectedAttribute.isTempAttribute) {
      handlePendingCreationUpdate(name);
    } else {
      await updateAttribute({
        variables: {
          input: {
            schemaId: selectedSubItem.id,
            attributeGroupId: selectedAttributeRoute.attributeGroupId,
            attributeId: selectedAttribute.id,
            name,
          },
        },
      });

      await refetchSchemaTree();
    }

    setOpenAttributeEdit(false);
  };

  const showEditButton =
    !schemaTree.isPublished || selectedAttribute.isTempAttribute;

  return (
    <>
      <div styleName="attribute-header-container">
        <div styleName="attribute-header-text">{selectedAttribute?.name}</div>
        {showEditButton && (
          <IconButton
            onClick={() => setOpenAttributeEdit(true)}
            size="small"
            tooltip="Rename"
          >
            <Icon>edit</Icon>
          </IconButton>
        )}
      </div>
      <EditItemModal
        name={selectedAttribute.name}
        type="Attribute"
        handleSave={handleAttributeEdit}
        open={openAttributeEdit}
        closeModal={() => setOpenAttributeEdit(false)}
      />
    </>
  );
};

export default AttributeDetailHeader;
