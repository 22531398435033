import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import * as inventoryExportActions from '@atom/actions/inventoryExportActions';
import { getSelectedSchemaId } from '@atom/selectors/inventoryExportSelectors';
import {
  InventoryFullCategoryTreeType,
  InventorySchemaItem,
} from '@atom/types/inventory';
import { ExportTemplateDetailType } from '@atom/types/inventoryExport';
import { fullCategoryLeftTreeIcon } from '@atom/utilities/iconUtilities';

import CategoryTreeRow from './CategoryTreeRow';

import './inventoryExportDetail.css';

interface Props {
  categoryTree: InventoryFullCategoryTreeType;
  rootSchemas: InventorySchemaItem[];
  exportTemplateDetail: ExportTemplateDetailType;
  updateSelectedSchema: (schemaId: string) => void;
}

const CategoryPane = ({
  categoryTree,
  rootSchemas,
  exportTemplateDetail,
  updateSelectedSchema,
}: Props) => {
  const dispatch = useDispatch();
  const [selectedCategoryIds, setCategoryIds] = useState(
    new Set(exportTemplateDetail.categoryIds),
  );

  const [selectedSchemaId, setSchemaId] = useState(() => {
    const schemaId = getSelectedSchemaId(categoryTree, exportTemplateDetail);
    updateSelectedSchema(schemaId);
    return schemaId;
  });

  const addCategoryId = (categoryId: string, schemaId: string) => {
    const updatedCategoryIds = [...selectedCategoryIds.add(categoryId)];

    setCategoryIds(new Set(updatedCategoryIds));
    setSchemaId(schemaId);
    updateSelectedSchema(schemaId);

    dispatch(
      inventoryExportActions.requestExportTemplateUpdate({
        exportTemplateId: exportTemplateDetail.id,
        categoryIds: updatedCategoryIds,
        rootSchemaId: schemaId,
      }),
    );
  };

  const removeCategoryId = (categoryId: string) => {
    selectedCategoryIds.delete(categoryId);
    const updatedCategoryIds = [...selectedCategoryIds];

    setCategoryIds(new Set(updatedCategoryIds));

    if (R.isEmpty(updatedCategoryIds)) {
      setSchemaId('');
      updateSelectedSchema('');
    }

    dispatch(
      inventoryExportActions.requestExportTemplateUpdate({
        exportTemplateId: exportTemplateDetail.id,
        categoryIds: updatedCategoryIds,
      }),
    );
  };

  const updateCategoryIds = (categoryId: string, schemaId: string) => {
    return selectedCategoryIds.has(categoryId)
      ? removeCategoryId(categoryId)
      : addCategoryId(categoryId, schemaId);
  };

  const leftIcon = fullCategoryLeftTreeIcon();

  return (
    <div styleName="section-pane">
      <div styleName="pane-header">Select Inventory Folder(s)</div>
      <div styleName="item-tree-container">
        <div styleName="row">
          {leftIcon}
          <div styleName="category-row-name">{categoryTree.name}</div>
        </div>
        {categoryTree.children ? (
          categoryTree.children.map(
            (category: InventoryFullCategoryTreeType) => {
              return (
                <CategoryTreeRow
                  key={category.id}
                  category={category}
                  indentation={1}
                  rootSchemas={rootSchemas}
                  updateCategoryIds={updateCategoryIds}
                  selectedCategoryIds={selectedCategoryIds}
                  selectedSchemaId={selectedSchemaId}
                />
              );
            },
          )
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default CategoryPane;
