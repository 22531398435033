import * as React from 'react';

import {
  getWorkOrderStatusColor,
  getWorkOrderStatusDescription,
} from '@atom/utilities/workOrderStatusUtilities';

import './workOrderStatusPill.css';

interface Props {
  statusId: string | number;
  className:
    | ''
    | 'table'
    | 'detail'
    | 'bulk-modal'
    | 'detail-pane'
    | 'dashboard-map-list';
  style?: React.CSSProperties;
}

const WorkOrderStatusPill = ({ statusId, className, style }: Props) => {
  const pillStyle = {
    backgroundColor: getWorkOrderStatusColor(statusId),
  };

  const componentClassName = className ? className : '';

  return (
    <span
      styleName={`work-order-status-pill ${componentClassName}`}
      style={{ ...pillStyle, ...style }}
    >
      {getWorkOrderStatusDescription(statusId)}
    </span>
  );
};

export default WorkOrderStatusPill;
