import axios, { CancelToken } from 'axios';

import { interceptAxiosError } from './interceptors';
import { DEFAULT_HEADERS as headers } from './requestUtilities';

axios.interceptors.response.use(
  response => response,
  error => {
    interceptAxiosError({ ...error });
    return Promise.reject(error);
  },
);

// TODO: [ATOM-3084] Move into common NPM Utility and import instead
export function get<T = any>(
  endpoint: string,
  params?: Object,
  cancelToken?: CancelToken,
) {
  return axios.get<T>(endpoint, {
    headers,
    ...(params ? { params } : {}),
    ...(cancelToken ? { cancelToken } : {}),
  });
}

export function head<T = any>(endpoint: string, params?: Object) {
  return axios.head<T>(endpoint, {
    headers,
    ...(params ? { params } : {}),
  });
}

export function put<T = any>(endpoint: string, data?: Object, params?: Object) {
  return axios.put<T>(endpoint, data, {
    headers,
    ...(params ? { params } : {}),
  });
}

export function post<T = any>(
  endpoint: string,
  data?: Object,
  params?: Object,
  cancelToken?: CancelToken,
) {
  return axios.post<T>(endpoint, data, {
    headers,
    ...(params ? { params } : {}),
    ...(cancelToken ? { cancelToken } : {}),
  });
}

export function patch<T = any>(
  endpoint: string,
  data?: Object,
  params?: Object,
) {
  return axios.patch<T>(endpoint, data, {
    headers,
    ...(params ? { params } : {}),
  });
}

export function deleteApi<T = any>(endpoint: string, params?: Object) {
  return axios.delete<T>(endpoint, {
    headers,
    ...(params ? { params } : {}),
  });
}

export default {
  get,
  head,
  put,
  post,
  patch,
  delete: deleteApi,
};
