import React, { useEffect, useState } from 'react';
import * as R from 'ramda';

import { MediaItem } from '@atom/types/media';
import api from '@atom/utilities/api';
import { FILES_ENDPOINT, MEDIA_ENDPOINT } from '@atom/utilities/endpoints';
import { TOTAL_COUNT_HEADER } from '@atom/utilities/requestUtilities';

import '../workOrderDetailPdfReport.css';

const MEDIA_LIMIT = 250;

const styles = {
  photoName: {
    width: '27rem',
    margin: '0 auto',
    textAlign: 'center',
  },
};

const sortByName = (first: any, second: any): number =>
  first.name.localeCompare(second.name, undefined, { numeric: true });

interface Props {
  workOrderId: string;
  setOtherPhotosLoading: (currentLoading: { [id: string]: boolean }) => void;
}

const PhotosBox = ({ workOrderId, setOtherPhotosLoading }: Props) => {
  const [photos, setPhotos] = useState<MediaItem[]>([]);

  const getMediaPage = (page: number): Promise<MediaItem[]> => {
    return api
      .get<MediaItem[]>(MEDIA_ENDPOINT, {
        parentSubjectId: workOrderId,
        type: 'image',
        limit: MEDIA_LIMIT,
        page,
      })
      .then(res => res.data);
  };

  useEffect(() => {
    const getMedia = async () => {
      const response = await api.get(MEDIA_ENDPOINT, {
        parentSubjectId: workOrderId,
        type: 'image',
        page: 1,
        limit: MEDIA_LIMIT,
      });
      let totalPhotos = response.data;
      const total = Number(response.headers[TOTAL_COUNT_HEADER]);

      if (total > MEDIA_LIMIT) {
        const pages = Math.ceil(total / MEDIA_LIMIT);
        const promises: Array<Promise<MediaItem[]>> = [];

        for (let index = 2; index <= pages; index++) {
          promises.push(getMediaPage(index));
        }

        const allRemainingPhotos = await Promise.all(promises);
        totalPhotos = [...totalPhotos, ...R.flatten(allRemainingPhotos)];
      }

      const sortedPhotos = R.sort(sortByName, totalPhotos);
      setPhotos(sortedPhotos);
      // @ts-ignore
      setOtherPhotosLoading(currentLoading => ({
        ...currentLoading,
        photoBox: false,
      }));
    };
    getMedia();
  }, []);

  useEffect(() => {
    if (photos.length) {
      photos.forEach(photo => {
        // @ts-ignore
        setOtherPhotosLoading(currentLoading => ({
          ...currentLoading,
          [photo.id]: true,
        }));
      });
    }
  }, [photos]);

  return (
    <>
      {!!photos.length && (
        <>
          <div styleName="page-break" />
          <table styleName="collapse-border">
            <thead>
              <tr>
                <th styleName="textbox-table-title">Photos</th>
              </tr>
            </thead>
            <tbody styleName="content">
              <div styleName="photos-container">
                {photos.map(photo => (
                  <div>
                    <div styleName="photo-tile idl" key={photo.id}>
                      <span styleName="img-helper" />
                      <img
                        styleName="img"
                        src={`${FILES_ENDPOINT}/${photo.fileId}`}
                        onLoad={() => {
                          // @ts-ignore
                          setOtherPhotosLoading(loadingState => ({
                            ...loadingState,
                            [photo.id]: false,
                          }));
                        }}
                      />
                    </div>
                    <div style={styles.photoName}>{photo.name}</div>
                  </div>
                ))}
              </div>
            </tbody>
          </table>
        </>
      )}
    </>
  );
};

export default PhotosBox;
