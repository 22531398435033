import React from 'react';
import * as R from 'ramda';

import { MediaItem } from '@atom/types/media';

import PhotoBlock from './PhotoBlock';
import { sortReportMedia } from './scdotReportUtilities';

import './scdotPdfReport.css';

interface Props {
  media: MediaItem[];
  mainPhotoId?: string;
}

const PhotoSection = ({ media, mainPhotoId }: Props) => {
  const sortedMedia = sortReportMedia(media, mainPhotoId);
  const groupedMedia = R.splitEvery(2, sortedMedia);

  return (
    <>
      {groupedMedia.map((items, index) => (
        <div styleName="photos-section" key={index}>
          <div styleName="photos-section-title">Photos</div>
          {items.map(medium => (
            <PhotoBlock key={medium?.id} medium={medium} />
          ))}
        </div>
      ))}
    </>
  );
};

export default PhotoSection;
