import React from 'react';

import { Modal } from '@atom/mui';

import './elements.css';

interface Props {
  title: string;
  assetName: string;
  open: boolean;
  closeModal: () => any;
}

const NoElementsDialog = ({ title, assetName, open, closeModal }: Props) => {
  return (
    <div>
      <Modal
        title={title}
        open={open}
        onCancel={closeModal}
        cancelButtonText="OK"
      >
        There are no elements under
        <span styleName="element-modal-asset-name"> {assetName}</span>
      </Modal>
    </div>
  );
};

export default NoElementsDialog;
