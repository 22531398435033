import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import debounce from 'lodash.debounce';

import WorkOrderContext, {
  WorkOrderActionTypes,
} from '@atom/components/workOrderDetail/WorkOrderContext';
import { TASK_UPDATE } from '@atom/graph/task';
import { Icon, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { Task, TaskUpdateInput } from '@atom/types/task';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';

import './taskDetailView.css';

const DEBOUNCE_TIME = 1000;

const styles = {
  icon: {
    paddingTop: '0.5rem',
  },
};

const TaskDescription = () => {
  const { workOrderDetail, task, dispatch } = useContext(WorkOrderContext);

  const [description, setDescription] = useState<string>(
    task?.description || '',
  );

  useEffect(() => {
    setDescription(task?.description || '');
  }, [task.id]);

  const updateTaskProperty = (property: keyof Task, value: any) => {
    dispatch({
      type: WorkOrderActionTypes.UPDATE_TASK_PROPERTY,
      data: {
        taskId: task.id,
        property,
        value,
      },
    });
  };

  const [taskUpdate] = useMutation<
    { taskUpdate: Task },
    { input: TaskUpdateInput }
  >(TASK_UPDATE);

  const progressiveUpdate = useCallback(
    debounce(async (value: string) => {
      const res = await taskUpdate({
        variables: {
          input: {
            id: task.id,
            workOrderId: workOrderDetail.id,
            description: value,
          },
        },
      });

      updateTaskProperty('description', res?.data?.taskUpdate?.description);
    }, DEBOUNCE_TIME),
    [workOrderDetail.id, task.id],
  );

  const handleChange = event => {
    setDescription(event.target.value);
    progressiveUpdate(event.target.value);
  };

  const isDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  return (
    <>
      <Icon style={styles.icon} color={colors.neutral.gray}>
        subject
      </Icon>
      <div styleName="task-detail-row-content" id="description">
        <TextField
          id={description}
          label="Description"
          value={description}
          disabled={isDisabled}
          onChange={handleChange}
          fullWidth
          multiline
        />
      </div>
    </>
  );
};

export default TaskDescription;
