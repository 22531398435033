import React, { useContext } from 'react';
import * as R from 'ramda';

import { Tab } from '@atom/components/common/chipTab/ChipTab';
import TabContainer from '@atom/components/common/chipTab/TabContainer';
import AddTeamModalContext, {
  View,
} from '@atom/components/common/workOrderDetail/addTeamModal/AddTeamModalContext';

import GroupsTab from './groupsTab/GroupsTab';
import SearchTab from './searchTab/SearchTab';
import FavoritesTab from './usersTab/FavoritesTab';
import UsersTab from './usersTab/UsersTab';

import './addTeamModal.css';

const tabs: Tab[] = [
  {
    icon: 'star',
    label: 'favorite users',
    value: View.FAVORITES,
    showCount: true,
  },
  {
    label: 'all users',
    value: View.USERS,
  },
  {
    label: 'groups',
    value: View.GROUPS,
  },
];

const views = {
  [View.FAVORITES]: <FavoritesTab />,
  [View.USERS]: <UsersTab />,
  [View.GROUPS]: <GroupsTab />,
};

export interface Props {
  groupsEnabled?: boolean;
}

const TabSection = ({ groupsEnabled = true }: Props) => {
  const {
    activeView,
    setActiveView,
    favoritesEnabled,
    uniqueFavoritesCount,
  } = useContext(AddTeamModalContext);

  // @ts-ignore
  const newViews = groupsEnabled ? views : { ...R.omit([View.GROUPS], views) };

  return activeView === View.SEARCH ? (
    <SearchTab />
  ) : (
    <TabContainer
      activeView={activeView}
      setActiveView={setActiveView}
      count={uniqueFavoritesCount}
      tabs={tabs.filter(
        (tab: Tab) =>
          !(!favoritesEnabled && tab.value === View.FAVORITES) &&
          !(!groupsEnabled && tab.value === View.GROUPS),
      )}
      views={newViews}
    />
  );
};

export default TabSection;
