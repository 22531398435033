import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SortDirection } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as R from 'ramda';

import TeamContext from '@atom/components/teamPortal/TeamContext';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Icon, IconButton, ListTable, Progress } from '@atom/mui';
import { TimeEntriesConnection } from '@atom/types/timeEntry';
import { WorkOrderDetailType } from '@atom/types/work';
import {
  getSortedTimeEntries,
  timeSheetTableWidths,
} from '@atom/utilities/timeSheetUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import TimeSheetTableCreateRow from './TimeSheetTableCreateRow';
import TimeSheetTableDisplayRow from './TimeSheetTableDisplayRow';
import TimeSheetTableEditRow from './TimeSheetTableEditRow';
import TimeSheetTableTotalsRow from './TimeSheetTableTotalsRow';

import './timeSheet.css';

const { TableBody, TableHead, TableRow, TableCell } = ListTable;

const useStyles = makeStyles({
  root: {
    minWidth: '1100px',
  },
});

const styles = {
  center: {
    padding: '8px 4px',
  },
  start: {
    padding: '8px',
  },
};

type SortField = 'userGroup' | 'budget';

interface Props {
  userId: string;
  timeEntriesConnection: TimeEntriesConnection;
  loading: boolean;
  refetchTimeSheet: () => void;
  editTimeEntryId?: string;
  setEditTimeEntryId: (id: string) => void;
  createTimeEntry: boolean;
  setCreateTimeEntry: (createTimeEntry: boolean) => void;
  newWorkOrder?: WorkOrderDetailType;
}

const TimeSheetTable = ({
  userId,
  timeEntriesConnection,
  loading,
  refetchTimeSheet,
  editTimeEntryId,
  setEditTimeEntryId,
  createTimeEntry,
  setCreateTimeEntry,
  newWorkOrder,
}: Props) => {
  const classes = useStyles();
  const preferences = usePreferences();
  const { payPeriodWeeks } = useContext(TeamContext);

  const [selectedPayPeriodWeek, setSelectedPayPeriodWeek] = useState<number>(0);
  const [dateFilter, setDateFilter] = useState<number>();

  const [sortBy, setSortBy] = useState<string>('');

  const timeEntries = R.pathOr([], ['timeEntries'], timeEntriesConnection);

  useEffect(() => {
    setSelectedPayPeriodWeek(0);
  }, [payPeriodWeeks]);

  const getSortDirection = useCallback(
    (field: SortField) => {
      if (!sortBy?.includes(field)) {
        return false;
      }

      return R.pathOr('asc', [1], sortBy.split(','));
    },
    [sortBy],
  );

  const handleSortDirectionChange = (field: string) => (
    newSortDirection: SortDirection,
  ) => {
    setSortBy(`${field},${newSortDirection}`);
  };

  const sortedTimeEntries = useMemo(() => {
    const sortedList = getSortedTimeEntries(timeEntries, sortBy);

    return dateFilter
      ? R.filter(R.propEq('date', dateFilter))(sortedList)
      : sortedList;
  }, [timeEntries, sortBy, dateFilter]);

  const decrementWeek = () => {
    const maxWeek = payPeriodWeeks.length - 1;
    return selectedPayPeriodWeek === 0 ? maxWeek : selectedPayPeriodWeek - 1;
  };

  const incrementWeek = () => {
    const maxWeek = payPeriodWeeks.length - 1;
    return selectedPayPeriodWeek === maxWeek ? 0 : selectedPayPeriodWeek + 1;
  };

  const handlePayPeriodWeekChange = (direction: 'next' | 'prev') => {
    const newPayPeriodWeek =
      direction === 'next' ? incrementWeek() : decrementWeek();

    setSelectedPayPeriodWeek(newPayPeriodWeek);
  };

  const currentWeekDates = payPeriodWeeks[selectedPayPeriodWeek];
  const isEditMode = !isNilOrEmpty(editTimeEntryId) || createTimeEntry;

  return (
    <>
      <ListTable fullHeight={false} offsetTop="350px">
        <TableHead>
          <TableRow header>
            <TableCell
              width={timeSheetTableWidths.status}
              style={styles.start}
              center
              variant="head"
            >
              Status
            </TableCell>
            <TableCell width={timeSheetTableWidths.workType} variant="head">
              Work Template
            </TableCell>
            <TableCell variant="head">Work</TableCell>
            <TableCell width={timeSheetTableWidths.task} variant="head">
              Task
            </TableCell>
            <TableCell
              width={timeSheetTableWidths.userGroup}
              variant="head"
              sortDirection={getSortDirection('userGroup')}
              onSortChange={handleSortDirectionChange('userGroup')}
            >
              Group
            </TableCell>
            <TableCell
              width={timeSheetTableWidths.budget}
              variant="head"
              sortDirection={getSortDirection('budget')}
              onSortChange={handleSortDirectionChange('budget')}
            >
              {isNilOrEmpty(preferences?.timeTracking?.typeEnumeration)
                ? 'Budget'
                : 'Wage Type'}
            </TableCell>
            <TableCell
              width={timeSheetTableWidths.small}
              variant="head"
              center
              style={styles.center}
            >
              <IconButton
                size="small"
                onClick={() => handlePayPeriodWeekChange('prev')}
                data-cy="timesheetArrowLeftButton"
              >
                <Icon>keyboard_arrow_left</Icon>
              </IconButton>
            </TableCell>
            {currentWeekDates.map(day => (
              <TableCell
                width={timeSheetTableWidths.small}
                center
                style={styles.center}
                key={day}
                variant="head"
              >
                <div styleName="header-date-container">
                  <div styleName="header-date-day">
                    {setDisplayDate(day, 'EEE')}
                  </div>
                  <div styleName="header-date">
                    {setDisplayDate(day, 'MM/dd')}
                  </div>
                </div>
              </TableCell>
            ))}
            <TableCell
              width={timeSheetTableWidths.small}
              variant="head"
              center
              style={styles.center}
            >
              <IconButton
                size="small"
                onClick={() => handlePayPeriodWeekChange('next')}
                data-cy="timesheetArrowRightButton"
              >
                <Icon>keyboard_arrow_right</Icon>
              </IconButton>
            </TableCell>
            {isEditMode && (
              <TableCell
                width={timeSheetTableWidths.action}
                variant="head"
                style={styles.center}
              />
            )}
            <TableCell
              width={timeSheetTableWidths.action}
              variant="head"
              center
              style={styles.center}
            />
          </TableRow>
          <TimeSheetTableTotalsRow
            dailyTotals={timeEntriesConnection?.dailyTotals}
            selectedPayPeriodWeek={selectedPayPeriodWeek}
            editTimeEntryId={editTimeEntryId}
            createTimeEntry={createTimeEntry}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
          />
        </TableHead>
        {!loading && (
          <TableBody classes={{ root: classes.root }}>
            {sortedTimeEntries.map(timeEntry => {
              return editTimeEntryId === timeEntry.id ? (
                <TimeSheetTableEditRow
                  key={timeEntry.id}
                  timeEntry={timeEntry}
                  refetchTimeSheet={refetchTimeSheet}
                  setEditTimeEntryId={setEditTimeEntryId}
                  selectedPayPeriodWeek={selectedPayPeriodWeek}
                />
              ) : (
                <TimeSheetTableDisplayRow
                  key={timeEntry.id}
                  timeEntry={timeEntry}
                  editTimeEntryId={editTimeEntryId}
                  refetchTimeSheet={refetchTimeSheet}
                  setEditTimeEntryId={setEditTimeEntryId}
                  selectedPayPeriodWeek={selectedPayPeriodWeek}
                  createTimeEntry={createTimeEntry}
                />
              );
            })}
            {createTimeEntry && (
              <TimeSheetTableCreateRow
                key="create"
                userId={userId}
                refetchTimeSheet={refetchTimeSheet}
                setCreateTimeEntry={setCreateTimeEntry}
                selectedPayPeriodWeek={selectedPayPeriodWeek}
                newWorkOrder={newWorkOrder}
              />
            )}
          </TableBody>
        )}
      </ListTable>
      {loading && (
        <div styleName="loading-container">
          <Progress />
        </div>
      )}
    </>
  );
};

export default TimeSheetTable;
