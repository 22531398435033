import * as React from 'react';

import TextOverflowTooltip from '@atom/components/common/tooltip/TextOverflowTooltip';

import attributeDisplayUtility from './attributeDisplayUtility';

import '../styles/table.css';

export function tableCell(key, displayValue) {
  return <td key={key}>{displayValue}</td>;
}

export function displayTableCell(key, data, dataType, unit) {
  const displayValue = attributeDisplayUtility.display(data, dataType, unit);
  return tableCell(key, displayValue);
}

export function displayNavigationalCell(key, data, callback) {
  return (
    <td styleName="clickable" key={key} onClick={callback}>
      <TextOverflowTooltip text={data} width="180px" lightTooltip />
    </td>
  );
}

const tableDisplayUtilities = { displayTableCell, displayNavigationalCell };
export default tableDisplayUtilities;
