// This hook is used to update the chart data when the source data changes
// - API response is passed in as dataRaw when updated
// - Returns formatted data collection with other data points needed for the
//   Recharts tooltip and legend components

import { useContext, useEffect, useState } from 'react';
import * as R from 'ramda';

import { BudgetChart } from '@atom/types/budget';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from '../BudgetDetailContext';

import { getMonthOnly, GroupKeys } from './expendituresChartUtils';

export const usePrepExpendituresChartData = (budgetsRaw: BudgetChart[]) => {
  const { budget } = useContext(BudgetDetailContext);

  const [dataFields, setDataFields] = useState<string[]>([]);
  const [actualFields, setActualFields] = useState<string[]>([]);
  const [remainingFields, setRemainingFields] = useState<string[]>([]);
  const [maxVal, setMaxVal] = useState<number>(0);
  const [dataIsEmpty, setDataIsEmpty] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (isNilOrEmpty(budgetsRaw)) {
      return;
    }

    // Get current budget in budgetsRaw
    // - Use current budget chart months to dictate range
    // - Months outside current budget range are ignored
    // - Months in comparison budgets that have no data will set both values to`0`

    const currentBudgetChart = budgetsRaw.find(
      ({ budgetId }) => budgetId === budget.id,
    );

    if (isNilOrEmpty(currentBudgetChart)) {
      return;
    }

    // Move current budget in data to first position
    const budgetsSorted = R.move(
      budgetsRaw.indexOf(currentBudgetChart),
      0,
      budgetsRaw,
    );

    const dataPrepped = currentBudgetChart.charts.reduce((acc, next) => {
      const nextMonthName = next.month;
      const nextMonth = {
        name: nextMonthName,
      };
      budgetsSorted.forEach(budgetChart => {
        const matchMonth = budgetChart.charts.find(
          ({ month }) => getMonthOnly(month) === getMonthOnly(nextMonthName),
        );
        nextMonth[`${budgetChart.budgetName} ${GroupKeys.ACTUAL}`] =
          matchMonth?.actualCost || 0;
        nextMonth[`${budgetChart.budgetName} ${GroupKeys.REMAINING}`] =
          matchMonth?.totalRemaining || 0;
      });

      return [...acc, nextMonth];
    }, []);

    setData(dataPrepped);

    const dataFieldsCurrent = R.without(
      ['name'],
      R.keys(R.pathOr({}, [0], dataPrepped)),
    );
    setDataFields(dataFieldsCurrent);
    setActualFields(
      dataFieldsCurrent.filter(field => R.endsWith(GroupKeys.ACTUAL, field)),
    );

    setRemainingFields(
      dataFieldsCurrent.filter(field => R.endsWith(GroupKeys.REMAINING, field)),
    );

    const getValues = (field: string): number[] =>
      dataPrepped.map(item => item[field]);

    const allVals: number[] = dataFieldsCurrent.reduce(
      (acc, field): number[] => [...acc, ...getValues(field)],
      [],
    );

    // dataIsEmpty not currently in use, but may come up soon
    const uniqueValues = R.uniq(allVals);
    setDataIsEmpty(uniqueValues.length === 1 && uniqueValues[0] === 0);

    setMaxVal(Math.max(...allVals));
  }, [budgetsRaw]);

  return {
    dataFields,
    actualFields,
    remainingFields,
    maxVal,
    data,
    dataIsEmpty,
  };
};
