import React, { useState } from 'react';

import { useKmlLayers } from '@atom/components/mapPortal/hooks/kmlHook';
import { useLayersValue } from '@atom/components/mapPortal/hooks/layersHook';
import { Checkbox, Collapse, Icon, IconButton, Switch } from '@atom/mui';
import markerUtilities from '@atom/utilities/markerUtilities';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import MapDrawerLayerIcon from './MapDrawerLayerIcon';

import './mapDrawerLayers.css';

interface Props {
  toggled: boolean;
  onUpdate: (toggled: boolean, selected: Set<string>) => void;
}

const MapDrawerLayersKmlSection = ({ toggled, onUpdate }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const { layers: kmlLayers } = useKmlLayers();
  const { kmlLayerIds: selectedKmlLayers } = useLayersValue();

  const toggleKmlLayer = (id: string) => {
    onUpdate(toggled, toggleFromSet(selectedKmlLayers, id));
  };

  const selectAll = () => {
    onUpdate(toggled, new Set(kmlLayers.map(layer => layer.id)));
  };
  const deselectAll = () => {
    onUpdate(toggled, new Set([]));
  };

  const allSelected = kmlLayers.every(layer => selectedKmlLayers.has(layer.id));
  const noneSelected = !selectedKmlLayers.size;

  return (
    <div styleName="section-container">
      <div styleName="section-header-container">
        <Switch
          checked={toggled}
          label={
            <div styleName="toggle-label">
              <div styleName="section-header-icon">
                <MapDrawerLayerIcon name="layers" />
              </div>
              <div>Geospatial Layers</div>
            </div>
          }
          onChange={() => {
            onUpdate(!toggled, selectedKmlLayers);
          }}
        />
        <IconButton edge="end" onClick={() => setExpanded(current => !current)}>
          <Icon>{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
        </IconButton>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div styleName="section-filters expanded">
          <div styleName="select-all-container">
            <div
              onClick={selectAll}
              styleName={`select-all-label ${
                !toggled || allSelected ? 'disabled' : ''
              }`}
            >
              Select All
            </div>
            <div styleName="select-all-divider" />
            <div
              onClick={deselectAll}
              styleName={`select-all-label ${
                !toggled || noneSelected ? 'disabled' : ''
              }`}
            >
              Clear All
            </div>
          </div>
          {kmlLayers.map(layer => {
            const icon = markerUtilities.getKmlSvg(layer.markerId);

            return (
              <div styleName="section-filter-row" key={layer.id}>
                <Checkbox
                  disabled={!toggled}
                  checked={selectedKmlLayers.has(layer.id)}
                  onChange={() => toggleKmlLayer(layer.id)}
                  edge="start"
                />
                <img style={{ height: 34, width: 34 }} src={icon} />
                <span styleName="section-filter-row-text">{layer.name}</span>
              </div>
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};

export default MapDrawerLayersKmlSection;
