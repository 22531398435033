import { call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import actionTypes from '@atom/actions/actionTypes';
import {
  createWorkOrderAssetFailure,
  createWorkOrderAssetSuccess,
  createWorkOrderElementBatchFailure,
  createWorkOrderElementBatchSuccess,
  deleteWorkOrderElementFailure,
  deleteWorkOrderElementSuccess,
  getWorkOrderAssetElementsFailure,
  getWorkOrderAssetElementsSuccess,
  getWorkOrderAssetFailure,
  getWorkOrderAssetSuccess,
  patchWorkOrderAssetFailure,
  patchWorkOrderAssetSuccess,
  workOrderElementTreeNodeUpdateFailure,
  workOrderElementTreeNodeUpdateSuccess,
} from '@atom/actions/workOrderAssetActions';
import textConstants from '@atom/constants/textConstants';
import { Snackbar } from '@atom/mui';
import accountUtilities from '@atom/utilities/accountUtilities';
import api from '@atom/utilities/api';
import {
  getWorkOrderAssetElementsEndpoint,
  getWorkOrderAssetsEndpoint,
} from '@atom/utilities/endpoints';

export function* getWorkOrderAsset(action) {
  try {
    const { workOrderId, workOrderAssetId } = action.data;
    const baseEndpoint = getWorkOrderAssetsEndpoint(workOrderId);
    const endpoint = `${baseEndpoint}/${workOrderAssetId}`;

    const { data } = yield call(api.get, endpoint);

    yield put(getWorkOrderAssetSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      getWorkOrderAssetFailure,
    );
  }
}

export function* getWorkOrderAssetElements(action) {
  try {
    const { workOrderId, workOrderAssetId } = action.data;
    const endpoint = getWorkOrderAssetElementsEndpoint(
      workOrderId,
      workOrderAssetId,
    );

    const { data } = yield call(api.get, endpoint);

    yield put(getWorkOrderAssetElementsSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      getWorkOrderAssetElementsFailure,
    );
  }
}

export function* patchWorkOrderAsset(action) {
  try {
    const {
      workOrderId,
      workOrderAssetId,
      attributeGroupName,
      body,
    } = action.data;
    const baseEndpoint = getWorkOrderAssetsEndpoint(workOrderId);
    const endpoint = `${baseEndpoint}/${workOrderAssetId}`;

    const { data } = yield call(api.patch, endpoint, body);

    yield put(patchWorkOrderAssetSuccess({ ...data, attributeGroupName }));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      patchWorkOrderAssetFailure,
    );
  }
}

export function* createWorkOrderAsset(action) {
  try {
    const { workOrderId, workOrderAssetId, body, taskId } = action.data;
    const baseEndpoint = getWorkOrderAssetsEndpoint(workOrderId);
    const endpoint = `${baseEndpoint}/${workOrderAssetId}`;

    const { data } = yield call(api.post, endpoint, body);

    yield put(
      createWorkOrderAssetSuccess({
        taskId,
        asset: data,
      }),
    );

    Snackbar.info({
      message: `Asset ${data.name} added to task`,
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      createWorkOrderAssetFailure,
    );
  }
}

export function* updateWorkOrderElementTreeNode({ data: updatedAsset }) {
  try {
    const {
      elementPath,
      workOrderId,
      workOrderAssetId,
      ...body
    } = updatedAsset;
    const baseEndpoint = getWorkOrderAssetsEndpoint(workOrderId);
    const endpoint = `${baseEndpoint}/${workOrderAssetId}`;

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_UPDATING_TEXT,
    });
    const { data } = yield call(api.patch, endpoint, body);

    yield put(
      workOrderElementTreeNodeUpdateSuccess({
        data,
        elementPath,
      }),
    );

    Snackbar.info({ message: 'Updated' });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      workOrderElementTreeNodeUpdateFailure,
    );
  }
}

export function* deleteWorkOrderElement({
  data: { workOrderId, workOrderAssetId, elementPath },
}) {
  try {
    const baseEndpoint = getWorkOrderAssetsEndpoint(workOrderId);
    const endpoint = `${baseEndpoint}/${workOrderAssetId}`;

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
    });

    yield call(api.delete, endpoint);

    yield put(
      deleteWorkOrderElementSuccess({
        id: workOrderAssetId,
        elementPath,
      }),
    );

    Snackbar.info({
      message: 'Element Deleted',
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      deleteWorkOrderElementFailure,
    );
  }
}

export function* batchCreateWorkOrderElements({ data: batchPayload }) {
  try {
    const { workOrderId, workOrderAssetId, ...requestPayload } = batchPayload;
    const baseEndpoint = getWorkOrderAssetsEndpoint(workOrderId);
    const rootAssetEndpoint = `${baseEndpoint}/${workOrderAssetId}/elements`;
    const endpoint = `${baseEndpoint}/${workOrderAssetId}/elements`;

    yield call(api.post, endpoint, requestPayload);

    const { data } = yield call(api.get, rootAssetEndpoint);

    yield put(createWorkOrderElementBatchSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      createWorkOrderElementBatchFailure,
    );
  }
}

/* WATCHERS */
export function* retrieveWorkOrderAsset() {
  yield takeLatest(actionTypes.REQUEST_WORK_ORDER_ASSET, getWorkOrderAsset);
}

export function* retrieveWorkOrderAssetElements() {
  yield takeLatest(
    actionTypes.REQUEST_WORK_ORDER_ASSET_ELEMENTS,
    getWorkOrderAssetElements,
  );
}

export function* requestPatchWorkOrderAsset() {
  yield takeEvery(
    actionTypes.REQUEST_PATCH_WORK_ORDER_ASSET,
    patchWorkOrderAsset,
  );
}

export function* requestCreateWorkOrderAsset() {
  yield takeEvery(
    actionTypes.REQUEST_CREATE_WORK_ORDER_ASSET,
    createWorkOrderAsset,
  );
}

export function* requestWorkOrderElementTreeNodeUpdate() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_WORK_ORDER_ELEMENT_TREE_NODE_UPDATE,
    updateWorkOrderElementTreeNode,
  );
}

export function* requestDeleteWorkOrderElement() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_DELETE_WORK_ORDER_ASSET,
    deleteWorkOrderElement,
  );
}

export function* requestWorkOrderElementBatchCreate() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_WORK_ORDER_ELEMENT_BATCH_CREATE,
    batchCreateWorkOrderElements,
  );
}

export default [
  retrieveWorkOrderAsset,
  retrieveWorkOrderAssetElements,
  requestPatchWorkOrderAsset,
  requestCreateWorkOrderAsset,
  requestWorkOrderElementTreeNodeUpdate,
  requestDeleteWorkOrderElement,
  requestWorkOrderElementBatchCreate,
];
