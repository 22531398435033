import React from 'react';

import { Icon, IconButton } from '@atom/mui';

import './workTemplateSettings.css';

const styles = {
  helpIconButton: {
    width: '1.25rem',
    height: '1.25rem',
  },
  helpIcon: {
    fontSize: '1.25rem',
  },
};

const SettingsTitle = () => {
  const tooltipContent = (
    <div styleName="settings-tooltip">
      <div styleName="settings-tooltip-row">
        <div>&bull;</div>
        <div styleName="settings-tooltip-content">
          When a section is enabled, the child work template must inherit the
          content in that section as it appears on the parent without permission
          to modify the inherited content.
        </div>
      </div>
      <div styleName="settings-tooltip-row">
        <div>&bull;</div>
        <div styleName="settings-tooltip-content">
          When a section is disabled, the child work template will not inherit
          any content from that section.
        </div>
      </div>
      <div styleName="settings-tooltip-row">
        In both scenarios, the child work template can add additional fields,
        files, or items to any of the sections.
      </div>
    </div>
  );

  return (
    <div styleName="header-container">
      <div styleName="title">Child Work Template Settings</div>
      <IconButton
        style={styles.helpIconButton}
        tooltip={tooltipContent}
        lightTooltip
        TooltipProps={{
          placement: 'bottom-end',
        }}
      >
        <Icon style={styles.helpIcon}>help</Icon>
      </IconButton>
    </div>
  );
};

export default SettingsTitle;
