import React from 'react';
import {
  Table as MUITable,
  TableContainer as MUITableContainer,
  TableContainerProps as MUITableContainerProps,
  TableProps as MUITableProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as R from 'ramda';

import TableBody from './TableBody';
import TableCell from './TableCell';
import TableFooter from './TableFooter';
import TableHead from './TableHead';
import TablePagination from './TablePagination';
import TableRow from './TableRow';

export type ListTableProps = {
  /**
   * sets the top offset for the full page table
   */
  offsetTop?: string;
  /**
   * optional props to pass to the TableContainer that wraps ListTable
   */
  TableContainerProps?: MUITableContainerProps;
  /**
   * fullHeight causes table to utilize the height of calc(100vh - ${offsetTop}).
   * When this is false, the table's height will dynamically change based on
   * row heights. fullHeight will default as true.
   */
  fullHeight?: boolean;
} & MUITableProps;

const useStyles = makeStyles({
  container: ({ offsetTop = '0px', fullHeight = true }: ListTableProps) => ({
    // maxHeight will cause table height to be dynamic, height is fixed
    [fullHeight ? 'height' : 'maxHeight']: `calc(100vh - ${offsetTop})`,
  }),
});

// ListTable is a full page, paginated table
export const ListTable = (props: ListTableProps) => {
  const classes = useStyles(props);

  const { TableContainerProps = {}, stickyHeader = true, ...data } = props;

  return (
    <MUITableContainer
      classes={{ root: classes.container, ...TableContainerProps.classes }}
      {...TableContainerProps}
    >
      <MUITable
        classes={{ ...props.classes }}
        stickyHeader={stickyHeader}
        // @ts-ignore
        {...R.omit(['offsetTop', 'fullHeight'], data)}
      />
    </MUITableContainer>
  );
};

ListTable.TableBody = TableBody;
ListTable.TableCell = TableCell;
ListTable.TableFooter = TableFooter;
ListTable.TableHead = TableHead;
ListTable.TablePagination = TablePagination;
ListTable.TableRow = TableRow;
