import React, { useState } from 'react';

import ManageUserGroupsModal from '@atom/components/common/manageUserGroupModal/ManageUserGroupsModal';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { Icon, Menu } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import {
  hasRolePermissions,
  hasSuperiorOrEqualUserLevel,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';

import DeactivateUserModal from './DeactivateUserModal';

import './teamControls.css';

const { MenuItem } = Menu;

const styles = {
  iconButtonStyle: {
    padding: 0,
    margin: 0,
    border: 0,
  },
  iconStyle: {
    color: colors.neutral.gray,
  },
  menuItemStyle: {
    textTransform: 'capitalize',
    height: '2.25rem',
    padding: '0px 16px',
    overflow: 'hidden',
  },
  iconBox: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  primaryTextBox: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 0,
    padding: 0,
  },
  primaryText: {
    margin: '0 1rem',
    color: colors.neutral.dark,
    fontSize: fonts.md,
    fontWeight: '500',
  },
};

interface Props {
  user: any;
  refetch: () => Promise<any>;
}

const UserOptions = ({ user, refetch }: Props) => {
  const userProfile = useUserProfile();

  const [manageGroupOpen, setManageGroupOpen] = useState<boolean>(false);
  const [deactivateOpen, setDeactivateOpen] = useState<boolean>(false);

  const isDeactivateDisabled = userProfile.userId === user.id;

  const renderDeactivate =
    !user.isPending &&
    !isDeactivateDisabled &&
    hasRolePermissions(ROLE_SETS.ORG_ADMIN) &&
    hasSuperiorOrEqualUserLevel(user.level);

  const renderManageUserGroups = hasRolePermissions(ROLE_SETS.ORG_ADMIN);

  return renderDeactivate || renderManageUserGroups ? (
    <div styleName="options-wrapper">
      <Menu
        IconProps={{ color: colors.neutral.gray }}
        IconButtonProps={{ style: styles.iconButtonStyle }}
      >
        {renderManageUserGroups && (
          <MenuItem
            style={styles.menuItemStyle}
            onClick={() => setManageGroupOpen(true)}
          >
            <div style={styles.primaryTextBox}>
              <div style={styles.iconBox}>
                <Icon color={colors.neutral.gray}>group</Icon>
                <div style={styles.primaryText}>Manage User Groups</div>
              </div>
            </div>
          </MenuItem>
        )}
        {renderDeactivate && (
          <MenuItem
            style={styles.menuItemStyle}
            onClick={() => setDeactivateOpen(true)}
          >
            <div style={styles.primaryTextBox}>
              <div style={styles.iconBox}>
                <Icon color={colors.neutral.gray}>not_interested</Icon>
                <div style={styles.primaryText}>Deactivate</div>
              </div>
            </div>
          </MenuItem>
        )}
      </Menu>
      <ManageUserGroupsModal
        open={manageGroupOpen}
        onClose={() => setManageGroupOpen(false)}
        userId={user.id}
        refetch={refetch}
      />
      <DeactivateUserModal
        open={deactivateOpen}
        onClose={() => setDeactivateOpen(false)}
        userId={user.id}
        refetch={refetch}
      />
    </div>
  ) : (
    <div />
  );
};

export default UserOptions;
