import React, { useContext } from 'react';
import * as R from 'ramda';

import { Checkbox, Icon, IconButton, Progress, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { AnalyticsFolderTree as FolderTree } from '@atom/types/analytics';

import AnalyticsFolderSearchResults from './AnalyticsFolderSearchResults';
import AnalyticsFolderSelectionContext from './AnalyticsFolderSelectionContext';
import AnalyticsFolderSelectRow from './AnalyticsFolderSelectRow';
import { MIN_SEARCH_CHARS, styles } from './analyticsFolderSelectUtils';

const AnalyticsFolderSelectTree = () => {
  const {
    openFolders,
    toggleOpen,
    treeData,
    treeLoading,
    handleChange,
    selectedFolderIds,
    query,
    setQuery,
  } = useContext(AnalyticsFolderSelectionContext);

  const expanded = openFolders.has(treeData.id);

  const arrowDirection = expanded ? 'arrow_drop_down' : 'arrow_right';

  const renderExpandedArrow = !R.isEmpty(treeData.children);

  const folderDisplayStyleArrow = renderExpandedArrow ? {} : styles.indentation;

  const folderDisplayStyle = {
    ...folderDisplayStyleArrow,
  };

  const rootSelected = selectedFolderIds.has('analytics');

  const showSearchResults = query.length >= MIN_SEARCH_CHARS;
  const displayContent = showSearchResults ? (
    <AnalyticsFolderSearchResults />
  ) : (
    <>
      <div style={folderDisplayStyle}>
        {renderExpandedArrow && (
          <IconButton
            onClick={() => toggleOpen(treeData.id)}
            style={styles.iconStyle}
          >
            <Icon color={colors.neutral.dim}>{arrowDirection}</Icon>
          </IconButton>
        )}
        <Checkbox
          checked={rootSelected}
          onChange={() => handleChange(treeData)}
        />
        All
      </div>
      {expanded ? (
        <>
          {treeData.children.map((child: FolderTree) => (
            <AnalyticsFolderSelectRow
              key={child.id}
              folder={child}
              indentation={0}
              isAncestorSelected={rootSelected}
            />
          ))}
        </>
      ) : null}
    </>
  );

  return treeLoading ? (
    <Progress />
  ) : (
    <>
      <TextField
        value={query}
        onChange={event => setQuery(event.target.value)}
        placeholder="Search"
        InputProps={{
          startAdornment: <Icon>search</Icon>,
          endAdornment: query && (
            <Icon style={{ cursor: 'pointer' }} onClick={() => setQuery('')}>
              close
            </Icon>
          ),
        }}
      />
      {displayContent}
    </>
  );
};

export default AnalyticsFolderSelectTree;
