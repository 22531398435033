import React, { useEffect, useState } from 'react';
import { match as Match } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_WORK_ORDER } from '@atom/graph/work';
import { Progress } from '@atom/mui';
import {
  AttributeGroupItem,
  FhwaModuleElementType,
  FormInstanceTemplateType,
} from '@atom/types/form';
import { MediaItem } from '@atom/types/media';
import { WorkOrderDetailType } from '@atom/types/work';
import api from '@atom/utilities/api';
import {
  FORM_INSTANCES_ENDPOINT,
  MEDIA_ENDPOINT,
} from '@atom/utilities/endpoints';

import MainPhotoTile from '../MainPhotoTile';

import FhwaElementTextbox from './FhwaElementTextbox';
import FormAttributesTextbox from './FormAttributesTextbox';
import PhotosBox from './PhotosBox';

import '../workOrderDetailPdfReport.css';

interface Props {
  match: Match<{ workOrderId: string }>;
}

const IdlWorkOrderPdfReport = ({ match }: Props) => {
  const [workOrder, setWorkOrder] = useState<WorkOrderDetailType>(
    {} as WorkOrderDetailType,
  );
  const [formInstanceIds, setFormInstanceIds] = useState<string[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [assetAttributeGroups, setAssetAttributeGroups] = useState<
    AttributeGroupItem[]
  >([]);
  const [fhwaElements, setFhwaElements] = useState<FhwaModuleElementType[]>([]);
  const [mainPhoto, setMainPhoto] = useState<MediaItem>({} as MediaItem);
  const [mainPhotoLoading, setMainPhotoLoading] = useState<boolean>(true);
  const [otherPhotosLoading, setOtherPhotosLoading] = useState<{
    [id: string]: boolean;
  }>({ photoBox: true });

  const onCompleted = data => {
    const { formInstances: ids } = data.workOrder;
    setFormInstanceIds(Object.keys(ids));
    setWorkOrder(data.workOrder);
  };

  const { loading } = useQuery(GET_WORK_ORDER, {
    variables: {
      id: match.params.workOrderId,
    },
    onCompleted,
  });

  useEffect(() => {
    if (formInstanceIds.length) {
      const getFormInstances = async () => {
        // Note: Based on requirements, this is under the assumption that only
        // one form is added to a work.
        const endpoint = `${FORM_INSTANCES_ENDPOINT}/${formInstanceIds[0]}`;
        const { data } = await api.get<FormInstanceTemplateType>(endpoint);

        data?.pages?.forEach(page => {
          Object.values(page.fields).forEach(field => {
            if (field.type === 'asset') {
              setAssetAttributeGroups(field.assets[0].attributeGroups);
            }
            if (field.type === 'fhwa') {
              const values = field?.order?.map(id => field.elements[id]);
              setFhwaElements(values);
            }
          });
        });
        setLoaded(true);
      };
      getFormInstances();
    }
  }, [formInstanceIds]);

  useEffect(() => {
    if (workOrder.mainPhotoId) {
      const getPhoto = async () => {
        const endpoint = `${MEDIA_ENDPOINT}/${workOrder.mainPhotoId}`;
        const response = await api.get(endpoint);
        setMainPhoto(response.data);
      };
      getPhoto();
    } else {
      setMainPhotoLoading(false);
    }
  }, [workOrder.mainPhotoId]);

  return (
    <div styleName="background">
      {loading ? (
        <Progress />
      ) : (
        <div styleName="main">
          <div styleName="details">
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ width: '50%' }}>
                <FormAttributesTextbox
                  header="Condition"
                  formAttributeGroups={assetAttributeGroups}
                />
                <FormAttributesTextbox
                  header="Wearing Surface & Deadload Information"
                  formAttributeGroups={assetAttributeGroups}
                />
              </div>
              <div style={{ width: '50%' }}>
                {workOrder.mainPhotoId ? (
                  <MainPhotoTile
                    image={mainPhoto}
                    imageHeight="15.5rem"
                    imageWidth="100%"
                    loading={setMainPhotoLoading}
                  />
                ) : (
                  <div styleName="empty-photo-section idl" />
                )}
              </div>
            </div>
            <FormAttributesTextbox
              header="Additional Condition Information"
              formAttributeGroups={assetAttributeGroups}
            />
          </div>
          <div styleName="page-break" />
          <div styleName="group-container">
            {fhwaElements?.map(fhwaElement => (
              <FhwaElementTextbox
                element={fhwaElement}
                attributes={[
                  'environment',
                  'totalQuantity',
                  'cs1',
                  'cs2',
                  'cs3',
                  'cs4',
                ]}
                indentLevel={-1}
              />
            ))}
            <div styleName="page-break" />

            <FormAttributesTextbox
              header="Inspection"
              formAttributeGroups={assetAttributeGroups}
            />
            <FormAttributesTextbox
              header="Identification"
              formAttributeGroups={assetAttributeGroups}
            />
            <FormAttributesTextbox
              header="Age & Service"
              formAttributeGroups={assetAttributeGroups}
            />
            <FormAttributesTextbox
              header="Appraisal"
              formAttributeGroups={assetAttributeGroups}
            />
            <FormAttributesTextbox
              header="Load Rating"
              formAttributeGroups={assetAttributeGroups}
            />
            <FormAttributesTextbox
              header="Classification"
              formAttributeGroups={assetAttributeGroups}
            />
            <FormAttributesTextbox
              header="Structure Type and Materials"
              formAttributeGroups={assetAttributeGroups}
            />
            <FormAttributesTextbox
              header="Geometric Data"
              formAttributeGroups={assetAttributeGroups}
            />
            <PhotosBox
              workOrderId={match.params.workOrderId}
              setOtherPhotosLoading={setOtherPhotosLoading}
            />
          </div>
          {loaded &&
            !mainPhotoLoading &&
            Object.values(otherPhotosLoading).every(load => !load) && (
              <div id="loaded" />
            )}
        </div>
      )}
    </div>
  );
};

export default IdlWorkOrderPdfReport;
