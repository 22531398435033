import React from 'react';

import InfoSection from './InfoSection';
import UserDetailMap from './UserDetailMap';

import './infoTab.css';

const InfoTab = () => {
  return (
    <div styleName="info-tab-container">
      <InfoSection />
      <div styleName="right-pane">
        <UserDetailMap />
      </div>
    </div>
  );
};

export default InfoTab;
