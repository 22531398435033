import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import { GET_WORK_ORDERS } from '@atom/graph/work';
import {
  WorkOrdersConnection,
  WorkOrdersConnectionInput,
} from '@atom/types/work';

import AssetWorkOrdersFilterPanel from './AssetWorkOrdersFilterPanel';
import AssetWorkOrdersTable from './AssetWorkOrdersTable';

import './assetWorkOrdersContainer.css';

export const RESULTS_PER_PAGE_OPTIONS = [25, 50, 100];

interface Props {
  assetId: string;
  schemaId: string;
}

const AssetWorkOrdersContainer = ({ assetId, schemaId }: Props) => {
  const [sortBy, setSortBy] = useState<string>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    RESULTS_PER_PAGE_OPTIONS[0],
  );
  const [activeFilters, setActiveFilters] = useState<WorkOrdersConnectionInput>(
    {},
  );

  const {
    data: { workOrders: { totalCount, workOrders } } = {
      workOrders: { totalCount: 0, workOrders: [] },
    },
    loading: loadingWorkOrders,
  } = useQuery<
    { workOrders: WorkOrdersConnection },
    { input: WorkOrdersConnectionInput }
  >(GET_WORK_ORDERS, {
    variables: {
      input: {
        page: currentPage,
        limit: rowsPerPage,
        assetId,
        sortBy: `${sortBy},${sortDirection}`,
        ...activeFilters,
      },
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <div styleName="tab-content">
      <div styleName="filter-panel-container">
        <AssetWorkOrdersFilterPanel
          schemaId={schemaId}
          loadingWorkOrders={loadingWorkOrders}
          setActiveFilters={setActiveFilters}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <div styleName="table-container">
        <AssetWorkOrdersTable
          workOrders={workOrders}
          loadingWorkOrders={loadingWorkOrders}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalCount={totalCount}
        />
      </div>
    </div>
  );
};

export default AssetWorkOrdersContainer;
