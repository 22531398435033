import React from 'react';
import {
  ListItem as MUIListItem,
  ListItemProps as ListItemPropsType,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
});

type CustomProps = {
  style?: React.CSSProperties;
};

export type ListItemProps = CustomProps & ListItemPropsType;

export const ListItem = (props: ListItemProps) => {
  const classes = useStyles(props);
  const { style = {} } = props;

  // @ts-ignore
  return (
    <MUIListItem {...props} style={style} classes={{ root: classes.root }} />
  );
};

export default ListItem;
