import { gql } from '@apollo/client';

export const GET_PAY_PERIODS = gql`
  query payPeriods($input: PayPeriodsInput!) {
    payPeriods(input: $input) {
      currentPeriod
      availableYears
      periods {
        period
        startDate
        endDate
      }
    }
  }
`;
