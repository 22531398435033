import React from 'react';

type Props = {
  isActive: Boolean;
  count: Number;
  color: string;
};

const AssetGroupMarker = ({ isActive, count, color = '#9209AE' }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={isActive ? 42 : 34}
  >
    <defs>
      <filter
        {...(isActive
          ? { width: '150%', height: '163.6%', x: '-25%', y: '-21.2%' }
          : { width: '183.3%', height: '183.3%', x: '-41.7%', y: '-41.7%' })}
        id="a"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dy={isActive ? '-1' : '1'}
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="1.5"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      {isActive && (
        <>
          <linearGradient id="b" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopOpacity="0.209" />
            <stop offset="100%" />
          </linearGradient>
          <filter
            id="c"
            width="208.3%"
            height="424.8%"
            x="-54.1%"
            y="-162.4%"
            filterUnits="objectBoundingBox"
          >
            <feGaussianBlur in="SourceGraphic" stdDeviation="2.165" />
          </filter>
        </>
      )}
    </defs>
    {isActive ? (
      <>
        <g
          fill="none"
          fillRule="evenodd"
          filter="url(#a)"
          transform="translate(3 4)"
        >
          <ellipse
            cx="14"
            cy="31"
            fill="url(#b)"
            filter="url(#c)"
            opacity="0.498"
            rx="6"
            ry="2"
          />
          <path
            fill={color}
            d="M7.02 26.139C2.826 23.721 0 19.19 0 14 0 6.268 6.268 0 14 0s14 6.268 14 14c0 5.354-3.006 10.006-7.421 12.361L14 32l-7-5.86.02-.001z"
          />
        </g>
      </>
    ) : (
      <circle
        cx="12"
        cy="12"
        r="13"
        fill="#FFF"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="2"
        filter="url(#a)"
        transform="translate(5 4)"
      />
    )}
    <text
      x={17}
      y={isActive ? 20 : 17}
      textAnchor="middle"
      alignmentBaseline="middle"
      color={color}
      fontSize="16px"
      fontFamily="Roboto, Arial, sans-serif"
      fontWeight="400"
      fill={isActive ? '#FFF' : color}
    >
      {count}
    </text>
  </svg>
);

export default React.memo(AssetGroupMarker);
