import * as React from 'react';

import './mapDrawerContent.css';

interface Props {
  label: string;
  value: string;
}

const DetailRow = ({ label, value }: Props) => {
  return (
    <div styleName="detail-row-container">
      <div styleName="detail-row-label">{label}:</div>
      <div styleName="detail-row-value">{value}</div>
    </div>
  );
};

export default DetailRow;
