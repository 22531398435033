import React from 'react';
import * as R from 'ramda';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { WorkTemplateFolderTree } from '@atom/types/workTemplate';

import './workTypeFolderTree.css';

const PADDING_OFFSET = 2;

const styles = {
  arrowStyle: {
    fontSize: '24px',
    margin: 0,
    color: colors.neutral.dim,
  },
  iconStyle: {
    fontSize: '24px',
    margin: 0,
    color: colors.neutral.gray,
  },
  indentation: {
    paddingLeft: '3rem',
  },
  blankStyle: {
    boxSizing: 'border-box',
    padding: '24px',
  },
};

export interface Props {
  folder: WorkTemplateFolderTree;
  indentation: number;
  open: Set<string>;
  toggleOpen: (id: string) => void;
  selectWorkTemplateFolder: (
    workTemplateFolder: WorkTemplateFolderTree,
  ) => void;
}

const WorkTypeFolderTreeRow = ({
  folder,
  indentation,
  open,
  toggleOpen,
  selectWorkTemplateFolder,
}: Props) => {
  const expanded = open.has(folder.id);

  const arrowDirection = expanded ? 'arrow_drop_down' : 'arrow_right';

  const expandClick = async () => {
    if (R.isEmpty(folder.children)) {
      selectWorkTemplateFolder(folder);
    }

    toggleOpen(folder.id);
  };

  const renderExpandedArrow = !R.isEmpty(folder.children);

  const leftIcon = (
    <>
      {renderExpandedArrow && (
        <IconButton>
          <Icon style={styles.arrowStyle}>{arrowDirection}</Icon>
        </IconButton>
      )}
      <Icon style={styles.iconStyle}>folder</Icon>
    </>
  );

  const containerStyle = {
    paddingLeft: `calc(2rem + ${PADDING_OFFSET * indentation}rem)`,
  };

  return (
    <>
      <div styleName="folder-row" style={containerStyle} onClick={expandClick}>
        <div
          styleName="folder-name-container"
          style={renderExpandedArrow ? {} : styles.indentation}
        >
          {leftIcon}
          <div styleName="row-name-container">
            <div styleName="row-title">{folder.name}</div>
          </div>
        </div>
      </div>
      {expanded ? (
        folder.children.map((child: WorkTemplateFolderTree) => (
          <WorkTypeFolderTreeRow
            key={child.id}
            folder={child}
            indentation={indentation + 1}
            open={open}
            toggleOpen={toggleOpen}
            selectWorkTemplateFolder={selectWorkTemplateFolder}
          />
        ))
      ) : (
        <div />
      )}
    </>
  );
};

export default WorkTypeFolderTreeRow;
