import React from 'react';

import { WorkOrderAssetTreeType } from '@atom/types/work';
import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';

import '../workOrderDetailPdfReport.css';

interface Props {
  workOrderAsset: WorkOrderAssetTreeType;
  elementGroupName: string;
}

const ElementGroupDetailsTable = ({
  workOrderAsset,
  elementGroupName,
}: Props) => {
  return (
    <>
      {workOrderAsset.elementGroups.map(
        elementGroup =>
          elementGroup.name === elementGroupName &&
          elementGroup.elements.map(element => (
            <table styleName="collapse-border">
              <thead>
                <tr>
                  <th styleName="textbox-table-title" colSpan={2}>
                    {element.name}
                  </th>
                </tr>
              </thead>
              <tbody styleName="content">
                {element.attributeGroups.map(attributeGroup => {
                  const attributeGroupIds = attributeGroup.attributes;

                  return attributeGroupIds.map(key => (
                    <tr styleName="textbox-table-row">
                      <td styleName="textbox-table-row-title">
                        {element.attributes[key].name}
                      </td>
                      <td styleName="value">
                        {attributeDisplayUtility.display(
                          element.attributes[key].value,
                          element.attributes[key].dataType,
                          element.attributes[key].unit,
                          !!element.attributes[key].changeId,
                          element.attributes[key].newValue,
                        )}
                      </td>
                    </tr>
                  ));
                })}
              </tbody>
            </table>
          )),
      )}
    </>
  );
};

export default ElementGroupDetailsTable;
