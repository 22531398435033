import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';

import { requestDownloadFiles } from '@atom/actions/fileActions';
import MediaTableHeader from '@atom/components/common/mediaTable/MediaTableHeader';
import WorkOrderContext from '@atom/components/workOrderDetail/WorkOrderContext';
import textConstants from '@atom/constants/textConstants';
import {
  GET_MEDIA,
  MEDIA_BULK_DELETE,
  MEDIA_DELETE,
  MEDIA_UPDATE,
} from '@atom/graph/media';
import { useFileUpload } from '@atom/hooks/useFileUpload';
import { Snackbar } from '@atom/mui';
import {
  Media,
  MediaBulkDeleteInput,
  MediaType,
  MediaUpdateInput,
  SubjectType,
} from '@atom/types/media';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import VideosPagination from './VideosPagination';
import VideoTile from './VideoTile';

import './workOrderVideos.css';

const PAGE_LIMIT = 12;

const WorkOrderVideos = () => {
  const dispatch = useDispatch();
  const [uploadFiles] = useFileUpload();
  const { workOrderDetail } = useContext(WorkOrderContext);

  const [media, setMedia] = useState<Media[]>([]);
  const [mediaTotal, setMediaTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set([]));

  const onCompleted = data => {
    setMedia(data?.media?.media);
    setMediaTotal(data?.media?.totalCount);
  };

  const { refetch, loading: loadingMedia } = useQuery(GET_MEDIA, {
    variables: {
      input: {
        parentSubjectIds: [
          workOrderDetail.id,
          ...R.keys(workOrderDetail.formInstances),
        ],
        type: MediaType.VIDEO,
        page,
        limit: PAGE_LIMIT,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted,
  });

  const [mediaUpdate, { loading: loadingUpdate }] = useMutation<
    { mediaUpdate: Media },
    { input: MediaUpdateInput }
  >(MEDIA_UPDATE);

  const [mediaDelete, { loading: loadingDelete }] = useMutation<
    { mediaDelete: boolean },
    { id: string }
  >(MEDIA_DELETE);

  const [mediaBulkDelete, { loading: loadingBulkDelete }] = useMutation<
    { mediaBulkDelete: boolean },
    { input: MediaBulkDeleteInput }
  >(MEDIA_BULK_DELETE);

  const resetToFirstPage = () => {
    if (page === 1) {
      refetch({
        input: {
          parentSubjectIds: [
            workOrderDetail.id,
            ...R.keys(workOrderDetail.formInstances),
          ],
          type: MediaType.VIDEO,
          page: 1,
          limit: PAGE_LIMIT,
        },
      });
    } else {
      setPage(1);
    }
  };

  const handleUpload = async event => {
    const { files } = event.target;

    if (!files.length) {
      return;
    }

    await uploadFiles({
      files,
      subjectId: workOrderDetail.id,
      subjectType: SubjectType.WORK_ORDER_TEMPLATE,
      parentSubjectId: workOrderDetail.id,
    });

    refetch();
  };

  const renameMedia = async (id: string, name: string) => {
    const res = await mediaUpdate({
      variables: {
        input: {
          id,
          name,
        },
      },
    });

    const updatedMedia = media.map((mediaItem: Media) => {
      return mediaItem.id === id ? res?.data?.mediaUpdate : mediaItem;
    });

    setMedia(updatedMedia);
  };

  const deleteMedia = async (id: string) => {
    try {
      Snackbar.info({
        message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
      });

      await mediaDelete({
        variables: {
          id,
        },
      });

      Snackbar.info({
        message: 'Successfully deleted file',
      });

      setSelectedItems(new Set([]));
      resetToFirstPage();
    } catch (err) {
      Snackbar.error({
        message: 'Failed to delete file',
      });
    }
  };

  const downloadMultipleFiles = () => {
    const selectedMedia = media.filter(medium =>
      [...selectedItems].includes(medium.id),
    );

    dispatch(requestDownloadFiles(selectedMedia));
  };

  const deleteMultipleFiles = async () => {
    await mediaBulkDelete({
      variables: {
        input: {
          ids: [...selectedItems],
        },
      },
    });

    setSelectedItems(new Set([]));
    resetToFirstPage();
  };

  const incrementPage = (increment: number) => {
    setPage(page + increment);
    setSelectedItems(new Set([]));
  };

  const toggleCheckbox = (id: string) => {
    setSelectedItems(toggleFromSet(selectedItems, id));
  };

  const isEditDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  const loading =
    loadingMedia || loadingUpdate || loadingDelete || loadingBulkDelete;

  return (
    <div styleName="body-container">
      <div styleName="center-pane">
        <MediaTableHeader
          type={MediaType.VIDEO}
          totalCount={mediaTotal}
          selectedItems={selectedItems}
          isEditDisabled={isEditDisabled}
          handleUpload={handleUpload}
          handleDownloadMultipleFiles={downloadMultipleFiles}
          handleDeleteMultipleMedia={deleteMultipleFiles}
          loading={loading}
        />
        <div styleName="photo-block">
          <div styleName="photo-tiles-container">
            {media.map((medium: Media) => {
              const isChecked = selectedItems.has(medium.id);

              return (
                <VideoTile
                  key={medium.id}
                  media={medium}
                  renameMedia={renameMedia}
                  deleteMedia={deleteMedia}
                  loading={loading}
                  isEditDisabled={isEditDisabled}
                  isChecked={isChecked}
                  toggleCheckbox={toggleCheckbox}
                />
              );
            })}
          </div>
        </div>
        {!!media.length && (
          <VideosPagination
            incrementPage={incrementPage}
            page={page}
            limit={PAGE_LIMIT}
            total={mediaTotal}
          />
        )}
      </div>
    </div>
  );
};

export default WorkOrderVideos;
