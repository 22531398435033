import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import UserGroupContext from '@atom/components/userGroupDetail/UserGroupContext';
import { UPDATE_USER_GROUP } from '@atom/graph/userGroup';
import { Button, Icon, IconButton, Progress, TextField } from '@atom/mui';
import { UserGroup, UserGroupUpdateInput } from '@atom/types/userGroups';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import './userGroupInfo.css';

const DescriptionSection = () => {
  const { userGroup } = useContext(UserGroupContext);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(userGroup.description);

  const [updateUserGroup, { loading }] = useMutation<
    { userGroupUpdate: UserGroup },
    { input: UserGroupUpdateInput }
  >(UPDATE_USER_GROUP);

  const confirm = () => {
    updateUserGroup({
      variables: {
        input: {
          id: userGroup.id,
          description,
        },
      },
    });

    setEditMode(false);
  };

  const onCancel = () => {
    setEditMode(false);
    setDescription(userGroup.description);
  };

  const rightButtons = editMode ? (
    <div>
      <Button onClick={onCancel} disabled={loading}>
        Cancel
      </Button>
      <Button color="primary" onClick={confirm} disabled={loading}>
        Save
      </Button>
      {loading && (
        <div styleName="spinner-container">
          <Progress size={25} thickness={2} />
        </div>
      )}
    </div>
  ) : hasRolePermissions(ROLE_SETS.ORG_ADMIN) ? (
    <IconButton onClick={() => setEditMode(true)}>
      <Icon>edit</Icon>
    </IconButton>
  ) : (
    <div />
  );

  return (
    <>
      <div styleName="title-container">
        <div>Description</div>
        {rightButtons}
      </div>
      <div styleName="content-container">
        {editMode ? (
          <TextField
            multiline
            name="description"
            value={description}
            onChange={event => setDescription(event.target.value)}
          />
        ) : (
          <div>{userGroup.description}</div>
        )}
      </div>
    </>
  );
};

export default DescriptionSection;
