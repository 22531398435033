import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import { GET_POLICIES_FOR_VALIDATION } from '@atom/graph/policy';
import { DUPLICATE_ROLE } from '@atom/graph/role';
import {
  Checkbox,
  Icon,
  Modal,
  Progress,
  Snackbar,
  TextField,
} from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  PoliciesConnection,
  PoliciesConnectionInput,
  Policy,
  PolicyAction,
  Resource,
} from '@atom/types/policy';
import { Role, RoleDuplicateInput } from '@atom/types/role';
import history from '@atom/utilities/history';
import {
  getPolicyActionLabel,
  POLICY_RESOURCE_OPTIONS,
} from '@atom/utilities/policyUtilities';

import LoadMoreButton from '../loadMoreButton/LoadMoreButton';

import './roleDuplicateModal.css';

const NAME_LIMIT = 100;
const DESCRIPTION_LIMIT = 200;
const HTTP_STATUS_CONFLICT = 409;
const POLICIES_LIMIT = 5;

interface Props {
  open: boolean;
  onClose: (refetch: boolean) => void;
  role: Role;
}

const styles = {
  modal: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '30.375rem',
  },
  input: {
    margin: '1rem 0 0 0',
  },
  icon: {
    marginRight: '2rem',
    marginTop: '2rem',
  },
  characterCount: {
    fontSize: '0.75rem',
    float: 'right',
  },
};

const getInitialState = (role: Role): RoleDuplicateInput => ({
  id: role?.id || '',
  name: `Copy of ${role?.name || ''}`,
  description: role?.description || '',
  policyIds: [],
});

const getLabelForGrants = (grants: Resource[]) => {
  if (!grants.length) {
    return 'All Sub Source';
  }

  return grants.map(grant => grant.name).join(', ');
};

const getLabelForAction = (action: PolicyAction) => {
  return action === PolicyAction.ALL
    ? 'All Actions'
    : `${getPolicyActionLabel(action)} Only`;
};

const getPolicyLabel = (policy: Policy): React.ReactNode => {
  const option = POLICY_RESOURCE_OPTIONS[policy.resource];

  if (!option) {
    return null;
  }

  const label = [
    option.label,
    getLabelForGrants(policy.grants),
    getLabelForAction(policy.action),
  ].join('; ');

  return (
    <div styleName="policy-row">
      <span style={{ marginRight: '1rem' }}>{option.icon}</span>
      <div>{label}</div>
    </div>
  );
};

const RoleDuplicateModal = ({ open, onClose, role }: Props) => {
  const [input, setInput] = useState<RoleDuplicateInput>(getInitialState(role));
  const [policies, setPolicies] = useState<Policy[]>([]);
  const [page, setPage] = useState<number>(1);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');

  const { name, description, policyIds } = input;

  const resetState = () => {
    setInput(getInitialState(role));
    setPolicies([]);
    setPage(1);
    setAllSelected(false);
    setErrorText('');
  };

  useEffect(() => {
    resetState();
  }, [role]);

  const [
    getPolicies,
    { data, loading: loadingPolicies, error: policiesError },
  ] = useLazyQuery<
    { policies: PoliciesConnection },
    { input: PoliciesConnectionInput }
  >(GET_POLICIES_FOR_VALIDATION, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: res =>
      setPolicies(current => [...current, ...res.policies.policies]),
  });

  useEffect(() => {
    if (open) {
      getPolicies({
        variables: {
          input: {
            page,
            sortBy: 'resource',
            sortDirection: 'asc',
            subjectIds: [role?.id],
            limit: POLICIES_LIMIT,
          },
        },
      });
    }
  }, [open, page]);

  const [duplicateRole, { loading }] = useMutation<
    { roleDuplicate: Role },
    { input: RoleDuplicateInput }
  >(DUPLICATE_ROLE);

  const isValid = () => {
    return Boolean(name && description && (allSelected || policyIds.length));
  };

  const togglePolicyId = (policyId: string) => {
    const isToggled = policyIds.includes(policyId);

    if (isToggled) {
      setInput(current => ({
        ...current,
        policyIds: current.policyIds.filter(id => id !== policyId),
      }));
    } else {
      setInput(current => ({
        ...current,
        policyIds: [...current.policyIds, policyId],
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      const res = await duplicateRole({
        variables: {
          input: {
            id: role?.id,
            name,
            description,
            ...(!allSelected && {
              policyIds,
            }),
          },
        },
      });

      onClose(true);

      Snackbar.info({
        message: `Created role "${name}"`,
        action: 'View',
        onActionClick: () =>
          history.push(`/team/role/${res?.data?.roleDuplicate?.id}`),
      });
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_STATUS_CONFLICT) {
        setErrorText('This name is already in use.');
      } else {
        Snackbar.error({ message: 'An unknown error occurred' });
      }
    }
  };

  const countStyle = (inputLength, maxLength) =>
    inputLength > maxLength ? { color: colors.brand.red } : {};

  const characterLimitReached =
    name.length > NAME_LIMIT || description.length > DESCRIPTION_LIMIT;

  const hasMore = data?.policies?.totalCount > policies.length;

  return (
    <Modal
      title="Duplicate Role"
      open={open}
      onCancel={() => onClose(false)}
      onConfirm={handleSubmit}
      loading={loading}
      onExited={resetState}
      disabled={!isValid() || characterLimitReached}
      confirmButtonText="Duplicate"
      contentStyle={styles.modal}
    >
      <div styleName="modal-row">
        <Icon style={styles.icon}>
          <img src={renameIcon} />
        </Icon>
        <div style={{ width: '100%' }}>
          <TextField
            name="name"
            label="Name"
            value={name}
            required
            onChange={event =>
              setInput(current => ({ ...current, name: event.target.value }))
            }
            error={!!errorText}
            helperText={errorText}
            style={styles.input}
          />
          <div style={styles.characterCount}>
            <span style={countStyle(name.length, NAME_LIMIT)}>
              {name.length}/{NAME_LIMIT}
            </span>
          </div>
        </div>
      </div>
      <div styleName="modal-row">
        <Icon style={styles.icon}>subject</Icon>
        <div style={{ width: '100%' }}>
          <TextField
            multiline
            required
            name="description"
            label="Description"
            value={description}
            onChange={event =>
              setInput(current => ({
                ...current,
                description: event.target.value,
              }))
            }
            style={styles.input}
          />
          <div style={styles.characterCount}>
            <span style={countStyle(description.length, DESCRIPTION_LIMIT)}>
              {description.length}/{DESCRIPTION_LIMIT}
            </span>
          </div>
        </div>
      </div>
      <>
        <div styleName="policies-title">Policies</div>
        {loadingPolicies && !policies.length ? (
          <Progress style={{ flex: 1 }} />
        ) : (
          <div>
            <div>
              <Checkbox
                disabled={!data?.policies?.totalCount}
                checked={allSelected}
                onChange={() => setAllSelected(!allSelected)}
                label={`All of ${data?.policies?.totalCount || 0} policies`}
              />
            </div>
            {policies.map(policy => {
              const option = POLICY_RESOURCE_OPTIONS[policy.resource];

              if (!option) {
                return null;
              }

              return (
                <div key={policy.id}>
                  <Checkbox
                    checked={policyIds.includes(policy.id) || allSelected}
                    disabled={allSelected}
                    onChange={() => togglePolicyId(policy.id)}
                    label={getPolicyLabel(policy)}
                  />
                </div>
              );
            })}
          </div>
        )}
        {hasMore && (
          <LoadMoreButton
            loading={loadingPolicies}
            error={!!policiesError}
            onClick={() => setPage(current => current + 1)}
          />
        )}
      </>
    </Modal>
  );
};

export default RoleDuplicateModal;
