import React, { useContext } from 'react';
import * as R from 'ramda';

import TextOverflowTooltip from '@atom/components/common/tooltip/TextOverflowTooltip';
import UserGroupIcon from '@atom/components/common/UserGroupIcon';
import AddTeamModalContext from '@atom/components/common/workOrderDetail/addTeamModal/AddTeamModalContext';
import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import { UserGroupTreeType } from '@atom/types/userGroups';

import './groupsTab.css';

const PADDING_OFFSET = 2;

const styles = {
  arrowStyle: {
    fontSize: '1.5rem',
    margin: 0,
    padding: '0.75rem',
  },
  indentation: {
    paddingLeft: '3rem',
  },
};

interface Props {
  userGroup: UserGroupTreeType;
  indentation: number;
}

const GroupsTreeRow = ({ userGroup, indentation }: Props) => {
  const {
    expanded,
    toggleExpanded,
    selectedUserGroups,
    addUserGroupToCart,
    removeUserGroupFromCart,
    userGroupsOnTask,
  } = useContext(AddTeamModalContext);

  const isExpanded = expanded.has(userGroup.id);
  const isOnTask = userGroupsOnTask.includes(userGroup.id);
  const isSelected = selectedUserGroups
    .map(group => group.id)
    .includes(userGroup.id);

  const arrowDirection = isExpanded ? 'arrow_drop_down' : 'arrow_right';
  const renderExpandedArrow = !R.isEmpty(userGroup.userGroups);

  const containerStyle = {
    paddingLeft: `calc(2rem + ${PADDING_OFFSET * indentation}rem)`,
  };

  const getTitleStyle = () => {
    if (isSelected) {
      return 'title selected';
    }

    if (isOnTask) {
      return 'title disabled';
    }

    return 'title';
  };

  const onClick = () => {
    if (isSelected) {
      return removeUserGroupFromCart(userGroup.id);
    }

    if (isOnTask) {
      return () => {};
    }

    return addUserGroupToCart(userGroup);
  };

  const titleWidth = `calc(28rem - ${PADDING_OFFSET * indentation}rem)`;

  const checkboxIcon = isOnTask ? (
    <Icon color={colors.neutral.ash}>check_box</Icon>
  ) : isSelected ? (
    <Icon color={colors.brand.blue}>check_box</Icon>
  ) : (
    <Icon color={colors.neutral.gray}>check_box_outline_blank</Icon>
  );

  return (
    <>
      <div styleName="row" style={containerStyle}>
        <div
          styleName="row-content sub-row"
          style={renderExpandedArrow ? {} : styles.indentation}
        >
          <div styleName="left-content">
            {renderExpandedArrow && (
              <Icon
                style={styles.arrowStyle}
                styleName="icon"
                onClick={() => toggleExpanded(userGroup.id)}
              >
                {arrowDirection}
              </Icon>
            )}
            <div styleName="group-icon-and-name" onClick={onClick}>
              {checkboxIcon}
              <UserGroupIcon colorId={userGroup.colorId} disabled={isOnTask} />
              <div style={{ width: titleWidth }} styleName="name-container">
                <div styleName={getTitleStyle()}>
                  <TextOverflowTooltip
                    width={titleWidth}
                    text={userGroup.name}
                    lightTooltip
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isExpanded &&
        userGroup.userGroups.map(group => (
          <GroupsTreeRow
            key={group.id}
            userGroup={group}
            indentation={indentation + 1}
          />
        ))}
    </>
  );
};

export default GroupsTreeRow;
