import React, { useContext, useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import { StringParam, useQueryParams } from 'use-query-params';

import { Icon, IconButton, Popover } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventoryCategoryTree } from '@atom/types/inventory';

import InventoryPortalContext from './InventoryPortalContext';

import './inventoryPortal.css';

interface Breadcrumb {
  id: string;
  name: string;
  path: any[];
}

const hydratePaths = (
  categoryTree: InventoryCategoryTree,
  activePath: any[],
): Breadcrumb[] => {
  // @ts-ignore
  return activePath.reduce((acc, item, index) => {
    const path = R.slice(0, R.inc(index), activePath);

    return R.equals('categories', item)
      ? acc
      : [...acc, { ...R.pathOr({}, path, categoryTree), path }];
  }, []);
};

const INVENTORY_BREADCRUMB: Breadcrumb = {
  id: 'inventory',
  name: 'Inventory',
  path: [],
};

const InventoryBreadcrumbs = () => {
  const container = useRef<HTMLDivElement>();
  const [query, setQuery] = useQueryParams({ id: StringParam });

  const [anchor, setAnchor] = useState<any>(null);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [overflowing, setOverflowing] = useState<boolean>(false);

  const { categoryTree, activePath, setActiveCategory } = useContext(
    InventoryPortalContext,
  );

  const breadcrumbs = hydratePaths(categoryTree, activePath);
  const activeBreadcrumb = R.last(breadcrumbs) || INVENTORY_BREADCRUMB;
  const lastIndex = R.dec(breadcrumbs.length);
  const overflowBreadcrumbs = R.slice(
    0,
    R.dec(breadcrumbs.length),
    breadcrumbs,
  );

  const handleToggle = event => {
    setAnchor(event.currentTarget);
    setPopoverOpen(true);
  };

  const handleClick = (path: any[]) => {
    const activeCategory = R.pathOr({}, path, categoryTree);
    // @ts-ignore
    setActiveCategory(activeCategory, path);
    setQuery({ id: '' });
    setPopoverOpen(false);
  };

  useEffect(() => {
    setOverflowing(
      container.current.offsetHeight < container.current.scrollHeight,
    );
  }, [activePath]);

  const arrow = <Icon color={colors.neutral.white}>keyboard_arrow_right</Icon>;

  const inventoryBreadcrumb = (
    <>
      <div styleName="breadcrumb" onClick={() => handleClick([])}>
        Inventory
      </div>
      {Boolean(breadcrumbs.length) && arrow}
    </>
  );

  const overflowContent = (
    <div
      styleName="breadcrumb-container"
      style={{ visibility: overflowing ? 'visible' : 'hidden' }}
    >
      {inventoryBreadcrumb}
      <IconButton onClick={handleToggle}>
        <Icon color={colors.neutral.white}>more_horiz</Icon>
      </IconButton>
      <Popover
        open={popoverOpen}
        anchorEl={anchor}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        onClose={() => setPopoverOpen(false)}
      >
        {overflowBreadcrumbs.map(breadcrumb => (
          <div
            key={query + breadcrumb.id}
            onClick={() => handleClick(breadcrumb.path)}
            styleName="breadcrumb-menu-item"
          >
            {breadcrumb.name}
          </div>
        ))}
      </Popover>
      {arrow}
      <span
        styleName="breadcrumb"
        onClick={() => handleClick(activeBreadcrumb?.path)}
      >
        {activeBreadcrumb?.name}
      </span>
    </div>
  );

  const regularContent = (
    <div
      styleName="breadcrumb-container"
      ref={container}
      style={{ visibility: overflowing ? 'hidden' : 'visible' }}
    >
      {inventoryBreadcrumb}
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={breadcrumb.id}>
          <div
            styleName="breadcrumb"
            onClick={() => handleClick(breadcrumb.path)}
          >
            {breadcrumb.name}
          </div>
          {index !== lastIndex && arrow}
        </React.Fragment>
      ))}
    </div>
  );

  return overflowing ? (
    <>
      {overflowContent}
      {regularContent}
    </>
  ) : (
    <>
      {regularContent}
      {overflowContent}
    </>
  );
};

export default InventoryBreadcrumbs;
