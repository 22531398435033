import React, { useContext, useRef, useState } from 'react';

import { usePreferences } from '@atom/hooks/usePreferences';
import { List, Popover } from '@atom/mui';
import { TYPE_OPTIONS, WorkOrderType } from '@atom/types/work';
import { WorkTypeVariant } from '@atom/utilities/workTemplateUtilities';

import WorkTemplateContext from '../../WorkTemplateContext';
import WorkTemplateTypeOption from '../workTemplateOption/WorkTemplateTypeOption';

import './workTypeEditModal.css';

const { ListItemButton } = List;

interface Props {
  type: WorkOrderType;
  setType: (type: WorkOrderType) => void;
}

const WorkTemplateTypeSelect = ({ type, setType }: Props) => {
  const ref = useRef();
  const preferences = usePreferences();
  const multiLocationEnabled = preferences?.workOrders?.multiLocationEnabled;

  const { workTypeVariant } = useContext(WorkTemplateContext);

  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = () => {
    setOpen(!open);
    setAnchorEl(ref.current);
  };

  const handleClosePopover = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleClick = (newType: WorkOrderType) => {
    setType(newType);
    handleClosePopover();
  };

  // Enable template type selection if multi location is enabled and the
  // work type is not a child.
  const isDropdownEnabled =
    multiLocationEnabled && workTypeVariant !== WorkTypeVariant.CHILD;

  const typeOption =
    type === WorkOrderType.DEFAULT
      ? type
      : WorkOrderType.TASK_ASSETS_AND_LOCATIONS;

  return (
    <div styleName="type-select-container">
      <div styleName="section-title">Template Type</div>
      <div styleName="type-input" ref={ref}>
        <WorkTemplateTypeOption
          option={TYPE_OPTIONS[typeOption]}
          onDropdownClick={handleOpen}
          dropdown={isDropdownEnabled}
        />
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        onClose={handleClosePopover}
      >
        <List>
          <ListItemButton onClick={() => handleClick(WorkOrderType.DEFAULT)}>
            <WorkTemplateTypeOption
              selected={type === WorkOrderType.DEFAULT}
              option={TYPE_OPTIONS[WorkOrderType.DEFAULT]}
            />
          </ListItemButton>
          <ListItemButton
            onClick={() => handleClick(WorkOrderType.TASK_ASSETS_AND_LOCATIONS)}
          >
            <WorkTemplateTypeOption
              selected={type === WorkOrderType.TASK_ASSETS_AND_LOCATIONS}
              option={TYPE_OPTIONS[WorkOrderType.TASK_ASSETS_AND_LOCATIONS]}
            />
          </ListItemButton>
        </List>
      </Popover>
    </div>
  );
};

export default WorkTemplateTypeSelect;
