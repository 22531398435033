import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SortDirection } from '@mui/material';

import { Icon, ListTable } from '@atom/components/common/mui';
import colors from '@atom/styles/colors';
import layout from '@atom/styles/layout';
import { AnalyticsCard, AnalyticsSearch } from '@atom/types/analytics';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';

import AnalyticsContext from './AnalyticsContext';
import CardOptions from './CardOptions';

import './analyticsDashboard.css';

const styles = {
  link: {
    fontWeight: 500,
  },
  row: {
    backgroundColor: colors.neutral.white,
    height: '3rem',
  },
};

const {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} = ListTable;

interface Props {
  getSortDirection: (field: string) => SortDirection;
  handleSortDirectionChange: any;
  analyticsCards: AnalyticsCard[] | AnalyticsSearch[];
  refetch: any;
  totalCount: number;
  limit: number;
  setLimit: any;
  page: number;
  setPage: any;
}

const SpacerRow = (
  <TableRow hover={false}>
    <TableCell style={{ height: '22px', border: 'none' }} colSpan={5} />
  </TableRow>
);

const AnalyticsTable = ({
  getSortDirection,
  handleSortDirectionChange,
  analyticsCards,
  refetch,
  totalCount,
  limit,
  setLimit,
  page,
  setPage,
}: Props) => {
  const { bulkMoveSelected, setBulkMoveSelected } = useContext(
    AnalyticsContext,
  );

  return (
    <ListTable offsetTop="165px">
      <TableHead>
        <TableRow header>
          <TableCell variant="head" padding="checkbox" />
          <TableCell variant="head" padding="checkbox" />
          <TableCell
            variant="head"
            sortDirection={getSortDirection('name')}
            onSortChange={handleSortDirectionChange('name')}
          >
            Name
          </TableCell>
          <TableCell variant="head">Created By</TableCell>
          <TableCell
            variant="head"
            sortDirection={getSortDirection('createdDate')}
            onSortChange={handleSortDirectionChange('createdDate')}
          >
            Created On
          </TableCell>
          <TableCell variant="head" />
        </TableRow>
      </TableHead>
      <TableBody>
        {analyticsCards.map(card => (
          <TableRow key={card.id} style={styles.row}>
            <TableCell padding="checkbox">
              {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
                <input
                  onChange={() =>
                    setBulkMoveSelected(
                      toggleFromSet(bulkMoveSelected, card.id),
                    )
                  }
                  checked={bulkMoveSelected.has(card.id)}
                  type="checkbox"
                />
              )}
            </TableCell>
            <TableCell padding="checkbox">
              <Icon color={colors.brand.blue}>assessment</Icon>
            </TableCell>
            <TableCell style={styles.link}>
              <Link to={`/analytics/${card.id}`}>{card.name}</Link>
            </TableCell>
            <TableCell>
              {card?.createdBy
                ? `${card?.createdBy?.firstName} ${card?.createdBy?.lastName}`
                : '-'}
            </TableCell>
            <TableCell>{setDisplayDate(card?.createdDate)}</TableCell>
            <TableCell width="3rem">
              {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
                <CardOptions card={card} refetch={refetch} />
              )}
            </TableCell>
          </TableRow>
        ))}
        {SpacerRow}
      </TableBody>
      <TableFooter
        style={{ border: `1px solid ${colors.neutral.ash}` }}
        width={`calc(100vw - ${layout.sidePaneWidth})`}
      >
        <TableRow hover={false}>
          <TablePagination
            style={{ border: 'none' }}
            rowsPerPageOptions={[25, 50, 100, 250]}
            count={totalCount}
            rowsPerPage={limit}
            page={page}
            onPageChange={pageData => setPage(pageData)}
            onRowsPerPageChange={event => {
              setLimit(+event.target.value);
              setPage(1);
            }}
          />
        </TableRow>
      </TableFooter>
    </ListTable>
  );
};

export default AnalyticsTable;
