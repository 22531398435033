import React from 'react';
import {
  TableRow as MUITableRow,
  TableRowProps as MUITableRowProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as R from 'ramda';

import colors from '@atom/styles/colors';

type TableRowProps = {
  /**
   * Denotes row is being used as a table header.
   */
  header?: boolean;
  /**
   * Determines the height of the row.
   * Defaults to none.
   */
  height?: string;
  /**
   * Determines the background color of the row.
   * Defaults to white.
   */
  backgroundColor?: string;
  /**
   * Determines the hover color.
   * Defaults to 'auto' (gray).
   * Dom prop collision with camelCased hoverColor
   */
  hovercolor?: string;
} & MUITableRowProps;

const useStyles = makeStyles(() => ({
  root: ({
    height = 'none',
    backgroundColor = colors.neutral.white,
  }: TableRowProps) => ({
    height,
    backgroundColor,
  }),
  hover: ({ hovercolor = 'auto' }: TableRowProps) => ({
    '&:hover': {
      backgroundColor: `${hovercolor} !important`,
    },
  }),
}));

const TableRow = (props: TableRowProps) => {
  const classes = useStyles(props);
  const hover = props.hover ?? !props.header;

  return (
    <MUITableRow
      hover={hover}
      {...R.omit(['header', 'backgroundColor'], props)}
      classes={{ ...props.classes, root: classes.root, hover: classes.hover }}
    />
  );
};

export default TableRow;
