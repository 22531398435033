import * as React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';

import NavigationButton from '@atom/components/common/map/mapDrawerContent/NavigationButton';
import NavigationControls from '@atom/components/common/map/mapDrawerContent/NavigationControls';
import WorkOrderStatusPill from '@atom/components/common/workOrderDetail/workOrderStatusPill/WorkOrderStatusPill';
import WorkPriorityIcon from '@atom/components/common/WorkPriorityIcon';
import { Icon, IconButton, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import { ReduxStore } from '@atom/types/store';
import { WorkOrderDetailType } from '@atom/types/work';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';
import { getStaticImage } from '@atom/utilities/mapUtilities';
import userUtilities from '@atom/utilities/userUtilities';

import WorkSummaryInfo from './WorkSummaryInfo';
import WorkSummaryTasks from './WorkSummaryTasks';
import WorkSummaryTeam from './WorkSummaryTeam';

import './workSummaryTray.css';

const views = [
  {
    value: 'info',
    icon: 'info',
  },
  {
    value: 'tasks',
    icon: 'playlist_add_check',
  },
  {
    value: 'team',
    icon: 'supervisor_account',
  },
];

interface ReduxStateProps {
  workOrderDetail: WorkOrderDetailType;
  loading: boolean;
}

interface PassedProps {
  onCancel: () => void;
}

interface State {
  activeView: string;
}

type Props = ReduxStateProps & PassedProps;

class WorkSummaryTray extends React.Component<Props, State> {
  state = {
    activeView: 'info',
  };

  getTrayContentBody = () => {
    const { workOrderDetail } = this.props;
    const { activeView } = this.state;

    switch (activeView) {
      case 'info': {
        return <WorkSummaryInfo work={workOrderDetail} />;
      }
      case 'tasks': {
        return <WorkSummaryTasks tasks={workOrderDetail.tasks} />;
      }
      case 'team': {
        return (
          <WorkSummaryTeam
            // @ts-ignore
            users={userUtilities.rollUpTaskUsers(workOrderDetail.tasks)}
          />
        );
      }
      default: {
        return <WorkSummaryInfo work={workOrderDetail} />;
      }
    }
  };

  getSummaryImage = () => {
    const { workOrderDetail } = this.props;

    if (R.isNil(workOrderDetail)) {
      return getStaticImage(null);
    }

    return !workOrderDetail.mainPhotoFileId
      ? getStaticImage(workOrderDetail.location)
      : `${FILES_ENDPOINT}/${workOrderDetail.mainPhotoFileId}_180`;
  };

  setActiveView = activeView => {
    this.setState({ activeView });
  };

  navigateToWorkOrder = () => {
    const {
      workOrderDetail: { id },
    } = this.props;

    history.push(`/workOrders/${id}`);
  };

  render() {
    const { loading, workOrderDetail, onCancel } = this.props;
    const { activeView } = this.state;

    const body = this.getTrayContentBody();

    const imageUrl = {
      backgroundImage: `url('${this.getSummaryImage()}')`,
    };

    const content = loading ? (
      <div styleName="spinner-container">
        <Progress />
      </div>
    ) : (
      <div styleName="tray-container">
        <div styleName="header-container">
          <div styleName="header-title-container">
            <div styleName="header-title">{workOrderDetail.name}</div>
            <div styleName="exit-container">
              <IconButton onClick={onCancel}>
                <Icon color={colors.neutral.gray}>close</Icon>
              </IconButton>
            </div>
          </div>
          <div styleName="priority-status-container">
            <div styleName="priority-container">
              <WorkPriorityIcon priorityId={workOrderDetail.priorityId} />
            </div>
            <WorkOrderStatusPill
              statusId={workOrderDetail.statusId}
              className="dashboard-map-list"
            />
          </div>
        </div>
        <div styleName="tray-content-container">
          <div styleName="summary-image-container" style={imageUrl} />
          <NavigationControls
            views={views}
            activeView={activeView}
            setActiveView={this.setActiveView}
          />
          <div>{body}</div>
        </div>
        <NavigationButton
          label="View Full Detail"
          onClick={this.navigateToWorkOrder}
        />
      </div>
    );

    return <div styleName="container">{content}</div>;
  }
}

const mapStateToProps = (state: ReduxStore, props): ReduxStateProps => ({
  workOrderDetail: R.has('data')(props) ? props.data : state.workOrderDetail,
  loading: R.has('loading')(props)
    ? props.loading
    : state.loading.loadingWorkOrderDetail,
});

export default connect(mapStateToProps, null)(WorkSummaryTray);
