import React, { useMemo } from 'react';

import { useSchemas } from '@atom/hooks/useSchemas';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';

import { ReportMaterial } from './reportUtilities';

import './workOrderReport.css';

interface Props {
  reportMaterial: ReportMaterial;
  isCompletedOrAbove: boolean;
}

const ReportUsageRow = ({ reportMaterial, isCompletedOrAbove }: Props) => {
  const { schemas = [] } = useSchemas();

  const schema = useMemo(() => {
    return schemas.find(({ id }) => id === reportMaterial.schemaId);
  }, [schemas, reportMaterial.schemaId]);

  const amount = reportMaterial?.entries.reduce((acc, entry) => {
    return acc + entry?.amount || 0;
  }, 0);

  const name = reportMaterial?.unit
    ? `${reportMaterial?.name} (${reportMaterial?.unit.toUpperCase()})`
    : reportMaterial?.name;

  return (
    <div styleName="material-row">
      <div styleName="material-type">{schema?.name || ''}</div>
      <div styleName="material-name">{name}</div>
      <div styleName="material-row-sub">
        <div styleName="material-value used">{amount || ''}</div>
        {isCompletedOrAbove && (
          <div styleName="material-value">
            {reportMaterial?.rate && amount
              ? numberToLocaleString(reportMaterial?.rate * amount)
              : ''}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportUsageRow;
