import React, { useContext } from 'react';
import * as R from 'ramda';

import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import {
  getLocalDisplayDate,
  getUtcDisplayDate,
  setDisplayDate,
  setDurationDisplay,
  setWorkTimeDisplay,
} from '@atom/utilities/timeUtilities';

import WorkOrderContext from '../WorkOrderContext';

import './workOrderInfo.css';

const DetailPane = () => {
  const { workOrderDetail } = useContext(WorkOrderContext);
  const workTemplateFolderPath = workOrderDetail.workTemplateFolderPath
    .map(path => path.name)
    .join(' / ');

  const showActualCost = hasRolePermissions(ROLE_SETS.MANAGER);

  return (
    <>
      <div styleName="section-header">
        <div>Details</div>
      </div>
      <div styleName="detail-content">
        <div styleName="info-row">
          <div styleName="row-label">Work Template:</div>
          <div>{workOrderDetail.workTemplateName || '-'}</div>
        </div>
        <div styleName="info-row">
          <div styleName="row-label">Work Template Folder:</div>
          <div styleName="info-value">{workTemplateFolderPath || '-'}</div>
        </div>
        <div styleName="info-row">
          <div styleName="row-label">Created Date:</div>
          <div>
            <div>{getUtcDisplayDate(workOrderDetail.createdDate)}</div>
            <div styleName="local-display-date">
              {getLocalDisplayDate(workOrderDetail.createdDate)}
            </div>
          </div>
        </div>
        <div styleName="info-row">
          <div styleName="row-label">Created By:</div>
          <div>{workOrderDetail.createdByName || '-'}</div>
        </div>
        <div styleName="info-row">
          <div styleName="row-label">Team Lead:</div>
          <div>{workOrderDetail.leadAssigneeName || '-'}</div>
        </div>
        <div styleName="info-row">
          <div styleName="row-label">Due Date:</div>
          <div>{setDisplayDate(workOrderDetail.dueDate)}</div>
        </div>
        <div styleName="info-row">
          <div styleName="row-label">Start Date:</div>
          <div>{setDisplayDate(workOrderDetail.startTime)}</div>
        </div>
        <div styleName="info-row">
          <div styleName="row-label">Completed Date:</div>
          <div>
            <div>{getUtcDisplayDate(workOrderDetail.completionDate)}</div>
            <div styleName="local-display-date">
              {getLocalDisplayDate(workOrderDetail.completionDate)}
            </div>
          </div>
        </div>
        <div styleName="info-row">
          <div styleName="row-label">Completed By:</div>
          <div>{workOrderDetail.completedByName || '-'}</div>
        </div>
        <div styleName="info-row">
          <div styleName="row-label">Work Time:</div>
          <div>{setWorkTimeDisplay(workOrderDetail.workTime)}</div>
        </div>
        <div styleName="info-row">
          <div styleName="row-label">Duration:</div>
          <div>{setDurationDisplay(workOrderDetail.duration)}</div>
        </div>
        <div styleName="info-row">
          <div styleName="row-label">Estimated Cost:</div>
          <div>{numberToLocaleString(workOrderDetail.estimatedCost)}</div>
        </div>
        {showActualCost && (
          <div styleName="info-row">
            <div styleName="row-label">Actual Cost:</div>
            <div>{numberToLocaleString(workOrderDetail.actualCost)}</div>
          </div>
        )}
        <div styleName="info-row">
          <div styleName="row-label">Closed Date:</div>
          <div>
            <div>{getUtcDisplayDate(workOrderDetail.closedDate)}</div>
            <div styleName="local-display-date">
              {getLocalDisplayDate(workOrderDetail.closedDate)}
            </div>
          </div>
        </div>
        <div styleName="info-row">
          <div styleName="row-label">Closed By:</div>
          <div>{workOrderDetail.closedByName || '-'}</div>
        </div>
        {!R.isEmpty(workOrderDetail.inventoryAssetId) && (
          <>
            <div styleName="info-row">
              <div styleName="row-label">Inventory Update Date:</div>
              <div>
                <div>{getUtcDisplayDate(workOrderDetail.appliedDate)}</div>
                <div styleName="local-display-date">
                  {getLocalDisplayDate(workOrderDetail.appliedDate)}
                </div>
              </div>
            </div>
            <div styleName="info-row">
              <div styleName="row-label">Updated By:</div>
              <div>{workOrderDetail.appliedByName || '-'}</div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DetailPane;
