import React from 'react';
import {
  Autocomplete as MUIAutocomplete,
  FilterOptionsState,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  List,
  Progress,
  TextField,
  TextFieldProps as TextFieldPropsType,
} from '@atom/mui';
import fonts from '@atom/styles/fonts';

const { ListItemText } = List;

export type SelectAutocompleteProps<T> = {
  id?: string;
  style?: React.CSSProperties;
  options: any[];
  getOptionLabel?: any;
  fullWidth?: boolean;
  value: any;
  onChange: any;
  disabled?: boolean;
  label?: string;
  loading?: boolean;
  filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[];
  placeholder?: string;
  TextFieldProps?: TextFieldPropsType;
  helperText?: string;
  blurOnSelect?: boolean;
};

const useStyles = makeStyles({
  popupIndicator: {
    padding: '4px',
  },
  clearIndicator: {
    padding: '4px',
  },
});

const styles = {
  defaultStyle: {
    width: '100%',
  },
  option: {
    fontSize: fonts.md,
    fontWeight: '500',
  },
};

export function SelectAutocomplete<T>(props: SelectAutocompleteProps<T>) {
  const classes = useStyles(props);
  const {
    style,
    loading,
    getOptionLabel = (option: string) => option,
    fullWidth = true,
    disabled = false,
    label = '',
    placeholder = 'Type to search',
    TextFieldProps,
    helperText,
    ...data
  } = props;

  return (
    <div style={{ ...styles.defaultStyle, ...style }}>
      <MUIAutocomplete
        {...data}
        classes={{
          popupIndicator: classes.popupIndicator,
          clearIndicator: classes.clearIndicator,
        }}
        fullWidth={fullWidth}
        getOptionLabel={getOptionLabel}
        autoHighlight
        disabled={disabled}
        // set disableClearable and freeSolo true when loading
        // to remove internal end adornments
        disableClearable={loading}
        freeSolo={loading}
        renderInput={params => (
          <TextField
            {...params}
            {...TextFieldProps}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              ...(loading && {
                endAdornment: <Progress size={16} />,
              }),
            }}
          />
        )}
        renderOption={(propOptions, option) => {
          return (
            <li {...propOptions}>
              <ListItemText>
                <span style={styles.option}>{option}</span>
              </ListItemText>
            </li>
          );
        }}
      />
    </div>
  );
}

export default SelectAutocomplete;
