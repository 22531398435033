import * as React from 'react';
import { Control, Fieldset } from 'react-redux-form';
import * as R from 'ramda';

import DatePicker from '../../common/DatePicker';

import { filterStyle } from './attributeStyle';

import './attributeControls.css';

interface WrapperProps {
  onChange: (date: any, property: string) => void;
  value: any;
  name: string;
  title: string;
}

export class DatePickerWrapper extends React.Component<WrapperProps> {
  onChange = (property, date) => {
    return this.props.onChange(date, property);
  };

  render() {
    return (
      <DatePicker
        {...this.props}
        onDataChange={this.onChange}
        valueMillis={this.props.value}
      />
    );
  }
}

interface Props {
  value: any;
  name: string;
  title: string;
}

interface State {
  from: any;
  to: any;
}

class DateAttribute extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      from: R.pathOr({}, ['value', 'from'], props),
      to: R.pathOr({}, ['value', 'to'], props),
    };
  }

  onChange = (date, property) => {
    // @ts-ignore
    this.setState({ [property]: date });
  };

  render() {
    const { name, title } = this.props;
    const { from, to } = this.state;

    return (
      <Fieldset key={name} model={`.${name}`}>
        <div styleName="range-container">
          <div styleName="title">{title}:</div>
          <div styleName="half">
            {/* 
              // @ts-ignore */}
            <Control
              model=".from"
              component={DatePickerWrapper}
              value={from}
              onChange={date => this.onChange(date, 'from')}
              mapProps={{
                name: 'from',
                fullWidth: true,
                hintText: 'mm/dd/yyy',
                floatingLabelFixed: true,
                floatingLabelText: 'From',
                underlineStyle: filterStyle.underlineStyle,
                textFieldStyle: filterStyle.textFieldStyle,
                style: filterStyle.datePicker,
                floatingLabelStyle: filterStyle.floatingLabelStyle,
              }}
            />
          </div>
          <div styleName="half">
            {/* 
              // @ts-ignore */}
            <Control
              model=".to"
              component={DatePickerWrapper}
              value={to}
              onChange={date => this.onChange(date, 'to')}
              mapProps={{
                name: 'to',
                fullWidth: true,
                hintText: 'mm/dd/yyy',
                floatingLabelFixed: true,
                floatingLabelText: 'To',
                underlineStyle: filterStyle.underlineStyle,
                textFieldStyle: filterStyle.textFieldStyle,
                style: filterStyle.datePicker,
                floatingLabelStyle: filterStyle.floatingLabelStyle,
              }}
            />
          </div>
        </div>
      </Fieldset>
    );
  }
}

export default DateAttribute;
