import React from 'react';

import { Task } from '@atom/types/task';
import { TaskLocation } from '@atom/types/taskLocation';
import { WorkOrderType } from '@atom/types/work';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import CustomFieldsSection from './CustomFieldsSection';
import ReportAssetsSection from './ReportAssetsSection';
import ReportLocationsSection from './ReportLocationsSection';
import ReportMaterials from './ReportMaterials';
import ReportSection from './ReportSection';
import ReportUsers from './ReportUsers';
import {
  EMPTY_TASK,
  getFilteredMaterials,
  getFilteredUsers,
  getNotesField,
  getSectionActualCost,
  ReportTaskMaterials,
  ReportUser,
} from './reportUtilities';

import './workOrderReport.css';

interface Props {
  workType: WorkOrderType;
  task: Task;
  isCompletedOrAbove: boolean;
  reportUsers: ReportUser[];
  reportMaterials: ReportTaskMaterials;
  reportAssets: any[];
  reportLocations: TaskLocation[];
}

const TaskDetailReport = ({
  workType,
  task,
  reportUsers,
  reportMaterials,
  reportAssets,
  reportLocations,
  isCompletedOrAbove,
}: Props) => {
  const filteredUsers = getFilteredUsers(reportUsers, isCompletedOrAbove);

  const filteredStartEndMaterials = getFilteredMaterials(
    reportMaterials?.startEndMaterials,
    isCompletedOrAbove,
  );

  const filteredUsageMaterials = getFilteredMaterials(
    reportMaterials?.usageMaterials,
    isCompletedOrAbove,
  );

  const actualCostData = getSectionActualCost(task);

  const showCustomFields = !isNilOrEmpty(task?.fields);
  const hideTaskName = task?.id === EMPTY_TASK.id;

  const notesField = getNotesField(task);
  const showCompletedNotesField =
    notesField && notesField?.value && isCompletedOrAbove;

  const notesSectionHeight = isNilOrEmpty(task?.fields) ? '9rem' : '4.5rem';

  const showLocationsSection =
    workType === WorkOrderType.TASK_ASSETS_AND_LOCATIONS &&
    (task.id === EMPTY_TASK.id || task?.locationsEnabled);

  return (
    <div styleName="task-container">
      <div styleName="task-header">
        {!hideTaskName && (
          <div>
            <div styleName="task-title">{task?.name}</div>
          </div>
        )}
        {isCompletedOrAbove && (
          <div styleName="actual-cost-container">
            Actual Cost:
            <span styleName="actual-cost-value">
              {numberToLocaleString(task?.actualCost)}
            </span>
          </div>
        )}
      </div>
      {showCustomFields && (
        <CustomFieldsSection
          isCompletedOrAbove={isCompletedOrAbove}
          task={task}
        />
      )}
      <ReportSection
        title="Team"
        isCompletedOrAbove={isCompletedOrAbove}
        actualCost={actualCostData.team}
      >
        <ReportUsers
          users={filteredUsers}
          isCompletedOrAbove={isCompletedOrAbove}
        />
      </ReportSection>
      <ReportSection
        title="Equipment and Material"
        isCompletedOrAbove={isCompletedOrAbove}
        actualCost={actualCostData.material}
      >
        <ReportMaterials
          startEndMaterials={filteredStartEndMaterials}
          usageMaterials={filteredUsageMaterials}
          isCompletedOrAbove={isCompletedOrAbove}
        />
      </ReportSection>
      {showLocationsSection && (
        <ReportSection title="Locations">
          <ReportLocationsSection reportLocations={reportLocations} />
        </ReportSection>
      )}
      <ReportSection title="Assets">
        <ReportAssetsSection reportAssets={reportAssets} />
      </ReportSection>
      {!isCompletedOrAbove && (
        <ReportSection title="Notes" emptyHeight={notesSectionHeight} />
      )}
      {showCompletedNotesField && (
        <ReportSection title="Notes">
          <div styleName="basic-container">{notesField?.value}</div>
        </ReportSection>
      )}
    </div>
  );
};

export default TaskDetailReport;
