/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  Autocomplete as MUIAutocomplete,
  AutocompleteProps as MUIAutocompleteProps,
  Popper as MUIPopper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Icon, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';

export type AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> = {
  label?: string;
  labelStyle?: React.CSSProperties;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  /**
   * popperAutoWidth if true will cause the select list's
   * width to grow dynamically with the content of each list item
   */
  popperAutoWidth?: boolean;
  autoFocus?: boolean;
  error?: boolean;
  errorHelperText?: string;
} & Omit<
  MUIAutocompleteProps<T, Multiple, DisableClearable, true>,
  'autoComplete' | 'renderInput'
>;

const useStyles = makeStyles(theme => ({
  clear: {
    top: 'calc(50% - 18px)',
  },
  clearIndicator: {
    padding: '4px',
  },
  inputLabel: (props: any) => props.labelStyle || null,
  inputLabelFocused: {
    color: `${colors.neutral.gray} !important`,
  },
  inputLabelError: {
    color: `${theme.palette.error.main} !important`,
  },
  group: {
    color: colors.neutral.gray,
    textTransform: 'uppercase',
    fontSize: fonts.sm,
  },
}));

const styles = {
  popper: {
    maxWidth: 'fit-content',
  },
};

export function Autocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
>(props: AutocompleteProps<T, Multiple, DisableClearable>) {
  const classes = useStyles(props);

  const {
    label,
    placeholder,
    startAdornment,
    endAdornment,
    disableClearable,
    clearIcon,
    popperAutoWidth,
    autoFocus,
    error,
    errorHelperText,
    labelStyle: _,
    ...data
  } = props;

  return (
    <MUIAutocomplete<T, Multiple, DisableClearable, true>
      freeSolo
      disableClearable={
        disableClearable ?? (!!endAdornment as DisableClearable)
      }
      {...(popperAutoWidth && {
        PopperComponent: popperProps => (
          <MUIPopper
            {...popperProps}
            style={styles.popper}
            placement="bottom-start"
          />
        ),
      })}
      renderInput={params => (
        <TextField
          {...params}
          error={error}
          helperText={error && errorHelperText}
          autoFocus={autoFocus}
          label={label}
          placeholder={placeholder}
          InputLabelProps={{
            classes: {
              // @ts-ignore
              ...params.InputLabelProps?.classes,
              root: classes.inputLabel,
              focused: classes.inputLabelFocused,
              error: classes.inputLabelError,
            },
            ...params.InputLabelProps,
          }}
          InputProps={{
            ...params.InputProps,
            ...(!!startAdornment && {
              startAdornment,
            }),
            ...(!!endAdornment && {
              endAdornment,
            }),
          }}
        />
      )}
      clearIcon={clearIcon || <Icon>close</Icon>}
      {...data}
      classes={{
        groupLabel: classes.group,
        // styles for "clearable" icon
        // not applied if custom endAdornment is provided
        ...(!endAdornment && {
          endAdornment: classes.clear,
        }),
        clearIndicator: classes.clearIndicator,
        ...props.classes,
      }}
    />
  );
}

export default Autocomplete;
