import * as R from 'ramda';
import { call, fork, put, takeEvery, throttle } from 'redux-saga/effects';

import actionTypes from '@atom/actions/actionTypes';
import * as inventorySchemaActions from '@atom/actions/inventorySchemaActions';
import textConstants from '@atom/constants/textConstants';
import { Snackbar } from '@atom/mui';
import accountUtilities from '@atom/utilities/accountUtilities';
import api from '@atom/utilities/api';
import {
  getSchemaAttributeGroupsEndpoint,
  getSchemaAttributesEndpoint,
  getSchemaGroupsEndpoint,
  INVENTORY_ASSETS_ENDPOINT,
  INVENTORY_SCHEMAS_ENDPOINT,
} from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';

export function* getInventorySchemaDetail({ data: { id } }) {
  try {
    const endpoint = `${INVENTORY_SCHEMAS_ENDPOINT}/${id}`;
    const { data } = yield call(api.get, endpoint);

    yield put(inventorySchemaActions.getInventorySchemaSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.getInventorySchemaFailure,
    );
  }
}

export function* getFullInventorySchemaDetail({ data: { id } }) {
  try {
    const endpoint = `${INVENTORY_SCHEMAS_ENDPOINT}/${id}/fulltree`;

    const { data } = yield call(api.get, endpoint);

    yield put(inventorySchemaActions.getSchemaDetailSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.getSchemaDetailFailure,
    );
  }
}

export function* updateSchemaAttribute({ data: body }) {
  try {
    const { schemaId, attributeId, ...payload } = body;
    const endpoint = `${INVENTORY_SCHEMAS_ENDPOINT}/${schemaId}/attributes/${attributeId}`;
    const { data } = yield call(api.patch, endpoint, payload);

    yield put(inventorySchemaActions.schemaAttributeUpdateSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.schemaAttributeUpdateFailure,
    );
  }
}

export function* getInventorySchemaByAssetIdDetail({ data: { id } }) {
  try {
    const assetEndpoint = `${INVENTORY_ASSETS_ENDPOINT}/${id}`;

    const {
      data: { schemaId },
    } = yield call(api.get, assetEndpoint);

    const schemaEndpoint = `${INVENTORY_SCHEMAS_ENDPOINT}/${schemaId}`;

    const { data } = yield call(api.get, schemaEndpoint);

    yield put(inventorySchemaActions.getInventorySchemaSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.getInventorySchemaFailure,
    );
  }
}

export function* getInventorySchemasList({
  data: { rootSchemas, isPublished },
}) {
  try {
    const params = {
      ...(rootSchemas ? { rootSchemas } : {}),
      ...(isPublished ? { isPublished } : {}),
    };

    const { data } = yield call(api.get, INVENTORY_SCHEMAS_ENDPOINT, params);

    yield put(inventorySchemaActions.getInventorySchemasSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.getInventorySchemasFailure,
    );
  }
}

export function* getSchemasByIds({
  data: { parentSchemaId, fieldId, isPublished },
}) {
  try {
    const params = {
      ...(parentSchemaId ? { parentSchemaId } : {}),
      ...(isPublished ? { isPublished } : {}),
    };

    const { data } = yield call(api.get, INVENTORY_SCHEMAS_ENDPOINT, params);

    yield put(
      inventorySchemaActions.getSchemasByIdsSuccess({ schemas: data, fieldId }),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.getSchemasByIdsFailure,
    );
  }
}

export function* getInventorySchemaTreeNode({ data: { id, elementPath } }) {
  try {
    const endpoint = `${INVENTORY_SCHEMAS_ENDPOINT}/${id}`;
    const {
      data: { elementGroups },
    } = yield call(api.get, endpoint);
    const data = {
      elementGroups,
      elementPath,
    };

    yield put(inventorySchemaActions.getInventorySchemaTreeNodeSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.getInventorySchemaTreeNodeFailure,
    );
  }
}

export function* getSchemaTree({ data: { id } }) {
  try {
    const endpoint = `${INVENTORY_SCHEMAS_ENDPOINT}/${id}/tree`;
    const { data } = yield call(api.get, endpoint);

    yield put(inventorySchemaActions.getSchemaTreeSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.getSchemaTreeFailure,
    );
  }
}

export function* updateSchemaTree({ data }) {
  try {
    yield put(inventorySchemaActions.updateSchemaTreeSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.updateSchemaTreeFailure,
    );
  }
}

export function* createSchema(action) {
  try {
    const endpoint = INVENTORY_SCHEMAS_ENDPOINT;
    const { data } = yield call(api.post, endpoint, action.data);

    yield put(inventorySchemaActions.createSchemaSuccess(data));

    if (!R.isNil(action.data.colorId)) {
      // @ts-ignore
      yield call(history.push, `/admin/inventoryTypes/${data.id}`);
    }
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.createSchemaFailure,
    );
  }
}

export function* updateSchema(action) {
  try {
    const { schemaId, ...body } = action.data;
    const endpoint = `${INVENTORY_SCHEMAS_ENDPOINT}/${schemaId}`;

    const { data } = yield call(api.patch, endpoint, body);

    yield put(inventorySchemaActions.updateSchemaSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.updateSchemaFailure,
    );
  }
}

export function* updateGroup(action) {
  try {
    const { groupId, rootSchemaId, name } = action.data;
    const baseEndpoint = getSchemaGroupsEndpoint(rootSchemaId);
    const endpoint = `${baseEndpoint}/${groupId}`;

    const body = {
      rootSchemaId,
      name,
    };

    const { data } = yield call(api.patch, endpoint, body);

    yield put(inventorySchemaActions.updateGroupSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.updateGroupFailure,
    );
  }
}

export function* deleteSchema(action) {
  try {
    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
    });

    const { schemaId, rootSchemaId, detail } = action.data;

    const endpoint = `${INVENTORY_SCHEMAS_ENDPOINT}/${schemaId}`;
    const params = {
      ...(rootSchemaId ? { rootSchemaId } : {}),
    };

    const response = yield call(api.delete, endpoint, params);

    const data = {
      schemaId,
      schema: response.data,
    };

    yield put(inventorySchemaActions.deleteSchemaSuccess(data));

    if (detail) {
      // @ts-ignore
      yield call(history.push, '/admin/inventoryTypes');
    }

    Snackbar.info({
      message: 'Successfully deleted inventory type.',
    });
  } catch (error) {
    if (error.response && error.response.status === 422) {
      Snackbar.warning({
        message:
          'Work is associated with an inventory asset of this inventory type. Deletion is not allowed.',
      });
    } else {
      Snackbar.error({
        message: 'Failed to delete inventory type.',
      });
    }
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.deleteSchemaFailure,
    );
  }
}

export function* deleteGroup(action) {
  try {
    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
    });

    const { groupId, rootSchemaId } = action.data;

    const baseEndpoint = getSchemaGroupsEndpoint(rootSchemaId);

    const endpoint = `${baseEndpoint}/${groupId}`;
    const params = { rootSchemaId };

    const { data } = yield call(api.delete, endpoint, params);

    yield put(inventorySchemaActions.deleteGroupSuccess(data));

    Snackbar.info({
      message: 'Successfully deleted group.',
    });
  } catch (error) {
    Snackbar.error({
      message: 'Failed to delete group.',
    });

    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.deleteGroupFailure,
    );
  }
}

export function* createAttributeGroup(action) {
  try {
    const { rootSchemaId, schemaId, name } = action.data;

    const baseEndpoint = getSchemaAttributeGroupsEndpoint(schemaId);

    const body = { rootSchemaId, name };
    const { data } = yield call(api.post, baseEndpoint, body);

    yield put(inventorySchemaActions.createAttributeGroupSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.createAttributeGroupFailure,
    );
  }
}

export function* updateAttributeGroup(action) {
  try {
    const { rootSchemaId, schemaId, attributeGroupId, name } = action.data;

    const baseEndpoint = getSchemaAttributeGroupsEndpoint(schemaId);

    const endpoint = `${baseEndpoint}/${attributeGroupId}`;
    const body = { rootSchemaId, name };

    const { data } = yield call(api.patch, endpoint, body);

    yield put(inventorySchemaActions.updateAttributeGroupSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.updateAttributeGroupFailure,
    );
  }
}

export function* deleteAttributeGroup(action) {
  try {
    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
    });

    const { rootSchemaId, schemaId, attributeGroupId } = action.data;

    const baseEndpoint = getSchemaAttributeGroupsEndpoint(schemaId);

    const endpoint = `${baseEndpoint}/${attributeGroupId}`;
    const params = { rootSchemaId };

    const { data } = yield call(api.delete, endpoint, params);

    yield put(inventorySchemaActions.deleteAttributeGroupSuccess(data));

    Snackbar.info({
      message: 'Successfully deleted attribute group.',
    });
  } catch (error) {
    Snackbar.error({
      message: 'Failed to delete attribute group.',
    });

    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.deleteAttributeGroupFailure,
    );
  }
}

export function* createAttribute(action) {
  try {
    const {
      rootSchemaId,
      schemaId,
      attributeGroupId,
      ...payload
    } = action.data;

    const baseEndpoint = getSchemaAttributesEndpoint(
      schemaId,
      attributeGroupId,
    );

    const body = { rootSchemaId, ...payload };
    const { data } = yield call(api.post, baseEndpoint, body);

    yield put(inventorySchemaActions.createAttributeSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.createAttributeFailure,
    );
  }
}

export function* updateAttribute(action) {
  try {
    const {
      rootSchemaId,
      schemaId,
      attributeGroupId,
      attributeId,
      ...payload
    } = action.data;

    const baseEndpoint = getSchemaAttributesEndpoint(
      schemaId,
      attributeGroupId,
    );

    const endpoint = `${baseEndpoint}/${attributeId}`;
    const body = { rootSchemaId, ...payload };

    const { data } = yield call(api.patch, endpoint, body);

    yield put(inventorySchemaActions.updateAttributeSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.updateAttributeFailure,
    );
  }
}

export function* deleteAttribute(action) {
  try {
    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
    });

    const {
      rootSchemaId,
      schemaId,
      attributeGroupId,
      attributeId,
    } = action.data;

    const baseEndpoint = getSchemaAttributesEndpoint(
      schemaId,
      attributeGroupId,
    );

    const endpoint = `${baseEndpoint}/${attributeId}`;
    const params = { rootSchemaId };

    const { data } = yield call(api.delete, endpoint, params);

    yield put(inventorySchemaActions.deleteAttributeSuccess(data));

    Snackbar.info({
      message: 'Successfully deleted attribute.',
    });
  } catch (error) {
    Snackbar.error({
      message: 'Failed to delete attribute.',
    });

    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      inventorySchemaActions.deleteAttributeFailure,
    );
  }
}

export function* clearInventorySchema() {
  yield put(inventorySchemaActions.clearInventorySchemaSuccess());
}

/* WATCHERS */

export function* retrieveInventorySchema() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_INVENTORY_SCHEMA,
    getInventorySchemaDetail,
  );
}

export function* requestSchemaAttributeUpdate() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_SCHEMA_ATTRIBUTE_UPDATE,
    updateSchemaAttribute,
  );
}

export function* retrieveInventorySchemaDetail() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_SCHEMA_DETAIL,
    getFullInventorySchemaDetail,
  );
}

export function* retrieveInventorySchemaByAssetId() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_INVENTORY_SCHEMA_BY_ASSET_ID,
    getInventorySchemaByAssetIdDetail,
  );
}

export function* retrieveInventorySchemas() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_INVENTORY_SCHEMAS,
    getInventorySchemasList,
  );
}

export function* retrieveSchemasByIds() {
  // @ts-ignore
  yield takeEvery(actionTypes.REQUEST_SCHEMA_BY_IDS, getSchemasByIds);
}

export function* retrieveInventorySchemaTreeNode() {
  yield takeEvery(
    // @ts-ignore
    actionTypes.REQUEST_INVENTORY_SCHEMA_TREE_NODE,
    getInventorySchemaTreeNode,
  );
}

export function* retrieveSchemaTree() {
  // @ts-ignore
  yield takeEvery(actionTypes.REQUEST_SCHEMA_TREE, getSchemaTree);
}

export function* requestUpdateSchemaTree() {
  // @ts-ignore
  yield takeEvery(actionTypes.REQUEST_SCHEMA_TREE_UPDATE, updateSchemaTree);
}

export function* requestCreateSchema() {
  yield takeEvery(actionTypes.REQUEST_CREATE_SCHEMA, createSchema);
}

export function* requestDeleteSchema() {
  yield takeEvery(actionTypes.REQUEST_DELETE_SCHEMA, deleteSchema);
}

export function* requestDeleteGroup() {
  yield takeEvery(actionTypes.REQUEST_DELETE_GROUP, deleteGroup);
}

export function* requestUpdateSchema() {
  yield takeEvery(actionTypes.REQUEST_UPDATE_SCHEMA, updateSchema);
}

export function* requestUpdateGroup() {
  yield takeEvery(actionTypes.REQUEST_UPDATE_GROUP, updateGroup);
}

export function* requestCreateAttributeGroup() {
  yield takeEvery(
    actionTypes.REQUEST_CREATE_ATTRIBUTE_GROUP,
    createAttributeGroup,
  );
}

export function* requestUpdateAttributeGroup() {
  yield takeEvery(
    actionTypes.REQUEST_UPDATE_ATTRIBUTE_GROUP,
    updateAttributeGroup,
  );
}

export function* requestDeleteAttributeGroup() {
  yield takeEvery(
    actionTypes.REQUEST_DELETE_ATTRIBUTE_GROUP,
    deleteAttributeGroup,
  );
}

export function* requestCreateAttribute() {
  yield takeEvery(actionTypes.REQUEST_CREATE_ATTRIBUTE, createAttribute);
}

export function* requestUpdateAttribute() {
  yield throttle(500, actionTypes.REQUEST_UPDATE_ATTRIBUTE, updateAttribute);
}

export function* requestDeleteAttribute() {
  yield takeEvery(actionTypes.REQUEST_DELETE_ATTRIBUTE, deleteAttribute);
}

export function* requestInventorySchemaClear() {
  yield takeEvery(
    actionTypes.REQUEST_INVENTORY_SCHEMA_CLEAR,
    clearInventorySchema,
  );
}

export default [
  retrieveInventorySchema,
  requestSchemaAttributeUpdate,
  retrieveInventorySchemaDetail,
  retrieveInventorySchemaByAssetId,
  retrieveInventorySchemas,
  retrieveInventorySchemaTreeNode,
  retrieveSchemaTree,
  requestUpdateSchemaTree,
  retrieveSchemasByIds,
  requestCreateSchema,
  requestDeleteSchema,
  requestUpdateSchema,
  requestUpdateGroup,
  requestDeleteGroup,
  requestCreateAttributeGroup,
  requestUpdateAttributeGroup,
  requestDeleteAttributeGroup,
  requestCreateAttribute,
  requestUpdateAttribute,
  requestDeleteAttribute,
  requestInventorySchemaClear,
];
