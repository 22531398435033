import { call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import actionTypes from '@atom/actions/actionTypes';
import {
  createMediaFolderFailure,
  createMediaFolderSuccess,
  deleteMediaFailure,
  deleteMediaFolderFailure,
  deleteMediaFolderSuccess,
  deleteMediaSuccess,
  getMediaFailure,
  getMediaFoldersFailure,
  getMediaFoldersSuccess,
  getMediaFolderTreeFailure,
  getMediaFolderTreeSuccess,
  getMediaSuccess,
  getMediaTotalsFailure,
  getMediaTotalsSuccess,
  getMediaTotalSuccess,
  patchMediaFailure,
  patchMediaSuccess,
  retrieveMedia,
  retrieveMediaFolders,
  updateMediaFolderFailure,
  updateMediaFolderSuccess,
  updateMediaFolderTreeSuccess,
} from '@atom/actions/mediaActions';
import textConstants from '@atom/constants/textConstants';
import { Snackbar } from '@atom/mui';
import accountUtilities from '@atom/utilities/accountUtilities';
import api from '@atom/utilities/api';
import { MEDIA_ENDPOINT } from '@atom/utilities/endpoints';
import { TOTAL_COUNT_HEADER } from '@atom/utilities/requestUtilities';

export function* getMediaTotals(action) {
  try {
    const { subjectIds, parentSubjectIds } = action.data;

    const params = {
      ...(subjectIds ? { subjectIds } : {}),
      ...(parentSubjectIds ? { parentSubjectIds } : {}),
    };

    const documents = yield call(api.head, MEDIA_ENDPOINT, {
      ...params,
      type: 'document',
    });

    const images = yield call(api.head, MEDIA_ENDPOINT, {
      ...params,
      type: 'image',
    });

    const videos = yield call(api.head, MEDIA_ENDPOINT, {
      ...params,
      type: 'video',
    });

    const data = {
      document: {
        count: documents.headers[TOTAL_COUNT_HEADER],
        name: 'documents',
      },
      image: { count: images.headers[TOTAL_COUNT_HEADER], name: 'photos' },
      video: { count: videos.headers[TOTAL_COUNT_HEADER], name: 'videos' },
    };

    yield put(getMediaTotalsSuccess(data));
  } catch (error) {
    yield fork(accountUtilities.apiErrorHandler, error, getMediaTotalsFailure);
  }
}

export function* getMedia(action) {
  try {
    const { data, headers } = yield call(api.get, MEDIA_ENDPOINT, action.data);

    yield put(getMediaSuccess(data));
    yield put(getMediaTotalSuccess(Number(headers[TOTAL_COUNT_HEADER])));
  } catch (error) {
    yield fork(accountUtilities.apiErrorHandler, error, getMediaFailure);
  }
}

export function* getMediaFolders(action) {
  try {
    const { subjectId, parentFolderId, name } = action.data;
    const endpoint = `${MEDIA_ENDPOINT}/folders`;
    const params = {
      ...(subjectId ? { subjectId } : {}),
      ...(name ? { name } : {}),
      ...(parentFolderId ? { parentFolderId } : {}),
    };

    const { data } = yield call(api.get, endpoint, params);

    yield put(getMediaFoldersSuccess(data));
  } catch (error) {
    yield fork(accountUtilities.apiErrorHandler, error, getMediaFoldersFailure);
  }
}

export function* createMediaFolder(action) {
  try {
    const { parentFolderId, folderPath, name, subjectId } = action.data;
    const endpoint = `${MEDIA_ENDPOINT}/folders`;
    const body = {
      name,
      subjectId,
      ...(parentFolderId && parentFolderId !== 'root'
        ? { parentFolderId }
        : {}),
    };

    const { data } = yield call(api.post, endpoint, body);
    yield put(createMediaFolderSuccess({ folderData: data, folderPath }));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      createMediaFolderFailure,
    );
  }
}

export function* deleteMediaFolder(action) {
  try {
    const {
      folderId,
      folderPath,
      subjectId,
      subjectType,
      selectedFolder,
    } = action.data;
    const endpoint = `${MEDIA_ENDPOINT}/folders/${folderId}`;

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
    });

    yield call(api.delete, endpoint);

    if (subjectType === 'inventoryAsset') {
      const mediaAction = {
        type: null,
        data: {
          parentSubjectIds: subjectId,
          subjectTypes: subjectType,
        },
      };
      yield call(getMediaTotals, mediaAction);
    }

    yield put(deleteMediaFolderSuccess({ folderId, folderPath }));
    if (folderId === selectedFolder) {
      yield put(
        retrieveMedia({
          parentSubjectIds: subjectId,
          folderId: 'root',
        }),
      );
      yield put(retrieveMediaFolders({ subjectId }));
    }

    Snackbar.info({
      message: 'Successfully deleted folder',
    });
  } catch (error) {
    Snackbar.error({
      message: 'Failed to delete folder',
    });

    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      deleteMediaFolderFailure,
    );
  }
}

export function* updateMediaFolder(action) {
  try {
    const { folderId, folderPath, name } = action.data;
    const endpoint = `${MEDIA_ENDPOINT}/folders/${folderId}`;
    const body = { name };

    const { data } = yield call(api.patch, endpoint, body);
    yield put(updateMediaFolderSuccess({ folderData: data, folderPath }));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      updateMediaFolderFailure,
    );
  }
}

export function* getMediaFolderTree(action) {
  try {
    const endpoint = `${MEDIA_ENDPOINT}/folders/tree`;
    const { data } = yield call(api.get, endpoint, action.data);
    yield put(getMediaFolderTreeSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      getMediaFolderTreeFailure,
    );
  }
}

export function* updateMediaFolderTree(action) {
  yield put(updateMediaFolderTreeSuccess(action.data));
}

export function* patchMedia(action) {
  try {
    const { id } = action.data;
    const endpoint = `${MEDIA_ENDPOINT}/${id}`;
    const { data } = yield call(api.patch, endpoint, action.data);
    yield put(patchMediaSuccess(data));
  } catch (error) {
    yield fork(accountUtilities.apiErrorHandler, error, patchMediaFailure);
  }
}

export function* deleteMedia(action) {
  try {
    const { id, subjectId, subjectType } = action.data;
    const endpoint = `${MEDIA_ENDPOINT}/${id}`;

    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
    });

    yield call(api.delete, endpoint);

    if (subjectType === 'inventoryAsset') {
      const mediaAction = {
        type: null,
        data: {
          parentSubjectIds: subjectId,
          subjectTypes: subjectType,
        },
      };
      yield call(getMediaTotals, mediaAction);
    }

    yield put(deleteMediaSuccess(id));

    Snackbar.info({
      message: 'Successfully deleted file',
    });
  } catch (error) {
    Snackbar.error({
      message: 'Failed to delete file',
    });

    yield fork(accountUtilities.apiErrorHandler, error, deleteMediaFailure);
  }
}

/* WATCHERS */
export function* requestMedia() {
  yield takeEvery(actionTypes.REQUEST_MEDIA, getMedia);
}

export function* requestMediaFolders() {
  yield takeEvery(actionTypes.REQUEST_MEDIA_FOLDERS, getMediaFolders);
}

export function* requestMediaFolderCreate() {
  yield takeEvery(actionTypes.REQUEST_MEDIA_FOLDER_CREATE, createMediaFolder);
}

export function* requestMediaFolderDelete() {
  yield takeEvery(actionTypes.REQUEST_MEDIA_FOLDER_DELETE, deleteMediaFolder);
}

export function* requestMediaFolderUpdate() {
  yield takeEvery(actionTypes.REQUEST_MEDIA_FOLDER_UPDATE, updateMediaFolder);
}

export function* retrieveMediaTotals() {
  yield takeEvery(actionTypes.REQUEST_MEDIA_TOTALS, getMediaTotals);
}

export function* retrieveMediaFolderTree() {
  yield takeLatest(actionTypes.REQUEST_MEDIA_FOLDER_TREE, getMediaFolderTree);
}

export function* requestMediaFolderTreeUpdate() {
  yield takeLatest(
    actionTypes.REQUEST_MEDIA_FOLDER_TREE_UPDATE,
    updateMediaFolderTree,
  );
}

export function* requestPatchMedia() {
  yield takeLatest(actionTypes.REQUEST_PATCH_MEDIA, patchMedia);
}

export function* requestDeleteMedia() {
  yield takeEvery(actionTypes.REQUEST_DELETE_MEDIA, deleteMedia);
}

export default [
  requestMedia,
  requestMediaFolders,
  requestMediaFolderUpdate,
  requestMediaFolderCreate,
  requestMediaFolderDelete,
  retrieveMediaTotals,
  retrieveMediaFolderTree,
  requestMediaFolderTreeUpdate,
  requestPatchMedia,
  requestDeleteMedia,
];
