import React from 'react';
import * as R from 'ramda';

import { RadioGroup } from '@atom/mui';

import { BooleanOrientation } from './DataTypeInput';

const { RadioButton } = RadioGroup;

enum BooleanOption {
  YES = 'YES',
  NO = 'NO',
}

interface Props {
  id: string;
  value: any;
  onChange: (value: any) => void;
  disabled: boolean;
  booleanOrientation: BooleanOrientation;
  dataCyPrefix: string;
}

const BooleanInput = ({
  id,
  value,
  onChange,
  disabled,
  booleanOrientation = BooleanOrientation.VERTICAL,
  dataCyPrefix,
}: Props) => {
  const handleChange = event => {
    onChange(event.target.value === BooleanOption.YES);
  };

  const currentValue = R.isNil(value)
    ? null
    : value
    ? BooleanOption.YES
    : BooleanOption.NO;
  const isRow = booleanOrientation === BooleanOrientation.HORIZONTAL;

  return (
    <RadioGroup
      key={id}
      value={currentValue}
      onChange={handleChange}
      row={isRow}
      data-cy={`${dataCyPrefix}BooleanInput`}
    >
      <RadioButton value={BooleanOption.YES} label="Yes" disabled={disabled} />
      <RadioButton value={BooleanOption.NO} label="No" disabled={disabled} />
    </RadioGroup>
  );
};

export default BooleanInput;
