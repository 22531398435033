import React from 'react';
import { Marker, Polyline } from '@react-google-maps/api';
import * as R from 'ramda';

type Props = {
  stringProps: string;
  click: Function;
};

type Params = {
  id: string;
  icon: google.maps.Icon;
  position: google.maps.LatLngLiteral;
  path: google.maps.LatLng[];
  zIndex: number;
  size: any;
  onClick: any;
  isActive: boolean;
  color: string;
  clickable: boolean;
  polylineOptions?: any;
  disabled?: boolean;
};

const MapShapeMemo = (props: Props) => {
  const params: Params = JSON.parse(props.stringProps);
  const opacity = params?.disabled ? 0.5 : 1;

  return (
    <>
      <Marker
        {...params}
        onClick={() => {
          props.click(params.id);
        }}
        zIndex={params.zIndex || (params.isActive ? 502 : 498)}
        opacity={opacity}
      />
      {params.path && (
        <>
          <Polyline
            {...params}
            options={{
              ...params,
              strokeWeight: params.isActive ? 10 : 3,
              strokeColor: params.color,
              zIndex: params.isActive ? 500 : 496,
              ...params.polylineOptions,
            }}
            onClick={() => {
              props.click(params.id);
            }}
          />
          {params.isActive && (
            <>
              <Polyline
                {...params}
                options={{
                  strokeColor: '#FFFFFF',
                  strokeWeight: 14,
                  zIndex: 499,
                  clickable: params.clickable,
                }}
              />
              <Polyline
                {...params}
                options={{
                  strokeColor: '#1E88E5',
                  strokeWeight: 18,
                  strokeOpacity: 0.5,
                  zIndex: 498,
                  clickable: params.clickable,
                }}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
// @ts-ignore
export default React.memo(MapShapeMemo, R.eqProps('stringProps'));
