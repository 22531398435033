import React from 'react';

import { Icon, List } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventoryAssetDetailType } from '@atom/types/inventory';
import schemaUtilities from '@atom/utilities/schemaUtilities';

const { ListItem, ListItemText } = List;

interface Props {
  asset?: InventoryAssetDetailType;
  onClick?: () => void;
  onRemove?: () => void;
  requireAsset?: boolean;
}

const styles = {
  choose: {
    boxSizing: 'border-box',
    minHeight: '4.125rem',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    color: colors.brand.blue,
    cursor: 'pointer',
  },
  listItem: {
    minHeight: '4.125rem',
    marginLeft: '-6px',
    padding: 0,
  },
  icon: {
    cursor: 'pointer',
  },
};

const InventoryAssetTile = ({
  asset,
  onClick,
  onRemove,
  requireAsset,
}: Props) => {
  const displayText = requireAsset ? 'Add asset *' : 'Add asset';

  return asset ? (
    <ListItem disableGutters style={styles.listItem}>
      <img
        style={{ marginRight: '0.5rem' }}
        src={schemaUtilities.getSchemaIconFromMarkerId(asset.markerId)}
      />
      <ListItemText primary={asset.name} secondary={asset.assetType} />
      {onRemove && (
        <Icon style={styles.icon} onClick={onRemove}>
          close
        </Icon>
      )}
    </ListItem>
  ) : (
    <div onClick={onClick} style={styles.choose}>
      {displayText}
    </div>
  );
};

export default InventoryAssetTile;
