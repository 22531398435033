import React, { useEffect, useReducer, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import * as R from 'ramda';
import { ArrayParam, NumberParam, useQueryParams } from 'use-query-params';

import WorkOrderPreview from '@atom/components/workOrderPreview/WorkOrderPreview';
import WorkOrdersContext, {
  FilterSection,
  reducer,
} from '@atom/components/workOrders/WorkOrdersContext';
import { GET_WORK_ORDERS } from '@atom/graph/work';
import { GET_WORK_ORDER_COLUMNS } from '@atom/graph/workColumns';
import {
  GET_CUSTOM_FIELD_FILTERS,
  GET_WORK_FILTERS,
} from '@atom/graph/workFilters';
import { usePreferences } from '@atom/hooks/usePreferences';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import {
  INITIAL_INPUT,
  useWorkOrdersFilters,
  WorkOrdersInputState,
  WorkOrdersView,
} from '@atom/hooks/useWorkOrdersFilters';
import { Progress } from '@atom/mui';
import layout from '@atom/styles/layout';
import {
  WorkOrdersConnection,
  WorkOrdersConnectionInput,
} from '@atom/types/work';
import {
  presetDateFilterKeys,
  presetTaskDateFilterKeys,
  TaskDatePresets,
  WorkDatePresetKey,
  WorkDatePresets,
  WorkFiltersConnection,
  WorkFiltersConnectionInput,
  WorkFilterSource,
} from '@atom/types/workFilters';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import {
  applyDatePresetParam,
  DateRange,
  getDateKeyFromPreset,
  getDatePresets,
} from '@atom/utilities/workOrdersDateFilterUtilities';

import WorkOrdersFilters from './workOrdersFilters/WorkOrdersFilters';
import WorkOrdersCalendar from './WorkOrdersCalendar';
import WorkOrdersHeader from './WorkOrdersHeader';
import WorkOrdersTable from './WorkOrdersTable';

import './workOrders.css';

const TYPENAME = '__typename';

interface Props {
  variant: 'workOrders' | 'workExportDetail';
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  progress: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const QUERY_FILTER_PARAM_WHITELIST = {
  createdByIds: ArrayParam,
  workTemplateIds: ArrayParam,
  statusIds: ArrayParam,
  userIds: ArrayParam,
  createdDateStart: NumberParam,
  createdDateEnd: NumberParam,
  dueDateEnd: NumberParam,
};

const WorkOrders = ({ variant = 'workOrders' }: Props) => {
  const userProfile = useUserProfile();
  const preferences = usePreferences();
  const customFieldFilterIds =
    preferences?.workOrders?.filtering?.tasks?.fields;

  const [queryParamsToCheck, setQueryParamsToCheck] = useQueryParams(
    QUERY_FILTER_PARAM_WHITELIST,
  );

  const { input, setInput, workOrdersInput } = useWorkOrdersFilters();

  const [workOrdersInputCart, dispatch] = useReducer(
    reducer,
    {} as WorkOrdersInputState,
  );

  const [collapsedSections, setCollapsedSections] = useState<
    Set<FilterSection>
  >(new Set([]));

  const { data, loading: workOrderLoading, refetch } = useQuery<
    { workOrders: WorkOrdersConnection },
    { input: WorkOrdersConnectionInput }
  >(GET_WORK_ORDERS, {
    variables: {
      input: workOrdersInput,
    },
    fetchPolicy: 'no-cache',
  });

  const [
    getWorkFilters,
    {
      data: workFiltersData,
      loading: workFiltersLoading,
      refetch: refetchWorkFilters,
    },
  ] = useLazyQuery<{
    workFilters: WorkFiltersConnection;
    input: WorkFiltersConnectionInput;
  }>(GET_WORK_FILTERS, {
    onCompleted: completedData => {
      // Only set filter if a whitelisted query param is present
      const hasQueryParamFilters = R.values(queryParamsToCheck).some(
        param => !isNilOrEmpty(param),
      );

      let queryParamFilters = {};

      if (hasQueryParamFilters) {
        const sanitizedFilters = R.reject(R.isNil)(queryParamsToCheck);

        queryParamFilters = R.keys(sanitizedFilters).reduce(
          (acc, filter: string) => ({
            ...acc,
            [filter]: sanitizedFilters[filter],
          }),
          {},
        );

        setQueryParamsToCheck({});
      }

      const filters = completedData?.workFilters?.workFilters;
      const defaultFilter = R.find(R.propEq('isDefault', true), filters);
      const defaultParams = defaultFilter?.params || {};

      // Apply preset DateRange filters to workOrder input
      const workDatePresets: WorkDatePresets = R.pathOr(
        {},
        [WorkDatePresetKey.WORK],
        defaultFilter,
      );
      const taskDatePresets: TaskDatePresets = R.pathOr(
        {},
        [WorkDatePresetKey.TASK],
        defaultFilter,
      );

      defaultFilter.workDatePresets = defaultFilter.workDatePresets || {};
      defaultFilter.taskDatePresets = defaultFilter.taskDatePresets || {};

      // Fix existing custom date filters set before DateRange feature
      // - these are saved work filters with dates but no date preset selected
      // - detect and auto-select 'CUSTOM_RANGE'
      presetDateFilterKeys.forEach(key => {
        const selectedRange: DateRange = workDatePresets[key];
        const startKey = getDateKeyFromPreset(key, 'Start');
        const endKey = getDateKeyFromPreset(key, 'End');

        if (
          R.isNil(selectedRange) &&
          (defaultFilter.params[startKey] || defaultFilter.params[endKey])
        ) {
          workDatePresets[key] = DateRange.CUSTOM_RANGE;
          defaultFilter.workDatePresets[key] = DateRange.CUSTOM_RANGE;
        }

        applyDatePresetParam(
          R.pathOr({}, ['params'], defaultFilter),
          defaultParams,
          key,
          workDatePresets,
        );
      });

      // Task filters are one level deeper and do not contain "createdDatePreset"
      presetTaskDateFilterKeys.forEach(key => {
        const selectedRange: DateRange = taskDatePresets[key];
        const startKey = getDateKeyFromPreset(key, 'Start');
        const endKey = getDateKeyFromPreset(key, 'End');
        if (
          R.isNil(selectedRange) &&
          (defaultFilter?.params?.tasks?.[startKey] ||
            defaultFilter?.params?.tasks?.[endKey])
        ) {
          taskDatePresets[key] = DateRange.CUSTOM_RANGE;
          defaultFilter.taskDatePresets[key] = DateRange.CUSTOM_RANGE;
        }

        applyDatePresetParam(
          R.pathOr({}, ['params', 'tasks'], defaultFilter),
          R.pathOr({}, ['tasks'], defaultParams),
          key,
          taskDatePresets,
        );
      });

      const baseDateFilterPresetsCart = getDatePresets(defaultFilter);

      const params = hasQueryParamFilters
        ? queryParamFilters
        : R.omit([TYPENAME], defaultParams);

      const dateFilterPresetsCart = hasQueryParamFilters
        ? {
            ...baseDateFilterPresetsCart,
            workDatePresets: {
              ...baseDateFilterPresetsCart.workDatePresets,
              createdDatePreset: DateRange.CUSTOM_RANGE,
              dueDatePreset: DateRange.CUSTOM_RANGE,
            },
          }
        : baseDateFilterPresetsCart;

      setInput({
        ...INITIAL_INPUT,
        ...params,
        loadedDefault: true,
        defaultFilter,
        dateFilterPresetsCart,
      });
    },
    fetchPolicy: 'no-cache',
  });

  const [
    getCustomFieldFilters,
    { data: customFieldFiltersData, loading: customFieldFiltersLoading },
  ] = useLazyQuery(GET_CUSTOM_FIELD_FILTERS);

  const [
    getWorkOrderColumns,
    {
      data: workOrderColumnsData,
      loading: workOrderColumnsLoading,
      refetch: refetchColumns,
    },
  ] = useLazyQuery(GET_WORK_ORDER_COLUMNS);

  useEffect(() => {
    getWorkOrderColumns({
      variables: {
        workTemplateId:
          R.length(workOrdersInput?.workTemplateIds) === 1
            ? workOrdersInput?.workTemplateIds.join('')
            : 'none',
      },
    });
  }, [workOrdersInput?.workTemplateIds]);

  useEffect(() => {
    if (!isNilOrEmpty(customFieldFilterIds)) {
      getCustomFieldFilters({
        variables: {
          input: {
            ids: customFieldFilterIds,
            page: 1,
            limit: 250,
          },
        },
      });
    }
  }, [customFieldFilterIds]);

  useEffect(() => {
    if (!input?.loadedDefault) {
      getWorkFilters({
        variables: {
          input: {
            userId: userProfile.userId,
            source: WorkFilterSource.WEB,
          },
        },
      });
    }
  }, []);

  const workFilters = R.pathOr(
    [],
    ['workFilters', 'workFilters'],
    workFiltersData,
  );

  const filtersDisabled = variant === 'workExportDetail';
  const totalCount = R.pathOr(0, ['workOrders', 'totalCount'], data);
  const workOrders = R.pathOr([], ['workOrders', 'workOrders'], data);
  const workOrderColumns = R.pathOr(
    [],
    ['workOrderColumns', 'workOrderColumns'],
    workOrderColumnsData,
  );
  const customFieldFilters = R.pathOr(
    [],
    ['customFieldFilters', 'customFieldFilters'],
    customFieldFiltersData,
  );

  const getTablePaneWidth = () => {
    switch (true) {
      case input.workOrderId && input.expanded:
        return `calc(100% - ${layout.sideAndWorkPreviewWidth})`;
      case !!input.workOrderId:
        return `calc(100% - ${layout.workPreviewWidth})`;
      case input.expanded:
        return `calc(100% - ${layout.sidePaneWidth})`;
      default:
        return '100%';
    }
  };

  const rightPaneStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: getTablePaneWidth(),
  };

  const loadingFilters = customFieldFiltersLoading || workFiltersLoading;
  const loading = workOrderLoading || workOrderColumnsLoading || loadingFilters;

  return (
    <WorkOrdersContext.Provider
      value={{
        workOrders,
        workOrderColumns,
        customFieldFilters,
        refetchColumns,
        totalCount,
        loading,
        workFilters,
        refetchWorkFilters,
        loadingFilters,
        input,
        setInput,
        workOrdersInputCart,
        dispatch,
        refetch,
        filtersDisabled,
        collapsedSections,
        setCollapsedSections,
        getWorkFilters,
      }}
    >
      {variant === 'workOrders' && <WorkOrdersHeader />}
      <div style={styles.container}>
        <WorkOrdersFilters />
        <div style={rightPaneStyle}>
          {loading && input.view === WorkOrdersView.LIST ? (
            <div style={styles.progress}>
              <Progress />
            </div>
          ) : input.view === WorkOrdersView.LIST ? (
            <WorkOrdersTable />
          ) : (
            <WorkOrdersCalendar />
          )}
        </div>
        {input.workOrderId && <WorkOrderPreview />}
      </div>
    </WorkOrdersContext.Provider>
  );
};

export default WorkOrders;
