import { format } from 'date-fns';
import * as R from 'ramda';

import { ReportOption } from '@atom/components/workOrderDetail/workOrderDetailHeader/reportOptions';
import { WorkOrderDetailType } from '@atom/types/work';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import { getWorkOrderStatusType } from '@atom/utilities/workOrderStatusUtilities';

export const getWorkReportBody = (
  name: string,
  reportOption: ReportOption,
  workOrder: WorkOrderDetailType,
) => {
  const workOrderMetaData = {
    workOrderName: workOrder?.name,
    createdDate: setDisplayDate(workOrder?.createdDate),
    createdYear: format(workOrder?.createdDate, 'yyyy'),
    workStatus: getWorkOrderStatusType(workOrder?.statusId)?.label,
    createdByName: workOrder?.createdByName,
    completedYear: workOrder?.completionDate
      ? format(workOrder?.completionDate, 'yyyy')
      : '',
    completedDate: setDisplayDate(workOrder?.completionDate),
    dueDateYear: workOrder?.dueDate ? format(workOrder?.dueDate, 'yyyy') : '',
    closedByName: workOrder?.closedByName,
    workTemplateName: workOrder?.workTemplateName,
    inventoryAssetName: workOrder?.inventoryAssetName,
    categoryPathName: R.pathOr(
      'Unknown',
      ['assets', workOrder?.inventoryAssetId, 'categoryPath', 1, 'name'],
      workOrder,
    ),
    actualCost: numberToLocaleString(workOrder?.actualCost),
  };

  return {
    id: workOrder?.id,
    name,
    type: reportOption?.type,
    value: reportOption?.value,
    ...(reportOption?.displayEmptyAttributes && {
      displayEmptyAttributes: reportOption?.displayEmptyAttributes,
    }),
    workOrderMetaData,
  };
};
