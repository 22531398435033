import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as inventoryExportActions from '@atom/actions/inventoryExportActions';
import { Checkbox, Icon } from '@atom/mui';
import {
  getAttributeCount,
  getSubItemCheckedValue,
} from '@atom/selectors/inventoryExportSelectors';
import colors from '@atom/styles/colors';
import {
  ElementGroupsType,
  InventorySchemaTreeType,
} from '@atom/types/inventory';
import { ExportTemplateDetailType } from '@atom/types/inventoryExport';

import SchemaTreeGroupRow from './SchemaTreeGroupRow';
import SchemaTreeRow from './SchemaTreeRow';

import './inventoryExportDetail.css';

const styles = {
  arrowStyle: {
    fontSize: '24px',
    margin: 0,
  },
};

interface Props {
  inventorySchemaTree: InventorySchemaTreeType;
  exportTemplateDetail: ExportTemplateDetailType;
  selectedSubItemId: string;
  updateSelectedSubItem: (subItemId: string) => void;
}

const SchemaPane = ({
  inventorySchemaTree,
  exportTemplateDetail,
  updateSelectedSubItem,
  selectedSubItemId,
}: Props) => {
  const dispatch = useDispatch();
  const [isChecked, setChecked] = useState(
    getSubItemCheckedValue(inventorySchemaTree.id, exportTemplateDetail),
  );

  const toggleCheck = () => {
    if (isChecked) {
      dispatch(
        inventoryExportActions.requestDeleteExportTemplateSchema({
          exportTemplateId: exportTemplateDetail.id,
          schemaId: inventorySchemaTree.id,
        }),
      );
    } else {
      dispatch(
        inventoryExportActions.requestAddExportTemplateSchema({
          exportTemplateId: exportTemplateDetail.id,
          schemaId: inventorySchemaTree.id,
        }),
      );
    }

    setChecked(!isChecked);
  };

  const onClick = () => {
    updateSelectedSubItem(inventorySchemaTree.id);
  };

  const iconStyle = {
    fontSize: '24px',
    margin: 0,
  };

  const leftIcon = (
    <>
      <Icon style={styles.arrowStyle} color={colors.neutral.gray}>
        arrow_drop_down
      </Icon>
      <Checkbox checked={isChecked} onChange={toggleCheck} />
      <Icon
        style={iconStyle}
        color={isChecked ? colors.brand.blue : colors.neutral.gray}
      >
        room
      </Icon>
    </>
  );

  const selectedRowStyle =
    inventorySchemaTree.id === selectedSubItemId
      ? 'schema-row selected'
      : 'schema-row';

  const attributeCount = getAttributeCount(
    exportTemplateDetail,
    inventorySchemaTree.id,
  );

  return (
    <div styleName="section-pane center">
      <div styleName="pane-header">Select Sub Item Type(s)</div>
      <div styleName="item-tree-container">
        <div styleName={selectedRowStyle} onClick={onClick}>
          {leftIcon}
          <div styleName="schema-row-name-container">
            <div styleName="schema-row-name">{inventorySchemaTree.name}</div>
            {attributeCount > 0 && (
              <div styleName="schema-row-count">{attributeCount}</div>
            )}
          </div>
        </div>
        {inventorySchemaTree.elementGroups ? (
          inventorySchemaTree.elementGroups.map(
            (elementGroup: ElementGroupsType, index: number) => {
              return elementGroup.isGroup ? (
                <SchemaTreeGroupRow
                  key={`${elementGroup.name}-${index}`}
                  element={elementGroup}
                  indentation={1}
                  exportTemplateDetail={exportTemplateDetail}
                  updateSelectedSubItem={updateSelectedSubItem}
                  selectedSubItemId={selectedSubItemId}
                />
              ) : (
                <SchemaTreeRow
                  key={`${elementGroup.name}-${index}`}
                  element={elementGroup.elements[0]}
                  indentation={1}
                  exportTemplateDetail={exportTemplateDetail}
                  updateSelectedSubItem={updateSelectedSubItem}
                  selectedSubItemId={selectedSubItemId}
                />
              );
            },
          )
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default SchemaPane;
