import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import SchemaDetailContext, {
  PendingCreations,
} from '@atom/components/schemaDetail/SchemaDetailContext';
import {
  ATTRIBUTE_CREATE_BULK,
  ATTRIBUTE_UPDATE_BULK,
  SCHEMA_UPDATE,
} from '@atom/graph/schema';
import { Button, Icon, Menu, Modal, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  AttributeCreateBulkInput,
  AttributeUpdateBulkInput,
  SchemaTree,
  SchemaUpdateInput,
} from '@atom/types/schema';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import { getModalValue, ModalVariant } from './SchemaStatusModalValues';

import './schemaDetailHeader.css';

const { MenuItem } = Menu;

const HTTP_STATUS_CONFLICT = 422;

const styles = {
  publishButton: {
    backgroundColor: colors.neutral.white,
    color: colors.brand.purple,
  },
};

const SchemaStatus = () => {
  const {
    schemaTree,
    refetchSchemaTree,
    pendingUpdates,
    setPendingUpdates,
    pendingCreations,
    setPendingCreations,
  } = useContext(SchemaDetailContext);

  const [modalVariant, setModalVariant] = useState<ModalVariant>();

  const [updateSchema] = useMutation<
    { schemaUpdate: SchemaTree },
    { input: SchemaUpdateInput }
  >(SCHEMA_UPDATE);

  const [attributeUpdateBulk] = useMutation<
    { attributeUpdateBulk: SchemaTree },
    { input: AttributeUpdateBulkInput }
  >(ATTRIBUTE_UPDATE_BULK);

  const [attributeCreateBulk] = useMutation<
    { attributeCreateBulk: SchemaTree },
    { input: AttributeCreateBulkInput }
  >(ATTRIBUTE_CREATE_BULK);

  const handleSchemaUpdate = async () => {
    const isPublished = modalVariant === ModalVariant.PUBLISH_CONFIRM;

    try {
      await updateSchema({
        variables: {
          input: {
            schemaId: schemaTree?.id,
            isPublished,
          },
        },
      });

      refetchSchemaTree();

      setModalVariant(null);
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_STATUS_CONFLICT) {
        setModalVariant(ModalVariant.DRAFT_ERROR);
      } else {
        Snackbar.error({ message: 'An unknown error occurred' });
      }
    }
  };

  const publishPendingChanges = async (input: AttributeUpdateBulkInput) => {
    await attributeUpdateBulk({
      variables: {
        input,
      },
    });
  };

  const publishPendingCreations = async (input: AttributeCreateBulkInput) => {
    await attributeCreateBulk({
      variables: {
        input,
      },
    });
  };

  const getPendingAttributeCreationPayload = (
    pendingCreationsData: PendingCreations,
    schemaId: any,
  ) => {
    const currentElement = pendingCreationsData[schemaId];

    const attributes = R.keys(currentElement).reduce((acc, groupKey) => {
      return [...acc, ...R.values(currentElement[groupKey])];
    }, []);

    return attributes.map(attribute =>
      R.omit(['id', 'isTempAttribute'], attribute),
    );
  };

  const handlePublishChanges = async () => {
    // Each element that has changed attributes must be updated individually
    // This creates an array of promises per updated element.
    const pendingUpdatePromises = R.keys(pendingUpdates).reduce(
      (acc, key) => [
        ...acc,
        publishPendingChanges({
          schemaId: key.toString(),
          attributes: R.values(pendingUpdates[key]),
        }),
      ],
      [],
    );

    // Each element that has newly created attributes must be updated individually
    // This creates an array of promises per element with new attributes.
    const pendingCreationPromises = R.keys(pendingCreations).reduce(
      (acc, key) => [
        ...acc,
        publishPendingCreations({
          schemaId: key.toString(),
          // @ts-ignore
          attributes: getPendingAttributeCreationPayload(pendingCreations, key),
        }),
      ],
      [],
    );

    await Promise.all([...pendingUpdatePromises, ...pendingCreationPromises]);

    await refetchSchemaTree();

    setPendingUpdates(null);
    setPendingCreations(null);
    setModalVariant(null);
  };

  const modalValue = getModalValue(modalVariant);
  const hasPendingChanges =
    !isNilOrEmpty(pendingUpdates) || !isNilOrEmpty(pendingCreations);
  const onConfirm = hasPendingChanges
    ? () => handlePublishChanges()
    : () => handleSchemaUpdate();

  const getFooter = () => {
    return modalVariant === ModalVariant.DRAFT_ERROR ? (
      <Button
        color="primary"
        variant="contained"
        onClick={() => setModalVariant(null)}
      >
        {modalValue?.button}
      </Button>
    ) : (
      <div>
        <Button
          onClick={() => setModalVariant(null)}
          style={{ marginRight: '0.5rem' }}
        >
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={onConfirm}>
          {modalValue?.button}
        </Button>
      </div>
    );
  };

  const icon = schemaTree.isPublished ? 'lock' : 'edit';
  const text = schemaTree.isPublished ? 'published' : 'draft';
  const publishedOnClick = schemaTree.isPublished
    ? () => {}
    : () => setModalVariant(ModalVariant.PUBLISH_CONFIRM);
  const draftOnClick = !schemaTree.isPublished
    ? () => {}
    : () => setModalVariant(ModalVariant.DRAFT_CONFIRM);

  return (
    <>
      {hasPendingChanges ? (
        <Button
          style={styles.publishButton}
          variant="contained"
          onClick={() => setModalVariant(ModalVariant.PENDING_PUBLISH_CONFIRM)}
        >
          Publish
        </Button>
      ) : (
        <Menu
          icon={
            <div styleName="status-container">
              <Icon color={colors.neutral.white}>{icon}</Icon>
              <div styleName="status-text">{text}</div>
              <Icon color={colors.neutral.white}>arrow_drop_down</Icon>
            </div>
          }
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          noIconButton
        >
          <MenuItem
            key="published"
            onClick={publishedOnClick}
            selected={schemaTree.isPublished}
            startAdornment={<Icon>lock</Icon>}
          >
            <div styleName="status-option">
              <div>PUBLISHED</div>
              {schemaTree.isPublished && (
                <Icon color={colors.brand.blue}>check</Icon>
              )}
            </div>
          </MenuItem>
          <MenuItem
            key="draft"
            onClick={draftOnClick}
            selected={!schemaTree.isPublished}
            startAdornment={<Icon>edit</Icon>}
          >
            <div styleName="status-option">
              <div>DRAFT</div>
              {!schemaTree.isPublished && (
                <Icon color={colors.brand.blue}>check</Icon>
              )}
            </div>
          </MenuItem>
        </Menu>
      )}
      <Modal
        title={modalValue?.title}
        open={!isNilOrEmpty(modalVariant)}
        onCancel={() => setModalVariant(null)}
        footer={getFooter()}
      >
        {modalValue?.content}
      </Modal>
    </>
  );
};

export default SchemaStatus;
