import * as R from 'ramda';

import {
  WorkOrderColumn,
  WorkOrderColumnProperty,
} from '@atom/types/workColumns';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

export const getDataAccessByProperty = (property: WorkOrderColumnProperty) => {
  const dataAccessMap = {
    WORK_FIELD: 'workField',
    TASK_FIELD: 'taskField',
    PRIORITY: 'priorityId',
    STATUS: 'statusId',
    INVENTORY_NAME: 'inventoryAssetName',
    DUE_DATE: 'dueDate',
    ASSIGNED_TO: 'assignedTo',
    COMPLETED_DATE: 'completionDate',
    COMPLETED_BY: 'completedBy',
    NUM_TASKS: 'numTasks',
    NUM_FORMS: 'numForms',
    WORK_TYPE: 'workTemplateId',
    CREATED_DATE: 'createdDate',
    CREATED_BY: 'createdBy',
    START_DATE: 'startTime',
    INVENTORY_UPDATE_DATE: 'appliedDate',
    DURATION: 'duration',
  };

  return dataAccessMap[property] || '';
};

export const getUniqueColumnId = (column: WorkOrderColumn): string => {
  return column.property + column?.fieldId + column?.taskId;
};

export const getAdditionalColumns = (
  currentColumns: WorkOrderColumn[],
  availableColumns: WorkOrderColumn[],
): WorkOrderColumn[] => {
  if (R.length(currentColumns) === 0) {
    return availableColumns;
  }

  if (
    R.length(currentColumns) === R.length(availableColumns) ||
    isNilOrEmpty(currentColumns) ||
    isNilOrEmpty(availableColumns)
  ) {
    return [];
  }

  const currentColumnIds = currentColumns.map(column =>
    getUniqueColumnId(column),
  );

  return availableColumns.filter(
    column => !currentColumnIds.includes(getUniqueColumnId(column)),
  );
};

export type AdditionalColumnSection = {
  sectionName: string;
  columns: WorkOrderColumn[];
};

export const groupAdditionalColumns = (
  columns: WorkOrderColumn[],
  workTemplateName: string = '',
): AdditionalColumnSection[] => {
  if (isNilOrEmpty(columns)) {
    return [];
  }

  const sectionMap = columns.reduce((acc, column) => {
    switch (true) {
      case column.property === WorkOrderColumnProperty.WORK_FIELD:
        return {
          ...acc,
          [WorkOrderColumnProperty.WORK_FIELD]: {
            sectionName: workTemplateName,
            columns: [
              ...R.pathOr(
                [],
                [WorkOrderColumnProperty.WORK_FIELD, 'columns'],
                acc,
              ),
              column,
            ],
          },
        };
      case !isNilOrEmpty(column.taskId):
        return {
          ...acc,
          [column.taskId]: {
            sectionName: column.taskName,
            columns: [...R.pathOr([], [column.taskId, 'columns'], acc), column],
          },
        };
      default:
        return {
          ...acc,
          WORK_COLUMNS: {
            sectionName: 'WORK_COLUMNS',
            columns: [
              ...R.pathOr([], ['WORK_COLUMNS', 'columns'], acc),
              column,
            ],
          },
        };
    }
  }, {});

  const workColumns = R.pathOr({}, ['WORK_COLUMNS'], sectionMap);
  const workFieldColumns = R.pathOr(
    {},
    [WorkOrderColumnProperty.WORK_FIELD],
    sectionMap,
  );
  const tasksColumnsArray = R.values(
    R.omit(['WORK_COLUMNS', WorkOrderColumnProperty.WORK_FIELD], sectionMap),
  );

  // @ts-ignore
  return [
    ...(workColumns && [workColumns]),
    ...(workFieldColumns && [workFieldColumns]),
    ...tasksColumnsArray,
  ];
};
