import { INITIAL_INPUT } from '@atom/hooks/useWorkOrdersFilters';
import { INITIAL_WORK_VALIDATIONS } from '@atom/hooks/useWorkValidations';
// @ts-ignore
import defaultProfileImage from '@atom/images/defaultProfileImage.png';
import { ReduxStore } from '@atom/types/store';

const initialState: ReduxStore = {
  auth: {
    isVerified: false,
    isLoggedIn: false,
    permissions: [],
  },
  userDetail: {
    id: '',
    employeeId: '',
    baseLocation: '',
    firstName: '',
    lastName: '',
    isVerified: false,
    isActive: false,
    photoUrl: '',
    department: '',
    title: '',
    role: '',
    level: 0,
    rate: 0,
    phoneNumber: '',
    email: '',
    location: {
      type: 'Point',
      coordinates: [0, 0],
    },
    createdDate: 0,
    updatedDate: 0,
    preferences: {},
    notifications: [],
    roles: [],
    inheritedRoles: [],
  },
  users: [],
  usersTotal: 0,
  workOrders: [],
  media: [],
  mediaTotal: 0,
  mediaFolders: [],
  mediaFolderTree: { id: 'root', name: 'All Files' },
  mediaTotals: {
    document: { name: 'Documents', count: 0 },
    image: { name: 'Photos', count: 0 },
    video: { name: 'Videos', count: 0 },
  },
  workOrdersTotal: 0,
  // @ts-ignore
  workOrderDetail: {},
  // @ts-ignore
  workOrderAssetDetail: {},
  inventorySchemas: [],
  inventorySchemasByIds: {},
  // @ts-ignore
  inventorySchema: {},
  // @ts-ignore
  inventorySchemaDetail: {},
  inventorySchemaTree: {},
  poolAttributes: [],
  csvErrors: [],
  inventoryFullCategoryTree: {},
  inventory: {
    categories: {
      inventoryPortalPath: {
        id: 'inventory',
        path: 'inventory',
      },
      inventoryCategory: '',
      inventoryCategoryTree: {
        id: 'inventory',
        name: 'Inventory',
        assetType: '',
        expanded: false,
        children: [],
        hasChildren: true,
      },
    },
    assets: {
      parentCategoryId: 'inventory',
      total: 0,
    },
    data: [],
    columns: [],
    filters: {},
    sort: {},
  },
  inventoryTotal: 0,
  inventoryAssetDetail: {},
  inventoryAssetTree: {},
  inventorySearch: [],
  inventoryFilterCategoryTree: [
    {
      id: 'inventory',
      name: 'Inventory',
      hasCategories: true,
      expanded: false,
    },
  ],
  organizations: [],
  userProfile: {
    userId: '',
    firstName: '',
    lastName: '',
    department: '',
    role: '',
    email: '',
    isActive: false,
    isVerified: false,
    profileImage: defaultProfileImage,
    location: {
      type: 'Point',
      coordinates: [0, 0],
    },
    phoneNumber: '',
    title: '',
  },
  preferences: {},
  menuState: {
    search: false,
  },
  workTemplates: [],
  workTemplatesTotal: 0,
  formInstance: {
    id: '',
    name: '',
    createdDate: 0,
    createdBy: '',
    pages: [
      {
        id: '1',
        name: 'Page 1',
        fields: {},
        order: [],
      },
    ],
    media: {},
  },
  formTemplates: [],
  formTemplatesTotal: 0,
  formTemplateDetail: {
    id: '',
    name: '',
    createdDate: 0,
    createdBy: '',
    pages: [
      {
        id: '1',
        name: 'Page 1',
        fields: {},
        order: [],
      },
    ],
  },
  exportTemplateDetail: {
    id: '',
    name: '',
    createdBy: '',
    createdDate: 0,
    updatedBy: '',
    updatedDate: 0,
    isPublished: false,
    categoryIds: [],
    schemas: {},
  },
  exportTemplates: [],
  applicationSearch: {
    query: '',
    assets: [],
    workOrders: [],
    users: [],
  },
  loading: {
    loadingTenantCreation: false,
    loadingInventory: false,
    loadingWorkOrders: true,
    loadingUserLogin: false,
    loadingOrganizations: false,
    loadingUserRegistration: false,
    retrievedUser: false,
    loadingUsers: true,
    loadingInventoryAssetDetail: true,
    loadingInventoryAssetAttributes: [],
    loadingInventoryElementDetail: false,
    loadingInventoryElementAttributes: [],
    loadingInventorySchema: true,
    loadingInventorySchemas: true,
    loadingInventorySchemasByIds: true,
    loadingInventorySchemaTree: false,
    loadingWorkOrderDetail: true,
    loadingWorkOrderAssetDetail: true,
    loadingWorkOrderAssetAttributes: [],
    loadingWorkOrderAssetElements: true,
    loadingElementBatchCreation: false,
    loadingCreateWorkOrder: false,
    loadingCreateAsset: false,
    loadingMedia: false,
    loadingMediaFolders: false,
    loadingFormTemplateCreation: false,
    loadingFormTemplateUpdate: false,
    loadingFormTemplate: false,
    loadingFormTemplates: true,
    loadingFormInstance: true,
    loadingInventoryAssetTreeNode: false,
    loadingInventoryFilterCategoryTreeNode: false,
    loadingSchemaDetail: true,
    loadingSchemaTreeNode: false,
    loadingUserDetail: true,
    loadingUserUpdate: false,
    loadingCsvUploadValidation: false,
    savingFormInstance: false,
    loadingApplicationSearch: false,
    loadingCharts: true,
    loadingSchema: false,
    loadingWorkTemplates: true,
    loadingWorkTemplateCreation: false,
    loadingElementDelete: false,
    loadingMediaRename: false,
    loadingExportTemplates: false,
    loadingExportTemplateCreation: false,
    loadingExportTemplateDetail: false,
    loadingFullCategoryTree: false,
    loadingApproveAll: false,
    loadingInventoryAssetTree: false,
  },
  notification: {
    open: false,
  },
  breadcrumbs: {
    inventory: {
      breadcrumbs: [],
      currentElementName: null,
    },
    task: [],
  },
  workOrdersInput: INITIAL_INPUT,
  workValidations: INITIAL_WORK_VALIDATIONS,
};

export default initialState;
