import React, { useState } from 'react';

import DeleteModal from '@atom/components/common/DeleteModal';
import RenameModal from '@atom/components/common/RenameModal';
import { Icon, Menu } from '@atom/mui';
import { Media } from '@atom/types/media';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';

const { MenuItem } = Menu;

interface Props {
  medium: Media;
  isEditDisabled: boolean;
  handleRename: (id: string, name: string) => void;
  handleDelete: (id: string) => void;
}

const MediaTableOptions = ({
  medium,
  isEditDisabled,
  handleRename,
  handleDelete,
}: Props) => {
  const [renameModalOpen, setRenameModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const deleteMedia = (id: string) => {
    handleDelete(id);
    setDeleteModalOpen(false);
  };

  const renameMedia = (id: string, name: string) => {
    handleRename(id, name);
    setRenameModalOpen(false);
  };

  return (
    <>
      <Menu noMinWidth IconButtonProps={{ size: 'small' }}>
        {!isEditDisabled && (
          <MenuItem
            startAdornment={<Icon>edit</Icon>}
            onClick={() => setRenameModalOpen(true)}
          >
            Rename
          </MenuItem>
        )}
        <a
          href={`${FILES_ENDPOINT}/${medium.fileId}`}
          download={`${medium.name}.${medium.fileExtension}`}
          target="_blank"
        >
          <MenuItem
            startAdornment={<Icon>file_download</Icon>}
            onClick={() => {}}
          >
            Download
          </MenuItem>
        </a>
        {!isEditDisabled && (
          <MenuItem
            startAdornment={<Icon>delete</Icon>}
            onClick={() => setDeleteModalOpen(true)}
          >
            Delete
          </MenuItem>
        )}
      </Menu>
      <RenameModal
        id={medium.id}
        name={medium.name}
        type={medium.type}
        renameAction={renameMedia}
        open={renameModalOpen}
        closeModal={() => setRenameModalOpen(false)}
      />
      <DeleteModal
        open={deleteModalOpen}
        onConfirm={() => deleteMedia(medium.id)}
        onCancel={() => setDeleteModalOpen(false)}
        title={`Delete ${textDisplayUtilities.capitalize(medium.type)}`}
        content={`Are you sure you want to delete this ${medium.type}?`}
      />
    </>
  );
};

export default MediaTableOptions;
