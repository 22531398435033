import * as React from 'react';

export const TermsOfService = (
  <div>
    <p>Welcome to&nbsp;Atom!&nbsp;</p>
    <p>
      Thanks for using our&nbsp;online, web-based platform and mobile
      applications known as Atom (the &ldquo;Services&rdquo;).&nbsp;The Services
      are provided by&nbsp;SADA&nbsp;Systems,&nbsp;Inc.&nbsp;and Collins
      Engineers, Inc&nbsp;(together referred to in these Terms of Service
      as&nbsp;&ldquo;Owner&rdquo;,&nbsp;&ldquo;we&rdquo; or
      &ldquo;our&rdquo;).&nbsp;
    </p>
    <p>
      By using our Services, you are agreeing to these terms. Please read them
      carefully.&nbsp;
    </p>
    <p>
      <strong>Using our Services</strong>&nbsp;
    </p>
    <p>
      You must follow any policies made available to you within the
      Services.&nbsp;
    </p>
    <p>
      Do not misuse our Services, for example, do not interfere with our
      Services or try to access them using a method other than the interface and
      the instructions&nbsp;that we provide. You may use the&nbsp;Services only
      as&nbsp;described in these terms,&nbsp;your&nbsp;subscription agreement,
      order form, or as&nbsp;permitted by&nbsp;applicable&nbsp;law. We may
      suspend or stop providing the&nbsp;Services to you if you do not comply
      with our terms or policies or if we are investigating suspected
      misconduct.&nbsp;
    </p>
    <p>
      Using the&nbsp;Services does not give you ownership of any intellectual
      property rights in the&nbsp;Services or the content that you
      access.&nbsp;You may not use content from the&nbsp;Services unless you
      obtain permission from its owner or are otherwise permitted by law. These
      terms do not grant you the right to use any branding&nbsp;or logos used in
      the&nbsp;Services. Do not remove, obscure or alter any legal notices
      displayed in or along with the&nbsp;Services.&nbsp;
    </p>
    <p>
      The&nbsp;Services display some content that is not&nbsp;ours. This content
      is the sole responsibility of the entity that makes it
      available.&nbsp;&nbsp;
    </p>
    <p>
      In connection with your use of the Services, we may send you service
      announcements, administrative messages and other information. You may opt
      out of some of those communications, but not all of them.&nbsp;
    </p>
    <p>
      Some of the&nbsp;Services are available on mobile devices. Do not use such
      Services in a way that distracts you and prevents you from obeying traffic
      or safety laws.&nbsp;
    </p>
    <p>
      In addition, you agree that you will not, and will not allow third parties
      to use our Services to:&nbsp;
    </p>
    <ul>
      <li>
        participate&nbsp;in,&nbsp;or&nbsp;promote any&nbsp;activity
        considered&nbsp;illegal;&nbsp;
      </li>
      <li>
        launch&nbsp;any unlawful, invasive, infringing, defamatory or
        fraudulent&nbsp;software or enterprise;&nbsp;
      </li>
      <li>
        intentionally distribute viruses, worms, Trojan horses, corrupted files,
        hoaxes, or other items of a destructive or deceptive nature;&nbsp;
      </li>
      <li>
        interfere with&nbsp;anyone&rsquo;s&nbsp;use of our Services, or
        interfere&nbsp;with&nbsp;the&nbsp;software and&nbsp;equipment used to
        provide our Services;&nbsp;
      </li>
    </ul>
    <ul>
      <li>
        disable, interfere with or circumvent any aspect of our Services;&nbsp;
      </li>
      <li>
        take any action to allow&nbsp;users to infringe or misappropriate the
        intellectual property rights of others;&nbsp;
      </li>
      <li>
        generate, distribute, or facilitate&nbsp;unsolicited mass
        email&nbsp;or&nbsp;advertising; or&nbsp;&nbsp;
      </li>
      <li>
        use the Services, or any interfaces included in the Services, to access
        any other&nbsp;company&rsquo;s&nbsp;product or service in a manner that
        violates the terms of service of such other product or service.&nbsp;
      </li>
    </ul>
    <p>
      <strong>Privacy&nbsp;</strong>&nbsp;
    </p>
    <p>
      Please review the Atom&nbsp;Privacy&nbsp;Policy&nbsp;to
      understand&nbsp;how we treat your personal data and protect your privacy
      when you use our Services. By using our Services, you agree
      that&nbsp;we&nbsp;can use such data in accordance with our Privacy
      Policy.&nbsp;
    </p>
    <p>
      <strong>About Software in our Services</strong>&nbsp;
    </p>
    <p>
      When the&nbsp;Service requires or includes downloadable software, this
      software may be updated automatically on your device once a new version or
      feature is available.&nbsp;&nbsp;
    </p>
    <p>
      We&nbsp;give you a personal, worldwide, royalty-free, non-assignable and
      non-exclusive&nbsp;license&nbsp;for the term of your subscription&nbsp;to
      use the software provided to you by&nbsp;us&nbsp;as part of the Services.
      This&nbsp;license&nbsp;is for the sole purpose of enabling you to use and
      enjoy the benefit of the Services as provided by&nbsp;us&nbsp;in the
      manner permitted by these terms,&nbsp;the subscription
      agreement,&nbsp;and&nbsp;order form. You may not copy, modify, distribute,
      sell or lease any part of our Services or included software, nor may you
      reverse engineer or attempt to extract the source code of that software,
      unless laws prohibit those restrictions or you have our written
      permission.&nbsp;
    </p>
    <p>
      <strong>Our Warranties and Disclaimers</strong>&nbsp;
    </p>
    <p>
      We provide the&nbsp;Services using a commercially reasonable level of
      skill and care and we hope that you will enjoy using them. But there are
      certain things&nbsp;that we do not promise about the&nbsp;Services.&nbsp;
    </p>
    <p>
      Other than as expressly set out in these terms and our&nbsp;additional
      terms, neither&nbsp;SADA&nbsp;nor its&nbsp;partners,&nbsp;suppliers or
      distributors makes any specific promises about the Services. For example,
      we do not make any commitments about the content within the Services, the
      specific functions of the Services or their reliability, availability or
      ability to meet your needs. We provide the Services &ldquo;as
      is&rdquo;.&nbsp;
    </p>
    <p>
      Some jurisdictions provide for certain warranties, like the implied
      warranty of merchantability, fitness for a particular purpose and
      non-infringement. To the extent permitted by law, we exclude all
      warranties.&nbsp;
    </p>
    <p>
      <strong>About these Terms</strong>&nbsp;
    </p>
    <p>
      We may modify these terms or any&nbsp;additional terms that apply to
      the&nbsp;Services&nbsp;to, for example, reflect changes to the law or
      changes to the&nbsp;Services. You should look at the terms regularly.
      We&rsquo;ll post notice of modifications to&nbsp;the Service
      and&nbsp;these terms on this page. Changes will not apply retrospectively
      and will become effective no earlier than fourteen days after they are
      posted. However, changes addressing new functions for
      the&nbsp;Services&nbsp;or changes made for legal reasons will be effective
      immediately.&nbsp;&nbsp;
    </p>
    <p>
      If there is any inconsistency between these terms and the additional
      terms, the additional terms will prevail to the extent of the
      inconsistency.&nbsp;
    </p>
    <p>
      If you do not comply with these terms and we do not take action
      immediately, this doesn&rsquo;t mean that we are giving up any rights that
      we may have (such as taking action in the future).&nbsp;
    </p>
    <p>
      If it turns out that a particular term is not enforceable, this will not
      affect any other terms.&nbsp;
    </p>
    <p>
      For information about how to contact&nbsp;us, please see the&nbsp;Atom
      Privacy&nbsp;Policy.&nbsp;
    </p>
    <p>&nbsp;</p>
  </div>
);
