import React from 'react';
import {
  ListItemButton as MUIListItemButton,
  ListItemButtonProps as ListItemButtonPropsType,
} from '@mui/material';

type CustomProps = {
  style?: React.CSSProperties;
};

export type ListItemButtonProps = CustomProps & ListItemButtonPropsType;

export const ListItemButton = (props: ListItemButtonProps) => {
  const { style = {} } = props;

  return <MUIListItemButton {...props} style={style} />;
};

export default ListItemButton;
