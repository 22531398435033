import React from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';

import './workOrderVideos.css';

interface Props {
  incrementPage: (increment: number) => void;
  page: number;
  limit: number;
  total: number;
}

const VideosPagination = ({ incrementPage, page, limit, total }: Props) => {
  const startRangeNumber = (page - 1) * limit + 1;
  const limitTotal = page * limit;
  const totalLessThanLimit = total <= limitTotal;
  const endRangeNumber = totalLessThanLimit ? total : limitTotal;

  return (
    <div styleName="pagination-container">
      <div>{`${startRangeNumber}-${endRangeNumber} of ${total}`}</div>
      <div>
        <IconButton onClick={() => incrementPage(-1)} disabled={page === 1}>
          <Icon color={colors.neutral.dim}>chevron_left</Icon>
        </IconButton>

        <IconButton
          onClick={() => incrementPage(1)}
          disabled={totalLessThanLimit}
        >
          <Icon color={colors.neutral.dim}>chevron_right</Icon>
        </IconButton>
      </div>
    </div>
  );
};

export default VideosPagination;
