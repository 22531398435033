import { gql } from '@apollo/client';

import { BASE_FAVORITE_LIST_FRAGMENT } from './favoritesList';

export const BASE_FAVORITE_FRAGMENT = gql`
  fragment BaseFavorite on Favorite {
    id
    subjectType
    subjectId
    subjectName
    listIds
    data {
      colorId
      assetType
      categories {
        id
        name
      }
      photoUrl
      email
      firstName
      lastName
      markerId
      schemaId
      isMaterial
      inactive
    }
    createdDate
    createdBy {
      firstName
      lastName
    }
  }
`;

export const GET_FAVORITES = gql`
  query favorites($input: FavoritesConnectionInput!) {
    favorites(input: $input) {
      totalCount
      favorites {
        ...BaseFavorite
      }
    }
  }
  ${BASE_FAVORITE_FRAGMENT}
`;

export const CREATE_FAVORITE = gql`
  mutation favoriteCreate($input: FavoriteCreateInput!) {
    favoriteCreate(input: $input) {
      ...BaseFavorite
    }
  }
  ${BASE_FAVORITE_FRAGMENT}
`;

export const DELETE_FAVORITE = gql`
  mutation favoriteDelete($input: FavoriteDeleteInput!) {
    favoriteDelete(input: $input)
  }
`;

export const UPDATE_FAVORITE = gql`
  mutation favoriteUpdate($input: FavoriteUpdateInput!) {
    favoriteUpdate(input: $input) {
      totalCount
      totalUniqueFavorites
      favoritesLists {
        ...BaseFavoritesList
      }
    }
  }
  ${BASE_FAVORITE_LIST_FRAGMENT}
`;
