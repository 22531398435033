import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import TaskContext from '@atom/components/workOrderPreview/taskView/TaskContext';
import WorkOrderPreviewContext from '@atom/components/workOrderPreview/WorkOrderPreviewContext';
import { TASK_UPDATE } from '@atom/graph/task';
import { Icon, IconButton, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { Task, TaskUpdateInput } from '@atom/types/task';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';

import './taskView.css';

const styles = {
  icon: {
    padding: 0,
    fontSize: fonts.xl,
  },
  inputContainer: {
    marginTop: '4px',
  },
  input: {
    fontSize: fonts.md,
    textAlign: 'right',
  },
  textField: {
    color: colors.neutral.white,
  },
  underline: {
    borderColor: colors.neutral.gray,
  },
};

const TaskEstimatedCost = () => {
  const { workOrderDetail, refetch } = useContext(WorkOrderPreviewContext);
  const { task } = useContext(TaskContext);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [estimatedCost, setEstimatedCost] = useState<number>(
    task.estimatedCost,
  );
  const [isEstimatedCostOverridden, setIsEstimatedCostOverridden] = useState<
    boolean
  >(task.isEstimatedCostOverridden);

  const [taskUpdate] = useMutation<
    { taskUpdate: Task },
    { input: TaskUpdateInput }
  >(TASK_UPDATE, {
    onCompleted: () => {
      refetch();
    },
  });

  useEffect(() => {
    setIsEstimatedCostOverridden(task.isEstimatedCostOverridden);
  }, [task.isEstimatedCostOverridden]);

  useEffect(() => {
    setEstimatedCost(task.estimatedCost);
  }, [task.estimatedCost]);

  const handleToggle = () => {
    const updatedValue = !isEstimatedCostOverridden;
    setIsEstimatedCostOverridden(updatedValue);

    if (!updatedValue) {
      taskUpdate({
        variables: {
          input: {
            id: task.id,
            workOrderId: workOrderDetail.id,
            isEstimatedCostOverridden: false,
          },
        },
      });
    }

    if (updatedValue) {
      setEditMode(true);
    }
  };

  const cancel = () => {
    const updatedValue = !isEstimatedCostOverridden;

    setIsEstimatedCostOverridden(updatedValue);
    setEditMode(false);
  };

  const confirm = async () => {
    await taskUpdate({
      variables: {
        input: {
          id: task.id,
          workOrderId: workOrderDetail.id,
          isEstimatedCostOverridden: true,
          estimatedCost,
        },
      },
    });

    refetch();

    setEditMode(false);
  };

  const icon = isEstimatedCostOverridden ? 'refresh' : 'edit';

  const isDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.MANAGER);

  return (
    <div styleName="cost-content-left">
      <div styleName="label-container">
        <div styleName="label">Estimated Cost</div>
      </div>
      {editMode ? (
        <div styleName="task-cost-textfield-container">
          <TextField
            id="name"
            autoFocus
            type="number"
            value={estimatedCost}
            onChange={event => setEstimatedCost(Number(event.target.value))}
            disabled={isDisabled}
            style={styles.inputContainer}
          />
          <div styleName="button-container">
            <div styleName="button">
              <Icon color={colors.brand.blue} onClick={cancel}>
                close
              </Icon>
            </div>
            <div styleName="button">
              <Icon color={colors.brand.blue} onClick={confirm}>
                check
              </Icon>
            </div>
          </div>
        </div>
      ) : (
        <div styleName="estimated-cost-container">
          <div styleName="estimated-cost-value">
            {numberToLocaleString(task.estimatedCost)}
          </div>
          <IconButton size="small" onClick={handleToggle} disabled={isDisabled}>
            <Icon style={styles.icon}>{icon}</Icon>
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default TaskEstimatedCost;
