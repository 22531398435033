import * as React from 'react';
import * as R from 'ramda';

import FontIconButton from '@atom/components/common/FontIconButton';
import { buildAssetFieldName } from '@atom/selectors/formSelectors';
import colors from '@atom/styles/colors';
import { AssetSchemaType, AttributeGroupItem } from '@atom/types/form';
import {
  AttributesType,
  HydratedAttributeGroupsItem,
  HydratedInventorySchemaItem,
} from '@atom/types/inventory';
import { SchemaType } from '@atom/types/schema';
import schemaUtilities from '@atom/utilities/schemaUtilities';

import AttributeGroupBlock from './AttributeGroupBlock';

import '../formBuilder.css';

const styles = {
  icon: {
    padding: '0px',
    height: '35px',
    width: '35px',
  },
};

interface PassedProps {
  fullSchema: HydratedInventorySchemaItem;
  assetSchema: AssetSchemaType;
  rootSchema: SchemaType;
  fieldId: string;
  updateAttributeGroup: (
    fieldId: string,
    schemaId: string,
    addGroup: boolean,
    attributeGroup: AttributeGroupItem,
  ) => void;
  updateAttribute: (
    fieldId: string,
    schemaId: string,
    addAttribute: boolean,
    attributeGroupName: string,
    attribute: AttributesType,
  ) => void;
  addAllAttributes: (
    fieldId: string,
    schemaId: string,
    attributeGroupName: string,
    attributes: AttributesType[],
  ) => void;
  removeAssetSchema: (fieldId: string, schemaId: string) => void;
}

type Props = PassedProps;

interface State {
  open: boolean;
}

class AssetField extends React.Component<Props, State> {
  state = {
    open: true,
  };

  toggleCollapse = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  getAssetAttributeGroup = (
    name: string,
  ): HydratedAttributeGroupsItem | Object => {
    const { assetSchema } = this.props;

    if (R.isNil(assetSchema.attributeGroups)) {
      return {};
    }

    const matchedAssetGroup = assetSchema.attributeGroups.find(
      (group: HydratedAttributeGroupsItem): boolean => group.name === name,
    );

    return matchedAssetGroup ? matchedAssetGroup : {};
  };

  render() {
    const {
      assetSchema,
      fullSchema,
      rootSchema,
      updateAttributeGroup,
      updateAttribute,
      addAllAttributes,
      fieldId,
      removeAssetSchema,
    } = this.props;
    const { open } = this.state;

    const collapseIcon = open ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    const assetFieldName = buildAssetFieldName(
      assetSchema.name,
      assetSchema.ancestors,
    );
    const leftIcon = schemaUtilities.getSchemaIconFromSchemaOrAsset(rootSchema);

    return (
      <div styleName="schema-field-container">
        <div styleName="schema-field-header">
          <div styleName="schema-left-header">
            {leftIcon}
            <div styleName="schema-header-title">{assetFieldName}</div>
          </div>
          <div>
            <FontIconButton
              style={styles.icon}
              tooltip="Delete"
              onClick={(): void => removeAssetSchema(fieldId, fullSchema.id)}
              color={colors.neutral.gray}
              icon="delete"
              tooltipPosition="bottom-left"
            />
            <FontIconButton
              style={styles.icon}
              onClick={this.toggleCollapse}
              color={colors.neutral.gray}
              icon={collapseIcon}
            />
          </div>
        </div>
        {open &&
          fullSchema.attributeGroups.map(
            (fullAttributeGroup: HydratedAttributeGroupsItem) => {
              const assetAttributeGroup = this.getAssetAttributeGroup(
                fullAttributeGroup.name,
              );

              return (
                <AttributeGroupBlock
                  updateAttributeGroup={updateAttributeGroup}
                  key={fullAttributeGroup.name}
                  fieldId={fieldId}
                  schemaId={fullSchema.id}
                  fullAttributeGroup={fullAttributeGroup}
                  // @ts-ignore
                  assetAttributeGroup={assetAttributeGroup}
                  updateAttribute={updateAttribute}
                  addAllAttributes={addAllAttributes}
                />
              );
            },
          )}
      </div>
    );
  }
}

export default AssetField;
