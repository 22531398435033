import React from 'react';
import Link from '@mui/material/Link';

// @ts-ignore
import pdfIcon from '@atom/components/common/svgIcons/pdfIcon.svg';
import { Icon, ListTable, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import { INVENTORY_REPORT_ENDPOINT } from '@atom/utilities/endpoints';
import {
  Environment,
  isCurrentEnvironment,
  isCurrentTenant,
  Tenant,
} from '@atom/utilities/featureToggleUtilities';
import { buildEndpointWithParamsFromData } from '@atom/utilities/requestUtilities';

import './inventoryExport.css';

const { TableHead, TableRow, TableCell, TableBody } = ListTable;

const InventoryCustomExportsTable = () => {
  const onFileDownloadClick = () => {
    Snackbar.info({
      message: 'Generating Report...',
      autoHideDuration: 25000,
    });
  };

  const getInventoryReportEndpoint = () => {
    const params = {
      type: 'MUTCD',
      title: 'Sign Code Report',
      fileName: 'Sign Code Manual.pdf',
    };
    return buildEndpointWithParamsFromData(INVENTORY_REPORT_ENDPOINT, params);
  };

  return (
    <>
      {(isCurrentEnvironment([Environment.DEV]) ||
        isCurrentTenant([Tenant.HENNEPIN, Tenant.UAT_HENNEPIN])) && (
        <ListTable offsetTop="110px">
          <TableHead>
            <TableRow header>
              <TableCell variant="head" />
              <TableCell variant="head">Name</TableCell>
              <TableCell variant="head" style={{ alignItems: 'right' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableCell width="20px">
              <img className="pdf-icon" src={pdfIcon} />
            </TableCell>
            <TableCell>Sign Code Report</TableCell>
            <TableCell width="50px">
              <Link
                onClick={onFileDownloadClick}
                href={getInventoryReportEndpoint()}
              >
                <Icon color={colors.neutral.gray} style={{ cursor: 'pointer' }}>
                  file_download
                </Icon>
              </Link>
            </TableCell>
          </TableBody>
        </ListTable>
      )}
    </>
  );
};

export default InventoryCustomExportsTable;
