import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import AddCategoriesModal from '@atom/components/common/workOrderDetail/addCategoriesModal/AddCategoriesModal';
import AddMaterialAssetsModal from '@atom/components/common/workOrderDetail/addMaterialAssetsModal/AddMaterialAssetsModal';
import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import {
  WORK_TEMPLATE_TASK_MATERIAL_CREATE,
  WORK_TEMPLATE_TASK_UPDATE,
} from '@atom/graph/workTemplate';
import { Icon, IconButton, Snackbar } from '@atom/mui';
import {
  InheritedComponentType,
  WorkOrderTemplateMaterialCreateInput,
  WorkOrderTemplateTaskUpdateInput,
  WorkTemplateTaskCategory,
  WorkTemplateTaskItem,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { isComponentInherited } from '@atom/utilities/workTemplateUtilities';

import CategoryTile from './CategoryTile';
import MaterialTile from './MaterialTile';

import './materialSection.css';

const MaterialSection = () => {
  const { workTemplate, task, refetch } = useContext(WorkTemplateContext);

  const [expanded, setExpanded] = useState<boolean>(true);
  const [openMaterialAdd, setOpenMaterialAdd] = useState<boolean>(false);
  const [openCategoryModal, setOpenCategoryModal] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<
    WorkTemplateTaskCategory
  >(null);

  const [addMaterials] = useMutation<
    void,
    { input: WorkOrderTemplateMaterialCreateInput }
  >(WORK_TEMPLATE_TASK_MATERIAL_CREATE, {
    onCompleted: res => refetch(),
  });

  const [updateTask] = useMutation<
    { workOrderTemplateTaskUpdate: WorkTemplateTaskItem },
    { input: WorkOrderTemplateTaskUpdateInput }
  >(WORK_TEMPLATE_TASK_UPDATE, { onCompleted: res => refetch() });

  const isMaterialsEmpty = R.isEmpty(task.materials);

  const totalCount = R.length(task.materials) || 0;
  const icon = expanded ? 'expand_less' : 'expand_more';

  const handleModalClose = () => {
    setSelectedCategory(null);
    setOpenMaterialAdd(false);
    setOpenCategoryModal(false);
  };

  const handleCategoryClick = (category: WorkTemplateTaskCategory) => {
    if (!workTemplate?.published) {
      setSelectedCategory(category);
      setOpenMaterialAdd(true);
    }
  };

  const handleAddMaterials = async (assetIds: string[]) => {
    if (R.isEmpty(assetIds)) {
      handleModalClose();
    }

    try {
      await addMaterials({
        variables: {
          input: {
            workTemplateId: workTemplate.id,
            taskId: task.id,
            materials: assetIds.map(assetId => {
              return { assetId };
            }),
          },
        },
      });

      handleModalClose();
    } catch (err) {
      Snackbar.error({
        message: 'Failed to add inventory. Please try again.',
      });

      handleModalClose();
    }
  };

  const handleAddCategories = async (categoryIds: string[]) => {
    if (R.isEmpty(categoryIds)) {
      handleModalClose();
    }

    const isInherited = isComponentInherited(
      InheritedComponentType.TASK_CATEGORY,
      task?.inheritedComponents || [],
    );

    const updatedCategoryIds = isInherited
      ? categoryIds
      : [...task.categories.map(category => category.id), ...categoryIds];

    try {
      await updateTask({
        variables: {
          input: {
            taskId: task.id,
            workTemplateId: workTemplate.id,
            categoryIds: updatedCategoryIds,
          },
        },
      });

      handleModalClose();
    } catch (err) {
      Snackbar.error({
        message: 'Failed to add inventory folders. Please try again.',
      });

      handleModalClose();
    }
  };

  const isDisabled =
    workTemplate?.published || doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  return (
    <>
      <div styleName="header-container">
        <div styleName="section-left">
          <div styleName="section-title">Equipment and Material</div>
          <div styleName="total-count">{totalCount}</div>
        </div>
        <div styleName="section-right">
          {expanded && (
            <>
              <IconButton
                onClick={() => setOpenCategoryModal(true)}
                edge="end"
                tooltip="Add Equipment and Material Folders"
                disabled={isDisabled}
              >
                <Icon>create_new_folder</Icon>
              </IconButton>
              <IconButton
                onClick={() => setOpenMaterialAdd(true)}
                edge="end"
                tooltip="Add Equipment and Material"
                disabled={isDisabled}
              >
                <Icon>add</Icon>
              </IconButton>
            </>
          )}
          <IconButton onClick={() => setExpanded(!expanded)} edge="end">
            <Icon>{icon}</Icon>
          </IconButton>
        </div>
      </div>
      {expanded && (
        <div>
          <div styleName="category-section">
            {task.categories.map(category => (
              <CategoryTile
                key={category.id}
                category={category}
                onClick={() => handleCategoryClick(category)}
              />
            ))}
          </div>
          <>
            {isMaterialsEmpty ? (
              <div styleName="empty-section-text">
                Add Equipment and Material
              </div>
            ) : (
              task.materials.map(material => (
                <MaterialTile key={material.assetId} material={material} />
              ))
            )}
          </>
        </div>
      )}
      <AddMaterialAssetsModal
        open={openMaterialAdd}
        onClose={handleModalClose}
        rootCategory={selectedCategory}
        task={task}
        handleAddMaterials={handleAddMaterials}
        favoritesEnabled={false}
      />
      <AddCategoriesModal
        open={openCategoryModal}
        onClose={handleModalClose}
        task={task}
        handleAddCategories={handleAddCategories}
      />
    </>
  );
};

export default MaterialSection;
