import React from 'react';
import * as R from 'ramda';

import { Task, TaskField } from '@atom/types/task';
import {
  WorkOrderAssetTreeType,
  WorkOrderDetailType,
  WorkOrderField,
  WorkValidations,
} from '@atom/types/work';
import { getUserIdSync } from '@atom/utilities/accountUtilities';
import { getFieldPathById } from '@atom/utilities/workOrderFieldUtilities';

interface Context {
  refetch: () => Promise<any>;
  refetchWorkOrderAssetTree: () => Promise<any>;
  refetchWorkOrderAssetTreeLoading: boolean;
  loading: boolean;
  workOrderDetail: WorkOrderDetailType;
  dispatch: React.Dispatch<WorkOrderActions>;
  task: Task;
  workOrderAsset: WorkOrderAssetTreeType;
  workOrderAssetLoading: boolean;
  setActiveTask: Function;
  setActiveWorkOrderAsset: Function;
  refetchMedia: boolean;
  setRefetchMedia: (refetchMedia: boolean) => void;
  workValidations: WorkValidations;
  setWorkValidations: (validations: WorkValidations) => void;
  setTask: (task: Task | {}) => void;
}

export const initialState: Context = {
  refetch: () => Promise.resolve({}),
  refetchWorkOrderAssetTree: () => Promise.resolve({}),
  refetchWorkOrderAssetTreeLoading: false,
  loading: true,
  // @ts-ignore
  workOrderDetail: {},
  dispatch: () => {},
  // @ts-ignore
  task: {},
  // @ts-ignore
  workOrderAsset: {},
  workOrderAssetLoading: false,
  setActiveTask: () => {},
  setActiveWorkOrderAsset: () => {},
  refetchMedia: false,
  setRefetchMedia: () => {},
  workValidations: {},
  setWorkValidations: () => {},
  setTask: () => {},
};

const WorkOrderContext = React.createContext<Context>(initialState);

export enum WorkOrderActionTypes {
  SET_WORK_ORDER = 'SET_WORK_ORDER',
  SET_TASK = 'SET_TASK',
  DELETE_TASK = 'DELETE_TASK',
  UPDATE_WORK_ORDER_PROPERTY = 'UPDATE_WORK_ORDER_PROPERTY',
  UPDATE_TASK_PROPERTY = 'UPDATE_TASK_PROPERTY',
  ADD_TASK_FIELD = 'ADD_TASK_FIELD',
  UPDATE_TASK_FIELD = 'UPDATE_TASK_FIELD',
  UPDATE_TASK_FIELD_VALUE = 'UPDATE_TASK_FIELD_VALUE',
  DELETE_TASK_FIELD = 'DELETE_TASK_FIELD',
  ADD_WORK_ORDER_FIELD = 'ADD_WORK_ORDER_FIELD',
  UPDATE_WORK_ORDER_FIELD = 'UPDATE_WORK_ORDER_FIELD',
  UPDATE_WORK_ORDER_FIELD_VALUE = 'UPDATE_WORK_ORDER_FIELD_VALUE',
  DELETE_WORK_ORDER_FIELD = 'DELETE_WORK_ORDER_FIELD',
}

interface SetWorkOrder {
  type: WorkOrderActionTypes.SET_WORK_ORDER;
  data: WorkOrderDetailType;
}

interface UpdateWorkOrderProperty {
  type: WorkOrderActionTypes.UPDATE_WORK_ORDER_PROPERTY;
  data: {
    property: keyof WorkOrderDetailType;
    value: any;
  };
}

interface UpdateTaskProperty {
  type: WorkOrderActionTypes.UPDATE_TASK_PROPERTY;
  data: {
    taskId: string;
    property: keyof Task;
    value: any;
  };
}

interface SetTask {
  type: WorkOrderActionTypes.SET_TASK;
  data: Task;
}

interface DeleteTask {
  type: WorkOrderActionTypes.DELETE_TASK;
  data: {
    taskId: string;
  };
}

interface AddTaskField {
  type: WorkOrderActionTypes.ADD_TASK_FIELD;
  data: {
    taskId: string;
    field: TaskField;
  };
}

interface UpdateTaskField {
  type: WorkOrderActionTypes.UPDATE_TASK_FIELD;
  data: {
    taskId: string;
    field: TaskField;
  };
}

interface UpdateTaskFieldValue {
  type: WorkOrderActionTypes.UPDATE_TASK_FIELD_VALUE;
  data: {
    taskId: string;
    field: TaskField;
  };
}

interface DeleteTaskField {
  type: WorkOrderActionTypes.DELETE_TASK_FIELD;
  data: {
    taskId: string;
    fieldId: string;
  };
}

interface AddWorkOrderField {
  type: WorkOrderActionTypes.ADD_WORK_ORDER_FIELD;
  data: {
    field: WorkOrderField;
  };
}

interface UpdateWorkOrderField {
  type: WorkOrderActionTypes.UPDATE_WORK_ORDER_FIELD;
  data: {
    field: WorkOrderField;
  };
}

interface UpdateWorkOrderFieldValue {
  type: WorkOrderActionTypes.UPDATE_WORK_ORDER_FIELD_VALUE;
  data: {
    field: WorkOrderField;
  };
}

interface DeleteWorkOrderField {
  type: WorkOrderActionTypes.DELETE_WORK_ORDER_FIELD;
  data: {
    fieldId: string;
  };
}

export type WorkOrderActions =
  | SetWorkOrder
  | UpdateWorkOrderProperty
  | UpdateTaskProperty
  | SetTask
  | DeleteTask
  | AddTaskField
  | UpdateTaskField
  | UpdateTaskFieldValue
  | DeleteTaskField
  | AddWorkOrderField
  | UpdateWorkOrderField
  | UpdateWorkOrderFieldValue
  | DeleteWorkOrderField;

// updates a single property on the workOrder.
const updateWorkOrderProperty = (
  state: WorkOrderDetailType,
  action: UpdateWorkOrderProperty,
) => {
  return {
    ...state,
    [action.data.property]: action.data.value,
  };
};

// replaces the entire task with the new updatedTask
const setTask = (state: WorkOrderDetailType, action: SetTask) => {
  const { data: updatedTask } = action;
  const taskIndex = state.tasks.findIndex(task => task.id === updatedTask.id);

  return R.over(R.lensPath(['tasks', taskIndex]), () => updatedTask, state);
};

const deleteTask = (state: WorkOrderDetailType, action: DeleteTask) => {
  return {
    ...state,
    tasks: state.tasks.filter(task => task.id !== action.data.taskId),
  };
};

// sets updatedBy and updatedDate on task in state
// needed as some validation checks updatedDate to determine
// if task has ever been updated
const updateTask = (state: WorkOrderDetailType, index: number) => {
  const updateBy = getUserIdSync();
  const updatedDate = new Date().valueOf();
  const lens = R.lensPath(['tasks', index]);
  return R.over(lens, task => ({ ...task, updatedDate, updateBy }), state);
};

const addTaskField = (state: WorkOrderDetailType, action: AddTaskField) => {
  const { taskId, field } = action.data;
  const taskIndex = state.tasks.findIndex(task => task.id === taskId);

  const updatedTaskFieldOrder = R.over(
    R.lensPath(['tasks', taskIndex]),
    task => ({ ...task, fieldOrder: [...task.fieldOrder, field.id] }),
  )(updateTask(state, taskIndex));

  return R.over(R.lensPath(['tasks', taskIndex, 'fields']), fields => [
    ...fields,
    field,
  ])(updatedTaskFieldOrder);
};

const updateTaskField = (
  state: WorkOrderDetailType,
  action: UpdateTaskField | UpdateTaskFieldValue,
) => {
  const { taskId, field: updatedField } = action.data;
  const taskIndex = state.tasks.findIndex(task => task.id === taskId);

  const updatePath = getFieldPathById(
    updatedField.id,
    state.tasks[taskIndex].fields,
  );

  const updatedPayload =
    action.type === WorkOrderActionTypes.UPDATE_TASK_FIELD_VALUE
      ? { value: updatedField.value }
      : updatedField;

  return R.over(R.lensPath(['tasks', taskIndex, ...updatePath]), field => ({
    ...field,
    ...updatedPayload,
  }))(updateTask(state, taskIndex));
};

const deleteTaskField = (
  state: WorkOrderDetailType,
  action: DeleteTaskField,
) => {
  const { taskId, fieldId } = action.data;
  const taskIndex = state.tasks.findIndex(task => task.id === taskId);

  const updatedTaskFieldOrder = R.over(
    R.lensPath(['tasks', taskIndex]),
    task => ({
      ...task,
      fieldOrder: task.fieldOrder.filter(item => item !== fieldId),
    }),
  )(updateTask(state, taskIndex));

  return R.over(R.lensPath(['tasks', taskIndex, 'fields']), fields =>
    fields.filter(field => field.id !== fieldId),
  )(updatedTaskFieldOrder);
};

const addWorkOrderField = (
  state: WorkOrderDetailType,
  action: AddWorkOrderField,
) => {
  const { field } = action.data;

  const updatedWorkOrder = {
    ...state,
    fieldOrder: [...state.fieldOrder, field.id],
  };

  return R.over(R.lensPath(['fields']), fields => [...fields, field])(
    updatedWorkOrder,
  );
};

const updateWorkOrderField = (
  state: WorkOrderDetailType,
  action: UpdateWorkOrderField | UpdateWorkOrderFieldValue,
) => {
  const { field: updatedField } = action.data;

  const updatePath = getFieldPathById(updatedField.id, state.fields);

  const updatedPayload =
    action.type === WorkOrderActionTypes.UPDATE_WORK_ORDER_FIELD_VALUE
      ? { value: updatedField.value }
      : updatedField;

  return R.over(R.lensPath(updatePath), field => ({
    ...field,
    ...updatedPayload,
  }))(state);
};

const deleteWorkOrderField = (
  state: WorkOrderDetailType,
  action: DeleteWorkOrderField,
) => {
  const { fieldId } = action.data;

  const updatedWorkOrder = {
    ...state,
    fieldOrder: state.fieldOrder.filter(itemId => itemId !== fieldId),
  };

  return R.over(R.lensPath(['fields']), fields =>
    fields.filter(field => field.id !== fieldId),
  )(updatedWorkOrder);
};

const updateTaskProperty = (
  state: WorkOrderDetailType,
  action: UpdateTaskProperty,
) => {
  const { taskId, property, value } = action.data;
  const taskIndex = state.tasks.findIndex(task => task.id === taskId);
  return R.set(R.lensPath(['tasks', taskIndex, property]), value)(state);
};

export const reducer = (
  state: WorkOrderDetailType,
  action: WorkOrderActions,
): WorkOrderDetailType => {
  switch (action.type) {
    case WorkOrderActionTypes.SET_WORK_ORDER:
      return action.data;
    case WorkOrderActionTypes.UPDATE_WORK_ORDER_PROPERTY:
      return updateWorkOrderProperty(state, action);
    case WorkOrderActionTypes.UPDATE_TASK_PROPERTY:
      return updateTaskProperty(state, action);
    case WorkOrderActionTypes.SET_TASK:
      return setTask(state, action);
    case WorkOrderActionTypes.DELETE_TASK:
      return deleteTask(state, action);
    case WorkOrderActionTypes.ADD_TASK_FIELD:
      return addTaskField(state, action);
    case WorkOrderActionTypes.UPDATE_TASK_FIELD:
    case WorkOrderActionTypes.UPDATE_TASK_FIELD_VALUE:
      return updateTaskField(state, action);
    case WorkOrderActionTypes.DELETE_TASK_FIELD:
      return deleteTaskField(state, action);
    case WorkOrderActionTypes.ADD_WORK_ORDER_FIELD:
      return addWorkOrderField(state, action);
    case WorkOrderActionTypes.UPDATE_WORK_ORDER_FIELD:
    case WorkOrderActionTypes.UPDATE_WORK_ORDER_FIELD_VALUE:
      return updateWorkOrderField(state, action);
    case WorkOrderActionTypes.DELETE_WORK_ORDER_FIELD:
      return deleteWorkOrderField(state, action);
    default: {
      return state;
    }
  }
};

export default WorkOrderContext;
