import React from 'react';
import {
  FormControlLabel as MUIFormControlLabel,
  Switch as MUISwitch,
  SwitchProps as MUISwitchProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import fonts from '@atom/styles/fonts';

type CustomProps = {
  /**
   * value of the toggle label.
   * Defaults to empty string.
   */
  label?: React.ReactNode;
  style?: React.CSSProperties;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
};

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: fonts.md,
  },
}));

export type SwitchProps = CustomProps & MUISwitchProps;

export const Switch = (props: SwitchProps) => {
  const classes = useStyles(props);
  const {
    label = '',
    labelPlacement = 'end',
    color = 'primary',
    style,
    ...data
  } = props;

  return (
    <MUIFormControlLabel
      control={<MUISwitch {...data} color={color} />}
      classes={{
        label: classes.label,
        ...props.classes,
      }}
      style={style}
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};

export default Switch;
