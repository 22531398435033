import React, { useContext } from 'react';

// @ts-ignore
import workTypeLocationIcon from '@atom/components/common/svgIcons/workTypeLocationIcon.svg';
import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';

import PreviewAttributeDetail from '../previewSubItems/PreviewAttributeDetail';

import './previewInfo.css';

const PreviewInfo = () => {
  const { schemaTree } = useContext(SchemaDetailContext);

  return schemaTree.isMaterial ? (
    <div styleName="center-pane-container">
      <div styleName="center-pane">
        <PreviewAttributeDetail schema={schemaTree} />
      </div>
    </div>
  ) : (
    <>
      <div styleName="left-body-pane-half">
        <PreviewAttributeDetail schema={schemaTree} />
      </div>
      <div styleName="right-body-pane-half">
        <div styleName="location-section">
          <div styleName="location-content">
            <img styleName="location-image" src={workTypeLocationIcon} />
            <div styleName="location-title">
              Location is determined during asset creation.
            </div>
            <div styleName="location-text">
              When an asset is created, this section will display the location
              of that asset on a map.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewInfo;
