import React, { useMemo, useState } from 'react';

import InventoryAssetTree from '@atom/components/common/inventoryAssetTree/InventoryAssetTree';
import { Modal } from '@atom/mui';
import { Task } from '@atom/types/task';
import { WorkTemplateTaskItem } from '@atom/types/workTemplate';
import { toggleFromSet } from '@atom/utilities/setUtilities';

interface Props {
  open: boolean;
  onClose: () => void;
  task: Task | WorkTemplateTaskItem;
  handleAddCategories: (categoryIds: string[]) => void;
}

const styles = {
  modal: {
    height: '50vh',
    padding: '2rem',
  },
};

const AddCategoriesModal = ({
  open,
  onClose,
  task,
  handleAddCategories,
}: Props) => {
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<Set<string>>(
    new Set(),
  );

  const handleToggle = (category: { id: string }) => {
    setSelectedCategoryIds(toggleFromSet(selectedCategoryIds, category.id));
  };

  const handleSubmit = () => {
    handleAddCategories([...selectedCategoryIds]);
  };

  const disabledCategoryIds = useMemo(() => {
    return new Set<string>(task.categories.map(category => category.id));
  }, [task]);

  return (
    <Modal
      title="Add Equipment and Material Folders"
      open={open}
      onConfirm={handleSubmit}
      onCancel={onClose}
      contentStyle={styles.modal}
      onExited={() => setSelectedCategoryIds(new Set())}
    >
      <InventoryAssetTree
        includeAssets={false}
        includeLocationBased={false}
        selectedCategoryIds={
          new Set([...selectedCategoryIds, ...disabledCategoryIds])
        }
        onCategoryToggle={handleToggle}
        disabledCategoryIds={disabledCategoryIds}
        search="categories"
      />
    </Modal>
  );
};

export default AddCategoriesModal;
