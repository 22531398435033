import React from 'react';

import { Icon, IconButton } from '@atom/mui';
import markerUtilities from '@atom/utilities/markerUtilities';

import './drawerContent.css';

interface Props {
  title?: string;
  subtitle?: string;
  markerId?: number;
  attributes?: { [key: string]: any };
  onCancel: () => void;
}

const KmlPlacemark = ({
  title,
  subtitle,
  markerId,
  attributes = {},
  onCancel,
}: Props) => {
  const icon = markerUtilities.getKmlSvg(markerId);
  return (
    <div styleName="container">
      <div styleName="tray-container">
        <div styleName="kml-header">
          <div styleName="kml-header-title-container">
            <img
              style={{ height: 40, width: 40, marginRight: '0.5rem' }}
              src={icon}
            />
            <div>
              <div styleName="kml-header-title">{title}</div>
              <div styleName="kml-attribute">{subtitle}</div>
            </div>
          </div>
          <IconButton onClick={onCancel}>
            <Icon>close</Icon>
          </IconButton>
        </div>
        <div styleName="kml-attributes">
          {Object.entries(attributes).map(([key, value], index) => (
            <div key={index} styleName="kml-row">
              <div styleName="kml-attribute label">{key}</div>
              <div styleName="kml-value">{value || '-'}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KmlPlacemark;
