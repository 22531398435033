import React from 'react';

import UserThumbnail from '@atom/components/common/UserThumbnail';
import { Checkbox } from '@atom/mui';
import { UserDetail } from '@atom/types/user';

import './teamControls.css';

interface Props {
  user: UserDetail;
  selectedUsers: Set<string>;
  toggleUser: (id: string) => void;
  groupId: string;
}

const AddUserRow = ({ user, toggleUser, selectedUsers, groupId }: Props) => {
  const userGroups = user.userGroups.map(userGroup => userGroup.id);

  const isPending = !user.isActive && !user.isVerified;
  const isDisabled = userGroups.includes(groupId);
  const isChecked = selectedUsers.has(user.id);

  const onToggle = isDisabled ? () => {} : () => toggleUser(user.id);

  const getUserLabel = () => {
    if (isPending) {
      return 'Pending Request';
    }

    if (isDisabled) {
      return 'Already in this group';
    }

    return '';
  };

  const disabledStyle = isDisabled ? 'disabled' : '';

  return (
    <div styleName="user-row">
      <div styleName="users-left-content">
        <div>
          <Checkbox onChange={onToggle} checked={isChecked} />
        </div>
        <UserThumbnail
          image={user.photoUrl}
          alt={user.firstName}
          pending={isPending}
        />
        <div styleName="info-container" onClick={onToggle}>
          <div
            styleName={`user-name ${disabledStyle}`}
          >{`${user.firstName} ${user.lastName}`}</div>
          <div styleName={`user-email ${disabledStyle}`}>{user.email}</div>
        </div>
      </div>
      <div styleName="user-label">{getUserLabel()}</div>
    </div>
  );
};

export default AddUserRow;
