import React from 'react';
import * as R from 'ramda';

import { Checkbox } from '@atom/mui';
import colors from '@atom/styles/colors';
import { FormInstanceFieldItem, PciModuleDistress } from '@atom/types/form';

import './pci.css';

interface Props {
  field: FormInstanceFieldItem;
}

const styles = {
  checkbox: {
    marginLeft: '2rem',
  },
  icon: {
    color: colors.neutral.gray,
    marginRight: '0.5rem',
  },
};

const AcSummary = ({ field }: Props) => {
  const hasNoDistress = R.pathOr(false, ['pci', 'hasNoDistress'], field);

  const distresses = R.pathOr<PciModuleDistress[]>(
    [],
    ['pci', 'distress'],
    field,
  );

  return (
    <>
      <div styleName={`inspection-container ${hasNoDistress ? 'checked' : ''}`}>
        <div styleName="no-distress-container">
          <div styleName="subtitle">Inspection</div>
          <Checkbox
            style={styles.checkbox}
            label="No Distress"
            disabled
            checked={hasNoDistress}
          />
        </div>
      </div>
      {!hasNoDistress && (
        <>
          <div styleName="table-header">
            <div styleName="column distress-summary">distress</div>
            <div styleName="column severity-summary">distress severity</div>
            <div styleName="column quantity-summary">total qty</div>
          </div>
          <div styleName="table-rows-container">
            {distresses.map(({ distress, severity, quantity }) => (
              <div styleName="table-row">
                <div styleName="column summary distress-summary">
                  {distress}
                </div>
                <div styleName="column summary severity-summary">
                  {severity}
                </div>
                <div styleName="column summary quantity-summary">
                  {quantity}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default AcSummary;
