import * as R from 'ramda';

import {
  PendingCreations,
  PendingUpdates,
} from '@atom/components/schemaDetail/SchemaDetailContext';
import { SchemaTreeAttribute } from '@atom/types/schema';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

export const hydrateAttributesWithPendingChanges = (
  schemaId: string,
  attributeGroupId: string,
  attributes: SchemaTreeAttribute[],
  pendingUpdates: PendingUpdates,
  pendingCreations: PendingCreations,
): SchemaTreeAttribute[] => {
  if (isNilOrEmpty(pendingUpdates) && isNilOrEmpty(pendingCreations)) {
    return attributes;
  }

  const updates = R.pathOr({}, [schemaId], pendingUpdates);
  const creations =
    R.values(R.pathOr({}, [schemaId, attributeGroupId], pendingCreations)) ||
    [];

  const updatedAttributes = attributes.map(attribute => ({
    ...attribute,
    ...R.pathOr({}, [attribute.id], updates),
  }));

  return [...updatedAttributes, ...creations];
};
