import React, { useCallback, useEffect, useRef } from 'react';
import * as R from 'ramda';

import { Icon, List, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import { BudgetUnitSearch } from '@atom/types/budget';

const { ListItem, ListItemText } = List;

const styles = {
  iconStyle: {
    fontSize: '1.75rem',
    margin: 0,
  },
  containerPadding: {
    margin: '1rem',
  },
  listItemText: {
    paddingLeft: '0.5rem',
  },
  textNoWrap: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  listItem: {
    cursor: 'pointer',
    maxWidth: '21rem',
  },
  optionsContainer: {
    maxHeight: '330px',
    overflow: 'auto',
  },
};
export interface Props {
  searchResults: BudgetUnitSearch[];
  loadingSearch: boolean;
  handleNavigateToUnit: (unitId: string) => void;
  handlePageScroll: (nextPage: number) => void;
  page: number;
  total: number;
}

const BudgetDetailUnitSearchResults = ({
  searchResults,
  loadingSearch,
  handleNavigateToUnit,
  handlePageScroll,
  page,
  total,
}: Props) => {
  const scrollContainerRef = useRef<any>(null);

  const handleScroll = useCallback(() => {
    const elem = scrollContainerRef?.current;
    const isBottom =
      elem?.scrollTop + elem?.clientHeight + 0.5 >= elem?.scrollHeight;
    if (isBottom && searchResults.length < total && !loadingSearch) {
      handlePageScroll(page + 1);
    }
  }, [
    scrollContainerRef.current,
    total,
    page,
    searchResults,
    handlePageScroll,
  ]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef?.current?.addEventListener('scroll', handleScroll);
      return () =>
        scrollContainerRef?.current?.removeEventListener(
          'scroll',
          handleScroll,
        );
    }
    return () => {};
  }, [handleScroll]);

  return R.isNil(searchResults) ? (
    <Progress style={styles.containerPadding} size={20} />
  ) : R.isEmpty(searchResults) ? (
    <div style={styles.containerPadding}>no results</div>
  ) : (
    <div ref={scrollContainerRef} style={styles.optionsContainer}>
      <List>
        {searchResults.map(unit => (
          <ListItem
            key={unit.id}
            style={styles.listItem}
            onClick={() => handleNavigateToUnit(unit.id)}
          >
            <Icon style={styles.iconStyle} color={colors.neutral.gray}>
              folder
            </Icon>
            <ListItemText
              style={styles.listItemText}
              primary={unit.name}
              primaryTextStyle={styles.textNoWrap}
              primaryTypographyProps={{ style: { whiteSpace: 'nowrap' } }}
              secondary={unit.ancestors.map(({ name }) => name).join(' / ')}
              secondaryTextStyle={styles.textNoWrap}
            />
          </ListItem>
        ))}
      </List>
      {loadingSearch && (
        <div>
          <Progress size={20} />
        </div>
      )}
    </div>
  );
};

export default BudgetDetailUnitSearchResults;
