import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import EditRoleModal from '@atom/components/common/editRoleModal/EditRoleModal';
import RoleDuplicateModal from '@atom/components/common/roleDuplicateModal/RoleDuplicateModal';
import { DELETE_ROLE, GET_ROLES } from '@atom/graph/role';
import { Icon, IconButton, ListTable, Menu, Progress } from '@atom/mui';
import { Role, RolesConnectionInput } from '@atom/types/role';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import { getUserFullName } from '@atom/utilities/userUtilities';

import CreateRoleModal from './CreateRoleModal';

import './rolesTable.css';

const {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} = ListTable;

const { MenuItem } = Menu;

type SortDirection = 'asc' | 'desc';
type SortField = 'name' | 'updatedDate';

const RolesTable = () => {
  const [selectedRole, setSelectedRole] = useState<Role>(null);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [input, setInput] = useState<RolesConnectionInput>({
    page: 1,
    limit: 25,
    showAdmin: hasRolePermissions(ROLE_SETS.ORG_ADMIN),
    sortBy: 'name',
    sortDirection: 'asc',
  });

  const [deleteRole, { loading: loadingDelete }] = useMutation(DELETE_ROLE);

  const { data, loading, refetch } = useQuery(GET_ROLES, {
    variables: { input },
    fetchPolicy: 'network-only',
  });

  const getSortDirection = useCallback(
    (field: SortField) => {
      if (input.sortBy !== field) {
        return false;
      }

      return input.sortDirection;
    },
    [input.sortBy, input.sortDirection],
  );

  const handleSortDirectionChange = (sortBy: SortField) => (
    sortDirection: SortDirection,
  ) => {
    setInput({ ...input, sortBy, sortDirection });
  };

  const handleDelete = async () => {
    await deleteRole({
      variables: { id: selectedRole.id },
    });

    setDeleteModalOpen(false);
    refetch();
  };

  const totalCount = R.pathOr<number>(0, ['roles', 'totalCount'], data);
  const roles = R.pathOr<Role[]>([], ['roles', 'roles'], data);

  return (
    <>
      <div styleName="roles-header">
        <div>Roles</div>
        {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
          <IconButton
            onClick={() => setCreateModalOpen(true)}
            data-cy="teamPortalRolesAddRoleButton"
          >
            <Icon>add</Icon>
          </IconButton>
        )}
      </div>
      {loading ? (
        <div styleName="loading">
          <Progress />
        </div>
      ) : (
        <ListTable offsetTop="215px">
          <TableHead>
            <TableRow header>
              <TableCell
                width="11.25rem"
                variant="head"
                sortDirection={getSortDirection('name')}
                onSortChange={handleSortDirectionChange('name')}
              >
                <div>Name</div>
              </TableCell>
              <TableCell variant="head">Description</TableCell>
              <TableCell width="12.5rem" variant="head">
                Last Updated By
              </TableCell>
              <TableCell
                width="9.375rem"
                variant="head"
                sortDirection={getSortDirection('updatedDate')}
                onSortChange={handleSortDirectionChange('updatedDate')}
              >
                Last Updated On
              </TableCell>
              <TableCell variant="head" />
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map(role => (
              <TableRow key={role.id}>
                <TableCell style={{ fontWeight: 500 }}>
                  <Link to={`/team/role/${role.id}`}>{role.name}</Link>
                </TableCell>
                <TableCell>{role.description}</TableCell>
                <TableCell>
                  {/* @ts-ignore */}
                  {getUserFullName(role.updatedBy)}
                </TableCell>
                <TableCell>{setDisplayDate(role.updatedDate)}</TableCell>
                <TableCell width="48px">
                  {hasRolePermissions(ROLE_SETS.ORG_ADMIN) ? (
                    <Menu>
                      {!role.isDefault && (
                        <MenuItem
                          startAdornment={<Icon>edit</Icon>}
                          onClick={() => {
                            setSelectedRole(role);
                            setEditModalOpen(true);
                          }}
                        >
                          Edit
                        </MenuItem>
                      )}
                      <MenuItem
                        startAdornment={<Icon>content_copy</Icon>}
                        onClick={() => {
                          setSelectedRole(role);
                          setDuplicateModalOpen(true);
                        }}
                        data-cy="roleDuplicateButton"
                      >
                        Duplicate
                      </MenuItem>
                      {!role.isDefault && (
                        <MenuItem
                          startAdornment={<Icon>delete</Icon>}
                          onClick={() => {
                            setSelectedRole(role);
                            setDeleteModalOpen(true);
                          }}
                          data-cy="roleDeleteButton"
                        >
                          Delete
                        </MenuItem>
                      )}
                    </Menu>
                  ) : (
                    <div style={{ height: '48px' }} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                count={totalCount}
                rowsPerPage={input.limit}
                page={input.page}
                onPageChange={page => setInput({ ...input, page })}
                onRowsPerPageChange={event =>
                  setInput({ ...input, limit: +event.target.value, page: 1 })
                }
              />
            </TableRow>
          </TableFooter>
        </ListTable>
      )}
      <CreateRoleModal
        open={createModalOpen}
        onClose={shouldRefetch => {
          setCreateModalOpen(false);
          if (shouldRefetch) {
            refetch();
          }
        }}
      />
      <EditRoleModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        role={selectedRole}
      />
      <RoleDuplicateModal
        open={duplicateModalOpen}
        onClose={created => {
          setDuplicateModalOpen(false);

          if (created) {
            refetch();
          }
        }}
        role={selectedRole}
      />
      <DeleteModal
        title="Delete Role?"
        content="Deleting a role will delete all of its policies and remove it from all assigned users and user groups. Are you sure?"
        open={deleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={handleDelete}
        loading={loadingDelete}
      />
    </>
  );
};

export default RolesTable;
