import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';

import * as inventoryActionCreators from '@atom/actions/inventoryAssetActions';
import AttributeDetails from '@atom/components/common/attributeDetail/AttributeDetails';
import ElementMedia from '@atom/components/common/elements/ElementMedia';
import ElementNavigationIcon from '@atom/components/common/elements/ElementNavigationIcon';
import { Progress } from '@atom/mui';
import { InventoryAssetActions } from '@atom/types/actions';
import { AttributesType } from '@atom/types/inventory';
import { PolicyAction } from '@atom/types/policy';
import { InventoryAssetDetailState } from '@atom/types/store';
import { hasAccess } from '@atom/utilities/accessUtilities';

import './detail.css';

interface PassedProps {
  inventoryAssetDetail: InventoryAssetDetailState;
  loadingAsset: boolean;
  loadingAssetAttributes: any[];
  loadingElementAttributes: any[];
  elementDetail: any;
  selectedItem: any;
}

interface ReduxDispatchProps {
  inventoryAssetActions: InventoryAssetActions;
}

type Props = ReduxDispatchProps & PassedProps;

interface State {
  activeView: string;
}

const initialState = {
  activeView: 'info',
};

class DetailViewPane extends React.Component<Props, State> {
  state = initialState;

  static getDerivedStateFromProps(
    { elementDetail }: Props,
    // eslint-disable-next-line
    state: State,
  ): State | null {
    if (R.isNil(elementDetail) || R.isEmpty(elementDetail)) {
      return {
        ...initialState,
      };
    }

    return null;
  }

  setActiveView = (activeView: string) => {
    this.setState({ activeView });
  };

  onPendingApproval = (
    action: string,
    attributeGroupName: string,
    attribute: AttributesType,
  ) => {
    const { inventoryAssetActions, elementDetail } = this.props;

    inventoryAssetActions.requestElementAttributePendingApproval({
      action,
      attributeGroupName,
      attribute,
      assetId: elementDetail.id,
    });
  };

  onUpdate = (payload: Object) => {
    const { inventoryAssetActions, elementDetail } = this.props;
    inventoryAssetActions.requestInventoryElementDetailUpdate({
      ...payload,
      rootAssetId: elementDetail.id,
    });
  };

  setElementBodyPane = (activeView: string) => {
    const {
      elementDetail,
      loadingAssetAttributes,
      loadingElementAttributes,
      loadingAsset,
      inventoryAssetDetail,
    } = this.props;

    if (
      !R.isEmpty(loadingAssetAttributes) ||
      !R.isEmpty(loadingElementAttributes) ||
      loadingAsset
    ) {
      return (
        <div styleName="element-detail-spinner-container">
          <Progress style={{ height: '100%' }} />
        </div>
      );
    }

    if (R.isNil(elementDetail) || R.isEmpty(elementDetail)) {
      return <div />;
    }

    const canManageChanges = hasAccess(
      PolicyAction.MANAGE_INVENTORY_CHANGES,
      inventoryAssetDetail.actions,
    );

    const canUpdate = hasAccess(
      PolicyAction.UPDATE,
      inventoryAssetDetail.actions,
    );

    const canCreateMedia = hasAccess(
      PolicyAction.CREATE_MEDIA,
      inventoryAssetDetail.actions,
    );

    const canUpdateMedia = hasAccess(
      PolicyAction.UPDATE_MEDIA,
      inventoryAssetDetail.actions,
    );

    const canDeleteMedia = hasAccess(
      PolicyAction.DELETE_MEDIA,
      inventoryAssetDetail.actions,
    );

    const content = {
      info: (
        <AttributeDetails
          inventoryAssetDetail={elementDetail}
          onPendingApproval={this.onPendingApproval}
          onAttributeUpdate={this.onUpdate}
          loading={loadingElementAttributes}
          canManageChanges={canManageChanges}
          canUpdate={canUpdate}
        />
      ),
      media: (
        <ElementMedia
          subjectId={elementDetail.id}
          parentSubjectId={inventoryAssetDetail.id}
          subjectType="inventoryAsset"
          canCreateMedia={canCreateMedia}
          canUpdateMedia={canUpdateMedia}
          canDeleteMedia={canDeleteMedia}
        />
      ),
    };

    return content[activeView];
  };

  render() {
    const { activeView } = this.state;
    const { selectedItem, elementDetail } = this.props;

    const views = [
      {
        value: 'info',
        icon: 'info',
      },
      {
        value: 'media',
        icon: 'folder',
      },
    ];

    const renderNavigation =
      !R.isEmpty(selectedItem) &&
      !R.isNil(elementDetail) &&
      !R.isEmpty(elementDetail);

    return (
      <React.Fragment>
        {renderNavigation && (
          <div>
            <div styleName="icon-row">
              <div styleName="element-name">{selectedItem.name}</div>
              {views.map((view: any) => {
                return (
                  <ElementNavigationIcon
                    key={view.value}
                    value={view.value}
                    icon={view.icon}
                    activeView={activeView}
                    setActiveView={this.setActiveView}
                  />
                );
              })}
            </div>
            {this.setElementBodyPane(activeView)}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  inventoryAssetActions: bindActionCreators(inventoryActionCreators, dispatch),
});

export default connect(null, mapDispatchToProps)(DetailViewPane);
