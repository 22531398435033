import React, { useContext } from 'react';

import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { RadioGroup, Switch } from '@atom/mui';
import {
  SchemaBudget,
  StartEndReadingOption,
  StockBasedOption,
} from '@atom/types/schema';

import './costDetail.css';

const { RadioButton } = RadioGroup;

interface Props {
  budget: SchemaBudget;
  setBudget: (budget: SchemaBudget) => void;
  updateBudget: (budget: SchemaBudget) => void;
}

const ReadingsSection = ({ budget, setBudget, updateBudget }: Props) => {
  const { schemaTree } = useContext(SchemaDetailContext);

  const { startEndReadingOption, stockBasedOption } = budget;

  const updateStartEndReadingOption = (option: StartEndReadingOption) => {
    const updatedBudget = {
      ...budget,
      startEndReadingOption: option,
    };

    setBudget(updatedBudget);
    updateBudget(updatedBudget);
  };

  const handleToggle = () => {
    const option =
      startEndReadingOption === StartEndReadingOption.NONE
        ? StartEndReadingOption.START_END_READING
        : StartEndReadingOption.NONE;

    const updatedBudget = {
      ...budget,
      startEndReadingOption: option,
    };

    setBudget(updatedBudget);
    updateBudget(updatedBudget);
  };

  const isStockBased =
    stockBasedOption === StockBasedOption.STOCK_BASED ||
    stockBasedOption === StockBasedOption.STOCK_BASED_OPTIONAL;

  const toggled =
    !isStockBased &&
    (startEndReadingOption === StartEndReadingOption.START_END_READING ||
      startEndReadingOption ===
        StartEndReadingOption.START_END_READING_OPTIONAL);

  const isDisabled = schemaTree.isPublished || isStockBased;

  const label = isStockBased ? (
    <div styleName="readings-container">
      <div styleName="readings-bold">Enable Start and End Readings</div>
      <div styleName="readings-subtext">
        Not supported for&nbsp;
        <span styleName="readings-bold">Stock-based Inventory</span>
      </div>
    </div>
  ) : (
    'Enable Start and End Readings'
  );

  return (
    <div styleName="cost-container">
      <Switch
        checked={toggled}
        onChange={handleToggle}
        label={label}
        disabled={isDisabled}
      />
      {toggled && (
        <RadioGroup
          value={startEndReadingOption}
          onChange={event => updateStartEndReadingOption(event.target.value)}
        >
          <RadioButton
            value={StartEndReadingOption.START_END_READING}
            label="Default"
            disabled={isDisabled}
          />
          <div styleName="option-subtext">
            Enable for all inventory items of this type.
          </div>
          <RadioButton
            value={StartEndReadingOption.START_END_READING_OPTIONAL}
            label="Optional"
            disabled={isDisabled}
          />
          <div styleName="option-subtext">
            Enable at the individual inventory item level.
          </div>
        </RadioGroup>
      )}
    </div>
  );
};

export default ReadingsSection;
