import React from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { AnalyticsModal } from '@atom/types/analytics';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import history from '@atom/utilities/history';

import './analyticsDetail.css';

interface Props {
  name: string;
  setActiveModal: (modal: AnalyticsModal) => void;
  loading: boolean;
}

const navigateBack = (): void => {
  return history.push('/analytics/dashboard');
};

const AnalyticsHeader = ({ name, setActiveModal, loading }: Props) => {
  return (
    <div styleName="header-container">
      <IconButton onClick={navigateBack}>
        <Icon color={colors.neutral.white}>arrow_back</Icon>
      </IconButton>
      <div>{name}</div>
      {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
        <div styleName="header-buttons">
          <IconButton
            disabled={loading}
            onClick={() => !loading && setActiveModal(AnalyticsModal.UPDATE)}
            data-cy="analyticsDetailEditCardIcon"
          >
            <Icon color={loading ? colors.neutral.dim : colors.neutral.white}>
              edit
            </Icon>
          </IconButton>
          <IconButton
            disabled={loading}
            onClick={() => !loading && setActiveModal(AnalyticsModal.DELETE)}
            data-cy="analyticsDetailDeleteCardIcon"
          >
            <Icon color={loading ? colors.neutral.dim : colors.neutral.white}>
              delete
            </Icon>
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default AnalyticsHeader;
