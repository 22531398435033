import React, { useContext, useMemo } from 'react';

import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { rollUpHydratedTaskUsers } from '@atom/utilities/userUtilities';

import './workTemplateInfo.css';

const WorkTemplateDetails = () => {
  const { workTemplate } = useContext(WorkTemplateContext);

  const hydratedUsers = rollUpHydratedTaskUsers(workTemplate.tasks);

  const teamLead = useMemo(() => {
    return hydratedUsers.find(user => user.id === workTemplate?.leadAssigneeId);
  }, [workTemplate?.leadAssigneeId, hydratedUsers]);

  const teamLeadName = teamLead
    ? `${teamLead?.firstName} ${teamLead?.lastName}`
    : '-';

  return (
    <>
      <div styleName="section-header">
        <div>Details</div>
      </div>
      <div styleName="detail-content">
        <div styleName="info-row">
          <div styleName="row-label">Team Lead:</div>
          <div>{teamLeadName}</div>
        </div>
        <div styleName="info-row">
          <div styleName="row-label">Estimated Cost:</div>
          <div>
            <div>{numberToLocaleString(workTemplate?.estimatedCost)}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkTemplateDetails;
