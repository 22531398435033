import * as R from 'ramda';

import { DataType } from '@atom/types/dataType';
import { MaterialEntry } from '@atom/types/materialEntry';
import { Preferences } from '@atom/types/preferences';
import { Task, TaskField } from '@atom/types/task';
import { ALDOTLocationDataTitle, TaskLocation } from '@atom/types/taskLocation';
import { WorkOrderTimeEntry } from '@atom/types/timeEntry';
import {
  WorkOrderDetailType,
  WorkOrderType,
  WorkReport,
} from '@atom/types/work';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import { getNestedLeafPath } from '@atom/utilities/workOrderFieldUtilities';

import { aldotCustomCascadeValues } from './reportConstants';

export interface ReportUser {
  userId: string;
  name: string;
  duration: number;
  actualCost: number;
  isTeamLead: boolean;
  perDiem?: string[];
}

export interface ReportTaskMaterials {
  startEndMaterials: ReportMaterial[];
  usageMaterials: ReportMaterial[];
}

export interface ReportMaterial {
  assetId: string;
  schemaId: string;
  name: string;
  rate: number;
  unit?: string;
  entries?: MaterialEntry[];
}

export interface MappedField {
  id: string;
  required: boolean;
  dataType: DataType;
  title: string;
  value?: any;
  units?: string;
}

export interface ReportLocations {
  managementUnit: string;
  county: string;
  roadClass: number;
  locations: TaskLocation[];
}

export interface ReportMultiAssets {
  managementUnit: string;
  county: string;
  roadClass: number;
  multiAssets: any[];
}

export const mapFieldToMappedField = (field: TaskField): MappedField => {
  return {
    id: field?.id,
    title: field?.title,
    required: field?.required,
    dataType: field?.dataType,
    units: field?.units,
    value: field?.value,
  };
};

export const getReportUsersDictionary = (
  timeEntries: WorkOrderTimeEntry[] = [],
  workOrder: WorkOrderDetailType,
  preferences: Preferences,
) => {
  if (isNilOrEmpty(workOrder)) {
    return {};
  }

  const durationMap = timeEntries.reduce((acc, timeEntry) => {
    const complexId = timeEntry?.taskId + timeEntry?.userId;

    const duration =
      timeEntry?.type === 'AUTO_GENERATED' ? timeEntry?.duration : 0;

    return {
      ...acc,
      [complexId]: acc[complexId] ? acc[complexId] + duration : duration,
    };
  }, {});

  const perDiemMap = timeEntries.reduce((acc, timeEntry) => {
    const complexId = timeEntry?.taskId + timeEntry?.userId;

    const typeLabel = R.find(
      R.propEq('value', timeEntry?.type),
      preferences?.timeTracking?.typeEnumeration,
    )?.name;

    const perDiemValue =
      timeEntry?.type === 'AUTO_GENERATED' ? [] : [typeLabel];

    return {
      ...acc,
      [complexId]: acc[complexId]
        ? [...acc[complexId], ...perDiemValue]
        : perDiemValue,
    };
  }, {});

  return workOrder?.tasks?.reduce((acc, task) => {
    return {
      ...acc,
      [task?.id]: task?.users?.map(user => ({
        userId: user?.id,
        name: `${user?.firstName} ${user?.lastName}`,
        duration: durationMap[task?.id + user?.id] || 0,
        actualCost: user?.actualCost || 0,
        isTeamLead: workOrder?.leadAssigneeId === user?.id,
        perDiem: perDiemMap[task?.id + user?.id],
      })),
    };
  }, {});
};

export const getUsageMap = (
  task: Task,
  materialEntryMap: any,
): ReportTaskMaterials => {
  if (isNilOrEmpty(task?.materials)) {
    return { startEndMaterials: [], usageMaterials: [] };
  }

  return task?.materials.reduce(
    (acc, material) => {
      const asset = R.find(
        R.propEq('assetId', material?.assetId),
        task?.materials,
      );

      const mappedMaterial: ReportMaterial = {
        assetId: material?.assetId,
        schemaId: asset?.schemaId,
        name: material?.name,
        unit: material?.unit,
        rate: material?.rate || 0,
        entries: materialEntryMap[task?.id + material?.assetId] || [],
      };

      return material?.isStartEndReading
        ? {
            ...acc,
            startEndMaterials: [...acc.startEndMaterials, mappedMaterial],
          }
        : {
            ...acc,
            usageMaterials: [...acc.usageMaterials, mappedMaterial],
          };
    },
    {
      startEndMaterials: [],
      usageMaterials: [],
    },
  );
};

export const getReportAssetsDictionary = (workOrder: WorkOrderDetailType) => {
  if (isNilOrEmpty(workOrder)) {
    return {};
  }

  return workOrder?.tasks?.reduce(
    (acc, task) => ({
      ...acc,
      [task?.id]: task?.assetIds?.map(assetId =>
        workOrder.type === WorkOrderType.TASK_ASSETS_AND_LOCATIONS
          ? workOrder?.multiAssets[assetId]
          : workOrder?.assets[assetId],
      ),
    }),
    {},
  );
};

export const getReportMaterialEntriesDictionary = (
  materialEntries: MaterialEntry[] = [],
  workOrder: WorkOrderDetailType,
) => {
  if (isNilOrEmpty(workOrder)) {
    return {};
  }

  const materialEntryMap = materialEntries.reduce((acc, materialEntry) => {
    const complexId = materialEntry?.taskId + materialEntry?.assetId;

    return {
      ...acc,
      [complexId]: acc[complexId]
        ? [...acc[complexId], materialEntry]
        : [materialEntry],
    };
  }, {});

  return workOrder?.tasks?.reduce((acc, task) => {
    return {
      ...acc,
      [task?.id]: getUsageMap(task, materialEntryMap),
    };
  }, {});
};

export const getSelectedValueArray = (field: TaskField) => {
  const subFieldIndex = R.findIndex(R.propEq('matchValue', field?.value))(
    field?.subFields || [],
  );

  if (subFieldIndex < 0) {
    return [field];
  }

  // Gets path from root to leaf node through current cascade tree
  const leafNodePath = [
    'subFields',
    subFieldIndex,
    'nestedField',
    ...getNestedLeafPath(field?.subFields[subFieldIndex]?.nestedField),
  ];

  // Every 3 items in the path array represents a selected value;
  const valueCount = leafNodePath.length / 3;

  // Construct and array of paths to each selected value in the cascade tree
  const valuePaths = Array.from(Array(valueCount), _ => []).map((_, index) => {
    const sliceLength = 3 * (index + 1);
    return R.slice(0, sliceLength, leafNodePath);
  });

  // Use the paths array to pull each value from the cascade tree into a flat array
  const nestedValues = valuePaths.map(
    // @ts-ignore
    path => R.view(R.lensPath(path), field),
  );

  return [field, ...nestedValues];
};

export const flattenNestedCascadingField = (
  list: any,
  field: TaskField,
  required: boolean,
) => {
  const currentField = {
    ...mapFieldToMappedField(field),
    required,
  };

  if (isNilOrEmpty(field?.subFields)) {
    return [...list, currentField];
  }

  const nestedList = flattenNestedCascadingField(
    [...list, currentField],
    field?.subFields[0].nestedField,
    required,
  );

  return nestedList;
};

const isFieldValid = (field: TaskField): boolean => {
  // Test to see if the cascading field fits the proper data
  // layout for this custom report

  const firstLevelTitles = field?.subFields.map(
    subField => subField?.nestedField?.title,
  );

  return R.all(R.equals(firstLevelTitles[0]), firstLevelTitles);
};

export const flattenCascadingField = (
  field: TaskField,
  isCompletedOrAbove: boolean,
) => {
  // If the cascading field begins with a specific title, then it should
  // return a custom list of cells to render.
  const isCustomCascade = R.keys(aldotCustomCascadeValues).includes(
    // @ts-ignore
    field?.title,
  );

  if (isCustomCascade && !isCompletedOrAbove) {
    return aldotCustomCascadeValues[field?.title].map(item => ({
      ...item,
      required: field?.required,
    }));
  }

  if (!isFieldValid(field) && !isCompletedOrAbove) {
    return [];
  }

  // Gets an array of values for the user selected branch of
  // a custom cascading field
  const selectedValueArray = getSelectedValueArray(field);

  // If the workOrder is completed or above, the selected value
  // array is all that is needed.
  if (isCompletedOrAbove) {
    return selectedValueArray;
  }

  const singleList = [mapFieldToMappedField(field)];

  const list = isNilOrEmpty(field?.subFields)
    ? singleList
    : flattenNestedCascadingField(
        singleList,
        field?.subFields[0]?.nestedField || [],
        field?.required,
      );

  const hydratedNestedList = list.map((mappedField, index) => {
    const fieldData = selectedValueArray[index];
    // @ts-ignore
    const fieldDataValue = selectedValueArray[index]?.value;

    return {
      ...mappedField,
      value: !isNilOrEmpty(fieldData) ? fieldDataValue : null,
    };
  });

  return hydratedNestedList;
};

export const getNotesField = (task: Task): MappedField => {
  return mapFieldToMappedField(
    R.find(R.propEq('title', 'Notes'))(task?.fields || []),
  );
};

export const getMappedCustomFields = (
  task: Task,
  isCompletedOrAbove: boolean,
): MappedField[] => {
  if (!task || isNilOrEmpty(task?.fields)) {
    return [];
  }

  const mappedFields = task?.fields.reduce((acc, field) => {
    const isCascadingField =
      !isNilOrEmpty(field?.subFields) || field?.subFieldsTruncated;

    const hideNotesField = field?.title === 'Notes';

    const basicFields = hideNotesField ? [] : [mapFieldToMappedField(field)];

    const mappedField = isCascadingField
      ? flattenCascadingField(field, isCompletedOrAbove)
      : basicFields;

    return [...acc, ...mappedField];
  }, []);

  return isCompletedOrAbove
    ? mappedFields.reduce((acc, field) => {
        return isNilOrEmpty(field?.value) ? acc : [...acc, field];
      }, [])
    : mappedFields;
};

export const getSectionActualCost = (task: Task) => {
  const team = task?.users.reduce((acc, user) => {
    return acc + user?.actualCost || 0;
  }, 0);

  const material = task?.materials.reduce((acc, item) => {
    return acc + item?.cost || 0;
  }, 0);

  return {
    team,
    material,
  };
};

const EMPTY_REPORT_USER = {
  userId: 'empty',
  name: '',
  duration: 0,
  actualCost: 0,
  isTeamLead: false,
};

const MINIMUM_USERS = 3;

export const fillEmptyUsers = (users: ReportUser[]): ReportUser[] => {
  const emptyAmount = MINIMUM_USERS - users.length;

  const emptyUsers = Array.from(Array(emptyAmount), _ => []).map((_, index) => {
    return {
      ...EMPTY_REPORT_USER,
      userId: `${EMPTY_REPORT_USER.userId}${index}`,
    };
  });

  return [...users, ...emptyUsers];
};

export const getFilteredUsers = (
  users: ReportUser[] = [],
  isCompletedOrAbove: boolean,
): ReportUser[] => {
  const filledUsers =
    users.length < MINIMUM_USERS ? fillEmptyUsers(users) : users;

  return isCompletedOrAbove
    ? filledUsers.reduce((acc, user) => {
        return !user?.duration && isNilOrEmpty(user?.perDiem)
          ? acc
          : [...acc, user];
      }, [])
    : filledUsers;
};

export const EMPTY_REPORT_MATERIAL = {
  assetId: 'empty',
  name: '',
  entries: [],
};

const MINIMUM_MATERIALS = 3;

export const fillEmptyMaterials = (reportMaterials: ReportMaterial[]) => {
  const emptyAmount = MINIMUM_MATERIALS - reportMaterials.length;

  const emptyMaterials = Array.from(Array(emptyAmount), _ => []).map(
    (_, index) => {
      return {
        ...EMPTY_REPORT_MATERIAL,
        assetId: `${EMPTY_REPORT_MATERIAL.assetId}${index}`,
      };
    },
  );

  return [...reportMaterials, ...emptyMaterials];
};

export const getFilteredMaterials = (
  reportMaterials: ReportMaterial[] = [],
  isCompletedOrAbove: boolean,
): ReportMaterial[] => {
  const filledMaterials =
    reportMaterials.length < MINIMUM_MATERIALS
      ? fillEmptyMaterials(reportMaterials)
      : reportMaterials;

  return isCompletedOrAbove
    ? filledMaterials.reduce((acc, material) => {
        return isNilOrEmpty(material?.entries) ? acc : [...acc, material];
      }, [])
    : filledMaterials;
};

export const getWorkReportDictionary = (
  workReports: WorkReport[],
  workOrder: WorkOrderDetailType,
): { [key: string]: WorkReport } => {
  if (isNilOrEmpty(workReports) || isNilOrEmpty(workOrder)) {
    return {};
  }

  return workOrder?.tasks.reduce((acc, task) => {
    const taskReport = R.find(R.propEq('taskId', task?.id))(workReports);

    return {
      ...acc,
      [task?.id]: taskReport,
    };
  }, {});
};

export const EMPTY_TASK_LOCATION = {
  id: 'empty',
  workOrderId: '',
  taskId: '',
  name: '',
  geometry: null,
  data: [],
};

const MINIMUM_LOCATIONS = 6;

export const fillEmptyLocations = (reportLocations: TaskLocation[]) => {
  const emptyAmount = MINIMUM_LOCATIONS - reportLocations.length;

  const emptyLocations = Array.from(Array(emptyAmount), _ => []).map(
    (_, index) => {
      return {
        ...EMPTY_TASK_LOCATION,
        id: `${EMPTY_TASK_LOCATION.id}${index}`,
      };
    },
  );

  return [...reportLocations, ...emptyLocations];
};

export const getFilledLocations = (
  reportLocations: TaskLocation[] = [],
  isCompletedOrAbove: boolean,
): ReportLocations => {
  const filledLocations =
    reportLocations.length < MINIMUM_LOCATIONS
      ? fillEmptyLocations(reportLocations)
      : reportLocations;

  const managementUnit =
    reportLocations[0]?.data.find(
      item => item.title === ALDOTLocationDataTitle.MANAGEMENT_UNIT,
    ).value || '';

  const county =
    reportLocations[0]?.data.find(
      item => item.title === ALDOTLocationDataTitle.COUNTY,
    ).value || '';

  const roadClass =
    reportLocations[0]?.data.find(
      item => item.title === ALDOTLocationDataTitle.ROAD_CLASS,
    ).value || '';

  return {
    managementUnit,
    county,
    roadClass,
    locations: isCompletedOrAbove ? reportLocations : filledLocations,
  };
};

export const getLocationsDictionary = (
  locations: TaskLocation[] = [],
  workOrder: WorkOrderDetailType,
) => {
  if (isNilOrEmpty(workOrder)) {
    return {};
  }

  return workOrder?.tasks?.reduce((acc, task) => {
    return {
      ...acc,
      [task?.id]: R.filter(R.propEq('taskId', task?.id), locations),
    };
  }, {});
};

export const EMPTY_MULTI_ASSET = {
  id: 'empty',
};

const MINIMUM_MULTI_ASSETS = 3;

export const fillEmptyMultiAssets = (reportMultiAssets: any[]) => {
  const emptyAmount = MINIMUM_MULTI_ASSETS - reportMultiAssets.length;

  const emptyMultiAssets = Array.from(Array(emptyAmount), _ => []).map(
    (_, index) => {
      return {
        ...EMPTY_MULTI_ASSET,
        id: `${EMPTY_MULTI_ASSET.id}${index}`,
      };
    },
  );

  return [...reportMultiAssets, ...emptyMultiAssets];
};

export const getFilledMultiAssets = (
  reportMultiAssets: any[] = [],
  isCompletedOrAbove: boolean,
): ReportMultiAssets => {
  const filledMultiAssets =
    reportMultiAssets.length < MINIMUM_MULTI_ASSETS
      ? fillEmptyMultiAssets(reportMultiAssets)
      : reportMultiAssets;

  const firstAttributes =
    R.values(R.pathOr({}, [0, 'attributes'], reportMultiAssets)) || [];

  const managementUnit =
    firstAttributes.find(
      item => item.name === ALDOTLocationDataTitle.MANAGEMENT_UNIT,
    )?.value || '';

  const county =
    firstAttributes.find(item => item.name === ALDOTLocationDataTitle.COUNTY)
      ?.value || '';

  const roadClass =
    firstAttributes.find(
      item => item.name === ALDOTLocationDataTitle.ROAD_CLASS,
    )?.value || '';

  return {
    managementUnit,
    county,
    roadClass,
    multiAssets: isCompletedOrAbove ? reportMultiAssets : filledMultiAssets,
  };
};
