import React, { useContext } from 'react';

import WorkOrderDetailMap from '@atom/components/common/workOrderDetail/workOrderDetailMap/WorkOrderDetailMap';
import WorkOrderPreviewContext, {
  ExpandedInfo,
} from '@atom/components/workOrderPreview/WorkOrderPreviewContext';
import { Icon, IconButton } from '@atom/mui';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import './infoView.css';

const LocationSection = () => {
  const {
    workOrderDetail,
    refetch,
    loading,
    expandedInfo,
    setExpandedInfo,
  } = useContext(WorkOrderPreviewContext);

  const toggleSection = () => {
    setExpandedInfo(toggleFromSet(expandedInfo, ExpandedInfo.LOCATION));
  };

  const icon = expandedInfo.has(ExpandedInfo.LOCATION)
    ? 'expand_less'
    : 'expand_more';

  return (
    <>
      <div styleName="section-header">
        <div styleName="section-title">Location</div>
        <IconButton onClick={toggleSection}>
          <Icon>{icon}</Icon>
        </IconButton>
      </div>
      {expandedInfo.has(ExpandedInfo.LOCATION) && (
        <div styleName="section-content location">
          <WorkOrderDetailMap
            workOrderDetail={workOrderDetail}
            refetch={refetch}
            loading={loading}
            initialOpenEditList={false}
          />
        </div>
      )}
    </>
  );
};

export default LocationSection;
