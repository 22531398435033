import React from 'react';
import * as R from 'ramda';

import { getInstanceInputValidation } from '@atom/selectors/formSelectors';
import { EventType } from '@atom/types/event';
import {
  CiModuleInspectionUpdate,
  FormAssetErrors,
  FormInstanceFieldItem,
  FormModuleKeys,
  PciModuleDataUpdate,
} from '@atom/types/form';
import {
  InventoryAssetDetailType,
  InventorySchemaItem,
} from '@atom/types/inventory';
import { MediaItem } from '@atom/types/media';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { isNumericZero } from '@atom/utilities/validationUtilities';

import InstanceAssetContainer from './assetField/InstanceAssetContainer';
import CiField from './formInstanceModules/ci/CiField';
import FhwaField from './formInstanceModules/FhwaField';
import PciField from './formInstanceModules/pci/PciField';
import PciSummary from './formInstanceModules/pci/PciSummary';
import CurrencyField from './regularFields/CurrencyField';
import DatePickerField from './regularFields/DatePickerField';
import DescriptionField from './regularFields/DescriptionField';
import EnumMultipleField from './regularFields/EnumMultipleField';
import EnumSingleField from './regularFields/EnumSingleField';
import IFrameField from './regularFields/IFrameField';
import LongTextField from './regularFields/LongTextField';
import NumericField from './regularFields/NumericField';
import RadioField from './regularFields/RadioField';
import ShortTextField from './regularFields/ShortTextField';
import TitleField from './regularFields/TitleField';

import './formInstance.css';

interface PassedProps {
  field: FormInstanceFieldItem;
  pageName: string;
  isEditable: boolean;
  preview: boolean;
  rootSchema: InventorySchemaItem;
  id: string;
  media: MediaItem[];
  inventoryAssetId: string;
  inventoryAsset?: InventoryAssetDetailType;
  formInstanceId: any;
  uploadFiles: (
    files?: any[],
    subjectId?: string,
    subjectType?: string,
    inspectionUpdate?: CiModuleInspectionUpdate,
  ) => void;
  removeFile: (mediaId: string, subjectId: string) => void;
  progressiveUpdateFormInstanceField: (fieldId: string, body: Object) => void;
  progressiveUpdateFormInstanceAttribute: (
    fieldId: string,
    assetId: string,
    attributeGroupName: string,
    attributeId: string,
    body: Object,
  ) => void;
  progressiveUpdateFormInstanceFhwa: (
    fieldId: string,
    assetId: string,
    body: Object,
    elementPath: any[],
  ) => void;
  progressiveUpdateFormInstanceCi: (update: CiModuleInspectionUpdate) => void;
  progressiveUpdateFormInstancePci: (update: PciModuleDataUpdate) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  savingFormInstance: boolean;
  assetErrors?: FormAssetErrors;
}

type Props = PassedProps;

interface State {
  value: any;
}

class FieldBlock extends React.Component<Props, State> {
  state = {
    value:
      isNumericZero(this.props.field.type, this.props.field.value) ||
      this.props.field.value
        ? this.props.field.value
        : '',
  };

  onChange = (event: EventType) => {
    const { field } = this.props;
    let value = event.target.value;

    const isValidated = getInstanceInputValidation(field.type, value);

    if (isValidated) {
      if (field.type === 'number' || field.type === 'currency') {
        value = Number(event.target.value);
      }

      this.progressiveSaveField(value);
      this.setState({ value });
    }
  };

  onDateChange = (dateMillis: number) => {
    const value = R.isNil(dateMillis) ? '' : dateMillis;
    this.progressiveSaveField(value);
    this.setState({ value });
  };

  onSingleSelectChange = (event: any) => {
    const { value } = event.target;

    this.progressiveSaveField(value);
    this.setState({ value });
  };

  onMultiSelectChange = (event: any) => {
    const { value } = event.target;
    this.progressiveSaveField(value);
    this.setState({ value });
  };

  onRadioChange = (value: string) => {
    this.progressiveSaveField(value);
    this.setState({ value });
  };

  progressiveSaveField = (value: any) => {
    const { id, progressiveUpdateFormInstanceField } = this.props;
    const body = {
      value,
    };

    progressiveUpdateFormInstanceField(id, body);
  };

  fieldFileUpload = (event: EventType) => {
    const { uploadFiles, id } = this.props;

    const files = event.target.files;
    const subjectType = 'formInstanceField';

    uploadFiles(files, id, subjectType);
  };

  render() {
    const {
      field,
      pageName,
      removeFile,
      id,
      rootSchema,
      progressiveUpdateFormInstanceAttribute,
      media,
      uploadFiles,
      progressiveUpdateFormInstanceFhwa,
      progressiveUpdateFormInstanceCi,
      progressiveUpdateFormInstancePci,
      inventoryAssetId,
      inventoryAsset,
      isEditable,
      preview,
      formInstanceId,
      renameMedia,
      savingFormInstance,
      assetErrors,
    } = this.props;
    const { value } = this.state;

    const isInputEditable =
      hasRolePermissions(ROLE_SETS.INSPECTOR) && (isEditable || preview);
    const isInputEditableWhileSaving =
      hasRolePermissions(ROLE_SETS.INSPECTOR) &&
      (isEditable || preview) &&
      !savingFormInstance;
    const isUploadDisabled =
      !hasRolePermissions(ROLE_SETS.INSPECTOR) || preview || savingFormInstance;

    switch (field.type) {
      case 'title': {
        return <TitleField field={field} />;
      }
      case 'description': {
        return <DescriptionField field={field} />;
      }
      case 'shorttext': {
        return (
          <ShortTextField
            fieldFileUpload={this.fieldFileUpload}
            field={field}
            media={media}
            fieldId={id}
            onChange={this.onChange}
            value={value}
            removeFile={removeFile}
            isEditable={isInputEditable}
            isUploadDisabled={isUploadDisabled}
            renameMedia={renameMedia}
          />
        );
      }
      case 'longtext': {
        return (
          <LongTextField
            fieldFileUpload={this.fieldFileUpload}
            field={field}
            media={media}
            fieldId={id}
            onChange={this.onChange}
            value={value}
            removeFile={removeFile}
            isEditable={isInputEditable}
            isUploadDisabled={isUploadDisabled}
            renameMedia={renameMedia}
          />
        );
      }
      case 'number': {
        return (
          <NumericField
            fieldFileUpload={this.fieldFileUpload}
            field={field}
            media={media}
            fieldId={id}
            onChange={this.onChange}
            value={value}
            removeFile={removeFile}
            isEditable={isInputEditable}
            isUploadDisabled={isUploadDisabled}
            renameMedia={renameMedia}
          />
        );
      }
      case 'enumsingle': {
        return (
          <EnumSingleField
            fieldFileUpload={this.fieldFileUpload}
            field={field}
            media={media}
            fieldId={id}
            onChange={this.onSingleSelectChange}
            value={value}
            removeFile={removeFile}
            isEditable={isInputEditableWhileSaving}
            isUploadDisabled={isUploadDisabled}
            renameMedia={renameMedia}
          />
        );
      }
      case 'enummultiple': {
        return (
          <EnumMultipleField
            fieldFileUpload={this.fieldFileUpload}
            field={field}
            media={media}
            fieldId={id}
            onChange={this.onMultiSelectChange}
            value={value}
            removeFile={removeFile}
            isEditable={isInputEditableWhileSaving}
            isUploadDisabled={isUploadDisabled}
            renameMedia={renameMedia}
          />
        );
      }
      case 'radio': {
        return (
          <RadioField
            fieldFileUpload={this.fieldFileUpload}
            field={field}
            media={media}
            fieldId={id}
            onChange={this.onRadioChange}
            value={value}
            removeFile={removeFile}
            isEditable={isInputEditableWhileSaving}
            isUploadDisabled={isUploadDisabled}
            renameMedia={renameMedia}
          />
        );
      }
      case 'currency': {
        return (
          <CurrencyField
            fieldFileUpload={this.fieldFileUpload}
            field={field}
            media={media}
            fieldId={id}
            onChange={this.onChange}
            value={value}
            removeFile={removeFile}
            isEditable={isInputEditable}
            isUploadDisabled={isUploadDisabled}
            renameMedia={renameMedia}
          />
        );
      }
      case 'date': {
        return (
          <DatePickerField
            fieldFileUpload={this.fieldFileUpload}
            field={field}
            media={media}
            fieldId={id}
            onChange={this.onDateChange}
            value={value}
            removeFile={removeFile}
            isEditable={isInputEditableWhileSaving}
            isUploadDisabled={isUploadDisabled}
            renameMedia={renameMedia}
          />
        );
      }
      case 'asset': {
        return (
          <InstanceAssetContainer
            removeFile={removeFile}
            uploadFiles={uploadFiles}
            isUploadDisabled={isUploadDisabled}
            field={field}
            fieldId={id}
            media={media}
            preview={preview}
            isEditable={isInputEditable}
            rootSchema={rootSchema}
            progressiveUpdateFormInstanceAttribute={
              progressiveUpdateFormInstanceAttribute
            }
            renameMedia={renameMedia}
            savingFormInstance={savingFormInstance}
            assetErrors={assetErrors}
          />
        );
      }
      case FormModuleKeys.FHWA: {
        return (
          <FhwaField
            removeFile={removeFile}
            renameMedia={renameMedia}
            rootSchema={rootSchema}
            formInstanceId={formInstanceId}
            preview={preview}
            uploadFiles={uploadFiles}
            inventoryAssetId={inventoryAssetId}
            field={field}
            fieldId={id}
            media={media}
            isEditable={isInputEditable}
            isUploadDisabled={isUploadDisabled}
            progressiveUpdateFormInstanceFhwa={
              progressiveUpdateFormInstanceFhwa
            }
          />
        );
      }
      case FormModuleKeys.CI: {
        return (
          <CiField
            field={field}
            fieldId={id}
            assetId={inventoryAssetId}
            media={media}
            preview={preview}
            onChange={progressiveUpdateFormInstanceCi}
            uploadFiles={uploadFiles}
            removeFile={removeFile}
            renameMedia={renameMedia}
            isEditable={isInputEditable}
          />
        );
      }
      case FormModuleKeys.PCI: {
        return (
          <PciField
            formInstanceId={formInstanceId}
            fieldId={id}
            field={field}
            pageName={pageName}
            inventoryAsset={inventoryAsset}
            inventoryAssetId={inventoryAssetId}
            media={media}
            onChange={progressiveUpdateFormInstancePci}
            preview={preview}
            removeFile={removeFile}
            renameMedia={renameMedia}
            isEditable={isInputEditable}
          />
        );
      }
      case FormModuleKeys.PCI_SUMMARY: {
        return (
          <PciSummary
            fieldId={id}
            field={field}
            inventoryAsset={inventoryAsset}
            inventoryAssetId={inventoryAssetId}
          />
        );
      }
      case 'iframe': {
        return <IFrameField field={field} />;
      }
      default: {
        return <div />;
      }
    }
  }
}

export default FieldBlock;
