import React, { useContext, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';

import PriorityControl from '@atom/components/common/PriorityControl';
// @ts-ignore
import createWorkTypeIconWhite from '@atom/components/common/svgIcons/createWorkTypeIconWhite.svg';
import NewWorkOrderIcon from '@atom/components/common/svgIcons/NewWorkOrderIcon';
import WorkTemplateContext, {
  getTabData,
  WorkTemplateActionTypes,
} from '@atom/components/workTemplate/WorkTemplateContext';
import WorkTemplateWalkThrough from '@atom/components/workTemplate/workTemplateWalkThrough/WorkTemplateWalkThrough';
import {
  WORK_TEMPLATE_DELETE,
  WORK_TEMPLATE_DUPLICATE,
  WORK_TEMPLATE_UPDATE,
} from '@atom/graph/workTemplate';
import {
  Button,
  Icon,
  IconButton,
  Menu,
  Modal,
  Progress,
  Snackbar,
} from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  WorkOrderTemplateUpdateInput,
  WorkTemplate,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  hasRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import history from '@atom/utilities/history';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import { WorkTypeVariant } from '@atom/utilities/workTemplateUtilities';
import { getWorkTypeIconSvg } from '@atom/utilities/workTypeIconUtilities';

import WorkTemplateChildCreate from './workTemplateChildCreate/WorkTemplateChildCreate';
import WorkTemplateChildSettings from './workTemplateChildSettings/WorkTemplateChildSettings';
import WorkTemplateSettings from './workTemplateSettings/WorkTemplateSettings';
import WorkTemplateStatus from './workTemplateStatus/WorkTemplateStatus';
import WorkTypeEditModal from './workTypeEditModal/WorkTypeEditModal';
import { getModalValue, ModalVariant } from './WorkTemplateDeleteModalValues';
import WorkTemplateHeaderButton from './WorkTemplateHeaderButton';
import WorkTemplateHeaderInfo from './WorkTemplateHeaderInfo';

import './workTemplateHeader.css';

const { MenuItem } = Menu;

const HTTP_STATUS_CONFLICT = 409;

const styles = {
  name: {
    width: '100%',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  publishButton: {
    backgroundColor: colors.neutral.white,
    color: colors.brand.blue,
    marginLeft: '1rem',
  },
  delete: {
    background: colors.brand.red,
    color: colors.neutral.white,
  },
};

const WorkTemplateHeader = () => {
  const container = useRef<HTMLDivElement>();

  const { workTemplate, workTypeVariant, dispatch, loading } = useContext(
    WorkTemplateContext,
  );

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [deleteModalVariant, setDeleteModalVariant] = useState<ModalVariant>();
  const [walkThroughModalOpen, setWalkThroughModalOpen] = useState<boolean>(
    false,
  );
  const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);
  const [childCreateModalOpen, setChildCreateModalOpen] = useState<boolean>(
    false,
  );
  const [childWorkTypeSettingsOpen, setChildWorkTypeSettingsOpen] = useState<
    boolean
  >(false);

  const [workTemplateUpdate] = useMutation<
    { workOrderTemplateUpdate: WorkTemplate },
    { input: WorkOrderTemplateUpdateInput }
  >(WORK_TEMPLATE_UPDATE);

  const [workTemplateDuplicate] = useMutation<
    { workOrderTemplateDuplicate: { id: string; name: string } },
    { id: string }
  >(WORK_TEMPLATE_DUPLICATE);

  const [workTemplateDelete] = useMutation<{}, { id: string }>(
    WORK_TEMPLATE_DELETE,
  );

  const updateWorkTemplate = async (
    property: keyof WorkTemplate,
    value: any,
  ) => {
    const { data } = await workTemplateUpdate({
      variables: {
        input: {
          workOrderTemplateId: workTemplate.id,
          [property]: value,
        },
      },
    });

    dispatch({
      type: WorkTemplateActionTypes.UPDATE_WORK_TEMPLATE_PROPERTY,
      data: {
        property,
        value: data?.workOrderTemplateUpdate[property],
      },
    });
  };

  const duplicateWorkTemplate = async () => {
    try {
      const res = await workTemplateDuplicate({
        variables: {
          id: workTemplate.id,
        },
      });

      Snackbar.info({
        message: `Created work template "${res?.data?.workOrderTemplateDuplicate?.name}"`,
        action: 'View',
        onActionClick: () =>
          history.push(
            `/work-templates/${res?.data?.workOrderTemplateDuplicate?.id}`,
          ),
      });
    } catch (err) {
      Snackbar.error({ message: 'An unknown error occurred' });
    }
  };

  const getSnackbarText = (variant: WorkTypeVariant) => {
    const texts = {
      [WorkTypeVariant.REGULAR]: 'Work template deleted',
      [WorkTypeVariant.PARENT]: 'Parent work template deleted',
      [WorkTypeVariant.CHILD]: 'Child work template deleted',
    };

    return texts[variant];
  };

  const deleteWorkTemplate = async () => {
    try {
      await workTemplateDelete({
        variables: {
          id: workTemplate.id,
        },
      });

      setDeleteModalVariant(null);

      history.push('/work-templates');

      Snackbar.info({
        message: getSnackbarText(workTypeVariant),
      });
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_STATUS_CONFLICT) {
        setDeleteModalVariant(ModalVariant.DELETE_ERROR);
      } else {
        Snackbar.error({ message: 'An unknown error occurred' });
      }
    }
  };

  const handlePriorityUpdate = (params: any) => {
    updateWorkTemplate('priorityId', params.priorityId);
  };

  const workNameWidth = `${container.current?.offsetWidth - 400}px`;

  const disableEditWhenPublished =
    workTemplate?.published || doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);
  const disableEdit = doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  const showOption = hasRolePermissions(ROLE_SETS.ORG_ADMIN);
  const showChildWorkTypeMenu = workTypeVariant === WorkTypeVariant.PARENT;
  const showChildWorkTypeSettings = workTypeVariant === WorkTypeVariant.CHILD;

  const deleteModalValue = getModalValue(workTypeVariant, deleteModalVariant);

  const getFooter = () => {
    return deleteModalVariant === ModalVariant.DELETE_ERROR ? (
      <Button
        color="primary"
        variant="contained"
        onClick={() => setDeleteModalVariant(null)}
      >
        {deleteModalValue?.button}
      </Button>
    ) : (
      <div>
        <Button
          onClick={() => setDeleteModalVariant(null)}
          style={{ marginRight: '0.5rem' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={deleteWorkTemplate}
          style={styles.delete}
        >
          {deleteModalValue?.button}
        </Button>
      </div>
    );
  };

  const tabData = getTabData(workTypeVariant);

  const getTooltipText = (variant: WorkTypeVariant) => {
    const texts = {
      [WorkTypeVariant.REGULAR]: 'Delete Work Template',
      [WorkTypeVariant.PARENT]: 'Delete Parent Work Template',
      [WorkTypeVariant.CHILD]: 'Delete Child Work Template',
    };

    return texts[variant];
  };

  return (
    <>
      <div ref={container} styleName="header-container">
        <div styleName="title-content">
          <IconButton tooltip="Go back" onClick={history.goBack}>
            <Icon color={colors.neutral.white}>arrow_back</Icon>
          </IconButton>
          <img
            src={getWorkTypeIconSvg(
              workTemplate?.isParent,
              workTemplate?.parentId,
              false,
              true,
            )}
          />
          <div style={{ width: workNameWidth }} styleName="title-bar">
            <span style={styles.name}>{workTemplate.name}</span>
          </div>
        </div>
        <div styleName="icon-bar">
          {loading && (
            <div styleName="loading-container">
              <Progress
                size={20}
                thickness={2}
                progressColor={colors.neutral.white}
              />
            </div>
          )}
          <PriorityControl
            priorityId={workTemplate?.priorityId}
            updateWorkOrder={handlePriorityUpdate}
            disabled={disableEditWhenPublished}
          />
          <IconButton
            tooltip="Edit Work Template"
            edge="end"
            onClick={() => setEditModalOpen(true)}
            disabled={disableEditWhenPublished}
          >
            <Icon color={colors.neutral.white}>edit</Icon>
          </IconButton>
          <IconButton
            tooltip="Duplicate Work Template"
            edge="end"
            onClick={duplicateWorkTemplate}
            disabled={disableEdit}
          >
            <Icon color={colors.neutral.white}>content_copy</Icon>
          </IconButton>
          {showChildWorkTypeMenu && (
            <Menu
              icon={<img src={createWorkTypeIconWhite} />}
              IconButtonProps={{ edge: 'end' }}
            >
              {showOption && (
                <MenuItem
                  key="createChildWorkType"
                  onClick={() => setChildCreateModalOpen(true)}
                  startAdornment={
                    <NewWorkOrderIcon color={colors.neutral.gray} />
                  }
                >
                  Create Child Work Template
                </MenuItem>
              )}
              {showOption && (
                <MenuItem
                  key="childWorkTypeSettings"
                  onClick={() => setSettingsModalOpen(true)}
                  startAdornment={<Icon>settings</Icon>}
                >
                  Child Work Template Settings
                </MenuItem>
              )}
              <MenuItem
                key="help"
                onClick={() => setWalkThroughModalOpen(true)}
                startAdornment={<Icon>help</Icon>}
              >
                Help
              </MenuItem>
            </Menu>
          )}
          {showChildWorkTypeSettings && (
            <IconButton
              tooltip="Child Work Template Settings"
              edge="end"
              onClick={() => setChildWorkTypeSettingsOpen(true)}
            >
              <Icon color={colors.neutral.white}>settings</Icon>
            </IconButton>
          )}
          <IconButton
            tooltip={getTooltipText(workTypeVariant)}
            edge="end"
            onClick={() => setDeleteModalVariant(ModalVariant.DELETE_CONFIRM)}
            disabled={disableEdit}
          >
            <Icon color={colors.neutral.white}>delete</Icon>
          </IconButton>
          <WorkTemplateStatus disabled={disableEdit} />
        </div>
        <WorkTemplateHeaderInfo />
        <div styleName="icon-row">
          {tabData.map(view => {
            return <WorkTemplateHeaderButton key={view.value} view={view} />;
          })}
        </div>
      </div>
      <WorkTypeEditModal
        open={editModalOpen}
        closeModal={() => setEditModalOpen(false)}
      />
      <Modal
        title={deleteModalValue?.title}
        open={!isNilOrEmpty(deleteModalVariant)}
        onCancel={() => setDeleteModalVariant(null)}
        footer={getFooter()}
      >
        {deleteModalValue?.content}
      </Modal>
      <WorkTemplateWalkThrough
        open={walkThroughModalOpen}
        onClose={() => setWalkThroughModalOpen(false)}
      />
      <WorkTemplateSettings
        open={settingsModalOpen}
        onClose={() => setSettingsModalOpen(false)}
      />
      <WorkTemplateChildCreate
        open={childCreateModalOpen}
        onClose={() => setChildCreateModalOpen(false)}
      />
      <WorkTemplateChildSettings
        open={childWorkTypeSettingsOpen}
        onClose={() => setChildWorkTypeSettingsOpen(false)}
      />
    </>
  );
};

export default WorkTemplateHeader;
