import React from 'react';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import LayerControls from '@atom/components/common/map/mapTools/layerControls/LayerControls';
import MapSearchBox from '@atom/components/common/map/mapTools/mapSearchBox/MapSearchBox';
import StyleControls from '@atom/components/common/map/mapTools/styleControls/StyleControls';
// @ts-ignore
import zoomInMapIcon from '@atom/components/common/svgIcons/zoomInMapIcon.svg';
import { Progress, Tooltip } from '@atom/mui';
import {
  LocationLinkOption,
  LocationUpdatePayload,
  MapEditMetadata,
  MapParams,
} from '@atom/types/map';
import history from '@atom/utilities/history';
import {
  getGeoJSONFromGoogleMapShape,
  getGoogleShapeCenter,
} from '@atom/utilities/mapUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import {
  useGoogleMapsStateUpdater,
  useGoogleMapsStateValue,
} from '../hooks/googleMapsStateHook';

import DrawingControls from './drawingControls/DrawingControls';
import DrawingData from './drawingControls/DrawingData';
import LocationLinkControls from './locationLinkControls/LocationLinkControls';

import './mapTools.css';

type Props = {
  loading: boolean;
  mapStyleControl?: boolean;
  mapSearchBox?: boolean;
  layerControls?: boolean;
  drawingControls?: boolean;
  editControls?: boolean;
  mapEditMetadata?: MapEditMetadata[];
  editItem?: MapEditMetadata;
  setEditItem?: (editItem: MapEditMetadata) => void;
  onSave?: (payload: LocationUpdatePayload) => void;
  locationEditZoom?: number;
  locationLinkControls?: boolean;
  locationLinkOptions?: LocationLinkOption[];
  locationLinkControlsDisabled?: boolean;
  initialOpenEditList?: boolean;
  mapParams?: MapParams;
  updateMapParams?: (mapParams: MapParams) => void;
  onCenterMapClick?: () => void;
};

const MapTools = ({
  loading,
  mapStyleControl,
  mapSearchBox,
  layerControls,
  drawingControls,
  editControls,
  mapEditMetadata,
  editItem,
  setEditItem,
  onSave,
  locationEditZoom,
  locationLinkControls,
  locationLinkOptions,
  locationLinkControlsDisabled,
  initialOpenEditList,
  mapParams,
  updateMapParams,
  onCenterMapClick,
}: Props) => {
  const [queryParams, setQueryParams] = useQueryParams({
    center: StringParam,
    zoom: NumberParam,
    searchPoint: StringParam,
    searchTerm: StringParam,
  });

  const {
    shape,
    mapStyle,
    drawingMode,
    grab,
    mapTypeId,
    traffic,
    poi,
  } = useGoogleMapsStateValue();
  const dispatch = useGoogleMapsStateUpdater();

  const updateParams = (params: any) => {
    if (!isNilOrEmpty(updateMapParams)) {
      updateMapParams(params);
    } else {
      setQueryParams(params);
    }
  };

  const onSelect = ({ lat, lng }, searchTerm) => {
    updateParams({
      center: `${lat},${lng}`,
      zoom: 15,
      searchPoint: `${lat},${lng}`,
      searchTerm,
    });
  };

  const onCenter = ({ lat, lng }, zoom) => {
    updateParams({
      center: `${lat},${lng}`,
      zoom,
    });

    if (isNilOrEmpty(updateMapParams)) {
      history.handleHistoryChange(location, 'POP');
    }
  };

  const onClearSearch = () => {
    updateParams({
      searchPoint: undefined,
      searchTerm: undefined,
    });
  };

  const onChange = key => data => {
    dispatch({ type: 'SET', payload: { key, data } });
  };

  const onToggle = payload => () => {
    dispatch({ type: 'TOGGLE', payload });
  };

  const clearDrawing = () => {
    dispatch({ type: 'SET', payload: { key: 'shape', data: null } });

    if (!isNilOrEmpty(editItem)) {
      setEditItem(null);
      dispatch({ type: 'SET', payload: { key: 'drawingMode', data: null } });
    }
  };

  const onEditSave = () => {
    const location = getGeoJSONFromGoogleMapShape(shape);
    const center = getGoogleShapeCenter(location);

    updateParams({
      center: `${center?.lat},${center?.lng}`,
    });

    onSave({ location, type: editItem?.type });

    if (isNilOrEmpty(updateMapParams)) {
      history.handleHistoryChange(location, 'POP');
    }

    clearDrawing();
  };

  const searchTerm = isNilOrEmpty(updateMapParams)
    ? queryParams.searchTerm
    : mapParams.searchTerm;

  return (
    <>
      <div styleName="map-tools-wrapper">
        {loading && (
          <div styleName="spinner-container">
            <Progress size={20} thickness={2} />
          </div>
        )}
        {(mapSearchBox || editControls) && (
          <MapSearchBox
            hintText="Search Maps"
            searchTerm={searchTerm}
            onLocationChange={onSelect}
            onClearSearch={onClearSearch}
            editControls={editControls}
            mapEditMetadata={mapEditMetadata}
            onCenter={onCenter}
            onChange={onChange('drawingMode')}
            editItem={editItem}
            setEditItem={setEditItem}
            clearDrawing={clearDrawing}
            onEditSave={onEditSave}
            locationEditZoom={locationEditZoom}
            initialOpenEditList={initialOpenEditList}
          />
        )}
        {locationLinkControls && (
          <LocationLinkControls
            locationLinkOptions={locationLinkOptions}
            disabled={locationLinkControlsDisabled}
          />
        )}
        {mapStyleControl && (
          <StyleControls onChange={onChange('mapStyle')} mapStyle={mapStyle} />
        )}
        {drawingControls && (
          <DrawingControls
            drawingMode={drawingMode}
            grab={grab}
            onChange={onChange('drawingMode')}
            onCursorChange={onChange('grab')}
            clearDrawing={clearDrawing}
          />
        )}
        {layerControls && (
          <LayerControls
            setMapTypeId={onChange('mapTypeId')}
            mapTypeId={mapTypeId}
            togglePointsOfInterest={onToggle('poi')}
            toggleTraffic={onToggle('traffic')}
            traffic={traffic}
            pointsOfInterest={poi}
          />
        )}
        <DrawingData editItem={editItem} />
      </div>
      {onCenterMapClick && (
        <div styleName="center-button-container">
          <Tooltip title="Center" placement="bottom-start">
            <div styleName="icon-container" onClick={onCenterMapClick}>
              <img src={zoomInMapIcon} />
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default MapTools;
