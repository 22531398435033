import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';

import * as actionCreators from '@atom/actions/formTemplateActions';
import * as inventorySchemaActionCreators from '@atom/actions/inventorySchemaActions';
import { DragDropContext } from '@atom/components/common/dragAndDrop';
import {
  addAllFormTemplateAttributes,
  addFormTemplateAssetField,
  addFormTemplateField,
  addFormTemplateModule,
  addFormTemplatePage,
  deleteFormTemplateField,
  deleteFormTemplatePage,
  duplicateFormTemplateField,
  duplicateFormTemplatePage,
  formTemplateSelector,
  publishFormTemplate,
  removeFormTemplateAssetSchema,
  updateCiFormModule,
  updateFormTemplateAttribute,
  updateFormTemplateAttributeGroup,
  updateFormTemplateFieldOrder,
  updateFormTemplateFields,
  updateFormTemplatePageName,
  updateFormTemplatePageOrder,
} from '@atom/selectors/formSelectors';
import { getLocationBasedSchemas } from '@atom/selectors/inventorySchemasSelectors';
import {
  FormTemplateActions,
  InventorySchemaActions,
} from '@atom/types/actions';
import {
  AttributeGroupItem,
  FormFieldType,
  FormTemplateType,
} from '@atom/types/form';
import {
  AttributesType,
  InventorySchemaItem,
  InventorySchemaTreeType,
} from '@atom/types/inventory';
import {
  FormTemplateDetailState,
  InventorySchemasState,
  ReduxStore,
} from '@atom/types/store';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import history from '@atom/utilities/history';

import FormBuilderCanvas from './FormBuilderCanvas';
import FormBuilderContext from './FormBuilderContext';
import FormBuilderHeader from './FormBuilderHeader';
import FormPages from './FormPages';
import ToolbarBase from './ToolbarBase';

import './formBuilder.css';

interface DragDrop {
  draggableId?: string;
  source?: any;
  destination?: any;
}

interface ReduxStateProps {
  formTemplate: FormTemplateDetailState;
  loadingFormTemplate: boolean;
  inventorySchemas: InventorySchemasState;
  inventorySchemaTree: InventorySchemaTreeType;
  loadingInventorySchemaTree: boolean;
}

interface ReduxDispatchProps {
  formTemplateActions: FormTemplateActions;
  inventorySchemaActions: InventorySchemaActions;
}

interface PassedProps {
  match: any;
}

type Props = ReduxStateProps & ReduxDispatchProps & PassedProps;

interface State {
  isDrawerOpen: boolean;
  selectedPage: number;
  formTemplate: FormTemplateType;
  pageInEditMode: any;
  selectedSchema?: string;
  pageName: string;
}

const initialFormTemplate = {
  id: '',
  name: '',
  createdDate: 0,
  createdBy: '',
  pages: [],
};

class FormBuilder extends React.Component<Props, State> {
  state: State = {
    isDrawerOpen: true,
    selectedPage: 0,
    pageInEditMode: null,
    pageName: '',
    selectedSchema: '',
    formTemplate: initialFormTemplate,
  };

  componentDidMount() {
    const {
      formTemplate,
      formTemplateActions,
      match,
      inventorySchemas,
      inventorySchemaActions,
    } = this.props;

    const { id } = match.params;

    if (doesNotHaveRolePermissions(ROLE_SETS.MANAGER)) {
      history.push(`/form-builder/${match.params.id}/preview`);
    }

    if (R.isNil(inventorySchemas) || R.isEmpty(inventorySchemas)) {
      inventorySchemaActions.retrieveInventorySchemas({
        rootSchemas: true,
      });
    }

    if (formTemplate?.id === id) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ formTemplate });
    }

    formTemplateActions.getFormTemplate(id);
  }

  componentDidUpdate(prevProps: Props) {
    const { formTemplate, match } = this.props;
    const { id } = match.params;

    if (id === formTemplate?.id && prevProps.formTemplate !== formTemplate) {
      if (formTemplate.isPublished) {
        history.push(`/form-builder/${formTemplate.id}/preview`);
      } else {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          formTemplate,
          selectedSchema: formTemplate.schemaId,
        });
      }
    }
  }

  toggleDrawer = () => {
    const { isDrawerOpen } = this.state;
    this.setState({ isDrawerOpen: !isDrawerOpen });
  };

  updateSelectedPage = (index: number) => {
    this.setState({ selectedPage: index, pageInEditMode: null });
  };

  updatePageInEditMode = (index: any) => {
    const { formTemplate } = this.state;
    if (R.isNil(index)) {
      this.setState({ pageInEditMode: index, pageName: '' });
      return;
    }

    const pageName = formTemplate.pages[index].name;
    this.setState({ pageInEditMode: index, selectedPage: index, pageName });
  };

  addField = (type: string) => {
    const { formTemplate, selectedPage } = this.state;
    const updatedFormTemplate = addFormTemplateField(
      type,
      selectedPage,
      formTemplate,
    );

    this.setState({ formTemplate: updatedFormTemplate });
    this.saveFormTemplate(updatedFormTemplate);
  };

  addAssetField = (assetField: FormFieldType, schemaId: string) => {
    const { formTemplate, selectedPage } = this.state;
    const updatedFormTemplate = addFormTemplateAssetField(
      assetField,
      selectedPage,
      formTemplate,
      schemaId,
    );

    this.setState({
      formTemplate: updatedFormTemplate,
      selectedSchema: schemaId,
    });
    this.saveFormTemplate(updatedFormTemplate);
  };

  deleteField = (id: string) => {
    const { formTemplate, selectedPage } = this.state;
    const updatedFormTemplate = deleteFormTemplateField(
      id,
      selectedPage,
      formTemplate,
    );

    this.setState({ formTemplate: updatedFormTemplate });
    this.saveFormTemplate(updatedFormTemplate);
  };

  duplicateField = (id: string) => {
    const { formTemplate, selectedPage } = this.state;
    const updatedFormTemplate = duplicateFormTemplateField(
      id,
      selectedPage,
      formTemplate,
    );

    this.setState({ formTemplate: updatedFormTemplate });
    this.saveFormTemplate(updatedFormTemplate);
  };

  addPage = () => {
    const { formTemplate } = this.state;
    const selectedPage = R.length(formTemplate.pages);
    const updatedFormTemplate = addFormTemplatePage(formTemplate);
    const pageName = updatedFormTemplate.pages[selectedPage].name;

    this.setState({
      formTemplate: updatedFormTemplate,
      selectedPage,
      pageInEditMode: selectedPage,
      pageName,
    });

    this.saveFormTemplate(updatedFormTemplate);
  };

  deletePage = (index: number): boolean => {
    const { formTemplate } = this.state;
    const updatedFormTemplate = deleteFormTemplatePage(index, formTemplate);

    this.setState({
      formTemplate: updatedFormTemplate,
      selectedPage: 0,
    });

    this.saveFormTemplate(updatedFormTemplate);
    return true;
  };

  duplicatePage = (index: number) => {
    const { formTemplate } = this.state;
    const updatedFormTemplate = duplicateFormTemplatePage(index, formTemplate);
    const selectedPage = index + 1;
    const pageName = updatedFormTemplate.pages[selectedPage].name;

    this.setState({
      formTemplate: updatedFormTemplate,
      selectedPage,
      pageInEditMode: selectedPage,
      pageName,
    });

    this.saveFormTemplate(updatedFormTemplate);
  };

  updatePageName = (name: string) => {
    const { pageInEditMode, formTemplate } = this.state;
    const { formTemplateActions } = this.props;

    const updatedFormTemplate = updateFormTemplatePageName(
      name,
      pageInEditMode,
      formTemplate,
    );

    this.setState({ formTemplate: updatedFormTemplate, pageName: name });
    formTemplateActions.updateFormTemplateText(updatedFormTemplate);
  };

  updateFormTemplate = (id: string, data: Object) => {
    const { selectedPage, formTemplate } = this.state;
    const { formTemplateActions } = this.props;
    const updatedFormTemplate = updateFormTemplateFields(
      id,
      selectedPage,
      data,
      formTemplate,
    );

    this.setState({ formTemplate: updatedFormTemplate });
    formTemplateActions.updateFormTemplateText(updatedFormTemplate);
  };

  updateFieldOrder = (id: string, toIndex: number, fromIndex: number) => {
    const { selectedPage, formTemplate } = this.state;
    const updatedFormTemplate = updateFormTemplateFieldOrder(
      id,
      toIndex,
      fromIndex,
      selectedPage,
      formTemplate,
    );

    this.setState({ formTemplate: updatedFormTemplate });
    this.saveFormTemplate(updatedFormTemplate);
  };

  updatePageOrder = (toIndex: number, fromIndex: number) => {
    const { formTemplate } = this.state;
    const updatedFormTemplate = updateFormTemplatePageOrder(
      toIndex,
      fromIndex,
      formTemplate,
    );

    this.setState({ formTemplate: updatedFormTemplate });
    this.saveFormTemplate(updatedFormTemplate);
  };

  onDragEndFields = (result: DragDrop) => {
    const { draggableId, source, destination } = result;

    if (!destination) {
      return;
    }

    this.updateFieldOrder(draggableId, destination.index, source.index);
  };

  onDragEndPages = (result: DragDrop) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    this.updatePageOrder(destination.index, source.index);
    this.updateSelectedPage(destination.index);
  };

  saveFormTemplate = (formTemplate: FormTemplateDetailState) => {
    const { formTemplateActions } = this.props;
    formTemplateActions.updateFormTemplate(formTemplate);
  };

  onToggle = (path: any[], expanded: boolean) => {
    const { inventorySchemaActions } = this.props;

    // Double ! is used to transform undefined into a false boolean.
    // Checking against undefined here removes the need to spread in
    // the expanded key value to every schemaTree in the reducer.
    inventorySchemaActions.requestSchemaTreeUpdate({
      path,
      expanded: !!expanded,
    });
  };

  updateAttributeGroup = (
    fieldId: string,
    schemaId: string,
    addGroup: boolean,
    attributeGroup: AttributeGroupItem,
  ) => {
    const { selectedPage, formTemplate } = this.state;

    const updatedFormTemplate = updateFormTemplateAttributeGroup(
      fieldId,
      schemaId,
      addGroup,
      attributeGroup,
      selectedPage,
      formTemplate,
    );

    this.setState({ formTemplate: updatedFormTemplate });
    this.saveFormTemplate(updatedFormTemplate);
  };

  updateAttribute = (
    fieldId: string,
    schemaId: string,
    addAttribute: boolean,
    attributeGroupName: string,
    attribute: AttributesType,
  ) => {
    const { selectedPage, formTemplate } = this.state;

    const updatedFormTemplate = updateFormTemplateAttribute(
      fieldId,
      schemaId,
      addAttribute,
      attributeGroupName,
      attribute,
      selectedPage,
      formTemplate,
    );

    this.setState({ formTemplate: updatedFormTemplate });
    this.saveFormTemplate(updatedFormTemplate);
  };

  addAllAttributes = (
    fieldId: string,
    schemaId: string,
    attributeGroupName: string,
    attributes: AttributesType[],
  ) => {
    const { selectedPage, formTemplate } = this.state;

    const updatedFormTemplate = addAllFormTemplateAttributes(
      fieldId,
      schemaId,
      attributeGroupName,
      attributes,
      selectedPage,
      formTemplate,
    );

    this.setState({ formTemplate: updatedFormTemplate });
    this.saveFormTemplate(updatedFormTemplate);
  };

  removeAssetSchema = (fieldId: string, schemaId: string) => {
    const { selectedPage, formTemplate } = this.state;

    const updatedFormTemplate = removeFormTemplateAssetSchema(
      fieldId,
      schemaId,
      selectedPage,
      formTemplate,
    );

    this.setState({ formTemplate: updatedFormTemplate });
    this.saveFormTemplate(updatedFormTemplate);
  };

  addFormModule = (
    type: string,
    conditionInspectionId?: string,
    schemaId?: string,
  ) => {
    const { selectedPage, formTemplate } = this.state;

    const updatedFormTemplate = addFormTemplateModule(
      type,
      selectedPage,
      formTemplate,
      conditionInspectionId,
      schemaId,
    );

    this.setState({
      formTemplate: updatedFormTemplate,
      selectedPage: selectedPage + 1,
    });

    this.saveFormTemplate(updatedFormTemplate);
  };

  updateCiFormInspection = (fieldId: string, conditionInspectionId: string) => {
    const { selectedPage, formTemplate } = this.state;

    const updatedFormTemplate = updateCiFormModule(
      selectedPage,
      fieldId,
      conditionInspectionId,
      formTemplate,
    );

    this.setState({ formTemplate: updatedFormTemplate });

    this.saveFormTemplate(updatedFormTemplate);
  };

  publishForm = () => {
    const { formTemplate } = this.state;

    const updatedFormTemplate = publishFormTemplate(formTemplate);

    this.setState({ formTemplate: updatedFormTemplate });
    this.saveFormTemplate(updatedFormTemplate);
  };

  render() {
    const {
      isDrawerOpen,
      selectedPage,
      formTemplate,
      pageInEditMode,
      pageName,
      selectedSchema,
    } = this.state;

    const {
      loadingFormTemplate,
      inventorySchemas,
      inventorySchemaTree,
      loadingInventorySchemaTree,
    } = this.props;

    const matchedSchema = inventorySchemas.find(
      (schema: InventorySchemaItem): boolean => schema.id === selectedSchema,
    );

    const rootSchema = matchedSchema ? matchedSchema : {};

    return (
      <FormBuilderContext.Provider
        value={{ ...this.state, schemas: inventorySchemas }}
      >
        <FormBuilderHeader
          formTemplate={formTemplate}
          saveFormTemplate={this.saveFormTemplate}
          publishForm={this.publishForm}
        />
        <div styleName="form-edit-container">
          <DragDropContext onDragEnd={this.onDragEndPages}>
            <FormPages
              formTemplate={formTemplate}
              toggleDrawer={this.toggleDrawer}
              isDrawerOpen={isDrawerOpen}
              selectedPage={selectedPage}
              pageInEditMode={pageInEditMode}
              updateSelectedPage={this.updateSelectedPage}
              updatePageInEditMode={this.updatePageInEditMode}
              addPage={this.addPage}
              deletePage={this.deletePage}
              duplicatePage={this.duplicatePage}
              pageName={pageName}
              updatePageName={this.updatePageName}
            />
          </DragDropContext>
          <DragDropContext onDragEnd={this.onDragEndFields}>
            <div styleName="form-edit-section">
              <ToolbarBase
                toggleDrawer={this.toggleDrawer}
                addField={this.addField}
                inventorySchemaTree={inventorySchemaTree}
                loadingInventorySchemaTree={loadingInventorySchemaTree}
                // @ts-ignore
                onToggle={this.onToggle}
                addAssetField={this.addAssetField}
                addFormModule={this.addFormModule}
              />
              <FormBuilderCanvas
                // @ts-ignore
                rootSchema={rootSchema}
                formTemplate={formTemplate}
                selectedPage={selectedPage}
                loading={loadingFormTemplate}
                updateFormTemplate={this.updateFormTemplate}
                deleteField={this.deleteField}
                duplicateField={this.duplicateField}
                updateAttributeGroup={this.updateAttributeGroup}
                updateAttribute={this.updateAttribute}
                addAllAttributes={this.addAllAttributes}
                removeAssetSchema={this.removeAssetSchema}
                updateCiFormInspection={this.updateCiFormInspection}
              />
            </div>
          </DragDropContext>
        </div>
      </FormBuilderContext.Provider>
    );
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  formTemplate: formTemplateSelector(state),
  loadingFormTemplate: state.loading.loadingFormTemplate,
  loadingInventorySchemaTree: state.loading.loadingInventorySchemaTree,
  inventorySchemas: getLocationBasedSchemas(state),
  inventorySchemaTree: state.inventorySchemaTree,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  formTemplateActions: bindActionCreators(actionCreators, dispatch),
  inventorySchemaActions: bindActionCreators(
    inventorySchemaActionCreators,
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormBuilder);
