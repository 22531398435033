import React from 'react';

import { TaskLocation } from '@atom/types/taskLocation';

import './workOrderReport.css';

interface Props {
  reportLocations: TaskLocation[];
}

const ReportLocationsSection = ({ reportLocations = [] }: Props) => {
  const locationNames = reportLocations.map(location => location?.name);

  return <div styleName="basic-container">{locationNames.join(', ')}</div>;
};

export default ReportLocationsSection;
