import React, { useContext } from 'react';

import { Progress } from '@atom/mui';

import TreeContext, { Tree } from './TreeContext';
import TreeList from './TreeList';

import './inventoryAssetTree.css';

export interface Props {
  level: number;
  loadingTree: boolean;
  trees: Tree[];
}

const TreeView = ({ level, loadingTree, trees }: Props) => {
  const { rootTree, selectedCategoryIds } = useContext(TreeContext);
  return (
    <>
      {!rootTree || loadingTree ? (
        <div styleName="progress-container">
          <Progress style={{ flex: 1 }} />
        </div>
      ) : (
        <TreeList
          isAncestorSelected={selectedCategoryIds?.has(rootTree.id)}
          level={level}
          trees={trees}
        />
      )}
    </>
  );
};

export default TreeView;
