import React from 'react';

import UserGroupControls from './UserGroupControls';
import UserGroupsBreadcrumbs from './UserGroupsBreadcrumbs';

import './userGroupsTable.css';

const styles = {
  breadcrumb: {
    display: 'flex',
    flexFlow: 'row wrap',
    flex: 'auto',
    alignItems: 'flex-start',
    width: '45rem',
    height: '3.125rem',
  },
};

const UserGroupsHeader = () => {
  return (
    <div styleName="header-container">
      <div style={styles.breadcrumb}>
        <UserGroupsBreadcrumbs />
      </div>
      <UserGroupControls />
    </div>
  );
};

export default UserGroupsHeader;
