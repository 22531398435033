import * as R from 'ramda';

import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function inventoryFilterCategoryTreeReducer(
  state = initialState.inventoryFilterCategoryTree,
  action,
) {
  const { data, type } = action;
  switch (type) {
    case actionTypes.GET_INVENTORY_FILTER_CATEGORY_TREE_NODE_SUCCESS: {
      const { subCategories, categoryPath, expanded } = data;
      const lensPath = R.lensPath(categoryPath);

      return R.over(
        lensPath,
        category => ({
          ...category,
          subCategories,
          expanded,
        }),
        state,
      );
    }
    case actionTypes.CREATE_CATEGORY_SUCCESS: {
      const { categoryPath, child } = data;
      const lensPath = R.lensPath(categoryPath);

      return R.over(
        lensPath,
        category => ({
          ...category,
          hasCategories: true,
          expanded: true,
          subCategories: [
            {
              id: child.id,
              name: child.name,
              hasCategories: false,
              schemaId: child.schemaId,
            },
            ...category.subCategories,
          ],
        }),
        state,
      );
    }
    case actionTypes.UPDATE_CATEGORY_TREE_SUCCESS: {
      const { path, name } = data;
      const lensPath = R.lensPath(path);

      return R.over(
        lensPath,
        category => ({
          ...category,
          name,
        }),
        state,
      );
    }
    case actionTypes.CLEAR_INVENTORY_FILTER_CATEGORY_TREE_NODE_SUCCESS: {
      return initialState.inventoryFilterCategoryTree;
    }
    default: {
      return state;
    }
  }
}
