import * as React from 'react';
import * as R from 'ramda';

import { Button, Switch } from '@atom/mui';
import colors from '@atom/styles/colors';
import fontStyles from '@atom/styles/fonts';
import { AttributeGroupItem } from '@atom/types/form';
import {
  AttributesType,
  HydratedAttributeGroupsItem,
} from '@atom/types/inventory';

import AttributeBlock from './AttributeBlock';

import '../formBuilder.css';

const styles = {
  toggleLabelStyles: {
    fontSize: fontStyles.md,
    color: colors.neutral.dark,
    whiteSpace: 'nowrap',
    paddingLeft: '0.5rem',
  },
  buttonStyles: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignContent: 'center',
  },
  labelStyles: {
    color: colors.brand.blue,
    whiteSpace: 'nowrap',
    paddingRight: '1rem',
  },
};

interface PassedProps {
  fullAttributeGroup: HydratedAttributeGroupsItem;
  assetAttributeGroup: HydratedAttributeGroupsItem;
  schemaId: string;
  fieldId: string;
  updateAttributeGroup: (
    fieldId: string,
    schemaId: string,
    addGroup: boolean,
    attributeGroup: AttributeGroupItem,
  ) => void;
  updateAttribute: (
    fieldId: string,
    schemaId: string,
    addAttribute: boolean,
    attributeGroupName: string,
    attribute: AttributesType,
  ) => void;
  addAllAttributes: (
    fieldId: string,
    schemaId: string,
    attributeGroupName: string,
    attributes: AttributesType[],
  ) => void;
}

type Props = PassedProps;

interface State {
  toggled: boolean;
  selectAll: boolean;
}

class AttributeGroupBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      toggled: props.fullAttributeGroup.name === props.assetAttributeGroup.name,
      selectAll: false,
    };
  }

  toggleGroup = () => {
    const {
      updateAttributeGroup,
      fieldId,
      schemaId,
      fullAttributeGroup,
    } = this.props;
    const { toggled } = this.state;
    const addGroup = !toggled;
    const attributeGroup = { name: fullAttributeGroup.name, attributes: [] };

    updateAttributeGroup(fieldId, schemaId, addGroup, attributeGroup);

    this.setState({ toggled: !toggled });
  };

  selectAll = () => {
    const {
      addAllAttributes,
      fieldId,
      schemaId,
      fullAttributeGroup,
    } = this.props;

    addAllAttributes(
      fieldId,
      schemaId,
      fullAttributeGroup.name,
      fullAttributeGroup.attributes,
    );

    this.setState({ selectAll: true });
  };

  resetSelectAll = () => {
    this.setState({ selectAll: false });
  };

  isAttributeChecked = (id: string): boolean => {
    const { assetAttributeGroup } = this.props;

    if (R.isNil(assetAttributeGroup.attributes)) {
      return false;
    }

    return !!assetAttributeGroup.attributes.find(
      (attribute: AttributesType): boolean => attribute.id === id,
    );
  };

  render() {
    const {
      fullAttributeGroup,
      updateAttribute,
      fieldId,
      schemaId,
    } = this.props;
    const { toggled, selectAll } = this.state;

    return (
      <div styleName="attribute-group-block-container">
        <div styleName="attribute-group-header">
          <Switch
            style={styles.toggleLabelStyles}
            label={fullAttributeGroup.name}
            name={fullAttributeGroup.name}
            checked={toggled}
            onChange={this.toggleGroup}
          />
          {toggled && (
            <Button color="primary" onClick={this.selectAll}>
              SELECT ALL
            </Button>
          )}
        </div>
        <div styleName="attribute-list-container">
          {toggled &&
            fullAttributeGroup.attributes.map(
              (fullAttribute: AttributesType) => {
                const isAttributeChecked = this.isAttributeChecked(
                  fullAttribute.id,
                );

                return (
                  <AttributeBlock
                    fieldId={fieldId}
                    schemaId={schemaId}
                    attributeGroupName={fullAttributeGroup.name}
                    key={fullAttribute.name}
                    fullAttribute={fullAttribute}
                    isAttributeChecked={isAttributeChecked}
                    selectAll={selectAll}
                    resetSelectAll={this.resetSelectAll}
                    updateAttribute={updateAttribute}
                  />
                );
              },
            )}
        </div>
      </div>
    );
  }
}

export default AttributeGroupBlock;
