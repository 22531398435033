import * as React from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';

import './workOrderElementView.css';

interface View {
  value: string;
  icon: string;
}

interface Props {
  view: View;
  activeView: View;
  setActiveView: (view: View) => void;
}

const ElementNavigationIcon = ({ view, activeView, setActiveView }: Props) => {
  const iconColor =
    activeView.value === view.value ? colors.brand.blue : colors.neutral.gray;
  const styleName =
    activeView.value === view.value
      ? 'icon-container active'
      : 'icon-container';

  return (
    <div onClick={() => setActiveView(view)} styleName={styleName}>
      <IconButton>
        <Icon color={iconColor}>{view.icon}</Icon>
      </IconButton>
    </div>
  );
};

export default ElementNavigationIcon;
