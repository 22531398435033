import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import CreateModal from '@atom/components/common/CreateModal';
import DeleteModal from '@atom/components/common/DeleteModal';
import EditModal from '@atom/components/common/EditModal';
// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import {
  CREATE_ANALYTICS_FOLDER,
  DELETE_ANALYTICS_FOLDER,
  UPDATE_ANALYTICS_FOLDER,
} from '@atom/graph/analytics';
import { Icon, Menu, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  AnalyticsFolder,
  AnalyticsFolderTree,
  AnalyticsFolderUpdateInput,
} from '@atom/types/analytics';

import AnalyticsContext from './AnalyticsContext';

import './analyticsFolderTree.css';

const { MenuItem } = Menu;

interface Props {
  folder: AnalyticsFolderTree;
  isRootFolder?: boolean;
}

const FolderOptions = ({ folder, isRootFolder }: Props) => {
  const {
    refetchTree,
    setActiveFolderId,
    setIsActiveFolderLeafNode,
    setSearching,
    setTriggerListRefetch,
    triggerListRefetch,
    setBulkMoveSelected,
  } = useContext(AnalyticsContext);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const [createFolder, { loading: loadingCreate }] = useMutation(
    CREATE_ANALYTICS_FOLDER,
  );

  const [editFolder, { loading: loadingEdit }] = useMutation<
    { editFolder: AnalyticsFolder },
    { input: AnalyticsFolderUpdateInput }
  >(UPDATE_ANALYTICS_FOLDER);

  const [deleteFolder, { loading: loadingDelete }] = useMutation(
    DELETE_ANALYTICS_FOLDER,
  );

  const handleCreate = async (name: string) => {
    try {
      const folderData = await createFolder({
        variables: {
          input: {
            parentFolderId: folder.id,
            name,
          },
        },
      });
      const createdFolder = folderData.data.analyticsFolderCreate;
      refetchTree();
      setTriggerListRefetch(!triggerListRefetch);
      Snackbar.info({
        message: `Created folder "${name}".`,
        action: 'View',
        onActionClick: () => {
          setActiveFolderId(createdFolder.id);
          setIsActiveFolderLeafNode(true);
          setSearching(false);
        },
      });
    } catch (error) {
      Snackbar.error({
        message: `An error occurred while Creating folder "${name}". Please Try Again.`,
      });
    }
    setCreateModalOpen(false);
  };

  const handleEdit = async (name: string) => {
    await editFolder({
      variables: {
        input: {
          id: folder.id,
          name,
        },
      },
    });
    refetchTree();
    setTriggerListRefetch(!triggerListRefetch);
    setEditModalOpen(false);
    Snackbar.info({
      message: `Renamed folder from "${folder.name}" to "${name}".`,
    });
  };

  const handleDelete = async () => {
    await deleteFolder({
      variables: {
        id: folder.id,
      },
    });
    setActiveFolderId('analytics');
    setSearching(false);
    setIsActiveFolderLeafNode(false);
    refetchTree();
    setTriggerListRefetch(!triggerListRefetch);
    setDeleteModalOpen(false);
    setBulkMoveSelected(new Set([]));
    Snackbar.info({
      message: 'Deleted folder.',
    });
  };

  const isDeletedAllowed = !isRootFolder;

  return (
    <div
      styleName="options-wrapper"
      data-cy={`analyticsFolderMenu_${folder?.name}`}
    >
      <Menu IconProps={{ color: colors.neutral.gray }}>
        {!folder.hasCards && (
          <MenuItem
            styleName="menu-item"
            onClick={() => setCreateModalOpen(true)}
            data-cy="analyticsCreateFolderOption"
          >
            <div styleName="primary-text-box">
              <div styleName="icon-box">
                <Icon color={colors.neutral.gray}>create_new_folder</Icon>
                <div styleName="primary-text">Create Folder</div>
              </div>
            </div>
          </MenuItem>
        )}
        {!isRootFolder && (
          <MenuItem
            styleName="menu-item"
            onClick={() => setEditModalOpen(true)}
            data-cy="analyticsEditFolderOption"
          >
            <div styleName="primary-text-box">
              <div styleName="icon-box">
                <img src={renameIcon} />
                <div styleName="primary-text">Rename</div>
              </div>
            </div>
          </MenuItem>
        )}
        {isDeletedAllowed && (
          <MenuItem
            styleName="menu-item"
            onClick={() => setDeleteModalOpen(true)}
            data-cy="analyticsDeleteFolderOption"
          >
            <div styleName="primary-text-box">
              <div styleName="icon-box">
                <Icon color={colors.neutral.gray}>delete</Icon>
                <div styleName="primary-text">Delete</div>
              </div>
            </div>
          </MenuItem>
        )}
      </Menu>
      <DeleteModal
        open={deleteModalOpen}
        loading={loadingDelete}
        title="Delete Folder"
        content="Are you sure you want to delete this folder? All folders and cards within this folder will also be deleted."
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />
      <CreateModal
        open={createModalOpen}
        loading={loadingCreate}
        title="Create Folder"
        fieldName="name"
        initialValue="Untitled"
        onCancel={() => setCreateModalOpen(false)}
        onConfirm={handleCreate}
        icon={renameIcon}
      />
      <EditModal
        initialValue={folder.name}
        open={editModalOpen}
        loading={loadingEdit}
        title="Rename Folder"
        fieldName="folder name"
        onCancel={() => setEditModalOpen(false)}
        onConfirm={handleEdit}
        icon={renameIcon}
      />
    </div>
  );
};

export default FolderOptions;
