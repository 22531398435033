import * as React from 'react';
import { Control } from 'react-redux-form';

import TextField from '../common/TextField';

import textStyle from './textStyle';

import './attributeControls.css';

interface Props {
  name: string;
  value: any;
  isEditable: boolean;
  onUpdate?: (hasUnsavedChanges: boolean) => void;
}

interface State {
  value: any;
}

class LongTextAttribute extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { value: props.value ? props.value : '' };
  }

  onChange = event => {
    this.setState({ value: event.target.value });
    if (this.props.onUpdate) {
      this.props.onUpdate(true);
    }
  };

  render() {
    const { name, isEditable } = this.props;
    const { value } = this.state;

    return (
      <div key={name}>
        <Control
          type={'text'}
          model={`.${name}`}
          component={TextField}
          value={value}
          onChange={event => this.onChange(event)}
          disabled={!isEditable}
          controlProps={{
            fullWidth: true,
            multiLine: true,
            maxLength: 5000,
            style: textStyle.inputText,
            textareaStyle: textStyle.textarea,
          }}
        />
      </div>
    );
  }
}

export default LongTextAttribute;
