import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import LocationsAndAssetsContext, {
  ProceedAction,
} from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsContext';
import RequiredFieldsConfirmModal from '@atom/components/common/workOrderDetail/requiredFieldsConfirmModal/RequiredFieldsConfirmModal';
import TaskItemTile from '@atom/components/common/workOrderDetail/taskItemTile/TaskItemTile';
import {
  TASK_LOCATION_DELETE,
  TASK_LOCATION_DUPLICATE,
} from '@atom/graph/taskLocation';
import { Icon, Menu, Snackbar } from '@atom/mui';
import { TaskLocation } from '@atom/types/taskLocation';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';

import TaskLocationModal from '../taskLocationModal/TaskLocationModal';

const { MenuItem } = Menu;

interface Props {
  location: TaskLocation;
}

const LocationTile = ({ location }: Props) => {
  const { workOrderDetail, task, refetch, dataCySuffix } = useContext(
    LocationsAndAssetsContext,
  );

  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openFieldsConfirm, setOpenFieldsConfirm] = useState<boolean>(false);
  const [proceedAction, setProceedAction] = useState<ProceedAction>();

  const [deleteLocation, { loading: loadingDelete }] = useMutation(
    TASK_LOCATION_DELETE,
  );
  const [duplicateLocation, { loading: loadingDuplicate }] = useMutation(
    TASK_LOCATION_DUPLICATE,
  );

  const handleDuplicate = async () => {
    Snackbar.info({ message: `Duplicating ${location.name}...` });

    try {
      await duplicateLocation({
        variables: { id: location.id },
      });

      refetch();

      Snackbar.info({ message: `Duplicated ${location.name}.` });
    } catch (error) {
      Snackbar.error({ message: `Failed to duplicate ${location.name}.` });
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    Snackbar.info({ message: `Deleting ${location.name}...` });

    try {
      await deleteLocation({
        variables: { id: location.id },
      });

      refetch();

      Snackbar.info({ message: `Deleted ${location.name}.` });
    } catch (error) {
      Snackbar.error({ message: `Failed to delete ${location.name}.` });
    }
  };

  const isEditDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  const handleDuplicateClick = () => {
    if (task.isCompleted && task?.requireAtLeastOneLocation) {
      setOpenFieldsConfirm(true);
      setProceedAction(ProceedAction.DUPLICATE);
    } else {
      handleDuplicate();
    }
  };

  const handleDeleteClick = () => {
    if (task.isCompleted && task?.requireAtLeastOneLocation) {
      setOpenFieldsConfirm(true);
      setProceedAction(ProceedAction.DELETE);
    } else {
      setOpenDelete(true);
    }
  };

  const onProceedComplete = async () => {
    await refetch();

    setOpenFieldsConfirm(false);

    if (proceedAction === ProceedAction.DELETE) {
      setProceedAction(null);
      setOpenDelete(true);
    } else {
      setProceedAction(null);
      handleDuplicate();
    }
  };

  const actionButton = isEditDisabled ? (
    <div />
  ) : (
    <Menu IconButtonProps={{ size: 'small' }} noMinWidth>
      <MenuItem
        startAdornment={<Icon>content_copy</Icon>}
        onClick={handleDuplicateClick}
        disabled={loadingDuplicate}
        data-cy={`locationTileDuplicate_${dataCySuffix}`}
      >
        Duplicate
      </MenuItem>
      <MenuItem
        startAdornment={<Icon>delete</Icon>}
        onClick={handleDeleteClick}
        disabled={loadingDelete}
        data-cy={`locationTileDelete_${dataCySuffix}`}
      >
        Delete
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <TaskItemTile
        content={R.pathOr('', ['name'], location)}
        onTileClick={() => setOpen(true)}
        icon={<Icon>place</Icon>}
        actionButton={actionButton}
        tooltipContent="Edit Location"
      />
      <DeleteModal
        open={openDelete}
        onConfirm={handleDelete}
        onCancel={() => setOpenDelete(false)}
        title="Delete Location?"
        content="Are you sure you want to delete this location?"
        cancelText="Cancel"
        confirmText="Delete"
      />
      <TaskLocationModal
        open={open}
        onClose={() => setOpen(false)}
        location={location}
        editMode
      />
      <RequiredFieldsConfirmModal
        open={openFieldsConfirm}
        onCancel={() => setOpenFieldsConfirm(false)}
        onComplete={onProceedComplete}
        task={task}
        workOrderId={workOrderDetail?.id}
      />
    </>
  );
};

export default LocationTile;
