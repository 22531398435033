import React, { useContext } from 'react';
import * as R from 'ramda';

import AddTeamModalContext from '@atom/components/common/workOrderDetail/addTeamModal/AddTeamModalContext';
import { UserDetail } from '@atom/types/user';
import { UserGroup } from '@atom/types/userGroups';

import UserRow from '../usersTab/UserRow';

import UserGroupRow from './UserGroupRow';

import './searchTab.css';

const SearchTab = () => {
  const { searchUsers, userGroups } = useContext(AddTeamModalContext);

  const userGroupsDictionary = userGroups.reduce((acc, userGroup) => {
    const groupPath = R.dropLast(1, userGroup.groupPath).join(' / ');

    return acc[groupPath]
      ? { ...acc, [groupPath]: [...acc[groupPath], userGroup] }
      : { ...acc, [groupPath]: [userGroup] };
  }, {});

  return (
    <>
      {!R.isEmpty(searchUsers) && (
        <>
          <div styleName="section-header">all users</div>
          <div>
            {searchUsers.map((user: UserDetail) => (
              <UserRow key={user.id} user={user} />
            ))}
          </div>
        </>
      )}
      {!R.isEmpty(userGroups) && (
        <>
          <div styleName="section-header">groups</div>
          <div>
            {R.keys(userGroupsDictionary).map(
              (section: string, index: number) => (
                <div key={section + index} styleName="section-container">
                  <div styleName="group-path-title" key={section}>
                    {section}
                  </div>
                  <div>
                    {userGroupsDictionary[section].map(
                      (userGroup: UserGroup) => (
                        <UserGroupRow
                          key={userGroup.id}
                          userGroup={userGroup}
                        />
                      ),
                    )}
                  </div>
                </div>
              ),
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SearchTab;
