import * as R from 'ramda';
import { createSelector } from 'reselect';

export const poolAttributesSelector = R.pathOr([], ['poolAttributes']);

export const getPoolAttributeColumnsSelector = createSelector(
  poolAttributesSelector,
  attributes => [
    {
      name: 'Inventory Name',
      fieldName: 'assetName',
      dataType: 'shorttext',
    },
    {
      name: 'Path',
      fieldName: 'ancestors',
      dataType: 'shorttext',
    },
    {
      name: 'Name',
      fieldName: 'name',
      dataType: 'shorttext',
    },
    {
      name: 'Type',
      fieldName: 'assetType',
      dataType: 'shorttext',
    },
    ...attributes.map(({ id, name, dataType }) => ({
      name,
      fieldName: `attributes.${id}`,
      dataType,
    })),
  ],
);

export const getPoolAttributeFilterSelector = createSelector(
  poolAttributesSelector,
  attributes => [
    {
      name: 'Common Attributes',
      attributes,
    },
  ],
);
