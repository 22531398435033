import React from 'react';
import { match as Match } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import WorkOrders from '@atom/components/workOrders/WorkOrders';
import { GET_EXPORT } from '@atom/graph/export';
import { Progress } from '@atom/mui';
import { Export } from '@atom/types/export';

import WorkExportDetailHeader from './WorkExportDetailHeader';

interface Props {
  match: Match<{ id: string }>;
}

const styles = {
  progress: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const WorkExportDetail = ({ match }: Props) => {
  const { data, loading } = useQuery<{ export: Export }, { id: string }>(
    GET_EXPORT,
    {
      variables: {
        id: match.params.id,
      },
    },
  );

  return loading || !data ? (
    <div style={styles.progress}>
      <Progress />
    </div>
  ) : (
    <div>
      <WorkExportDetailHeader export={data.export} />
      <WorkOrders variant="workExportDetail" />
    </div>
  );
};

export default WorkExportDetail;
