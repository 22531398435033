import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import TextOverflowTooltip from '@atom/components/common/tooltip/TextOverflowTooltip';
import UserContext from '@atom/components/userDetail/UserContext';
import { USER_UPDATE } from '@atom/graph/user';
import { Button, Icon, IconButton, Progress, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import {
  UserDetail,
  UserGroupListItem,
  UserUpdateInput,
} from '@atom/types/user';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';
import { maskUntilPos } from '@atom/utilities/stringUtilities';

import './infoTab.css';

const styles = {
  inputField: {
    color: colors.neutral.dark,
    fontSize: fonts.md,
    marginTop: '-17px',
    marginBottom: 0,
  },
};

const InfoSection = () => {
  const { user } = useContext(UserContext);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [employeeId, setEmployeeId] = useState<string>(user.employeeId);
  const [baseLocation, setBaseLocation] = useState<string>(user.baseLocation);
  const [department, setDepartment] = useState<string>(user.department);
  const [title, setTitle] = useState<string>(user.title);

  const [updateUser, { loading }] = useMutation<
    { userUpdate: UserDetail },
    { input: UserUpdateInput }
  >(USER_UPDATE);

  const confirm = () => {
    updateUser({
      variables: {
        input: {
          id: user.id,
          ...(employeeId !== user.employeeId && { employeeId }),
          ...(baseLocation !== user.baseLocation && { baseLocation }),
          ...(department !== user.department && { department }),
          ...(title !== user.title && { title }),
        },
      },
    });

    setEditMode(false);
  };

  const onCancel = () => {
    setEditMode(false);
    setEmployeeId(user.employeeId);
    setBaseLocation(user.baseLocation);
    setDepartment(user.department);
    setTitle(user.title);
  };

  const isAldot = isCurrentClient([Client.ALDOT]);
  const isRightEditButtonDisabled = isAldot;
  const maskEmployeeId = isAldot;

  const rightButtons = editMode ? (
    <div>
      <Button onClick={onCancel} disabled={loading}>
        Cancel
      </Button>
      <Button color="primary" onClick={confirm} disabled={loading}>
        Save
      </Button>
      {loading && (
        <div styleName="spinner-container">
          <Progress size={25} thickness={2} />
        </div>
      )}
    </div>
  ) : hasRolePermissions(ROLE_SETS.ORG_ADMIN) && !isRightEditButtonDisabled ? (
    <IconButton onClick={() => setEditMode(true)}>
      <Icon>edit</Icon>
    </IconButton>
  ) : (
    <div />
  );

  return (
    <div styleName="left-pane">
      <div styleName="info-header">
        <div>Basic Information</div>
        {rightButtons}
      </div>
      <div styleName="attribute-list-container">
        <div styleName="attribute-list-row">
          <div styleName="attribute-label">Employee ID:</div>
          <div styleName="attribute-value">
            {editMode ? (
              <TextField
                style={styles.inputField}
                value={employeeId}
                onChange={event => setEmployeeId(event.target.value)}
              />
            ) : maskEmployeeId ? (
              maskUntilPos(user.employeeId)
            ) : (
              user.employeeId
            )}
          </div>
        </div>
        <div styleName="attribute-list-row">
          <div styleName="attribute-label">Base Location:</div>
          <div styleName="attribute-value">
            {editMode ? (
              <TextField
                style={styles.inputField}
                value={baseLocation}
                onChange={event => setBaseLocation(event.target.value)}
              />
            ) : (
              user.baseLocation
            )}
          </div>
        </div>
        <div styleName="attribute-list-row">
          <div styleName="attribute-label">Department:</div>
          <div styleName="attribute-value">
            {editMode ? (
              <TextField
                style={styles.inputField}
                value={department}
                onChange={event => setDepartment(event.target.value)}
              />
            ) : (
              user.department
            )}
          </div>
        </div>
        <div styleName="attribute-list-row">
          <div styleName="attribute-label">Job Title:</div>
          <div styleName="attribute-value">
            {editMode ? (
              <TextField
                style={styles.inputField}
                value={title}
                onChange={event => setTitle(event.target.value)}
              />
            ) : (
              user.title
            )}
          </div>
        </div>
        <div styleName="attribute-list-row">
          <div styleName="attribute-label">Groups:</div>
        </div>
        <div styleName="attribute-group-value">
          {user.userGroups.map((userGroup: UserGroupListItem) => (
            <Link to={`/team/group/${userGroup.id}`}>
              <TextOverflowTooltip
                paddingBottom="0.5rem"
                placementBottom
                text={userGroup.groupPath.join(' / ')}
                width="100%"
                lightTooltip
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
