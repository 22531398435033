import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function formTemplatesTotalReducer(
  state = initialState.formTemplatesTotal,
  action,
) {
  switch (action.type) {
    case actionTypes.GET_FORM_TEMPLATES_TOTAL_SUCCESS: {
      return action.data ? action.data : 0;
    }
    case actionTypes.DELETE_FORM_TEMPLATE_SUCCESS: {
      return state > 0 ? state - 1 : state;
    }
    default: {
      return state;
    }
  }
}
