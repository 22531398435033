import React from 'react';

import { DataType } from '@atom/types/dataType';
import {
  getDisplayDateTime,
  setDisplayDate,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import { MappedField } from './reportUtilities';

import './workOrderReport.css';

interface Props {
  field: MappedField;
}

const getTitle = (field: MappedField) => {
  const requiredTitle = field?.required ? `*${field?.title}` : field?.title;

  return (
    <span>
      <span>{requiredTitle}</span>
      {!isNilOrEmpty(field?.units) && (
        <span styleName="unit">{` (${field?.units})`}</span>
      )}
      <span>{`: `}</span>
    </span>
  );
};

const getValueDisplay = (field: MappedField) => {
  if (isNilOrEmpty(field?.value)) {
    return <span styleName="empty-value">space for values</span>;
  }

  if (field?.dataType === DataType.DATE) {
    return <span>{setDisplayDate(field?.value)}</span>;
  }

  if (field?.dataType === DataType.DATE_TIME) {
    return <span>{getDisplayDateTime(field?.value)}</span>;
  }

  if (field?.dataType === DataType.ENUM_MULTIPLE) {
    return <span>{field?.value.join(', ')}</span>;
  }

  return <span>{field?.value}</span>;
};

const CustomFieldCell = ({ field }: Props) => {
  const title = getTitle(field);
  const value = getValueDisplay(field);

  return (
    <div styleName="custom-field-tile">
      <div>
        {title}
        {value}
      </div>
    </div>
  );
};

export default CustomFieldCell;
