import * as R from 'ramda';

export const attributesIntersectWithForm = R.memoizeWith(
  (attributeGroup, form) => JSON.stringify([attributeGroup, form]),
  (attributeGroup, form) => {
    return !R.isEmpty(
      R.intersection(
        attributeGroup.attributes.map(attribute => attribute.id),
        R.keys(form),
      )
        .map(id => form[id])
        .filter(attribute => {
          if (Array.isArray(attribute) && R.isEmpty(attribute)) {
            return false;
          }

          if (attribute.from === '' && attribute.to === '') {
            return false;
          }

          return true;
        }),
    );
  },
);
