import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function (state = initialState.inventorySchemas, action) {
  switch (action.type) {
    case actionTypes.GET_INVENTORY_SCHEMAS_SUCCESS: {
      return action.data;
    }
    case actionTypes.DELETE_SCHEMA_SUCCESS: {
      return state.filter(schema => schema.id !== action.data.schemaId);
    }
    default: {
      return state;
    }
  }
}
