import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import {
  DELETE_ANALYTICS_CARD,
  GET_ANALYTICS_CARD,
  UPDATE_ANALYTICS_CARD,
} from '@atom/graph/analytics';
import { Progress, Snackbar } from '@atom/mui';
import {
  AnalyticsCard,
  AnalyticsCardUpdateInput,
  AnalyticsModal,
} from '@atom/types/analytics';
import history from '@atom/utilities/history';

import EditAnalyticsCardModal from '../analyticsDashboard/analyticsModals/EditAnalyticsCardModal';
import DeleteModal from '../common/DeleteModal';

import AnalyticsHeader from './AnalyticsHeader';
import AnalyticsTile from './AnalyticsTile';

import './analyticsDetail.css';

interface Props {
  match: any;
}

const AnalyticsDetail = ({ match }: Props) => {
  const [activeModal, setActiveModal] = useState<AnalyticsModal>(null);

  const { data, loading, refetch } = useQuery<
    { analyticsCard: AnalyticsCard },
    { id: string }
  >(GET_ANALYTICS_CARD, {
    variables: {
      id: match.params.id,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const [updateCard] = useMutation<
    { analyticsCardUpdate: AnalyticsCard },
    { input: AnalyticsCardUpdateInput }
  >(UPDATE_ANALYTICS_CARD);

  const handleUpdateCard = async (input: AnalyticsCardUpdateInput) => {
    try {
      await updateCard({
        variables: {
          input,
        },
      });
      Snackbar.info({
        message: 'Updated.',
      });
      refetch();
    } catch (error) {
      Snackbar.error({
        message: `An error occurred while updating "${data?.analyticsCard?.name}". Please Try Again.`,
      });
    } finally {
      setActiveModal(null);
    }
  };

  const [deleteCard] = useMutation<{}, { id: string }>(DELETE_ANALYTICS_CARD);

  const handleDeleteCard = async () => {
    try {
      await deleteCard({ variables: { id: match.params.id } });
      history.push('/analytics/dashboard');
    } catch (error) {
      Snackbar.error({
        message: `An error occurred". Please Try Again.`,
      });
    }
  };

  const tileSize =
    data?.analyticsCard?.embedLinks.length === 1 ? 'large' : 'normal';
  return (
    <>
      <AnalyticsHeader
        name={data?.analyticsCard?.name || ''}
        setActiveModal={setActiveModal}
        loading={loading}
      />
      <div styleName="analytics-portal-content">
        {loading ? (
          <div styleName="loading-container">
            <Progress />
          </div>
        ) : (
          <>
            <div styleName={`analytics-tiles ${tileSize}`}>
              {data?.analyticsCard?.embedLinks.map(
                (url: string, index: number) => {
                  return (
                    <AnalyticsTile key={index} url={url} tileSize={tileSize} />
                  );
                },
              )}
            </div>
            <EditAnalyticsCardModal
              open={activeModal === AnalyticsModal.UPDATE}
              onClose={() => setActiveModal(null)}
              handleUpdateCard={handleUpdateCard}
              card={data?.analyticsCard}
            />
            <DeleteModal
              open={activeModal === AnalyticsModal.DELETE}
              title="Delete Card"
              content="Are you sure you want to delete this card?"
              onCancel={() => setActiveModal(null)}
              onConfirm={handleDeleteCard}
            />
          </>
        )}
      </div>
    </>
  );
};

export default AnalyticsDetail;
