import * as R from 'ramda';
import { call, fork, put, take, takeEvery } from 'redux-saga/effects';

import actionTypes from '@atom/actions/actionTypes';
import * as organizationActions from '@atom/actions/organizationActions';
import * as userActions from '@atom/actions/userActions';
import { Snackbar } from '@atom/mui';
import {
  apiErrorHandler,
  getAccountPayload,
  isEmailVerified,
  logoutUser,
  persistAccountPayload,
  tokenExists,
  updateRequestHeaders,
} from '@atom/utilities/accountUtilities';
import api from '@atom/utilities/api';
import authUtilities from '@atom/utilities/authUtilities';
import {
  CURRENT_SUBDOMAIN,
  PASSWORD_RESET_ENDPOINT,
  TOKEN_EXCHANGE_ENDPOINT,
  USER_PROFILE_ENDPOINT,
  VERIFICATION_EMAIL_ENDPOINT,
} from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';

export function* clearUserSession() {
  yield call(logoutUser);
  yield put(userActions.logoutSuccess());
  history.push('/login');
}

export function* persistUserSession(action) {
  try {
    yield call(persistAccountPayload, action.data.token);
    yield put(userActions.persistUserTokenSuccess());

    if (CURRENT_SUBDOMAIN !== 'www') {
      // @ts-ignore
      yield call(history.push, '/');
    } else {
      // @ts-ignore
      yield call(history.push, '/tenant-selection');
      yield put(organizationActions.requestUserOrganizations());
    }
  } catch (error) {
    yield fork(apiErrorHandler, error, userActions.persistUserTokenFailure);
  }
}

export function* resendEmailVerificationLink() {
  try {
    Snackbar.info({
      message: 'Email Verification Sent',
    });
    yield call(api.post, VERIFICATION_EMAIL_ENDPOINT);

    yield put(userActions.getEmailVerificationLinkSuccess());
  } catch (error) {
    yield fork(
      apiErrorHandler,
      error,
      userActions.getEmailVerificationLinkFailure,
    );
  }
}

export function* sendPasswordResetEmail(action) {
  try {
    Snackbar.info({
      message: 'Password Reset Email Sent',
    });

    const payload = {
      email: action.data.email,
    };

    yield call(api.post, PASSWORD_RESET_ENDPOINT, payload);
    yield put(userActions.userPasswordResetSuccess());
  } catch (error) {
    yield fork(apiErrorHandler, error, userActions.userPasswordResetFailure);
  }
}

export function* getLoggedInUser() {
  try {
    if (!tokenExists()) {
      // @ts-ignore
      yield call(history.push, '/login');
      return;
    }

    if (!isEmailVerified()) {
      // @ts-ignore
      yield call(history.push, '/verify-email');
      return;
    }

    const { data } = yield call(api.get, USER_PROFILE_ENDPOINT);
    yield call(updateRequestHeaders);

    authUtilities.setUserRole(data.role);
    authUtilities.setUserRoleLevel(data.level);
    authUtilities.setFullStoryAccount(data);

    if (!data.isVerified || !data.isActive) {
      // @ts-ignore
      yield call(history.push, '/unverified');
    }
    if (!data.id) {
      // @ts-ignore
      yield call(history.push, '/login');
    }

    yield put(userActions.loggedInUserSuccess(data));
  } catch (error) {
    if (error.response && error.response.status === 403) {
      yield put(userActions.forbiddenUser());
      // @ts-ignore
      yield call(history.push, '/unverified');
    } else {
      yield put(userActions.loggedInUserFailure());
      // @ts-ignore
      yield call(history.push, '/login');
    }
  }
}

export function* exchangeAuthToken(action) {
  try {
    const payload = {
      ...action.data,
    };
    yield call(api.post, TOKEN_EXCHANGE_ENDPOINT, payload);
    yield put(userActions.userAuthTokenExchangeSuccess());

    if (!isEmailVerified()) {
      // @ts-ignore
      yield call(history.push, '/verify-email');
      return;
    }

    if (CURRENT_SUBDOMAIN !== 'www') {
      // @ts-ignore
      yield call(history.push, '/');
    } else {
      // @ts-ignore
      yield call(history.push, '/tenant-selection');
      yield put(organizationActions.requestUserOrganizations());
    }
  } catch (error) {
    yield fork(
      apiErrorHandler,
      error,
      userActions.userAuthTokenExchangeFailure,
    );
  }
}

export function* getUserOrganizations() {
  try {
    const token = yield call(getAccountPayload);
    const organizations = token.organizations;

    if (R.isEmpty(organizations)) {
      // @ts-ignore
      yield call(history.push, '/tenant-entry');
    }
    yield put(organizationActions.getUserOrganizationsSuccess(organizations));
  } catch (error) {
    yield fork(
      apiErrorHandler,
      error,
      organizationActions.getUserOrganizationsFailure,
    );
  }
}

/* WATCHERS */

export function* requestUserTokenPersist() {
  yield takeEvery(actionTypes.REQUEST_USER_TOKEN_PERSIST, persistUserSession);
}

export function* requestUserOrganizations() {
  yield takeEvery(actionTypes.REQUEST_USER_ORGANIZATIONS, getUserOrganizations);
}

export function* requestUserPasswordReset() {
  yield takeEvery(
    actionTypes.REQUEST_USER_PASSWORD_RESET,
    sendPasswordResetEmail,
  );
}

export function* requestEmailVerificationLink() {
  yield takeEvery(
    actionTypes.REQUEST_EMAIL_VERIFICATION_LINK,
    resendEmailVerificationLink,
  );
}

export function* requestUserAuthTokenExchange() {
  yield takeEvery(
    actionTypes.REQUEST_USER_AUTH_TOKEN_EXCHANGE,
    exchangeAuthToken,
  );
}

export function* logout() {
  while (true) {
    yield take(actionTypes.TRIGGER_LOGOUT);
    yield fork(clearUserSession);
  }
}

export function* loggedInUser() {
  while (true) {
    yield take(actionTypes.REQUEST_LOGGED_IN_USER);
    yield fork(getLoggedInUser);
  }
}

export default [
  requestUserTokenPersist,
  requestUserPasswordReset,
  requestUserAuthTokenExchange,
  requestUserOrganizations,
  requestEmailVerificationLink,
  logout,
  loggedInUser,
];
