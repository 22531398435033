import React, { useContext, useEffect, useRef, useState } from 'react';
import * as R from 'ramda';

import TeamContext, { View } from '@atom/components/teamPortal/TeamContext';
import UserGroupOptions from '@atom/components/teamPortal/teamControls/UserGroupOptions';
import { Icon, IconButton, Tooltip } from '@atom/mui';
import { UserGroupTreeType } from '@atom/types/userGroups';
import { getColorFromColorId } from '@atom/utilities/colorUtilities';

import './userGroupTree.css';

const PADDING_OFFSET = 0.75;

const styles = {
  iconButton: {
    margin: '0 9px',
  },
  indentation: {
    paddingLeft: '3rem',
  },
};

export interface Props {
  userGroup: UserGroupTreeType;
  indentation: number;
  path: UserGroupTreeType[];
}

const UserGroupRow = ({ userGroup, indentation, path }: Props) => {
  const {
    activeView,
    activeGroup,
    setActiveView,
    handleGroup,
    open,
    toggleOpen,
  } = useContext(TeamContext);

  const [overflowing, setOverflowing] = useState<boolean>(false);
  const container = useRef<HTMLDivElement>();

  useEffect(() => {
    const { offsetWidth, scrollWidth } = container.current;
    setOverflowing(offsetWidth < scrollWidth);
  }, [userGroup.name]);

  useEffect(() => {
    if (activeGroup.id === userGroup.id) {
      handleGroup(userGroup, path);
    }
  }, [activeGroup.id]);

  const expanded = open.has(userGroup.id);
  const arrowDirection = expanded ? 'arrow_drop_down' : 'arrow_right';
  const renderExpandedArrow = !R.isEmpty(userGroup.userGroups);

  const rowStyle =
    activeView === View.DIRECTORY && activeGroup.id === userGroup.id
      ? 'row selected'
      : 'row';

  const onClick = (selectedUserGroup: UserGroupTreeType) => {
    setActiveView(View.DIRECTORY);
    handleGroup(selectedUserGroup, path);
  };

  const leftIcon = (
    <>
      {renderExpandedArrow && (
        <IconButton
          onClick={() => toggleOpen(userGroup.id)}
          size="small"
          style={styles.iconButton}
        >
          <Icon>{arrowDirection}</Icon>
        </IconButton>
      )}
      <Icon color={getColorFromColorId(userGroup.colorId)}>lens</Icon>
    </>
  );

  const containerStyle = {
    paddingLeft: `calc(${PADDING_OFFSET * indentation}rem)`,
  };

  const overflowStyle = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: `calc(12.5rem - (${PADDING_OFFSET * indentation}rem))`,
  };

  return (
    <>
      <div
        styleName={rowStyle}
        style={containerStyle}
        onClick={() => onClick(userGroup)}
      >
        <div
          styleName="row-content sub-row"
          style={renderExpandedArrow ? {} : styles.indentation}
        >
          <div ref={container} styleName="left-content">
            {leftIcon}
            <div styleName="name-container">
              <div styleName="title">
                {!overflowing ? (
                  userGroup.name
                ) : (
                  <Tooltip
                    lightTooltip
                    title={userGroup.name}
                    placement="right-start"
                  >
                    <p style={overflowStyle} styleName="title">
                      {userGroup.name}
                    </p>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          <UserGroupOptions userGroup={userGroup} />
        </div>
      </div>
      {expanded ? (
        userGroup.userGroups.map((item: UserGroupTreeType) => (
          <UserGroupRow
            key={item.id}
            userGroup={item}
            indentation={indentation + 1}
            path={[...path, item]}
          />
        ))
      ) : (
        <div />
      )}
    </>
  );
};

export default UserGroupRow;
