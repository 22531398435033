import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { TIME_ENTRIES_UPDATE_BY_APPROVER } from '@atom/graph/timeEntry';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { Button, Snackbar, Tooltip } from '@atom/mui';
import {
  TimeEntriesUpdateByApproverInput,
  TimeEntryStatus,
} from '@atom/types/timeEntry';
import { UserDetail } from '@atom/types/user';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';

import TeamContext from '../TeamContext';

import './timeHeaders.css';

interface Props {
  users: UserDetail[];
  loading: boolean;
  selectedUserIds: Set<string>;
  setSelectedUserIds: (selectedUserIds: Set<string>) => void;
  refetchUsersWithTimeSheetEntries: () => void;
}

const ListBulkControls = ({
  selectedUserIds,
  setSelectedUserIds,
  refetchUsersWithTimeSheetEntries,
  loading,
  users,
}: Props) => {
  const userProfile = useUserProfile();
  const { activeTimeSheetGroup, selectedPayPeriod } = useContext(TeamContext);

  const [
    timeEntriesUpdateByApprover,
    { loading: timeEntriesUpdateByApproverLoading },
  ] = useMutation<
    { timeEntriesUpdateByApprover: boolean },
    { input: TimeEntriesUpdateByApproverInput }
  >(TIME_ENTRIES_UPDATE_BY_APPROVER);

  const handleSubmit = async (status: TimeEntryStatus) => {
    if (
      status === TimeEntryStatus.approved &&
      selectedUserIds.has(userProfile.userId)
    ) {
      setSelectedUserIds(new Set([]));
      return Snackbar.error({ message: 'Cannot approve your own timesheet.' });
    }

    await timeEntriesUpdateByApprover({
      variables: {
        input: {
          status,
          dateStart: selectedPayPeriod.startDate,
          dateEnd: selectedPayPeriod.endDate,
          userGroupId: activeTimeSheetGroup?.id,
          userIds: [...selectedUserIds],
        },
      },
    });

    return refetchUsersWithTimeSheetEntries();
  };

  const selectedUsers = users.filter(user => selectedUserIds.has(user.id));
  const hasOpenWork =
    selectedUsers.filter(user => user?.timeEntries?.totalOpenWorkOrders > 0)
      .length > 0;

  const disableReject =
    loading ||
    timeEntriesUpdateByApproverLoading ||
    doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  const disableApprove =
    loading ||
    timeEntriesUpdateByApproverLoading ||
    hasOpenWork ||
    doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  return (
    <div styleName="list-bulk-controls-header">
      <div styleName="left-container bold">{`${selectedUserIds.size} selected`}</div>
      <div styleName="button-container">
        <Button
          onClick={() => handleSubmit(TimeEntryStatus.rejected)}
          disabled={disableReject}
          data-cy="rejectTimesheetButton"
        >
          Reject
        </Button>
        <Tooltip lightTooltip title={hasOpenWork ? 'Contains open work' : ''}>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit(TimeEntryStatus.approved)}
              disabled={disableApprove}
              data-cy="approveTimesheetButton"
            >
              Approve
            </Button>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default ListBulkControls;
