import React from 'react';

import { GeoJSONGeometry, GeoJSONPoint } from './geojson';
import { RoleType } from './user';
import { WorkPriorityType } from './work';

export enum MapEditMetadataType {
  ASSET = 'ASSET',
  WORK_ORDER = 'WORK_ORDER',
  USER = 'USER',
}

export enum MapDistanceUnit {
  MILES = 'MILES',
  FEET = 'FEET',
  YARDS = 'YARDS',
  METERS = 'METERS',
}

export enum MapAreaUnit {
  MILES = 'MILES',
  FEET = 'FEET',
  YARDS = 'YARDS',
  ACRES = 'ACRES',
  METERS = 'METERS',
}

export enum BoundingBoxSubjectType {
  LOCATION = 'LOCATION',
  ASSET = 'ASSET',
  ALL = 'ALL',
}

export interface MapCluster {
  id: string;
  location: GeoJSONPoint;
  count: number;
}

export interface MapWorkOrder {
  id: string;
  name: string;
  inventoryAssetId: string;
  inventoryAssetName: string;
  type: string;
  statusId: number | string;
  priorityId: WorkPriorityType;
  location: GeoJSONGeometry;
  estimatedCost: number;
  dueDate: number;
  taskCount: number;
  assigneeCount: number;
}

export interface MapAsset {
  id: string;
  name: string;
  assetType: string;
  updatedDate: number;
  markerId: number;
  location: GeoJSONGeometry;
  mainPhotoFileId?: string;
  schemaId?: string;
}

export interface MapUser {
  id: string;
  firstName: string;
  lastName: string;
  photoUrl: string;
  role: RoleType;
  location: GeoJSONPoint;
  email: string;
}

export interface MapWorkCluster {
  id: string;
  location: GeoJSONPoint;
  count: number;
  entities: MapWorkOrder;
}

export interface MapAssetCluster {
  id: string;
  location: GeoJSONPoint;
  count: number;
  entities: MapAsset;
}

export type MapStyle = 'default' | 'silver' | 'retro' | 'dark';

export type LineCoordinates = [[number, number], [number, number]];
export type LineStringCoordinates = Array<[number, number]>;

export interface MapData {
  clusters: MapCluster[];
  assets: MapAsset[];
  assetsCount: number;
  workOrders: MapWorkOrder[];
  workOrdersCount: number;
  users: MapUser[];
  usersCount: number;
}

export interface MapInput {
  coordinates: number[][][];
  zoomLevel: number;
  clusterDensity?: string;
  includeWorkOrders?: boolean;
  includeAssets?: boolean;
  includeUsers?: boolean;
  dueDateStart?: number;
  dueDateEnd?: number;
  workCreatedDateStart?: number;
  workCreatedDateEnd?: number;
  statusIds?: string[];
  userIds?: string[];
  priorityIds?: number[];
  workTemplateIds?: string[];
  workCreatedByIds?: string[];
  workTemplateFolderIds?: string[];
  schemaIds?: string[];
  showAdmin?: boolean;
}

export interface InventoryMapData {
  clusters: MapCluster[];
  assets: MapAsset[];
}

export interface InventoryMapFilters {
  schemaId: string;
  categoryIds: string;
  hasPendingChangesOnly: boolean;
  assetFilters: { [key: string]: any[] };
}

// matches google.maps.drawing.OverlayType for convenience
export enum MapShapeType {
  POLYLINE = 'polyline',
  POLYGON = 'polygon',
  CIRCLE = 'circle',
  MARKER = 'marker',
}

export interface MapShape {
  type: MapShapeType;
}

export interface MapCenterAndZoomParams {
  center: string;
  zoom: number;
}

export interface KmlLayer {
  id: string;
  name: string;
  markerId: number;
  parser: 'GOOGLE_MAPS' | 'GEOXML3';
  url: string;
  pollingInterval?: number;
  suppressInfoWindows: boolean;
  extendedData: KmlExtendedData[];
}

export interface KmlExtendedData {
  type: 'TITLE' | 'SUBTITLE' | 'MARKER_ID' | 'ATTRIBUTE';
  key: string;
  name: string;
}

export interface MapEditMetadata {
  id: string;
  type: MapEditMetadataType;
  icon: React.ReactNode;
  name?: string;
  address?: string;
  location: GeoJSONPoint;
  markerId?: number;
  statusId?: string;
  canEdit?: boolean;
}

export interface LocationUpdatePayload {
  location: GeoJSONPoint;
  type?: MapEditMetadataType;
}

export interface LocationLinkOption {
  text: string;
  onClick: () => void;
}

export interface BoundingBox {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
}

export interface WorkOrderBoundingBoxInput {
  workOrderId: string;
  subjectType?: BoundingBoxSubjectType;
}

export interface TaskBoundingBoxInput {
  workOrderId: string;
  taskId: string;
  subjectType?: BoundingBoxSubjectType;
}

export interface MapParams {
  ids?: string[];
  disabledIds?: string[];
  hoverId?: string;
  isActive?: boolean;
  center?: string;
  zoom?: number;
  searchPoint?: string;
  searchTerm?: string;
}
