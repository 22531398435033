import React from 'react';

// @ts-ignore
import lockIconGray from '@atom/components/common/svgIcons/lockIconGray.svg';
import { Tooltip } from '@atom/mui';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './workTemplate.css';

interface Props {
  tooltip?: string;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
}

const InheritanceLockIcon = ({ tooltip, placement }: Props) => {
  return (
    <Tooltip
      lightTooltip
      title={tooltip}
      placement={placement}
      disableHoverListener={isNilOrEmpty(tooltip)}
    >
      <span>
        <img src={lockIconGray} />
      </span>
    </Tooltip>
  );
};

export default InheritanceLockIcon;
