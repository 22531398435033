import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function workOrdersInput(
  state = initialState.workValidations,
  action,
) {
  const { data, type } = action;
  switch (type) {
    case actionTypes.UPDATE_WORK_VALIDATIONS: {
      return data;
    }
    default: {
      return state;
    }
  }
}
