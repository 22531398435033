export enum Portal {
  INVENTORY = 'INVENTORY',
  TEAM = 'TEAM',
  WORK = 'WORK',
  ANALYTICS = 'ANALYTICS',
  SCHEDULE = 'SCHEDULE',
  MAP = 'MAP',
  DASHBOARD = 'DASHBOARD',
  BUDGETING = 'BUDGETING',
}

export interface PortalData {
  urlPath: string;
  image: any;
  description: string;
  dataCy: string;
  disabled?: boolean;
}
