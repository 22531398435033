import React from 'react';
import { makeStyles } from '@mui/styles';

import Dropzone from '@atom/components/common/dropzone/Dropzone';
import { useSchemas } from '@atom/hooks/useSchemas';
import { Icon, IconButton, RadioGroup, Select, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import { CsvErrorItem } from '@atom/types/error';
import { InventoryCategoryTree } from '@atom/types/inventory';
import { BULK_EDIT_ASSETS_TEMPLATE_ENDPOINT } from '@atom/utilities/endpoints';
import { buildEndpointWithParamsFromData } from '@atom/utilities/requestUtilities';
import schemaUtilities from '@atom/utilities/schemaUtilities';

import { InventoryUploadType, InventoryUploadView } from './InventoryUpload';
import InventoryUploadError from './InventoryUploadError';

import './inventoryUpload.css';

const { RadioButton } = RadioGroup;
const { MenuItem } = Select;

interface Props {
  type: InventoryUploadType;
  onTypeChange: (type: InventoryUploadType) => void;
  file?: File;
  onFileChange: (file?: File) => void;
  schemaId?: string;
  onSchemaChange: (schemaId: string) => void;
  category?: InventoryCategoryTree;
  onCategoryChange: (category?: InventoryCategoryTree) => void;
  onViewChange: (view: InventoryUploadView) => void;
  errors: CsvErrorItem[];
  validating: boolean;
}

const useStyles = makeStyles({
  root: {
    flexDirection: 'row',
  },
  label: {
    position: 'unset',
    color: `${colors.neutral.dim} !important`,
    paddingBottom: '0.5rem',
  },
});

const styles = {
  icon: {
    marginRight: '2rem',
    alignSelf: 'center',
  },
  downloadIcon: {
    alignSelf: 'center',
    marginLeft: '1.5rem',
    cursor: 'pointer',
  },
  category: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  link: {
    color: colors.brand.blue,
    cursor: 'pointer',
  },
};

// "Main" view for inventory upload modal content
const InventoryUploadMain = ({
  type,
  onTypeChange,
  file,
  onFileChange,
  schemaId,
  onSchemaChange,
  category,
  onCategoryChange,
  onViewChange,
  errors,
  validating,
}: Props) => {
  const classes = useStyles();

  const { schemas = [] } = useSchemas();

  const getTemplateDownloadEndpoint = () => {
    return buildEndpointWithParamsFromData(BULK_EDIT_ASSETS_TEMPLATE_ENDPOINT, {
      schemaId,
    });
  };

  return (
    <>
      <RadioGroup
        classes={{
          root: classes.root,
        }}
        value={type}
        onChange={event => onTypeChange(event.target.value)}
      >
        <RadioButton
          value={InventoryUploadType.CREATE}
          label={InventoryUploadType.CREATE}
        />
        <RadioButton
          value={InventoryUploadType.UPDATE}
          label={InventoryUploadType.UPDATE}
        />
      </RadioGroup>
      {type === InventoryUploadType.UPDATE && (
        <div styleName="update-inventory-text">
          File must ONLY include attributes that you wish to update and values
          that you wish to see in Inventory. Select an inventory type to
          download a template for upload.
        </div>
      )}
      <div styleName="dropzone-section">
        <Dropzone
          onFilesChange={files => onFileChange(files[0] || null)}
          files={file ? [file] : []}
          onFilesRemove={() => onFileChange(null)}
          accept=".csv"
          loading={validating}
          loadingText="Validating..."
        />
      </div>
      {type === InventoryUploadType.CREATE && (
        <div styleName="section">
          <Icon
            color={
              schemaId
                ? schemaUtilities.getSchemaColorFromColorId(category?.colorId)
                : colors.neutral.gray
            }
            style={styles.icon}
          >
            folder
          </Icon>
          {category ? (
            <div style={styles.category}>
              <div>{category.name}</div>
              <IconButton onClick={() => onCategoryChange(null)} size="small">
                <Icon>close</Icon>
              </IconButton>
            </div>
          ) : (
            <div
              style={{ ...styles.category, ...styles.link }}
              onClick={() => onViewChange(InventoryUploadView.INVENTORY_FOLDER)}
            >
              Choose Folder
            </div>
          )}
        </div>
      )}
      <div styleName="section">
        <div>
          <Icon style={styles.icon}>category</Icon>
        </div>
        <div style={schemaId ? { width: '75%' } : { width: '100%' }}>
          <Select
            label="Inventory Type"
            value={schemaId}
            onChange={event => onSchemaChange(event.target.value)}
            InputLabelProps={{ classes: { root: classes.label } }}
            disabled={!!category?.schemaId}
          >
            {schemas.map(schema => (
              <MenuItem key={schema.id} value={schema.id}>
                {schema.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        {schemaId && (
          <Tooltip title="download template">
            <div style={styles.downloadIcon}>
              <Icon onClick={() => window.open(getTemplateDownloadEndpoint())}>
                get_app
              </Icon>
            </div>
          </Tooltip>
        )}
      </div>
      {!!errors.length && (
        <div style={{ marginBottom: '1rem' }}>
          {errors.map((err, index) => (
            <InventoryUploadError key={index} error={err} />
          ))}
        </div>
      )}
    </>
  );
};

export default InventoryUploadMain;
