import React, { useState } from 'react';

import { Checkbox, Collapse, Icon, IconButton, Switch } from '@atom/mui';
import colors from '@atom/styles/colors';
import workOrderStatusUtilities from '@atom/utilities/workOrderStatusUtilities';

import MapDrawerLayerIcon from './MapDrawerLayerIcon';
import MapDrawerLayersWorkTemplateFilter from './MapDrawerLayersWorkTemplateFilter';
import MapDrawerWorkTemplateFolderFilter from './MapDrawerWorkTemplateFolderFilter';

import './mapDrawerLayers.css';

const ALL_WORK_STATUS_IDS = [
  workOrderStatusUtilities.REQUESTED_STATUS_ID,
  workOrderStatusUtilities.UNASSIGNED_STATUS_ID,
  workOrderStatusUtilities.ASSIGNED_STATUS_ID,
  workOrderStatusUtilities.IN_PROGRESS_STATUS_ID,
  workOrderStatusUtilities.COMPLETED_STATUS_ID,
  workOrderStatusUtilities.IN_REVIEW_STATUS_ID,
  workOrderStatusUtilities.CLOSED_STATUS_ID,
];

interface Props {
  onUpdate: (isInputChecked: boolean, workStatusIds: Set<any>) => any;
  onWorkTemplatesUpdate: (toggled: boolean, selected: Set<any>) => any;
  onWorkTemplateFoldersUpdate: (toggled: boolean, selected: Set<any>) => any;
  toggled: boolean;
  workStatusIds: any;
  workTemplateIds: any;
  workTemplateFolderIds?: any;
}

const MapDrawerLayersWorkSection = ({
  onUpdate,
  onWorkTemplatesUpdate,
  onWorkTemplateFoldersUpdate,
  toggled,
  workStatusIds,
  workTemplateIds,
  workTemplateFolderIds,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(true);

  const toggleWorkStatusLayer = workStatusId => {
    const worStatusIdsSet = new Set([...workStatusIds]);
    if (worStatusIdsSet.has(workStatusId)) {
      worStatusIdsSet.delete(workStatusId);
    } else {
      worStatusIdsSet.add(workStatusId);
    }

    onUpdate(toggled, worStatusIdsSet);
  };

  const selectAllWorkLayers = () => {
    onUpdate(toggled, new Set([...ALL_WORK_STATUS_IDS]));
  };

  const deselectAllWorkLayers = () => {
    onUpdate(toggled, new Set([]));
  };

  const allWorkLayersSelected = ALL_WORK_STATUS_IDS.every(status =>
    workStatusIds.has(status),
  );

  const noWorkLayersSelected = !workStatusIds.size;

  return (
    <div styleName="section-container">
      <div styleName="section-header-container">
        <Switch
          label={
            <div styleName="toggle-label">
              <div styleName="section-header-icon">
                <MapDrawerLayerIcon name="work_outlined" />
              </div>
              <div>Work</div>
            </div>
          }
          checked={toggled}
          onChange={() => {
            onUpdate(!toggled, workStatusIds);
          }}
        />
        <IconButton edge="end" onClick={() => setExpanded(!expanded)}>
          <Icon>{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
        </IconButton>
      </div>
      <div styleName="section-filters expanded">
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <div styleName="section-label">Status</div>
          <div styleName="select-all-container">
            <div
              onClick={() => toggled && selectAllWorkLayers()}
              styleName={`select-all-label ${
                !toggled || allWorkLayersSelected ? 'disabled' : ''
              }`}
            >
              Select All
            </div>
            <div styleName="select-all-divider" />
            <div
              onClick={() => toggled && deselectAllWorkLayers()}
              styleName={`select-all-label ${
                !toggled || noWorkLayersSelected ? 'disabled' : ''
              }`}
            >
              Clear All
            </div>
          </div>
          <div styleName="section-filter-row">
            <div styleName="section-filter-col">
              <Checkbox
                edge="start"
                checked={workStatusIds.has(
                  workOrderStatusUtilities.REQUESTED_STATUS_ID,
                )}
                onChange={() =>
                  toggleWorkStatusLayer(
                    workOrderStatusUtilities.REQUESTED_STATUS_ID,
                  )
                }
                disabled={!toggled}
                data-cy="dashboardWorkFilterRequested"
              />
              <MapDrawerLayerIcon name="work" color={colors.status.requested} />
              <span styleName="section-filter-row-text">Requested</span>
            </div>
            <div styleName="section-filter-col">
              <Checkbox
                edge="start"
                checked={workStatusIds.has(
                  workOrderStatusUtilities.UNASSIGNED_STATUS_ID,
                )}
                onChange={() =>
                  toggleWorkStatusLayer(
                    workOrderStatusUtilities.UNASSIGNED_STATUS_ID,
                  )
                }
                disabled={!toggled}
                data-cy="dashboardWorkFilterUnassigned"
              />
              <MapDrawerLayerIcon
                name="work"
                color={colors.status.unassigned}
              />
              <span styleName="section-filter-row-text">Unassigned</span>
            </div>
          </div>
          <div styleName="section-filter-row">
            <div styleName="section-filter-col">
              <Checkbox
                edge="start"
                checked={workStatusIds.has(
                  workOrderStatusUtilities.ASSIGNED_STATUS_ID,
                )}
                onChange={() =>
                  toggleWorkStatusLayer(
                    workOrderStatusUtilities.ASSIGNED_STATUS_ID,
                  )
                }
                disabled={!toggled}
                data-cy="dashboardWorkFilterAssigned"
              />
              <MapDrawerLayerIcon name="work" color={colors.status.assigned} />
              <span styleName="section-filter-row-text">Assigned</span>
            </div>
            <div styleName="section-filter-col">
              <Checkbox
                edge="start"
                checked={workStatusIds.has(
                  workOrderStatusUtilities.IN_PROGRESS_STATUS_ID,
                )}
                onChange={() =>
                  toggleWorkStatusLayer(
                    workOrderStatusUtilities.IN_PROGRESS_STATUS_ID,
                  )
                }
                disabled={!toggled}
                data-cy="dashboardWorkFilterInProgress"
              />
              <MapDrawerLayerIcon
                name="work"
                color={colors.status.inprogress}
              />
              <span styleName="section-filter-row-text">In Progress</span>
            </div>
          </div>
          <div styleName="section-filter-row">
            <div styleName="section-filter-col">
              <Checkbox
                edge="start"
                checked={workStatusIds.has(
                  workOrderStatusUtilities.COMPLETED_STATUS_ID,
                )}
                onChange={() =>
                  toggleWorkStatusLayer(
                    workOrderStatusUtilities.COMPLETED_STATUS_ID,
                  )
                }
                disabled={!toggled}
                data-cy="dashboardWorkFilterCompleted"
              />
              <MapDrawerLayerIcon name="work" color={colors.status.completed} />
              <span styleName="section-filter-row-text">Completed</span>
            </div>
            <div styleName="section-filter-col">
              <Checkbox
                edge="start"
                checked={workStatusIds.has(
                  workOrderStatusUtilities.IN_REVIEW_STATUS_ID,
                )}
                onChange={() =>
                  toggleWorkStatusLayer(
                    workOrderStatusUtilities.IN_REVIEW_STATUS_ID,
                  )
                }
                disabled={!toggled}
                data-cy="dashboardWorkFilterInReview"
              />
              <MapDrawerLayerIcon name="work" color={colors.status.inReview} />
              <span styleName="section-filter-row-text">In Review</span>
            </div>
          </div>
          <div styleName="section-filter-row">
            <Checkbox
              edge="start"
              checked={workStatusIds.has(
                workOrderStatusUtilities.CLOSED_STATUS_ID,
              )}
              onChange={() =>
                toggleWorkStatusLayer(workOrderStatusUtilities.CLOSED_STATUS_ID)
              }
              disabled={!toggled}
              data-cy="dashboardWorkFilterClosed"
            />
            <MapDrawerLayerIcon name="work" color={colors.status.closed} />
            <span styleName="section-filter-row-text">Closed</span>
          </div>
          <div styleName="filter-container">
            <MapDrawerWorkTemplateFolderFilter
              onWorkTemplateFoldersUpdate={selectedIds =>
                onWorkTemplateFoldersUpdate(toggled, selectedIds)
              }
              workTemplateFolderIds={workTemplateFolderIds}
              toggled={toggled}
            />
          </div>
          <div styleName="filter-container">
            <MapDrawerLayersWorkTemplateFilter
              onWorkTemplatesUpdate={selectedIds =>
                onWorkTemplatesUpdate(toggled, selectedIds)
              }
              workTemplateIds={workTemplateIds}
              toggled={toggled}
            />
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default MapDrawerLayersWorkSection;
