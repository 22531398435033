import React, { useContext } from 'react';

import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { RadioGroup, Switch } from '@atom/mui';
import {
  SchemaBudget,
  StartEndReadingOption,
  StockBasedOption,
} from '@atom/types/schema';

import './costDetail.css';

const { RadioButton } = RadioGroup;

interface Props {
  budget: SchemaBudget;
  setBudget: (budget: SchemaBudget) => void;
  updateBudget: (budget: SchemaBudget) => void;
}

const StockSection = ({ budget, setBudget, updateBudget }: Props) => {
  const { schemaTree } = useContext(SchemaDetailContext);

  const { stockBasedOption } = budget;

  const toggled =
    stockBasedOption === StockBasedOption.STOCK_BASED ||
    stockBasedOption === StockBasedOption.STOCK_BASED_OPTIONAL;

  const updateStockBasedOption = (option: StockBasedOption) => {
    const updatedBudget = {
      ...budget,
      stockBasedOption: option,
      startEndReadingOption: StartEndReadingOption.NONE,
    };

    setBudget(updatedBudget);
    updateBudget(updatedBudget);
  };

  const handleToggle = () => {
    const option =
      stockBasedOption === StockBasedOption.NONE
        ? StockBasedOption.STOCK_BASED
        : StockBasedOption.NONE;

    const updatedBudget = {
      ...budget,
      stockBasedOption: option,
      startEndReadingOption: StartEndReadingOption.NONE,
    };

    setBudget(updatedBudget);
    updateBudget(updatedBudget);
  };

  const isDisabled = schemaTree.isPublished;

  return (
    <div styleName="cost-container">
      <Switch
        checked={toggled}
        onChange={handleToggle}
        label="Stock-based Inventory"
        disabled={isDisabled}
      />
      {toggled && (
        <RadioGroup
          value={stockBasedOption}
          onChange={event => updateStockBasedOption(event.target.value)}
        >
          <RadioButton
            value={StockBasedOption.STOCK_BASED}
            label="Default"
            disabled={isDisabled}
          />
          <div styleName="option-subtext">
            Set an available inventory level and track usage for all inventory
            of this type.
          </div>
          <RadioButton
            value={StockBasedOption.STOCK_BASED_OPTIONAL}
            label="Optional"
            disabled={isDisabled}
          />
          <div styleName="option-subtext">
            Set an available inventory level and track usage at the individual
            inventory item level.
          </div>
        </RadioGroup>
      )}
    </div>
  );
};

export default StockSection;
