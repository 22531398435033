import * as R from 'ramda';

import api from '@atom/utilities/api';
import { WORK_ORDERS_ENDPOINT } from '@atom/utilities/endpoints';
import { TOTAL_COUNT_HEADER } from '@atom/utilities/requestUtilities';

import actionTypes from './actionTypes';

export const requestCreateWorkOrder = data => ({
  type: actionTypes.REQUEST_CREATE_WORK_ORDER,
  data,
});

export const createWorkOrderSuccess = data => ({
  type: actionTypes.CREATE_WORK_ORDER_SUCCESS,
  data,
});

export const createWorkOrderFailure = () => ({
  type: actionTypes.CREATE_WORK_ORDER_FAILURE,
});

export const requestDeleteWorkOrder = data => ({
  type: actionTypes.REQUEST_DELETE_WORK_ORDER,
  data,
});

export const deleteWorkOrderSuccess = data => ({
  type: actionTypes.DELETE_WORK_ORDER_SUCCESS,
  data,
});

export const deleteWorkOrderFailure = () => ({
  type: actionTypes.DELETE_WORK_ORDER_FAILURE,
});

export const requestPatchWorkOrder = data => ({
  type: actionTypes.REQUEST_PATCH_WORK_ORDER,
  data,
});

export const patchWorkOrderSuccess = data => ({
  type: actionTypes.PATCH_WORK_ORDER_SUCCESS,
  data,
});

export const patchWorkOrderFailure = () => ({
  type: actionTypes.PATCH_WORK_ORDER_FAILURE,
});

export const retrieveWorkOrder = data => ({
  type: actionTypes.REQUEST_WORK_ORDER_DETAIL,
  data,
});

export const getWorkOrderSuccess = data => ({
  type: actionTypes.GET_WORK_ORDER_DETAIL_SUCCESS,
  data,
});

export const getWorkOrderFailure = () => ({
  type: actionTypes.GET_WORK_ORDER_DETAIL_FAILURE,
});

export const clearWorkOrderLoading = () => ({
  type: actionTypes.CLEAR_WORK_ORDER_LOADING_STATE,
});

/* Work Orders */
export const getWorkOrdersSuccess = data => ({
  type: actionTypes.GET_WORK_ORDERS_SUCCESS,
  data,
});

export const getWorkOrdersFailure = () => ({
  type: actionTypes.GET_WORK_ORDERS_FAILURE,
});

export const getWorkOrdersTotalSuccess = workOrdersTotal => ({
  type: actionTypes.GET_WORK_TOTAL_SUCCESS,
  workOrdersTotal,
});

export const getWorkOrdersTotalFailure = () => ({
  type: actionTypes.GET_WORK_TOTAL_FAILURE,
});

export const requestWorkOrders = () => ({
  type: actionTypes.REQUEST_WORK_ORDERS,
});

export const requestWorkOrderReportCreation = data => ({
  type: actionTypes.REQUEST_WORK_ORDER_REPORT_CREATION,
  data,
});

export const workOrderReportCreationSuccess = () => ({
  type: actionTypes.WORK_ORDER_CREATION_REPORT_SUCCESS,
});

export const workOrderReportCreationFailure = () => ({
  type: actionTypes.WORK_ORDER_CREATION_REPORT_FAILURE,
});

export const requestWorkOrderHistorySnapshotCreation = data => ({
  type: actionTypes.REQUEST_WORK_ORDER_HISTORY_SNAPSHOT_CREATION,
  data,
});

export const workOrderHistorySnapshotSuccess = () => ({
  type: actionTypes.WORK_ORDER_HISTORY_SNAPSHOT_SUCCESS,
});

export const workOrderHistorySnapshotFailure = () => ({
  type: actionTypes.WORK_ORDER_HISTORY_SNAPSHOT_FAILURE,
});

export const requestWorkStatus = data => ({
  type: actionTypes.REQUEST_WORK_STATUS,
  data,
});

export const workStatusSuccess = data => ({
  type: actionTypes.WORK_STATUS_SUCCESS,
  data,
});

export const workStatusFailure = () => ({
  type: actionTypes.WORK_STATUS_FAILURE,
});

export const requestBulkWorkCreation = data => ({
  type: actionTypes.REQUEST_BULK_WORK_CREATION,
  data,
});

export const bulkWorkCreationSuccess = () => ({
  type: actionTypes.BULK_WORK_CREATION_SUCCESS,
});

export const bulkWorkCreationFailure = () => ({
  type: actionTypes.BULK_WORK_CREATION_FAILURE,
});

export const requestBulkWorkFromFilterCreation = data => ({
  type: actionTypes.REQUEST_BULK_WORK_CREATION_FROM_FILTER,
  data,
});

export const bulkWorkCreationFromFilterSuccess = () => ({
  type: actionTypes.BULK_WORK_CREATION_FROM_FILTER_SUCCESS,
});

export const bulkWorkCreationFromFilterFailure = () => ({
  type: actionTypes.BULK_WORK_CREATION_FROM_FILTER_FAILURE,
});

// TODO: [ATOM-1891]: Refactor function signature
export function getWorkOrders(page, limit, filters, sortBy, isAscending) {
  const {
    completionDateStart,
    completionDateEnd,
    dueDateStart,
    dueDateEnd,
    categoryIdsFilters,
    workTemplateIds,
    statusIds,
    priorityIds,
    userIds,
    assetId,
  } = filters;

  return async function (dispatch) {
    dispatch(requestWorkOrders());

    // @ts-ignore
    const params = {
      ...(page ? { page } : {}),
      ...(limit ? { limit } : {}),
      ...(!R.isEmpty(completionDateStart) ? { completionDateStart } : {}),
      ...(!R.isEmpty(completionDateEnd) ? { completionDateEnd } : {}),
      ...(dueDateStart ? { dueDateStart } : {}),
      ...(dueDateEnd ? { dueDateEnd } : {}),
      ...(categoryIdsFilters && categoryIdsFilters.length > 0
        ? {
            categoryIds: categoryIdsFilters.join(','),
          }
        : {}),
      ...(statusIds && statusIds.length > 0
        ? { statusIds: statusIds.join(',') }
        : {}),
      ...(workTemplateIds && workTemplateIds.length > 0
        ? { workTemplateIds: workTemplateIds.join(',') }
        : {}),
      ...(priorityIds && priorityIds.length > 0
        ? { priorityIds: priorityIds.join(',') }
        : {}),
      ...(userIds && userIds.length > 0 ? { userIds: userIds.join(',') } : {}),
      ...(sortBy ? { sortBy } : {}),
      ...(assetId ? { assetId } : {}),
      ...(isAscending ? { isAscending } : {}),
    };

    try {
      const response = await api.get(WORK_ORDERS_ENDPOINT, params);
      const listOfWorkOrders = response.data;

      dispatch(getWorkOrdersSuccess(listOfWorkOrders));

      const totalWork = Number(response.headers[TOTAL_COUNT_HEADER]);
      if (isNaN(totalWork)) {
        dispatch(getWorkOrdersTotalFailure());
      } else {
        dispatch(
          getWorkOrdersTotalSuccess(
            Number(response.headers[TOTAL_COUNT_HEADER]),
          ),
        );
      }
    } catch (error) {
      dispatch(getWorkOrdersFailure());
      throw error;
    }
  };
}
