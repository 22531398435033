import * as React from 'react';
import { useQueryParam } from 'use-query-params';

import { InventoryAssetDetailType } from '@atom/types/inventory';
import { getGoogleShapeCenter } from '@atom/utilities/mapUtilities';
import schemaUtilities from '@atom/utilities/schemaUtilities';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';
import timeUtilities from '@atom/utilities/timeUtilities';

import './drawerContent.css';

interface Props {
  asset: InventoryAssetDetailType;
  navigateToAsset: (id: string) => void;
}

const AssetListItem = ({ asset, navigateToAsset }: Props) => {
  const centerLocation = getGoogleShapeCenter(asset.location);
  const icon = schemaUtilities.getSchemaIconFromSchemaOrAsset(asset);
  const [, setId] = useQueryParam('hover');

  const onMouseEnter = () => {
    setId(asset.id);
  };

  const onMouseLeave = () => {
    setId(null);
  };
  return (
    <div
      styleName="asset-row-container"
      onClick={() => navigateToAsset(asset.id)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div styleName="asset-list-legend-container">{icon}</div>
      <div>
        <div styleName="asset-name-container">{asset.name}</div>
        <div styleName="asset-content-container">{`Lat & Long: ${textDisplayUtilities.latLong(
          // @ts-ignore
          centerLocation.lat,
        )}, ${textDisplayUtilities.latLong(
          // @ts-ignore
          centerLocation.lng,
        )}`}</div>
        <div styleName="asset-content-container">
          {`Last Updated: ${timeUtilities.setDisplayDate(asset.updatedDate)}`}
        </div>
      </div>
    </div>
  );
};

export default AssetListItem;
