import React, { useState } from 'react';

import { DatePicker, DateTimePicker } from '@atom/mui';
import { DataType } from '@atom/types/dataType';
import { WorkOrderField } from '@atom/types/work';

const styles = {
  date: {
    flex: 0.27,
  },
  dateTime: {
    flex: 0.47,
  },
};

interface Props {
  field: WorkOrderField;
  onChange: (fieldId: string, value: any) => void;
  isDisabled?: boolean;
}

const WorkOrderDatePicker = ({ field, onChange, isDisabled }: Props) => {
  const [value, setValue] = useState<Date>(
    field.value ? new Date(field.value) : null,
  );

  const handleChange = (date?: Date) => {
    setValue(date);
    // date was removed entirely
    if (!date) {
      onChange(field.id, '');
    } else if (!Number.isNaN(date.valueOf())) {
      onChange(field.id, date.valueOf());
    }
  };

  return field.dataType === DataType.DATE ? (
    <DatePicker
      label={field.title}
      value={value}
      disabled={isDisabled}
      onChange={handleChange}
      style={styles.date}
    />
  ) : (
    <DateTimePicker
      label={field.title}
      value={value}
      disabled={isDisabled}
      onChange={handleChange}
      style={styles.dateTime}
    />
  );
};

export default WorkOrderDatePicker;
