import React, { useState } from 'react';
import {
  DateTimePicker as KeyboardDateTimePicker,
  DateTimePickerProps as KeyboardDateTimePickerProps,
} from '@mui/lab';
import { makeStyles } from '@mui/styles';

import { TextField, TextFieldProps as TextFieldPropsType } from '@atom/mui';
import colors from '@atom/styles/colors';

type CustomProps = {
  /**
   * style to be applied to the input container
   */
  style?: React.CSSProperties;
  /**
   * if input should be full width of container
   * defaults to true
   */
  fullWidth?: boolean;
  /**
   * Fix label to "floating" position (focused)
   * defaults to true
   */
  floatingLabelFixed?: boolean;
  /**
   * Optional placeholder label
   */
  placeholder?: string;
  /**
   * Denotes that the field is required
   */
  required?: boolean;
  /**
   * date format (defaults to "MM/dd/yyyy hh:mm a")
   */
  format?: string;
  /**
   * Optional props to pass to the internal TextField component
   */
  TextFieldProps?: TextFieldPropsType;
  /**
   * Provide an error message when error passed down to TextField.
   */
  errorHelperText?: string;
};

type DateTimePickerProps = CustomProps &
  Omit<KeyboardDateTimePickerProps, 'renderInput'>;

const useStyles = makeStyles(theme => ({
  inputIcon: {
    '& button': {
      color: colors.neutral.gray,
    },
  },
  inputIconHidden: {
    '& button': {
      display: 'none',
    },
  },
}));

const defaultStyle: React.CSSProperties = {
  width: '100%',
};

export const DateTimePicker = (props: DateTimePickerProps) => {
  const classes = useStyles(props);

  const [hovered, setHovered] = useState<boolean>(false);

  const {
    style,
    placeholder = 'mm/dd/yyyy hh:mm am/pm',
    required,
    fullWidth = true,
    floatingLabelFixed = true,
    format = 'MM/dd/yyyy hh:mm a',
    TextFieldProps,
    errorHelperText,
    ...data
  } = props;

  return (
    <div style={{ ...defaultStyle, ...style }}>
      <KeyboardDateTimePicker
        {...data}
        fullWidth={fullWidth}
        label={props.label}
        inputFormat={format}
        renderInput={textFieldProps => (
          <TextField
            {...textFieldProps}
            {...TextFieldProps}
            floatingLabelFixed={floatingLabelFixed}
            required={required}
            error={textFieldProps?.error || TextFieldProps?.error}
            helperText={
              (textFieldProps?.error || TextFieldProps?.error) &&
              errorHelperText
            }
            inputProps={{ ...textFieldProps?.inputProps, placeholder }}
            InputProps={{
              ...textFieldProps?.InputProps,
              ...TextFieldProps?.InputProps,
              onMouseEnter: () => setHovered(true),
              onMouseLeave: () => setHovered(false),
            }}
          />
        )}
        InputAdornmentProps={{
          ...props.InputAdornmentProps,
          classes: {
            root:
              hovered && !props.disabled
                ? classes.inputIcon
                : classes.inputIconHidden,
            ...props.InputAdornmentProps?.classes,
          },
        }}
      />
    </div>
  );
};

export default DateTimePicker;
