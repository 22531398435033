import React from 'react';
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';

import { Icon } from '@atom/mui';

import colors from '../../styles/colors';

import DrawerContentList from './drawerContent/ContentList';
import AssetsListWrapper from './drawerContentWrappers/AssetsListWrapper';
import AssetSummaryWrapper from './drawerContentWrappers/AssetSummaryWrapper';
import KmlPlacemarkWrapper from './drawerContentWrappers/KmlPlacemarkWrapper';
import MapDrawerLayersWrapper from './drawerContentWrappers/MapDrawerLayersWrapper';
import UserSummaryWrapper from './drawerContentWrappers/UserSummaryWrapper';
import WorkOrderGroupListWrapper from './drawerContentWrappers/WorkOrderGroupListWrapper';
import WorkSummaryWrapper from './drawerContentWrappers/WorkSummaryWrapper';

import './mapDrawerContent.css';

const MapDrawerContent = () => {
  const [queryParams, setQueryParams] = useQueryParams({
    view: StringParam,
    collapsed: BooleanParam,
  });

  const layerMap = {
    layers: MapDrawerLayersWrapper,
    workOrder: WorkSummaryWrapper,
    user: UserSummaryWrapper,
    asset: AssetSummaryWrapper,
    list: DrawerContentList,
    assets: AssetsListWrapper,
    workOrders: WorkOrderGroupListWrapper,
    kml: KmlPlacemarkWrapper,
  };

  const ActiveComponent = layerMap[queryParams.view] || 'div';

  return (
    <div styleName="container">
      <div styleName="content">
        {!queryParams.collapsed && <ActiveComponent />}
      </div>
      <div
        styleName="drawer-arrow"
        onClick={() => {
          setQueryParams({
            collapsed: !queryParams.collapsed,
            view: queryParams.view,
          });
        }}
      >
        <Icon
          styleName={`${queryParams.collapsed ? 'collapsed' : 'expanded'} `}
          color={colors.neutral.gray}
        >
          arrow_drop_down
        </Icon>
      </div>
    </div>
  );
};

export default MapDrawerContent;
