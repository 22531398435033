import React from 'react';

import { DashboardCard } from '@atom/types/dashboard';

import { getCardHeight } from '../dashboardUtilities';

import '../dashboardCard.css';

interface Props {
  card: DashboardCard;
}

const CardEmpty = ({ card }: Props) => {
  const isPendingUsers = card.type === 'PENDING_USERS';
  const style = {
    height: getCardHeight(card),
    fontSize: isPendingUsers ? '2.5rem' : 'inherit',
  };
  return (
    <div style={style} styleName="card-body-empty">
      {isPendingUsers ? '0' : 'No Results'}
    </div>
  );
};

export default CardEmpty;
