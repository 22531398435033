import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';

import { requestDownloadFiles } from '@atom/actions/fileActions';
import CarouselWrapper from '@atom/components/common/photoCarousel/CarouselWrapper';
import WorkTemplateContext, {
  WorkTemplateActionTypes,
} from '@atom/components/workTemplate/WorkTemplateContext';
import textConstants from '@atom/constants/textConstants';
import {
  GET_MEDIA,
  MEDIA_BULK_DELETE,
  MEDIA_DELETE,
  MEDIA_UPDATE,
} from '@atom/graph/media';
import { WORK_TEMPLATE_UPDATE } from '@atom/graph/workTemplate';
import { useFileUpload } from '@atom/hooks/useFileUpload';
import { useMediaSearch } from '@atom/hooks/useMediaSearch';
import { Snackbar } from '@atom/mui';
import {
  MediaBulkDeleteInput,
  MediaItem,
  MediaType,
  MediaUpdateInput,
  SubjectType,
} from '@atom/types/media';
import {
  InheritedComponentType,
  WorkOrderTemplateUpdateInput,
  WorkTemplate,
  WorkTemplateMedia,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import { isComponentInherited } from '@atom/utilities/workTemplateUtilities';

import PhotosHeader from './PhotosHeader';
import PhotosPagination from './PhotosPagination';
import PhotoTile from './PhotoTile';

import './workTemplatePhotos.css';

const PAGE_LIMIT = 12;
const CHARACTER_MIN = 3;

const WorkTemplatePhotos = () => {
  const reduxDispatch = useDispatch();
  const [uploadFiles] = useFileUpload();
  const { workTemplate, dispatch } = useContext(WorkTemplateContext);

  const [media, setMedia] = useState<MediaItem[]>([]);
  const [mediaTotal, setMediaTotal] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set([]));

  const onCompleted = data => {
    setMedia(data?.media?.media);
    setMediaTotal(data?.media?.totalCount);
  };

  const isInherited = isComponentInherited(
    InheritedComponentType.MEDIA,
    workTemplate?.inheritedComponents || [],
  );

  const parentSubjectIds = isInherited
    ? [workTemplate.id, workTemplate.parentId]
    : [workTemplate.id];

  const { refetch, loading: loadingMedia } = useQuery(GET_MEDIA, {
    variables: {
      input: {
        parentSubjectIds,
        type: MediaType.IMAGE,
        page,
        limit: PAGE_LIMIT,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted,
  });

  const [mediaSearch, { loadingSearch }] = useMediaSearch({
    query: searchValue,
    parentSubjectIds,
    type: MediaType.IMAGE,
    limit: PAGE_LIMIT,
    characterMin: CHARACTER_MIN,
  });

  useEffect(() => {
    if (searchValue.length >= CHARACTER_MIN) {
      setMedia(mediaSearch.media);
      setMediaTotal(mediaSearch.totalCount);
    }
  }, [mediaSearch.media, mediaSearch.totalCount, searchValue]);

  const [workTemplateUpdate] = useMutation<
    { workOrderTemplateUpdate: WorkTemplate },
    { input: WorkOrderTemplateUpdateInput }
  >(WORK_TEMPLATE_UPDATE);

  const [mediaUpdate, { loading: loadingUpdate }] = useMutation<
    { mediaUpdate: MediaItem },
    { input: MediaUpdateInput }
  >(MEDIA_UPDATE);

  const [mediaDelete, { loading: loadingDelete }] = useMutation<
    { mediaDelete: boolean },
    { id: string }
  >(MEDIA_DELETE);

  const [mediaBulkDelete, { loading: loadingBulkDelete }] = useMutation<
    { mediaBulkDelete: boolean },
    { input: MediaBulkDeleteInput }
  >(MEDIA_BULK_DELETE);

  const updateWorkTemplateMedia = async (updatedMedia: WorkTemplateMedia[]) => {
    const { data } = await workTemplateUpdate({
      variables: {
        input: {
          workOrderTemplateId: workTemplate.id,
          media: updatedMedia,
        },
      },
    });

    dispatch({
      type: WorkTemplateActionTypes.UPDATE_WORK_TEMPLATE_PROPERTY,
      data: {
        property: 'media',
        value: data?.workOrderTemplateUpdate?.media,
      },
    });
  };

  const resetToFirstPage = () => {
    if (page === 1) {
      refetch({
        input: {
          parentSubjectIds,
          type: MediaType.IMAGE,
          page,
          limit: PAGE_LIMIT,
        },
      });
    } else {
      setPage(1);
    }
  };

  const handleUpload = async event => {
    const { files } = event.target;

    if (!files.length) {
      return;
    }

    const uploadedMedia = await uploadFiles({
      files,
      subjectId: workTemplate.id,
      parentSubjectId: workTemplate.id,
      subjectType: SubjectType.WORK_ORDER_TEMPLATE,
    });

    await updateWorkTemplateMedia([
      ...workTemplate?.media.map(item => R.omit(['__typename'], item)),
      ...uploadedMedia.map(medium => ({
        fileId: medium.fileId,
        name: medium.name,
        type: medium.type,
        fileExtension: medium.fileExtension,
        mediaId: medium.id,
      })),
    ]);

    refetch();
  };

  const renameMedia = async (id: string, subjectId: string, name: string) => {
    const res = await mediaUpdate({
      variables: {
        input: {
          id,
          name,
        },
      },
    });

    const updatedMedia = media.map((mediaItem: MediaItem) => {
      return mediaItem.id === id ? res?.data?.mediaUpdate : mediaItem;
    });

    const otherMedia = workTemplate?.media
      .filter(item => item.type !== MediaType.IMAGE)
      .map(item => R.omit(['__typename'], item));

    await updateWorkTemplateMedia([
      ...otherMedia,
      ...updatedMedia.map(medium => ({
        fileId: medium.fileId,
        name: medium.name,
        type: medium.type,
        fileExtension: medium.fileExtension,
        mediaId: medium.id,
      })),
    ]);

    setMedia(updatedMedia);
  };

  const deleteMedia = async (id: string) => {
    try {
      Snackbar.info({
        message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
      });

      await mediaDelete({
        variables: {
          id,
        },
      });

      Snackbar.info({
        message: 'Successfully deleted file',
      });

      await updateWorkTemplateMedia(
        workTemplate.media
          .filter(item => item.mediaId !== id)
          .map(item => R.omit(['__typename'], item)),
      );

      setSelectedItems(new Set([]));
      resetToFirstPage();
    } catch (err) {
      Snackbar.error({
        message: 'Failed to delete file',
      });
    }
  };

  const downloadMultipleFiles = async () => {
    const selectedMedia = media.filter((medium: any) =>
      selectedItems.has(medium.id),
    );

    reduxDispatch(requestDownloadFiles(selectedMedia));
  };

  const deleteMultipleFiles = async () => {
    await mediaBulkDelete({
      variables: {
        input: {
          ids: [...selectedItems],
        },
      },
    });

    await updateWorkTemplateMedia(
      workTemplate.media
        .filter(item => !selectedItems.has(item.mediaId))
        .map(item => R.omit(['__typename'], item)),
    );

    setSelectedItems(new Set([]));
    resetToFirstPage();
  };

  const incrementPage = (increment: number) => {
    setPage(page + increment);
    setSelectedItems(new Set([]));
  };

  const onChange = (value: string) => {
    setSearchValue(value);
  };

  const clearSearch = async () => {
    setSearchValue('');
    setSelectedItems(new Set([]));
    resetToFirstPage();
  };

  const toggleCheckbox = (id: string) => {
    setSelectedItems(toggleFromSet(selectedItems, id));
  };

  const selectAll = () => {
    const mediaIds = media.map(medium => medium.id);
    setSelectedItems(new Set(mediaIds));
  };

  const isEditDisabled =
    workTemplate?.published || doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  const loading =
    loadingMedia ||
    loadingUpdate ||
    loadingDelete ||
    loadingBulkDelete ||
    loadingSearch;

  return (
    <div styleName="body-container">
      <div styleName="center-pane">
        <PhotosHeader
          media={media}
          selectLimit={R.length(media)}
          searchValue={searchValue}
          clearSearch={clearSearch}
          onChange={event => onChange(event.target.value)}
          uploadFiles={handleUpload}
          downloadMultipleFiles={downloadMultipleFiles}
          deleteMultipleFiles={deleteMultipleFiles}
          selectAll={selectAll}
          deselectAll={() => setSelectedItems(new Set([]))}
          selectedItems={[...selectedItems]}
          loading={loading}
          isEditDisabled={isEditDisabled}
          isInherited={isInherited}
        />
        <div styleName="photo-block">
          <div styleName="photo-tiles-container">
            {media.map((medium: MediaItem) => {
              const isChecked = selectedItems.has(medium.id);

              const isItemInherited = isComponentInherited(
                InheritedComponentType.MEDIA,
                workTemplate?.inheritedComponents || [],
                medium?.fileId,
              );

              return (
                <CarouselWrapper
                  key={medium.id}
                  media={media}
                  renameMedia={renameMedia}
                  selectedMediaId={medium.id}
                  removeFile={deleteMedia}
                  subjectId={medium.subjectId}
                  toggleCheckbox={toggleCheckbox}
                  isChecked={isChecked}
                  canMarkAsCoverPhoto={!isEditDisabled && !isInherited}
                  canUpdateMedia={!isEditDisabled && !isInherited}
                  canDeleteMedia={!isEditDisabled && !isInherited}
                >
                  <PhotoTile
                    media={medium}
                    renameMedia={renameMedia}
                    deleteMedia={deleteMedia}
                    loadingDelete={loadingDelete}
                    isEditDisabled={isEditDisabled}
                    isItemInherited={isItemInherited}
                  />
                </CarouselWrapper>
              );
            })}
          </div>
        </div>
        {!!media.length && (
          <PhotosPagination
            incrementPage={incrementPage}
            page={page}
            limit={PAGE_LIMIT}
            total={mediaTotal}
          />
        )}
      </div>
    </div>
  );
};

export default WorkTemplatePhotos;
