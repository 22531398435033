import React, { useCallback, useContext, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

import client from '@atom/graph/client';
import { GET_USERS } from '@atom/graph/user';
import { Autocomplete, Avatar, Chip, List, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import { GrantType, POLICY_GRANT_IDS_ALL } from '@atom/types/policy';
import {
  UserDetail,
  UsersConnection,
  UsersConnectionInput,
} from '@atom/types/user';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { getUserFullName } from '@atom/utilities/userUtilities';

import PolicyModalContext from './PolicyModalContext';

const { ListItemText } = List;

const DEBOUNCE_TIME = 300;
const MIN_CHAR = 3;
const ALL_USERS = [
  {
    id: POLICY_GRANT_IDS_ALL,
    name: 'all',
  },
];

type DebouncedUserSearch = (input: UsersConnectionInput) => void;

const styles = {
  label: {
    position: 'unset',
    color: `${colors.neutral.dim} !important`,
  },
  chip: {
    marginTop: '5px',
    marginRight: '5px',
    backgroundColor: colors.neutral.ash,
    borderRadius: '20px',
  },
};

const PolicyUserSelection = () => {
  const { state, updateState } = useContext(PolicyModalContext);
  const { grants } = state;

  const [query, setQuery] = useState<string>('');
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [loadingPreselected, setLoadingPreselected] = useState<boolean>(false);
  const [userSearch, setUserSearch] = useState<UserDetail[]>([]);
  const [selected, setSelected] = useState<UserDetail[]>([]);

  const searchUsers = async (
    // eslint-disable-next-line @typescript-eslint/no-shadow
    input: UsersConnectionInput,
    isPreselected?: boolean,
  ) => {
    if (isPreselected) {
      setLoadingPreselected(true);
    } else {
      setLoadingSearch(true);
    }

    const { data } = await client.query<{ users: UsersConnection }>({
      query: GET_USERS,
      variables: {
        input: {
          ...input,
          showAdmin: hasRolePermissions(ROLE_SETS.ADMIN),
        },
      },
    });

    if (isPreselected) {
      setLoadingPreselected(false);
      setSelected(data?.users?.users || []);
    } else {
      setUserSearch(data?.users?.users || []);
      setLoadingSearch(false);
    }
  };

  const searchUsersDebounced = useCallback<DebouncedUserSearch>(
    debounce(searchUsers, DEBOUNCE_TIME),
    [],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const isAllUsers =
      grants.find(grant => grant.id === POLICY_GRANT_IDS_ALL) || !grants.length;

    if (!isAllUsers) {
      searchUsers({ ids: [...grants.map(grant => grant.id)] }, true);
    }

    if (!grants.length) {
      return updateState({
        grantType: GrantType.USER,
        grants: ALL_USERS,
      });
    }
  }, [grants]);

  useEffect(() => {
    if (query.length >= MIN_CHAR) {
      searchUsersDebounced({ name: query });
    } else {
      setUserSearch([]);
    }
  }, [query]);

  const handleChange = (values: UserDetail[]) => {
    setSelected(values);

    const updatedGrants = values.length
      ? values.map(value => ({
          id: value.id,
          name: getUserFullName(value),
        }))
      : ALL_USERS;

    return updateState({
      grantType: GrantType.USER,
      grants: updatedGrants,
    });
  };

  const handleDeselect = (id: string) => {
    const updatedGrants = grants.filter(grant => grant.id !== id) || ALL_USERS;

    setSelected(curr => curr.filter(user => user.id !== id));

    return updateState({
      grantType: GrantType.USER,
      grants: updatedGrants,
    });
  };

  return (
    <Autocomplete<UserDetail, true, false>
      multiple
      label="User"
      options={userSearch}
      loading={loadingSearch}
      disabled={loadingPreselected}
      inputValue={query}
      onInputChange={(event, value) => setQuery(value || '')}
      getOptionLabel={user => getUserFullName(user)}
      value={selected}
      onChange={(event, values) => {
        handleChange(values);
      }}
      getOptionDisabled={user => grants.some(({ id }) => id === user.id)}
      renderOption={(optionProps, user) => (
        <li key={user.id} {...optionProps}>
          <Avatar
            src={user.photoUrl}
            alt={user.firstName}
            style={{ marginRight: '0.5rem' }}
          />
          <ListItemText
            primary={getUserFullName(user)}
            secondary={user.email}
          />
        </li>
      )}
      renderTags={values => {
        return values.map(user => (
          <Chip
            key={user.id}
            style={styles.chip}
            avatar={
              <Avatar
                src={user.photoUrl}
                alt={user.firstName}
                style={{ marginRight: '0.5rem' }}
              />
            }
            label={getUserFullName(user)}
            onDelete={() => handleDeselect(user.id)}
          />
        ));
      }}
      endAdornment={loadingPreselected && <Progress size={20} />}
      labelStyle={styles.label}
    />
  );
};

export default PolicyUserSelection;
