import React from 'react';

import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';

interface Props {
  name: string;
  color?: string;
}

const MapDrawerLayerIcon = ({ name, color }: Props) => {
  const iconStyles = {
    fontSize: 24,
    color: color ? color : colors.neutral.gray,
  };

  return <Icon style={iconStyles}>{name}</Icon>;
};

export default MapDrawerLayerIcon;
