import React, { useCallback, useContext } from 'react';
import debounce from 'lodash.debounce';

import WorkOrderDatePicker from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderDatePicker';
import WorkOrderEnumMultipleField from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderEnumMultipleField';
import WorkOrderEnumSingleField from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderEnumSingleField';
import WorkOrderNumericField from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderNumericField';
import WorkOrderSummary from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderSummary';
import WorkOrderTextField from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderTextField';
import { DataType } from '@atom/types/dataType';
import { WorkOrderField } from '@atom/types/work';
import {
  doesNotHaveRolePermissions,
  hasRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BulkContext from '../../../BulkContext';

import './workInfo.css';

const DEBOUNCE_TIME = 1000;

const CustomFields = () => {
  const { workOrder, setWorkOrder } = useContext(BulkContext);
  const { fieldOrder, fields } = workOrder;

  const updateFields = (newFields: WorkOrderField[]) => {
    const newWorkOrder = {
      ...workOrder,
      fields: [...newFields],
    };

    setWorkOrder(newWorkOrder);
  };

  const handleChange = (fieldId: string, value: any) => {
    const updatedFields = fields.map((field: WorkOrderField) =>
      field.id === fieldId ? { ...field, value } : field,
    );
    updateFields(updatedFields);
  };

  const handleChangeDebounced = useCallback(
    debounce(handleChange, DEBOUNCE_TIME),
    [handleChange],
  );

  const showButton = hasRolePermissions(ROLE_SETS.INSPECTOR);

  const getContent = (field: WorkOrderField) => {
    const isFieldEditDisabled = doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

    const props = {
      field,
      onChange: handleChange,
      isDisabled: isFieldEditDisabled,
      onBlur: handleChange,
    };

    const hasSubFields = !isNilOrEmpty(field.subFields);

    const components = {
      [DataType.SUMMARY]: <WorkOrderSummary {...props} />,
      [DataType.SHORT_TEXT]: (
        <WorkOrderTextField {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.LONG_TEXT]: (
        <WorkOrderTextField {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.DATE]: (
        <WorkOrderDatePicker {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.DATE_TIME]: (
        <WorkOrderDatePicker {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.ENUM_SINGLE]: hasSubFields ? (
        <div styleName="enum-single-nested-container">
          <WorkOrderEnumSingleField
            {...props}
            onChange={handleChangeDebounced}
          />
        </div>
      ) : (
        <WorkOrderEnumSingleField {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.ENUM_MULTIPLE]: <WorkOrderEnumMultipleField {...props} />,
      [DataType.NUMBER]: (
        <WorkOrderNumericField {...props} onChange={handleChangeDebounced} />
      ),
    };

    return <div styleName="field-container">{components[field.dataType]}</div>;
  };

  const hideSection = !showButton && isNilOrEmpty(fields);
  const containerStyle = hideSection
    ? 'section-content hide'
    : 'section-content';

  return (
    <>
      <div styleName="section-header">Custom Fields Section</div>
      <div styleName={containerStyle}>
        {fieldOrder?.length === 0 && (
          <div styleName="empty-list">There are no custom fields.</div>
        )}
        {fieldOrder?.map((fieldId: string) => {
          const field = fields.find(item => item.id === fieldId);
          return getContent(field);
        })}
      </div>
    </>
  );
};

export default CustomFields;
