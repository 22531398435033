import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import TextField from '@atom/components/common/TextField';
import TeamContext from '@atom/components/teamPortal/TeamContext';
import { CREATE_USER_GROUP, UPDATE_USER_GROUP } from '@atom/graph/userGroup';
import { Button, Icon, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import {
  UserGroup,
  UserGroupCreateInput,
  UserGroupUpdateInput,
} from '@atom/types/userGroups';
import { colorSwatch } from '@atom/utilities/colorUtilities';

import './teamControls.css';

const styles = {
  dialog: {
    bodyStyle: {
      margin: 0,
      padding: 0,
    },
    contentStyle: {
      paddingRight: '2rem',
      paddingBottom: '1rem',
    },
    title: {
      fontSize: fonts.xl,
      padding: '1.125em',
      fontWeight: '500',
    },
    actionsContainerStyle: {
      paddingRight: '1rem',
      paddingBottom: '1rem',
    },
  },
  actionButtons: {
    cancel: { marginRight: '1rem' },
  },
  floatingLabelStyle: {
    color: colors.neutral.dim,
  },
  textFieldStyle: {
    color: colors.neutral.dark,
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
  groupId: string;
  isEdit?: boolean;
  groupColorId?: number;
  groupName?: string;
}

const CreateEditUserGroupModal = ({
  open,
  onClose,
  isEdit,
  groupId,
  groupColorId,
  groupName,
}: Props) => {
  const { refetchTree, refetchTable, activePath, setActivePath } = useContext(
    TeamContext,
  );
  const [name, setName] = useState<string>(groupName || '');
  const [colorId, setColorId] = useState<number>(groupColorId || 0);
  const [errorText, setErrorText] = useState<string>('');

  useEffect(() => {
    if (open) {
      setName(groupName || '');
      setColorId(groupColorId || 0);
    }
  }, [open, groupName, groupColorId]);

  const [createUserGroup, { error: createUserGroupError }] = useMutation<
    { userGroupCreate: UserGroup },
    { input: UserGroupCreateInput }
  >(CREATE_USER_GROUP, {
    onCompleted: () => {
      refetchTree();
      refetchTable();
    },
  });

  useEffect(() => {
    if (createUserGroupError) {
      // @ts-ignore
      const statusCode = createUserGroupError.networkError?.statusCode;

      if (statusCode === 409) {
        setErrorText('This name is already in use.');
      }
    }
  }, [createUserGroupError]);

  const [updateUserGroup, { error: updateUserGroupError }] = useMutation<
    { userGroupUpdate: UserGroup },
    { input: UserGroupUpdateInput }
  >(UPDATE_USER_GROUP, {
    onCompleted: data => {
      setActivePath(
        activePath.map(pathItem =>
          pathItem.id === groupId ? data?.userGroupUpdate : pathItem,
        ),
      );

      refetchTree();
      refetchTable();
    },
  });

  useEffect(() => {
    if (updateUserGroupError) {
      // @ts-ignore
      const statusCode = updateUserGroupError.networkError?.statusCode;

      if (statusCode === 409) {
        setErrorText('This name is already in use.');
      }
    }
  }, [updateUserGroupError]);

  const closeModal = () => {
    onClose();
    setName('');
    setColorId(0);
    setErrorText('');
  };

  const confirm = async () => {
    if (isEdit) {
      await updateUserGroup({
        variables: {
          input: {
            id: groupId,
            ...(name !== groupName && { name }),
            colorId,
          },
        },
      });
    } else {
      await createUserGroup({
        variables: {
          input: {
            parentGroupId: groupId,
            name,
            colorId,
          },
        },
      });
    }

    closeModal();
  };

  const renderColorSwatches = () => {
    return colorSwatch.map((swatch, index) => (
      <div
        key={index}
        styleName={index === colorId ? 'color-swatch selected' : 'color-swatch'}
        onClick={() => setColorId(index)}
      >
        <Icon style={{ fontSize: 28 }} color={swatch}>
          lens
        </Icon>
      </div>
    ));
  };

  const getFooter = () => {
    return (
      <div style={styles.dialog.actionsContainerStyle}>
        <Button style={styles.actionButtons.cancel} onClick={closeModal}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={confirm}
          disabled={R.isEmpty(name)}
        >
          {isEdit ? 'Save' : 'Create'}
        </Button>
      </div>
    );
  };

  return (
    <Modal
      title={isEdit ? 'Edit Group' : 'Create Group'}
      footer={getFooter()}
      contentStyle={styles.dialog.contentStyle}
      style={styles.dialog.bodyStyle}
      open={open}
    >
      <div styleName="modal-content">
        <TextField
          fullWidth
          floatingLabelFixed
          floatingLabelText="Name"
          style={styles.textFieldStyle}
          floatingLabelStyle={styles.floatingLabelStyle}
          value={name}
          name="groupName"
          onChange={event => setName(event.target.value)}
          errorText={errorText}
        />
        <div styleName="color-title">Color:</div>
        <div styleName="color-container">{renderColorSwatches()}</div>
      </div>
    </Modal>
  );
};

export default CreateEditUserGroupModal;
