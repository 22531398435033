import React, { CSSProperties, useState } from 'react';
import * as R from 'ramda';

import WorkTemplateTree from '@atom/components/common/workTemplateTree/WorkTemplateTree';
import { Button, Modal } from '@atom/mui';
import { WorkOrderTemplateFolder } from '@atom/types/workTemplate';

import './../assetWorkOrdersFilterPanel.css';

const styles: Record<any, CSSProperties> = {
  button: {
    minWidth: 0,
    padding: 0,
  },
  modal: {
    height: '50vh',
    padding: '2rem',
  },
};

interface Props {
  schemaId: string;
  areFiltersDisabled: boolean;
  workTemplateFolderFilter: WorkOrderTemplateFolder[];
  updateWorkTemplateFolderFilter: (
    templateFolders: WorkOrderTemplateFolder[],
  ) => void;
}

const TemplateFolderFilter = ({
  schemaId,
  areFiltersDisabled,
  workTemplateFolderFilter,
  updateWorkTemplateFolderFilter,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedFolders, setSelectedFolders] = useState<
    WorkOrderTemplateFolder[]
  >(workTemplateFolderFilter);

  const selectedFoldersIds = new Set(selectedFolders.map(folder => folder.id));

  const handleToggleFolder = (toggleFolder: WorkOrderTemplateFolder) => {
    const isSelected = selectedFoldersIds.has(toggleFolder.id);
    let updatedSelected: WorkOrderTemplateFolder[];
    if (isSelected) {
      updatedSelected = selectedFolders.filter(
        folder => folder.id !== toggleFolder.id,
      );
    } else {
      updatedSelected = [...selectedFolders, toggleFolder];
    }
    setSelectedFolders(updatedSelected);
  };

  const alignLocalToParentState = () => {
    setSelectedFolders(workTemplateFolderFilter);
  };

  const onOpenModal = () => {
    setIsModalOpen(true);
    alignLocalToParentState();
  };

  const onCancelModal = () => {
    alignLocalToParentState();
    setIsModalOpen(false);
  };

  const onConfirmModal = () => {
    updateWorkTemplateFolderFilter(selectedFolders);
    setIsModalOpen(false);
  };

  const onClearModal = () => {
    setSelectedFolders([]);
  };

  const onClearFilter = () => {
    updateWorkTemplateFolderFilter([]);
  };

  return (
    <>
      <div styleName="filter-container">
        <div styleName="filter-label">Work Template Folder</div>
        <div>
          {workTemplateFolderFilter.map(folder => folder.name).join(', ')}
        </div>
        <div styleName="filter-button-pair">
          <Button
            size="small"
            onClick={onOpenModal}
            style={styles.button}
            disabled={areFiltersDisabled}
          >
            select
          </Button>
          {!R.isEmpty(workTemplateFolderFilter) && (
            <Button
              size="small"
              onClick={onClearFilter}
              style={styles.button}
              disabled={areFiltersDisabled}
            >
              clear
            </Button>
          )}
        </div>
      </div>
      <Modal
        open={isModalOpen}
        title={'Select Work Template Folder'}
        onCancel={onCancelModal}
        onConfirm={onConfirmModal}
        onClear={onClearModal}
        width="lg"
        contentStyle={styles.modal}
      >
        <WorkTemplateTree
          autoSelectChildFolders
          hideTemplates
          includeSearch={false}
          schemaId={schemaId}
          hasSchema={false}
          onFolderToggle={handleToggleFolder}
          getFolderSelected={folder => selectedFoldersIds.has(folder.id)}
        />
      </Modal>
    </>
  );
};

export default TemplateFolderFilter;
