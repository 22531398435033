import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import * as R from 'ramda';

import { defaultClusterDensitySelector } from '@atom/selectors/mapSelectors';
import { InventoryMapData, InventoryMapFilters } from '@atom/types/map';
import api from '@atom/utilities/api';
import { formatAssetAttributeFilters } from '@atom/utilities/assetUtilities';
import { MAP_ENDPOINT } from '@atom/utilities/endpoints';

interface Params {
  coordinates: string;
  zoomLevel: number;
}

type Props = Params & InventoryMapFilters;

const isNilOrEmpty = (value: any): boolean =>
  R.or(R.isNil(value), R.isEmpty(value));

export const useInventoryMapData = ({
  coordinates,
  zoomLevel,
  categoryIds,
  schemaId,
  hasPendingChangesOnly,
  assetFilters,
}: Props): [InventoryMapData, boolean] => {
  const url = `${MAP_ENDPOINT}/assets-filter`;

  const clusterDensity = useSelector(defaultClusterDensitySelector);

  const [mapData, setMapData] = useState<InventoryMapData>({
    clusters: [],
    assets: [],
  });

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getInventoryMapData = async () => {
      try {
        setLoading(true);
        const { data } = await api.post(
          url,
          formatAssetAttributeFilters(assetFilters),
          R.reject(isNilOrEmpty, {
            coordinates,
            zoomLevel,
            categoryIds,
            schemaId,
            hasPendingChangesOnly,
            clusterDensity,
          }),
          source.token,
        );
        setMapData(data);
        setLoading(false);
      } catch (error) {
        if (!axios.isCancel(error)) {
          throw error;
        }
      }
    };

    if (coordinates) {
      getInventoryMapData();
    }

    return source.cancel;
  }, [
    coordinates,
    zoomLevel,
    categoryIds,
    schemaId,
    hasPendingChangesOnly,
    assetFilters,
    clusterDensity,
    url,
  ]);

  return [mapData, loading];
};
