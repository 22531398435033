import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { retrieveLoggedInUser } from '@atom/actions/userActions';
import { usePreferences } from '@atom/hooks/usePreferences';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { Progress } from '@atom/mui';
import { getPortals } from '@atom/utilities/portalUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import TopNavigationBar from './common/navigation/TopNavigationBar';

interface Props {
  component: any;
  path?: string;
  getKey?: (props: any) => string;
  isTopNavigationDisabled?: boolean;
  exact?: boolean;
}

const AuthorizedRoute = ({
  component: Component,
  path,
  getKey,
  isTopNavigationDisabled,
  exact = false,
}: Props) => {
  const userProfile = useUserProfile();
  const preferences = usePreferences();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveLoggedInUser());
  }, []);

  const pathname = location.pathname;
  const isLandingPage = path === '/';

  const isBudgetingPage = path.includes('/budget');

  const getContent = props => {
    // Retrieve the current user if they are not currently logged in
    // At this point the user is verified, so if they dont exist they will be created
    if (userProfile.isVerified && !userProfile.userId) {
      dispatch(retrieveLoggedInUser());
    }

    // defining a key ensures that component will be remounted on params change
    const key = getKey ? { key: getKey(props) } : {};

    if (isLandingPage && !isNilOrEmpty(preferences)) {
      return (
        <Redirect
          to={getPortals(preferences)[preferences.landingPortal].urlPath}
        />
      );
    }

    if (isBudgetingPage && !isNilOrEmpty(preferences)) {
      if (isNilOrEmpty(preferences.budgeting)) {
        return (
          <Redirect
            to={getPortals(preferences)[preferences.landingPortal].urlPath}
          />
        );
      }
    }

    if (userProfile.isVerified && userProfile.userId) {
      return (
        <div style={{ height: '100%' }}>
          {!isTopNavigationDisabled && (
            <>
              <TopNavigationBar pathname={pathname} />
            </>
          )}
          <Component {...props} {...key} />
        </div>
      );
    }

    return <Progress size={80} thickness={3} style={{ height: '100%' }} />;
  };

  return (
    <Route exact={exact} path={path} render={props => getContent(props)} />
  );
};

export default AuthorizedRoute;
