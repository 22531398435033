import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import AssignedRolesTable from '@atom/components/common/assignedRoles/AssignedRolesTable';
import AssignRolesModal from '@atom/components/common/assignedRoles/AssignRolesModal';
import { UPDATE_USER_GROUP } from '@atom/graph/userGroup';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { UserGroup, UserGroupUpdateInput } from '@atom/types/userGroups';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import UserGroupContext from '../UserGroupContext';

import './userGroupRoles.css';

const UserGroupRoles = () => {
  const {
    userGroup: { id, roles },
    refetch,
  } = useContext(UserGroupContext);

  const [addRolesModalOpen, setAddRolesModalOpen] = useState<boolean>(false);

  const [updateUserGroup, { loading }] = useMutation<
    { userGroupUpdate: UserGroup },
    { input: UserGroupUpdateInput }
  >(UPDATE_USER_GROUP);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleAssignRoles = async (roles: string[]) => {
    await updateUserGroup({
      variables: {
        input: {
          id,
          roles,
        },
      },
    });

    setAddRolesModalOpen(false);
    refetch();
  };

  const handleDelete = (roleId: string) => {
    handleAssignRoles(
      roles.filter(role => role.id !== roleId).map(role => role.id),
    );
  };

  return (
    <>
      <div styleName="header-container">
        <div>{!roles.length ? 'No roles' : `${roles.length} roles`}</div>
        {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
          <IconButton onClick={() => setAddRolesModalOpen(true)}>
            <Icon>add</Icon>
          </IconButton>
        )}
      </div>
      {!roles.length ? (
        <div styleName="no-roles-container">
          <Icon color={colors.neutral.silver} style={{ fontSize: '80px' }}>
            account_circle
          </Icon>
          <div styleName="no-roles">There are no roles</div>
        </div>
      ) : (
        <AssignedRolesTable roles={roles} onDelete={handleDelete} />
      )}
      <AssignRolesModal
        open={addRolesModalOpen}
        onClose={() => setAddRolesModalOpen(false)}
        onConfirm={handleAssignRoles}
        loading={loading}
        existingRoles={new Set(roles.map(role => role.id))}
      />
    </>
  );
};

export default UserGroupRoles;
