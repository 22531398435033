import React, { useEffect, useState } from 'react';

import { Icon, IconButton, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import { PolicyAction } from '@atom/types/policy';
import { WorkTemplateFolderTree } from '@atom/types/workTemplate';
import api from '@atom/utilities/api';
import { WORK_TEMPLATES_FOLDER_TREE_ENDPOINT } from '@atom/utilities/endpoints';

import WorkTypeFolderTreeRow from './WorkTypeFolderTreeRow';

import './workTypeFolderTree.css';

const styles = {
  arrowStyle: {
    fontSize: '24px',
    margin: 0,
    color: colors.neutral.dim,
  },
  indentation: {
    paddingLeft: '2rem',
  },
  iconStyle: {
    fontSize: '24px',
    margin: 0,
    color: colors.neutral.gray,
  },
};

interface Props {
  setWorkTemplateFolder: any;
  setActiveView: () => void;
}

const WorkTypeFolderTree = ({
  setWorkTemplateFolder,
  setActiveView,
}: Props) => {
  const [open, setOpen] = useState<Set<string>>(new Set(['workTypes']));
  const [loadingTree, setLoadingTree] = useState<boolean>(true);
  const [workTemplateFolderTree, setWorkTemplateFolderTree] = useState<
    WorkTemplateFolderTree
    // @ts-ignore
  >({});

  useEffect(() => {
    const getWorkTemplateFolderTree = async () => {
      setLoadingTree(true);

      const { data } = await api.get(
        `${WORK_TEMPLATES_FOLDER_TREE_ENDPOINT}?action=${PolicyAction.READ}`,
      );

      setWorkTemplateFolderTree(data);
      setLoadingTree(false);
    };

    getWorkTemplateFolderTree();
  }, []);

  const toggleOpen = (id: string) => {
    if (open.has(id)) {
      open.delete(id);
      setOpen(new Set([...open]));
    } else {
      setOpen(new Set([...open.add(id)]));
    }
  };

  const selectWorkTemplateFolder = (folder: WorkTemplateFolderTree) => {
    setWorkTemplateFolder(folder);
    setActiveView();
  };

  const expanded = open.has(workTemplateFolderTree.id);
  const arrowDirection = expanded ? 'arrow_drop_down' : 'arrow_right';

  return (
    <div styleName="tree-modal-container">
      {loadingTree ? (
        <Progress />
      ) : (
        <>
          <div
            styleName="folder-row"
            onClick={() => toggleOpen(workTemplateFolderTree.id)}
          >
            <div styleName="folder-name-container">
              <IconButton>
                <Icon style={styles.arrowStyle}>{arrowDirection}</Icon>
              </IconButton>
              <Icon style={styles.iconStyle}>folder</Icon>
              <div styleName="row-name-container">
                <div styleName="row-title">{workTemplateFolderTree.name}</div>
              </div>
            </div>
          </div>
          {expanded ? (
            <>
              {workTemplateFolderTree.children.map(
                (child: WorkTemplateFolderTree) => (
                  <WorkTypeFolderTreeRow
                    key={child.id}
                    folder={child}
                    indentation={0}
                    open={open}
                    toggleOpen={toggleOpen}
                    selectWorkTemplateFolder={selectWorkTemplateFolder}
                  />
                ),
              )}
            </>
          ) : (
            <div />
          )}
        </>
      )}
    </div>
  );
};

export default WorkTypeFolderTree;
