import * as React from 'react';
import { useEffect, useRef } from 'react';
import * as R from 'ramda';

import { Tooltip } from '@atom/mui';
import { InventoryAssetDetailType } from '@atom/types/inventory';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import timeUtilities from '@atom/utilities/timeUtilities';

import './header.css';

interface Props {
  inventoryAssetDetail: InventoryAssetDetailType;
}

const HeaderFields = ({ inventoryAssetDetail }: Props) => {
  const [overflowing, setOverflowing] = React.useState<boolean>(false);
  const container = useRef<HTMLDivElement>();

  useEffect(() => {
    if (container.current) {
      const { offsetWidth, scrollWidth } = container.current;
      setOverflowing(offsetWidth < scrollWidth);
    }
  }, []);

  const inventoryAssetName = inventoryAssetDetail.name || '-';
  const assetType = inventoryAssetDetail.assetType || '-';
  const address = inventoryAssetDetail.address || '-';
  const breadcrumbs =
    // @ts-ignore
    inventoryAssetDetail?.categories || [];

  const cost = (
    <span styleName="cost-text">{`${numberToLocaleString(
      R.pathOr('', ['budget', 'rate'], inventoryAssetDetail),
    )}/${R.pathOr('', ['budget', 'unit'], inventoryAssetDetail)}`}</span>
  );

  const headerItemText = inventoryAssetDetail.isMaterial
    ? 'Cost:'
    : 'Location:';
  const headerItemContent = inventoryAssetDetail.isMaterial ? cost : address;

  const breadcrumbString = breadcrumbs.map(folder => folder.name).join(' > ');

  return (
    <div styleName="info-boxes-wrapper">
      <div styleName="info-box label">
        <div>Inventory Name:</div>
        <div>Last Updated:</div>
      </div>
      <div styleName="info-box value">
        <div>{inventoryAssetName}</div>
        <div>
          {timeUtilities.getUtcDisplayDate(inventoryAssetDetail.updatedDate)}
        </div>
      </div>
      <div styleName="info-box label">
        <div>Type:</div>
        <div>{headerItemText}</div>
      </div>
      <div styleName="info-box value">
        <div>{assetType}</div>
        <div>{headerItemContent}</div>
      </div>
      <div styleName="breadcrumbs-label">Inventory Folder:</div>
      <div styleName="info-box breadcrumbs" ref={container}>
        {!overflowing ? (
          breadcrumbString
        ) : (
          <Tooltip title={breadcrumbString}>
            <span>{breadcrumbString}</span>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default HeaderFields;
