import * as React from 'react';

// @ts-ignore
import newWorkOrderIconBlue from '@atom/components/common/svgIcons/newWorkOrderIconBlue.svg';
// @ts-ignore
import newWorkOrderIconGray from '@atom/components/common/svgIcons/newWorkOrderIconGray.svg';
// @ts-ignore
import newWorkOrderIconSilver from '@atom/components/common/svgIcons/newWorkOrderIconSilver.svg';
// @ts-ignore
import newWorkOrderIconWhite from '@atom/components/common/svgIcons/newWorkOrderIconWhite.svg';
import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';

const COLOR_ICON_MAP = {
  [colors.neutral.gray]: newWorkOrderIconGray,
  [colors.neutral.silver]: newWorkOrderIconSilver,
  [colors.brand.blue]: newWorkOrderIconBlue,
};

interface Props {
  color?: string;
}

const NewWorkOrderIcon = ({ color }: Props) => {
  const src = COLOR_ICON_MAP[color] || newWorkOrderIconWhite;
  return (
    <Icon>
      <img src={src} />
    </Icon>
  );
};

export default NewWorkOrderIcon;
