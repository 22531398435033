import React, { useContext, useMemo } from 'react';
import * as R from 'ramda';

// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import WorkTypeFolderTile from '@atom/components/common/workTemplateFolderTree/WorkTypeFolderTile';
import { useSchemas } from '@atom/hooks/useSchemas';
import { Icon, Select, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventorySchemaItem } from '@atom/types/inventory';
import { TYPE_OPTIONS, WorkOrderType } from '@atom/types/work';
import { WorkTemplateFolderTree } from '@atom/types/workTemplate';
import schemaUtilities from '@atom/utilities/schemaUtilities';

import WorkTemplateContext from '../../WorkTemplateContext';
import WorkTemplateTypeOption from '../workTemplateOption/WorkTemplateTypeOption';

import './workTemplateChildCreate.css';

const { MenuItem } = Select;

const EMPTY_SCHEMA = 'empty';

const styles = {
  textFieldStyle: {
    marginLeft: '1rem',
    color: colors.neutral.dark,
  },
  icon: {
    marginTop: '1rem',
  },
  back: {
    marginRight: '1rem',
  },
};

enum View {
  MAIN = 'MAIN',
  WORK_TYPE_FOLDER = 'WORK_TYPE_FOLDER',
  INHERITANCE = 'INHERITANCE',
}

interface Props {
  name: string;
  setName: (name: string) => void;
  error: string;
  workTemplateFolder: WorkTemplateFolderTree;
  setWorkTemplateFolder: (workTemplateFolder: WorkTemplateFolderTree) => void;
  schemaId: string;
  setView: (view: View) => void;
  setError: (error: string) => void;
}

const ChildCreateMain = ({
  name,
  setName,
  error,
  workTemplateFolder,
  setWorkTemplateFolder,
  schemaId,
  setView,
  setError,
}: Props) => {
  const { schemas = [] } = useSchemas();
  const { workTemplate } = useContext(WorkTemplateContext);

  const locationBasedSchemas = useMemo(() => {
    return schemas.filter(schema => !schema.isMaterial);
  }, [schemas]);

  const handleNameChange = event => {
    setName(event.target.value);
    setError('');
  };

  return (
    <div styleName="create-container">
      <div styleName="section-header">Details</div>
      <div styleName="modal-row">
        <img style={styles.icon} src={renameIcon} />
        <TextField
          id="name"
          label="Name *"
          value={name}
          onChange={handleNameChange}
          style={styles.textFieldStyle}
          error={!!error}
          helperText={error}
        />
      </div>
      <div styleName="modal-row folder">
        <Icon color={colors.neutral.gray}>folder</Icon>
        {!R.isNil(workTemplateFolder) ? (
          <div styleName="tile-container">
            <WorkTypeFolderTile
              workTemplateFolder={workTemplateFolder}
              removeWorkTemplateFolder={() => setWorkTemplateFolder(null)}
            />
          </div>
        ) : (
          <div
            styleName="choose-button"
            onClick={() => setView(View.WORK_TYPE_FOLDER)}
          >
            Choose Folder *
          </div>
        )}
      </div>
      <div styleName="section-header">
        <div>Template Type</div>
        <div styleName="section-subtext">
          Child Work Template will inherit the type from the parent.
        </div>
      </div>
      <div styleName="type-container">
        <WorkTemplateTypeOption option={TYPE_OPTIONS[workTemplate?.type]} />
      </div>
      {workTemplate?.type === WorkOrderType.DEFAULT && (
        <div styleName="modal-row">
          <Icon color={colors.neutral.gray} style={styles.icon}>
            category
          </Icon>
          <Select
            value={schemaId}
            style={styles.textFieldStyle}
            onChange={() => {}}
            label="Asset Type"
            disabled
            fullWidth
          >
            <MenuItem key="empty" value={EMPTY_SCHEMA}>
              <div styleName="schema-menu-item-container">
                <div styleName="empty-icon">-</div>
                <div styleName="schema-menu-item-title empty">
                  All Asset Types
                </div>
              </div>
            </MenuItem>
            {locationBasedSchemas.map((schema: InventorySchemaItem) => {
              const icon = schemaUtilities.getSchemaIconFromSchemaOrAsset(
                schema,
              );

              return (
                <MenuItem key={schema.id} value={schema.id}>
                  <div styleName="schema-menu-item-container">
                    {icon}
                    <div styleName="schema-menu-item-title">{schema.name}</div>
                  </div>
                </MenuItem>
              );
            })}
          </Select>
        </div>
      )}
    </div>
  );
};

export default ChildCreateMain;
