import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import debounce from 'lodash.debounce';

import TaskContext from '@atom/components/workOrderPreview/taskView/TaskContext';
import WorkOrderPreviewContext from '@atom/components/workOrderPreview/WorkOrderPreviewContext';
import { TASK_UPDATE } from '@atom/graph/task';
import { DatePicker } from '@atom/mui';
import { Task, TaskUpdateInput } from '@atom/types/task';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { convertDateToMillisGMTMidday } from '@atom/utilities/timeUtilities';

import './taskView.css';

const DEBOUNCE_TIME = 1000;

const TaskCompletedTime = () => {
  const { workOrderDetail, refetch } = useContext(WorkOrderPreviewContext);
  const { task } = useContext(TaskContext);
  const [completionDate, setCompletionDate] = useState<Date>(
    task.completionDate ? new Date(task.completionDate) : null,
  );

  const [taskUpdate] = useMutation<
    { taskUpdate: Task },
    { input: TaskUpdateInput }
  >(TASK_UPDATE);

  useEffect(() => {
    setCompletionDate(
      task.completionDate && task.isCompleted
        ? new Date(task.completionDate)
        : null,
    );
  }, [task.completionDate, task.isCompleted]);

  const handleSubmit = async (date: Date) => {
    await taskUpdate({
      variables: {
        input: {
          id: task.id,
          workOrderId: workOrderDetail.id,
          completionDate: convertDateToMillisGMTMidday(date),
        },
      },
    });

    refetch();
  };

  const debouncedHandleSubmit = useCallback(
    debounce(handleSubmit, DEBOUNCE_TIME),
    [task.id, workOrderDetail.id],
  );

  const onChange = (date?: Date) => {
    setCompletionDate(date);

    if (!date) {
      return;
    } else if (!Number.isNaN(date.valueOf())) {
      debouncedHandleSubmit(date);
    }
  };

  const onBlur = _ => {
    if (!completionDate) {
      setCompletionDate(
        task.completionDate && task.isCompleted
          ? new Date(task.completionDate)
          : null,
      );
    }
  };

  const isDisabled =
    !task.isCompleted ||
    workOrderDetail.isClosed ||
    doesNotHaveRolePermissions(ROLE_SETS.MANAGER);

  return (
    <div styleName="time-row-item">
      <div styleName="label">Completed Date</div>
      <div styleName="time-picker-container">
        <DatePicker
          value={completionDate}
          onChange={onChange}
          format="MM/dd/yyyy"
          disabled={isDisabled}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
};

export default TaskCompletedTime;
