import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
import TeamErrorModal from '@atom/components/common/teamErrorModal/TeamErrorModal';
import UserContext from '@atom/components/userDetail/UserContext';
import { DELETE_USER_BUDGET } from '@atom/graph/user';
import { Progress } from '@atom/mui';
import { UserBudgetDeleteInput, UserDetail } from '@atom/types/user';

import './budgetsTab.css';

export interface Props {
  open: boolean;
  onClose: () => void;
  budgetId: string;
}

const DeleteBudgetModal = ({ open, onClose, budgetId }: Props) => {
  const { user } = useContext(UserContext);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);

  const [deleteBudget, { loading, error }] = useMutation<
    { userBudgetDelete: UserDetail },
    { input: UserBudgetDeleteInput }
  >(DELETE_USER_BUDGET);

  const confirm = () => {
    deleteBudget({
      variables: {
        input: {
          id: budgetId,
          userId: user.id,
        },
      },
    });

    onClose();
  };

  useEffect(() => {
    if (error) {
      // @ts-ignore
      const statusCode = error.networkError?.statusCode;

      if (statusCode === 422) {
        setErrorOpen(true);
      }
    }
  }, [error]);

  return (
    <>
      <DeleteModal
        title="Delete Budget?"
        onConfirm={confirm}
        onCancel={onClose}
        open={open}
        content={
          loading ? (
            <Progress />
          ) : (
            <div styleName="modal-container">
              Are you sure you want to remove this budget?
            </div>
          )
        }
      />
      <TeamErrorModal
        open={errorOpen}
        onClose={() => setErrorOpen(false)}
        type="budget"
      />
    </>
  );
};

export default DeleteBudgetModal;
