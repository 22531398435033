import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';

import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { ATTRIBUTE_UPDATE } from '@atom/graph/schema';
import { Icon, Select } from '@atom/mui';
import colors from '@atom/styles/colors';
import { DataType } from '@atom/types/dataType';
import {
  AttributeUpdateInput,
  SchemaTree,
  SchemaTreeAttribute,
} from '@atom/types/schema';

import { updatePendingCreation } from '../subItemDetail/subItemDetailUtilities';

import {
  DATA_TYPE_OPTIONS,
  getDataTypeChangePayload,
} from './attributeDetailUtilities';
import DefaultValueSection from './DefaultValueSection';
import EnumBuilder from './EnumBuilder';
import UnitInput from './UnitInput';

import './attributeDetail.css';

const { MenuItem } = Select;

const styles = {
  select: {
    paddingBottom: '0.5rem',
  },
};

const TypeSettings = () => {
  const {
    schemaTree,
    selectedAttribute,
    setSelectedAttribute,
    selectedSubItem,
    selectedAttributeRoute,
    refetchSchemaTree,
    setPendingCreations,
  } = useContext(SchemaDetailContext);

  const [updateAttribute] = useMutation<
    { attributeUpdate: SchemaTree },
    { input: AttributeUpdateInput }
  >(ATTRIBUTE_UPDATE);

  // If attribute is a temp attribute, values must be updated in pendingCreations state
  const handlePendingCreationUpdate = (
    payload: Partial<SchemaTreeAttribute>,
  ) => {
    const updatedAttribute = { ...selectedAttribute, ...payload };

    setPendingCreations(prev =>
      updatePendingCreation(
        selectedSubItem.id,
        selectedAttributeRoute,
        prev,
        updatedAttribute,
      ),
    );

    setSelectedAttribute(updatedAttribute);
  };

  const handleAttributeUpdate = async (payload: object) => {
    await updateAttribute({
      variables: {
        input: {
          schemaId: selectedSubItem.id,
          attributeGroupId: selectedAttributeRoute.attributeGroupId,
          attributeId: selectedAttribute.id,
          ...payload,
        },
      },
    });

    refetchSchemaTree();
  };

  const onDataTypeChange = async event => {
    const newDataType = event.target.value;
    const payload = getDataTypeChangePayload(newDataType);

    if (selectedAttribute.isTempAttribute) {
      handlePendingCreationUpdate(payload);
    } else {
      setSelectedAttribute(prev => ({ ...prev, ...payload }));
      await handleAttributeUpdate(payload);
    }
  };

  const isDisabled =
    schemaTree?.isPublished && !selectedAttribute.isTempAttribute;
  const showUnitInput = selectedAttribute.dataType === DataType.NUMBER;
  const showEnumBuilder =
    selectedAttribute.dataType === DataType.ENUM_SINGLE ||
    selectedAttribute.dataType === DataType.ENUM_MULTIPLE;

  return (
    <>
      <Select
        fullWidth
        label="Attribute Type"
        onChange={onDataTypeChange}
        value={selectedAttribute.dataType}
        disabled={isDisabled}
        style={styles.select}
      >
        {DATA_TYPE_OPTIONS.map(option => {
          return (
            <MenuItem key={option.dataType} value={option.dataType}>
              <div styleName="data-type-option-container">
                <Icon color={colors.neutral.gray}>{option.icon}</Icon>
                <div styleName="data-type-option-title">{option.title}</div>
              </div>
            </MenuItem>
          );
        })}
      </Select>
      <div styleName="data-type-builder-container">
        {showUnitInput && <UnitInput />}
        {showEnumBuilder && <EnumBuilder />}
      </div>
      <DefaultValueSection />
    </>
  );
};

export default TypeSettings;
