import querystring from 'querystring';

export const TOTAL_COUNT_HEADER = 'x-atom-total';
export const ATOM_USER_HEADER = 'x-atom-user';
export const ATOM_DEVICE_HEADER = 'x-atom-device';

export const DEFAULT_HEADERS = {
  'content-type': 'application/json',
  accept: 'application/json',
  'x-atom-device': 'desktop',
};

export const DEFAULT_OPTIONS = {
  headers: DEFAULT_HEADERS,
};

export const buildEndpointWithParamsFromData = (baseEndpoint, data) => {
  const params = querystring.stringify(data);

  return params ? `${baseEndpoint}?${params}` : baseEndpoint;
};
