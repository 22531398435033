import React, { useContext } from 'react';

import { DatePicker } from '@atom/mui';
import colors from '@atom/styles/colors';

import BulkContext from '../../../BulkContext';

export interface Props {
  disabled?: boolean;
  label: string;
  name: string;
  taskIndex: number;
}

const styles = {
  label: {
    position: 'unset',
    color: colors.neutral.dim,
  },
};

const TaskDateField = ({ disabled = false, label, name, taskIndex }: Props) => {
  const { workOrder, setWorkOrder } = useContext(BulkContext);
  const { tasks } = workOrder;

  const handleChange = (date?: Date) => {
    const value = date && !Number.isNaN(date.valueOf()) ? date.valueOf() : null;
    const newTasks = [...tasks];
    newTasks[taskIndex][name] = value;

    const newWorkOrder = {
      ...workOrder,
      tasks: newTasks,
    };

    setWorkOrder(newWorkOrder);
  };

  return (
    <DatePicker
      disabled={disabled}
      label={label}
      labelStyle={styles.label}
      onChange={handleChange}
      format="MM/dd/yyyy"
      value={tasks[taskIndex][name] || null}
    />
  );
};

export default TaskDateField;
