import React from 'react';

import { List } from '@atom/mui';
import { ApplicationSearchWorkOrdersState } from '@atom/types/store';
import history from '@atom/utilities/history';
import iconUtilities from '@atom/utilities/iconUtilities';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';

import './searchBar.css';

const { ListItemButton, ListItemText } = List;

const styles = {
  list: {
    padding: 0,
  },
  listItem: {
    paddingLeft: '2rem',
  },
  primaryTextStyle: {
    fontWeight: 'none',
  },
};

interface Props {
  query: string;
  workOrders: ApplicationSearchWorkOrdersState[];
}

const WorkSearchResultsContainer = ({ query, workOrders }: Props) => {
  const navigate = (id: string) => {
    history.push(`/workOrders/${id}`);
  };

  return (
    <>
      <div styleName="search-entity-results-heading">Work</div>
      <List style={styles.list}>
        {workOrders.map((workOrder: ApplicationSearchWorkOrdersState) => {
          const icon = iconUtilities.getWorkIconByStatusId(workOrder.statusId);

          const assetName = textDisplayUtilities.highlightedText(
            query,
            workOrder.name,
          );

          return (
            <ListItemButton
              key={workOrder.id}
              onClick={() => navigate(workOrder.id)}
              style={styles.listItem}
            >
              <div styleName="work-order-icon-container">{icon}</div>
              <ListItemText
                primary={assetName}
                primaryTextStyle={styles.primaryTextStyle}
              />
            </ListItemButton>
          );
        })}
      </List>
    </>
  );
};

export default WorkSearchResultsContainer;
