import React from 'react';

import { Modal } from '@atom/mui';

interface Props {
  open: boolean;
  loading: boolean;
  assetName: string;
  onCancel: () => void;
  onApproveAll: () => void;
}

const ApproveAllChangesModal = ({
  open,
  loading,
  assetName,
  onCancel,
  onApproveAll,
}: Props) => {
  return (
    <Modal
      open={open}
      loading={loading}
      cancelButtonText="Cancel"
      confirmButtonText="Approve All"
      onCancel={onCancel}
      onConfirm={onApproveAll}
      title="Approve All Changes"
    >
      {`Are you sure you want to approve all changes to ${assetName}?`}
    </Modal>
  );
};

export default ApproveAllChangesModal;
