import React, { useContext } from 'react';

import UserGroupContext, {
  View,
} from '@atom/components/userGroupDetail/UserGroupContext';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';

import './userGroupHeader.css';

export interface Props {
  view: {
    value: View;
    label: string;
    icon: string;
  };
}

const DetailHeaderButton = ({ view }: Props) => {
  const { activeView, setActiveView } = useContext(UserGroupContext);

  const styleName =
    activeView === view.value ? 'icon-container active' : 'icon-container';

  return (
    <div onClick={() => setActiveView(view.value)} styleName={styleName}>
      <IconButton>
        <Icon color={colors.neutral.white}>{view.icon}</Icon>
      </IconButton>
      <div styleName="label">{view.label}</div>
    </div>
  );
};

export default DetailHeaderButton;
