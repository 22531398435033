/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';

type CustomProps = {
  /**
   * style to be applied to the input container
   */
  style?: React.CSSProperties;
  /**
   * if input should be full width of container
   * defaults to true
   */
  fullWidth?: boolean;
  /**
   * Fix label to "floating" position (focused)
   * defaults to true
   */
  floatingLabelFixed?: boolean;
  /**
   * autocomplete rule passed down to the input
   * defaults to "off"
   */
  autoComplete?: string;
  /**
   * disable the underline for the standard input
   */
  disableUnderline?: boolean;
  /**
   * style to be applied to the helper text
   */
  FormHelperTextProps?: React.CSSProperties;
};

export type TextFieldProps = CustomProps & MUITextFieldProps;

const getInputLabel = ({ floatingLabelFixed = true }: TextFieldProps): any => {
  return floatingLabelFixed
    ? { position: 'unset', color: colors.neutral.gray }
    : {
        color: colors.neutral.gray,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        bottom: '0px',
        maxWidth: '100%',
      };
};

const getInputLabelShrink = ({
  floatingLabelFixed = true,
}: TextFieldProps): any => {
  return floatingLabelFixed
    ? { transform: 'unset', fontSize: fonts.sm }
    : {
        fontSize: fonts.sm,
        overflow: 'unset',
        textOverflow: 'unset',
        whiteSpace: 'unset',
      };
};

const useStyles = makeStyles(theme => ({
  inputRoot: {
    fontSize: fonts.md,
  },
  inputUnderline: {
    '&:before': {
      borderBottom: `1px solid ${colors.neutral.silver}`,
    },
  },
  inputLabel: getInputLabel,
  inputLabelShrunk: getInputLabelShrink,
  inputLabelFocused: {
    color: `${colors.neutral.gray} !important`,
  },
  inputLabelError: {
    color: `${theme.palette.error.main} !important`,
  },
  helperText: {
    height: 0,
    marginTop: 0,
  },
  formControl: {
    'label + &': {
      marginTop: '0',
    },
  },
}));

const defaultContainerStyle: React.CSSProperties = {
  width: '100%',
};

export const TextField = (props: TextFieldProps) => {
  const classes = useStyles(props);

  const {
    style,
    floatingLabelFixed = true,
    fullWidth = true,
    autoComplete = 'off',
    disableUnderline = false,
    variant = 'standard',
    FormHelperTextProps = {},
    ...data
  } = props;

  return (
    <div style={{ ...defaultContainerStyle, ...style }}>
      <MUITextField
        {...data}
        variant={variant}
        fullWidth={fullWidth}
        label={props.label}
        autoComplete={autoComplete}
        InputLabelProps={{
          ...props.InputLabelProps,
          classes: {
            root: classes.inputLabel,
            focused: classes.inputLabelFocused,
            error: classes.inputLabelError,
            ...(floatingLabelFixed && {
              shrink: classes.inputLabelShrunk,
            }),
            ...props.InputLabelProps?.classes,
          },
          ...(floatingLabelFixed && { shrink: true }),
        }}
        InputProps={{
          ...props.InputProps,
          ...{ disableUnderline },
          classes: {
            root: classes.inputRoot,
            underline: classes.inputUnderline,
            formControl: classes.formControl,
            ...props.InputProps?.classes,
          } as any,
        }}
        FormHelperTextProps={{
          ...props.FormHelperTextProps,
          classes: {
            root: classes.helperText,
            ...props.FormHelperTextProps?.classes,
          },
        }}
      />
    </div>
  );
};

export default TextField;
