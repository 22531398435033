import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import AddCategoriesModal from '@atom/components/common/workOrderDetail/addCategoriesModal/AddCategoriesModal';
import AddMaterialAssetsModal from '@atom/components/common/workOrderDetail/addMaterialAssetsModal/AddMaterialAssetsModal';
import WorkOrderContext from '@atom/components/workOrderDetail/WorkOrderContext';
import { TASK_MATERIALS_ADD, TASK_UPDATE } from '@atom/graph/task';
import { Icon, IconButton, Snackbar } from '@atom/mui';
import {
  Task,
  TaskCategory,
  TaskMaterialsAddInput,
  TaskUpdateInput,
} from '@atom/types/task';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';

import CategoryTile from './CategoryTile';
import MaterialTile from './MaterialTile';

import './materialSection.css';

const MaterialSection = () => {
  const { workOrderDetail, task, refetch } = useContext(WorkOrderContext);

  const [expanded, setExpanded] = useState<boolean>(true);
  const [openMaterialAdd, setOpenMaterialAdd] = useState<boolean>(false);
  const [openCategoryModal, setOpenCategoryModal] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<TaskCategory>(null);

  const [addMaterials] = useMutation<void, { input: TaskMaterialsAddInput }>(
    TASK_MATERIALS_ADD,
    {
      onCompleted: res => refetch(),
    },
  );

  const [updateTask] = useMutation<{ task: Task }, { input: TaskUpdateInput }>(
    TASK_UPDATE,
    { onCompleted: res => refetch() },
  );

  const isMaterialsEmpty = R.isEmpty(task.materials);

  const totalCount = R.length(task.materials);
  const icon = expanded ? 'expand_less' : 'expand_more';

  const handleModalClose = () => {
    setSelectedCategory(null);
    setOpenMaterialAdd(false);
    setOpenCategoryModal(false);
  };

  const handleCategoryClick = (category: TaskCategory) => {
    setSelectedCategory(category);
    setOpenMaterialAdd(true);
  };

  const handleAddMaterials = async (assetIds: string[]) => {
    if (R.isEmpty(assetIds)) {
      handleModalClose();
    }

    try {
      await addMaterials({
        variables: {
          input: {
            workOrderId: workOrderDetail.id,
            taskId: task.id,
            assetIds,
          },
        },
      });

      handleModalClose();
    } catch (err) {
      Snackbar.error({
        message: 'Failed to add inventory. Please try again.',
      });

      handleModalClose();
    }
  };

  const handleAddCategories = async (categoryIds: string[]) => {
    if (R.isEmpty(categoryIds)) {
      handleModalClose();
    }

    try {
      await updateTask({
        variables: {
          input: {
            id: task.id,
            workOrderId: workOrderDetail.id,
            categoryIds: [
              ...task.categories.map(category => category.id),
              ...categoryIds,
            ],
          },
        },
      });

      handleModalClose();
    } catch (err) {
      Snackbar.error({
        message: 'Failed to add inventory folders. Please try again.',
      });

      handleModalClose();
    }
  };

  const isEditDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  return (
    <>
      <div styleName="header-container">
        <div styleName="section-left">
          <div styleName="section-title">Equipment and Material</div>
          <div styleName="total-count">{totalCount}</div>
        </div>
        <div styleName="section-right">
          {expanded && (
            <>
              <IconButton
                onClick={() => setOpenCategoryModal(true)}
                edge="end"
                tooltip="Add Equipment and Material Folders"
                disabled={isEditDisabled}
                data-cy="addMaterialEquipmentFolderDetail"
              >
                <Icon>create_new_folder</Icon>
              </IconButton>
              <IconButton
                onClick={() => setOpenMaterialAdd(true)}
                edge="end"
                tooltip="Add Equipment and Material"
                disabled={isEditDisabled}
                data-cy="addMaterialEquipmentDetail"
              >
                <Icon>add</Icon>
              </IconButton>
            </>
          )}
          <IconButton onClick={() => setExpanded(!expanded)} edge="end">
            <Icon>{icon}</Icon>
          </IconButton>
        </div>
      </div>
      {expanded && (
        <div>
          <div styleName="category-section">
            {task.categories.map(category => (
              <CategoryTile
                key={category.id}
                category={category}
                onClick={() => handleCategoryClick(category)}
              />
            ))}
          </div>
          <>
            {isMaterialsEmpty ? (
              <div styleName="empty-section-text">
                Add Equipment and Material
              </div>
            ) : (
              task.materials.map(material => (
                <MaterialTile key={material.assetId} material={material} />
              ))
            )}
          </>
        </div>
      )}
      <AddMaterialAssetsModal
        open={openMaterialAdd}
        onClose={handleModalClose}
        rootCategory={selectedCategory}
        task={task}
        handleAddMaterials={handleAddMaterials}
        favoritesEnabled
      />
      <AddCategoriesModal
        open={openCategoryModal}
        onClose={handleModalClose}
        task={task}
        handleAddCategories={handleAddCategories}
      />
    </>
  );
};

export default MaterialSection;
