import * as React from 'react';

import RenameModal from '@atom/components/common/RenameModal';
import { Icon, Menu, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import { MediaItem } from '@atom/types/media';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';

import '../formInstance.css';

const { MenuItem } = Menu;

interface PassedProps {
  assetId: string;
  media: MediaItem;
  removeFile: (mediaId: string, subjectId: string) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
}

type Props = PassedProps;

enum ModalType {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  NONE = 'NONE',
}

interface State {
  name: string;
  openModal: { type: ModalType; id: any };
}

class FhwaMediaTile extends React.Component<Props, State> {
  state = {
    name: this.props.media.name,
    openModal: { type: ModalType.NONE, id: null },
  };

  toggleModal = (modalToOpen: ModalType, mediaId: any) => {
    this.setState({ openModal: { type: modalToOpen, id: mediaId } });
  };

  isModalOpen = (modalType: ModalType, mediaId: any) => {
    const { type, id } = this.state.openModal;
    return type === modalType && id === mediaId;
  };

  renameAction = (id: string, name: string) => {
    const { renameMedia, media } = this.props;
    this.setState({ name });

    renameMedia(id, media.subjectId, name);
  };

  render() {
    const { media, assetId, removeFile } = this.props;
    const { name } = this.state;
    const { fileExtension, fileId, id, type } = media;

    const onConfirmDeleteModal = () => {
      removeFile(id, assetId);
      this.toggleModal(ModalType.NONE, null);
    };

    return (
      <>
        <div styleName="fhwa-media-tile-container">
          <div styleName="fhwa-media-image-container">
            <img
              styleName="fhwa-image"
              src={`${FILES_ENDPOINT}/${fileId}_180`}
              alt={name}
            />
          </div>
          <div styleName="fhwa-media-tile-info">
            <div styleName="fhwa-file-name">{`${name}.${fileExtension}`}</div>
            <Menu>
              <MenuItem
                startAdornment={<Icon>edit</Icon>}
                onClick={() => this.toggleModal(ModalType.EDIT, id)}
              >
                Edit
              </MenuItem>
              <MenuItem
                startAdornment={<Icon>delete</Icon>}
                onClick={() => this.toggleModal(ModalType.DELETE, id)}
              >
                Delete
              </MenuItem>
            </Menu>
          </div>
        </div>
        <RenameModal
          open={this.isModalOpen(ModalType.EDIT, id)}
          closeModal={() => this.toggleModal(ModalType.NONE, null)}
          id={id}
          name={name}
          type={type}
          renameAction={this.renameAction}
        />
        <Modal
          open={this.isModalOpen(ModalType.DELETE, id)}
          onCancel={() => this.toggleModal(ModalType.NONE, null)}
          title={`Delete ${name}`}
          confirmButtonText="Delete"
          ConfirmButtonProps={{ style: { background: colors.brand.red } }}
          onConfirm={onConfirmDeleteModal}
        >
          Are you sure you want to delete this photo?
        </Modal>
      </>
    );
  }
}

export default FhwaMediaTile;
