import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import PriorityControl from '@atom/components/common/PriorityControl';
import WorkOrderDetailEdit from '@atom/components/common/workOrderDetail/workOrderDetailEdit/WorkOrderDetailEdit';
import WorkOrderStatusEdit from '@atom/components/common/workOrderDetail/workOrderStatusEdit/WorkOrderStatusEdit';
import WorkOrderPreviewContext, {
  View,
  WorkOrderActionTypes,
} from '@atom/components/workOrderPreview/WorkOrderPreviewContext';
import WorkOrdersContext from '@atom/components/workOrders/WorkOrdersContext';
import { WORK_ORDER_UPDATE } from '@atom/graph/work';
import { Icon, IconButton } from '@atom/mui';
import {
  WorkOrderDetailType,
  WorkOrderUpdate,
  WorkOrderUpdateInput,
} from '@atom/types/work';

import PreviewHeaderOptions from './PreviewHeaderOptions';

import './workOrderPreviewHeader.css';

const PreviewHeaderTools = () => {
  const { input, refetch: refetchList, setInput } = useContext(
    WorkOrdersContext,
  );
  const {
    workOrderDetail,
    refetch: refetchDetail,
    dispatch: contextDispatch,
    setActiveView,
    setWorkValidations,
  } = useContext(WorkOrderPreviewContext);

  const [updateWorkDetail] = useMutation<
    { workOrderUpdate: WorkOrderUpdate },
    { workOrder: WorkOrderUpdateInput }
  >(WORK_ORDER_UPDATE);

  const updateWorkOrderProperty = (
    property: keyof WorkOrderDetailType,
    value: any,
  ) => {
    contextDispatch({
      type: WorkOrderActionTypes.UPDATE_WORK_ORDER_PROPERTY,
      data: {
        property,
        value,
      },
    });
  };

  const updateWorkOrder = async params => {
    const { priorityId } = params;

    if (R.isNil(priorityId)) {
      return;
    }

    const res = await updateWorkDetail({
      variables: {
        workOrder: {
          id: workOrderDetail.id,
          priorityId,
        },
      },
    });

    refetchList();

    updateWorkOrderProperty(
      'priorityId',
      res?.data?.workOrderUpdate.priorityId,
    );
  };

  const closeTray = () => {
    setInput({ ...input, workOrderId: undefined });
  };

  const refreshListAndDetail = () => {
    refetchDetail();
    refetchList();
  };

  return (
    <div styleName="tools-container">
      <WorkOrderStatusEdit
        workOrder={workOrderDetail}
        createdById={workOrderDetail?.createdBy}
        refetch={refreshListAndDetail}
        styleType="detail"
        setWorkValidations={setWorkValidations}
      />
      <PriorityControl
        disabled={workOrderDetail.isClosed}
        priorityId={workOrderDetail.priorityId}
        updateWorkOrder={updateWorkOrder}
      />
      <IconButton
        onClick={() => setActiveView(View.INFO)}
        edge="end"
        tooltip="Show Info"
      >
        <Icon>info</Icon>
      </IconButton>
      <Link to={`/workOrders/${input.workOrderId}`}>
        <IconButton edge="end" tooltip="Open Detail">
          <Icon>open_in_new</Icon>
        </IconButton>
      </Link>
      {!workOrderDetail.isClosed && (
        <WorkOrderDetailEdit
          workOrderDetail={workOrderDetail}
          refetch={refreshListAndDetail}
        />
      )}
      <PreviewHeaderOptions />
      <IconButton onClick={closeTray} edge="end" tooltip="Close">
        <Icon>close</Icon>
      </IconButton>
    </div>
  );
};

export default PreviewHeaderTools;
