import React from 'react';
import * as R from 'ramda';

// @ts-ignore
import workTypeIconChildWhite from '@atom/components/common/svgIcons/workTypeIconChildWhite.svg';
import { TaskField } from '@atom/types/task';
import { SubField, WorkOrderField } from '@atom/types/work';
import { WorkTemplate, WorkTemplateTaskItem } from '@atom/types/workTemplate';
import { getUserIdSync } from '@atom/utilities/accountUtilities';
import { WorkTypeVariant } from '@atom/utilities/workTemplateUtilities';

export enum WorkTemplateView {
  INFO = 'INFO',
  TASKS = 'TASKS',
  TEAM = 'TEAM',
  DOCUMENTS = 'DOCUMENTS',
  PHOTOS = 'PHOTOS',
  VIDEOS = 'VIDEOS',
  CHILD_TYPES = 'CHILD_TYPES',
}

export const getTabData = (workTypeVariant: WorkTypeVariant) => {
  return [
    {
      value: WorkTemplateView.INFO,
      label: 'Info',
      icon: 'info',
    },
    {
      value: WorkTemplateView.TASKS,
      label: 'Tasks',
      icon: 'playlist_add_check',
    },
    {
      value: WorkTemplateView.TEAM,
      label: 'Team',
      icon: 'supervisor_account',
    },
    {
      value: WorkTemplateView.DOCUMENTS,
      label: 'Documents',
      icon: 'description',
    },
    {
      value: WorkTemplateView.PHOTOS,
      label: 'Photos',
      icon: 'insert_photo',
    },
    {
      value: WorkTemplateView.VIDEOS,
      label: 'Videos',
      icon: 'video_library',
    },
    ...(workTypeVariant === WorkTypeVariant.PARENT
      ? [
          {
            value: WorkTemplateView.CHILD_TYPES,
            label: 'Child Types',
            icon: <img src={workTypeIconChildWhite} />,
          },
        ]
      : []),
  ];
};

interface Context {
  workTemplate: WorkTemplate;
  workTypeVariant: WorkTypeVariant;
  task: WorkTemplateTaskItem;
  setActiveTask: Function;
  dispatch: React.Dispatch<WorkTemplateActions>;
  loading: boolean;
  activeView: WorkTemplateView;
  setActiveView: (view: WorkTemplateView) => void;
  refetch: () => Promise<any>;
}

export const initialState: Context = {
  // @ts-ignore
  workTemplate: {},
  workTypeVariant: null,
  // @ts-ignore
  task: {},
  setActiveTask: () => {},
  dispatch: () => {},
  loading: false,
  activeView: WorkTemplateView.INFO,
  setActiveView: () => {},
  refetch: () => Promise.resolve({}),
};

const WorkTemplateContext = React.createContext<Context>(initialState);

export enum WorkTemplateActionTypes {
  SET_WORK_TEMPLATE = 'SET_WORK_TEMPLATE',
  SET_TASK = 'SET_TASK',
  DELETE_TASK = 'DELETE_TASK',
  UPDATE_WORK_TEMPLATE_PROPERTY = 'UPDATE_WORK_TEMPLATE_PROPERTY',
  UPDATE_TASK_PROPERTY = 'UPDATE_TASK_PROPERTY',
  ADD_WORK_TEMPLATE_FIELD = 'ADD_WORK_TEMPLATE_FIELD',
  UPDATE_WORK_TEMPLATE_FIELD = 'UPDATE_WORK_TEMPLATE_FIELD',
  UPDATE_WORK_TEMPLATE_FIELD_VALUE = 'UPDATE_WORK_TEMPLATE_FIELD_VALUE',
  DELETE_WORK_TEMPLATE_FIELD = 'DELETE_WORK_TEMPLATE_FIELD',
  ADD_TASK_FIELD = 'ADD_TASK_FIELD',
  UPDATE_TASK_FIELD = 'UPDATE_TASK_FIELD',
  UPDATE_TASK_FIELD_VALUE = 'UPDATE_TASK_FIELD_VALUE',
  DELETE_TASK_FIELD = 'DELETE_TASK_FIELD',
}

interface SetWorkTemplate {
  type: WorkTemplateActionTypes.SET_WORK_TEMPLATE;
  data: WorkTemplate;
}

interface UpdateWorkTemplateProperty {
  type: WorkTemplateActionTypes.UPDATE_WORK_TEMPLATE_PROPERTY;
  data: {
    property: keyof WorkTemplate;
    value: any;
  };
}

interface AddWorkTemplateField {
  type: WorkTemplateActionTypes.ADD_WORK_TEMPLATE_FIELD;
  data: {
    field: WorkOrderField;
  };
}

interface UpdateWorkTemplateField {
  type: WorkTemplateActionTypes.UPDATE_WORK_TEMPLATE_FIELD;
  data: {
    field: WorkOrderField;
  };
}

interface UpdateWorkTemplateFieldValue {
  type: WorkTemplateActionTypes.UPDATE_WORK_TEMPLATE_FIELD_VALUE;
  data: {
    field: WorkOrderField;
  };
}

interface DeleteWorkTemplateField {
  type: WorkTemplateActionTypes.DELETE_WORK_TEMPLATE_FIELD;
  data: {
    fieldId: string;
  };
}

interface SetTask {
  type: WorkTemplateActionTypes.SET_TASK;
  data: WorkTemplateTaskItem;
}

interface DeleteTask {
  type: WorkTemplateActionTypes.DELETE_TASK;
  data: {
    taskId: string;
  };
}

interface UpdateTaskProperty {
  type: WorkTemplateActionTypes.UPDATE_TASK_PROPERTY;
  data: {
    taskId: string;
    property: keyof WorkTemplateTaskItem;
    value: any;
  };
}

interface AddTaskField {
  type: WorkTemplateActionTypes.ADD_TASK_FIELD;
  data: {
    taskId: string;
    field: TaskField;
  };
}

interface UpdateTaskField {
  type: WorkTemplateActionTypes.UPDATE_TASK_FIELD;
  data: {
    taskId: string;
    field: TaskField;
  };
}

interface UpdateTaskFieldValue {
  type: WorkTemplateActionTypes.UPDATE_TASK_FIELD_VALUE;
  data: {
    taskId: string;
    field: TaskField;
  };
}

interface DeleteTaskField {
  type: WorkTemplateActionTypes.DELETE_TASK_FIELD;
  data: {
    taskId: string;
    fieldId: string;
  };
}

type WorkTemplateActions =
  | SetWorkTemplate
  | SetTask
  | DeleteTask
  | UpdateTaskProperty
  | UpdateWorkTemplateProperty
  | AddWorkTemplateField
  | UpdateWorkTemplateField
  | UpdateWorkTemplateFieldValue
  | DeleteWorkTemplateField
  | AddTaskField
  | UpdateTaskField
  | UpdateTaskFieldValue
  | DeleteTaskField;

// updates a single property on the work template.
const updateWorkTemplateProperty = (
  state: WorkTemplate,
  action: UpdateWorkTemplateProperty,
) => {
  return {
    ...state,
    [action.data.property]: action.data.value,
  };
};

// replaces the entire task with the new updatedTask
const setTask = (state: WorkTemplate, action: SetTask) => {
  const { data: updatedTask } = action;
  const taskIndex = state.tasks.findIndex(task => task.id === updatedTask.id);

  return R.over(R.lensPath(['tasks', taskIndex]), () => updatedTask, state);
};

const deleteTask = (state: WorkTemplate, action: DeleteTask) => {
  return {
    ...state,
    tasks: state.tasks.filter(task => task.id !== action.data.taskId),
  };
};

const addWorkTemplateField = (
  state: WorkTemplate,
  action: AddWorkTemplateField,
) => {
  const { field } = action.data;

  const updatedWorkOrder = {
    ...state,
    fieldOrder: [...state.fieldOrder, field.id],
  };

  return R.over(R.lensPath(['fields']), fields => [...fields, field])(
    updatedWorkOrder,
  );
};

export const getNestedFieldPathById = (
  id: string,
  subFields: SubField[],
  previousPath: any[],
): any[] => {
  return subFields.reduce((acc: any[], subField: SubField, index: number) => {
    const currentPath = [...previousPath, 'subFields', index, 'nestedField'];

    return subField.nestedField.id === id
      ? [...acc, ...currentPath]
      : [
          ...acc,
          ...getNestedFieldPathById(
            id,
            subField.nestedField.subFields || [],
            currentPath,
          ),
        ];
  }, []);
};

export const getFieldPathById = (id: string, fields: any[]): any[] => {
  return fields.reduce((acc: any[], field: any, index: number) => {
    const currentPath = ['fields', index];

    return field.id === id
      ? [...acc, ...currentPath]
      : [
          ...acc,
          ...getNestedFieldPathById(id, field.subFields || [], currentPath),
        ];
  }, []);
};

const updateWorkTemplateField = (
  state: WorkTemplate,
  action: UpdateWorkTemplateField | UpdateWorkTemplateFieldValue,
) => {
  const { field: updatedField } = action.data;

  const updatePath = getFieldPathById(updatedField.id, state.fields);

  const updatedPayload =
    action.type === WorkTemplateActionTypes.UPDATE_WORK_TEMPLATE_FIELD_VALUE
      ? { value: updatedField.value }
      : updatedField;

  return R.over(R.lensPath(updatePath), field => ({
    ...field,
    ...updatedPayload,
  }))(state);
};

const deleteWorkTemplateField = (
  state: WorkTemplate,
  action: DeleteWorkTemplateField,
) => {
  const { fieldId } = action.data;

  const updatedWorkOrder = {
    ...state,
    fieldOrder: state.fieldOrder.filter(itemId => itemId !== fieldId),
  };

  return R.over(R.lensPath(['fields']), fields =>
    fields.filter(field => field.id !== fieldId),
  )(updatedWorkOrder);
};

const updateTaskProperty = (
  state: WorkTemplate,
  action: UpdateTaskProperty,
) => {
  const { taskId, property, value } = action.data;
  const taskIndex = state.tasks.findIndex(task => task.id === taskId);
  return R.set(R.lensPath(['tasks', taskIndex, property]), value)(state);
};

// sets updatedBy and updatedDate on task in state
// needed as some validation checks updatedDate to determine
// if task has ever been updated
const updateTask = (state: WorkTemplate, index: number) => {
  const updateBy = getUserIdSync();
  const updatedDate = new Date().valueOf();
  const lens = R.lensPath(['tasks', index]);
  return R.over(lens, task => ({ ...task, updatedDate, updateBy }), state);
};

const addTaskField = (state: WorkTemplate, action: AddTaskField) => {
  const { taskId, field } = action.data;
  const taskIndex = state.tasks.findIndex(task => task.id === taskId);

  const updatedTaskFieldOrder = R.over(
    R.lensPath(['tasks', taskIndex]),
    task => ({ ...task, fieldOrder: [...task.fieldOrder, field.id] }),
  )(updateTask(state, taskIndex));

  return R.over(R.lensPath(['tasks', taskIndex, 'fields']), fields => [
    ...fields,
    field,
  ])(updatedTaskFieldOrder);
};

const updateTaskField = (
  state: WorkTemplate,
  action: UpdateTaskField | UpdateTaskFieldValue,
) => {
  const { taskId, field: updatedField } = action.data;
  const taskIndex = state.tasks.findIndex(task => task.id === taskId);

  const updatePath = getFieldPathById(
    updatedField.id,
    state.tasks[taskIndex].fields,
  );

  const updatedPayload =
    action.type === WorkTemplateActionTypes.UPDATE_TASK_FIELD_VALUE
      ? { value: updatedField.value }
      : updatedField;

  return R.over(R.lensPath(['tasks', taskIndex, ...updatePath]), field => ({
    ...field,
    ...updatedPayload,
  }))(updateTask(state, taskIndex));
};

const deleteTaskField = (state: WorkTemplate, action: DeleteTaskField) => {
  const { taskId, fieldId } = action.data;
  const taskIndex = state.tasks.findIndex(task => task.id === taskId);

  const updatedTaskFieldOrder = R.over(
    R.lensPath(['tasks', taskIndex]),
    task => ({
      ...task,
      fieldOrder: task.fieldOrder.filter(item => item !== fieldId),
    }),
  )(updateTask(state, taskIndex));

  return R.over(R.lensPath(['tasks', taskIndex, 'fields']), fields =>
    fields.filter(field => field.id !== fieldId),
  )(updatedTaskFieldOrder);
};

export const reducer = (
  state: WorkTemplate,
  action: WorkTemplateActions,
): WorkTemplate => {
  switch (action.type) {
    case WorkTemplateActionTypes.SET_WORK_TEMPLATE:
      return action.data;
    case WorkTemplateActionTypes.SET_TASK:
      return setTask(state, action);
    case WorkTemplateActionTypes.DELETE_TASK:
      return deleteTask(state, action);
    case WorkTemplateActionTypes.UPDATE_TASK_PROPERTY:
      return updateTaskProperty(state, action);
    case WorkTemplateActionTypes.UPDATE_WORK_TEMPLATE_PROPERTY:
      return updateWorkTemplateProperty(state, action);
    case WorkTemplateActionTypes.ADD_WORK_TEMPLATE_FIELD:
      return addWorkTemplateField(state, action);
    case WorkTemplateActionTypes.UPDATE_WORK_TEMPLATE_FIELD:
    case WorkTemplateActionTypes.UPDATE_WORK_TEMPLATE_FIELD_VALUE:
      return updateWorkTemplateField(state, action);
    case WorkTemplateActionTypes.DELETE_WORK_TEMPLATE_FIELD:
      return deleteWorkTemplateField(state, action);
    case WorkTemplateActionTypes.ADD_TASK_FIELD:
      return addTaskField(state, action);
    case WorkTemplateActionTypes.UPDATE_TASK_FIELD:
    case WorkTemplateActionTypes.UPDATE_TASK_FIELD_VALUE:
      return updateTaskField(state, action);
    case WorkTemplateActionTypes.DELETE_TASK_FIELD:
      return deleteTaskField(state, action);
    default: {
      return state;
    }
  }
};

export default WorkTemplateContext;
