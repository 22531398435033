import React, { useEffect, useMemo, useState } from 'react';
import { createFilterOptions, Tooltip } from '@mui/material';
import * as R from 'ramda';

import { SelectAutocomplete } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { WorkOrderField } from '@atom/types/work';

interface Props {
  field: WorkOrderField;
  onChange: (fieldId: string, value: any) => void;
  isDisabled?: boolean;
  fieldsLoading?: Set<string>;
  isNested?: boolean;
  boldLabel?: boolean;
}

const filterOptions = createFilterOptions<string>({
  matchFrom: 'any',
  limit: 250,
});

const WorkOrderEnumSingleField = ({
  field,
  onChange,
  isDisabled,
  fieldsLoading = new Set([]),
  isNested,
  boldLabel,
}: Props) => {
  const [value, setValue] = useState<string>(field.value);

  const handleChange = (event: any, option: string) => {
    const newValue = R.isNil(option) ? '' : option;

    setValue(newValue);
    onChange(field.id, newValue);
  };

  useEffect(() => {
    setValue(field.value);
  }, [field]);

  const nestedField = useMemo(() => {
    if (!field.subFields) {
      return null;
    }

    const nestedFieldIndex = R.findIndex(R.propEq('matchValue', value))(
      field.subFields,
    );

    return field.subFields[nestedFieldIndex]?.nestedField;
  }, [value, field]);

  const loading = field.subFieldsTruncated && fieldsLoading.has(field.id);
  const hasSubFields = !R.isEmpty(field.subFields);
  const selectStyles =
    hasSubFields || isNested
      ? { marginRight: '0.5rem', marginBottom: '5px', width: '9rem' }
      : { flex: 0.66 };

  const tooltipText = (
    <>
      <strong>{value ? `${field.title}: ` : field.title}</strong>
      {value && <span>{value}</span>}
    </>
  );

  const styles = {
    overflow: {
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: fonts.sm,
      color: boldLabel ? colors.neutral.dim : colors.neutral.gray,
    },
  };

  return (
    <>
      <div style={selectStyles}>
        <Tooltip title={tooltipText} placement="right-start">
          <div style={styles.overflow}>
            <div style={styles.overflow}>{field?.title}</div>
          </div>
        </Tooltip>
        <SelectAutocomplete
          id={field.id}
          options={field.enumeration}
          value={value}
          onChange={handleChange}
          disabled={isDisabled}
          loading={loading}
          filterOptions={filterOptions}
        />
      </div>
      {nestedField && (
        <WorkOrderEnumSingleField
          field={nestedField}
          onChange={onChange}
          isDisabled={isDisabled}
          fieldsLoading={fieldsLoading}
          isNested
        />
      )}
    </>
  );
};

export default WorkOrderEnumSingleField;
