import React from 'react';

import { InventoryPortalView } from '@atom/types/inventory';

import './inventoryPortal.css';

export interface Props {
  activeView: InventoryPortalView;
  setActiveView: (activeView: InventoryPortalView) => void;
}

const InventoryPortalSubNav = ({ activeView, setActiveView }: Props) => {
  return (
    <div styleName="subnav-container">
      <div styleName="subnav-controls-container">
        {Object.keys(InventoryPortalView).map((view, key) => (
          <div
            key={key}
            onClick={() => setActiveView(InventoryPortalView[view])}
            styleName={`subnav-control ${activeView === view ? 'active' : ''}`}
          >
            {view}
          </div>
        ))}
      </div>
    </div>
  );
};

export default InventoryPortalSubNav;
