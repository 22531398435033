import * as React from 'react';

import {
  FormAssetErrors,
  FormInstanceAssetItem,
  FormInstanceFieldItem,
} from '@atom/types/form';
import { InventorySchemaItem } from '@atom/types/inventory';
import { MediaItem } from '@atom/types/media';

import InstanceAssetField from './InstanceAssetField';

import '../formInstance.css';

interface PassedProps {
  field: FormInstanceFieldItem;
  fieldId: string;
  rootSchema: InventorySchemaItem;
  isEditable: boolean;
  preview: boolean;
  media: MediaItem[];
  progressiveUpdateFormInstanceAttribute: (
    fieldId: string,
    assetId: string,
    attributeGroupName: string,
    attributeId: string,
    body: Object,
  ) => void;
  uploadFiles: (
    files?: any[],
    subjectId?: string,
    subjectType?: string,
  ) => void;
  removeFile: (mediaId: string, subjectId: string) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  isUploadDisabled: boolean;
  savingFormInstance: boolean;
  assetErrors?: FormAssetErrors;
}

type Props = PassedProps;

const InstanceAssetContainer = ({
  field,
  fieldId,
  rootSchema,
  preview,
  isEditable,
  progressiveUpdateFormInstanceAttribute,
  media,
  removeFile,
  uploadFiles,
  isUploadDisabled,
  renameMedia,
  savingFormInstance,
  assetErrors,
}: Props) => {
  const assetKey = preview ? 'assetSchemas' : 'assets';

  return (
    <div styleName="asset-container">
      {field[assetKey].map((asset: FormInstanceAssetItem) => (
        <InstanceAssetField
          preview={preview}
          key={asset.id}
          media={media}
          asset={asset}
          fieldId={fieldId}
          isEditable={isEditable}
          rootSchema={rootSchema}
          removeFile={removeFile}
          uploadFiles={uploadFiles}
          isUploadDisabled={isUploadDisabled}
          renameMedia={renameMedia}
          progressiveUpdateFormInstanceAttribute={
            progressiveUpdateFormInstanceAttribute
          }
          savingFormInstance={savingFormInstance}
          assetErrors={assetErrors}
        />
      ))}
    </div>
  );
};

export default InstanceAssetContainer;
