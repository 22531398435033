import React, { useCallback, useEffect } from 'react';

import { TaskTemplate } from '@atom/types/taskTemplate';

import TaskTemplateList from './TaskTemplateList';

import './taskCreate.css';

interface Props {
  outerRef: any;
  taskTemplates: TaskTemplate[];
  taskTemplatesLoading: boolean;
  total: number;
  page: number;
  search: string;
  selectedTaskTemplate: TaskTemplate;
  setSelectedTaskTemplate: (selectedTaskTemplate: TaskTemplate) => void;
  handleDelete: (id: string) => void;
  handlePageScroll: (page: number) => void;
  disableDelete?: boolean;
}

const TaskTemplateContent = ({
  outerRef,
  taskTemplates,
  taskTemplatesLoading,
  total,
  page,
  search,
  selectedTaskTemplate,
  setSelectedTaskTemplate,
  handleDelete,
  handlePageScroll,
  disableDelete,
}: Props) => {
  const handleScroll = useCallback(() => {
    const isBottom =
      outerRef.current.scrollTop + outerRef.current.clientHeight + 0.5 >=
      outerRef.current.scrollHeight;

    if (
      isBottom &&
      taskTemplates.length !== total &&
      !taskTemplatesLoading &&
      !search
    ) {
      handlePageScroll(page + 1);
    }
  }, [outerRef.current, total, page, taskTemplates, handlePageScroll]);

  useEffect(() => {
    if (outerRef.current) {
      outerRef.current.addEventListener('scroll', handleScroll);
      return () =>
        outerRef.current?.removeEventListener('scroll', handleScroll);
    }

    return () => {};
  }, [handleScroll]);

  return (
    <TaskTemplateList
      taskTemplatesLoading={taskTemplatesLoading}
      taskTemplates={taskTemplates}
      selectedTaskTemplate={selectedTaskTemplate}
      setSelectedTaskTemplate={setSelectedTaskTemplate}
      handleDelete={handleDelete}
      disableDelete={disableDelete}
    />
  );
};

export default TaskTemplateContent;
