import React from 'react';
import { Draggable as RBDraggable } from 'react-beautiful-dnd';

import colors from '@atom/styles/colors';

const getItemStyle = (
  isDragStyleDisabled: boolean,
  isDragging: boolean,
  draggableStyle: Object,
): Object => ({
  userSelect: 'none',
  background:
    isDragStyleDisabled || !isDragging
      ? colors.neutral.white
      : colors.neutral.fog,
  boxShadow:
    isDragStyleDisabled || !isDragging
      ? 'none'
      : 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
  ...draggableStyle,
});

interface Props {
  /**
   * represents the current index of the item in collection of items
   * contained within the droppable component
   */
  index: number;
  /**
   * When this item is dragged and dropped, the draggableId will
   * be used to identify this item in the DragDropContext handlers.
   * Usually this is the id of the item.
   */
  draggableId: string;
  /**
   * Only a <Droppable /> of the same type is can accept this component
   * of matching type
   */
  type?: string;
  isDragDisabled?: boolean;
  children: React.ReactNode;
  isDragStyleDisabled?: boolean;
}

// A Draggle component must be contained within a Droppable component
// which in turn must be contained within a DragDropContext

export const Draggable = ({
  index,
  draggableId,
  type = '',
  isDragDisabled = false,
  children,
  isDragStyleDisabled = false,
}: Props) => {
  return (
    <RBDraggable
      draggableId={draggableId}
      index={index}
      type={type}
      isDragDisabled={isDragDisabled}
    >
      {(draggableProvided: any, draggableSnapshot: any) => (
        <div
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
          style={getItemStyle(
            isDragStyleDisabled,
            draggableSnapshot.isDragging,
            draggableProvided.draggableProps.style,
          )}
        >
          {children}
        </div>
      )}
    </RBDraggable>
  );
};
