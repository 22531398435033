import React, { useContext } from 'react';
import * as R from 'ramda';

import { Collapse, List } from '@atom/mui';

import { isAsset } from './InventoryAssetTree';
import LoadAssetButton from './LoadAssetsButton';
import TreeContext, { Tree } from './TreeContext';
import TreeItem from './TreeItem';

export interface Props {
  isAncestorSelected?: boolean;
  level: number;
  trees: Tree[];
}

const getLoadAssetsButtonStyle = (hasChildren: boolean, level: number) =>
  hasChildren
    ? { paddingLeft: `${level + 3}rem` }
    : { paddingLeft: `${level + 5}rem` };

const TreeList = ({ isAncestorSelected, level, trees }: Props) => {
  const {
    fetchMore,
    getData,
    hasError,
    hasMore,
    includeAssets,
    isLoading,
    open,
    selectedCategoryIds,
  } = useContext(TreeContext);

  return (
    <List disablePadding>
      {trees.map(tree => {
        const hasAssets = !isAsset(tree) && tree.hasAssets;
        const hasCategories = !isAsset(tree) && !!tree.categories?.length;
        const hasChildren = (includeAssets && hasAssets) || hasCategories;
        return (
          <React.Fragment key={tree.id}>
            <TreeItem
              hasChildren={hasChildren}
              isAncestorSelected={isAncestorSelected}
              level={level}
              tree={tree}
            />
            {hasChildren && (
              <Collapse in={open.has(tree.id)} timeout="auto" unmountOnExit>
                {hasAssets ? (
                  <>
                    {<TreeList level={R.inc(level)} trees={getData(tree.id)} />}
                    {hasMore(tree.id) && (
                      <LoadAssetButton
                        loading={isLoading(tree.id)}
                        error={hasError(tree.id)}
                        onClick={() => fetchMore(tree.id)}
                        style={getLoadAssetsButtonStyle(hasChildren, level)}
                      />
                    )}
                  </>
                ) : (
                  <TreeList
                    isAncestorSelected={
                      selectedCategoryIds?.has(tree.id) || isAncestorSelected
                    }
                    level={R.inc(level)}
                    trees={(tree as any).categories}
                  />
                )}
              </Collapse>
            )}
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default TreeList;
