import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function inventoryTotalReducer(
  state = initialState.inventoryTotal,
  action,
) {
  const { data, type } = action;
  switch (type) {
    case actionTypes.GET_INVENTORY_CATEGORIES_SUCCESS:
    case actionTypes.GET_INVENTORY_ASSETS_SUCCESS:
    case actionTypes.GET_INVENTORY_ASSETS_FILTER_SUCCESS: {
      const { total: inventoryTotal } = data;
      return inventoryTotal;
    }
    default: {
      return state;
    }
  }
}
