import React, { useMemo } from 'react';
import * as R from 'ramda';

import { AttributesType } from '@atom/types/inventory';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import AttributeGroupHeader from './AttributeGroupHeader';
import AttributesList from './AttributesList';
import EditAttributeList from './EditAttributeList';

const styles = {
  listContainer: {
    padding: '1em 1.25rem 1em 3.75em',
  },
};

interface Props {
  loading: boolean;
  attributeGroup: any;
  onClick: (string) => void;
  onCollapse: (string) => void;
  collapsed: boolean;
  onCancel: (string) => void;
  onSave: (string) => void;
  isEdit?: boolean;
  onPendingApproval?: (
    action: string,
    attributeGroupName: string,
    attribute: AttributesType,
  ) => void;
  canManageChanges?: boolean;
  canUpdate?: boolean;
  isDisabled?: boolean;
  errorAttributes?: { [attributeGroupId: string]: string[] };
}

const attributeGroupHasPendingChanges = ({ attributes }: any): boolean =>
  R.values(attributes).some(attribute => !R.isNil(attribute.newValue));

const AttributeSection = ({
  attributeGroup,
  onClick,
  onCancel,
  onCollapse,
  collapsed,
  onSave,
  loading,
  isEdit,
  onPendingApproval,
  canManageChanges,
  canUpdate,
  isDisabled = false,
  errorAttributes,
}: Props) => {
  const { name, attributes } = attributeGroup;

  const hasPendingChanges = useMemo(() => {
    return attributeGroupHasPendingChanges(attributeGroup);
  }, [attributeGroup]);

  const errorAttributeIds = R.pathOr([], [attributeGroup.id], errorAttributes);

  const content = isEdit ? (
    <div style={styles.listContainer}>
      <EditAttributeList
        attributes={attributes}
        errorAttributeIds={errorAttributeIds}
      />
    </div>
  ) : collapsed ? (
    <div />
  ) : (
    <div style={styles.listContainer}>
      <AttributesList
        attributeGroupName={name}
        onPendingApproval={onPendingApproval}
        attributes={attributes}
        canManageChanges={canManageChanges}
        errorAttributeIds={errorAttributeIds}
      />
    </div>
  );
  return (
    <div>
      <AttributeGroupHeader
        name={name}
        onClick={onClick}
        onCollapse={onCollapse}
        collapsed={collapsed}
        isEdit={isEdit}
        onCancel={onCancel}
        onSave={onSave}
        loading={loading}
        hasPendingChanges={hasPendingChanges}
        canUpdate={canUpdate}
        isDisabled={isDisabled}
        hasAttributeError={!isNilOrEmpty(errorAttributeIds)}
      />
      {content}
    </div>
  );
};

export default AttributeSection;
