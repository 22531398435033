import React, { useContext, useEffect, useState } from 'react';

import { Select } from '@atom/mui';
import { getFormModuleByType } from '@atom/selectors/formModuleSelectors';
import { ConditionInspection, FormModuleKeys } from '@atom/types/form';
import api from '@atom/utilities/api';
import { INVENTORY_SCHEMAS_ENDPOINT } from '@atom/utilities/endpoints';

import FormBuilderContext from '../FormBuilderContext';

import '../formBuilder.css';

const { MenuItem } = Select;

const styles = {
  template: {
    width: '100%',
  },
  icon: {
    marginRight: '0.625rem',
  },
  menu: {
    maxHeight: '30rem',
  },
  select: {
    margin: '2rem 0',
    width: '50%',
  },
};

interface Props {
  field: any;
  fieldId: string;
  updateCiFormInspection: (fieldId: string, inspectionId: string) => void;
}

const FormModule = ({ field, fieldId, updateCiFormInspection }: Props) => {
  const { schemas, selectedSchema } = useContext(FormBuilderContext);

  const [selectedInspectionId, setSelectedInspectionId] = useState<string>(
    field.conditionInspectionId || '',
  );
  const [ciLoading, setCiLoading] = useState<boolean>(false);
  const [filteredCiOptions, setFilteredCiOptions] = useState<
    ConditionInspection[]
  >([]);

  const isCiModule = field.type === FormModuleKeys.CI;

  useEffect(() => {
    setSelectedInspectionId(field.conditionInspectionId || '');
  }, [field]);

  useEffect(() => {
    const getConditionInspections = async () => {
      setCiLoading(true);

      const { data = [] } = await api.get(`${INVENTORY_SCHEMAS_ENDPOINT}/ci`);

      const filteredBySelectedSchema = data.filter(
        item => item.id === selectedSchema,
      );

      setFilteredCiOptions(filteredBySelectedSchema);

      setCiLoading(false);
    };

    if (isCiModule && selectedSchema) {
      getConditionInspections();
    } else {
      setFilteredCiOptions([]);
    }
  }, [field.type, selectedSchema]);

  const handleConditionInspectionUpdate = (inspectionId: string) => {
    setSelectedInspectionId(inspectionId);
    updateCiFormInspection(fieldId, inspectionId);
  };

  const {
    icon,
    title,
    getLongDescription,
    previewImageSrc,
  } = getFormModuleByType(field.type);

  const titleContent = isCiModule ? `${title}: ${field.name || ''}` : title;
  const descriptionContent = isCiModule ? '' : getLongDescription(schemas);

  return (
    <>
      <div styleName="form-module-title-block">
        {icon}
        <div styleName="form-module-title">{titleContent}</div>
      </div>
      <div styleName="form-module-description-block">
        <div styleName="form-module-description-text">{descriptionContent}</div>
      </div>
      {isCiModule && (
        <Select
          label="Select Inspection"
          value={selectedInspectionId}
          onChange={event =>
            handleConditionInspectionUpdate(event.target.value)
          }
          disabled={ciLoading}
          defaultValue=""
          MenuProps={{ style: styles.menu }}
          style={styles.select}
        >
          {filteredCiOptions.map(option => {
            return (
              <MenuItem key={option.inspectionId} value={option.inspectionId}>
                {option.name}
              </MenuItem>
            );
          })}
        </Select>
      )}
      <img src={previewImageSrc} style={styles.template} alt="Form Module" />
    </>
  );
};

export default FormModule;
