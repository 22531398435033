import React from 'react';

import PaginationControl from '../PaginationControl';

import '../../../styles/table.css';

function setTableHeaderClass(field, sortableFields, sortBy, isAscending) {
  const isSortByField = sortBy === field;
  let classString =
    isSortByField || sortableFields.includes(field) ? 'is-sortable' : '';

  if (isSortByField && isAscending) {
    classString = classString.concat(' sort-ascending');
  } else if (isSortByField) {
    classString = classString.concat(' sort-descending');
  }

  return classString;
}
function buildTableHeaders(
  displayHeaders,
  sortableFields,
  fields,
  sortBy,
  isAscending,
  sortRows,
) {
  return displayHeaders.map((header, headerIndex) => {
    const field = fields[headerIndex];
    const isSortable = sortBy === field || sortableFields.includes(field);
    const onClick = isSortable ? () => sortRows(field) : () => {};

    return (
      <th
        onClick={onClick}
        styleName={setTableHeaderClass(
          field,
          sortableFields,
          sortBy,
          isAscending,
        )}
        key={headerIndex}
      >
        {header}
      </th>
    );
  });
}

function buildRowFields(row, fields, buildCell) {
  return fields.map((field, fieldIndex) => {
    return buildCell(row, field, fieldIndex);
  });
}

function buildRows(dataRows, fields, buildCell) {
  return dataRows.map((row, rowIndex) => {
    return <tr key={rowIndex}>{buildRowFields(row, fields, buildCell)}</tr>;
  });
}

interface Props {
  displayHeaders: any[];
  sortableFields?: any[];
  sortBy: string;
  isAscending: boolean;
  sortRows: () => any;
  dataRows: any[];
  fields: any[];
  buildCell: () => any;
  disablePagination: boolean;
  totalRows?: number;
  incrementPage: () => any;
  updateLimit: () => any;
  page?: number;
  limit?: number;
  // style represents the root style on this component
  style?: any;
  limitOptions?: number[];
}

const ListViewTable = ({
  displayHeaders,
  sortableFields,
  sortBy,
  isAscending,
  sortRows,
  dataRows,
  fields,
  buildCell,
  disablePagination,
  totalRows,
  incrementPage,
  updateLimit,
  page,
  limit,
  style,
  limitOptions,
}: Props) => {
  return (
    <div styleName="main-pane list-view" style={style}>
      <table styleName="sticky">
        <thead>
          <tr>
            {buildTableHeaders(
              displayHeaders,
              sortableFields,
              fields,
              sortBy,
              isAscending,
              sortRows,
            )}
          </tr>
        </thead>
        <tbody styleName="hidden">
          {buildRows(dataRows, fields, buildCell)}
        </tbody>
      </table>
      <table>
        <thead styleName="hidden">
          <tr>
            {buildTableHeaders(
              displayHeaders,
              sortableFields,
              fields,
              sortBy,
              isAscending,
              sortRows,
            )}
          </tr>
        </thead>
        <tbody>{buildRows(dataRows, fields, buildCell)}</tbody>
      </table>
      <table styleName="pagination-container">
        <tbody>
          {!disablePagination && (
            <tr>
              <td styleName="load-more-wrapper" colSpan={displayHeaders.length}>
                <PaginationControl
                  incrementPage={incrementPage}
                  updateLimit={updateLimit}
                  page={page}
                  limit={limit}
                  total={totalRows}
                  limitOptions={limitOptions}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

ListViewTable.defaultProps = {
  sortableFields: [],
  sortBy: '',
  sort: false,
  sortRows: () => {},
  disablePagination: false,
};

export default ListViewTable;
