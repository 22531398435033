import React from 'react';
import {
  TableCell as MUITableCell,
  TableCellProps as MUITableCellProps,
  TableSortLabel as MUITableSortLabel,
  TableSortLabelProps as MUITableSortLabelProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as R from 'ramda';

import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';

type TableCellProps = {
  /**
   * Hard-codes the width of the table cell such that it will not shrink
   * or grow based on content.
   */
  width?: string | number;
  /**
   * Centers the content in the cell
   */
  center?: boolean;
  /**
   * Optional callback to be called when sort direction changes.
   */
  onSortChange?: (sort: 'asc' | 'desc') => void;
  /**
   * Content to render in the cell. If the cell variant is "head" and
   * the content is a string the cell width will be dynamically set to
   * ensure content fits without wrapping.
   */
  children?: React.ReactNode;
  /**
   * Optional CSS styles to be applied to the cell.
   */
  style?: React.CSSProperties;
  /**
   * Optional props to be passed to table sort label.
   */
  TableSortLabelProps?: MUITableSortLabelProps;
} & MUITableCellProps;

const styles = {
  head: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    alignItems: 'center',
  },
  icon: {
    fontSize: '1rem',
    marginLeft: '0.5rem',
  },
};

const getPadding = align =>
  align === 'right' ? '0.5rem 1.5rem 0.5rem 0' : '0.5rem 0 0.5rem 1.5rem';

const useStyles = makeStyles({
  // @ts-ignore
  root: ({ width, style, center, align }: TableCellProps) => ({
    padding: getPadding(align),
    minWidth: width,
    maxWidth: width,
    textAlign: center ? 'center' : 'left',
    ...style,
  }),
  head: ({ width, children, sortDirection, center, align }: TableCellProps) => {
    // ensures headers never wrap
    const minWidth =
      !width &&
      typeof children === 'string' &&
      `calc(${children.length + 2}ch + ${
        !R.isNil(sortDirection) ? '1.5rem' : '0px'
      })`;

    return {
      padding: getPadding(align),
      textAlign: center ? 'center' : 'left',
      background: colors.neutral.fog,
      color: !!sortDirection ? colors.neutral.dark : colors.neutral.gray,
      fontSize: fonts.sm,
      textTransform: 'uppercase',
      cursor: !R.isNil(sortDirection) ? 'pointer' : 'inherit',
      ...(minWidth && { minWidth }),
    };
  },
});

const TableCell = (props: TableCellProps) => {
  const classes = useStyles(props);

  const {
    variant,
    children,
    sortDirection,
    onSortChange,
    center,
    TableSortLabelProps = {},
    align,
    ...data
  } = props;

  const handleCellClick = () => {
    if (onSortChange) {
      onSortChange(sortDirection === 'asc' ? 'desc' : 'asc');
    }
  };

  return (
    <MUITableCell
      padding="none"
      variant={variant}
      sortDirection={sortDirection}
      onClick={handleCellClick}
      classes={{
        root: classes.root,
        head: classes.head,
        ...props.classes,
      }}
      {...data}
    >
      <div
        style={
          align === 'right'
            ? styles.headRight
            : center
            ? styles.headCenter
            : styles.head
        }
      >
        {children}
        {!R.isNil(sortDirection) && (
          <MUITableSortLabel
            active
            direction={sortDirection || 'desc'}
            IconComponent={() => (
              <Icon
                style={styles.icon}
                color={
                  !!sortDirection ? colors.neutral.black : colors.neutral.gray
                }
              >
                {sortDirection === 'asc'
                  ? 'arrow_upward_icon'
                  : 'arrow_downward_icon'}
              </Icon>
            )}
            {...TableSortLabelProps}
          />
        )}
      </div>
    </MUITableCell>
  );
};

export default TableCell;
