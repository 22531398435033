import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';

import { GET_USER, USER_UPDATE } from '@atom/graph/user';
import { GET_USER_GROUP_TREE } from '@atom/graph/userGroup';
import { Button, Icon, IconButton, Modal, Progress, Snackbar } from '@atom/mui';
import { getAllTreeIds } from '@atom/selectors/userGroupSelectors';
import { userSelector } from '@atom/selectors/userSelectors';
import colors from '@atom/styles/colors';
import { UserDetail, UserUpdateInput } from '@atom/types/user';
import { UserGroupTreeType } from '@atom/types/userGroups';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import ManageUserGroupsRow from './ManageUserGroupsRow';

import './manageUserGroupModal.css';

const styles = {
  arrowStyle: {
    fontSize: '24px',
    margin: 0,
    color: colors.neutral.gray,
  },
};

const baseUserGroup = {
  id: 'root',
  name: 'directory',
  colorId: 0,
  userGroups: [],
};

interface Props {
  open: boolean;
  onClose: () => void;
  userId: string;
  refetch: () => Promise<any>;
}

const ManageUserGroupsModal = ({ open, onClose, userId, refetch }: Props) => {
  const [openRow, setOpenRow] = useState<Set<string>>(new Set([]));
  const [disabledGroupIds, setDisabledGroupIds] = useState<Set<string>>(
    new Set([]),
  );
  const [selectedGroups, setSelectedGroups] = useState<Set<string>>(
    new Set([]),
  );

  const [
    getUserGroupTree,
    { data: userGroupTreeData, loading: userGroupTreeLoading },
  ] = useLazyQuery<{
    user: UserGroupTreeType;
  }>(GET_USER_GROUP_TREE, {
    fetchPolicy: 'network-only',
  });

  const [getUser, { data: userData, loading: userLoading }] = useLazyQuery<{
    user: UserDetail;
  }>(GET_USER, {
    fetchPolicy: 'network-only',
  });

  const [updateUser, { loading: updateLoading }] = useMutation<
    { userUpdate: UserDetail },
    { input: UserUpdateInput }
  >(USER_UPDATE, {
    onCompleted: () => {
      refetch();
    },
  });

  const user = userSelector(userData);
  const userGroupTree = R.pathOr(
    baseUserGroup,
    ['userGroupsTree'],
    userGroupTreeData,
  );

  useEffect(() => {
    if (open) {
      getUser({
        variables: {
          id: userId,
        },
      });
      getUserGroupTree();
    }
  }, [open]);

  useEffect(() => {
    setOpenRow(new Set([...getAllTreeIds(userGroupTree)]));
  }, [userGroupTree, open]);

  useEffect(() => {
    const selectedGroupIds = user.userGroups.map(userGroup => userGroup.id);
    setSelectedGroups(new Set([...selectedGroupIds]));
  }, [user]);

  const resetState = () => {
    setOpenRow(new Set([]));
    setSelectedGroups(new Set([]));
    setDisabledGroupIds(new Set([]));
  };

  const closeModal = () => {
    resetState();
    onClose();
  };

  const toggleOpen = (id: string) => {
    setOpenRow(toggleFromSet(openRow, id));
  };

  const toggleSelect = async (id: string) => {
    return setSelectedGroups(toggleFromSet(selectedGroups, id));
  };

  const confirm = async () => {
    try {
      await updateUser({
        variables: {
          input: {
            id: userId,
            userGroups: [...selectedGroups],
          },
        },
      });

      Snackbar.info({ message: 'Updated groups' });

      closeModal();
    } catch (error) {
      if (error?.networkError?.statusCode === 422) {
        Snackbar.error({
          message:
            'The user has unapproved time entries for the group you wish to remove',
        });
      }

      closeModal();
    }
  };

  const expanded = openRow.has(userGroupTree.id);
  const arrowDirection = expanded ? 'arrow_drop_down' : 'arrow_right';

  const getFooter = () => {
    return (
      <div styleName="footer">
        <Button onClick={closeModal} style={{ marginRight: '0.5rem' }}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={confirm}>
          Save
        </Button>
      </div>
    );
  };

  return (
    <Modal
      title="Manage User Groups"
      width="lg"
      open={open}
      footer={getFooter()}
      contentStyle={{ padding: 0 }}
    >
      <div styleName="tree-container">
        {updateLoading || userGroupTreeLoading || userLoading ? (
          <Progress style={{ height: '100%' }} />
        ) : (
          <>
            <div styleName="row">
              <div styleName="row-content">
                <div styleName="left-content">
                  <IconButton
                    iconStyle={styles.arrowStyle}
                    onClick={() => toggleOpen(userGroupTree.id)}
                  >
                    <Icon>{arrowDirection}</Icon>
                  </IconButton>
                  <div styleName="name-container">
                    <div styleName="title">{userGroupTree.name}</div>
                  </div>
                </div>
              </div>
            </div>
            {expanded && !userGroupTreeLoading && !userLoading ? (
              <>
                {userGroupTree.userGroups.map(
                  (userGroup: UserGroupTreeType) => (
                    <ManageUserGroupsRow
                      key={userGroup.id}
                      userGroup={userGroup}
                      indentation={0}
                      openRow={openRow}
                      selectedGroups={selectedGroups}
                      toggleOpen={toggleOpen}
                      toggleSelect={toggleSelect}
                      disabledGroupIds={disabledGroupIds}
                    />
                  ),
                )}
              </>
            ) : (
              <div />
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default ManageUserGroupsModal;
