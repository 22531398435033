import { gql } from '@apollo/client';

const BASE_WORK_FILTERS = gql`
  fragment BaseWorkFilters on ExportFilter {
    dueDateStart
    dueDateEnd
    withoutDueDate
    createdDateStart
    createdDateEnd
    createdByIds
    startDateStart
    startDateEnd
    completionDateStart
    completionDateEnd
    durationStart
    durationEnd
    priorityIds
    statusIds
    workTemplateIds
    userIds
    groupIds
    categoryIds
    assetId
    completedByIds
    hasWorkTemplate
    name
    assetSchemaId
    hasForms
    tasks {
      dueDateStart
      dueDateEnd
      startDateStart
      startDateEnd
      completionDateStart
      completionDateEnd
      durationStart
      durationEnd
      each {
        id
        fields {
          id
          valueStart
          valueEnd
          value
          values
        }
      }
    }
    fields {
      id
      valueStart
      valueEnd
      value
      values
    }
  }
`;

const BASE_EXPORT = gql`
  fragment BaseExport on Export {
    id
    name
    type
    filter {
      ...BaseWorkFilters
    }
    projections {
      id
      name
      selectedFields
    }
    workTemplate {
      id
      name
    }
    createdDate
    createdBy {
      id
      firstName
      lastName
      email
    }
    updatedDate
    updatedBy {
      id
      firstName
      lastName
      email
    }
  }
  ${BASE_WORK_FILTERS}
`;

export const GET_EXPORT = gql`
  query export($id: ID!) {
    export(id: $id) {
      ...BaseExport
    }
  }
  ${BASE_EXPORT}
`;

export const GET_EXPORTS = gql`
  query exports($input: ExportsConnectionInput!) {
    exports(input: $input) {
      exports {
        ...BaseExport
      }
      totalCount
    }
  }
  ${BASE_EXPORT}
`;

export const GET_PROJECTION_OPTIONS = gql`
  query projectionOptions($input: ProjectionOptionsConnectionInput!) {
    projectionOptions(input: $input) {
      projectionOptions {
        id
        name
        availableFields
        customFields
        type
      }
    }
  }
`;

export const EXPORT_CREATE = gql`
  mutation exportCreate($input: ExportCreateInput!) {
    exportCreate(input: $input) {
      ...BaseExport
    }
  }
  ${BASE_EXPORT}
`;

export const EXPORT_UPDATE = gql`
  mutation exportUpdate($input: ExportUpdateInput!) {
    exportUpdate(input: $input) {
      ...BaseExport
    }
  }
  ${BASE_EXPORT}
`;

export const EXPORT_EVALUATE = gql`
  mutation exportEvaluate($input: ExportEvaluateInput!) {
    exportEvaluate(input: $input) {
      ...BaseExport
    }
  }
  ${BASE_EXPORT}
`;

export const EXPORT_DELETE = gql`
  mutation exportDelete($id: ID!) {
    exportDelete(id: $id)
  }
`;
