import React, { useContext } from 'react';
import { DragDrop } from 'react-beautiful-dnd';
import * as R from 'ramda';

import { DragDropContext } from '@atom/components/common/dragAndDrop';
import {
  ColumnsData,
  DashboardCard,
  DashboardCardSize,
} from '@atom/types/dashboard';

import DashboardColumn from './DashboardColumn';
import DashboardContext from './DashboardContext';
import { getColumnsData } from './dashboardUtilities';

import './dashboard.css';

const DashboardGrid = () => {
  const {
    setAllCards,
    allCards,
    lgColumn,
    smColumn,
    updateLayout,
  } = useContext(DashboardContext);

  const onDragEnd = (result: DragDrop, col: DashboardCardSize) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    const updatedCards: DashboardCard[] = R.move(
      source.index,
      destination.index,
      col === 'LG' ? lgColumn : smColumn,
    ).map((card, idx) => ({ ...card, order: idx }));

    const newAllCards: DashboardCard[] = allCards.map(card => {
      return card.size !== col || card.order === -1
        ? card
        : {
            ...updatedCards.find(updatedCard => updatedCard.id === card.id),
          };
    });

    const updatedColumns: ColumnsData =
      col === 'LG'
        ? getColumnsData(updatedCards, smColumn)
        : getColumnsData(lgColumn, updatedCards);

    setAllCards(newAllCards);
    updateLayout(updatedColumns);
  };

  return (
    <div styleName="grid-container">
      {!R.isEmpty(lgColumn) && (
        <DragDropContext
          onDragEnd={result => onDragEnd(result, DashboardCardSize.LG)}
        >
          <DashboardColumn cards={lgColumn} size="large" />
        </DragDropContext>
      )}
      <DragDropContext
        onDragEnd={result => onDragEnd(result, DashboardCardSize.SM)}
      >
        <DashboardColumn cards={smColumn} size="small" />
      </DragDropContext>
    </div>
  );
};

export default DashboardGrid;
