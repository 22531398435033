import React from 'react';
import { Step as MUIStep, StepProps as MUIStepProps } from '@mui/material';

export type StepProps = MUIStepProps;

export const Step = (props: StepProps) => {
  return <MUIStep {...props} />;
};

export default Step;
