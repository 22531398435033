import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';

import { Snackbar } from '@atom/components/common/mui/snackbar/Snackbar';
import { Modal } from '@atom/mui';
import api from '@atom/utilities/api';
import { formatAssetAttributeFilters } from '@atom/utilities/assetUtilities';
import { INVENTORY_ASSETS_FILTER_ENDPOINT } from '@atom/utilities/endpoints';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

const styles = {
  input: {
    display: 'none',
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
  ids?: string[];
  schemaId?: string;
  categoryIds?: string[];
  hasPendingChangesOnly?: boolean;
  attributeFilters?: Object;
}

const BulkCoverPhotoModal = ({
  open,
  onClose,
  ids,
  schemaId,
  categoryIds,
  hasPendingChangesOnly,
  attributeFilters,
}: Props) => {
  const inputFile = useRef(null);

  const [files, setFiles] = useState<any>();

  useEffect(() => {
    if (open) {
      inputFile.current.click();
    }
  }, [open]);

  const resetState = () => {
    onClose();
    setFiles(null);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    const fileKeys = Object.keys(files);
    const widths = ['180', '1440'];

    fileKeys.forEach(key => {
      formData.append('files', files.item(key));
    });
    widths.forEach(width => {
      formData.append('widths', width);
    });
    if (!isNilOrEmpty(attributeFilters)) {
      formData.append(
        'attributeFilters',
        JSON.stringify(formatAssetAttributeFilters(attributeFilters)),
      );
    }

    try {
      Snackbar.info({ message: `Uploading file...` });

      const endpoint = `${INVENTORY_ASSETS_FILTER_ENDPOINT}/coverPhoto`;

      const filterParams = {
        ...(categoryIds ? { categoryIds: categoryIds.join(',') } : {}),
        ...(schemaId ? { schemaId } : {}),
        ...(!R.isNil(hasPendingChangesOnly) && { hasPendingChangesOnly }),
      };

      const selectedAssetsParams = {
        ...(ids ? { ids: ids.join(',') } : {}),
      };

      resetState();

      await api.post(
        endpoint,
        formData,
        isNilOrEmpty(ids) ? filterParams : selectedAssetsParams,
      );

      Snackbar.info({
        message: 'Successfully assigned cover photo to inventory items.',
      });
    } catch (error) {
      if (error.response && error.response.status !== 403) {
        Snackbar.error({
          message: 'Upload failed. Please try again.',
        });
      }

      resetState();
    }
  };

  const openModal = open && !isNilOrEmpty(files);

  return (
    <>
      <input
        id="icon-button-file"
        style={styles.input}
        type="file"
        ref={inputFile}
        onChange={event => setFiles(event.target.files)}
      />
      <Modal
        title="Assign Cover Photo"
        open={openModal}
        onConfirm={handleUpload}
        confirmButtonText="Upload"
        onCancel={resetState}
      >
        Are you sure you want to assign this cover photo to these inventory
        items?
      </Modal>
    </>
  );
};

export default BulkCoverPhotoModal;
