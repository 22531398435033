import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import { UPDATE_ROLE } from '@atom/graph/role';
import { Icon, Modal, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { Role, RoleUpdateInput } from '@atom/types/role';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

interface Props {
  open: boolean;
  onClose: () => void;
  role?: Role;
}

const HTTP_STATUS_CONFLICT = 409;

const styles = {
  modal: {
    padding: '1rem 2rem',
  },
  input: {
    margin: '1rem 0 0 0',
  },
  icon: {
    marginRight: '2rem',
    marginTop: '2rem',
  },
  row: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-start',
  },
  characterCount: {
    fontSize: '0.75rem',
    float: 'right',
  },
};

const EditRoleModal = ({ role, open, onClose }: Props) => {
  const [name, setName] = useState<string>(role?.name || '');
  const [description, setDescription] = useState<string>(
    role?.description || '',
  );
  const [errorText, setErrorText] = useState<string>('');

  const [editRole, { loading }] = useMutation<
    { roleUpdate: Role },
    { input: RoleUpdateInput }
  >(UPDATE_ROLE);

  const resetState = () => {
    setName(role?.name || '');
    setDescription(role?.description || '');
    setErrorText('');
  };

  const isValid = () => {
    return Boolean(name && description);
  };

  useEffect(() => {
    resetState();
  }, [role]);

  const handleConfirm = async () => {
    try {
      await editRole({
        variables: {
          input: {
            id: role.id,
            ...R.reject(isNilOrEmpty, {
              name,
              description,
            }),
          },
        },
      });

      onClose();
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_STATUS_CONFLICT) {
        setErrorText('This name is already in use.');
      }
    }
  };

  const countStyle = (inputLength, maxLength) =>
    inputLength > maxLength ? { color: colors.brand.red } : {};

  const characterLimitReached = name.length > 100 || description.length > 200;

  return (
    <Modal
      title="Edit Role"
      open={open}
      onCancel={onClose}
      onConfirm={handleConfirm}
      loading={loading}
      onExited={resetState}
      disabled={!isValid() || characterLimitReached}
      confirmButtonText="Save"
      contentStyle={styles.modal}
    >
      <div style={styles.row}>
        <Icon style={styles.icon}>
          <img src={renameIcon} />
        </Icon>
        <div style={{ width: '100%' }}>
          <TextField
            name="name"
            label="Name"
            value={name}
            onChange={event => setName(event.target.value)}
            error={!!errorText}
            helperText={errorText}
            style={styles.input}
          />
          <div style={styles.characterCount}>
            <span style={countStyle(name.length, 100)}>{name.length}/100</span>
          </div>
        </div>
      </div>
      <div style={styles.row}>
        <Icon style={styles.icon}>subject</Icon>
        <div style={{ width: '100%' }}>
          <TextField
            multiline
            name="description"
            label="Description"
            value={description}
            onChange={event => setDescription(event.target.value)}
            style={styles.input}
          />
          <div style={styles.characterCount}>
            <span style={countStyle(description.length, 200)}>
              {description.length}/200
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditRoleModal;
