import React from 'react';
import * as R from 'ramda';

import { gwrrSchema } from '@atom/constants/customReportSchemaConstants';
import colors from '@atom/styles/colors';
import { InventoryAssetTreeStateType } from '@atom/types/inventory';
import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';

import '../workOrderDetailPdfReport.css';

interface Props {
  element: InventoryAssetTreeStateType;
  schemaIds: string[];
  isAssessmentReport?: boolean;
}

const ElementAssessmentRow = ({
  element,
  schemaIds,
  isAssessmentReport,
}: Props) => {
  const getCellStyle = (schemaId: string) => {
    const attributesToCheck = R.pick(
      ['boredAtId', 'shellId', 'voidId'],
      gwrrSchema.poolAttributes,
    );

    const elementHasAttribute = R.keys(element.attributes).includes(schemaId);
    const isSpecificAttribute = R.values(attributesToCheck).includes(schemaId);

    return {
      backgroundColor:
        !elementHasAttribute && isSpecificAttribute
          ? colors.neutral.typhoon
          : colors.neutral.white,
    };
  };

  return (
    <tr styleName="table-value">
      <td>
        {attributeDisplayUtility.displayElementNameOnChange(
          element.name,
          element.changeType,
        )}
        {isAssessmentReport && <div>{element.assetType}</div>}
      </td>
      {schemaIds.map(key => (
        <td style={getCellStyle(key)}>
          {key &&
            attributeDisplayUtility.display(
              element?.attributes[key]?.value,
              element?.attributes[key]?.dataType,
              element?.attributes[key]?.unit,
              !!element?.attributes[key]?.changeId,
              element?.attributes[key]?.newValue,
            )}
        </td>
      ))}
    </tr>
  );
};

export default ElementAssessmentRow;
