import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_HISTORY } from '@atom/graph/history';
import { Icon, IconButton, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import { HistoryConnection, HistorySubject } from '@atom/types/history';

import HistoryItem from './HistoryItem';

import './history.css';

export interface Props {
  subject: HistorySubject;
  id: string;
}

const styles = {
  paginationIcon: {
    fill: colors.neutral.dim,
  },
};

const HistoryDrawer = ({ subject, id: subjectId }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [limit] = useState<number>(50);

  const [getHistory, { data, loading }] = useLazyQuery<{
    history: HistoryConnection;
  }>(GET_HISTORY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (open) {
      getHistory({
        variables: {
          input: { subject, subjectId, page, limit },
        },
      });
    } else {
      setPage(1);
    }
  }, [open, page]);

  const title =
    subject === 'workOrders' ? 'Work Activities' : 'Inventory Activities';

  const history = data?.history?.history || [];
  const total = data?.history?.totalCount || 0;

  const startRangeNumber = !total ? 0 : (page - 1) * limit + 1;
  const limitTotal = page * limit;
  const totalLessThanLimit = total <= limitTotal;
  const endRangeNumber = totalLessThanLimit ? total : limitTotal;

  return (
    <>
      <IconButton edge="end" tooltip="History" onClick={() => setOpen(true)}>
        <Icon color={colors.neutral.white}>history</Icon>
      </IconButton>
      <div styleName={`history-drawer-container ${open ? 'open' : ''}`}>
        <div styleName="drawer-header">
          <div styleName="drawer-title-container">
            <Icon>history</Icon>
            <div styleName="drawer-title">{title}</div>
          </div>
          <IconButton edge="end" onClick={() => setOpen(false)}>
            <Icon>close</Icon>
          </IconButton>
        </div>
        <div styleName="history-items-container">
          {loading ? (
            <Progress style={{ height: '100%' }} />
          ) : (
            history.map(item => <HistoryItem history={item} key={item.id} />)
          )}
          <div styleName="history-pagination-container">
            <div>{`${startRangeNumber}-${endRangeNumber} of ${total}`}</div>
            <div>
              <IconButton
                onClick={() => setPage(page - 1)}
                iconStyle={styles.paginationIcon}
                disabled={page === 1}
              >
                <Icon>chevron_left</Icon>
              </IconButton>
              <IconButton
                onClick={() => setPage(page + 1)}
                iconStyle={styles.paginationIcon}
                disabled={totalLessThanLimit}
              >
                <Icon>chevron_right</Icon>
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryDrawer;
