import actionTypes from './actionTypes';

export const retrievePoolAttributes = () => ({
  type: actionTypes.REQUEST_POOL_ATTRIBUTES,
});

export const getPoolAttributesSuccess = data => ({
  type: actionTypes.POOL_ATTRIBUTES_GET_SUCCESS,
  data,
});

export const getPoolAttributesFailure = () => ({
  type: actionTypes.POOL_ATTRIBUTES_GET_FAILURE,
});
