import * as React from 'react';

import { MapAsset } from '@atom/types/map';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';
import { getStaticImage } from '@atom/utilities/mapUtilities';
import markerUtilities from '@atom/utilities/markerUtilities';
import timeUtilities from '@atom/utilities/timeUtilities';

import './drawerContent.css';

interface Props {
  asset: MapAsset;
}

const AssetListItem = ({ asset }: Props) => {
  const { name, assetType, markerId, mainPhotoFileId, location } = asset;

  const getSummaryImage = () => {
    return !mainPhotoFileId
      ? getStaticImage(location, '60x60')
      : `${FILES_ENDPOINT}/${mainPhotoFileId}_60`;
  };

  return (
    <div styleName="list-item-v2">
      <div styleName="icon-container">
        <div
          styleName="image-container"
          style={{
            backgroundImage: `url('${getSummaryImage()}')`,
          }}
        />
      </div>
      <div styleName="description-container">
        <div styleName="heading">{name}</div>
        <div styleName="secondary medium">
          <img
            styleName="icon"
            src={markerUtilities.getInactiveAssetIconFromSchemaMarkerId(
              markerId,
            )}
          />
          {assetType}
        </div>
        <div styleName="secondary medium">{`Last Updated: ${timeUtilities.setDisplayDate(
          asset.updatedDate,
        )}`}</div>
      </div>
    </div>
  );
};

export default AssetListItem;
