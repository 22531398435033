import React from 'react';
import { useGoogleMap } from '@react-google-maps/api';
import * as R from 'ramda';
import { StringParam, useQueryParam, withQueryParams } from 'use-query-params';

import { MapParams } from '@atom/types/map';
import { formatMapData } from '@atom/utilities/mapUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import { useGoogleMapsStateValue } from './hooks/googleMapsStateHook';
import MapShapeMemo from './markers/MapShapeMemo';

enum View {
  ASSET = 'asset',
  USER = 'user',
  WORK_ORDER = 'workOrder',
  WORK_ORDERS = 'workOrders',
  LIST = 'list',
}

type Props = {
  data: string;
  // autoActive can only be used when there is a single marker on the map.
  // It denotes the id of the item that should be auto set to active on load.
  // Additionally it will remove the icon onClick
  autoActiveId?: string;
  // Optional onClick, will replace current param setting logic is passed
  onClick?: (id: string) => void;
  // By default the map interacts with center, zoom, id, searchPoint, and searchTerm
  // via URL Params (use-query-params library)
  // If you would like to instead store these in component state, you must pass in mapParams
  // and updateMapParams props. This will also need to be passed into <Map />
  mapParams?: MapParams;
  updateMapParams?: (mapParams: MapParams) => void;
};

const MapMarkers = ({
  data,
  autoActiveId = '',
  onClick,
  mapParams,
  updateMapParams,
}: Props) => {
  const map = useGoogleMap();
  const { grab } = useGoogleMapsStateValue();

  const clusterClick = position => {
    map.setZoom(map.getZoom() + 3);
    map.setCenter(position);
  };

  const [paramActiveId, setParamActiveId] = useQueryParam('id');
  const [, setView] = useQueryParam('view');
  const [paramHoverId] = useQueryParam('hover');

  const disabledIds = mapParams?.disabledIds || [];
  const activeIds = isNilOrEmpty(updateMapParams)
    ? [paramActiveId]
    : mapParams?.ids || [];
  const hoverId = isNilOrEmpty(updateMapParams)
    ? paramHoverId
    : mapParams?.hoverId;

  const autoActive = !isNilOrEmpty(autoActiveId);

  const mapData: any = formatMapData({
    activeIds: autoActive ? autoActiveId : activeIds.join(','),
    disabledIds: disabledIds.join(','),
    hoverId,
    clickable: !grab,
  })(
    // @ts-ignore
    JSON.parse(data),
  );

  const {
    assets = [],
    clusters = [],
    workOrders = [],
    users = [],
    assetGroups = [],
    workOrderGroups = [],
    locations = [],
  } = mapData || {};

  const includesDisabledIds = (id: string) => {
    const ids = id.split(',');
    const uniqueIds = R.intersection(disabledIds, ids);
    return uniqueIds.length === ids.length;
  };

  const onIconClick = (type: View, id: string) => {
    switch (true) {
      case includesDisabledIds(id):
        return () => {};
      case !!onClick || !!updateMapParams:
        return onClick(id);
      case !autoActive: {
        setParamActiveId(id);
        return setView(type);
      }
      default:
        return () => {};
    }
  };

  return (
    <div>
      {assets.map(asset => (
        <MapShapeMemo
          stringProps={JSON.stringify(asset)}
          key={asset.id}
          click={id => onIconClick(View.ASSET, id)}
        />
      ))}
      {clusters.map(cluster => (
        <MapShapeMemo
          stringProps={JSON.stringify(cluster)}
          key={cluster.id}
          click={() => clusterClick(cluster.position)}
        />
      ))}
      {workOrders.map(workOrder => (
        <MapShapeMemo
          stringProps={JSON.stringify(workOrder)}
          key={workOrder.id}
          click={id => onIconClick(View.WORK_ORDER, id)}
        />
      ))}
      {users.map(user => (
        <MapShapeMemo
          stringProps={JSON.stringify(user)}
          key={user.id}
          click={id => onIconClick(View.USER, id)}
        />
      ))}
      {assetGroups.map(assetGroup => (
        <MapShapeMemo
          stringProps={JSON.stringify(assetGroup)}
          key={assetGroup.id}
          click={id => onIconClick(View.LIST, id)}
        />
      ))}
      {workOrderGroups.map(workOrderGroup => (
        <MapShapeMemo
          stringProps={JSON.stringify(workOrderGroup)}
          key={workOrderGroup.id}
          click={id => onIconClick(View.WORK_ORDERS, id)}
        />
      ))}
      {locations.map(location => (
        <MapShapeMemo
          stringProps={JSON.stringify(location)}
          key={location.id}
          click={() => {}}
        />
      ))}
    </div>
  );
};

export default React.memo(
  withQueryParams(
    { id: StringParam, hover: StringParam, view: StringParam },
    // @ts-ignore
    MapMarkers,
  ),
);
