import React, { useContext, useMemo } from 'react';

import { useSchemas } from '@atom/hooks/useSchemas';
import { WorkOrderType } from '@atom/types/work';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import history from '@atom/utilities/history';
import {
  setDisplayDate,
  setDurationDisplay,
} from '@atom/utilities/timeUtilities';

import WorkOrderContext from '../WorkOrderContext';

import './workOrderDetailHeader.css';

const HeaderInfoSection = () => {
  const { workOrderDetail } = useContext(WorkOrderContext);
  const { schemas = [] } = useSchemas();

  const inventorySchemaName = useMemo(() => {
    const foundSchema = schemas.find(
      schema => schema.id === workOrderDetail?.inventoryAssetSchemaId,
    );

    return foundSchema?.name || '-';
  }, [workOrderDetail?.inventoryAssetSchemaId, schemas]);

  const navigateToInventoryDetail = () =>
    history.push(`/inventory/${workOrderDetail.inventoryAssetId}`);

  const inventoryNameOnClick = workOrderDetail?.inventoryAssetId
    ? navigateToInventoryDetail
    : () => {};

  const showActualCost = hasRolePermissions(ROLE_SETS.MANAGER);
  const showInventoryInfo =
    workOrderDetail?.inventoryAssetSchemaId &&
    workOrderDetail?.type === WorkOrderType.DEFAULT;

  return (
    <div styleName="header-info-section">
      {showInventoryInfo && (
        <div styleName="info-section-block">
          <div styleName="info-label-container">
            <div>Asset Name:</div>
            <div>Asset Type:</div>
          </div>
          <div>
            <div styleName="inventory-name" onClick={inventoryNameOnClick}>
              {workOrderDetail?.inventoryAssetName || '-'}
            </div>
            <div styleName="inventory-name">{inventorySchemaName}</div>
          </div>
        </div>
      )}
      <div styleName="info-section-block">
        <div styleName="info-label-container">
          <div>Start Date:</div>
          <div>Completed Date:</div>
        </div>
        <div>
          <div>{setDisplayDate(workOrderDetail.startTime)}</div>
          <div>{setDisplayDate(workOrderDetail.completionDate)}</div>
        </div>
      </div>
      <div styleName="info-section-block">
        <div styleName="info-label-container">
          <div>Due Date:</div>
          <div>Duration:</div>
        </div>
        <div>
          <div>{setDisplayDate(workOrderDetail.dueDate)}</div>
          <div>{setDurationDisplay(workOrderDetail.duration)}</div>
        </div>
      </div>
      <div styleName="info-section-block">
        <div styleName="info-label-container">
          <div>Estimated Cost:</div>
          {showActualCost && <div>Actual Cost:</div>}
        </div>
        <div>
          <div>{numberToLocaleString(workOrderDetail.estimatedCost)}</div>
          {showActualCost && (
            <div>{numberToLocaleString(workOrderDetail.actualCost)}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderInfoSection;
