import React from 'react';

import { Icon, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';

import './searchBar.css';

type Props = {
  loading: boolean;
  isEmpty: boolean;
  query: string;
  navigate: () => void;
};

const SearchResultsNavigationControl = ({
  loading,
  isEmpty,
  query,
  navigate,
}: Props) => {
  return (
    <div styleName="search-results-query-container">
      {loading ? (
        <div styleName="loading-container">
          <Progress size={25} />
        </div>
      ) : (
        <div styleName="search-results-query-title">
          {isEmpty ? (
            <div styleName="empty-search">No matching search results</div>
          ) : (
            <>
              <Icon color={colors.brand.blue}>search</Icon>
              <div styleName="search-results-query-text" onClick={navigate}>
                <span>See all results with </span>
                <strong>{query}</strong>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchResultsNavigationControl;
