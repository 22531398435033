import { UserDetail } from './user';

export enum ExportType {
  WORK = 'WORK',
}

export enum ProjectionType {
  WORK = 'WORK',
  TASK = 'TASK',
  FORM = 'FORM',
}

export interface Export {
  id: string;
  name: string;
  type: ExportType;
  filter: ExportFilter;
  projections: ExportProjection[];
  workTemplate?: {
    id: string;
    name: string;
  };
  createdDate: number;
  createdBy: UserDetail;
  updatedDate?: number;
  updatedBy?: UserDetail;
}

export interface ExportsConnection {
  exports: Export[];
  totalCount: number;
}

export interface ExportsConnectionInput {
  page: number;
  limit: number;
  type: ExportType;
}

export interface ProjectionOption {
  id: string;
  name: string;
  availableFields: string[];
  customFields: string[];
  type: ProjectionType;
}

export type ExportTaskFilter = {
  dueDateStart?: number;
  dueDateEnd?: number;
  startDateStart?: number;
  startDateEnd?: number;
  completionDateStart?: number;
  completionDateEnd?: number;
  durationStart?: number;
  durationEnd?: number;
  each?: ExportTaskFieldsFilter[];
};

export type ExportTaskFieldsFilter = {
  id: string;
  fields: ExportFieldFilter[];
};

export type ExportFieldFilter = {
  id?: string;
  valueStart?: number;
  valueEnd?: number;
  value?: string;
  values?: string[];
};

export type ExportFilter = {
  dueDateStart?: number;
  dueDateEnd?: number;
  withoutDueDate?: boolean;
  createdDateStart?: number;
  createdDateEnd?: number;
  createdByIds?: string[];
  startDateStart?: number;
  startDateEnd?: number;
  completionDateStart?: number;
  completionDateEnd?: number;
  durationStart?: number;
  durationEnd?: number;
  priorityIds?: number[];
  statusIds?: number[];
  workTemplateIds?: string[];
  tasks?: ExportTaskFilter;
  fields?: ExportFieldFilter[];
  userIds?: string[];
  groupIds?: string[];
  categoryIds?: string[];
  assetId?: string;
  completedByIds?: string[];
  hasWorkTemplate?: boolean;
  name?: string;
  assetSchemaId?: string;
  hasForms?: boolean;
  sortBy?: string;
  page?: number;
  limit?: number;
};

export interface ProjectionOptionsConnection {
  projectionOptions: ProjectionOption[];
}

export interface ProjectionOptionsConnectionInput {
  type: ExportType;
  filter: ExportFilter;
}

export interface ExportProjection {
  id: string;
  name: string;
  selectedFields: string[];
}

export interface ExportCreateInput {
  name: string;
  type: ExportType;
  filter: ExportFilter;
  projections: ExportProjection[];
}

export interface ExportUpdateInput {
  id: string;
  name: string;
  type: ExportType;
  filter: ExportFilter;
  projections: ExportProjection[];
}

export interface ExportInlineInput {
  save: boolean;
  export: ExportCreateInput;
}

export interface ExportEvaluateInput {
  exportId: string;
  inline?: ExportInlineInput;
}
