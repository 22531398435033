import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_TASK_UPDATE } from '@atom/graph/workTemplate';
import { Modal } from '@atom/mui';
import {
  WorkOrderTemplateTaskUpdateInput,
  WorkTemplateTaskItem,
} from '@atom/types/workTemplate';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import SchemaTree from './SchemaTree';

import './addSchemaModal.css';

const styles = {
  modal: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 2rem',
    height: '50vh',
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
}

const AddSchemaModal = ({ open, onClose }: Props) => {
  const { workTemplate, task, refetch } = useContext(WorkTemplateContext);

  const [selectedSchemaIds, setSelectedSchemaIds] = useState<Set<string>>(
    new Set([]),
  );

  const [taskTemplateUpdate, { loading }] = useMutation<
    { workOrderTemplateTaskUpdate: WorkTemplateTaskItem },
    { input: WorkOrderTemplateTaskUpdateInput }
  >(WORK_TEMPLATE_TASK_UPDATE);

  const resetState = () => {
    setSelectedSchemaIds(new Set([]));
  };

  const handleClose = () => {
    resetState();
    onClose();
  };

  const toggleSchema = (id: string) => {
    setSelectedSchemaIds(toggleFromSet(selectedSchemaIds, id));
  };

  const lockedSchemaIds = new Set([...R.pathOr([], ['schemaIds'], task)]);

  const handleSave = async () => {
    const schemaIds = [...lockedSchemaIds, ...selectedSchemaIds];

    await taskTemplateUpdate({
      variables: {
        input: {
          workTemplateId: workTemplate.id,
          taskId: task.id,
          schemaIds,
        },
      },
    });

    handleClose();
    refetch();
  };

  return (
    <Modal
      title="Add Asset Type"
      open={open}
      onCancel={handleClose}
      onExited={() => resetState()}
      confirmButtonText="Save"
      onConfirm={handleSave}
      contentStyle={styles.modal}
      loading={loading}
    >
      <SchemaTree
        rootSchemaId={workTemplate?.schemaId}
        toggleSchema={toggleSchema}
        selectedSchemaIds={selectedSchemaIds}
        lockedSchemaIds={lockedSchemaIds}
      />
    </Modal>
  );
};

export default AddSchemaModal;
