import React, { useContext } from 'react';
import * as R from 'ramda';

import WorkUsers from '@atom/components/common/workUsers/WorkUsers';
import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { WorkTemplateTaskItem } from '@atom/types/workTemplate';
import timeUtilities from '@atom/utilities/timeUtilities';

import './workTemplateTasks.css';

interface Props {
  task: WorkTemplateTaskItem;
}

const TaskListItem = ({ task }: Props) => {
  const { task: activeTask, setActiveTask } = useContext(WorkTemplateContext);

  const selectedStyleName =
    activeTask && task.id === activeTask.id
      ? 'task-list-item-container selected'
      : 'task-list-item-container';

  return (
    <>
      <div styleName={selectedStyleName} onClick={() => setActiveTask(task.id)}>
        <div styleName="task-list-content-container">
          <div styleName="task-title">{task.name}</div>
          {R.isEmpty(task.users) && R.isEmpty(task.userGroups) ? (
            <div styleName="no-assignees-message">No Assignees</div>
          ) : (
            <WorkUsers
              users={task.users as any}
              userGroups={task.userGroups as any}
              avatarSize={32}
            />
          )}
          <div styleName="task-dates">
            <div>{`Due Date: ${timeUtilities.setDisplayDate(
              task.dueDate,
            )}`}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskListItem;
