import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
// @ts-ignore
import folderMove from '@atom/components/common/svgIcons/folderMove.svg';
// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import {
  DELETE_ANALYTICS_CARD,
  MOVE_ANALYTICS_CARDS,
  UPDATE_ANALYTICS_CARD,
} from '@atom/graph/analytics';
import { Icon, Menu, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  AnalyticsCard,
  AnalyticsCardUpdateInput,
  AnalyticsFolderTree,
  MoveAnalyticsCardInput,
} from '@atom/types/analytics';

import EditAnalyticsCardModal from './analyticsModals/EditAnalyticsCardModal';
import MoveAnalyticsCardModal from './analyticsModals/MoveAnalyticsCardModal';
import AnalyticsContext from './AnalyticsContext';

import './analyticsFolderTree.css';

const { MenuItem } = Menu;

interface Props {
  card: AnalyticsCard;
  refetch: () => void;
}

const CardOptions = ({ card, refetch }: Props) => {
  const { setBulkMoveSelected } = useContext(AnalyticsContext);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [moveModalOpen, setMoveModalOpen] = useState<boolean>(false);

  const [updateCard] = useMutation<
    { analyticsCardUpdate: AnalyticsCard },
    { input: AnalyticsCardUpdateInput }
  >(UPDATE_ANALYTICS_CARD);

  const handleUpdateCard = async (input: AnalyticsCardUpdateInput) => {
    try {
      await updateCard({
        variables: {
          input,
        },
      });
      Snackbar.info({
        message: 'Updated.',
      });
      refetch();
      setBulkMoveSelected(new Set([]));
    } catch (error) {
      Snackbar.error({
        message: `An error occurred while updating "${card?.name}". Please Try Again.`,
      });
    } finally {
      setEditModalOpen(false);
    }
  };

  const [deleteCard] = useMutation<{}, { id: string }>(DELETE_ANALYTICS_CARD);

  const handleDeleteCard = async () => {
    try {
      await deleteCard({ variables: { id: card.id } });
      Snackbar.info({
        message: 'Successfully deleted card.',
      });
      refetch();
      setBulkMoveSelected(new Set([]));
    } catch (error) {
      Snackbar.error({
        message: `An error occurred". Please Try Again.`,
      });
    }
  };

  const [moveCard] = useMutation<{
    MoveAnalyticsCardInput: MoveAnalyticsCardInput;
  }>(MOVE_ANALYTICS_CARDS);

  const handleMoveCard = async (folder: AnalyticsFolderTree) => {
    try {
      await moveCard({
        variables: {
          input: { destFolderId: folder.id, analyticsIds: [card.id] },
        },
      });
      Snackbar.info({
        message: `Moved "${card.name}" to folder "${folder.name}"`,
      });
      refetch();
      setBulkMoveSelected(new Set([]));
      setMoveModalOpen(false);
    } catch (error) {
      Snackbar.error({
        message: `An error occurred". Please Try Again.`,
      });
    }
  };

  return (
    <div
      styleName="options-wrapper"
      data-cy={`analyticsCardMenu_${card?.name}`}
    >
      <Menu IconProps={{ color: colors.neutral.gray }}>
        <MenuItem
          styleName="menu-item"
          onClick={() => setMoveModalOpen(true)}
          data-cy="analyticsMoveCardOption"
        >
          <div styleName="primary-text-box">
            <div styleName="icon-box">
              <img src={folderMove} />
              <div styleName="primary-text">Move to Folder</div>
            </div>
          </div>
        </MenuItem>
        <MenuItem
          styleName="menu-item"
          onClick={() => setEditModalOpen(true)}
          data-cy="analyticsEditCardOption"
        >
          <div styleName="primary-text-box">
            <div styleName="icon-box">
              <img src={renameIcon} />
              <div styleName="primary-text">Edit</div>
            </div>
          </div>
        </MenuItem>
        <MenuItem
          styleName="menu-item"
          onClick={() => setDeleteModalOpen(true)}
          data-cy="analyticsDeleteCardOption"
        >
          <div styleName="primary-text-box">
            <div styleName="icon-box">
              <Icon color={colors.neutral.gray}>delete</Icon>
              <div styleName="primary-text">Delete</div>
            </div>
          </div>
        </MenuItem>
      </Menu>
      <MoveAnalyticsCardModal
        open={moveModalOpen}
        onClose={() => setMoveModalOpen(false)}
        handleMoveCard={handleMoveCard}
        title="Move to Folder"
      />
      <EditAnalyticsCardModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        handleUpdateCard={handleUpdateCard}
        card={card}
      />
      <DeleteModal
        open={deleteModalOpen}
        title="Delete Card"
        content="Are you sure you want to delete this card?"
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteCard}
      />
    </div>
  );
};

export default CardOptions;
