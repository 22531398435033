import React, { useContext } from 'react';

// @ts-ignore
import createWorkTypeIconBlue from '@atom/components/common/svgIcons/createWorkTypeIconBlue.svg';
import WorkTemplateCreationModalContext, {
  InheritanceType,
} from '@atom/components/common/workTemplateCreationModal/WorkTemplateCreationModalContext';

import RadioButtonRow from './RadioButtonRow';
import { INHERITANCE_OPTIONS } from './workTemplateOptions';

import './workTemplateCreationModal.css';

const InheritanceView = () => {
  const { inheritanceType, setInheritanceType } = useContext(
    WorkTemplateCreationModalContext,
  );

  return (
    <>
      <div styleName="modal-row title">Inheritance</div>
      <RadioButtonRow
        option={INHERITANCE_OPTIONS[InheritanceType.REGULAR]}
        selected={inheritanceType === InheritanceType.REGULAR}
        onClick={setInheritanceType}
      />
      <RadioButtonRow
        option={INHERITANCE_OPTIONS[InheritanceType.INHERITANCE]}
        selected={inheritanceType === InheritanceType.INHERITANCE}
        onClick={setInheritanceType}
      />
      {inheritanceType === InheritanceType.INHERITANCE && (
        <div styleName="inheritance-subtext">
          <div styleName="icon-container">
            <img src={createWorkTypeIconBlue} />
          </div>
          <div>
            To create a child work template, find a parent and go to "Create
            child Work Template" in the top right corner.
          </div>
        </div>
      )}
    </>
  );
};

export default InheritanceView;
