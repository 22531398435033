import React, { useState } from 'react';
import * as R from 'ramda';

import CreateWorkForAssetModal from '@atom/components/common/createWorkModals/CreateWorkForAssetModal';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { AssetDetail } from '@atom/types/inventory';
import { WorkCollectionItem } from '@atom/types/work';

import WorkRow from './WorkRow';

import './mapDrawerContent.css';

const getWorkSectionHeading = length => {
  if (!length) {
    return '0 work';
  }

  return `${length} work`;
};

interface Props {
  work: WorkCollectionItem[];
  asset: AssetDetail;
}

const AssetSummaryWork = ({ work, asset }: Props) => {
  const preferences = usePreferences();
  const requireWorkType = R.pathOr(
    false,
    ['workOrders', 'requireWorkType'],
    preferences,
  );
  const [open, setOpen] = useState<boolean>(false);
  const title = getWorkSectionHeading(work.length);

  return (
    <>
      <div styleName="work-container">
        <div styleName="work-heading-container">
          <div styleName="work-heading-title">{title}</div>
          <IconButton
            onClick={() => setOpen(true)}
            iconStyle={{ color: colors.neutral.gray }}
          >
            <Icon>add</Icon>
          </IconButton>
        </div>
        <div styleName="work-detail-section">
          {work.map(workOrder => (
            <WorkRow key={workOrder.id} work={workOrder} />
          ))}
        </div>
      </div>
      <CreateWorkForAssetModal
        redirectOnCreate
        open={open}
        onClose={() => setOpen(false)}
        // @ts-ignore
        asset={asset}
        workTemplateRequired={requireWorkType}
      />
    </>
  );
};

export default AssetSummaryWork;
