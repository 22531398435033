import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import * as R from 'ramda';

import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { WorkCollectionItem } from '@atom/types/work';
import api from '@atom/utilities/api';
import { WORK_ORDERS_ENDPOINT } from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';
import { getWorkOrderStatusCalendarColor } from '@atom/utilities/workOrderStatusUtilities';

import ScheduleCalendarEvent from './ScheduleCalendarEvent';
import ScheduleCalendarToolbar from './ScheduleCalendarToolbar';

import './schedule.css';

const styles = {
  calendar: {
    height: 'calc(100vh - 60px)',
  },
  event: {
    color: colors.neutral.dim,
    fontSize: fonts.sm,
    borderRadius: '2px',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
  },
};

interface CalendarFilters {
  calendarEnd: number;
  calendarStart: number;
}

interface WorkOrdersDictionary {
  [key: string]: WorkCollectionItem;
}

const getCalendarFilters = (view: string, viewDate: any): CalendarFilters => {
  switch (view) {
    case 'month': {
      return {
        calendarStart: moment(viewDate).startOf('month').valueOf(),
        calendarEnd: moment(viewDate).endOf('month').valueOf(),
      };
    }
    case 'week': {
      return {
        calendarStart: moment(viewDate).startOf('week').valueOf(),
        calendarEnd: moment(viewDate).endOf('week').valueOf(),
      };
    }
    case 'day': {
      return {
        calendarStart: moment(viewDate).startOf('day').valueOf(),
        calendarEnd: moment(viewDate).endOf('day').valueOf(),
      };
    }
    default: {
      return null;
    }
  }
};

const ScheduleCalendar = () => {
  const { state } = history.location;
  const [loading, setLoading] = useState<boolean>(false);
  const [workOrdersDictionary, setWorkOrdersDictionary] = useState<
    WorkOrdersDictionary
  >({});
  const [viewDate, setViewDate] = useState<any>(
    state?.calendarViewDate || new Date(),
  );
  const [view, setView] = useState<string>(state?.calendarView || 'month');
  const [calendarFilters, setCalendarFilters] = useState<CalendarFilters>(
    getCalendarFilters(view, viewDate),
  );

  useEffect(() => {
    setCalendarFilters(getCalendarFilters(view, viewDate));
  }, [view, viewDate]);

  useEffect(() => {
    const getWorkOrders = async () => {
      setLoading(true);
      const params = {
        page: 1,
        limit: 250,
        sortBy: 'name',
        isAscending: true,
        dueDateStart: calendarFilters.calendarStart,
        dueDateEnd: calendarFilters.calendarEnd,
      };

      const { data } = await api.get(WORK_ORDERS_ENDPOINT, params);

      const dictionary = data.reduce((acc, workOrder) => {
        return { ...acc, [workOrder.id]: workOrder };
      }, {});

      setWorkOrdersDictionary(dictionary);
      setLoading(false);
    };

    getWorkOrders();
  }, [calendarFilters.calendarStart, calendarFilters.calendarEnd]);

  const localizer = momentLocalizer(moment);

  const events = R.keys(workOrdersDictionary).map((workOrderId: string) => ({
    start: moment(workOrdersDictionary[workOrderId].dueDate).toDate(),
    end: moment(workOrdersDictionary[workOrderId].dueDate).toDate(),
    allDay: true,
    // Due to library constraints with passing props per event,
    // title is equal to the workOrder's id.
    title: workOrdersDictionary[workOrderId].id,
  }));

  const onSelectEvent = event => {
    history.push(`/workOrders/${event.title}`, {
      calendarView: view,
      calendarViewDate: viewDate,
    });
  };

  const getEventStyles = event => {
    const workOrder = workOrdersDictionary[event.title];
    const backgroundColor = getWorkOrderStatusCalendarColor(workOrder.statusId);

    return {
      style: {
        ...styles.event,
        backgroundColor,
      },
    };
  };

  return (
    <div styleName="pane right">
      <Calendar
        localizer={localizer}
        defaultDate={viewDate}
        defaultView={view}
        events={events}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={onSelectEvent}
        style={styles.calendar}
        components={{
          toolbar: props => (
            <ScheduleCalendarToolbar
              {...props}
              setViewDate={setViewDate}
              setView={setView}
              loading={loading}
            />
          ),
          event: props => (
            <ScheduleCalendarEvent
              {...props}
              workOrdersDictionary={workOrdersDictionary}
            />
          ),
        }}
        eventPropGetter={getEventStyles}
      />
    </div>
  );
};

export default ScheduleCalendar;
