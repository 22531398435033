import React, { useEffect, useState } from 'react';
import { LocalForm } from 'react-redux-form';
import * as R from 'ramda';

import textConstants from '@atom/constants/textConstants';
import { Snackbar } from '@atom/mui';
import {
  AssetDetail,
  AttributesType,
  HydratedAttributeGroupsItem,
} from '@atom/types/inventory';
import { PolicyAction } from '@atom/types/policy';
import { hasAccess } from '@atom/utilities/accessUtilities';
import api from '@atom/utilities/api';
import { INVENTORY_ASSETS_ENDPOINT } from '@atom/utilities/endpoints';
import { getGoogleShapeCenter } from '@atom/utilities/mapUtilities';
import timeUtilities from '@atom/utilities/timeUtilities';

import AttributeGroupRow from './AttributeGroupRow';
import InfoRow from './InfoRow';

import './mapDrawerContent.css';

export const mapAttributeValue = (value: any, dataType: string) => {
  switch (dataType) {
    case 'currency':
    case 'number': {
      return R.isEmpty(value) ? null : Number(value);
    }
    case 'date': {
      return R.isNil(value) ? value : value.valueOf();
    }
    case 'boolean': {
      return value === 'true';
    }
    default:
      return value;
  }
};

interface Props {
  asset: AssetDetail;
  refetch: () => void;
  // Denotes if component is being used in work view for add asset and task map views
  workView?: boolean;
}

const AssetSummaryInfo = ({ asset, workView, refetch }: Props) => {
  const [form, setForm] = useState<Object>({});
  const [loading, setLoading] = useState<any>({});
  const centerLocation = getGoogleShapeCenter(asset.location);

  const lastWorkOrderName =
    asset.lastWorkOrder && asset.lastWorkOrder.name
      ? asset.lastWorkOrder.name
      : '-';

  const lastWorkOrderUpdatedDate =
    asset.lastWorkOrder && asset.lastWorkOrder.updatedDate
      ? timeUtilities.setDisplayDate(asset.lastWorkOrder.updatedDate)
      : '-';

  const onChange = (updatedForm: any) => {
    setForm(updatedForm);
  };

  useEffect(() => {
    if (!workView) {
      refetch();
    }
  }, []);

  const onAttributesPendingChange = async (
    attributeId: string,
    changeId: string,
    action: string,
  ) => {
    try {
      Snackbar.info({
        message: textConstants.GENERIC_APPLICATION_UPDATING_TEXT,
      });

      await api.post(
        `${INVENTORY_ASSETS_ENDPOINT}/${asset.id}/attributes/${attributeId}/changes/${changeId}/${action}`,
      );

      Snackbar.info({
        message: textConstants.GENERIC_APPLICATION_UPDATED_TEXT,
        autoHideDuration: 3000,
      });

      refetch();
    } catch (error) {
      Snackbar.error({
        message:
          'Something went wrong. Please try again or contact an administrator.',
      });
    }
  };

  const onAttributesRemove = (attributeIdsToRemove: string[]) => {
    const updatedForm = R.omit(attributeIdsToRemove, form);
    setForm(updatedForm);
  };

  const onAttributesSave = async (attributeGroupName: string) => {
    const attributeGroup = asset.attributeGroups.find(
      (group: HydratedAttributeGroupsItem) => group.name === attributeGroupName,
    );
    const attributeGroupIds = attributeGroup.attributes.map(
      (attribute: AttributesType) => attribute.id,
    );
    const attributeGroupIdsToSave = Object.keys(form).filter(
      (updatedId: string): any =>
        attributeGroupIds.filter(
          (groupId: string): boolean => groupId === updatedId,
        ).length,
    );

    try {
      setLoading({ [attributeGroupName]: true });
      const updatedAttributes = attributeGroupIdsToSave.reduce(
        (previous: any, key: any): any => {
          return {
            ...previous,
            [key]: {
              ...attributeGroup.attributes.filter(attr => attr.id === key)[0],
              newValue: mapAttributeValue(
                form[key],
                attributeGroup.attributes.filter(attr => attr.id === key)[0]
                  .dataType,
              ),
            },
          };
        },
        {},
      );

      await api.patch(
        `${INVENTORY_ASSETS_ENDPOINT}/${asset.id}/attributes`,
        updatedAttributes,
      );

      refetch();

      Snackbar.info({
        message: textConstants.GENERIC_APPLICATION_UPDATED_TEXT,
        autoHideDuration: 3000,
      });
    } catch (error) {
      Snackbar.error({
        message:
          'Something went wrong. Please try again or contact an administrator.',
      });
    }
    onAttributesRemove(attributeGroupIds);
    setLoading({ [attributeGroupName]: false });
  };

  const canUpdate = hasAccess(PolicyAction.UPDATE, asset?.actions);
  const canManageChanges = hasAccess(
    PolicyAction.MANAGE_INVENTORY_CHANGES,
    asset?.actions,
  );

  return (
    <div styleName="info-container">
      <div styleName="basic-info">
        <InfoRow
          icon="location_on"
          main={asset.address}
          sub={`${centerLocation.lat}, ${centerLocation.lng}`}
        />
        <InfoRow
          icon="edit"
          main={timeUtilities.setDisplayDate(asset.updatedDate)}
          sub="Last Updated"
        />
        <InfoRow
          icon="work"
          main={`Work ID: ${lastWorkOrderName}`}
          sub={`Last Updated: ${lastWorkOrderUpdatedDate}`}
        />
      </div>
      <LocalForm initialState={{}} onChange={onChange}>
        {asset.attributeGroups.map(
          (attributeGroup: HydratedAttributeGroupsItem) => (
            <AttributeGroupRow
              key={attributeGroup.id}
              attributeGroup={attributeGroup}
              onAttributesPendingChange={onAttributesPendingChange}
              onAttributesSave={onAttributesSave}
              onAttributesRemove={onAttributesRemove}
              loading={loading[attributeGroup.name]}
              canManageChanges={canManageChanges}
              canUpdate={canUpdate}
            />
          ),
        )}
      </LocalForm>
    </div>
  );
};

export default AssetSummaryInfo;
