import turfCircle from '@turf/circle';
import { polygon as turfPolygon } from '@turf/helpers';

import {
  MapAreaUnit,
  MapDistanceUnit,
  MapShape,
  MapShapeType,
} from '@atom/types/map';

export const pointToArray = (point: google.maps.LatLng) => [
  point.lng(),
  point.lat(),
];

export const getPolygonBoundingBox = (polygon: google.maps.Polygon): any => {
  const coordinates = polygon.getPath().getArray().map(pointToArray);
  // ensure polygon is closed which google maps does not go
  const { geometry } = turfPolygon([[...coordinates, coordinates[0]]]);

  return geometry;
};

const getCircleBoundingBox = (circle: google.maps.Circle): any => {
  const { geometry } = turfCircle(
    pointToArray(circle.getCenter()),
    circle.getRadius(),
    {
      units: 'meters',
    },
  );

  return geometry;
};

// @ts-ignore
export const shapeIsMarker = (shape: MapShape): shape is google.maps.Marker => {
  return shape?.type === MapShapeType.MARKER;
};

// @ts-ignore
export const shapeIsCircle = (shape: MapShape): shape is google.maps.Circle => {
  return shape?.type === MapShapeType.CIRCLE;
};

export const shapeIsPolygon = (
  shape: MapShape,
  // @ts-ignore
): shape is google.maps.Polygon => {
  return shape?.type === MapShapeType.POLYGON;
};

export const shapeIsPolyline = (
  shape: MapShape,
  // @ts-ignore
): shape is google.maps.Polyline => {
  return shape?.type === MapShapeType.POLYLINE;
};

export const getMapShapeBoundingBox = (shape: MapShape): any[] => {
  if (shapeIsPolygon(shape)) {
    return getPolygonBoundingBox(shape)?.coordinates;
  }

  if (shapeIsCircle(shape)) {
    return getCircleBoundingBox(shape)?.coordinates;
  }

  return [];
};

export const convertFromMeterToUnit = (
  areaMeters: number,
  unit: MapDistanceUnit,
): number => {
  // 1 ft is 0.30484 m
  const METER_TO_FEET = 0.3048;
  // 1 mile is 1609.344 m
  const METER_TO_MILE = 1609.344;
  // 1 yard is 0.9144 m
  const METER_TO_YARD = 0.9144;

  switch (unit) {
    case MapDistanceUnit.METERS: {
      return areaMeters;
    }
    case MapDistanceUnit.FEET: {
      return areaMeters / METER_TO_FEET;
    }
    case MapDistanceUnit.MILES: {
      return areaMeters / METER_TO_MILE;
    }
    case MapDistanceUnit.YARDS: {
      return areaMeters / METER_TO_YARD;
    }
    default: {
      return 0;
    }
  }
};

export const getShapeDistance = (
  shape: MapShape,
  unit: MapDistanceUnit,
): number => {
  if (!shape || !unit) {
    return 0;
  }

  if (shapeIsCircle(shape)) {
    // getRadius returns radius in meters
    const radiusMeters = shape.getRadius();
    const circumference = 2 * Math.PI * radiusMeters;

    return convertFromMeterToUnit(circumference, unit);
  }

  if (shapeIsPolygon(shape) || shapeIsPolyline(shape)) {
    // method returns distance in meters
    const distanceMeters = google.maps.geometry.spherical.computeLength(
      shape.getPath().getArray(),
    );

    return convertFromMeterToUnit(distanceMeters, unit);
  }

  return 0;
};

export const convertFromSquareMeterToUnit = (
  areaMeters: number,
  unit: MapAreaUnit,
): number => {
  // 1 acre is 4046.856422 m^2
  const SQUARE_METER_TO_ACRE = 4046.856422;
  // 1 ft^2 is 0.09290304 m^2
  const SQUARE_METER_TO_SQUARE_FEET = 0.09290304;
  // 1 mile^2 is 2,589,988.110336 m^2
  const SQUARE_METER_TO_SQUARE_MILE = 2589988.110336;
  // 1 yard^2 is 0.836127 m^2
  const SQUARE_METER_TO_SQUARE_YARD = 0.836127;

  switch (unit) {
    case MapAreaUnit.METERS: {
      return areaMeters;
    }
    case MapAreaUnit.ACRES: {
      return areaMeters / SQUARE_METER_TO_ACRE;
    }
    case MapAreaUnit.FEET: {
      return areaMeters / SQUARE_METER_TO_SQUARE_FEET;
    }
    case MapAreaUnit.MILES: {
      return areaMeters / SQUARE_METER_TO_SQUARE_MILE;
    }
    case MapAreaUnit.YARDS: {
      return areaMeters / SQUARE_METER_TO_SQUARE_YARD;
    }
    default: {
      return 0;
    }
  }
};

export const getShapeArea = (shape: MapShape, unit: MapAreaUnit): number => {
  if (!shape || !unit) {
    return 0;
  }

  if (shapeIsCircle(shape)) {
    // getRadius returns radius in meters
    const radiusMeters = shape.getRadius();
    const areaMeters = Math.PI * radiusMeters * radiusMeters;

    return convertFromSquareMeterToUnit(areaMeters, unit);
  }

  if (shapeIsPolygon(shape) || shapeIsPolyline(shape)) {
    // method returns area in meters
    const areaMeters = google.maps.geometry.spherical.computeArea(
      shape.getPath(),
    );

    return convertFromSquareMeterToUnit(areaMeters, unit);
  }

  return 0;
};

export const getMapAreaUnitLabel = (type: MapAreaUnit): string => {
  switch (type) {
    case MapAreaUnit.METERS: {
      return 'Sq Meters';
    }
    case MapAreaUnit.ACRES: {
      return 'Acres';
    }
    case MapAreaUnit.FEET: {
      return 'Sq Feet';
    }
    case MapAreaUnit.MILES: {
      return 'Sq Miles';
    }
    case MapAreaUnit.YARDS: {
      return 'Sq Yards';
    }
    default: {
      return '';
    }
  }
};

export const getMapDistanceUnitLabel = (type: MapDistanceUnit): string => {
  switch (type) {
    case MapDistanceUnit.METERS: {
      return 'Meters';
    }
    case MapDistanceUnit.FEET: {
      return 'Feet';
    }
    case MapDistanceUnit.MILES: {
      return 'Miles';
    }
    case MapDistanceUnit.YARDS: {
      return 'Yards';
    }
    default: {
      return '';
    }
  }
};
