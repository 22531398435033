import { gql } from '@apollo/client';

export const BASE_DASHBOARD_CARD_FRAGMENT = gql`
  fragment BaseDashboardCard on DashboardCard {
    id
    userId
    type
    title
    size
    embedLink
  }
`;

export const GET_DASHBOARD_CARDS = gql`
  query dashboardCards($input: DashboardCardsConnectionInput!) {
    dashboardCards(input: $input) {
      ...BaseDashboardCard
    }
  }
  ${BASE_DASHBOARD_CARD_FRAGMENT}
`;

export const GET_DASHBOARD_CARDS_LAYOUT = gql`
  query dashboardCardsLayout($input: DashboardCardsConnectionInput!) {
    dashboardCardsLayout(input: $input) {
      id
      columns
    }
  }
`;

export const DASHBOARD_CARDS_LAYOUT_UPDATE = gql`
  mutation dashboardCardsLayoutUpdate(
    $input: DashboardCardsLayoutUpdateInput!
  ) {
    dashboardCardsLayoutUpdate(input: $input) {
      columns
    }
  }
`;

export const GET_RECENTLY_UPDATED_ITEMS = gql`
  query recentlyUpdatedItems($input: DashboardCardsConnectionInput!) {
    recentlyUpdatedItems(input: $input) {
      workOrderId
      workOrderName
      workTemplateName
      statusId
      assetId
      assetName
      schemaName
      isMaterial
      markerId
      userId
      firstName
      lastName
      email
      photoUrl
      itemType
      updatedDate
      colorId
    }
  }
`;

export const CREATE_DASHBOARD_CARD = gql`
  mutation dashboardCardCreate($input: DashboardCardCreateInput!) {
    dashboardCardCreate(input: $input) {
      ...BaseDashboardCard
    }
  }
  ${BASE_DASHBOARD_CARD_FRAGMENT}
`;

export const DELETE_DASHBOARD_CARD = gql`
  mutation dashboardCardDelete($input: DashboardCardDeleteInput!) {
    dashboardCardDelete(input: $input)
  }
`;

export const UPDATE_DASHBOARD_CARD = gql`
  mutation dashboardCardUpdate($input: DashboardCardUpdateInput!) {
    dashboardCardUpdate(input: $input) {
      ...BaseDashboardCard
    }
  }
  ${BASE_DASHBOARD_CARD_FRAGMENT}
`;
