import React, { useState } from 'react';
import * as R from 'ramda';

import { Icon, Menu } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { UserGroupTreeType } from '@atom/types/userGroups';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import history from '@atom/utilities/history';

import AddUsersToGroupModal from './AddUsersToGroupModal';
import CreateEditUserGroupModal from './CreateEditUserGroupModal';
import DeleteUserGroupModal from './DeleteUserGroupModal';

import './teamControls.css';

const { MenuItem } = Menu;

const styles = {
  iconButtonStyle: {
    padding: 0,
    margin: 0,
    border: 0,
  },
  iconStyle: {
    color: colors.neutral.gray,
  },
  menuItemStyle: {
    textTransform: 'capitalize',
    height: '2.25rem',
    padding: '0px 16px',
    overflow: 'hidden',
  },
  iconBox: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  primaryTextBox: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 0,
    padding: 0,
  },
  primaryText: {
    margin: '0 1rem',
    color: colors.neutral.dark,
    fontSize: fonts.md,
    fontWeight: '500',
  },
};

interface Props {
  userGroup: UserGroupTreeType;
}

const UserGroupOptions = ({ userGroup }: Props) => {
  const [createGroupOpen, setCreateGroupOpen] = useState<boolean>(false);
  const [editGroupOpen, setEditGroupOpen] = useState<boolean>(false);
  const [deleteGroupOpen, setDeleteGroupOpen] = useState<boolean>(false);
  const [userOpen, setUserOpen] = useState<boolean>(false);

  const navigateToGroupDetail = () => {
    history.push(`/team/group/${userGroup.id}`);
  };

  return (
    <div styleName="options-wrapper">
      <Menu
        IconProps={{ color: colors.neutral.gray }}
        IconButtonProps={{ style: styles.iconButtonStyle }}
      >
        <MenuItem style={styles.menuItemStyle} onClick={navigateToGroupDetail}>
          <div style={styles.primaryTextBox}>
            <div style={styles.iconBox}>
              <Icon color={colors.neutral.gray}>settings</Icon>
              <div style={styles.primaryText}>Group Details</div>
            </div>
          </div>
        </MenuItem>
        {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
          <MenuItem
            style={styles.menuItemStyle}
            onClick={() => setCreateGroupOpen(true)}
          >
            <div style={styles.primaryTextBox}>
              <div style={styles.iconBox}>
                <Icon color={colors.neutral.gray}>group_add</Icon>
                <div style={styles.primaryText}>Create Group</div>
              </div>
            </div>
          </MenuItem>
        )}
        {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
          <MenuItem
            style={styles.menuItemStyle}
            onClick={() => setUserOpen(true)}
          >
            <div style={styles.primaryTextBox}>
              <div style={styles.iconBox}>
                <Icon color={colors.neutral.gray}>person_add</Icon>
                <div style={styles.primaryText}>Add Users To Group</div>
              </div>
            </div>
          </MenuItem>
        )}
        {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
          <MenuItem
            style={styles.menuItemStyle}
            onClick={() => setEditGroupOpen(true)}
          >
            <div style={styles.primaryTextBox}>
              <div style={styles.iconBox}>
                <Icon color={colors.neutral.gray}>edit</Icon>
                <div style={styles.primaryText}>Edit</div>
              </div>
            </div>
          </MenuItem>
        )}
        {R.isEmpty(userGroup.userGroups) &&
          hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
            <MenuItem
              style={styles.menuItemStyle}
              onClick={() => setDeleteGroupOpen(true)}
            >
              <div style={styles.primaryTextBox}>
                <div style={styles.iconBox}>
                  <Icon color={colors.neutral.gray}>delete</Icon>
                  <div style={styles.primaryText}>Delete</div>
                </div>
              </div>
            </MenuItem>
          )}
      </Menu>
      <CreateEditUserGroupModal
        open={createGroupOpen}
        onClose={() => setCreateGroupOpen(false)}
        groupId={userGroup.id}
      />
      <CreateEditUserGroupModal
        open={editGroupOpen}
        onClose={() => setEditGroupOpen(false)}
        groupId={userGroup.id}
        groupColorId={userGroup.colorId}
        groupName={userGroup.name}
        isEdit
      />
      <AddUsersToGroupModal
        open={userOpen}
        onClose={() => setUserOpen(false)}
        userGroup={userGroup}
      />
      <DeleteUserGroupModal
        open={deleteGroupOpen}
        onClose={() => setDeleteGroupOpen(false)}
        userGroup={userGroup}
      />
    </div>
  );
};

export default UserGroupOptions;
