import React from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import history from '@atom/utilities/history';

import '../../styles/detail-header.css';

const WorkExportsHeader = () => {
  const navigateBack = (): void => {
    return history.goBackWithState('/workOrders');
  };

  return (
    <div styleName="header-container">
      <IconButton onClick={navigateBack}>
        <Icon color={colors.neutral.white}>arrow_back</Icon>
      </IconButton>
      <div styleName="name-container no-padding">
        <span>Work Export</span>
      </div>
    </div>
  );
};

export default WorkExportsHeader;
