import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import WorkTypeFolderTree from '@atom/components/common/workTemplateFolderTree/WorkTypeFolderTree';
import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_CREATE } from '@atom/graph/workTemplate';
import { Button, Modal, Snackbar } from '@atom/mui';
import {
  InheritedComponentType,
  WorkOrderTemplateCreateInput,
  WorkTemplate,
  WorkTemplateFolderTree,
} from '@atom/types/workTemplate';
import history from '@atom/utilities/history';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import ChildCreateInheritance from './ChildCreateInheritance';
import ChildCreateMain from './ChildCreateMain';
import ChildCreateTitle from './ChildCreateTitle';

import './workTemplateChildCreate.css';

const HTTP_RESOURCE_CONFLICT = 409;
const EMPTY_SCHEMA = 'empty';

const styles = {
  modalContent: {
    padding: 0,
  },
};

enum View {
  MAIN = 'MAIN',
  WORK_TYPE_FOLDER = 'WORK_TYPE_FOLDER',
  INHERITANCE = 'INHERITANCE',
}

interface Props {
  open: boolean;
  onClose: () => void;
}

const WorkTemplateChildCreate = ({ open, onClose }: Props) => {
  const { workTemplate } = useContext(WorkTemplateContext);

  const [view, setView] = useState<View>(View.MAIN);
  const [name, setName] = useState<string>(`Child of ${workTemplate?.name}`);
  const [workTemplateFolder, setWorkTemplateFolder] = useState<
    WorkTemplateFolderTree
  >();
  const [
    additionalInheritedComponents,
    setAdditionalInheritedComponents,
  ] = useState<Set<InheritedComponentType>>(new Set([]));
  const [error, setError] = useState<string>('');

  const [workOrderTemplateCreate, { loading: createLoading }] = useMutation<
    { workOrderTemplateCreate: WorkTemplate },
    { input: WorkOrderTemplateCreateInput }
  >(WORK_TEMPLATE_CREATE);

  const resetState = () => {
    setView(View.MAIN);
    setName(`Child of ${workTemplate?.name}`);
    setAdditionalInheritedComponents(new Set([]));
    setWorkTemplateFolder(null);
    setError('');
  };

  useEffect(() => {
    if (open) {
      resetState();
    }
  }, [open]);

  const onModalClose = () => {
    resetState();
    onClose();
  };

  const confirm = async () => {
    try {
      const tasks = workTemplate?.tasks.map(task => {
        return { id: task?.id, name: task?.name };
      });

      const { data } = await workOrderTemplateCreate({
        variables: {
          input: {
            name,
            folderId: workTemplateFolder.id,
            ...(workTemplate.schemaId && { schemaId: workTemplate.schemaId }),
            ...(!isNilOrEmpty(tasks) && { tasks }),
            parentId: workTemplate.id,
            isParent: false,
            additionalInheritedComponents: [...additionalInheritedComponents],
          },
        },
      });

      Snackbar.info({
        message: `Created work template ${data?.workOrderTemplateCreate?.name}.`,
        action: 'View',
        onActionClick: () =>
          history.push(`/work-templates/${data?.workOrderTemplateCreate?.id}`),
      });

      onModalClose();
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_RESOURCE_CONFLICT) {
        setView(View.MAIN);
        setError('This name is already in use.');
      } else {
        Snackbar.error({ message: 'An unknown error occurred' });
        onModalClose();
      }
    }
  };

  const isNextDisabled = R.isEmpty(name) || R.isNil(workTemplateFolder);

  const getFooter = () => {
    const footers = {
      [View.MAIN]: (
        <>
          <Button onClick={onModalClose}>Cancel</Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setView(View.INHERITANCE)}
            disabled={isNextDisabled}
          >
            Next
          </Button>
        </>
      ),
      [View.WORK_TYPE_FOLDER]: (
        <Button onClick={onModalClose} style={{ marginRight: '0.5rem' }}>
          Cancel
        </Button>
      ),
      [View.INHERITANCE]: (
        <div styleName="modal-footer">
          <Button onClick={() => setView(View.MAIN)}>Back</Button>
          <div>
            <Button onClick={onModalClose} style={{ marginRight: '0.5rem' }}>
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={confirm}>
              Create
            </Button>
          </div>
        </div>
      ),
    };

    return footers[view];
  };

  const getContent = () => {
    const contents = {
      [View.MAIN]: (
        <ChildCreateMain
          name={name}
          setName={setName}
          error={error}
          workTemplateFolder={workTemplateFolder}
          setWorkTemplateFolder={setWorkTemplateFolder}
          schemaId={workTemplate?.schemaId || EMPTY_SCHEMA}
          setView={setView}
          setError={setError}
        />
      ),
      [View.WORK_TYPE_FOLDER]: (
        <WorkTypeFolderTree
          setWorkTemplateFolder={setWorkTemplateFolder}
          setActiveView={() => setView(View.MAIN)}
        />
      ),
      [View.INHERITANCE]: (
        <ChildCreateInheritance
          additionalInheritedComponents={additionalInheritedComponents}
          setAdditionalInheritedComponents={setAdditionalInheritedComponents}
        />
      ),
    };

    return contents[view];
  };

  return (
    <Modal
      open={open}
      footer={getFooter()}
      title={<ChildCreateTitle view={view} setView={setView} />}
      contentStyle={styles.modalContent}
      loading={createLoading}
    >
      {getContent()}
    </Modal>
  );
};

export default WorkTemplateChildCreate;
