import React, { useContext } from 'react';

import { Icon } from '@atom/mui';
import { formTemplateContainsModule } from '@atom/selectors/formModuleSelectors';
import { FormModuleKeys, FormModuleType } from '@atom/types/form';

import FormBuilderContext from '../FormBuilderContext';

import '../formBuilder.css';

interface Props {
  formModule: FormModuleType;
  selectedModule: string;
  selectModule: (data: string) => void;
  disabled?: boolean;
  displayOnly?: boolean;
}

const FormModuleTile = ({
  formModule,
  selectedModule,
  selectModule,
  disabled,
  displayOnly,
}: Props) => {
  const { formTemplate } = useContext(FormBuilderContext);

  const isAdded = formTemplateContainsModule(formModule.type, formTemplate);

  const isSelected = selectedModule === formModule.type;
  const selectedStyle = isSelected ? 'selected' : '';
  const disabledStyle = disabled || displayOnly ? 'disabled' : '';
  const showNextIcon =
    !isAdded && formModule.type === FormModuleKeys.CI && !displayOnly;

  const onClick = () => {
    return disabled || displayOnly ? {} : selectModule(formModule.type);
  };

  return (
    <div
      styleName={`form-module-item ${selectedStyle} ${disabledStyle}`}
      onClick={onClick}
    >
      {formModule.icon}
      <div styleName="form-module-info">
        <div>
          <div
            styleName={`form-module-modal-title ${disabled ? 'disabled' : ''}`}
          >
            {formModule.title}
          </div>
          <div
            styleName={`form-module-description ${disabled ? 'disabled' : ''}`}
          >
            {formModule.description}
          </div>
        </div>
        {isAdded && <div styleName="form-module-added">Added</div>}
        {showNextIcon && <Icon>navigate_next</Icon>}
      </div>
    </div>
  );
};

export default FormModuleTile;
