import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { MenuItem } from '@mui/material';
import * as R from 'ramda';

// @ts-ignore
import awesomeIcon from '@atom/components/common/svgIcons/awesomeIcon.svg';
// @ts-ignore
import favoriteListIcon from '@atom/components/common/svgIcons/favoriteListIcon.svg';
import {
  DELETE_FAVORITES_LIST,
  UPDATE_FAVORITES_LIST,
} from '@atom/graph/favoritesList';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { Checkbox, Icon, List, Menu, Progress, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import {
  FavoriteDetail,
  FavoritesList,
  FavoritesListDeleteInput,
  FavoritesListUpdateInput,
  FavoriteSubjectType,
} from '@atom/types/favorite';
import {
  combineSets,
  diffSets,
  toggleFromSet,
} from '@atom/utilities/setUtilities';
import { pluralize } from '@atom/utilities/stringUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import DeleteFavoritesListModal from './DeleteFavoritesListModal';
import FavoriteItem from './FavoriteItem';
import NewFavoritesListButton from './NewFavoritesListButton';
import NewFavoritesListModal from './NewFavoritesListModal';
import RenameFavoritesListModal from './RenameFavoritesListModal';

import './favoritesListsTab.css';
const { ListItemButton, ListItemText } = List;

const styles = {
  toggle: {
    cursor: 'pointer',
    marginRight: '0.5rem',
  },
  listButton: {
    gap: '0.5rem',
  },
  menuText: {
    margin: '0 1rem',
    color: colors.neutral.dark,
    fontSize: fonts.md,
    fontWeight: '500',
  },
};

export interface Props {
  favoritesLists: FavoritesList[];
  subjectType: FavoriteSubjectType;
  selectedFavoriteIds: Set<string>;
  disabledFavoriteIds: Set<string>;
  setSelectedFavoriteIds: (ids: Set<string>) => void;
  handleFavoriteToggle: (
    event: React.MouseEvent<any>,
    favorite: FavoriteDetail,
  ) => void;
  updateFavorite: (favorite: FavoriteDetail) => Promise<void>;
  allFavorites: FavoriteDetail[];
  handleAddList: (listName: string) => void;
  canAddFavorite: boolean;
  canAddList: boolean;
  favoritesLoading: boolean;
  favoritesError: any;
  handleGetFavorites: () => void;
}

const FavoritesListsTab = ({
  subjectType,
  favoritesLists,
  updateFavorite,
  allFavorites,
  handleFavoriteToggle,
  handleAddList,
  canAddFavorite,
  canAddList,
  favoritesLoading,
  favoritesError,
  selectedFavoriteIds,
  disabledFavoriteIds,
  setSelectedFavoriteIds,
  handleGetFavorites,
}: Props) => {
  const [openNewListModal, setOpenNewListModal] = useState<boolean>(false);

  const [deleteModalList, setDeleteModalList] = useState<FavoritesList | null>(
    null,
  );
  const [renameModalList, setRenameModalList] = useState<FavoritesList | null>(
    null,
  );

  const [openLists, setOpenLists] = useState<Set<string>>(new Set([]));
  const [selectedListIds, setSelectedListIds] = useState<Set<string>>(
    new Set(),
  );
  const userProfile = useUserProfile();

  const handleListToggle = list => {
    const listIsSelected = !selectedListIds.has(list.id);

    // Toggle contained favorites that are enabled
    const containedFavoriteIds = list.favorites.map(favorite => favorite.id);
    const newSelectedItemIds = diffSets(
      listIsSelected
        ? combineSets(selectedFavoriteIds, containedFavoriteIds)
        : diffSets(selectedFavoriteIds, containedFavoriteIds),
      disabledFavoriteIds,
    );

    setSelectedListIds(toggleFromSet(selectedListIds, list.id));
    setSelectedFavoriteIds(newSelectedItemIds);
  };

  const closeAndHandleAddList = (listName: string): void => {
    setOpenNewListModal(false);
    handleAddList(listName);
  };

  const handleListToggleOpen = (event: React.MouseEvent<any>, id: string) => {
    event.stopPropagation();
    setOpenLists(toggleFromSet(openLists, id));
  };

  const listDisabled = (list: FavoritesList): boolean => {
    const allFavoritesDisabled = list.favorites.every(favorite =>
      disabledFavoriteIds.has(favorite.id),
    );
    return allFavoritesDisabled || isNilOrEmpty(list.favorites);
  };

  const listSubtext = (list: FavoritesList): string => {
    const itemCount = list.favorites?.length || 0;
    const typeString = subjectType === 'MATERIAL' ? 'Item' : 'User';
    const countLabel = pluralize(itemCount, typeString);
    return `${itemCount} ${countLabel}`;
  };

  const listIcon = (list: FavoritesList): string =>
    R.isEmpty(list.favorites)
      ? 'blank_icon'
      : openLists.has(list.id)
      ? 'arrow_drop_down'
      : 'arrow_right';

  const showMenu = (list: FavoritesList): boolean =>
    list.id !== 'myFavorites' &&
    renameModalList === null &&
    deleteModalList === null;

  const favoritesListNames = favoritesLists.map(list => list.name);

  const handleNewListClick = event => {
    event.stopPropagation();
    setOpenNewListModal(canAddList);
  };

  const [deleteList] = useMutation<{ input: FavoritesListDeleteInput }>(
    DELETE_FAVORITES_LIST,
    {
      onCompleted: () => handleGetFavorites(),
    },
  );

  const [updateList] = useMutation<{ input: FavoritesListUpdateInput }>(
    UPDATE_FAVORITES_LIST,
    {
      onCompleted: () => handleGetFavorites(),
    },
  );

  const handleDeleteList = async () => {
    try {
      await deleteList({
        variables: {
          input: {
            userId: userProfile.userId,
            id: deleteModalList.id,
          },
        },
      });
      setDeleteModalList(null);
    } catch (err) {
      const status = err?.networkError?.statusCode;
      let message = 'Failed to add list. Please try again.';
      if (status === 409) {
        message = 'A list with that name already exists';
      }
      Snackbar.error({ message });
    }
  };

  const handleRenameList = async (listName: string) => {
    try {
      await updateList({
        variables: {
          input: {
            userId: userProfile.userId,
            id: renameModalList.id,
            name: listName,
          },
        },
      });
      setRenameModalList(null);
    } catch (err) {
      const status = err?.networkError?.statusCode;
      let message = 'Failed to rename list. Please try again.';
      if (status === 409) {
        message = 'A list with that name already exists';
      }
      Snackbar.error({ message });
    }
  };

  const isChecked = list => {
    if (
      list.favorites.every(favorite => !selectedFavoriteIds.has(favorite.id))
    ) {
      if (selectedListIds.has(list.id)) {
        // Last favorite was unselected from selected list, toggle list
        handleListToggle(list);
      }
      return false;
    }
    if (
      list.favorites.every(
        favorite =>
          selectedFavoriteIds.has(favorite.id) ||
          disabledFavoriteIds.has(favorite.id),
      )
    ) {
      if (!selectedListIds.has(list.id)) {
        // Last available favorite selected from unselected list, toggle list
        handleListToggle(list);
      }
      return true;
    }
    return selectedListIds.has(list.id);
  };

  const emptyItemName =
    subjectType === 'MATERIAL' ? ' equipment and material ' : ' users ';

  return (
    <div styleName="favorites-list">
      <div styleName="new-favorite-list-container">
        <NewFavoritesListButton
          canAddList={canAddList}
          handleClick={handleNewListClick}
        />
        <NewFavoritesListModal
          open={openNewListModal}
          onClose={() => setOpenNewListModal(false)}
          handleAddList={closeAndHandleAddList}
          favoritesListNames={favoritesListNames}
        />
      </div>
      {favoritesLists.length > 0 && (
        <List>
          {favoritesLists.map((list: FavoritesList) => (
            <div key={list.id}>
              <ListItemButton style={styles.listButton}>
                <Icon
                  onClick={event => handleListToggleOpen(event, list.id)}
                  style={styles.toggle}
                >
                  {listIcon(list)}
                </Icon>
                <Checkbox
                  disabled={listDisabled(list)}
                  checked={isChecked(list)}
                  onClick={() => handleListToggle(list)}
                />
                <img src={favoriteListIcon} />
                <ListItemText
                  primary={list.name}
                  secondary={listSubtext(list)}
                />
                {showMenu(list) && (
                  <Menu
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                  >
                    <MenuItem onClick={() => setRenameModalList(list)}>
                      <Icon color={colors.neutral.gray}>edit</Icon>
                      <div style={styles.menuText}>Rename</div>
                    </MenuItem>
                    <MenuItem onClick={() => setDeleteModalList(list)}>
                      <Icon color={colors.neutral.gray}>delete</Icon>
                      <div style={styles.menuText}>Delete</div>
                    </MenuItem>
                  </Menu>
                )}
              </ListItemButton>
              {openLists.has(list.id) &&
                !isNilOrEmpty(list.favorites) &&
                list.favorites.map(favorite => (
                  <FavoriteItem
                    key={favorite.id}
                    subjectType={subjectType}
                    handleAddList={handleAddList}
                    handleFavoriteToggle={handleFavoriteToggle}
                    handleListToggle={handleListToggle}
                    favorite={favorite}
                    allFavorites={allFavorites}
                    favoritesLists={favoritesLists}
                    updateFavorite={updateFavorite}
                    canAddFavorite={canAddFavorite}
                    canAddList={canAddList}
                    selectedFavoriteIds={selectedFavoriteIds}
                    disabledFavoriteIds={disabledFavoriteIds}
                  />
                ))}
            </div>
          ))}
          <RenameFavoritesListModal
            open={renameModalList !== null}
            onClose={() => setRenameModalList(null)}
            handleRenameList={handleRenameList}
            list={renameModalList}
            favoritesListNames={favoritesListNames}
          />
          <DeleteFavoritesListModal
            open={deleteModalList !== null}
            onClose={() => setDeleteModalList(null)}
            handleDeleteList={handleDeleteList}
            name={deleteModalList?.name}
          />
        </List>
      )}
      {favoritesLists?.length === 1 && (
        <div styleName="favorites-list empty-favorites-list">
          <div styleName="icon-col">
            <img src={awesomeIcon} />
          </div>
          <div>
            Organize your favorite {emptyItemName} into lists for quick addition
            to tasks. You can add up to 250 favorites in 50 lists.
          </div>
        </div>
      )}
      {(favoritesLoading || favoritesError) && <Progress />}
    </div>
  );
};

export default FavoritesListsTab;
