import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
import TaskItemTile from '@atom/components/common/workOrderDetail/taskItemTile/TaskItemTile';
import TaskContext from '@atom/components/workOrderPreview/taskView/TaskContext';
import WorkOrderPreviewContext from '@atom/components/workOrderPreview/WorkOrderPreviewContext';
import { TASK_UPDATE } from '@atom/graph/task';
import { Icon, IconButton, Snackbar } from '@atom/mui';
import { Task, TaskCategory, TaskUpdateInput } from '@atom/types/task';

import './materialSection.css';

interface Props {
  category: TaskCategory;
  onClick: () => void;
}

const styles = {
  container: {
    marginRight: '0',
  },
};

const CategoryTile = ({ category, onClick }: Props) => {
  const { id, name } = category;
  const { task } = useContext(TaskContext);
  const { workOrderDetail, refetch } = useContext(WorkOrderPreviewContext);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [updateTask, { loading }] = useMutation<
    { task: Task },
    { input: TaskUpdateInput }
  >(TASK_UPDATE);

  const handleDeleteConfirm = async () => {
    const categories = task.categories.filter(
      ({ id: categoryId }) => categoryId !== id,
    );
    const categoryIds = categories.map(({ id: categoryId }) => categoryId);

    setDeleteModalOpen(false);

    Snackbar.info({
      message: 'Deleting...',
    });

    await updateTask({
      variables: {
        input: {
          id: task.id,
          workOrderId: workOrderDetail.id,
          categoryIds,
        },
      },
    });

    refetch();

    Snackbar.info({
      message: `Removed Folder.`,
    });
  };

  const handleDeleteClick = event => {
    event.stopPropagation();
    setDeleteModalOpen(true);
  };

  const icon = <Icon>create_new_folder</Icon>;
  const actionButton = (
    <IconButton size="small" onClick={handleDeleteClick} tooltip="Remove">
      <Icon>close</Icon>
    </IconButton>
  );

  return (
    <>
      <TaskItemTile
        content={name}
        tooltipContent={name}
        onTileClick={onClick}
        icon={icon}
        actionButton={actionButton}
        maxWidth="18.75rem"
        containerStyles={styles.container}
      />
      <DeleteModal
        open={deleteModalOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setDeleteModalOpen(false)}
        loading={loading}
        title="Remove Folder"
        content="Are you sure you want to remove this folder?"
        confirmText="Remove"
      />
    </>
  );
};

export default CategoryTile;
