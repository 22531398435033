import { WorkOrderTab } from '@atom/types/work';

import { GoogleMapsLocation } from './gmapsLocation';
import { Portal } from './portals';

// Form Module Preferences
export enum FormModules {
  CI = 'CI',
  PCI = 'PCI',
  FHWA = 'FHWA',
}

// Report Type Preferences
export enum ReportTypes {
  BASIC_FULL = 'BASIC_FULL',
  BASIC_FULL_V2 = 'BASIC_FULL_V2',
  BASIC_PARTIAL = 'BASIC_PARTIAL',
  CUSTOM_V1 = 'CUSTOM_V1',
  CUSTOM_V2 = 'CUSTOM_V2',
  CUSTOM_V3 = 'CUSTOM_V3',
  CUSTOM_WORK_REPORT = 'CUSTOM_WORK_REPORT',
  SCDOT_REPORT = 'SCDOT_REPORT',
  ALDOT_REPORT = 'ALDOT_REPORT',
}

// Authentication Preferences
interface AuthenticationConnection {
  domains: string[];
  name: string;
  type: string;
}

export interface AuthenticationPreferences {
  connections: AuthenticationConnection[];
}

// Inventory Preferences
interface AssetPreference {
  dataType: string;
  fieldName: string;
  name: string;
}

interface AssetsPreferences {
  [key: string]: AssetPreference[];
}

interface BudgetUnitPreference {
  unit: string;
}

interface CategoryColumnPreference {
  dataType: string;
  fieldName: string;
  name: string;
}

interface CategoriesPreferences {
  columns: CategoryColumnPreference[];
}

export interface InventoryPreferences {
  assets: AssetsPreferences;
  budgetUnits: BudgetUnitPreference[];
  categories: CategoriesPreferences;
}

export interface WorkOrderPreferences {
  multiLocationEnabled?: boolean;
  reopenClosedWorkEnabled?: boolean;
  reopenOwnClosedWork?: boolean;
  deleteOwnWork?: boolean;
  deleteOwnTask?: boolean;
  completedDateEnabled?: boolean;
  workNamingDisabled?: boolean;
  requireTaskType?: boolean;
  requireWorkType?: boolean;
  disableTaskCreation?: boolean;
  navigateToAssetDetail?: boolean;
  defaultWorkOrderTab?: WorkOrderTab;
  changeTaskTemplateEnabled?: boolean;
  filtering?: {
    tasks?: {
      fields?: string[];
    };
  };
}

// Map Preferences
export enum ClusterDensity {
  default = 'default',
  low = 'low',
  high = 'high',
}

export interface MapPreferences {
  center: GoogleMapsLocation;
  clusterDensity: ClusterDensity;
  kml: string[];
  zoom: number;
}

// Teams Preferences
export interface TeamsPreferences {}

// Time Tracking Preferences
export enum TimeEntryType {
  AUTO_GENERATED = 'AUTO_GENERATED',
  TELEWORK = 'TELEWORK',
  SHIFT_DIFFERENTIAL = 'SHIFT_DIFFERENTIAL',
}

export interface TypeEnumerationItem {
  name: string;
  value: TimeEntryType;
}

export interface TimeTrackingPreferences {
  computedBudgets: boolean;
  timesheet: boolean;
  typeEnumeration: TypeEnumerationItem[];
}

export interface BudgetingPreferences {
  hideCostType: boolean;
}

// Full Preferences
export interface Preferences {
  authentication?: AuthenticationPreferences;
  budgeting?: BudgetingPreferences;
  fiscalStartDate?: number;
  formModules?: FormModules[];
  inventory?: InventoryPreferences;
  knowledgeCenter?: string;
  map?: MapPreferences;
  reports?: ReportTypes[];
  teams?: TeamsPreferences;
  timeTracking?: TimeTrackingPreferences;
  workOrders?: WorkOrderPreferences;
  landingPortal?: Portal;
}
