import { call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import actionTypes from '@atom/actions/actionTypes';
import * as taskActions from '@atom/actions/taskActions';
import * as workOrderActions from '@atom/actions/workOrderActions';
import textConstants from '@atom/constants/textConstants';
import { Snackbar } from '@atom/mui';
import accountUtilities from '@atom/utilities/accountUtilities';
import api from '@atom/utilities/api';
import {
  getTaskAssigneePostEndpoint,
  getWorkOrderTasksEndpoint,
  WORK_ORDERS_ENDPOINT,
} from '@atom/utilities/endpoints';

export function* getTask(action) {
  try {
    const { workOrderId, taskId } = action.data;
    const baseEndpoint = getWorkOrderTasksEndpoint(workOrderId);
    const endpoint = `${baseEndpoint}/${taskId}`;

    const { data } = yield call(api.get, endpoint);

    yield put(taskActions.getTaskSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      taskActions.getTaskFailure,
    );
  }
}

export function* patchTask(action) {
  try {
    const { workOrderId, taskId, body } = action.data;
    const baseEndpoint = getWorkOrderTasksEndpoint(workOrderId);
    const endpoint = `${baseEndpoint}/${taskId}`;

    const { data } = yield call(api.patch, endpoint, body);

    yield put(taskActions.patchTaskSuccess(data));
    yield put(workOrderActions.requestWorkStatus({ id: workOrderId }));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      taskActions.patchTaskFailure,
    );
  }
}

export function* deleteTask(action) {
  try {
    const { workOrderId, taskId } = action.data;
    const baseEndpoint = getWorkOrderTasksEndpoint(workOrderId);
    const endpoint = `${baseEndpoint}/${taskId}`;

    yield call(api.delete, endpoint);

    yield put(taskActions.deleteTaskSuccess(taskId));
    yield put(workOrderActions.requestWorkStatus({ id: workOrderId }));

    Snackbar.info({
      message: 'Task deleted',
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      taskActions.deleteTaskFailure,
    );
  }
}

export function* createTask(action) {
  try {
    const { workOrderId, body } = action.data;
    const baseEndpoint = getWorkOrderTasksEndpoint(workOrderId);

    const { data } = yield call(api.post, baseEndpoint, body);

    yield put(taskActions.createTaskSuccess(data));
    yield put(workOrderActions.requestWorkStatus({ id: workOrderId }));

    Snackbar.info({
      message: `Created task ${data.name}`,
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      taskActions.createTaskFailure,
    );
  }
}

export function* addAsset(action) {
  try {
    const { workId, taskId, assetIds } = action.data;
    const baseEndpoint = getWorkOrderTasksEndpoint(workId);
    const endpoint = `${baseEndpoint}/${taskId}/assets`;

    const { data } = yield call(api.post, endpoint, { assetIds });
    yield put(taskActions.addTaskAssetSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      taskActions.addTaskAssetFailure,
    );
  }
}

export function* createMaterial(action) {
  try {
    const { workId, taskId, assetId } = action.data;
    const baseEndpoint = getWorkOrderTasksEndpoint(workId);
    const endpoint = `${baseEndpoint}/${taskId}/materials`;

    const { data } = yield call(api.post, endpoint, { assetId });
    yield put(
      taskActions.createTaskMaterialSuccess({
        material: data,
        taskId,
        assetId,
      }),
    );

    const workOrderEndpoint = `${WORK_ORDERS_ENDPOINT}/${workId}`;

    const { data: workOrder } = yield call(api.get, workOrderEndpoint);

    yield put(taskActions.updateWorkOrderData(workOrder));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      taskActions.createTaskMaterialFailure,
    );
  }
}

export function* updateMaterial(action) {
  try {
    const { workId, taskId, assetId, quantity } = action.data;
    const baseEndpoint = getWorkOrderTasksEndpoint(workId);
    const endpoint = `${baseEndpoint}/${taskId}/materials/${assetId}`;

    const { data } = yield call(api.patch, endpoint, { quantity });
    yield put(
      taskActions.updateTaskMaterialSuccess({
        material: data,
        taskId,
        assetId,
      }),
    );

    const workOrderEndpoint = `${WORK_ORDERS_ENDPOINT}/${workId}`;

    const { data: workOrder } = yield call(api.get, workOrderEndpoint);
    yield put(taskActions.updateWorkOrderData(workOrder));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      taskActions.updateTaskMaterialFailure,
    );
  }
}

export function* addUsers(action) {
  try {
    const { workId, taskId, users } = action.data;
    const endpoint = getTaskAssigneePostEndpoint(workId, taskId);

    const { data } = yield call(api.post, endpoint, { assignees: users });
    yield put(taskActions.addTaskUserSuccess(data));

    yield put(workOrderActions.requestWorkStatus({ id: workId }));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      taskActions.addTaskUserFailure,
    );
  }
}

export function* deleteMaterial(action) {
  try {
    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
    });

    const { workId, taskId, assetId } = action.data;
    const baseEndpoint = getWorkOrderTasksEndpoint(workId);
    const endpoint = `${baseEndpoint}/${taskId}/materials/${assetId}`;

    yield call(api.delete, endpoint);

    yield put(taskActions.deleteTaskMaterialSuccess({ taskId, assetId }));

    const workOrderEndpoint = `${WORK_ORDERS_ENDPOINT}/${workId}`;

    const { data: workOrder } = yield call(api.get, workOrderEndpoint);
    yield put(taskActions.updateWorkOrderData(workOrder));

    Snackbar.info({
      message: `Deleted Material.`,
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      taskActions.deleteTaskMaterialSuccess,
    );
  }
}

export function* deleteAsset(action) {
  try {
    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
    });

    const { workId, taskId, assetId } = action.data;
    const baseEndpoint = getWorkOrderTasksEndpoint(workId);
    const endpoint = `${baseEndpoint}/${taskId}/assets/${assetId}`;

    yield call(api.delete, endpoint);

    yield put(taskActions.deleteTaskAssetSuccess({ taskId, assetId }));

    Snackbar.info({
      message: `Deleted Asset.`,
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      taskActions.deleteTaskAssetFailure,
    );
  }
}

export function* deleteAssignee(action) {
  try {
    Snackbar.info({
      message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
    });

    const { workId, taskId, userId } = action.data;
    const baseEndpoint = getWorkOrderTasksEndpoint(workId);
    const endpoint = `${baseEndpoint}/${taskId}/assignees/${userId}`;

    yield call(api.delete, endpoint);

    yield put(taskActions.deleteAssigneeSuccess({ taskId, userId }));

    const workOrderEndpoint = `${WORK_ORDERS_ENDPOINT}/${workId}`;

    const { data: workOrder } = yield call(api.get, workOrderEndpoint);
    yield put(taskActions.updateWorkOrderData(workOrder));

    Snackbar.info({
      message: 'Deleted Assignee.',
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      taskActions.deleteAssigneeSuccess,
    );
  }
}

/* WATCHERS */
export function* retrieveTask() {
  yield takeLatest(actionTypes.REQUEST_TASK, getTask);
}

export function* requestPatchTask() {
  yield takeEvery(actionTypes.REQUEST_PATCH_TASK, patchTask);
}

export function* requestDeleteTask() {
  yield takeEvery(actionTypes.REQUEST_DELETE_TASK, deleteTask);
}

export function* requestCreateTask() {
  yield takeEvery(actionTypes.REQUEST_CREATE_TASK, createTask);
}

export function* requestTaskMaterialCreate() {
  yield takeLatest(actionTypes.REQUEST_TASK_MATERIAL_CREATE, createMaterial);
}

export function* requestTaskMaterialUpdate() {
  yield takeLatest(actionTypes.REQUEST_TASK_MATERIAL_UPDATE, updateMaterial);
}

export function* requestTaskMaterialDelete() {
  yield takeLatest(actionTypes.REQUEST_TASK_MATERIAL_DELETE, deleteMaterial);
}

export function* requestAssigneeDelete() {
  yield takeLatest(actionTypes.REQUEST_ASSIGNEE_DELETE, deleteAssignee);
}

export function* requestTaskUserAdd() {
  yield takeLatest(actionTypes.REQUEST_TASK_USER_ADD, addUsers);
}

export function* requestTaskAssetDelete() {
  yield takeLatest(actionTypes.REQUEST_TASK_ASSET_DELETE, deleteAsset);
}

export function* requestTaskAssetAdd() {
  yield takeLatest(actionTypes.REQUEST_TASK_ASSET_ADD, addAsset);
}

export default [
  retrieveTask,
  requestPatchTask,
  requestDeleteTask,
  requestCreateTask,
  requestTaskMaterialCreate,
  requestTaskMaterialUpdate,
  requestTaskMaterialDelete,
  requestAssigneeDelete,
  requestTaskUserAdd,
  requestTaskAssetDelete,
  requestTaskAssetAdd,
];
