import React, { useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';

import EditAttributeList from '@atom/components/common/attributeDetail/EditAttributeList';
import { Button, Icon, IconButton, Progress } from '@atom/mui';
import {
  AttributesType,
  HydratedAttributeGroupsItem,
} from '@atom/types/inventory';

import AttributeRow from './AttributeRow';

import './mapDrawerContent.css';

const styles = {
  editIcon: {
    marginRight: '1rem',
  },
  content: {
    padding: '1rem 1rem 0 1rem',
  },
  progress: {
    alignSelf: 'center',
  },
  actionPanel: {
    display: 'flex',
  },
};

interface Props {
  attributeGroup: HydratedAttributeGroupsItem;
  loading: boolean;
  onAttributesPendingChange: (
    attributeId: string,
    changeId: string,
    action: string,
  ) => void;
  onAttributesSave: (attributeGroupName: string) => void;
  onAttributesRemove: (ids: string[]) => void;
  canManageChanges: boolean;
  canUpdate: boolean;
}

const AttributeGroupRow = ({
  attributeGroup,
  loading,
  onAttributesPendingChange,
  onAttributesSave,
  onAttributesRemove,
  canManageChanges,
  canUpdate,
}: Props) => {
  const [open, setOpen] = useState<boolean>(true);
  const [editing, setEditing] = useState<boolean>(false);

  const icon = open ? 'keyboard_arrow_up' : 'keyboard_arrow_down';

  const attributeGroupHasPendingChanges = ({ attributes }: any): boolean =>
    R.values(attributes).some(attribute => !R.isNil(attribute.newValue));

  const hasPendingChanges = useMemo(() => {
    return attributeGroupHasPendingChanges(attributeGroup);
  }, [attributeGroup]);

  useEffect(() => {
    if (!loading) {
      setEditing(false);
    }
  }, [loading]);

  const onEditClick = () => {
    setEditing(true);
    setOpen(true);
  };

  const onCancel = () => {
    const attributeGroupIds = attributeGroup.attributes.map(
      (attribute: AttributesType) => attribute.id,
    );
    onAttributesRemove(attributeGroupIds);
    setEditing(false);
  };

  const onSave = () => {
    onAttributesSave(attributeGroup.name);
  };

  const actionPanel = editing ? (
    <>
      <span style={styles.progress}>{loading && <Progress size={25} />}</span>
      <Button
        style={{ minWidth: 0 }}
        onClick={() => onCancel()}
        disabled={loading}
      >
        Cancel
      </Button>
      <Button
        style={{ minWidth: 0 }}
        color="primary"
        disabled={loading}
        onClick={(): void => onSave()}
      >
        Save
      </Button>
    </>
  ) : (
    <>
      {canUpdate && (
        <IconButton
          style={styles.editIcon}
          size="small"
          onClick={() => onEditClick()}
        >
          <Icon>edit</Icon>
        </IconButton>
      )}
      <IconButton size="small" onClick={() => setOpen(!open)}>
        <Icon children={icon} />
      </IconButton>
    </>
  );

  const content = editing ? (
    <div style={styles.content}>
      <EditAttributeList attributes={attributeGroup.attributes} width="45%" />
    </div>
  ) : (
    <div styleName="attributes-container">
      {attributeGroup.attributes.map((attribute: AttributesType) => (
        <AttributeRow
          key={attribute.id}
          attribute={attribute}
          onAttributesPendingChange={onAttributesPendingChange}
          canManageChanges={canManageChanges}
        />
      ))}
    </div>
  );

  return (
    <>
      <div styleName="attribute-group-row">
        <div styleName="change-indicator-container">
          <div>{attributeGroup.name}</div>
          {hasPendingChanges && <div styleName="change-indicator" />}
        </div>
        <div style={styles.actionPanel}>{actionPanel}</div>
      </div>
      {open && <div>{content}</div>}
    </>
  );
};

export default AttributeGroupRow;
