import React, { useState } from 'react';
import { actions, Control } from 'react-redux-form';
import { Autocomplete, Icon, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import colors from '@atom/styles/colors';

import './attributeControls.css';

const useStyles = makeStyles({
  tag: {
    backgroundColor: colors.neutral.ash,
    borderRadius: '20px',
  },
});

interface Props {
  enumeration: string[];
  name: string;
  value: any;
  isEditable: boolean;
  onUpdate?: (hasUnsavedChanges: boolean) => void;
}

const EnumMultipleAttribute = (props: Props) => {
  const classes = useStyles(props);
  const [values, setValues] = useState<string[]>(props.value || []);

  const onChange = ({ dispatch, model }) => {
    return async (event, newValue) => {
      await setValues(newValue);
      if (props.onUpdate) {
        props.onUpdate(true);
      }
      dispatch(actions.change(model, newValue));
    };
  };

  return (
    <div key={props.name}>
      <Control.custom
        model={`.${props.name}`}
        component={Autocomplete}
        disabled={!props.isEditable}
        mapProps={innerProps => ({
          value: values || [],
          multiple: true,
          // @ts-ignore
          onChange: onChange(innerProps),
          classes: {
            tag: classes.tag,
          },
          renderOption: (optionProps, option, { selected }) => (
            <li {...optionProps}>
              <Icon
                style={{
                  color: colors.brand.blue,
                  opacity: selected ? 1 : 0,
                  marginRight: '5px',
                }}
              >
                done
              </Icon>
              {option}
            </li>
          ),
          renderInput: params => (
            // @ts-ignore
            <TextField {...params} variant="standard" margin="normal" />
          ),
          options: props.enumeration,
          getOptionLabel: option => option,
        })}
      />
    </div>
  );
};

export default EnumMultipleAttribute;
