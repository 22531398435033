import React from 'react';
import { Link, Tooltip as MUITooltip } from '@mui/material';

import { WorkOrderField } from '@atom/types/work';

import './workOrderFields.css';

interface Props {
  field: WorkOrderField;
}

const WorkOrderHyperlinkField = ({ field }: Props) => {
  return (
    <div styleName="hyperlink-container">
      <div styleName="hyperlink-title">{field.title}</div>
      <MUITooltip title={field.hyperlink}>
        <Link href={field.hyperlink} target="_blank">
          {field.value}
        </Link>
      </MUITooltip>
    </div>
  );
};

export default WorkOrderHyperlinkField;
