import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import ApproveAllChangesModal from '@atom/components/common/map/mapDrawerContent/ApproveAllChangesModal';
import AssetSummaryInfo from '@atom/components/common/map/mapDrawerContent/AssetSummaryInfo';
// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import RenameInventoryModal from '@atom/components/mapPortal/assetSummary/RenameInventoryModal';
import textConstants from '@atom/constants/textConstants';
import { GET_ASSET } from '@atom/graph/asset';
import { Icon, IconButton, Progress, Snackbar } from '@atom/mui';
import { AssetDetail } from '@atom/types/inventory';
import { PolicyAction } from '@atom/types/policy';
import { hasAccess } from '@atom/utilities/accessUtilities';
import api from '@atom/utilities/api';
import {
  FILES_ENDPOINT,
  INVENTORY_ASSETS_ENDPOINT,
} from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';
import { getStaticImage } from '@atom/utilities/mapUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './locationsAndAssetsSection.css';

const styles = {
  iconContainer: {
    width: 24,
    height: 24,
    padding: 0,
    cursor: 'pointer',
  },
};

interface Props {
  assetId: string;
  onClose: () => void;
}

const AssetSummaryTray = ({ assetId, onClose }: Props) => {
  const [asset, setAsset] = useState<AssetDetail>();
  const [renameInventoryModalOpen, setRenameInventoryModalOpen] = useState<
    boolean
  >(false);
  const [approveChangesModalOpen, setApproveChangesModalOpen] = useState<
    boolean
  >(false);
  const [loadingApproveAll, setLoadingApproveAll] = useState<boolean>(false);
  const [loadingRename, setLoadingRename] = useState<boolean>(false);

  const [getAsset] = useLazyQuery<{ asset: AssetDetail }, { id: string }>(
    GET_ASSET,
    {
      fetchPolicy: 'network-only',
      onCompleted: data => setAsset(data?.asset),
    },
  );

  useEffect(() => {
    if (!assetId) {
      setAsset(null);
    }
  }, [assetId]);

  const handleGetAsset = async () => {
    if (assetId) {
      await getAsset({ variables: { id: assetId } });
    }
  };

  useEffect(() => {
    if (assetId) {
      getAsset({ variables: { id: assetId } });
    }
  }, [assetId]);

  const handleApproveChanges = async () => {
    try {
      setLoadingApproveAll(true);
      const { includeChangesBefore } = asset;
      await api.post(`${INVENTORY_ASSETS_ENDPOINT}/${asset.id}/approve`, {
        includeChangesBefore,
      });
      Snackbar.info({
        message: textConstants.GENERIC_APPLICATION_UPDATED_TEXT,
        autoHideDuration: 3000,
      });

      handleGetAsset();
      setApproveChangesModalOpen(false);
    } catch (error) {
      Snackbar.error({
        message:
          'Something went wrong. Please try again or contact an administrator.',
      });
    }
    setLoadingApproveAll(false);
  };

  const renameInventory = async (name: string) => {
    try {
      setLoadingRename(true);

      await api.patch(`${INVENTORY_ASSETS_ENDPOINT}/${asset.id}`, { name });

      handleGetAsset();
    } catch (err) {
      Snackbar.error({
        message:
          'Something went wrong. Please try again or contact an administrator.',
      });
    }
    setLoadingRename(false);
  };

  const handleClose = () => {
    setAsset(null);
    onClose();
  };

  const navigateToAssetDetail = () => {
    history.push(`/inventory/${asset.id}`);
  };

  const getSummaryImage = () => {
    if (!asset) {
      return '';
    }

    return !asset?.mainPhotoFileId
      ? getStaticImage(asset?.location)
      : `${FILES_ENDPOINT}/${asset?.mainPhotoFileId}_340`;
  };

  const loading = isNilOrEmpty(asset);
  const canManageChanges = hasAccess(
    PolicyAction.MANAGE_INVENTORY_CHANGES,
    asset?.actions,
  );
  const canRename = hasAccess(PolicyAction.RENAME, asset?.actions);
  const showApproveChanges = canManageChanges && asset?.changeType;

  const content = (
    <>
      <div styleName="tray-header-container">
        <div styleName="tray-header-title-container">
          <div>{asset?.name}</div>
          <div styleName="action-button-container">
            {showApproveChanges && (
              <div>
                <IconButton
                  tooltip="Approve All Changes"
                  onClick={() => setApproveChangesModalOpen(true)}
                >
                  <Icon style={styles.iconContainer}>done_all</Icon>
                </IconButton>
                <ApproveAllChangesModal
                  open={approveChangesModalOpen}
                  loading={loadingApproveAll}
                  assetName={asset.name}
                  onCancel={() => setApproveChangesModalOpen(false)}
                  onApproveAll={() => handleApproveChanges()}
                />
              </div>
            )}
            {canRename && (
              <div>
                <IconButton
                  tooltip="Rename"
                  onClick={() => setRenameInventoryModalOpen(true)}
                >
                  <Icon style={styles.iconContainer}>
                    <img src={renameIcon} />
                  </Icon>
                </IconButton>
                <RenameInventoryModal
                  open={renameInventoryModalOpen}
                  loading={loadingRename}
                  onCancel={() => setRenameInventoryModalOpen(false)}
                  renameInventory={renameInventory}
                  name={asset?.name}
                />
              </div>
            )}
            <IconButton onClick={handleClose}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        </div>
      </div>
      <div styleName="tray-content-container">
        <div
          styleName="summary-image-container"
          style={{
            backgroundImage: `url('${getSummaryImage()}')`,
          }}
        />
        <AssetSummaryInfo asset={asset} refetch={handleGetAsset} workView />
      </div>
      <div styleName="view-button" onClick={navigateToAssetDetail}>
        View Full Detail
      </div>
    </>
  );

  return (
    <div styleName="sidebar-container">{loading ? <Progress /> : content}</div>
  );
};

export default AssetSummaryTray;
