import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import { useSchemas } from '@atom/hooks/useSchemas';
import { Icon, Modal, Select, Switch, TextField } from '@atom/mui';
import { budgetUnitsSelector } from '@atom/selectors/preferencesSelectors';
import { InventoryAssetDetailType } from '@atom/types/inventory';
import { PolicyAction } from '@atom/types/policy';
import {
  SchemaBudgetType,
  StartEndReadingOption,
  StockBasedOption,
} from '@atom/types/schema';
import { hasAccess } from '@atom/utilities/accessUtilities';
import api from '@atom/utilities/api';
import { INVENTORY_ASSETS_ENDPOINT } from '@atom/utilities/endpoints';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';

import './header.css';

const { MenuItem } = Select;

const styles = {
  select: {
    textTransform: 'uppercase',
    marginLeft: '1rem',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  icon: {
    marginRight: '1.5rem',
  },
  unitOption: {
    textTransform: 'uppercase',
  },
};

export interface Props {
  inventoryAssetDetail: InventoryAssetDetailType;
  onAssetEdit: (
    id: string,
    name: string,
    rate?: number,
    unit?: string,
    isStockBased?: boolean,
    isStartEndReading?: boolean,
    isStartEndCalculated?: boolean,
    quantityRemaining?: number,
  ) => void;
  open: boolean;
  closeModal: () => void;
}

const EditAssetModal = ({
  inventoryAssetDetail,
  onAssetEdit,
  open,
  closeModal,
}: Props) => {
  const budgetUnits = useSelector(budgetUnitsSelector);

  const { schemas = [], loading } = useSchemas();

  const [name, setName] = useState<string>(inventoryAssetDetail.name);
  const [rate, setRate] = useState<number>(inventoryAssetDetail?.budget?.rate);
  const [unit, setUnit] = useState<string>(inventoryAssetDetail?.budget?.unit);
  const [isStockBased, setIsStockBased] = useState<boolean>(false);
  const [isStartEndReading, setIsStartEndReading] = useState<boolean>(false);
  const [isStartEndCalculated, setIsStartEndCalculated] = useState<boolean>(
    false,
  );
  const [quantityRemaining, setQuantityRemaining] = useState<number>(0);

  useEffect(() => {
    const getUsageSummary = async () => {
      const { data } = await api.get(
        `${INVENTORY_ASSETS_ENDPOINT}/${inventoryAssetDetail.id}/usageSummary`,
      );

      setQuantityRemaining(data.totalRemaining);
    };

    if (open) {
      getUsageSummary();
    }
  }, [inventoryAssetDetail.id, open]);

  useEffect(() => {
    if (open) {
      setName(inventoryAssetDetail?.name);
      setRate(inventoryAssetDetail?.budget?.rate);
      setUnit(inventoryAssetDetail?.budget?.unit);
      setIsStockBased(inventoryAssetDetail?.budget?.isStockBased);
      setIsStartEndReading(inventoryAssetDetail?.budget?.isStartEndReading);
      setIsStartEndCalculated(
        inventoryAssetDetail?.budget?.isStartEndCalculated,
      );
      setQuantityRemaining(0);
    }
  }, [open]);

  const schema = useMemo(() => {
    return schemas.find(({ id }) => id === inventoryAssetDetail.schemaId);
  }, [schemas, inventoryAssetDetail.schemaId]);

  const updateRate = event => {
    const newTimeRate = event.target.value
      ? Math.abs(Number(event.target.value))
      : null;

    setRate(newTimeRate);
  };

  const updateQuantityRemaining = event => {
    const newQuantityRemaining = event.target.value
      ? Math.abs(Number(event.target.value))
      : null;

    setQuantityRemaining(newQuantityRemaining);
  };

  const confirm = () => {
    onAssetEdit(
      inventoryAssetDetail.id,
      name,
      rate,
      unit,
      isStockBased,
      isStartEndReading,
      isStartEndCalculated,
      quantityRemaining,
    );

    closeModal();
  };

  const canRename = hasAccess(
    PolicyAction.RENAME,
    inventoryAssetDetail.actions,
  );
  const canUpdate = hasAccess(
    PolicyAction.UPDATE,
    inventoryAssetDetail.actions,
  );

  const isStockBasedToggleDisabled =
    schema?.budget?.stockBasedOption === StockBasedOption.STOCK_BASED ||
    !canUpdate ||
    isCurrentClient([Client.UDOT, Client.ALDOT]);
  const isRateDisabled =
    inventoryAssetDetail?.budget?.type === SchemaBudgetType.FIXED ||
    !canUpdate ||
    isCurrentClient([Client.UDOT, Client.ALDOT]);
  const isUnitDisabled =
    inventoryAssetDetail?.budget?.type === SchemaBudgetType.FIXED ||
    inventoryAssetDetail?.budget?.type === SchemaBudgetType.VARIABLE_COST ||
    !canUpdate ||
    isCurrentClient([Client.UDOT, Client.ALDOT]);
  const isQuantityRemainingDisabled = isCurrentClient([
    Client.UDOT,
    Client.ALDOT,
  ]);

  const showStockBased =
    schema?.budget?.stockBasedOption !== StockBasedOption.NONE;

  const showStartEndReading =
    !isStockBased &&
    schema?.budget?.startEndReadingOption !== StartEndReadingOption.NONE;

  const options = ['', ...budgetUnits];

  const isSaveDisabled =
    !name ||
    (inventoryAssetDetail?.isMaterial && !rate) ||
    (inventoryAssetDetail?.isMaterial && !unit) ||
    (inventoryAssetDetail?.isMaterial && isStockBased && !quantityRemaining);

  return (
    <Modal
      open={open}
      title="Edit Inventory"
      loading={loading}
      onConfirm={confirm}
      onCancel={closeModal}
      confirmButtonText="Save"
      disabled={isSaveDisabled}
    >
      <div styleName="inventory-row">
        <img style={styles.icon} src={renameIcon} />
        <TextField
          label="Name"
          value={name}
          onChange={event => setName(event.target.value)}
          disabled={!canRename}
        />
      </div>
      <div styleName="inventory-row">
        <Icon style={styles.icon}>monetization_on</Icon>
        <div styleName="cost-input-container">
          <TextField
            label="Cost"
            value={rate}
            type="number"
            fullWidth
            onChange={updateRate}
            InputProps={{
              startAdornment: '$',
            }}
            disabled={isRateDisabled}
          />
          <div styleName="separator">per</div>
          <Select
            label="Unit"
            fullWidth
            InputProps={{
              style: styles.unitOption,
            }}
            onChange={event => setUnit(event.target.value)}
            value={unit}
            disabled={isUnitDisabled}
          >
            {options.map(option => {
              return (
                <MenuItem
                  key={option}
                  value={option || null}
                  style={styles.unitOption}
                >
                  {option}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>
      {showStockBased && (
        <>
          <div styleName="inventory-row">
            <Switch
              checked={isStockBased}
              onChange={() => setIsStockBased(!isStockBased)}
              label="Stock-based Inventory"
              disabled={isStockBasedToggleDisabled}
            />
          </div>
          {isStockBased && (
            <div styleName="inventory-row quantity">
              <TextField
                label="Total Remaining"
                value={quantityRemaining}
                type="number"
                fullWidth
                onChange={updateQuantityRemaining}
                disabled={isQuantityRemainingDisabled}
              />
              <div styleName="quantity-suffix">{unit}</div>
            </div>
          )}
        </>
      )}
      {showStartEndReading && (
        <>
          <div styleName="inventory-row">
            <Switch
              checked={isStartEndReading}
              label="Enable Start and End Readings"
              disabled
            />
          </div>
          {isStartEndReading && (
            <div styleName="inventory-row">
              <Switch
                checked={isStartEndCalculated}
                label="Used Calculated from Start and End Readings"
                disabled
              />
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default EditAssetModal;
