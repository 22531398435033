import React, { useContext, useState } from 'react';

import LocationsAndAssetsContext from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsContext';
import RequiredFieldsConfirmModal from '@atom/components/common/workOrderDetail/requiredFieldsConfirmModal/RequiredFieldsConfirmModal';
import { Button, Icon, Progress, TextField, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  ALDOTLocationDataState,
  ALDOTLocationDataTitle,
  ALDOTLocationOptionsState,
} from '@atom/types/taskLocation';

import TaskLocationAldotInputs from './TaskLocationAldotInputs';

import './taskLocationModal.css';

const styles = {
  refreshIcon: {
    transform: 'rotateY(180deg)',
  },
};

interface Props {
  name: string;
  values: ALDOTLocationDataState;
  options: ALDOTLocationOptionsState;
  updateValue: (property: ALDOTLocationDataTitle, value: any) => void;
  loadingAutocompleteData: boolean;
  loadingFindLocation: boolean;
  clearValues: () => void;
  inputsLocked: boolean;
  rangeGapError: boolean;
  setRangeGapError: (rangeGapError: boolean) => void;
}

const TaskLocationSidebar = ({
  values,
  options,
  updateValue,
  name,
  loadingAutocompleteData,
  loadingFindLocation,
  clearValues,
  inputsLocked,
  rangeGapError,
  setRangeGapError,
}: Props) => {
  const { workOrderDetail, task, refetch } = useContext(
    LocationsAndAssetsContext,
  );

  const [openFieldsConfirm, setOpenFieldsConfirm] = useState<boolean>(false);

  const loading = loadingAutocompleteData || loadingFindLocation;
  const loadingText = loadingFindLocation
    ? 'Finding location, please wait...'
    : 'Filtering options, please wait...';
  const tooltipText =
    'Management Unit, County, and Road Class for locations and assets must remain the same on the task. To modify, please delete all assets and locations and start over.';

  const isClearDisabled =
    workOrderDetail?.isClosed ||
    loading ||
    (task.isCompleted && !task?.requireAtLeastOneLocation);
  const clearIconStyle = {
    ...styles.refreshIcon,
    color: isClearDisabled ? colors.neutral.silver : colors.brand.blue,
  };

  const onClearClick = () => {
    if (task.isCompleted) {
      return setOpenFieldsConfirm(true);
    }

    return clearValues();
  };

  const onProceedComplete = async () => {
    await refetch();

    setOpenFieldsConfirm(false);
    clearValues();
  };

  return (
    <>
      <div styleName="sidebar-container">
        <TextField value={name} label="Name" onChange={() => {}} disabled />
        <div styleName="info-row">
          <div styleName="info-content">
            Provide all of the following information to create a location on the
            map.
          </div>
          <Tooltip title={tooltipText} placement="bottom">
            <div>
              <Icon>help_outline</Icon>
            </div>
          </Tooltip>
        </div>
        <Button
          color="primary"
          startIcon={<Icon style={clearIconStyle}>refresh</Icon>}
          disabled={isClearDisabled}
          onClick={onClearClick}
        >
          Clear All Fields
        </Button>
        <TaskLocationAldotInputs
          values={values}
          options={options}
          updateValue={updateValue}
          loading={loading}
          inputsLocked={inputsLocked}
          rangeGapError={rangeGapError}
          setRangeGapError={setRangeGapError}
        />
        {loading && (
          <div styleName="loading-row">
            <Progress size={16} thickness={5} />
            <div styleName="loading-text">{loadingText}</div>
          </div>
        )}
      </div>
      <RequiredFieldsConfirmModal
        open={openFieldsConfirm}
        onCancel={() => setOpenFieldsConfirm(false)}
        onComplete={onProceedComplete}
        task={task}
        workOrderId={workOrderDetail?.id}
      />
    </>
  );
};

export default TaskLocationSidebar;
