import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as R from 'ramda';

import WorkTemplateTree from '@atom/components/common/workTemplateTree/WorkTemplateTree';
import { GET_WORK_ORDER_TEMPLATES } from '@atom/graph/workTemplate';
import { Button, Modal, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { PolicyAction } from '@atom/types/policy';
import {
  WorkOrderTemplatesConnectionInput,
  WorkTemplatesConnection,
} from '@atom/types/workTemplate';

import './mapDrawerWorkTemplateFilter.css';

interface WorkTemplate {
  id: string;
  name: string;
}

interface Props {
  onWorkTemplatesUpdate: (workTemplateIds: Set<any>) => any;
  workTemplateIds: Set<any>;
  toggled?: boolean;
}

const getStyles = toggled => ({
  modal: {
    height: '50vh',
    padding: '2rem',
  },
  button: {
    minWidth: 0,
    padding: 0,
    color: toggled ? colors.brand.blue : colors.neutral.gray,
    fontSize: fonts.sm,
    fontWeight: 'normal',
    textTransform: 'capitalize',
  },
  checkbox: {
    padding: 0,
    marginRight: '1rem',
  },
});

const MapDrawerLayersWorkTemplateFilter = ({
  onWorkTemplatesUpdate,
  workTemplateIds,
  toggled,
}: Props) => {
  const [selected, setSelected] = useState<WorkTemplate[]>([]);
  const [applied, setApplied] = useState<WorkTemplate[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const [getWorkTemplates, { loading }] = useLazyQuery<
    { workOrderTemplates: WorkTemplatesConnection },
    { input: WorkOrderTemplatesConnectionInput }
  >(GET_WORK_ORDER_TEMPLATES, {
    onCompleted: res => {
      const workTemplates = R.pathOr(
        [],
        ['workOrderTemplates', 'workOrderTemplates'],
        res,
      );

      setSelected(workTemplates);
      setApplied(workTemplates);
    },
  });

  useEffect(() => {
    if (workTemplateIds.size > 0) {
      getWorkTemplates({
        variables: {
          input: {
            ids: Array.from(workTemplateIds),
            action: PolicyAction.READ,
          },
        },
      });
    }
  }, [workTemplateIds]);

  useEffect(() => {
    const workTemplates = selected.filter(({ id }) => workTemplateIds.has(id));
    setSelected(workTemplates);
    setApplied(workTemplates);
  }, [workTemplateIds]);

  const handleToggle = (template?: WorkTemplate) => {
    const isSelected = selected.some(({ id }) => template.id === id);

    setSelected(
      isSelected
        ? selected.filter(({ id }) => id !== template.id)
        : [...selected, template],
    );
  };

  const handleSave = () => {
    const selectedWorkTemplateIds = selected.map(({ id }) => id);

    onWorkTemplatesUpdate(new Set(selectedWorkTemplateIds));

    setApplied(selected);
    setOpen(false);
  };

  const handleReset = () => {
    onWorkTemplatesUpdate(new Set([]));
  };

  const handleClear = () => setSelected([]);

  const handleCancel = () => {
    setSelected(applied);
    setOpen(false);
  };

  const selectedIds = useMemo(() => {
    return new Set(selected.map(({ id }) => id));
  }, [selected]);

  const styles = getStyles(toggled);

  return (
    <>
      <div styleName="filter-container-label">Work Template</div>
      <div styleName="button-container">
        <Button
          style={styles.button}
          size="small"
          onClick={() => setOpen(true)}
          disabled={loading || !toggled}
          data-cy="mapFilterTemplateSelect"
        >
          select
        </Button>
        {workTemplateIds.size > 0 && (
          <>
            <span styleName="button-divider">|</span>
            <Button
              style={{ ...styles.button, marginRight: '1rem' }}
              size="small"
              disabled={loading || !toggled}
              onClick={handleReset}
              data-cy="mapFilterTemplateClear"
            >
              clear all
            </Button>
          </>
        )}
      </div>
      <div styleName="filter-values">
        {loading && (
          <Progress size={20} style={{ justifyContent: 'flex-start' }} />
        )}
        {applied
          .filter(({ id }) => workTemplateIds.has(id))
          .map(({ name }) => name)
          .join(', ')}
      </div>
      <Modal
        title="Select Work Template"
        open={open}
        onCancel={handleCancel}
        onConfirm={handleSave}
        onClear={handleClear}
        contentStyle={styles.modal}
        confirmButtonText="Ok"
      >
        <WorkTemplateTree
          includeSearch
          getTemplateSelected={template => selectedIds.has(template.id)}
          onTemplateToggle={template => handleToggle(template)}
        />
      </Modal>
    </>
  );
};

export default MapDrawerLayersWorkTemplateFilter;
