import * as React from 'react';

import { InventoryAssetDetailType } from '@atom/types/inventory';

import AssetsListItem from './AssetsListItem';
import NoItemsPane from './NoItemsPane';

import './drawerContent.css';

interface Props {
  assets: InventoryAssetDetailType[];
  navigateToAsset: (id: string) => void;
}

const AssetsList = ({ assets, navigateToAsset }: Props) => {
  const totalVisible = assets.length;

  return (
    <div styleName="sidepane-list-container">
      <div styleName="list-header">{`${totalVisible} assets found in map area.`}</div>
      {!totalVisible ? (
        <NoItemsPane
          icon="location_off"
          text={[
            'There are 0 assets in this map area.',
            'Please zoom out or move around the map to find assets.',
          ]}
        />
      ) : (
        <div styleName="list-item-container">
          {assets.map(asset => (
            <AssetsListItem
              key={asset.id}
              asset={asset}
              navigateToAsset={navigateToAsset}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default AssetsList;
