import React, { useEffect, useState } from 'react';

import { MutcdCategory } from '@atom/types/inventory';
import api from '@atom/utilities/api';
import {
  FILES_ENDPOINT,
  INVENTORY_MUTCD_ENDPOINT,
} from '@atom/utilities/endpoints';

import './inventoryReport.css';

const HennepinSignCodePdfReport = () => {
  const [categories, setCategories] = useState<MutcdCategory[]>([]);
  const [photosLoading, setPhotosLoading] = useState<{ [id: string]: boolean }>(
    {
      categoriesLoading: true,
    },
  );

  useEffect(() => {
    const getCategories = async () => {
      const { data } = await api.get(INVENTORY_MUTCD_ENDPOINT, {
        schemaId: '5fac11ede537d1627ff82d8a',
        signTypeAttributeId: 'acc930af-2e20-4716-bd7b-d063a0475a1e',
        signCategoryAttributeId: '6ba063c4-6d3b-45b7-b8d0-5c67df43db46',
      });
      setCategories(data.categories);

      setPhotosLoading({
        categoriesLoading: false,
      });
    };

    getCategories();
  }, []);

  return (
    <div>
      {categories.map(category => (
        <div styleName="table-wrapper">
          <table>
            <thead>
              <div styleName="signs-section-title">{category.name}</div>
              <tr>
                <th>Category</th>
                <th>Type</th>
                <th>Image</th>
              </tr>
            </thead>
            <tbody>
              {category.types.map(type => (
                <tr>
                  <td>{category.name}</td>
                  <td>{type.name}</td>
                  <td>
                    {type.fileId && (
                      <img
                        styleName="signs-image"
                        src={`${FILES_ENDPOINT}/${type.fileId}`}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
      {Object.values(photosLoading).every(load => !load) && <div id="loaded" />}
    </div>
  );
};

export default HennepinSignCodePdfReport;
