import React, { useState } from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { SchemaSavePayload } from '@atom/types/schema';

import AddEditSchemaModal from '../common/addEditSchemaModal/AddEditSchemaModal';

import './dataManagement.css';

interface Props {
  navigateBack: () => void;
  addSchema: (payload: SchemaSavePayload) => void;
}

const styles = {
  icon: {
    width: '24px',
    height: '24px',
    fontWeight: '500',
  },
  button: {
    padding: '0 1rem',
  },
};

const DataManagementPortalHeader = ({ navigateBack, addSchema }: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleSave = (payload: SchemaSavePayload) => {
    addSchema(payload);
    setOpen(false);
  };

  return (
    <>
      <div styleName="navigation-bar-container">
        <div styleName="back-navigation-container" onClick={navigateBack}>
          <div styleName="back-button-icon">
            <Icon color={colors.neutral.white} style={styles.icon}>
              arrow_back
            </Icon>
          </div>
          <div styleName="back-button-text">Inventory Types</div>
        </div>
        <IconButton onClick={() => setOpen(true)} tooltip="Add Inventory Type">
          <Icon color={colors.neutral.white}>add</Icon>
        </IconButton>
      </div>
      <AddEditSchemaModal
        open={open}
        onClose={() => setOpen(false)}
        handleSave={handleSave}
      />
    </>
  );
};

export default DataManagementPortalHeader;
