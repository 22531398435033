import React, { useState } from 'react';
import * as R from 'ramda';

import { Checkbox, Collapse, Icon, IconButton, Switch } from '@atom/mui';
import markerUtilities from '@atom/utilities/markerUtilities';

import MapDrawerLayerIcon from './MapDrawerLayerIcon';

import './mapDrawerLayers.css';

interface Props {
  onUpdate: (isChecked: boolean, assetSchemas: Set<any>) => any;
  toggled: boolean;
  assetSchemas: any;
  schemas: any[];
}

const MapDrawerLayersAssetsSection = ({
  onUpdate,
  toggled,
  assetSchemas,
  schemas,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(true);

  const toggleAssetLayer = schemaId => {
    const assetSchemasSet = new Set([...assetSchemas]);
    if (assetSchemasSet.has(schemaId)) {
      assetSchemasSet.delete(schemaId);
    } else {
      assetSchemasSet.add(schemaId);
    }

    onUpdate(toggled, assetSchemasSet);
  };

  const locationBasedSchemas = schemas.filter(schema => !schema.isMaterial);

  const selectAllAssetLayers = () => {
    const assetSchemasSet = new Set(locationBasedSchemas.map(({ id }) => id));
    onUpdate(toggled, assetSchemasSet);
  };

  const deselectAllAssetLayers = () => {
    onUpdate(toggled, new Set([]));
  };

  const allAssetLayersSelected = locationBasedSchemas.every(({ id }) =>
    assetSchemas.has(id),
  );

  const noAssetLayersSelected = !assetSchemas.size;

  return (
    <div styleName="section-container">
      <div styleName="section-header-container">
        <Switch
          label={
            <div styleName="toggle-label">
              <div styleName="section-header-icon">
                <MapDrawerLayerIcon name="location_on" />
              </div>
              <div>Inventory</div>
            </div>
          }
          checked={toggled}
          onChange={() => {
            onUpdate(!toggled, assetSchemas);
          }}
        />
        <IconButton edge="end" onClick={() => setExpanded(!expanded)}>
          <Icon>{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
        </IconButton>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div styleName="section-filters expanded">
          <div styleName="select-all-container">
            <div
              onClick={selectAllAssetLayers}
              styleName={`select-all-label ${
                !toggled || allAssetLayersSelected ? 'disabled' : ''
              }`}
            >
              Select All
            </div>
            <div styleName="select-all-divider" />
            <div
              onClick={deselectAllAssetLayers}
              styleName={`select-all-label ${
                !toggled || noAssetLayersSelected ? 'disabled' : ''
              }`}
            >
              Clear All
            </div>
          </div>
          {!R.isNil(schemas) &&
            locationBasedSchemas.map(schema => {
              const icon = markerUtilities.getInactiveAssetIconFromSchemaMarkerId(
                schema.markerId,
              );

              return (
                <div styleName="section-filter-row" key={schema.id}>
                  <Checkbox
                    edge="start"
                    disabled={!toggled}
                    checked={assetSchemas.has(schema.id)}
                    onChange={() => toggleAssetLayer(schema.id)}
                  />
                  <img style={{ marginTop: '3px' }} src={icon} />
                  <span styleName="section-filter-row-text">
                    {schema.assetType}
                  </span>
                </div>
              );
            })}
        </div>
      </Collapse>
    </div>
  );
};

export default MapDrawerLayersAssetsSection;
