import React, { Dispatch, SetStateAction } from 'react';

import { PayPeriod, PayPeriods } from '@atom/types/payPeriod';
import {
  SelectedTimeSheetUser,
  TimeEntryApproverUserGroup,
} from '@atom/types/user';
import {
  UserFilters,
  UserGroupTable,
  UserGroupTreeType,
} from '@atom/types/userGroups';

export enum Section {
  DIRECTORY = 'DIRECTORY',
  PEOPLE = 'PEOPLE',
  TIME_SHEET = 'TIME_SHEET',
}

export enum View {
  DIRECTORY = 'DIRECTORY',
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  DEACTIVATED = 'DEACTIVATED',
  TIME_SHEET = 'TIME_SHEET',
  TIME_SHEET_LIST = 'TIME_SHEET_LIST',
  ROLES = 'ROLES',
}

export const baseUserGroup = {
  id: 'root',
  name: 'directory',
  colorId: 0,
  userGroups: [],
};

export const initialPayPeriodYear = Number(new Date().getUTCFullYear());

interface Context {
  csvDownloadEndpoint: string;
  isMapView: boolean;
  setIsMapView: Dispatch<SetStateAction<boolean>>;
  activeView: View;
  setActiveView: Dispatch<SetStateAction<View>>;
  setActiveGroup: Dispatch<SetStateAction<UserGroupTreeType>>;
  activeGroup: UserGroupTreeType;
  activePath: UserGroupTreeType[];
  setActivePath: Function;
  open: Set<string>;
  toggleOpen: (id: string) => void;
  userGroupTree: UserGroupTreeType;
  treeLoading: boolean;
  refetchTree: () => Promise<any>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  limit: number;
  setLimit: Dispatch<SetStateAction<number>>;
  totalCount: number;
  table: UserGroupTable[];
  tableLoading: boolean;
  refetchTable: () => Promise<any>;
  setFilters: Dispatch<SetStateAction<UserFilters>>;
  handleGroup: (
    userGroupTree: UserGroupTreeType,
    path: UserGroupTreeType[],
  ) => void;
  payPeriodYear: number;
  setPayPeriodYear: Function;
  selectedPayPeriod: PayPeriod;
  setSelectedPayPeriod: Function;
  payPeriodsLoading: boolean;
  payPeriods: PayPeriods;
  activeTimeSheetGroup: any;
  setActiveTimeSheetGroup: Function;
  payPeriodWeeks: number[][];
  selectedTimeSheetUser: SelectedTimeSheetUser;
  setSelectedTimeSheetUser: Function;
  userLoading: boolean;
  timeEntryApproverUserGroups: TimeEntryApproverUserGroup[];
  currentUserHasUserGroups: boolean;
  expanded: Set<Section>;
  toggleExpanded: (section: Section) => void;
}

export const initialState: Context = {
  csvDownloadEndpoint: '',
  isMapView: false,
  setIsMapView: () => {},
  activeView: View.DIRECTORY,
  setActiveView: () => {},
  activeGroup: baseUserGroup,
  activePath: [baseUserGroup],
  setActivePath: () => {},
  open: new Set(),
  toggleOpen: () => {},
  // @ts-ignore
  userGroupTree: {},
  treeLoading: false,
  refetchTree: () => Promise.resolve({}),
  page: 1,
  setPage: () => {},
  limit: 25,
  setLimit: () => {},
  totalCount: 0,
  table: [],
  tableLoading: false,
  refetchTable: () => Promise.resolve({}),
  setFilters: () => {},
  handleGroup: () => {},
  payPeriodYear: initialPayPeriodYear,
  setPayPeriodYear: () => {},
  selectedPayPeriod: null,
  setSelectedPayPeriod: () => {},
  activeTimeSheetGroup: null,
  setActiveTimeSheetGroup: () => {},
  payPeriodWeeks: [],
  selectedTimeSheetUser: null,
  setSelectedTimeSheetUser: () => {},
  userLoading: false,
  timeEntryApproverUserGroups: [],
  currentUserHasUserGroups: false,
  expanded: new Set([Section.DIRECTORY, Section.PEOPLE, Section.TIME_SHEET]),
  toggleExpanded: () => {},
};

const TeamContext = React.createContext<Context>(initialState);

export default TeamContext;
