import { FormInstanceTemplateType } from 'client/types/form';
import * as R from 'ramda';
import { createSelector } from 'reselect';

import {
  AncestorsItem,
  FormInstanceFieldItem,
  FormInstancePageType,
  FormModuleKeys,
} from '@atom/types/form';
import { InventorySchemaItem } from '@atom/types/inventory';
import { MediaItem } from '@atom/types/media';

export const formInstanceSelector = R.pathOr({}, ['formInstance']);

export const buildAssetFieldName = (
  name: string,
  ancestors: AncestorsItem[],
): string => {
  if (R.isNil(ancestors) || R.isEmpty(ancestors)) {
    return name;
  }

  const nameArray = [
    ...ancestors.map((ancestor: AncestorsItem): string => ancestor.name),
    name,
  ];

  return nameArray.join(' / ');
};

export const getRootSchema = (
  id: string,
  schemas: InventorySchemaItem[],
): any => {
  if (!id) {
    return {};
  }

  if (R.isEmpty(schemas) || R.isNil(schemas)) {
    return {};
  }

  const matchedSchema = schemas.find(
    (schema: InventorySchemaItem): boolean => schema.id === id,
  );

  return matchedSchema ? matchedSchema : {};
};

export const updateFormInstanceField = (
  form: Object,
  pageId: string,
  fieldId: string,
  body: Object,
): Object => ({
  ...form,
  // @ts-ignore
  pages: form.pages.map(
    (page: Object): Object => {
      // @ts-ignore
      return page.id === pageId
        ? {
            ...page,
            fields: {
              // @ts-ignore
              ...page.fields,
              [fieldId]: {
                // @ts-ignore
                ...page.fields[fieldId],
                // @ts-ignore
                value: body.value,
              },
            },
          }
        : page;
    },
  ),
});

export const updateAsset = (
  asset: Object,
  attributeGroupName: string,
  attributeId: string,
  body: Object,
): Object => ({
  ...asset,
  // @ts-ignore
  attributeGroups: asset.attributeGroups.map(
    (group: Object): Object => {
      // @ts-ignore
      return group.name === attributeGroupName
        ? {
            ...group,
            // @ts-ignore
            attributes: group.attributes.map(
              (attribute: Object): Object => {
                // @ts-ignore
                return attribute.id === attributeId
                  ? {
                      ...attribute,
                      // @ts-ignore
                      value: body.value,
                    }
                  : attribute;
              },
            ),
          }
        : group;
    },
  ),
});

export const updateFormInstanceAttribute = (
  form: Object,
  pageId: string,
  fieldId: string,
  assetId: string,
  attributeGroupName: string,
  attributeId: string,
  body: Object,
): Object => ({
  ...form,
  // @ts-ignore
  pages: form.pages.map(
    (page: Object): Object => {
      // @ts-ignore
      return page.id === pageId
        ? {
            ...page,
            fields: {
              // @ts-ignore
              ...page.fields,
              [fieldId]: {
                // @ts-ignore
                ...page.fields[fieldId],
                // @ts-ignore
                assets: page.fields[fieldId].assets.map(
                  (asset: Object): Object => {
                    // @ts-ignore
                    return asset.id === assetId
                      ? updateAsset(
                          asset,
                          attributeGroupName,
                          attributeId,
                          body,
                        )
                      : asset;
                  },
                ),
              },
            },
          }
        : page;
    },
  ),
});

export const updateFhwaElements = (
  elements: Object,
  assetId: string,
  body: Object,
  elementPath: any[],
): Object => {
  const lensPath = R.lensPath(elementPath);

  return R.over(
    lensPath,
    (element: Object): Object => ({
      ...element,
      // @ts-ignore
      attributes: R.keys(element.attributes).reduce(
        (attributes: Object, attributeName: string): Object => {
          // @ts-ignore
          const attribute = element.attributes[attributeName];
          // @ts-ignore
          const newValue = body.attributes[attribute.id];
          return {
            ...attributes,
            [attributeName]: {
              ...attribute,
              value: newValue,
            },
          };
        },
        {},
      ),
    }),
    elements,
  );
};

export const updateFormInstanceFhwa = (
  form: Object,
  pageId: string,
  fieldId: string,
  assetId: string,
  body: Object,
  elementPath: any[],
): Object => ({
  ...form,
  // @ts-ignore
  pages: form.pages.map(
    (page: Object): Object => {
      // @ts-ignore
      return page.id === pageId
        ? {
            ...page,
            fields: {
              // @ts-ignore
              ...page.fields,
              [fieldId]: {
                // @ts-ignore
                ...page.fields[fieldId],
                elements: updateFhwaElements(
                  // @ts-ignore
                  page.fields[fieldId].elements,
                  assetId,
                  body,
                  elementPath,
                ),
              },
            },
          }
        : page;
    },
  ),
});

const getOrderedFormInstanceFHWAMedia = (
  form: any,
  field: any,
): MediaItem[] => {
  if (
    R.isNil(field.order) ||
    R.isEmpty(field.order) ||
    R.isNil(field.elements)
  ) {
    return [];
  }

  return field.order.reduce((acc: any, next: any) => {
    const medium = form.media[next];

    if (!R.isNil(medium) && !R.isEmpty(medium)) {
      const hydratedMedia = medium.map(med => ({ ...med, fieldId: next }));
      return [...acc, ...hydratedMedia];
    }

    return acc;
  }, []);
};

const getOrderedFormInstanceAssetMedia = (
  form: any,
  field: any,
): MediaItem[] => {
  if (R.isNil(field.assets) || R.isEmpty(field.assets)) {
    return [];
  }

  return field.assets.reduce((acc: any, next: any) => {
    const medium = form.media[next.id];

    if (medium) {
      const hydratedMedia = medium.map(med => ({ ...med, fieldId: next.id }));
      return [...acc, ...hydratedMedia];
    }

    return acc;
  }, []);
};

const getOrderedPciMediaIds = field => {
  const distresses = R.pathOr([], ['pci', 'distress'], field);

  return distresses.reduce((acc, distress) => {
    if (!distress.mediaIds) {
      return acc;
    }

    return [...acc, ...distress.mediaIds];
  }, []);
};

const getOrderedFormInstancePageMedia = (form: any, page: any): MediaItem[] => {
  return page.order.reduce((acc: MediaItem[], fieldId: string) => {
    const field = page.fields[fieldId];
    if (field && field.type === 'asset') {
      const assetMedia = getOrderedFormInstanceAssetMedia(form, field);
      return [...acc, ...assetMedia];
    }

    if (field && field.type === FormModuleKeys.CI) {
      const ciMedia = form.media[form.inventoryAssetId] || [];
      return [...acc, ...ciMedia];
    }

    if (field && field.type === FormModuleKeys.PCI) {
      const pciMediaIds = getOrderedPciMediaIds(field);
      const assetMedia = form.media[form.inventoryAssetId] || [];

      return [
        ...acc,
        ...assetMedia.filter(medium => pciMediaIds.includes(medium.id)),
      ];
    }

    if (field && field.type === FormModuleKeys.FHWA) {
      const fhwaMedia = getOrderedFormInstanceFHWAMedia(form, field);
      return [...acc, ...fhwaMedia];
    }

    const medium = form.media[fieldId];

    if (!R.isNil(medium) && !R.isEmpty(medium)) {
      const hydratedMedia = medium.map(med => ({ ...med, fieldId }));
      return [...acc, ...hydratedMedia];
    }

    return acc;
  }, []);
};

export const getOrderedFormInstanceMedia = (form: any): any => {
  const media = form.pages.reduce(
    (acc: MediaItem[], page: FormInstancePageType) => {
      const pageMedia = getOrderedFormInstancePageMedia(form, page);

      return [...acc, ...pageMedia];
    },
    [],
  );

  return media;
};

export const updateFormInstanceMedia = (
  form: any,
  subjectId: string,
  mediaId: string,
  name: string,
): any => {
  return {
    ...form,
    media: {
      ...form.media,
      [subjectId]: form.media[subjectId].map((medium: any): any => {
        return medium.id === mediaId ? { ...medium, name } : medium;
      }),
    },
  };
};

export const getCiModuleScore = createSelector(formInstanceSelector, form => {
  // @ts-ignore
  if (!form?.pages) {
    return null;
  }

  // @ts-ignore
  return form.pages.reduce((score, { fields }) => {
    const ciField = R.values(fields).find(
      ({ type }) => type === FormModuleKeys.CI,
    );

    return score ?? ciField?.score?.value ?? null;
  }, null);
});

const getCiModuleEmergencySelected = (form: FormInstanceTemplateType) => {
  if (!form?.pages) {
    return false;
  }

  return form.pages.reduce((acc, { fields }) => {
    const ciField = R.values(fields).find(
      ({ type }) => type === FormModuleKeys.CI,
    );

    if (!ciField?.inspections) {
      return false;
    }

    const inspectionEmergencySelected = ciField.inspections.reduce(
      (innerAcc, inspection) => {
        if (!inspection?.choices) {
          return false;
        }

        const chosenChoice = inspection.choices.find(
          choice => choice.rating === inspection.rating,
        );

        return innerAcc || !!chosenChoice?.emergency;
      },
      false,
    );

    return acc || inspectionEmergencySelected;
  }, false);
};

export const getCiModuleEmergencySelectedSelector = createSelector(
  formInstanceSelector,
  getCiModuleEmergencySelected,
);

const mergeCiFields = (
  stateField: FormInstanceFieldItem,
  reduxField: FormInstanceFieldItem,
): FormInstanceFieldItem => {
  return {
    ...stateField,
    score: reduxField.score,
    inspections: stateField.inspections.map((inspection, inspectionIndex) => {
      const reduxStateInspection = reduxField.inspections[inspectionIndex];

      return {
        ...inspection,
        mediaIds: reduxStateInspection.mediaIds,
      };
    }),
  };
};

const mergePciSummaryFields = (
  stateField: FormInstanceFieldItem,
  reduxField: FormInstanceFieldItem,
): FormInstanceFieldItem => {
  return { ...stateField, pci: reduxField.pci };
};

const mergeFhwaFields = (
  stateField: FormInstanceFieldItem,
  reduxField: FormInstanceFieldItem,
) => {
  return {
    ...reduxField,
  };
};

// mergeFormStates merges the state of a form instance in component and redux state
// preferring component state except for fields where BE should be explicit source of truth
export const mergeFormStates = (componentState: any, reduxState: any): any => {
  const isEmergencySelected = getCiModuleEmergencySelected(reduxState);

  return {
    ...componentState,
    media: reduxState.media,
    pages: componentState.pages.map((page, index) => {
      const reduxStatePage = reduxState.pages[index];

      const fields = Object.entries(page.fields).reduce(
        (acc, [id, field]: [string, any]) => {
          const reduxField = reduxStatePage.fields[id];

          switch (field.type) {
            case FormModuleKeys.CI: {
              return {
                ...acc,
                [id]: isEmergencySelected
                  ? reduxField
                  : mergeCiFields(field, reduxField),
              };
            }
            case FormModuleKeys.PCI_SUMMARY: {
              return { ...acc, [id]: mergePciSummaryFields(field, reduxField) };
            }
            case FormModuleKeys.FHWA: {
              return { ...acc, [id]: mergeFhwaFields(field, reduxField) };
            }
            default: {
              return { ...acc, [id]: field };
            }
          }
        },
        {},
      );

      return {
        ...page,
        fields,
      };
    }),
  };
};
