import React from 'react';

import { WorkCollectionItem } from '@atom/types/work';

export interface Props {
  title: string;
  workOrdersDictionary: { [key: string]: WorkCollectionItem };
}

const WorkCalendarEvent = ({ title, workOrdersDictionary }: Props) => {
  // Due to library constraints with passing props per event,
  // title is equal to the workOrder's id.
  return <span>{workOrdersDictionary[title].name}</span>;
};

export default WorkCalendarEvent;
