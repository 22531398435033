import React from 'react';

import { GroupKeys } from './expendituresChartUtils';

interface Context {
  hiddenGroup: GroupKeys;
  focusDataKey: string;
  actualFields: string[];
  remainingFields: string[];
  handleLabelClick: (groupKey: GroupKeys) => void;
  refreshData: () => void;
}

export const initialState: Context = {
  hiddenGroup: null,
  focusDataKey: null,
  actualFields: null,
  remainingFields: null,
  handleLabelClick: () => {},
  refreshData: () => {},
};

const ExpendituresChartContext = React.createContext<Context>(initialState);

export default ExpendituresChartContext;
