import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import accountUtilities from '@atom/utilities/accountUtilities';
import { GRAPHQL_ENDPOINT } from '@atom/utilities/endpoints';
import { interceptApolloError } from '@atom/utilities/interceptors';
import {
  ATOM_DEVICE_HEADER,
  ATOM_USER_HEADER,
} from '@atom/utilities/requestUtilities';

const headersLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
    const email = accountUtilities.getLoggedInEmail();

    return {
      headers: {
        ...headers,
        [ATOM_USER_HEADER]: email || '',
        [ATOM_DEVICE_HEADER]: 'desktop',
      },
    };
  });
  return forward(operation).map(result => {
    return result;
  });
});

const errorLink = onError(error => {
  interceptApolloError({ ...error });
});

const httpLink = new HttpLink({
  uri: GRAPHQL_ENDPOINT,
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    resultCaching: true,
    typePolicies: {
      ExportProjection: {
        keyFields: false,
      },
      TaskConnectionItem: {
        keyFields: false,
      },
      AssignedRole: {
        keyFields: false,
      },
      AssetAttributeGroup: {
        keyFields: false,
      },
      AssetAttribute: {
        keyFields: false,
      },
      TaskField: {
        keyFields: false,
      },
      TaskUser: {
        keyFields: false,
      },
      TimeEntryComputedBudget: {
        keyFields: false,
      },
      WorkOrderTemplateMedia: {
        keyFields: false,
      },
      SchemaTree: {
        keyFields: false,
      },
    },
  }),
  link: ApolloLink.from([errorLink, headersLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});

export default client;
