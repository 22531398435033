import actionTypes from './actionTypes';

export const getMediaSuccess = data => ({
  type: actionTypes.GET_MEDIA_SUCCESS,
  data,
});

export const getMediaFailure = () => ({
  type: actionTypes.GET_MEDIA_FAILURE,
});

export const retrieveMedia = data => ({
  type: actionTypes.REQUEST_MEDIA,
  data,
});

export const getMediaTotalSuccess = data => ({
  type: actionTypes.GET_MEDIA_TOTAL_SUCCESS,
  data,
});

export const requestPatchMedia = data => ({
  type: actionTypes.REQUEST_PATCH_MEDIA,
  data,
});

export const patchMediaSuccess = data => ({
  type: actionTypes.PATCH_MEDIA_SUCCESS,
  data,
});

export const patchMediaFailure = () => ({
  type: actionTypes.PATCH_MEDIA_FAILURE,
});

export const requestDeleteMedia = data => ({
  type: actionTypes.REQUEST_DELETE_MEDIA,
  data,
});

export const deleteMediaSuccess = data => ({
  type: actionTypes.DELETE_MEDIA_SUCCESS,
  data,
});

export const deleteMediaFailure = () => ({
  type: actionTypes.DELETE_MEDIA_FAILURE,
});

export const requestMediaFolderTree = data => ({
  type: actionTypes.REQUEST_MEDIA_FOLDER_TREE,
  data,
});

export const getMediaFolderTreeSuccess = data => ({
  type: actionTypes.GET_MEDIA_FOLDER_TREE_SUCCESS,
  data,
});

export const getMediaFolderTreeFailure = () => ({
  type: actionTypes.GET_MEDIA_FOLDER_TREE_FAILURE,
});

export const requestMediaFolderTreeUpdate = data => ({
  type: actionTypes.REQUEST_MEDIA_FOLDER_TREE_UPDATE,
  data,
});

export const updateMediaFolderTreeSuccess = data => ({
  type: actionTypes.UPDATE_MEDIA_FOLDER_TREE_SUCCESS,
  data,
});

export const updateMediaFolderTreeFailure = () => ({
  type: actionTypes.UPDATE_MEDIA_FOLDER_TREE_FAILURE,
});

export const requestMediaTotals = data => ({
  type: actionTypes.REQUEST_MEDIA_TOTALS,
  data,
});

export const getMediaTotalsSuccess = data => ({
  type: actionTypes.GET_MEDIA_TOTALS_SUCCESS,
  data,
});

export const getMediaTotalsFailure = () => ({
  type: actionTypes.GET_MEDIA_TOTALS_FAILURE,
});

export const retrieveMediaFolders = data => ({
  type: actionTypes.REQUEST_MEDIA_FOLDERS,
  data,
});

export const getMediaFoldersSuccess = data => ({
  type: actionTypes.GET_MEDIA_FOLDERS_SUCCESS,
  data,
});

export const getMediaFoldersFailure = () => ({
  type: actionTypes.GET_MEDIA_FOLDERS_FAILURE,
});

export const requestMediaFolderUpdate = data => ({
  type: actionTypes.REQUEST_MEDIA_FOLDER_UPDATE,
  data,
});

export const updateMediaFolderSuccess = data => ({
  type: actionTypes.UPDATE_MEDIA_FOLDER_SUCCESS,
  data,
});

export const updateMediaFolderFailure = () => ({
  type: actionTypes.UPDATE_MEDIA_FOLDER_FAILURE,
});

export const requestMediaFolderCreate = data => ({
  type: actionTypes.REQUEST_MEDIA_FOLDER_CREATE,
  data,
});

export const createMediaFolderSuccess = data => ({
  type: actionTypes.CREATE_MEDIA_FOLDER_SUCCESS,
  data,
});

export const createMediaFolderFailure = () => ({
  type: actionTypes.CREATE_MEDIA_FOLDER_FAILURE,
});

export const requestMediaFolderDelete = data => ({
  type: actionTypes.REQUEST_MEDIA_FOLDER_DELETE,
  data,
});

export const deleteMediaFolderSuccess = data => ({
  type: actionTypes.DELETE_MEDIA_FOLDER_SUCCESS,
  data,
});

export const deleteMediaFolderFailure = () => ({
  type: actionTypes.DELETE_MEDIA_FOLDER_FAILURE,
});
