import React, { useContext, useEffect, useRef, useState } from 'react';
import * as R from 'ramda';

import { Icon, IconButton, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  AnalyticsFolderTree as FolderTree,
  SELECTED_BORDER_WIDTH,
} from '@atom/types/analytics';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import AnalyticsContext from './AnalyticsContext';
import AnalyticsFolderRow from './AnalyticsFolderRow';
import FolderOptions from './FolderOptions';

import './analyticsFolderTree.css';

const styles = {
  indentation: {
    paddingLeft: '2rem',
  },
  iconStyle: {
    fontSize: '24px',
    margin: 0,
  },
  overflowStyle: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '12rem',
  },
};

const AnalyticsFolderTree = () => {
  const {
    openFolders,
    toggleOpen,
    setActiveFolderId,
    activeFolderId,
    treeData,
    setIsActiveFolderLeafNode,
    setSearching,
  } = useContext(AnalyticsContext);

  const expanded = openFolders.has(treeData.id);
  const [overflowing, setOverflowing] = useState<boolean>(false);
  const container = useRef<HTMLDivElement>();

  useEffect(() => {
    const { offsetWidth, scrollWidth } = container.current;
    setOverflowing(offsetWidth < scrollWidth);
  }, [treeData.name]);

  const arrowDirection = expanded ? 'arrow_drop_down' : 'arrow_right';
  const rowStyle =
    treeData.id === activeFolderId ? 'folder-row selected' : 'folder-row';

  const renderExpandedArrow = !R.isEmpty(treeData.children);

  const onClick = () => {
    setActiveFolderId(treeData.id);
    setIsActiveFolderLeafNode(R.isEmpty(treeData.children));
    setSearching(false);
  };

  const isActiveFolder = activeFolderId === 'analytics';

  const containerStyle = {
    borderLeft: isActiveFolder
      ? `${SELECTED_BORDER_WIDTH} solid ${colors.brand.blue}`
      : 'none',
  };

  const folderDisplayStyleArrow = renderExpandedArrow ? {} : styles.indentation;

  const folderDisplayStyle = {
    ...folderDisplayStyleArrow,
    marginLeft: isActiveFolder ? `-${SELECTED_BORDER_WIDTH}` : '0',
  };

  const showFolderOptions = hasRolePermissions(ROLE_SETS.ORG_ADMIN);

  return (
    <>
      <div styleName={rowStyle} style={containerStyle} onClick={onClick}>
        <div
          ref={container}
          styleName="folder-name-container"
          style={folderDisplayStyle}
        >
          {renderExpandedArrow && (
            <IconButton
              onClick={() => toggleOpen(treeData.id)}
              style={styles.iconStyle}
            >
              <Icon color={colors.neutral.dim}>{arrowDirection}</Icon>
            </IconButton>
          )}
          <Icon style={styles.iconStyle} color={colors.neutral.gray}>
            folder
          </Icon>
          <div styleName="row-name-container">
            <div>
              {!overflowing ? (
                treeData.name
              ) : (
                <Tooltip
                  lightTooltip
                  title={treeData.name}
                  placement="right-start"
                >
                  <p style={styles.overflowStyle}>{treeData.name}</p>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        {showFolderOptions && <FolderOptions folder={treeData} isRootFolder />}
      </div>
      {expanded ? (
        <>
          {treeData.children.map((child: FolderTree) => (
            <AnalyticsFolderRow key={child.id} folder={child} indentation={0} />
          ))}
        </>
      ) : (
        <div />
      )}
    </>
  );
};

export default AnalyticsFolderTree;
