import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import TaskEnumMultipleField from '@atom/components/common/workOrderDetail/taskFields/TaskEnumMultipleField';
import TaskEnumSingleField from '@atom/components/common/workOrderDetail/taskFields/TaskEnumSingleField';
import WorkOrdersContext, {
  getTaskField,
  WorkOrdersInputActionTypes,
} from '@atom/components/workOrders/WorkOrdersContext';
import { GET_WORK_ORDER_TEMPLATE } from '@atom/graph/workTemplate';
import { Icon, IconButton } from '@atom/mui';
import { DataType } from '@atom/types/dataType';
import { Task, TaskField } from '@atom/types/task';
import { FieldFilter } from '@atom/types/work';
import {
  convertDateToLocalDayEnd,
  convertDateToLocalDayStart,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import { isCascadingField } from '@atom/utilities/workOrderFieldUtilities';

import WorkOrdersFiltersDatePicker from './WorkOrdersFiltersDatePicker';
import WorkOrdersFiltersNumericRange from './WorkOrdersFiltersNumericRange';

import './workOrdersFilters.css';

const WorkOrdersTaskFieldsFilter = () => {
  const { workOrdersInputCart, dispatch } = useContext(WorkOrdersContext);

  const [tasks, setTasks] = useState<Task[]>([]);
  const [collapsed, setCollapsed] = useState<{
    [key: string]: boolean;
  }>({});

  const [getWorkOrderTemplate, { data: workOrderTemplateData }] = useLazyQuery<
    { workOrderTemplate: { tasks } },
    { id: string }
  >(GET_WORK_ORDER_TEMPLATE);

  useEffect(() => {
    if (workOrderTemplateData) {
      setTasks(workOrderTemplateData.workOrderTemplate.tasks);
    }
  }, [workOrderTemplateData]);

  useEffect(() => {
    if (workOrdersInputCart.workTemplateIds.length === 1) {
      getWorkOrderTemplate({
        variables: {
          id: workOrdersInputCart.workTemplateIds.join(''),
        },
      });
    } else if (workOrdersInputCart.workTemplateIds.length !== 1) {
      setTasks(null);
    }
  }, [workOrdersInputCart.workTemplateIds]);

  const onCollapse = (name: string) => {
    setCollapsed({ ...collapsed, [name]: !collapsed[name] });
  };

  const handleDateStartChange = (
    fieldId?: string,
    fieldProperty?: keyof FieldFilter,
    taskId?: string,
  ) => (_, val: any) => {
    const dateMillis = val ? convertDateToLocalDayStart(val) : null;

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_TASKS_FIELD,
      data: {
        taskId,
        fieldId,
        fieldProperty,
        value: dateMillis,
      },
    });
  };

  const handleDateEndChange = (
    fieldId?: string,
    fieldProperty?: keyof FieldFilter,
    taskId?: string,
  ) => (_, val: any) => {
    const dateMillis = val ? convertDateToLocalDayEnd(val) : null;

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_TASKS_FIELD,
      data: {
        taskId,
        fieldId,
        fieldProperty,
        value: dateMillis,
      },
    });
  };

  const handleNumericFieldChange = (
    fieldId?: string,
    fieldProperty?: keyof FieldFilter,
    taskId?: string,
  ) => (_, val: any) => {
    const value = val === '' ? null : Number(val);

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_TASKS_FIELD,
      data: {
        taskId,
        fieldId,
        fieldProperty,
        value,
      },
    });
  };

  const handleChange = (
    fieldId?: string,
    fieldProperty?: keyof FieldFilter,
    taskId?: string,
  ) => (_, val: any) => {
    const value = val ? val.valueOf() : null;

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_TASKS_FIELD,
      data: {
        taskId,
        fieldId,
        fieldProperty,
        value,
      },
    });
  };

  const getDateValue = (
    fieldId?: string,
    fieldProperty?: keyof FieldFilter,
    taskId?: string,
  ) => {
    const field = getTaskField(workOrdersInputCart, fieldId, taskId);

    if (field && !isNilOrEmpty(field[fieldProperty])) {
      return new Date(Number(field[fieldProperty]));
    }

    return null;
  };

  const getContent = (field: TaskField, taskId: string) => {
    if (isCascadingField(field)) {
      return <div />;
    }

    const components = {
      [DataType.DATE]: (
        <div styleName="custom-field-container">
          <WorkOrdersFiltersDatePicker
            key={field.id}
            field={field}
            startDateValue={getDateValue(field.id, 'valueStart', taskId)}
            endDateValue={getDateValue(field.id, 'valueEnd', taskId)}
            onStartDateChange={handleDateStartChange(
              field.id,
              'valueStart',
              taskId,
            )}
            onEndDateChange={handleDateEndChange(field.id, 'valueEnd', taskId)}
          />
        </div>
      ),
      [DataType.DATE_TIME]: (
        <div styleName="custom-field-container">
          <WorkOrdersFiltersDatePicker
            key={field.id}
            field={field}
            startDateValue={getDateValue(field.id, 'valueStart', taskId)}
            endDateValue={getDateValue(field.id, 'valueEnd', taskId)}
            onStartDateChange={handleChange(field.id, 'valueStart', taskId)}
            onEndDateChange={handleChange(field.id, 'valueEnd', taskId)}
          />
        </div>
      ),
      [DataType.ENUM_SINGLE]: (
        <div styleName="custom-field-container">
          <TaskEnumSingleField
            key={field.id}
            field={{
              ...field,
              value: getTaskField(workOrdersInputCart, field.id, taskId)?.value,
            }}
            onChange={handleChange(field.id, 'value', taskId)}
          />
        </div>
      ),
      [DataType.ENUM_MULTIPLE]: (
        <div styleName="custom-field-container">
          <TaskEnumMultipleField
            key={field.id}
            field={{
              ...field,
              value: getTaskField(workOrdersInputCart, field.id, taskId)
                ?.values,
            }}
            onChange={handleChange(field.id, 'values', taskId)}
          />
        </div>
      ),
      [DataType.NUMBER]: (
        <div styleName="custom-field-container">
          <WorkOrdersFiltersNumericRange
            field={field}
            startValue={
              getTaskField(workOrdersInputCart, field.id, taskId)?.valueStart
            }
            endValue={
              getTaskField(workOrdersInputCart, field.id, taskId)?.valueEnd
            }
            onStartChange={handleNumericFieldChange(
              field.id,
              'valueStart',
              taskId,
            )}
            onEndChange={handleNumericFieldChange(field.id, 'valueEnd', taskId)}
          />
        </div>
      ),
    };

    return components[field.dataType];
  };

  return (
    <>
      {tasks.map(task =>
        !isNilOrEmpty(task?.fields) ? (
          <div key={task.id}>
            <div styleName="filters-section-header">
              <div>{task.name}</div>
              <IconButton onClick={() => onCollapse(task.id)} edge="end">
                <Icon>
                  {collapsed[task.id]
                    ? 'keyboard_arrow_down'
                    : 'keyboard_arrow_up'}
                </Icon>
              </IconButton>
            </div>
            {!collapsed[task.id] &&
              task.fields.map(field => getContent(field, task.id))}
          </div>
        ) : (
          <div />
        ),
      )}
    </>
  );
};

export default WorkOrdersTaskFieldsFilter;
