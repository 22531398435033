import { WorkTypeVariant } from '@atom/utilities/workTemplateUtilities';

export enum ModalVariant {
  PUBLISH_CONFIRM = 'PUBLISH_CONFIRM',
  PUBLISH_ERROR = 'PUBLISH_ERROR',
  DRAFT_CONFIRM = 'DRAFT_CONFIRM',
  DRAFT_ERROR = 'DRAFT_ERROR',
}

export const regularModalValues = {
  [ModalVariant.PUBLISH_CONFIRM]: {
    title: 'Publish Work Template',
    content:
      'Published work templates can be set back to Draft and modified as long as they are not in use. Are you sure you want to publish this work template?',
    button: 'PUBLISH',
  },
  [ModalVariant.DRAFT_CONFIRM]: {
    title: 'Unpublish Work Template to Draft Status',
    content:
      'Draft work templates cannot be used to create work. Draft work templates can be modified and published again. Are you sure you want to set this work template to Draft?',
    button: 'CONFIRM',
  },
  [ModalVariant.DRAFT_ERROR]: {
    title: 'Cannot Change Work Template Status to Draft',
    content:
      'Work template status cannot be changed to Draft because it was used to create work.',
    button: 'OK',
  },
};

export const parentModalValues = {
  [ModalVariant.PUBLISH_CONFIRM]: {
    title: 'Publish Parent Work Template',
    content:
      'Published parent work templates can be set back to Draft and modified as long as they do not have child work templates that are in use. Are you sure you want to publish this parent work template?',
    button: 'PUBLISH',
  },
  [ModalVariant.DRAFT_CONFIRM]: {
    title: 'Unpublish Parent Work Template to Draft Status',
    content:
      'Setting a parent work template back to Draft will set all its child work templates back to Draft. Are you sure you want to set this parent work template to Draft?',
    button: 'CONFIRM',
  },
  [ModalVariant.DRAFT_ERROR]: {
    title: 'Cannot change Parent Work Template Status to Draft',
    content:
      'Parent work template status cannot be changed to Draft because it has child work templates that were used to create work.',
    button: 'OK',
  },
};

export const childModalValues = {
  [ModalVariant.PUBLISH_CONFIRM]: {
    title: 'Publish Child Work Template',
    content:
      'Published child work templates can be set back to Draft and modified as long as they are not in use. Are you sure you want to publish this child work template?',
    button: 'PUBLISH',
  },
  [ModalVariant.PUBLISH_ERROR]: {
    title: 'Cannot Publish Child Work Template',
    content:
      'This child work template cannot be published because its parent work template is not published.',
    button: 'OK',
  },
  [ModalVariant.DRAFT_CONFIRM]: {
    title: 'Unpublish Child Work Template to Draft Status',
    content:
      'Draft child work templates cannot be used to create work. Draft child work templates can be modified and published again. Are you sure you want to set this child work template to Draft?',
    button: 'CONFIRM',
  },
  [ModalVariant.DRAFT_ERROR]: {
    title: 'Cannot Change Child Work Template Status to Draft',
    content:
      'Child work template status cannot be changed to Draft because it was used to create work.',
    button: 'OK',
  },
};

export const modalVariants = {
  [WorkTypeVariant.REGULAR]: regularModalValues,
  [WorkTypeVariant.PARENT]: parentModalValues,
  [WorkTypeVariant.CHILD]: childModalValues,
};

export const getModalValue = (
  workTypeVariant: WorkTypeVariant,
  modalVariant: ModalVariant,
) => {
  if (!workTypeVariant || !workTypeVariant) {
    return {};
  }

  return modalVariants[workTypeVariant][modalVariant];
};
