import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import {
  useGoogleMapsStateUpdater,
  useGoogleMapsStateValue,
  withGoogleMapsState,
} from '@atom/components/common/map/hooks/googleMapsStateHook';
import Map from '@atom/components/common/map/Map';
import MapMarkers from '@atom/components/common/map/MapMarkers';
import MapPinMarker from '@atom/components/common/map/markers/MapPinMarker';
import { USER_UPDATE } from '@atom/graph/user';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Progress } from '@atom/mui';
import { getLoggedInUserMapLocationSelector } from '@atom/selectors/mapSelectors';
import {
  LocationUpdatePayload,
  MapEditMetadata,
  MapEditMetadataType,
} from '@atom/types/map';
import { UserDetail, UserUpdateInput } from '@atom/types/user';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import history from '@atom/utilities/history';
import {
  getGeoJsonFromMapBounds,
  getGoogleShapeCenter,
  getMapCenterAndZoomParams,
  getMapEditMetadata,
  urlValueToLatLng,
} from '@atom/utilities/mapUtilities';

import UserContext from '../UserContext';

const USER_DETAIL_ZOOM = 14;

const UserDetailMap = () => {
  const currentUserLocation = useSelector(getLoggedInUserMapLocationSelector);

  const dispatch = useGoogleMapsStateUpdater();
  const { isLoaded } = useGoogleMapsStateValue();
  const preferences = usePreferences();

  const { user, refetch } = useContext(UserContext);

  const [map, setMap] = useState<google.maps.Map>(null);
  const [coordinates, setCoordinates] = useState<string>(null);

  const [updateUser, { loading: updateUserLoading }] = useMutation<
    { userUpdate: UserDetail },
    { input: UserUpdateInput }
  >(USER_UPDATE, {
    onCompleted: () => {
      refetch();
    },
  });

  useEffect(() => {
    dispatch({
      type: 'SET',
      payload: {
        key: 'grab',
        data: false,
      },
    });
  }, []);

  const [queryParams, setQueryParams] = useQueryParams({
    id: StringParam,
    center: StringParam,
    zoom: NumberParam,
    searchPoint: StringParam,
  });

  useEffect(() => {
    const center = getGoogleShapeCenter(user?.location);
    setQueryParams({
      ...queryParams,
      zoom: USER_DETAIL_ZOOM,
      center: `${center?.lat},${center?.lng}`,
    });

    history.handleHistoryChange(location, 'POP');
  }, []);

  const onLoad = useCallback(setMap, []);

  const onIdle = useCallback(() => {
    try {
      const params = getMapCenterAndZoomParams(
        map,
        queryParams,
        preferences,
        currentUserLocation,
      );

      if (!R.equals(queryParams, params) || !coordinates) {
        setQueryParams(params);
        R.pipe(
          getGeoJsonFromMapBounds,
          R.prop('coordinates'),
          JSON.stringify,
          setCoordinates,
        )(map);

        history.handleHistoryChange(location, 'POP');
      }
    } catch {
      setQueryParams(null);

      history.handleHistoryChange(location, 'POP');
    }
  }, [map, setQueryParams, queryParams, coordinates, preferences]);

  const onSave = (payload: LocationUpdatePayload) => {
    updateUser({
      variables: {
        input: {
          id: user.id,
          location: payload?.location,
        },
      },
    });
  };

  const canUpdateLocation = hasRolePermissions(ROLE_SETS.ORG_ADMIN);

  const mapEditMetadata: MapEditMetadata[] = useMemo(
    () => [
      getMapEditMetadata(user, canUpdateLocation, MapEditMetadataType.USER),
    ],
    [user],
  );

  const mapData = { users: [user] };

  return !isLoaded ? (
    <Progress style={{ height: '100%' }} />
  ) : (
    <Map
      onIdle={onIdle}
      onLoad={onLoad}
      // @ts-ignore
      center={queryParams.center && urlValueToLatLng(queryParams.center)}
      zoom={queryParams.zoom}
      mapStyleControl
      layerControls
      mapSearchBox
      editControls
      mapEditMetadata={mapEditMetadata}
      onSave={onSave}
      locationEditZoom={USER_DETAIL_ZOOM}
    >
      {!updateUserLoading && (
        // @ts-ignore
        <MapMarkers onClick={() => {}} data={JSON.stringify(mapData)} />
      )}
      {queryParams.searchPoint && (
        <MapPinMarker center={queryParams.searchPoint} />
      )}
    </Map>
  );
};

export default withGoogleMapsState(UserDetailMap);
