import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import AddTeamModal from '@atom/components/common/workOrderDetail/addTeamModal/AddTeamModal';
import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import {
  WORK_TEMPLATE_TASK_ASSIGNEE_CREATE,
  WORK_TEMPLATE_TASK_USER_GROUP_CREATE,
} from '@atom/graph/workTemplate';
import { Icon, IconButton } from '@atom/mui';
import { sortTaskUsersByName } from '@atom/selectors/taskSelectors';
import { TaskUserGroupInputItem } from '@atom/types/task';
import { UserDetail } from '@atom/types/user';
import {
  WorkOrderTemplateAssigneeCreateInput,
  WorkOrderTemplateUserGroupCreateInput,
  WorkTemplateTaskItem,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';

import TaskUserGroupTile from './TaskUserGroupTile';
import TaskUserTile from './TaskUserTile';

import './teamSection.css';

const TeamSection = () => {
  const { workTemplate, task, refetch } = useContext(WorkTemplateContext);

  const [expanded, setExpanded] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  const [taskUserAdd, { loading: addUserLoading }] = useMutation<
    { workOrderTemplateAssigneeCreate: WorkTemplateTaskItem },
    { input: WorkOrderTemplateAssigneeCreateInput }
  >(WORK_TEMPLATE_TASK_ASSIGNEE_CREATE, {
    onCompleted: () => {
      refetch();
    },
  });

  const [taskUserGroupAdd, { loading: addUserGroupLoading }] = useMutation<
    { workOrderTemplateUserGroupCreate: WorkTemplateTaskItem },
    { input: WorkOrderTemplateUserGroupCreateInput }
  >(WORK_TEMPLATE_TASK_USER_GROUP_CREATE, {
    onCompleted: () => {
      refetch();
    },
  });

  const handleAddUsers = async (users: UserDetail[]) => {
    await taskUserAdd({
      variables: {
        input: {
          taskId: task.id,
          workTemplateId: workTemplate.id,
          assignees: users.map((user: UserDetail) => ({
            id: user.id,
          })),
        },
      },
    });
  };

  const handleAddUserGroups = async (userGroups: TaskUserGroupInputItem[]) => {
    await taskUserGroupAdd({
      variables: {
        input: {
          taskId: task.id,
          workTemplateId: workTemplate.id,
          userGroups,
        },
      },
    });
  };

  const isUserGroupAndUserEmpty =
    R.isEmpty(task.userGroups) && R.isEmpty(task.users);

  const totalCount = R.length(task.userGroups) || 0 + R.length(task.users) || 0;
  const icon = expanded ? 'expand_less' : 'expand_more';

  const sortedUsers = sortTaskUsersByName(task.users);
  const isDisabled =
    workTemplate?.published || doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  const userGroupsOnTask = task.userGroups.map(group => group.userGroupId);
  const usersOnTask = task.users.map(user => user.id);

  const loading = addUserLoading || addUserGroupLoading;

  return (
    <>
      <div styleName="team-header-container">
        <div styleName="team-left">
          <div styleName="section-title">Teams</div>
          <div styleName="total-count">{totalCount}</div>
        </div>
        <div styleName="team-right">
          {expanded && (
            <IconButton
              onClick={() => setOpen(true)}
              edge="end"
              tooltip="Add Team"
              disabled={isDisabled}
            >
              <Icon>add</Icon>
            </IconButton>
          )}
          <IconButton onClick={() => setExpanded(!expanded)} edge="end">
            <Icon>{icon}</Icon>
          </IconButton>
        </div>
      </div>
      {expanded && (
        <div>
          {isUserGroupAndUserEmpty ? (
            <div styleName="empty-section-text">Add Team Members</div>
          ) : (
            <>
              {task.userGroups.map((userGroup, index) => (
                <TaskUserGroupTile key={index} userGroup={userGroup} />
              ))}
              {sortedUsers.map((user, index) => (
                <TaskUserTile key={index} user={user} />
              ))}
            </>
          )}
        </div>
      )}
      <AddTeamModal
        open={open}
        userGroupsOnTask={userGroupsOnTask}
        usersOnTask={usersOnTask}
        onClose={() => setOpen(false)}
        handleAddUsers={handleAddUsers}
        handleAddUserGroups={handleAddUserGroups}
        loading={loading}
        favoritesEnabled={false}
      />
    </>
  );
};

export default TeamSection;
