export const SELECTED_BORDER_WIDTH = '0.2rem';

export enum AnalyticsModal {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  MOVE = 'MOVE',
}

export type AnalyticsSortByOptions = 'name' | 'createdDate';

export interface AnalyticsConnection {
  totalCount: number;
  analytics: AnalyticsCard[];
}

export interface AnalyticsCard {
  id: string;
  name: string;
  embedLinks: string[];
  createdDate: number;
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
  folderPath?: string[];
}

export interface AnalyticsConnectionInput {
  page?: number;
  limit?: number;
  sortBy?: AnalyticsSortByOptions;
  isAscending?: boolean;
  parentFolderId?: string;
}

export interface AnalyticsFolderTree {
  id: string;
  name: string;
  hasCards: boolean;
  folderPath: string[];
  children: AnalyticsFolderTree[];
  cardsCount: number;
}

export interface AnalyticsFolder {
  id: string;
  name: string;
  parentFolderId?: string;
  ancestors: string[];
  hasCards?: boolean;
  folderPath?: string[];
}

export interface AnalyticsFolderCreateInput {
  parentFolderId: string;
  name: string;
}

export interface AnalyticsFolderUpdateInput {
  id: string;
  name: string;
}

export interface AnalyticsFoldersConnectionInput {
  parentFolderId: string;
  page: number;
  limit: number;
  sortBy: AnalyticsSortByOptions;
  isAscending: boolean;
}

export interface AnalyticsCardCreateInput {
  name: string;
  folderId: string;
  embedLinks: string[];
}

export interface AnalyticsCardUpdateInput {
  id: string;
  name: string;
  embedLinks: string[];
}

export interface AnalyticsSearch {
  id: string;
  name: string;
  createdDate: number;
  embedLinks: string[];
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

export interface AnalyticsSearchConnection {
  totalCount: number;
  analytics: AnalyticsSearch[];
}

export interface AnalyticsSearchInput {
  query: string;
  page: number;
  limit: number;
  sortBy: AnalyticsSortByOptions;
  isAscending: boolean;
}

export interface MoveAnalyticsCardInput {
  destFolderId: string;
  analyticsIds: string[];
}

export interface AnalyticsFolderSearchInput {
  query: string;
  limit?: number;
  page?: number;
  sortBy?: string;
  isAscending?: boolean;
}

export interface AnalyticsFolderSearch {
  id: string;
  name: string;
  ancestors: AnalyticsFolderSearch[];
}

export interface AnalyticsFolderSearchConnection {
  totalCount: number;
  analyticsFolders: AnalyticsFolderSearch[];
}
