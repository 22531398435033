import React, { useContext, useEffect, useRef, useState } from 'react';
import * as R from 'ramda';

import { Checkbox, Icon, IconButton, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import { AnalyticsFolderTree as FolderTree } from '@atom/types/analytics';

import AnalyticsFolderSelectionContext from './AnalyticsFolderSelectionContext';
import { PADDING_OFFSET, styles } from './analyticsFolderSelectUtils';

export interface Props {
  folder: FolderTree;
  indentation: number;
  isAncestorSelected: boolean;
}

const AnalyticsFolderSelectRow = ({
  folder,
  indentation,
  isAncestorSelected,
}: Props) => {
  const {
    openFolders,
    toggleOpen,
    handleChange,
    selectedFolderIds,
  } = useContext(AnalyticsFolderSelectionContext);
  const expanded = openFolders.has(folder.id);
  const [overflowing, setOverflowing] = useState<boolean>(false);
  const container = useRef<HTMLDivElement>();
  const selected = isAncestorSelected || selectedFolderIds.has(folder.id);

  useEffect(() => {
    const { offsetWidth, scrollWidth } = container.current;
    setOverflowing(offsetWidth < scrollWidth);
  }, [folder.name]);

  const arrowDirection = expanded ? 'arrow_drop_down' : 'arrow_right';

  const leftIcon = (
    <>
      {!R.isEmpty(folder.children) ? (
        <IconButton
          onClick={() => toggleOpen(folder.id)}
          style={styles.iconStyle}
        >
          <Icon color={colors.neutral.dim}>{arrowDirection}</Icon>
        </IconButton>
      ) : (
        <div style={styles.blankStyle} />
      )}
      <Checkbox
        checked={selected}
        onChange={() => handleChange(folder)}
        disabled={isAncestorSelected}
      />
      <Icon style={styles.iconStyle} color={colors.neutral.gray}>
        folder
      </Icon>
    </>
  );

  const containerStyle = {
    paddingLeft: `calc(1rem + ${PADDING_OFFSET * indentation}rem)`,
  };

  const overflowStyle = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: `calc(12rem - (1rem + ${PADDING_OFFSET * indentation}rem))`,
  };

  return (
    <>
      <div style={containerStyle}>
        <div style={styles.folderRowContainer}>
          {leftIcon}
          <div ref={container} style={styles.nameContainer}>
            <div>
              {!overflowing ? (
                folder.name
              ) : (
                <Tooltip
                  lightTooltip
                  title={folder.name}
                  placement="right-start"
                >
                  <p style={overflowStyle}>{folder.name}</p>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>
      {expanded ? (
        folder.children.map((child: FolderTree) => {
          return (
            <AnalyticsFolderSelectRow
              key={child.id}
              folder={child}
              indentation={indentation + 1}
              isAncestorSelected={selected}
            />
          );
        })
      ) : (
        <div />
      )}
    </>
  );
};

export default AnalyticsFolderSelectRow;
