import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as inventoryAssetActionCreators from '@atom/actions/inventoryAssetActions';
import AttributeDetails from '@atom/components/common/attributeDetail/AttributeDetails';
import { InventoryAssetActions } from '@atom/types/actions';
import { AttributesType } from '@atom/types/inventory';
import { LocationUpdatePayload } from '@atom/types/map';
import { PolicyAction } from '@atom/types/policy';
import { InventoryAssetDetailState, ReduxStore } from '@atom/types/store';
import { hasAccess } from '@atom/utilities/accessUtilities';

import InventoryDetailMap from '../inventoryDetailMap/InventoryDetailMap';

import '../../../../styles/body-pane.css';

interface ReduxStateProps {
  loading: any[];
  inventoryAssetDetail: InventoryAssetDetailState;
}

interface ReduxDispatchProps {
  inventoryAssetActions: InventoryAssetActions;
}

type Props = ReduxStateProps & ReduxDispatchProps;

class InventoryAssetInfo extends React.Component<Props> {
  onLocationUpdate = (payload: LocationUpdatePayload) => {
    const { inventoryAssetActions, inventoryAssetDetail } = this.props;

    inventoryAssetActions.requestInventoryAssetDetailUpdate({
      id: inventoryAssetDetail?.id,
      rootAssetId: inventoryAssetDetail?.id,
      location: payload?.location,
    });
  };

  onPendingApproval = (
    action: string,
    attributeGroupName: string,
    attribute: AttributesType,
  ) => {
    const { inventoryAssetDetail, inventoryAssetActions } = this.props;

    inventoryAssetActions.requestAssetAttributePendingApproval({
      action,
      attributeGroupName,
      attribute,
      assetId: inventoryAssetDetail.id,
    });
  };

  onAttributeUpdate = (payload: Object) => {
    const { inventoryAssetActions, inventoryAssetDetail } = this.props;

    inventoryAssetActions.requestInventoryAssetDetailUpdate({
      ...payload,
      rootAssetId: inventoryAssetDetail.id,
    });
  };

  render() {
    const { inventoryAssetDetail, loading } = this.props;

    const canManageChanges = hasAccess(
      PolicyAction.MANAGE_INVENTORY_CHANGES,
      inventoryAssetDetail.actions,
    );

    const canUpdate = hasAccess(
      PolicyAction.UPDATE,
      inventoryAssetDetail.actions,
    );

    return (
      <div styleName="body-container">
        {inventoryAssetDetail.isMaterial ? (
          <div styleName="center-pane-container">
            <div styleName="center-pane" style={{ overflow: 'auto' }}>
              <AttributeDetails
                onPendingApproval={this.onPendingApproval}
                inventoryAssetDetail={inventoryAssetDetail}
                onAttributeUpdate={this.onAttributeUpdate}
                loading={loading}
                canManageChanges={canManageChanges}
                canUpdate={canUpdate}
              />
            </div>
          </div>
        ) : (
          <>
            <div styleName="left-body-pane-half">
              <AttributeDetails
                onPendingApproval={this.onPendingApproval}
                inventoryAssetDetail={inventoryAssetDetail}
                onAttributeUpdate={this.onAttributeUpdate}
                loading={loading}
                canManageChanges={canManageChanges}
                canUpdate={canUpdate}
              />
            </div>
            <div styleName="right-body-pane-half">
              <InventoryDetailMap
                onSave={this.onLocationUpdate}
                asset={inventoryAssetDetail}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

export const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  inventoryAssetDetail: state.inventoryAssetDetail,
  loading: state.loading.loadingInventoryAssetAttributes,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  inventoryAssetActions: bindActionCreators(
    inventoryAssetActionCreators,
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryAssetInfo);
