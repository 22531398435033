import React, { useEffect, useState } from 'react';

import { Icon, IconButton, Modal, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { CiModuleInspection } from '@atom/types/form';

import './ci.css';

export interface Props {
  inspection: CiModuleInspection;
  onSubmit: (comments: string) => void;
  preview: boolean;
  isWorkOrderClosed: boolean;
  isEditable: boolean;
}

const CiCommentsModal = ({
  inspection,
  onSubmit,
  preview,
  isWorkOrderClosed,
  isEditable,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [comments, setComments] = useState<string>('');

  useEffect(() => {
    setComments(inspection.comments || '');
  }, [inspection, open]);

  const handleSubmit = () => {
    if (!preview && isEditable) {
      onSubmit(comments);
    }

    setOpen(false);
  };

  const hasComments = preview ? comments : inspection.comments;

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <Icon color={hasComments ? colors.brand.blue : colors.neutral.gray}>
          format_align_left
        </Icon>
      </IconButton>
      <Modal
        open={open}
        title="Notes"
        onConfirm={handleSubmit}
        onCancel={() => setOpen(false)}
        confirmButtonText="Save"
        disabled={isWorkOrderClosed || !isEditable}
      >
        <TextField
          fullWidth
          multiline
          key="name"
          value={comments}
          onChange={event => setComments(event.target.value)}
          disabled={isWorkOrderClosed || !isEditable}
        />
      </Modal>
    </>
  );
};

export default CiCommentsModal;
