import React, { useCallback, useContext } from 'react';
import { ClickAwayListener } from '@mui/material';

import PercentBar from '@atom/components/common/percentBar/PercentBar';
import { Icon, List, ListTable, Popper, Skeleton } from '@atom/mui';
import colors from '@atom/styles/colors';
import layout from '@atom/styles/layout';
import { BasicBudget, BudgetSummary } from '@atom/types/budget';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from './BudgetDetailContext';
import {
  BUDGET_COL_WIDTH,
  COMMENT_COL_WIDTH,
  COMPARE_MENU_HEIGHT,
  EmptyComparisonChart,
  getDateRange,
  ICON_COL_WIDTH,
} from './budgetDetailUtils';

import './budgetDetail.css';

const { TableRow, TableCell, TableBody } = ListTable;
const { ListItemText } = List;

const styles = {
  comparisonContainer: {
    maxHeight: COMPARE_MENU_HEIGHT,
    minWidth: '30rem',
    padding: '3rem 0 0',
    position: 'relative',
    overflow: 'auto',
  },
  loadingContainer: {
    width: '100%',
  },
  comparisonPrimaryText: {
    color: colors.neutral.black,
    fontWeight: 400,
  },
  popper: {
    backgroundColor: colors.neutral.white,
    boxShadow: layout.muiDropdownBoxShadow,
    zIndex: 100,
  },
  textNoWrap: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '17rem',
    paddingRight: '1.5rem',
  },
};

interface Props {
  handleCloseModal: () => void;
  loadingSummaries: boolean;
  budgetSummaries: BudgetSummary[];
  includeComments?: boolean;
  hideComments?: boolean;
  anchorEl: any;
  onClickAway: (event: any) => void;
}

const BudgetDetailComparison = ({
  handleCloseModal,
  loadingSummaries,
  budgetSummaries,
  includeComments = false,
  hideComments = false,
  anchorEl,
  onClickAway,
}: Props) => {
  const { comparisonBudgets, showTracking } = useContext(BudgetDetailContext);

  const getTitleNode = (budget: BasicBudget) => (
    <ListItemText
      primary={budget.name}
      primaryTextStyle={{
        ...styles.comparisonPrimaryText,
        ...styles.textNoWrap,
      }}
      primaryTypographyProps={{ style: { whiteSpace: 'nowrap' } }}
      secondary={getDateRange(budget)}
      secondaryTextStyle={styles.textNoWrap}
    />
  );

  const getSummary = useCallback(
    (id: string): BudgetSummary =>
      budgetSummaries.find(summary => summary.id === id),
    [budgetSummaries],
  );

  const getCurrencyField = (id: string, field: keyof BudgetSummary) =>
    numberToLocaleString(getSummary(id)?.[field]);

  const getDifferenceNode = (id: string) => (
    <PercentBar
      value={getSummary(id)?.remaining}
      limit={getSummary(id)?.budgetValue}
    />
  );

  const getCommentNode = (budget: BasicBudget) =>
    loadingSummaries ? (
      <Skeleton />
    ) : (
      budgetSummaries.find(summary => summary.id === budget.id)?.comment
    );

  const ValueCell = ({ children }) => (
    <TableCell width={BUDGET_COL_WIDTH} align="right">
      {loadingSummaries ? <Skeleton /> : children}
    </TableCell>
  );

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div>
        <Popper
          open
          style={styles.popper}
          anchorEl={anchorEl}
          placement="bottom-start"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 15],
              },
            },
          ]}
        >
          <div style={styles.comparisonContainer}>
            <div styleName="comparison-menu-heading">
              Compare with Reference Budgets
              <Icon styleName="clickable" onClick={handleCloseModal}>
                close
              </Icon>
            </div>
            {isNilOrEmpty(comparisonBudgets) ? (
              EmptyComparisonChart
            ) : (
              <ListTable fullHeight={false}>
                <TableBody>
                  {comparisonBudgets.map(budget => (
                    <TableRow key={budget.id} style={styles.loadingContainer}>
                      <TableCell>{getTitleNode(budget)}</TableCell>
                      <ValueCell>
                        {getCurrencyField(budget.id, 'budgetValue')}
                      </ValueCell>
                      {showTracking && (
                        <>
                          <ValueCell>
                            {getCurrencyField(budget.id, 'actualBudget')}
                          </ValueCell>
                          <ValueCell>
                            {getCurrencyField(budget.id, 'fixedCosts')}
                          </ValueCell>
                          <ValueCell>{getDifferenceNode(budget.id)}</ValueCell>
                        </>
                      )}
                      {includeComments && (
                        <TableCell width={COMMENT_COL_WIDTH}>
                          {!hideComments && getCommentNode(budget)}
                        </TableCell>
                      )}
                      <TableCell width={ICON_COL_WIDTH} />
                    </TableRow>
                  ))}
                </TableBody>
              </ListTable>
            )}
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default BudgetDetailComparison;
