import * as R from 'ramda';
import { createSelector } from 'reselect';

import {
  DEFAULT_MAP_CENTER,
  getGeoJSONLocation,
  getGoogleShapeCenter,
} from '@atom/utilities/mapUtilities';
import { replace } from '@atom/utilities/replaceUtilities';

const mapWorkOrdersSelector = R.pathOr([], ['map', 'workOrders']);
const mapAssetsSelector = R.pathOr([], ['map', 'assets']);
export const defaultClusterDensitySelector = R.pathOr('default', [
  'preferences',
  'map',
  'clusterDensity',
]);
export const preferenceMapCenterSelector = R.pathOr(null, [
  'preferences',
  'map',
  'center',
]);

const loggedInUserMapLocation = state => {
  const preferencesMapCenter = preferenceMapCenterSelector(state);
  const center = preferencesMapCenter || DEFAULT_MAP_CENTER;
  return R.pathOr(
    getGeoJSONLocation(center),
    ['userProfile', 'location'],
    state,
  );
};

const inventorySchemasSelector = R.pathOr([], ['inventorySchemas']);

export const getLoggedInUserMapLocationSelector = createSelector(
  loggedInUserMapLocation,
  location => getGoogleShapeCenter(location),
);

export const getMapInventorySchemas = createSelector(
  inventorySchemasSelector,
  inventorySchemas => inventorySchemas.filter(schema => !schema.isMaterial),
);

export const getClustersAndEntities = entities => {
  const uniqueLocations = entities.reduce((acc, next) => {
    if (next.location.type !== 'Point') {
      return acc;
    }

    // The unique identifier here is a concatenation of the lat/long
    const locationKey = `${next.location.coordinates[0]}${next.location.coordinates[1]}`;

    return !R.isNil(acc[locationKey])
      ? {
          ...acc,
          [locationKey]: acc[locationKey] + 1,
        }
      : {
          ...acc,
          [locationKey]: 0,
        };
  }, {});

  return entities.reduce(
    (acc, next) => {
      if (next.location.type !== 'Point') {
        // TODO: [ATOM-1026]  Provide localized clustering support with lineCenter
        return {
          entities: [...acc.entities, next],
          clusters: acc.clusters,
        };
      }

      // The unique identifier here is a concatenation of the lat/long
      const locationKey = `${next.location.coordinates[0]}${next.location.coordinates[1]}`;

      if (uniqueLocations[locationKey]) {
        const cluster = acc.clusters.find(clust => clust.id === locationKey);

        if (!cluster) {
          return {
            entities: acc.entities,
            clusters: [
              ...acc.clusters,
              {
                id: locationKey,
                location: next.location,
                count: 1,
                entities: [next],
              },
            ],
          };
        }

        const clusters = replace(
          clust => clust.id === locationKey,
          {
            ...cluster,
            count: cluster.count + 1,
            entities: [...cluster.entities, next],
          },
          [...acc.clusters],
        );

        return {
          entities: acc.entities,
          clusters,
        };
      }

      return {
        entities: [...acc.entities, next],
        clusters: acc.clusters,
      };
    },
    {
      entities: [],
      clusters: [],
    },
  );
};

export const getGeoJSONLineCoordinatesFromGoogleMapsCoordinates = (
  location: Number[][],
): Number[][] => {
  if (!location) {
    return [[]];
  }

  return location.map((coordinates: Number[]) => coordinates.reverse());
};

export const getMapWorkClustersAndEntitiesSelector = createSelector(
  mapWorkOrdersSelector,
  workOrders => getClustersAndEntities(workOrders),
);

export const getMapWorkClustersSelector = createSelector(
  getMapWorkClustersAndEntitiesSelector,
  clustersAndEntities => clustersAndEntities.clusters,
);

export const getMapWorkOrdersSelector = createSelector(
  getMapWorkClustersAndEntitiesSelector,
  clustersAndEntities => clustersAndEntities.entities,
);

export const getMapAssetsClustersAndEntitiesSelector = createSelector(
  mapAssetsSelector,
  workOrders => getClustersAndEntities(workOrders),
);

export const getMapAssetsClustersSelector = createSelector(
  getMapAssetsClustersAndEntitiesSelector,
  clustersAndEntities => clustersAndEntities.clusters,
);

export const getMapAssetsSelector = createSelector(
  getMapAssetsClustersAndEntitiesSelector,
  clustersAndEntities => clustersAndEntities.entities,
);
