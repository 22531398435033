import React from 'react';
import * as R from 'ramda';

import AddAssetElementModal from '@atom/components/common/elements/AddAssetElementModal';
import ElementsHeader from '@atom/components/common/elements/ElementsHeader';
import RenameModal from '@atom/components/common/RenameModal';
import {
  Collapse,
  Icon,
  IconButton,
  List,
  Menu,
  Modal,
  Progress,
  Tooltip,
} from '@atom/mui';
import colors from '@atom/styles/colors';
import fontStyles from '@atom/styles/fonts';
import { AttributesType, ChangeType } from '@atom/types/inventory';
import { PolicyAction } from '@atom/types/policy';
import { InventoryAssetDetailState } from '@atom/types/store';
import { hasAccess } from '@atom/utilities/accessUtilities';
import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';
import iconUtilities from '@atom/utilities/iconUtilities';

import '../../../common/elements/elements.css';

const { ListItemButton, ListItemText } = List;
const { MenuItem } = Menu;

const styles = {
  listStyle: {
    padding: '0',
  },
  selectedListItem: {
    boxSizing: 'border-box',
    backgroundColor: colors.utility.highlight,
    borderLeft: `3px solid ${colors.brand.blue}`,
    fontSize: fontStyles.md,
    height: '3.125rem',
    paddingTop: '0.6rem',
  },
  listItem: {
    boxSizing: 'border-box',
    borderLeft: '3px solid transparent',
    fontSize: fontStyles.md,
    height: '3.125rem',
    paddingTop: '0.6rem',
  },
  iconColor: colors.brand.blue,
  listItemTextStyle: { fontWeight: 0, marginLeft: '10px' },
  elementNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '70%',
  },
  elementRightIconContainer: {
    position: 'absolute',
    right: '1.25rem',
    display: 'flex',
    alignItems: 'center',
  },
  elementLabel: {
    padding: '0',
    margin: '0',
    marginBottom: '2px',
    fontWeight: '500',
  },
  editedAddedElementLabel: {
    padding: '0',
    margin: '0',
    marginBottom: '2px',
    fontWeight: '500',
    color: colors.brand.blue,
  },
  deletedElementLabel: {
    padding: '0',
    margin: '0',
    marginBottom: '2px',
    fontWeight: '500',
    textDecoration: 'line-through',
    textDecorationColor: colors.brand.blue,
  },
  selectedGroupStyle: {
    color: colors.brand.blue,
    fontWeight: '500',
  },
  assetType: {
    padding: '0',
    margin: '0',
    marginTop: '2px',
    color: colors.neutral.gray,
    fontSize: fontStyles.sm,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    minWidth: 0,
  },
};

enum ModalType {
  REJECT_EDIT = 'REJECT_EDIT',
  ADD_ELEMENT = 'ADD_ELEMENT',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  NONE = 'NONE',
}

interface PassedProps {
  inventoryAssetDetail: InventoryAssetDetailState;
  navigateToElement: (element: Object) => void;
  loading: boolean;
  selectedItem: any;
  onPendingApproval: (action: string, element: any) => void;
  updateSelectedItem: (item: Object, type: string) => void;
  onRenameAction: (payload: Object) => void;
  onDeleteAction: (payload: Object) => void;
  navigateToAsset: (asset: Object) => void;
  onBatchCreate: (payload: Object) => void;
  onToggle: (
    id: string,
    path: any[],
    expanded: boolean,
    hasChildren: any,
  ) => void;
}

type Props = PassedProps;

class DetailTree extends React.Component<Props> {
  state: {
    openModal: { type: ModalType; elementOrGroupId: any };
  } = { openModal: { type: ModalType.NONE, elementOrGroupId: null } };

  toggleModal = (modalToOpen: ModalType, id: any) => {
    this.setState({ openModal: { type: modalToOpen, elementOrGroupId: id } });
  };

  isModalOpen = (modalType: ModalType, id: any) => {
    const { type, elementOrGroupId } = this.state.openModal;
    return type === modalType && elementOrGroupId === id;
  };

  navigate = (element: any) => {
    if (!R.isNil(element.isGroup) && element.isGroup) {
      return;
    }
    const { navigateToElement, updateSelectedItem } = this.props;
    updateSelectedItem(element, 'element');
    navigateToElement(element);
  };

  selectAsset = () => {
    const {
      inventoryAssetDetail,
      updateSelectedItem,
      navigateToAsset,
    } = this.props;
    navigateToAsset(inventoryAssetDetail);
    updateSelectedItem(inventoryAssetDetail, 'asset');
  };

  getAttributeSubText = (element: any): string => {
    if (
      R.isNil(element.attributes) ||
      R.isEmpty(element.attributes) ||
      !Array.isArray(element.attributes)
    ) {
      return '';
    }

    return element.attributes.reduce(
      (acc: string, attribute: AttributesType): string => {
        const label = attribute.name;
        const value = attributeDisplayUtility.display(
          attribute.value,
          attribute.dataType,
          attribute.unit,
        );

        const text = `${label}: ${value}`;
        return acc ? `${acc}, ${text}` : text;
      },
      '',
    );
  };

  onPendingApproval = (action: string, element: object) => {
    const { onPendingApproval } = this.props;

    onPendingApproval(action, element);
  };

  getDisplayLabelStyle = (
    isPendingApproval: boolean,
    changeType: ChangeType,
  ) => {
    if (!isPendingApproval) {
      return styles.elementLabel;
    }

    if (changeType === ChangeType.DELETED) {
      return styles.deletedElementLabel;
    }

    if (
      changeType === ChangeType.ADDED ||
      changeType === ChangeType.EDITED ||
      changeType === ChangeType.ELEMENTS_CHANGED ||
      changeType === ChangeType.PARENT_ADDED
    ) {
      return styles.editedAddedElementLabel;
    }

    return styles.elementLabel;
  };

  getGroupStyle = (elementGroup: any) => {
    let style = {};
    if (!elementGroup || !elementGroup.isGroup) {
      return style;
    }

    elementGroup.elements.forEach(element => {
      if (element.changeType) {
        style = styles.selectedGroupStyle;
      }
    });

    return style;
  };

  getElementPendingStateControls = (element: any, id: any) => {
    const onConfirmRejectChangesModal = () => {
      this.onPendingApproval('reject', element);
      this.toggleModal(ModalType.NONE, null);
    };

    return (
      <div styleName="pending-approval-container">
        <div styleName="pending-element-label">{element.changeType}</div>
        <div styleName="pending-controls-container">
          <div styleName="pending-element-control">
            <IconButton
              tooltip="Reject"
              onClick={() => this.toggleModal(ModalType.REJECT_EDIT, id)}
            >
              <Icon color={styles.iconColor}>clear</Icon>
            </IconButton>
            <Modal
              open={this.isModalOpen(ModalType.REJECT_EDIT, id)}
              title="Reject Changes"
              onCancel={() => this.toggleModal(ModalType.NONE, null)}
              confirmButtonText="Reject"
              onConfirm={onConfirmRejectChangesModal}
            >
              Are you sure you want to reject the changes on this item?
            </Modal>
          </div>
          <div styleName="pending-element-control">
            <IconButton
              tooltip="Approve"
              onClick={() => this.onPendingApproval('accept', element)}
            >
              <Icon color={styles.iconColor}>check</Icon>
            </IconButton>
          </div>
        </div>
      </div>
    );
  };

  renderNestedElements = (
    elements: any[],
    elementPath: any[],
    level: number = 0,
  ): any[] => {
    if (R.isEmpty(elements) || R.isNil(elements)) {
      return [];
    }

    return elements.map((element: any, index: number) => {
      const {
        onToggle,
        selectedItem,
        updateSelectedItem,
        inventoryAssetDetail,
      } = this.props;
      const nestedAsset =
        R.isNil(element.isGroup) || element.isGroup
          ? element
          : R.pathOr([{}], ['elements'], element)[0];

      const { hasChildren } = nestedAsset;

      const { name, expanded, markerId } = element;

      const id = R.pathOr('', ['id'], element);

      const nestedElements = R.pathOr([], ['elements'], element);
      const nextElements =
        nestedElements[0]?.id === element.id ? [] : nestedElements;

      const path = [...elementPath, 'elements', index];

      const rootStyles =
        selectedItem.id === id ? styles.selectedListItem : styles.listItem;

      const isPendingApproval = !!nestedAsset.changeType;

      const onRename = (elementId: string, elementName: string): boolean => {
        const { onRenameAction } = this.props;

        onRenameAction({
          id: elementId,
          name: elementName,
          elementPath: path,
        });

        return true;
      };

      const onDelete = (): boolean => {
        const { onDeleteAction } = this.props;

        onDeleteAction({
          id,
          elementPath: path,
        });

        return true;
      };

      const onConfirmDeleteModal = () => {
        onDelete();
        this.toggleModal(ModalType.NONE, null);
      };

      const hasPendingDelete = element.changeType === ChangeType.DELETED;
      const canRename = hasAccess(
        PolicyAction.RENAME,
        inventoryAssetDetail.actions,
      );
      const canUpdate = hasAccess(
        PolicyAction.UPDATE,
        inventoryAssetDetail.actions,
      );

      const showDelete = canUpdate && !hasPendingDelete;
      const showRename = canRename && !hasPendingDelete;

      const canManageChanges = hasAccess(
        PolicyAction.MANAGE_INVENTORY_CHANGES,
        inventoryAssetDetail.actions,
      );

      const showChangeControls =
        canManageChanges &&
        isPendingApproval &&
        nestedAsset.changeType !== ChangeType.ELEMENTS_CHANGED &&
        nestedAsset.changeType !== ChangeType.PARENT_ADDED;

      const showOptions = showRename || showDelete || canUpdate;

      const rightIconButton = (
        <div
          styleName="element-right-icon-container"
          style={styles.elementRightIconContainer}
        >
          {showChangeControls &&
            this.getElementPendingStateControls(nestedAsset, id)}
          {showOptions && (
            <Menu>
              {canUpdate && (
                <MenuItem
                  key={`add-element-${id}`}
                  onClick={() => this.toggleModal(ModalType.ADD_ELEMENT, id)}
                  startAdornment={<Icon>add</Icon>}
                >
                  Add Element
                </MenuItem>
              )}
              {showRename && (
                <MenuItem
                  key={`rename-element-${id}`}
                  onClick={() => this.toggleModal(ModalType.EDIT, id)}
                  startAdornment={<Icon>edit</Icon>}
                >
                  Edit
                </MenuItem>
              )}
              {showDelete && (
                <MenuItem
                  key={`delete-element-${id}`}
                  onClick={() => this.toggleModal(ModalType.DELETE, id)}
                  startAdornment={<Icon>delete</Icon>}
                >
                  Delete
                </MenuItem>
              )}
            </Menu>
          )}
          {canUpdate && (
            <AddAssetElementModal
              open={this.isModalOpen(ModalType.ADD_ELEMENT, id)}
              closeModal={() => this.toggleModal(ModalType.NONE, null)}
              title="Add elements to asset"
              assetId={id}
              assetName={name}
              updateSelectedItem={updateSelectedItem}
            />
          )}
          {showRename && (
            <RenameModal
              open={this.isModalOpen(ModalType.EDIT, id)}
              closeModal={() => this.toggleModal(ModalType.NONE, null)}
              id={id}
              name={name}
              type="element"
              renameAction={onRename}
            />
          )}
          {showDelete && (
            <Modal
              open={this.isModalOpen(ModalType.DELETE, id)}
              title="Delete Element"
              onCancel={() => this.toggleModal(ModalType.NONE, null)}
              confirmButtonText="Delete"
              ConfirmButtonProps={{ style: { background: colors.brand.red } }}
              onConfirm={onConfirmDeleteModal}
            >
              Are you sure you want to delete this element?
            </Modal>
          )}
        </div>
      );

      const subText = this.getAttributeSubText(nestedAsset);

      const elementLabelStyle = this.getDisplayLabelStyle(
        isPendingApproval,
        nestedAsset.changeType,
      );

      const primaryText = (
        <div style={styles.elementNameContainer}>
          <div style={elementLabelStyle}>{name}</div>
          {!R.isEmpty(subText) && (
            <Tooltip
              lightTooltip
              title={subText.replace(/,/g, ';')}
              placement="right-start"
            >
              <div style={styles.assetType}>{subText}</div>
            </Tooltip>
          )}
        </div>
      );

      const icon =
        R.isEmpty(nextElements) && !hasChildren
          ? iconUtilities.getElementIcon(markerId, isPendingApproval)
          : expanded
          ? iconUtilities.leftElementIcon(
              markerId,
              'down-arrow',
              isPendingApproval,
            )
          : iconUtilities.leftElementIcon(
              markerId,
              'right-arrow',
              isPendingApproval,
            );

      return (
        <div key={`${index}-${id}`}>
          <ListItemButton
            disableGutters
            key={id}
            style={{ ...rootStyles, paddingLeft: `${level * 1.5}rem` }}
            onClick={(): void => {
              this.navigate(element);
              onToggle(id, path, expanded, hasChildren);
            }}
          >
            {icon}
            <ListItemText
              primaryTextStyle={styles.listItemTextStyle}
              primary={primaryText}
            />
            {rightIconButton}
          </ListItemButton>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List disablePadding>
              {this.renderNestedElements(nextElements, path, level + 1)}
            </List>
          </Collapse>
        </div>
      );
    });
  };

  renderTopLevelElements = (level: number = 0) => {
    const {
      inventoryAssetDetail,
      onToggle,
      selectedItem,
      updateSelectedItem,
    } = this.props;

    const elementGroups = R.pathOr([], ['elementGroups'], inventoryAssetDetail);

    return elementGroups.map((elementGroup: any, index: number) => {
      const { isGroup } = elementGroup;
      const id = R.pathOr('', ['id'], elementGroup);
      const topLevelElement = isGroup ? elementGroup : elementGroup.elements[0];

      const elements = R.pathOr([], ['elements'], topLevelElement);
      const elementPath = isGroup
        ? ['elementGroups', index]
        : ['elementGroups', index, 'elements', 0];

      const rootStyles =
        selectedItem.id === id && !isGroup
          ? styles.selectedListItem
          : styles.listItem;

      const groupIcon = topLevelElement.expanded
        ? iconUtilities.leftIcon('down-arrow')
        : iconUtilities.leftIcon('right-arrow');

      const isPendingApproval = !!topLevelElement.changeType;

      const elementIcon =
        !isGroup && R.isEmpty(elements) && !topLevelElement.hasChildren
          ? iconUtilities.getElementIcon(
              topLevelElement.markerId,
              isPendingApproval,
            )
          : topLevelElement.expanded
          ? iconUtilities.leftElementIcon(
              topLevelElement.markerId,
              'down-arrow',
              isPendingApproval,
            )
          : iconUtilities.leftElementIcon(
              topLevelElement.markerId,
              'right-arrow',
              isPendingApproval,
            );

      const icon = isGroup ? groupIcon : elementIcon;

      const subText = this.getAttributeSubText(topLevelElement);

      const elementLabelStyle = this.getDisplayLabelStyle(
        isPendingApproval,
        topLevelElement.changeType,
      );

      const primaryText = !isGroup ? (
        <div style={styles.elementNameContainer}>
          <div style={elementLabelStyle}>{topLevelElement.name}</div>
          {!R.isEmpty(subText) && (
            <Tooltip
              lightTooltip
              title={subText.replace(/,/g, ';')}
              placement="right-start"
            >
              <div style={styles.assetType}>{subText}</div>
            </Tooltip>
          )}
        </div>
      ) : (
        <div style={this.getGroupStyle(elementGroup)}>
          {topLevelElement.name}
        </div>
      );

      const onRename = (elementId: string, elementName: string): boolean => {
        const { onRenameAction } = this.props;

        onRenameAction({
          id: elementId,
          name: elementName,
          elementPath,
        });

        return true;
      };

      const onDelete = (): boolean => {
        const { onDeleteAction } = this.props;
        const path = R.dropLast(2, elementPath);

        onDeleteAction({
          id,
          elementPath: path,
        });

        return true;
      };

      const onConfirmDeleteModal = () => {
        onDelete();
        this.toggleModal(ModalType.NONE, null);
      };

      const hasPendingDelete =
        topLevelElement.changeType === ChangeType.DELETED;
      const canRename = hasAccess(
        PolicyAction.RENAME,
        inventoryAssetDetail.actions,
      );
      const canUpdate = hasAccess(
        PolicyAction.UPDATE,
        inventoryAssetDetail.actions,
      );

      const showDelete = canUpdate && !hasPendingDelete;
      const showRename = canRename && !hasPendingDelete;

      const canManageChanges = hasAccess(
        PolicyAction.MANAGE_INVENTORY_CHANGES,
        inventoryAssetDetail.actions,
      );

      const showChangeControls =
        canManageChanges &&
        isPendingApproval &&
        topLevelElement.changeType !== ChangeType.ELEMENTS_CHANGED &&
        topLevelElement.changeType !== ChangeType.PARENT_ADDED;

      const showOptions = showRename || showDelete || canUpdate;

      const rightIconButton = !isGroup ? (
        <div
          styleName="element-right-icon-container"
          style={styles.elementRightIconContainer}
        >
          {showChangeControls &&
            this.getElementPendingStateControls(topLevelElement, id)}
          {showOptions && (
            <Menu>
              {canUpdate && (
                <MenuItem
                  key={`add-element-${id}`}
                  startAdornment={<Icon>add</Icon>}
                  onClick={() => this.toggleModal(ModalType.ADD_ELEMENT, id)}
                >
                  Add Element
                </MenuItem>
              )}
              {showRename && (
                <MenuItem
                  key={`rename-element-${id}`}
                  onClick={() => this.toggleModal(ModalType.EDIT, id)}
                  startAdornment={<Icon>edit</Icon>}
                >
                  Edit
                </MenuItem>
              )}
              {showDelete && (
                <MenuItem
                  key={`delete-element-${id}`}
                  onClick={() => this.toggleModal(ModalType.DELETE, id)}
                  startAdornment={<Icon>delete</Icon>}
                >
                  Delete
                </MenuItem>
              )}
            </Menu>
          )}
          {canUpdate && (
            <AddAssetElementModal
              open={this.isModalOpen(ModalType.ADD_ELEMENT, id)}
              closeModal={() => this.toggleModal(ModalType.NONE, null)}
              title="Add elements to asset"
              assetId={id}
              assetName={topLevelElement.name}
              schemaId={topLevelElement.schemaId}
              updateSelectedItem={updateSelectedItem}
            />
          )}
          {showRename && (
            <RenameModal
              open={this.isModalOpen(ModalType.EDIT, id)}
              closeModal={() => this.toggleModal(ModalType.NONE, null)}
              id={id}
              name={topLevelElement.name}
              type="element"
              renameAction={onRename}
            />
          )}
          {showDelete && (
            <Modal
              open={this.isModalOpen(ModalType.DELETE, id)}
              title="Delete Element"
              onCancel={() => this.toggleModal(ModalType.NONE, null)}
              confirmButtonText="Delete"
              onConfirm={onConfirmDeleteModal}
            >
              Are you sure you want to delete this element?
            </Modal>
          )}
        </div>
      ) : (
        <div style={{ position: 'relative' }} />
      );

      return (
        <div key={`${index}-${id}`}>
          <ListItemButton
            disableGutters
            key={index}
            style={{ ...rootStyles, paddingLeft: `${level * 1.5}rem` }}
            onClick={(): void => {
              this.navigate(topLevelElement);
              onToggle(
                id,
                elementPath,
                topLevelElement.expanded,
                topLevelElement.hasChildren,
              );
            }}
          >
            {icon}
            <ListItemText
              primaryTextStyle={styles.listItemTextStyle}
              primary={primaryText}
            />
            {rightIconButton}
          </ListItemButton>
          <Collapse in={topLevelElement.expanded} timeout="auto" unmountOnExit>
            <List disablePadding>
              {this.renderNestedElements(elements, elementPath, level + 1)}
            </List>
          </Collapse>
        </div>
      );
    });
  };

  render() {
    const { inventoryAssetDetail, loading, onBatchCreate } = this.props;
    const { schemaId, id, name } = inventoryAssetDetail;

    const topLevelListItems = this.renderTopLevelElements();

    const canUpdate = hasAccess(
      PolicyAction.UPDATE,
      inventoryAssetDetail.actions,
    );

    return loading && R.isEmpty(inventoryAssetDetail) ? (
      <Progress style={{ height: '100%' }} />
    ) : (
      <div>
        <ElementsHeader
          assetName={name}
          schemaId={schemaId}
          assetId={id}
          label="Sub Items"
          onBatchCreateAction={onBatchCreate}
          isEditDisabled={!canUpdate}
        />
        <List style={styles.listStyle}>{topLevelListItems}</List>
      </div>
    );
  }
}

export default DetailTree;
