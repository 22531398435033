import React from 'react';

import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';

import './scdotPdfReport.css';

interface Props {
  title: string;
  rows: any[];
}

const SingleSection = ({ title, rows }: Props) => {
  return (
    <div styleName="single-section-container">
      <div styleName="table-wrapper">
        <table>
          <thead>
            <tr>
              <th styleName="single-left-column">{title}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {rows.map(row => (
              <tr key={row?.name}>
                <td styleName="single-left-column">{`${row?.name}:`}</td>
                <td>
                  {row?.value
                    ? attributeDisplayUtility.display(
                        row?.value,
                        row?.dataType,
                        row?.unit,
                        !!row?.changeId,
                        row?.newValue,
                      )
                    : '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SingleSection;
