import { AttributeDataType } from './dataType';
import { PciModuleInspectionOptions } from './form';

export enum SchemaBudgetType {
  FIXED = 'FIXED',
  VARIABLE = 'VARIABLE',
  VARIABLE_COST = 'VARIABLE_COST',
}

export enum StockBasedOption {
  STOCK_BASED = 'STOCK_BASED',
  STOCK_BASED_OPTIONAL = 'STOCK_BASED_OPTIONAL',
  NONE = 'NONE',
}

export enum StartEndReadingOption {
  START_END_READING = 'START_END_READING',
  START_END_READING_OPTIONAL = 'START_END_READING_OPTIONAL',
  NONE = 'NONE',
}

export enum LocationType {
  POINT = 'Point',
  LINE_STRING = 'LineString',
}

export interface SelectedAttributeRoute {
  attributeGroupId: string;
  attributeId: string;
}

export interface SchemaElementItem {
  id: string;
  name: string;
  isGroup?: boolean;
  elements?: SchemaElementItem[];
}

export interface SchemaAttributeGroup {
  id: string;
  name: string;
  attributes: string[];
}

export interface SchemaBudget {
  isFixed: boolean;
  isTimeBased: boolean;
  stockBasedOption: StockBasedOption;
  startEndReadingOption: StartEndReadingOption;
  type: SchemaBudgetType;
  rate: number;
  unit: string;
}

export interface SchemaType {
  id: string;
  name: string;
  assetCount: number;
  assetType: string;
  attributes: any;
  attributeGroups: SchemaAttributeGroup[];
  colorId: number;
  markerId: number | null;
  isMaterial: boolean;
  budget: SchemaBudget;
  elementCount: number;
  elementGroups: SchemaElementItem[];
  locationType: LocationType;
  ciEnabled?: boolean;
  pciEnabled?: boolean;
}

export interface SchemaByIdsType {
  [uuid: string]: SchemaType[];
}

export interface SchemaConditionInspectionTemplate {
  id: string;
  name: string;
  inspections: SchemaConditionInspection[];
}

export interface SchemaConditionInspection {
  name: string;
  choices: SchemaConditionInspectionChoice[];
  rating?: string;
  comments?: string;
  mediaIds?: string[];
}

export interface SchemaConditionInspectionChoice {
  description: string;
  rating: string;
  points: number;
  emergency?: boolean;
}

export interface SchemaPavementConditionInspection {
  id: string;
  name: string;
  options: {
    [type: string]: PciModuleInspectionOptions;
  };
}

export interface SchemaAttribute {
  id?: string;
  name: string;
  dataType: AttributeDataType;
  defaultValue?: any;
  enumeration?: string[];
  unit?: string;
  isVisibleAsSubtext?: boolean;
  isFilterable?: boolean;
  isEditable?: boolean;
  isRequired?: boolean;
}

export interface Schema {
  id: string;
  name: string;
  assetType: string;
  attributes: { [key: string]: SchemaAttribute };
  attributeGroups: SchemaAttributeGroup[];
  elements: any[];
  assetCount: number;
  elementCount: number;
  colorId: number;
  markerId: number;
  isMaterial: boolean;
  locationType: string;
  ciEnabled?: boolean;
  pciEnabled?: boolean;
  budget?: SchemaBudget;
}

export interface SchemasConnection {
  totalCount: number;
  schemas: Schema[];
}

export interface SchemasInput {
  name?: string;
  isMaterial?: 'true' | 'false' | 'true,false';
  rootSchemas?: boolean;
  parentSchemaId?: string;
  ids?: string[];
  isPublished?: 'true' | 'false' | 'true,false';
  assetCountBySchema?: boolean;
}

export interface SchemaTreeAttribute {
  id: string;
  name: string;
  dataType: AttributeDataType;
  defaultValue?: any;
  enumeration?: string[];
  unit?: string;
  isVisibleAsSubtext?: boolean;
  isFilterable?: boolean;
  isEditable?: boolean;
  isRequired?: boolean;
  // The below are used for temp attribute creation
  attributeGroupId?: string;
  isTempAttribute?: boolean;
}

export interface SchemaTreeAttributeGroup {
  id: string;
  name: string;
  attributes: SchemaTreeAttribute[];
}

export interface SchemaTreeElementGroup {
  id: string;
  name: string;
  elements: SchemaTree[];
}

export interface SchemaTree {
  id: string;
  name: string;
  isPublished: boolean;
  isMaterial: boolean;
  locationType?: LocationType;
  markerId: number;
  colorId: number;
  group?: string;
  assetType?: string;
  attributeGroups?: SchemaTreeAttributeGroup[];
  elements?: SchemaTree[];
  elementGroups?: SchemaTreeElementGroup[];
  budget?: SchemaBudget;
  createdBy?: string;
  createdDate?: number;
  updatedBy?: string;
  updatedDate?: number;
}

export interface SchemaCreateInput {
  name: string;
  assetType: string;
  locationType?: LocationType;
  colorId: number;
  markerId: number;
  isMaterial: boolean;
  isPublished: boolean;
}

export interface SchemaBudgetUpdateInput {
  isFixed?: boolean;
  isTimeBased?: boolean;
  stockBasedOption?: StockBasedOption;
  startEndReadingOption?: StartEndReadingOption;
  type?: SchemaBudgetType;
  rate?: number;
  unit?: string;
}

export interface SchemaUpdateInput {
  schemaId: string;
  name?: string;
  assetType?: string;
  locationType?: LocationType;
  colorId?: number;
  markerId?: number;
  isMaterial?: boolean;
  isPublished?: boolean;
  budget?: SchemaBudgetUpdateInput;
}

export interface SchemaSavePayload {
  name: string;
  colorId?: number;
  markerId?: number;
  locationType?: LocationType;
  isMaterial: boolean;
}

export interface ElementCreateInput {
  name: string;
  assetType: string;
  parentSchemaId: string;
  rootSchemaId: string;
  group?: string;
  groupId?: string;
}

export interface ReorderGroupInput {
  id: string;
  name: string;
  attributeGroups: string[];
}

export interface ElementUpdateInput {
  schemaId: string;
  name?: string;
  assetType?: string;
  attributeGroups?: ReorderGroupInput[];
}

export interface ElementGroupCreateInput {
  group: string;
  name: string;
  assetType: string;
  parentSchemaId: string;
  rootSchemaId: string;
}

export interface ElementGroupUpdateInput {
  groupId: string;
  rootSchemaId: string;
  name: string;
}

export interface ElementGroupDeleteInput {
  groupId: string;
  rootSchemaId: string;
}

export interface AttributeGroupCreateInput {
  schemaId: string;
  name: string;
}

export interface AttributeGroupUpdateInput {
  schemaId: string;
  attributeGroupId: string;
  name: string;
}

export interface AttributeGroupDeleteInput {
  schemaId: string;
  attributeGroupId: string;
}

export interface AttributeCreateInput {
  schemaId: string;
  attributeGroupId: string;
  name: string;
  dataType: AttributeDataType;
  unit?: string;
  enumeration?: string[];
  defaultValue?: any;
  isFilterable?: boolean;
  isVisibleAsSubtext: boolean;
  isEditable: boolean;
  isRequired: boolean;
}

export interface AttributeCreateBulkInputItem {
  attributeGroupId: string;
  name: string;
  dataType: AttributeDataType;
  unit?: string;
  enumeration?: string[];
  defaultValue?: any;
  isFilterable?: boolean;
  isVisibleAsSubtext: boolean;
  isEditable: boolean;
  isRequired: boolean;
}

export interface AttributeCreateBulkInput {
  schemaId: string;
  attributes: AttributeCreateBulkInputItem[];
}

export interface AttributeUpdateInput {
  schemaId: string;
  attributeGroupId: string;
  attributeId: string;
  name?: string;
  dataType?: AttributeDataType;
  unit?: string;
  enumeration?: string[];
  defaultValue?: any;
  isFilterable?: boolean;
  isVisibleAsSubtext?: boolean;
  isEditable?: boolean;
  isRequired?: boolean;
}

export interface AttributeUpdateBulkInputItem {
  id: string;
  enumeration?: string[];
  isFilterable?: boolean;
  isVisibleAsSubtext?: boolean;
  isEditable?: boolean;
}

export interface AttributeUpdateBulkInput {
  schemaId: string;
  attributes: AttributeUpdateBulkInputItem[];
}

export interface AttributeDeleteInput {
  schemaId: string;
  attributeGroupId: string;
  attributeId: string;
}
