import React, { useContext, useState } from 'react';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import TaskItemTile from '@atom/components/common/workOrderDetail/taskItemTile/TaskItemTile';
import WorkOrderContext from '@atom/components/workOrderDetail/WorkOrderContext';
import { useWorkValidations } from '@atom/hooks/useWorkValidations';
import { Icon, IconButton, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import api from '@atom/utilities/api';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { WORK_ORDERS_ENDPOINT } from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';

import './formSection.css';

interface Props {
  formInstanceId: string;
}

const styles = {
  icon: {
    height: '24px',
    width: '24px',
  },
};

const FormTile = ({ formInstanceId }: Props) => {
  const { workOrderDetail, task, refetch } = useContext(WorkOrderContext);

  const { workValidations } = useWorkValidations();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const removeForm = async () => {
    Snackbar.info({
      message: 'Deleting...',
    });

    const endpoint = `${WORK_ORDERS_ENDPOINT}/${workOrderDetail.id}/tasks/${task.id}/formInstances/${formInstanceId}`;

    await api.delete(endpoint);
    refetch();

    Snackbar.info({
      message: `Removed Form.`,
    });

    refetch();
  };

  const navigateToFormInstance = () => {
    return history.push(`/form-instance/${formInstanceId}`, {
      workOrderClosed: workOrderDetail.isClosed,
      workOrderId: workOrderDetail.id,
      taskId: task.id,
    });
  };

  const formInstance = R.pathOr(
    { name: '', isValid: true },
    ['formInstances', formInstanceId],
    workOrderDetail,
  );

  const iconColor = formInstance.isValid ? colors.brand.blue : colors.brand.red;
  const textColor = formInstance.isValid
    ? colors.neutral.dim
    : colors.brand.red;
  const isEditDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  const icon = (
    <Icon style={styles.icon} color={iconColor}>
      library_books
    </Icon>
  );
  const actionButton = isEditDisabled ? (
    <div />
  ) : (
    <IconButton
      onClick={() => setDeleteModalOpen(true)}
      tooltip="Remove"
      size="small"
    >
      <Icon>close</Icon>
    </IconButton>
  );

  const hasError = R.has(formInstanceId)(
    R.pathOr({}, ['taskValidations', task.id, 'invalidForms'], workValidations),
  );

  return (
    <>
      <TaskItemTile
        content={formInstance.name}
        contentColor={textColor}
        onTileClick={navigateToFormInstance}
        icon={icon}
        actionButton={actionButton}
        hasError={hasError}
      />
      <DeleteModal
        open={deleteModalOpen}
        onConfirm={removeForm}
        onCancel={() => setDeleteModalOpen(false)}
        title="Remove Form From Task"
        content="Are you sure you want to remove this form from this task? Removing this form will not cancel the inventory data changes that are already made within it."
        cancelText="Cancel"
        confirmText="Remove"
      />
    </>
  );
};

export default FormTile;
