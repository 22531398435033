import * as R from 'ramda';
import {
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
  throttle,
} from 'redux-saga/effects';

import actionTypes from '@atom/actions/actionTypes';
import * as workOrderActions from '@atom/actions/workOrderActions';
import { Snackbar } from '@atom/mui';
import accountUtilities from '@atom/utilities/accountUtilities';
import api from '@atom/utilities/api';
import {
  BULK_WORK_ORDERS_ENDPOINT,
  BULK_WORK_ORDERS_FROM_FILTER_ENDPOINT,
  FILES_ENDPOINT,
  WORK_ORDERS_ENDPOINT,
} from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';

export function* getWorkOrder(action) {
  try {
    const endpoint = `${WORK_ORDERS_ENDPOINT}/${action.data}`;

    const { data } = yield call(api.get, endpoint);

    yield put(workOrderActions.getWorkOrderSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      workOrderActions.getWorkOrderFailure,
    );
  }
}

export function* getWorkOrderStatus(action) {
  try {
    const endpoint = `${WORK_ORDERS_ENDPOINT}/${action.data.id}/status`;

    const { data } = yield call(api.get, endpoint);

    yield put(workOrderActions.workStatusSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      workOrderActions.workStatusFailure,
    );
  }
}

export function* patchWorkOrder(action) {
  try {
    const { id, body } = action.data;
    const endpoint = `${WORK_ORDERS_ENDPOINT}/${id}`;

    const { data } = yield call(api.patch, endpoint, body);

    yield put(workOrderActions.patchWorkOrderSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      workOrderActions.patchWorkOrderFailure,
    );

    if (error.response && error.response.status === 409) {
      Snackbar.error({
        message: 'This Work ID already exists',
      });

      yield put(workOrderActions.clearWorkOrderLoading());
    }
  }
}

export function* deleteWorkOrder(action) {
  try {
    const id = action.data;
    const endpoint = `${WORK_ORDERS_ENDPOINT}/${id}`;

    yield call(api.delete, endpoint);

    yield put(workOrderActions.deleteWorkOrderSuccess(id));

    Snackbar.info({
      message: 'Work deleted',
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      workOrderActions.deleteWorkOrderFailure,
    );
  }
}

export function* createWorkOrder(action) {
  try {
    const { data } = yield call(api.post, WORK_ORDERS_ENDPOINT, action.data);

    yield put(workOrderActions.createWorkOrderSuccess(data));

    Snackbar.info({
      message: `Created work ${data.name}.`,
      action: 'View',
      onActionClick: () => history.push(`/workOrders/${data.id}`),
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      workOrderActions.createWorkOrderFailure,
    );

    if (error.response && error.response.status === 409) {
      Snackbar.error({
        message: 'This Work ID already exists',
      });
    }
  }
}

export function* createBulkWorkOrders(action) {
  try {
    const { data } = yield call(
      api.post,
      BULK_WORK_ORDERS_ENDPOINT,
      action.data,
    );

    yield put(workOrderActions.bulkWorkCreationSuccess());

    if (data.totalAssets === data.totalWorkOrders) {
      Snackbar.info({
        message: `Created ${data.totalWorkOrders} work.`,
        action: 'View',
        onActionClick: () => history.push('/workOrders'),
      });
    } else {
      const duplicateCount = data.totalAssets - data.totalWorkOrders;

      Snackbar.warning({
        message: `Created ${data.totalWorkOrders} work. ${duplicateCount} work were not created due to duplicate name(s).`,
      });
    }
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      workOrderActions.bulkWorkCreationFailure,
    );

    if (error.response) {
      Snackbar.error({
        message: 'There was an error creating work.',
      });
    }
  }
}

export function* createBulkWorkOrdersFromFilter(action) {
  try {
    Snackbar.info({
      message: 'Creating work...',
    });

    const {
      workTemplateId,
      dueDate,
      schemaId,
      categoryIdsFilters,
      assetFilters,
      hasPendingChangesOnly,
    } = action.data;

    const body = { ...assetFilters };

    const params = {
      ...(dueDate ? { dueDate } : {}),
      workTemplateId,
      ...(!R.isNil(hasPendingChangesOnly) ? { hasPendingChangesOnly } : {}),
      ...(schemaId ? { schemaId } : {}),
      ...(categoryIdsFilters
        ? { categoryIds: categoryIdsFilters.join(',') }
        : {}),
    };

    const { data } = yield call(
      api.post,
      BULK_WORK_ORDERS_FROM_FILTER_ENDPOINT,
      body,
      params,
    );

    yield put(workOrderActions.bulkWorkCreationFromFilterSuccess());

    if (data.totalAssets === data.totalWorkOrders) {
      Snackbar.info({
        message: `Created ${data.totalWorkOrders} work.`,
        action: 'View',
        onActionClick: () => history.push('/workOrders'),
      });
    } else {
      const duplicateCount = data.totalAssets - data.totalWorkOrders;

      Snackbar.warning({
        message: `Created ${data.totalWorkOrders} work. ${duplicateCount} work were not created due to duplicate name(s).`,
      });
    }
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      workOrderActions.bulkWorkCreationFromFilterFailure,
    );

    if (error.response) {
      Snackbar.error({
        message: 'There was an error creating work.',
      });
    }
  }
}

export function* createWorkOrderReport(action) {
  try {
    Snackbar.info({
      message: 'Generating Work Report...',
    });

    const endpoint = `${WORK_ORDERS_ENDPOINT}/${action.data.id}/report`;
    const { data } = yield call(api.post, endpoint, action.data);

    Snackbar.info({
      message: 'Work Report generated',
      action: 'View',
      onActionClick: () => {
        // open report in new tab and focus that
        const tab = window.open(`${FILES_ENDPOINT}/${data.fileId}`, '_blank');
        return tab.focus();
      },
    });

    yield put(workOrderActions.workOrderReportCreationSuccess());
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      workOrderActions.workOrderReportCreationFailure,
    );
  }
}

export function* applyHistorySnapshot(action) {
  try {
    const {
      id,
      inventoryAssetName,
      inventoryAssetId,
      formInstanceIds,
    } = action.data;

    const endpoint = `${WORK_ORDERS_ENDPOINT}/${id}/apply`;
    const body = {
      ...(!R.isEmpty(formInstanceIds) ? { formInstanceIds } : {}),
      rootAssetId: inventoryAssetId,
    };

    yield call(api.post, endpoint, body);
    yield put(workOrderActions.workOrderHistorySnapshotSuccess());

    Snackbar.info({
      message: `Asset ${inventoryAssetName} updated.`,
      action: 'View',
      onActionClick: () => history.push(`/inventory/${inventoryAssetId}`),
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      workOrderActions.workOrderHistorySnapshotFailure,
    );

    Snackbar.error({
      message: 'Could not version asset',
    });
  }
}

/* WATCHERS */
export function* retrieveWorkOrder() {
  yield takeLatest(actionTypes.REQUEST_WORK_ORDER_DETAIL, getWorkOrder);
}

export function* retrieveWorkOrderStatus() {
  yield takeLatest(actionTypes.REQUEST_WORK_STATUS, getWorkOrderStatus);
}

export function* requestPatchWorkOrder() {
  yield takeEvery(actionTypes.REQUEST_PATCH_WORK_ORDER, patchWorkOrder);
}

export function* requestDeleteWorkOrder() {
  yield takeEvery(actionTypes.REQUEST_DELETE_WORK_ORDER, deleteWorkOrder);
}

export function* requestCreateWorkOrder() {
  yield takeEvery(actionTypes.REQUEST_CREATE_WORK_ORDER, createWorkOrder);
}

export function* requestBulkWorkOrderCreation() {
  yield takeEvery(actionTypes.REQUEST_BULK_WORK_CREATION, createBulkWorkOrders);
}

export function* requestBulkWorkOrderFromFilterCreation() {
  yield takeEvery(
    actionTypes.REQUEST_BULK_WORK_CREATION_FROM_FILTER,
    createBulkWorkOrdersFromFilter,
  );
}

export function* requestWorkOrderHistorySnapshotCreation() {
  yield takeEvery(
    actionTypes.REQUEST_WORK_ORDER_HISTORY_SNAPSHOT_CREATION,
    applyHistorySnapshot,
  );
}

export function* requestWorkOrderReportCreation() {
  yield throttle(
    2000,
    actionTypes.REQUEST_WORK_ORDER_REPORT_CREATION,
    createWorkOrderReport,
  );
}

export default [
  retrieveWorkOrder,
  retrieveWorkOrderStatus,
  requestPatchWorkOrder,
  requestDeleteWorkOrder,
  requestCreateWorkOrder,
  requestWorkOrderReportCreation,
  requestWorkOrderHistorySnapshotCreation,
  requestBulkWorkOrderCreation,
  requestBulkWorkOrderFromFilterCreation,
];
