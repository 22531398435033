import React, { useContext } from 'react';

import UserGroupIcon from '@atom/components/common/UserGroupIcon';
import AddTeamModalContext from '@atom/components/common/workOrderDetail/addTeamModal/AddTeamModalContext';
import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import { UserGroup } from '@atom/types/userGroups';

import './searchTab.css';

interface Props {
  userGroup: UserGroup;
}

const UserGroupRow = ({ userGroup }: Props) => {
  const {
    userGroupsOnTask,
    selectedUserGroups,
    removeUserGroupFromCart,
    addUserGroupToCart,
  } = useContext(AddTeamModalContext);

  const isOnTask = userGroupsOnTask.includes(userGroup.id);
  const isSelected = selectedUserGroups
    .map(selectedUserGroup => selectedUserGroup.id)
    .includes(userGroup.id);

  const getTitleStyle = () => {
    if (isSelected) {
      return 'title selected';
    }

    if (isOnTask) {
      return 'title disabled';
    }

    return 'title';
  };

  const onClick = () => {
    if (isSelected) {
      return removeUserGroupFromCart(userGroup.id);
    }

    if (isOnTask) {
      return () => {};
    }

    return addUserGroupToCart(userGroup);
  };

  return (
    <div styleName="group-row" onClick={onClick}>
      <div styleName="left-container">
        <UserGroupIcon colorId={userGroup.colorId} disabled={isOnTask} />
        <div styleName="name-container">
          <div styleName={getTitleStyle()}>{userGroup.name}</div>
        </div>
      </div>
      {isSelected && <Icon color={colors.brand.blue}>check</Icon>}
    </div>
  );
};

export default UserGroupRow;
