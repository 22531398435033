import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import uuid from 'uuid/v4';

import {
  Button,
  Collapse,
  Icon,
  IconButton,
  List,
  Modal,
  Progress,
  Snackbar,
  TextField,
} from '@atom/mui';
import colors from '@atom/styles/colors';
import { MediaFolderTree } from '@atom/types/media';
import api from '@atom/utilities/api';
import { MEDIA_ENDPOINT } from '@atom/utilities/endpoints';
import iconUtilities from '@atom/utilities/iconUtilities';

import './folders.css';

const { ListItemButton, ListItemText } = List;
export interface Props {
  mediaFolderTree: MediaFolderTree;
  type: 'media' | 'folder';
  ids: string[];
  loading: boolean;
  open?: boolean;
  closeModal?: () => void;
  disabled?: string[];
  onEdit: (id: string, path: any[], name: string) => void;
  onCreate: (id: string, path: any[], name: string) => void;
  onComplete?: (id: string, destId: string, destPath: any[]) => void;
}

const styles = {
  listItem: {
    padding: '16px 16px 16px 50px',
  },
  nestedList: {
    padding: 0,
  },
  folderIcon: {
    color: colors.neutral.gray,
    marginRight: '10px',
  },
  selected: {
    backgroundColor: colors.utility.highlight,
    borderLeft: `3px solid ${colors.brand.blue}`,
  },
  deselected: {
    borderLeft: '3px solid transparent',
  },
  rightIconButton: {
    padding: '0.25rem',
    width: '36px',
    height: '36px',
  },
  rightIcon: {
    color: colors.neutral.gray,
  },
  progress: {
    height: '100%',
  },
};

const MoveMediaFolderModal = ({
  type,
  ids,
  mediaFolderTree,
  open,
  closeModal,
  loading,
  onEdit,
  onCreate,
  disabled,
  onComplete,
}: Props) => {
  // @ts-ignore
  const [tree, setTree] = useState<MediaFolderTree>({});

  const [expanded, setExpanded] = useState<Set<string>>(new Set(['root']));
  // @ts-ignore
  const [selected, setSelected] = useState<MediaFolderTree>({});
  const [hovered, setHovered] = useState<string>('');

  // @ts-ignore
  const [editing, setEditing] = useState<MediaFolderTree>({});
  const [folderName, setFolderName] = useState<string>('');
  const [selectedPath, setSelectedPath] = useState<any[]>([]);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const [loadingMove, setLoadingMove] = useState<boolean>(false);

  const resetState = () => {
    // @ts-ignore
    setTree(mediaFolderTree);

    setExpanded(new Set(['root']));
    // @ts-ignore
    setSelected({});
    setHovered('');

    // @ts-ignore
    setEditing({});
    setFolderName('');
    setSelectedPath([]);
    setLoadingMove(false);
  };

  useEffect(() => {
    if (!open) {
      resetState();
    }
  }, [open]);

  useEffect(() => {
    setTree(mediaFolderTree);
    setFolderName('');
    setLoadingUpdate(false);
    // @ts-ignore
    setEditing({});
  }, [mediaFolderTree]);

  const handleExpand = (event, id: string) => {
    event.stopPropagation();

    if (expanded.has(id)) {
      expanded.delete(id);
      setExpanded(new Set([...expanded]));
    } else {
      expanded.add(id);
      setExpanded(new Set([...expanded]));
    }
  };

  const handleClick = (folder: MediaFolderTree) => {
    setSelected(folder);
  };

  const handleAdd = (parent: MediaFolderTree, path: any[]) => {
    const lens = R.lensPath(path);
    const id = uuid();
    const item = { id, name: 'Untitled', parentId: parent.id };

    if (!expanded.has(parent.id)) {
      expanded.add(parent.id);
      setExpanded(new Set([...expanded]));
    }

    setTree(
      R.set(
        lens,
        {
          ...parent,
          children: parent.children ? [item, ...parent.children] : [item],
        },
        tree,
      ),
    );

    setSelectedPath(path);
    setEditing(item);
    setFolderName('Untitled');
  };

  const handleEdit = (child: MediaFolderTree, path: any[]) => {
    setEditing(child);
    setSelectedPath(path);
    setFolderName(child.name);
  };

  const handleCancel = () => {
    // @ts-ignore
    setEditing({});
    setFolderName('');
    setTree(mediaFolderTree);
  };

  const handleSave = () => {
    setLoadingUpdate(true);

    if (editing.parentId) {
      onCreate(editing.parentId, selectedPath, folderName);
    } else {
      onEdit(editing.id, selectedPath, folderName);
    }
  };

  const handleComplete = () => {
    const id = R.pathOr('', [0], ids);

    setLoadingMove(false);
    closeModal();

    Snackbar.info({
      message: `Moved ${ids.length === 1 ? 'item' : 'items'} to ${
        selected.name
      }.`,
    });

    if (onComplete) {
      onComplete(id, selected.id, selectedPath);
    }
  };

  const moveMedia = async () => {
    setLoadingMove(true);
    const endpoint = `${MEDIA_ENDPOINT}/move`;
    await api.post(endpoint, {
      mediaIds: ids,
      destFolderId: selected.id,
    });
    handleComplete();
  };

  const moveFolder = async () => {
    const id = R.path([0], ids);
    setLoadingMove(true);
    const endpoint = `${MEDIA_ENDPOINT}/folders/${id}/move`;
    await api.post(endpoint, { destParentFolderId: selected.id });
    handleComplete();
  };

  const handleMove = () => {
    if (type === 'media') {
      moveMedia();
    } else {
      moveFolder();
    }
  };

  const renderListItems = (
    children: MediaFolderTree[],
    previousPath: any[],
    level: number = 0,
    isRoot?: boolean,
  ) => {
    return children.map((child, index) => {
      const isFolder = type === 'folder';
      const id = R.pathOr('', [0], ids);

      const isExpanded = expanded.has(child.id);
      const isSelected = selected.id === child.id;
      const isHovered = hovered === child.id;

      const isDisabled =
        (disabled && disabled.includes(child.id)) ||
        child.id === editing.id ||
        (isFolder && child.ancestors && child.ancestors.includes(id));

      const isEditing = editing.id === child.id;
      const path = isRoot ? [] : [...previousPath, 'children', index];

      const hasChildren =
        !R.isNil(child.children) && !R.isEmpty(child.children);

      const leftIcon = (
        <div
          style={
            hasChildren
              ? { display: 'inline-block' }
              : { visibility: 'hidden', display: 'inline-block' }
          }
          onClick={event => handleExpand(event, child.id)}
        >
          {isExpanded
            ? iconUtilities.leftIcon('down-arrow')
            : iconUtilities.leftIcon('right-arrow')}
        </div>
      );

      const rightIcons = R.isEmpty(editing) && isHovered && (
        <div styleName="media-tree-buttons-container">
          <IconButton
            style={styles.rightIconButton}
            iconStyle={styles.rightIcon}
            onClick={() => handleAdd(child, path)}
          >
            <Icon>create_new_folder</Icon>
          </IconButton>
          {!isRoot && (
            <IconButton
              style={styles.rightIconButton}
              iconStyle={styles.rightIcon}
              onClick={() => handleEdit(child, path)}
            >
              <Icon>edit</Icon>
            </IconButton>
          )}
        </div>
      );

      const primaryText = isEditing ? (
        <div styleName="media-tree-row edit">
          <TextField
            value={folderName}
            onChange={event => setFolderName(event.target.value)}
            name="name"
          />
          <Button
            onClick={handleCancel}
            style={{ marginLeft: '5px' }}
            disabled={loadingUpdate}
          >
            CANCEL
          </Button>
          <Button onClick={handleSave} disabled={loadingUpdate} color="primary">
            SAVE
          </Button>
        </div>
      ) : (
        <div styleName="media-tree-row">
          <div
            styleName={`media-tree-text-container ${
              isDisabled ? 'disabled' : ''
            }`}
          >
            <Icon
              style={{
                ...styles.folderIcon,
                opacity: isDisabled ? '50%' : '100%',
              }}
            >
              folder
            </Icon>
            {child.name}
          </div>
          {rightIcons}
        </div>
      );

      const rootStyle = isSelected ? styles.selected : styles.deselected;

      return (
        <>
          <ListItemButton
            key={child.id}
            style={{ ...rootStyle, paddingLeft: `${level * 1}rem` }}
            onClick={() => (isDisabled ? () => {} : handleClick(child))}
            onMouseEnter={() => setHovered(child.id)}
            onMouseLeave={() => setHovered('')}
          >
            {leftIcon}
            <ListItemText
              primaryTextStyle={{ fontWeight: 0 }}
              primary={primaryText}
            />
          </ListItemButton>
          {hasChildren && (
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <List disablePadding>
                {renderListItems(child.children, path, level + 1)}
              </List>
            </Collapse>
          )}
        </>
      );
    });
  };

  return (
    <Modal
      open={open}
      title="Move to Folder"
      confirmButtonText="Move"
      onConfirm={handleMove}
      onCancel={closeModal}
      disabled={
        R.isEmpty(selected) || !R.isEmpty(editing) || loading || loadingMove
      }
    >
      <div styleName="modal-tree-container">
        {loading || loadingMove ? (
          <Progress style={styles.progress} />
        ) : (
          <List disablePadding>{renderListItems([tree], [], 1, true)}</List>
        )}
      </div>
    </Modal>
  );
};

export default MoveMediaFolderModal;
