import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as R from 'ramda';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';

import './dropzone.css';

const styles = {
  selectText: {
    color: colors.brand.blue,
    cursor: 'pointer',
  },
};

interface Props {
  files: File[];
  /**
   * Callback called when files are drag and dropped or selected from device.
   */
  onFilesChange: (file: File[]) => void;
  /**
   * Callback called when files are to be removed. Currently, all files will
   * be removed on click.
   */
  onFilesRemove: () => void;
  /**
   * Multiple is an optional boolean prop that allows the drag and drop or
   * selection of multiple files. Default has been set to false, which only
   * allows the ability to save 1 file at a time.
   */
  multiple?: boolean;
  /**
   * The accept attribute defines which file types can be uploaded. This is a
   * comma separated list of unique file type specifiers as a string.
   * (e.g. .jpg, .png, .csv, .doc, audio/*, video/*, image/*)
   */
  accept?: string;
  /**
   * Indicates some operation such as file validation is loading
   * and spinner should be shown in place of file icon
   */
  loading?: boolean;
  /**
   * Optional text to show when loading prop is true.
   * Default to "Loading..."
   */
  loadingText?: string;
}

function Dropzone(props: Props) {
  const {
    files,
    onFilesChange,
    onFilesRemove,
    accept,
    loading,
    loadingText = 'Loading...',
    multiple = false,
  } = props;

  const [isDraggedOver, setIsDraggedOver] = useState<boolean>(false);

  const onDrop = useCallback(acceptedFiles => {
    onFilesChange(acceptedFiles);
    setIsDraggedOver(false);
  }, []);

  const onDragEnter = () => {
    setIsDraggedOver(true);
  };

  const onDragLeave = () => {
    setIsDraggedOver(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave,
    multiple,
    accept,
  });

  return (
    <section>
      <div
        styleName={
          isDraggedOver
            ? 'dropzone drag'
            : R.isEmpty(files)
            ? 'dropzone dashed'
            : 'dropzone solid'
        }
        {...getRootProps({ className: 'dropzone' })}
      >
        {R.isEmpty(files) ? (
          <>
            <input {...getInputProps()} />
            <div>
              <span>Drag CSV file here, or </span>
              <span style={styles.selectText}>Select from your device</span>
            </div>
          </>
        ) : (
          <div styleName="tile-container">
            <div styleName="upload-name-container">
              <div styleName="icon">
                {loading ? (
                  <div styleName="loading" />
                ) : (
                  <Icon color={colors.brand.blue}>insert_drive_file</Icon>
                )}
              </div>
              {loading
                ? loadingText
                : files.map((file, index) => (
                    <div styleName="file-name" key={index}>
                      {/* @ts-ignore */}
                      {file.path}
                    </div>
                  ))}
              <IconButton size="small" onClick={onFilesRemove}>
                <Icon>close</Icon>
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Dropzone;
