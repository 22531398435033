import React, { useContext, useState } from 'react';

import WorkOrderAssetViewContext from '@atom/components/common/workOrderDetail/workOrderAssetView/WorkOrderAssetViewContext';
import { Icon, IconButton, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import { ChangeType } from '@atom/types/inventory';
import { WorkOrderAssetTreeElement } from '@atom/types/work';
import api from '@atom/utilities/api';
import { getWorkOrderAssetsEndpoint } from '@atom/utilities/endpoints';

import './workOrderAssetTree.css';

const EDITABLE_ERROR_CODE = 422;

interface Props {
  element: WorkOrderAssetTreeElement;
}

const ElementControls = ({ element }: Props) => {
  const {
    workOrderDetail,
    refetchWorkOrderAssetTree,
    workOrderAsset,
    activeElementId,
    setActiveElementId,
  } = useContext(WorkOrderAssetViewContext);

  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [openRejectModal, setOpenRejectModal] = useState<boolean>(false);

  const handleApprove = async () => {
    try {
      await api.post(
        `${getWorkOrderAssetsEndpoint(workOrderDetail.id)}/${
          element.id
        }/approve`,
        { includeChangesBefore: element.includeChangesBefore },
      );

      if (
        element.changeType === ChangeType.DELETED &&
        element.id === activeElementId
      ) {
        setActiveElementId(workOrderAsset.id);
      }

      refetchWorkOrderAssetTree();
    } catch (error) {
      if (error?.response?.status === EDITABLE_ERROR_CODE) {
        setOpenErrorModal(true);
      }
    }
  };

  const handleReject = async () => {
    try {
      await api.post(
        `${getWorkOrderAssetsEndpoint(workOrderDetail.id)}/${
          element.id
        }/reject`,
        { includeChangesBefore: element.includeChangesBefore },
      );

      if (
        element.changeType === ChangeType.ADDED &&
        element.id === activeElementId
      ) {
        setActiveElementId(workOrderAsset.id);
      }

      await refetchWorkOrderAssetTree();
    } catch (error) {
      if (error?.response?.status === EDITABLE_ERROR_CODE) {
        setOpenErrorModal(true);
      }
    } finally {
      setOpenRejectModal(false);
    }
  };

  return (
    <>
      <div styleName="pending-approval-container">
        <div styleName="pending-element-label">{element.changeType}</div>
        <div styleName="pending-controls-container">
          <div styleName="pending-element-control">
            <IconButton
              tooltip="Reject"
              onClick={() => setOpenRejectModal(true)}
            >
              <Icon color={colors.brand.blue}>clear</Icon>
            </IconButton>
          </div>
          <div styleName="pending-element-control">
            <IconButton tooltip="Approve" onClick={handleApprove}>
              <Icon color={colors.brand.blue}>check</Icon>
            </IconButton>
          </div>
        </div>
      </div>
      <Modal
        open={openRejectModal}
        cancelButtonText="Cancel"
        confirmButtonText="Reject"
        onCancel={() => setOpenRejectModal(false)}
        onConfirm={handleReject}
        title="Reject Changes"
      >
        Are you sure you want to reject the changes on this attribute?
      </Modal>
      <Modal
        open={openErrorModal}
        confirmButtonText="OK"
        onConfirm={() => setOpenErrorModal(false)}
        title="Cannot Approve All Changes"
      >
        Some pending changes cannot be approved because the attribute is not
        editable. Please set the attribute to editable in the asset schema to
        approve or reject changes.
      </Modal>
    </>
  );
};

export default ElementControls;
