import React, { useContext } from 'react';

import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { Icon } from '@atom/mui';
import { WorkOrderType } from '@atom/types/work';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';

import FormSection from './formSection/FormSection';
import LocationSection from './locationSection/LocationSection';
import MaterialSection from './materialSection/MaterialSection';
import MultiSchemaSection from './multiSchemaSection/MultiSchemaSection';
import SchemaSection from './schemaSection/SchemaSection';
import TaskFields from './taskFields/TaskFields';
import TeamSection from './teamSection/TeamSection';
import WorkTemplateTaskHeader from './workTemplateTaskHeader/WorkTemplateTaskHeader';
import TaskActualCost from './TaskActualCost';
import TaskCompletedDate from './TaskCompletedDate';
import TaskDescription from './TaskDescription';
import TaskDueDate from './TaskDueDate';
import TaskDuration from './TaskDuration';
import TaskEstimatedCost from './TaskEstimatedCost';
import TaskStartDate from './TaskStartDate';
import TaskWorkTime from './TaskWorkTime';

import './workTemplateTaskDetail.css';

const styles = {
  icon: {
    paddingTop: '8px',
  },
};

const WorkTemplateTaskDetail = () => {
  const { workTemplate } = useContext(WorkTemplateContext);

  const showSchemaSection =
    workTemplate.schemaId && workTemplate?.type === WorkOrderType.DEFAULT;
  const showLocationSection =
    workTemplate?.type === WorkOrderType.TASK_ASSETS_AND_LOCATIONS;
  const showMultiSchemaSection =
    workTemplate?.type === WorkOrderType.TASK_ASSETS_AND_LOCATIONS;

  const showDescription = !isCurrentClient([Client.ALDOT]);

  return (
    <div styleName="task-container">
      <WorkTemplateTaskHeader />
      <div styleName="content-container">
        <div styleName="left-column">
          <div styleName="time-detail-row">
            <Icon style={styles.icon}>today</Icon>
            <div styleName="time-row-container">
              <TaskDueDate />
              <TaskStartDate />
              <TaskCompletedDate />
              <TaskWorkTime />
              <TaskDuration />
            </div>
          </div>
          {showDescription && (
            <div styleName="task-detail-row">
              <TaskDescription />
            </div>
          )}
          <TaskFields />
          {showSchemaSection && <SchemaSection />}
          {showLocationSection && <LocationSection />}
          {showMultiSchemaSection && <MultiSchemaSection />}
          <FormSection />
        </div>
        <div styleName="right-column">
          <div styleName="cost-container">
            <TaskEstimatedCost />
            <TaskActualCost />
          </div>
          <TeamSection />
          <MaterialSection />
        </div>
      </div>
    </div>
  );
};

export default WorkTemplateTaskDetail;
