import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { getYear } from 'date-fns';
import * as R from 'ramda';

import { Snackbar } from '@atom/components/common/mui/snackbar/Snackbar';
import { GET_PAY_PERIODS } from '@atom/graph/payPeriod';
import { TASK_USERS_STATUS_UPDATE } from '@atom/graph/task';
import {
  GET_TIME_ENTRIES_STATUS,
  TIME_ENTRY_CREATE,
} from '@atom/graph/timeEntry';
import { GET_USER } from '@atom/graph/user';
import { WORK_ORDER_UPDATE } from '@atom/graph/work';
import { usePreferences } from '@atom/hooks/usePreferences';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import {
  DatePicker,
  Modal,
  Progress,
  Select,
  Switch,
  TextField,
} from '@atom/mui';
import { userBudgetsSelector } from '@atom/selectors/userSelectors';
import { PayPeriods, PayPeriodsInput } from '@atom/types/payPeriod';
import { TimeEntryType } from '@atom/types/preferences';
import { TaskUsersStatusUpdateInput, TaskUserStatus } from '@atom/types/task';
import {
  TimeEntriesConnectionInput,
  TimeEntriesStatusConnection,
  TimeEntryCreateInput,
  TimeEntryStatus,
  WorkOrderTimeEntry,
} from '@atom/types/timeEntry';
import { UserDetail } from '@atom/types/user';
import { WorkOrderUpdate, WorkOrderUpdateInput } from '@atom/types/work';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import {
  convertDateToMillisGMTMidday,
  convertHoursToMillis,
  getCurrentPayPeriod,
  roundTimeEntryDuration,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import ModalTitle from './ModalTitle';

import './userStatusControls.css';

const { MenuItem } = Select;

const styles = {
  typeOption: {
    textTransform: 'capitalize',
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
  taskUserStatus: TaskUserStatus;
  workOrderId: string;
  taskId: string;
  refetch: () => Promise<any>;
}

const CheckInModal = ({
  open,
  onClose,
  taskUserStatus,
  workOrderId,
  taskId,
  refetch,
}: Props) => {
  const userProfile = useUserProfile();
  const preferences = usePreferences();

  const [date, setDate] = useState<Date>(new Date());
  const [duration, setDuration] = useState<number>(0);
  const [selectedBudgetId, setSelectedBudgetId] = useState<string>('');
  const [selectedUserGroupId, setSelectedUserGroupId] = useState<string>('');
  const [isLeadAssignee, setIsLeadAssignee] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<TimeEntryType>();

  const [showTimeEntry, setShowTimeEntry] = useState<boolean>(false);
  const [payPeriods, setPayPeriods] = useState<PayPeriods>();

  const [getUser, { data, loading }] = useLazyQuery<{
    user: UserDetail;
  }>(GET_USER, {
    fetchPolicy: 'network-only',
    variables: {
      id: userProfile.userId,
    },
  });

  const [
    getTimeEntriesStatus,
    { loading: timeEntriesStatusLoading, data: timeEntriesStatusData },
  ] = useLazyQuery<
    { timeEntries: TimeEntriesStatusConnection },
    { input: TimeEntriesConnectionInput }
  >(GET_TIME_ENTRIES_STATUS, {
    fetchPolicy: 'network-only',
  });

  const [getPayPeriods, { loading: payPeriodsLoading }] = useLazyQuery<
    { payPeriods: PayPeriods },
    { input: PayPeriodsInput }
  >(GET_PAY_PERIODS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: payPeriodsData => {
      setPayPeriods(payPeriodsData?.payPeriods);
    },
  });

  const [updateAssignee, { loading: updateAssigneeLoading }] = useMutation<
    { workOrderUpdate: WorkOrderUpdate },
    { workOrder: WorkOrderUpdateInput }
  >(WORK_ORDER_UPDATE);

  const [timeEntryCreate, { loading: timeEntryCreateLoading }] = useMutation<
    { timeEntryCreate: WorkOrderTimeEntry },
    { input: TimeEntryCreateInput }
  >(TIME_ENTRY_CREATE);

  const [
    updateUsersTaskStatus,
    { loading: updateUsersTaskStatusLoading },
  ] = useMutation<
    { taskUsersStatusUpdate: boolean },
    { input: TaskUsersStatusUpdateInput }
  >(TASK_USERS_STATUS_UPDATE);

  const user = data?.user;

  const setInitialUserGroup = () => {
    const initialUserGroup =
      R.pathOr([], ['userGroups'], user).length === 1
        ? user.userGroups[0].id
        : '';

    setSelectedUserGroupId(initialUserGroup);
  };

  const currentPayPeriod = useMemo(() => {
    return getCurrentPayPeriod(payPeriods, date);
  }, [date, payPeriods]);

  useEffect(() => {
    if (!isNilOrEmpty(date) && open && preferences?.timeTracking?.timesheet) {
      getPayPeriods({
        variables: {
          input: {
            year: getYear(date),
            currentDate: convertDateToMillisGMTMidday(new Date()),
          },
        },
      });
    }
  }, [open, date, preferences]);

  useEffect(() => {
    if (
      !isNilOrEmpty(user) &&
      !isNilOrEmpty(selectedUserGroupId) &&
      !isNilOrEmpty(currentPayPeriod)
    ) {
      getTimeEntriesStatus({
        variables: {
          input: {
            userId: user.id,
            userGroupId: selectedUserGroupId,
            dateStart: currentPayPeriod.startDate,
            dateEnd: currentPayPeriod.endDate,
          },
        },
      });
    }
  }, [selectedUserGroupId, date]);

  useEffect(() => {
    if (isNilOrEmpty(date)) {
      setInitialUserGroup();
    }
  }, [date]);

  useEffect(() => {
    setInitialUserGroup();
  }, [user]);

  useEffect(() => {
    if (open) {
      getUser();
    } else {
      setDate(new Date());
      setDuration(0);
      setSelectedBudgetId('');
      setSelectedUserGroupId('');
      setShowTimeEntry(false);
      setSelectedType(null);
      setPayPeriods(null);
    }
  }, [open]);

  const setLeadAssignee = () => {
    updateAssignee({
      variables: {
        workOrder: {
          id: workOrderId,
          leadAssigneeId: userProfile.userId,
        },
      },
    });
  };

  const createTimeEntry = () => {
    timeEntryCreate({
      variables: {
        input: {
          userId: userProfile.userId,
          workOrderId,
          taskId,
          date: convertDateToMillisGMTMidday(date),
          duration: convertHoursToMillis(duration),
          ...(preferences?.timeTracking?.timesheet && {
            userGroupId: selectedUserGroupId,
          }),
          ...(!preferences?.timeTracking?.computedBudgets &&
            !R.isEmpty(selectedBudgetId) && {
              budgetId: selectedBudgetId,
            }),
          ...(!isNilOrEmpty(preferences?.timeTracking?.typeEnumeration) && {
            type: selectedType,
          }),
        },
      },
    });
  };

  const checkIn = async () => {
    await updateUsersTaskStatus({
      variables: {
        input: {
          workOrderId,
          taskId,
          users: [
            {
              userId: userProfile.userId,
              status: TaskUserStatus.IN_PROGRESS,
            },
          ],
        },
      },
    });

    Snackbar.info({
      message: 'Checked In',
    });
  };

  const isLoading =
    loading ||
    timeEntryCreateLoading ||
    updateAssigneeLoading ||
    updateUsersTaskStatusLoading ||
    R.isNil(user);

  const handleCheckIn = async () => {
    if (showTimeEntry) {
      await createTimeEntry();
    }

    await checkIn();

    if (isLeadAssignee) {
      await setLeadAssignee();
    }

    refetch();
    return onClose();
  };

  const toggleLabel = (
    <div styleName="toggle-label">
      {`Work Time${showTimeEntry ? ': ' : ''}`}
      {showTimeEntry && (
        <strong>{`${duration} ${duration === 1 ? 'Hour' : 'Hours'}`}</strong>
      )}
    </div>
  );

  const handleDuration = (newDuration: number) => {
    if (newDuration >= 0) {
      setDuration(newDuration);
    }
  };

  const onDurationBlur = () => {
    const roundedValue = roundTimeEntryDuration(duration);
    setDuration(roundedValue);
  };

  const budgets = userBudgetsSelector(user);
  const userGroups = user?.userGroups;
  const typeOptions = preferences?.timeTracking?.typeEnumeration;
  const hasTypeOptions = !isNilOrEmpty(typeOptions);

  const budgetOptions = useMemo(() => {
    const options = budgets.filter(
      budget =>
        !budget.restored ||
        (budget.restored && budget.reopenedWorkOrderIds.includes(workOrderId)),
    );

    return [{ id: '', name: '', rate: 0 }, ...options];
  }, [budgets, workOrderId]);

  const validationLoading = payPeriodsLoading || timeEntriesStatusLoading;
  const userGroupIsApproved =
    timeEntriesStatusData?.timeEntries?.status === TimeEntryStatus.approved;

  const isCheckInDisabled =
    showTimeEntry &&
    (R.isNil(date) ||
      R.isNil(duration) ||
      validationLoading ||
      userGroupIsApproved ||
      (preferences?.timeTracking?.timesheet &&
        isNilOrEmpty(selectedUserGroupId)) ||
      (hasTypeOptions && R.isNil(selectedType)));

  const hoursLabel = duration === 1 ? 'Hour' : 'Hours';

  return (
    <Modal
      title={
        <ModalTitle
          user={userProfile}
          isLeadAssignee={isLeadAssignee}
          setIsLeadAssignee={setIsLeadAssignee}
          taskUserStatus={taskUserStatus}
        />
      }
      onConfirm={handleCheckIn}
      onCancel={onClose}
      confirmButtonText="Check In"
      open={open}
      loading={isLoading}
      width="lg"
      disabled={isCheckInDisabled}
    >
      <div>
        <Switch
          checked={showTimeEntry}
          onChange={() => setShowTimeEntry(!showTimeEntry)}
          name="showTimeEntry"
          label={toggleLabel}
          disabled={isLoading}
        />
        {showTimeEntry && (
          <div styleName="time-entry-row">
            <div styleName="time-entry-data-section">
              <div styleName="input-container">
                <DatePicker
                  label="Start Date"
                  value={date}
                  onChange={setDate}
                  format="MM/dd/yyyy"
                />
              </div>
              {preferences?.timeTracking?.timesheet && (
                <div styleName="input-container">
                  <Select
                    fullWidth
                    label="Group"
                    value={selectedUserGroupId || ''}
                    onChange={event =>
                      setSelectedUserGroupId(event.target.value)
                    }
                    disabled={isNilOrEmpty(date)}
                    error={userGroupIsApproved}
                    helperText={
                      userGroupIsApproved &&
                      'Cannot add time to an approved timesheet.'
                    }
                  >
                    {userGroups.map(userGroup => {
                      const primaryText = userGroup.groupPath.join(' / ');

                      return (
                        <MenuItem key={userGroup.id} value={userGroup.id}>
                          {primaryText}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              )}
              <div styleName="input-container work-time">
                <TextField
                  type="number"
                  value={duration}
                  label="Work Time"
                  onChange={event => handleDuration(event.target.value)}
                  onBlur={onDurationBlur}
                />
                <div styleName="hours-suffix">{hoursLabel}</div>
              </div>
              {preferences?.timeTracking?.computedBudgets ? (
                hasTypeOptions && (
                  <div styleName="input-container">
                    <Select
                      fullWidth
                      label="Wage Type"
                      value={selectedType || ''}
                      onChange={event => setSelectedType(event.target.value)}
                    >
                      {typeOptions.map(type => {
                        const primaryText = type.name;

                        return (
                          <MenuItem
                            key={type.value}
                            value={type.value}
                            style={styles.typeOption}
                          >
                            {primaryText}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                )
              ) : (
                <div styleName="input-container">
                  <Select
                    fullWidth
                    label="Budget"
                    value={selectedBudgetId || ''}
                    onChange={event => setSelectedBudgetId(event.target.value)}
                  >
                    {budgetOptions.map(option => {
                      const primaryText = option.id ? (
                        <>
                          <span>
                            {`${option.name}: ${numberToLocaleString(
                              option.rate,
                            )}/Hours `}
                          </span>
                          {option.restored && (
                            <span styleName="snapshot-suffix">(snapshot)</span>
                          )}
                        </>
                      ) : (
                        'No Budget'
                      );

                      return (
                        <MenuItem key={option.id} value={option.id}>
                          {primaryText}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              )}
            </div>
            {validationLoading && <Progress size={20} />}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CheckInModal;
