import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import * as R from 'ramda';

import TeamContext, {
  baseUserGroup,
} from '@atom/components/teamPortal/TeamContext';
import CreateEditUserGroupModal from '@atom/components/teamPortal/teamControls/CreateEditUserGroupModal';
import DeleteUserGroupModal from '@atom/components/teamPortal/teamControls/DeleteUserGroupModal';
import TeamCreateControls from '@atom/components/teamPortal/teamControls/TeamCreateControls';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import './userGroupsTable.css';

const UserGroupControls = () => {
  const { activeGroup } = useContext(TeamContext);

  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

  const isRoot = activeGroup.id === baseUserGroup.id;
  const isLeaf = R.isEmpty(activeGroup.userGroups);

  return (
    <>
      <div styleName="group-controls-container">
        {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
          <TeamCreateControls userGroup={activeGroup} />
        )}
        {!isRoot && hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
          <IconButton onClick={() => setEditOpen(true)}>
            <Icon color={colors.neutral.gray}>edit</Icon>
          </IconButton>
        )}
        {!isRoot && (
          <Link to={`/team/group/${activeGroup.id}`}>
            <IconButton>
              <Icon color={colors.neutral.gray}>settings</Icon>
            </IconButton>
          </Link>
        )}
        {isLeaf && !isRoot && hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
          <IconButton onClick={() => setDeleteOpen(true)}>
            <Icon color={colors.neutral.gray}>delete</Icon>
          </IconButton>
        )}
      </div>
      <CreateEditUserGroupModal
        open={editOpen}
        onClose={() => setEditOpen(false)}
        groupId={activeGroup.id}
        groupColorId={activeGroup.colorId}
        groupName={activeGroup.name}
        isEdit
      />
      <DeleteUserGroupModal
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        userGroup={activeGroup}
      />
    </>
  );
};

export default UserGroupControls;
