import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useLoadScript } from '@react-google-maps/api';
import * as R from 'ramda';

import baseReducer from '@atom/components/mapPortal/hooks/reducer';
import { MapShape, MapShapeType, MapStyle } from '@atom/types/map';
import {
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_LIBRARIES,
} from '@atom/utilities/mapUtilities';

type State = {
  isLoaded: boolean;
  mapStyle: MapStyle;
  mapTypeId: string;
  traffic: boolean;
  poi: boolean;
  drawingMode: MapShapeType;
  shape: MapShape;
  grab: boolean;
};

const initialState: State = {
  isLoaded: false,
  traffic: false,
  poi: false,
  mapStyle: 'default',
  mapTypeId: 'roadmap',
  drawingMode: null,
  shape: null,
  grab: false,
};

const localState: State = JSON.parse(localStorage.getItem('gmapState'));

const reducer = baseReducer(localState || initialState);

const ValueContext = createContext(localState || initialState);
const UpdaterContext = createContext(reducer);

const ValueProvider = ValueContext.Provider;
const UpdaterProvider = UpdaterContext.Provider;

export const useGoogleMapsStateValue = () => useContext(ValueContext);
export const useGoogleMapsStateUpdater = () => useContext(UpdaterContext);

export function withGoogleMapsState<T>(WrappedComponent: any): React.FC<T> {
  const GoogleMapsState = props => {
    // @ts-ignore
    const [googleMapsState, setGoogleMapsState] = useReducer(
      // @ts-ignore
      reducer,
      localStorage.getItem('gmapState')
        ? JSON.parse(localStorage.getItem('gmapState'))
        : initialState,
    );

    const { isLoaded } = useLoadScript({
      googleMapsApiKey: GOOGLE_MAPS_API_KEY,
      // @ts-ignore
      libraries: GOOGLE_MAPS_LIBRARIES,
    });

    useMemo(() => {
      // @ts-ignore
      setGoogleMapsState({
        type: 'SET',
        payload: {
          key: 'isLoaded',
          data: isLoaded,
        },
      });
    }, [isLoaded]);

    useEffect(() => {
      localStorage.setItem(
        'gmapState',
        JSON.stringify(R.omit(['drawingMode', 'shape'], googleMapsState)),
      );
    }, [googleMapsState]);

    return (
      <ValueProvider value={googleMapsState}>
        <UpdaterProvider value={setGoogleMapsState}>
          <WrappedComponent {...props} />
        </UpdaterProvider>
      </ValueProvider>
    );
  };

  GoogleMapsState.getInitialProps = ctx => {
    if (WrappedComponent.getInitialProps) {
      WrappedComponent.getInitialProps(ctx);
    }
  };

  return GoogleMapsState;
}
