import * as React from 'react';
import { Control, Fieldset } from 'react-redux-form';
import * as R from 'ramda';

import TextField from '@atom/components/common/TextField';

import { filterStyle, textStyle } from './attributeStyle';

import './attributeControls.css';

interface Props {
  name: string;
  title: string;
  unit?: string;
}

interface State {
  from: string;
  to: string;
}

class CurrencyAttribute extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      from: R.pathOr('', ['value', 'from'], props),
      to: R.pathOr('', ['value', 'to'], props),
    };
  }

  onChange = (event, property) => {
    // @ts-ignore
    this.setState({ [property]: event.target.value });
  };

  render() {
    const { name, title } = this.props;
    const { from, to } = this.state;
    return (
      <Fieldset key={name} model={`.${name}`}>
        <div styleName="range-container">
          <div styleName="title">{title}:</div>
          <div styleName="half">
            <span styleName="units-text">$</span>
            <div>
              <Control
                type="number"
                step="any"
                model=".from"
                component={TextField}
                value={from}
                onChange={event => this.onChange(event, 'from')}
                controlProps={{
                  floatingLabelText: 'From',
                  floatingLabelFixed: true,
                  fullWidth: true,
                  textareaStyle: textStyle.textarea,
                  style: textStyle.inputText,
                  underlineStyle: filterStyle.underlineStyle,
                  floatingLabelStyle: filterStyle.floatingLabelStyle,
                }}
              />
            </div>
          </div>
          <div styleName="half">
            <span styleName="units-text">$</span>
            <div>
              <Control
                type="number"
                step="any"
                model=".to"
                component={TextField}
                value={to}
                onChange={event => this.onChange(event, 'to')}
                controlProps={{
                  floatingLabelText: 'To',
                  floatingLabelFixed: true,
                  fullWidth: true,
                  textareaStyle: textStyle.textarea,
                  style: textStyle.inputText,
                  underlineStyle: filterStyle.underlineStyle,
                  floatingLabelStyle: filterStyle.floatingLabelStyle,
                }}
              />
            </div>
          </div>
        </div>
      </Fieldset>
    );
  }
}

export default CurrencyAttribute;
