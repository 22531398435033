import React from 'react';
import { format } from 'date-fns';

import { Task } from '@atom/types/task';

import TaskCompletionIcon from './TaskCompletionIcon';

import './workSummaryTray.css';

interface Props {
  task: Task;
}

const TaskRow = ({ task }: Props) => {
  const dueDateText = task?.dueDate ? format(task?.dueDate, 'MM/dd/yyyy') : '-';

  return (
    <div styleName="task-row-container">
      <TaskCompletionIcon isCompleted={task?.isCompleted} />
      <div styleName="task-row-content">
        <div styleName="task-row-description">{task?.name}</div>
        <div styleName="task-row-details-container">
          <div styleName="task-detail-content">
            {`Due Date: ${dueDateText}`}
          </div>
          <div styleName="task-detail-content">Team: {task?.users?.length}</div>
        </div>
      </div>
    </div>
  );
};

export default TaskRow;
