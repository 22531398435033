import * as React from 'react';
import BaseTextField from 'material-ui/TextField';

import colors from '@atom/styles/colors';

const formControlUnderlineFocus = {
  color: colors.brand.blue,
};

const formControlUnderline = {
  borderColor: colors.neutral.silver,
};

interface Props {
  autoFocus?: boolean;
  children?: any;
  className?: string;
  defaultValue?: any;
  disabled?: boolean;
  errorStyle?: object;
  errorText?: any;
  floatingLabelFixed?: boolean;
  floatingLabelFocusStyle?: object;
  floatingLabelShrinkStyle?: object;
  floatingLabelStyle?: object;
  floatingLabelText?: any;
  fullWidth?: boolean;
  hintStyle?: object;
  hintText?: any;
  id?: string;
  inputStyle?: object;
  multiLine?: boolean;
  name?: string;
  onBlur?: any;
  onChange?: any;
  onKeyDown?: any;
  rows?: number;
  rowsMax?: number;
  style?: object;
  textareaStyle?: object;
  type?: string;
  underlineDisabledStyle?: object;
  underlineFocusStyle?: object;
  underlineShow?: boolean;
  underlineStyle?: object;
  value?: any;
  step?: any;
  min?: any;
  maxLength?: any;
  placeholder?: string;
}

class TextField extends React.Component<Props> {
  state = {
    underlineFocusStyle: formControlUnderlineFocus,
    underlineStyle: formControlUnderline,
    autoComplete: 'off',
  };

  render() {
    const props = { ...this.state, ...this.props };
    return <BaseTextField {...props} />;
  }
}

export default TextField;
