import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function inventorySchemaDetailReducer(
  state = initialState.inventorySchemaDetail,
  action,
) {
  switch (action.type) {
    case actionTypes.GET_SCHEMA_DETAIL_SUCCESS:
    case actionTypes.CREATE_SCHEMA_SUCCESS:
    case actionTypes.UPDATE_SCHEMA_SUCCESS:
    case actionTypes.UPDATE_GROUP_SUCCESS:
    case actionTypes.DELETE_GROUP_SUCCESS:
    case actionTypes.CREATE_ATTRIBUTE_GROUP_SUCCESS:
    case actionTypes.UPDATE_ATTRIBUTE_GROUP_SUCCESS:
    case actionTypes.DELETE_ATTRIBUTE_GROUP_SUCCESS:
    case actionTypes.CREATE_ATTRIBUTE_SUCCESS:
    case actionTypes.UPDATE_ATTRIBUTE_SUCCESS:
    case actionTypes.DELETE_ATTRIBUTE_SUCCESS:
    case actionTypes.SCHEMA_ATTRIBUTE_UPDATE_SUCCESS: {
      return action.data;
    }
    case actionTypes.DELETE_SCHEMA_SUCCESS: {
      return { ...state, ...action.data.schema };
    }
    default: {
      return state;
    }
  }
}
