import React, { useContext, useState } from 'react';
import * as R from 'ramda';

import AddWorkAssetsModal from '@atom/components/common/workOrderDetail/addWorkAssetsModal/AddWorkAssetsModal';
import WorkOrderContext from '@atom/components/workOrderDetail/WorkOrderContext';
import { Icon, IconButton } from '@atom/mui';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';

import AssetTile from './AssetTile';

import './assetSection.css';

const AssetSection = () => {
  const { workOrderDetail, task, refetch } = useContext(WorkOrderContext);

  const [open, setOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(true);

  const isSectionEmpty = R.isEmpty(task.assetIds);
  const totalCount = R.length(task.assetIds);
  const icon = expanded ? 'expand_less' : 'expand_more';
  const isEditDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  return (
    <>
      <div styleName="section-container">
        <Icon>category</Icon>
        <div styleName="section-title">Assets</div>
        <div styleName="total-count">{totalCount}</div>
        <div styleName="buttons-container">
          {expanded && (
            <IconButton
              onClick={() => setOpen(!isEditDisabled)}
              edge="end"
              tooltip="Add Assets"
              disabled={isEditDisabled}
            >
              <Icon>add</Icon>
            </IconButton>
          )}
          <IconButton onClick={() => setExpanded(!expanded)} edge="end">
            <Icon>{icon}</Icon>
          </IconButton>
        </div>
      </div>
      {expanded && (
        <div styleName="tile-list">
          {isSectionEmpty ? (
            <div styleName="empty-section-text">Add Assets</div>
          ) : (
            <>
              {task.assetIds.map((assetId, index) => (
                <AssetTile key={`${assetId}${index}`} assetId={assetId} />
              ))}
            </>
          )}
        </div>
      )}
      <AddWorkAssetsModal
        open={open}
        onClose={() => setOpen(false)}
        workOrderDetail={workOrderDetail}
        task={task}
        refetch={refetch}
      />
    </>
  );
};

export default AssetSection;
