import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';

import { Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';

import './tooltip.css';

interface Props {
  text: string;
  tooltipText?: React.ReactNode;
  width?: string;
  paddingBottom?: string;
  placementBottom?: boolean;
  bold?: boolean;
  color?: string;
  fontSize?: string;
  lightTooltip?: boolean;
}

const TextOverflowTooltip = ({
  text,
  tooltipText = '',
  width,
  paddingBottom = '0',
  placementBottom = false,
  bold = false,
  fontSize = '14px',
  color = colors.neutral.dark,
  lightTooltip = false,
}: Props) => {
  const [overflowing, setOverflowing] = useState<boolean>(false);
  const container = useRef<HTMLDivElement>();

  useEffect(() => {
    const { offsetWidth, scrollWidth } = container.current;
    setOverflowing(offsetWidth < scrollWidth);
  }, []);

  const styles = {
    fontWeight: bold ? '500' : '400',
    fontSize,
    color,
  };

  return !R.isNil(text) ? (
    <div
      ref={container}
      styleName="overflow-container"
      style={{ width, paddingBottom }}
    >
      {!overflowing ? (
        <div style={styles}>{text}</div>
      ) : (
        <Tooltip
          lightTooltip={lightTooltip}
          title={tooltipText || text}
          placement={placementBottom ? 'bottom' : 'right'}
          disableHoverListener={!overflowing}
        >
          <p styleName="overflow-text" style={{ ...styles, width }}>
            {text}
          </p>
        </Tooltip>
      )}
    </div>
  ) : (
    <div />
  );
};

export default TextOverflowTooltip;
