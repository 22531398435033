import { gql } from '@apollo/client';

export const BASE_TASK_TEMPLATE = gql`
  fragment BaseTaskTemplate on TaskTemplate {
    id
    name
    rootSchemaName
    rootSchemaId
    applicable
    locationsEnabled
    requireAtLeastOneLocation
    multiAssetsEnabled
    allowedMultiAssetSchemaIds
    allowedMultiAssetSchemas {
      id
      name
      markerId
      colorId
      ancestors
    }
    requireAtLeastOneMultiAsset
    requiredMultiAssetSchemaIds
  }
`;

export const GET_TASK_TEMPLATES = gql`
  query taskTemplates($input: TaskTemplatesConnectionInput!) {
    taskTemplates(input: $input) {
      totalCount
      taskTemplates {
        ...BaseTaskTemplate
      }
    }
  }
  ${BASE_TASK_TEMPLATE}
`;

export const TASK_TEMPLATE_CREATE = gql`
  mutation taskTemplateCreate($input: TaskTemplateCreateInput!) {
    taskTemplateCreate(input: $input) {
      ...BaseTaskTemplate
    }
  }
  ${BASE_TASK_TEMPLATE}
`;

export const TASK_TEMPLATE_DELETE = gql`
  mutation taskTemplateDelete($id: ID!) {
    taskTemplateDelete(id: $id)
  }
`;
