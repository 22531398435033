import React from 'react';

import { FavoriteDetail, FavoritesList } from '@atom/types/favorite';
import {
  InventoryAssetDetailType,
  InventoryCategoryTree,
  SearchAsset,
} from '@atom/types/inventory';

export type Tree = InventoryCategoryTree | InventoryAssetDetailType;

export enum View {
  FAVORITES,
  MATERIALS,
}

interface Context {
  allAssetsSelected: boolean;
  autoSelectChildCategories: boolean;
  createFavorite: (tree: Tree) => void;
  updateFavorite: (favorite: FavoriteDetail) => void;
  disabledAssetIds: Set<string>;
  disabledCategoryIds: Set<string>;
  favoritesEnabled: boolean;
  favoriteMaterials: FavoriteDetail[];
  favoritesLists: FavoritesList[];
  fetchMore: (id: string) => void;
  getData: (id: string) => Tree[];
  handleAssetClick: (asset: InventoryAssetDetailType | SearchAsset) => void;
  handleAssetToggle: (
    event: React.MouseEvent<any>,
    asset: SearchAsset | InventoryAssetDetailType,
  ) => void;
  handleCategoryToggle: (
    event: React.MouseEvent<any> | null,
    category: InventoryCategoryTree,
  ) => void;
  handleToggle: (tree: Tree) => void;
  hasError: (id: string) => boolean;
  hasMore: (id: string) => boolean;
  includeAssets: boolean;
  isLoading: (id: string) => boolean;
  onSelectAllAssetsToggle: () => void;
  open: Set<string>;
  rootTree: InventoryCategoryTree;
  schemaId: string;
  selectedAssetIds: Set<string>;
  selectedCategoryId: string;
  selectedCategoryIds: Set<string>;
  showRootCategoryCheckbox: boolean;
  handleAddList: (listName: string) => void;
  canAddFavorite: boolean;
  canAddList: boolean;
}

export const initialState: Context = {
  allAssetsSelected: false,
  autoSelectChildCategories: false,
  createFavorite: () => {},
  updateFavorite: () => {},
  disabledAssetIds: null,
  disabledCategoryIds: null,
  favoritesEnabled: false,
  favoriteMaterials: [],
  favoritesLists: [],
  fetchMore: () => {},
  getData: () => null,
  handleAssetClick: () => {},
  handleAssetToggle: () => {},
  handleCategoryToggle: () => {},
  handleToggle: () => {},
  hasError: () => false,
  hasMore: () => false,
  includeAssets: true,
  isLoading: () => false,
  onSelectAllAssetsToggle: () => {},
  open: null,
  rootTree: null,
  schemaId: null,
  selectedAssetIds: null,
  selectedCategoryId: null,
  selectedCategoryIds: null,
  showRootCategoryCheckbox: false,
  handleAddList: (listName: string): void => {},
  canAddFavorite: true,
  canAddList: true,
};

const TreeContext = React.createContext<Context>(initialState);

export default TreeContext;
