import * as React from 'react';

// @ts-ignore
import poiIcon from '@atom/components/common/svgIcons/googlePlaces.svg';
// @ts-ignore
import poiActiveIcon from '@atom/components/common/svgIcons/googlePlacesActive.svg';

import MapControl from '../../../mapControl/MapControl';

import './layerControls.css';

interface Props {
  mapTypeId: string;
  setMapTypeId: (string) => any;
  toggleTraffic?: () => any;
  traffic?: boolean;
  togglePointsOfInterest: () => void;
  pointsOfInterest?: boolean;
}

const LayerControls = ({
  mapTypeId,
  setMapTypeId,
  traffic,
  toggleTraffic,
  pointsOfInterest,
  togglePointsOfInterest,
}: Props) => {
  return (
    <div styleName="layer-controls-container">
      <div styleName="icon-container">
        <MapControl
          icon="satellite"
          tooltip="Satellite"
          onClick={() => setMapTypeId('hybrid')}
          active={mapTypeId === 'hybrid'}
        />
      </div>
      <div styleName="icon-container">
        <MapControl
          icon="map"
          tooltip="Map"
          onClick={() => setMapTypeId('roadmap')}
          active={mapTypeId === 'roadmap'}
        />
      </div>
      <div styleName="icon-container">
        <MapControl
          icon="traffic"
          tooltip="Traffic"
          onClick={toggleTraffic}
          active={traffic}
        />
      </div>
      <div styleName="icon-container">
        <MapControl
          icon={<img src={pointsOfInterest ? poiActiveIcon : poiIcon} />}
          tooltip="Google Places"
          onClick={togglePointsOfInterest}
          active={pointsOfInterest}
        />
      </div>
    </div>
  );
};

export default LayerControls;
