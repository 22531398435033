import React, { useContext, useMemo } from 'react';

import WorkOrderPreviewContext from '@atom/components/workOrderPreview/WorkOrderPreviewContext';
import { useSchemas } from '@atom/hooks/useSchemas';
import { Tooltip } from '@atom/mui';
import { WorkOrderType } from '@atom/types/work';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import history from '@atom/utilities/history';
import {
  setDisplayDate,
  setDurationDisplay,
} from '@atom/utilities/timeUtilities';

import './workOrderPreviewHeader.css';

const PreviewHeaderInfo = () => {
  const { workOrderDetail } = useContext(WorkOrderPreviewContext);
  const { schemas = [] } = useSchemas();

  const inventorySchemaName = useMemo(() => {
    const foundSchema = schemas.find(
      schema => schema.id === workOrderDetail?.inventoryAssetSchemaId,
    );

    return foundSchema?.name || '-';
  }, [workOrderDetail?.inventoryAssetSchemaId, schemas]);

  const navigateToInventoryDetail = () =>
    history.push(`/inventory/${workOrderDetail.inventoryAssetId}`);

  const inventoryNameOnClick = workOrderDetail?.inventoryAssetId
    ? navigateToInventoryDetail
    : () => {};

  const showActualCost = hasRolePermissions(ROLE_SETS.MANAGER);
  const showInventoryInfo =
    workOrderDetail?.inventoryAssetSchemaId &&
    workOrderDetail?.type === WorkOrderType.DEFAULT;

  return (
    <div styleName="header-info-section">
      {showInventoryInfo && (
        <div styleName="info-section-block">
          <div styleName="info-content">
            <span>Asset Name: </span>
            <Tooltip title={workOrderDetail?.inventoryAssetName}>
              <span styleName="inventory-name" onClick={inventoryNameOnClick}>
                {workOrderDetail?.inventoryAssetName || '-'}
              </span>
            </Tooltip>
          </div>
          <div styleName="info-content">
            <span>Asset Type: </span>
            <Tooltip title={inventorySchemaName}>
              <span styleName="inventory-name">{inventorySchemaName}</span>
            </Tooltip>
          </div>
        </div>
      )}
      <div styleName="info-section-block">
        <div styleName="info-content">{`Start Date: ${setDisplayDate(
          workOrderDetail.startTime,
        )}`}</div>
        <div styleName="info-content">{`Completed Date: ${setDisplayDate(
          workOrderDetail.completionDate,
        )}`}</div>
      </div>
      <div styleName="info-section-block">
        <div styleName="info-content">{`Due Date: ${setDisplayDate(
          workOrderDetail.dueDate,
        )}`}</div>
        <div styleName="info-content">{`Duration: ${setDurationDisplay(
          workOrderDetail.duration,
        )}`}</div>
      </div>
      <div styleName="info-section-block">
        <div styleName="info-content">{`Estimated Cost: ${numberToLocaleString(
          workOrderDetail.estimatedCost,
        )}`}</div>
        {showActualCost && (
          <div styleName="info-content">{`Actual Cost: ${numberToLocaleString(
            workOrderDetail.actualCost,
          )}`}</div>
        )}
      </div>
    </div>
  );
};

export default PreviewHeaderInfo;
