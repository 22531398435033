import React from 'react';

import ScheduleCalendar from './ScheduleCalendar';
import ScheduleSidebar from './ScheduleSidebar';

import './schedule.css';

const SchedulePortal = () => {
  return (
    <div styleName="portal-container">
      <ScheduleSidebar />
      <ScheduleCalendar />
    </div>
  );
};

export default SchedulePortal;
