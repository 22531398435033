import React, { useEffect } from 'react';

import { Button, Icon, IconButton, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';

import './schedule.css';

export interface Props {
  onNavigate: (keyword: string) => void;
  onView: (view: string) => void;
  setViewDate: (date: any) => void;
  setView: (option: string) => void;
  label: string;
  view: string;
  date: any;
  loading: boolean;
}

const WorkCalendarToolbar = ({
  onNavigate,
  label,
  onView,
  view,
  setViewDate,
  setView,
  date,
  loading,
}: Props) => {
  useEffect(() => {
    setViewDate(date);
  }, [setViewDate, date]);

  const onChange = option => {
    onView(option);
    setView(option);
  };

  return (
    <div styleName="calendar-toolbar-container">
      <div styleName="calendar-toolbar-left-controls">
        <Button
          style={{
            color: colors.neutral.white,
            border: `1px solid ${colors.neutral.white}`,
            backgroundColor: colors.brand.orange,
          }}
          onClick={() => onNavigate('TODAY')}
        >
          Today
        </Button>
        <div styleName="date-label">{label}</div>
        <IconButton onClick={() => onNavigate('PREV')}>
          <Icon color={colors.neutral.white}>keyboard_arrow_left</Icon>
        </IconButton>
        <IconButton onClick={() => onNavigate('NEXT')}>
          <Icon color={colors.neutral.white}>keyboard_arrow_right</Icon>
        </IconButton>
      </div>
      <div styleName="right-container">
        {loading && (
          <div styleName="loading-container">
            <Progress
              size={20}
              thickness={1.5}
              progressColor={colors.neutral.white}
            />
          </div>
        )}
        <div>
          <Button
            style={{
              color: colors.neutral.white,
              backgroundColor:
                view === 'month'
                  ? colors.opacity.black0_1
                  : colors.brand.orange,
            }}
            onClick={() => onChange('month')}
          >
            Month
          </Button>
          <Button
            style={{
              color: colors.neutral.white,
              backgroundColor:
                view === 'week' ? colors.opacity.black0_2 : colors.brand.orange,
            }}
            onClick={() => onChange('week')}
          >
            Week
          </Button>
          <Button
            style={{
              color: colors.neutral.white,
              backgroundColor:
                view === 'day' ? colors.opacity.black0_2 : colors.brand.orange,
            }}
            onClick={() => onChange('day')}
          >
            Day
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WorkCalendarToolbar;
