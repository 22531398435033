import React from 'react';

import TextField from '@atom/components/common/TextField';
import { RadioGroup } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { EventType } from '@atom/types/event';
import { isValidUrl } from '@atom/utilities/validationUtilities';

import '../formBuilder.css';

const { RadioButton } = RadioGroup;

export interface Props {
  title: string;
  url: string;
  width: number;
  onChange: (event: EventType) => void;
}

const styles = {
  input: {
    fontSize: fonts.md,
    color: colors.neutral.dark,
  },
  radioGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  radio: {
    width: 'inherit',
    marginRight: '16px',
  },
  iframeContainer: {
    position: 'relative',
    paddingBottom: '56.25%',
    paddingTop: '2.5rem',
    height: 0,
    marginBottom: '3rem',
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: `1px solid ${colors.neutral.ash}`,
  },
};

const WIDTH_OPTIONS = [
  { value: 33, label: 'Small' },
  { value: 50, label: 'Medium' },
  { value: 100, label: 'Large' },
];

const IFrameInput = ({ title, url, width, onChange }: Props) => {
  const handleWidthChange = (event: any, value: string) => {
    onChange({
      ...event,
      target: {
        name: 'width',
        value,
      },
    });
  };

  return (
    <>
      <div styleName="multi-input-container">
        <div styleName="double-input">
          <TextField
            value={title}
            onChange={onChange}
            name="title"
            style={styles.input}
            fullWidth
            floatingLabelText="Title"
          />
        </div>
        <div styleName="double-input">
          <TextField
            value={url}
            onChange={onChange}
            name="url"
            style={styles.input}
            fullWidth
            floatingLabelText="Embed URL"
          />
        </div>
      </div>
      <div styleName="iframe-size-container">
        <div styleName="iframe-size-label">Size:</div>
        <RadioGroup
          row
          name="width"
          defaultValue={width}
          onChange={handleWidthChange}
          style={styles.radioGroup}
        >
          {WIDTH_OPTIONS.map(option => (
            <RadioButton
              key={option.value}
              value={option.value.toString()}
              label={option.label}
              style={styles.radio}
            />
          ))}
        </RadioGroup>
      </div>
      <div styleName="iframe-preview">
        <div style={{ width: `${width || 100}%` }}>
          <div style={styles.iframeContainer}>
            {isValidUrl(url) ? (
              <iframe style={styles.iframe} src={url} frameBorder={0} />
            ) : (
              <div
                style={{ ...styles.iframe, background: colors.neutral.ash }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default IFrameInput;
