import React, { useContext } from 'react';

import FavoritesIconButton from '@atom/components/common/favorites/FavoritesIconButton';
import UserThumbnail from '@atom/components/common/UserThumbnail';
import AddTeamModalContext from '@atom/components/common/workOrderDetail/addTeamModal/AddTeamModalContext';
import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import { UserDetail } from '@atom/types/user';

import './usersTab.css';

export interface Props {
  user: UserDetail;
}

const UserRow = ({ user }: Props) => {
  const {
    usersOnTask,
    favoriteUsers,
    selectedUsers,
    removeUserFromCart,
    addUserToCart,
    createFavorite,
    favoritesEnabled,
    favoritesLists,
    handleAddList,
    updateFavorite,
  } = useContext(AddTeamModalContext);

  const isOnTask = usersOnTask.includes(user.id);
  const isSelected = selectedUsers
    .map(selectedUser => selectedUser.id)
    .includes(user.id);
  const subtextStyle = isOnTask ? 'subtext disabled' : 'subtext';

  const getTitleStyle = () => {
    if (isSelected) {
      return 'title selected';
    }

    if (isOnTask) {
      return 'title disabled';
    }

    return 'title';
  };

  const onClick = () => {
    if (isSelected) {
      return removeUserFromCart(user.id);
    }

    if (isOnTask) {
      return () => {};
    }

    return addUserToCart(user);
  };

  const checkbox = isOnTask ? (
    <Icon color={colors.neutral.ash}>check_box</Icon>
  ) : isSelected ? (
    <Icon color={colors.brand.blue}>check_box</Icon>
  ) : (
    <Icon color={colors.neutral.gray}>check_box_outline_blank</Icon>
  );

  return (
    <div styleName="user-row" onClick={onClick}>
      {checkbox}
      <div styleName="left-container">
        <UserThumbnail
          image={user.photoUrl}
          alt={user.firstName}
          disabled={isOnTask}
        />
        <div>
          <div
            styleName={getTitleStyle()}
          >{`${user.firstName} ${user.lastName}`}</div>
          <div styleName={subtextStyle}>{user.email}</div>
        </div>
      </div>
      {favoritesEnabled && (
        <FavoritesIconButton
          item={user}
          createFavorite={createFavorite}
          updateFavorite={updateFavorite}
          favorites={favoriteUsers}
          favoritesLists={favoritesLists}
          typeName="users"
          handleAddList={handleAddList}
          canAddFavorite
          canAddList
        />
      )}
    </div>
  );
};

export default UserRow;
