import React from 'react';

import { MapAsset } from '@atom/types/map';
import { InvalidTaskFields, WorkOrderDetailType } from '@atom/types/work';
import {
  SearchWorkTemplate,
  WorkTemplate as WorkTemplateType,
  WorkTemplateListItem,
} from '@atom/types/workTemplate';

import GeneralBody from './formView/body/general/GeneralBody';
import TaskBody from './formView/body/task/TaskBody';
import WorkInfoBody from './formView/body/workInfo/WorkInfoBody';

export interface Tab {
  id: string;
  body: React.ComponentType<any>;
  icon: string;
  label: string;
  subText?: string;
  taskIndex?: number;
  value: number;
}

export const coreTabs: Tab[] = [
  {
    id: 'general',
    body: GeneralBody,
    icon: 'work',
    label: 'General',
    value: 1,
  },
  {
    id: 'work_info',
    body: WorkInfoBody,
    icon: 'work',
    label: 'Work Info',
    value: 2,
  },
];

export const buildTabs = tasks => [
  ...coreTabs,
  ...tasks.map((task, index) => ({
    id: task.id,
    body: TaskBody,
    icon: 'playlist_add_check',
    taskIndex: index,
    label: task.name,
    subText: `Task ${index + 1} of ${tasks.length}`,
    value: index + 3, // Start from index 3 due to the existing General and Work Info tabs.
  })),
];

export type WorkTemplate = WorkTemplateListItem | SearchWorkTemplate;

export enum View {
  WORK_TYPE,
  FORM,
}

interface Context {
  tab: Tab;
  setTab: React.Dispatch<React.SetStateAction<Tab>>;
  assets: MapAsset[];
  invalidTabs: Set<string>;
  setInvalidTabs: React.Dispatch<React.SetStateAction<Set<string>>>;
  invalidFields: InvalidTaskFields;
  setInvalidFields: React.Dispatch<React.SetStateAction<InvalidTaskFields>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setView: React.Dispatch<React.SetStateAction<View>>;
  workTemplate: WorkTemplate;
  setWorkTemplate: React.Dispatch<React.SetStateAction<WorkTemplate>>;
  workOrder: WorkOrderDetailType;
  setWorkOrder: React.Dispatch<React.SetStateAction<WorkOrderDetailType>>;
  workTemplateType: WorkTemplateType;
  setWorkTemplateType: React.Dispatch<React.SetStateAction<WorkTemplateType>>;
}

export const initialState: Context = {
  tab: null,
  setTab: () => {},
  invalidTabs: new Set(),
  setInvalidTabs: () => {},
  invalidFields: {},
  setInvalidFields: () => {},
  assets: [],
  name: null,
  workTemplate: null,
  setName: () => {},
  setView: () => {},
  setWorkTemplate: () => {},
  setWorkOrder: () => {},
  // @ts-ignore
  workOrder: {
    closedDate: new Date().valueOf(),
    completionDate: new Date().valueOf(),
    dueDate: new Date().valueOf(),
    fieldOrder: [],
    fields: [],
    summary: [],
    tasks: [],
  },
  workTemplateType: null,
  setWorkTemplateType: () => {},
};

const BulkContext = React.createContext<Context>(initialState);

export default BulkContext;
