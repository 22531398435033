import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as R from 'ramda';

import { GET_WORK_ORDER_TEMPLATE } from '@atom/graph/workTemplate';
import { Button } from '@atom/mui';
import {
  FieldFilter,
  TaskFieldsFilter,
  WorkOrdersConnectionInput,
} from '@atom/types/work';
import {
  WorkOrderTemplateFolder,
  WorkTemplate,
} from '@atom/types/workTemplate';

import TaskFiltersSection from './AssetWorkOrdersFilters/TaskFiltersSection';
import TeamFiltersSection from './AssetWorkOrdersFilters/TeamFiltersSection';
import WorkFiltersSection from './AssetWorkOrdersFilters/WorkFiltersSection';

import './assetWorkOrdersFilterPanel.css';

export interface AdditionalFieldFilters {
  [fieldId: string]: FieldFilter;
}

export interface TasksAdditionalFieldFilters {
  [taskId: string]: AdditionalFieldFilters;
}

export interface FilterForm {
  workDueDateStart?: number;
  workDueDateEnd?: number;
  workDueDateSelectValue?: string;
  workCompletionDateStart?: number;
  workCompletionDateEnd?: number;
  workCompletionDateSelectValue?: string;
  workAppliedDateStart?: number;
  workAppliedDateEnd?: number;
  workAppliedDateSelectValue?: string;
  selectStatuses: number[];
  workTemplateFolders: WorkOrderTemplateFolder[];
  hasWorkTemplate?: boolean;
  workTemplates: WorkTemplate[];
  workTemplateFields: AdditionalFieldFilters;
  taskDueDateStart?: number;
  taskDueDateEnd?: number;
  taskDueDateSelectValue?: string;
  taskStartDateStart?: number;
  taskStartDateEnd?: number;
  taskStartDateSelectValue?: string;
  taskCompletionDateStart?: number;
  taskCompletionDateEnd?: number;
  taskCompletionDateSelectValue?: string;
  taskDurationStart?: number;
  taskDurationEnd?: number;
  taskCustomCommonFields: AdditionalFieldFilters;
  workTemplateTaskFields: TasksAdditionalFieldFilters;
  teamAssignedToGroupIds: string[];
  teamAssignedToUserIds: string[];
  teamCompletedByIds: string[];
  teamCreatedByIds: string[];
}

const FILTER_DEFAULTS: FilterForm = {
  workDueDateStart: null,
  workDueDateEnd: null,
  workDueDateSelectValue: null,
  workCompletionDateStart: null,
  workCompletionDateEnd: null,
  workCompletionDateSelectValue: null,
  workAppliedDateStart: null,
  workAppliedDateEnd: null,
  workAppliedDateSelectValue: null,
  selectStatuses: [],
  workTemplateFolders: [],
  hasWorkTemplate: null,
  workTemplates: [],
  workTemplateFields: {},
  taskDueDateStart: null,
  taskDueDateEnd: null,
  taskDueDateSelectValue: null,
  taskStartDateStart: null,
  taskStartDateEnd: null,
  taskStartDateSelectValue: null,
  taskCompletionDateStart: null,
  taskCompletionDateEnd: null,
  taskCompletionDateSelectValue: null,
  taskDurationStart: null,
  taskDurationEnd: null,
  taskCustomCommonFields: {},
  workTemplateTaskFields: {},
  teamAssignedToGroupIds: [],
  teamAssignedToUserIds: [],
  teamCompletedByIds: [],
  teamCreatedByIds: [],
};

interface Props {
  schemaId: string;
  loadingWorkOrders: boolean;
  setActiveFilters: Dispatch<SetStateAction<WorkOrdersConnectionInput>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const AssetWorkOrdersFilterPanel = ({
  schemaId,
  loadingWorkOrders,
  setActiveFilters,
  setCurrentPage,
}: Props) => {
  const [filterFormData, setFilterFormData] = useState<FilterForm>({
    ...FILTER_DEFAULTS,
  });

  const {
    workDueDateStart,
    workDueDateEnd,
    workDueDateSelectValue,
    workCompletionDateStart,
    workCompletionDateEnd,
    workCompletionDateSelectValue,
    workAppliedDateStart,
    workAppliedDateEnd,
    workAppliedDateSelectValue,
    selectStatuses,
    workTemplateFolders,
    hasWorkTemplate,
    workTemplates,
    workTemplateFields,
    taskDueDateStart,
    taskDueDateEnd,
    taskDueDateSelectValue,
    taskStartDateStart,
    taskStartDateEnd,
    taskStartDateSelectValue,
    taskCompletionDateStart,
    taskCompletionDateEnd,
    taskCompletionDateSelectValue,
    taskDurationStart,
    taskDurationEnd,
    taskCustomCommonFields,
    workTemplateTaskFields,
    teamAssignedToGroupIds,
    teamAssignedToUserIds,
    teamCompletedByIds,
    teamCreatedByIds,
  } = filterFormData;

  const [
    getWorkOrderTemplate,
    {
      data: { workOrderTemplate } = { workOrderTemplate: null },
      loading: loadingWorkTemplateData,
    },
  ] = useLazyQuery<{ workOrderTemplate: WorkTemplate }, { id: string }>(
    GET_WORK_ORDER_TEMPLATE,
  );

  useEffect(() => {
    if (workTemplates.length === 1) {
      getWorkOrderTemplate({
        variables: {
          id: workTemplates[0]?.id,
        },
      });
    }
  }, [workTemplates]);

  const updateFilterFormData = (
    filterField: keyof FilterForm,
    updatedValue: any,
  ) => {
    setFilterFormData(prevData => ({
      ...prevData,
      [filterField]: updatedValue,
    }));
  };

  const sanitizeAndApplyFilters = () => {
    const workFilters = {
      ...(workDueDateStart && { dueDateStart: workDueDateStart }),
      ...(workDueDateEnd && { dueDateEnd: workDueDateEnd }),
      ...(workCompletionDateStart && {
        completionDateStart: workCompletionDateStart,
      }),
      ...(workCompletionDateEnd && {
        completionDateEnd: workCompletionDateEnd,
      }),
      ...(workAppliedDateStart && { appliedDateStart: workAppliedDateStart }),
      ...(workAppliedDateEnd && { appliedDateEnd: workAppliedDateEnd }),
      ...(!R.isEmpty(selectStatuses) && { statusIds: selectStatuses }),
      ...(!R.isEmpty(workTemplateFolders) && {
        workTemplateFolderIds: [
          ...workTemplateFolders.map(folder => folder.id),
        ],
      }),
      ...(!R.isNil(hasWorkTemplate) && { hasWorkTemplate }),
      ...(!R.isEmpty(workTemplates) && {
        workTemplateIds: [...workTemplates.map(template => template.id)],
      }),
      ...(!R.isEmpty(workTemplateFields) && {
        fields: Object.values(workTemplateFields),
      }),
    };

    const sanitizeAdditionalTaskFields = (
      taskFields: FilterForm['workTemplateTaskFields'],
    ): TaskFieldsFilter[] => {
      const sanitizedArray: TaskFieldsFilter[] = R.keys(taskFields).reduce(
        (acc, taskId) => {
          return [
            ...acc,
            { id: taskId, fields: Object.values(taskFields[taskId]) },
          ];
        },
        [],
      );
      return sanitizedArray;
    };

    const taskFilters = {
      ...(taskDueDateStart && { dueDateStart: taskDueDateStart }),
      ...(taskDueDateEnd && { dueDateEnd: taskDueDateEnd }),
      ...(taskStartDateStart && { startDateStart: taskStartDateStart }),
      ...(taskStartDateEnd && { startDateEnd: taskStartDateEnd }),
      ...(taskCompletionDateStart && {
        completionDateStart: taskCompletionDateStart,
      }),
      ...(taskCompletionDateEnd && {
        completionDateEnd: taskCompletionDateEnd,
      }),
      ...(taskDurationStart && { durationStart: taskDurationStart }),
      ...(taskDurationEnd && { durationEnd: taskDurationEnd }),
      ...(!R.isEmpty(taskCustomCommonFields) && {
        commonFields: Object.values(taskCustomCommonFields),
      }),
      ...(!R.isEmpty(workTemplateTaskFields) && {
        each: sanitizeAdditionalTaskFields(workTemplateTaskFields),
      }),
    };

    const teamFilters = {
      ...(!R.isEmpty(teamAssignedToGroupIds) && {
        groupIds: teamAssignedToGroupIds,
      }),
      ...(!R.isEmpty(teamAssignedToUserIds) && {
        userIds: teamAssignedToUserIds,
      }),
      ...(!R.isEmpty(teamCompletedByIds) && {
        completedByIds: teamCompletedByIds,
      }),
      ...(!R.isEmpty(teamCreatedByIds) && { createdByIds: teamCreatedByIds }),
    };

    setActiveFilters({
      ...(!R.isEmpty(workFilters) && { ...workFilters }),
      ...(!R.isEmpty(taskFilters) && { tasks: { ...taskFilters } }),
      ...(!R.isEmpty(teamFilters) && { ...teamFilters }),
    });
    setCurrentPage(1);
  };

  const onResetFilters = () => {
    setFilterFormData({ ...FILTER_DEFAULTS });
    setActiveFilters({});
    setCurrentPage(1);
  };

  const areFiltersDisabled: boolean =
    loadingWorkOrders || loadingWorkTemplateData;

  return (
    <>
      <div styleName="header filter-section sticky-top">
        <div styleName="header-title">Filters</div>
        <div styleName="header-button-container">
          <Button onClick={onResetFilters} disabled={areFiltersDisabled}>
            Reset
          </Button>
          <Button
            color="primary"
            onClick={sanitizeAndApplyFilters}
            disabled={areFiltersDisabled}
          >
            Apply
          </Button>
        </div>
      </div>
      <WorkFiltersSection
        schemaId={schemaId}
        areFiltersDisabled={areFiltersDisabled}
        workDueDateSelectValue={workDueDateSelectValue}
        workDueDateStart={workDueDateStart}
        workDueDateEnd={workDueDateEnd}
        workCompletionDateSelectValue={workCompletionDateSelectValue}
        workCompletionDateStart={workCompletionDateStart}
        workCompletionDateEnd={workCompletionDateEnd}
        workAppliedDateStart={workAppliedDateStart}
        workAppliedDateEnd={workAppliedDateEnd}
        workAppliedDateSelectValue={workAppliedDateSelectValue}
        selectStatuses={selectStatuses}
        workTemplateFolders={workTemplateFolders}
        hasWorkTemplate={hasWorkTemplate}
        workTemplates={workTemplates}
        workTemplateFields={workTemplateFields}
        workOrderTemplate={workOrderTemplate}
        updateFilterFormData={updateFilterFormData}
      />
      <TaskFiltersSection
        areFiltersDisabled={areFiltersDisabled}
        taskDueDateSelectValue={taskDueDateSelectValue}
        taskDueDateStart={taskDueDateStart}
        taskDueDateEnd={taskDueDateEnd}
        taskStartDateSelectValue={taskStartDateSelectValue}
        taskStartDateStart={taskStartDateStart}
        taskStartDateEnd={taskStartDateEnd}
        taskCompletionDateSelectValue={taskCompletionDateSelectValue}
        taskCompletionDateStart={taskCompletionDateStart}
        taskCompletionDateEnd={taskCompletionDateEnd}
        taskDurationStart={taskDurationStart}
        taskDurationEnd={taskDurationEnd}
        taskCustomCommonFields={taskCustomCommonFields}
        workTemplateTaskFields={workTemplateTaskFields}
        workTemplates={workTemplates}
        workOrderTemplate={workOrderTemplate}
        updateFilterFormData={updateFilterFormData}
      />
      <TeamFiltersSection
        areFiltersDisabled={areFiltersDisabled}
        teamAssignedToGroupIds={teamAssignedToGroupIds}
        teamAssignedToUserIds={teamAssignedToUserIds}
        teamCompletedByIds={teamCompletedByIds}
        teamCreatedByIds={teamCreatedByIds}
        updateFilterFormData={updateFilterFormData}
      />
    </>
  );
};

export default AssetWorkOrdersFilterPanel;
