import React, { useCallback, useContext, useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import debounce from 'lodash.debounce';

import client from '@atom/graph/client';
import { GET_USERS } from '@atom/graph/user';
import {
  Autocomplete,
  Avatar,
  Icon,
  IconButton,
  List,
  TextField,
} from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  UserDetail,
  UsersConnection,
  UsersConnectionInput,
} from '@atom/types/user';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { getUserFullName } from '@atom/utilities/userUtilities';

import BulkContext from '../../../BulkContext';
import { useWorkOrderValidation } from '../../../hooks/bulkWorkOrderHook';

import './userSelectField.css';

const { ListItemText } = List;

export interface Props {
  error: boolean;
  label: string;
  name: string;
}

const DEBOUNCE_TIME = 300;
const MIN_CHAR = 3;

type DebouncedUserSearch = (input: UsersConnectionInput) => void;

const styles = {
  avatar: {
    height: 24,
    width: 24,
  },
  iconButton: {
    border: '0',
    margin: '0',
    padding: '0',
  },
  label: {
    position: 'unset',
    color: colors.neutral.dim,
  },
};

const UserSelectField = ({ error = false, label, name }: Props) => {
  const { workOrder, setWorkOrder } = useContext(BulkContext);
  const [query, setQuery] = useState<string>('');
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [users, setUsers] = useState<UserDetail[]>([]);
  const [selected, setSelected] = useState<UserDetail>(workOrder?.[name]);
  const { validateField } = useWorkOrderValidation();

  const searchUsers = async (input: UsersConnectionInput) => {
    setLoadingSearch(true);

    const { data } = await client.query<{ users: UsersConnection }>({
      query: GET_USERS,
      variables: {
        input: {
          ...input,
          limit: 250,
          showAdmin: hasRolePermissions(ROLE_SETS.ADMIN),
        },
      },
    });

    setUsers(data?.users?.users || []);
    setLoadingSearch(false);
  };

  const searchUsersDebounced = useCallback<DebouncedUserSearch>(
    debounce(searchUsers, DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    if (query.length >= MIN_CHAR) {
      searchUsersDebounced({ name: query });
    } else {
      setUsers([]);
    }
  }, [query]);

  const handleSelect = (value?: UserDetail) => {
    setSelected(value);

    const newWorkOrder = {
      ...workOrder,
      [name]: value,
    };

    setWorkOrder(newWorkOrder);
    validateField(newWorkOrder, name);
  };

  const handleRemove = () => {
    setSelected(null);

    const newWorkOrder = {
      ...workOrder,
      [name]: null,
    };

    setWorkOrder(newWorkOrder);
  };

  if (selected) {
    const userName = `${selected.firstName} ${selected.lastName}`;
    return (
      <div styleName="user-container">
        <TextField
          fullWidth
          label={label}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <Avatar
                  src={selected.photoUrl}
                  alt={userName}
                  style={styles.avatar}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleRemove}
                  tooltip="Remove"
                  edge="end"
                  style={styles.iconButton}
                >
                  <Icon>close</Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={userName}
        />
      </div>
    );
  }

  return (
    <Autocomplete<UserDetail, false, false>
      fullWidth
      autoFocus
      clearOnBlur
      error={error}
      errorHelperText="Please choose a team member"
      label={label}
      popperAutoWidth
      options={users}
      loading={loadingSearch}
      inputValue={query}
      onInputChange={(_, value) => setQuery(value || '')}
      getOptionLabel={user => getUserFullName(user)}
      placeholder="Search for Team Member"
      value={selected}
      onChange={(event, value) => handleSelect(value)}
      renderOption={(optionProps, user) => (
        <li key={user.id} {...optionProps}>
          <Avatar
            src={user.photoUrl}
            alt={user.firstName}
            style={{ marginRight: '0.5rem' }}
          />
          <ListItemText
            primary={getUserFullName(user)}
            secondary={user.email}
          />
        </li>
      )}
      labelStyle={styles.label}
    />
  );
};

export default UserSelectField;
