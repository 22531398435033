import React, { useContext, useState } from 'react';
import * as R from 'ramda';

import AddFormsModal from '@atom/components/common/workOrderDetail/addFormsModal/AddFormsModal';
import TaskContext from '@atom/components/workOrderPreview/taskView/TaskContext';
import WorkOrderPreviewContext from '@atom/components/workOrderPreview/WorkOrderPreviewContext';
import { Icon, IconButton } from '@atom/mui';
import api from '@atom/utilities/api';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { WORK_ORDERS_ENDPOINT } from '@atom/utilities/endpoints';

import FormTile from './FormTile';

import './formSection.css';

const styles = {
  icon: {
    paddingTop: '0.75rem',
  },
};

const FormSection = () => {
  const { workOrderDetail, refetch, loading } = useContext(
    WorkOrderPreviewContext,
  );
  const { task } = useContext(TaskContext);

  const [open, setOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleAddForm = async (formTemplateIds: string[]) => {
    const endpoint = `${WORK_ORDERS_ENDPOINT}/${workOrderDetail.id}/tasks/${task.id}/formInstances`;

    await api.post(endpoint, { formTemplateIds });

    setOpen(false);
    refetch();
  };

  const isSectionEmpty = R.isEmpty(task.formInstanceIds);
  const totalCount = R.length(task.formInstanceIds);
  const icon = expanded ? 'expand_less' : 'expand_more';
  const isEditDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  return (
    <>
      <div styleName="section-container">
        <Icon style={styles.icon}>library_books</Icon>
        <div styleName="right-container">
          <div styleName="header-container">
            <div styleName="left">
              <div styleName="section-title">Forms</div>
              <div styleName="total-count">{totalCount}</div>
            </div>
            <div styleName="right">
              {expanded && (
                <IconButton
                  onClick={() => setOpen(!isEditDisabled)}
                  edge="end"
                  tooltip="Add Forms"
                >
                  <Icon>add</Icon>
                </IconButton>
              )}
              <IconButton onClick={() => setExpanded(!expanded)} edge="end">
                <Icon>{icon}</Icon>
              </IconButton>
            </div>
          </div>
          {expanded && (
            <div>
              {isSectionEmpty ? (
                <div styleName="empty-section-text">Add Forms</div>
              ) : (
                <>
                  {task.formInstanceIds.map(formInstanceId => (
                    <FormTile
                      key={formInstanceId}
                      formInstanceId={formInstanceId}
                    />
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <AddFormsModal
        schemaId={workOrderDetail?.inventoryAssetSchemaId}
        loading={loading}
        open={open}
        onClose={() => setOpen(false)}
        handleAddForm={handleAddForm}
      />
    </>
  );
};

export default FormSection;
