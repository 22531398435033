import React, { useEffect, useState } from 'react';
import * as R from 'ramda';

import LoadMoreButton from '@atom/components/common/loadMoreButton/LoadMoreButton';
import { useWorkOrderSearch } from '@atom/hooks/useWorkOrderSearch';
import { Autocomplete, List } from '@atom/mui';
import { WorkOrder, WorkOrderDetailType } from '@atom/types/work';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './timeSheet.css';

const { ListItemText } = List;

const LIMIT = 10;

interface Props {
  workTemplateId: string;
  selectedWorkOrder: WorkOrder | WorkOrderDetailType;
  disabled: boolean;
  setSelectedWorkOrder: (selectedWorkOrder: WorkOrder) => void;
}

const WorkOrderSearch = ({
  selectedWorkOrder,
  workTemplateId,
  setSelectedWorkOrder,
  disabled,
}: Props) => {
  const [query, setQuery] = useState<string>('');
  const [selected, setSelected] = useState<any>(selectedWorkOrder || null);

  const [
    connection,
    {
      initialSearch,
      hasMore,
      fetchMore,
      loadingSearch,
      loadingPagination,
      error,
    },
  ] = useWorkOrderSearch({
    query,
    workTemplateIds: workTemplateId ? [workTemplateId] : [],
    statusIds: [0, 1, 2, 3, 4],
    limit: LIMIT,
    sortBy: 'name,asc',
  });

  const workOrders = R.pathOr([], ['workOrders'], connection);

  const handleSelect = (workOrder?: WorkOrder) => {
    setSelected(workOrder);
    setSelectedWorkOrder(workOrder);
  };

  useEffect(() => {
    if (workTemplateId) {
      initialSearch();
    }
  }, [workTemplateId]);

  useEffect(() => {
    if (!selectedWorkOrder || disabled) {
      setSelected(null);
      setQuery('');
    } else {
      setSelected(selectedWorkOrder);
    }
  }, [selectedWorkOrder, disabled]);

  return (
    <Autocomplete<WorkOrder, false, false>
      fullWidth
      popperAutoWidth
      options={workOrders}
      loading={loadingSearch}
      inputValue={query}
      onInputChange={(_, value) => setQuery(value || '')}
      getOptionLabel={workOrder => workOrder.name || ''}
      groupBy={() => 'Your work'}
      placeholder="Search"
      noOptionsText="No results"
      value={selected}
      onFocus={initialSearch}
      onChange={(event, value) => handleSelect(value)}
      disabled={disabled}
      renderGroup={options => {
        return (
          <div key={options.group}>
            <div styleName="search-group-header">{options.group}</div>
            {options.children}
            {hasMore && (
              <LoadMoreButton
                loading={loadingPagination}
                error={!!error}
                onClick={() => fetchMore()}
                style={{
                  width: '100%',
                  marginTop: '1rem',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              />
            )}
          </div>
        );
      }}
      renderOption={(propOptions, option) => {
        return (
          <li {...propOptions}>
            <ListItemText>
              <div>
                <div styleName="option-title">{option.name}</div>
                {option.inventoryAssetName && (
                  <div styleName="option-subtext">{`Inventory: ${option.inventoryAssetName}`}</div>
                )}
                {!isNilOrEmpty(option.dueDate) && (
                  <div styleName="option-subtext">{`Due Date: ${setDisplayDate(
                    option.dueDate,
                  )}`}</div>
                )}
              </div>
            </ListItemText>
          </li>
        );
      }}
    />
  );
};

export default WorkOrderSearch;
