import React from 'react';

import './workOrderReport.css';

interface Props {
  reportAssets: any[];
}

const ReportAssetsSection = ({ reportAssets = [] }: Props) => {
  const assetNames = reportAssets.map(asset => asset?.name);

  return <div styleName="basic-container">{assetNames.join(', ')}</div>;
};

export default ReportAssetsSection;
