import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
import EditRoleModal from '@atom/components/common/editRoleModal/EditRoleModal';
import RoleDuplicateModal from '@atom/components/common/roleDuplicateModal/RoleDuplicateModal';
import { DELETE_ROLE } from '@atom/graph/role';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import history from '@atom/utilities/history';

import RoleContext, { View } from '../RoleContext';

import './roleHeader.css';

const RoleHeader = () => {
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const { role, view: activeView, setView } = useContext(RoleContext);

  const [deleteRole, { loading: loadingDelete }] = useMutation(DELETE_ROLE);

  const views = [
    {
      value: View.POLICIES,
      label: 'policies',
      icon: 'settings',
    },
    {
      value: View.ASSIGNEES,
      label: 'assigned to',
      icon: 'group',
    },
  ];

  const handleDelete = async () => {
    await deleteRole({
      variables: { id: role.id },
    });

    history.push('/team');
  };

  return (
    <>
      <div styleName="header-container">
        <div styleName="top-bar">
          <div styleName="title-container">
            <IconButton onClick={() => history.goBackWithState('/team')}>
              <Icon color={colors.neutral.white}>arrow_back</Icon>
            </IconButton>
            <div styleName="title">{role.name}</div>
          </div>
          {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
            <div>
              {!role.isDefault && (
                <IconButton onClick={() => setEditModalOpen(true)}>
                  <Icon color={colors.neutral.white}>edit</Icon>
                </IconButton>
              )}
              <IconButton onClick={() => setDuplicateModalOpen(true)}>
                <Icon color={colors.neutral.white}>content_copy</Icon>
              </IconButton>
              {!role.isDefault && (
                <IconButton onClick={() => setDeleteModalOpen(true)}>
                  <Icon color={colors.neutral.white}>delete</Icon>
                </IconButton>
              )}
            </div>
          )}
        </div>
        <div styleName="description">{role.description}</div>
        <div styleName="navigation-bar">
          {views.map(view => {
            const styleName =
              activeView === view.value
                ? 'icon-container active'
                : 'icon-container';

            return (
              <div
                key={view.value}
                onClick={() => setView(view.value)}
                styleName={styleName}
              >
                <IconButton
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  sx={{
                    ml: 1,
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                >
                  <Icon color={colors.neutral.white}>{view.icon}</Icon>
                </IconButton>
                <div styleName="label">{view.label}</div>
              </div>
            );
          })}
        </div>
      </div>
      <EditRoleModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        role={role}
      />
      <RoleDuplicateModal
        open={duplicateModalOpen}
        onClose={() => setDuplicateModalOpen(false)}
        role={role}
      />
      <DeleteModal
        title="Delete Role?"
        content="Deleting a role will delete all of its policies and remove it from all assigned users and user groups. Are you sure?"
        open={deleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={handleDelete}
        loading={loadingDelete}
      />
    </>
  );
};

export default RoleHeader;
