import React, { useContext, useState } from 'react';

import CreateModal from '@atom/components/common/CreateModal';
import DeleteModal from '@atom/components/common/DeleteModal';
import EditModal from '@atom/components/common/EditModal';
// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import WorkTemplatesContext from '@atom/components/workTemplates/WorkTemplatesContext';
import { Icon, Menu, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { WorkTemplateFolderTree } from '@atom/types/workTemplate';
import api from '@atom/utilities/api';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { WORK_TEMPLATES_FOLDER_ENDPOINT } from '@atom/utilities/endpoints';

import './workTemplatesFolderTree.css';

const { MenuItem } = Menu;

const styles = {
  iconButtonStyle: {
    padding: 0,
    margin: 0,
    border: 0,
    width: '1.25rem',
    height: '1.5rem',
  },
  menuItemStyle: {
    textTransform: 'capitalize',
    height: '2.25rem',
    padding: 0,
    overflow: 'hidden',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
  iconBox: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  primaryTextBox: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 0,
    padding: 0,
  },
  primaryText: {
    margin: '0 1rem',
    color: colors.neutral.dark,
    fontSize: fonts.md,
  },
};

interface Props {
  folder: WorkTemplateFolderTree;
  isRootFolder?: boolean;
}

const FolderOptions = ({ folder, isRootFolder }: Props) => {
  const {
    refetchWorkTemplateFolderTree,
    setActiveFolderId,
    setIsActiveFolderLeafNode,
  } = useContext(WorkTemplatesContext);

  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const handleDelete = async () => {
    setLoadingDelete(true);
    const endpoint = `${WORK_TEMPLATES_FOLDER_ENDPOINT}/${folder.id}`;

    try {
      await api.delete(endpoint);

      Snackbar.info({
        message: 'Folder deleted',
      });

      refetchWorkTemplateFolderTree();

      setLoadingDelete(false);
      setDeleteModalOpen(false);
      setActiveFolderId('workTypes');
      setIsActiveFolderLeafNode(false);
    } catch (error) {
      if (error.response.status === 422) {
        Snackbar.error({
          message: 'Folders containing work templates cannot be deleted.',
        });
      }

      setLoadingDelete(false);
      setDeleteModalOpen(false);
    }
  };

  const handleCreate = async (name: string) => {
    setLoadingCreate(true);
    await api.post(WORK_TEMPLATES_FOLDER_ENDPOINT, {
      name,
      parentFolderId: folder.id,
    });

    refetchWorkTemplateFolderTree();

    setLoadingCreate(false);
    setCreateModalOpen(false);
  };

  const handleEdit = async (value: string) => {
    setLoadingEdit(true);
    const endpoint = `${WORK_TEMPLATES_FOLDER_ENDPOINT}/${folder.id}`;
    await api.patch(endpoint, { name: value });

    refetchWorkTemplateFolderTree();

    setLoadingEdit(false);
    setEditModalOpen(false);
  };

  const isDeletedAllowed =
    hasRolePermissions(ROLE_SETS.MANAGER) && !isRootFolder;

  return (
    <div styleName="options-wrapper">
      <Menu IconProps={{ color: colors.neutral.gray }}>
        {hasRolePermissions(ROLE_SETS.MANAGER) && !isRootFolder && (
          <MenuItem
            style={styles.menuItemStyle}
            onClick={() => setEditModalOpen(true)}
          >
            <div style={styles.primaryTextBox}>
              <div style={styles.iconBox}>
                <img src={renameIcon} />
                <div style={styles.primaryText}>Rename</div>
              </div>
            </div>
          </MenuItem>
        )}
        {hasRolePermissions(ROLE_SETS.MANAGER) && !folder.hasTemplates && (
          <MenuItem
            style={styles.menuItemStyle}
            onClick={() => setCreateModalOpen(true)}
          >
            <div style={styles.primaryTextBox}>
              <div style={styles.iconBox}>
                <Icon color={colors.neutral.gray}>create_new_folder</Icon>
                <div style={styles.primaryText}>Create Folder</div>
              </div>
            </div>
          </MenuItem>
        )}
        {isDeletedAllowed && (
          <MenuItem
            style={styles.menuItemStyle}
            onClick={() => setDeleteModalOpen(true)}
          >
            <div style={styles.primaryTextBox}>
              <div style={styles.iconBox}>
                <Icon color={colors.neutral.gray}>delete</Icon>
                <div style={styles.primaryText}>Delete</div>
              </div>
            </div>
          </MenuItem>
        )}
      </Menu>
      <DeleteModal
        open={deleteModalOpen}
        loading={loadingDelete}
        title="Delete Work Template Folder"
        content="Are you sure you want to delete this folder? Any folders and work templates below this folder will also be deleted if they are not associated to work."
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />
      <CreateModal
        open={createModalOpen}
        loading={loadingCreate}
        title="Create Folder"
        fieldName="folder name"
        initialValue="Untitled"
        onCancel={() => setCreateModalOpen(false)}
        onConfirm={handleCreate}
      />
      <EditModal
        initialValue={folder.name}
        open={editModalOpen}
        loading={loadingEdit}
        title="Rename Folder"
        fieldName="folder name"
        onCancel={() => setEditModalOpen(false)}
        onConfirm={handleEdit}
      />
    </div>
  );
};

export default FolderOptions;
