import { applyMiddleware, compose, createStore } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import createSagaMiddleware, { END } from 'redux-saga';
import thunkMiddleware from 'redux-thunk';

import rootReducer from '../reducers/rootReducer';

export function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [
    reduxImmutableStateInvariant(),
    thunkMiddleware,
    sagaMiddleware,
  ];

  // @ts-ignore
  const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__;

  const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middlewares),
      reduxDevTools ? reduxDevTools() : format => format, // add support for Redux dev tools
    ),
  );

  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept('../reducers/rootReducer', () => {
      const nextReducer = require('../reducers/rootReducer').default;
      store.replaceReducer(nextReducer);
    });
  }
  // @ts-ignore
  store.runSaga = sagaMiddleware.run;
  // @ts-ignore
  store.close = () => store.dispatch(END);

  return store;
}
