import React from 'react';
import * as R from 'ramda';

// @ts-ignore
import { Button, Icon, IconButton, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';

import './analyticsCardModal.css';

export interface Props {
  embedLinks: string[];
  setEmbedLinks: (embedLinks: string[]) => void;
}

const styles = {
  fieldRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  button: {
    padding: '0',
    marginLeft: '5px',
  },
  addOptionButton: {
    marginTop: '0.5rem',
  },
};

const EmbedLinksField = ({ embedLinks, setEmbedLinks }: Props) => {
  const removeLastEmbedDisabled = embedLinks.length <= 1;

  const updateOption = (index: number, value: string): void => {
    setEmbedLinks(R.update(index, value, embedLinks));
  };

  const removeOption = (index: number): void => {
    setEmbedLinks(R.remove(index, 1, embedLinks));
  };

  return (
    <div style={{ ...styles.fieldRow, alignItems: 'baseline' }}>
      <div>
        <Icon>link</Icon>
      </div>
      <div style={{ flex: '1 0 auto' }}>
        <div styleName="embed-label">Embed URL</div>
        {embedLinks.map((url: string, index: number) => {
          return (
            <div key={index} styleName="option-input-container">
              <div styleName="option-number">{`${index + 1}.`}</div>
              <TextField
                id={`url_${index}`}
                value={url}
                onChange={event => updateOption(index, event.target.value)}
                style={{ width: '100%' }}
              />
              <IconButton
                style={styles.button}
                onClick={(): void => removeOption(index)}
                disabled={removeLastEmbedDisabled}
                data-cy={`removeAnalyticsEmbedLink_${index + 1}`}
              >
                <Icon
                  color={
                    removeLastEmbedDisabled
                      ? colors.neutral.silver
                      : colors.neutral.gray
                  }
                >
                  close
                </Icon>
              </IconButton>
            </div>
          );
        })}
        <div>
          <Button
            color="primary"
            startIcon={<Icon color={colors.brand.blue}>add</Icon>}
            onClick={() =>
              setEmbedLinks(R.insert(embedLinks.length, '', embedLinks))
            }
            style={styles.addOptionButton}
            data-cy="addAnalyticsEmbedLink"
          >
            Add URL
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EmbedLinksField;
