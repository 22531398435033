import React, { useContext, useEffect, useRef, useState } from 'react';
import * as R from 'ramda';

import { Icon, IconButton, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';

import InventoryPortalContext from '../InventoryPortalContext';

import InventoryCategoryTreeOptions from './InventoryCategoryTreeOptions';
import InventoryCategoryTreeRow from './InventoryCategoryTreeRow';

// @ts-ignore
import paneStyles from '../../../styles/left-pane.css';
// @ts-ignore
import treeStyles from './inventoryCategoryTree.css';

// @ts-ignore
let styleReference = paneStyles;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
styleReference = treeStyles;

const styles = {
  indentation: {
    paddingLeft: '2rem',
  },
  overflowStyle: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '12rem',
  },
};

const InventoryCategoryTree = () => {
  const {
    categoryTree,
    loading,
    open,
    toggleOpen,
    activeCategory,
    setActiveCategory,
    setActivePath,
    resetSelectedAssets,
  } = useContext(InventoryPortalContext);
  const [overflowing, setOverflowing] = useState<boolean>(false);
  const container = useRef<HTMLDivElement>();

  useEffect(() => {
    const { offsetWidth, scrollWidth } = container.current;
    setOverflowing(offsetWidth < scrollWidth);
  }, [categoryTree.name]);

  const expanded = open.has(categoryTree.id);

  const arrowDirection = expanded ? 'arrow_drop_down' : 'arrow_right';

  const rowStyle =
    categoryTree.id === activeCategory.id
      ? 'treeStyles.folder-row treeStyles.selected'
      : 'treeStyles.folder-row';

  const renderExpandedArrow = !R.isEmpty(categoryTree.categories);

  const onToggle = (event: any, id: string) => {
    event.stopPropagation();
    toggleOpen(id);
  };

  const onClick = () => {
    setActiveCategory(categoryTree, []);
    setActivePath([]);
    resetSelectedAssets();
  };

  return (
    <div styleName="paneStyles.left-pane">
      <div styleName={rowStyle} onClick={onClick}>
        <div
          ref={container}
          styleName="treeStyles.folder-name-container"
          style={renderExpandedArrow ? {} : styles.indentation}
        >
          {renderExpandedArrow && (
            <IconButton onClick={event => onToggle(event, categoryTree.id)}>
              <Icon color={colors.neutral.dim}>{arrowDirection}</Icon>
            </IconButton>
          )}
          <Icon color={colors.neutral.gray}>folder</Icon>
          <div styleName="treeStyles.row-name-container">
            <div>
              {!overflowing ? (
                categoryTree.name
              ) : (
                <Tooltip
                  lightTooltip
                  title={categoryTree.name}
                  placement="right-start"
                >
                  <p style={styles.overflowStyle}>{categoryTree.name}</p>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        {/* onClick handler prevents modal clicks from propagating up */}
        <div onClick={event => event.stopPropagation()}>
          <InventoryCategoryTreeOptions
            category={categoryTree}
            path={[]}
            isRootFolder
          />
        </div>
      </div>
      {expanded && !loading ? (
        <>
          {categoryTree.categories.map((child: any, index: number) => (
            <InventoryCategoryTreeRow
              key={child.id}
              category={child}
              indentation={0}
              path={['categories', index]}
            />
          ))}
        </>
      ) : (
        <div />
      )}
    </div>
  );
};

export default InventoryCategoryTree;
