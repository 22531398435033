import React, { useContext, useEffect, useState } from 'react';

import { useScrollPos } from '@atom/hooks/useScrollPos';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { DashboardCardContentState } from '@atom/types/dashboard';

import DashboardContext from './DashboardContext';
import DashboardCustomizeIcon from './DashboardCustomizeIcon';

import './dashboard.css';

const REFRESHED_MSG_TIMEOUT = 3000;

const getBorder = (scrolled: boolean) => ({
  borderBottom: scrolled ? `1px solid ${colors.neutral.ash}` : 'none',
});

const DashboardTitleBar = () => {
  const {
    emptyBoard,
    updateAllCardsState,
    setShowCustomize,
    someCardIsLoading,
  } = useContext(DashboardContext);
  const scrolled = useScrollPos() > 0;

  const [showRefreshedMsg, setShowRefreshedMsg] = useState(false);

  useEffect(() => {
    let timeout;

    if (someCardIsLoading === false && !timeout) {
      setShowRefreshedMsg(true);
      timeout = setTimeout(() => {
        setShowRefreshedMsg(false);
        timeout = undefined;
      }, REFRESHED_MSG_TIMEOUT);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [someCardIsLoading]);

  return (
    <div style={getBorder(scrolled)} styleName="dashboard-title-bar">
      <div styleName="dashboard-title">Dashboard</div>
      <div styleName="dashboard-icons">
        {someCardIsLoading && <span>Refreshing...</span>}
        {showRefreshedMsg && !someCardIsLoading && (
          <span>Refreshed just now.</span>
        )}
        {!emptyBoard && (
          <IconButton
            onClick={() =>
              updateAllCardsState(DashboardCardContentState.LOADING)
            }
            tooltip="Refresh"
            data-cy="dashboardHeaderRefreshIcon"
          >
            <Icon>refresh</Icon>
          </IconButton>
        )}
        <IconButton
          onClick={() => setShowCustomize(true)}
          tooltip="Customize Dashboard"
          data-cy="dashboardHeaderCustomizeIcon"
        >
          <DashboardCustomizeIcon color={colors.neutral.gray} />
        </IconButton>
      </div>
    </div>
  );
};

export default DashboardTitleBar;
