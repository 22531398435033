import React from 'react';
import * as R from 'ramda';

import WorkUsers from '@atom/components/common/workUsers/WorkUsers';
import { Task } from '@atom/types/task';
import { setDisplayDate } from '@atom/utilities/timeUtilities';

import CollapsedTaskHeader from './CollapsedTaskHeader';

import './collapsedTask.css';

interface Props {
  task: Task;
}

const CollapsedTask = ({ task }: Props) => {
  return (
    <div styleName="collapsed-row">
      <CollapsedTaskHeader task={task} />
      <div styleName="users-section">
        {R.isEmpty(task.users) ? (
          <div styleName="no-assignees-message">No Assignees</div>
        ) : (
          <WorkUsers
            users={task.users as any}
            userGroups={task.userGroups as any}
            avatarSize={32}
            showTaskUserStatus
          />
        )}
      </div>
      <div styleName="task-dates">
        <div>{`Due Date: ${setDisplayDate(task.dueDate)}`}</div>
        <div styleName="date-spacer">&bull;</div>
        <div>{`Completion Date: ${setDisplayDate(task.completionDate)}`}</div>
      </div>
    </div>
  );
};

export default CollapsedTask;
