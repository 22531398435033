import React, { useState } from 'react';

import ApproveAllChangesModal from '@atom/components/common/map/mapDrawerContent/ApproveAllChangesModal';
import AssetSummaryInfo from '@atom/components/common/map/mapDrawerContent/AssetSummaryInfo';
import AssetSummaryWork from '@atom/components/common/map/mapDrawerContent/AssetSummaryWork';
import NavigationButton from '@atom/components/common/map/mapDrawerContent/NavigationButton';
import NavigationControls from '@atom/components/common/map/mapDrawerContent/NavigationControls';
// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import textConstants from '@atom/constants/textConstants';
import { Icon, IconButton, Progress, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import { AssetDetail } from '@atom/types/inventory';
import { PolicyAction } from '@atom/types/policy';
import { WorkCollectionItem } from '@atom/types/work';
import { hasAccess } from '@atom/utilities/accessUtilities';
import api from '@atom/utilities/api';
import {
  FILES_ENDPOINT,
  INVENTORY_ASSETS_ENDPOINT,
} from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';
import { getStaticImage } from '@atom/utilities/mapUtilities';

import RenameInventoryModal from './RenameInventoryModal';

import './assetSummaryTray.css';

const styles = {
  icon: {
    color: colors.neutral.gray,
    width: 24,
    height: 24,
    padding: 0,
  },
  iconContainer: {
    width: 24,
    height: 24,
    padding: 0,
    cursor: 'pointer',
  },
};

const views = [
  {
    value: 'info',
    icon: 'info',
  },
  {
    value: 'work',
    icon: 'work',
  },
];

interface Props {
  asset: AssetDetail;
  workOrders: WorkCollectionItem[];
  loading: boolean;
  loadingRename: boolean;
  renameInventory: (name: string) => void;
  onCancel: () => void;
  refetch: () => void;
}

const AssetSummaryTray = ({
  asset,
  workOrders,
  onCancel,
  loading,
  loadingRename,
  renameInventory,
  refetch,
}: Props) => {
  const [activeView, setActiveView] = useState<string>('info');
  const [renameInventoryModalOpen, setRenameInventoryModalOpen] = useState<
    boolean
  >(false);
  const [approveChangesModalOpen, setApproveChangesModalOpen] = useState<
    boolean
  >(false);
  const [loadingApproveAll, setLoadingApproveAll] = useState<boolean>(false);

  const getSummaryImage = () => {
    return !asset.mainPhotoFileId
      ? getStaticImage(asset.location)
      : `${FILES_ENDPOINT}/${asset.mainPhotoFileId}_340`;
  };

  const getTrayContentBody = () => {
    switch (activeView) {
      case 'info': {
        return <AssetSummaryInfo asset={asset} refetch={refetch} />;
      }
      case 'work': {
        return <AssetSummaryWork work={workOrders} asset={asset} />;
      }
      default: {
        return <AssetSummaryInfo asset={asset} refetch={refetch} />;
      }
    }
  };

  const handleApproveChanges = async () => {
    try {
      setLoadingApproveAll(true);
      const { includeChangesBefore } = asset;
      await api.post(`${INVENTORY_ASSETS_ENDPOINT}/${asset.id}/approve`, {
        includeChangesBefore,
      });
      Snackbar.info({
        message: textConstants.GENERIC_APPLICATION_UPDATED_TEXT,
        autoHideDuration: 3000,
      });

      refetch();
      setApproveChangesModalOpen(false);
    } catch (error) {
      Snackbar.error({
        message:
          'Something went wrong. Please try again or contact an administrator.',
      });
    }
    setLoadingApproveAll(false);
  };

  const navigateToAssetDetail = () => {
    history.push(`/inventory/${asset.id}`);
  };

  const canManageChanges = hasAccess(
    PolicyAction.MANAGE_INVENTORY_CHANGES,
    asset?.actions,
  );
  const canRename = hasAccess(PolicyAction.RENAME, asset?.actions);

  const showApproveChanges = canManageChanges && asset?.changeType;

  const content = loading ? (
    <div styleName="spinner-container">
      <Progress />
    </div>
  ) : (
    <div styleName="tray-container">
      <div styleName="header-container">
        <div styleName="header-title-container">
          <div styleName="header-title">
            {loadingRename ? <Progress size={20} /> : asset.name}
          </div>
          {showApproveChanges && (
            <div>
              <IconButton
                tooltip="Approve All Changes"
                onClick={() => setApproveChangesModalOpen(true)}
              >
                <Icon style={styles.iconContainer}>done_all</Icon>
              </IconButton>
              <ApproveAllChangesModal
                open={approveChangesModalOpen}
                loading={loadingApproveAll}
                assetName={asset.name}
                onCancel={() => setApproveChangesModalOpen(false)}
                onApproveAll={() => handleApproveChanges()}
              />
            </div>
          )}
          {canRename && (
            <div>
              <IconButton
                tooltip="Rename"
                onClick={() => setRenameInventoryModalOpen(true)}
              >
                <Icon style={styles.iconContainer}>
                  <img src={renameIcon} />
                </Icon>
              </IconButton>
              <RenameInventoryModal
                open={renameInventoryModalOpen}
                loading={loadingRename}
                onCancel={() => setRenameInventoryModalOpen(false)}
                renameInventory={renameInventory}
                name={asset?.name}
              />
            </div>
          )}
          <div>
            <IconButton tooltip="Close">
              <Icon
                style={styles.iconContainer}
                iconStyle={styles.icon}
                onClick={onCancel}
              >
                close
              </Icon>
            </IconButton>
          </div>
        </div>
      </div>
      <div styleName="tray-content-container">
        <div
          styleName="summary-image-container"
          style={{
            backgroundImage: `url('${getSummaryImage()}')`,
          }}
        />
        <NavigationControls
          views={views}
          activeView={activeView}
          setActiveView={setActiveView}
        />
        <div>{getTrayContentBody()}</div>
      </div>
      <NavigationButton
        label="View Full Detail"
        onClick={navigateToAssetDetail}
      />
    </div>
  );

  return <div styleName="container">{content}</div>;
};

export default AssetSummaryTray;
