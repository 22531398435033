import React from 'react';

import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { convertMillisToExactHours } from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import { ReportUser } from './reportUtilities';

import './workOrderReport.css';

interface Props {
  user: ReportUser;
  showPerDiem: boolean;
  isCompletedOrAbove: boolean;
}

const ReportUserRow = ({ user, showPerDiem, isCompletedOrAbove }: Props) => {
  const getDurationDisplay = () => {
    if (user?.duration) {
      return convertMillisToExactHours(user?.duration);
    }

    if (!user?.duration && isCompletedOrAbove) {
      return '-';
    }

    return '';
  };

  const getPerDiemDisplay = () => {
    if (!isNilOrEmpty(user?.perDiem)) {
      return user?.perDiem.map(item => <div key={item}>{item}</div>);
    }

    if (isNilOrEmpty(user?.perDiem) && isCompletedOrAbove) {
      return '-';
    }

    return '';
  };

  const duration = getDurationDisplay();
  const perDiem = getPerDiemDisplay();

  return (
    <div styleName="users-table-row">
      <div styleName="users-table-sub-row">
        <div styleName="users-row-cell name">
          {user?.isTeamLead && <span styleName="team-lead">&#9733;</span>}
          {user?.name}
        </div>
        <div styleName="users-row-cell">{duration}</div>
        {showPerDiem && <div styleName="users-row-cell">{perDiem}</div>}
      </div>
      {isCompletedOrAbove && (
        <div styleName="users-row-cell cost">
          {numberToLocaleString(user?.actualCost)}
        </div>
      )}
    </div>
  );
};

export default ReportUserRow;
