import { add, endOfDay, format, startOfDay, sub } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import moment from 'moment';
import * as R from 'ramda';

import { PayPeriod, PayPeriods } from '@atom/types/payPeriod';
import { WorkOrderTimeEntry } from '@atom/types/timeEntry';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

// This enum denotes the following for date filters:
// GMT_MIDDAY = 12:00:00pm GMT
// LOCAL_START = 12:00:01am Local Timezone
// LOCAL_END = 11:59:59pm Local Timezone
export enum DateType {
  GMT_MIDDAY,
  LOCAL_START,
  LOCAL_END,
}

const HOUR_IN_MILLISECONDS = 3.6e6;
const MINUTES_IN_HOUR = 60;
const MINUTE_IN_MILLISECONDS = 60000;
const TWELVE_HOURS_IN_MILLISECONDS = 4.32e7;
export const DAY_IN_MILLISECONDS = 8.64e7;

export function getUnixTime(date: number): number {
  return date;
}

// Convert Date to 12:00:00pm GMT in milliseconds
export function convertDateToMillisGMTMidday(date: Date): number {
  const localDay = startOfDay(date);
  const gmtOffset = localDay.getTimezoneOffset() * MINUTE_IN_MILLISECONDS;
  const today = localDay.valueOf() - gmtOffset + TWELVE_HOURS_IN_MILLISECONDS;
  return today;
}

// Convert Date to 12:00:00am Local Timezone in milliseconds
export function convertDateToLocalDayStart(date: Date): number {
  const localDayStart = startOfDay(date);
  return localDayStart.valueOf();
}

// Convert Date to 11:59:59pm Local Timezone in milliseconds
export function convertDateToLocalDayEnd(date: Date): number {
  const localDayEnd = endOfDay(date);
  return localDayEnd.valueOf();
}

export function getTimeComponentInMillis(dateMillis: number): number {
  return dateMillis - startOfDay(dateMillis).valueOf();
}

export function getDateComponentInMillis(dateMillis: number): number {
  return startOfDay(dateMillis).valueOf();
}

export function setDisplayDate(
  dateInMillis: number,
  dateFormat: string = 'MM/dd/yyyy',
): string {
  if (!Number.isInteger(dateInMillis) || dateInMillis === 0) {
    return '-';
  }
  const date = new Date(dateInMillis);
  return format(date, dateFormat);
}

export const getDisplayDateTime = (dateInMillis: number): string => {
  if (!Number.isInteger(dateInMillis) || dateInMillis === 0) {
    return '';
  }

  const date = new Date(dateInMillis);
  return format(date, 'MM/dd/yyyy hh:mm a');
};

export const getUtcDisplayDate = (dateInMillis: number): string => {
  if (!Number.isInteger(dateInMillis)) {
    return '-';
  }

  const date = new Date(dateInMillis);

  const utcDate = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );

  return `${format(utcDate, 'MM/dd/yyyy HH:mm:ss')} GMT`;
};

export function getStartOfCurrentYear(dateInMillis: number): number {
  const date = new Date(dateInMillis);
  const currentYear = date.getUTCFullYear();
  const currentYearDay = new Date(currentYear, 0, 1);
  return convertDateToLocalDayStart(currentYearDay);
}

export function getEndOfCurrentYear(dateInMillis: number): number {
  const date = new Date(dateInMillis);
  const currentYear = date.getUTCFullYear();
  const currentYearDay = new Date(currentYear, 11, 31);
  return convertDateToLocalDayEnd(currentYearDay);
}

export function getStartOfCurrentMonth(): number {
  return moment().startOf('month').valueOf();
}

export function getEndOfCurrentMonth(): number {
  return moment().endOf('month').valueOf();
}

export function getOffsetDateMillis(
  dateInMillis: number,
  offsetDays: number,
): number {
  return dateInMillis + DAY_IN_MILLISECONDS * offsetDays;
}

export function isInvalidDateRange(
  property: string,
  dateMillis: number,
  startDateMillis: any,
  endDateMillis: any,
): boolean {
  const isStart = property.includes('Start') || property.includes('start');
  if (isStart) {
    return dateMillis > endDateMillis;
  }
  return startDateMillis > dateMillis;
}

export function convertHoursToMillis(hours: number): number {
  return hours * HOUR_IN_MILLISECONDS;
}

export function convertMinutesToMillis(minutes: number): number {
  return minutes * MINUTE_IN_MILLISECONDS;
}

export function convertHoursAndMinutesToMillis(
  hours: number,
  minutes: number,
): number {
  return convertHoursToMillis(hours) + convertMinutesToMillis(minutes);
}

interface HoursAndMinutes {
  hours: number;
  minutes: number;
}

export const convertMillisToExactHours = (millis: number): number => {
  return Number(millis / HOUR_IN_MILLISECONDS);
};

export function convertMillisToHours(millis: number): number {
  return Number((millis / HOUR_IN_MILLISECONDS).toFixed(2));
}

export const convertMillisToMinutes = (millis: number): number => {
  return Math.trunc(millis / MINUTE_IN_MILLISECONDS);
};

export const convertMillisToDays = (millis: number): number => {
  return Math.trunc(millis / DAY_IN_MILLISECONDS);
};

export function convertMillisToHoursAndMinutes(
  millis: number,
): HoursAndMinutes {
  const totalMinutes = convertMillisToMinutes(millis);
  const minutes = totalMinutes % MINUTES_IN_HOUR;
  const hours = (totalMinutes - minutes) / MINUTES_IN_HOUR;

  return {
    hours: hours || 0,
    minutes: minutes || 0,
  };
}

export const setDurationDisplay = (millis: number): string => {
  if (millis >= DAY_IN_MILLISECONDS) {
    const days = convertMillisToDays(millis);
    const dayText = days === 1 ? 'Day' : 'Days';

    return `${days} ${dayText}`;
  }

  return '0 Days';
};

export const setWorkTimeDisplay = (millis: number): string => {
  if (millis === HOUR_IN_MILLISECONDS) {
    return '1 Hour';
  }

  const hours = convertMillisToExactHours(millis);
  return `${hours} Hours`;
};

export const getLocalDisplayDate = (date: number): string => {
  if (!date) {
    return '';
  }

  const timeZone = `(${new Date(date).toString().split('(')[1]}`;
  return `${format(date, 'MM/dd/yyyy HH:mm:ss')} ${timeZone}`;
};

export const convertToDateString = dateMillis => {
  if (dateMillis === null) {
    return {};
  }

  if (dateMillis !== null && typeof dateMillis === 'object') {
    return dateMillis;
  }

  return new Date(dateMillis);
};

export const getWorkTimeFromTimeEntries = (
  timeEntries: WorkOrderTimeEntry[],
): number => {
  return timeEntries.reduce((acc, timeEntry) => {
    const { duration } = timeEntry;
    return acc + duration;
  }, 0);
};

// Rounds duration up to next quarter hour. All values above 24
// will be rounded down to 24 as per business rules.
export const roundTimeEntryDuration = (hours: number): number => {
  if (hours >= 24) {
    return 24;
  }

  return Number((Math.ceil(hours * 4) / 4).toFixed(2));
};

// Produces an array of dates in millis between the start
// and end date of the passed in pay period. This is then split into
// 7 day week arrays.
export const getPayPeriodWeeks = (payPeriod: PayPeriod): number[][] => {
  if (!payPeriod) {
    return [];
  }

  let fullDates = [convertDateToMillisGMTMidday(new Date(payPeriod.startDate))];

  while (R.last(fullDates) < payPeriod.endDate) {
    fullDates = [
      ...fullDates,
      convertDateToMillisGMTMidday(
        add(new Date(R.last(fullDates)), { days: 1 }),
      ),
    ];
  }

  const weeks = R.splitEvery(7, fullDates);

  return weeks;
};

export const getCurrentPayPeriod = (
  payPeriods: PayPeriods,
  date: Date,
): PayPeriod => {
  if (isNilOrEmpty(payPeriods) || isNilOrEmpty(date)) {
    return null;
  }

  return payPeriods.periods.find(
    period =>
      date.valueOf() >= period.startDate && date.valueOf() <= period.endDate,
  );
};

// Millisecond values for start and end of current day
interface StartEndDay {
  start: number;
  end: number;
}

// Get the start and end of current date in milliseconds in the given timezone
export const getStartEndTimezoneDay = (
  timezone: string = 'UTC',
): StartEndDay => {
  const todayCurrentTimezone = utcToZonedTime(new Date(), timezone);
  const start = startOfDay(todayCurrentTimezone).valueOf();
  const end = endOfDay(todayCurrentTimezone).valueOf();

  return {
    start,
    end,
  };
};

export const formatDateMoment = (dateMillis: number, formatString: string) => {
  if (!dateMillis || !formatString) {
    return '';
  }

  return moment(new Date(dateMillis)).format(formatString);
};

export const generateYYYYMM = (): string => {
  const now = moment();
  return moment.utc([now.year(), now.month(), now.date(), 12]).format('YYYYMM');
};

export const generateYYYYMMName = (template: any): string =>
  [R.pathOr('', ['name'], template), generateYYYYMM()]
    .filter(val => !!val)
    .join(' - ');

export const getDaysAgo = (days: number = 1): Date => sub(new Date(), { days });

export const getEndOfYesterdayMillis = (): number =>
  endOfDay(getDaysAgo()).valueOf();

const timeUtilities = {
  convertDateToMillisGMTMidday,
  getUnixTime,
  getTimeComponentInMillis,
  getDateComponentInMillis,
  setDisplayDate,
  getUtcDisplayDate,
  getOffsetDateMillis,
  isInvalidDateRange,
  getStartOfCurrentYear,
  getEndOfCurrentYear,
  getStartOfCurrentMonth,
  getEndOfCurrentMonth,
  convertHoursToMillis,
  convertMinutesToMillis,
  convertHoursAndMinutesToMillis,
  convertMillisToHoursAndMinutes,
  convertMillisToExactHours,
  generateYYYYMM,
  generateYYYYMMName,
  getDaysAgo,
  getEndOfYesterdayMillis,
};

export default timeUtilities;
