import * as R from 'ramda';

export const reducerWrapper = (
  reducer: object,
  initialState: object,
): Function => (state = initialState, action) => {
  const types = Object.keys(reducer);

  const conditions = [
    ...types.map(type => [
      R.propEq('type', type),
      ({ payload }) => reducer[type](payload)(state),
    ]),
    [R.identity, R.always(state)],
    [R.T, R.always(state)],
  ];

  // @ts-ignore
  return R.cond(conditions)(action);
};

// updateAtId takes an id and Ramda transform function
// applies transformation to object in list with id
// example... updateAtId(itemId, R.assoc('flag', true))(items)
export const updateAtId = (id: string, transform) =>
  R.map(R.when(R.propEq('id', id), transform));
