import React from 'react';

import { setDurationDisplay } from '@atom/utilities/timeUtilities';

import './workTemplateTaskDetail.css';

const TaskDuration = () => {
  return (
    <div styleName="time-row-item duration">
      <div styleName="label-container">
        <div styleName="label">Duration</div>
      </div>
      <div styleName="date-display">{setDurationDisplay(0)}</div>
    </div>
  );
};

export default TaskDuration;
