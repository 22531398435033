import actionTypes from '@atom/actions/actionTypes';
import { replace } from '@atom/utilities/replaceUtilities';

import initialState from './initialState';

export default function mediaReducer(state = initialState.media, action) {
  const { data } = action;
  switch (action.type) {
    case actionTypes.GET_MEDIA_SUCCESS: {
      return data;
    }
    case actionTypes.PATCH_MEDIA_SUCCESS: {
      let updatedData = state.find(medium => medium.id === data.id);
      if (!updatedData) {
        updatedData = data;
      } else {
        updatedData = {
          ...updatedData,
          ...data,
          createdBy: updatedData.createdBy,
        };
      }

      return replace(medium => medium.id === data.id, updatedData, [...state]);
    }
    case actionTypes.DELETE_MEDIA_SUCCESS: {
      return state.filter(medium => medium.id !== data);
    }
    default: {
      return state;
    }
  }
}
