import React from 'react';

import AttributeDetailHeader from './AttributeDetailHeader';
import ControlsSection from './ControlsSection';
import TypeSection from './TypeSection';

import './attributeDetail.css';

const AttributeDetail = () => {
  return (
    <>
      <AttributeDetailHeader />
      <TypeSection />
      <ControlsSection />
    </>
  );
};

export default AttributeDetail;
