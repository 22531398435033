// Created for use in budgets page remaining columns
// - displays value as currency
// - displays green percent bar if value is within limit
// - displays red percent bar if value is negative, using absolute value
// - caps bar at 100% if value exceeds limit

import React, { useMemo } from 'react';

import colors from '@atom/styles/colors';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';

interface Props {
  value: number;
  limit: number;
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
  },
  barContainer: {
    height: '0.5rem',
    borderRadius: '0.25rem',
    width: '100%',
  },
  bar: {
    height: '0.5rem',
    borderRadius: '0.25rem',
  },
};

const PercentBar = ({ value, limit }: Props) => {
  const width = useMemo(() => {
    const absVal = Math.abs(value);
    return absVal > limit ? '100%' : `${(absVal / limit) * 100}%`;
  }, [value, limit]);

  const isNegative = useMemo(() => value < 0, [value]);

  const backgroundColor = useMemo(
    () =>
      isNegative ? colors.percentBar.negativeBk : colors.percentBar.positiveBk,
    [isNegative],
  );

  const barColor = useMemo(
    () =>
      isNegative ? colors.percentBar.negative : colors.percentBar.positive,
    [isNegative],
  );

  return (
    <div style={styles.container}>
      <span>{numberToLocaleString(value)}</span>
      <div style={{ ...styles.barContainer, background: backgroundColor }}>
        {value !== 0 && (
          <div style={{ ...styles.bar, width, background: barColor }} />
        )}
      </div>
    </div>
  );
};

export default PercentBar;
