import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import { MEDIA_UPDATE } from '@atom/graph/media';
import { DateTimePicker, Icon, IconButton, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { EventType } from '@atom/types/event';
import { MediaItem, MediaUpdateInput } from '@atom/types/media';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';
import {
  getDisplayDateTime,
  getUtcDisplayDate,
} from '@atom/utilities/timeUtilities';

import './carousel.css';

const SIDE_BAR_CLOSED_WIDTH = '-23.75rem';

const styles = {
  input: {
    color: colors.neutral.white,
  },
  editButton: {
    marginTop: '-5px',
  },
};

interface Props {
  sideBarOpen: boolean;
  onClose: () => void;
  selectedMedia: any;
  canUpdateMedia: boolean;
  refetchMedia?: () => void;
}

const InfoSideBar = ({
  sideBarOpen,
  onClose,
  selectedMedia,
  canUpdateMedia,
  refetchMedia = () => {},
}: Props) => {
  const [editDescription, setEditDescription] = useState<Boolean>(false);
  const [editCapturedDate, setEditCapturedDate] = useState<boolean>(false);
  const [capturedDate, setCapturedDate] = useState<Date>(
    selectedMedia.capturedDate ? new Date(selectedMedia.capturedDate) : null,
  );
  const [description, setDescription] = useState<String>(
    selectedMedia.description || '',
  );

  const [mediaUpdate] = useMutation<
    { mediaUpdate: MediaItem },
    { input: MediaUpdateInput }
  >(MEDIA_UPDATE);

  useEffect(() => {
    setEditDescription(false);
    setEditCapturedDate(false);
  }, [selectedMedia]);

  useEffect(() => {
    setDescription(selectedMedia.description || '');
    setEditDescription(false);
  }, [selectedMedia.description]);

  useEffect(() => {
    setCapturedDate(
      selectedMedia.capturedDate ? new Date(selectedMedia.capturedDate) : null,
    );
    setEditCapturedDate(false);
  }, [selectedMedia.capturedDate]);

  const updateMedia = async (property: keyof MediaUpdateInput, value: any) => {
    await mediaUpdate({
      variables: {
        input: {
          id: selectedMedia?.id,
          [property]: value,
        },
      },
    });

    setEditDescription(false);
    setEditCapturedDate(false);
    refetchMedia();
  };

  const sideBarStyle = {
    marginRight: sideBarOpen ? '0' : SIDE_BAR_CLOSED_WIDTH,
  };

  const updateDescription = (event: EventType) => {
    setDescription(event.target.value);
  };

  const onDescriptionSave = () => {
    updateMedia('description', description);
  };

  const onCapturedDateSave = () => {
    if (!capturedDate) {
      updateMedia('capturedDate', '');
    } else if (!Number.isNaN(capturedDate.valueOf())) {
      updateMedia('capturedDate', capturedDate.valueOf());
    }
  };

  const toggleCapturedDate = () => {
    setEditCapturedDate(!editCapturedDate);
    setEditDescription(false);
  };

  const toggleDescription = () => {
    setEditDescription(!editDescription);
    setEditCapturedDate(false);
  };

  const onCancel = () => {
    setDescription(selectedMedia.description);
    setCapturedDate(selectedMedia.capturedDate);

    setEditDescription(false);
    setEditCapturedDate(false);
  };

  return (
    <div styleName="side-bar-container" style={sideBarStyle}>
      <div styleName="side-bar-header">
        <div styleName="side-bar-header-title">Details</div>
        <IconButton size="small" tooltip="Close" onClick={onClose}>
          <Icon color={colors.neutral.white}>close</Icon>
        </IconButton>
      </div>
      <div styleName="side-bar-content-container">
        <div styleName="side-bar-content-row">
          <div styleName="side-bar-content label">Name:</div>
          <div styleName="side-bar-content value">{`${selectedMedia.name}.${selectedMedia.fileExtension}`}</div>
        </div>
        <div styleName="side-bar-content-row">
          <div styleName="side-bar-content label">Uploaded by:</div>
          <div styleName="side-bar-content value">
            {!R.isEmpty(selectedMedia.createdBy)
              ? textDisplayUtilities.displayName(selectedMedia.createdBy)
              : ''}
          </div>
        </div>
        <div styleName="side-bar-content-row">
          <div styleName="side-bar-content label">Uploaded on:</div>
          <div styleName="side-bar-content value">
            {getUtcDisplayDate(selectedMedia.createdDate)}
          </div>
        </div>
        <div styleName="side-bar-content-row">
          <div styleName="side-bar-content label">Captured on:</div>
          {editCapturedDate ? (
            <div styleName="side-bar-content value">
              <div styleName="input-container">
                <DateTimePicker
                  value={capturedDate}
                  onChange={date => setCapturedDate(date)}
                  InputProps={{ style: styles.input }}
                />
              </div>
              <div styleName="button-container">
                <div styleName="button cancel" onClick={onCancel}>
                  Cancel
                </div>
                <div styleName="button save" onClick={onCapturedDateSave}>
                  Save
                </div>
              </div>
            </div>
          ) : (
            <div styleName="description-row">
              {canUpdateMedia && (
                <IconButton
                  size="small"
                  style={styles.editButton}
                  onClick={toggleCapturedDate}
                >
                  <Icon color={colors.neutral.white}>edit</Icon>
                </IconButton>
              )}
              <span>
                {getDisplayDateTime(
                  capturedDate ? capturedDate.valueOf() : null,
                )}
              </span>
            </div>
          )}
        </div>
        <div styleName="side-bar-content-row">
          <div styleName="side-bar-content label">Description:</div>
          {editDescription ? (
            <div styleName="side-bar-content value">
              <div styleName="input-container">
                <TextField
                  value={description}
                  onChange={updateDescription}
                  multiline
                  variant="standard"
                  InputProps={{ style: styles.input }}
                />
              </div>
              <div styleName="button-container">
                <div styleName="button cancel" onClick={onCancel}>
                  Cancel
                </div>
                <div styleName="button save" onClick={onDescriptionSave}>
                  Save
                </div>
              </div>
            </div>
          ) : (
            <div styleName="description-row">
              {canUpdateMedia && (
                <IconButton
                  size="small"
                  style={styles.editButton}
                  onClick={toggleDescription}
                >
                  <Icon color={colors.neutral.white}>edit</Icon>
                </IconButton>
              )}
              <span>{description}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoSideBar;
