import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import UserContext from '@atom/components/userDetail/UserContext';
import { USER_UPDATE } from '@atom/graph/user';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { Switch } from '@atom/mui';
import { UserDetail, UserUpdateInput } from '@atom/types/user';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import {
  NOTIFICATION_OPTIONS,
  ReportOption,
} from '@atom/utilities/notificationUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './settingsTab.css';

const SettingsTab = () => {
  const { user } = useContext(UserContext);
  const userProfile = useUserProfile();

  const [updateUser, { loading }] = useMutation<
    { userUpdate: UserDetail },
    { input: UserUpdateInput }
  >(USER_UPDATE);

  const onToggle = (notification, toggled) => {
    const updatedNotifications = new Set([...user.notifications]);

    if (toggled) {
      updatedNotifications.add(notification);
    } else {
      updatedNotifications.delete(notification);
    }

    updateUser({
      variables: {
        input: {
          id: user.id,
          notifications: [...updatedNotifications],
        },
      },
    });
  };

  const canEdit =
    hasRolePermissions(ROLE_SETS.ORG_ADMIN) || userProfile.userId === user.id;

  const disabled = !canEdit || loading;

  const makeSwitchLabel = (option: ReportOption): React.ReactNode => (
    <div styleName="notification-label-container">
      <div>{option.label}</div>
      {!isNilOrEmpty(option.subheading) && (
        <div styleName="notification-subheading">{option.subheading}</div>
      )}
    </div>
  );

  const makeSwitch = (option: ReportOption): React.ReactNode => (
    <Switch
      disabled={disabled}
      name={option.value}
      label={makeSwitchLabel(option)}
      checked={user.notifications.includes(option.value)}
      onChange={(event, toggled) => onToggle(option.value, toggled)}
    />
  );

  return (
    <>
      <div styleName="header">Notifications</div>
      <div styleName="notifications-container">
        <div styleName="notifications-heading">
          Atom will send email notifications to me when:
        </div>
        {R.keys(NOTIFICATION_OPTIONS).map(type =>
          makeSwitch(NOTIFICATION_OPTIONS[type]),
        )}
      </div>
    </>
  );
};

export default SettingsTab;
