import * as R from 'ramda';

// eslint-disable-next-line valid-jsdoc
/**
 * lifted straight from StackOverflow
 * don't ask me how it works...
 * https://stackoverflow.com/questions/8667070/javascript-regular-expression-to-validate-url
 */
export const isValidUrl = (url: string) =>
  /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    url,
  );

export const isNilOrEmpty = (val: any) => R.or(R.isNil(val), R.isEmpty(val));

export const MAX_HYPERLINK_CHARS = 35;
export const VALIDATE_HYPERLINK_DEBOUNCE_MS = 500;
export const URL_ERROR_MSG = 'Please provide a valid URL';

// TODO: add specific error messages as needed
export const isValidUrlWithMessage = (str: string): [boolean, string] => {
  try {
    if (str !== '' && !R.test(/^https:\/\//, str)) {
      return [false, URL_ERROR_MSG];
    }

    const newUrl = new URL(str);
    // catch valid URL with no top-level-domain or empty domain/subdomain:
    const [extension, ...domain] = newUrl.hostname
      .split('.')
      .filter(segment => segment !== 'www')
      .reverse();
    if (
      !extension ||
      isNilOrEmpty(domain) ||
      domain.some(segment => !segment)
    ) {
      return [false, URL_ERROR_MSG];
    }
    return [true, ''];
  } catch (err) {
    if (str === '') {
      return [true, ''];
    }
    return [false, URL_ERROR_MSG];
  }
};

export const isNumericZero = (type: string, value: any): boolean =>
  ['number', 'currency'].includes(type) && value === 0;
