import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';

import { requestDownloadFiles } from '@atom/actions/fileActions';
import MediaTable from '@atom/components/common/mediaTable/MediaTable';
import MediaTableHeader from '@atom/components/common/mediaTable/MediaTableHeader';
import WorkOrderContext from '@atom/components/workOrderDetail/WorkOrderContext';
import textConstants from '@atom/constants/textConstants';
import {
  GET_MEDIA,
  MEDIA_BULK_DELETE,
  MEDIA_DELETE,
  MEDIA_UPDATE,
} from '@atom/graph/media';
import { useFileUpload } from '@atom/hooks/useFileUpload';
import { Snackbar } from '@atom/mui';
import {
  Media,
  MediaBulkDeleteInput,
  MediaType,
  MediaUpdateInput,
  SubjectType,
} from '@atom/types/media';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import './workOrderDocuments.css';

const WorkOrderDocuments = () => {
  const dispatch = useDispatch();
  const [uploadFiles] = useFileUpload();
  const { workOrderDetail, refetchMedia, setRefetchMedia } = useContext(
    WorkOrderContext,
  );

  const [media, setMedia] = useState<Media[]>([]);
  const [mediaTotal, setMediaTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(25);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set([]));

  const onCompleted = data => {
    setMedia(data?.media?.media);
    setMediaTotal(data?.media?.totalCount);
  };

  const { refetch, loading: loadingMedia } = useQuery(GET_MEDIA, {
    variables: {
      input: {
        parentSubjectIds: [
          workOrderDetail.id,
          ...R.keys(workOrderDetail.formInstances),
        ],
        type: MediaType.DOCUMENT,
        page,
        limit,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted,
  });

  useEffect(() => {
    if (refetchMedia) {
      refetch();
      setRefetchMedia(false);
    }
  }, [refetchMedia]);

  const [mediaUpdate, { loading: loadingUpdate }] = useMutation<
    { mediaUpdate: Media },
    { input: MediaUpdateInput }
  >(MEDIA_UPDATE);

  const [mediaDelete, { loading: loadingDelete }] = useMutation<
    { mediaDelete: boolean },
    { id: string }
  >(MEDIA_DELETE);

  const [mediaBulkDelete, { loading: loadingBulkDelete }] = useMutation<
    { mediaBulkDelete: boolean },
    { input: MediaBulkDeleteInput }
  >(MEDIA_BULK_DELETE);

  const toggleMedia = (id: string) => {
    setSelectedItems(toggleFromSet(selectedItems, id));
  };

  const resetToFirstPage = () => {
    if (page === 1) {
      refetch({
        input: {
          parentSubjectIds: [
            workOrderDetail.id,
            ...R.keys(workOrderDetail.formInstances),
          ],
          type: MediaType.DOCUMENT,
          page: 1,
          limit,
        },
      });
    } else {
      setPage(1);
    }
  };

  const handleUpload = async event => {
    const { files } = event.target;
    if (!files.length) {
      return;
    }

    await uploadFiles({
      files,
      subjectId: workOrderDetail.id,
      parentSubjectId: workOrderDetail.id,
      subjectType: SubjectType.WORK_ORDER,
    });

    refetch();
  };

  const renameMedia = async (id: string, name: string) => {
    const res = await mediaUpdate({
      variables: {
        input: {
          id,
          name,
        },
      },
    });

    const updatedMedia = media.map((mediaItem: Media) => {
      return mediaItem.id === id ? res?.data?.mediaUpdate : mediaItem;
    });

    setMedia(updatedMedia);
  };

  const deleteMedia = async (id: string) => {
    try {
      Snackbar.info({
        message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
      });

      await mediaDelete({
        variables: {
          id,
        },
      });

      Snackbar.info({
        message: 'Successfully deleted file',
      });

      setSelectedItems(new Set([]));
      resetToFirstPage();
    } catch (err) {
      Snackbar.error({
        message: 'Failed to delete file',
      });
    }
  };

  const downloadMultipleFiles = async () => {
    const selectedMedia = media.filter((medium: any) =>
      selectedItems.has(medium.id),
    );

    dispatch(requestDownloadFiles(selectedMedia));
  };

  const deleteMultipleMedia = async () => {
    await mediaBulkDelete({
      variables: {
        input: {
          ids: [...selectedItems],
        },
      },
    });

    setSelectedItems(new Set([]));
    resetToFirstPage();
  };

  const isEditDisabled =
    (workOrderDetail.isClosed &&
      doesNotHaveRolePermissions(ROLE_SETS.MANAGER)) ||
    doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  const loading =
    loadingMedia || loadingUpdate || loadingDelete || loadingBulkDelete;

  return (
    <div styleName="documents-container">
      <div styleName="center-pane">
        <MediaTableHeader
          type={MediaType.DOCUMENT}
          totalCount={mediaTotal}
          selectedItems={selectedItems}
          isEditDisabled={isEditDisabled}
          handleUpload={handleUpload}
          handleDownloadMultipleFiles={downloadMultipleFiles}
          handleDeleteMultipleMedia={deleteMultipleMedia}
          loading={loading}
        />
        <MediaTable
          media={media}
          totalCount={mediaTotal}
          page={page}
          limit={limit}
          selectedItems={selectedItems}
          setPage={setPage}
          setLimit={setLimit}
          toggleMedia={toggleMedia}
          isEditDisabled={isEditDisabled}
          handleDelete={deleteMedia}
          handleRename={renameMedia}
        />
      </div>
    </div>
  );
};

export default WorkOrderDocuments;
