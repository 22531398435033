// @ts-ignore
import analyticsImage from '@atom/components/common/svgIcons/analyticsPortal.svg';
// @ts-ignore
import budgetingImage from '@atom/components/common/svgIcons/budgetingPortal.svg';
// @ts-ignore
import dashboardImage from '@atom/components/common/svgIcons/dashboardPortal.svg';
// @ts-ignore
import inventoryImage from '@atom/components/common/svgIcons/inventoryPortal.svg';
// @ts-ignore
import mapImage from '@atom/components/common/svgIcons/mapPortal.svg';
// @ts-ignore
import scheduleImage from '@atom/components/common/svgIcons/scheduleImage.svg';
// @ts-ignore
import teamImage from '@atom/components/common/svgIcons/teamPortal.svg';
// @ts-ignore
import workOrdersImage from '@atom/components/common/svgIcons/workOrdersPortal.svg';
import { Portal } from '@atom/types/portals';
import { Preferences } from '@atom/types/preferences';

import { isNilOrEmpty } from './validationUtilities';

export const getPortals = (preferences: Preferences) => ({
  [Portal.ANALYTICS]: {
    urlPath: '/analytics/dashboard',
    image: analyticsImage,
    description: 'Analytics',
    dataCy: 'globalAnalytics',
  },
  [Portal.BUDGETING]: {
    urlPath: '/budgets',
    image: budgetingImage,
    description: 'Budgets',
    disabled: isNilOrEmpty(preferences.budgeting),
    dataCy: 'globalBudgeting',
  },
  [Portal.DASHBOARD]: {
    urlPath: '/dashboard',
    image: dashboardImage,
    description: 'Dashboard',
    dataCy: 'globalDashboard',
    disabled: false,
  },
  [Portal.INVENTORY]: {
    urlPath: '/inventory',
    image: inventoryImage,
    description: 'Inventory',
    dataCy: 'globalInventory',
  },
  [Portal.MAP]: {
    urlPath: '/map',
    image: mapImage,
    description: 'Map',
    dataCy: 'globalMap',
  },
  [Portal.SCHEDULE]: {
    urlPath: '/schedule',
    image: scheduleImage,
    description: 'Schedule',
    dataCy: 'globalSchedule',
  },
  [Portal.TEAM]: {
    urlPath: '/team',
    image: teamImage,
    description: 'Team',
    dataCy: 'globalTeam',
  },
  [Portal.WORK]: {
    urlPath: '/workOrders',
    image: workOrdersImage,
    description: 'Work',
    dataCy: 'globalWork',
  },
});
