import React, { useCallback, useContext, useEffect } from 'react';

import AddTeamModalContext from '@atom/components/common/workOrderDetail/addTeamModal/AddTeamModalContext';

import UsersList from './UsersList';

const UsersTab = () => {
  const {
    users,
    setPage,
    total,
    usersLoading,
    page,
    usersError,
    ref,
  } = useContext(AddTeamModalContext);

  const handleScroll = useCallback(() => {
    const isBottom =
      ref.current.scrollTop + ref.current.clientHeight + 0.5 >=
      ref.current.scrollHeight;

    if (isBottom && users.length !== total && !usersLoading) {
      setPage(currentPage => currentPage + 1);
    }
  }, [ref.current, total, page, users]);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('scroll', handleScroll);
      return () => ref.current?.removeEventListener('scroll', handleScroll);
    }

    return () => {};
  }, [handleScroll]);

  return <UsersList users={users} loading={usersLoading} errors={usersError} />;
};

export default UsersTab;
