import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as R from 'ramda';

import WorkOrderEnumMultipleField from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderEnumMultipleField';
import WorkOrderEnumSingleField from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderEnumSingleField';
import WorkOrdersFiltersDatePicker from '@atom/components/workOrders/workOrdersFilters/WorkOrdersFiltersDatePicker';
import WorkOrdersFiltersNumericRange from '@atom/components/workOrders/workOrdersFilters/WorkOrdersFiltersNumericRange';
import { GET_CUSTOM_FIELD_FILTERS } from '@atom/graph/workFilters';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Icon, IconButton } from '@atom/mui';
import { DataType } from '@atom/types/dataType';
import { FieldFilter, WorkOrderField } from '@atom/types/work';
import {
  convertDateToLocalDayEnd,
  convertDateToLocalDayStart,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import { AdditionalFieldFilters } from '../AssetWorkOrdersFilterPanel';

import './../assetWorkOrdersFilterPanel.css';

interface Props {
  areFiltersDisabled: boolean;
  taskCustomCommonFields: AdditionalFieldFilters;
  updateTaskCustomCommonFields: (
    customCommonFieldsUpdate: AdditionalFieldFilters,
  ) => void;
}

const TaskCustomFieldsFilter = ({
  areFiltersDisabled,
  taskCustomCommonFields,
  updateTaskCustomCommonFields,
}: Props) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const preferences = usePreferences();
  const customFieldFilterIds =
    preferences?.workOrders?.filtering?.tasks?.fields;

  const [
    getCustomFieldFilters,
    {
      data: { customFieldFilters: { customFieldFilters, totalCount } } = {
        customFieldFilters: { customFieldFilters: [], totalCount: 0 },
      },
      loading: customFiltersLoading,
    },
  ] = useLazyQuery(GET_CUSTOM_FIELD_FILTERS);

  useEffect(() => {
    if (!isNilOrEmpty(customFieldFilterIds)) {
      getCustomFieldFilters({
        variables: {
          input: {
            ids: customFieldFilterIds,
            page: 1,
            limit: 250,
          },
        },
      });
    }
  }, [customFieldFilterIds]);

  if (
    customFiltersLoading ||
    isNilOrEmpty(customFieldFilters) ||
    totalCount === 0
  ) {
    return null;
  }

  const updateFilterState = (
    id?: string,
    fieldProperty?: keyof FieldFilter,
    value?: number | string | string[],
  ) => {
    const updatedFieldFilters = {
      ...taskCustomCommonFields,
      [id]: {
        ...taskCustomCommonFields[id],
        id,
        [fieldProperty]: value,
      },
    };

    updateTaskCustomCommonFields(updatedFieldFilters);
  };

  const getField = (id?: string): FieldFilter | null => {
    const foundField: FieldFilter = taskCustomCommonFields[id];

    return foundField || null;
  };

  const getDateValue = (id?: string, fieldProperty?: keyof FieldFilter) => {
    const foundField = getField(id);

    if (foundField && !isNilOrEmpty(foundField[fieldProperty])) {
      return new Date(Number(foundField[fieldProperty]));
    }

    return null;
  };

  const handleDateChange = (id?: string, fieldProperty?: keyof FieldFilter) => (
    _,
    dateValue: Date,
  ) => {
    const dateMillis = dateValue
      ? fieldProperty === 'valueStart'
        ? convertDateToLocalDayStart(dateValue)
        : convertDateToLocalDayEnd(dateValue)
      : null;

    updateFilterState(id, fieldProperty, dateMillis);
  };

  const handleDateTimeChange = (
    id?: string,
    fieldProperty?: keyof FieldFilter,
  ) => (_, dateValue: Date) => {
    const dateMillis = dateValue ? dateValue.getTime() : null;

    updateFilterState(id, fieldProperty, dateMillis);
  };

  const handleSelectChange = (
    id: string,
    fieldProperty?: keyof FieldFilter,
  ) => (_, value: any) => {
    const sanitizedValue = R.isEmpty(value) ? null : value;
    updateFilterState(id, fieldProperty, sanitizedValue);
  };

  const handleNumericFieldChange = (
    id: string,
    fieldProperty?: keyof FieldFilter,
  ) => (_, value: any) => {
    const numValue = value === '' ? null : Number(value);

    updateFilterState(id, fieldProperty, numValue);
  };

  const getContent = (field: WorkOrderField) => {
    const components = {
      [DataType.DATE]: (
        <WorkOrdersFiltersDatePicker
          field={field}
          startDateValue={getDateValue(field.id, 'valueStart')}
          endDateValue={getDateValue(field.id, 'valueEnd')}
          onStartDateChange={handleDateChange(field.id, 'valueStart')}
          onEndDateChange={handleDateChange(field.id, 'valueEnd')}
          isEditable={!areFiltersDisabled}
        />
      ),
      [DataType.DATE_TIME]: (
        <WorkOrdersFiltersDatePicker
          field={field}
          startDateValue={getDateValue(field.id, 'valueStart')}
          endDateValue={getDateValue(field.id, 'valueEnd')}
          onStartDateChange={handleDateTimeChange(field.id, 'valueStart')}
          onEndDateChange={handleDateTimeChange(field.id, 'valueEnd')}
          isEditable={!areFiltersDisabled}
        />
      ),
      [DataType.ENUM_SINGLE]: (
        <WorkOrderEnumSingleField
          field={{ ...field, value: getField(field.id)?.value }}
          onChange={handleSelectChange(field.id, 'value')}
          boldLabel
          isDisabled={areFiltersDisabled}
        />
      ),
      [DataType.ENUM_MULTIPLE]: (
        <WorkOrderEnumMultipleField
          field={{ ...field, value: getField(field.id)?.values }}
          onChange={handleSelectChange(field.id, 'values')}
          isDisabled={areFiltersDisabled}
        />
      ),
      [DataType.NUMBER]: (
        <WorkOrdersFiltersNumericRange
          field={field}
          startValue={getField(field.id)?.valueStart}
          endValue={getField(field.id)?.valueEnd}
          onStartChange={handleNumericFieldChange(field.id, 'valueStart')}
          onEndChange={handleNumericFieldChange(field.id, 'valueEnd')}
          isEditable={areFiltersDisabled}
        />
      ),
    };

    return components[field.dataType];
  };

  return (
    <>
      <div styleName="header filter-section-header filter-container">
        <div>Common Fields</div>
        <IconButton onClick={() => setCollapsed(!collapsed)} edge="end">
          <Icon>{collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</Icon>
        </IconButton>
      </div>
      {!collapsed &&
        customFieldFilters?.map(field => (
          <div styleName="filter-container" key={field.id}>
            {getContent(field)}
          </div>
        ))}
    </>
  );
};

export default TaskCustomFieldsFilter;
