import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';

import WorkExportProjections from '@atom/components/common/workExport/WorkExportProjections';
import {
  EXPORT_CREATE,
  EXPORT_EVALUATE,
  GET_PROJECTION_OPTIONS,
} from '@atom/graph/export';
import { GET_WORK_ORDER_TEMPLATE } from '@atom/graph/workTemplate';
import { useWorkOrdersFilters } from '@atom/hooks/useWorkOrdersFilters';
import {
  Button,
  Icon,
  Modal,
  Progress,
  Snackbar,
  Switch,
  TextField,
} from '@atom/mui';
import {
  Export,
  ExportCreateInput,
  ExportEvaluateInput,
  ExportType,
  ProjectionOption,
  ProjectionOptionsConnection,
} from '@atom/types/export';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import WorkExportNotification from '../common/workExport/WorkExportNotification';

import './exportWorkModal.css';

interface Props {
  open: boolean;
  onClose: () => void;
}

const ExportWorkModal = ({ open, onClose }: Props) => {
  const { input, workExportFilters } = useWorkOrdersFilters();

  const [projections, setProjections] = useState<ProjectionOption[]>([]);
  const [filteredProjections, setFilteredProjections] = useState<
    ProjectionOption[]
  >([]);
  const [selectedProjections, setSelectedProjections] = useState<{
    [id: string]: Set<string>;
  }>({});

  const [saveAsWorkExport, setSaveAsWorkExport] = useState<boolean>(false);
  const [exportedMessage, setExportedMessage] = useState<string>('');
  const [workExportName, setWorkExportName] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [template, setTemplate] = useState<{ id: string; name: string }>(null);

  const [
    loadProjectionOptions,
    { data: projectionsData, loading: loadingProjections },
  ] = useLazyQuery<{
    projectionOptions: ProjectionOptionsConnection;
  }>(GET_PROJECTION_OPTIONS, {
    fetchPolicy: 'network-only',
  });

  const [
    getWorkOrderTemplate,
    { data: workOrderTemplateData, loading: loadingTemplate },
  ] = useLazyQuery<
    { workOrderTemplate: { id: string; name: string } },
    { id: string }
  >(GET_WORK_ORDER_TEMPLATE);

  useEffect(() => {
    if (workOrderTemplateData) {
      setTemplate(workOrderTemplateData.workOrderTemplate);
    }
  }, [workOrderTemplateData]);

  useEffect(() => {
    if (open) {
      loadProjectionOptions({
        variables: {
          input: {
            type: ExportType.WORK,
            filter: workExportFilters,
          },
        },
      });
    }
  }, [open]);

  useEffect(() => {
    const workTemplateIds = input?.workTemplateIds || [];

    if (open && workTemplateIds.length === 1) {
      getWorkOrderTemplate({
        variables: {
          id: workTemplateIds.join(''),
        },
      });
    } else if (workTemplateIds.length !== 1) {
      setTemplate(null);
    }
  }, [open, input.workTemplateIds]);

  useEffect(() => {
    const newProjections =
      projectionsData?.projectionOptions?.projectionOptions || [];
    setProjections(newProjections);
    setFilteredProjections(newProjections);
    setSelectedProjections(
      newProjections.reduce((acc, section) => {
        return {
          ...acc,
          [section.id]: new Set(section.availableFields),
        };
      }, {}),
    );
  }, [projectionsData]);

  useEffect(() => {
    const filtered = projections.map(projection => ({
      ...projection,
      availableFields: projection.availableFields.filter(field =>
        field.toLowerCase().includes(searchWord.toLowerCase()),
      ),
    }));
    setFilteredProjections(filtered);
  }, [searchWord, projections]);

  const handleToggle = (id: string, field: string) => {
    setSelectedProjections(
      R.over(
        R.lensPath([id]),
        selected =>
          selected ? toggleFromSet(selected, field) : new Set([field]),
        selectedProjections,
      ),
    );
  };

  const handleSectionToggle = (id: string, fieldLength: number) => {
    setSelectedProjections(
      selectedProjections[id]?.size === fieldLength
        ? R.omit([id], selectedProjections)
        : {
            ...selectedProjections,
            [id]: new Set(
              projections.find(section => section.id === id).availableFields,
            ),
          },
    );
  };

  const isSelected = Object.values(selectedProjections)?.filter(
    projection => projection.size > 0,
  )?.length;

  const handleSelectAll = () => {
    setSelectedProjections(
      isSelected
        ? filteredProjections.reduce((acc, section) => {
            return {
              ...acc,
              [section.id]: new Set([]),
            };
          }, {})
        : filteredProjections.reduce((acc, section) => {
            return {
              ...acc,
              [section.id]: new Set(section.availableFields),
            };
          }, {}),
    );
  };

  const resetState = () => {
    setExportedMessage('');
    setWorkExportName('');
    setSaveAsWorkExport(false);
    setSearchWord('');
  };

  const [createExport, { loading: loadingCreate }] = useMutation<
    { exportCreate: Export },
    { input: ExportCreateInput }
  >(EXPORT_CREATE);

  const [evaluateExport, { loading: loadingEvaluate }] = useMutation<
    { exportCreate: Export },
    { input: ExportEvaluateInput }
  >(EXPORT_EVALUATE);

  const getProjectionsForInput = () =>
    projections
      .filter(projection => !!selectedProjections[projection.id])
      .map(projection => ({
        id: projection.id,
        name: projection.name,
        selectedFields: [...selectedProjections[projection.id]],
      }));

  const handleSave = async () => {
    try {
      await createExport({
        variables: {
          input: {
            name: workExportName,
            type: ExportType.WORK,
            filter: workExportFilters,
            projections: getProjectionsForInput(),
          },
        },
      });

      Snackbar.info({
        message: `Saved ${workExportName}`,
      });

      onClose();
    } catch (err) {
      Snackbar.error({
        message: `Save ${workExportName} Failed`,
      });
    }
  };

  const handleExport = async () => {
    try {
      await evaluateExport({
        variables: {
          input: {
            exportId: '',
            inline: {
              save: saveAsWorkExport,
              export: {
                name: workExportName,
                type: ExportType.WORK,
                filter: workExportFilters,
                projections: getProjectionsForInput(),
              },
            },
          },
        },
      });

      setExportedMessage(
        saveAsWorkExport
          ? 'Export is saved and in progress!'
          : 'Export is in progress!',
      );
    } catch (err) {
      Snackbar.error({
        message: `Save and export ${workExportName} Failed`,
      });
    }
  };

  const loading = loadingProjections || loadingCreate || loadingEvaluate;

  const renderFooter = exportedMessage ? (
    <Button onClick={() => onClose()}>OK</Button>
  ) : saveAsWorkExport ? (
    <>
      <Button onClick={onClose} disabled={loading}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        disabled={loading || !workExportName}
      >
        Save
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleExport}
        disabled={loading || !workExportName}
      >
        Save & Export
      </Button>
    </>
  ) : (
    <>
      <Button onClick={onClose} disabled={loading}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleExport}
        disabled={loading}
      >
        Export
      </Button>
    </>
  );

  return (
    <>
      <Modal
        title={exportedMessage ? 'Export in Progress' : 'Export Work'}
        open={open}
        confirmButtonText="Export"
        footer={renderFooter}
        loading={loading}
        onExited={resetState}
      >
        {exportedMessage ? (
          <WorkExportNotification message={exportedMessage} />
        ) : (
          <>
            <div>
              {template && !loadingTemplate ? (
                <span>
                  Customize columns for work template{' '}
                  <strong>{template.name}</strong>
                </span>
              ) : (
                'Customize columns'
              )}
            </div>
            <div styleName="search-box">
              <div styleName="search-box-spacing">
                <div styleName="search-input">
                  <Icon>search</Icon>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="Search data"
                    value={searchWord}
                    onChange={event => setSearchWord(event.target.value)}
                  />
                </div>

                <div styleName="select-all-button-container">
                  <div styleName="select-all-button" onClick={handleSelectAll}>
                    {isSelected ? 'Deselect All' : 'Select All'}
                  </div>
                  {!!input?.workTemplateIds?.length && (
                    <div styleName="custom-fields-description">
                      * denote custom fields
                    </div>
                  )}
                </div>
                {loadingProjections ? (
                  <div styleName="progress">
                    <Progress style={{ flex: 1 }} />
                  </div>
                ) : (
                  filteredProjections.map((filteredProjection, index) => (
                    <WorkExportProjections
                      key={index}
                      projection={filteredProjection}
                      filterCounter={
                        projections[index].availableFields.length -
                        filteredProjection.availableFields.length
                      }
                      fields={filteredProjection.availableFields}
                      customFields={filteredProjection.customFields}
                      fieldLength={filteredProjection.availableFields.length}
                      selectedProjections={selectedProjections}
                      handleToggle={handleToggle}
                      handleSectionToggle={handleSectionToggle}
                    />
                  ))
                )}
              </div>
            </div>
            <div style={{ padding: '0.5rem 0 0.5rem 0' }}>
              <Switch
                checked={saveAsWorkExport}
                onChange={() => setSaveAsWorkExport(!saveAsWorkExport)}
                name="saveAsWorkExport"
                label="Save as work export"
              />
            </div>
            {saveAsWorkExport && (
              <div style={{ padding: '0 0 0.5rem 2.75rem' }}>
                <TextField
                  autoComplete="off"
                  style={{ width: '18.5rem' }}
                  label="Name"
                  value={workExportName}
                  onChange={event => setWorkExportName(event.target.value)}
                />
              </div>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default ExportWorkModal;
