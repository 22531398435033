import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { StringParam, useQueryParams } from 'use-query-params';

import AssetSummaryTray from '@atom/components/mapPortal/assetSummary/AssetSummaryTray';
import { GET_ASSET } from '@atom/graph/asset';
import client from '@atom/graph/client';
import api from '@atom/utilities/api';
import { INVENTORY_ASSETS_ENDPOINT } from '@atom/utilities/endpoints';

import { useWorkOrdersData } from '../hooks/workOrdersDataHook';

const AssetSummaryWrapper = () => {
  const [params, setParams] = useQueryParams({
    view: StringParam,
    id: StringParam,
  });

  const [loadingRename, setLoadingRename] = useState<boolean>(false);

  const [getAsset, { loading: assetLoading, data, refetch }] = useLazyQuery(
    GET_ASSET,
  );

  const [workOrders, workOrdersLoading] = useWorkOrdersData({
    assetId: params.id,
    statusIds: '0,1,2,3,4,5',
    sortBy: 'status',
  });

  useEffect(() => {
    if (!params.id.includes(',')) {
      getAsset({ variables: { id: params.id } });
    }
  }, [params.id]);

  const renameInventory = async (name: string) => {
    setLoadingRename(true);

    await api.patch(`${INVENTORY_ASSETS_ENDPOINT}/${params.id}`, { name });

    client.cache.modify({
      id: client.cache.identify(data.asset),
      fields: {
        name: () => name,
      },
    });

    setLoadingRename(false);
  };

  const onCancel = () => {
    setParams({
      view: 'list',
      id: '',
    });
  };

  const loading = (assetLoading && workOrdersLoading) || !data;

  return (
    <AssetSummaryTray
      asset={data?.asset}
      refetch={refetch}
      loading={loading}
      loadingRename={loadingRename}
      workOrders={workOrders}
      renameInventory={renameInventory}
      onCancel={onCancel}
    />
  );
};

export default AssetSummaryWrapper;
