import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import { CREATE_ROLE } from '@atom/graph/role';
import { Icon, Modal, Snackbar, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { Role, RoleCreateInput } from '@atom/types/role';
import history from '@atom/utilities/history';

import './rolesTable.css';

interface Props {
  open: boolean;
  onClose: (refetch: boolean) => void;
}

const HTTP_STATUS_CONFLICT = 409;

const styles = {
  modal: {
    padding: '1rem 2rem',
  },
  input: {
    margin: '1rem 0 0 0',
  },
  icon: {
    marginRight: '2rem',
    marginTop: '2rem',
  },
  characterCount: {
    fontSize: '0.75rem',
    float: 'right',
  },
};

const CreateRoleModal = ({ open, onClose }: Props) => {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');

  const [createRole, { loading }] = useMutation<
    { roleCreate: Role },
    { input: RoleCreateInput }
  >(CREATE_ROLE);

  const resetState = () => {
    setName('');
    setDescription('');
    setErrorText('');
  };

  const isValid = () => {
    return Boolean(name && description);
  };

  const handleConfirm = async () => {
    try {
      const res = await createRole({
        variables: {
          input: { name, description },
        },
      });

      onClose(true);

      Snackbar.info({
        message: `Created role "${name}"`,
        action: 'View',
        onActionClick: () =>
          history.push(`/team/role/${res?.data?.roleCreate?.id}`),
      });
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_STATUS_CONFLICT) {
        setErrorText('This name is already in use.');
      }
    }
  };

  const countStyle = (inputLength, maxLength) =>
    inputLength > maxLength ? { color: colors.brand.red } : {};

  const characterLimitReached = name.length > 100 || description.length > 200;

  return (
    <Modal
      title="Create Role"
      open={open}
      onCancel={() => onClose(false)}
      onConfirm={handleConfirm}
      loading={loading}
      onExited={resetState}
      disabled={!isValid() || characterLimitReached}
      confirmButtonText="Create"
    >
      <div styleName="modal-row">
        <Icon style={styles.icon}>
          <img src={renameIcon} />
        </Icon>
        <div style={{ width: '100%' }}>
          <TextField
            name="name"
            label="Name"
            value={name}
            onChange={event => setName(event.target.value)}
            error={!!errorText}
            helperText={errorText}
            style={styles.input}
          />
          <div style={styles.characterCount}>
            <span style={countStyle(name.length, 100)}>{name.length}/100</span>
          </div>
        </div>
      </div>
      <div styleName="modal-row">
        <Icon style={styles.icon}>subject</Icon>
        <div style={{ width: '100%' }}>
          <TextField
            multiline
            name="description"
            label="Description"
            value={description}
            onChange={event => setDescription(event.target.value)}
            style={styles.input}
          />
          <div style={styles.characterCount}>
            <span style={countStyle(description.length, 200)}>
              {description.length}/200
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateRoleModal;
