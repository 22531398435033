import React from 'react';

// @ts-ignore
import timeEntryStatusApproved from '@atom/components/common/svgIcons/timeEntryStatusApproved.svg';
// @ts-ignore
import timeEntryStatusDraft from '@atom/components/common/svgIcons/timeEntryStatusDraft.svg';
// @ts-ignore
import timeEntryStatusPending from '@atom/components/common/svgIcons/timeEntryStatusPending.svg';
// @ts-ignore
import timeEntryStatusRejected from '@atom/components/common/svgIcons/timeEntryStatusRejected.svg';
import { TimeEntryStatus } from '@atom/types/timeEntry';

interface Props {
  status: TimeEntryStatus;
}

const getTimeEntryStatusIcon = (status: TimeEntryStatus) => {
  const data = {
    [TimeEntryStatus.draft]: timeEntryStatusDraft,
    [TimeEntryStatus.pending]: timeEntryStatusPending,
    [TimeEntryStatus.approved]: timeEntryStatusApproved,
    [TimeEntryStatus.rejected]: timeEntryStatusRejected,
  };

  return data[status];
};

const TimeEntryStatusIcon = ({ status }: Props) => {
  const icon = getTimeEntryStatusIcon(status);

  return <img src={icon} />;
};

export default TimeEntryStatusIcon;
