import React from 'react';

import { getUtcDisplayDate } from '@atom/utilities/timeUtilities';

import { Inspection, Overview } from './GwrrWorkOrderPdfReport';

import '../workOrderDetailPdfReport.css';

const OVERVIEW_MAP: { [key in string]: string } = {
  region: 'Region',
  division: 'Division',
  railroad: 'Railroad',
  subdivision: 'Sub-Division',
  assetName: 'Asset Name',
  latitude: 'Latitude',
  longitude: 'Longitude',
};

const INSPECTION_MAP: { [key in string]: string } = {
  workName: 'Work Name',
  dueDate: 'Due Date',
  completedDate: 'Completed Date',
  completedBy: 'Completed By',
  closedDate: 'Closed Date',
  closedBy: 'Closed By',
  leadInspector: 'Lead Inspector',
  otherPersonnel: 'Other Personnel',
};

interface Props {
  header: string;
  data: Inspection | Overview;
  labelWidth?: React.CSSProperties;
}

const getLabels = header => {
  switch (header) {
    case 'Overview':
      return OVERVIEW_MAP;
    case 'Inspection':
      return INSPECTION_MAP;
    default:
      return '';
  }
};

const CustomTextbox = ({ header, data, labelWidth }: Props) => {
  const renderTextRow = () => {
    const labels = getLabels(header);
    return Object.keys(labels).map(key => {
      const label = labels[key];
      const isDateKey =
        key === 'dueDate' || key === 'closedDate' || key === 'completedDate';
      const value = isDateKey ? getUtcDisplayDate(data[key]) : data[key];

      return (
        <div styleName="row">
          <div styleName="value" style={labelWidth}>
            {label}:
          </div>
          <div styleName="value">{value}</div>
        </div>
      );
    });
  };

  return (
    <>
      <div styleName="header-title">{header}</div>
      <div styleName="content">{renderTextRow()}</div>
    </>
  );
};

export default CustomTextbox;
