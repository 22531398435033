import React from 'react';

import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import { Tab } from '../../../BulkContext';

import CustomFields from './CustomFields';
import SummaryPane from './SummaryPane';

import '../body.css';

export interface Props {
  tab: Tab;
}

const WorkInfoBody = ({ tab }: Props) => {
  const showFields = hasRolePermissions(ROLE_SETS.INSPECTOR);

  return (
    <div styleName="body">
      <div styleName="body-header">
        <div styleName="body-header-title">{tab.label}</div>
      </div>
      <div styleName="body-content">
        <SummaryPane />
        {showFields && <CustomFields />}
      </div>
    </div>
  );
};

export default WorkInfoBody;
