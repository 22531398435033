import React from 'react';

import { BudgetUnitSearch, BudgetUnitTree } from '@atom/types/budget';

interface Context {
  searchResults: BudgetUnitSearch[];
  setSearchResults: React.Dispatch<React.SetStateAction<BudgetUnitSearch[]>>;
  query: string;
  setQuery: (query: string) => void;
  collapsed: Set<string>;
  setCollapsed: React.Dispatch<React.SetStateAction<Set<string>>>;
  selectedUnitIds: Set<string>;
  handleChange: (unit: BudgetUnitTree) => void;
  allUnits: BudgetUnitTree[];
}

export const initialState: Context = {
  searchResults: [],
  setSearchResults: () => {},
  query: '',
  setQuery: () => {},
  collapsed: new Set(),
  setCollapsed: () => {},
  selectedUnitIds: new Set(),
  handleChange: () => {},
  allUnits: [],
};

const BudgetingUnitSelectionContext = React.createContext<Context>(
  initialState,
);

export default BudgetingUnitSelectionContext;
