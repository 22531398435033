import React, { useCallback, useState } from 'react';
import { DragDrop } from 'react-beautiful-dnd';
import { useMutation } from '@apollo/client';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import {
  DragDropContext,
  Draggable,
  Droppable,
} from '@atom/components/common/dragAndDrop';
import {
  WORK_ORDER_FIELD_CREATE,
  WORK_ORDER_FIELD_DELETE,
  WORK_ORDER_FIELD_UPDATE,
  WORK_ORDER_UPDATE,
} from '@atom/graph/work';
import { Button, Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import { DataType } from '@atom/types/dataType';
import {
  WorkOrderDetailType,
  WorkOrderField,
  WorkOrderFieldCreateInput,
  WorkOrderFieldCreatePayload,
  WorkOrderFieldDeleteInput,
  WorkOrderFieldUpdateInput,
  WorkOrderFieldUpdatePayload,
  WorkOrderUpdate,
  WorkOrderUpdateInput,
} from '@atom/types/work';
import {
  doesNotHaveRolePermissions,
  hasRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { addToSet, removeFromSet } from '@atom/utilities/setUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import WorkOrderDatePicker from './WorkOrderDatePicker';
import WorkOrderEnumMultipleField from './WorkOrderEnumMultipleField';
import WorkOrderEnumSingleField from './WorkOrderEnumSingleField';
import WorkOrderFieldCreateModal from './WorkOrderFieldCreateModal';
import WorkOrderFieldMenu from './WorkOrderFieldMenu';
import WorkOrderFieldUpdateModal from './WorkOrderFieldUpdateModal';
import WorkOrderHyperlinkField from './WorkOrderHyperlinkField';
import WorkOrderNumericField from './WorkOrderNumericField';
import WorkOrderSummary from './WorkOrderSummary';
import WorkOrderTextField from './WorkOrderTextField';

import './workOrderFields.css';

const styles = {
  icon: {
    marginRight: '5px',
  },
  addButton: {
    marginLeft: '2rem',
  },
};

const DEBOUNCE_TIME = 1000;
const DROPPABLE_ID = 'fields';

export interface Props {
  workOrderDetail: WorkOrderDetailType;
  dispatch: any;
  WorkOrderActionTypes: any;
}

const WorkOrderFields = ({
  workOrderDetail,
  dispatch,
  WorkOrderActionTypes,
}: Props) => {
  const [selectedField, setSelectedField] = useState<WorkOrderField>(null);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [fieldsLoading, setFieldsLoading] = useState<Set<string>>(new Set());
  const [hoveredField, setHoveredField] = useState<string>();
  const [fieldOrder, setFieldOrder] = useState<string[]>(
    workOrderDetail.fieldOrder,
  );

  const [createWorkOrderField, { loading: loadingCreate }] = useMutation<
    { workOrderFieldCreate: WorkOrderField },
    { input: WorkOrderFieldCreateInput }
  >(WORK_ORDER_FIELD_CREATE);

  const [updateField, { loading: loadingUpdate }] = useMutation<
    { workOrderFieldUpdate: WorkOrderField },
    { input: WorkOrderFieldUpdateInput }
  >(WORK_ORDER_FIELD_UPDATE);

  const [deleteField, { loading: loadingDelete }] = useMutation<
    { workOrderFieldDelete: boolean },
    { input: WorkOrderFieldDeleteInput }
  >(WORK_ORDER_FIELD_DELETE);

  const [updateWorkDetail] = useMutation<
    { workOrderUpdate: WorkOrderUpdate },
    { workOrder: WorkOrderUpdateInput }
  >(WORK_ORDER_UPDATE);

  const handleEditClick = (field: WorkOrderField) => {
    setSelectedField(field);
    setEditModalOpen(true);
  };

  const handleDeleteClick = (field: WorkOrderField) => {
    setSelectedField(field);
    setDeleteModalOpen(true);
  };

  const handleAddField = (field: WorkOrderField) => {
    dispatch({
      type: WorkOrderActionTypes.ADD_WORK_ORDER_FIELD,
      data: {
        field,
      },
    });

    setFieldOrder([...fieldOrder, field.id]);
  };

  const handleEditField = (field: WorkOrderField) => {
    dispatch({
      type: WorkOrderActionTypes.UPDATE_WORK_ORDER_FIELD,
      data: {
        field,
      },
    });
  };

  const handleEditFieldValue = (field: WorkOrderField) => {
    dispatch({
      type: WorkOrderActionTypes.UPDATE_WORK_ORDER_FIELD_VALUE,
      data: {
        field,
      },
    });
  };

  const handleChange = useCallback(
    async (fieldId: string, value: any) => {
      setFieldsLoading(loading => addToSet(loading, fieldId));

      const res = await updateField({
        variables: {
          input: {
            workOrderId: workOrderDetail.id,
            fieldId,
            value,
          },
        },
      });

      const field = res?.data?.workOrderFieldUpdate;

      if (field.subFieldsTruncated) {
        handleEditField(field);
      } else {
        handleEditFieldValue(field);
      }

      setFieldsLoading(loading => removeFromSet(loading, fieldId));
    },
    [workOrderDetail.id],
  );

  const handleChangeDebounced = useCallback(
    debounce(handleChange, DEBOUNCE_TIME),
    [handleChange],
  );

  const handleDeleteConfirm = async () => {
    const fieldOrderIndex = R.indexOf(selectedField.id, fieldOrder);
    const newFieldOrder = R.remove(fieldOrderIndex, 1, fieldOrder);
    setFieldOrder(newFieldOrder);

    const res = await deleteField({
      variables: {
        input: {
          workOrderId: workOrderDetail.id,
          fieldId: selectedField.id,
        },
      },
    });

    if (res?.data?.workOrderFieldDelete) {
      setSelectedField(null);
      setDeleteModalOpen(false);
      dispatch({
        type: WorkOrderActionTypes.DELETE_WORK_ORDER_FIELD,
        data: {
          fieldId: selectedField.id,
        },
      });
    }
  };

  const handleCreate = async (payload: WorkOrderFieldCreatePayload) => {
    const res = await createWorkOrderField({
      variables: {
        input: {
          workOrderId: workOrderDetail.id,
          ...payload,
        },
      },
    });

    handleAddField(res?.data?.workOrderFieldCreate);
    setCreateModalOpen(false);
  };

  const onDragEnd = (result: DragDrop) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const newFieldOrder = R.move(source.index, destination.index, fieldOrder);
    setFieldOrder(newFieldOrder);

    updateWorkDetail({
      variables: {
        workOrder: {
          id: workOrderDetail.id,
          fieldOrder: newFieldOrder,
        },
      },
    });

    dispatch({
      type: WorkOrderActionTypes.UPDATE_WORK_ORDER_PROPERTY,
      data: {
        property: 'fieldOrder',
        value: newFieldOrder,
      },
    });
  };

  const handleUpdate = async (payload: WorkOrderFieldUpdatePayload) => {
    const res = await updateField({
      variables: {
        input: {
          workOrderId: workOrderDetail.id,
          ...payload,
        },
      },
    });

    handleEditField(res?.data?.workOrderFieldUpdate);
    setEditModalOpen(false);
  };

  const showButton =
    hasRolePermissions(ROLE_SETS.INSPECTOR) &&
    !workOrderDetail.isClosed &&
    !workOrderDetail.workTemplateId;

  const isFieldEditDisabled =
    doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR) || workOrderDetail.isClosed;

  const getContent = (field: WorkOrderField) => {
    const props = {
      field,
      fieldsLoading,
      onChange: handleChange,
      isDisabled: isFieldEditDisabled,
      onBlur: handleChange,
    };

    const hasSubFields = !isNilOrEmpty(field.subFields);

    const components = {
      [DataType.SUMMARY]: <WorkOrderSummary {...props} />,
      [DataType.SHORT_TEXT]: (
        <WorkOrderTextField {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.LONG_TEXT]: (
        <WorkOrderTextField {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.DATE]: (
        <WorkOrderDatePicker {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.DATE_TIME]: (
        <WorkOrderDatePicker {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.ENUM_SINGLE]: hasSubFields ? (
        <div styleName="enum-single-nested-container">
          <WorkOrderEnumSingleField
            {...props}
            onChange={handleChangeDebounced}
          />
        </div>
      ) : (
        <WorkOrderEnumSingleField {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.ENUM_MULTIPLE]: <WorkOrderEnumMultipleField {...props} />,
      [DataType.NUMBER]: (
        <WorkOrderNumericField {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.HYPERLINK]: <WorkOrderHyperlinkField {...props} />,
    };

    const iconColor =
      hoveredField === field.id ? colors.neutral.ash : colors.neutral.white;

    return (
      <div
        styleName="field-drag-container"
        onMouseEnter={() => setHoveredField(field.id)}
        onMouseDown={() => setHoveredField(field.id)}
        onMouseUp={() => setHoveredField(null)}
      >
        <Icon color={iconColor} style={styles.icon}>
          drag_indicator
        </Icon>
        {components[field.dataType]}
        {showButton && (
          <WorkOrderFieldMenu
            field={field}
            isWorkTemplate={!!workOrderDetail.workTemplateId}
            onEdit={handleEditClick}
            onDelete={handleDeleteClick}
          />
        )}
      </div>
    );
  };

  const isDragDisabled =
    workOrderDetail?.isClosed || !isNilOrEmpty(workOrderDetail?.workTemplateId);
  const hideSection = !showButton && isNilOrEmpty(workOrderDetail.fields);
  const containerStyle = hideSection
    ? 'section-content hide'
    : 'section-content fields';

  return (
    <>
      <div
        styleName={containerStyle}
        onMouseLeave={() => setHoveredField(null)}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={DROPPABLE_ID}>
            {fieldOrder.map((fieldId: string, index: number) => {
              const field = workOrderDetail.fields.find(
                item => item.id === fieldId,
              );

              return (
                <Draggable
                  key={`${field.title}-${index}`}
                  draggableId={field.id}
                  isDragDisabled={isDragDisabled}
                  index={index}
                >
                  {getContent(field)}
                </Draggable>
              );
            })}
          </Droppable>
        </DragDropContext>
        {showButton && (
          <Button
            color="primary"
            style={styles.addButton}
            startIcon={<Icon color={colors.brand.blue}>add</Icon>}
            onClick={() => setCreateModalOpen(true)}
          >
            Add Field
          </Button>
        )}
      </div>
      <WorkOrderFieldCreateModal
        fields={workOrderDetail?.fields}
        handleCreate={handleCreate}
        loadingCreate={loadingCreate}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />
      <WorkOrderFieldUpdateModal
        fields={workOrderDetail?.fields}
        handleUpdate={handleUpdate}
        loadingUpdate={loadingUpdate}
        isTitleDisabled={!!workOrderDetail.workTemplateId}
        onClose={() => setEditModalOpen(false)}
        field={selectedField}
        open={editModalOpen}
      />
      <DeleteModal
        open={deleteModalOpen}
        loading={loadingDelete}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteConfirm}
        title="Delete Field"
        content="Are you sure you want to delete this field?"
      />
    </>
  );
};

export default WorkOrderFields;
