import { gql } from '@apollo/client';

export const BASE_ASSET = gql`
  fragment BaseAsset on Asset {
    id
    name

    address
    externalId
    categoryId
    assetType
    creationType
    schemaId
    parentAssetId
    colorId
    markerId
    isMaterial
    mainPhotoFileId
    inactive
    ancestors {
      id
      name
    }

    changeType
    includeChangesBefore

    lastAppliedWorkOrderName
    lastAppliedDate
    lastWorkOrder {
      id
      name
      updatedDate
    }

    location {
      coordinates
      type
    }
    locationType

    attributeGroups {
      id
      name
      attributes {
        id
        name
        value
        newValue
        changeId
        defaultValue
        enumeration
        isEditable
        isRequired
        unit
        group
        order
        dataType
      }
    }

    budget {
      isFixed
      isTimeBased
      isStartEndReading
      isStartEndCalculated
      rate
      unit
    }

    createdDate
    createdBy {
      firstName
      lastName
    }
    updatedDate
    updatedBy {
      firstName
      lastName
    }

    actions
    grants {
      type
      ids
    }
  }
`;

export const GET_ASSET = gql`
  query asset($id: ID!) {
    asset(id: $id) {
      ...BaseAsset
    }
  }
  ${BASE_ASSET}
`;

export const GET_ASSETS = gql`
  query assets($input: AssetsConnectionInput!) {
    assets(input: $input) {
      totalCount
      assets {
        id
        name
        assetType
        schemaId
        colorId
        markerId
        mainPhotoFileId
        attributes
        location {
          coordinates
          type
        }
        createdDate
        createdBy {
          firstName
          lastName
        }
        updatedDate
        updatedBy {
          firstName
          lastName
        }
      }
    }
  }
`;

export const SEARCH_ASSETS = gql`
  query assetsSearch($input: SearchAssetsConnectionInput!) {
    assetsSearch(input: $input) {
      totalCount
      assets {
        id
        schemaId
        externalId
        name
        assetType
        markerId
        colorId
        categoryPath
        isMaterial
        inactive
      }
    }
  }
`;

export const CREATE_ASSET = gql`
  mutation assetCreate($input: AssetCreateInput!) {
    assetCreate(input: $input) {
      ...BaseAsset
    }
  }
  ${BASE_ASSET}
`;
