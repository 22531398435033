import * as React from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import history from '@atom/utilities/history';

import CreateFormModal from './CreateFormModal';

import '../../styles/detail-header.css';

function navigateToWorkOrders() {
  history.goBackWithState('/workOrders');
}

const RepositoryHeader = () => {
  return (
    <div styleName="header-container">
      <div styleName="name-container">
        <IconButton onClick={navigateToWorkOrders} tooltip="Go back">
          <Icon color={colors.neutral.white}>arrow_back</Icon>
        </IconButton>
        <span>Work Forms</span>
      </div>
      <div styleName="action-icon-container">
        {hasRolePermissions(ROLE_SETS.MANAGER) && <CreateFormModal />}
      </div>
    </div>
  );
};

export default RepositoryHeader;
