import React from 'react';
import {
  DragDrop,
  DragDropContext as RBDragDropContext,
} from 'react-beautiful-dnd';

interface Props {
  /**
   * A handler that is triggered when a downstream <Draggble />
   * has been dragged and dropped within a <Droppable />
   */
  onDragEnd: (result: DragDrop) => void;
  children: React.ReactNode;
}

// A <DragDropContext /> contains <Droppable /> and <Draggable />

export const DragDropContext = ({ onDragEnd, children }: Props) => {
  return (
    <RBDragDropContext onDragEnd={onDragEnd}> {children}</RBDragDropContext>
  );
};
