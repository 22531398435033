import * as React from 'react';

import TextField from '@atom/components/common/TextField';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { EventType } from '@atom/types/event';

import '../../formInstance.css';

const styles = {
  inputStyles: {
    fontSize: fonts.md,
    color: colors.neutral.dark,
  },
};

interface PassedProps {
  type: string;
  value: any;
  unit: string;
  isEditable: boolean;
  onChange: (event: EventType) => void;
}

type Props = PassedProps;

const NumericAttribute = ({
  type,
  value,
  onChange,
  unit,
  isEditable,
}: Props) => {
  return (
    <div styleName={`attribute-input${type === 'currency' ? ' currency' : ''}`}>
      <TextField
        name={type}
        style={styles.inputStyles}
        fullWidth
        onChange={onChange}
        value={value}
        type="number"
        disabled={!isEditable}
      />
      {type === 'number' && <div styleName="unit-text">{unit}</div>}
    </div>
  );
};

export default NumericAttribute;
