import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import WorkTemplateContext, {
  WorkTemplateActionTypes,
} from '@atom/components/workTemplate/WorkTemplateContext';
import {
  getInheritedTypeTitle,
  taskInheritedTypes,
  workInheritedTypes,
} from '@atom/components/workTemplate/WorkTemplateInheritedTypes';
import { WORK_TEMPLATE_UPDATE } from '@atom/graph/workTemplate';
import { Modal, Switch } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  InheritedComponentType,
  WorkOrderTemplateUpdateInput,
  WorkTemplate,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import SettingsTitle from './SettingsTitle';

import './workTemplateSettings.css';

const styles = {
  toggleStyle: {
    color: colors.neutral.black,
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
}

const WorkTemplateSettings = ({ open, onClose }: Props) => {
  const { workTemplate, dispatch } = useContext(WorkTemplateContext);

  const [
    requiredInheritedComponents,
    setRequiredInheritedComponents,
  ] = useState<Set<InheritedComponentType>>(
    new Set(workTemplate?.requiredInheritedComponents || []),
  );

  useEffect(() => {
    if (open) {
      setRequiredInheritedComponents(
        new Set(workTemplate?.requiredInheritedComponents || []),
      );
    }
  }, [open, workTemplate]);

  const [updateWorkTemplate] = useMutation<
    { workOrderTemplateUpdate: WorkTemplate },
    { input: WorkOrderTemplateUpdateInput }
  >(WORK_TEMPLATE_UPDATE);

  const onSave = async () => {
    const { data } = await updateWorkTemplate({
      variables: {
        input: {
          workOrderTemplateId: workTemplate.id,
          requiredInheritedComponents: [...requiredInheritedComponents],
        },
      },
    });

    dispatch({
      type: WorkTemplateActionTypes.UPDATE_WORK_TEMPLATE_PROPERTY,
      data: {
        property: 'requiredInheritedComponents',
        value: data?.workOrderTemplateUpdate?.requiredInheritedComponents,
      },
    });

    onClose();
  };

  const disableEnableAll =
    requiredInheritedComponents.size ===
      new Set([...workInheritedTypes, ...taskInheritedTypes]).size ||
    workTemplate?.published;
  const disableDisableAll =
    requiredInheritedComponents.size === 0 || workTemplate?.published;

  const enableStyle = `settings-button left ${
    disableEnableAll ? '' : 'enabled'
  }`;
  const disableStyle = `settings-button ${disableDisableAll ? '' : 'enabled'}`;

  const onClickEnableAll = () => {
    setRequiredInheritedComponents(
      new Set([...workInheritedTypes, ...taskInheritedTypes]),
    );
  };

  const onClickDisableAll = () => {
    setRequiredInheritedComponents(new Set([]));
  };

  const onToggle = (type: InheritedComponentType) => {
    setRequiredInheritedComponents(
      toggleFromSet(requiredInheritedComponents, type),
    );
  };

  const disableSave =
    doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN) || workTemplate?.published;

  return (
    <Modal
      open={open}
      cancelButtonText="Cancel"
      confirmButtonText="Save"
      onCancel={onClose}
      onConfirm={onSave}
      disabled={disableSave}
      title={<SettingsTitle />}
    >
      <>
        <div>
          <div styleName="settings-content-row">
            Enable the sections which contain the content that the child work
            type must inherit from this parent work template.
          </div>
          <div styleName="settings-content-row">
            The child work template can still add additional fields, files, and
            items to any of the sections, even if disabled. See the help icon
            for more.
          </div>
        </div>
        <div>
          <div styleName="selection-title">Selection Sections:</div>
          <div styleName="settings-buttons-container">
            <div
              styleName={enableStyle}
              onClick={disableEnableAll ? () => {} : onClickEnableAll}
            >
              Enable All
            </div>
            <div
              styleName={disableStyle}
              onClick={disableDisableAll ? () => {} : onClickDisableAll}
            >
              Disable All
            </div>
          </div>
        </div>
        <div styleName="selection-container">
          <div styleName="section-title">Work</div>
          {workInheritedTypes.map(type => {
            return (
              <Switch
                key={type}
                checked={requiredInheritedComponents.has(type)}
                onChange={() => onToggle(type)}
                name={type}
                color="primary"
                label={getInheritedTypeTitle(type)}
                style={styles.toggleStyle}
                disabled={workTemplate?.published}
              />
            );
          })}
        </div>
        <div styleName="selection-container">
          <div styleName="section-title">Task</div>
          {taskInheritedTypes.map(type => {
            return (
              <Switch
                key={type}
                checked={requiredInheritedComponents.has(type)}
                onChange={() => onToggle(type)}
                name={type}
                color="primary"
                label={getInheritedTypeTitle(type)}
                style={styles.toggleStyle}
                disabled={workTemplate?.published}
              />
            );
          })}
        </div>
      </>
    </Modal>
  );
};

export default WorkTemplateSettings;
