import React from 'react';

import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import MultiAssetRow from './MultiAssetRow';
import { getFilledMultiAssets } from './reportUtilities';

import './workOrderReport.css';

interface Props {
  isCompletedOrAbove: boolean;
  reportAssets: any[];
}

const ReportMultiAssetsSection = ({
  isCompletedOrAbove,
  reportAssets,
}: Props) => {
  const filteredMultiAssets = getFilledMultiAssets(
    reportAssets,
    isCompletedOrAbove,
  );

  const showLocations = !isNilOrEmpty(filteredMultiAssets);

  return showLocations ? (
    <>
      <div styleName="unified-data-row">
        <div styleName="data-cell">{`Management Unit: ${filteredMultiAssets.managementUnit}`}</div>
        <div styleName="data-cell">{`County: ${filteredMultiAssets.county}`}</div>
        <div styleName="data-cell">{`Road Class: ${filteredMultiAssets.roadClass}`}</div>
      </div>
      <div styleName="asset-table-header">
        <div styleName="asset-header-name">Asset Name</div>
        <div styleName="asset-table-sub">
          <div styleName="asset-header-cell asset-route">Route</div>
          <div styleName="asset-header-cell">Direction</div>
          <div styleName="asset-header-cell">Start MP</div>
          <div styleName="asset-header-cell">End MP</div>
        </div>
      </div>
      <div>
        {filteredMultiAssets?.multiAssets.map((rowMultiAsset, index) => (
          <MultiAssetRow key={index} rowMultiAsset={rowMultiAsset} />
        ))}
      </div>
    </>
  ) : (
    <div />
  );
};

export default ReportMultiAssetsSection;
