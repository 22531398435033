import * as React from 'react';

import TextField from '@atom/components/common/TextField';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import fontStyles from '@atom/styles/fonts';

const styles = {
  searchBarBox: {
    width: '21.875rem',
    height: '2.125rem',
    backgroundColor: colors.neutral.typhoon,
    marginRight: '1rem',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: ' center',
  },
  iconStyle: {
    color: colors.neutral.gray,
    marginLeft: '0.625rem',
    marginRight: '0.625rem',
  },
  searchBoxStyles: {
    fontSize: fontStyles.md,
  },
};

interface Props {
  searchValue: string;
  onChange: () => void;
  clearSearch: () => void;
  openSearch: () => void;
  expanded: boolean;
  searchFiles: () => void;
}

class SearchBox extends React.Component<Props> {
  render() {
    const {
      searchValue,
      onChange,
      clearSearch,
      openSearch,
      expanded,
      searchFiles,
    } = this.props;

    return (
      <div>
        {!expanded ? (
          <IconButton onClick={openSearch}>
            <Icon>search</Icon>
          </IconButton>
        ) : (
          <div style={styles.searchBarBox}>
            <Icon style={styles.iconStyle}>search</Icon>
            <TextField
              underlineShow={false}
              fullWidth
              style={styles.searchBoxStyles}
              hintText={'Search'}
              value={searchValue}
              onChange={onChange}
              onKeyDown={searchFiles}
            />
            <Icon style={styles.iconStyle} onClick={clearSearch}>
              close
            </Icon>
          </div>
        )}
      </div>
    );
  }
}

export default SearchBox;
