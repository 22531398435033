import React, { useContext, useState } from 'react';

// @ts-ignore
import insertModuleIcon from '@atom/components/common/svgIcons/insertModuleIcon.svg';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Icon, IconButton } from '@atom/mui';
import {
  formTemplateContainsModule,
  getFormModulesList,
  isCurrentPageStandaloneModule,
} from '@atom/selectors/formModuleSelectors';
import colors from '@atom/styles/colors';
import { FormFieldType, FormModuleKeys } from '@atom/types/form';
import { InventorySchemaTreeType } from '@atom/types/inventory';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import FormModulesModal from './formModules/FormModulesModal';
import FormBuilderContext from './FormBuilderContext';
import LinkInventoryModal from './LinkInventoryModal';

import './formBuilder.css';

const styles = {
  icon: {
    marginRight: '0.5rem',
  },
};

export interface Props {
  inventorySchemaTree: InventorySchemaTreeType;
  loadingInventorySchemaTree: boolean;
  onToggle: (data: any[], boolean: boolean) => void;
  addField: (type: string) => void;
  addFormModule: (
    type: string,
    inspectionId?: string,
    schemaId?: string,
  ) => void;
  addAssetField: (assetField: FormFieldType, schemaId: string) => void;
}

const Toolbar = ({
  addField,
  inventorySchemaTree,
  loadingInventorySchemaTree,
  onToggle,
  addAssetField,
  addFormModule,
}: Props) => {
  const preferences = usePreferences();
  const { formTemplate, selectedPage } = useContext(FormBuilderContext);

  const [openLinkInventory, setOpenLinkInventory] = useState<boolean>(false);
  const [openFormModule, setOpenFormModule] = useState<boolean>(false);

  const formHasPciModule = formTemplateContainsModule(
    FormModuleKeys.PCI,
    formTemplate,
  );
  const isPageStandaloneModule = isCurrentPageStandaloneModule(
    formTemplate,
    selectedPage,
  );

  const isLinkInventoryDisabled = isPageStandaloneModule || formHasPciModule;

  const formModules = getFormModulesList(preferences);
  const isFormModulesDisabled = isNilOrEmpty(formModules);

  return (
    <>
      <div styleName="toolbar-section">
        <IconButton
          tooltip="Title"
          onClick={(): void => addField('title')}
          style={styles.icon}
          disabled={isPageStandaloneModule}
          size="small"
        >
          <Icon>title</Icon>
        </IconButton>
        <IconButton
          tooltip="Description Text"
          onClick={(): void => addField('description')}
          style={styles.icon}
          disabled={isPageStandaloneModule}
          size="small"
        >
          <Icon>subject</Icon>
        </IconButton>
      </div>
      <div styleName="toolbar-section">
        <IconButton
          tooltip="Short Text Field"
          onClick={(): void => addField('shorttext')}
          style={styles.icon}
          disabled={isPageStandaloneModule}
          size="small"
        >
          <Icon>short_text</Icon>
        </IconButton>
        <IconButton
          tooltip="Long Text Field"
          onClick={(): void => addField('longtext')}
          style={styles.icon}
          disabled={isPageStandaloneModule}
          size="small"
        >
          <Icon>format_align_left</Icon>
        </IconButton>
        <IconButton
          tooltip="Numeric"
          onClick={(): void => addField('number')}
          style={styles.icon}
          disabled={isPageStandaloneModule}
          size="small"
        >
          <Icon>looks_one</Icon>
        </IconButton>
        <IconButton
          tooltip="Single Select Dropdown"
          onClick={(): void => addField('enumsingle')}
          style={styles.icon}
          disabled={isPageStandaloneModule}
          size="small"
        >
          <Icon>arrow_drop_down_circle</Icon>
        </IconButton>
        <IconButton
          tooltip="Multi-Select Dropdown"
          onClick={(): void => addField('enummultiple')}
          style={styles.icon}
          disabled={isPageStandaloneModule}
          size="small"
        >
          <Icon>done_all</Icon>
        </IconButton>
        <IconButton
          tooltip="Multiple Choices"
          onClick={(): void => addField('radio')}
          style={styles.icon}
          disabled={isPageStandaloneModule}
          size="small"
        >
          <Icon>radio_button_checked</Icon>
        </IconButton>
      </div>
      <div styleName="toolbar-section">
        <IconButton
          tooltip="Date Picker Field"
          onClick={(): void => addField('date')}
          style={styles.icon}
          disabled={isPageStandaloneModule}
          size="small"
        >
          <Icon>today</Icon>
        </IconButton>
        <IconButton
          tooltip="Currency Field (USD)"
          onClick={(): void => addField('currency')}
          style={styles.icon}
          disabled={isPageStandaloneModule}
          size="small"
        >
          <Icon>attach_money</Icon>
        </IconButton>
      </div>
      <div
        styleName="toolbar-section"
        onClick={() => (!isPageStandaloneModule ? addField('iframe') : {})}
      >
        <div styleName="dropdown-button">
          <Icon>code</Icon>
          <div
            styleName={`menu-button-title ${
              isPageStandaloneModule ? 'disabled' : ''
            }`}
          >
            Insert Web Content
          </div>
        </div>
      </div>
      <div styleName="toolbar-section">
        <div
          styleName="dropdown-button"
          onClick={() => setOpenLinkInventory(!isLinkInventoryDisabled)}
        >
          <Icon color={colors.brand.purple}>category</Icon>
          <div
            styleName={`menu-button-title ${
              isLinkInventoryDisabled ? 'disabled' : ''
            }`}
          >
            Link Inventory
          </div>
        </div>
      </div>
      <div styleName="toolbar-section">
        <div
          styleName="dropdown-button"
          onClick={() => setOpenFormModule(!isFormModulesDisabled)}
        >
          <img src={insertModuleIcon} alt="Insert Module" />
          <div
            styleName={`menu-button-title ${
              isFormModulesDisabled ? 'disabled' : ''
            }`}
          >
            Insert Module
          </div>
        </div>
      </div>
      <LinkInventoryModal
        open={openLinkInventory}
        onClose={() => setOpenLinkInventory(false)}
        inventorySchemaTree={inventorySchemaTree}
        loading={loadingInventorySchemaTree}
        onToggle={onToggle}
        addAssetField={addAssetField}
      />
      <FormModulesModal
        open={openFormModule}
        onClose={() => setOpenFormModule(false)}
        addFormModule={addFormModule}
      />
    </>
  );
};

export default Toolbar;
