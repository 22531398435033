import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import UserGroupContext from '@atom/components/userGroupDetail/UserGroupContext';
import {
  CREATE_USER_GROUP_BUDGET,
  UPDATE_USER_GROUP_BUDGET,
} from '@atom/graph/userGroup';
import { Modal, Progress, TextField } from '@atom/mui';
import {
  UserGroup,
  UserGroupBudget,
  UserGroupBudgetCreateInput,
  UserGroupBudgetUpdateInput,
} from '@atom/types/userGroups';

import './userGroupBudgets.css';

interface Props {
  open: boolean;
  onClose: () => void;
  isEdit?: boolean;
  budget?: UserGroupBudget;
}

const AddEditBudgetModal = ({ open, onClose, isEdit, budget }: Props) => {
  const { userGroup } = useContext(UserGroupContext);

  const [name, setName] = useState<string>(isEdit ? budget.name : '');
  const [rate, setRate] = useState<number>(isEdit ? budget.rate : null);

  useEffect(() => {
    if (open) {
      setName(isEdit ? budget.name : '');
      setRate(isEdit ? budget.rate : null);
    } else {
      setName('');
      setRate(null);
    }
  }, [open]);

  const [createBudget, { loading: createBudgetLoading }] = useMutation<
    { userGroupBudgetCreate: UserGroup },
    { input: UserGroupBudgetCreateInput }
  >(CREATE_USER_GROUP_BUDGET);

  const [updateBudget, { loading: updateBudgetLoading }] = useMutation<
    { userGroupBudgetUpdate: UserGroup },
    { input: UserGroupBudgetUpdateInput }
  >(UPDATE_USER_GROUP_BUDGET);

  const confirm = () => {
    if (isEdit) {
      updateBudget({
        variables: {
          input: {
            id: budget.id,
            userGroupId: userGroup.id,
            name,
            rate,
          },
        },
      });
    } else {
      createBudget({
        variables: {
          input: {
            userGroupId: userGroup.id,
            name,
            rate,
          },
        },
      });
    }

    onClose();
  };

  return (
    <Modal
      title={isEdit ? 'Edit Budget' : 'Add Budget'}
      confirmButtonText={isEdit ? 'Save' : 'Add'}
      onConfirm={confirm}
      onCancel={onClose}
      disabled={R.isEmpty(name) || R.isNil(rate) || Number(rate) < 0}
      open={open}
    >
      {createBudgetLoading || updateBudgetLoading ? (
        <Progress />
      ) : (
        <div styleName="modal-container">
          <div styleName="left-textfield">
            <TextField
              label="Name"
              value={name}
              name="name"
              onChange={event => setName(event.target.value)}
            />
          </div>
          <div styleName="right-textfield">
            <span styleName="prefix-icon">$</span>
            <TextField
              label="Hourly Rate"
              type="number"
              value={rate}
              name="rate"
              onChange={event => setRate(event.target.value)}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AddEditBudgetModal;
