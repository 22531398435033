import React from 'react';

// @ts-ignore
import defaultProfileImage from '@atom/images/defaultProfileImage.png';
import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import { History } from '@atom/types/history';
import { setDisplayDate } from '@atom/utilities/timeUtilities';

import './history.css';

export interface Props {
  history: History;
}

const NEWLINE_TOKEN = '/newline/';

const tokens = {
  '/startbold/': '<strong>',
  '/endbold/': '</strong>',
};

export const renderMessage = (message: string) => {
  const parsed = Object.entries(tokens).reduce((acc, [key, value]) => {
    const regex = new RegExp(key, 'g');
    return acc.replace(regex, value);
  }, message);

  return parsed
    .split(NEWLINE_TOKEN)
    .map(section => `<div>${section}</div>`)
    .join('');
};

const HistoryItem = ({ history }: Props) => {
  const deviceIcon =
    history.device !== 'desktop' ? (
      <Icon color={colors.neutral.gray}>phone_iphone</Icon>
    ) : (
      <div />
    );

  return (
    <div styleName="history-item-container">
      <div styleName="history-item-header">
        <div styleName="history-item-header-item">
          <img
            styleName="profile-image"
            src={history.user.photoUrl}
            onError={(error: any) => {
              error.target.src = defaultProfileImage;
            }}
          />
          <div styleName="user-name">{`${history.user.firstName} ${history.user.lastName}`}</div>
        </div>
        <div styleName="history-item-header-item">
          {deviceIcon}
          <div styleName="history-date">{setDisplayDate(history.date)}</div>
        </div>
      </div>
      <div
        styleName="history-message"
        dangerouslySetInnerHTML={{
          __html: renderMessage(history.message),
        }}
      />
    </div>
  );
};

export default HistoryItem;
