import client from '@atom/graph/client';
import { GET_RECENTLY_UPDATED_ITEMS } from '@atom/graph/dashboard';
import { GET_SCHEMAS } from '@atom/graph/schema';
import { GET_USERS } from '@atom/graph/user';
import { GET_WORK_ORDERS } from '@atom/graph/work';
import {
  DashboardCard,
  DashboardCardConfigurations,
  DashboardCardContentState,
  DashboardCardLoadStyle,
  DashboardCardsConnectionInput,
  DashboardCardSize,
  DashboardCardType,
  RecentlyUpdatedItemsConnection,
} from '@atom/types/dashboard';
import { SchemasConnection, SchemasInput } from '@atom/types/schema';
import { UsersConnection, UsersConnectionInput } from '@atom/types/user';
import {
  WorkOrdersConnection,
  WorkOrdersConnectionInput,
} from '@atom/types/work';
import { WorkOrderColumnProperty } from '@atom/types/workColumns';
import { getEndOfYesterdayMillis } from '@atom/utilities/timeUtilities';

export const DEFAULT_LOAD_HEIGHT = '500px';

export const DASHBOARD_SIZE_OPTIONS = [
  {
    label: 'Large',
    value: DashboardCardSize.LG,
  },
  {
    label: 'Small',
    value: DashboardCardSize.SM,
  },
];

export const initUIStateProps = {
  contentState: DashboardCardContentState.LOADING,
};

// Card data the FE manages is set here
// - these properties are not in the API responses
export const CARD_CONFIG: DashboardCardConfigurations = {
  [DashboardCardType.OPEN_WORK]: {
    getCardData: (params: { userId: string; sortBy?: string; page?: number }) =>
      client.query<
        { workOrders: WorkOrdersConnection },
        { input: WorkOrdersConnectionInput }
      >({
        query: GET_WORK_ORDERS,
        variables: {
          input: {
            sortBy: params.sortBy || 'name,desc',
            page: params.page || 1,
            limit: 10,
            statusIds: [0, 1, 2, 3],
            userIds: [params.userId],
          },
        },
        fetchPolicy: 'network-only',
      }),
    portalLink: (userId: string): string =>
      `/workOrders?statusIds=0&statusIds=1&statusIds=2&statusIds=3&userIds=${userId}`,
    loadStyle: DashboardCardLoadStyle.TABLE,
    tableColumns: [
      {
        property: WorkOrderColumnProperty.INVENTORY_NAME,
        label: 'INVENTORY',
      },
      {
        property: WorkOrderColumnProperty.CREATED_BY,
        label: 'CREATED BY',
      },
      {
        property: WorkOrderColumnProperty.STATUS,
        label: 'STATUS',
      },
      {
        property: WorkOrderColumnProperty.DUE_DATE,
        label: 'DUE DATE',
      },
      {
        property: WorkOrderColumnProperty.ASSIGNED_TO,
        label: 'ASSIGNED TO',
      },
    ],
  },
  [DashboardCardType.PENDING_USERS]: {
    getCardData: () =>
      client.query<{ users: UsersConnection }, { input: UsersConnectionInput }>(
        {
          query: GET_USERS,
          variables: {
            input: {
              isActive: false,
              isVerified: false,
              showAdmin: true,
            },
          },
          fetchPolicy: 'network-only',
        },
      ),
    portalLink: () => '/team',
    emptyHeight: 100,
    loadStyle: DashboardCardLoadStyle.CHART,
  },
  [DashboardCardType.ASSET_COUNT]: {
    getCardData: () =>
      client.query<{ schemas: SchemasConnection }, { input: SchemasInput }>({
        query: GET_SCHEMAS,
        variables: {
          input: {
            assetCountBySchema: true,
          },
        },
        fetchPolicy: 'network-only',
      }),
    portalLink: () => '/inventory',
    loadStyle: DashboardCardLoadStyle.CHART,
    emptyHeight: 450,
  },
  [DashboardCardType.PAST_DUE_WORK]: {
    getCardData: (params: { sortBy?: string; page?: number }) =>
      client.query<
        { workOrders: WorkOrdersConnection },
        { input: WorkOrdersConnectionInput }
      >({
        query: GET_WORK_ORDERS,
        variables: {
          input: {
            sortBy: params.sortBy || 'dueDate,asc',
            page: params.page || 1,
            limit: 10,
            statusIds: [0, 1, 2, 3],
            dueDateEnd: getEndOfYesterdayMillis(),
          },
        },
        fetchPolicy: 'network-only',
      }),
    portalLink: () =>
      `/workOrders?statusIds=0&statusIds=1&statusIds=2&statusIds=3&dueDateEnd=${getEndOfYesterdayMillis()}`,
    loadStyle: DashboardCardLoadStyle.TABLE,
    tableColumns: [
      {
        property: WorkOrderColumnProperty.INVENTORY_NAME,
        label: 'INVENTORY',
      },
      {
        property: WorkOrderColumnProperty.STATUS,
        label: 'STATUS',
      },
      {
        property: WorkOrderColumnProperty.DUE_DATE,
        label: 'DUE DATE',
      },
      {
        property: WorkOrderColumnProperty.ASSIGNED_TO,
        label: 'ASSIGNED TO',
      },
    ],
  },
  [DashboardCardType.RECENTLY_UPDATED]: {
    getCardData: (params: { userId: string }) =>
      client.query<
        { recentlyUpdatedItems: RecentlyUpdatedItemsConnection },
        { input: DashboardCardsConnectionInput }
      >({
        query: GET_RECENTLY_UPDATED_ITEMS,
        variables: {
          input: {
            userId: params.userId,
          },
        },
        fetchPolicy: 'network-only',
      }),
    emptyHeight: 800,
    loadStyle: DashboardCardLoadStyle.LIST,
  },
  [DashboardCardType.EMBED]: {
    getCardData: async (params: { card: DashboardCard }) => {
      const dataContainer = await Promise.resolve(() => ({
        data: params?.card?.embedLink,
      }));
      return dataContainer();
    },
    loadStyle: DashboardCardLoadStyle.CHART,
  },
};
