import React, { useContext, useEffect, useState } from 'react';
import * as R from 'ramda';

import TeamContext from '@atom/components/teamPortal/TeamContext';
import { Checkbox, Icon, IconButton, ListTable } from '@atom/mui';
import colors from '@atom/styles/colors';
import { TimeEntryStatus } from '@atom/types/timeEntry';
import { UserDetail } from '@atom/types/user';
import { setDisplayDate } from '@atom/utilities/timeUtilities';

import TimeSheetListTableRow from './TimeSheetListTableRow';

import './timeSheetList.css';

const {
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
} = ListTable;

const styles = {
  center: {
    padding: '0.5rem',
  },
};

interface Props {
  users: UserDetail[];
  totalCount: number;
  page: number;
  limit: number;
  setPage: (page: number) => void;
  setLimit: (limit: number) => void;
  selectedUserIds: Set<string>;
  setSelectedUserIds: (selectedUserIds: Set<string>) => void;
}

const TimeSheetListTable = ({
  users = [],
  totalCount = 0,
  page,
  limit,
  setPage,
  setLimit,
  selectedUserIds,
  setSelectedUserIds,
}: Props) => {
  const { payPeriodWeeks } = useContext(TeamContext);

  const [selectedPayPeriodWeek, setSelectedPayPeriodWeek] = useState<number>(0);

  useEffect(() => {
    setSelectedPayPeriodWeek(0);
  }, [payPeriodWeeks]);

  const decrementWeek = () => {
    const maxWeek = payPeriodWeeks.length - 1;
    return selectedPayPeriodWeek === maxWeek ? 0 : selectedPayPeriodWeek + 1;
  };

  const incrementWeek = () => {
    const maxWeek = payPeriodWeeks.length - 1;
    return selectedPayPeriodWeek === 0 ? maxWeek : selectedPayPeriodWeek - 1;
  };

  const handlePayPeriodWeekChange = (direction: 'next' | 'prev') => {
    const newPayPeriodWeek =
      direction === 'next' ? incrementWeek() : decrementWeek();

    setSelectedPayPeriodWeek(newPayPeriodWeek);
  };

  const getAllSelectableUserIds = () => {
    return users.reduce((acc, user) => {
      const isSelectable =
        !R.isEmpty(user?.timeEntries?.timeEntries) &&
        user?.timeEntries?.status === TimeEntryStatus.pending;

      return isSelectable ? [...acc, user.id] : acc;
    }, []);
  };

  const toggleCheck = () => {
    if (!!selectedUserIds.size) {
      return setSelectedUserIds(new Set());
    }

    const allUserIds = getAllSelectableUserIds();
    return setSelectedUserIds(new Set(allUserIds));
  };

  const currentWeekDates = payPeriodWeeks[selectedPayPeriodWeek];

  return (
    <ListTable offsetTop="265px">
      <TableHead>
        <TableRow header>
          <TableCell variant="head" width="30px">
            <Checkbox
              checkedIcon={
                <Icon color={colors.brand.blue}>indeterminate_check_box</Icon>
              }
              checked={!!selectedUserIds.size}
              onClick={toggleCheck}
            />
          </TableCell>
          <TableCell variant="head" width="30px" />
          <TableCell variant="head">Name</TableCell>
          <TableCell variant="head">Open Work</TableCell>
          <TableCell variant="head">Work Templates</TableCell>
          <TableCell variant="head">Work</TableCell>
          <TableCell variant="head" width="30px" center style={styles.center}>
            <IconButton
              size="small"
              onClick={() => handlePayPeriodWeekChange('prev')}
            >
              <Icon>keyboard_arrow_left</Icon>
            </IconButton>
          </TableCell>
          {currentWeekDates.map(day => (
            <TableCell
              center
              style={styles.center}
              key={day}
              variant="head"
              width="30px"
            >
              <div styleName="header-date-container">
                <div styleName="header-date-day">
                  {setDisplayDate(day, 'EEE')}
                </div>
                <div styleName="header-date">
                  {setDisplayDate(day, 'MM/dd')}
                </div>
              </div>
            </TableCell>
          ))}
          <TableCell variant="head" width="30px" center style={styles.center}>
            <IconButton
              size="small"
              onClick={() => handlePayPeriodWeekChange('next')}
            >
              <Icon>keyboard_arrow_right</Icon>
            </IconButton>
          </TableCell>
          <TableCell center variant="head" style={styles.center}>
            Group
          </TableCell>
          <TableCell center variant="head" style={styles.center}>
            Total
          </TableCell>
          <TableCell variant="head">Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map(user => (
          <TimeSheetListTableRow
            key={user.id}
            user={user}
            selectedPayPeriodWeek={selectedPayPeriodWeek}
            setSelectedUserIds={setSelectedUserIds}
            selectedUserIds={selectedUserIds}
          />
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            count={totalCount}
            rowsPerPage={limit}
            page={page}
            onPageChange={newPage => setPage(newPage)}
            onRowsPerPageChange={event => {
              setPage(1);
              setLimit(+event.target.value);
            }}
          />
        </TableRow>
      </TableFooter>
    </ListTable>
  );
};

export default TimeSheetListTable;
