import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

/**
 * @param {any} [state=initialState.menuState] The current state
 * @param {any} action The dispatched action
 * @returns {any} The updated state
 */
export default function menuStateReducer(
  state = initialState.menuState,
  action,
) {
  switch (action.type) {
    case actionTypes.LOCATION_CHANGE: {
      return initialState.menuState;
    }
    case actionTypes.TOGGLE_NAVIGATION_MENU: {
      return { ...state, ...action.newState };
    }
    default: {
      return state;
    }
  }
}
