import * as R from 'ramda';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import actionTypes from '@atom/actions/actionTypes';
import * as userActions from '@atom/actions/userActions';
import { Snackbar } from '@atom/mui';
import accountUtilities from '@atom/utilities/accountUtilities';
import api from '@atom/utilities/api';
import {
  REGISTRATION_ENDPOINT,
  USERS_ENDPOINT,
} from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';
import { TOTAL_COUNT_HEADER } from '@atom/utilities/requestUtilities';

export function* getUsers(action) {
  try {
    const { page, limit, selected, sortBy, isAscending } = action.data;

    let isVerified;
    let isActive;

    if (selected === 'requested') {
      isActive = false;
      isVerified = false;
    }

    if (selected === 'deactivated') {
      isActive = false;
      isVerified = true;
    }

    const params = {
      ...(page ? { page } : {}),
      ...(limit ? { limit } : {}),
      ...(sortBy ? { sortBy } : {}),
      ...(!R.isNil(isAscending) ? { isAscending } : {}),
      ...(!R.isNil(isVerified) ? { isVerified } : {}),
      ...(!R.isNil(isActive) ? { isActive } : {}),
    };

    const { data, headers } = yield call(api.get, USERS_ENDPOINT, params);
    const total = Number(headers[TOTAL_COUNT_HEADER]) || 0;

    yield put(userActions.getUsersSuccess(data));
    yield put(userActions.getUsersTotalSuccess(Number(total)));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      userActions.getUsersFailure,
    );
  }
}

export function* getUser(action) {
  try {
    const { id } = action.data;
    const endpoint = `${USERS_ENDPOINT}/${id}`;
    const { data } = yield call(api.get, endpoint);

    yield put(userActions.getUserSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      userActions.getUserFailure,
    );
    // @ts-ignore
    yield call(history.push, '/team');
  }
}

export function* registerUser(action) {
  try {
    yield call(api.post, REGISTRATION_ENDPOINT, action.data);
    const { email, password } = action.data;

    const result = yield call(
      accountUtilities.individualAccountLogin,
      email,
      password,
    );

    yield put(
      userActions.requestUserAuthTokenExchange({
        accessToken: result.accessToken,
      }),
    );
  } catch (error) {
    Snackbar.error({
      message: 'This email account already exists.',
    });

    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      userActions.userRegisterFailure,
    );
  }
}

export function* deactivateUser(action) {
  try {
    const { id, firstName, lastName } = action.data;
    const endpoint = `${USERS_ENDPOINT}/${id}`;

    const body = {
      id,
      isActive: false,
    };

    yield call(api.patch, endpoint, body);
    yield put(userActions.userDeactivationSuccess({ id }));
    Snackbar.info({
      message: `Deactivated ${firstName} ${lastName}.`,
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      userActions.userDeactivationFailure,
    );
  }
}

export function* verifyUser(action) {
  try {
    const { id, firstName, lastName } = action.data;
    const endpoint = `${USERS_ENDPOINT}/${id}`;

    const body = {
      id,
      isVerified: true,
    };

    yield call(api.patch, endpoint, body);
    yield put(userActions.userVerificationSuccess({ id }));

    Snackbar.info({
      message: `Approved ${firstName} ${lastName}'s access request.`,
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      userActions.userVerificationFailure,
    );
  }
}

export function* deleteUser(action) {
  try {
    const { id, firstName, lastName } = action.data;
    const endpoint = `${USERS_ENDPOINT}/${id}`;

    yield call(api.delete, endpoint);
    yield put(userActions.userDeletionSuccess({ id }));

    Snackbar.info({
      message: `Declined ${firstName} ${lastName}'s access request.`,
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      userActions.userDeletionFailure,
    );
  }
}

export function* activateUser(action) {
  try {
    const { id, firstName, lastName } = action.data;
    const endpoint = `${USERS_ENDPOINT}/${id}`;

    const body = {
      id,
      isActive: true,
    };

    yield call(api.patch, endpoint, body);
    yield put(userActions.userActivationSuccess({ id }));

    Snackbar.info({
      message: `Activated ${firstName} ${lastName}.`,
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      userActions.userActivationFailure,
    );
  }
}

export function* updateUser(action) {
  try {
    const body = action.data;
    const endpoint = `${USERS_ENDPOINT}/${body.id}`;

    const { data } = yield call(api.patch, endpoint, body);
    yield put(userActions.userUpdateSuccess(data));

    Snackbar.info({
      message: 'User Updated',
    });
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      userActions.userActivationFailure,
    );
  }
}

/* WATCHERS */
export function* retrieveUsers() {
  yield takeEvery(actionTypes.REQUEST_USERS, getUsers);
}

export function* retrieveUser() {
  yield takeEvery(actionTypes.REQUEST_USER, getUser);
}

export function* requestUserRegistration() {
  yield takeEvery(actionTypes.REQUEST_USER_REGISTER, registerUser);
}

export function* requestUserDeactivation() {
  yield takeEvery(actionTypes.REQUEST_DEACTIVATE_USER, deactivateUser);
}

export function* requestUserVerification() {
  yield takeEvery(actionTypes.REQUEST_VERIFY_USER, verifyUser);
}

export function* requestUserDeletion() {
  yield takeEvery(actionTypes.REQUEST_USER_DELETION, deleteUser);
}

export function* requestUserActivation() {
  yield takeEvery(actionTypes.REQUEST_USER_ACTIVATION, activateUser);
}

export function* requestUserUpdate() {
  yield takeEvery(actionTypes.REQUEST_USER_UPDATE, updateUser);
}

export default [
  retrieveUsers,
  retrieveUser,
  requestUserRegistration,
  requestUserDeactivation,
  requestUserVerification,
  requestUserDeletion,
  requestUserActivation,
  requestUserUpdate,
];
