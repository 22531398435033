import * as R from 'ramda';
import { createSelector } from 'reselect';

import { UserCollectionItem } from '@atom/types/user';
import { sortByFullName } from '@atom/utilities/userUtilities';

export const userSelector = R.pathOr({ userGroups: [] }, ['user']);

export const userBudgetsSelector = user => {
  const userBudgets = R.pathOr([], ['budgets'], user).map(userBudget => ({
    ...userBudget,
    // budgets with external id should not be editable
    isEditable: !userBudget.externalId,
  }));

  const userGroupBudgets = R.pathOr([], ['userGroups'], user).reduce(
    (acc, userGroup) => {
      return [
        ...acc,
        ...userGroup.budgets.map(budget => ({
          ...budget,
          groupPath: userGroup.groupPath,
          isEditable: false,
        })),
      ];
    },
    [],
  );

  return [...userGroupBudgets, ...userBudgets];
};

export const userCertificationsSelector = user => {
  const userCerts = R.pathOr([], ['certifications'], user).map(userCert => ({
    ...userCert,
    isEditable: true,
  }));

  const userGroupCertifications = R.pathOr([], ['userGroups'], user).reduce(
    (acc, userGroup) => {
      return [
        ...acc,
        ...userGroup.certifications.map(certification => ({
          ...certification,
          groupPath: userGroup.groupPath,
          isEditable: false,
        })),
      ];
    },
    [],
  );

  return [...userGroupCertifications, ...userCerts];
};

const userOrganizationsSelector = R.pathOr([], ['organizations']);

const usersSelector = R.pathOr([], ['users']);

export const getSortedUsersSelector = createSelector(
  usersSelector,
  (users: UserCollectionItem[]): UserCollectionItem[] =>
    R.sort(sortByFullName)(users),
);

const getDomain = name => {
  let baseDomain = '';
  const productionDomain = 'atomapp.com';
  const domains = {
    local: 'local-atom.com:8080',
    dev: 'dev-atom.io',
    qa: 'qa-atom.io',
    uat: 'uat-atom.io',
  };

  if (name === 'atomlite') {
    baseDomain = domains.dev;
  } else if (name === 'local-acme') {
    baseDomain = domains.local;
  } else {
    const prefix = name.split('-')[0].toLowerCase();
    baseDomain = domains[prefix] ? domains[prefix] : productionDomain;
  }

  return `${name}.${baseDomain}`;
};

export const getUserTenants = organizations =>
  R.pipe(
    R.map((organization: any) => ({
      ...organization,
      domain: getDomain(organization.name),
    })),
    R.sortWith([R.ascend(R.prop('name'))]),
  )(organizations);

export const getUserTenantsSelector = createSelector(
  userOrganizationsSelector,
  organizations => getUserTenants(organizations),
);

export const getSortedUsersByFullName = createSelector(
  users => users,
  (users: UserCollectionItem[]) => {
    if (!users) {
      return [];
    }

    return users.sort(sortByFullName);
  },
);

export const userDirectoryPermissions = [
  {
    device: 'Desktop',
    page: 'User Directory',
    name: 'Directory',
    readOnly: 'viewOnly',
    field: 'viewOnly',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Access Requests',
    readOnly: 'noAccess',
    field: 'noAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Deactivated Users',
    readOnly: 'noAccess',
    field: 'noAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
];

export const userDetailPagesPermissions = [
  {
    page: 'User Detail Pages',
    name: 'User Info',
    readOnly: 'viewOnly',
    field: 'viewOnly',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Work',
    readOnly: 'viewOnly',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'User Permissions',
    readOnly: 'noAccess',
    field: 'noAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Notifications - Work Status Change',
    readOnly: 'noAccess',
    field: 'noAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
];

export const permissionPagePermissions = [
  {
    page: 'Permission Page / Link',
    name: 'Role Management',
    readOnly: 'noAccess',
    field: 'noAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Notifications',
    readOnly: 'noAccess',
    field: 'noAccess',
    manager: 'fullAccess',
    orgAdmin: 'noAccess',
  },
];

export const dashboardPermissions = [
  {
    page: 'Dashboard',
    name: 'Work Tile',
    readOnly: 'fullAccess',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Access Work',
    readOnly: 'fullAccess',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Dashboard Layer Filters',
    readOnly: 'fullAccess',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
];

export const workOrderPortalPermissions = [
  {
    page: 'Work Portal',
    name: 'Work Listing (List)',
    readOnly: 'fullAccess',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Work Listing (Map)',
    readOnly: 'fullAccess',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Create New Work (List & Map)',
    readOnly: 'noAccess',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Access New Work Form Repository',
    readOnly: 'noAccess',
    field: 'viewOnly',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Delete Work',
    readOnly: 'noAccess',
    field: 'noAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
];

export const workOrderFormsPermissions = [
  {
    page: 'Work Forms',
    name: 'Form Repository',
    readOnly: 'noAccess',
    field: 'viewOnly',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'View Forms In Repository',
    readOnly: 'noAccess',
    field: 'viewOnly',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Create Forms',
    readOnly: 'noAccess',
    field: 'noAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Delete Forms',
    readOnly: 'noAccess',
    field: 'noAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
];

export const workOrderDetailPermissions = [
  {
    page: 'Work Detail Pages',
    name: 'Work Management (Edit)',
    readOnly: 'noAccess',
    field: 'noAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Work Status Changes',
    readOnly: 'noAccess',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Work Completed - Editing',
    readOnly: 'noAccess',
    field: 'viewOnly',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Work Priority',
    readOnly: 'viewOnly',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Work Closing',
    readOnly: 'noAccess',
    field: 'noAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Generate PDF Report',
    readOnly: 'fullAccess',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Task Management',
    readOnly: 'viewOnly',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Task - Check-In/Help/Complete',
    readOnly: 'viewOnly',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Task - Team Editing',
    readOnly: 'viewOnly',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Task - Asset Editing',
    readOnly: 'viewOnly',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Task - Form Editing',
    readOnly: 'viewOnly',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Summary Management',
    readOnly: 'viewOnly',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Team Management (Tab) - Team Lead',
    readOnly: 'viewOnly',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Document Management',
    readOnly: 'viewOnly',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Photo Management',
    readOnly: 'viewOnly',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Photo Management (Cover Photo)',
    readOnly: 'viewOnly',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Video Management',
    readOnly: 'viewOnly',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
];

export const inventoryPortalPermissions = [
  {
    page: 'Inventory Portal',
    name: 'Inventory Folders',
    readOnly: 'fullAccess',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Inventory Filtering',
    readOnly: 'fullAccess',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Inventory Download (From Filtering)',
    readOnly: 'noAccess',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Folder - Editing',
    readOnly: 'noAccess',
    field: 'noAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Create New Asset',
    readOnly: 'noAccess',
    field: 'noAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Bulk Upload CSV - Asset/Schema Creation',
    readOnly: 'noAccess',
    field: 'noAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Bulk Work Creation - By Folder',
    readOnly: 'noAccess',
    field: 'noAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Inventory Data Management',
    readOnly: 'noAccess',
    field: 'noAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
];

export const inventoryDetailPermissions = [
  {
    page: 'Inventory Detail Pages',
    name: 'Create a Work',
    readOnly: 'noAccess',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Asset Lat/Long Edit',
    readOnly: 'viewOnly',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Asset Info Management',
    readOnly: 'viewOnly',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Elements Management',
    readOnly: 'viewOnly',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Activity Log',
    readOnly: 'noAccess',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Work Management',
    readOnly: 'fullAccess',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Document Management',
    readOnly: 'viewOnly',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Photo Management',
    readOnly: 'viewOnly',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Video Management',
    readOnly: 'viewOnly',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
];

export const analyticsPortalPermissions = [
  {
    page: 'Analytics Portal',
    name: 'View Analytics Tiles',
    readOnly: 'fullAccess',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
  {
    name: 'Analytic Filters',
    readOnly: 'fullAccess',
    field: 'fullAccess',
    manager: 'fullAccess',
    orgAdmin: 'fullAccess',
  },
];

export const desktopPermissions = [
  ...userDirectoryPermissions,
  ...userDetailPagesPermissions,
  ...permissionPagePermissions,
  ...dashboardPermissions,
  ...workOrderPortalPermissions,
  ...workOrderFormsPermissions,
  ...workOrderDetailPermissions,
  ...inventoryPortalPermissions,
  ...inventoryDetailPermissions,
  ...analyticsPortalPermissions,
];

export const mobilePermissions = [];

export const permissionsTable = [...desktopPermissions, ...mobilePermissions];

const userSelectors = {
  permissionsTable,
  getUserTenantsSelector,
  getUserTenants,
  getDomain,
  usersSelector,
  getSortedUsersSelector,
};

export default userSelectors;
