import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as userActionCreators from '@atom/actions/userActions';
import { Button } from '@atom/mui';
import { UserActions } from '@atom/types/actions';

import AuthLogo from './AuthLogo';
import LoginFooter from './LoginFooter';

import './login.css';

interface ReduxDispatchProps {
  userActions: UserActions;
}

type Props = ReduxDispatchProps;

class Unverified extends React.Component<Props> {
  logout = () => {
    const { userActions } = this.props;
    userActions.triggerLogout();
  };

  render() {
    return (
      <div styleName="container">
        <div styleName="auth-background" />
        <div styleName="logo-offset">
          <AuthLogo />
        </div>
        <div styleName="inner-container">
          <div styleName="content-container">
            <div styleName="unverified-content">
              <div styleName="unverified-heading">
                Thank you for signing up for Atom!
              </div>
              <div styleName="unverified-content-description">
                <p>
                  You have successfully signed up for Atom. An administrator
                  will review your profile and notify you when the account has
                  been given access to the application.
                </p>
                <p>
                  If you have any questions, please contact your administrator
                  directly.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div styleName="nav-container">
          <div styleName="unverified-login-button">
            <Button
              style={{ color: 'black' }}
              color="inherit"
              variant="contained"
              onClick={this.logout}
            >
              BACK TO LOGIN
            </Button>
          </div>
        </div>
        <LoginFooter />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  userActions: bindActionCreators(userActionCreators, dispatch),
});

export default connect(null, mapDispatchToProps)(Unverified);
