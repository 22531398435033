import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_TASK_UPDATE } from '@atom/graph/workTemplate';
import { Icon, IconButton, Modal, Switch } from '@atom/mui';
import {
  WorkOrderTemplateTaskUpdateInput,
  WorkTemplateTaskItem,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { WorkTypeVariant } from '@atom/utilities/workTemplateUtilities';

import MultiSchemaList from './MultiSchemaList';
import SchemaSettingsModal from './SchemaSettingsModal';

import './multiSchemaSection.css';

const styles = {
  switchStyles: {
    marginRight: '0',
    marginLeft: '5px',
  },
};

const MultiSchemaSection = () => {
  const { workTemplate, task, refetch, workTypeVariant } = useContext(
    WorkTemplateContext,
  );

  const [expanded, setExpanded] = useState<boolean>(true);
  const [toggleModalOpen, setToggleModalOpen] = useState<boolean>(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);

  const [taskTemplateUpdate] = useMutation<
    { workOrderTemplateTaskUpdate: WorkTemplateTaskItem },
    { input: WorkOrderTemplateTaskUpdateInput }
  >(WORK_TEMPLATE_TASK_UPDATE);

  const updateEnabledOption = async (multiAssetsEnabled: boolean) => {
    await taskTemplateUpdate({
      variables: {
        input: {
          workTemplateId: workTemplate.id,
          taskId: task.id,
          multiAssetsEnabled,
          allowedMultiAssetSchemaIds: [],
          requireAtLeastOneMultiAsset: false,
          requiredMultiAssetSchemaIds: [],
        },
      },
    });

    setToggleModalOpen(false);
    refetch();
  };

  const toggleEnabledSetting = (newMultiAssetsEnabled: boolean) => {
    if (newMultiAssetsEnabled) {
      setSettingsModalOpen(true);
    } else {
      setToggleModalOpen(true);
    }
  };

  const icon = expanded ? 'expand_less' : 'expand_more';
  const isDisabled =
    workTemplate?.published ||
    doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN) ||
    workTypeVariant === WorkTypeVariant.CHILD;

  return (
    <>
      <div styleName="section-container">
        <Icon>category</Icon>
        <div styleName="section-title">Assets</div>
        <div styleName="buttons-container">
          {task?.multiAssetsEnabled && (
            <IconButton onClick={() => setSettingsModalOpen(true)}>
              <Icon>settings</Icon>
            </IconButton>
          )}
          <Switch
            style={styles.switchStyles}
            checked={task?.multiAssetsEnabled}
            onChange={() => toggleEnabledSetting(!task?.multiAssetsEnabled)}
            disabled={isDisabled}
          />
          <IconButton onClick={() => setExpanded(!expanded)} edge="end">
            <Icon>{icon}</Icon>
          </IconButton>
        </div>
      </div>
      {expanded && (
        <>
          {task?.multiAssetsEnabled ? (
            <MultiSchemaList />
          ) : (
            <div styleName="tile-list">
              <div styleName="empty-section-text">
                This section will not show in the task view.
              </div>
            </div>
          )}
        </>
      )}
      <SchemaSettingsModal
        open={settingsModalOpen}
        onClose={() => setSettingsModalOpen(false)}
      />
      <Modal
        open={toggleModalOpen}
        cancelButtonText="Cancel"
        confirmButtonText="Disable"
        onCancel={() => setToggleModalOpen(false)}
        onConfirm={() => updateEnabledOption(!task?.multiAssetsEnabled)}
        title="Disable Section?"
      >
        Disabling this section will hide it in the task view. Any changes made
        to the section settings will be lost. Are you sure?
      </Modal>
    </>
  );
};

export default MultiSchemaSection;
