import React, { useEffect, useState } from 'react';
import { match as Match } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import { GET_WORK_ORDER } from '@atom/graph/work';
import { Progress } from '@atom/mui';
import { MediaItem } from '@atom/types/media';
import { WorkOrderAssetTreeType, WorkOrderDetailType } from '@atom/types/work';
import api from '@atom/utilities/api';
import {
  MEDIA_ENDPOINT,
  WORK_ORDERS_ENDPOINT,
} from '@atom/utilities/endpoints';

import MainPhotoTile from '../MainPhotoTile';

import AttributesTable from './AttributesTable';
import CustomAttributesTextbox from './CustomAttributesTextbox';
import ElementGroupDetailsTable from './ElementGroupDetailsTable';
import PhotoLogAttachmentsTable from './PhotoLogAttachmentsTable';
import PhotosBox from './PhotosBox';
import RecommendationTextbox from './RecommendationTextbox';

import '../workOrderDetailPdfReport.css';

interface Props {
  match: Match<{ workOrderId: string }>;
}

const OdotWorkOrderPdfReport = ({ match }: Props) => {
  const [workOrder, setWorkOrder] = useState<WorkOrderDetailType>(
    {} as WorkOrderDetailType,
  );
  const [inventoryAssetId, setInventoryAssetId] = useState<string>('');
  const [workOrderAsset, setWorkOrderAsset] = useState<WorkOrderAssetTreeType>(
    {} as WorkOrderAssetTreeType,
  );
  const [mainPhoto, setMainPhoto] = useState<MediaItem>({} as MediaItem);
  const [mainPhotoLoading, setMainPhotoLoading] = useState<boolean>(true);
  const [otherPhotosLoading, setOtherPhotosLoading] = useState<{
    [id: string]: boolean;
  }>({ photoBox: true });

  const onCompleted = data => {
    setWorkOrder(data.workOrder);
    setInventoryAssetId(data.workOrder.inventoryAssetId);
  };

  const { loading } = useQuery(GET_WORK_ORDER, {
    variables: {
      id: match.params.workOrderId,
    },
    onCompleted,
  });

  useEffect(() => {
    if (inventoryAssetId) {
      const getWorkOrderAssetTree = async () => {
        const endpoint = `${WORK_ORDERS_ENDPOINT}/${match.params.workOrderId}/assets/${inventoryAssetId}/tree`;
        const { data } = await api.get(endpoint);
        return setWorkOrderAsset(data);
      };
      getWorkOrderAssetTree();
    }
  }, [inventoryAssetId]);

  useEffect(() => {
    if (workOrder.mainPhotoId) {
      const getPhoto = async () => {
        const endpoint = `${MEDIA_ENDPOINT}/${workOrder.mainPhotoId}`;
        const response = await api.get(endpoint);
        setMainPhoto(response.data);
      };
      getPhoto();
    } else {
      setMainPhotoLoading(false);
    }
  }, [workOrder.mainPhotoId]);

  return (
    <div styleName="background">
      {loading || R.isEmpty(workOrderAsset) ? (
        <Progress />
      ) : (
        <div styleName="main">
          <div styleName="details">
            <div styleName="col-2">
              <CustomAttributesTextbox
                header={'Overall Structure Assessment'}
                workOrderAsset={workOrderAsset}
                height={'16.2rem'}
              />
            </div>
            <div styleName="col-2">
              {workOrder.mainPhotoId ? (
                <MainPhotoTile
                  image={mainPhoto}
                  imageWidth="100%"
                  imageHeight="18rem"
                  loading={setMainPhotoLoading}
                />
              ) : (
                <div styleName="empty-photo-section odot" />
              )}
            </div>
          </div>
          <div styleName="group-container">
            <CustomAttributesTextbox
              header={'General Data'}
              workOrderAsset={workOrderAsset}
              columnCount={2}
            />
            <RecommendationTextbox workOrderAsset={workOrderAsset} />
            <div style={{ pageBreakAfter: 'always' }} />
            <AttributesTable
              workOrderAsset={workOrderAsset}
              header={'Foundation'}
            />
            <AttributesTable
              workOrderAsset={workOrderAsset}
              header={'Anchor Bolts'}
            />
            <AttributesTable
              workOrderAsset={workOrderAsset}
              header={'End Frame / Pole'}
            />
            <AttributesTable
              workOrderAsset={workOrderAsset}
              header={'Cantilever / Span'}
            />
            <AttributesTable workOrderAsset={workOrderAsset} header={'Sign'} />
            <ElementGroupDetailsTable
              workOrderAsset={workOrderAsset}
              elementGroupName={'Sign'}
            />
            <AttributesTable
              workOrderAsset={workOrderAsset}
              header={'Ratings'}
            />
            <AttributesTable
              workOrderAsset={workOrderAsset}
              header={'Photo Log'}
            />
            <PhotoLogAttachmentsTable workOrderAsset={workOrderAsset} />
            <PhotosBox
              workOrderId={match.params.workOrderId}
              setOtherPhotosLoading={setOtherPhotosLoading}
            />
          </div>

          {!mainPhotoLoading &&
            Object.values(otherPhotosLoading).every(load => !load) && (
              <div id="loaded" />
            )}
        </div>
      )}
    </div>
  );
};

export default OdotWorkOrderPdfReport;
