import * as React from 'react';

import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';

import './drawerContent.css';

const styles = {
  icon: {
    fontSize: '5.5rem',
    width: '5.5rem',
    height: '5.5rem',
    marginTop: '7.5rem',
    marginBottom: '1rem',
  },
};

interface Props {
  icon: string;
  text: string;
  subtext: string;
}

const NoItemsPane = ({ icon, text, subtext }: Props) => {
  return (
    <div styleName="no-items-container-v2">
      <Icon style={styles.icon} color={colors.neutral.gray}>
        {icon}
      </Icon>
      <p styleName="no-items-text-v2">{text}</p>
      <p styleName="no-items-subtext-v2">{subtext}</p>
    </div>
  );
};

export default NoItemsPane;
