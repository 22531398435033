import React, { useContext, useEffect, useState } from 'react';
import { format } from 'date-fns';
import * as R from 'ramda';

import { usePreferences } from '@atom/hooks/usePreferences';
import {
  Icon,
  IconButton,
  Modal,
  RadioGroup,
  Snackbar,
  TextField,
} from '@atom/mui';
import colors from '@atom/styles/colors';
import { ReportTypes } from '@atom/types/preferences';
import api from '@atom/utilities/api';
import {
  FILES_ENDPOINT,
  WORK_ORDERS_ENDPOINT,
} from '@atom/utilities/endpoints';
import { formatDateMoment } from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import { getWorkReportBody } from '@atom/utilities/workReportUtilities';

import WorkOrderContext from '../WorkOrderContext';

import { REPORT_OPTIONS, ReportOption } from './reportOptions';

import './workOrderDetailHeader.css';

const { RadioButton } = RadioGroup;

const ReportModal = () => {
  const { workOrderDetail, setRefetchMedia } = useContext(WorkOrderContext);
  const preferences = usePreferences();
  const reportTypes = preferences?.reports || [];

  const options = reportTypes.map(reportType => REPORT_OPTIONS[reportType]);

  const [selectedReport, setSelectedReport] = useState<ReportOption | null>(
    !isNilOrEmpty(options) ? options[0] : null,
  );
  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>('');

  const isCustomReport =
    R.intersection(
      [ReportTypes.CUSTOM_WORK_REPORT, ReportTypes.ALDOT_REPORT],
      reportTypes,
    ).length > 0;

  const isSCDOTReport = R.includes(ReportTypes.SCDOT_REPORT, reportTypes);

  // Custom name that will only work in SCDOT related tenants
  const getSCDOTName = () => {
    const workOrderAssetName = R.pathOr(
      '',
      ['inventoryAssetName'],
      workOrderDetail,
    );

    const latestTask = workOrderDetail?.tasks[0] || {};
    const customFields = R.pathOr([], ['fields'], latestTask);
    const inspectionDateField = R.find(R.propEq('title', 'Inspection Date'))(
      customFields,
    );
    const formattedDate = formatDateMoment(
      R.pathOr(null, ['value'], inspectionDateField),
      'YYYY-MM-DD',
    );

    if (!workOrderAssetName && !formattedDate) {
      return '';
    }

    if (!formattedDate) {
      return workOrderAssetName;
    }

    return `${workOrderAssetName}_${formattedDate}`;
  };

  const getInitialName = () => {
    if (isSCDOTReport) {
      return getSCDOTName();
    }

    if (isCustomReport) {
      return workOrderDetail?.workTemplateName
        ? `${workOrderDetail?.workTemplateName} Work Report`
        : 'Work Report';
    }

    return `${workOrderDetail.name} - ${format(new Date(), 'MM-dd-yyyy')}`;
  };

  useEffect(() => {
    setName(getInitialName());
  }, [workOrderDetail.name]);

  const confirm = async () => {
    Snackbar.info({
      message: 'Generating Work Report...',
      autoHideDuration: null,
    });

    const body = getWorkReportBody(name, selectedReport, workOrderDetail);

    setOpen(false);
    setName(getInitialName());
    setSelectedReport(options[0]);

    const endpoint = `${WORK_ORDERS_ENDPOINT}/${workOrderDetail.id}/report`;

    try {
      const { data } = await api.post(endpoint, body);

      Snackbar.info({
        message: 'Work Report generated',
        action: 'View',
        onActionClick: () => {
          const tab = window.open(`${FILES_ENDPOINT}/${data.fileId}`, '_blank');
          return tab.focus();
        },
      });

      setRefetchMedia(true);
    } catch (error) {
      Snackbar.error({
        message: 'An error has occurred when generating the report.',
      });
    }
  };

  const showExportOptions = !isCustomReport && !isSCDOTReport;

  return (
    <>
      <IconButton
        onClick={() => setOpen(true)}
        tooltip="Generate Report"
        edge="end"
      >
        <Icon color={colors.neutral.white}>assignment</Icon>
      </IconButton>
      <Modal
        title="Generate PDF Report"
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={() => confirm()}
        confirmButtonText="Generate"
        loading={R.isEmpty(name) || R.isEmpty(options)}
      >
        <div>
          <div styleName="report-text">
            You will find the PDF report under the "Documents" tab.
          </div>
          <TextField
            id="name"
            label="Report Name"
            value={name}
            onChange={event => setName(event.target.value)}
            required
          />
          {showExportOptions && (
            <>
              <div styleName="export-options-text">Export Options:</div>
              <div>
                <RadioGroup
                  value={selectedReport?.value}
                  onChange={event =>
                    setSelectedReport(REPORT_OPTIONS[event.target.value])
                  }
                >
                  {options.map(option => {
                    return (
                      <RadioButton
                        key={option.label}
                        value={option.value}
                        label={option.label}
                      />
                    );
                  })}
                </RadioGroup>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ReportModal;
