import * as React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';

import * as userActionCreators from '@atom/actions/userActions';
import { Progress } from '@atom/mui';
import { UserActions } from '@atom/types/actions';
import { CURRENT_SUBDOMAIN } from '@atom/utilities/endpoints';

interface ReduxDispatchProps {
  userActions: UserActions;
}

type Props = ReduxDispatchProps;

class AuthCallback extends React.Component<Props> {
  componentDidMount() {
    const { userActions } = this.props;

    const token = this.getToken();
    const tenant = this.getTenant();

    if (CURRENT_SUBDOMAIN === tenant) {
      userActions.requestUserTokenPersist({ token });
    } else {
      const subDomain = tenant ? tenant : 'www';
      const hostLocation = window.location.host;
      const protocol = window.location.protocol;
      const domain = R.pipe(
        R.split('.'),
        R.drop(1),
        R.prepend(`${protocol}//${subDomain}`),
        R.join('.'),
      )(hostLocation);

      window.location.href = `${domain}/callback/${token}?tenant=${subDomain}`;
    }
  }

  getToken = (): string => {
    return window.location.pathname.split('/callback/').join('').split('#')[0];
  };

  getTenant = (): string => {
    const params = new URLSearchParams(window.location.search);
    return params.get('tenant');
  };

  render() {
    return (
      <React.Fragment>
        <Progress />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (): any => ({});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  userActions: bindActionCreators(userActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthCallback);
