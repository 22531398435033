import React, { useContext, useState } from 'react';

import ManageUserGroupsModal from '@atom/components/common/manageUserGroupModal/ManageUserGroupsModal';
import UserContext, { View } from '@atom/components/userDetail/UserContext';
import { useUserProfile } from '@atom/hooks/useUserProfile';
// @ts-ignore
import defaultProfileImage from '@atom/images/defaultProfileImage.png';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  hasRolePermissions,
  hasSuperiorOrEqualUserLevel,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import history from '@atom/utilities/history';
import userUtilities from '@atom/utilities/userUtilities';

import DeactivateUserModal from './DeactivateUserModal';
import NavigationButton from './NavigationButton';

import './userHeader.css';

interface ViewDetail {
  value: View;
  label: string;
  icon: string;
}
const UserGroupHeader = () => {
  const userProfile = useUserProfile();
  const { user, refetch } = useContext(UserContext);

  const [deactivateOpen, setDeactivateOpen] = useState<boolean>(false);
  const [manageGroupsOpen, setManageGroupsOpen] = useState<boolean>(false);

  const isOwnProfile = userProfile.userId === user.id;

  const renderDeactivate =
    !isOwnProfile &&
    hasRolePermissions(ROLE_SETS.ORG_ADMIN) &&
    hasSuperiorOrEqualUserLevel(user.level);

  const isBudgetsDisabled =
    !isOwnProfile && !hasRolePermissions(ROLE_SETS.MANAGER);

  const views: ViewDetail[] = [
    {
      value: View.INFO,
      label: 'info',
      icon: 'info',
    },
    {
      value: View.ROLES,
      label: 'roles',
      icon: 'account_circle',
    },
    {
      value: View.BUDGETS,
      label: 'budgets',
      icon: 'monetization_on',
    },
    {
      value: View.PERMISSIONS,
      label: 'permissions',
      icon: 'vpn_key',
    },
    {
      value: View.CERTIFICATIONS,
      label: 'certifications',
      icon: 'card_membership',
    },
    {
      value: View.WORK,
      label: 'work',
      icon: 'work',
    },
    {
      value: View.SETTINGS,
      label: 'settings',
      icon: 'settings',
    },
  ];

  return (
    <>
      <div styleName="header-container">
        <div styleName="top-bar">
          <div styleName="title-container">
            <IconButton onClick={() => history.goBackWithState()}>
              <Icon color={colors.neutral.white}>arrow_back</Icon>
            </IconButton>
            <div styleName="info-container">
              <div styleName="profile-image-container">
                <img
                  styleName="profile-image"
                  src={user.photoUrl}
                  onError={(error: any) => {
                    error.target.src = defaultProfileImage;
                  }}
                />
              </div>
              <div styleName="title-content">
                <div styleName="title">{`${user.firstName} ${user.lastName}`}</div>
                <div>{user.email}</div>
                <div>{userUtilities.getUserRole(user.role)}</div>
              </div>
            </div>
          </div>
          <div>
            {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
              <IconButton onClick={() => setManageGroupsOpen(true)}>
                <Icon color={colors.neutral.white}>group</Icon>
              </IconButton>
            )}
            {renderDeactivate && (
              <IconButton onClick={() => setDeactivateOpen(true)}>
                <Icon color={colors.neutral.white}>not_interested</Icon>
              </IconButton>
            )}
          </div>
        </div>
        <div styleName="navigation-bar">
          {views
            .reduce(
              (acc, view): ViewDetail[] =>
                view.value === View.BUDGETS && isBudgetsDisabled
                  ? acc
                  : [...acc, view],
              [],
            )
            .map(view => (
              <NavigationButton key={view.value} view={view} />
            ))}
        </div>
      </div>
      <ManageUserGroupsModal
        open={manageGroupsOpen}
        onClose={() => setManageGroupsOpen(false)}
        userId={user.id}
        refetch={refetch}
      />
      <DeactivateUserModal
        open={deactivateOpen}
        onClose={() => setDeactivateOpen(false)}
        userId={user.id}
      />
    </>
  );
};

export default UserGroupHeader;
