import * as React from 'react';

import UserThumbnail from '@atom/components/common/UserThumbnail';
import { ApplicationSearchUsersState } from '@atom/types/store';
import history from '@atom/utilities/history';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';

import './searchPortal.css';

interface Props {
  query: string;
  users: ApplicationSearchUsersState[];
}

class TeamSearchResultsRows extends React.Component<Props> {
  navigate = (id: string) => {
    history.push(`/team/${id}`);
  };

  render() {
    const { query, users } = this.props;

    return (
      <div styleName="search-entity-results-container">
        {users.map((user: ApplicationSearchUsersState) => {
          const name = textDisplayUtilities.highlightedText(
            query,
            `${user.firstName} ${user.lastName}`,
          );

          const emailAddress = textDisplayUtilities.highlightedText(
            query,
            user.email,
          );

          return (
            <div
              styleName="search-entity-row"
              key={user.id}
              onClick={(): void => this.navigate(user.id)}
            >
              <UserThumbnail image={user.photoUrl} />
              <div styleName="search-entity-row-text-container">
                <div styleName="search-entity-row-text">{name}</div>
                <div styleName="search-entity-row-subtext">{emailAddress}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default TeamSearchResultsRows;
