import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import UserSummaryTray from '@atom/components/mapPortal/userSummary/UserSummaryTray';
import history from '@atom/utilities/history';

import { useDetailsData } from '../hooks/detailsDataHook';
import { useWorkOrdersData } from '../hooks/workOrdersDataHook';

const UserSummaryWrapper = () => {
  const [{ view, id }] = useQueryParams({
    view: StringParam,
    id: StringParam,
  });

  const [user, loading] = useDetailsData(view, id);
  const [workOrders, woLoading] = useWorkOrdersData({ userIds: id });

  return (
    <UserSummaryTray
      data={user}
      loading={loading || woLoading}
      workOrders={workOrders}
      onCancel={history.goBack}
    />
  );
};

export default UserSummaryWrapper;
