import * as React from 'react';

import FontIconButton from '@atom/components/common/FontIconButton';
import TextField from '@atom/components/common/TextField';
import TextLimit from '@atom/components/common/TextLimit';
import {
  MAX_CHAR_DESCRIPTION,
  MAX_CHAR_SHORT_INPUT,
  MAX_OPTIONS,
  REVEAL_LONG,
} from '@atom/selectors/formSelectors';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';

import '../formBuilder.css';

const styles = {
  fontSize: fonts.md,
  color: colors.neutral.dark,
};

interface Props {
  title: string;
  description: string;
  enumValue: string[];
  onChange: (event: Object) => void;
  updateOption: (event: Object) => void;
  removeOption: (index: number) => void;
  addOption: () => void;
}

const MultipleOptionInput = ({
  title,
  description,
  enumValue,
  onChange,
  updateOption,
  removeOption,
  addOption,
}: Props) => {
  return (
    <React.Fragment>
      <div styleName="multi-input-container">
        <div styleName="double-input">
          <TextField
            value={title}
            onChange={onChange}
            name="title"
            fullWidth
            style={styles}
            floatingLabelText="Title"
          />
          <div styleName="text-limit-container">
            <TextLimit
              limit={MAX_CHAR_SHORT_INPUT}
              reveal={REVEAL_LONG}
              text={title}
            />
          </div>
        </div>
        <div styleName="double-input">
          <TextField
            value={description}
            onChange={onChange}
            name="description"
            fullWidth
            style={styles}
            floatingLabelText="Description"
          />
          <div styleName="text-limit-container">
            <TextLimit
              limit={MAX_CHAR_DESCRIPTION}
              reveal={REVEAL_LONG}
              text={description}
            />
          </div>
        </div>
      </div>
      <div styleName="section-text">Options:</div>
      {enumValue ? (
        enumValue.map((option: string, index: number) => {
          return (
            <div key={index}>
              <div styleName="options-input-container">
                <div styleName="options-number">{`${index + 1}.`}</div>
                <TextField
                  fullWidth
                  value={option}
                  key={index}
                  onChange={updateOption}
                  id={index.toString()}
                  style={styles}
                />
                <FontIconButton
                  onClick={(): void => removeOption(index)}
                  color={colors.neutral.gray}
                  icon="cancel"
                />
              </div>
              <div styleName="text-limit-container option">
                <TextLimit
                  limit={MAX_CHAR_SHORT_INPUT}
                  reveal={REVEAL_LONG}
                  text={option}
                />
              </div>
            </div>
          );
        })
      ) : (
        <div />
      )}
      {enumValue.length < MAX_OPTIONS && (
        <div styleName="options-input-container">
          <FontIconButton
            onClick={addOption}
            color={colors.neutral.gray}
            icon="add_circle_outline"
          />
          <div styleName="add-option-text">Add Option</div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MultipleOptionInput;
