import React from 'react';

import { RadioGroup } from '@atom/mui';
import { WorkOrderTypeOption } from '@atom/types/work';

import './workTemplateCreationModal.css';

const { RadioButton } = RadioGroup;

interface Props {
  option: WorkOrderTypeOption;
  selected: boolean;
  onClick: (value: any) => void;
}

const RadioButtonRow = ({ option, selected, onClick }: Props) => {
  const styleName = selected ? 'radio-button-row selected' : 'radio-button-row';

  return (
    <div styleName={styleName} onClick={() => onClick(option.value)}>
      <RadioButton
        onChange={event => onClick(event.target.value)}
        value={option.value}
        checked={selected}
        label={
          <div styleName="radio-button-label">
            <img styleName="radio-button-row-icon" src={option.icon} />
            <div>
              <div styleName="radio-button-row-title">{option.title}</div>
              <div styleName="radio-button-row-subtext">{option.subtext}</div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default RadioButtonRow;
