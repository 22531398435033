import React from 'react';

import TaskName from './TaskName';
import WorkTemplateTaskControls from './WorkTemplateTaskControls';

import './taskHeader.css';

const WorkTemplateTaskHeader = () => {
  return (
    <div styleName="task-header-container">
      <TaskName />
      <div styleName="action-buttons-container">
        <WorkTemplateTaskControls />
      </div>
    </div>
  );
};

export default WorkTemplateTaskHeader;
