import React from 'react';

import { Task } from '@atom/types/task';

interface Context {
  task: Task;
}

export const initialState: Context = {
  //@ts-ignore
  task: {},
};

const TaskContext = React.createContext<Context>(initialState);

export default TaskContext;
