import React from 'react';
import * as R from 'ramda';

import { Task } from '@atom/types/task';
import { WorkOrderType } from '@atom/types/work';

import CustomFieldRow from './CustomFieldRow';
import { EMPTY_TASK, getEmptyTaskFields } from './reportConstants';
import { getMappedCustomFields } from './reportUtilities';

import './workOrderReport.css';

interface Props {
  task: Task;
  isCompletedOrAbove: boolean;
  type: WorkOrderType;
}

const CustomFieldsSection = ({ task, isCompletedOrAbove, type }: Props) => {
  const mappedCustomFields =
    task?.id === EMPTY_TASK.id
      ? getEmptyTaskFields(type)
      : getMappedCustomFields(task, isCompletedOrAbove);

  const customFieldRows = R.splitEvery(3, mappedCustomFields);

  return (
    <div styleName="custom-field-section">
      {customFieldRows.map((fields, index) => (
        <CustomFieldRow key={index} fields={fields} />
      ))}
    </div>
  );
};

export default CustomFieldsSection;
