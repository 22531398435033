import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as R from 'ramda';

import WorkTemplateTree from '@atom/components/common/workTemplateTree/WorkTemplateTree';
import { GET_WORK_ORDER_TEMPLATE_FOLDERS } from '@atom/graph/workTemplate';
import { Button, Modal, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import {
  WorkOrderTemplateFolder,
  WorkOrderTemplateFoldersConnection,
  WorkOrderTemplateFoldersConnectionInput,
} from '@atom/types/workTemplate';
import { flattenTreeChildren } from '@atom/utilities/treeUtilities';

import './mapDrawerWorkTemplateFilter.css';

interface Props {
  onWorkTemplateFoldersUpdate: (workTemplateFolderIds: Set<any>) => any;
  workTemplateFolderIds: Set<any>;
  toggled?: boolean;
}

const getStyles = toggled => ({
  modal: {
    height: '50vh',
    padding: '2rem',
  },
  button: {
    minWidth: 0,
    padding: 0,
    color: toggled ? colors.brand.blue : colors.neutral.gray,
    fontSize: fonts.sm,
    fontWeight: 'normal',
    textTransform: 'capitalize',
  },
  checkbox: {
    padding: 0,
    marginRight: '1rem',
  },
});

const MapDrawerWorkTemplateFolderFilter = ({
  onWorkTemplateFoldersUpdate,
  workTemplateFolderIds,
  toggled,
}: Props) => {
  const [selected, setSelected] = useState<WorkOrderTemplateFolder[]>([]);
  const [applied, setApplied] = useState<WorkOrderTemplateFolder[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const [getWorkTemplateFolders, { loading }] = useLazyQuery<
    { workOrderTemplateFolders: WorkOrderTemplateFoldersConnection },
    { input: WorkOrderTemplateFoldersConnectionInput }
  >(GET_WORK_ORDER_TEMPLATE_FOLDERS, {
    onCompleted: res => {
      const workTemplateFolders = R.pathOr(
        [],
        ['workOrderTemplateFolders', 'workOrderTemplateFolders'],
        res,
      );

      setSelected(workTemplateFolders);
      setApplied(workTemplateFolders);
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (workTemplateFolderIds.size > 0) {
      getWorkTemplateFolders({
        variables: {
          input: {
            ids: Array.from(workTemplateFolderIds),
          },
        },
      });
    }
  }, [workTemplateFolderIds]);

  useEffect(() => {
    const workTemplateFolders = selected.filter(({ id }) =>
      workTemplateFolderIds.has(id),
    );

    setSelected(workTemplateFolders);
    setApplied(workTemplateFolders);
  }, [workTemplateFolderIds]);

  const handleToggle = (folder?: WorkOrderTemplateFolder) => {
    const isSelected = selected.some(({ id }) => folder.id === id);

    setSelected(
      isSelected
        ? selected.filter(({ id }) => id !== folder.id)
        : [...selected, folder],
    );
  };

  const getTopLevelFolders = (folders: WorkOrderTemplateFolder[]): string[] => {
    const subfolders = folders.reduce((prev, folder) => {
      return R.uniq([...prev, ...flattenTreeChildren(folder)]);
    }, []);

    return R.without(
      subfolders.map(({ id }) => id),
      folders.map(({ id }) => id),
    );
  };

  const handleSave = () => {
    const rootSelected = selected.some(folder => folder.id === 'workTypes');
    const selectedWorkTemplateFolderIds = rootSelected
      ? ['workTypes']
      : getTopLevelFolders(selected);

    onWorkTemplateFoldersUpdate(new Set(selectedWorkTemplateFolderIds));
    setOpen(false);
  };

  const handleReset = () => {
    onWorkTemplateFoldersUpdate(new Set([]));
  };

  const handleClear = () => {
    setSelected([]);
  };

  const handleCancel = () => {
    setSelected(applied);
    setOpen(false);
  };

  const selectedIds = useMemo(() => {
    return new Set(selected.map(({ id }) => id));
  }, [selected]);

  const styles = getStyles(toggled);

  return (
    <>
      <div styleName="filter-container-label">Work Template Folder</div>
      <div styleName="button-container">
        <Button
          style={styles.button}
          size="small"
          onClick={() => setOpen(true)}
          disabled={loading || !toggled}
          data-cy="mapFilterFolderSelect"
        >
          select
        </Button>
        {workTemplateFolderIds.size > 0 && (
          <>
            <span styleName="button-divider">|</span>
            <Button
              style={{ ...styles.button, marginRight: '1rem' }}
              size="small"
              disabled={loading || !toggled}
              onClick={handleReset}
              data-cy="mapFilterFolderClear"
            >
              clear all
            </Button>
          </>
        )}
      </div>
      <div styleName="filter-values">
        {loading && (
          <Progress size={20} style={{ justifyContent: 'flex-start' }} />
        )}
        {workTemplateFolderIds.has('workTypes')
          ? 'All Work Folders'
          : applied
              .filter(({ id }) => workTemplateFolderIds.has(id))
              .map(({ name }) => name)
              .join(', ')}
      </div>
      <Modal
        title="Select Work Template Folder"
        open={open}
        onCancel={handleCancel}
        onConfirm={handleSave}
        onClear={handleClear}
        contentStyle={styles.modal}
        confirmButtonText="Ok"
      >
        <WorkTemplateTree
          onFolderToggle={template => handleToggle(template)}
          getFolderSelected={folder => selectedIds.has(folder.id)}
          autoSelectChildFolders
          hideTemplates
          includeSearch={false}
        />
      </Modal>
    </>
  );
};

export default MapDrawerWorkTemplateFolderFilter;
