import * as React from 'react';

import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import accountUtilities from '@atom/utilities/accountUtilities';

import './login.css';

interface Organization {
  domain: string;
  id: string;
  isActive: boolean;
  isVerified: boolean;
  name: string;
  role: string;
}

interface Props {
  organization: Organization;
}

const styles = {
  icon: {
    color: colors.brand.blue,
  },
};

class TenantSelectionRow extends React.Component<Props> {
  navigate = async () => {
    const { organization } = this.props;
    const protocol = window.location.protocol;

    const token = accountUtilities.getToken();

    window.location.href = `${protocol}//${organization.domain}/callback/${token}?tenant=${organization.name}`;
  };

  render() {
    const { organization } = this.props;
    const organizationName = organization.name.toLocaleUpperCase();

    return (
      <div styleName="tenant-selection-row-container" onClick={this.navigate}>
        <div styleName="tenant-selection-icon">
          <Icon className="material-icons" style={styles.icon}>
            business
          </Icon>
        </div>
        <div styleName="tenant-selection-name-container">
          <div styleName="tenant-selection-name">{organizationName}</div>
          <div styleName="tenant-selection-domain">{organization.domain}</div>
        </div>
        <div styleName="tenant-navigation-button-container">
          <div styleName="tenant-selection-button">continue</div>
        </div>
      </div>
    );
  }
}

export default TenantSelectionRow;
