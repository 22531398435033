import React from 'react';

import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import { WorkOrderTab } from '@atom/types/work';

import './workOrderDetailHeader.css';

export interface Props {
  view: {
    value: WorkOrderTab;
    label: string;
    icon: string;
    dataCy: string;
  };
  activeView: WorkOrderTab;
  setActiveView: (view: WorkOrderTab) => void;
}

const DetailHeaderButton = ({ view, activeView, setActiveView }: Props) => {
  const { value, label, icon, dataCy } = view;

  const styleName =
    activeView === value ? 'icon-container active' : 'icon-container';

  return (
    <div
      onClick={() => setActiveView(value)}
      styleName={styleName}
      data-cy={dataCy}
    >
      <div styleName="icon">
        <Icon color={colors.neutral.white} children={icon} />
      </div>
      <div styleName="label">{label}</div>
    </div>
  );
};

export default DetailHeaderButton;
