import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import TaskItemTile from '@atom/components/common/workOrderDetail/taskItemTile/TaskItemTile';
import WorkOrderContext from '@atom/components/workOrderDetail/WorkOrderContext';
import { TASK_ASSET_REMOVE } from '@atom/graph/task';
import { usePreferences } from '@atom/hooks/usePreferences';
import { useWorkValidations } from '@atom/hooks/useWorkValidations';
import { Icon, IconButton, Snackbar } from '@atom/mui';
import { TaskAssetRemoveInput } from '@atom/types/task';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import history from '@atom/utilities/history';
import iconUtilities from '@atom/utilities/iconUtilities';
import markerUtilities from '@atom/utilities/markerUtilities';

import './assetSection.css';

interface Props {
  assetId: string;
}

const styles = {
  icon: {
    height: '24px',
    width: '24px',
  },
};

const AssetTile = ({ assetId }: Props) => {
  const preferences = usePreferences();
  const navigateToAssetDetail = R.pathOr(
    false,
    ['workOrders', 'navigateToAssetDetail'],
    preferences,
  );

  const {
    workOrderDetail,
    refetch,
    task,
    setActiveWorkOrderAsset,
  } = useContext(WorkOrderContext);

  const { workValidations } = useWorkValidations();

  const [open, setOpen] = useState<boolean>(false);

  const [deleteAsset] = useMutation<
    { taskAssetRemove: boolean },
    { input: TaskAssetRemoveInput }
  >(TASK_ASSET_REMOVE);

  const removeAsset = async () => {
    setOpen(false);

    Snackbar.info({
      message: 'Deleting...',
    });

    await deleteAsset({
      variables: {
        input: {
          workOrderId: workOrderDetail.id,
          taskId: task.id,
          assetId,
        },
      },
    });

    Snackbar.info({
      message: `Removed Asset.`,
    });

    refetch();
  };

  const asset = workOrderDetail.assets[assetId];
  const hasPendingChanges = R.pathOr(false, ['hasPendingChanges'])(asset);
  const isEditDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  const handleClickAsset = (): void => {
    if (navigateToAssetDetail) {
      // If this is a root asset, go to that inventory page
      // - otherwise, go to the subItems tab of the rootAncestorAsset inventory page
      if (R.isEmpty(asset.ancestors)) {
        history.push(`/inventory/${assetId}`);
      } else {
        const rootAncestorAssetId = asset.ancestors[0]?.id;
        if (rootAncestorAssetId) {
          history.push(`/inventory/${rootAncestorAssetId}?activeView=subItems`);
        }
      }
      return;
    }
    setActiveWorkOrderAsset(assetId);
  };

  const icon = (
    <div styleName="change-indicator-container">
      {workOrderDetail.inventoryAssetId === assetId ? (
        <img
          src={markerUtilities.getInactiveAssetIconFromSchemaMarkerId(
            workOrderDetail.inventoryAssetMarkerId,
          )}
          style={styles.icon}
        />
      ) : (
        iconUtilities.getDataManagementElementIcon(null, false, styles.icon)
      )}
      {hasPendingChanges && <div styleName="change-indicator" />}
    </div>
  );

  const actionButton = isEditDisabled ? (
    <div />
  ) : (
    <IconButton onClick={() => setOpen(true)} size="small" tooltip="Remove">
      <Icon>close</Icon>
    </IconButton>
  );

  const hasError = R.has(assetId)(
    R.pathOr(
      {},
      ['taskValidations', task.id, 'invalidAssets'],
      workValidations,
    ),
  );

  return (
    <>
      <TaskItemTile
        content={R.pathOr('', ['name'], asset)}
        onTileClick={handleClickAsset}
        icon={icon}
        actionButton={actionButton}
        hasError={hasError}
      />
      <DeleteModal
        open={open}
        onConfirm={removeAsset}
        onCancel={() => setOpen(false)}
        title="Remove Asset From Task"
        content="Are you sure you want to remove this asset from the task?"
        cancelText="Cancel"
        confirmText="Remove"
      />
    </>
  );
};

export default AssetTile;
