import * as React from 'react';

import { Summary } from '@atom/types/work';

import './workSummaryTray.css';

interface Props {
  summary: Summary[];
}

const WorkSummarySection = ({ summary }: Props) => {
  return (
    <div styleName="summary-section-container">
      {summary.map((section, index) => (
        <div styleName="summary-section" key={index}>
          <div styleName="summary-section-title">{section.title}</div>
          <div styleName="summary-section-contents">{section.contents}</div>
        </div>
      ))}
    </div>
  );
};

export default WorkSummarySection;
