import * as React from 'react';

import DetailRow from '@atom/components/common/map/mapDrawerContent/DetailRow';
import SectionHeading from '@atom/components/common/map/mapDrawerContent/SectionHeading';
import { WorkOrderDetailType } from '@atom/types/work';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';
import timeUtilities from '@atom/utilities/timeUtilities';

import WorkSummarySection from './WorkSummarySection';

import './workSummaryTray.css';

interface Props {
  work: WorkOrderDetailType;
}

const WorkSummaryInfo = ({ work }: Props) => {
  return (
    <div styleName="info-container">
      <SectionHeading title="Details" />
      <div styleName="info-detail-section">
        <DetailRow label="Asset Name" value={work.inventoryAssetName} />
        <DetailRow
          label="Lat & Long"
          value={`${textDisplayUtilities.latLong(
            // @ts-ignore
            work.location.coordinates[1],
          )}, ${textDisplayUtilities.latLong(
            // @ts-ignore
            work.location.coordinates[0],
          )}`}
        />
        <DetailRow
          label="Due Date"
          value={timeUtilities.setDisplayDate(work.dueDate)}
        />
        <DetailRow
          label="Completed Date"
          value={timeUtilities.setDisplayDate(work.completionDate)}
        />
        <DetailRow
          label="Estimated Cost"
          value={numberToLocaleString(work.estimatedCost)}
        />
      </div>
      <SectionHeading title="Summary" />
      <WorkSummarySection summary={work.summary} />
    </div>
  );
};

export default WorkSummaryInfo;
