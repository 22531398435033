import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';
/**
 * @param {any} [state=initialState.user] The current state
 * @param {any} action The dispatched action
 * @returns {any} The updated state
 */

export default function userProfileReducer(
  state = initialState.userProfile,
  action,
) {
  switch (action.type) {
    case actionTypes.LOGGED_IN_USER_GET_SUCCESS: {
      return {
        ...state,
        userId: action.data.id,
        role: action.data.role,
        isVerified: action.data.isVerified,
        isActive: action.data.isActive,
        email: action.data.email,
        firstName: action.data.firstName,
        lastName: action.data.lastName,
        department: action.data.department,
        title: action.data.title,
        phoneNumber: action.data.phoneNumber,
        profileImage: action.data.photoUrl || state.profileImage,
        location: action.data.location,
        defaultLocation: action.data.defaultLocation,
      };
    }
    default: {
      return state;
    }
  }
}
