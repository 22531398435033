import { gql } from '@apollo/client';

export const GET_KML_LAYERS = gql`
  query kmlLayers {
    kmlLayers {
      id
      name
      markerId
      parser
      url
      pollingInterval
      suppressInfoWindows
      extendedData {
        type
        key
        name
      }
    }
  }
`;
