import * as React from 'react';

import WorkOrderIcon from '@atom/components/common/svgIcons/work';
import WorkOrderStatusPill from '@atom/components/common/workOrderDetail/workOrderStatusPill/WorkOrderStatusPill';
import { MapWorkOrder } from '@atom/types/map';
import timeUtilities from '@atom/utilities/timeUtilities';

import './drawerContent.css';

interface Props {
  workOrder: MapWorkOrder;
}

const WorkOrderListItem = ({ workOrder }: Props) => {
  const { name, statusId, dueDate, assigneeCount, taskCount } = workOrder;

  return (
    <div styleName="list-item-v2">
      <div styleName="icon-container">
        <WorkOrderIcon status={+statusId} />
      </div>
      <div styleName="description-container">
        <div styleName="subheading">{name}</div>
        <div style={{ margin: '-6px 0 -1px' }}>
          <WorkOrderStatusPill
            statusId={statusId}
            className="dashboard-map-list"
          />
        </div>
        <div styleName="details-container">
          <div styleName="secondary">
            Due Date: {timeUtilities.setDisplayDate(dueDate)}
          </div>
          <div styleName="secondary">Team: {assigneeCount}</div>
          <div styleName="secondary">Tasks: {taskCount}</div>
        </div>
      </div>
    </div>
  );
};

export default WorkOrderListItem;
