import { gql } from '@apollo/client';

export const SEARCH_CATEGORIES = gql`
  query categoriesSearch($input: SearchCategoriesConnectionInput!) {
    categoriesSearch(input: $input) {
      totalCount
      categories {
        id
        name
        schemaId
        colorId
        categoryPath {
          id
          name
        }
      }
    }
  }
`;
