import * as R from 'ramda';

import { reducerWrapper } from '@atom/utilities/stateUtilities';

export const baseActionTypes = {
  TOGGLE: 'TOGGLE',
  SET: 'SET',
  SET_ONCE: 'SET_ONCE',
  MERGE: 'MERGE',
};

// This is required for actions that can only happen once for selected key within one browser session
// In this scenario, whenever user comes back to dashboard page by clicking back, this is going to prevent setting layer filters to "all"
const tapIntoLocalstorage = key => () => {
  const storageKey = `${key}_SET`;
  const value = localStorage.getItem(storageKey);
  if (!value) {
    localStorage.setItem(storageKey, 'true');
    window.addEventListener('beforeunload', () => {
      localStorage.removeItem(storageKey);
    });
  }
  return !!value;
};

const baseReducer = (initialState: object = {}, extraActions: object = {}) =>
  reducerWrapper(
    {
      [baseActionTypes.TOGGLE]: data =>
        R.over(R.lensPath(data.split('.')), R.not),
      [baseActionTypes.SET]: ({ key, data }) =>
        R.set(R.lensPath(key.split('.')), data),
      [baseActionTypes.SET_ONCE]: ({ key, data }) =>
        R.unless(
          tapIntoLocalstorage(key),
          R.set(R.lensPath(key.split('.')), data),
        ),
      [baseActionTypes.MERGE]: R.mergeDeepLeft,
      ...extraActions,
    },
    initialState,
  );

export default baseReducer;
