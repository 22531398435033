import React, { useMemo } from 'react';
import * as R from 'ramda';

import { useUserProfile } from '@atom/hooks/useUserProfile';
import { Icon, IconButton } from '@atom/mui';
import { MaterialEntry } from '@atom/types/materialEntry';
import { TaskMaterialItem, TaskUser } from '@atom/types/task';
import { WorkOrderDetailType } from '@atom/types/work';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { setDisplayDate } from '@atom/utilities/timeUtilities';

import './materialModal.css';

export interface Props {
  materialEntry: MaterialEntry;
  material: TaskMaterialItem;
  workOrderDetail: WorkOrderDetailType;
  availableUsers: TaskUser[];
  editMaterialEntryId: string;
  createMaterialEntry: boolean;
  setEditMaterialEntryId: (id: string) => void;
  handleMaterialEntryDelete: (id: string) => void;
}

const MaterialEntryDisplayRow = ({
  materialEntry,
  availableUsers,
  material,
  workOrderDetail,
  editMaterialEntryId,
  createMaterialEntry,
  setEditMaterialEntryId,
  handleMaterialEntryDelete,
}: Props) => {
  const userProfile = useUserProfile();

  const convertNumberToLocaleString = (input: string | number): string => {
    return Number(input).toLocaleString('en-US');
  };

  const user = availableUsers.find(
    availableUser => availableUser.id === materialEntry.userId,
  );

  const cost = useMemo(() => {
    const rawCost =
      R.pathOr(0, ['rate'], material) * R.pathOr(0, ['amount'], materialEntry);

    return numberToLocaleString(rawCost.toFixed(2));
  }, [material, materialEntry]);

  const canDeleteOrEdit =
    userProfile.userId === materialEntry.userId
      ? hasRolePermissions(ROLE_SETS.INSPECTOR)
      : hasRolePermissions(ROLE_SETS.MANAGER);

  const isEditDisabled =
    !!editMaterialEntryId ||
    createMaterialEntry ||
    workOrderDetail.isClosed ||
    !canDeleteOrEdit;
  const isDeleteDisabled =
    !!editMaterialEntryId ||
    createMaterialEntry ||
    workOrderDetail.isClosed ||
    !canDeleteOrEdit;

  return (
    <div styleName="entry-row">
      <div styleName="entry-info-section">
        <div styleName="display-container name">
          <div styleName="label">Team Member</div>
          <div> {`${user?.firstName} ${user?.lastName}`}</div>
        </div>
        <div styleName="display-container">
          <div styleName="label">Date</div>
          <div>{setDisplayDate(materialEntry?.date)}</div>
        </div>
        {material?.isStartEndReading && (
          <>
            <div styleName="display-container">
              <div styleName="label">Start Reading</div>
              <div>{materialEntry?.startReading ?? <span>&ndash;</span>}</div>
            </div>
            <div styleName="display-container">
              <div styleName="label">End Reading</div>
              <div>{materialEntry?.endReading ?? <span>&ndash;</span>}</div>
            </div>
          </>
        )}
        <div styleName="display-container">
          <div styleName="label">
            <span>Used (</span>
            <span styleName="material-unit">{material?.unit}</span>
            <span>)</span>
          </div>
          <div>
            {convertNumberToLocaleString(materialEntry?.amount.toFixed(2))}
          </div>
        </div>
        <div styleName="display-container cost">
          <div styleName="label">Cost</div>
          <div>{cost}</div>
        </div>
      </div>
      <div styleName="entry-button-section">
        <IconButton
          tooltip="Edit"
          onClick={() => setEditMaterialEntryId(materialEntry.id)}
          disabled={isEditDisabled}
        >
          <Icon>edit</Icon>
        </IconButton>
        <IconButton
          tooltip="Delete"
          onClick={() => handleMaterialEntryDelete(materialEntry.id)}
          disabled={isDeleteDisabled}
        >
          <Icon>delete</Icon>
        </IconButton>
      </div>
    </div>
  );
};

export default MaterialEntryDisplayRow;
