import React from 'react';

import { Button, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';

const styles = {
  bodyStyle: {
    margin: 0,
    padding: '0 1.125rem',
  },
  title: {
    fontSize: fonts.xl,
    padding: '1.125rem',
    fontWeight: '500',
    color: colors.brand.red,
  },
  textStyle: {
    fontSize: fonts.md,
    color: colors.neutral.dark,
  },
};

interface Props {
  open: boolean;
  closeModal: () => void;
}

const InvalidFormModal = ({ open, closeModal }: Props) => {
  const getFooter = () => {
    return <Button onClick={() => closeModal()}>Done</Button>;
  };

  const titleText = 'Could not complete task';
  const contentText =
    'There are invalid fields in your forms. Please fix them before you complete a task';

  return (
    <Modal
      title={titleText}
      DialogTitleProps={{
        sx: {
          color: colors.brand.red,
        },
      }}
      footer={getFooter()}
      style={styles.bodyStyle}
      open={open}
    >
      <div style={styles.textStyle}>{contentText}</div>
    </Modal>
  );
};

export default InvalidFormModal;
