import * as React from 'react';

import { InventorySchemaActions } from '@atom/types/actions';
import iconUtilities from '@atom/utilities/iconUtilities';

import FhwaSchemaElementListRow from './FhwaSchemaElementListRow';

import './elements.css';

const RIGHT_ARROW = 'right-arrow';
const DOWN_ARROW = 'down-arrow';
const VISIBLE = 'inherit';
const HIDDEN = 'none';

interface ElementGroup {
  name: string;
  elements: any[];
}

interface Props {
  elementGroup: ElementGroup;
  selectedItems: Set<string>;
  schemaQuantities: object;
  onClick: (id?: string) => any;
  elementGroupIndex?: number;
  inventorySchemaActions: InventorySchemaActions;
  loadingSchemaTreeNode: boolean;
  elementExistsOnAsset: boolean;
}

class FhwaSchemaElementGroupSection extends React.Component<Props> {
  state = {
    icon: iconUtilities.leftIcon(RIGHT_ARROW),
    expanded: HIDDEN,
  };

  toggleSection = () => {
    const { expanded } = this.state;

    if (expanded === HIDDEN) {
      this.setState({
        icon: iconUtilities.leftIcon(DOWN_ARROW),
        expanded: VISIBLE,
      });
    } else {
      this.setState({
        icon: iconUtilities.leftIcon(RIGHT_ARROW),
        expanded: HIDDEN,
      });
    }
  };

  render() {
    const {
      elementGroup,
      selectedItems,
      onClick,
      schemaQuantities,
      elementGroupIndex,
      inventorySchemaActions,
      loadingSchemaTreeNode,
      elementExistsOnAsset,
    } = this.props;
    const { icon, expanded } = this.state;

    return (
      <div>
        <div styleName="group-container modal" onClick={this.toggleSection}>
          <div styleName="group-icon">{icon}</div>
          <div styleName="group-label">{elementGroup.name}</div>
        </div>
        <div styleName="element-list-container" style={{ display: expanded }}>
          {elementGroup.elements.map((element, elementIndex) => (
            <FhwaSchemaElementListRow
              isChecked={selectedItems.has(element.id)}
              inventorySchemaActions={inventorySchemaActions}
              loading={loadingSchemaTreeNode}
              key={element.id}
              element={element}
              value={schemaQuantities[element.id]}
              selectedItems={selectedItems}
              schemaQuantities={schemaQuantities}
              onClick={onClick}
              elementPath={[
                'elementGroups',
                elementGroupIndex,
                'elements',
                elementIndex,
              ]}
              elementExistsOnAsset={elementExistsOnAsset}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default FhwaSchemaElementGroupSection;
