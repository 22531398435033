import React, { useContext, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';

import AssignedRolesTable from '@atom/components/common/assignedRoles/AssignedRolesTable';
import AssignRolesModal from '@atom/components/common/assignedRoles/AssignRolesModal';
import { USER_UPDATE } from '@atom/graph/user';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { UserDetail, UserUpdateInput } from '@atom/types/user';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import UserContext from '../UserContext';

import './rolesTab.css';

const RolesTab = () => {
  const {
    user: { id, roles, inheritedRoles },
    refetch,
  } = useContext(UserContext);

  const [addRolesModalOpen, setAddRolesModalOpen] = useState<boolean>(false);

  const [updateUser, { loading }] = useMutation<
    { userUpdate: UserDetail },
    { input: UserUpdateInput }
  >(USER_UPDATE);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleAssignRoles = async (roles: string[]) => {
    await updateUser({
      variables: {
        input: {
          id,
          roles,
        },
      },
    });

    setAddRolesModalOpen(false);
    refetch();
  };

  // set of roles applied directly to the user (not inherited)
  const userRoleIds = useMemo(() => {
    return new Set(
      roles.filter(role => !role?.inheritedFrom?.length).map(role => role.id),
    );
  }, [roles, inheritedRoles]);

  const handleDelete = (roleId: string) => {
    handleAssignRoles(
      roles.filter(role => role.id !== roleId).map(role => role.id),
    );
  };

  return (
    <>
      <div styleName="header-container">
        <div>{!roles.length ? 'No roles' : `${roles.length} roles`}</div>
        {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
          <IconButton onClick={() => setAddRolesModalOpen(true)}>
            <Icon>add</Icon>
          </IconButton>
        )}
      </div>
      {!roles.length ? (
        <div styleName="no-roles-container">
          <Icon color={colors.neutral.silver} style={{ fontSize: '80px' }}>
            account_circle
          </Icon>
          <div styleName="no-roles">There are no roles</div>
        </div>
      ) : (
        <AssignedRolesTable
          roles={[...roles, ...inheritedRoles]}
          onDelete={handleDelete}
        />
      )}
      <AssignRolesModal
        open={addRolesModalOpen}
        onClose={() => setAddRolesModalOpen(false)}
        onConfirm={handleAssignRoles}
        loading={loading}
        existingRoles={userRoleIds}
      />
    </>
  );
};

export default RolesTab;
