import React, { useContext, useMemo } from 'react';

import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { useSchemas } from '@atom/hooks/useSchemas';
import { removeFromSet, toggleFromSet } from '@atom/utilities/setUtilities';
import { WorkTypeVariant } from '@atom/utilities/workTemplateUtilities';

import SchemaRow from './SchemaRow';

import './multiSchemaSection.css';

interface Props {
  allowedMultiAssetSchemaIds: Set<string>;
  setAllowedMultiAssetSchemaIds: (
    allowedMultiAssetSchemaIds: Set<string>,
  ) => void;
  requiredMultiAssetSchemaIds: Set<string>;
  setRequiredMultiAssetSchemaIds: (
    requiredMultiAssetSchemaIds: Set<string>,
  ) => void;
}

const AllowedSchemaView = ({
  allowedMultiAssetSchemaIds,
  setAllowedMultiAssetSchemaIds,
  requiredMultiAssetSchemaIds,
  setRequiredMultiAssetSchemaIds,
}: Props) => {
  const { workTypeVariant } = useContext(WorkTemplateContext);

  const { schemas = [] } = useSchemas();

  const locationBasedSchemas = useMemo(() => {
    return schemas.filter(schema => !schema.isMaterial);
  }, [schemas]);

  const onSchemaClick = (schemaId: string) => {
    // If allowed schema is being toggled off, also remove it from required Set
    if (allowedMultiAssetSchemaIds.has(schemaId)) {
      setRequiredMultiAssetSchemaIds(
        removeFromSet(requiredMultiAssetSchemaIds, schemaId),
      );
    }

    setAllowedMultiAssetSchemaIds(
      toggleFromSet(allowedMultiAssetSchemaIds, schemaId),
    );
  };

  const disabled = workTypeVariant === WorkTypeVariant.CHILD;

  return (
    <>
      <div>
        <div styleName="view-title">Allowed Asset Types</div>
        <div styleName="view-subtext">
          Only assets of the allowed asset types can be added on the task. At
          least 1 asset type needs to be allowed when Assets section is enabled.
        </div>
      </div>
      <div>
        {locationBasedSchemas.map(schema => (
          <SchemaRow
            key={schema?.id}
            schema={schema}
            onClick={onSchemaClick}
            checked={allowedMultiAssetSchemaIds.has(schema?.id)}
            disabled={disabled}
          />
        ))}
      </div>
    </>
  );
};

export default AllowedSchemaView;
