import { GeoJSONPoint } from './geojson';

export enum MediaType {
  DOCUMENT = 'document',
  IMAGE = 'image',
  VIDEO = 'video',
  REPORT = 'report',
}

export enum SubjectType {
  WORK_ORDER = 'workOrder',
  WORK_ORDER_ASSET = 'workOrderAsset',
  WORK_ORDER_TEMPLATE = 'workOrderTemplate',
  INVENTORY_ASSET = 'inventoryAsset',
  FORM_INSTANCE_FIELD = 'formInstanceField',
  FORM_INSTANCE_ASSET = 'formInstanceAsset',
}

export interface MediaTotalDocumentsType {
  name: 'Documents';
  count: number;
}

export interface MediaTotalImagesType {
  name: 'Photos';
  count: number;
}

export interface MediaTotalVideosType {
  name: 'Videos';
  count: number;
}

export interface MediaItem {
  createdBy: string;
  createdDate: number;
  subjectId: string;
  subjectType: SubjectType;
  parentSubjectId: string;
  location: GeoJSONPoint;
  capturedDate?: number;
  id: string;
  fileId: string;
  fieldId?: string;
  type: MediaType;
  name: string;
  fileExtension: string;
  description: string;
  folderId: string;
}

export interface FolderItem {
  id: string;
  name: string;
  parentFolderId?: string;
  ancestors?: string[];
  createdDate: number;
  updatedDate: number;
  createdBy: string;
  updatedBy: string;
}

export interface MediaFolderTree {
  id: string;
  name: string;
  children?: MediaFolderTree[];
  ancestors?: string[];
  // used client side to track pending additions
  parentId?: string;
}

export interface Media {
  id: string;
  name: string;
  type: MediaType;
  fileId: string;
  fileExtension: string;
  location: GeoJSONPoint;
  description: string;
  parentSubjectId: string;
  subjectId: string;
  subjectType: SubjectType;
  fieldId?: string;
  folderId: string;
  folderPath: string[];
  capturedDate?: number;
  createdDate: number;
  createdBy: {
    firstName: string;
    lastName: string;
  };
  updatedDate: number;
  updatedBy: {
    firstName: string;
    lastName: string;
  };
}

export interface MediaConnection {
  totalCount: number;
  media: MediaItem[];
}

export interface MediaConnectionInput {
  page?: number;
  limit?: number;
  ids?: string[];
  parentSubjectId?: string;
  parentSubjectIds?: string[];
  subjectIds?: string[];
  subjectTypes?: SubjectType[];
  folderId?: string;
  type?: MediaType;
  name?: String;
}

export interface MediaUpdateInput {
  id: string;
  name?: string;
  description?: string;
  capturedDate?: number;
}

export interface MediaBulkDeleteInput {
  ids: string[];
}
