import { gql } from '@apollo/client';

export const BASE_BOUNDING_BOX = gql`
  fragment BaseBoundingBox on BoundingBox {
    xMin
    xMax
    yMin
    yMax
  }
`;

export const GET_WORK_ORDER_BOUNDING_BOX = gql`
  query workOrderBoundingBox($input: WorkOrderBoundingBoxInput!) {
    workOrderBoundingBox(input: $input) {
      ...BaseBoundingBox
    }
  }
  ${BASE_BOUNDING_BOX}
`;

export const GET_TASK_BOUNDING_BOX = gql`
  query taskBoundingBox($input: TaskBoundingBoxInput!) {
    taskBoundingBox(input: $input) {
      ...BaseBoundingBox
    }
  }
  ${BASE_BOUNDING_BOX}
`;

export const GET_MAP = gql`
  query map($input: MapInput!) {
    map(input: $input) {
      clusters {
        id
        location
        count
      }
      assets {
        id
        name
        location
        assetType
        markerId
        updatedDate
      }
      assetsCount
      workOrders {
        id
        name
        inventoryAssetName
        dueDate
        location
        statusId
        isClosed
        taskCount
        assigneeCount
      }
      workOrdersCount
      users {
        id
        email
        firstName
        lastName
        isVerified
        isActive
        photoUrl
        location
        role
      }
      usersCount
    }
  }
`;
