import { ErrorResponse } from '@apollo/client/link/error';
import { AxiosError } from 'axios';
import * as R from 'ramda';

import { Snackbar } from '@atom/mui';
import history from '@atom/utilities/history';

const GQL_OPERATION_DEFINITION = 'OperationDefinition';

// Any routes that the interceptors should ignore completely.
// This list will be used to regex match against the route and
// remove it from the error handler flow.
const ignoredRoutes = ['/profile'];

enum HTTPMethods {
  GET = 'get',
  POST = 'post',
  PATCH = 'patch',
  PUT = 'put',
  DELETE = 'delete',
}

enum GQLMethods {
  QUERY = 'query',
  MUTATION = 'mutation',
}

export const handleRetrieveMethodErrors = (status: number) => {
  switch (true) {
    case status === 403:
      return history.push('/no-permission');
    case status === 404:
      return history.push('/not-found');
    case status >= 500:
      return history.push('/unknown-error');
    default:
      return '';
  }
};

export const handleActionMethodErrors = (status: number) => {
  switch (true) {
    case status === 403:
      return Snackbar.error({
        message: `You don't have permission to complete this action.`,
      });
    case status === 404:
      return Snackbar.error({
        message: `The resource could not be found. It may have been deleted.`,
      });
    default:
      return '';
  }
};

export const handleErrors = (
  method: HTTPMethods | GQLMethods,
  status: number,
) => {
  switch (method) {
    case GQLMethods.QUERY:
      return handleRetrieveMethodErrors(status);
    case HTTPMethods.GET:
      return handleRetrieveMethodErrors(status);
    case GQLMethods.MUTATION:
      handleActionMethodErrors(status);
      return '';
    case HTTPMethods.POST:
      handleActionMethodErrors(status);
      return '';
    case HTTPMethods.PATCH:
      handleActionMethodErrors(status);
      return '';
    case HTTPMethods.PUT:
      handleActionMethodErrors(status);
      return '';
    case HTTPMethods.DELETE:
      handleActionMethodErrors(status);
      return '';
    default:
      return '';
  }
};

const containsIgnoredRoute = (route: string) => {
  return ignoredRoutes.reduce((acc, ignoredRoute) => {
    return acc || R.includes(ignoredRoute, route);
  }, false);
};

export const interceptAxiosError = (error: AxiosError) => {
  if (error && error?.isAxiosError) {
    const doesNotContainIgnoredRoute = !containsIgnoredRoute(
      error?.request?.responseURL,
    );

    const status = error?.response?.status;
    const method = error?.config?.method;

    if (method && status && doesNotContainIgnoredRoute) {
      // @ts-ignore
      handleErrors(method, status);
    }
  }
};

export const interceptApolloError = (error: ErrorResponse) => {
  // @ts-ignore
  const status = error?.networkError?.statusCode;
  const method = error?.operation?.query?.definitions.find(
    definition => definition.kind === GQL_OPERATION_DEFINITION,
    // @ts-ignore
  )?.operation;

  if (method && status) {
    handleErrors(method, status);
  }
};
