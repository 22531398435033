import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function formInstanceReducer(
  state = initialState.exportTemplates,
  action,
) {
  const { data, type } = action;
  switch (type) {
    case actionTypes.GET_EXPORT_TEMPLATES_SUCCESS: {
      return data;
    }
    case actionTypes.DELETE_EXPORT_TEMPLATE_SUCCESS: {
      return state.filter(exportTemplate => exportTemplate.id !== data.id);
    }
    default: {
      return state;
    }
  }
}
