import React, { useContext, useState } from 'react';
import * as R from 'ramda';

import AddTeamModal from '@atom/components/common/workOrderDetail/addTeamModal/AddTeamModal';
import { Icon, IconButton } from '@atom/mui';
import { sortTaskUsersByName } from '@atom/selectors/taskSelectors';
import { Task } from '@atom/types/task';
import { UserDetail } from '@atom/types/user';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';

import BulkContext from '../../../../BulkContext';

import TaskUserTile from './TaskUserTile';

import './teamSection.css';

export interface Props {
  taskIndex: number;
}

const TeamSection = ({ taskIndex }: Props) => {
  const { workOrder, setWorkOrder } = useContext(BulkContext);
  const { tasks } = workOrder;
  const task = tasks[taskIndex];

  const [expanded, setExpanded] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  const handleAddUsers = (users: UserDetail[]) => {
    const newUsers = [
      ...task.users,
      ...users.map(
        ({
          id,
          firstName,
          lastName,
          photoUrl,
          title,
          email,
          employeeId,
          status,
        }: UserDetail) => ({
          id,
          firstName,
          lastName,
          photoUrl,
          title,
          email,
          employeeId,
          status,
        }),
      ),
    ];

    const newWorkOrder = {
      ...workOrder,
      tasks: tasks.map((taskItem: Task, indexValue: number) =>
        indexValue === taskIndex ? { ...taskItem, users: newUsers } : taskItem,
      ),
    };

    setWorkOrder(newWorkOrder);
  };

  const isUserListEmpty = R.isEmpty(task.users);

  const totalCount = R.length(task.users);
  const icon = expanded ? 'expand_less' : 'expand_more';

  const sortedUsers = sortTaskUsersByName(task.users);
  const isEditDisabled = doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  const usersOnTask = task.users.map(user => user.id);

  return (
    <>
      <div styleName="team-header-container">
        <div styleName="team-left">
          <div styleName="section-title">Team Members</div>
          <div styleName="total-count">{totalCount}</div>
        </div>
        <div styleName="team-right">
          {expanded && (
            <IconButton
              onClick={() => setOpen(true)}
              edge="end"
              tooltip="Add Team"
              disabled={isEditDisabled}
            >
              <Icon>add</Icon>
            </IconButton>
          )}
          <IconButton onClick={() => setExpanded(!expanded)} edge="end">
            <Icon>{icon}</Icon>
          </IconButton>
        </div>
      </div>
      {expanded && (
        <>
          {isUserListEmpty ? (
            <div styleName="empty-section-text">Add Team Members</div>
          ) : (
            <>
              {sortedUsers.map((user, index) => (
                <TaskUserTile key={index} user={user} taskIndex={taskIndex} />
              ))}
            </>
          )}
        </>
      )}
      <AddTeamModal
        open={open}
        userGroupsOnTask={[]}
        usersOnTask={usersOnTask}
        onClose={() => setOpen(false)}
        handleAddUsers={handleAddUsers}
        handleAddUserGroups={() => {}}
        groupsEnabled={false}
        loading={false}
        favoritesEnabled
      />
    </>
  );
};

export default TeamSection;
