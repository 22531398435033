import React from 'react';

import { Icon } from '@atom/mui';

import { FilterForm } from '../AssetWorkOrdersFilterPanel';

import TeamAssignedToFilter from './TeamAssignedToFilters';
import TeamCommonUserFilter from './TeamCommonUserFilter';

import './../assetWorkOrdersFilterPanel.css';

interface Props {
  areFiltersDisabled: boolean;
  teamAssignedToGroupIds: string[];
  teamAssignedToUserIds: string[];
  teamCompletedByIds: string[];
  teamCreatedByIds: string[];
  updateFilterFormData: (field: keyof FilterForm, updatedVal: any) => void;
}

const TeamFiltersSection = ({
  areFiltersDisabled,
  teamAssignedToGroupIds,
  teamAssignedToUserIds,
  teamCompletedByIds,
  teamCreatedByIds,
  updateFilterFormData,
}: Props) => {
  return (
    <div styleName="filter-section">
      <div styleName="filter-section-header">
        <Icon>people</Icon>
        Team
      </div>
      <div styleName="filter-container">
        <TeamAssignedToFilter
          areFiltersDisabled={areFiltersDisabled}
          teamAssignedToGroupIds={teamAssignedToGroupIds}
          teamAssignedToUserIds={teamAssignedToUserIds}
          updateAssignedToGroupIds={updatedGroupIds =>
            updateFilterFormData('teamAssignedToGroupIds', updatedGroupIds)
          }
          updateAssignedToUserIds={updatedUserIds =>
            updateFilterFormData('teamAssignedToUserIds', updatedUserIds)
          }
        />
      </div>
      <div styleName="filter-container">
        <TeamCommonUserFilter
          areFiltersDisabled={areFiltersDisabled}
          placeholder="Search for user"
          label="Completed By"
          usersFilter={teamCompletedByIds}
          updateUsersFilter={updatedFilter =>
            updateFilterFormData('teamCompletedByIds', updatedFilter)
          }
        />
      </div>
      <div styleName="filter-container">
        <TeamCommonUserFilter
          areFiltersDisabled={areFiltersDisabled}
          placeholder="Search for user"
          label="Created By"
          usersFilter={teamCreatedByIds}
          updateUsersFilter={updatedFilter =>
            updateFilterFormData('teamCreatedByIds', updatedFilter)
          }
        />
      </div>
    </div>
  );
};

export default TeamFiltersSection;
