import React from 'react';
import * as R from 'ramda';

import { Icon } from '@atom/components/common/mui';
import { DashboardCard, DashboardCardLoadStyle } from '@atom/types/dashboard';

import { getCardHeight } from '../dashboardUtilities';

import SkeletonListRow from './SkeletonListRow';
import SkeletonTableRow from './SkeletonTableRow';

import '../dashboardCard.css';

interface Props {
  card: DashboardCard;
}

const skeletonRows = (type: DashboardCardLoadStyle) => {
  const row = index =>
    type === 'TABLE' ? (
      <SkeletonTableRow key={index} />
    ) : (
      <SkeletonListRow key={index} />
    );
  return R.times(index => row(index), 12);
};

const CardLoading = ({ card }: Props) => {
  const style = {
    outerBodyChart: {
      height: getCardHeight(card, 48),
      margin: '24px',
    },
    outerBody: {
      height: getCardHeight(card),
    },
    innerBody: {
      display: 'flex',
      alignItems: 'center',
    },
  };
  const content =
    card.loadStyle === 'CHART' ? (
      <div style={style.outerBodyChart} styleName="card-body-loading-chart">
        <div style={style.innerBody}>
          <Icon>bar_chart</Icon>
        </div>
      </div>
    ) : (
      <div style={style.outerBody} styleName="card-body-loading">
        {skeletonRows(card.loadStyle)}
      </div>
    );

  return content;
};

export default CardLoading;
