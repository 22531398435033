import actionTypes from './actionTypes';

export const requestCreateFormTemplate = data => ({
  type: actionTypes.REQUEST_CREATE_FORM_TEMPLATE,
  data,
});

export const createFormTemplateSuccess = data => ({
  type: actionTypes.CREATE_FORM_TEMPLATE_SUCCESS,
  data,
});

export const createFormTemplateFailure = () => ({
  type: actionTypes.CREATE_FORM_TEMPLATE_FAILURE,
});

export const requestDuplicateFormTemplate = data => ({
  type: actionTypes.REQUEST_DUPLICATE_FORM_TEMPLATE,
  data,
});

export const duplicateFormTemplateSuccess = data => ({
  type: actionTypes.DUPLICATE_FORM_TEMPLATE_SUCCESS,
  data,
});

export const duplicateFormTemplateFailure = () => ({
  type: actionTypes.DUPLICATE_FORM_TEMPLATE_FAILURE,
});

export const requestRenameFormTemplate = data => ({
  type: actionTypes.REQUEST_RENAME_FORM_TEMPLATE,
  data,
});

export const renameFormTemplateSuccess = data => ({
  type: actionTypes.RENAME_FORM_TEMPLATE_SUCCESS,
  data,
});

export const renameFormTemplateFailure = () => ({
  type: actionTypes.RENAME_FORM_TEMPLATE_FAILURE,
});

export const updateFormTemplate = data => ({
  type: actionTypes.REQUEST_UPDATE_FORM_TEMPLATE,
  data,
});

export const updateFormTemplateText = data => ({
  type: actionTypes.REQUEST_UPDATE_FORM_TEMPLATE_TEXT,
  data,
});

export const updateFormTemplateSuccess = data => ({
  type: actionTypes.UPDATE_FORM_TEMPLATE_SUCCESS,
  data,
});

export const updateFormTemplateFailure = () => ({
  type: actionTypes.UPDATE_FORM_TEMPLATE_FAILURE,
});

export const getFormTemplate = data => ({
  type: actionTypes.REQUEST_FORM_TEMPLATE,
  data,
});

export const getFormTemplateSuccess = data => ({
  type: actionTypes.FORM_TEMPLATE_GET_SUCCESS,
  data,
});

export const getFormTemplateFailure = () => ({
  type: actionTypes.FORM_TEMPLATE_GET_FAILURE,
});

export const requestClearFormTemplate = () => ({
  type: actionTypes.REQUEST_CLEAR_FORM_TEMPLATE,
});

export const clearFormTemplateSuccess = () => ({
  type: actionTypes.FORM_TEMPLATE_CLEAR_SUCCESS,
});

export const getFormTemplatesTotalSuccess = data => ({
  type: actionTypes.GET_FORM_TEMPLATES_TOTAL_SUCCESS,
  data,
});

export const getFormTemplatesSuccess = data => ({
  type: actionTypes.GET_FORM_TEMPLATES_SUCCESS,
  data,
});

export const getFormTemplatesFailure = () => ({
  type: actionTypes.GET_FORM_TEMPLATES_FAILURE,
});

export const getFormTemplates = data => ({
  type: actionTypes.REQUEST_FORM_TEMPLATES,
  data,
});

export const requestFormTemplateDelete = data => ({
  type: actionTypes.REQUEST_FORM_TEMPLATE_DELETE,
  data,
});

export const deleteFormTemplateSuccess = data => ({
  type: actionTypes.DELETE_FORM_TEMPLATE_SUCCESS,
  data,
});

export const deleteFormTemplateFailure = () => ({
  type: actionTypes.DELETE_FORM_TEMPLATE_FAILURE,
});
