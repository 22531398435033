import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import { WORK_ORDER_BULK_MAP_CREATE } from '@atom/graph/work';
import { Button, Modal, Progress, Snackbar } from '@atom/mui';
import { MapAsset } from '@atom/types/map';
import {
  WorkOrderBulkMapCreateInput,
  WorkOrderDetailType,
} from '@atom/types/work';
import { workOrderToBulkMapCreate } from '@atom/utilities/bulkWorkOrderUtilities';
import { pluralize } from '@atom/utilities/stringUtilities';

import './createModal.css';

export enum Phase {
  CONFIRM = 'confirm',
  CREATING = 'creating',
  CREATED = 'created',
}

export interface Props {
  assets: MapAsset[];
  open: boolean;
  onClose: () => void;
  onFinish: () => void;
  workOrder: WorkOrderDetailType;
}

const styles = {
  progress: {
    marginRight: '0.5rem',
  },
};

const CreateModal = ({ assets, open, onClose, onFinish, workOrder }: Props) => {
  const [phase, setPhase] = useState<Phase>(Phase.CONFIRM);

  useEffect(() => {
    if (open) {
      setPhase(Phase.CONFIRM);
    }
  }, [open]);

  const [bulkCreateWork, { loading }] = useMutation<{
    input: WorkOrderBulkMapCreateInput;
  }>(WORK_ORDER_BULK_MAP_CREATE);

  const createBulkWorkOrders = async () => {
    try {
      const inputWorkOrder = {
        assets,
        ...workOrder,
      };
      await bulkCreateWork({
        variables: {
          // @ts-ignore (it complains about workTemplateId being optional).
          input: { ...workOrderToBulkMapCreate(inputWorkOrder) },
        },
      });
      setPhase(Phase.CREATED);
    } catch (error) {
      Snackbar.error({
        message:
          'Something went wrong. Please try again or contact administrator.',
      });
      setPhase(Phase.CONFIRM);
      onClose();
    }
  };

  const onConfirm = () => {
    setPhase(Phase.CREATING);
    createBulkWorkOrders();
  };

  const isLoading = loading || phase === Phase.CREATING;

  const label = `${assets.length} ${pluralize(assets.length, 'work item')}`;

  const getTitle = () => {
    if (phase === Phase.CREATED) {
      return `Creating ${label}...`;
    }
    const title = `Create ${label}?`;
    if (isLoading) {
      return <div styleName="disabled">{title}</div>;
    }
    return title;
  };

  const getFooter = () => {
    if (phase === Phase.CREATED) {
      return (
        <Button variant="contained" color="primary" onClick={onFinish}>
          OK
        </Button>
      );
    }
    return (
      <>
        <Button disabled={isLoading} onClick={onClose}>
          {phase === Phase.CONFIRM && 'CANCEL'}
          {isLoading && 'DISABLED'}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirm}
          disabled={isLoading}
        >
          {isLoading && (
            <Progress size={16} style={styles.progress} thickness={5} />
          )}
          CREATE
        </Button>
      </>
    );
  };

  const getContent = () => {
    if (phase === Phase.CREATED) {
      return `${label} ${
        assets.length > 1 ? 'are' : 'is'
      } currently being created, and we will send an email to you once the process is finished. This process may take a moment, you may close this window now and continue using Atom.`;
    }
    const content =
      'Creating work in bulk may not be reversible, make sure you have provided the correct information before you continue.';
    if (isLoading) {
      return <div styleName="disabled">{content}</div>;
    }
    return content;
  };

  return (
    <Modal footer={getFooter()} title={getTitle()} open={open}>
      {getContent()}
    </Modal>
  );
};

export default CreateModal;
