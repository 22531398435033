import React from 'react';

import { Select, TextField } from '@atom/mui';
import { DashboardCard, DashboardCardSize } from '@atom/types/dashboard';

import { DASHBOARD_SIZE_OPTIONS } from '../dashboardSettings';

import './customizeDashboardModal.css';

const { MenuItem } = Select;

export interface Props {
  cardData: DashboardCard;
  setCardData: (card: DashboardCard) => void;
  urlErrorMsg: string;
}

const CustomEmbedFormView = ({ cardData, setCardData, urlErrorMsg }: Props) => {
  const updateCardField = (
    field: keyof DashboardCard,
    value: string | DashboardCardSize,
  ) => {
    setCardData({
      ...cardData,
      [field]: value,
    });
  };

  return (
    <div styleName="add-customize-embed-form">
      <TextField
        id="title"
        label="Name"
        value={cardData.title}
        onChange={event => updateCardField('title', event.target.value)}
        required
      />
      <TextField
        id="title"
        label="Embed URL"
        value={cardData.embedLink}
        onChange={event => updateCardField('embedLink', event.target.value)}
        required
        error={urlErrorMsg.length > 0}
        helperText={urlErrorMsg}
      />
      <Select
        label="Card Width"
        value={cardData.size}
        onChange={event => updateCardField('size', event.target.value)}
      >
        {DASHBOARD_SIZE_OPTIONS.map(option => (
          <MenuItem key={option.value} value={option.value}>
            <div>{option.label}</div>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default CustomEmbedFormView;
