import React, { useContext, useState } from 'react';
import * as R from 'ramda';

import UserGroupContext from '@atom/components/userGroupDetail/UserGroupContext';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { UserGroupCertification } from '@atom/types/userGroups';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import AddEditCertModal from './AddEditCertModal';
import UserGroupCertTile from './UserGroupCertTile';

import './userGroupCerts.css';

const getCertHeaderLabel = (
  certifications: UserGroupCertification[],
): string => {
  if (!certifications || !R.length(certifications)) {
    return 'No certification configured';
  }

  if (R.length(certifications) === 1) {
    return '1 certification';
  }

  return `${R.length(certifications)} certifications`;
};

const UserGroupCerts = () => {
  const { userGroup } = useContext(UserGroupContext);

  const [open, setOpen] = useState<boolean>(false);

  const certHeaderLabel = getCertHeaderLabel(userGroup.certifications);

  return (
    <>
      <div styleName="header-container">
        <div>{certHeaderLabel}</div>
        {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
          <IconButton onClick={() => setOpen(true)}>
            <Icon color={colors.neutral.gray}>add</Icon>
          </IconButton>
        )}
      </div>
      <div styleName="cert-list-container">
        {userGroup.certifications.map(
          (certification: UserGroupCertification) => (
            <UserGroupCertTile
              key={certification.id}
              certification={certification}
            />
          ),
        )}
      </div>
      <AddEditCertModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default UserGroupCerts;
