import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DragDrop } from 'react-beautiful-dnd';
import { useMutation } from '@apollo/client';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import {
  DragDropContext,
  Draggable,
  Droppable,
} from '@atom/components/common/dragAndDrop';
import WorkOrderDatePicker from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderDatePicker';
import WorkOrderEnumMultipleField from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderEnumMultipleField';
import WorkOrderEnumSingleField from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderEnumSingleField';
import WorkOrderFieldCreateModal from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderFieldCreateModal';
import WorkOrderFieldUpdateModal from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderFieldUpdateModal';
import WorkOrderHyperlinkField from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderHyperlinkField';
import WorkOrderNumericField from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderNumericField';
import WorkOrderSummary from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderSummary';
import WorkOrderTextField from '@atom/components/common/workOrderDetail/workOrderFields/WorkOrderTextField';
import InheritanceLockIcon from '@atom/components/workTemplate/InheritanceLockIcon';
import WorkTemplateContext, {
  WorkTemplateActionTypes,
} from '@atom/components/workTemplate/WorkTemplateContext';
import {
  WORK_TEMPLATE_FIELD_CREATE,
  WORK_TEMPLATE_FIELD_DELETE,
  WORK_TEMPLATE_FIELD_UPDATE,
  WORK_TEMPLATE_UPDATE,
} from '@atom/graph/workTemplate';
import { Button, Icon, Menu } from '@atom/mui';
import colors from '@atom/styles/colors';
import { DataType } from '@atom/types/dataType';
import {
  WorkOrderField,
  WorkOrderFieldCreatePayload,
  WorkOrderFieldUpdatePayload,
} from '@atom/types/work';
import {
  InheritedComponentType,
  WorkOrderTemplateFieldCreateInput,
  WorkOrderTemplateFieldDeleteInput,
  WorkOrderTemplateFieldUpdateInput,
  WorkOrderTemplateUpdateInput,
  WorkTemplate,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { addToSet, removeFromSet } from '@atom/utilities/setUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import { isComponentInherited } from '@atom/utilities/workTemplateUtilities';

import './workTemplateInfo.css';

const { MenuItem } = Menu;

const styles = {
  icon: {
    marginRight: '5px',
  },
  addButton: {
    marginLeft: '2rem',
  },
};

const DEBOUNCE_TIME = 1000;
const DROPPABLE_ID = 'fields';
const HTTP_STATUS_CONFLICT = 409;

const WorkTemplateFields = () => {
  const { workTemplate, dispatch } = useContext(WorkTemplateContext);

  const [selectedField, setSelectedField] = useState<WorkOrderField>(null);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [fieldsLoading, setFieldsLoading] = useState<Set<string>>(new Set());
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [hoveredField, setHoveredField] = useState<string>();
  const [fieldOrder, setFieldOrder] = useState<string[]>(
    workTemplate?.fieldOrder || [],
  );
  const [errorText, setErrorText] = useState<string>();

  const [createWorkTemplateField, { loading: loadingCreate }] = useMutation<
    { workOrderTemplateFieldCreate: WorkOrderField },
    { input: WorkOrderTemplateFieldCreateInput }
  >(WORK_TEMPLATE_FIELD_CREATE);

  const [updateWorkTemplateField, { loading: loadingUpdate }] = useMutation<
    { workOrderTemplateFieldUpdate: WorkOrderField },
    { input: WorkOrderTemplateFieldUpdateInput }
  >(WORK_TEMPLATE_FIELD_UPDATE);

  const [deleteWorkTemplateField, { loading: loadingDelete }] = useMutation<
    { workOrderTemplateFieldDelete: boolean },
    { input: WorkOrderTemplateFieldDeleteInput }
  >(WORK_TEMPLATE_FIELD_DELETE);

  const [updateWorkTemplate] = useMutation<
    { workOrderTemplateUpdate: WorkTemplate },
    { input: WorkOrderTemplateUpdateInput }
  >(WORK_TEMPLATE_UPDATE);

  useEffect(() => {
    if (createModalOpen || editModalOpen) {
      setErrorText(null);
    }
  }, [createModalOpen, editModalOpen]);

  useEffect(() => {
    setFieldOrder(workTemplate?.fieldOrder || []);
  }, [workTemplate?.fieldOrder]);

  const handleEditClick = (field: WorkOrderField) => {
    setSelectedField(field);
    setEditModalOpen(true);
  };

  const handleDeleteClick = (field: WorkOrderField) => {
    setSelectedField(field);
    setDeleteModalOpen(true);
  };

  const handleAddField = (field: WorkOrderField) => {
    dispatch({
      type: WorkTemplateActionTypes.ADD_WORK_TEMPLATE_FIELD,
      data: {
        field,
      },
    });

    setFieldOrder([...fieldOrder, field.id]);
  };

  const handleEditField = (field: WorkOrderField) => {
    dispatch({
      type: WorkTemplateActionTypes.UPDATE_WORK_TEMPLATE_FIELD,
      data: {
        field,
      },
    });
  };

  const handleEditFieldValue = (field: WorkOrderField) => {
    dispatch({
      type: WorkTemplateActionTypes.UPDATE_WORK_TEMPLATE_FIELD_VALUE,
      data: {
        field,
      },
    });
  };

  const handleDeleteConfirm = async () => {
    const fieldOrderIndex = R.indexOf(selectedField.id, fieldOrder);
    const newFieldOrder = R.remove(fieldOrderIndex, 1, fieldOrder);
    setFieldOrder(newFieldOrder);

    const res = await deleteWorkTemplateField({
      variables: {
        input: {
          workTemplateId: workTemplate.id,
          fieldId: selectedField.id,
        },
      },
    });

    if (res?.data?.workOrderTemplateFieldDelete) {
      setSelectedField(null);
      setDeleteModalOpen(false);
      dispatch({
        type: WorkTemplateActionTypes.DELETE_WORK_TEMPLATE_FIELD,
        data: {
          fieldId: selectedField.id,
        },
      });
    }
  };

  const handleCreate = async (payload: WorkOrderFieldCreatePayload) => {
    try {
      const res = await createWorkTemplateField({
        variables: {
          input: {
            workTemplateId: workTemplate.id,
            ...payload,
          },
        },
      });

      handleAddField(res?.data?.workOrderTemplateFieldCreate);
      setCreateModalOpen(false);
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_STATUS_CONFLICT) {
        setErrorText('This name is already in use.');
      }
    }
  };

  const handleUpdate = async (payload: WorkOrderFieldUpdatePayload) => {
    try {
      const res = await updateWorkTemplateField({
        variables: {
          input: {
            workTemplateId: workTemplate.id,
            ...payload,
          },
        },
      });

      handleEditField(res?.data?.workOrderTemplateFieldUpdate);
      setEditModalOpen(false);
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_STATUS_CONFLICT) {
        setErrorText('This name is already in use.');
      }
    }
  };

  const handleChange = useCallback(
    async (fieldId: string, value: any) => {
      setFieldsLoading(loading => addToSet(loading, fieldId));

      const res = await updateWorkTemplateField({
        variables: {
          input: {
            workTemplateId: workTemplate.id,
            fieldId,
            value,
          },
        },
      });

      const field = res?.data?.workOrderTemplateFieldUpdate;

      if (field.subFieldsTruncated) {
        handleEditField(field);
      } else {
        handleEditFieldValue(field);
      }

      setFieldsLoading(loading => removeFromSet(loading, fieldId));
    },
    [workTemplate.id],
  );

  const handleChangeDebounced = useCallback(
    debounce(handleChange, DEBOUNCE_TIME),
    [handleChange],
  );

  const onDragEnd = (result: DragDrop) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const newFieldOrder = R.move(source.index, destination.index, fieldOrder);
    setFieldOrder(newFieldOrder);

    updateWorkTemplate({
      variables: {
        input: {
          workOrderTemplateId: workTemplate.id,
          fieldOrder: newFieldOrder,
        },
      },
    });

    dispatch({
      type: WorkTemplateActionTypes.UPDATE_WORK_TEMPLATE_PROPERTY,
      data: {
        property: 'fieldOrder',
        value: newFieldOrder,
      },
    });
  };

  const isDisabled =
    workTemplate?.published || doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  const getContent = (field: WorkOrderField) => {
    const isInherited = isComponentInherited(
      InheritedComponentType.CUSTOM_FIELD,
      workTemplate?.inheritedComponents || [],
      field.id,
    );

    const props = {
      field,
      fieldsLoading,
      onChange: handleChange,
      onBlur: handleChange,
      isDisabled: isDisabled || isInherited,
    };

    const hasSubFields = !isNilOrEmpty(field.subFields);

    const components = {
      [DataType.SUMMARY]: <WorkOrderSummary {...props} />,
      [DataType.SHORT_TEXT]: (
        <WorkOrderTextField {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.LONG_TEXT]: (
        <WorkOrderTextField {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.DATE]: (
        <WorkOrderDatePicker {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.DATE_TIME]: (
        <WorkOrderDatePicker {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.ENUM_SINGLE]: hasSubFields ? (
        <div styleName="enum-single-nested-container">
          <WorkOrderEnumSingleField
            {...props}
            onChange={handleChangeDebounced}
          />
        </div>
      ) : (
        <WorkOrderEnumSingleField {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.ENUM_MULTIPLE]: <WorkOrderEnumMultipleField {...props} />,
      [DataType.NUMBER]: (
        <WorkOrderNumericField {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.HYPERLINK]: <WorkOrderHyperlinkField {...props} />,
    };

    const iconColor =
      hoveredField === field.id ? colors.neutral.ash : colors.neutral.white;

    return (
      <div
        styleName="field-drag-container"
        onMouseEnter={() => setHoveredField(field.id)}
        onMouseDown={() => setHoveredField(field.id)}
        onMouseUp={() => setHoveredField(null)}
      >
        <Icon color={iconColor} style={styles.icon}>
          drag_indicator
        </Icon>
        {components[field.dataType]}
        {isInherited && (
          <div styleName="lock-container">
            <InheritanceLockIcon
              tooltip="Inherited Custom Fields Section cannot be removed or edited."
              placement="bottom-end"
            />
          </div>
        )}
        {!isDisabled && !isInherited && (
          <Menu>
            <MenuItem onClick={() => handleEditClick(field)}>
              <Icon style={styles.icon}>create</Icon>
              Edit
            </MenuItem>
            <MenuItem onClick={() => handleDeleteClick(field)}>
              <Icon style={styles.icon}>delete</Icon>
              Delete
            </MenuItem>
          </Menu>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        styleName="section-content fields"
        onMouseLeave={() => setHoveredField(null)}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={DROPPABLE_ID}>
            {fieldOrder.map((fieldId: string, index: number) => {
              const field = workTemplate.fields.find(
                item => item.id === fieldId,
              );

              if (!field) {
                return <div />;
              }

              const isInherited = isComponentInherited(
                InheritedComponentType.CUSTOM_FIELD,
                workTemplate?.inheritedComponents || [],
              );

              return (
                <Draggable
                  key={`${field.title}-${index}`}
                  draggableId={field.id}
                  isDragDisabled={isDisabled || isInherited}
                  index={index}
                >
                  {getContent(field)}
                </Draggable>
              );
            })}
          </Droppable>
        </DragDropContext>
        {!isDisabled && (
          <Button
            color="primary"
            style={styles.addButton}
            startIcon={<Icon color={colors.brand.blue}>add</Icon>}
            onClick={() => setCreateModalOpen(true)}
            disabled={isDisabled}
          >
            Add Field
          </Button>
        )}
      </div>
      <WorkOrderFieldCreateModal
        fields={workTemplate?.fields}
        handleCreate={handleCreate}
        loadingCreate={loadingCreate}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        isWorkTemplate
        errorText={errorText}
      />
      <WorkOrderFieldUpdateModal
        fields={workTemplate?.fields}
        handleUpdate={handleUpdate}
        loadingUpdate={loadingUpdate}
        onClose={() => setEditModalOpen(false)}
        field={selectedField}
        open={editModalOpen}
        isWorkTemplate
        errorText={errorText}
      />
      <DeleteModal
        open={deleteModalOpen}
        loading={loadingDelete}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteConfirm}
        title="Delete Field"
        content="Are you sure you want to delete this field?"
      />
    </>
  );
};

export default WorkTemplateFields;
