import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import * as R from 'ramda';

import { Progress } from '@atom/mui';
import {
  getCurrentPageModule,
  isCurrentPageStandaloneModule,
} from '@atom/selectors/formModuleSelectors';
import { AttributeGroupItem } from '@atom/types/form';
import { AttributesType } from '@atom/types/inventory';
import { SchemaType } from '@atom/types/schema';

import FormModule from './formModules/FormModule';
import FieldContainer from './FieldContainer';
import FormBuilderContext from './FormBuilderContext';

import './formBuilder.css';

export interface Props {
  loading: boolean;
  rootSchema: SchemaType;
  deleteField: (id: string) => void;
  duplicateField: (id: string) => void;
  updateFormTemplate: (id: string, data: Object) => void;
  updateAttributeGroup: (
    fieldId: string,
    schemaId: string,
    addGroup: boolean,
    attributeGroup: AttributeGroupItem,
  ) => void;
  updateAttribute: (
    fieldId: string,
    schemaId: string,
    addAttribute: boolean,
    attributeGroupName: string,
    attribute: AttributesType,
  ) => void;
  addAllAttributes: (
    fieldId: string,
    schemaId: string,
    attributeGroupName: string,
    attributes: AttributesType[],
  ) => void;
  removeAssetSchema: (fieldId: string, schemaId: string) => void;
  updateCiFormInspection: (fieldId: string, inspectionId: string) => void;
}

const DROPPABLE_ID = 'fields';

class FormBuilderCanvas extends React.Component<Props> {
  static contextType = FormBuilderContext;

  renderFields = (): any[] => {
    const { formTemplate, selectedPage } = this.context;

    const isStandaloneModule = isCurrentPageStandaloneModule(
      formTemplate,
      selectedPage,
    );

    const {
      deleteField,
      duplicateField,
      updateFormTemplate,
      rootSchema,
      updateAttributeGroup,
      updateAttribute,
      addAllAttributes,
      removeAssetSchema,
      updateCiFormInspection,
    } = this.props;
    if (R.isEmpty(formTemplate.pages)) {
      return [];
    }

    const matchedPage = formTemplate.pages[selectedPage];
    const page = matchedPage ? matchedPage : { order: [], fields: {} };

    // @ts-ignore
    return page.order.map((key: string, index: number) => {
      const field = page.fields[key];

      return !isStandaloneModule ? (
        <FieldContainer
          field={field}
          rootSchema={rootSchema}
          id={key}
          index={index}
          key={key}
          updateFormTemplate={updateFormTemplate}
          updateAttributeGroup={updateAttributeGroup}
          addAllAttributes={addAllAttributes}
          removeAssetSchema={removeAssetSchema}
          updateAttribute={updateAttribute}
          deleteField={deleteField}
          duplicateField={duplicateField}
        />
      ) : (
        <FormModule
          key={index}
          field={field}
          fieldId={key}
          updateCiFormInspection={updateCiFormInspection}
        />
      );
    });
  };

  render() {
    const { formTemplate, selectedPage } = this.context;
    const { loading } = this.props;

    const moduleType = getCurrentPageModule(formTemplate, selectedPage);

    const content = loading ? (
      <div styleName="spinner-container">
        <Progress />
      </div>
    ) : (
      this.renderFields()
    );

    return (
      <div styleName="form-edit-canvas">
        <Droppable droppableId={DROPPABLE_ID}>
          {(provided: any) => (
            <div
              style={
                moduleType
                  ? {
                      borderTop: `3px solid ${moduleType?.color}`,
                    }
                  : {}
              }
              styleName="form-edit-paper"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {content}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  }
}

export default FormBuilderCanvas;
