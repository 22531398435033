import React, { useState } from 'react';
import * as R from 'ramda';

import { Modal, TextField } from '@atom/mui';
import { Summary } from '@atom/types/work';

import './workOrderSummary.css';

const styles = {
  inputContainer: {
    marginTop: '2rem',
  },
  contentStyle: {
    padding: '3rem',
    overflow: 'auto',
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
  addSummary: (newSummary: Summary) => void;
}

const AddSummaryModal = ({ open, onClose, addSummary }: Props) => {
  const [title, setTitle] = useState<string>('');
  const [contents, setContents] = useState<string>('');

  const confirm = () => {
    addSummary({ title, contents });
    setTitle('');
    setContents('');
    onClose();
  };

  return (
    <Modal
      title="Add Summary"
      open={open}
      onCancel={onClose}
      onConfirm={() => confirm()}
      confirmButtonText="Save"
      disabled={R.isEmpty(title) || R.isEmpty(contents)}
      contentStyle={styles.contentStyle}
    >
      <div styleName="summary-modal-content">
        <TextField
          id="title"
          label="Title"
          value={title}
          onChange={event => setTitle(event.target.value)}
        />
        <TextField
          id="description"
          label="Description Text"
          value={contents}
          onChange={event => setContents(event.target.value)}
          style={styles.inputContainer}
          maxRows={20}
          multiline
        />
      </div>
    </Modal>
  );
};

export default AddSummaryModal;
