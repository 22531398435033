import React, { useContext } from 'react';

import WorkOrderAssetView from '@atom/components/common/workOrderDetail/workOrderAssetView/WorkOrderAssetView';
import WorkOrderPreviewContext from '@atom/components/workOrderPreview/WorkOrderPreviewContext';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import TaskView from './taskView/TaskView';
import WorkOrderPreviewHeader from './workOrderPreviewHeader/WorkOrderPreviewHeader';

import './workOrderPreview.css';

const MainView = () => {
  const {
    workOrderAsset,
    workOrderDetail,
    setActiveWorkOrderAsset,
    activeWorkAssetTaskId,
    refetchWorkOrderAssetTreeLoading,
    refetchWorkOrderAssetTree,
    refetch,
  } = useContext(WorkOrderPreviewContext);

  const showTaskView = isNilOrEmpty(workOrderAsset);

  return (
    <>
      <WorkOrderPreviewHeader />
      <div>
        {showTaskView ? (
          <TaskView />
        ) : (
          <WorkOrderAssetView
            workOrderDetail={workOrderDetail}
            workOrderAsset={workOrderAsset}
            setActiveWorkOrderAsset={setActiveWorkOrderAsset}
            refetchWorkOrderAssetTreeLoading={refetchWorkOrderAssetTreeLoading}
            refetchWorkOrderAssetTree={refetchWorkOrderAssetTree}
            refetch={refetch}
            taskId={activeWorkAssetTaskId}
            previewView
          />
        )}
      </div>
    </>
  );
};

export default MainView;
