import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
import TaskItemTile from '@atom/components/common/workOrderDetail/taskItemTile/TaskItemTile';
import InheritanceLockIcon from '@atom/components/workTemplate/InheritanceLockIcon';
import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_TASK_FORM_TEMPLATE_DELETE } from '@atom/graph/workTemplate';
import { Icon, IconButton, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  InheritedComponentType,
  WorkOrderTemplateFormDeleteInput,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { isComponentInherited } from '@atom/utilities/workTemplateUtilities';

import './formSection.css';

interface Props {
  formTemplate: { id: string; name: string };
}

const styles = {
  icon: {
    height: '24px',
    width: '24px',
  },
};

const FormTile = ({ formTemplate }: Props) => {
  const { workTemplate, task, refetch } = useContext(WorkTemplateContext);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [removeForm] = useMutation<
    void,
    { input: WorkOrderTemplateFormDeleteInput }
  >(WORK_TEMPLATE_TASK_FORM_TEMPLATE_DELETE, {
    onCompleted: res => refetch(),
  });

  const handleRemoveForm = async () => {
    Snackbar.info({
      message: 'Deleting...',
    });

    await removeForm({
      variables: {
        input: {
          workTemplateId: workTemplate.id,
          taskId: task.id,
          formTemplateId: formTemplate.id,
        },
      },
    });

    Snackbar.info({
      message: `Removed Form.`,
    });
  };

  const isItemInherited = isComponentInherited(
    InheritedComponentType.TASK_FORM_TEMPLATE,
    task?.inheritedComponents || [],
    formTemplate?.id,
  );
  const isDisabled =
    workTemplate?.published || doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  const icon = (
    <Icon style={styles.icon} color={colors.brand.blue}>
      library_books
    </Icon>
  );
  const actionButton = isItemInherited ? (
    <div styleName="lock-container">
      <InheritanceLockIcon
        tooltip="Inherited Forms cannot be removed or edited."
        placement="bottom-start"
      />
    </div>
  ) : isDisabled ? (
    <div />
  ) : (
    <IconButton
      onClick={() => setDeleteModalOpen(true)}
      tooltip="Remove"
      size="small"
    >
      <Icon>close</Icon>
    </IconButton>
  );

  return (
    <>
      <TaskItemTile
        content={formTemplate.name}
        icon={icon}
        actionButton={actionButton}
      />
      <DeleteModal
        open={deleteModalOpen}
        onConfirm={handleRemoveForm}
        onCancel={() => setDeleteModalOpen(false)}
        title="Remove Form From Task"
        content="Are you sure you want to remove this form from this task?"
        cancelText="Cancel"
        confirmText="Remove"
      />
    </>
  );
};

export default FormTile;
