import React from 'react';
import { FormControlLabel as MUIFormControlLabel, Radio } from '@mui/material';
import { makeStyles } from '@mui/styles';

import fonts from '@atom/styles/fonts';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: fonts.md,
  },
}));

export type RadioButtonProps = {
  value: string;
  label: any;
  disabled?: boolean;
  checked?: boolean;
};

export const RadioButton = (props: RadioButtonProps) => {
  const classes = useStyles();
  const { disabled = false, ...data } = props;

  return (
    <MUIFormControlLabel
      classes={{ label: classes.label }}
      control={<Radio color="primary" disabled={disabled} />}
      {...data}
    />
  );
};

export default RadioButton;
