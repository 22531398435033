import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as applicationSearchActionCreators from '@atom/actions/applicationSearchActions';
import { ApplicationSearchActions } from '@atom/types/actions';
import {
  ApplicationSearchInventoryState,
  ApplicationSearchUsersState,
  ApplicationSearchWorkOrdersState,
  ReduxStore,
} from '@atom/types/store';
import history from '@atom/utilities/history';

import AllSearchResultsRows from './AllSearchResultsRows';
import InventorySearchResultsRows from './InventorySearchResultsRows';
import SearchNavigationBar from './SearchNavigationBar';
import SearchPortalResultsContainer from './SearchPortalResultsContainer';
import TeamSearchResultsRows from './TeamSearchResultsRows';
import WorkSearchResultsRows from './WorkSearchResultsRows';

import './searchPortal.css';

interface ReduxStateProps {
  query: string;
  assets: ApplicationSearchInventoryState[];
  workOrders: ApplicationSearchWorkOrdersState[];
  users: ApplicationSearchUsersState[];
  loading: boolean;
}

interface ReduxDispatchProps {
  applicationSearchActions: ApplicationSearchActions;
}

interface PassedProps {
  location: any;
  match: any;
}

type Props = ReduxStateProps & ReduxDispatchProps & PassedProps;

interface State {
  activeView: string;
}

class SearchPortal extends React.Component<Props, State> {
  state = {
    activeView: 'all',
  };

  componentDidMount() {
    const { applicationSearchActions, location } = this.props;

    const params = new URLSearchParams(location.search);
    const query = params.get('query');

    applicationSearchActions.requestApplicationSearch({
      query,
      top: false,
      includeAssets: true,
      includeWorkOrders: true,
      includeUsers: true,
    });
  }

  componentWillUnmount() {
    const { applicationSearchActions } = this.props;
    applicationSearchActions.clearApplicationSearch();
  }

  navigateBack = (): void => {
    history.goBackWithState('/');
  };

  onFilterClick = (type: 'all' | 'assets' | 'work' | 'team') => {
    const { applicationSearchActions, query } = this.props;

    this.setState({ activeView: type });

    applicationSearchActions.requestApplicationSearch({
      query,
      top: false,
      includeAssets: type === 'assets' || type === 'all',
      includeWorkOrders: type === 'work' || type === 'all',
      includeUsers: type === 'team' || type === 'all',
    });
  };

  getResultsContainerContent = () => {
    const { query, assets, workOrders, users, loading } = this.props;
    const { activeView } = this.state;

    let data = [];
    let total = 0;

    if (activeView === 'all') {
      // @ts-ignore
      data = (
        <AllSearchResultsRows
          query={query}
          users={users}
          workOrders={workOrders}
          assets={assets}
        />
      );
      total = users.length + assets.length + workOrders.length;
    } else if (activeView === 'team') {
      // @ts-ignore
      data = <TeamSearchResultsRows users={users} query={query} />;
      total = users.length;
    } else if (activeView === 'work') {
      // @ts-ignore
      data = <WorkSearchResultsRows workOrders={workOrders} query={query} />;
      total = workOrders.length;
    } else {
      // @ts-ignore
      data = <InventorySearchResultsRows assets={assets} query={query} />;
      total = assets.length;
    }

    return (
      <SearchPortalResultsContainer
        loading={loading}
        total={total}
        data={data}
      />
    );
  };

  render() {
    const { activeView } = this.state;

    const content = this.getResultsContainerContent();

    return (
      <div>
        <SearchNavigationBar
          navigateBack={this.navigateBack}
          onFilterClick={this.onFilterClick}
          activeView={activeView}
        />
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  query: state.applicationSearch.query,
  assets: state.applicationSearch.assets,
  workOrders: state.applicationSearch.workOrders,
  users: state.applicationSearch.users,
  loading: state.loading.loadingApplicationSearch,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  applicationSearchActions: bindActionCreators(
    applicationSearchActionCreators,
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPortal);
