import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';

import DeleteModal from '@atom/components/common/DeleteModal';
// @ts-ignore
import workExportIcon from '@atom/components/common/svgIcons/workExport.svg';
import { EXPORT_DELETE, GET_EXPORTS } from '@atom/graph/export';
import {
  INITIAL_INPUT,
  useWorkOrdersFilters,
} from '@atom/hooks/useWorkOrdersFilters';
import { Icon, ListTable, Menu, Progress, Snackbar } from '@atom/mui';
import layout from '@atom/styles/layout';
import {
  Export,
  ExportFilter,
  ExportsConnection,
  ExportsConnectionInput,
  ExportType,
} from '@atom/types/export';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import { getUserFullName } from '@atom/utilities/userUtilities';

import WorkExportsFilter from './WorkExportsFilter';
import WorkExportsHeader from './WorkExportsHeader';

const {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} = ListTable;

const { MenuItem } = Menu;

const TYPENAME = '__typename';

const styles = {
  link: {
    fontWeight: 500,
  },
  rightPane: {
    display: 'flex',
    flexDirection: 'column',
    width: `calc(100% - ${layout.sidePaneWidth})`,
    height: `calc(100vh - ${layout.fixedHeaderOffset})`,
    marginTop: '6.875rem',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    height: `calc(100vh - ${layout.fixedHeaderOffset})`,
  },
};

const WorkExports = () => {
  const { setInput } = useWorkOrdersFilters();
  const [exportsInput, setExportsInput] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 25),
  });

  const [exportToDelete, setExportToDelete] = useState<Export>(null);

  const { data, loading: exportsLoading, refetch } = useQuery<
    { exports: ExportsConnection },
    { input: ExportsConnectionInput }
  >(GET_EXPORTS, {
    variables: {
      input: {
        page: exportsInput.page,
        limit: exportsInput.limit,
        type: ExportType.WORK,
      },
    },
    fetchPolicy: 'network-only',
  });

  const [deleteExport, { loading: loadingDelete }] = useMutation(EXPORT_DELETE);

  const deleteWorkExport = async (id: string, name: string) => {
    try {
      await deleteExport({
        variables: {
          id,
        },
      });

      Snackbar.info({
        message: `Deleted work export ${name}`,
      });

      refetch();
    } catch (err) {
      Snackbar.error({
        message: `Delete work export ${name} Failed`,
      });
    }

    setExportToDelete(null);
  };

  const updateFilters = (filter: ExportFilter) => {
    setInput({
      ...INITIAL_INPUT,
      ...R.omit([TYPENAME], filter),
    });
  };

  const exports = R.pathOr<Export[]>([], ['exports', 'exports'], data);
  const totalCount = R.pathOr(0, ['exports', 'totalCount'], data);

  const mainPane = exportsLoading ? (
    <div style={styles.progress}>
      <Progress />
    </div>
  ) : (
    <>
      <ListTable offsetTop="165px">
        <TableHead>
          <TableRow header>
            <TableCell variant="head" />
            <TableCell variant="head">Name</TableCell>
            <TableCell variant="head">Work Template</TableCell>
            <TableCell variant="head">Created On</TableCell>
            <TableCell variant="head">Created By</TableCell>
            <TableCell variant="head" />
          </TableRow>
        </TableHead>
        <TableBody>
          {exports.map(exp => {
            return (
              <TableRow key={exp.id}>
                <TableCell width="3rem" align="right">
                  <div style={{ width: '100%' }}>
                    <Icon>
                      <img src={workExportIcon} />
                    </Icon>
                  </div>
                </TableCell>
                <TableCell style={styles.link}>
                  <Link
                    onClick={() => updateFilters(exp.filter)}
                    to={`/workExports/${exp.id}`}
                  >
                    {exp.name}
                  </Link>
                </TableCell>
                <TableCell>{exp.workTemplate?.name}</TableCell>
                <TableCell>{setDisplayDate(exp.createdDate)}</TableCell>
                <TableCell>
                  {exp.createdBy ? getUserFullName(exp.createdBy) : ''}
                </TableCell>
                <TableCell width="3rem">
                  <Menu>
                    <MenuItem
                      startAdornment={<Icon>delete</Icon>}
                      onClick={() => setExportToDelete(exp)}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              count={totalCount}
              rowsPerPage={exportsInput.limit}
              page={exportsInput.page}
              onPageChange={page => setExportsInput({ ...exportsInput, page })}
              onRowsPerPageChange={event =>
                setExportsInput({
                  ...exportsInput,
                  limit: +event.target.value,
                  page: 1,
                })
              }
            />
          </TableRow>
        </TableFooter>
      </ListTable>
      <DeleteModal
        title="Delete Work Export"
        open={!!exportToDelete}
        onCancel={() => setExportToDelete(null)}
        onConfirm={() =>
          deleteWorkExport(exportToDelete.id, exportToDelete.name)
        }
        content={`Are you sure you want to delete export ${exportToDelete?.name}?`}
        loading={loadingDelete}
      />
    </>
  );

  return (
    <div>
      <WorkExportsHeader />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <WorkExportsFilter />
        <div style={styles.rightPane}>{mainPane}</div>
      </div>
    </div>
  );
};

export default WorkExports;
