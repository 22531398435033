/* eslint-disable id-length */
import React, { useCallback, useMemo, useState } from 'react';
import naturalSort from 'natural-sort';

import { ListTable } from '@atom/mui';
import { AssignedRole } from '@atom/types/role';

import AssignedRolesRow from './AssignedRolesRow';

const { TableHead, TableRow, TableCell, TableBody } = ListTable;

type SortField = 'name' | 'assignedDate';
type SortDirection = 'asc' | 'desc';
interface Props {
  roles: AssignedRole[];
  onDelete: (id: string) => void;
}

const AssignedRolesTable = ({ roles, onDelete }: Props) => {
  const [sortBy, setSortBy] = useState<SortField>('name');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');

  const getSortDirection = useCallback(
    (field: SortField) => {
      if (sortBy !== field) {
        return false;
      }

      return sortDirection;
    },
    [sortBy, sortDirection],
  );

  const handleSortDirectionChange = (field: SortField) => (
    direction: SortDirection,
  ) => {
    setSortBy(field);
    setSortDirection(direction);
  };

  const sortedRoles = useMemo(() => {
    return [...roles].sort((a, b) => {
      return naturalSort({ direction: sortDirection })(a[sortBy], b[sortBy]);
    });
  }, [roles, sortBy, sortDirection]);

  return (
    <ListTable offsetTop="272px">
      <TableHead>
        <TableRow header>
          <TableCell
            variant="head"
            sortDirection={getSortDirection('name')}
            onSortChange={handleSortDirectionChange('name')}
          >
            Name
          </TableCell>
          <TableCell variant="head">Description</TableCell>
          <TableCell variant="head">Assigned By</TableCell>
          <TableCell
            variant="head"
            sortDirection={getSortDirection('assignedDate')}
            onSortChange={handleSortDirectionChange('assignedDate')}
          >
            Assigned On
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedRoles.map(role => (
          <AssignedRolesRow
            key={`${role.id}-${role.inheritedFrom
              ?.map(group => group.id)
              .join('-')}`}
            role={role}
            onDelete={onDelete}
          />
        ))}
      </TableBody>
    </ListTable>
  );
};

export default AssignedRolesTable;
