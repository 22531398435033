import React from 'react';
import * as R from 'ramda';

import { MediaItem } from '@atom/types/media';
import { Task } from '@atom/types/task';
import { WorkOrderDetailType } from '@atom/types/work';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import { getWorkOrderStatusType } from '@atom/utilities/workOrderStatusUtilities';

import './scdotPdfReport.css';

interface Props {
  workOrder: WorkOrderDetailType;
  task: Task;
  media: MediaItem[];
}

const CoverSection = ({ workOrder, task, media }: Props) => {
  const coverPhoto = R.find(R.propEq('id', workOrder?.mainPhotoId))(media);
  const inspectionDate = R.find(R.propEq('title', 'Inspection Date'))(
    task?.fields,
  );
  const teamLeader = R.find(R.propEq('title', 'Team Leader'))(task?.fields);
  const teamMember = R.find(R.propEq('title', 'Team Member'))(task?.fields);

  return (
    <div styleName="cover-section">
      <div styleName="general-data-section">
        <div styleName="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>General Data</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Work:</td>
                <td>{workOrder?.workTemplateName || '-'}</td>
              </tr>
              <tr>
                <td>Work Status:</td>
                <td>
                  {getWorkOrderStatusType(workOrder?.statusId)?.label || '-'}
                </td>
              </tr>
              <tr>
                <td>Inspection Date:</td>
                <td>{setDisplayDate(inspectionDate?.value)}</td>
              </tr>
              <tr>
                <td>Team Leader:</td>
                <td>{teamLeader?.value || '-'}</td>
              </tr>
              <tr>
                <td>Team Member:</td>
                <td>{teamMember?.value || '-'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div styleName="cover-photo-section">
        <div
          style={{
            backgroundImage: `url('${FILES_ENDPOINT}/${coverPhoto?.fileId}_1440')`,
          }}
          styleName="main-photo-container"
        />
      </div>
    </div>
  );
};

export default CoverSection;
