import React from 'react';
import Slider from 'react-slick';

import { MediaItem } from '@atom/types/media';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';

import NextArrow from './NextArrow';
import PrevArrow from './PrevArrow';

import './carousel.css';

const INFO_SIDE_BAR_SLIDE_WIDTH = '23.75rem';

interface Props {
  media: MediaItem[];
  selectedMediaIndex: number;
  sideBarOpen: boolean;
  updateSelectedMediaId: (selectedMediaIndex: number) => void;
}

const Carousel = ({
  media,
  selectedMediaIndex,
  sideBarOpen,
  updateSelectedMediaId,
}: Props) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: selectedMediaIndex,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: updateSelectedMediaId,
  };

  const carouselStyle = {
    width: sideBarOpen ? `calc(100vw  - ${INFO_SIDE_BAR_SLIDE_WIDTH}` : '100vw',
  };

  return (
    <div styleName="carousel-container" style={carouselStyle}>
      <Slider {...settings}>
        {media.map((mediaItem: MediaItem) => {
          const slideStyle = {
            backgroundImage: `url('${FILES_ENDPOINT}/${mediaItem.fileId}_1440')`,
          };

          return (
            <div key={mediaItem.id}>
              <div style={slideStyle} styleName="carousel-slide" />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Carousel;
