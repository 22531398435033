/* eslint-disable */
// TODO: [AM-1797] Unify the `env` and `environment` variables
export const environment = process.env.NODE_ENV;

export const projectId = 'sada-atom';
export const clientSideLoggingAPIKey =
  'AIzaSyDjMnEry26rvGYBfOV5v0L1T2pSjJ7zY3k';

export const LOCAL = 'localhost';
export const DEV = 'dev';
export const QA = 'qa';
export const UAT = 'uat';
export const PRODUCTION = 'prod';

// TODO: [AM-1797] Unify the `env` and `environment` variables
let env;
const domain = window.location.hostname.toLowerCase();
if (domain.includes('localhost') || domain.includes('local-atom')) {
  env = LOCAL;
} else if (domain.includes('dev')) {
  env = DEV;
} else if (domain.includes('qa')) {
  env = QA;
} else if (domain.includes('uat')) {
  env = UAT;
} else {
  env = PRODUCTION;
}

const configuration = {
  constants: {
    LOCAL,
    DEV,
    QA,
    UAT,
    PRODUCTION,
  },
  env,
  environment,
  projectId,
  clientSideLoggingAPIKey,
};

export default configuration;
