import React, { useEffect, useState } from 'react';
import * as R from 'ramda';

import { Modal, TextField } from '@atom/mui';
import { Summary } from '@atom/types/work';

import './workOrderSummary.css';

const styles = {
  inputContainer: {
    marginTop: '2rem',
  },
  contentStyle: {
    padding: '3rem',
    overflow: 'auto',
  },
};

interface Props {
  summary: Summary;
  open: boolean;
  editSummary: (newSummary: Summary) => void;
  closeModal: () => void;
}

const EditSummaryModal = ({
  summary,
  open,
  closeModal,
  editSummary,
}: Props) => {
  const [title, setTitle] = useState<string>(summary.title);
  const [contents, setContents] = useState<string>(summary.contents);

  useEffect(() => {
    setTitle(summary.title);
  }, [summary.title]);

  useEffect(() => {
    setContents(summary.contents);
  }, [summary.contents]);

  const confirm = () => {
    editSummary({ ...summary, title, contents });
    closeModal();
  };

  return (
    <Modal
      title="Edit Summary"
      open={open}
      onCancel={closeModal}
      onConfirm={() => confirm()}
      confirmButtonText="Save"
      disabled={R.isEmpty(title) || R.isEmpty(contents)}
      contentStyle={styles.contentStyle}
    >
      <div styleName="summary-modal-content">
        <TextField
          id="title"
          label="Title"
          value={title}
          onChange={event => setTitle(event.target.value)}
        />
        <TextField
          id="description"
          label="Description Text"
          value={contents}
          onChange={event => setContents(event.target.value)}
          style={styles.inputContainer}
          maxRows={20}
          multiline
        />
      </div>
    </Modal>
  );
};

export default EditSummaryModal;
