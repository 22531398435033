import React from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { AssetDetailView } from '@atom/types/inventory';

import './header.css';

interface Props {
  view: {
    value: AssetDetailView;
    label: string;
    icon: any;
    dataCy?: string;
  };
  activeView: AssetDetailView;
  setActiveView: (activeView: AssetDetailView) => void;
}

function setButtonStyleActive(activeView, value) {
  return activeView === value ? ' active' : '';
}
const HeaderNavigationIcon = ({ view, activeView, setActiveView }: Props) => {
  const { value, dataCy, icon, label } = view;

  const iconActiveStyle = setButtonStyleActive(activeView, value);
  const activeClassName = `icon-container${iconActiveStyle}`;

  return (
    <div
      onClick={() => setActiveView(value)}
      styleName={activeClassName}
      data-cy={dataCy}
    >
      <IconButton>
        <Icon color={colors.neutral.white}>{icon}</Icon>
      </IconButton>
      <div styleName="label">{label}</div>
    </div>
  );
};

export default HeaderNavigationIcon;
