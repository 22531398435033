import React, { useContext } from 'react';

import { Progress } from '@atom/mui';
import fonts from '@atom/styles/fonts';
import { BudgetUnit } from '@atom/types/budget';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailBreadcrumb from './BudgetDetailBreadcrumb';
import BudgetDetailContext from './BudgetDetailContext';
import BudgetDetailUnitTreeNav from './BudgetDetailUnitTreeNav';

import './budgetDetail.css';

const styles = {
  breadcrumbs: {
    fontSize: fonts.xl,
    display: 'flex',
    alignItems: 'center',
    fontWeight: '500',
    height: '1.75rem',
    paddingBottom: '0.75rem',
  },
};

const BudgetDetailBreadcrumbs = () => {
  const { parentBudgetUnit, unitsVisited } = useContext(BudgetDetailContext);

  const crumbs: BudgetUnit[] | string[] = parentBudgetUnit?.ancestors.reduce(
    (acc, id) => [...acc, unitsVisited[id] || id],
    [],
  );

  return (
    <>
      <div style={styles.breadcrumbs}>
        {isNilOrEmpty(parentBudgetUnit) ? (
          <Progress style={{ justifyContent: 'left' }} size={20} />
        ) : (
          <>
            {crumbs?.map(crumb => (
              <BudgetDetailBreadcrumb key={crumb.id || crumb} crumb={crumb} />
            ))}
            <BudgetDetailBreadcrumb crumb={parentBudgetUnit} last />
            <BudgetDetailUnitTreeNav />
          </>
        )}
      </div>
    </>
  );
};

export default BudgetDetailBreadcrumbs;
