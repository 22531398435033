import React from 'react';

import { usePreferences } from '@atom/hooks/usePreferences';
import { Button, DatePicker, Modal, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { TimeEntryWorkOrder } from '@atom/types/timeEntry';
import { WorkOrderDetailType } from '@atom/types/work';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import {
  CLOSED_STATUS_ID,
  COMPLETED_STATUS_ID,
} from '@atom/utilities/workOrderStatusUtilities';

const styles = {
  datePicker: {
    marginTop: '1rem',
  },
  progress: {
    height: '4rem',
  },
  subtext: {
    fontSize: fonts.sm,
    color: colors.neutral.gray,
  },
};

enum StatusModalType {
  CLOSE_PENDING = 'CLOSE_PENDING',
  CLOSE_INCOMPLETE = 'CLOSE_INCOMPLETE',
  CLOSE_NORMAL = 'CLOSE_NORMAL',
  COMPLETE_INCOMPLETE = 'COMPLETE_INCOMPLETE',
  COMPLETE_NORMAL = 'COMPLETE_NORMAL',
  REOPEN_CLOSED = 'REOPEN_CLOSED',
}

const statusModalValues = {
  [StatusModalType.CLOSE_PENDING]: {
    title: 'Cannot Close Work',
    body:
      'There are pending changes in this work. Please approve or reject all changes and try again.',
    button: 'DONE',
    showCompletedDate: false,
  },
  [StatusModalType.CLOSE_INCOMPLETE]: {
    title: 'Close Work?',
    body:
      'Work contains incomplete tasks. This action will set all tasks to “Completed” and work to “Closed”. Are you sure?',
    button: 'CLOSE',
    showCompletedDate: true,
  },
  [StatusModalType.CLOSE_NORMAL]: {
    title: 'Close Work?',
    body:
      'Closed Work cannot be edited or modified. You can still generate reports and download documents, photos, and videos.',
    button: 'CLOSE',
    showCompletedDate: true,
  },
  [StatusModalType.COMPLETE_INCOMPLETE]: {
    title: 'Complete Work?',
    body:
      'Work contains incomplete tasks. This action will set all tasks to “Completed”. Are you sure?',
    button: 'COMPLETE',
    showCompletedDate: true,
  },
  [StatusModalType.COMPLETE_NORMAL]: {
    title: 'Complete Work?',
    body: 'Are you sure you want to set this work to "Completed"?',
    button: 'COMPLETE',
    showCompletedDate: true,
  },
  [StatusModalType.REOPEN_CLOSED]: {
    title: 'Reopen Work?',
    body: 'Opening work will allow edits to assets. Are you sure?',
    button: 'REOPEN',
    showCompletedDate: false,
  },
};

interface Props {
  workOrder: WorkOrderDetailType | TimeEntryWorkOrder;
  hasPendingChanges: boolean;
  statusId: string;
  hasIncompleteTasks?: boolean;
  updateWorkOrder: (statusId: string, completionDate: Date) => void;
  loading: boolean;
  open: boolean;
  onClose: () => void;
  completionDate: Date;
  handleCompletionDateChange: (date?: Date) => void;
}

const ConfirmationModal = ({
  workOrder,
  hasPendingChanges,
  statusId,
  hasIncompleteTasks,
  updateWorkOrder,
  loading,
  open,
  onClose,
  completionDate,
  handleCompletionDateChange,
}: Props) => {
  const preferences = usePreferences();
  const completedDateEnabled = preferences?.workOrders?.completedDateEnabled;

  const getStatusModalValue = () => {
    switch (true) {
      case workOrder.statusId === CLOSED_STATUS_ID:
        return statusModalValues[StatusModalType.REOPEN_CLOSED];
      case statusId === CLOSED_STATUS_ID && hasPendingChanges:
        return statusModalValues[StatusModalType.CLOSE_PENDING];
      case statusId === CLOSED_STATUS_ID && hasIncompleteTasks:
        return statusModalValues[StatusModalType.CLOSE_INCOMPLETE];
      case statusId === CLOSED_STATUS_ID:
        return statusModalValues[StatusModalType.CLOSE_NORMAL];
      case statusId === COMPLETED_STATUS_ID && hasIncompleteTasks:
        return statusModalValues[StatusModalType.COMPLETE_INCOMPLETE];
      default:
        return statusModalValues[StatusModalType.COMPLETE_NORMAL];
    }
  };

  const statusModalValue = getStatusModalValue();

  const buttonDisabled =
    completedDateEnabled && statusModalValue.showCompletedDate
      ? isNilOrEmpty(completionDate) || loading
      : loading;

  const getFooter = () => {
    return hasPendingChanges && statusId === CLOSED_STATUS_ID ? (
      <Button onClick={onClose}>Done</Button>
    ) : (
      <div>
        <Button onClick={onClose} style={{ marginRight: '0.5rem' }}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={buttonDisabled}
          onClick={() => updateWorkOrder(statusId, completionDate)}
        >
          {statusModalValue.button}
        </Button>
      </div>
    );
  };

  return (
    <Modal
      title={loading ? '' : statusModalValue.title}
      open={open}
      onCancel={onClose}
      footer={loading ? <div /> : getFooter()}
      loading={loading}
      data-cy="workOrderStatusModal"
    >
      {loading ? (
        <Progress style={styles.progress} />
      ) : (
        <>
          <div>{statusModalValue.body}</div>
          {completedDateEnabled && statusModalValue.showCompletedDate && (
            <>
              <DatePicker
                value={completionDate}
                onChange={handleCompletionDateChange}
                label="Completed Date *"
                style={styles.datePicker}
              />
              <div style={styles.subtext}>Date the work was performed on.</div>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
