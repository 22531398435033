import React from 'react';
import { makeStyles } from '@mui/styles';

import { TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { TaskField } from '@atom/types/task';
import { WorkOrderField } from '@atom/types/work';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './workOrdersFilters.css';

const useClasses = makeStyles({
  label: {
    position: 'unset',
    color: `${colors.neutral.dim} !important`,
  },
});

interface Props {
  field: WorkOrderField | TaskField;
  startValue: any;
  endValue: any;
  onStartChange: (id: string, value: any) => void;
  onEndChange: (id: string, value: any) => void;
}

const WorkOrdersFiltersNumericRange = ({
  field,
  startValue,
  endValue,
  onStartChange,
  onEndChange,
}: Props) => {
  const classes = useClasses();

  return (
    <div styleName="filters-number-range">
      <TextField
        value={startValue}
        onChange={event =>
          onStartChange(
            field.id,
            !isNilOrEmpty(event.target.value) ? event.target.value : '',
          )
        }
        label={field.title}
        type="number"
        variant="standard"
        InputLabelProps={{
          classes: { root: classes.label },
        }}
      />
      <span>to</span>
      <TextField
        value={endValue}
        onChange={event =>
          onEndChange(
            field.id,
            !isNilOrEmpty(event.target.value) ? event.target.value : '',
          )
        }
        label=""
        type="number"
        variant="standard"
      />
    </div>
  );
};

export default WorkOrdersFiltersNumericRange;
