import React from 'react';

import InputDateRange from '@atom/components/common/inputDateRange/InputDateRange';
import { Icon, TextField } from '@atom/mui';
import { EventType } from '@atom/types/event';
import { WorkTemplate } from '@atom/types/workTemplate';
import {
  convertDateToLocalDayEnd,
  convertDateToLocalDayStart,
  convertMillisToDays,
  DAY_IN_MILLISECONDS,
} from '@atom/utilities/timeUtilities';
import {
  DateRangePresetOption,
  DateRangeTerminator,
} from '@atom/utilities/workOrdersDateFilterUtilities';

import {
  AdditionalFieldFilters,
  FilterForm,
  TasksAdditionalFieldFilters,
} from '../AssetWorkOrdersFilterPanel';

import TaskCustomFieldsFilter from './TaskCustomFieldsFilter';
import WorkTemplateTaskFieldsFilter from './WorkTemplateTaskFieldsFilter';

import './../assetWorkOrdersFilterPanel.css';

interface Props {
  areFiltersDisabled: boolean;
  taskDueDateSelectValue?: string;
  taskDueDateStart?: number;
  taskDueDateEnd?: number;
  taskStartDateSelectValue?: string;
  taskStartDateStart: number;
  taskStartDateEnd: number;
  taskCompletionDateSelectValue?: string;
  taskCompletionDateStart?: number;
  taskCompletionDateEnd?: number;
  taskDurationStart?: number;
  taskDurationEnd?: number;
  taskCustomCommonFields: AdditionalFieldFilters;
  workTemplateTaskFields: TasksAdditionalFieldFilters;
  workTemplates: WorkTemplate[];
  workOrderTemplate?: WorkTemplate;
  updateFilterFormData: (field: keyof FilterForm, updatedVal: any) => void;
}

const TaskFiltersSection = ({
  areFiltersDisabled,
  taskDueDateSelectValue,
  taskDueDateStart,
  taskDueDateEnd,
  taskStartDateSelectValue,
  taskStartDateStart,
  taskStartDateEnd,
  taskCompletionDateSelectValue,
  taskCompletionDateStart,
  taskCompletionDateEnd,
  taskDurationStart,
  taskDurationEnd,
  taskCustomCommonFields,
  workTemplateTaskFields,
  workTemplates,
  workOrderTemplate,
  updateFilterFormData,
}: Props) => {
  const handleInputDateRangeChange = (
    dateValue: Date,
    terminator: DateRangeTerminator,
    formDataKey: keyof FilterForm,
  ) => {
    const dateMillis = dateValue
      ? terminator === DateRangeTerminator.START
        ? convertDateToLocalDayStart(dateValue)
        : convertDateToLocalDayEnd(dateValue)
      : null;
    updateFilterFormData(formDataKey, dateMillis);
  };

  const handleInputDateRangeSelectChange = (
    formDataKey: keyof FilterForm,
    option: DateRangePresetOption,
  ) => {
    updateFilterFormData(formDataKey, option.value);
  };

  const handleDurationChange = (
    event: EventType,
    formDataKey: keyof FilterForm,
  ) => {
    const inputValue = event.target.value;
    const updatedValue =
      inputValue === '' ? null : Number(inputValue) * DAY_IN_MILLISECONDS;

    updateFilterFormData(formDataKey, updatedValue);
  };

  return (
    <div styleName="filter-section">
      <div styleName="filter-section-header">
        <Icon>playlist_add_check_icon</Icon>
        Task
      </div>
      <div styleName="filter-container">
        <InputDateRange
          label="Due Date"
          disabled={areFiltersDisabled}
          presetSelected={taskDueDateSelectValue}
          handleSelectDatePreset={(option: DateRangePresetOption) =>
            handleInputDateRangeSelectChange('taskDueDateSelectValue', option)
          }
          startDateValue={taskDueDateStart ? new Date(taskDueDateStart) : null}
          handleStartChange={(dateValue: Date) =>
            handleInputDateRangeChange(
              dateValue,
              DateRangeTerminator.START,
              'taskDueDateStart',
            )
          }
          endDateValue={taskDueDateEnd ? new Date(taskDueDateEnd) : null}
          handleEndChange={(dateValue: Date) =>
            handleInputDateRangeChange(
              dateValue,
              DateRangeTerminator.END,
              'taskDueDateEnd',
            )
          }
        />
      </div>
      <div styleName="filter-container">
        <InputDateRange
          label="Start Date"
          disabled={areFiltersDisabled}
          presetSelected={taskStartDateSelectValue}
          handleSelectDatePreset={(option: DateRangePresetOption) =>
            handleInputDateRangeSelectChange('taskStartDateSelectValue', option)
          }
          startDateValue={
            taskStartDateStart ? new Date(taskStartDateStart) : null
          }
          handleStartChange={(dateValue: Date) =>
            handleInputDateRangeChange(
              dateValue,
              DateRangeTerminator.START,
              'taskStartDateStart',
            )
          }
          endDateValue={taskStartDateEnd ? new Date(taskStartDateEnd) : null}
          handleEndChange={(dateValue: Date) =>
            handleInputDateRangeChange(
              dateValue,
              DateRangeTerminator.END,
              'taskStartDateEnd',
            )
          }
        />
      </div>
      <div styleName="filter-container">
        <InputDateRange
          label="Completed Date"
          disabled={areFiltersDisabled}
          presetSelected={taskCompletionDateSelectValue}
          handleSelectDatePreset={(option: DateRangePresetOption) =>
            handleInputDateRangeSelectChange(
              'taskCompletionDateSelectValue',
              option,
            )
          }
          startDateValue={
            taskCompletionDateStart ? new Date(taskCompletionDateStart) : null
          }
          handleStartChange={(dateValue: Date) =>
            handleInputDateRangeChange(
              dateValue,
              DateRangeTerminator.START,
              'taskCompletionDateStart',
            )
          }
          endDateValue={
            taskCompletionDateEnd ? new Date(taskCompletionDateEnd) : null
          }
          handleEndChange={(dateValue: Date) =>
            handleInputDateRangeChange(
              dateValue,
              DateRangeTerminator.END,
              'taskCompletionDateEnd',
            )
          }
        />
      </div>
      <div styleName="filter-container">
        <div styleName="filter-label">Duration</div>
        <div styleName="filter-duration-range-container">
          <TextField
            type="number"
            disabled={areFiltersDisabled}
            value={
              taskDurationStart ? convertMillisToDays(taskDurationStart) : ''
            }
            onChange={event => handleDurationChange(event, 'taskDurationStart')}
          />
          <div styleName="filter-label">to</div>
          <TextField
            type="number"
            disabled={areFiltersDisabled}
            value={taskDurationEnd ? convertMillisToDays(taskDurationEnd) : ''}
            onChange={event => handleDurationChange(event, 'taskDurationEnd')}
          />
          <div styleName="filter-label">Days</div>
        </div>
      </div>
      <TaskCustomFieldsFilter
        areFiltersDisabled={areFiltersDisabled}
        taskCustomCommonFields={taskCustomCommonFields}
        updateTaskCustomCommonFields={customCommonFieldsUpdate =>
          updateFilterFormData(
            'taskCustomCommonFields',
            customCommonFieldsUpdate,
          )
        }
      />
      {workTemplates.length === 1 && workOrderTemplate && (
        <WorkTemplateTaskFieldsFilter
          areFiltersDisabled={areFiltersDisabled}
          workOrderTemplate={workOrderTemplate}
          taskFieldFilters={workTemplateTaskFields}
          updateTaskFieldFilters={fieldFiltersUpdate =>
            updateFilterFormData('workTemplateTaskFields', fieldFiltersUpdate)
          }
        />
      )}
    </div>
  );
};

export default TaskFiltersSection;
