import React from 'react';

import { DatePicker } from '@atom/mui';

import './workTemplateTaskDetail.css';

const TaskCompletedDate = () => {
  return (
    <div styleName="time-row-item input">
      <div styleName="label">Completed Date</div>
      <div styleName="time-picker-container">
        <DatePicker
          value={null}
          onChange={() => {}}
          format="MM/dd/yyyy"
          disabled
        />
      </div>
    </div>
  );
};

export default TaskCompletedDate;
