import React, { useContext, useEffect, useState } from 'react';
import * as R from 'ramda';

import { useUserProfile } from '@atom/hooks/useUserProfile';
import {
  DashboardCard,
  DashboardCardContentState,
} from '@atom/types/dashboard';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import CardContent from './CardContent/CardContent';
import CardEmpty from './commonCardStates/CardEmpty';
import CardError from './commonCardStates/CardError';
import CardLoading from './commonCardStates/CardLoading';
import DashboardContext from './DashboardContext';

import './dashboardCard.css';

interface Props {
  card: DashboardCard;
}

const DashboardCardLoader = ({ card }: Props) => {
  const [cardData, setCardData] = useState<any>();
  const { updateCardState } = useContext(DashboardContext);
  const { userId } = useUserProfile();

  useEffect(() => {
    const loadCardContent = async getCardData => {
      try {
        const data = await getCardData({ userId, card });
        setCardData(R.pathOr({}, ['data'], data));
        updateCardState(card, DashboardCardContentState.READY);
      } catch (error) {
        updateCardState(card, DashboardCardContentState.ERROR);
      }
    };

    if (card.contentState === 'LOADING') {
      if (isNilOrEmpty(card.getCardData)) {
        updateCardState(card, DashboardCardContentState.READY);
        return;
      }
      loadCardContent(card.getCardData);
    }
  }, [card.contentState]);

  switch (card.contentState) {
    case 'ERROR':
      return <CardError card={card} />;
    case 'LOADING':
      return <CardLoading card={card} />;
    case 'READY':
      return (
        <CardContent
          cardData={cardData}
          setCardData={setCardData}
          card={card}
        />
      );
    case 'EMPTY':
    default:
      return <CardEmpty card={card} />;
  }
};

export default DashboardCardLoader;
