import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import WorkTypeFolderTile from '@atom/components/common/workTemplateFolderTree/WorkTypeFolderTile';
import WorkTypeFolderTree from '@atom/components/common/workTemplateFolderTree/WorkTypeFolderTree';
import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_UPDATE } from '@atom/graph/workTemplate';
import { Icon, IconButton, Modal, Snackbar, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { PolicyAction } from '@atom/types/policy';
import { WorkOrderType } from '@atom/types/work';
import {
  WorkOrderTemplateUpdateInput,
  WorkTemplate,
  WorkTemplateFolderTree,
} from '@atom/types/workTemplate';
import api from '@atom/utilities/api';
import { WORK_TEMPLATES_FOLDER_TREE_ENDPOINT } from '@atom/utilities/endpoints';
import { getWorkTemplateFolderByFolderPath } from '@atom/utilities/workTemplateUtilities';

import WorkTemplateSchemaSelect from './WorkTemplateSchemaSelect';
import WorkTemplateTypeSelect from './WorkTemplateTypeSelect';

import './workTypeEditModal.css';

const HTTP_RESOURCE_CONFLICT = 409;
const EMPTY_SCHEMA = 'empty';

enum View {
  MAIN = 'MAIN',
  FOLDER_TREE = 'FOLDER_TREE',
  CONFIRM = 'CONFIRM',
}

const styles = {
  textFieldStyle: {
    marginLeft: '1rem',
    color: colors.neutral.dark,
  },
  icon: {
    marginTop: '1rem',
  },
  modalContent: {
    padding: 0,
  },
  back: {
    marginRight: '1rem',
  },
};

interface Props {
  open: boolean;
  closeModal: () => void;
}

const WorkTypeEditModal = ({ open, closeModal }: Props) => {
  const { workTemplate, refetch } = useContext(WorkTemplateContext);

  const [name, setName] = useState<string>(workTemplate?.name);
  const [error, setError] = useState<string>('');
  const [activeView, setActiveView] = useState<View>(View.MAIN);
  const [schemaId, setSchemaId] = useState<string>(
    workTemplate?.schemaId || EMPTY_SCHEMA,
  );
  const [type, setType] = useState<WorkOrderType>(
    R.pathOr(WorkOrderType.DEFAULT, ['type'], workTemplate),
  );
  const [workTemplateFolder, setWorkTemplateFolder] = useState<
    WorkTemplateFolderTree
  >();
  const [loadingTree, setLoadingTree] = useState<boolean>(true);
  const [workTemplateFolderTree, setWorkTemplateFolderTree] = useState<
    WorkTemplateFolderTree
    // @ts-ignore
  >({});

  const [workTemplateUpdate, { loading: editLoading }] = useMutation<
    { workOrderTemplateUpdate: WorkTemplate },
    { input: WorkOrderTemplateUpdateInput }
  >(WORK_TEMPLATE_UPDATE);

  useEffect(() => {
    const getWorkTemplateFolderTree = async () => {
      setLoadingTree(true);

      const { data } = await api.get(
        `${WORK_TEMPLATES_FOLDER_TREE_ENDPOINT}?action=${PolicyAction.READ}`,
      );

      setWorkTemplateFolderTree(data);
      setLoadingTree(false);
    };

    getWorkTemplateFolderTree();
  }, []);

  const selectedWorkTemplateFolder = useMemo(() => {
    return getWorkTemplateFolderByFolderPath(
      workTemplateFolderTree,
      workTemplate.folderPath,
    );
  }, [workTemplateFolderTree, workTemplate]);

  useEffect(() => {
    setWorkTemplateFolder(selectedWorkTemplateFolder);
  }, [selectedWorkTemplateFolder]);

  useEffect(() => {
    setName(workTemplate?.name);
    setSchemaId(workTemplate?.schemaId || EMPTY_SCHEMA);
    setWorkTemplateFolder(selectedWorkTemplateFolder);
    setActiveView(View.MAIN);
    setType(R.pathOr(WorkOrderType.DEFAULT, ['type'], workTemplate));
    setError('');
  }, [workTemplate, open]);

  const confirm = async () => {
    try {
      const selectedSchemaId =
        type === WorkOrderType.DEFAULT
          ? schemaId !== EMPTY_SCHEMA
            ? schemaId
            : ''
          : '';

      await workTemplateUpdate({
        variables: {
          input: {
            workOrderTemplateId: workTemplate.id,
            folderId: workTemplateFolder.id,
            type,
            ...(name !== workTemplate.name && { name }),
            ...(!R.isNil(selectedSchemaId) && { schemaId: selectedSchemaId }),
          },
        },
      });

      refetch();
      closeModal();
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_RESOURCE_CONFLICT) {
        setError('This name is already in use.');
      } else {
        Snackbar.error({ message: 'An unknown error occurred' });
        closeModal();
      }
    }
  };

  const onConfirm = () => {
    if (
      activeView !== View.CONFIRM &&
      R.pathOr(WorkOrderType.DEFAULT, ['type'], workTemplate) !== type
    ) {
      setActiveView(View.CONFIRM);
    } else {
      confirm();
    }
  };

  const handleNameChange = event => {
    setName(event.target.value);
    setError('');
  };

  const isDisabled =
    R.isEmpty(name) || R.isNil(workTemplateFolder) || workTemplate.published;

  const getTitle = (): React.ReactNode => {
    const titles = {
      [View.MAIN]: 'Edit Work Template',
      [View.FOLDER_TREE]: (
        <>
          <IconButton
            style={styles.back}
            onClick={() => setActiveView(View.MAIN)}
            size="small"
          >
            <Icon>arrow_back</Icon>
          </IconButton>
          Choose Folder
        </>
      ),
      [View.CONFIRM]: 'Change Work Template Type?',
    };

    return titles[activeView];
  };

  const chooseFolderOnClick = workTemplate.published
    ? () => {}
    : () => setActiveView(View.FOLDER_TREE);

  const confirmButtonText = activeView === View.CONFIRM ? 'Confirm' : 'Save';

  const singleToMultiText =
    'Changing the template type will remove the asset type and forms of the same asset type from all tasks. Are you sure?';

  const multiToSingleText =
    'Changing the template type will remove all the changes you made to the Locations and Assets sections. Are you sure?';

  const getContent = () => {
    const content = {
      [View.MAIN]: (
        <div styleName="work-type-creation-modal-content">
          <div styleName="section-title">Details</div>
          <div styleName="modal-row">
            <img style={styles.icon} src={renameIcon} />
            <TextField
              id="name"
              label="Name *"
              value={name}
              onChange={handleNameChange}
              style={styles.textFieldStyle}
              error={!!error}
              helperText={error}
              disabled={workTemplate.published}
            />
          </div>
          <div styleName="modal-row">
            <Icon color={colors.neutral.gray}>folder</Icon>
            {!R.isNil(workTemplateFolder) ? (
              <div styleName="tile-container">
                <WorkTypeFolderTile
                  workTemplateFolder={workTemplateFolder}
                  removeWorkTemplateFolder={() => setWorkTemplateFolder(null)}
                  disabled={workTemplate.published}
                />
              </div>
            ) : (
              <div styleName="choose-button" onClick={chooseFolderOnClick}>
                Choose Folder *
              </div>
            )}
          </div>
          <WorkTemplateTypeSelect type={type} setType={setType} />
          {type === WorkOrderType.DEFAULT && (
            <WorkTemplateSchemaSelect
              schemaId={schemaId}
              setSchemaId={setSchemaId}
            />
          )}
        </div>
      ),
      [View.FOLDER_TREE]: (
        <WorkTypeFolderTree
          setWorkTemplateFolder={setWorkTemplateFolder}
          setActiveView={() => setActiveView(View.MAIN)}
        />
      ),
      [View.CONFIRM]: (
        <div styleName="confirm-text">
          {type === WorkOrderType.DEFAULT
            ? multiToSingleText
            : singleToMultiText}
        </div>
      ),
    };

    return content[activeView];
  };

  return (
    <Modal
      title={getTitle()}
      open={open}
      onCancel={() => closeModal()}
      onConfirm={() => onConfirm()}
      confirmButtonText={confirmButtonText}
      loading={editLoading || loadingTree}
      contentStyle={styles.modalContent}
      disabled={isDisabled}
    >
      {getContent()}
    </Modal>
  );
};

export default WorkTypeEditModal;
