import React from 'react';

import {
  ColumnsData,
  DashboardCard,
  DashboardCardContentState,
} from '@atom/types/dashboard';

interface Context {
  emptyBoard: boolean;
  allCards: DashboardCard[];
  setAllCards: (any) => void;
  updateCardState: (
    card: DashboardCard,
    state: DashboardCardContentState,
  ) => void;
  updateAllCardsState: (state: DashboardCardContentState) => void;
  setShowCustomize: (showCustomize: boolean) => void;
  lgColumn: DashboardCard[];
  smColumn: DashboardCard[];
  updateLayout: (columns: ColumnsData) => Promise<any>;
  updatingLayout: boolean;
  someCardIsLoading: boolean;
  handleAddEmbedCard: (card: DashboardCard) => void;
  handleDeleteEmbedCard: (card: DashboardCard) => void;
  handleEditEmbedCard: (card: DashboardCard) => void;
  loadingEmbedCard: boolean;
}

export const initialState: Context = {
  emptyBoard: false,
  allCards: [],
  setAllCards: () => {},
  updateCardState: () => {},
  updateAllCardsState: () => {},
  setShowCustomize: () => {},
  lgColumn: [],
  smColumn: [],
  // @ts-ignore
  updateLayout: () => {},
  updatingLayout: false,
  someCardIsLoading: false,
  handleAddEmbedCard: () => {},
  handleDeleteEmbedCard: () => {},
  handleEditEmbedCard: () => {},
  loadingEmbedCard: false,
};

const DashboardContext = React.createContext<Context>(initialState);

export default DashboardContext;
