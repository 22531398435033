import React from 'react';
import { Droppable as RBDroppable } from 'react-beautiful-dnd';

import colors from '@atom/styles/colors';

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
  background: isDraggingOver ? colors.neutral.white : colors.neutral.white,
});

interface Props {
  /**
   * An id that is used to identify this specific droppable area
   * within the DragDropContext action handlers.
   * Example: If this is the droppable area for users, then this
   * can be "users"
   */
  droppableId: string;
  /**
   * Only a <Draggable /> of the same type is allowed to be
   * dropped in this component
   */
  type?: string;
  style?: React.CSSProperties;
  isDropDisabled?: boolean;
  children: React.ReactNode;
}

// A <Droppable /> component must be contained within a <DragDropContext />
// <Draggable /> are placed inside this component

export const Droppable = ({
  droppableId,
  type = '',
  isDropDisabled = false,
  children,
}: Props) => {
  return (
    <RBDroppable
      droppableId={droppableId}
      isDropDisabled={isDropDisabled}
      type={type}
    >
      {(provided: any, snapshot: any) => (
        <div
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
          {...provided.droppableProps}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </RBDroppable>
  );
};
