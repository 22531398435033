import React, { useContext, useState } from 'react';
import * as R from 'ramda';

import AddSummaryModal from '@atom/components/common/workOrderDetail/workOrderSummary/AddSummaryModal';
import { Icon, IconButton } from '@atom/mui';
import { Summary } from '@atom/types/work';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import BulkContext from '../../../BulkContext';

import SummaryRow from './SummaryRow';

import './workInfo.css';

const SummaryPane = () => {
  const { workOrder, setWorkOrder } = useContext(BulkContext);
  const [open, setOpen] = useState<boolean>(false);
  const { summary } = workOrder;

  const addSummary = (newSummary: Summary) => {
    const newWorkOrder = {
      ...workOrder,
      summary: [...summary, newSummary],
    };

    setWorkOrder(newWorkOrder);
  };

  const updateSummary = (newSummaries: Summary[]) => {
    const newWorkOrder = {
      ...workOrder,
      summary: [...newSummaries],
    };

    setWorkOrder(newWorkOrder);
  };

  const canAddSummary = hasRolePermissions(ROLE_SETS.INSPECTOR);

  return (
    <>
      <div styleName="section-header">
        <div>Summary</div>
        {canAddSummary && (
          <IconButton onClick={() => setOpen(true)} style={{ padding: 0 }}>
            <Icon>add</Icon>
          </IconButton>
        )}
      </div>
      <div styleName="section-content summary">
        {R.isEmpty(summary) ? (
          <div styleName="empty-list">There is no summary.</div>
        ) : (
          summary.map((summaryItem: Summary, index: number) => (
            <SummaryRow
              key={`${summaryItem.title}-${index}`}
              summary={summaryItem}
              summaries={summary}
              index={index}
              updateSummary={updateSummary}
            />
          ))
        )}
      </div>
      <AddSummaryModal
        open={open}
        onClose={() => setOpen(false)}
        addSummary={addSummary}
      />
    </>
  );
};

export default SummaryPane;
