import * as React from 'react';

import './textLimit.css';

type Props = {
  limit: number;
  reveal: number;
  text: string;
};

const TextLimit = ({ limit, reveal, text }: Props) => {
  const currentTextCount = text.length;
  const limitText = `${currentTextCount} / ${limit}`;
  const textLimitStyles = currentTextCount >= limit ? 'red' : '';
  const isRevealed = currentTextCount + reveal > limit;

  return (
    <React.Fragment>
      {isRevealed && (
        <div styleName={`text-limit ${textLimitStyles}`}>{limitText}</div>
      )}
    </React.Fragment>
  );
};

export default TextLimit;
