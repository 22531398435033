import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import * as R from 'ramda';

import {
  GET_BUDGET,
  GET_BUDGET_OPTIONS,
  GET_BUDGET_SUMMARIES,
  GET_BUDGET_UNIT,
} from '@atom/graph/budget';
import { usePreferences } from '@atom/hooks/usePreferences';
import { useScrollPos } from '@atom/hooks/useScrollPos';
import { Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  BasicBudget,
  BasicBudgetUnit,
  Budget,
  BudgetCategory,
  BudgetItem,
  BudgetItemTemplate,
  BudgetsConnection,
  BudgetsConnectionInput,
  BudgetSummary,
  BudgetSummaryConnection,
  BudgetSummaryConnectionInput,
  BudgetUnit,
  BudgetUnitConnectionInput,
} from '@atom/types/budget';
import {
  Environment,
  isCurrentEnvironment,
} from '@atom/utilities/featureToggleUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailFilters from './filtersBar/BudgetDetailFilters';
import BudgetDetailBreadcrumbs from './BudgetDetailBreadcrumbs';
import BudgetDetailContext from './BudgetDetailContext';
import BudgetDetailHeader from './BudgetDetailHeader';
import BudgetDetailStatus from './BudgetDetailStatus';
import BudgetDetailUnit from './BudgetDetailUnit';
import { BUDGET_OPTIONS_LIMIT, EditField } from './budgetDetailUtils';

import './budgetDetail.css';

interface Props {
  match: any;
}

const styles = {
  fixedFiltersContainer: {
    position: 'fixed',
    height: '75px',
    marginTop: '110px',
    padding: '1rem 0 1rem 1.5rem',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.neutral.white,
    zIndex: 100,
  },
  breadcrumbRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const getBorder = (scrolled: boolean) => ({
  borderBottom: scrolled ? `1px solid ${colors.neutral.ash}` : 'none',
});

const BudgetDetail = ({ match }: Props) => {
  const preferences = usePreferences();
  const showExpenditures = R.pathOr(
    false,
    ['budgeting', 'showExpenditures'],
    preferences,
  );

  const [parentBudgetUnit, setParentBudgetUnit] = useState<BudgetUnit>();
  const [childBudgetUnits, setChildBudgetUnits] = useState<BudgetUnit[]>([]);
  const [budgetCategories, setBudgetCategories] = useState<BudgetCategory[]>(
    null,
  );
  const [unitsVisited, setUnitsVisited] = useState<{ id?: BasicBudgetUnit }>(
    {},
  );

  // navigation options
  const [budgetOptions, setBudgetOptions] = useState<Budget[]>([]);
  const [totalBudgetOptions, setTotalBudgetOptions] = useState<number>(0);
  const [
    getBudgetOptions,
    { data: budgetOptionsData, loading: loadingBudgetOptions },
  ] = useLazyQuery<
    { budgets: BudgetsConnection },
    { input: BudgetsConnectionInput }
  >(GET_BUDGET_OPTIONS, { fetchPolicy: 'network-only' });

  useEffect(() => {
    if (!isNilOrEmpty(budgetOptionsData)) {
      const nextOptions: Budget[] = R.pathOr(
        [],
        ['budgetOptions', 'budgets'],
        budgetOptionsData,
      );
      const newTotal: number = R.pathOr(
        0,
        ['budgetOptions', 'totalCount'],
        budgetOptionsData,
      );
      setBudgetOptions([...(budgetOptions || []), ...nextOptions]);
      setTotalBudgetOptions(newTotal);
    }
  }, [budgetOptionsData]);

  // filters
  const [showTracking, setShowTracking] = useState<boolean>(showExpenditures);
  const [showChart, setShowChart] = useState<boolean>(showExpenditures);
  const [excludeZeroBudgetItems, setExcludeZeroBudgetItems] = useState<boolean>(
    false,
  );
  const [categoryFilters, setCategoryFilters] = useState<BudgetCategory[]>([]);
  const [budgetItemTemplateFilters, setBudgetItemTemplateFilters] = useState<
    BudgetItemTemplate[]
  >([]);
  const categoryIds = useMemo(
    () => categoryFilters.map(category => category?.id),
    [categoryFilters],
  );
  const budgetItemTemplateNames = useMemo(
    () => budgetItemTemplateFilters.map(template => template.name),
    [budgetItemTemplateFilters],
  );

  // Budget Comparisons
  const [openComparisonRowId, setOpenComparisonRowId] = useState<string>();
  const [comparisonBudgets, setComparisonBudgets] = useState<BasicBudget[]>([]);
  const [budgetSummaries, setBudgetSummaries] = useState<BudgetSummary[]>([]);
  const [budgetOverviewSummaries, setBudgetOverviewSummaries] = useState<
    BudgetSummary[]
  >([]);

  const [fetchSummaries, { loading: loadingSummaries }] = useLazyQuery<
    { budgetSummary: BudgetSummaryConnection },
    { input: BudgetSummaryConnectionInput }
  >(GET_BUDGET_SUMMARIES, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const summaries: BudgetSummary[] = R.pathOr(
        [],
        ['budgetSummary', 'budgetSummary'],
        data,
      );
      setBudgetSummaries(summaries);
    },
  });

  // Item Editing
  const [editingItem, setEditingItem] = useState<BudgetItem>();
  const [editingField, setEditingField] = useState<EditField>();
  const [itemHoverId, setItemHoverId] = useState<string>();
  const [expandedCategories, setExpandedCategories] = useState<Set<string>>(
    new Set([]),
  );

  // Top-level call to get budget for this page
  const { data: budgetData } = useQuery(GET_BUDGET, {
    variables: {
      id: match.params?.id,
    },
    fetchPolicy: 'network-only',
  });
  const budget: Budget = R.pathOr(null, ['budget'], budgetData);

  const scrolled = useScrollPos() > 0;

  // Clear Comparison Selections on budget navigation
  useEffect(() => {
    setComparisonBudgets([]);
    setParentBudgetUnit(null);
    setChildBudgetUnits([]);
    setBudgetCategories([]);
    if (!isNilOrEmpty(budget)) {
      setBudgetOptions([]);
      getBudgetOptions({
        variables: {
          input: {
            templateId: budget.templateId,
            page: 1,
            limit: BUDGET_OPTIONS_LIMIT,
            sortBy: 'name,asc',
          },
        },
      });
    }
  }, [budget]);

  // Update unitsVisited and clear overview summaries on parent unit navigation
  useEffect(() => {
    setBudgetOverviewSummaries([]);
    if (
      !isNilOrEmpty(parentBudgetUnit) &&
      !unitsVisited[parentBudgetUnit?.id]
    ) {
      setUnitsVisited({
        ...unitsVisited,
        [parentBudgetUnit?.id]: parentBudgetUnit,
      });
    }
  }, [parentBudgetUnit]);

  const [
    getParentUnit,
    { data: parentBudgetUnitData, loading: loadingParentUnit },
  ] = useLazyQuery<
    { budgetUnit: BudgetUnit },
    { input: BudgetUnitConnectionInput }
  >(GET_BUDGET_UNIT, {
    fetchPolicy: 'network-only',
  });

  useEffect(
    () =>
      setParentBudgetUnit(R.pathOr(null, ['budgetUnit'], parentBudgetUnitData)),
    [parentBudgetUnitData],
  );

  useEffect(() => {
    if (!isNilOrEmpty(budget)) {
      getParentUnit({
        variables: {
          input: {
            budgetId: budget?.id,
            budgetUnitId: budget?.rootBudgetUnitId,
            categoryIds,
            budgetItemTemplateNames,
          },
        },
      });
    }
  }, [budget]);

  const FixedHeader = ({ children }) => (
    <div
      style={{
        ...styles.fixedFiltersContainer,
        ...getBorder(scrolled),
      }}
    >
      {children}
    </div>
  );

  return (
    <BudgetDetailContext.Provider
      value={{
        budget,
        parentBudgetUnit,
        setParentBudgetUnit,
        childBudgetUnits,
        setChildBudgetUnits,
        budgetCategories,
        setBudgetCategories,
        unitsVisited,
        setUnitsVisited,
        categoryFilters,
        categoryIds,
        setCategoryFilters,
        budgetItemTemplateFilters,
        setBudgetItemTemplateFilters,
        budgetItemTemplateNames,
        editingItem,
        setEditingItem,
        editingField,
        setEditingField,
        expandedCategories,
        setExpandedCategories,
        excludeZeroBudgetItems,
        setExcludeZeroBudgetItems,
        getParentUnit,
        loadingParentUnit,
        comparisonBudgets,
        setComparisonBudgets,
        budgetSummaries,
        setBudgetSummaries,
        budgetOverviewSummaries,
        setBudgetOverviewSummaries,
        openComparisonRowId,
        setOpenComparisonRowId,
        fetchSummaries,
        loadingSummaries,
        itemHoverId,
        setItemHoverId,
        showTracking,
        setShowTracking,
        showExpenditures,
        showChart,
        setShowChart,
        loadingBudgetOptions,
        totalBudgetOptions,
        setTotalBudgetOptions,
        budgetOptions,
        setBudgetOptions,
        getBudgetOptions,
      }}
    >
      <>
        {isNilOrEmpty(budget) ? (
          <Progress />
        ) : (
          <>
            <BudgetDetailHeader />
            <div styleName="container">
              <FixedHeader>
                <div style={styles.breadcrumbRow}>
                  <BudgetDetailBreadcrumbs />
                  {/*
                    TODO: Replace with Budgeting preference
                    https://atomai.atlassian.net/browse/AM-15653
                  */}
                  {isCurrentEnvironment([Environment.DEV]) && (
                    <BudgetDetailStatus />
                  )}
                </div>
                <BudgetDetailFilters />
              </FixedHeader>
              <BudgetDetailUnit />
            </div>
          </>
        )}
      </>
    </BudgetDetailContext.Provider>
  );
};

export default BudgetDetail;
