import React from 'react';
import {
  ListItemText as MUIListItemText,
  ListItemTextProps as ListItemTextPropsType,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as R from 'ramda';

import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';

type CustomProps = {
  primaryTextStyle?: React.CSSProperties;
  secondaryTextStyle?: React.CSSProperties;
};

export type ListItemTextProps = CustomProps & ListItemTextPropsType;

const useStyles = makeStyles({
  // @ts-ignore
  primary: ({ primaryTextStyle }: ListItemTextProps) => ({
    fontWeight: 500,
    fontSize: fonts.md,
    color: colors.neutral.dark,
    ...primaryTextStyle,
  }),
  // @ts-ignore
  secondary: ({ secondaryTextStyle }) => ({
    fontSize: fonts.sm,
    color: colors.neutral.gray,
    ...secondaryTextStyle,
  }),
});

export const ListItemText = (props: ListItemTextProps) => {
  const { primary, secondary } = useStyles(props);

  const {
    classes = {},
    primaryTypographyProps = { style: { whiteSpace: 'normal' } },
  } = props;

  return (
    <MUIListItemText
      {...R.omit(['primaryTextStyle', 'secondaryTextStyle'], props)}
      classes={{
        primary,
        secondary,
        ...classes,
      }}
      primaryTypographyProps={primaryTypographyProps}
    />
  );
};

export default ListItemText;
