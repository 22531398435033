import * as React from 'react';

import { Select } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';

import '../../formInstance.css';

const { MenuItem } = Select;

const styles = {
  textFieldStyle: {
    color: colors.neutral.dark,
    fontSize: fonts.md,
  },
  underlineStyle: {
    '&:before': {
      borderBottom: `1px solid ${colors.neutral.silver}`,
    },
    '&:after': {
      borderBottom: `1px solid ${colors.neutral.silver}`,
    },
  },
  selectFieldStyle: {
    input: {
      fontSize: fonts.md,
      color: colors.neutral.black,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    selected: {
      fontSize: fonts.md,
      color: colors.brand.blue,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    iconStyle: {
      fill: colors.neutral.dim,
    },
  },
};

interface PassedProps {
  type: string;
  value: any;
  isEditable: boolean;
  options: string[];
  onChange: (event: any) => void;
}

type Props = PassedProps;

const EnumSingleAttribute = ({
  type,
  value,
  options,
  isEditable,
  onChange,
}: Props) => {
  return (
    <div styleName="attribute-input">
      <Select
        name={type}
        value={value}
        onChange={onChange}
        fullWidth
        style={styles.textFieldStyle}
        disabled={!isEditable}
      >
        {options.map((option: string) => {
          return (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default EnumSingleAttribute;
