import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as inventoryExportActionCreators from '@atom/actions/inventoryExportActions';
import { View } from '@atom/components/inventoryExport/InventoryExportContext';
import layout from '@atom/styles/layout';
import history from '@atom/utilities/history';

import InventoryCustomExportsTable from './InventoryCustomExportsTable';
import InventoryExportList from './InventoryExportList';
import InventoryExportPortalHeader from './InventoryExportPortalHeader';
import InventoryExportSidebar from './InventoryExportSidebar';

import './inventoryExport.css';

const styles = {
  rightPane: {
    display: 'flex',
    flexDirection: 'column',
    width: `calc(100% - ${layout.sidePaneWidth})`,
    height: `calc(100vh - ${layout.fixedHeaderOffset})`,
    marginTop: '6.875rem',
  },
};

const InventoryExportPortal = () => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.loading.loadingExportTemplates);
  const exportTemplates = useSelector(state => state.exportTemplates);
  const loadingExportTemplateCreation = useSelector(
    state => state.loading.loadingExportTemplateCreation,
  );

  const [activeView, setActiveView] = useState<View>(View.DATA_EXPORTS);

  useEffect(() => {
    dispatch(
      inventoryExportActionCreators.requestExportTemplates({
        isPublished: 'true,false',
      }),
    );
  }, []);

  const getContent = () => {
    const views = {
      [View.DATA_EXPORTS]: (
        <InventoryExportList
          exportTemplates={exportTemplates}
          loading={loading}
        />
      ),
      [View.CUSTOM_EXPORTS]: <InventoryCustomExportsTable />,
    };

    return views[activeView];
  };

  const navigateBack = (): void => {
    const state = history.location.state;
    return history.push('/inventory', state);
  };

  return (
    <div styleName="portal-wrapper">
      <InventoryExportPortalHeader
        navigateBack={navigateBack}
        loading={loadingExportTemplateCreation}
      />
      <div styleName="left-pane">
        <InventoryExportSidebar
          activeView={activeView}
          setActiveView={setActiveView}
        />
      </div>
      <div style={styles.rightPane}>{getContent()}</div>
    </div>
  );
};

export default InventoryExportPortal;
