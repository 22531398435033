import React, { useEffect } from 'react';
import { Task } from 'client/types/task';
import { WorkOrderDetailType } from 'client/types/work';
import * as R from 'ramda';

import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import AttributeGroupTable from './AttributeGroupTable';
import RootAssetInfo from './RootAssetInfo';

import '../workOrderDetailPdfReport.css';

interface Props {
  workOrderAssetTreesPerTask?: any[];
  taskIndex: number;
  taskTitle: string;
  task: Task;
  workOrder: WorkOrderDetailType;
  setOtherPhotosLoading: (currentLoading: { [id: string]: boolean }) => void;
  getFullPathName: (id: string) => void;
}

const ReportTask = ({
  workOrderAssetTreesPerTask,
  taskIndex,
  taskTitle,
  task,
  workOrder,
  setOtherPhotosLoading,
  getFullPathName,
}: Props) => {
  useEffect(() => {
    if (isNilOrEmpty(workOrderAssetTreesPerTask)) {
      // @ts-ignore
      setOtherPhotosLoading(currentLoading => ({
        ...currentLoading,
        attributeGroupTable: false,
      }));
    }
  }, []);

  useEffect(() => {
    const hasRoot = !!R.find(
      R.propEq('id', workOrder?.inventoryAssetId),
      workOrderAssetTreesPerTask,
    );

    // If no root asset is present, ends photo loading state
    if (!hasRoot) {
      // @ts-ignore
      setOtherPhotosLoading(currentLoading => ({
        ...currentLoading,
        attributeGroupAssessment: false,
      }));
    }
  }, []);

  const getDescriptionLines = () => {
    const splitValue = R.pathOr('', ['description'], task).split('\n');
    const cleanLines = splitValue.reduce((acc, value) => {
      return value ? [...acc, value] : acc;
    }, []);

    return cleanLines;
  };

  const descriptionLines = getDescriptionLines();

  return (
    <>
      <div styleName="task-title">{taskTitle}</div>
      {descriptionLines.map(item => (
        <div styleName="task-subtitle">{item}</div>
      ))}
      {workOrderAssetTreesPerTask.map((linkedTree, linkedTreeIndex) => {
        const lastTreeLoaded =
          workOrder.tasks.length === taskIndex + 1 &&
          workOrderAssetTreesPerTask.length === linkedTreeIndex + 1;

        const isRootAsset = linkedTree.id === workOrder.inventoryAssetId;

        return (
          <>
            {isRootAsset && (
              <RootAssetInfo
                workOrderAsset={linkedTree}
                workOrderId={workOrder.id}
                setOtherPhotosLoading={setOtherPhotosLoading}
                getFullPathName={getFullPathName}
              />
            )}
            <AttributeGroupTable
              isRootAsset={isRootAsset}
              tree={linkedTree}
              lastTreeLoaded={lastTreeLoaded}
              getFullPathName={getFullPathName}
              workOrderId={workOrder.id}
              setOtherPhotosLoading={setOtherPhotosLoading}
            />
          </>
        );
      })}
    </>
  );
};

export default ReportTask;
