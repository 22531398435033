import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import TextOverflowTooltip from '@atom/components/common/tooltip/TextOverflowTooltip';
import WorkTemplateContext, {
  WorkTemplateView,
} from '@atom/components/workTemplate/WorkTemplateContext';
import { GET_WORK_ORDER_TEMPLATES } from '@atom/graph/workTemplate';
import { Icon, ListTable } from '@atom/mui';
import { PolicyAction } from '@atom/types/policy';
import {
  WorkOrderTemplatesConnectionInput,
  WorkTemplatesConnection,
} from '@atom/types/workTemplate';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import { getWorkTypeIconSvg } from '@atom/utilities/workTypeIconUtilities';

import './workTemplateChildTypes.css';

const {
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
} = ListTable;

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 25;

const styles = {
  progress: {
    height: '100%',
  },
  statusCell: {
    display: 'flex',
    alignItems: 'center',
  },
  statusName: {
    paddingLeft: '5px',
  },
};

const WorkTemplateChildTypes = () => {
  const { workTemplate: workTemplateContext, setActiveView } = useContext(
    WorkTemplateContext,
  );

  const [page, setPage] = useState<number>(INITIAL_PAGE);
  const [limit, setLimit] = useState<number>(INITIAL_LIMIT);

  const { data, refetch } = useQuery<
    { workOrderTemplates: WorkTemplatesConnection },
    { input: WorkOrderTemplatesConnectionInput }
  >(GET_WORK_ORDER_TEMPLATES, {
    variables: {
      input: {
        page,
        limit,
        action: PolicyAction.READ,
        parentId: workTemplateContext?.id,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    refetch();
  }, [workTemplateContext?.id]);

  const workTemplates = data?.workOrderTemplates?.workOrderTemplates || [];
  const totalCount = data?.workOrderTemplates?.totalCount || 0;

  const getStatusCell = (published: boolean) => {
    return (
      <div style={styles.statusCell}>
        <Icon>{published ? 'lock' : 'edit'}</Icon>
        <div style={styles.statusName}>{published ? 'Published' : 'Draft'}</div>
      </div>
    );
  };

  const setView = useCallback(() => setActiveView(WorkTemplateView.INFO), [
    setActiveView,
  ]);

  const headerLabel =
    totalCount === 1
      ? `${totalCount} Child Work template`
      : `${totalCount} Child Work templates`;

  return (
    <div styleName="child-types-container">
      <div styleName="center-pane">
        <div styleName="header-label">{headerLabel}</div>
        <ListTable offsetTop="320px">
          <TableHead>
            <TableRow header>
              <TableCell variant="head" />
              <TableCell variant="head">Name</TableCell>
              <TableCell variant="head">Status</TableCell>
              <TableCell variant="head">Asset Type</TableCell>
              <TableCell variant="head">Created By</TableCell>
              <TableCell variant="head">Created On</TableCell>
              <TableCell variant="head" />
            </TableRow>
          </TableHead>
          <TableBody>
            {workTemplates.map(workTemplate => {
              return (
                <TableRow key={workTemplate.id}>
                  <TableCell width="2rem" align="right">
                    <Icon>
                      <img
                        src={getWorkTypeIconSvg(
                          workTemplate?.isParent,
                          workTemplate?.parentId,
                        )}
                      />
                    </Icon>
                  </TableCell>
                  <TableCell>
                    <Link
                      to={`/work-templates/${workTemplate.id}`}
                      onClick={setView}
                    >
                      <TextOverflowTooltip
                        text={workTemplate.name}
                        width="14rem"
                        bold
                        lightTooltip
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{getStatusCell(workTemplate.published)}</TableCell>
                  <TableCell>{workTemplate.schemaName || 'N/A'}</TableCell>
                  <TableCell>
                    {workTemplate.createdBy.firstName}{' '}
                    {workTemplate.createdBy.lastName}
                  </TableCell>
                  <TableCell>
                    {setDisplayDate(workTemplate.createdDate)}
                  </TableCell>
                  <TableCell />
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter width="70%">
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                count={totalCount}
                rowsPerPage={limit}
                page={page}
                onPageChange={value => setPage(value)}
                onRowsPerPageChange={event => {
                  setPage(1);
                  setLimit(+event.target.value);
                }}
              />
            </TableRow>
          </TableFooter>
        </ListTable>
      </div>
    </div>
  );
};

export default WorkTemplateChildTypes;
