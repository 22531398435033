import React, { useEffect, useState } from 'react';

import { TextField } from '@atom/mui';
import { TaskField } from '@atom/types/task';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

interface Props {
  field: TaskField;
  taskId?: string;
  onChange: (fieldId: string, value: any) => void;
  onBlur: (fieldId: string, value: any) => void;
  isDisabled?: boolean;
  previewView?: boolean;
  error?: boolean;
  helperText?: string;
  onClick?: () => void;
}

const TaskNumericField = ({
  field,
  taskId,
  onChange,
  onBlur,
  isDisabled,
  previewView,
  error,
  helperText,
  onClick,
}: Props) => {
  const [value, setValue] = useState<any>(
    !isNilOrEmpty(field?.value) ? field?.value : '',
  );

  useEffect(() => {
    setValue(!isNilOrEmpty(field?.value) ? field?.value : '');
  }, [taskId, field?.id]);

  const handleChange = event => {
    const number =
      event.target.value === '' ? '' : parseFloat(event.target.value);
    setValue(number);
    onChange(field.id, number);
  };

  const handleOnBlur = event => {
    const number =
      event.target.value === '' ? '' : parseFloat(event.target.value);

    if (field?.value !== number) {
      setValue(number);
      onBlur(field.id, number);
    }
  };

  const styles = {
    unitsStyle: {
      textTransform: 'uppercase',
    },
    textField: {
      flex: previewView ? 1 : 0.66,
    },
  };

  const title = (
    <span>
      <span>{field.required ? `* ${field.title}` : field.title}</span>
      {field?.units && (
        <span style={styles.unitsStyle}>{` (${field?.units})`}</span>
      )}
    </span>
  );

  return (
    <TextField
      id={field.id}
      label={title}
      style={styles.textField}
      type="number"
      value={value}
      disabled={isDisabled}
      onChange={handleChange}
      onBlur={handleOnBlur}
      error={error}
      helperText={helperText}
      onClick={onClick}
    />
  );
};

export default TaskNumericField;
