import React from 'react';

import { Icon } from '@atom/mui';
import { FavoriteDetail, FavoriteSubjectType } from '@atom/types/favorite';
import schemaUtilities from '@atom/utilities/schemaUtilities';

import UserThumbnail from '../UserThumbnail';

export interface Props {
  subjectType: FavoriteSubjectType;
  favorite: FavoriteDetail;
}

const styles = {
  icon: {
    marginRight: '0.5rem',
  },
};

const FavoriteItemIcon = ({ subjectType, favorite }: Props) => {
  if (subjectType === 'USER') {
    return (
      <div style={styles.icon}>
        <UserThumbnail image={favorite?.photoUrl} alt={favorite?.firstName} />
      </div>
    );
  }
  return (
    <Icon
      color={schemaUtilities.getSchemaColorFromColorId(favorite?.colorId)}
      style={styles.icon}
    >
      category
    </Icon>
  );
};

export default FavoriteItemIcon;
