// use this hook to detect clicks outside the current component
// - added ignoreDatepicker param for date range inputs to ignore MUI datepicker clicks
// - Datepickers are both outside the parent container and not present in
//   the tree when useOutsideClick is called

import { useEffect, useRef } from 'react';

export const useOutsideClick = (
  callback: () => void,
  ignoreDatepicker: boolean = false,
) => {
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (ref?.current && !ref.current.contains(target)) {
        const datepicker = document.getElementsByClassName(
          'MuiCalendarPicker-root',
        );
        if (ignoreDatepicker && !datepicker[0]?.contains(target)) {
          callback();
        }
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref]);

  return ref;
};
