import { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import client from '@atom/graph/client';
import { WORK_TEMPLATE_SEARCH } from '@atom/graph/workTemplate';
import { PolicyAction } from '@atom/types/policy';
import { WorkOrderType } from '@atom/types/work';
import {
  SearchWorkTemplate,
  SearchWorkTemplatesConnection,
  SearchWorkTemplatesConnectionInput,
} from '@atom/types/workTemplate';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

export interface WorkTemplateSearchOptions {
  query: string;
  schemaId?: string;
  hasSchema?: boolean;
  limit?: number;
  debounceTime?: number;
  characterMin?: number;
  action?: PolicyAction;
  type?: WorkOrderType;
}

type ReturnData = [
  SearchWorkTemplatesConnection,
  {
    fetchMore: () => void;
    hasMore: boolean;
    loadingSearch: boolean;
    loadingPagination: boolean;
    error: boolean;
  },
];

type DebouncedSearch = (
  input: SearchWorkTemplatesConnectionInput,
  loaded: SearchWorkTemplate[],
) => Promise<void>;

export const useWorkTemplateSearch = ({
  query,
  schemaId,
  hasSchema,
  limit,
  action,
  debounceTime = 300,
  characterMin = 3,
  type,
}: WorkTemplateSearchOptions): ReturnData => {
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [loadingPagination, setLoadingPagination] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [connection, setConnection] = useState<SearchWorkTemplatesConnection>({
    totalCount: 0,
    workOrderTemplates: [],
  });

  const nextPage = useMemo(() => {
    if (!limit || !connection.totalCount) {
      return 1;
    }

    return Math.floor(connection.workOrderTemplates.length / limit) + 1;
  }, [connection, limit]);

  const searchWorkTemplates = async (
    input: SearchWorkTemplatesConnectionInput,
    loaded: SearchWorkTemplate[],
  ) => {
    setError(false);

    try {
      const { data } = await client.query<
        { workOrderTemplateSearch: SearchWorkTemplatesConnection },
        { input: SearchWorkTemplatesConnectionInput }
      >({
        query: WORK_TEMPLATE_SEARCH,
        fetchPolicy: 'no-cache',
        // @ts-ignore
        variables: { input: R.reject(isNilOrEmpty, input) },
      });

      setConnection({
        totalCount: data.workOrderTemplateSearch.totalCount,
        workOrderTemplates: [
          ...loaded,
          ...data.workOrderTemplateSearch.workOrderTemplates,
        ],
      });
    } catch (err) {
      setError(true);
    }

    setLoadingSearch(false);
    setLoadingPagination(false);
  };

  const searchWorkTemplatesDebounced = useCallback<DebouncedSearch>(
    debounce(searchWorkTemplates, debounceTime),
    [],
  );

  useEffect(() => {
    setConnection({ totalCount: 0, workOrderTemplates: [] });

    if (query.length >= characterMin) {
      setLoadingSearch(true);
      searchWorkTemplatesDebounced(
        {
          query,
          schemaId,
          hasSchema,
          limit,
          page: 1,
          published: true,
          action,
          type,
        },
        [],
      );
    }
  }, [query, schemaId, hasSchema]);

  const hasMore = connection.workOrderTemplates.length < connection.totalCount;

  const fetchMore = () => {
    if (hasMore) {
      setLoadingPagination(true);

      searchWorkTemplates(
        {
          query,
          schemaId,
          hasSchema,
          limit,
          action,
          page: nextPage,
          published: true,
          type,
        },
        connection.workOrderTemplates,
      );
    }
  };

  return [
    connection,
    {
      fetchMore,
      hasMore,
      loadingSearch,
      loadingPagination,
      error,
    },
  ];
};
