import React from 'react';

import { Button, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';

interface Props {
  loading: boolean;
  error: boolean;
  onClick: () => void;
  style?: React.CSSProperties;
}

const styles = {
  progress: {
    marginRight: '0.2rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  error: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: fonts.md,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  button: {
    color: colors.brand.blue,
  },
};

const LoadMoreButton = ({ loading, error, onClick, style = {} }: Props) => {
  if (error) {
    return (
      <div style={{ ...styles.container, ...style }}>
        <span style={styles.error}>Load Failed.</span>
        <Button onClick={onClick} style={styles.button}>
          Try Again
        </Button>
      </div>
    );
  }

  return (
    <div style={style}>
      <Button onClick={onClick} style={styles.button}>
        {loading ? (
          <>
            <Progress size={16} thickness={5} style={styles.progress} />
            Loading ...
          </>
        ) : (
          'Load More'
        )}
      </Button>
    </div>
  );
};

export default LoadMoreButton;
