import React, { useContext, useEffect, useMemo } from 'react';

import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { useSchemas } from '@atom/hooks/useSchemas';
import { RadioGroup } from '@atom/mui';
import { RequiredOption } from '@atom/types/workTemplate';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import { WorkTypeVariant } from '@atom/utilities/workTemplateUtilities';

import SchemaRow from './SchemaRow';

import './multiSchemaSection.css';

const { RadioButton } = RadioGroup;

interface Props {
  allowedMultiAssetSchemaIds: Set<string>;
  requiredMultiAssetSchemaIds: Set<string>;
  setRequiredMultiAssetSchemaIds: (
    requiredMultiAssetSchemaIds: Set<string>,
  ) => void;
  requireAtLeastOneMultiAsset: boolean;
  setRequireAtLeastOneMultiAsset: (
    requireAtLeastOneMultiAsset: boolean,
  ) => void;
  requiredOption: RequiredOption;
  setRequiredOption: (requiredOption: RequiredOption) => void;
}

const RequiredSchemaView = ({
  allowedMultiAssetSchemaIds,
  requiredMultiAssetSchemaIds,
  setRequiredMultiAssetSchemaIds,
  requireAtLeastOneMultiAsset,
  setRequireAtLeastOneMultiAsset,
  requiredOption,
  setRequiredOption,
}: Props) => {
  const { workTypeVariant } = useContext(WorkTemplateContext);

  const { schemas = [] } = useSchemas();

  const allowedSchemas = useMemo(() => {
    return schemas.filter(schema => allowedMultiAssetSchemaIds.has(schema?.id));
  }, [schemas, allowedMultiAssetSchemaIds]);

  const updateInitialOption = () => {
    switch (true) {
      case requireAtLeastOneMultiAsset: {
        return setRequiredOption(RequiredOption.AT_LEAST_ONE);
      }
      case requiredMultiAssetSchemaIds.size >= 1: {
        return setRequiredOption(RequiredOption.MANY);
      }
      default:
        return setRequiredOption(RequiredOption.NONE);
    }
  };

  useEffect(() => {
    updateInitialOption();
  }, []);

  const updateOption = (newOption: RequiredOption) => {
    setRequiredMultiAssetSchemaIds(new Set([]));
    setRequireAtLeastOneMultiAsset(newOption === RequiredOption.AT_LEAST_ONE);
    setRequiredOption(newOption);
  };

  const onSchemaClick = (schemaId: string) => {
    setRequiredMultiAssetSchemaIds(
      toggleFromSet(requiredMultiAssetSchemaIds, schemaId),
    );
  };

  const disableRadioButton = workTypeVariant === WorkTypeVariant.CHILD;

  const disableSchema =
    requiredOption !== RequiredOption.MANY ||
    workTypeVariant === WorkTypeVariant.CHILD;

  return (
    <>
      <div>
        <div styleName="view-title">Required Assets</div>
        <div styleName="view-subtext">
          If any assets needs to be added on the task for the task to be
          completed or work to be completed or closed.
        </div>
      </div>
      <RadioGroup
        value={requiredOption}
        onChange={event => updateOption(event.target.value)}
      >
        <RadioButton
          value={RequiredOption.NONE}
          label="No assets are required"
          disabled={disableRadioButton}
        />
        <RadioButton
          value={RequiredOption.AT_LEAST_ONE}
          label="At least 1 asset from any allowed asset type is required"
          disabled={disableRadioButton}
        />
        <RadioButton
          value={RequiredOption.MANY}
          label="At least 1 asset from each of the selected asset type(s) is required"
          disabled={disableRadioButton}
        />
      </RadioGroup>
      <div styleName="sub-list">
        {allowedSchemas.map(schema => (
          <SchemaRow
            key={schema?.id}
            schema={schema}
            onClick={onSchemaClick}
            checked={requiredMultiAssetSchemaIds.has(schema?.id)}
            disabled={disableSchema}
          />
        ))}
      </div>
    </>
  );
};

export default RequiredSchemaView;
