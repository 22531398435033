import React from 'react';

import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { convertMillisToExactHours } from '@atom/utilities/timeUtilities';

import { ReportUser } from './reportUtilities';

import './workOrderReport.css';

interface Props {
  user: ReportUser;
  isCompletedOrAbove: boolean;
}

const ReportUserRow = ({ user, isCompletedOrAbove }: Props) => {
  const getDurationDisplay = () => {
    if (user?.duration) {
      return convertMillisToExactHours(user?.duration);
    }

    if (!user?.duration && isCompletedOrAbove) {
      return '-';
    }

    return '';
  };

  const duration = getDurationDisplay();

  return (
    <div styleName="users-table-row">
      <div styleName="users-table-sub-row">
        <div styleName="users-row-cell name">
          {user?.isTeamLead && <span styleName="team-lead">&#9733;</span>}
          {user?.name}
        </div>
        <div styleName="users-row-cell">{duration}</div>
      </div>
      {isCompletedOrAbove && (
        <div styleName="users-row-cell cost">
          {numberToLocaleString(user?.actualCost)}
        </div>
      )}
    </div>
  );
};

export default ReportUserRow;
