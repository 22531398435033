import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { SortDirection } from '@mui/material';
import * as R from 'ramda';

import { GET_ANALYTICS_FOLDERS } from '@atom/graph/analytics';
import { Icon, ListTable, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import layout from '@atom/styles/layout';
import { AnalyticsSortByOptions } from '@atom/types/analytics';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';

import AnalyticsContext from './AnalyticsContext';
import FolderOptions from './FolderOptions';

import '../../styles/table.css';

const {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} = ListTable;

const styles = {
  progress: {
    height: '100%',
  },
  name: {
    cursor: 'pointer',
    fontWeight: 500,
    overflow: 'visible',
    whiteSpace: 'nowrap',
  },
};

const SpacerRow = (
  <TableRow hover={false}>
    <TableCell style={{ height: '37px', border: 'none' }} colSpan={5} />
  </TableRow>
);

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 25;

const AnalyticsFolderList = () => {
  const {
    activeFolderId,
    setActiveFolderId,
    setSearching,
    triggerListRefetch,
    setIsActiveFolderLeafNode,
  } = useContext(AnalyticsContext);

  const [page, setPage] = useState<number>(INITIAL_PAGE);
  const [limit, setLimit] = useState<number>(INITIAL_LIMIT);
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [sortBy, setSortBy] = useState<AnalyticsSortByOptions>('name');

  const { data, loading, refetch } = useQuery(GET_ANALYTICS_FOLDERS, {
    variables: {
      input: {
        parentFolderId: activeFolderId,
        page,
        limit,
        sortBy,
        isAscending: sortDirection === 'asc',
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    refetch();
  }, [triggerListRefetch]);

  const getSortDirection = (field: string) => {
    return field === sortBy ? sortDirection : 'desc';
  };

  const handleSortDirectionChange = (field: AnalyticsSortByOptions) => (
    newSortDirection: SortDirection,
  ) => {
    setSortBy(field);
    setSortDirection(newSortDirection);
  };

  const analyticsFolders = data?.analyticsFolders?.analyticsFolders || [];
  const totalCount = data?.analyticsFolders?.totalCount || 0;
  const showFolderOptions = hasRolePermissions(ROLE_SETS.ORG_ADMIN);

  return (
    <>
      {loading ? (
        <Progress style={styles.progress} />
      ) : (
        <ListTable offsetTop="110px">
          <TableHead>
            <TableRow header>
              <TableCell variant="head" />
              <TableCell
                variant="head"
                sortDirection={getSortDirection('name')}
                onSortChange={handleSortDirectionChange('name')}
              >
                Name
              </TableCell>
              <TableCell variant="head">Created By</TableCell>
              <TableCell
                variant="head"
                sortDirection={getSortDirection('createdDate')}
                onSortChange={handleSortDirectionChange('createdDate')}
              >
                Created On
              </TableCell>
              <TableCell variant="head" />
            </TableRow>
          </TableHead>
          <TableBody>
            {analyticsFolders.map(folder => {
              const onClick = () => {
                setActiveFolderId(folder.id);
                setSearching(false);
                setIsActiveFolderLeafNode(folder.hasCards);
              };
              return (
                <TableRow key={folder.id}>
                  <TableCell width="2rem" align="right">
                    <Icon>folder</Icon>
                  </TableCell>
                  <TableCell>
                    <div style={styles.name} onClick={onClick}>
                      {folder.name}
                    </div>
                  </TableCell>
                  <TableCell>
                    {!R.isNil(folder.createdBy)
                      ? `${folder.createdBy.firstName} ${folder.createdBy.lastName}`
                      : '-'}
                  </TableCell>
                  <TableCell>{setDisplayDate(folder.createdDate)}</TableCell>
                  <TableCell width="3rem">
                    {showFolderOptions && <FolderOptions folder={folder} />}
                  </TableCell>
                </TableRow>
              );
            })}
            {SpacerRow}
          </TableBody>
          <TableFooter
            style={{ border: `1px solid ${colors.neutral.ash}` }}
            width={`calc(100vw - ${layout.sidePaneWidth})`}
          >
            <TableRow hover={false}>
              <TablePagination
                style={{ border: 'none' }}
                rowsPerPageOptions={[25, 50, 100, 250]}
                count={totalCount}
                rowsPerPage={limit}
                page={page}
                onPageChange={pageData => setPage(pageData)}
                onRowsPerPageChange={event => {
                  setLimit(+event.target.value);
                  setPage(1);
                }}
              />
            </TableRow>
          </TableFooter>
        </ListTable>
      )}
    </>
  );
};

export default AnalyticsFolderList;
