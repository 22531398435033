import React, { Dispatch, SetStateAction } from 'react';

export enum View {
  DATA_EXPORTS = 'DATA_EXPORTS',
  CUSTOM_EXPORTS = 'CUSTOM_EXPORTS',
}

interface Context {
  activeView: View;
  setActiveView: Dispatch<SetStateAction<boolean>>;
}

export const initialState: Context = {
  activeView: View.DATA_EXPORTS,
  setActiveView: () => {},
};

const InventoryExportContext = React.createContext<Context>(initialState);

export default InventoryExportContext;
