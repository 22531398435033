import React from 'react';

import { Icon, IconButton } from '@atom/mui';
import { pluralize } from '@atom/utilities/stringUtilities';

import './drawerContent.css';

interface Props {
  actionIcon?: React.ReactNode;
  actionOnClick?: () => void;
  actionTooltip?: string;
  count: number;
  label: string;
  open: boolean;
  toggle: () => void;
}

const ContentListHeader = ({
  actionIcon,
  actionOnClick,
  actionTooltip,
  count,
  label,
  open,
  toggle,
}: Props) => {
  if (count === 0) {
    return null;
  }
  const title = pluralize(count, label);
  return (
    <div styleName="drawer-content-list-header">
      <div styleName="drawer-content-list-header-title">
        {count} {title}
      </div>
      <div styleName="drawer-content-list-header-action">
        {actionIcon && (
          <IconButton
            edge="end"
            tooltip={actionTooltip}
            onClick={actionOnClick}
          >
            {actionIcon}
          </IconButton>
        )}
        <IconButton onClick={toggle}>
          <Icon>{open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
        </IconButton>
      </div>
    </div>
  );
};

export default ContentListHeader;
