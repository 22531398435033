import React, { useContext } from 'react';
import * as R from 'ramda';

import TextOverflowTooltip from '@atom/components/common/tooltip/TextOverflowTooltip';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { BudgetUnitTree } from '@atom/types/budget';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from './BudgetDetailContext';

import './budgetDetail.css';

const PADDING_OFFSET = 1;

const styles = {
  iconStyle: {
    fontSize: '1.75rem',
    margin: 0,
    padding: '0.25rem 0',
  },
  blankStyle: {
    fontSize: '1.75rem',
    margin: 0,
    padding: '0.25rem 0.75rem',
  },
  unitNameContainer: {
    display: 'flex',
    flexFlow: 'column',
    paddingLeft: '0.75rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  unitIconContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    maxWidth: '21rem',
  },
};

export interface Props {
  tree: BudgetUnitTree;
  indentation?: number;
  handleNavigateToUnit: (unitId: string) => void;
  collapsed: Set<string>;
  setCollapsed: (collapsed: Set<string>) => void;
}

const BudgetDetailUnitTree = ({
  tree,
  indentation = 0,
  handleNavigateToUnit,
  collapsed,
  setCollapsed,
}: Props) => {
  const { parentBudgetUnit } = useContext(BudgetDetailContext);

  const leftIcons = (
    <>
      {!R.isEmpty(tree.children) ? (
        <IconButton
          style={styles.iconStyle}
          onClick={() => setCollapsed(toggleFromSet(collapsed, tree.id))}
        >
          <Icon color={colors.neutral.dim}>
            {collapsed.has(tree.id) ? 'arrow_right' : 'arrow_drop_down'}
          </Icon>
        </IconButton>
      ) : (
        <div style={styles.blankStyle} />
      )}
      <Icon
        style={styles.iconStyle}
        color={
          isNilOrEmpty(tree.children)
            ? colors.utility.activeBlue
            : colors.neutral.gray
        }
        onClick={() => handleNavigateToUnit(tree.id)}
      >
        folder
      </Icon>
    </>
  );

  const indentWidth = PADDING_OFFSET * indentation;

  const isCurrentUnit = tree.id === parentBudgetUnit.id;

  const containerStyle = {
    paddingLeft: `calc(${indentWidth}rem)`,
    maxWidth: '21rem',
    backgroundColor: isCurrentUnit ? colors.utility.blueHighlight : 'auto',
  };

  return (
    <>
      <div styleName="unit-nav-item-container" style={containerStyle}>
        <div style={styles.unitIconContainer}>
          {leftIcons}
          <div
            onClick={() => handleNavigateToUnit(tree.id)}
            style={styles.unitNameContainer}
          >
            <TextOverflowTooltip
              width={`${17 - indentWidth}rem`}
              text={tree.name}
              color={isCurrentUnit ? colors.brand.blue : 'auto'}
              bold={isCurrentUnit}
            />
          </div>
        </div>
      </div>
      {!collapsed.has(tree.id) &&
        tree.children.map((child: BudgetUnitTree) => {
          return (
            <BudgetDetailUnitTree
              key={child.id}
              tree={child}
              indentation={indentation + 1}
              handleNavigateToUnit={handleNavigateToUnit}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
          );
        })}
    </>
  );
};

export default BudgetDetailUnitTree;
