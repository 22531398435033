import React, { useContext } from 'react';
import * as R from 'ramda';

import TaskItemTile from '@atom/components/common/workOrderDetail/taskItemTile/TaskItemTile';
import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { getSchemaIconFromMarkerId } from '@atom/utilities/schemaUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './multiSchemaSection.css';

const styles = {
  icon: {
    height: '28px',
    width: '28px',
  },
};

const MultiSchemaList = () => {
  const { task } = useContext(WorkTemplateContext);

  const requiredText = () => {
    switch (true) {
      case task?.requireAtLeastOneMultiAsset: {
        return 'At least 1 asset is required for the task to be completed or the work to be completed or closed.';
      }
      case isNilOrEmpty(task?.requiredMultiAssetSchemas): {
        return 'No assets are required for the task to be completed or the work to be completed or closed.';
      }
      default:
        return 'At least 1 asset from each of the following asset type(s) is required for the task to be completed or the work to be completed or closed.';
    }
  };

  const allowedCount = task?.allowedMultiAssetSchemas.length;

  return (
    <div styleName="schema-list-section">
      <div>
        <div>{`${allowedCount} asset ${
          allowedCount === 1 ? 'type is' : 'types are'
        } allowed for the task.`}</div>
        <div styleName="schema-list">
          {task?.allowedMultiAssetSchemas.map(schema => (
            <TaskItemTile
              key={`allowed-${schema?.id}`}
              content={R.pathOr('', ['name'], schema)}
              icon={
                <img
                  style={styles.icon}
                  src={getSchemaIconFromMarkerId(schema.markerId)}
                />
              }
            />
          ))}
        </div>
      </div>
      <div>
        <div>{requiredText()}</div>
        <div styleName="schema-list">
          {task?.requiredMultiAssetSchemas.map(schema => (
            <TaskItemTile
              key={`required-${schema?.id}`}
              content={R.pathOr('', ['name'], schema)}
              icon={
                <img
                  style={styles.icon}
                  src={getSchemaIconFromMarkerId(schema.markerId)}
                />
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MultiSchemaList;
