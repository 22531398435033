import actionTypes from './actionTypes';

export function setNewMenuState(menuState, toggledMenuString) {
  const state = Object.keys(menuState)
    .filter(key => key !== toggledMenuString)
    .reduce((newState, key) => {
      newState[key] = false;
      return newState;
    }, {});
  state[toggledMenuString] = !menuState[toggledMenuString];
  return state;
}

export const toggleMenu = newState => ({
  type: actionTypes.TOGGLE_NAVIGATION_MENU,
  newState,
});

export function toggleNavigationMenu(menuState, propertyString) {
  const newState = setNewMenuState(menuState, propertyString);
  return async function (dispatch) {
    dispatch(toggleMenu(newState));
  };
}
