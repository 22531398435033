import React, { useState } from 'react';

import WorkTemplateTree from '@atom/components/common/workTemplateTree/WorkTemplateTree';
import { Modal } from '@atom/mui';
import { PolicyAction } from '@atom/types/policy';
import {
  SearchWorkTemplate,
  WorkTemplateListItem,
} from '@atom/types/workTemplate';

const styles = {
  modal: {
    height: '50vh',
    padding: '2rem',
  },
};

type WorkTemplate = WorkTemplateListItem | SearchWorkTemplate;

interface Props {
  open: boolean;
  onClose: () => void;
  setSelectedWorkTemplate: (workTemplate: any) => void;
  selectedWorkTemplate: any;
}

const WorkTemplateModal = ({
  open,
  onClose,
  setSelectedWorkTemplate,
  selectedWorkTemplate,
}: Props) => {
  const [workTemplate, setWorkTemplate] = useState<any>(selectedWorkTemplate);

  const handleClose = () => {
    setWorkTemplate(null);
    onClose();
  };

  const handleConfirm = () => {
    setSelectedWorkTemplate(workTemplate);
    handleClose();
  };

  const handleToggle = (template: WorkTemplate) => {
    setWorkTemplate(selectedWorkTemplate?.id === template.id ? null : template);
  };

  return (
    <Modal
      title="Select Work Template"
      onConfirm={handleConfirm}
      onCancel={handleClose}
      confirmButtonText="Save"
      contentStyle={styles.modal}
      open={open}
      width="lg"
    >
      <WorkTemplateTree
        includeSearch
        select="checkmark"
        getTemplateSelected={template => workTemplate?.id === template.id}
        onTemplateToggle={template => handleToggle(template)}
        onClick={template => handleToggle(template)}
        action={PolicyAction.READ}
      />
    </Modal>
  );
};

export default WorkTemplateModal;
