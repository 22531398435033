import React from 'react';
import {
  Skeleton as MUISkeleton,
  SkeletonProps as SkeletonPropsType,
} from '@mui/material';

type CustomProps = {
  /**
   * Width of the skeleton relative to container
   * @default '100%'
   */
  width?: string;
};

export type SkeletonProps = CustomProps & SkeletonPropsType;

export const Skeleton = (props: SkeletonProps) => {
  const { width = '100%', ...data } = props;

  return <MUISkeleton width={width} {...data} {...props} />;
};

export default Skeleton;
