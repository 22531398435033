import React, { useContext, useMemo } from 'react';

import WorkTemplateCreationModalContext, {
  EMPTY_SCHEMA,
} from '@atom/components/common/workTemplateCreationModal/WorkTemplateCreationModalContext';
import { usePreferences } from '@atom/hooks/usePreferences';
import { useSchemas } from '@atom/hooks/useSchemas';
import { Icon, Select } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventorySchemaItem } from '@atom/types/inventory';
import { TYPE_OPTIONS, WorkOrderType } from '@atom/types/work';
import schemaUtilities from '@atom/utilities/schemaUtilities';

import RadioButtonRow from './RadioButtonRow';

import './workTemplateCreationModal.css';

const { MenuItem } = Select;

const styles = {
  textFieldStyle: {
    marginLeft: '1rem',
    color: colors.neutral.dark,
  },
  icon: {
    marginTop: '1rem',
  },
};

const TemplateTypeView = () => {
  const { schemas = [] } = useSchemas();
  const preferences = usePreferences();
  const multiLocationEnabled = preferences?.workOrders?.multiLocationEnabled;

  const { type, setType, schemaId, setSchemaId } = useContext(
    WorkTemplateCreationModalContext,
  );

  const locationBasedSchemas = useMemo(() => {
    return schemas.filter(schema => !schema.isMaterial);
  }, [schemas]);

  return (
    <>
      <div styleName="modal-row title">Template Type</div>
      {multiLocationEnabled && (
        <RadioButtonRow
          option={TYPE_OPTIONS[WorkOrderType.TASK_ASSETS_AND_LOCATIONS]}
          selected={type === WorkOrderType.TASK_ASSETS_AND_LOCATIONS}
          onClick={setType}
        />
      )}
      <RadioButtonRow
        option={TYPE_OPTIONS[WorkOrderType.DEFAULT]}
        selected={type === WorkOrderType.DEFAULT}
        onClick={setType}
      />
      {type === WorkOrderType.DEFAULT && (
        <div styleName="modal-row">
          <Icon color={colors.neutral.gray} style={styles.icon}>
            category
          </Icon>
          <Select
            value={schemaId}
            style={styles.textFieldStyle}
            onChange={event => setSchemaId(event.target.value)}
            label="Asset Type"
            fullWidth
          >
            <MenuItem key="empty" value={EMPTY_SCHEMA}>
              <div styleName="schema-menu-item-container">
                <div styleName="empty-icon">-</div>
                <div styleName="schema-menu-item-title empty">
                  All Asset Types
                </div>
              </div>
            </MenuItem>
            {locationBasedSchemas.map((schema: InventorySchemaItem) => {
              const icon = schemaUtilities.getSchemaIconFromSchemaOrAsset(
                schema,
              );

              return (
                <MenuItem key={schema.id} value={schema.id}>
                  <div styleName="schema-menu-item-container">
                    {icon}
                    <div styleName="schema-menu-item-title">{schema.name}</div>
                  </div>
                </MenuItem>
              );
            })}
          </Select>
        </div>
      )}
    </>
  );
};

export default TemplateTypeView;
