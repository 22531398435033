import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import {
  useGoogleMapsStateUpdater,
  useGoogleMapsStateValue,
  withGoogleMapsState,
} from '@atom/components/common/map/hooks/googleMapsStateHook';
import Map from '@atom/components/common/map/Map';
import MapMarkers from '@atom/components/common/map/MapMarkers';
import MapPinMarker from '@atom/components/common/map/markers/MapPinMarker';
import { INITIAL_LAYERS } from '@atom/components/mapPortal/hooks/layersHook';
import { useMapData } from '@atom/components/mapPortal/hooks/mapDataHook';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Progress } from '@atom/mui';
import {
  defaultClusterDensitySelector,
  getLoggedInUserMapLocationSelector,
} from '@atom/selectors/mapSelectors';
import history from '@atom/utilities/history';
import {
  buildMapDataRequest,
  getGeoJsonFromMapBounds,
  getMapCenterAndZoomParams,
  urlValueToLatLng,
} from '@atom/utilities/mapUtilities';

const TeamMapView = () => {
  const currentUserLocation = useSelector(getLoggedInUserMapLocationSelector);
  const clusterDensity = useSelector(defaultClusterDensitySelector);

  const dispatch = useGoogleMapsStateUpdater();
  const { isLoaded } = useGoogleMapsStateValue();
  const preferences = usePreferences();

  const [map, setMap] = useState<google.maps.Map>(null);
  const [coordinates, setCoordinates] = useState<string>(null);

  useEffect(() => {
    dispatch({
      type: 'SET',
      payload: {
        key: 'grab',
        data: false,
      },
    });
  }, []);

  const [queryParams, setQueryParams] = useQueryParams({
    id: StringParam,
    center: StringParam,
    zoom: NumberParam,
    searchPoint: StringParam,
  });

  const navigateToDetailPage = (id: string) => {
    history.push(`/team/${id}`);
  };

  const onLoad = useCallback(setMap, []);

  const layers = {
    ...INITIAL_LAYERS,
    assets: false,
    work: false,
    kmlLayers: false,
    workStatusIds: new Set([]),
    clusterDensity,
  };

  const url = buildMapDataRequest(layers, {
    coordinates,
    zoom: queryParams.zoom,
  });

  const [mapData, loading] = useMapData(url);

  const onIdle = useCallback(() => {
    try {
      const params = getMapCenterAndZoomParams(
        map,
        queryParams,
        preferences,
        currentUserLocation,
      );

      if (!R.equals(queryParams, params) || !coordinates) {
        setQueryParams(params);
        R.pipe(
          getGeoJsonFromMapBounds,
          R.prop('coordinates'),
          JSON.stringify,
          setCoordinates,
        )(map);
      }
    } catch {
      setQueryParams(null);
    }
  }, [map, setQueryParams, queryParams, coordinates, preferences]);

  return !isLoaded ? (
    <Progress style={{ height: '100%' }} />
  ) : (
    <Map
      onIdle={onIdle}
      onLoad={onLoad}
      // @ts-ignore
      center={queryParams.center && urlValueToLatLng(queryParams.center)}
      zoom={queryParams.zoom}
      loading={loading}
      mapStyleControl
      mapSearchBox
    >
      <MapMarkers
        // @ts-ignore
        data={JSON.stringify(mapData)}
        onClick={navigateToDetailPage}
      />
      {queryParams.searchPoint && (
        <MapPinMarker center={queryParams.searchPoint} />
      )}
    </Map>
  );
};

export default withGoogleMapsState(TeamMapView);
