import actionTypes from './actionTypes';

export const clearApplicationSearch = () => ({
  type: actionTypes.CLEAR_APPLICATION_SEARCH,
});

export const requestApplicationSearch = data => ({
  type: actionTypes.REQUEST_APPLICATION_SEARCH,
  data,
});

export const getApplicationSearchSuccess = data => ({
  type: actionTypes.GET_APPLICATION_SEARCH_SUCCESS,
  data,
});

export const getApplicationSearchFailure = () => ({
  type: actionTypes.GET_APPLICATION_SEARCH_FAILURE,
});
