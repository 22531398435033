import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';

import LocationsAndAssetsContext from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsContext';
import { GET_ASSETS } from '@atom/graph/asset';
import { TASK_ASSETS_ADD } from '@atom/graph/task';
import { Modal, Snackbar } from '@atom/mui';
import {
  AssetConnectionItem,
  AssetsConnection,
  AssetsConnectionInput,
} from '@atom/types/inventory';
import { TaskAssetsAddInput } from '@atom/types/task';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import AssetSummaryTray from '../AssetSummaryTray';
import {
  AddAssetError,
  getAllAttributesEqual,
  getCartCrossAttributes,
  getCrossValidationAttributes,
} from '../taskLocationUtilities';

import TaskAssetMap from './TaskAssetMap';
import TaskAssetSidebar from './TaskAssetSidebar';
import TaskAssetTree from './TaskAssetTree';

import './taskAssetModal.css';

const styles = {
  modalContent: { padding: '0' },
};

enum AssetView {
  MAP = 'MAP',
  FOLDERS = 'FOLDERS',
}

interface Props {
  open: boolean;
  onClose: () => void;
}

const TaskAssetModal = ({ open, onClose }: Props) => {
  const {
    workOrderDetail,
    task,
    locations,
    assets: assetsOnTask,
    refetch,
  } = useContext(LocationsAndAssetsContext);

  const [cartAssetIds, setCartAssetIds] = useState<string[]>([]);
  const [assets, setAssets] = useState<AssetConnectionItem[]>([]);
  const [hoverId, setHoverId] = useState<string>('');
  const [selectedAssetId, setSelectedAssetId] = useState<string>('');
  const [view, setView] = useState<AssetView>(AssetView.MAP);

  const [getAssets, { loading: assetsLoading }] = useLazyQuery<
    { assets: AssetsConnection },
    { input: AssetsConnectionInput }
  >(GET_ASSETS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setAssets(R.pathOr([], ['assets', 'assets'], data));
    },
  });

  const [addAsset, { loading: loadingAdd }] = useMutation<
    { taskAssetsAdd: string[] },
    { input: TaskAssetsAddInput }
  >(TASK_ASSETS_ADD);

  const getAldotErrorType = () => {
    if (R.isEmpty(assets)) {
      return AddAssetError.NONE;
    }

    const allAttributesEqual = getAllAttributesEqual(assets);
    const hasInitialAttributes =
      !isNilOrEmpty(assetsOnTask) || !isNilOrEmpty(locations);

    if (!allAttributesEqual) {
      return hasInitialAttributes
        ? AddAssetError.ALDOT_CROSS
        : AddAssetError.ALDOT_CART;
    }

    if (hasInitialAttributes && allAttributesEqual) {
      const crossAttributes = getCrossValidationAttributes(
        assetsOnTask,
        locations,
      );
      const cartCrossAttributes = getCartCrossAttributes(assets);

      return R.equals(crossAttributes, cartCrossAttributes)
        ? AddAssetError.NONE
        : AddAssetError.ALDOT_CROSS;
    }

    return AddAssetError.NONE;
  };

  const assetErrorType: AddAssetError = useMemo(() => {
    // Error logic will be built per tenant. Currently only ALDOT
    // has specific error logic.

    switch (true) {
      case isCurrentClient([Client.ALDOT]):
        return getAldotErrorType();
      default:
        return AddAssetError.NONE;
    }
  }, [cartAssetIds, assets, assetsOnTask, locations]);

  useEffect(() => {
    if (cartAssetIds.length === 0) {
      setAssets([]);
      setHoverId('');
      setSelectedAssetId('');
    }

    if (open && cartAssetIds.length !== 0) {
      getAssets({
        variables: {
          input: {
            ids: cartAssetIds,
          },
        },
      });
    }
  }, [open, cartAssetIds]);

  const handleClose = () => {
    setView(AssetView.MAP);
    setCartAssetIds([]);
    setAssets([]);
    setHoverId('');
    setSelectedAssetId('');
    onClose();
  };

  const handleCreate = async () => {
    try {
      await addAsset({
        variables: {
          input: {
            workOrderId: workOrderDetail.id,
            taskId: task.id,
            assetIds: cartAssetIds,
          },
        },
      });
    } catch (error) {
      Snackbar.error({ message: 'An unknown error occurred' });
    }

    refetch();
    handleClose();
  };

  const handleCloseModal = (event, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  };

  const onTrayClose = () => {
    setHoverId('');
    setSelectedAssetId('');
  };

  const onAssetClick = (ids: string) => {
    onTrayClose();

    setCartAssetIds(currentCartAssetIds => {
      const newIds = ids.split(',').reduce((acc, id) => {
        return task?.assetIds.includes(id) ? acc : [...acc, id];
      }, []);

      const updatedCartIds = [...newIds, ...currentCartAssetIds];

      return [...new Set(updatedCartIds)];
    });
  };

  const clearAssets = () => {
    setCartAssetIds([]);
    setHoverId('');
    setAssets([]);
  };

  const removeAsset = (id: string) => {
    setCartAssetIds(currentAssetIds => {
      const assetIndex = R.indexOf(id, currentAssetIds);
      return R.remove(assetIndex, 1, currentAssetIds);
    });
  };

  const onAssetToggle = (id: string) => {
    onTrayClose();
    const hasAssetId = new Set(cartAssetIds).has(id);
    return hasAssetId ? removeAsset(id) : onAssetClick(id);
  };

  const isAddDisabled =
    assetErrorType !== AddAssetError.NONE || cartAssetIds.length === 0;

  const getTabStyle = (value: AssetView) => {
    return value === view ? 'asset-tab active' : 'asset-tab';
  };

  const taskAssetIds = R.pathOr([], ['assetIds'], task);

  return (
    <Modal
      open={open}
      title="Add Assets"
      contentStyle={styles.modalContent}
      width="xxxl"
      confirmButtonText="Add"
      onCancel={(event, reason) => handleCloseModal(event, reason)}
      onConfirm={handleCreate}
      disabled={isAddDisabled}
      loading={loadingAdd}
    >
      <div styleName="modal-content" data-cy="addAssetModal">
        {selectedAssetId ? (
          <AssetSummaryTray assetId={selectedAssetId} onClose={onTrayClose} />
        ) : (
          <TaskAssetSidebar
            assets={assets}
            cartAssetIds={cartAssetIds}
            assetsLoading={assetsLoading}
            removeAsset={removeAsset}
            clearAssets={clearAssets}
            setHoverId={setHoverId}
            selectedAssetId={selectedAssetId}
            setSelectedAssetId={setSelectedAssetId}
            assetErrorType={assetErrorType}
          />
        )}
        <div styleName="map-section">
          <div styleName="tab-header">
            <div
              styleName={getTabStyle(AssetView.MAP)}
              onClick={() => setView(AssetView.MAP)}
            >
              Find on Map
            </div>
            <div
              styleName={getTabStyle(AssetView.FOLDERS)}
              onClick={() => setView(AssetView.FOLDERS)}
            >
              Search Folders
            </div>
          </div>
          {view === AssetView.MAP ? (
            <div styleName="map-container">
              <TaskAssetMap
                cartAssetIds={cartAssetIds}
                onAssetClick={onAssetClick}
                hoverId={hoverId}
                taskAssetIds={taskAssetIds}
              />
            </div>
          ) : (
            <div styleName="tree-container">
              <TaskAssetTree
                cartAssetIds={cartAssetIds}
                taskAssetIds={taskAssetIds}
                onAssetToggle={onAssetToggle}
                setSelectedAssetIds={setCartAssetIds}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default TaskAssetModal;
