import * as React from 'react';

import { FormFieldType } from '@atom/types/form';

import '../formInstance.css';

interface PassedProps {
  field: FormFieldType;
}

type Props = PassedProps;

const DescriptionField = ({ field }: Props) => {
  return (
    <div styleName="field-block">
      <div styleName="description-field-text">{field.text}</div>
    </div>
  );
};

export default DescriptionField;
