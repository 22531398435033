import * as React from 'react';
import * as R from 'ramda';

import './folders.css';

interface Props {
  onToggle: (id: string, path: any[], expanded: boolean) => void;
  folderPath: any[];
  mediaFolderTree: any;
  selectedType?: any;
  mediaTotals?: any;
}

class FolderBreadcrumbs extends React.Component<Props> {
  setFolderView = (id, path, index, arrayLength) => {
    const { onToggle } = this.props;
    const expanded = true;
    return index === arrayLength - 1 ? () => {} : onToggle(id, path, expanded);
  };

  buildBreadcrumbs = () => {
    const { folderPath, mediaFolderTree } = this.props;

    const breadcrumbs = folderPath.reduce((acc, path, index) => {
      if (path === 'children') {
        return acc;
      }
      const currentPath = R.dropLast(index - 1, folderPath);
      const lensPath = R.lensPath(currentPath);
      const folder = R.view(lensPath, mediaFolderTree);
      const breadcrumb = {
        // @ts-ignore
        name: folder.name,
        // @ts-ignore
        id: folder.id,
        path: currentPath,
      };
      return [breadcrumb, ...acc];
    }, []);

    const baseBreadcrumb = {
      name: 'All Files',
      id: 'root',
      path: [],
    };

    return [baseBreadcrumb, ...breadcrumbs];
  };

  render() {
    const { selectedType, mediaTotals } = this.props;
    const mediaTotal = R.pathOr(
      { count: 0, name: '' },
      [selectedType],
      mediaTotals,
    );
    const breadcrumbData = this.buildBreadcrumbs();

    const selectedMediaTotal = (
      <div styleName="media-totals-type">
        {`${mediaTotal.count} ${mediaTotal.name}`}
      </div>
    );

    const breadcrumbs = breadcrumbData.map((breadcrumb, index) => (
      <div
        key={breadcrumb.id}
        styleName="breadcrumb-item"
        onClick={() =>
          this.setFolderView(
            breadcrumb.id,
            breadcrumb.path,
            index,
            breadcrumbs.length,
          )
        }
      >
        {breadcrumb.name}
      </div>
    ));

    const content = selectedType ? selectedMediaTotal : breadcrumbs;

    return <div styleName="folder-breadcrumbs">{content}</div>;
  }
}

export default FolderBreadcrumbs;
