/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useRef } from 'react';
import {
  Circle,
  DrawingManager,
  Marker,
  Polygon,
  Polyline,
} from '@react-google-maps/api';
import debounce from 'lodash.debounce';

import {
  useGoogleMapsStateUpdater,
  useGoogleMapsStateValue,
} from '@atom/components/common/map/hooks/googleMapsStateHook';
import colors from '@atom/styles/colors';
import {
  MapDistanceUnit,
  MapEditMetadata,
  MapEditMetadataType,
  MapShape,
  MapShapeType,
} from '@atom/types/map';
import { getColorFromColorId } from '@atom/utilities/colorUtilities';
import {
  getShapeDistance,
  shapeIsCircle,
  shapeIsMarker,
  shapeIsPolygon,
  shapeIsPolyline,
} from '@atom/utilities/mapShapeUtilities';
import markerUtilities from '@atom/utilities/markerUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './drawingControls.css';

// @react-google-maps/api does not have callbacks for these events
const POLYGON_CHANGE_EVENTS = ['set_at', 'insert_at', 'remove_at'];

const DEBOUNCE_TIME = 500;

// returns the distance of a polyline in miles fixed to 2 decimal places
export const getPolylineDistanceDisplay = (line: MapShape): string => {
  const miles = getShapeDistance(line, MapDistanceUnit.MILES);
  return miles.toFixed(2);
};

const options = {
  manager: {
    drawingControl: false,
    polylineOptions: {
      strokeColor: colors.brand.blue,
      strokeOpacity: 0.5,
      clickable: false,
      draggable: false,
      editable: false,
    },
    circleOptions: {
      fillOpacity: 0,
    },
  },
  distancePolyline: {
    strokeOpacity: 1,
    strokeColor: colors.brand.blue,
  },
  drawPolyline: {
    strokeOpacity: 1,
    strokeColor: colors.brand.blue,
  },
  polygon: {
    fillOpacity: 0,
  },
  circle: {
    fillOpacity: 0,
  },
};

interface Props {
  editItem?: MapEditMetadata;
}

const DrawingData = ({ editItem }: Props) => {
  const { drawingMode, shape } = useGoogleMapsStateValue();
  const dispatch = useGoogleMapsStateUpdater();

  const isEditMode = !isNilOrEmpty(editItem);

  const circle = useRef<Circle>(null);

  const setShape = (shape: any) => {
    dispatch({
      type: 'SET',
      payload: { key: 'shape', data: shape },
    });
  };

  const debouncedSetShape = useCallback(debounce(setShape, DEBOUNCE_TIME), [
    setShape,
  ]);

  const addShapeEventListeners = (shape: any) => {
    if (shapeIsPolygon(shape)) {
      POLYGON_CHANGE_EVENTS.forEach(event => {
        shape.getPath().addListener(event, () => debouncedSetShape(shape));
      });
    }

    return shape;
  };

  const handleShapeChange = (type: MapShapeType) => (shape: any) => {
    shape.type = type;

    // ensure polygon is closed
    if (shapeIsPolygon(shape)) {
      const path = shape.getPath().getArray();
      if (path.length === 1) {
        // If it's a point, reset the shape and continue editing.
        return;
      }
      shape.setPath([...path, path[0]]);
    }

    shape.setVisible(false);

    dispatch({ type: 'SET', payload: { key: 'drawingMode', data: null } });
    setShape(addShapeEventListeners(shape));
  };

  const handlePointChange = (type: MapShapeType) => (shape: any) => {
    shape.setMap(null);
    shape.type = type;

    setShape(shape);
  };

  const polylineOptions = isEditMode
    ? {
        ...options.drawPolyline,
        strokeColor: getColorFromColorId(editItem?.markerId),
      }
    : options.distancePolyline;

  const managerOptions = {
    ...options.manager,
    polylineOptions: {
      ...options.manager.polylineOptions,
      strokeOpacity: isEditMode ? 1 : 0.5,
      strokeColor: isEditMode
        ? getColorFromColorId(editItem?.markerId)
        : colors.brand.blue,
    },
  };

  const getMarkerIcon = () => {
    switch (editItem?.type) {
      case MapEditMetadataType.ASSET:
        return markerUtilities.getActiveAssetIconFromSchemaMarkerId(
          editItem?.markerId,
        );
      case MapEditMetadataType.WORK_ORDER:
        return markerUtilities.getWorkOrderSvg(editItem?.statusId, true);
      case MapEditMetadataType.USER:
        return markerUtilities.getUserSvg(true);
      default:
        return <div />;
    }
  };

  return (
    <>
      <DrawingManager
        // @ts-ignore
        drawingMode={drawingMode}
        options={managerOptions}
        onMarkerComplete={handlePointChange(MapShapeType.MARKER)}
        onPolylineComplete={handleShapeChange(MapShapeType.POLYLINE)}
        onCircleComplete={handleShapeChange(MapShapeType.CIRCLE)}
        onPolygonComplete={handleShapeChange(MapShapeType.POLYGON)}
      />
      {shapeIsMarker(shape) && (
        <Marker
          position={shape.getPosition()}
          icon={getMarkerIcon()}
          zIndex={502}
        />
      )}
      {shapeIsPolyline(shape) && (
        <>
          <Polyline
            editable={isEditMode}
            path={shape.getPath()}
            options={polylineOptions}
          />
        </>
      )}
      {shapeIsPolygon(shape) && (
        <Polygon
          editable
          draggable
          path={shape.getPath()}
          options={options.polygon}
        />
      )}
      {shapeIsCircle(shape) && (
        <Circle
          editable
          draggable
          radius={shape.getRadius()}
          center={shape.getCenter()}
          options={options.circle}
          onCenterChanged={() => {
            // this is a hack due to @react-google-maps-api
            // not updating shape and not passing new center to callback
            if (circle.current?.state) {
              shape.setCenter(circle.current.state.circle.getCenter());
              debouncedSetShape(shape);
            }
          }}
          onRadiusChanged={() => {
            // this is a hack due to @react-google-maps-api
            // not updating shape and not passing new radius to callback
            if (circle.current?.state) {
              shape.setRadius(circle.current.state.circle.getRadius());
              setShape(shape);
            }
          }}
          ref={circle}
        />
      )}
    </>
  );
};

export default DrawingData;
