import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';

import { GET_WORK_ORDER_TEMPLATE } from '@atom/graph/workTemplate';
import { Progress } from '@atom/mui';
import { WorkTemplate as WorkTemplateType } from '@atom/types/workTemplate';
import { templateTaskToTask } from '@atom/utilities/taskUtilities';

import BulkContext, { buildTabs, Tab } from '../BulkContext';

import Step from './Step';

import './formView.css';

const FormView = () => {
  const {
    tab,
    workOrder,
    setWorkOrder,
    workTemplate,
    workTemplateType,
    setWorkTemplateType,
  } = useContext(BulkContext);

  const onCompleted = ({ workOrderTemplate }) => {
    setWorkTemplateType(workOrderTemplate);

    // Initialize the new work order tasks:
    const tasks = templateTaskToTask(workOrderTemplate.tasks);
    const { fieldOrder, fields, summary } = workOrderTemplate;

    const newWorkOrder = { ...workOrder, fieldOrder, fields, summary, tasks };
    setWorkOrder(newWorkOrder);
  };

  const { loading } = useQuery<
    { workOrderTemplate: WorkTemplateType },
    { id: string }
  >(GET_WORK_ORDER_TEMPLATE, {
    variables: { id: workTemplate?.id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted,
  });

  if (loading || !workTemplateType) {
    return (
      <div styleName="form-spinner">
        <Progress />
      </div>
    );
  }

  const tabs = buildTabs(workTemplateType.tasks);

  return (
    <div styleName="form-container">
      <div styleName="form-content">
        <div styleName="form-stepper">
          {tabs.map((tabItem: Tab) => (
            <Step tab={tabItem} currentTab={tab} />
          ))}
        </div>
        <tab.body tab={tab} />
      </div>
    </div>
  );
};

export default FormView;
