import React, { useContext, useMemo } from 'react';

import FavoritesListsTab from '@atom/components/common/favorites/FavoritesListsTab';
import AddTeamModalContext from '@atom/components/common/workOrderDetail/addTeamModal/AddTeamModalContext';
import { FavoriteDetail, FavoriteSubjectType } from '@atom/types/favorite';

import './usersTab.css';

const FavoritesTab = () => {
  const {
    favoriteUsers,
    favoritesLoading,
    favoritesError,
    favoritesLists,
    selectedUsers,
    setSelectedUsers,
    usersOnTask,
    updateFavorite,
    addUserToCart,
    removeUserFromCart,
    handleAddList,
    handleGetFavorites,
  } = useContext(AddTeamModalContext);

  const selectedFavoriteIds: Set<string> = useMemo(
    () => new Set(selectedUsers.map(user => user.id)),
    [selectedUsers],
  );

  const disabledFavoriteIds: Set<string> = useMemo(
    () => new Set([...usersOnTask]),
    [usersOnTask],
  );

  const handleFavoriteToggle = (event, favorite: FavoriteDetail) => {
    event.stopPropagation();
    const toggledUser = favoriteUsers.find(user => user.id === favorite.id);
    if (selectedFavoriteIds.has(favorite.id)) {
      removeUserFromCart(toggledUser.id);
    }
    addUserToCart(toggledUser);
  };

  const handleSetSelectedFavoriteIds = (ids: Set<string>) => {
    const newSelectedUsers = Array.from(ids).map(id => {
      return favoriteUsers.find(user => user.id === id);
    });
    // @ts-ignore
    setSelectedUsers(newSelectedUsers);
  };

  if (favoritesLists.length === 0) {
    return (
      <div styleName="users-list empty-users-list">
        Add your favorite Users to quickly find them again when working on any
        tasks. You can add up to 250 favorites in 50 lists.
      </div>
    );
  }

  return (
    <FavoritesListsTab
      favoritesLists={favoritesLists}
      subjectType={FavoriteSubjectType.USER}
      selectedFavoriteIds={selectedFavoriteIds}
      setSelectedFavoriteIds={handleSetSelectedFavoriteIds}
      disabledFavoriteIds={disabledFavoriteIds}
      handleFavoriteToggle={handleFavoriteToggle}
      updateFavorite={updateFavorite}
      allFavorites={favoriteUsers}
      handleAddList={handleAddList}
      canAddFavorite
      canAddList
      favoritesLoading={favoritesLoading}
      favoritesError={favoritesError}
      handleGetFavorites={handleGetFavorites}
    />
  );
};

export default FavoritesTab;
