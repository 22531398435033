import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';

import * as workOrderActionCreators from '@atom/actions/workOrderActions';
import NavigationButton from '@atom/components/common/map/mapDrawerContent/NavigationButton';
import NavigationControls from '@atom/components/common/map/mapDrawerContent/NavigationControls';
import { Icon, IconButton, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import { WorkOrderActions } from '@atom/types/actions';
import { ReduxStore } from '@atom/types/store';
import { UserDetail } from '@atom/types/user';
import { WorkCollectionItem } from '@atom/types/work';
import history from '@atom/utilities/history';
import userUtilities from '@atom/utilities/userUtilities';
import workOrderStatusUtilities from '@atom/utilities/workOrderStatusUtilities';

import UserSummaryInfo from './UserSummaryInfo';
import UserSummaryWork from './UserSummaryWork';

import './userSummaryTray.css';

const views = [
  {
    value: 'info',
    icon: 'info',
  },
  {
    value: 'work',
    icon: 'work',
  },
];

interface ReduxStateProps {
  user: UserDetail;
  workOrders: WorkCollectionItem[];
  loading: boolean;
}

interface ReduxDispatchProps {
  workOrderActions: WorkOrderActions;
}

interface PassedProps {
  userId: string;
  onCancel: () => void;
}

interface State {
  activeView: string;
}

type Props = PassedProps & ReduxStateProps & ReduxDispatchProps;

class UserSummaryTray extends React.Component<Props, State> {
  state = {
    activeView: 'info',
  };

  componentDidMount() {
    const { workOrderActions, userId } = this.props;
    const page = 1;
    const limit = 250;
    const sortBy = 'dueDate';
    const isAscending = true;

    const filters = {
      statusIds: [
        workOrderStatusUtilities.UNASSIGNED_STATUS_ID,
        workOrderStatusUtilities.ASSIGNED_STATUS_ID,
        workOrderStatusUtilities.IN_PROGRESS_STATUS_ID,
        workOrderStatusUtilities.COMPLETED_STATUS_ID,
      ],
      userIds: [userId],
    };

    workOrderActions.getWorkOrders(page, limit, filters, sortBy, isAscending);
  }

  getTrayContentBody = () => {
    const { user, workOrders } = this.props;
    const { activeView } = this.state;

    switch (activeView) {
      case 'info': {
        return <UserSummaryInfo user={user} />;
      }
      case 'work': {
        return <UserSummaryWork work={workOrders} />;
      }
      default: {
        return <UserSummaryInfo user={user} />;
      }
    }
  };

  setActiveView = activeView => {
    this.setState({ activeView });
  };

  navigateToTeamDetail = () => {
    const {
      user: { id },
    } = this.props;

    history.push(`/team/${id}`);
  };

  render() {
    const { loading, user, onCancel } = this.props;
    const { activeView } = this.state;

    const body = this.getTrayContentBody();

    const content = loading ? (
      <div styleName="spinner-container">
        <Progress />
      </div>
    ) : (
      <div styleName="tray-container">
        <div styleName="header-container">
          <div styleName="exit-container">
            <IconButton onClick={onCancel}>
              <Icon color={colors.neutral.gray}>close</Icon>
            </IconButton>
          </div>
          <div styleName="profile-container">
            <div styleName="header-profile-image">
              <img styleName="profile-image" src={user.photoUrl} />
            </div>
            <div styleName="header-title-container">
              <div styleName="header-name">
                {userUtilities.getUserFullName(user)}
              </div>
              <div styleName="header-subtext">{user.email}</div>
              <div styleName="header-subtext">{user.role}</div>
            </div>
          </div>
        </div>
        <div styleName="tray-content-container">
          <div styleName="summary-image-container" />
          <NavigationControls
            views={views}
            activeView={activeView}
            setActiveView={this.setActiveView}
          />
          <div>{body}</div>
        </div>
        <NavigationButton
          label="View Full Detail"
          onClick={this.navigateToTeamDetail}
        />
      </div>
    );

    return <div styleName="container">{content}</div>;
  }
}

const mapStateToProps = (state: ReduxStore, props): ReduxStateProps => ({
  user: R.has('data')(props) ? props.data : state.userDetail,
  loading: R.has('loading')(props)
    ? props.loading
    : state.loading.loadingUserDetail,
  workOrders: R.has('workOrders')(props) ? props.workOrders : state.workOrders,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  workOrderActions: bindActionCreators(workOrderActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSummaryTray);
