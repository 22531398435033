import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function (state = initialState.inventoryAssetTree, action) {
  switch (action.type) {
    case actionTypes.GET_INVENTORY_ASSET_TREE_SUCCESS: {
      return action.data;
    }
    default: {
      return state;
    }
  }
}
