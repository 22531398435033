import { gql } from '@apollo/client';

export const BASE_TIME_ENTRY = gql`
  fragment BaseTimeEntry on TimeEntry {
    id
    userId
    workOrderId
    taskId
    userGroupId
    userGroup {
      id
      name
      groupPath
    }
    status
    duration
    date
    type

    createdBy {
      firstName
      lastName
    }
    createdDate

    updatedBy {
      firstName
      lastName
    }
    updatedDate

    budget {
      id
      name
      rate
      restored
      reopenedWorkOrderIds
    }

    computedBudgets {
      id
      name
      rate
      duration
      restored
      reopenedWorkOrderIds
    }
    user {
      firstName
      lastName
    }
  }
`;

// Additional time entry properties that are used
// specifically in the time sheet UI
export const ADDITIONAL_TIME_ENTRY = gql`
  fragment AdditionalTimeEntry on TimeEntry {
    workOrder {
      id
      name
      priorityId
      statusId
      inventoryAssetId
      inventoryAssetName
      workTemplateId
      reopened
      createdBy {
        id
        firstName
        lastName
      }
      workTemplate {
        name
      }
      dueDate
      completionDate
      completedBy {
        firstName
        lastName
      }
      tasks {
        id
        name
        isCompleted
      }
      workTemplateFolderActions
      workTemplateFolderGrants {
        type
        ids
      }
    }
    task {
      id
      name
    }
  }
`;

// Additional timeEntries connection properties used to render
// specific parts of the time sheet UI.
export const TIME_SHEET_SUMMARY = gql`
  fragment TimeSheetSummary on TimeEntriesConnection {
    status
    totalDuration
    totalWorkTypes
    totalWorkOrders
    totalOpenWorkOrders
    dailyTotals {
      date
      duration
    }
    summary {
      title
      duration
    }
  }
`;

export const TIME_SHEET_ENTRIES = gql`
  fragment TimeSheetEntries on TimeEntriesConnection {
    totalCount
    ...TimeSheetSummary
    timeEntries {
      ...BaseTimeEntry
      ...AdditionalTimeEntry
    }
  }
  ${TIME_SHEET_SUMMARY}
  ${BASE_TIME_ENTRY}
  ${ADDITIONAL_TIME_ENTRY}
`;

export const GET_TIME_ENTRIES = gql`
  query timeEntries($input: TimeEntriesConnectionInput!) {
    timeEntries(input: $input) {
      totalCount
      timeEntries {
        ...BaseTimeEntry
      }
    }
  }
  ${BASE_TIME_ENTRY}
`;

export const GET_TIME_SHEET_ENTRIES = gql`
  query timeEntries($input: TimeEntriesConnectionInput!) {
    timeEntries(input: $input) {
      ...TimeSheetEntries
    }
  }
  ${TIME_SHEET_ENTRIES}
`;

export const GET_TIME_ENTRIES_STATUS = gql`
  query timeEntries($input: TimeEntriesConnectionInput!) {
    timeEntries(input: $input) {
      totalCount
      status
    }
  }
`;

export const TIME_ENTRY_CREATE = gql`
  mutation timeEntryCreate($input: TimeEntryCreateInput!) {
    timeEntryCreate(input: $input) {
      ...BaseTimeEntry
    }
  }
  ${BASE_TIME_ENTRY}
`;

export const TIME_ENTRIES_BULK_CREATE = gql`
  mutation timeEntriesBulkCreate($input: [TimeEntryCreateInput!]!) {
    timeEntriesBulkCreate(input: $input)
  }
`;

export const TIME_ENTRY_UPDATE = gql`
  mutation timeEntryUpdate($input: TimeEntryUpdate!) {
    timeEntryUpdate(input: $input) {
      ...BaseTimeEntry
    }
  }
  ${BASE_TIME_ENTRY}
`;

export const TIME_ENTRY_DELETE = gql`
  mutation timeEntryDelete($id: ID!) {
    timeEntryDelete(id: $id)
  }
`;

export const TIME_ENTRIES_SUBMIT_BY_USER = gql`
  mutation timeEntriesSubmitByUser($input: TimeEntriesSubmitByUserInput!) {
    timeEntriesSubmitByUser(input: $input)
  }
`;

export const TIME_ENTRIES_UPDATE_BY_APPROVER = gql`
  mutation timeEntriesUpdateByApprover(
    $input: TimeEntriesUpdateByApproverInput!
  ) {
    timeEntriesUpdateByApprover(input: $input)
  }
`;
