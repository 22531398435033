import { useEffect, useState } from 'react';

import { InventoryCategoryTree } from '@atom/types/inventory';
import api from '@atom/utilities/api';
import { CATEGORIES_ENDPOINT } from '@atom/utilities/endpoints';

export interface Options {
  // lazy denotes the data should not be retrieved immediately
  // and that the consumer will manually invoke refetch when data is needed
  lazy?: boolean;
  // params to pass to the API call
  params?: { [key: string]: any };
}

export interface Data {
  loading: boolean;
  refetch: () => Promise<void>;
}

type ReturnTuple = [InventoryCategoryTree, Data];

export const useInventoryCategoryTree = (options?: Options): ReturnTuple => {
  const lazy = options?.lazy ?? false;
  const [tree, setTree] = useState(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [invoked, setInvoked] = useState<boolean>(false);

  const refetch = async () => {
    setInvoked(true);
    setLoading(true);

    const { data } = await api.get<InventoryCategoryTree>(
      `${CATEGORIES_ENDPOINT}/tree`,
      options?.params || {},
    );

    setTree(data || null);
    setLoading(false);
  };

  useEffect(() => {
    if (lazy && !invoked) {
      setLoading(false);
      return;
    }

    refetch();
  }, []);

  return [tree, { loading, refetch }];
};
