import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import TaskCreateControls from '@atom/components/common/workOrderDetail/taskCreate/TaskCreateControls';
import WorkOrderPreviewContext from '@atom/components/workOrderPreview/WorkOrderPreviewContext';
import { TASK_CREATE } from '@atom/graph/task';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Progress } from '@atom/mui';
import { Task, TaskCreateInput } from '@atom/types/task';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { removeFromSet } from '@atom/utilities/setUtilities';

import CollapsedTask from './collapsedTask/CollapsedTask';
import TaskDetail from './TaskDetail';

import './taskView.css';

const TaskView = () => {
  const preferences = usePreferences();
  const disableTaskCreation = R.pathOr(
    false,
    ['workOrders', 'disableTaskCreation'],
    preferences,
  );

  const {
    workOrderDetail,
    collapsedTasks,
    setCollapsedTasks,
    loading,
    refetch,
  } = useContext(WorkOrderPreviewContext);
  const [taskCreate, { loading: loadingCreate }] = useMutation<
    { taskCreate: Task },
    { input: TaskCreateInput }
  >(TASK_CREATE);

  const tasks = R.pathOr([], ['tasks'], workOrderDetail);
  const taskAmount = R.length(workOrderDetail.tasks);
  const taskText = taskAmount === 1 ? 'Task' : 'Tasks';

  const handleCreate = async (
    property: keyof TaskCreateInput,
    value: string,
  ) => {
    const res = await taskCreate({
      variables: {
        input: {
          workOrderId: workOrderDetail.id,
          [property]: value,
        },
      },
    });

    const taskId = res?.data?.taskCreate?.id;
    setCollapsedTasks(removeFromSet(collapsedTasks, taskId));

    refetch();
  };

  const canCreateTask =
    hasRolePermissions(ROLE_SETS.INSPECTOR) && !disableTaskCreation;

  const isCreateDisabled = loading || workOrderDetail.isClosed;

  return (
    <>
      <div styleName="task-section-header">
        <div>{`${taskAmount} ${taskText}`}</div>
        <div styleName="right-header-section">
          {loading && <Progress />}
          {canCreateTask && (
            <TaskCreateControls
              loading={loading || loadingCreate}
              workOrderId={workOrderDetail?.id}
              handleCreate={handleCreate}
              isCreateDisabled={isCreateDisabled}
              data-cy="addTaskPreview"
            />
          )}
        </div>
      </div>
      <div styleName="task-section-container">
        {tasks.map((task: Task) => {
          return !collapsedTasks.has(task.id) ? (
            <TaskDetail key={task.id} task={task} />
          ) : (
            <CollapsedTask key={task.id} task={task} />
          );
        })}
      </div>
    </>
  );
};

export default TaskView;
