import * as React from 'react';

import { Button, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';

import './elements.css';

const styles = {
  dialog: {
    bodyStyle: {
      borderTop: 'initial',
      borderBottom: 'initial',
    },
    title: {
      fontSize: fonts.xl,
      padding: '1.125em 1.125em 1.125em 1.25em',
      fontWeight: '500',
    },
    actionsContainerStyle: {
      margin: 0,
      padding: 0,
    },
  },
  actionButtons: {
    cancel: { margin: '0.25rem' },
  },
  floatingLabelStyle: {
    color: colors.neutral.dim,
  },
  textFieldStyle: {
    color: colors.neutral.dark,
  },
};

interface Props {
  title: string;
  assetName: string;
  open: boolean;
  closeModal: () => any;
}

const FhwaNoElementsDialog = ({
  title,
  assetName,
  open,
  closeModal,
}: Props) => {
  const getFooter = () => {
    return (
      <div style={styles.dialog.actionsContainerStyle}>
        <Button style={styles.actionButtons.cancel} onClick={closeModal}>
          OK
        </Button>
      </div>
    );
  };

  const dialogContent = (
    <div styleName="element-modal-subheading">
      There are no elements under
      <span styleName="element-modal-asset-name"> {assetName}</span>
    </div>
  );

  return (
    <div>
      <Modal
        title={title}
        footer={getFooter()}
        style={styles.dialog.bodyStyle}
        open={open}
      >
        {dialogContent}
      </Modal>
    </div>
  );
};

export default FhwaNoElementsDialog;
