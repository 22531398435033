import React, { useContext, useMemo } from 'react';
import * as R from 'ramda';

import { Icon, IconButton } from '@atom/mui';
import { SchemaAttributeGroup } from '@atom/types/schema';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import CreateAssetModalContext from '../CreateAssetModalContext';

import './attributesTab.css';

interface Props {
  attributeGroup: SchemaAttributeGroup;
}

const AttributeGroupRow = ({ attributeGroup }: Props) => {
  const { collapsedGroups, setCollapsedGroups, errorAttributeIds } = useContext(
    CreateAssetModalContext,
  );

  const handleToggle = () => {
    setCollapsedGroups(toggleFromSet(collapsedGroups, attributeGroup?.id));
  };

  const hasErrorAttributes = useMemo(() => {
    const matchingAttributes = attributeGroup.attributes.map(attributeId => {
      return errorAttributeIds.has(attributeId);
    });

    return R.any(R.equals(true), matchingAttributes);
  }, [attributeGroup, errorAttributeIds]);

  const icon = collapsedGroups.has(attributeGroup?.id)
    ? 'expand_less'
    : 'expand_more';

  return (
    <div styleName="attribute-group-row">
      <div>{attributeGroup.name}</div>
      <div styleName="action-container">
        {hasErrorAttributes && <div>Please fill out the required fields</div>}
        <IconButton onClick={handleToggle} edge="end">
          <Icon>{icon}</Icon>
        </IconButton>
      </div>
    </div>
  );
};

export default AttributeGroupRow;
