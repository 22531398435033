import * as React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';

import * as inventoryAssetActionCreators from '@atom/actions/inventoryAssetActions';
import * as inventorySchemaActionCreators from '@atom/actions/inventorySchemaActions';
import NoElementsDialog from '@atom/components/common/elements/NoElementsDialog';
import { Button, Modal, Progress } from '@atom/mui';
import { getFilteredSchemaByMarkerId } from '@atom/selectors/schemaSelectors';
import {
  InventoryAssetActions,
  InventorySchemaActions,
} from '@atom/types/actions';
import { EventType } from '@atom/types/event';
import { ElementGroupsItem } from '@atom/types/inventory';
import { InventorySchemaState, ReduxStore } from '@atom/types/store';

import FhwaSchemaElementGroupSection from './FhwaSchemaElementGroupSection';
import FhwaSchemaElementListRow from './FhwaSchemaElementListRow';

import './elements.css';

const styles = {
  dialog: {
    bodyStyle: { margin: 0, padding: 0 },
  },
  actionButtons: {
    cancel: { marginRight: '1rem' },
  },
};

const setInitialState = (): any => ({
  open: false,
  selectedItems: new Set([]),
  schemaQuantities: {},
});

type ReduxStateProps = {
  schema: InventorySchemaState;
  loadingBatchCreation: boolean;
  loadingInventorySchema: boolean;
  loadingElementDelete: boolean;
  loadingSchemaTreeNode: boolean;
};

type PassedProps = {
  open?: boolean;
  closeModal?: () => void;
  title: string;
  assetId: string;
  assetName: string;
  onBatchCreate: (payload: Object) => void;
  existingSchemaIds: { [schemaId: string]: any };
};

type ReduxDispatchProps = {
  inventoryAssetActions: InventoryAssetActions;
  inventorySchemaActions: InventorySchemaActions;
};

type Props = PassedProps & ReduxStateProps & ReduxDispatchProps;

type State = {
  open: boolean;
  selectedItems: any;
  schemaQuantities: any;
};

class FhwaAddAssetElementModal extends React.Component<Props, State> {
  state = { ...setInitialState(), open: this.props.open };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (this.props.loadingBatchCreation && !nextProps.loadingBatchCreation) {
      this.closeModal();
    }

    if (this.state.open !== nextProps.open) {
      this.openModal(nextProps.open);
    }
  }

  closeModal = (): (() => void) => {
    const { closeModal, inventorySchemaActions, schema } = this.props;
    this.setState(setInitialState);

    if (!R.isEmpty(schema)) {
      inventorySchemaActions.requestInventorySchemaClear();
    }

    // @ts-ignore
    return closeModal();
  };

  openModal = (open: boolean) => {
    const { assetId, inventorySchemaActions } = this.props;

    inventorySchemaActions.retrieveInventorySchemaByAssetId({ id: assetId });

    this.setState({
      open,
    });
  };

  confirm = (): (() => void) => {
    const {
      schema,
      assetId,
      inventoryAssetActions,
      onBatchCreate,
    } = this.props;
    const { schemaQuantities, selectedItems } = this.state;
    const elements = [...selectedItems].reduce(
      (previous: any[], next: any): any => {
        const pathAndId = next.split('.');
        const schemaId = R.last(pathAndId);
        const path = R.dropLast(1, pathAndId);

        const batchItem = {
          schemaId,
          quantity: schemaQuantities[next] || 1,
        };

        if (R.isEmpty(path)) {
          return [...previous, batchItem];
        }

        const indexPath = path.reduce(
          (previousPath: any[], nextPathId: string): string[] => {
            const lens = R.lensPath(previousPath);
            const section = R.view(lens, previous);

            // @ts-ignore
            const nextIndex = section.findIndex(
              (item: any): boolean => item.schemaId === nextPathId,
            );
            return [...previousPath, nextIndex, 'elements'];
          },
          [],
        );

        // @ts-ignore
        const lens = R.lensPath(indexPath);

        const view = R.view(lens, previous) || [];
        // @ts-ignore
        return R.set(lens, [...view, batchItem], previous);
      },
      [],
    );

    const batchSchemaRequestBody = {
      assetId,
      assetSchemaId: schema.id,
      elements,
    };

    if (!R.isNil(onBatchCreate)) {
      onBatchCreate(batchSchemaRequestBody);
    } else {
      inventoryAssetActions.requestElementBatchCreate(batchSchemaRequestBody);
    }

    return this.closeModal();
  };

  setSchemaQuantity = (input: string): number => {
    const parsedValue = Math.floor(Number(input));
    if (parsedValue > 99) {
      return 99;
    }
    if (parsedValue < 1) {
      return 1;
    }

    return parsedValue;
  };

  onChange = (event: EventType, name: string, value: any): void => {
    const { schemaQuantities } = this.state;
    const schemaQuantity = this.setSchemaQuantity(value);

    return this.setState({
      schemaQuantities: { ...schemaQuantities, [name]: schemaQuantity },
    });
  };

  toggleChecked = (id: string): void => {
    const { selectedItems } = this.state;

    const selectedItemsSet = new Set([...selectedItems]);

    if (selectedItemsSet.has(id)) {
      selectedItemsSet.delete(id);
      const selectedItemsWithRemovals = new Set(
        [...selectedItemsSet].filter(
          (item: any): boolean => !item.startsWith(id),
        ),
      );
      return this.setState({ selectedItems: selectedItemsWithRemovals });
    }
    selectedItemsSet.add(id);
    return this.setState({ selectedItems: selectedItemsSet });
  };

  renderElements = () => {
    const {
      schema,
      loadingSchemaTreeNode,
      inventorySchemaActions,
      existingSchemaIds,
    } = this.props;
    const { selectedItems, schemaQuantities } = this.state;

    if (R.isEmpty(schema) || R.isEmpty(schema.elementGroups)) {
      return <div />;
    }

    const elementGroups = schema.elementGroups;

    return elementGroups.map(
      (elementGroup: ElementGroupsItem, elementGroupIndex: number) => {
        if (elementGroup.isGroup) {
          return (
            <>
              {/* 
                // @ts-ignore */}
              <FhwaSchemaElementGroupSection
                key={elementGroup.name}
                elementGroupIndex={elementGroupIndex}
                inventorySchemaActions={inventorySchemaActions}
                loadingSchemaTreeNode={loadingSchemaTreeNode}
                onClick={this.toggleChecked}
                onChange={this.onChange}
                elementGroup={elementGroup}
                selectedItems={selectedItems}
                schemaQuantities={schemaQuantities}
              />
            </>
          );
        }

        const element = elementGroup.elements[0];
        const subElementExistsOnAsset = existingSchemaIds
          ? !!existingSchemaIds[element.id]
          : false;
        return (
          <div styleName="element-list-row-container" key={element.id}>
            <FhwaSchemaElementListRow
              isChecked={selectedItems.has(element.id)}
              onChange={this.onChange}
              loading={loadingSchemaTreeNode}
              // @ts-ignore
              element={element}
              inventorySchemaActions={inventorySchemaActions}
              onClick={this.toggleChecked}
              value={schemaQuantities[element.id]}
              selectedItems={selectedItems}
              schemaQuantities={schemaQuantities}
              elementPath={['elementGroups', elementGroupIndex, 'elements', 0]}
              elementExistsOnAsset={subElementExistsOnAsset}
            />
          </div>
        );
      },
    );
  };

  render() {
    const {
      title,
      assetName,
      loadingBatchCreation,
      loadingInventorySchema,
      loadingElementDelete,
      schema,
    } = this.props;
    const { open, selectedItems } = this.state;
    const addModalDisabledState = !selectedItems.size;

    const getFooter = () => {
      return (
        <>
          <Button style={styles.actionButtons.cancel} onClick={this.closeModal}>
            Cancel
          </Button>
          <Button
            disabled={addModalDisabledState}
            onClick={this.confirm}
            color="primary"
            variant="contained"
          >
            Add
          </Button>
        </>
      );
    };

    const dialogContent =
      loadingElementDelete ||
      loadingInventorySchema ||
      loadingBatchCreation ||
      R.isEmpty(schema) ? (
        <div styleName="element-modal-spinner-container">
          <Progress />
        </div>
      ) : (
        <div styleName="element-modal-container">
          <div styleName="element-modal-subheading">
            Select elements and quantities from inventory type for
            <span styleName="element-modal-asset-name">{assetName}</span>
          </div>
          <div styleName="element-modal-selection-backdrop">
            {this.renderElements()}
          </div>
        </div>
      );

    const dialog = R.isEmpty(schema.elementGroups) ? (
      <NoElementsDialog
        title="No elements available."
        assetName={assetName}
        closeModal={this.closeModal}
        open={open}
      />
    ) : (
      <Modal
        title={title}
        width="lg"
        footer={getFooter()}
        style={styles.dialog.bodyStyle}
        open={open}
        onCancel={this.closeModal}
      >
        {dialogContent}
      </Modal>
    );

    return <div>{dialog}</div>;
  }
}

export const mapStateToProps = (
  state: ReduxStore,
  ownProps: Props,
): ReduxStateProps => ({
  // @ts-ignore
  schema: getFilteredSchemaByMarkerId(state, ownProps),
  loadingInventorySchema: state.loading.loadingInventorySchema,
  loadingBatchCreation: state.loading.loadingElementBatchCreation,
  loadingElementDelete: state.loading.loadingElementDelete,
  loadingSchemaTreeNode: state.loading.loadingSchemaTreeNode,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  inventorySchemaActions: bindActionCreators(
    inventorySchemaActionCreators,
    dispatch,
  ),
  inventoryAssetActions: bindActionCreators(
    inventoryAssetActionCreators,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FhwaAddAssetElementModal);
