import React from 'react';

import { numberToLocaleString } from '@atom/utilities/currencyUtility';

import './workOrderReport.css';

interface Props {
  title: string;
  emptyHeight?: string;
  actualCost?: number;
  isCompletedOrAbove?: boolean;
  children?: React.ReactNode;
}

const ReportSection = ({
  title,
  emptyHeight,
  actualCost,
  isCompletedOrAbove,
  children,
}: Props) => {
  return (
    <div styleName="section-container">
      <div styleName="section-title-container">
        <div>{title}</div>
        {isCompletedOrAbove && <div>{numberToLocaleString(actualCost)}</div>}
      </div>
      {children || <div style={{ height: emptyHeight }} />}
    </div>
  );
};

export default ReportSection;
