import React from 'react';
import { Popper as MUIPopper } from '@mui/material';

export type PopperProps = any;

export const Popper = (props: PopperProps) => {
  return <MUIPopper {...props} />;
};

export default Popper;
