import React, { useContext } from 'react';

import WorkTemplateContext, {
  WorkTemplateView,
} from '@atom/components/workTemplate/WorkTemplateContext';
import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';

import './workTemplateHeader.css';

export interface Props {
  view: {
    value: WorkTemplateView;
    label: string;
    icon: any;
  };
}

const WorkTemplateHeaderButton = ({ view }: Props) => {
  const { activeView, setActiveView } = useContext(WorkTemplateContext);

  const styleName =
    activeView === view.value ? 'icon-container active' : 'icon-container';

  return (
    <div onClick={() => setActiveView(view.value)} styleName={styleName}>
      <div styleName="icon">
        <Icon color={colors.neutral.white} children={view.icon} />
      </div>
      <div styleName="label">{view.label}</div>
    </div>
  );
};

export default WorkTemplateHeaderButton;
