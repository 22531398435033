import React from 'react';

import { Icon, IconButton, Menu } from '@atom/mui';
import colors from '@atom/styles/colors';

import './paginationControl.css';

const { MenuItem } = Menu;

const LIMIT_OPTIONS = [25, 50, 100];

const styles = {
  selectedMenuItemStyle: {
    color: colors.brand.blue,
  },
  iconStyle: {
    fill: colors.neutral.dim,
  },
};

interface Props {
  incrementPage: (value: number) => any;
  updateLimit: (value: string) => any;
  page: number;
  limit: number;
  total: number;
  limitOptions?: number[];
}

const PaginationControl = ({
  incrementPage,
  updateLimit,
  page = 1,
  limit = 25,
  total = 0,
  limitOptions = LIMIT_OPTIONS,
}: Props) => {
  const startRangeNumber = (page - 1) * limit + 1;
  const limitTotal = page * limit;
  const totalLessThanLimit = total <= limitTotal;
  const endRangeNumber = totalLessThanLimit ? total : limitTotal;

  const handleUpdateLimit = value => {
    if (value !== limit) {
      updateLimit(value);
    }
  };

  return (
    <div styleName="pagination-container">
      <div>Rows per page:</div>
      <div styleName="pagination-limit">{limit}</div>
      <Menu
        value={limit}
        icon="arrow_drop_down"
        IconProps={{ color: colors.neutral.dim }}
        noMinWidth
      >
        {limitOptions.map((option, key) => (
          <MenuItem onClick={() => handleUpdateLimit(option)} key={key}>
            <span
              styleName="limit-option"
              style={option === limit ? styles.selectedMenuItemStyle : {}}
            >
              {option}
            </span>
          </MenuItem>
        ))}
      </Menu>
      <div>{`${startRangeNumber}-${endRangeNumber} of ${total}`}</div>
      <div>
        <IconButton onClick={() => incrementPage(-1)} disabled={page === 1}>
          <Icon color={colors.neutral.dim}>chevron_left</Icon>
        </IconButton>
        <IconButton
          onClick={() => incrementPage(1)}
          disabled={totalLessThanLimit}
        >
          <Icon color={colors.neutral.dim}>chevron_right</Icon>
        </IconButton>
      </div>
    </div>
  );
};

export default PaginationControl;
