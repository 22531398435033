import React, { useContext, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import { GET_ANALYTICS_FOLDER_TREE } from '@atom/graph/analytics';
import { Progress } from '@atom/mui';
import { AnalyticsFolderTree } from '@atom/types/analytics';
import { POLICY_GRANT_IDS_ALL } from '@atom/types/policy';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import { flattenTreeChildren } from '@atom/utilities/treeUtilities';

import PolicyModalContext from '../PolicyModalContext';

import AnalyticsFolderSelectionContext from './AnalyticsFolderSelectionContext';
import AnalyticsFolderSelectTree from './AnalyticsFolderSelectTree';
import {
  getAllDescendantFolderIds,
  ROOT_TREE_ID,
} from './analyticsFolderSelectUtils';

const AnalyticsFolderSelection = () => {
  const { data, loading } = useQuery(GET_ANALYTICS_FOLDER_TREE, {
    fetchPolicy: 'network-only',
  });
  const { state, updateState } = useContext(PolicyModalContext);
  const { grants } = state;

  const selectedFolderIds = useMemo(
    () =>
      grants.some(({ id }) => id === POLICY_GRANT_IDS_ALL)
        ? new Set([ROOT_TREE_ID])
        : new Set(grants.map(({ id }) => id)),
    [grants],
  );

  const handleChange = (folder: AnalyticsFolderTree) => {
    if (folder.id === ROOT_TREE_ID) {
      const isAllSelected = grants.some(
        ({ id }) => id === POLICY_GRANT_IDS_ALL,
      );
      return updateState({
        grants: isAllSelected
          ? []
          : [{ id: POLICY_GRANT_IDS_ALL, name: 'All' }],
      });
    }

    const isSelected = selectedFolderIds.has(folder.id);
    const updated = isSelected
      ? grants.filter(({ id }) => id !== folder.id)
      : [...grants, { id: folder.id, name: folder.name }];

    const descendantIds = new Set(getAllDescendantFolderIds(folder));
    const filtered = updated.filter(({ id }) => !descendantIds.has(id));

    return updateState({ grants: filtered });
  };

  const [searchResults, setSearchResults] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');

  const [openFolders, setOpen] = useState<Set<string>>(new Set(['analytics']));
  const toggleOpen = (id: string) => setOpen(toggleFromSet(openFolders, id));
  const treeData = data?.analyticsFolderTree;
  const treeLoading = loading || R.isNil(treeData);
  const allFolders: AnalyticsFolderTree[] = useMemo(
    () => (!treeLoading ? flattenTreeChildren(treeData) : []),
    [treeData],
  );

  return (
    <AnalyticsFolderSelectionContext.Provider
      value={{
        openFolders,
        toggleOpen,
        treeLoading,
        treeData,
        allFolders,
        searchResults,
        setSearchResults,
        query,
        setQuery,
        selectedFolderIds,
        handleChange,
      }}
    >
      {treeLoading ? <Progress /> : <AnalyticsFolderSelectTree />}
    </AnalyticsFolderSelectionContext.Provider>
  );
};

export default AnalyticsFolderSelection;
