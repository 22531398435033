import React from 'react';

import { Icon, IconButton } from '@atom/mui';

import './workTemplateTaskDetail.css';

const styles = {
  iconButton: {
    width: '1rem',
    height: '1rem',
    padding: '0 0 0.2rem 0',
  },
  icon: {
    fontSize: '1rem',
  },
};

const TaskActualCost = () => {
  const tooltipContent = (
    <div styleName="tooltip-content">
      <strong>How is Actual Cost calculated?</strong>
      <div>Labor cost + Inventory Cost</div>
    </div>
  );

  return (
    <div styleName="cost-content-right">
      <div styleName="label-container">
        <div styleName="label">Actual Cost</div>
        <IconButton tooltip={tooltipContent} style={styles.iconButton}>
          <Icon style={styles.icon}>help</Icon>
        </IconButton>
      </div>
      <div>-</div>
    </div>
  );
};

export default TaskActualCost;
