import React from 'react';

import { AnalyticsFolderTree } from '@atom/types/analytics';

interface Context {
  openFolders: Set<string>;
  toggleOpen: (id: string) => void;
  treeLoading: boolean;
  treeData: AnalyticsFolderTree;
  allFolders: AnalyticsFolderTree[];
  searchResults: boolean; // should be set of results
  setSearchResults: React.Dispatch<React.SetStateAction<boolean>>;
  query: string;
  setQuery: (query: string) => void;
  selectedFolderIds: Set<string>;
  handleChange: (folder: AnalyticsFolderTree) => void;
}

export const initialState: Context = {
  openFolders: new Set(['analytics']),
  toggleOpen: () => {},
  treeLoading: false,
  treeData: null,
  allFolders: [],
  searchResults: false,
  setSearchResults: () => {},
  query: '',
  setQuery: () => {},
  selectedFolderIds: new Set(),
  handleChange: () => {},
};

const AnalyticsFolderSelectionContext = React.createContext<Context>(
  initialState,
);

export default AnalyticsFolderSelectionContext;
