import React from 'react';

import { DatePicker } from '@atom/mui';
import { convertDateToMillisGMTMidday } from '@atom/utilities/timeUtilities';

import '../../formInstance.css';

interface PassedProps {
  value: any;
  type: string;
  isEditable: boolean;
  onChange: (dateMillis?: number) => void;
}

type Props = PassedProps;

const DateAttribute = ({ value, type, onChange, isEditable }: Props) => {
  const onDateChange = (date?: Date) => {
    if (!date) {
      onChange(null);
    } else {
      onChange(convertDateToMillisGMTMidday(date));
    }
  };

  return (
    <div styleName="attribute-input">
      <DatePicker
        name={type}
        value={value || null}
        onChange={onDateChange}
        disabled={!isEditable}
      />
    </div>
  );
};

export default DateAttribute;
